import {FC, useState} from "react";
import moment from "moment";

interface iJobSchedulerProps {
  scheduler: any;
}
export const JobSchedulerControls: FC<iJobSchedulerProps> = ({scheduler}) => {
  const [disabled, setDisabled] = useState(true);

  const handleClickPrevNavButton = () => {
    scheduler._click.dhx_cal_prev_button();
    const {min_date} = scheduler?.getState();
    verifyDatesRange(new Date(), min_date);
  };

  const handleClickNextNavButton = () => {
    scheduler._click.dhx_cal_next_button();
    const {min_date} = scheduler?.getState();
    verifyDatesRange(new Date(), min_date);
  };

  function verifyDatesRange(date: Date, minDate: moment.Moment) {
    if (moment(date).isBefore(minDate)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  return (
    <div className="JobsSettingsCalendarTemplate-controls ">
      <div className="JobsSettingsCalendarTemplate-nav">
        <button
          onClick={handleClickPrevNavButton}
          className="JobsSettingsCalendarTemplate-nav-button-prev"
          aria-label="Prev"
          disabled={disabled}
        />
        <button
          onClick={handleClickNextNavButton}
          className="JobsSettingsCalendarTemplate-nav-button-next"
          aria-label="Next"
        />
      </div>
    </div>
  );
};
