import {FC, useState} from "react";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {tEstimateData} from "api/budget/estimate/types";
import {getUsMoneyFormat} from "helpers/formattingData";
import {EXPANDED_BUDGET_LIST} from "../../EstimateTable/EstimateGroupRow/EstimateGroupRow";
import {AppTooltips} from "components/AppTooltips/AppTooltips";
import "./EstimateListWrap.scss";

type tEstimateListProps = {
  data: tEstimateData | null;
};

export const EstimateList: FC<tEstimateListProps> = ({data}) => {
  const firstItem = data?.category_values?.[0]?.id || null;
  const [activeItem, setActiveItem] = useState<number | null>(firstItem);

  const scrollTo = (id: number) => {
    setActiveItem(id);
    const section = document.querySelector(`#estimateGroup-${id}`);
    section?.scrollIntoView({behavior: "smooth", block: "start"});

    const expanded = section?.classList.contains(EXPANDED_BUDGET_LIST);
    const toggler = section?.children?.[0] as HTMLButtonElement;
    !expanded && toggler && toggler?.click();
  };

  return (
    <div className="EstimateList">
      {data?.category_values?.map((item, i) => {
        const currClass = activeItem === item?.id ? "EstimateListItem active" : "EstimateListItem";

        return (
          <TextComponent
            w100
            key={item.id}
            className={currClass}
            onClick={() => scrollTo?.(item?.id)}
          >
            <TextComponent size="13" weight="400">
              {item?.code}
            </TextComponent>
            <TextComponent
              size="13"
              weight="500"
              truncate
              className={!!item?.total ? "" : "name-lg"}
            >
              {item?.name}

              {i === 0 && (
                <div className="AppTooltipsWrap">
                  <AppTooltips page="estimate" tooltip="QUICK NAVigation" />
                </div>
              )}
            </TextComponent>
            {!!item?.total && (
              <TextComponent size="13" weight="400" color="gray-10" textAlign="right">
                {getUsMoneyFormat(item.total)}
              </TextComponent>
            )}
          </TextComponent>
        );
      })}
    </div>
  );
};
