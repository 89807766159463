import React, {useState, FC, ReactNode} from "react";
import {createPortal} from "react-dom";
import "./TooltipPortal.scss";

type tTooltipPortalProps = {
  children?: ReactNode;
  position?: "left" | "right" | "top" | "bottom";
  color?: "dark" | "light";
  message: ReactNode;
  width?: number;
  maxWidth?: number; //in px, maybe need if child have truncate property
};

export const TooltipPortal: FC<tTooltipPortalProps> = props => {
  const {children, position = "bottom", color = "dark", message, width = 200, maxWidth} = props;
  const halfWIdth = width / 2;

  type posType = {left: string; top: string; transform: string} | null;
  const [elPos, setPosition] = useState<posType>(null);

  const onMouseEnter = (e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    const root: DOMRect | undefined = e.currentTarget.getBoundingClientRect();
    const halfRootWidth = root?.width / 2;
    const halfRootHeight = root?.height / 2;
    const chevronWidth = "8px";
    let transform = "";

    switch (position) {
      case "top":
        transform = `translate(calc(-100% + ${halfWIdth}px + ${halfRootWidth}px), calc(-100% + -${chevronWidth}))`;
        setPosition({left: `${root?.left}px`, top: `${root?.top}px`, transform});
        break;
      case "bottom":
        transform = `translate(calc(-100% + ${halfWIdth}px + ${halfRootWidth}px), ${chevronWidth})`;
        setPosition({left: `${root?.left}px`, top: `${root?.bottom}px`, transform});
        break;
      case "left":
        transform = `translate(calc(-100% + -${chevronWidth}), calc(-50% + -${halfRootHeight}px))`;
        setPosition({left: `${root?.left}px`, top: `${root?.bottom}px`, transform});
        break;
      case "right":
        transform = `translate(calc(${root?.width}px + ${chevronWidth}), calc(-50% + -${halfRootHeight}px))`;
        setPosition({left: `${root?.left}px`, top: `${root?.bottom}px`, transform});
        break;
      default:
        break;
    }
  };

  return (
    <div
      className="TooltipPortalWrap"
      style={{maxWidth: `${maxWidth}px`, width: "fit-content"}}
      onMouseEnter={onMouseEnter}
      onMouseLeave={() => setPosition(null)}
    >
      {children}

      {!!elPos &&
        createPortal(
          <div
            className={`portal-tooltip ${position}`}
            style={{...elPos, maxWidth: `${width}px`, width: `${width}px`}}
          >
            <div className={`TooltipPortal-text ${color}`}>{message}</div>
          </div>,
          document?.body
        )}
    </div>
  );
};

/* how to use
<TooltipPortal message={'some text'} position="top">
  <div>
    some text
  </div>
</TooltipPortal>
*/
