import {useContextJobBudget} from "../../_context/JobBudgetContext";

export const useGenerateData = () => {
  const {estimate} = useContextJobBudget();

  const budgetCosts = [
    {
      title: "Crew Payroll Fee",
      percent: estimate?.crew_payroll_fee_percent,
      value: estimate?.crew_payroll_fee
    },
    {
      title: "Talent Payroll Fee",
      percent: estimate?.talent_payroll_fee_percent,
      value: estimate?.talent_payroll_fee
    },
    {
      title: "Union Crew Payroll Fee",
      percent: estimate?.union_crew_payroll_fee_percent,
      value: estimate?.union_crew_payroll_fee
    },
    {
      title: "Union Talent Payroll Fee",
      percent: estimate?.union_talent_payroll_fee_percent,
      value: estimate?.union_talent_payroll_fee
    }
  ];

  const grandTotal = [
    {
      title: "Production %",
      percent: estimate?.production_percent,
      value: estimate?.production_total
    },
    {
      title: "Insurance %",
      percent: estimate?.insurance_percent,
      value: estimate?.insurance
    },
    {
      title: "Green",
      percent: estimate?.green_percent,
      value: estimate?.green
    },
    {
      title: "Sales Tax",
      percent: estimate?.sales_tax_percent,
      value: estimate?.sales_tax
    }
  ];

  return {budgetCosts, grandTotal, budgetCostVal: estimate?.budgeted_costs};
};
