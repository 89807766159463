import {tExpenseList} from "api/budget/track/types";
import {useSortTableColumns} from "../ExpenseTable/_helpers/useSortTableColumns";
import {useFilterList} from "../ExpenseTable/_helpers/useFilterList";
import {HIDDEN_COLUMNS} from "../../_modals/ExpenseListViewOptions/ExpenseListViewOptions";
import {useSetApiParams} from "hooks/useSetApiParams";
import {expenseListTableTitles} from "../ExpenseTable/expenseListTableTitles";
import {getUsMoneyFormat} from "helpers/formattingData";

type tColumn = {
  id: string;
  label: string;
  sort: boolean;
};
const exceptionColumn = ["W8", "W9", "ACH", "WIRE"];

export function useExportExpensesCsv() {
  const apiParams = useSetApiParams();
  const {sortList} = useSortTableColumns();
  const {filterList} = useFilterList();

  const totalData = {working_details: 0, actual_details: 0, charged: 0};

  function getActualColumns() {
    const hiddenColumns = apiParams?.preparedParams?.[HIDDEN_COLUMNS]?.split(",");

    const actualColumns = expenseListTableTitles
      .filter(title => !hiddenColumns?.find(c => c === title.viewOptionId) && title?.viewOptionId)
      .filter(col => !exceptionColumn.includes(col.id));

    const elementToInsert = {id: "bill_state", label: "Bill state", sort: false}; // The element you want to insert
    return [...actualColumns, elementToInsert];
  }

  const getFilteredList = (expenseList: tExpenseList, column: tColumn[]): any[] => {
    return filterList(sortList(expenseList?.list)).map((expense: any) => {
      totalData["working_details"] =
        totalData.working_details + (+expense?.working_details?.amount || 0);
      totalData["actual_details"] =
        totalData.actual_details + (+expense?.actual_details?.amount || 0);
      totalData["charged"] = totalData.charged + (+expense?.charged || 0);
      let newExpense = {};
      column.forEach(({id, label}) => {
        let value = expense[id.toLowerCase()];
        if (id === "estimate" || id === "qb_status" || id === "pay_status") {
          value = expense[id]?.name;
        }
        if (id === "actual_details") {
          value = getUsMoneyFormat(expense[id]?.amount || 0);
        }
        if (id === "working_details") {
          value = getUsMoneyFormat(expense[id]?.amount || 0);
        }
        if (id === "charged") {
          value = getUsMoneyFormat(expense[id] || 0);
        }
        if (id === "cost_value") {
          value = `${expense[id]?.code}${expense[id]?.suffix} ${expense[id]?.name}`;
        }
        if (id === "pay_value") {
          const target = expense[id]?.target;
          const targetValue = !!target
            ? `${target?.name || ""} ${target?.holder_initials || ""} ${
                target?.last_four_digits || ""
              }`
            : null;

          const expIdName = expense[id]?.payId?.name;
          value = targetValue || expIdName;
        }
        if (id === "QP") {
          value = expense?.qb_state;
        }
        newExpense = {...newExpense, [label]: value ? `"${value}"` : ""};
      });

      return {...newExpense, "Bill state": expense?.bill_state ? "" : "do not bill"};
    });
  };

  const sortColumnOrder = (column: tColumn[], list: any[]): Array<string[]> => {
    const preparedList: any[] = list.map(val => {
      return column.reduce((prev: any[], current: any, index) => {
        return (prev[index] = [...prev, val?.[current?.label]]);
      }, []);
    });
    return [column.map(k => k.label), ...preparedList];
  };
  const saveFile = (expenseList: tExpenseList | undefined, name = "") => {
    if (!expenseList) return;
    const actualColumns: tColumn[] = getActualColumns();
    const list: any = getFilteredList(expenseList, actualColumns);
    let sortedList = sortColumnOrder(actualColumns, list);
    const totalRowCols = actualColumns.filter(({id}) => Object.keys(totalData).find(k => id === k));
    const totalRow = !!totalRowCols.length ? new Array(actualColumns.length) : [];
    totalRowCols.forEach(current => {
      const index = sortedList[0].findIndex(v => v === current.label);
      // @ts-ignore
      totalRow[index] = totalData[current.id];
    }, []);
    const csvContent = [...sortedList, totalRow].reduce((prev, current, currentIndex) => {
      return (prev += current.join(",") + "\n");
    }, "");
    const blob = new Blob([csvContent], {type: "text/csv;charset=utf-8,"});
    const objUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", objUrl);
    link.setAttribute("download", `${name}.csv`);
    link.click();
    link.remove();
  };

  return {saveFile};
}
