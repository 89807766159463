import {useEffect, useState} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {estimateApi} from "api/budget/estimate";
import {tCategoryCost, tEstimateType} from "api/budget/estimate/types";

export type tBudgetTypesReturn = ReturnType<typeof useBudgetTypes>;

type tStep = "budgetTypes" | "categories" | "costs";
type tData = {
  id: number;
  name: string;
};
type tStepData = {
  budgetTypes?: tData;
  categories?: tData;
  costs?: tData;
  step: tStep;
};

type tCosts = {
  [key: number]: tCategoryCost[];
};

export function useBudgetTypes() {
  const budgetTypeQuery = useHttp();
  const [budgetTypeList, setBudgetTypeList] = useState<tEstimateType[]>([]);
  const [stepData, setStepData] = useState<tStepData>({
    step: "budgetTypes"
  });

  const [costs, setCosts] = useState<tCosts>({});

  useEffect(() => {
    budgetTypeQuery.call(estimateApi.getEstimateTypesList()).then(({data: {data}}) => {
      setBudgetTypeList(data);
    });
  }, []); // eslint-disable-line

  return {
    costs,
    setCosts,
    isLoading: budgetTypeQuery.isLoading,
    budgetTypeList,
    setBudgetTypeList,
    stepData,
    setStepData
  };
}
