import {FC} from "react";
import {CurrencyRates} from "components/shared/CurrencyRates/CurrencyRates";

interface iJobSettingsCurrencyProps {
  onClose: (arg: null) => void;
}

export const JobSettingsCurrency: FC<iJobSettingsCurrencyProps> = ({onClose}) => {
  return <CurrencyRates type="default" onClose={() => onClose(null)} />;
};
