import {FC, FormEvent} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useContextJobTasks} from "../_context/JobTaskContext";
import {notValidForm} from "helpers/misc";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";

type tRenameTasksGroupProps = {
  onClose: () => void;
  name: string;
  id: number;
};

export const RenameTasksGroup: FC<tRenameTasksGroupProps> = ({onClose, name, id}) => {
  const {tasks, updateTasksData, updateDataCall: {isLoading}} = useContextJobTasks(); // prettier-ignore
  const FORM_ID = "rename-job-task-group-form-id";

  const form = {
    name: useTextInput({value: name})
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (notValidForm(form)) return;

    const newData = tasks?.data?.map((item, i) => {
      if (id === i) {
        return {...item, name: form.name.value};
      }

      return item;
    });

    updateTasksData(newData, onClose);
  };

  return (
    <ConfirmWindow
      show={true}
      title="Rename group"
      cancelBtn={{text: "Cancel", type: "button"}}
      onClose={onClose}
      confirmBtn={{text: "rename", type: "submit", form: FORM_ID}}
      isLoading={isLoading}
    >
      <form onSubmit={onSubmit} id={FORM_ID}>
        <InputField {...form.name.inputProps} errors={form.name.errors} />
      </form>
    </ConfirmWindow>
  );
};
