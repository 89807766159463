import moment from "moment";

export function getPeriodTime(date?: Date) {
  return moment(date).calendar({
    sameDay: "[Today]",
    lastDay: "[Yesterday]",
    lastWeek: "[Previous Week]",
    sameElse: "[Earlier]"
  });
}

export function getNotificationPeriodTime<T extends {created_at: Date}>(
  date: Date,
  index: number,
  array: T[]
) {
  return getPeriodTime(array[index - 1]?.created_at) !== getPeriodTime(date) || index === 0
    ? getPeriodTime(date)
    : "";
}
