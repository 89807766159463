import {ChangeEvent, Dispatch, FC, FormEvent, SetStateAction, useState} from "react";
import {FormWrap, iFormWrapProps} from "components/shared/FormWrap/FormWrap";
import {SenderRecipientsGroup} from "./SenderRecipientsGroup/SenderRecipientsGroup";
import {SenderRecipientToolbar} from "./SenderRecipientToolbar/SenderRecipientToolbar";
import {tCallSHeetGroupContacts} from "api/callSheet/types";
import "./CallSheetSenderRecipients.scss";

interface iCallSheetSenderRecipientsProps {
  onRecipientSubmitCallback: (groups: Array<tCallSHeetGroupContacts>) => void;
  formWrapProps?: Partial<Omit<iFormWrapProps, "children" | "idForm">>;
  contactsGroups: Array<tCallSHeetGroupContacts>;
  setContactsGroups: Dispatch<SetStateAction<tCallSHeetGroupContacts[]>>;
}
export const CallSheetSenderRecipients: FC<iCallSheetSenderRecipientsProps> = ({
  onRecipientSubmitCallback,
  formWrapProps,
  contactsGroups,
  setContactsGroups
}) => {
  const [expanded, setExpanded] = useState<number[]>([]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onRecipientSubmitCallback(contactsGroups);
  };

  const onSelectContact = (event: ChangeEvent<HTMLInputElement>, id: number | string) => {
    setContactsGroups(prev => {
      let newGroups: tCallSHeetGroupContacts[] = [];

      prev.forEach(group => {
        newGroups = [
          ...newGroups,
          {
            ...group,
            contacts: group.contacts.map(contact =>
              String(contact.id) === String(id)
                ? {...contact, selected: !contact.selected}
                : contact
            )
          }
        ];
      });

      return newGroups;
    });
  };

  const onSelectGroup = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    setContactsGroups(prev => {
      let newGroups: tCallSHeetGroupContacts[] = [];
      prev.forEach(group => {
        if (group.id === id) {
          newGroups = [
            ...newGroups,
            {
              ...group,
              contacts: group.contacts.map(contact => ({
                ...contact,
                selected: !!event?.target.checked
              }))
            }
          ];
          return;
        }
        newGroups = [...newGroups, group];
      });
      return newGroups;
    });
  };

  const toggleAll = () => {
    if (expanded.length) {
      setExpanded([]);
    } else {
      setExpanded([...contactsGroups.map(group => group.id)]);
    }
  };

  const onCheckAll = (event: ChangeEvent<HTMLInputElement>) => {
    setContactsGroups(prev => {
      let newGroups: tCallSHeetGroupContacts[] = [];
      prev.forEach(group => {
        newGroups = [
          ...newGroups,
          {
            ...group,
            contacts: group.contacts.map(contact => ({
              ...contact,
              selected: !!event?.target?.checked
            }))
          }
        ];
      });
      return newGroups;
    });
  };

  const isCheckedAll = contactsGroups.every((group) => group.contacts.every((contact) => contact.selected)); // prettier-ignore

  return (
    <div className="CallSheetSenderRecipients">
      <FormWrap
        headerTitle="RESEND LATEST DELIVERY TO <NAME>"
        idForm="call-sheet-recipient-form"
        type="titleCenterAndOneBtn"
        submitTitle="next"
        subtitle="Select RECIPIENTS"
        {...formWrapProps}
      >
        <SenderRecipientToolbar
          expanded={expanded}
          toggleAll={toggleAll}
          onCheckAll={onCheckAll}
          isCheckedAll={isCheckedAll}
        />
        <form id="call-sheet-recipient-form" onSubmit={handleSubmit}>
          {!!contactsGroups?.length &&
            contactsGroups?.map((group, index) => (
              <SenderRecipientsGroup
                key={group.id * index}
                onToggle={setExpanded}
                expanded={expanded}
                data={group}
                onSelectContact={onSelectContact}
                onSelectGroup={onSelectGroup}
              />
            ))}
        </form>
      </FormWrap>
    </div>
  );
};
