import {FC, forwardRef, MouseEvent, useEffect, useState} from "react";
import DatePicker, {ReactDatePickerProps} from "react-datepicker";
import moment from "moment";
import {Icon} from "components/Icon/Icon";
import {ReactSelect} from "../ReactSelect/ReactSelect";

import "react-datepicker/dist/react-datepicker.css";
import "./Datepicker.scss";

interface iDatepickerProps extends Partial<Omit<ReactDatePickerProps, "value" | "onChange">> {
  onChange: (date: string) => void;
  value: string;
  label?: string;
  className?: string;
  errors?: string[];
  dateFormat?: string;
  withIcon?: boolean;
}

export const DatepickerWithYear: FC<iDatepickerProps> = ({
  dateFormat = "MM/DD/yyyy",
  errors = [],
  className = "",
  label = "",
  withIcon,
  value,
  onChange,
  ...props
}) => {
  const [yearMenuIsOpen, setYearMenuIsOpen] = useState(false);

  useEffect(() => {
    return () => setYearMenuIsOpen(false);
  }, []);

  if (!onChange) return null;

  const isError = !!errors.length ? "error" : "";

  const InputWithIcon = forwardRef((props: any, ref: any) => (
    <div ref={ref} className="Datepicker-inputWrap wrap" onClick={props.onClick}>
      {withIcon && <Icon icon="calendar" color="dark-gray" />}
      <input type="text" {...props} readOnly />
    </div>
  ));

  const years = () => {
    let years: any = [];
    const dateStart = moment().set("year", 1900);
    const dateEnd = moment().subtract(11, "years");
    while (dateEnd.diff(dateStart, "years") >= 0) {
      const year = dateStart.format("YYYY");
      years = [...years, {value: year, label: year}];
      dateStart.add(1, "year");
    }
    return years;
  };

  const handleChange = (date: Date | null) => onChange(date ? date.toString() : "");

  const getMonth = (date: Date) => moment(date).format("MMMM");
  const getYear = (
    date: Date | string
  ): {
    value: string;
    label: string;
  } => {
    const year = typeof date === "string" ? date : moment(date).format("YYYY");
    return {
      value: year,
      label: year
    };
  };
  const onClickControls = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    !yearMenuIsOpen && setYearMenuIsOpen(true);
  };

  return (
    <div className={`Datepicker ${className} ${withIcon ? "withIcon" : ""}`}>
      {label && <span className={`Datepicker-label label ${isError}`}>{label}</span>}

      <DatePicker
        {...props}
        value={!!value ? moment(value).format(dateFormat) : moment().set("year", 2000).toString()}
        className={`Datepicker-input input ${isError}`}
        onChange={handleChange}
        onYearChange={handleChange}
        onMonthChange={handleChange}
        maxDate={new Date()}
        customInput={<InputWithIcon />}
        selected={!!value ? moment(value).toDate() : moment().set("year", 2000).toDate()}
        onCalendarClose={() => setYearMenuIsOpen(false)}
        renderCustomHeader={({
          date,
          changeYear,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <div>
            <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              <Icon icon="arrow-left" />
            </button>

            <div className="Datepicker-customControls">
              <h5 onClick={onClickControls} className="Datepicker-customMonth">
                {getMonth(date)}
              </h5>
              <ReactSelect
                value={date ? getYear(date) : getYear("2000")}
                onChange={option => changeYear(option.value)}
                options={years()}
                menuIsOpen={yearMenuIsOpen}
                onMenuOpen={() => setYearMenuIsOpen(prev => !prev)}
                onMenuClose={() => setYearMenuIsOpen(false)}
              />
            </div>
            <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              <Icon icon="arrow-right" />
            </button>
          </div>
        )}
      />
      {errors.map((error, i) => (
        <span key={i} className="Datepicker-error-message error-message">
          {error}
        </span>
      ))}
    </div>
  );
};
