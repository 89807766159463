import {FC, FormEvent} from "react";
import {ImgWithFallback} from "components/shared/ImgWithFallback/ImgWithFallback";
import BudgetImage from "assets/images/budget_mono.svg";
import {TextComponent} from "../../../common/TextComponent/TextComponent";
import {Button} from "components/shared/Button/Button";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useSelect} from "hooks/inputHooks";
import {Select} from "components/shared/FormComponents/Select/Select";
import {notValidForm} from "helpers/misc";
import {useHttp} from "hooks/httpServices/useHttp";
import {advanceApi} from "api/budget/advance";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useContextAdvance} from "../context";
import {useContextJobBudget} from "pages/Jobs/JobDetails/JobBudget/context/JobBudgetContext";
import {useNavigate} from "react-router-dom";
import "./AdvanceEmptyState.scss";

export const AdvanceEmptyState: FC = () => {
  const navigate = useNavigate();
  const {estimateList} = useContextJobBudget();
  const {setAdvanceList} = useContextAdvance();
  const {call, isLoading} = useHttp();

  const formData = {
    name: useTextInput({
      isRequired: true,
      validators: [lengthValidation(3)]
    }),
    estimate: useSelect({isRequired: true})
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (notValidForm(formData)) return;

    const payload = {name: formData.name.value, estimate_id: formData.estimate.value};

    try {
      const {
        data: {data, message}
      } = await call(advanceApi.createAdvance(payload));
      eventBus.dispatch("showToast", {text: message, type: "success"});
      setAdvanceList([data]);
      navigate(String(data?.id));
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {text: msg, type: "error"});
    }
  };

  return (
    <div className="AdvanceEmptyState">
      <ImgWithFallback className="AdvanceEmptyState-image" src={BudgetImage} />
      <TextComponent weight="300" size="20" m="mb-40">
        Name your advance and choose an estimate.
      </TextComponent>

      <form className="AdvanceEmptyState-form" onSubmit={handleSubmit}>
        <InputField
          {...formData.name.inputProps}
          placeholder="Name"
          errors={formData.name.errors}
        />
        <Select
          {...formData.estimate.inputProps}
          canSelectEmpty
          errors={formData.estimate.errors}
          options={estimateList?.filter(item => item.approved_at)}
          targetKeys={{
            value: "id",
            label: "name"
          }}
        />
        <Button size="md" color="dark" width="fullWidth" loading={isLoading}>
          Add Advance Invoice
        </Button>
      </form>
    </div>
  );
};

function lengthValidation(minLength: number) {
  return {
    checkFn: (v: string) => Boolean(v?.length >= minLength),
    error: "The advance name must be at least 3 characters long"
  };
}
