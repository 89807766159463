import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useContextContactsDetails} from "pages/Contacts/context/ContactsDetailsContext";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useTags} from "hooks/inputHooks";
import {iCompanyDetails} from "api/contacts/companies/interfaces";
import {useDynamicFields} from "./useDynamicFields";
import {tNotValidTabs} from "../../CompanyForm";
import {notValidForm} from "helpers/misc";
import {lengthValidation} from "hooks/useTextInput/validators";

export type tUseCompanyMainForm = ReturnType<typeof useCompanyFormMain>;
interface iProps {
  company?: iCompanyDetails | null;
  setNotValidTab: Dispatch<SetStateAction<Partial<tNotValidTabs> | undefined>>;
}
export const useCompanyFormMain = ({company, setNotValidTab}: iProps) => {
  const context = useContextContactsDetails();
  const {labels, locales, emailTypes, platforms, phoneTypes} = context;
  const {isLoadingMain, getAdditionalFormDataMain} = context;

  const dynamicFields = useDynamicFields({company});
  const {phonesRef, phones, setPhones, emailsRef, emails, setEmails, socPlatformRef, accounts, setAccounts  } = dynamicFields; // prettier-ignore
  const [companyPhoto, setCompanyPhoto] = useState(company?.avatar_url || "");

  const formData = {
    name: useTextInput({
      validators: [lengthValidation(2)],
      isRequired: true,
      value: company?.name || ""
    }),
    website: useTextInput({
      validators: ["website"],
      isRequired: false,
      value: company?.website || "https://"
    }),
    phones: {
      checkValidity: () => !phonesRef?.current?.checkFieldsValidity(),
      value: phones,
      isRequired: false,
      isChanged: dynamicFields.isChanged
    },
    emails: {
      checkValidity: () => !emailsRef?.current?.checkFieldsValidity(),
      value: emails,
      isRequired: false,
      isChanged: dynamicFields.isChanged
    },
    locales: useTags({
      isRequired: false,
      value: company?.locales.map(({id, name}) => ({id: String(id), name}))
    }),
    labels: useTags({
      isRequired: false,
      value: company?.labels.map(({id, name}) => ({id: String(id), name}))
    }),
    accounts: {
      checkValidity: () => !socPlatformRef?.current?.checkFieldsValidity(),
      value: accounts,
      isRequired: false,
      isChanged: dynamicFields.isChanged
    }
  };

  useEffect(() => {
    getAdditionalFormDataMain();
  }, []); // eslint-disable-line

  const propsAvatar = company?.avatar_url || "";
  const isChanged =
    Object.values(formData).some(field => field.isChanged) || propsAvatar !== companyPhoto;

  useEffect(() => {
    if (!formData.name.isDirty) return;
    const valid = {
      0: notValidForm(formData)
    };
    setNotValidTab(valid);
  }, [formData.name.value, formData.name.isDirty]); // eslint-disable-line

  return {
    localesOptions: locales.data?.data?.data.filter(
      ({id}: {id: number}) => !company?.locales.some(locale => locale.id === id)
    ),
    labelsOptions: labels.data?.data?.data.filter(
      ({id}: {id: number}) => !company?.labels.some(locale => locale.id === id)
    ),
    isLoading: isLoadingMain,
    formData,

    companyPhoto,
    setCompanyPhoto,

    phonesOptions: phoneTypes,
    phonesRef,
    phones,
    setPhones,

    emailsOptions: emailTypes,
    emailsRef,
    emails,
    setEmails,

    accPlatformOptions: platforms,
    socPlatformRef,
    accounts,
    setAccounts,
    company,
    isChanged
  };
};
