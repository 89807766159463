import {FC} from "react";
import {Client} from "./sections/Client";
import {Agency} from "./sections/Agency";
import {LeadContact} from "./sections/LeadContact";
import {JobContacts} from "./sections/JobContacts";
import {Artists} from "./sections/Artists";
import {Locations} from "./sections/Locations";
import {Details} from "./sections/Details";
import {Links} from "./sections/Links/Links";
import "./JobOverview.scss";

interface iPropsJobOverview {}

export const JobOverview: FC<iPropsJobOverview> = () => {
  return (
    <div className="JobOverview">
      <div className="JobOverview-content">
        <div className="JobOverview-section">
          <Client />
          <Agency />
          <LeadContact />
        </div>

        <JobContacts />
        <Artists />
        <Locations />
      </div>

      <div className="JobOverview-sidebar">
        <Details />
        <Links />
      </div>
    </div>
  );
};
