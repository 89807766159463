import {FC, ReactNode} from "react";
import "./CallSheetTemplate.scss";

type tCallSheetTemplateProps = {
  children: ReactNode;
  className?: string;
  id?: string;
};

export const CallSheetTemplate: FC<tCallSheetTemplateProps> = ({
  children,
  className = "",
  id = ""
}) => {
  return (
    <div className={`CallSheetTemplate ${className}`} id={id}>
      {children}
    </div>
  );
};
