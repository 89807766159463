import {FC} from "react";
import {tTrack} from "api/budget/track/types";
import {HeaderFees} from "./HeaderFees/HeaderFees";
import {BodyFees} from "./BodyFees";

type tOtherFeesProps = {
  trackData: tTrack | null;
};

export const OtherFees: FC<tOtherFeesProps> = ({trackData}) => {
  return (
    <>
      <HeaderFees
        title="Other fees"
        tooltipText="“Other” actuals default to the estimated amount and the fields are editable. Expenses are not billed."
      />
      <BodyFees
        typeFees="other_fees"
        data={trackData?.other_fees?.production}
        title="Production %"
      />
      <BodyFees typeFees="other_fees" data={trackData?.other_fees?.insurance} title="Insurance %" />
      <BodyFees typeFees="other_fees" data={trackData?.other_fees?.green} title="Green" />
      <BodyFees typeFees="other_fees" data={trackData?.other_fees?.sales_tax} title="Sales Tax" />
    </>
  );
};
