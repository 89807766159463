import {useEffect, useState} from "react";
import {eventBus} from "EventBus/EventBus";
import {trackApi} from "api/budget/track";
import {tCurrencyRates} from "api/common/types";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";

export const useTrackCurrencyList = ({jobId}: {jobId: number | undefined}) => {
  const [trackCurrList, setTrackCurrRates] = useState<tCurrencyRates[]>([]);
  const {call: callCurr, isLoading: currLoading} = useHttp({cancelRequest: false});

  const getTrackCurrRates = async () => {
    if (!jobId) {
      return eventBus.dispatch("showToast", {type: "error", text: "No job id"});
    }

    try {
      const data = await callCurr(trackApi.getTrackCurrencyList(jobId));
      setTrackCurrRates(data?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  useEffect(() => {
    getTrackCurrRates();
  }, []); // eslint-disable-line

  return {
    trackCurrList,
    setTrackCurrRates,
    getTrackCurrRates,
    currLoading
  };
};
