import {useEffect} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {people} from "api/contacts/people";
import {iContactsPeople} from "../People/interfaces";
import {useSetApiParams} from "hooks/useSetApiParams";
import {useLocation} from "react-router-dom";
import {absRoutes} from "router/helpers";
import {tMeta} from "components/Pagination/types";

export const usePeople = () => {
  const {call, data, isLoading, isError, error} = useHttp<{data: iContactsPeople[]; meta: tMeta}>();
  const apiParams = useSetApiParams();
  const history = useLocation();

  useEffect(() => {
    if (!history.pathname.includes(absRoutes.contacts.people)) return;
    getPeopleList();
  }, [apiParams?.searchParams, history.pathname]); // eslint-disable-line

  const getPeopleList = async () => {
    const params = {
      locales: apiParams?.preparedParams.locales?.split(","),
      labels: apiParams?.preparedParams.labels?.split(","),
      ...(apiParams?.preparedParams.favorite && {
        favorite: apiParams?.preparedParams.favorite === "true" ? "1" : "0"
      })
    };
    await call(people.getList({...apiParams?.preparedParams, ...params}));
  };

  return {
    people: data?.data?.data || [],
    peopleListMeta: data?.data?.meta,
    isLoading,
    isError,
    error,
    apiParams,
    getPeopleList
  };
};
