import {FC, FormEvent} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {eventBus} from "EventBus/EventBus";
import {estimateApi} from "api/budget/estimate";
import {useContextJobBudget} from "../../context/JobBudgetContext";
import {useNavigate, useParams} from "react-router-dom";
import {sortByKey} from "helpers/misc";

interface iEstimateDeleteProps {
  onClose: () => void;
}

export const EstimateDelete: FC<iEstimateDeleteProps> = ({onClose}) => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {estimate, getEstimateList} = useContextJobBudget();
  const {call, isLoading} = useHttp();

  const onConfirm = async (event: FormEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!estimate?.id) return;
    try {
      await call(estimateApi.deleteEstimate(estimate.id));
      onClose();
      const response = await getEstimateList();
      const list: any[] = sortByKey(response?.data?.data || [], "name");
      const jobId = list?.[0]?.id || "";
      navigate(`/jobs/${id}/budget/estimate/${jobId}`);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <ConfirmWindow
      isLoading={isLoading}
      show={true}
      onConfirm={onConfirm}
      onClose={onClose}
      title="Delete Estimate"
      subTitle="This estimate will be permanently deleted."
      warning="This can't be undone."
      confirmBtn={{
        text: "Delete",
        color: "red"
      }}
      cancelBtn={{
        text: "Cancel"
      }}
    />
  );
};
