import {FC} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {tUseJobArtistFieldReturn} from "./useJobArtistField";
import {SearchField} from "components/shared/FormComponents/SearchField/SearchField";
import {JobArtistItem} from "./JobArtistItem";
import {iJobArtisFieldRow} from "./types";
import "./JobArtistField.scss";

interface iJobArtistFieldProps extends tUseJobArtistFieldReturn {
  id?: string;
  titles?: Array<any>;
}

export const JobArtistField: FC<iJobArtistFieldProps> = props => {
  const {values, errors, person, addContact, searchingPersons} = props;
  const {onRemove, handleChange, titles} = props;

  const personValue = values.map(item => item.person);
  const isErrorClassName = !!errors.length ? "error" : "";

  return (
    <div className={`JobArtistField ${isErrorClassName}`} id={props?.id}>
      <label className="title">Artists</label>
      {!!values?.length &&
        values.map(({uid, title, agency, agent, person}: iJobArtisFieldRow) => (
          <JobArtistItem
            key={uid}
            uid={uid}
            title={title}
            person={person}
            agency={agency}
            agent={agent}
            onRemove={onRemove}
            handleChange={handleChange}
            titles={titles}
          />
        ))}

      {searchingPersons && (
        <SearchField
          {...person}
          isClearable={false}
          isMulti={true}
          components={{MultiValue}}
          value={personValue}
          getOptionValue={option => option.id}
          getOptionLabel={option => `${option.first_name} ${option.last_name}`}
        />
      )}
      {!searchingPersons && (
        <Button onClick={addContact} type="button" color="outline" size="sm">
          <Icon icon="add" size="xsm" />
          Add Artist
        </Button>
      )}

      {!!errors?.length &&
        errors.map(error => (
          <span key={error} className="JobArtistField-error">
            {error}
          </span>
        ))}
    </div>
  );
};

const MultiValue = () => <span></span>;
