import {FC} from "react";
import {StyleSheet, View, Text} from "@react-pdf/renderer";

export const Footer: FC = () => {
  return (
    <View style={styles.footer} fixed>
      <View style={styles.wrapper}>
        <View style={styles.row}>
          <StyledText>Versatile Studios</StyledText>
          <StyledText
            render={({pageNumber, totalPages}: any) => `Page ${pageNumber} of ${totalPages}`}
          />
        </View>
        <View style={styles.row}>
          <StyledText>866 6th Ave, 8th Fl, New York, NY 10001, USA</StyledText>
          <StyledText style={styles.hidden}> - </StyledText>
        </View>
        <View style={styles.row}>
          <StyledText>+1 212.685.4384</StyledText>
          <StyledText>www.versatilestudios.com</StyledText>
        </View>
      </View>
    </View>
  );
};
function StyledText<T>(props: T) {
  return <Text style={styles.styledText} {...props} />;
}

const styles = StyleSheet.create({
  footer: {
    width: "100%",
    display: "flex",
    flexDirection: "row"
  },
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    borderTop: "1px solid #BDBDBF",
    paddingTop: "8px",
    marginTop: "8px"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  styledText: {
    fontSize: 9,
    color: "#7E7E80",
    fontFamily: "Helvetica400"
  },
  item: {
    display: "flex"
  },
  hidden: {
    opacity: 0
  }
});
