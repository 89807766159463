import {Dispatch, FC, SetStateAction} from "react";
import {CalendarSelection} from "./CalendarSelection";
import {EventCreator} from "./EventCreator";
import {CalendarManagement} from "./CalendarManagement";
import {tEventModal} from "../../helpers";
import "./SchedulerActions.scss";

interface iSchedulerActions {
  setEvents: Dispatch<SetStateAction<tEventModal[]>>;
  setIsLoading: Dispatch<SetStateAction<"download" | "saveToDropbox" | null>>;
  isLoading: "download" | "saveToDropbox" | null;
}

export const SchedulerActions: FC<iSchedulerActions> = ({setIsLoading, isLoading, setEvents}) => {
  return (
    <div className="SchedulerActions">
      <CalendarSelection />
      <CalendarManagement setIsLoading={setIsLoading} isLoading={isLoading} />
      <EventCreator setEvents={setEvents} />
    </div>
  );
};
