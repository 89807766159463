import {uid} from "helpers/misc";
import {ReactNode} from "react";

export const createNewRowObj = () => {
  return {
    uid: uid(),
    id: undefined,
    name: undefined,
    value: ""
  };
};

export type tConfirmNotification = {
  notificationType?: "deletePhone";
  currItem?: any;
  notificationData?: any;
};

export const confirmNotification = ({
  notificationType,
  currItem,
  notificationData
}: tConfirmNotification) => {
  if (!notificationType) {
    return `Are you sure you want to delete ${currItem ? currItem : "this item"}`;
  }

  const msg: Record<typeof notificationType, ReactNode> = {
    deletePhone: `Are you sure you want to delete ${
      // currItem ? formatPhoneNumber({value: currItem}) : "this item"
      currItem ? currItem : "this item"
    } from ${notificationData}`
  };

  return <p>{msg[notificationType]}</p>;
};
