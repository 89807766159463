import {FC, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {iJobFileEntity, iJobFilesReturn} from "api/jobs/files/types";
import {JobFilesListItem} from "../JobFilesListItem/JobFilesListItem";
import {Loading} from "components/shared/Loading/Loading";
import "./JobFilesList.scss";

interface iJobFilesListProps {
  sortBy: "asc" | "desc";
  data: iJobFilesReturn["entries"];
  isLoading: boolean;
}

export const JobFilesList: FC<iJobFilesListProps> = ({data, isLoading, sortBy}) => {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const [sorted, setSorted] = useState<iJobFileEntity[]>([]);

  function onSort(sortBy: "asc" | "desc", data: iJobFileEntity[]) {
    const sortedData = data
      .sort((a, b) => {
        if (sortBy === "asc") {
          return a.name.localeCompare(b.name);
        }
        return b.name.localeCompare(a.name);
      })
      .map(item => ({...item}));
    setSorted(sortedData);
  }

  useEffect(() => {
    onSort(sortBy, data);
  }, [sortBy, data]); // eslint-disable-line react-hooks/exhaustive-deps

  const isNestedFolder = pathname.split("/").length > 4;
  const pathArray = pathname.split("/");
  const backPath = pathArray.splice(0, pathArray.length - 1).join("/");

  const onBack = () => navigate(backPath);

  return (
    <ul className="JobFilesList">
      {isLoading && <Loading type="inBlock" />}
      {isNestedFolder && (
        <li onClick={onBack} className="JobFilesList-item">
          ...
        </li>
      )}
      {sorted.map(item => (
        <JobFilesListItem key={item.id} data={item} />
      ))}
    </ul>
  );
};
