import {Navigate, Route, Routes} from "react-router-dom";
import {routes} from "router/routes";
import {JobOverview} from "./JobOverview/JobOverview";
import {JobCalendar} from "./JobCalendar/JobCalendar";
import {JobDetailsLayout} from "./JobDetailsLayout/JobDetailsLayout";
import {allIn} from "helpers/misc";
import {JobDetailsContextProvider} from "../context/JobDetailsContext/JobDetailsContext";
import JobCrew from "./JobCrew/JobCrew";
import JobCallSheets from "./JobCallSheets/JobCallSheets";
import {JobFiles} from "./JobFiles/JobFiles";
import JobTasks from "./JobTasks/JobTasks";
import JobBudget from "./JobBudget/JobBudget";
import JobGrids, {GridHeaderTable} from "./JobGrids/JobGrids";
import {JobWrap} from "./JobWrap/JobWrap";
import {JobSchedule} from "./JobSchedule/JobSchedule";
import {CallSheetBuild} from "pages/Jobs/JobDetails/JobCallSheets/CallSheetBuild/CallSheetBuild";
import {TrackingTable} from "pages/Jobs/JobDetails/JobCallSheets/TrackingTable/TrackingTable";
import {Estimate} from "pages/Jobs/JobDetails/JobBudget/Estimate/Estimate";
import {EstimateBuild} from "pages/Jobs/JobDetails/JobBudget/Estimate/EstimateBuild/EstimateBuild";
import {EstimateExport} from "pages/Jobs/JobDetails/JobBudget/Estimate/EstimateExport/EstimateExport";
import {Advance} from "pages/Jobs/JobDetails/JobBudget/Advance/Advance";
import {AdvanceDocument} from "pages/Jobs/JobDetails/JobBudget/Advance/AdvanceDocument/AdvanceDocument";
import TrackWA from "pages/Jobs/JobDetails/JobBudget/Track/TrackWA/TrackWA";
import ExpenseList from "pages/Jobs/JobDetails/JobBudget/Track/ExpenseList/ExpenseList";
import {WrapPDF} from "pages/Jobs/JobDetails/JobBudget/Track/WrapPDF/WrapPDF";
import {Invoice} from "pages/Jobs/JobDetails/JobBudget/Invoice/Invoice";
import {JobScheduler} from "pages/Jobs/JobDetails/JobCalendar/JobScheduler/JobScheduler";

export const JobDetailsRouter = () => {
  const {advance, track, expenseList, wrapPDF, invoice, estimate} = routes.jobs.details.budget;

  return (
    <Routes>
      <Route element={<JobDetailsLayout />}>
        <Route path={allIn(routes.jobs.details.overview)} element={<JobOverview />} />
        <Route path={allIn(routes.jobs.details.crew)} element={<JobCrew />} />
        <Route path={allIn(routes.jobs.details.callSheets.main, "")} element={<JobCallSheets />}>
          <Route
            path={allIn(routes.jobs.details.callSheets.details.main, "")}
            element={<CallSheetBuild />}
          />
          <Route
            path={allIn(routes.jobs.details.callSheets.details.track, "")}
            element={<TrackingTable />}
          />
        </Route>
        <Route path={allIn(routes.jobs.details.calendar.main, "")} element={<JobCalendar />}>
          <Route
            path={allIn(routes.jobs.details.calendar.details, "")}
            element={<JobScheduler />}
          />
        </Route>
        <Route path={allIn(routes.jobs.details.files.main)} element={<JobFiles />} />
        <Route path={allIn(routes.jobs.details.tasks.main)} element={<JobTasks />} />

        {/* JOB BUDGET ROUTER START*/}
        <Route path={allIn(routes.jobs.details.budget.main)} element={<JobBudget />}>
          <Route index element={<Navigate to={allIn(estimate.main, "")} />} />
          <Route path={allIn(estimate.main, "")} element={<Estimate />}>
            <Route path={allIn(estimate.details.build, "")} element={<EstimateBuild />} />
            <Route path={allIn(estimate.details.export, "")} element={<EstimateExport />} />
          </Route>
          <Route path={allIn(advance.main, "")} element={<Advance />}>
            <Route path={allIn(advance.details.main, "")} element={<AdvanceDocument />} />
          </Route>
          <Route path={allIn(track)} element={<TrackWA />} />
          <Route path={allIn(expenseList)} element={<ExpenseList />} />
          <Route path={allIn(wrapPDF)} element={<WrapPDF />} />
          <Route path={allIn(invoice)} element={<Invoice />} />
          <Route path="*" element={<Navigate to={allIn(estimate.main, "")} />} />
        </Route>
        {/* JOB BUDGET ROUTER END*/}

        <Route path={allIn(routes.jobs.details.grids?.main)} element={<JobGrids />}>
          <Route path={allIn(routes.jobs.details.grids?.gridId)} element={<GridHeaderTable />} />
        </Route>
        <Route path={allIn(routes.jobs.details.wrap.main)} element={<JobWrap />} />
        <Route path={allIn(routes.jobs.details.schedule.main)} element={<JobSchedule />} />
        <Route path="*" element={<Navigate to={allIn(routes.jobs.details.overview, "")} />} />
      </Route>
    </Routes>
  );
};

export const JobDetails = () => {
  return (
    <JobDetailsContextProvider>
      <JobDetailsRouter />
    </JobDetailsContextProvider>
  );
};
