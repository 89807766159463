import {ChangeEvent, FC} from "react";
import {CheckBoxGroupe} from "components/shared/FormComponents/CheckBoxGroupe/CheckBoxGroupe";
import {useCheckBoxGroupe} from "hooks/inputHooks/useCheckBoxGroupe";
import {useContextJobCalendar} from "../context";
import {AppTooltips} from "components/AppTooltips/AppTooltips";

interface iSchedulerFiltersProps {}

const eventTypeList = [
  {id: "event", name: "Events"},
  {id: "hold", name: "Holds"},
  {id: "task", name: "Tasks"}
];

export const JobSchedulerFilters: FC<iSchedulerFiltersProps> = () => {
  const {apiParams} = useContextJobCalendar();

  const initValueFilter = apiParams?.preparedParams?.entities?.split(",");
  const eventType = useCheckBoxGroupe({initValue: initValueFilter});

  const onChangeCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, checked} = e.target;
    let prevParams = apiParams?.preparedParams?.entities?.split(",") || [];
    let newArrDep = prevParams;

    const isSelected = checked && value;
    isSelected && (newArrDep = [...prevParams, value]);
    !isSelected && (newArrDep = prevParams?.filter(item => item !== value));
    const params = {entities: newArrDep};
    apiParams.setApiParams({...Object.fromEntries(apiParams.searchParams), params});
  };

  return (
    <div className="JobSchedulerFilters">
      <CheckBoxGroupe
        customClass="JobScheduler-filter"
        checkBoxList={eventTypeList}
        initValue={eventType.selectedCheckBoxes.current}
        onChange={onChangeCheck}
      />

      <AppTooltips page="calendar" tooltip="CALENDAR VIEWS" chevronPosition="topRight" />
    </div>
  );
};
