import {useState} from "react";
import {tFieldOption} from "./FieldSetup";

type tProps = {
  fields: tFieldOption[];
  setFields: React.Dispatch<React.SetStateAction<tFieldOption[]>>;
};

export const useChangeOrder = ({fields, setFields}: tProps) => {
  const [dragId, setDragId] = useState<string | null>(null);

  const handleDrag = (id: string) => {
    setDragId(id);
  };

  const handleDrop = (id: string) => {
    const dragFields = fields.find(value => value.id === dragId);
    const dropFields = fields.find(value => value.id === id);
    // @ts-ignore
    const dragBoxOrder = dragFields.order;
    // @ts-ignore
    const dropBoxOrder = dropFields.order;

    const newFields = fields.map(value => {
      if (value.id === dragId) {
        value.order = dropBoxOrder;
      }
      if (value.id === id) {
        value.order = dragBoxOrder;
      }
      return value;
    });

    setFields(newFields);
  };

  function toOrderArray(array: tFieldOption[]) {
    return array.sort((a, b) => {
      if (Number(a.order) > Number(b.order)) return 1;
      if (Number(a.order) < Number(b.order)) return -1;
      return 0;
    });
  }

  return {
    handleDrag,
    handleDrop,
    toOrderArray
  };
};
