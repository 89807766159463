import {FC} from "react";
import {StyleSheet, View, Text} from "@react-pdf/renderer";

interface iSectionTitleProps {
  stylesObject?: object;
}

export const SectionTitle: FC<iSectionTitleProps> = ({stylesObject, ...props}) => {
  return (
    <View style={{...styles.section, ...stylesObject}}>
      <Text style={styles.text}>{props?.children}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  section: {
    width: "100%",
    borderBottom: "2px solid black",
    marginBottom: "12px",
    paddingBottom: "4px"
  },
  text: {
    fontSize: "13px",
    fontWeight: 700,
    textTransform: "uppercase",
    fontFamily: "Helvetica700"
  }
});
