import {FC} from "react";
import moment from "moment/moment";
import {iCallSheetsDeliveryConfirmation, tCallSheetContact} from "api/callSheet/types";
import {AppTooltips} from "components/AppTooltips/AppTooltips";

interface iCellLatestDeliveryProps {
  isAgent?: boolean;
  confirmation?: iCallSheetsDeliveryConfirmation | null;
  contact: tCallSheetContact;
  showTooltip?: boolean;
}

export const CellLatestStatus: FC<iCellLatestDeliveryProps> = ({
  isAgent,
  contact,
  confirmation,
  showTooltip
}) => {
  const getLatestStatus = () => {
    if (!isAgent && contact.agent) return "";

    if (
      confirmation?.custom &&
      confirmation?.status_changed_at &&
      confirmation?.status?.name !== "Unopened"
    ) {
      return `${confirmation?.status?.name} ${moment(confirmation?.status_changed_at).format(
        "M/DD h:mmA"
      )}`;
    }

    return confirmation?.status?.name;
  };

  return (
    <td className={`TrackingTableRow-cell status ${confirmation?.status?.name}`}>
      <span>{getLatestStatus()}</span>

      {showTooltip && <AppTooltips page="callSheetTrack" tooltip="CONFIRMATION STATUS" />}
    </td>
  );
};
