import {Routes, Route, Navigate} from "react-router-dom";
import Calendar from "./Calendar/Calendar";
import Overview from "./Overview/Overview";
import {absRoutes} from "router/helpers";
import {routes} from "router/routes";

export const Dashboard = () => {
  return (
    <Routes>
      <Route path={routes.dashboard.overview} element={<Overview />} />
      <Route path={routes.dashboard.calendar} element={<Calendar />} />
      <Route index element={<Navigate to={absRoutes.dashboard.overview} />} />
    </Routes>
  );
};
