import {AxiosRequestConfig} from "axios";
import {tFieldPostData} from "./types";

const field = "field";

type tFields = "person" | "company";

export const fields = {
  getFields: (type: tFields): AxiosRequestConfig => ({
    url: `fields/${type}`
  }),
  getFieldTypes: (): AxiosRequestConfig => ({
    url: `${field}/types`
  }),
  addNewField: (data: tFieldPostData): AxiosRequestConfig => ({
    url: `${field}`,
    method: "post",
    data
  }),
  getFieldDetails: (id: number): AxiosRequestConfig => ({
    url: `${field}/${id}`
  }),
  updateField: (id: number, data: tFieldPostData): AxiosRequestConfig => ({
    url: `${field}/${id}`,
    method: "post",
    data
  }),
  deleteField: (id: number): AxiosRequestConfig => ({
    url: `${field}/${id}`,
    method: "delete"
  })
};
