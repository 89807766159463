import {useEffect, useLayoutEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {useHttp} from "hooks/httpServices/useHttp";
import {callSheet} from "api/callSheet";
import {iDeliveryInfo, tCallSheetInfo, tCallSheetList} from "api/callSheet/types";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {generateRow} from "../CallSheetBuild/CallSheetBuildSchedule/generateRow";

export const useJobCallSheet = () => {
  const intervalRef = useRef<NodeJS.Timer | null>(null);

  const {pathname} = useLocation();
  const navigate = useNavigate();
  const {callSheetId} = useParams();

  const {jobDetailsData} = useContextJobDetails();
  const {call: callAllList, ...listInfo} = useHttp<{data: tCallSheetList[]}>();
  const {call: deliveryCall} = useHttp();

  const {call, isLoading} = useHttp();

  const [callSheetInfo, setCallSheetInfo] = useState<tCallSheetInfo | null>(null);
  const [proofedCallSheetInfo, setProofedCallSheetInfo] = useState<tCallSheetInfo | null>(null);

  const [deliveryInfo, setDeliveryInfo] = useState<iDeliveryInfo | null>(null);

  useLayoutEffect(() => {
    jobDetailsData?.id &&
      getCallSheetList(jobDetailsData.id).then(({data: {data}}) => {
        data?.[0]?.id && navigate(String(data[0].id));
      });
  }, []); // eslint-disable-line

  // sync delivery info after first send
  useEffect(() => {
    if (deliveryInfo?.status?.name === "pending" || deliveryInfo?.status?.name === "scheduled") {
      intervalRef.current = setInterval(() => {
        getDeliveryInfo();
      }, 5000);
    } else {
      intervalRef?.current && clearInterval(intervalRef.current);
    }
    return () => {
      intervalRef?.current && clearInterval(intervalRef.current);
    };
  }, [deliveryInfo?.status]); // eslint-disable-line

  const getCallSheetList = async (id: number) => await callAllList(callSheet.getCallSheetList(id));

  useEffect(() => {
    getCallSheetHandler();
    getDeliveryInfo();
  }, [callSheetId]); // eslint-disable-line

  const getDeliveryInfo = async (): Promise<iDeliveryInfo | undefined> => {
    if (!callSheetId) return;

    const isTrackingPage = pathname.includes("call-sheets/track");

    try {
      const {
        data: {data}
      } = await deliveryCall(callSheet.getLatestDelivery(+callSheetId));
      setDeliveryInfo(data);
      if (!data?.groups && isTrackingPage) {
        navigate(String(callSheetId));
      }
      return data;
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      console.log(msg);
    }
  };

  const getCallSheetHandler = async () => {
    if (!callSheetId) return;

    try {
      const res = await call(callSheet.getCallSheet(+callSheetId));
      const data = res?.data?.data as tCallSheetInfo;
      const preparedCallSheetsDetails = {
        ...data,
        ...(!data?.attributes?.schedule?.length && {
          attributes: {...data?.attributes, schedule: generateRow(10)}
        })
      };
      setCallSheetInfo(preparedCallSheetsDetails);
      setProofedCallSheetInfo(preparedCallSheetsDetails);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      console.log(msg);
    }
  };

  return {
    proofedCallSheetInfo,
    setProofedCallSheetInfo,
    isLoading,
    callSheetId,
    callSheetInfo,
    setCallSheetInfo,
    callSheetListInfo: listInfo,
    callSheetList: listInfo?.data?.data?.data as Array<tCallSheetList>,
    getCallSheetList,
    getDeliveryInfo,
    callAllList,
    deliveryInfo,
    setDeliveryInfo
  };
};
