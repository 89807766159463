import {useEffect} from "react";
import {creditCardAndPayroll} from "api/budget/creditCardAndPayroll";
import {useSelect} from "hooks/inputHooks/useSelect";
import {useHttp} from "hooks/httpServices/useHttp";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {tCreditCard} from "api/budget/creditCardAndPayroll/types";

export const useCreditCardForm = ({
  cardData,
  cardsList
}: {
  cardData?: tCreditCard;
  cardsList: any;
}) => {
  const form = {
    type: useSelect({
      options: [],
      value: cardData?.type?.id?.toString(),
      isRequired: true,
      targetKeys: {value: "id", label: "display_name"}
    }),
    initials: useTextInput({
      value: cardData?.holder_initials || "",
      validators: ["name"],
      filters: ["name"]
    }),
    digits: useTextInput({
      value: cardData?.last_four_digits || "",
      filters: ["onlyDigits"]
    })
  };

  // const {data} = useHttp({onMountCall: true, config: creditCardAndPayroll?.getCreditCardTypes()});
  useEffect(() => {
    // data && form?.type?.setOptions(data?.data?.data || []);
    cardsList && form?.type?.setOptions(cardsList || []);
    // }, [data]); // eslint-disable-line
  }, [cardsList]); // eslint-disable-line

  useEffect(() => {
    // @ts-ignore
    cardData?.type && form?.type?.setSelected(cardData.type);
  }, [form?.type?.options]); // eslint-disable-line

  return {form};
};
