import {FC} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {useContextJobCalendar} from "../../context";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {jobsCalendarApi} from "api/jobs/calendar";
import {useHttp} from "hooks/httpServices/useHttp";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import "./SaveAsNewTemplate.scss";

interface iSaveAsNewTemplateProps {
  onClose: () => void;
}

export const SaveAsNewTemplate: FC<iSaveAsNewTemplateProps> = ({onClose}) => {
  const {call, isLoading} = useHttp();
  const {activeCalendar, getTemplates} = useContextJobCalendar();

  const name = useTextInput({value: ""});

  const onSave = async () => {
    if (!name.checkValidity() || !activeCalendar?.id) return;
    const data = {
      name: name.value
    };
    try {
      await call(jobsCalendarApi.createTemplateFromCalendar(activeCalendar?.id, data)); // prettier-ignore
      getTemplates();
      eventBus.dispatch("showToast", {text: "Template saved"});
      onClose();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <ConfirmWindow
      show={true}
      className="SaveAsNewTemplate"
      onClose={onClose}
      title="Save as new template"
      subTitle="Name your template"
      cancelBtn={{
        text: "Cancel",
        onClick: onClose
      }}
      confirmBtn={{
        text: "Save"
      }}
      onConfirm={onSave}
      isLoading={isLoading}
    >
      <InputField {...name.inputProps} errors={name.errors} />
      <p className="SaveAsNewTemplate-description">
        Store calendar events to be loaded into a new calendar with new dates.
      </p>
    </ConfirmWindow>
  );
};
