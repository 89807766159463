import {FC, useState} from "react";
import {useContextProfile} from "context";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {OnboardingStep} from "./OnboardingStep";
import {steps} from "./steps";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {useHttp} from "hooks/httpServices/useHttp";
import {profileInfo} from "api/profile";

export const Onboarding: FC = () => {
  const {userData, setUserData} = useContextProfile();
  const [step, setStep] = useState(0);
  const {call, isLoading} = useHttp();

  const updateStep = () => {
    const isLastStep = step === steps?.length - 1;
    if (isLastStep) {
      return onSetOnboardingDone();
    }

    setStep(prev => prev + 1);
  };

  const onSetOnboardingDone = async () => {
    try {
      const res = await call(profileInfo?.setOnboardingDone());
      setUserData(res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <ModalPopup
      size="w-528"
      show={!!userData && !userData?.onboarding}
      showCloseButton={false}
      withoutPaddings
      persistOnScreen
    >
      <OnboardingStep setStep={setStep} step={step} updateStep={updateStep} isLoading={isLoading} />
    </ModalPopup>
  );
};
