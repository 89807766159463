import {FC, useState} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {Loading} from "components/shared/Loading/Loading";
import {PeopleDetails} from "./PeopleDetails/PeopleDetails";
import {PeopleForm} from "pages/Contacts/People/PeopleForm/PeopleForm";
import {iPersonDetails} from "pages/Contacts/People/interfaces";
import {ModalConfirmCancelEditing} from "components/shared/ModalConfirmCancelEditing/ModalConfirmCancelEditing";

interface iPeopleModalsModel {
  personDetails: iPersonDetails;
  editedPerson?: iPersonDetails | null;
  closeModal: () => void;
  closeEditModal?: () => void;
  onEditCallback?: Function;
  showTeamModals: (data: {user: iPersonDetails}) => void;
  showEditForm: (user: iPersonDetails) => void;
  show: boolean;
  className?: string;
}

export const PeopleModalsModel: FC<iPeopleModalsModel> = ({children, ...props}) => {
  const {
    showEditForm,
    showTeamModals,
    personDetails,
    closeModal,
    closeEditModal,
    onEditCallback,
    editedPerson,
    show,
    className = ""
  } = props;

  const [isChanged, setIsChanged] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);

  const onEditClose = () => {
    if (isChanged) {
      setConfirmClose(true);
    } else {
      closeEditModal?.();
    }
  };

  const onConfirmCloseEdit = () => {
    setConfirmClose(false);
    closeEditModal?.();
  };

  return (
    <>
      <ModalPopup
        className={`PeopleModals ${className}`}
        show={show}
        onClose={editedPerson ? onEditClose : closeModal}
      >
        {!personDetails && <Loading type="inBlock" />}

        {!editedPerson && personDetails && (
          <PeopleDetails
            person={personDetails}
            showEditForm={() => showEditForm(personDetails)}
            setTeamModal={showTeamModals}
          />
        )}

        {editedPerson && (
          <PeopleForm
            person={editedPerson}
            closeModal={closeModal}
            closeEditModal={onEditClose}
            onEditCallback={onEditCallback}
            setIsChangedFormData={setIsChanged}
          />
        )}
      </ModalPopup>
      <ModalConfirmCancelEditing
        show={confirmClose}
        onConfirm={onConfirmCloseEdit}
        onClose={() => setConfirmClose(false)}
      />
    </>
  );
};
