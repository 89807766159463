import {FC} from "react";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {EstimateSideBarLisItem} from "../EstimateSideBarLisItem/EstimateSideBarLisItem";
import {GlobalPercent} from "./GlobalPercent";
import {useGenerateData} from "./useGenerateData";
import {getUsMoneyFormat} from "helpers/formattingData";
import {useContextJobBudget} from "../../_context/JobBudgetContext";
import "./EstimateSidebarDetails.scss";

type tEstimateSidebarDetailsProps = {
  [key: string]: any;
};

export const EstimateSidebarDetails: FC<tEstimateSidebarDetailsProps> = () => {
  const {estimate} = useContextJobBudget();
  const {budgetCosts, budgetCostVal, grandTotal} = useGenerateData();

  return (
    <div className="EstimateSidebarDetails">
      <div>
        {budgetCosts.map((item, i) => {
          return (
            <EstimateSideBarLisItem
              key={i}
              title={item.title}
              percent={`(${item.percent}%)` || ""}
              value={item.value || 0}
            />
          );
        })}

        <TextComponent size="15" weight="700" uppercase className="total">
          <span>BUDGETED COSTS</span>{" "}
          <span> {!!budgetCostVal ? getUsMoneyFormat(budgetCostVal) : "$0.00"}</span>
        </TextComponent>
      </div>

      <div>
        {!estimate?.approved_at && <GlobalPercent />}

        {grandTotal.map((item, i) => {
          return (
            <EstimateSideBarLisItem
              key={i}
              title={item.title}
              percent={`(${item.percent}%)` || ""}
              value={item.value || 0}
            />
          );
        })}
      </div>
    </div>
  );
};
