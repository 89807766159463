import {FC} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {useContextJobCalendar} from "../../context";
import {jobsCalendarApi} from "api/jobs/calendar";
import {useHttp} from "hooks/httpServices/useHttp";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {useParams} from "react-router-dom";

import "./JobCalendarDelete.scss";

interface iJobCalendarDeleteProps {
  onClose: () => void;
}

export const JobCalendarDelete: FC<iJobCalendarDeleteProps> = ({onClose}) => {
  const {id} = useParams();
  const {call, isLoading} = useHttp();
  const {activeCalendar, getCalendarList, calendarsListQuery} = useContextJobCalendar();

  const onDelete = async () => {
    if (!activeCalendar?.id) return;

    try {
      await call(jobsCalendarApi.deleteCalendar(activeCalendar.id));
      eventBus.dispatch("showToast", {text: "Calendar deleted."});
      await getCalendarList(Number(id));

      // setCalendarsList(data);

      // if (!!data?.length) {
      //   const list = sortByKey(data, "name");
      //   setActiveCalendar(list[0]);
      //   navigate(`/jobs/${id}/calendar/${list[0].id}`);
      // } else {
      //   navigate(`/jobs/${id}/calendar`);
      // }
      onClose();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <ConfirmWindow
      show={true}
      className="JobCalendarDelete"
      onClose={onClose}
      title="Delete calendar"
      subTitle="This calendar will be permanently deleted."
      cancelBtn={{
        text: "Cancel",
        onClick: onClose
      }}
      confirmBtn={{
        text: "Delete",
        color: "red"
      }}
      onConfirm={onDelete}
      isLoading={isLoading || calendarsListQuery.isLoading}
    >
      <p className="error-message">This can’t be undone.</p>
    </ConfirmWindow>
  );
};
