import {useEffect, useState} from "react";
import {useDebounce} from "hooks/useDebounce";
import {useHttp} from "hooks/httpServices/useHttp";
import {tags} from "api/tags";
import {useRadio} from "hooks/useRadio/useRadio";
import {useSetApiParams} from "hooks/useSetApiParams";

type tOptions = {
  id: string;
  name: string;
};

const favoriteRadioList = [
  {label: "ON", name: "favorites", value: "true"},
  {label: "OFF", name: "favorites", value: "false"}
];

export function useContactsTableFilter() {
  const apiParams = useSetApiParams();

  const apiData = {
    labels: useHttp(),
    locales: useHttp()
  };

  const [search, setSearch] = useState({
    labels: "",
    locales: ""
  });

  const debounceValueLocales = useDebounce(search.locales, 200);
  const debounceValueLabels = useDebounce(search.labels, 200);

  useEffect(() => {
    const labelsRequestPayload = apiParams?.preparedParams?.labels?.split(",");
    const localesRequestPayload = apiParams?.preparedParams?.locales?.split(",");
    !!labelsRequestPayload?.length &&
      apiData.labels.call(tags.labels.get({ids: labelsRequestPayload.map(v => +v)}));
    !!localesRequestPayload?.length &&
      apiData.locales.call(tags.locales.get({ids: localesRequestPayload.map(v => +v)}));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (debounceValueLocales) {
      onSearchLocales(debounceValueLocales);
    }
  }, [debounceValueLocales]); // eslint-disable-line

  useEffect(() => {
    if (debounceValueLabels) {
      onSearchLabels(debounceValueLabels);
    }
  }, [debounceValueLabels]); // eslint-disable-line

  const labelArr = apiParams?.preparedParams?.labels?.split(",") || [];
  const locArr = apiParams?.preparedParams?.locales?.split(",") || [];

  const favorite = apiParams?.preparedParams?.favorite;

  const getSelectedTag = (options: tOptions[], selected: string[]) => {
    if (!selected?.length || !options?.length) return [];
    return options.filter((item: any) => {
      return selected?.find((locale: string) => Number(locale) === Number(item.id));
    });
  };

  const filterControl = {
    favorite: useRadio({fields: favoriteRadioList, value: favorite ? favorite : ""}),
    labels: {
      value: getSelectedTag(apiData.labels?.data?.data?.data, labelArr),
      onChange: (option: tOptions[]) => {
        const params = {labels: option.length ? option.map((item: tOptions) => item.id) : []};
        apiParams.setApiParams({...Object.fromEntries(apiParams.searchParams), params});
      }
    },
    locales: {
      value: getSelectedTag(apiData.locales?.data?.data?.data, locArr),
      onChange: (option: tOptions[]) => {
        const params = {locales: option.length ? option.map((item: tOptions) => item.id) : []};
        apiParams.setApiParams({...Object.fromEntries(apiParams.searchParams), params});
      }
    }
  };

  useEffect(() => {
    const params = {favorite: filterControl.favorite.value};
    apiParams.setApiParams({...Object.fromEntries(apiParams.searchParams), params});
  }, [filterControl.favorite.value]); // eslint-disable-line

  const clearFilters = () => {
    filterControl.favorite.setValue("");
    apiParams?.clearAllParams();
  };

  const onChangeInput = (value: string, name: "labels" | "locales") => {
    setSearch(prev => ({...prev, [name]: value}));
  };

  const onSearchLocales = (search: string) => {
    apiData.locales.call(tags.locales.get({search, search_type: "and"}));
  };

  const onSearchLabels = (search: string) => {
    apiData.labels.call(tags.labels.get({search, search_type: "and"}));
  };

  return {
    filterControl,
    apiData,
    onChangeInput,
    clearFilters,
    localesOption: apiData.locales?.data?.data?.data || [],
    labelsOption: apiData.labels?.data?.data?.data || []
  };
}
