import {FC, FormEvent} from "react";
import {tTrackExpenseLine, typeFees} from "api/budget/track/types";
import {Select} from "components/shared/FormComponents/Select/Select";
import {FormWrap} from "components/shared/FormWrap/FormWrap";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {useSelect} from "hooks/inputHooks/useSelect";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {notValidForm} from "helpers/misc";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextTrack} from "../_context/TrackContext";
import {trackApi} from "api/budget/track";
import {useResponseType} from "../_common/useResponseType";
import {useContextExpenseList} from "../ExpenseList/context/ExpenseListContext";
import {generateTrackList} from "./generateMoveLists";

type tMoveExpenseProps = {
  onClose: () => void;
  trackLine: tTrackExpenseLine;
  typeFees?: typeFees;
};

export const MoveExpense: FC<tMoveExpenseProps> = ({onClose, trackLine, typeFees}) => {
  const {setTrackData, trackData} = useContextTrack(); // prettier-ignore
  const {setExpenseList, filterList} = useContextExpenseList();
  const {isExpense, response_type} = useResponseType();
  const {call, isLoading} = useHttp();
  const formId = "track-move-expense";

  const getPayrollList = () => {
    if (!typeFees || !trackData?.[typeFees]) return [];

    const arr = Object.entries(trackData[typeFees]);
    return arr?.map(item => {
      return {value: item?.[1]?.target_name, label: item?.[1]?.target_name};
    });
  };

  const trackList = !!typeFees ? getPayrollList() : generateTrackList(trackData);
  const list: Array<any> = isExpense ? filterList : trackList;
  const form = {
    moveLine: useSelect({
      options: list,
      value: "",
      isRequired: true
    })
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (notValidForm(form) || !form?.moveLine?.selected?.value) return;

    try {
      const res = await call(
        trackApi.moveExpenseLine(trackLine?.id, {
          line_number: `${form?.moveLine?.selected?.value}`,
          response_type
        })
      );
      isExpense && setExpenseList(res?.data?.data);
      !isExpense && setTrackData(res?.data?.data);

      onClose();
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <ModalPopup
      size="w-528"
      show={true}
      onClose={onClose}
      className="TrackMoveExpense"
      withoutPaddings
    >
      <FormWrap
        headerTitle="Move Expense"
        idForm={formId}
        type="titleCenterAndTwoBtn"
        cancelTitle="Cancel"
        onCancel={onClose}
        submitTitle="Move"
        isLoading={isLoading}
      >
        <form id={formId} onSubmit={handleSubmit}>
          <Select
            label="Line number"
            {...form.moveLine.inputProps}
            canSelectEmpty
            errors={form.moveLine.errors}
          />
        </form>
      </FormWrap>
    </ModalPopup>
  );
};
