import {FC} from "react";
import {jobCrewContact} from "api/jobCrewContact";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {TextComponent} from "../../../common/TextComponent/TextComponent";
import {useContextJobCrew} from "../../JobCrewContext";
import {returnSIfSeveralItems} from "helpers/misc";
import {eventBus} from "EventBus/EventBus";

type tRemoveContactsProps = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const RemoveContacts: FC<tRemoveContactsProps> = ({setModal}) => {
  const {selectedContacts, jobCrewGroupList: {setCrewGroupList}, jobCrew: {crew}} = useContextJobCrew(); // prettier-ignore
  const {call, isLoading} = useHttp();

  const deleteGroup = async () => {
    if (!selectedContacts?.length) return;

    try {
      const contacts = selectedContacts.map(item => +item);
      const res = await call(jobCrewContact.deleteContacts(crew?.id, {contacts}));
      setCrewGroupList(() => res?.data?.data);
      eventBus.dispatch("showToast", {text: res?.data?.message || "Success!"});
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  const selected = selectedContacts?.length ? selectedContacts?.length : 0;
  return (
    <ConfirmWindow
      show={true}
      title={`Remove contact${returnSIfSeveralItems(selectedContacts)}?`}
      subTitle={`${selected} selected contacts will be removed.`}
      cancelBtn={{text: "Cancel", type: "button"}}
      onClose={() => setModal(false)}
      confirmBtn={{text: "Remove", type: "submit", color: "red"}}
      onConfirm={deleteGroup}
      isLoading={isLoading}
    >
      <TextComponent color="red" weight="400" size="14" center className="mb-40">
        This can’t be unndone.
      </TextComponent>
    </ConfirmWindow>
  );
};
