import {useState} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {Button} from "components/shared/Button/Button";
import {TeamMemberInvite} from "./TeamMemberForms/TeamMemberInvite";

export const Invite = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button onClick={() => setShowModal(true)} size="md" color="dark">
        Invite
      </Button>

      <ModalPopup
        className="TeamMemberInviteModal"
        show={showModal}
        onClose={() => setShowModal(false)}
      >
        <TeamMemberInvite onCloseModal={() => setShowModal(false)} />
      </ModalPopup>
    </>
  );
};
