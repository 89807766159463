import {useHttp} from "hooks/httpServices/useHttp";
import {useEffect, useState} from "react";
import {fields as fieldsApi} from "api/fields";
import {iField} from "api/fields/types";
import {getDateFormat} from "helpers/dates";
import moment from "moment";

interface iCustomFieldsFormProps {
  data: iField[] | undefined;
  fieldsType: "person" | "company";
}

type tOption = {value: string | number; name: string};
type tFormDataPayload = {
  id: number | string;
  value: string | tOption | tOption[];
};

export type tUseCustomFieldsForm = ReturnType<typeof useCustomFieldsForm>;

export function useCustomFieldsForm(props: iCustomFieldsFormProps) {
  const isPeoplePage = props.fieldsType === "person";

  const fieldsQuery = useHttp<{data: iField[]}>();
  const [fields, setFields] = useState<iField[]>([]);

  useEffect(() => {
    props?.data ? setFields(props.data) : getFields();
  }, []); // eslint-disable-line

  function getFields() {
    fieldsQuery
      .call(fieldsApi.getFields(isPeoplePage ? "person" : "company"))
      .then(({data: {data}}) => setFields(data));
  }

  const onChange = (value: iField) => setFields(prev => prev.map(v => (v.id === value.id ? value : v))); // prettier-ignore

  const getFormDataValues = () => {
    return fields.reduce(
      (prev: tFormDataPayload[], {value, type, options, id}: iField): tFormDataPayload[] => {
        if (!value) return prev;
        if (type === "dropdown") {
          return [
            ...prev,
            {id, value: options.find(o => String(o.value) === String(value))?.value}
          ];
        }
        if (type === "date" && value) {
          return [...prev, {id, value: getDateFormat(moment(value).startOf("day"), "timestamp")}];
        }
        if (value) {
          return [...prev, {id, value}];
        }
        return prev ? [...prev] : [];
      },
      []
    );
  };

  return {
    fieldsQuery,
    fields,
    onChange,
    getFormDataValues,
    isChanged: isChangedCustomFields(props?.data, fields)
  };
}

function isChangedCustomFields(propData: iField[] | undefined, fields: iField[]) {
  const propArray = propData || [];
  const hasPropData = !!propArray.length;
  const hasValue = fields.some(
    field =>
      (typeof field?.value === "string" && field?.value) ||
      (Array.isArray(field?.value) && field?.value?.length)
  );

  if (!hasPropData && !hasValue) {
    return false;
  }

  if (!hasPropData && hasValue) {
    return true;
  }

  let changed = false;

  for (let value of fields) {
    const propField = propArray.find(v => v.id === value.id);

    //FIXME need to check
    // if (value.type === "checkbox") {
    //   changed = !!value?.value?.filter(
    //     (v: string) => !propField?.value?.some((propVal: string) => propVal === v)
    //   ).length;

    //   if (changed) break;
    // }

    if (value.type === "date") {
      const propDate = moment(propField?.value);
      const valueDate = value?.value ? moment(value?.value) : moment();
      const isDifferentDate = !!propDate.diff(valueDate, "day");
      if (isDifferentDate) {
        changed = true;
        break;
      }
    }

    if (value.type !== "date" && value.type !== "checkbox") {
      if (String(value?.value || "") !== String(propField?.value || "")) {
        changed = true;
        break;
      }
    }
  }

  return changed;
}
