import {useApi} from "../useApi";
import {useAsync} from "./useAsync";
import {AxiosRequestConfig} from "axios";
import {useEffect, useLayoutEffect, useRef} from "react";

type tUseHttp = {
  config?: AxiosRequestConfig;
  onMountCall?: boolean;
  cancelRequest?: boolean;
  disableTimeout?: boolean;
};

export type tUseHttpReturn<T = any> = {
  call: (config: AxiosRequestConfig) => Promise<{data: T}>;
  data: {data: T} | null;
  error: any;
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  mounted: React.MutableRefObject<boolean>;
};

export const useHttp = <T = any>(params?: tUseHttp): tUseHttpReturn<T> => {
  const cancelRequest = params?.cancelRequest === undefined ? true : params.cancelRequest;
  const {apiCall} = useApi(false, params?.disableTimeout);
  const {run, data, error, isError, isIdle, isLoading, isSuccess} = useAsync<T>();
  const call = async (config: AxiosRequestConfig) => run(apiCall(config, cancelRequest));

  useEffect(() => {
    params?.onMountCall && params?.config && call(params?.config);
  }, []); // eslint-disable-line

  const mounted = useRef(false);
  useLayoutEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return {call, data, error, isError, isIdle, isLoading, isSuccess, mounted};
};
