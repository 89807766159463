import {getApiError} from "helpers/getApiError";
import "./useApiListNotification.scss";

type tUseApiListNotification<T> = {
  isLoading: boolean;
  isError: boolean;
  error: any;
  list: Array<T>;
};

export function useApiListNotification<T>(props: tUseApiListNotification<T>) {
  const {isLoading, isError, list, error} = props;
  const {msg, errors} = getApiError(error);
  const errMsg = (errors && Object.values(errors || {})?.[0]) || msg || "Error!";
  return {
    show: isLoading || isError || !list?.length,
    element: (
      <tr className="useApiListNotification-tr">
        <td className="useApiListNotification">
          <div className={`info ${isError ? "err" : ""}`}>
            {isLoading && "Loading..."}
            {!isLoading && isError && errMsg}
            {!isLoading && !list?.length && !isError && "This list is empty"}
          </div>
        </td>
      </tr>
    )
  };
}
