import {FC} from "react";
import {UnderConstruction} from "components/shared/UnderConstruction/UnderConstruction";
import {Button} from "components/shared/Button/Button";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import "./JobWrap.scss";

interface iJobWrapProps {}

export const JobWrap: FC<iJobWrapProps> = () => {
  const {removeSection, removeSectionQuery} = useContextJobDetails();

  return (
    <div className="JobWrap">
      <UnderConstruction />
      <Button
        className="EmptyPageState-remove"
        onClick={() => removeSection()}
        disabled={removeSectionQuery.isLoading}
      >
        Remove wrap section
      </Button>
    </div>
  );
};
