import {useEffect, useState} from "react";
import moment from "moment";

export type useDateRangePickerReturn = ReturnType<typeof useDateRangePicker>;

type tDate = Date | null;
// type tEvent = SyntheticEvent<any, Event> | undefined;

interface iUseDateRangePickerProps {
  required?: boolean;
  startDate?: string;
  endDate?: string;
}

const messages = {
  isEmpty: "This field can't be empty",
  isEmptyStart: "Start date can't be empty",
  isEmptyEnd: "End date can't be empty",
  isIncorrect: "Start date must be before or equal to the end date"
};

export function useDateRangePicker(props?: iUseDateRangePickerProps) {
  const [startDate, setStartDate] = useState<tDate>(
    props?.startDate ? new Date(props.startDate) : new Date()
  );
  const [endDate, setEndDate] = useState<tDate>(
    props?.endDate ? new Date(props.endDate) : new Date()
  );
  const [errors, setErrors] = useState<string[]>([]);

  const [required, setRequired] = useState(props?.required !== undefined ? props.required : false);
  const [wasUserChanges, setUserChanges] = useState(false);

  useEffect(() => {
    if (props?.startDate) setStartDate(new Date(props?.startDate));
    if (props?.endDate) setEndDate(new Date(props?.endDate));
  }, [props?.startDate, props?.endDate]);

  const checkValidity = (dates: {start?: tDate; end?: tDate}) => {
    const start = dates?.start ? dates.start : startDate;
    const end = dates?.end ? dates.end : endDate;

    if (moment(start).isAfter(end)) {
      setErrors([messages.isIncorrect]);
      return false;
    }

    if (required && (!start || !end)) {
      setErrors([messages.isEmpty]);

      return false;
    }

    setErrors([]);
    return true;
  };

  const handleStartDateChange = (date: tDate) => {
    setUserChanges(true);
    checkValidity({start: date});
    setStartDate(date);
  };

  const handleEndDateChange = (date: tDate) => {
    setUserChanges(true);
    checkValidity({end: date});
    setEndDate(date);
  };

  const onChangeTime = (value: {hours: string; minutes: string; zone: string; type: string}) => {
    setUserChanges(true);
    const isStartValue = value.type === "start";

    const hours = value.zone === "pm" && +value.hours < 12 ? +value.hours + 12 : +value.hours;

    const newDateTime = (v: any) => {
      return moment(v)
        .set("hour", hours)
        .set("minute", +value.minutes)
        .toDate();
    };

    isStartValue
      ? handleStartDateChange(newDateTime(startDate))
      : handleEndDateChange(newDateTime(endDate));
  };

  return {
    startDate,
    setStartDate: handleStartDateChange,
    endDate,
    setEndDate: handleEndDateChange,
    required,
    setRequired,
    checkValidity,
    errors,
    setErrors,
    onChangeTime,
    wasUserChanges
  };
}
