import {iUseImportModal} from "pages/Contacts/commonComponent/ImportModal/useImportModal";
import {FullContactDataTypes} from "./FullContactDataTypes";
import type {vCardTypes} from "./vCardTypes";
var vCard = require("vcard-parser");

type tGenerateObjs = FullContactDataTypes;

export const vCardParse = (fileString: string, type: iUseImportModal["type"]) => {
  const vCardList = fileString?.split("BEGIN:VCARD");
  vCardList.shift();

  let response = {
    errors: null,
    data: [] as Array<FullContactDataTypes>,
    errorMessage: ""
  };

  for (let i = 0; i < vCardList.length; i++) {
    const currItem: vCardTypes = vCard.parse(vCardList[i]);

    // const {msg} = isValid(currItem, i); // FE validation
    // if (!!msg) {
    //   response.errorMessage = msg;
    //   break;
    // }

    const generatedVCard = generateVCard(currItem, type);
    response.data.push(generatedVCard);
  }

  return response;
};

/**
 * helpers
 */

function generateVCard(card: vCardTypes, type?: iUseImportModal["type"]) {
  console.log("parsed card --->", card);

  const ln = card?.n?.[0]?.value?.[0];
  const fn = card?.n?.[0]?.value?.[1];
  const mn = card?.n?.[0]?.value?.[2];
  const rawAddress = card?.adr?.[0]?.value?.filter(Boolean)?.join(", ");
  const bDef = card?.billing?.[0]?.value?.[0]?.split(", ");

  const generateContactData = (): Partial<tGenerateObjs> => {
    return {
      legal_name: card?.fn?.[0]?.value,
      first_name: fn,
      last_name: ln,
      middle_name: mn,
      travel: card?.travel?.[0]?.value,
      dietary_restrictions: card?.["dietary-restriction"]?.map(item => item?.value),
      pronoun_id: card?.pronouns?.[0]?.value,
      billing_default: bDef,
      team: card?.team?.map(item => {
        return {user_id: item?.value?.[0], user_relationship_id: item?.value?.[1]};
      })
    };
  };

  const generateCompanyData = (): Partial<tGenerateObjs> => {
    return {
      name: card?.fn?.[0]?.value,
      website: card?.url?.[0]?.value
    };
  };

  //['', 'billing firstName', 'billing lastN', 'billing_info@mail.com', '970097976', 'Лесі Українки Вулиця, apartment']
  const bFn = card?.billing?.[0]?.value?.[1];
  const bLn = card?.billing?.[0]?.value?.[2];
  const bEmail = card?.billing?.[0]?.value?.[3];
  const bPhone = card?.billing?.[0]?.value?.[4];
  const rawBillingAddress = card?.billing?.[0]?.value?.[5];

  const data: tGenerateObjs = {
    ...(type === "people" && {...generateContactData()}),
    ...(type === "companies" && {...generateCompanyData()}),
    billing_info: {
      first_name: bFn,
      last_name: bLn,
      email: bEmail,
      phone: bPhone,
      address: rawBillingAddress ? {raw: rawBillingAddress} : undefined
    },
    //common data
    address: rawAddress ? {raw: rawAddress} : undefined,
    emails: card?.email?.map(item => {
      return {type_id: item?.meta?.type?.[0], value: item?.value};
    }),
    phones: card?.tel?.map(item => {
      return {type_id: item?.meta?.type?.[0], value: item?.value};
    }),
    accounts: card?.["X-SOCIALPROFILE"]?.map(item => {
      return {value: item?.value, platform: item?.meta?.type?.[0]};
    }),
    custom_fields: card?.custom?.map(item => {
      if (item?.meta?.type?.[0] === "checkbox" && typeof item?.value === "string") {
        return {id: item?.meta?.name?.[0], value: [item?.value]};
      }
      return {id: item?.meta?.name?.[0], value: item?.value || ""};
    }),
    labels: card?.label?.map(item => item?.value),
    locales: card?.locale?.map(item => item?.value),
    companies: card?.company?.map(item => item?.value),
    agencies: card?.["artist-agency"]?.map(item => item?.value),
    titles: Array.isArray(card?.title?.[0]?.value)
      ? card?.title?.[0]?.value
      : [card?.title?.[0]?.value],
    avatar: card?.photo?.[0]?.value,
    departments: card?.["associated-department"]?.map(item => item?.value),
    preferred: card?.["preferred-star"]?.[0]?.value
      ? !!card?.["preferred-star"]?.[0]?.value
      : undefined,
    versatile_relationship_id: card?.["relationship-to-versatile"]?.[0]?.value,
    rate: card?.rate?.[0]?.value,
    notes: card?.note?.[0]?.value,
    other_dietary_restriction: card?.["other-dietary-restriction"]?.[0]?.value?.join(", ")
  };

  const {custom_fields, ...rest} = data;
  const cleanData = removeEmptyKeys(rest) as tGenerateObjs;

  console.log("ready cleanData --->", {custom_fields, ...cleanData});
  return {custom_fields, ...cleanData};
}

type Obj = Record<string, any>;
function removeEmptyKeys(obj: Obj): Obj {
  const newObj: Obj = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const val = obj[key];
      if (val !== null && val !== undefined && val !== "") {
        if (typeof val === "object") {
          newObj[key] = removeEmptyKeys(val as Obj);
        } else {
          newObj[key] = val;
        }
      }
    }
  }
  return newObj;
}
