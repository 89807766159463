import {FormEvent} from "react";
import {gridApi} from "api/jobs/grid";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {notValidForm} from "helpers/misc";
import {useHttp} from "hooks/httpServices/useHttp";
import {useTextInputReturn} from "hooks/useTextInput/useTextInput";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {tGridFormType} from "../GridHeader/MenuGrid";
import {useContextJobGrid} from "../JobGridContext";
import {eventBus} from "EventBus/EventBus";
import {isUniqueGridName} from "./isUniqueGridName";

type tProps = {
  closeTemplateModal: () => void;
  type?: tGridFormType;
  form: {name: useTextInputReturn};
};

export const useOnSubmitGrid = ({closeTemplateModal, type, form}: tProps) => {
  const {setGridList, grid, setGrid, setCurrGridId, gridList} = useContextJobGrid();
  const {jobDetailsData} = useContextJobDetails();
  const id = jobDetailsData?.id;

  const {call, isLoading: submitting} = useHttp();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isUniqueGridName(gridList, form)) {
      return eventBus.dispatch("showToast", {type: "error", text: "Name should be unique!"});
    }

    if (notValidForm(form) || !id) return;

    try {
      let res: any;

      if (!type) {
        res = await call(gridApi.createNewGrid({job_id: id, name: form?.name?.value}));
        setGridList(prev => (!!prev ? [...prev, res?.data?.data] : prev));
        setGrid(res?.data?.data);
        setCurrGridId(res?.data?.data?.id);
      }

      if (type === "rename" && grid?.id) {
        res = await call(gridApi.renameGrid(grid?.id, {name: form?.name?.value}));
        setGrid(() => res?.data?.data);
        setGridList(prev => {
          if (!prev) return prev;
          return prev.map(item => {
            if (item.id === grid.id) {
              return (item = {...item, name: res?.data?.data?.name});
            }
            return item;
          });
        });
      }

      if (type === "duplicate" && grid?.id) {
        res = await call(gridApi.duplicateGrid(grid?.id, {name: form?.name?.value}));
        setGridList(prev => (!!prev ? [...prev, res?.data?.data] : prev));
      }

      eventBus.dispatch("showToast", {text: res?.data?.message || "Success!"});
      closeTemplateModal();
    } catch (error) {
      const {msg} = apiErrorHandler(error, form);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return {onSubmit, submitting};
};
