import {FC} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {ReactSelect} from "components/shared/FormComponents/ReactSelect/ReactSelect";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";

import "./LocationInput.scss";

interface iLocationInputProps {
  value: any;
  options: any[];
  onChangeInputRow: any;
  hasRemoveButton?: boolean;
  onRemoveRow?: any;
}

export const LocationInput: FC<iLocationInputProps> = ({
  value,
  onRemoveRow,
  hasRemoveButton,
  options,
  onChangeInputRow
}) => {
  const onChangeLabel = (event: any) => onChangeInputRow({...value, [event.target.name]: event.target.value}); // prettier-ignore

  const onChangeLocation = (option: any) => {
    onChangeInputRow({
      ...value,
      location_id: option.id,
      name: option.name,
      address: option?.address
    });
  };

  const isError = !!value?.errors?.length;
  const isErrorLabel = isError && !value?.label;
  const isErrorLocation = isError && !value?.location_id;

  return (
    <>
      <div className="LocationInput">
        <InputField
          name="label"
          value={value?.label}
          onChange={onChangeLabel}
          errors={isErrorLabel ? value?.errors : []}
        />
        <div>
          <ReactSelect
            className={isErrorLocation ? "error" : ""}
            name="location"
            value={{
              id: value.location_id,
              name: value.name
            }}
            options={options}
            getOptionLabel={option => option.name}
            onChange={onChangeLocation}
            hideArrow={false}
            menuPosition="fixed"
            menuPlacement="top"
          />
          {isErrorLocation
            ? value?.errors?.map((error: string) => (
                <p key={error} className="LocationInput-error">
                  {error}
                </p>
              ))
            : null}
        </div>

        {hasRemoveButton && (
          <Button type="button" onClick={() => onRemoveRow?.(value)}>
            <Icon icon="close" size="14" color={"dark-gray"} />
          </Button>
        )}
      </div>
    </>
  );
};
