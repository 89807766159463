import {useEffect, useRef, useState} from "react";
import {useContextBasicData, useContextProfile} from "context";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {createNewRowObj} from "components/shared/FormComponents/DynamicFields/model";
import {tDynamicFieldRowObj} from "components/shared/FormComponents/DynamicFields/types";
import {uid} from "helpers/misc";
import {useAddressField} from "components/shared/FormComponents/AddressField/useAddressField";
import {usePhoneInput} from "hooks/usePhoneInput";
import {useDatePicker} from "components/shared/FormComponents/Datepicker/useDatePicker";
import {useSelectInput} from "components/shared/FormComponents/SelectInput/useSelectInput";

type tCheckFieldsValidity = {
  checkFieldsValidity: () => boolean;
};

export const useAccountProfileForm = () => {
  const {countries, platforms, pronouns, getData} = useContextBasicData();

  const {userData} = useContextProfile();
  const [avatar, setAvatar] = useState(userData?.avatar_url || "");

  const socPlatformRef = useRef<tCheckFieldsValidity>(null);
  const [accounts, setAccounts] = useState<tDynamicFieldRowObj[]>([createNewRowObj()]);

  useEffect(() => {
    getData("pronouns");
    getData("countries");
    getData("platforms");
  }, []); // eslint-disable-line

  const form = {
    display_name: useTextInput({
      value: userData?.display_name || "",
      validators: ["name"],
      filters: ["name"]
    }),
    pronoun_id: useSelectInput({
      value: String(userData?.pronoun?.id) || "",
      customValue: userData?.pronoun_custom || "",
      isRequired: true,
      targetKeys: {value: "id", label: "name"},
      placeholder: "Select...",
      options: pronouns
    }),
    birthday: useDatePicker({initValue: userData?.birthday, isRequired: true}),
    fun_facts: useTextInput({value: userData?.fun_facts, isRequired: false}),
    accounts: {
      checkValidity: () => !socPlatformRef?.current?.checkFieldsValidity(),
      value: accounts
    },
    phone: useTextInput({
      validators: ["phone"],
      filters: ["phone"],
      value: userData?.phone || ""
    }),
    personal_email: useTextInput({validators: ["email"], value: userData?.personal_email || ""}),
    home_address: useAddressField({
      isRequired: true,
      address: userData?.home_address,
      countryOptions: countries
    }),
    // emergency
    full_name: useTextInput({ validators: ["name"], filters: ["name"], value: userData?.emergency_contact?.full_name }), // prettier-ignore
    relationship: useTextInput({value: userData?.emergency_contact?.relationship}),
    phoneEmergency: useTextInput({
      validators: ["phone"],
      filters: ["phone"],
      value: userData?.emergency_contact ? userData?.emergency_contact?.phone : '' // prettier-ignore
    }),
    email: useTextInput({validators: ["email"], value: userData?.emergency_contact?.email})
  };

  useEffect(() => {
    if (!!pronouns?.length) {
      form.pronoun_id.setOptions(pronouns);
    }
  }, [pronouns]); // eslint-disable-line

  useEffect(() => {
    if (userData?.accounts?.length) {
      setAccounts(
        userData.accounts.map((item: any) => {
          return {
            uid: item.id || uid(),
            id: item?.platform?.id,
            value: item?.value || "",
            name: item?.platform?.name
          };
        })
      );
    }
  }, []); // eslint-disable-line

  return {
    form,
    avatar,
    setAvatar,
    socPlatformRef,
    accounts,
    setAccounts,
    accPlatformOptions: platforms
  };
};
