import {useEffect, useState} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {useParams, useLocation, useNavigate} from "react-router-dom";
import {jobsApi} from "api/jobs";
import {iJobDetailsDataReturn, iRemoveSectionReturn} from "api/jobs/types";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {routes} from "router";

export const useJobDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const locations = useLocation();
  const pipelines = useHttp();
  const sectionsQuery = useHttp<{data: iJobDetailsDataReturn}>();
  const removeSectionQuery = useHttp<{data: iRemoveSectionReturn; message: string}>();
  const jobDetailsQuery = useHttp<{data: iJobDetailsDataReturn}>();

  const [jobDetails, setJobDetails] = useState<iJobDetailsDataReturn | null>(null);

  useEffect(() => {
    jobDetailsQuery
      .call(jobsApi.getJobDetails(Number(params.id)))
      .then(({data: {data}}) => {
        setJobDetails(data);
      })
      .catch(error => {
        if (error?.response?.status === 404) navigate(-1);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateJobDetails = async () => {
    jobDetailsQuery.call(jobsApi.getJobDetails(Number(params.id))).then(({data: {data}}) => {
      setJobDetails(data);
    });
  };

  const removeSection = async (onCloseCallback?: Function) => {
    const pathname = locations.pathname.split("/")?.at(-1);
    const sectionId = jobDetails?.sections?.find(section => {
      if(pathname?.toLowerCase() === routes?.jobs?.details?.budget?.estimate?.main?.toLowerCase()) {
        return section?.name?.toLowerCase()?.includes('budget');
      } 

      return section.name.split(' ').join('-').toLowerCase() === pathname?.toLowerCase();
    })?.id; // prettier-ignore

    if (!sectionId) return;
    try {
      const {data: {data, message}} = await removeSectionQuery.call(jobsApi.removeSectionsFromJob(jobDetails.id, sectionId)); // prettier-ignore
      const {sections, available_sections} = data;
      setJobDetails(prev => {
        const prevState = prev as iJobDetailsDataReturn;
        return {
          ...prevState,
          sections,
          available_sections
        };
      });
      navigate(`/jobs/${params.id}/overview`);
      eventBus.dispatch("showToast", {text: message});
      onCloseCallback?.();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error); // eslint-disable-line
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return {
    jobDetailsData: jobDetails,
    updateJobDetails,
    setJobDetails,
    pipelines,
    sectionsQuery,
    removeSection,
    removeSectionQuery,
    jobDetailsQuery
  };
};
