import {FC, FormEvent} from "react";
import {PasswordField} from "components/shared/FormComponents/PasswordField/PasswordField";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useComparePasswordFields} from "hooks/useTextInput/useComparePasswordFields";
import {notValidForm} from "helpers/misc";
import {tUseHttpReturn} from "hooks/httpServices/useHttp";
import {profileInfo} from "api/profile";
import {getApiError} from "helpers/getApiError";
import {ACCOUNT_FORM_ID} from "../AccountLayoutTab/AccountLayoutTab";
import {eventBus} from "EventBus/EventBus";
import "./AccountPassword.scss";

interface iAccountPassword {
  data: tUseHttpReturn;
}

export const AccountPassword: FC<iAccountPassword> = ({data}) => {
  const {call} = data;

  const formData = {
    current_password: useTextInput(),
    password: useTextInput({validators: ["password"]}),
    confirm_password: useTextInput()
  };

  const {current_password, password, confirm_password} = formData;

  useComparePasswordFields({pass: password, confirmPass: confirm_password});

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (notValidForm(formData)) return;

    try {
      const apiData = {
        current_password: current_password.value,
        password: password.value,
        password_confirmation: confirm_password.value
      };
      const {
        data: {message}
      } = await call(profileInfo.changePassword(apiData));

      eventBus.dispatch("showToast", {text: message || "Success! Password updated."});
      Object.values(formData).forEach(value => {
        value.setValue("");
        value.setErrors([]);
      });
      password.setErrors([]);
    } catch (error) {
      const {msg} = getApiError(error, formData);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <form id={ACCOUNT_FORM_ID} className="AccountPassword" onSubmit={handleSubmit}>
      <PasswordField
        {...formData.current_password.inputProps}
        label="Current Password"
        errors={formData.current_password.errors}
        maxLength={255}
      />
      <PasswordField
        {...formData.password.inputProps}
        label="New Password"
        errors={formData.password.errors}
        maxLength={255}
      />
      <PasswordField
        {...formData.confirm_password.inputProps}
        label="Confirm New Password"
        errors={formData.confirm_password.errors}
        maxLength={255}
      />
    </form>
  );
};
