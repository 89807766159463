import {FC} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {tUseJobContactsFieldReturn} from "./useJobContactsField";
import {SearchField} from "components/shared/FormComponents/SearchField/SearchField";
import {JobsContactItem} from "./JobsContactItem";
import {iJobContactsFieldRow} from "./types";
import "./JobContactsField.scss";

interface iJobContactsFieldProps extends tUseJobContactsFieldReturn {
  id?: string;
}

export const JobContactsField: FC<iJobContactsFieldProps> = props => {
  const {values, onChangeLead, errors, person, onChangeTitle} = props;
  const {onRemove, addContact, searchingPersons} = props;

  const personValue = values.map(item => item.person);
  const isErrorClassName = !!errors.length ? "error" : "";

  return (
    <div className={`JobContactsField ${isErrorClassName}`} id={props?.id}>
      <label className="title">Job Contacts</label>
      {!!values?.length &&
        values.map(({uid, person, title, lead}: iJobContactsFieldRow, index: number) => (
          <JobsContactItem
            onRemove={onRemove}
            onChangeTitle={onChangeTitle}
            onChangeLead={onChangeLead}
            key={uid}
            title={title}
            person={person}
            lead={lead}
            index={index}
            uid={uid}
          />
        ))}

      {searchingPersons && (
        <SearchField
          {...person}
          isClearable={false}
          isMulti={true}
          components={{MultiValue}}
          value={personValue}
          getOptionValue={option => option.id}
          getOptionLabel={option => `${option.first_name} ${option.last_name}`}
          placeholder="Type to search contacts..."
        />
      )}
      {!searchingPersons && (
        <Button onClick={addContact} type="button" color="outline" size="sm">
          <Icon icon="add" size="xsm" />
          Add Contact
        </Button>
      )}

      {!!errors?.length &&
        errors.map(error => (
          <span key={error} className="JobContactsField-error">
            {error}
          </span>
        ))}
    </div>
  );
};

const MultiValue = ({selectProps, index}: any) => {
  const {placeholder, inputValue} = selectProps;
  if (index > 0 || inputValue) return <span />;
  return <span className="JobContactsField-MultiValue-placeholder">{placeholder}</span>;
};
