import React, {FC, FormEvent} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {Button} from "components/shared/Button/Button";
import {useHttp} from "hooks/httpServices/useHttp";
import {AxiosRequestConfig} from "axios";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {eventBus} from "EventBus/EventBus";
import "./ManageTagsForm.scss";

interface iManageTagsFormProps {
  title: string;
  placeholder: string;
  onClose: () => void;
  show: boolean;
  data?: {id: number; name: string} | null;
  addConfig: (data: {name: string}) => AxiosRequestConfig;
  updateConfig: (id: number, data: {name: string}) => AxiosRequestConfig;
  updateList?: () => void;
}

export const ManageTagsForm: FC<iManageTagsFormProps> = ({
  addConfig,
  updateConfig,
  title,
  placeholder,
  show,
  onClose,
  data,
  updateList
}) => {
  const {isLoading, call} = useHttp();

  const name = useTextInput({isRequired: true, value: data?.name, validators: ["valueLength"]});

  const handleOnSave = async (event: FormEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!name.checkValidity() || name?.value?.trim()?.length < 2) {
      return name?.setValue(name?.value?.trim());
    }
    try {
      const response = data?.id
        ? await call(updateConfig(data.id, {name: name?.value}))
        : await call(addConfig({name: name?.value}));

      await onClose();
      await updateList?.();
      eventBus.dispatch("showToast", {text: response?.data?.message});
    } catch (error: any) {
      eventBus.dispatch("showToast", {type: "error", text: error?.message});
    }
  };

  return (
    <ModalPopup className="ManageTagsForm" size="sm" show={show} onClose={onClose}>
      <form className="ManageTagsForm-content" onSubmit={handleOnSave}>
        <h2 className="ManageTagsForm-title">{title}</h2>
        <div className="ManageTagsForm-body">
          <InputField
            id="search"
            type="text"
            {...name.inputProps}
            placeholder={placeholder}
            errors={name.errors}
          />
        </div>
        <div className="ManageTagsForm-buttons">
          <Button type="button" disabled={isLoading} size="md" color="light" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={Boolean(name.errors?.length)}
            loading={isLoading}
            size="md"
            color="dark"
          >
            Save
          </Button>
        </div>
      </form>
    </ModalPopup>
  );
};
