import {useEffect} from "react";
import {locationApi} from "api/location";
import {useHttp} from "hooks/httpServices/useHttp";
import {tags as tagsApi} from "api/tags";
import {useSetApiParams} from "hooks/useSetApiParams";
import {useLocation} from "react-router-dom";
import {iBaseOptions} from "api/common/types";
import {iLocationsListItemResponse} from "api/location/types";
import {tMeta} from "components/Pagination/types";

export const useLocations = () => {
  const {locales} = tagsApi;
  const {data: localesData} = useHttp<{data: iBaseOptions[]}>({
    config: locales.get(),
    onMountCall: true
  });
  const locationList = useHttp<{data: iLocationsListItemResponse[]; meta: tMeta}>();
  const {pathname} = useLocation();
  const apiParams = useSetApiParams();

  useEffect(() => {
    pathname.includes("/locations") && getLocationsList();
  }, [apiParams?.searchParams]); // eslint-disable-line

  const getLocationsList = () => {
    const locales = apiParams?.preparedParams.locales?.split(",").map(item => +item);
    locationList?.call(locationApi.locations.get({...apiParams?.preparedParams, locales}));
  };

  return {
    localesData,
    locationList,
    getLocationsList,
    apiParams
  };
};
