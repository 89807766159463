import {FC, useState} from "react";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {useContextJobTasks} from "pages/Jobs/JobDetails/JobTasks/_context/JobTaskContext";
import {tJobTask} from "api/jobs/tasks/types";
import {Loading} from "components/shared/Loading/Loading";
import "./StatusCell.scss";

type tStatusCellProps = {
  data?: tJobTask;
};

export const StatusCell: FC<tStatusCellProps> = ({data}) => {
  const {statuses, onSubmit, submitting, cellKeyRef, generateKey} = useContextJobTasks();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const typeApi = "status";
  const currCellKey = generateKey(typeApi, data?.id);
  const isLoading = cellKeyRef?.current === currCellKey && submitting;

  const setStatus = async (currId: number) => {
    setMenuAnchor(null);

    if (!data?.id || data?.status?.id === currId) return;
    if (data?.status === null && currId === null) return;

    onSubmit({taskId: data?.id, typeApi, data: {status_id: currId}});
  };

  const opt = {
    "0": {id: null, name: "Empty", color: "var(--grey-0)", bg: "var(--gray-30)"},
    "1": {color: "var(--black-10)", bg: "var(--green-10)"},
    "2": {color: "var(--black-10)", bg: "var(--yellow-0)"},
    "3": {color: "var(--white-0)", bg: "var(--red-20)"}
  };

  if (!statuses?.length) return null;

  // @ts-ignore
  const list = [opt?.["0"], ...statuses?.map(item => ({...item, ...opt?.[item?.id]}))];
  // @ts-ignore
  const currId = data?.status?.id ? opt?.[data?.status?.id] : opt?.["0"];

  return (
    <div className="StatusCell posRelative">
      {isLoading && <Loading type="inBlock" customSize={0.5} />}

      <TextComponent
        onClick={showMenu}
        as="button"
        color="black-10"
        w100
        size="10"
        weight="500"
        uppercase
        h100
        style={{backgroundColor: currId?.bg, color: currId?.color}}
        className={!data?.status?.name ? "empty" : ""}
      >
        {data?.status?.name || "Select status..."}
      </TextComponent>

      <DropdownMenu
        anchor={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        className="StatusCellDropdown"
        bodyStyles={{width: menuAnchor?.clientWidth}}
      >
        {list?.map(item => {
          return (
            <TextComponent
              key={item?.id}
              as="button"
              w100
              style={{backgroundColor: item.bg, color: item.color}}
              size="10"
              weight="500"
              uppercase
              onClick={() => setStatus(item?.id)}
            >
              {item.name}
            </TextComponent>
          );
        })}
      </DropdownMenu>
    </div>
  );
};
