import {useRef, useLayoutEffect, useCallback, useReducer} from "react";

const useSafeDispatch = (dispatch: any) => {
  const mounted = useRef(false);

  useLayoutEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return useCallback((...args) => (mounted.current ? dispatch(...args) : void 0), [dispatch]);
};

const defaultInitialState = {status: "idle", data: null, error: null};
type useAsyncInitState<T = any> = {
  status: string;
  data: {data: T} | null;
  error: any;
};

export const useAsync = <T = any>(initialState: useAsyncInitState<T> = defaultInitialState) => {
  const initialStateRef = useRef({
    ...defaultInitialState,
    ...initialState
  });

  const [{status, data, error}, setState] = useReducer(
    (state: useAsyncInitState<T>, action: useAsyncInitState<T>) => ({...state, ...action}),
    initialStateRef.current
  );

  const safeSetState = useSafeDispatch(setState);

  const setData = useCallback(data => safeSetState({data, status: "resolved"}), [safeSetState]);
  const setError = useCallback(error => safeSetState({error, status: "rejected"}), [safeSetState]);
  const reset = useCallback(() => safeSetState(initialStateRef.current), [safeSetState]);

  const run = (promise: any): {data: T} => {
    if (!promise || !promise.then) {
      throw new Error(
        `The argument passed to useAsync().run must be a promise. Maybe a function that's passed isn't returning anything?`
      );
    }

    safeSetState({status: "pending"});

    return promise.then(
      (data: any) => {
        setData(data);
        return data;
      },
      (error: any) => {
        setError(error);
        return Promise.reject(error);
      }
    );
  };

  return {
    // using the same names that react-query uses for convenience
    isIdle: status === "idle",
    isLoading: status === "pending",
    isError: status === "rejected",
    isSuccess: status === "resolved",

    setData,
    setError,
    error,
    status,
    data,
    run,
    reset
  };
};
