import {FC, MouseEvent, useEffect, useState} from "react";
import {copyToClipboard} from "helpers/supporters";
import {iLocationsListItemResponse} from "api/location/types";
import {parseAddress, initMapbox} from "helpers/misc";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {Button} from "components/shared/Button/Button";
import {Address} from "components/shared/Address/Address";
import {eventBus} from "EventBus/EventBus";

interface iLocationTableAddressProps {
  locationItem: iLocationsListItemResponse;
}

export const LocationTableAddress: FC<iLocationTableAddressProps> = ({locationItem}) => {
  const [showMap, setShowMap] = useState(false);
  const {string} = parseAddress(locationItem?.address);

  const handleClick = async (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (!locationItem?.coordinates?.lat) return;

    setShowMap(true);
  };

  useEffect(() => {
    if (!showMap) return;
    setTimeout(() => {
      initMapbox({
        container: "LocationsTableRow-map",
        lat: locationItem?.coordinates?.lat,
        lng: locationItem?.coordinates?.lng
      });
    }, 0);
  }, [showMap, locationItem?.coordinates?.lat, locationItem?.coordinates?.lng]);

  const copyAddress = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    copyToClipboard(string.toString());
    eventBus.dispatch("showToast", {text: "Address was copied"});
  };

  const preventMapClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <>
      <td className="LocationsTableRow-cell LocationsTableRow-cell-address">
        <span onClick={handleClick}>
          <Address address={locationItem?.address} />
          <button onClick={event => copyAddress(event)} className="button button-clipboard-copy">
            Copy
          </button>
        </span>

        {showMap && (
          <div onClick={preventMapClick}>
            <ModalPopup show={showMap} onClose={() => setShowMap(false)}>
              <h4 className="LocationsTableRow-map-title">
                {locationItem.name} - {string}
              </h4>
              <div id="LocationsTableRow-map" className="LocationsTableRow-map" />
              <Button
                className="LocationsTableRow-map-button"
                size="sm"
                color="dark"
                onClick={() => setShowMap(false)}
              >
                Close
              </Button>
            </ModalPopup>
          </div>
        )}
      </td>
    </>
  );
};
