import {useEffect, useState} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {notificationsApi} from "api/notifications";
import {iNotificationsParams, tReturnNotificationData} from "api/notifications/types";
import {eventBus} from "EventBus/EventBus";
import {useContextApp} from "context";

type tNotificationsDataReturn = {
  data: tReturnNotificationData[];
  meta: {current_page: number; last_page: number};
};

export const useNotifications = () => {
  const {notificationsWindow} = useContextApp();
  const {data, call, isLoading} = useHttp<tNotificationsDataReturn>();
  const {call: markAllNotificationsCall} = useHttp();
  const [notifications, setNotifications] = useState<tReturnNotificationData[] | null>(null);

  useEffect(() => {
    notificationsWindow && getNotifications();

    return () => {
      setNotifications(null);
    };
  }, [notificationsWindow]); // eslint-disable-line

  const getNotifications = async (params?: iNotificationsParams) => {
    try {
      const {
        data: {data}
      } = await call(notificationsApi?.getProfileNotifications(params));
      setNotifications(notifications ? [...notifications, ...data] : data);
    } catch (error: any) {
      console.warn("warn", error?.message);
    }
  };

  const loadMoreNotifications = async () => {
    if (!data?.data?.meta) return;
    const {current_page, last_page} = data?.data?.meta;
    if (current_page < last_page) {
      await getNotifications({page: String(current_page + 1)});
    }
  };

  const markAllAsRead = async () => {
    try {
      await markAllNotificationsCall(notificationsApi.markAllNotificationsAsRead());
      setNotifications([]);
      eventBus.dispatch("showToast", {text: "Notifications were marked as read."});
    } catch (error: any) {
      eventBus.dispatch("showToast", {type: "error", text: error?.message});
    }
  };

  return {
    setNotifications,
    markAllAsRead,
    notifications,
    isLoading,
    getNotifications,
    loadMoreNotifications,
    data
  };
};
