import {FC} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {TagButton} from "components/shared/TagButton/TagButton";
import moment from "moment";
import {Icon} from "components/Icon/Icon";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {tEventModal, tEventType} from "pages/Jobs/JobDetails/JobCalendar/helpers";
import {useEventDetails} from "./useEventDetails";
import "./EventDetails.scss";

interface iEventDetailsProps {
  data: tEventModal;
  onClose: () => void;
  onEditEvent: (event: tEventModal) => void;
}

export const EventDetails: FC<iEventDetailsProps> = ({data, onEditEvent, onClose}) => {
  const {
    onExport,
    onDelete,
    onConfirmDelete,
    isLocked,
    isLoading,
    confirmDelete,
    setConfirmDelete
  } = useEventDetails({data, onClose});
  const {name, description, location, start_date, end_date} = data;

  const formatTime = (time: string, format = "MMM DD, YYYY, hh:mm A") =>
    moment(time).format(format);
  const isEventType = data.type === "events";

  const getEventDate: any = ({all_day, type}: {all_day?: boolean; type: tEventType}) => {
    const dType = {
      events: all_day
        ? `${formatTime(start_date, "MMM DD, YYYY")} - ${formatTime(end_date, "MMM DD, YYYY")}`
        : `${formatTime(start_date)} - ${formatTime(end_date)}`,
      holds: `${formatTime(start_date, "MMM DD, YYYY")} - ${formatTime(end_date, "MMM DD, YYYY")}`,
      tasks: `${formatTime(start_date)} - ${formatTime(end_date)}`
    };

    return dType[type];
  };

  return (
    <>
      <ModalPopup
        showCloseButton={false}
        className="EventDetailsModal"
        withoutPaddings
        show={Boolean(data)}
        onClose={onClose}
      >
        <div className="EventDetails">
          <div className="EventDetails-header">
            {!isLocked && isEventType && (
              <button onClick={() => onEditEvent(data)}>
                <Icon icon="edit" color="dark-gray" />
              </button>
            )}

            {isEventType && (
              <button onClick={onExport}>
                <Icon icon="export" color="dark-gray" />
              </button>
            )}

            {!isLocked && isEventType && (
              <button onClick={onConfirmDelete}>
                <Icon icon="delete" color="dark-gray" />
              </button>
            )}
            <button onClick={onClose} className="EventDetails-delete">
              <Icon icon="close" color="dark-gray" />
            </button>
          </div>
          <h2 className="EventDetails-title">{name}</h2>
          <div className="EventDetails-time">
            {data.type ? getEventDate({all_day: data?.all_day, type: data?.type}) : ""}
          </div>
          {description && <div className="EventDetails-description">{description}</div>}
          {location && <TagButton color="light">{location.name}</TagButton>}
        </div>
      </ModalPopup>
      <ConfirmWindow
        className="EventDetailsDeleteConfirm"
        isLoading={isLoading}
        show={confirmDelete}
        title="Delete event?"
        onClose={() => setConfirmDelete(false)}
        onConfirm={onDelete}
        confirmBtn={{
          text: "Delete",
          color: "red"
        }}
        cancelBtn={{
          text: "Cancel"
        }}
      >
        <p>This event will be permanently deleted.</p>
        <p className="error-message">This can't be undone.</p>
      </ConfirmWindow>
    </>
  );
};
