import {FC} from "react";
import {getDateFormat} from "helpers/dates";
import {useSetApiParams} from "hooks/useSetApiParams";
import moment from "moment";

interface iSchedulerControlsProps {
  schedulerDashboard: any;
}
export const SchedulerControls: FC<iSchedulerControlsProps> = ({schedulerDashboard}) => {
  const apiParams = useSetApiParams();

  const handleClickTodayButton = () => {
    const today = getDateFormat(moment().startOf("month").format("YYYY-MM-DD hh:mm"), 'timestamp'); // prettier-ignore
    apiParams.setApiParams({params: {date: today}});
    schedulerDashboard._click.dhx_cal_today_button();
  };

  function setParamDate(direction: "next" | "prev") {
    const currentDateParam = Number(apiParams?.preparedParams?.date) * 1000 || new Date(); // Math.floor(Date.now() / 1000)
    const newDate = {
      next: moment(currentDateParam).add(1, "months"),
      prev: moment(currentDateParam).subtract(1, "months")
    };

    apiParams.setApiParams({
      params: {
        date: getDateFormat(moment(newDate[direction]).format("YYYY-MM-DD hh:mm"), "timestamp")
      }
    });
  }

  const handleClickPrevNavButton = () => {
    setParamDate("prev");
    schedulerDashboard._click.dhx_cal_prev_button();
  };

  const handleClickNextNavButton = () => {
    setParamDate("next");
    schedulerDashboard._click.dhx_cal_next_button();
  };

  return (
    <div id="scheduler-controls" className="SchedulerDashboard-nav">
      <button
        onClick={handleClickPrevNavButton}
        className="SchedulerDashboard-nav-button-prev"
        aria-label="Prev"
      />

      <button onClick={handleClickTodayButton} className="SchedulerDashboard-nav-today">
        Today
      </button>

      <button
        onClick={handleClickNextNavButton}
        className="SchedulerDashboard-nav-button-next"
        aria-label="Next"
      />
    </div>
  );
};
