import {FC, useState, FormEvent} from "react";
import {FormWrap, iFormWrapProps} from "components/shared/FormWrap/FormWrap";
import {Select} from "components/shared/FormComponents/Select/Select";
import {CheckBoxGroupe} from "components/shared/FormComponents/CheckBoxGroupe/CheckBoxGroupe";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {useSelect} from "hooks/inputHooks";
import {useDatePicker} from "components/shared/FormComponents/Datepicker/useDatePicker";
import {useCheckBoxGroupe} from "hooks/inputHooks/useCheckBoxGroupe";
import {notValidForm} from "helpers/misc";
import {SwitchInput} from "components/shared/FormComponents/SwitchInput/SwitchInput";
import {Datepicker} from "components/shared/FormComponents/Datepicker/Datepicker";
import moment from "moment";
import {TimeField} from "components/shared/FormComponents/DateRangePicker/TimeField";
import {tAddDeliveryPostData, iDeliveryInfo} from "api/callSheet/types";
import {TextEditor} from "components/shared/TextEditor/TextEditor";
import {useTextEditor} from "components/shared/TextEditor/useTextEditor";
import {getDateFormat} from "helpers/dates";

import "./CallSheetSenderForm.scss";

type tFormData = Omit<tAddDeliveryPostData, "contacts" | "groups">;

interface iCallSheetSenderFormProps {
  type?: "schedule" | "resend";
  formWrapProps: Omit<iFormWrapProps, "children" | "idForm" | "submitTitle">;
  onSubmitCallback?: (formData: tFormData) => void;
  isLoading?: boolean;
  deliveryInfo?: iDeliveryInfo | null;
  submitTitle?: string;
}

const methodsArr = [
  {
    id: "1",
    name: "Email"
  },
  {
    id: "2",
    name: "SMS text"
  }
];

export const CallSheetSenderForm: FC<iCallSheetSenderFormProps> = ({
  type = "schedule",
  formWrapProps,
  onSubmitCallback,
  isLoading,
  deliveryInfo,
  submitTitle
}) => {
  const {jobDetailsData} = useContextJobDetails();
  const [time, setTime] = useState<any>(deliveryInfo?.schedule || new Date()); // prettier-ignore
  const [scheduleDelivery, setScheduleDelivery] = useState(!!deliveryInfo?.schedule);

  const form = {
    contacts: useSelect({isRequired: true, value: String(deliveryInfo?.team_contact?.id)}), // prettier-ignore
    methods: useCheckBoxGroupe({isRequired: true, initValue: deliveryInfo?.methods.map(i => i.id) || []}), // prettier-ignore
    date: useDatePicker({initValue: deliveryInfo?.schedule || new Date().toDateString(), isRequired: true}), // prettier-ignore
    message: useTextEditor({required: true, value: deliveryInfo?.message})
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (notValidForm(form)) return;
    const dateSum = moment(form.date.value).format("YYYY-MM-DD") + " " + moment(time).format("HH:mm:ss"); // prettier-ignore
    const schedule = getDateFormat(dateSum, "timestamp");

    onSubmitCallback?.({
      message: form.message.value,
      methods: form.methods.selectedCheckBoxes.current.map(m => +m),
      team_contact_id: +form.contacts?.value,
      schedule: scheduleDelivery ? +schedule : null
    });
  };

  const handleChangeTime = (value: any) => {
    const hours = value.zone === "pm" && +value.hours < 12 ? +value.hours + 12 : +value.hours;

    const newTime = moment()
      .set("hour", hours)
      .set("minute", +value.minutes)
      .toDate();

    setTime(newTime);
  };

  const withSchedule = type === "schedule";
  const defaultButtonTitle = scheduleDelivery ? "Schedule" : "Send now";
  const buttonTitle = submitTitle || defaultButtonTitle;
  return (
    <div className="CallSheetSenderForm">
      <FormWrap
        headerTitle="RESEND LATEST DELIVERY TO <NAME>"
        idForm="call-sheet-sender-form"
        type="titleCenterAndOneBtn"
        isLoading={isLoading}
        submitTitle={buttonTitle}
        {...formWrapProps}
      >
        <form
          id="call-sheet-sender-form"
          className="CallSheetSenderForm-form"
          onSubmit={handleSubmit}
        >
          <div className="CallSheetSenderForm-team">
            <h5 className="CallSheetSenderForm-label">Team Contact</h5>
            <Select
              {...form.contacts.inputProps}
              options={jobDetailsData?.team}
              errors={form.contacts.errors}
              targetKeys={{
                value: "id",
                label: "display_name"
              }}
              canSelectEmpty
            />
          </div>
          <div className="CallSheetSenderForm-method">
            <h5 className="CallSheetSenderForm-label">DELIVERY METHOD</h5>
            <CheckBoxGroupe
              {...form.methods}
              onChange={form.methods.checkBoxGroupeOnChange}
              checkBoxList={methodsArr}
              errors={form.methods.errors}
            />
          </div>
          <div className="CallSheetSenderForm-message">
            <h5 className="CallSheetSenderForm-label">Message</h5>
            <TextEditor {...form.message.inputProps} errors={form.message.errors} />
          </div>
          {withSchedule && (
            <>
              <div className="CallSheetSenderForm-schedule">
                <h5 className="CallSheetSenderForm-label">SCHEDULE DELIVERY?</h5>
                <SwitchInput
                  checked={scheduleDelivery}
                  onChange={() => setScheduleDelivery(prev => !prev)}
                />
              </div>

              <div className="CallSheetSenderForm-date">
                <Datepicker
                  {...form.date.inputProps}
                  label="Date*"
                  placeholderText="MM/DD/yyyy"
                  dateFormat="MM/DD/yyyy"
                  dropdownMode="select"
                  errors={form.date.errors}
                  minDate={new Date()}
                  disabled={!scheduleDelivery}
                  withIcon
                />
                <TimeField
                  time={time}
                  type="start"
                  label="Time*"
                  onChange={handleChangeTime}
                  disabled={!scheduleDelivery}
                  showTimezone
                />
              </div>
            </>
          )}
        </form>
      </FormWrap>
    </div>
  );
};
