import {ChangeEvent, Dispatch, FC, SetStateAction} from "react";

import {SenderRecipientItem} from "../SenderRecipientItem/SenderRecipientItem";
import {SenderRecipientHeader} from "../SenderRecipientHeader/SenderRecipientHeader";
import {tCallSheetContact, tCallSHeetGroupContacts} from "api/callSheet/types";

import "./SenderRecipientsGroup.scss";

interface iSenderRecipientsGroupProps {
  data: tCallSHeetGroupContacts;
  onToggle: Dispatch<SetStateAction<number[]>>;
  expanded: number[];
  onSelectContact: (event: ChangeEvent<HTMLInputElement>, id: number | string) => void;
  onSelectGroup: (event: ChangeEvent<HTMLInputElement>, id: number) => void;
}

export const SenderRecipientsGroup: FC<iSenderRecipientsGroupProps> = ({
  onToggle,
  expanded,
  data,
  onSelectContact,
  onSelectGroup
}) => {
  const isExpanded = Boolean(expanded?.find((item: number) => item === data.id));

  return (
    <div className="SenderRecipientsGroup">
      <SenderRecipientHeader
        key={data.id}
        id={data.id}
        name={data.name}
        isExpanded={isExpanded}
        onToggle={onToggle}
        data={data}
        onSelectGroup={onSelectGroup}
      />

      {isExpanded &&
        data.contacts.map((contact: tCallSheetContact) => {
          return (
            <>
              <SenderRecipientItem
                key={contact.id}
                id={contact.id}
                name={contact.name}
                position={contact.position}
                selected={contact.selected}
                onSelectContact={onSelectContact}
              />
              {contact?.agent && (
                <SenderRecipientItem
                  id={contact.id}
                  key={contact.agent.id}
                  name={contact.agent.name}
                  position="Agent"
                  selected={contact.selected}
                  onSelectContact={onSelectContact}
                />
              )}
            </>
          );
        })}
    </div>
  );
};
