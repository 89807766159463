import {FC, useState} from "react";
import {ChapterHeader} from "../ChapterHeader/ChapterHeader";
import {TextComponent} from "../../../common/TextComponent/TextComponent";
import {tAdvanceDetails} from "api/budget/advance/types";
import {iJobDetailsDataReturn} from "api/jobs/types";
import {parseAddress} from "helpers/misc";
import {getDateRange} from "helpers/dates";

import "./BudgetOverlayJobDetails.scss";
import {SelectWithButton} from "../../Advance/SelectWithButton/SelectWithButton";
import {useBudgetOverlayTermsBank} from "../BudgetOverlayTermsBank/useBudgetOverlayTermsBank";
import {tBankDetails} from "api/budget/bankDetails/types";
import {tTermsConditions} from "api/budget/termsConditions/types";
import {AdvanceBankModal} from "../../Advance/modals/AdvanceBankModal/AdvanceBankModal";

interface iBudgetOverlayJobDetailsProps {
  advanceDetails: tAdvanceDetails;
  client: iJobDetailsDataReturn["client"];
  //bank
  bankDetails: tBankDetails | null;
  updateData: (data: {bank_detail: tBankDetails} | {term_condition: tTermsConditions}) => void;
}

export const BudgetOverlayJobDetails: FC<iBudgetOverlayJobDetailsProps> = ({
  client,
  advanceDetails,
  children,
  bankDetails,
  updateData
}) => {
  const addressString = client ? parseAddress(client.address).string : "";
  const {period} = getDateRange({
    start_at: advanceDetails.job_start_at,
    end_at: advanceDetails.job_end_at
  });

  const {job_name, job_number, notes, usage_license, creative, shoot_location, photographer, po} =
    advanceDetails;

  //bank
  const {bankList, setBankList} = useBudgetOverlayTermsBank();
  const [modal, setModal] = useState<"bank" | null>(null);
  const onChangeBank = (bank: tBankDetails) => updateData({bank_detail: bank});

  return (
    <div className="BudgetOverlayJobDetails">
      <div>
        <ChapterHeader>Client</ChapterHeader>
        <div className="BudgetOverlayJobDetails-clientRow">
          <div>
            <TextComponent size="13" weight="500">
              {client?.name || ""}
            </TextComponent>
            <TextComponent size="13" weight="400">
              {addressString}
            </TextComponent>
          </div>
          {/*<div>*/}
          {/*  <TextComponent size="13" weight="500">*/}
          {/*    CONNIE YANG*/}
          {/*  </TextComponent>*/}
          {/*  <TextComponent size="13" weight="400">*/}
          {/*    987.567.6789*/}
          {/*  </TextComponent>*/}
          {/*</div>*/}
        </div>
      </div>
      {children || <div />}

      <div>
        <ChapterHeader>JOB DESCRIPTION</ChapterHeader>
        <div className="BudgetOverlayJobDetails-description">
          <TextComponent size="13" weight="700" color="black-10" uppercase m="mb-4">
            JOB Name
          </TextComponent>
          <TextComponent size="13" weight="400" color="black-10" m="mb-4">
            {job_name}
          </TextComponent>
          <TextComponent size="13" weight="700" color="black-10" uppercase m="mb-4">
            JOB Number
          </TextComponent>
          <TextComponent size="13" weight="400" color="black-10" m="mb-4">
            {job_number}
          </TextComponent>
          <TextComponent size="13" weight="700" color="black-10" uppercase m="mb-4">
            PHOTOGRAPHER
          </TextComponent>
          <TextComponent size="13" weight="400" color="black-10" m="mb-4">
            {photographer}
          </TextComponent>
          <TextComponent size="13" weight="700" color="black-10" uppercase m="mb-4">
            PO
          </TextComponent>
          <TextComponent size="13" weight="400" color="black-10" m="mb-4">
            {po}
          </TextComponent>
          <TextComponent size="13" weight="700" color="black-10" uppercase m="mb-4">
            SHOOT LOCATION
          </TextComponent>
          <TextComponent size="13" weight="400" color="black-10" m="mb-4">
            {shoot_location}
          </TextComponent>
          <TextComponent size="13" weight="700" color="black-10" uppercase m="mb-4">
            SHOOT DATES
          </TextComponent>
          <TextComponent size="13" weight="400" color="black-10" m="mb-4">
            {period}
          </TextComponent>
          {advanceDetails?.extended?.[0]?.label && (
            <>
              <TextComponent size="13" weight="700" color="black-10" uppercase m="mb-4">
                {advanceDetails?.extended?.[0]?.label}
              </TextComponent>
              <TextComponent size="13" weight="400" color="black-10" m="mb-4">
                {advanceDetails?.extended?.[0]?.text}
              </TextComponent>
            </>
          )}
          {advanceDetails?.extended?.[1]?.label && (
            <>
              <TextComponent size="13" weight="700" color="black-10" uppercase m="mb-4">
                {advanceDetails?.extended?.[1]?.label}
              </TextComponent>
              <TextComponent size="13" weight="400" color="black-10" m="mb-4">
                {advanceDetails?.extended?.[1]?.text}
              </TextComponent>
            </>
          )}
        </div>
        {notes && (
          <>
            <ChapterHeader>NOTES</ChapterHeader>
            <TextComponent color="gray-10" size="13" weight="400">
              {notes}
            </TextComponent>
          </>
        )}
      </div>
      <div>
        <>
          <ChapterHeader>Bank</ChapterHeader>
          <SelectWithButton
            onChange={onChangeBank}
            value={bankDetails}
            options={bankList}
            placeholder="Insert bank details"
            buttonText={!!bankList.length ? "Edit bank details" : "Add bank details"}
            formatOptionLabel={item => item.name}
            buttonProps={{
              onClick: () => setModal("bank")
            }}
          />
          <TextComponent
            className="BudgetOverlayTermsBank-textWrap"
            weight="400"
            size="13"
            as="p"
            m={"mb-8"}
          >
            {bankDetails?.details || ""}
          </TextComponent>
        </>

        {creative && (
          <>
            <ChapterHeader>Creative & USAGE</ChapterHeader>
            <TextComponent size="13" weight="700" color="black-10" m="mb-4">
              CREATIVE
            </TextComponent>
            <TextComponent color="gray-10" size="13" weight="400" m="mb-16">
              {creative}
            </TextComponent>
          </>
        )}
        {usage_license && (
          <>
            <TextComponent size="13" weight="700" color="black-10" m="mb-4">
              USAGE LICENSE
            </TextComponent>
            <TextComponent color="gray-10" size="13" weight="400">
              {usage_license}
            </TextComponent>
          </>
        )}
      </div>

      {modal === "bank" && (
        <AdvanceBankModal
          bankList={bankList}
          setBankList={setBankList}
          onClose={() => setModal(null)}
        />
      )}
    </div>
  );
};
