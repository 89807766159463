import {FC} from "react";
import {TagButton} from "components/shared/TagButton/TagButton";
import ava from "assets/images/person.png";
import "./JobTeamTag.scss";

interface iJobTeamTagProps {
  value: any[];
  onRemove?: (id: number | undefined) => void;
}

export const JobTeamTag: FC<iJobTeamTagProps> = ({value, onRemove}) => {
  if (!value?.length) return null;

  return (
    <div className="JobTeamTag">
      {value.map(({id, avatar_url, display_name}: any) => {
        return (
          <TagButton color="outline" key={id} id={id} onRemove={onRemove ? onRemove : undefined}>
            <img className="JobTeamTag-avatar" src={avatar_url || ava} alt="team member" />
            {display_name}
          </TagButton>
        );
      })}
    </div>
  );
};
