import {FC, FormEvent} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {TemplateModal} from "components/shared/TemplateModal/TemplateModal";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {TextComponent} from "../../common/TextComponent/TextComponent";
import {jobTasksApi} from "api/jobs/tasks";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {useContextJobTasks} from "../_context/JobTaskContext";
import {useHttp} from "hooks/httpServices/useHttp";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {notValidForm} from "helpers/misc";

type tCreateNewTaskProps = {
  onClose: () => void;
};

export const CreateNewTask: FC<tCreateNewTaskProps> = ({onClose}) => {
  const {jobDetailsData} = useContextJobDetails();
  const id = jobDetailsData?.id;
  const {setTasks} = useContextJobTasks();
  const {call, isLoading} = useHttp();
  const FORM_ID = "create-job-task-form-id";

  const form = {
    name: useTextInput({value: ""})
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (notValidForm(form) || !id) return;

    try {
      const res = await call(jobTasksApi.createTaskList(id, {name: form.name.value}));
      onClose();
      setTasks(res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <ModalPopup
      show={true}
      className="LoadFromTemplate"
      onClose={onClose}
      withoutPaddings
      size="w-528"
    >
      <form className="LoadFromTemplate-form" onSubmit={onSubmit} id={FORM_ID}>
        <TemplateModal
          formId={FORM_ID}
          title={`NEW Task List`}
          primaryButton={{text: `CREATE Task List`}}
          secondaryButton={{text: "Cancel"}}
          onCancel={onClose}
          isLoading={isLoading}
        >
          <TextComponent weight="400" size="14" center m="mb-16">
            Name your task list
          </TextComponent>
          <InputField {...form.name.inputProps} errors={form.name.errors} />
        </TemplateModal>
      </form>
    </ModalPopup>
  );
};
