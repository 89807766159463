import {useEffect} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {estimateApi} from "api/budget/estimate";
import {tEstimateTypeCategories} from "api/budget/estimate/types";

export const useGetEstimateTypes = (formData: any) => {
  const {call: callListType, isLoading: isFetching} = useHttp({});

  useEffect(() => {
    callListType(estimateApi.getEstimateTypesList()).then((res: any) => {
      const types = res?.data?.data;
      types?.length &&
        formData.template.setOptions(
          types.map((item: any) => {
            return {label: item.name, value: item.id, id: item.id, categories: item?.categories};
          })
        );
    });
  }, []); // eslint-disable-line

  const selectedTypeList = formData?.template?.selected
    ? (formData?.template?.selected as {
        label: string;
        value: string;
        categories: Array<tEstimateTypeCategories>;
      })
    : null;

  return {isFetching, selectedTypeList};
};
