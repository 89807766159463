import {FC, InputHTMLAttributes} from "react";
import {Button} from "components/shared/Button/Button";
import "./UploadField.scss";

interface iUploadField extends InputHTMLAttributes<HTMLInputElement> {
  buttonText?: string;
  errors?: string[];
  isLoading?: boolean;
}

const UploadField: FC<iUploadField> = ({
  buttonText = "Upload File",
  errors,
  isLoading,
  ...props
}) => {
  return (
    <div className="UploadField">
      <Button loading={isLoading} size="sm" color="outline" className="UploadField-button">
        {buttonText}
      </Button>
      <input className="UploadField-field" type="file" {...props} />
      {errors?.map(error => (
        <p className="UploadField-error-message">{error}</p>
      ))}
    </div>
  );
};

export default UploadField;
