import {FC} from "react";
import {iTeammateDetailsData} from "api/team/types";
import {getDateFormat} from "helpers/dates";
import "./TeamMemberFooter.scss";

interface iTeamMemberFooter {
  teammateDetail: iTeammateDetailsData;
  className?: string;
};

export const TeamMemberFooter: FC<iTeamMemberFooter> = ({
  className = "",
  teammateDetail,
  children
}) => {
  const {invited_at, invited_by} = teammateDetail;

  return (
    <div className={`TeamMemberFooter ${className}`}>
      <div>
        {invited_at && `Invited on ${getDateFormat(invited_at, "MMM DD, YYYY")}`}
        &nbsp;
        {invited_by && `by ${invited_by?.first_name || ""} ${invited_by?.last_name || ""}`}
      </div>
      {children}
    </div>
  );
};
