import {FC, useEffect, useState} from "react";
import {AdvanceTermsModal} from "../JobDetails/JobBudget/Advance/modals/AdvanceTermsModal/AdvanceTermsModal";
import {tTermsConditions} from "api/budget/termsConditions/types";
import {termsConditionsApi} from "api/budget/termsConditions";
import {useHttp} from "hooks/httpServices/useHttp";

interface iJobSettingsTermsConditionsProps {
  onClose: (arg: null) => void;
}

export const JobSettingsTermsConditions: FC<iJobSettingsTermsConditionsProps> = ({onClose}) => {
  const termsListQuery = useHttp();
  const [termsList, setTermsList] = useState<tTermsConditions[]>([]);

  useEffect(() => {
    termsListQuery
      .call(termsConditionsApi.getTermsConditionsList())
      .then(({data: {data}}) => setTermsList(data));
  }, []); // eslint-disable-line

  return (
    <AdvanceTermsModal
      termsList={termsList}
      setTermsList={setTermsList}
      onClose={() => onClose(null)}
      isLoading={termsListQuery.isLoading}
    />
  );
};
