import {FC} from "react";
import {useContextJobGrid} from "../JobGridContext";
import "./Grid.scss";

type tGridProps = {};

export const Grid: FC<tGridProps> = () => {
  const {grid} = useContextJobGrid();

  if (!grid) return null;
  const {url, name} = grid;

  return (
    <div className="Grid">
      {/* <iframe src={url} frameBorder="0" title={name} /> */}
      <iframe src={url + "?rm=demo"} frameBorder="0" title={name} />

      {/* rm=minimal
          rm=full
          rm=embedded
          rm=demo
          rm=(render mode) */}
    </div>
  );
};
