import {FC, useEffect, useRef, RefObject, MouseEvent} from "react";
import {LocationDetailsHeader} from "./LocationDetailsHeader";
import {parseAddress, initMapbox} from "helpers/misc";
import {Tag} from "components/shared/Tag/Tag";
import {TagButton} from "components/shared/TagButton/TagButton";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import {ModalInfoSection} from "components/shared/ModalInfoSection/ModalInfoSection";
import {iLocationDetailsResponse} from "api/location/types";
import {LocationDetailsJobs} from "./LocationDetailsJobs/LocationDetailsJobs";
import {ModalInfoSectionWrap} from "components/shared/ModalInfoSection/ModalInfoSectionWrap";
import "./LocationDetails.scss";

interface iLocationDetailsProps {
  location: iLocationDetailsResponse;
  editLocation: () => void;
  showContactDetails: (event: MouseEvent<HTMLButtonElement>, id: number) => void;
}

export const LocationDetails: FC<iLocationDetailsProps> = ({
  location,
  showContactDetails,
  editLocation
}) => {
  const mapRef: RefObject<HTMLDivElement> | null = useRef(null);

  useEffect(() => {
    location?.coordinates?.lng &&
      location?.coordinates?.lat &&
      initMapbox({
        container: mapRef,
        lng: location?.coordinates?.lng,
        lat: location?.coordinates?.lat
      });
  }, [location]);

  const addressString = parseAddress(location?.address, true)?.string || "";

  const localesRender = () => location?.locales.map((locale: any) => <Tag size="lg" key={locale.id}>{locale.name}</Tag>) // prettier-ignore
  const websiteRender = () =>
    location.website ? (
      <a
        rel="noreferrer"
        target="_blank"
        href={location?.website}
        className="LocationDetails-valueLink"
      >
        {location?.website}
      </a>
    ) : null;

  const locationsRender = () =>
    location.contacts.map((value: any) => {
      return (
        <Tooltip key={value?.contact?.id} message={value?.title?.name}>
          <TagButton onClick={event => showContactDetails(event, value?.contact?.id)}>
            {value?.contact?.first_name} {value?.contact?.last_name}
          </TagButton>
        </Tooltip>
      );
    });

  return (
    <>
      <div className="LocationDetails">
        <LocationDetailsHeader data={location} editLocation={editLocation} />
        <ModalInfoSectionWrap>
          <ModalInfoSection
            labelSize="sm"
            list={[
              {label: "Address", value: addressString},
              {label: "Locales", value: localesRender()},
              {label: "Type", value: location?.location_type?.name},
              {label: "Website", value: websiteRender()}
            ]}
          />

          <ModalInfoSection
            labelSize="sm"
            list={[
              {label: "Permit Required", value: location?.permit_required ? "Yes" : "No"},
              {label: "Rate", value: location?.rate},
              {label: "Contacts", value: locationsRender()}
            ]}
          />
        </ModalInfoSectionWrap>

        <ModalInfoSection labelSize="sm" list={[{label: "Notes", value: location?.notes}]} />
        <ModalInfoSection
          labelSize="sm"
          list={[
            {
              label: "Jobs",
              value: !!location?.jobs?.length ? <LocationDetailsJobs data={location?.jobs} /> : null
            }
          ]}
        />
        {location.coordinates?.lat && (
          <div id="location-details-map" className="LocationDetails-map" ref={mapRef} />
        )}
      </div>
    </>
  );
};
