/**
 * Validates phone number
 * @param {string} value - phone number to validate
 * @return {boolean} - true in case if validation was successful, false in opposite
 */
export const phoneValidation = <T>(value: T) => {
  if (typeof value !== "string") return false;
  // .replace(/[^\(\)\s\-\.\+\d]/g, "").replace(/\s\s+/g, " ")
  const newPhone = value.replace(/\D/g, "");
  return newPhone.length > 5 && newPhone.length < 25;
};

export const phoneCountryValidation = <T>(value: T) => {
  if (typeof value !== "string") return false;

  const newPhone = value.replace(/\D/g, "");
  return newPhone.length > 10;
};

/**
 * Validates name
 * @param {string} value - name to validate
 * @return {boolean} - true in case if validation was successful, false in opposite
 */
export const nameValidation = <T>(value: T) => {
  if (typeof value !== "string") return false;
  const lettersRx = new RegExp(/[^\p{L}'`\s-]+/gmu);
  const twoSpacesRx = new RegExp(/\s\s+/gm);
  return !lettersRx.test(value) && !twoSpacesRx.test(value) && value.length >= 2;
};

/**
 * @param {string | number | undefined} value - birth year to validate
 * @return {boolean} - true in case if validation was successful, false in opposite
 */
export const birthYearValidation = <T>(value: T): boolean => {
  if (typeof value === "string" && value.length !== 4) return false;
  return !(Number(value) < 1900 || Number(value) > 3000);
};

/**
 * Validates email
 * @param {string} value - email to validate
 * @return {boolean} - true in case if validation was successful, false in opposite
 */
export const emailValidation = <T>(value: T) => {
  if (typeof value !== "string") return false;
  const re = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return re.test(value);
};

/**
 * Validates name
 * @param {string} value - password to validate
 * @return {boolean} - true in case if validation was successful, false in opposite
 */

export const passwordValidation = <T>(value: T) => {
  if (typeof value !== "string") return false;
  const re = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])((?=.*?[0-9])|(?=.*?[#?!@$%^&*-])).{8,}$/g);
  return re.test(value);
};

/**
 * Validates name
 * @param {string} value - zipcode to validate
 * @return {boolean} - true in case if validation was successful, false in opposite
 */

export const zipcodeValidation = <T>(value: T): boolean => {
  if (typeof value !== "string") return false;
  const re = new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/g);
  return re.test(value);
};

/**
 * Validates name
 * @param {any} value - any value to validate
 * @return {boolean} - true in case if validation was successful, false in opposite
 */

export const checkEmptyValidation = <T>(value: T) => {
  const type = typeof value;
  let isValid = false;
  switch (type) {
    case "boolean":
      isValid = true;
      break;
    case "string":
      if (typeof value !== "string") return false;
      isValid = value!.length > 0;
      break;
    case "bigint":
      isValid = true;
      break;
    // FIXME
    // eslint-disable-next-line no-self-compare
    case "number":
      // eslint-disable-next-line no-self-compare
      isValid = value === value; // NaN is considered here to be kinda empty for number
      break;
    case "object":
      if (value !== null) isValid = typeof value === 'object' ? Object.keys(value).length > 0 : false;
      else if (Array.isArray(value)) isValid = value!.length > 0;
      break;
    case "function":
      isValid = true;
      break;
    default:
      break;
  }
  return isValid;
};
