import {FC, forwardRef} from "react";
import DatePickerPlugin, {ReactDatePickerProps} from "react-datepicker";
import moment from "moment";
import {Icon} from "components/Icon/Icon";

import "react-datepicker/dist/react-datepicker.css";
import "./Datepicker.scss";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";

interface iDatepickerProps extends Partial<Omit<ReactDatePickerProps, "value" | "onChange">> {
  onChange: (date: string) => void;
  value: string;
  label?: string;
  className?: string;
  errors?: string[];
  dateFormat?: string;
  withIcon?: boolean;
  inputValueCalculation?: string | number;
  isPortal?: boolean;
}

export const Datepicker: FC<iDatepickerProps> = ({
  dateFormat = "MM/DD/yyyy",
  errors = [],
  className = "",
  label = "",
  withIcon,
  value,
  onChange,
  inputValueCalculation,
  isPortal = false,
  ...props
}) => {
  if (!onChange) return null;

  const isError = !!errors.length ? "error" : "";

  const InputWithIcon = forwardRef((props: any, ref: any) => (
    <div ref={ref} className="Datepicker-inputWrap wrap" onClick={props.onClick}>
      {withIcon && <Icon icon="calendar" color="dark-gray" />}
      <input type="text" {...props} readOnly />
      {inputValueCalculation && (
        <TextComponent color="gray-10" size="13" weight="400">
          {inputValueCalculation}
        </TextComponent>
      )}
    </div>
  ));

  const handleChange = (date: Date | null) => onChange(date ? date.toString() : "");

  const portal = isPortal ? "root-date-picker-portal" : undefined;
  return (
    <div className={`Datepicker ${className} ${withIcon ? "withIcon" : ""}`}>
      {label && <span className={`Datepicker-label label ${isError}`}>{label}</span>}

      <DatePickerPlugin
        {...props}
        value={!!value ? moment(value).format(dateFormat) : value}
        className={`Datepicker-input input ${isError}`}
        onChange={handleChange}
        onYearChange={handleChange}
        onMonthChange={handleChange}
        customInput={<InputWithIcon />}
        selected={!!value ? moment(value).toDate() : undefined}
        portalId={portal}
        clearButtonClassName={"Datepicker-clearButton"}
      />
      {errors.map((error, i) => (
        <span key={i} className="Datepicker-error-message error-message">
          {error}
        </span>
      ))}
    </div>
  );
};
