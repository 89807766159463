import {FC} from "react";
import moment from "moment/moment";
import {
  iCallSheetsDeliveryConfirmation,
  iDeliveryInfo,
  tCallSheetContact
} from "api/callSheet/types";

interface iCellLatestDeliveryProps {
  isAgent?: boolean;
  confirmation?: iCallSheetsDeliveryConfirmation | null;
  contact: tCallSheetContact;
  deliveryInfo: iDeliveryInfo;
}

export const CellLatestDelivery: FC<iCellLatestDeliveryProps> = ({
  isAgent,
  contact,
  deliveryInfo,
  confirmation
}) => {
  const latestDelivery = () => {
    if (
      (confirmation?.custom && !confirmation?.custom?.sent_time) ||
      (!confirmation?.custom && !deliveryInfo?.sent_time)
    ) {
      return "Sending...";
    }

    if ((isAgent || !contact.agent) && deliveryInfo?.sent_time) {
      return moment(confirmation?.custom?.sent_time || deliveryInfo?.sent_time).format("MM/DD h:mmA"); // prettier-ignore
    }

    return "";
  };

  return (
    <td className="TrackingTableRow-cell">
      <span className="latestDelivery"> {latestDelivery()}</span>
    </td>
  );
};
