import {useState} from "react";
type Props<T> = {
  required?: boolean;
  options: Array<T> | null | undefined;
};

export const useCustomReactSelect = <T>(props?: Props<T>) => {
  const [value, setValue] = useState<any>(null);
  const [isError, setErr] = useState(false);
  const [options, setOptions] = useState(props?.options);

  const checkValidity = () => {
    const isErr = !props?.required ? true : !!value ? true : false;
    setErr(!isErr);
    return isErr;
  };

  const onChange = (value: any) => {
    setErr(false);
    setValue(value);
  };

  const onReset = () => setValue(null);

  return {
    value,
    onChange,
    checkValidity,
    isError,
    setOptions,
    onReset,
    options: options ?? undefined
  };
};
