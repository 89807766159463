import {tCurrencyRates} from "api/common/types";
import {AxiosRequestConfig} from "axios";
import {
  tEstimateExportInfoPostData,
  tPostEstimateLine,
  tPostEstimateLineDescription,
  tUpdateTotalDetails
} from "./types";

const jobEstimate = "job_estimate";

export const estimateApi = {
  getEstimateList: (jobId: number): AxiosRequestConfig => ({
    url: `job/${jobId}/estimates`
  }),
  getEstimateTypesList: (): AxiosRequestConfig => ({
    url: `${jobEstimate}/types`
  }),
  createEstimate: (data: {job_id: number; name: string; type_id: string}): AxiosRequestConfig => ({
    url: jobEstimate,
    method: "POST",
    data
  }),
  createEstimateFromTemplate: (
    templateId: number,
    data: {job_id: string; name: string}
  ): AxiosRequestConfig => ({
    url: `${jobEstimate}/template/${templateId}`,
    method: "POST",
    data
  }),
  getEstimate: (estimateId: number): AxiosRequestConfig => ({
    url: `${jobEstimate}/${estimateId}`
  }),
  syncEstimate: (estimateId: number, timestamp: number): AxiosRequestConfig => ({
    url: `${jobEstimate}/${estimateId}/sync/${timestamp}`
  }),
  getEstimateResources: (estimateId: number): AxiosRequestConfig => ({
    url: `${jobEstimate}/${estimateId}/resources`
  }),
  updateTotalDetails: (jobEstimateId: number, data: tUpdateTotalDetails): AxiosRequestConfig => ({
    url: `${jobEstimate}/${jobEstimateId}/total_details`,
    method: "POST",
    data
  }),
  estimateApprove: (
    jobEstimateId: number,
    data: {approved_at: string | null}
  ): AxiosRequestConfig => ({
    url: `${jobEstimate}/${jobEstimateId}/approved`,
    method: "POST",
    data
  }),
  renameEstimate: (jobEstimateId: number, data: {name: string}): AxiosRequestConfig => ({
    url: `${jobEstimate}/${jobEstimateId}/rename`,
    method: "POST",
    data
  }),
  duplicateEstimate: (jobEstimateId: number, data: {name: string}): AxiosRequestConfig => ({
    url: `${jobEstimate}/${jobEstimateId}/duplicate`,
    method: "POST",
    data
  }),
  revealAllCostValue: (jobEstimateId: number): AxiosRequestConfig => ({
    url: `${jobEstimate}/${jobEstimateId}/reveal_all`,
    method: "POST"
  }),
  deleteEstimate: (jobEstimateId: number): AxiosRequestConfig => ({
    url: `${jobEstimate}/${jobEstimateId}`,
    method: "DELETE"
  }),

  /**
   * Estimate Category value (group data)
   */
  updateGroupPercent: (
    job_estimate_category_value: number | string,
    data: {production_percent: number | null}
  ): AxiosRequestConfig => ({
    url: `job_estimate_category_value/${job_estimate_category_value}`,
    method: "POST",
    data
  }),

  /**
   * Estimate cost value (table line)
   */
  changeLineOrder: (
    estimateConstValId: number | string,
    data: {order: number}
  ): AxiosRequestConfig => ({
    url: `job_estimate_cost_value/${estimateConstValId}/order`,
    method: "POST",
    data
  }),
  createLine: (data: {category_value_id: number}): AxiosRequestConfig => ({
    url: `job_estimate_cost_value`,
    method: "POST",
    data
  }),
  updateLine: (
    job_estimate_cost_value: number | string,
    data: tPostEstimateLine
  ): AxiosRequestConfig => ({
    url: `job_estimate_cost_value/${job_estimate_cost_value}`,
    method: "POST",
    data
  }),
  updateLineDescription: (
    job_estimate_cost_value: number | string,
    data: tPostEstimateLineDescription
  ): AxiosRequestConfig => ({
    url: `job_estimate_cost_value/${job_estimate_cost_value}/description`,
    method: "POST",
    data
  }),
  deleteLine: (job_estimate_cost_value: number | string): AxiosRequestConfig => ({
    url: `job_estimate_cost_value/${job_estimate_cost_value}`,
    method: "DELETE"
  }),
  duplicateLine: (job_estimate_cost_value: number | string): AxiosRequestConfig => ({
    url: `job_estimate_cost_value/${job_estimate_cost_value}/duplicate`,
    method: "POST"
  }),
  clearLine: (job_estimate_cost_value: number | string): AxiosRequestConfig => ({
    url: `job_estimate_cost_value/${job_estimate_cost_value}/clear`,
    method: "POST"
  }),
  // Export budget
  getExportInfo: (job_estimate_id: number): AxiosRequestConfig => ({
    url: `${jobEstimate}/${job_estimate_id}/export`
  }),
  updateExportInfo: (
    job_estimate_id: number,
    data: Partial<tEstimateExportInfoPostData>
  ): AxiosRequestConfig => ({
    method: "POST",
    url: `${jobEstimate}/${job_estimate_id}/export`,
    data
  }),
  storeEstimateToDropbox: (job_estimate_id: number, data: {file: Blob}): AxiosRequestConfig => ({
    headers: {
      "Content-Type": "multipart/form-data"
    },
    method: "POST",
    url: `${jobEstimate}/${job_estimate_id}/save_to_dropbox`,
    data
  }),
  addEstimateCategory: (
    jobEstimateTypeId: number,
    data: {name: string; code?: string}
  ): AxiosRequestConfig => ({
    method: "POST",
    url: `job_estimate_type/${jobEstimateTypeId}/add_category`,
    data
  }),
  getCategoryCosts: (categoryId: number): AxiosRequestConfig => ({
    method: "GET",
    url: `/job_estimate_category/${categoryId}/costs`
  }),
  renameEstimateCost: (costId: number, data: {name: string}): AxiosRequestConfig => ({
    method: "POST",
    url: `/job_estimate_cost/${costId}`,
    data
  }),
  addNewCost: (categoryId: number, data: {code: string; name: string}): AxiosRequestConfig => ({
    method: "POST",
    url: `/job_estimate_category/${categoryId}/add_cost`,
    data
  }),
  removeEstimateCategory: (job_estimate_category_id: number): AxiosRequestConfig => ({
    method: "DELETE",
    url: `/job_estimate_category/${job_estimate_category_id}`
  }),
  setTaxDefault: (
    job_estimate_type_id: number,
    data: {tax_default: boolean}
  ): AxiosRequestConfig => ({
    method: "POST",
    url: `/job_estimate_type/${job_estimate_type_id}/tax_default`,
    data
  }),

  /**
   * Templates
   */

  getEstimateTemplateList: (): AxiosRequestConfig => ({
    method: "GET",
    url: `${jobEstimate}/templates`
  }),
  deleteEstimateTemplate: (template_id: number): AxiosRequestConfig => ({
    method: "DELETE",
    url: `${jobEstimate}/template/${template_id}`
  }),
  renameEstimateTemplate: (template_id: number, data: {name: string}): AxiosRequestConfig => ({
    method: "POST",
    url: `${jobEstimate}/template/${template_id}/rename`,
    data
  }),
  createNewTemplate: (jobEstimateId: number, data: {name: string}): AxiosRequestConfig => ({
    method: "POST",
    url: `${jobEstimate}/${jobEstimateId}/template`,
    data
  }),
  saveAsExistingTemplate: (jobEstimateId: number, templateId: number): AxiosRequestConfig => ({
    method: "POST",
    url: `${jobEstimate}/${jobEstimateId}/template/${templateId}`
  }),
  getEstimateTemplateCurrencyList: (templateId: number): AxiosRequestConfig => ({
    method: "GET",
    url: `${jobEstimate}/${templateId}/currency_rates`
  }),
  editEstimateTemplateCurrencyList: (
    templateId: number,
    data: tCurrencyRates[]
  ): AxiosRequestConfig => ({
    method: "POST",
    url: `${jobEstimate}/${templateId}/currency_rates`,
    data
  }),
  deleteEstimateCost: (costId: number): AxiosRequestConfig => ({
    method: "DELETE",
    url: `job_estimate_cost/${costId}`
  })
};
