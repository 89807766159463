import {createContext, FC, useContext} from "react";
import {useJobsList} from "./useJobs";

export type tUseJobsList = ReturnType<typeof useJobsList>;

export type tJobsListContext = tUseJobsList;

const JobsListContext = createContext<tJobsListContext | null>(null);
JobsListContext.displayName = "JobsListContext";

export const JobsListContextProvider: FC = ({children}) => {
  const value = useJobsList();

  return <JobsListContext.Provider value={value}>{children}</JobsListContext.Provider>;
};

export const useContextJobsList = () => {
  const context = useContext(JobsListContext);

  if (context === null) {
    throw new Error("<<< JobsContext >>> must be used within a JobsContextProvider");
  }

  return context as tJobsListContext;
};
