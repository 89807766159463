import {FC, FormEvent, useState} from "react";
import {Button} from "components/shared/Button/Button";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {eventBus} from "EventBus/EventBus";
import {useHttp} from "hooks/httpServices/useHttp";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {notValidForm} from "helpers/misc";
import {useContextJobGrid} from "../JobGridContext";
import {gridApi} from "api/jobs/grid";

type tCreateNewTemplateProps = {
  [key: string]: any;
};

export const CreateNewTemplate: FC<tCreateNewTemplateProps> = () => {
  const [showModal, setModal] = useState(false);
  return (
    <>
      <Button size="sm" onClick={() => setModal(true)}>
        Save as new template
      </Button>
      {showModal && <SaveNewTemplateForm setModal={setModal} />}
    </>
  );
};

type tSaveNewTemplateFormProps = {setModal: React.Dispatch<React.SetStateAction<boolean>>};

export const SaveNewTemplateForm: FC<tSaveNewTemplateFormProps> = ({setModal}) => {
  const {grid} = useContextJobGrid();
  const {call, isLoading} = useHttp();
  const FORM_ID = "add_new_grid_template_form_id";

  const form = {
    name: useTextInput()
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (notValidForm(form) || !grid?.id) return;

    try {
      const res = await call(gridApi.createTemplate(grid?.id, {name: form.name.value}));
      eventBus.dispatch("showToast", {text: res?.data?.message || "Success!"});
      setModal(false);
    } catch (error) {
      const {msg} = apiErrorHandler(error, form);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <ConfirmWindow
      show={true}
      title="Save as new template"
      subTitle="Name your template"
      cancelBtn={{text: "Cancel", type: "button"}}
      onClose={() => setModal(false)}
      confirmBtn={{text: "save", type: "submit", form: FORM_ID}}
      isLoading={isLoading}
    >
      <form className="AddGroup" onSubmit={onSubmit} id={FORM_ID}>
        <InputField {...form.name.inputProps} errors={form.name.errors} />

        {/* <TextComponent size="13" weight="400" color="gray-10" center className="mb-40">
          Store current groups and contacts to be loaded into another job.
        </TextComponent> */}
      </form>
    </ConfirmWindow>
  );
};
