import {FC, useState} from "react";
import {iImgWithFallbackProps, ImgWithFallback} from "components/shared/ImgWithFallback/ImgWithFallback"; // prettier-ignore
import {PeopleModals} from "components/shared/Modals/PeopleModals/PeopleModals";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {CompanyModals} from "components/shared/Modals/CompanyModals/CompanyModals";
import "./ContactBlock.scss";

type tPlaceholder = {
  placeholder: string;
  title?: never;
  subTitle?: never;
  contact?: never;
  imgUrl?: never;
  fallBackType?: never;
  fulWidth?: boolean;
  textCenter?: boolean;
  id?: number;
  type?: "company" | "person";
  // companyId?: number;
};
type tData = {
  title: string;
  subTitle: string;
  imgUrl: string;
  contact?: string;
  fallBackType?: iImgWithFallbackProps["fallBackType"];
  placeholder?: never;
  fulWidth?: boolean;
  textCenter?: never;
  id?: number;
  type?: "company" | "person";
  // companyId?: number;
};

type tContactBlockProps = tPlaceholder | tData;

export const ContactBlock: FC<tContactBlockProps> = (props: tContactBlockProps) => {
  const {updateJobDetails} = useContextJobDetails();
  const {placeholder, title, subTitle, imgUrl, contact, type = "person"} = props;
  const {fallBackType = "avatar", fulWidth, textCenter, id} = props;

  const [showModal, setShowModal] = useState(false);
  const closePeopleModal = () => setShowModal(false);

  return (
    <div
      className={`ContactBlock ${fulWidth ? "fulWidth" : ""} ${textCenter ? "textCenter" : ""}`}
      onClick={() => setShowModal(true)}
    >
      {placeholder && <div className="placeholder">{placeholder}</div>}

      {!placeholder && (
        <>
          <div className="imageWrap">
            <ImgWithFallback src={imgUrl || ""} fallBackType={fallBackType} className="avatar" />
          </div>
          <div className="ContactBlock-body">
            <h4 className="title">{title}</h4>
            <div className="subTitle">{subTitle}</div>
            {contact && <div className="contact">{contact}</div>}
          </div>
        </>
      )}

      {showModal && id && type === "person" && (
        <PeopleModals
          person={id}
          closePeopleModal={closePeopleModal}
          onEditCallback={updateJobDetails}
        />
      )}

      {showModal && id && type === "company" && (
        <CompanyModals
          company={id}
          closeCompaniesModal={closePeopleModal}
          onEditCallback={updateJobDetails}
        />
      )}
    </div>
  );
};
