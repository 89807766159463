import {FC, FormEvent} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {eventBus} from "EventBus/EventBus";
import {estimateApi} from "api/budget/estimate";
import {useContextJobBudget} from "../../context/JobBudgetContext";

interface iEstimateRenameProps {
  onClose: () => void;
}

export const EstimateRename: FC<iEstimateRenameProps> = ({onClose}) => {
  const {estimate, getEstimateList, estimateList} = useContextJobBudget();
  const {call, isLoading} = useHttp();

  const currEstimate = estimateList.find(item => item.id === estimate?.id);
  const name = useTextInput({value: currEstimate?.name});

  const onConfirm = async (event: FormEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (name.value.length < 3 || !estimate?.id) {
      name.setErrors(["The estimate name must be at least 3 characters long"]);
      return;
    }

    try {
      const {
        data: {message}
      } = await call(estimateApi.renameEstimate(estimate?.id, {name: name.value}));
      onClose();
      eventBus.dispatch("showToast", {text: message});
      getEstimateList();
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      name.setErrors([msg]);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <ConfirmWindow
      isLoading={isLoading}
      show={true}
      onConfirm={onConfirm}
      onClose={onClose}
      title="Rename Estimate"
      subTitle="Enter a new name"
      confirmBtn={{text: "Rename"}}
      cancelBtn={{text: "Cancel"}}
    >
      <form onSubmit={onConfirm}>
        <InputField {...name.inputProps} errors={name.errors} />
      </form>
    </ConfirmWindow>
  );
};
