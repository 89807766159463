export const manuallyEnteredContact = {
  name: "",
  position: "",
  email: {
    value: "",
    show: true
  },
  phone: {
    value: "",
    show: false
  },
  time: "",
  location: {
    id: 5,
    name: ""
  }
};
