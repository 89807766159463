import {FC, useState} from "react";
import {tCrewContact} from "api/jobCrewGroup/types";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import {PeopleModals} from "components/shared/Modals/PeopleModals/PeopleModals";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {useContextJobCrew} from "pages/Jobs/JobDetails/JobCrew/JobCrewContext";

type tShowDetailsContactProps = {
  crewContact: tCrewContact;
  isAgent?: boolean;
};

export const ShowDetailsContact: FC<tShowDetailsContactProps> = ({crewContact, isAgent}) => {
  const {contact, agent} = crewContact;
  const {jobCrewGroupList: {getJobCrewGroupList}, jobCrew: {crew: {id}}} = useContextJobCrew(); // prettier-ignore
  const {updateJobDetails} = useContextJobDetails();
  const [showModal, setShowModal] = useState(false);

  const updateData = async () => {
    await updateJobDetails();
    await getJobCrewGroupList(id);
  };
  return (
    <>
      <Tooltip message="View" position="top" onClick={() => setShowModal(true)}>
        <Button onClick={() => setShowModal(true)}>
          <Icon size="sm" color="gray" icon="eye" />
        </Button>
      </Tooltip>

      {showModal && (
        <PeopleModals
          person={isAgent && agent?.id ? agent?.id : contact?.id}
          closePeopleModal={() => setShowModal(false)}
          onEditCallback={updateData}
        />
      )}
    </>
  );
};
