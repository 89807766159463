import {ChangeEvent, FC} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {iField} from "api/fields/types";

interface iCustomSingleInputProps {
  field: any;
  onChange: (value: iField) => void;
}

export const CustomSingleInput: FC<iCustomSingleInputProps> = ({field, onChange}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange({...field, value: event.target.value});
  };

  return <InputField label={field.name} value={field?.value || ""} onChange={handleChange} />;
};
