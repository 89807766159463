import {FC, FormEvent} from "react";
import {CheckBoxGroupe} from "components/shared/FormComponents/CheckBoxGroupe/CheckBoxGroupe";
import {Datepicker} from "components/shared/FormComponents/Datepicker/Datepicker";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {RadioField} from "components/shared/FormComponents/RadioField/RadioField";
import {TextareaField} from "components/shared/FormComponents/TextareaField/TextareaField";
import {getDateFormat} from "helpers/dates";
import {notValidForm} from "helpers/misc";
import {tTeammatePostData} from "api/team/types";
import {useTeamMemberForm} from "./useTeamMemberForm";
import {iTeamMemberFormProps} from "./interfaces";
import "./TeamMemberForm.scss";

export const TEAM_FORM_ID = "team-form-id";

export const TeamMemberForm: FC<iTeamMemberFormProps> = props => {
  const {departmentsList, initData, submitCallback, roleList, officeLocationList, type} = props; // prettier-ignore
  const formData = useTeamMemberForm({roleList, officeLocationList, initData});
  const {email, first_name, last_name, departments, notes} = formData;
  const {role_id, office_location_id, title, start_date} = formData;

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (notValidForm(formData)) return;

    const data: tTeammatePostData = {
      email: email.value,
      first_name: first_name.value,
      last_name: last_name.value,
      office_location_id: +office_location_id?.value,
      role_id: +role_id.value,
      notes: notes.value,
      title: title?.value,
      start_date: getDateFormat(start_date.value, "timestamp"),
      departments: departments.selectedCheckBoxes.current.map(item => +item)
    };

    submitCallback(data, formData);
  };

  const notesLabel = type === "update" ? "Notes" : "Admin Notes";
  return (
    <form id={TEAM_FORM_ID} onSubmit={onSubmit} className={`TeamMemberForm ${type}`}>
      <InputField
        label="First Name"
        {...formData.first_name.inputProps}
        errors={formData.first_name.errors}
      />
      <InputField
        label="Last Name"
        {...formData.last_name.inputProps}
        errors={formData.last_name.errors}
      />
      <InputField
        label="Email Address (@versatile.com)"
        {...formData.email.inputProps}
        errors={formData.email.errors}
      />
      <InputField label="Title" {...formData.title.inputProps} errors={formData.title.errors} />

      <CheckBoxGroupe
        label="Associated Department(s)"
        checkBoxList={departmentsList}
        initValue={departments.selectedCheckBoxes.current}
        onChange={departments.checkBoxGroupeOnChange}
        errors={formData.departments.errors}
      />

      <RadioField {...role_id} title="Role" errors={role_id.errors} />
      <RadioField
        {...office_location_id}
        title="Office location"
        errors={office_location_id.errors}
      />

      <h4 className="TeamMemberForm-title">INTERNAL</h4>

      <Datepicker
        {...formData.start_date.inputProps}
        label="Start Date"
        placeholderText="mm/dd/yyyy"
        errors={formData.start_date.errors}
        className="TeamMemberDatePicker"
      />

      <TextareaField {...formData.notes.inputProps} label={notesLabel} />
    </form>
  );
};
