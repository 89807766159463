import {Suspense} from "react";
import {Navigate, Route, Routes} from "react-router-dom";

import {ToastMessage} from "components/shared/ToastMessage/ToastMessage";
import Account from "pages/Account/Account";
import {Search} from "components/Search/Search";
import {absRoutes} from "router";
import {Redirects} from "components/Redirects/Redirects";
import {allIn} from "helpers/misc";

import PageLayout from "./layouts/PageLayout/PageLayout";

import {Auth} from "pages/Auth/Auth";
import {Dashboard} from "pages/Dashboard/Dashboard";
import {Contacts} from "pages/Contacts/Contacts";
import Jobs from "pages/Jobs/Jobs";
import Locations from "pages/Locations/Locations";
import Team from "pages/Team/Team";
import CallSheetConfirm from "pages/CallSheetConfirm/CallSheetConfirm";

function App() {
  return (
    <>
      <Suspense fallback={null}>
        <Redirects />
        <Routes>
          <Route path={allIn(absRoutes.auth.main)} element={<Auth />} />
          <Route path={allIn(absRoutes.jobs.main)} element={<Jobs />} />
          <Route path={allIn(absRoutes.callSheetConfirm.main)} element={<CallSheetConfirm />} />
          <Route path="/" element={<PageLayout />}>
            <Route path={allIn(absRoutes.dashboard.main)} element={<Dashboard />} />
            <Route path={allIn(absRoutes.contacts.main)} element={<Contacts />} />
            <Route path={allIn(absRoutes.locations.main)} element={<Locations />} />
            <Route path={allIn(absRoutes.team.main)} element={<Team />} />
            <Route index element={<Navigate to={absRoutes.dashboard.main} />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>

      <Account />
      <ToastMessage />
      <Search />
    </>
  );
}

export default App;
