import {useEffect, useMemo, useState, MouseEvent, KeyboardEvent} from "react";
import {iStateOption} from "components/shared/FormComponents/TagsField/TagsField";
import {iUseTagsInput} from "./interfaces";
import {ActionMeta, InputActionMeta, MultiValue} from "react-select";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {titles as titlesApi} from "api/titles";
import {eventBus} from "EventBus/EventBus";
import {useDebounce} from "hooks/useDebounce";

export type tUseTagsFieldCreating = ReturnType<typeof useTagsFieldCreating>;

export const useTagsFieldCreating = (props?: iUseTagsInput) => {
  const creatingQuery = useHttp();
  const titlesListQuery = useHttp();

  const [menuRef, setMenuRef] = useState<any>(null);

  const [value, setValue] = useState<iStateOption[]>(props?.value || []);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<{id: number; name: string}[]>([]);

  const [errors, setErrors] = useState<string[]>(props?.errors || []);
  const [validateOnChange, setValidateOnChange] = useState(!!props?.validateOnChange);
  const [isValid, setIsValid] = useState(props?.isValid !== undefined ? props.isValid : true);
  const [isDirty, setIsDirty] = useState(false);
  const [isRequired] = useState(props?.isRequired !== undefined ? props.isRequired : true);

  const emptyErr = props?.emptyErrText ? props?.emptyErrText : "This field can't be empty";

  const debounceValue = useDebounce(inputValue, 250);

  useEffect(() => {
    if (debounceValue) {
      onSearch(debounceValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  const onSearch = async (value: string) => {
    try {
      const response = await titlesListQuery.call(
        titlesApi.get({search: value, search_type: "and"})
      );
      setOptions(response?.data?.data);
    } catch (error: any) {
      console.log(error?.message);
    }
  };

  useEffect(() => {
    const val = (value as iStateOption[]).map(({id}) => id);
    if (!validateOnChange) {
      props?.onChangeCallback?.(val);
      return;
    }
    checkValidity();
    props?.onChangeCallback?.(val);
  }, [value]); // eslint-disable-line

  const checkValidity = (val?: MultiValue<iStateOption>): boolean => {
    const newValue = val !== undefined ? val : value;
    if (isRequired && !newValue.length) {
      setErrors([emptyErr]);
      setIsValid(false);
      return false;
    }
    setIsValid(true);
    setErrors([]);
    return true;
  };
  /**
   * Interceptor to validate input on first blur if user was interacting with it
   * @callback {FocusEventHandler<HTMLInputElement>} onBlur
   * @returns void
   */
  const onBlur = () => {
    if (isDirty && !validateOnChange) {
      setValidateOnChange(true);
      checkValidity();
    }
    props?.onBlurCallback?.();
    setIsDirty(true);
  };

  const onChange = (newValue: any[], actionMeta: ActionMeta<any>) => {
    setValue(newValue);
    !isDirty && setIsDirty(true);
    checkValidity(newValue);
  };

  const onInputChange = (value: string, meta?: InputActionMeta) => {
    setInputValue(value);
  };

  const validateAndSet = (value: any[]) => {
    setValidateOnChange(true);
    checkValidity(value);
  };

  const onCreateTitle = async (
    event: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLDivElement>,
    value: string | undefined
  ) => {
    event.preventDefault();

    if (!value) return;

    try {
      const {
        data: {data}
      } = await creatingQuery.call(titlesApi.addNewTitle({name: value}));
      setOptions(prev => [...prev, data]);
      setValue(prev => [...prev, data]);
      setInputValue("");

      menuRef?.props?.onMenuClose();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const isChanged = () => {
    const propArray = props?.value || [];
    const values = value;
    if (!propArray?.length && values?.[0] && !values?.[0]?.id && !values?.[0]?.value) return false;
    if (propArray.length !== values.length) return true;
    return !!values.filter(
      value =>
        !propArray?.some(prop => prop.name === value.name && String(prop.id) === String(value.id))
    ).length;
  };

  const preparedValues = useMemo(() => value.map(({id}) => Number(id)), [value]);

  return {
    value,
    setValue: validateAndSet,
    preparedValues,
    options,
    onChange,
    onBlur,
    errors,
    setErrors,
    isValid,
    setIsValid,
    isDirty,
    setIsDirty,
    isChanged: isChanged(),
    checkValidity,
    validateOnChange,
    setValidateOnChange,
    setMenuRef,
    onCreateTitle,
    isCreating: creatingQuery.isLoading,
    titlesListLoading: titlesListQuery.isLoading,
    inputProps: {inputValue, value, onChange, onBlur, onInputChange}
  };
};
