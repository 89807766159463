import {FC} from "react";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {Button} from "components/shared/Button/Button";
import {useEstimateManagement} from "./useEstimateManagement";
import {Icon} from "components/Icon/Icon";
import {CurrencyRates} from "components/shared/CurrencyRates/CurrencyRates";
import {useContextTemplateLayout} from "pages/Jobs/JobSettings/JobSettingsTemplates/_common/TemplateLayoutContext";
import {useContextJobBudget} from "../../_context/JobBudgetContext";
import "./EstimateManagement.scss";

interface iEstimateManagementProps {}

export const EstimateManagement: FC<iEstimateManagementProps> = () => {
  const {id} = useContextTemplateLayout();
  const {resources, setResources, getEstimate} = useContextJobBudget();
  const {anchor, toggleMenu, modal, onClose, showModal} = useEstimateManagement();

  const updateResources = (list: any) => {
    getEstimate();
    setResources((prev: any) => {
      return {...prev, currency_rate: list || []};
    });
  };

  const modalRender = {
    currency: (
      <CurrencyRates
        onClose={onClose}
        type="estimateTemplate"
        templateId={id}
        customList={resources?.currency_rate}
        updateCallback={updateResources}
      />
    )
  };

  return (
    <>
      {modal && modalRender[modal]}

      <div className="EstimateManagement">
        <Button color="outline" size="sm" onClick={toggleMenu}>
          <Icon icon="menu" size="xsm" />
        </Button>

        <DropdownMenu
          className="EstimateManagement-dropdown"
          anchor={anchor}
          onClose={() => toggleMenu(null)}
        >
          <Button size="sm" width="fullWidth" onClick={() => showModal("currency")}>
            Currency rates
          </Button>
        </DropdownMenu>
      </div>
    </>
  );
};
