import {CrewTable} from "./CrewTable/CrewTable";
import {CrewHeader} from "./CrewHeader/CrewHeader";
import {JobCrewContextProvider, useContextJobCrew} from "./JobCrewContext";
import {Loading} from "components/shared/Loading/Loading";

import "./JobCrew.scss";

export default function JobCrew() {
  return (
    <JobCrewContextProvider>
      <JobCrewComponent />
    </JobCrewContextProvider>
  );
}

function JobCrewComponent() {
  const {jobCrew: { crew}, isLoading, jobCrewGroupList: {crewGroupList}, moveGroupSubmitting} = useContextJobCrew(); // prettier-ignore

  return (
    <div className="JobCrew">
      {(isLoading || moveGroupSubmitting) && <Loading type="inBlock" bgColor="transparent" />}
      {!!crew && (
        <>
          <CrewHeader />
          {!!crewGroupList?.length && <CrewTable />}
        </>
      )}
    </div>
  );
}
