import {useState, MouseEvent} from "react";

type tModal = "estimate" | "template";

export function useEstimateSelection() {
  const [modal, setModal] = useState<tModal | null>(null);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const toggleMenu = (event: MouseEvent<HTMLElement> | null) => setAnchor(event?.currentTarget || null); // prettier-ignore
  const closeModal = () => setModal(null);

  const showModal = (type: tModal) => {
    setAnchor(null);
    setModal(type);
  };

  return {anchor, toggleMenu, modal, closeModal, setModal, showModal};
}
