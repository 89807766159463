import {FC, useState} from "react";
import Select, {components, SelectInstance} from "react-select";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import "./SearchButtonField.scss";

interface iSearchButtonFieldProps {
  label?: string;
  placeholder?: string;
  options?: any[];
  title?: string;
  onChange: (data: any[]) => void;
  onInputChange: (inputValue: string) => void;
  value: any[];
  isLoading: boolean;
}

export interface StateOption {
  readonly value: string;
  readonly label: string;
}

export const SearchButtonField: FC<iSearchButtonFieldProps> = ({
  title = "",
  options = [],
  placeholder = "",
  label = "",
  onChange,
  onInputChange,
  value,
  isLoading
}) => {
  const [isShowInputField, setIsShowInputField] = useState(false);

  let selectRef: SelectInstance<StateOption> | null = null;

  const showSearchField = () => {
    setIsShowInputField(true);
    selectRef!.focus();
  };
  
  const hideSearchField = () => setIsShowInputField(false);

  const handleChange = (data: any) => {
    setIsShowInputField(false);
    onChange(data);
  };

  return (
    <div
      className={`SearchButtonField ${isShowInputField ? "SearchButtonField-container-show" : ""} `}
    >
      {label && <label className="SearchButtonField-label">{label}</label>}

      <Select
        ref={(ref: any) => {
          selectRef = ref;
        }}
        isMulti
        value={value}
        onChange={handleChange}
        isClearable={false}
        className="SearchButtonField"
        classNamePrefix="SearchButtonField-select"
        options={options}
        placeholder={placeholder}
        openMenuOnFocus={true}
        isLoading={isLoading}
        onInputChange={onInputChange}
        getOptionValue={(option: any) => option.id}
        getOptionLabel={(option: any) => option.name}
        components={{
          MultiValueLabel: () => <span />,
          MultiValueRemove: () => <span />,
          NoOptionsMessage: (props: any) => (
            <components.NoOptionsMessage {...props}>
              <span>No results found.</span>
            </components.NoOptionsMessage>
          )
        }}
        onBlur={hideSearchField}
      />

      <Button
        onClick={showSearchField}
        size="sm"
        color="outline"
        type="button"
        className={`SearchButtonField-button ${isShowInputField ? "hidden" : ""}`}
      >
        <Icon icon="add" size="sm" />
        {title}
      </Button>
    </div>
  );
};
