import {MouseEvent, useState} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {advanceApi} from "api/budget/advance";
import {useContextAdvance} from "../context";
import {getDateFormat} from "helpers/dates";

type tModal = "pay" | "send" | "preview";

export function useAdvanceStatusLine() {
  const {advanceDetails, setAdvanceDetails, createPdfPreviewData, } = useContextAdvance(); // prettier-ignore

  const saveAdvanceQuery = useHttp();
  const saveToDropboxQuery = useHttp();

  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [modal, setModal] = useState<tModal | null>(null);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [isSavingToDropbox, setIsSavingToDropbox] = useState(false);

  const toggleMenu = (event: MouseEvent<HTMLButtonElement> | null) => {
    setAnchor(event?.currentTarget || null);
  };

  const onShowPreview = async () => {
    setIsLoadingPreview(true);
    if (!advanceDetails) throw Error("Doesn't have Advance details");
    try {
      await createPdfPreviewData(advanceDetails);
      setModal("preview");
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingPreview(false);
    }
  };

  const toggleModal = (m: tModal | null) => {
    toggleMenu(null);
    setModal(m);
  };

  const onSaveAdvance = async () => {
    if (!advanceDetails) return;
    const {
      date,
      due_date,
      advance_contact,
      late_payment_daily_percent,
      amount_required,
      invoice_number,
      terms,
      bank_detail,
      term_condition
    } = advanceDetails;

    const payload = {
      ...(advance_contact?.id && {advance_contact_id: advance_contact.id}),
      ...(invoice_number && {invoice_number}),
      ...(terms && {terms}),
      ...(bank_detail && {bank_id: bank_detail.id}),
      ...(term_condition && {term_condition_id: term_condition.id}),
      date: getDateFormat(date, "timestamp"),
      due_date: getDateFormat(due_date, "timestamp"),
      late_payment_daily_percent,
      amount_required
    };

    try {
      const {data: {message, data}} = await saveAdvanceQuery.call(advanceApi.updateAdvanceDetails(advanceDetails.id, payload)); // prettier-ignore
      setAdvanceDetails(data);
      createPdfPreviewData(data);
      eventBus.dispatch("showToast", {text: message});
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {text: msg, type: "error"});
    }
  };

  const onSaveToDropbox = async () => {
    if (!advanceDetails) return;
    setIsSavingToDropbox(true);
    try {
      const pdfData = await createPdfPreviewData(advanceDetails);
      if (!pdfData?.url) return;
      const payload: any = new FormData();
      payload.append("file", pdfData.blob);
      const {data: {message}} = await saveToDropboxQuery.call(advanceApi.saveAdvanceToDropbox(advanceDetails.id, payload)); // prettier-ignore
      eventBus.dispatch("showToast", {text: message});
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {text: msg, type: "error"});
    } finally {
      setIsSavingToDropbox(false);
    }
  };

  return {
    anchor,
    toggleMenu,
    onShowPreview,
    modal,
    setModal,
    toggleModal,
    onSaveAdvance,
    saveAdvanceQuery,
    isLoadingPreview,
    onSaveToDropbox,
    saveToDropboxQuery,
    isSavingToDropbox
  };
}
