import {FC} from "react";
import {useContextProfile} from "context";
import {tIcon, Icon} from "components/Icon/Icon";
import {ImgWithFallback} from "components/shared/ImgWithFallback/ImgWithFallback";
import {useContextApp} from "context/AppContext/AppContext";
import "./AccountSidebar.scss";

interface iAccountSidebar {
  handleClick: (tab: number) => void;
  tab: number;
}

export const AccountSidebar: FC<iAccountSidebar> = ({tab, handleClick}) => {
  const {logout, userData} = useContextProfile();
  const {setAccountModal} = useContextApp();

  const handleLogout = () => {
    setAccountModal(false);
    logout();
  };

  // @ts-ignore
  const navigation: tIcon[] = ["notifications", "profile", "password", "settings"];

  return (
    <div className="AccountSidebar">
      <div className="AccountSidebar-header">
        <ImgWithFallback
          src={userData?.avatar_url || ""}
          fallBackType={"avatar"}
          className="AccountSidebar-header-avatar"
        />
        <h2 className="AccountSidebar-header-name">
          {userData?.first_name || ""} {userData?.last_name || ""}
        </h2>
        <p className="AccountSidebar-header-job">{userData?.title}</p>
        <p className="AccountSidebar-header-stage">
          {userData?.departments?.map(item => item?.name)?.join(", ")}
        </p>
      </div>

      <div className="AccountSidebar-body">
        {navigation.map((item, i) => {
          const isActive = tab === i;
          return (
            <button
              className={`AccountSidebar-button ${isActive ? "active" : ""}`}
              onClick={() => handleClick(i)}
              key={i}
            >
              <Icon
                size="md"
                icon={item}
                className="AccountSidebar-icon"
                color={isActive ? "white" : "dark-gray"}
              />
              {item}
            </button>
          );
        })}
      </div>

      <div className="AccountSidebar-footer">
        <button
          onClick={handleLogout}
          className="AccountSidebar-button AccountSidebar-button-logout"
        >
          <Icon icon="logout" size="md" color="dark-gray" className="AccountSidebar-icon" />
          Log Out
        </button>
      </div>
    </div>
  );
};
