import {createContext, FC, useContext, useLayoutEffect, useState} from "react";
import {gridApi} from "api/jobs/grid";
import {tJobGrid} from "api/jobs/grid/types";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {eventBus} from "EventBus/EventBus";
import {useContextTemplateLayout} from "../_common/TemplateLayoutContext";

export const useGetJobGrid = () => {
  const {id} = useContextTemplateLayout();
  const [grid, setGrid] = useState<tJobGrid | null>(null);
  const [currGridId, setCurrGridId] = useState<number | null>(null);
  const {call, isLoading} = useHttp();

  const getGrid = async () => {
    if (!id) return;
    try {
      const res = await call(gridApi.getGrid(id));
      setGrid(res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error getting grid!"});
    }
  };

  useLayoutEffect(() => {
    getGrid();
  }, [currGridId]); // eslint-disable-line

  return {grid, setGrid, isLoading, setCurrGridId};
};

type tJobGridContext = ReturnType<typeof useGetJobGrid>;
const JobGridContext = createContext<tJobGridContext | null>(null);
JobGridContext.displayName = "JobGridContext";
export const JobGridContextProvider: FC = ({children}) => (
  <JobGridContext.Provider value={useGetJobGrid()}>{children}</JobGridContext.Provider>
);

export const useContextJobGrid = () => {
  const context = useContext(JobGridContext);

  if (context === null) {
    throw new Error("<<< JobGridContext >>> must be used within a JobGridContextProvider");
  }

  return context as tJobGridContext;
};
