import {ChangeEvent, useState} from "react";
import {trackApi} from "api/budget/track";
import {tExpenseLineFileData} from "api/budget/track/types";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextTrack} from "pages/Jobs/JobDetails/JobBudget/Track/_context/TrackContext";
import {useContextExpenseList} from "pages/Jobs/JobDetails/JobBudget/Track/ExpenseList/context/ExpenseListContext";
import {useResponseType} from "pages/Jobs/JobDetails/JobBudget/Track/_common/useResponseType";
import {useCompressImg} from "hooks/useCompressImg";

export const useExpenseLineFile = () => {
  const {call, isLoading} = useHttp({disableTimeout: true});
  const {setTrackData} = useContextTrack();
  const {setExpenseList} = useContextExpenseList();
  const {isExpense, response_type} = useResponseType();
  const [submitting, setSubmitting] = useState(false);

  const {onChangeHandler} = useCompressImg();

  const attachFile = async (
    e: ChangeEvent<HTMLInputElement>,
    lineId: number,
    file_type: tExpenseLineFileData["file_type"]
  ) => {
    const fd: any = new FormData();
    const files = e?.target?.files;

    setSubmitting(true);

    if (file_type === "actual" && files?.length) {
      for (let i = 0; i < files?.length; i++) {
        const compressed = await onChangeHandler(files?.[i]);
        fd.append("file[]", compressed, files?.[i]?.name);
      }
    } else {
      const file = e?.target?.files?.[0];
      const compressed = await onChangeHandler(file);
      fd.append("file", compressed, file?.name);
    }

    fd.append("file_type", file_type);
    fd.append("response_type", response_type);

    try {
      const res = await call(trackApi.setFileToLine(lineId, fd));
      isExpense && setExpenseList(res?.data?.data);
      !isExpense && setTrackData(res?.data?.data);
      setSubmitting(false);
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
      setSubmitting(false);
    }
  };

  return {attachFile, isLoading: isLoading || submitting};
};
