import {FC, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {TextComponent} from "../../common/TextComponent/TextComponent";
import {JobTasksFilters} from "./JobTasksFilters/JobTasksFilters";
import {AddTaskGroupForm} from "../_modals/AddTaskGroupForm";
import {SaveAsNewTemplate} from "../_modals/SaveAsNewTemplate";
import {SaveAsExistingTemplate} from "../_modals/SaveAsExistingTemplate";
import {LoadTemplate} from "../_modals/LoadTemplate";
import "./JobTasksHeader.scss";

type tModals = "addGroup" | "saveAsNewTemplate" | "saveAsExistingTemplate" | "loadTemplate";
export const JobTasksHeader: FC = () => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const [modal, setModal] = useState<tModals | null>(null);
  const onClose = () => setModal(null);

  const onSetModal = (modalType: tModals) => {
    setModal(modalType);
    setMenuAnchor(null);
  };

  const modals: Record<tModals, any> = {
    addGroup: <AddTaskGroupForm onClose={onClose} />,
    saveAsNewTemplate: <SaveAsNewTemplate onClose={onClose} />,
    saveAsExistingTemplate: <SaveAsExistingTemplate onClose={onClose} />,
    loadTemplate: <LoadTemplate onClose={onClose} />
  };

  return (
    <div className="JobTasksHeader">
      <TextComponent uppercase className="title">
        Tasks
      </TextComponent>

      <JobTasksFilters />

      <Button
        color="outline"
        size="sm"
        className="addGroupBtn"
        onClick={() => onSetModal("addGroup")}
      >
        <Icon icon="add" color="black" size="xsm" />
        Group
      </Button>

      <Button onClick={showMenu} size="sm" color="outline">
        <Icon icon="menu" size="xsm" />
      </Button>

      <DropdownMenu
        anchor={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        className="JobTasksHeader-dropdown"
      >
        <Button size="sm" onClick={() => onSetModal("saveAsNewTemplate")}>
          SAVE AS NEW TEMPLATE
        </Button>
        <Button size="sm" onClick={() => onSetModal("saveAsExistingTemplate")}>
          SAVE AS EXISTING TEMPLATE
        </Button>
        <Button size="sm" onClick={() => onSetModal("loadTemplate")}>
          Load Template
        </Button>
      </DropdownMenu>

      {!!modal && modals[modal]}
    </div>
  );
};
