import {FC, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {ListLineLayout} from "../../../_common/ListLineLayout";
import {creditCardAndPayroll} from "api/budget/creditCardAndPayroll";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextTrack} from "../../../_context/TrackContext";
import {tCreditCard} from "api/budget/creditCardAndPayroll/types";

type tCardLineProps = {
  onEditCallback: () => void;
  card: tCreditCard;
};

export const CardLine: FC<tCardLineProps> = ({onEditCallback, card}) => {
  const {lists: {payIds: {setPayIdList}}} = useContextTrack(); // prettier-ignore
  const {call, isLoading} = useHttp();
  const [modal, setModal] = useState<"delete" | null>(null);
  const closeModal = () => setModal(null);
  const onRemove = async () => {
    try {
      await call(creditCardAndPayroll?.deleteCreditCard(card?.id));

      setPayIdList(prev => {
        if (!prev) return [];
        return prev?.map(item => {
          if (item?.target_type === "credit_card") {
            const newItems = item?.target_items?.filter(item => item?.id !== card?.id);
            return {...item, target_items: newItems};
          }

          return item;
        });
      });

      setModal(null);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const {type, holder_initials, last_four_digits} = card;
  const name = `${type?.name ?? ""} ${holder_initials ?? ""} ${last_four_digits ?? ""}`;
  return (
    <ListLineLayout>
      <TextComponent weight="500" size="14" uppercase>
        {name}
      </TextComponent>

      <Button onClick={onEditCallback}>
        <Icon icon="edit" size="md" color="gray" />
      </Button>
      <Button onClick={() => setModal("delete")}>
        <Icon icon="delete" size="md" color="gray" />
      </Button>

      <ConfirmWindow
        title="Delete credit card?"
        subTitle={`This credit card will be permanently deleted.`}
        show={modal === "delete"}
        onClose={closeModal}
        onConfirm={onRemove}
        isLoading={isLoading}
        confirmBtn={{text: "Delete", color: "red"}}
        cancelBtn={{text: "cancel"}}
      />
    </ListLineLayout>
  );
};
