import {FC} from "react";
import {tTrack, tTrackCategoriesItem} from "api/budget/track/types";
import {StyleSheet, Text, View, Font} from "@react-pdf/renderer"; // @ts-ignore
import Helvetica700 from "assets/fonts/HelveticaNeue/HelveticaNeue-Bold.ttf"; // @ts-ignore
import Helvetica500 from "assets/fonts/HelveticaNeue/HelveticaNeue-Medium.ttf"; // @ts-ignore
import Helvetica400 from "assets/fonts/HelveticaNeue/HelveticaNeue.ttf";
import {getUsMoneyFormat} from "helpers/formattingData";
import {SectionTitle} from "../../PdfTemplates/a4/SectionTitle";

Font.register({
  family: "Helvetica500",
  src: Helvetica500
});
Font.register({
  family: "Helvetica400",
  src: Helvetica400
});
Font.register({
  family: "Helvetica700",
  src: Helvetica700
});

interface iProductionCostsProps {
  categories: tTrackCategoriesItem[];
  fees: tTrack["payroll_fees"];
  total: number;
}

export const ProductionCosts: FC<iProductionCostsProps> = ({categories, total, fees}) => {
  return (
    <View style={styles.container}>
      <SectionTitle>Production costs</SectionTitle>
      {categories.map(({category, category_total}) => {
        return (
          <View key={category.id} style={styles.row}>
            <Text style={styles.code}>{category.code}</Text>
            <Text style={styles.name}>{category.name}</Text>
            <Text style={styles.total}>{getUsMoneyFormat(category_total.total_actual)}</Text>
          </View>
        );
      })}

      {Object.entries(fees).map(([key, value]) => {
        return (
          <View key={key} style={styles.row}>
            <Text style={styles.code} />
            <Text style={styles.nameFee}>{key.split("_").join(" ")}</Text>
            <Text style={styles.total}>{getUsMoneyFormat(value.total_actual)}</Text>
          </View>
        );
      })}

      <View style={styles.totalRow}>
        <Text style={styles.totalRowTitle}>Total costs</Text>
        <Text style={styles.totalRowValue}>{getUsMoneyFormat(total)}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16
  },
  row: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 4
  },
  code: {
    display: "flex",
    flexDirection: "row",
    width: "10%",
    fontSize: 11
  },
  name: {
    display: "flex",
    flexDirection: "row",
    width: "50%",
    fontSize: 11,
    fontFamily: "Helvetica500"
  },
  nameFee: {
    display: "flex",
    flexDirection: "row",
    width: "50%",
    fontSize: 11,
    fontFamily: "Helvetica500",
    textTransform: "capitalize"
  },
  total: {display: "flex", flexDirection: "row", width: "40%", textAlign: "right", fontSize: 11},
  totalRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 8,
    marginBottom: 16
  },
  totalRowTitle: {
    fontFamily: "Helvetica700",
    fontSize: 13,
    textTransform: "uppercase"
  },
  totalRowValue: {
    fontFamily: "Helvetica700",
    fontSize: 13,
    textAlign: "right",
    width: "150px"
  }
});
