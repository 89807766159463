import {FC} from "react";
import {StyleSheet, Text, View} from "@react-pdf/renderer";
import {SectionTitle} from "./SectionTitle";
import {iJobDetailsDataReturn} from "api/jobs/types";

interface iConfirmationSectionProps {
  jobDetailsData: iJobDetailsDataReturn;
}

export const ConfirmationSection: FC<iConfirmationSectionProps> = ({jobDetailsData}) => {
  return (
    <View>
      <SectionTitle>Confirmation</SectionTitle>
      <Text style={styles.text}>
        I have reviewed this estimate from Versatile Studios and approve as is.
      </Text>
      <Text style={styles.text}>
        An advance on estimated costs is due no later than 5 days after estimate approval.
      </Text>

      <View style={styles.signBox}>
        <View style={styles.signBoxUpper}>
          <Text style={styles.signedText}>Signed:</Text>
          <Text style={styles.signedText}>For and on behalf of: {jobDetailsData.client.name}</Text>
        </View>
        <View style={styles.signBoxName}>
          <Text style={styles.signedText}>Name</Text>
        </View>
        <View style={styles.signBoxDate}>
          <Text style={styles.signedText}>Date</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: "Helvetica400",
    fontSize: "10px",
    color: "#221F20",
    marginBottom: 12
  },
  signBox: {
    borderRadius: 5,
    border: "1px solid #808080",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  signBoxUpper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    height: "60px",
    padding: 4
  },
  signBoxName: {
    width: "35%",
    height: "38px",
    padding: 4,
    borderTop: "1px solid #808080"
  },
  signBoxDate: {
    width: "65%",
    height: "38px",
    padding: 4,
    borderTop: "1px solid #808080",
    borderLeft: "1px solid #808080"
  },
  signedText: {
    textTransform: "uppercase",
    fontSize: "8px",
    fontFamily: "Helvetica500"
  }
});
