import {FC} from "react";
import {tExpenseListItem} from "api/budget/track/types";
import {Select} from "components/shared/FormComponents/Select/Select";
import {useChangeExpenseInputValue} from "../../../TrackWA/TableTrackWA/Category/CategoryBody/ExpenseRow/_common/useChangeExpenseInputValue";
import {TotalCellWrap} from "../../../TrackWA/TableTrackWA/Category/CategoryBody/ExpenseRow/_common/TotalCellWrap";
import {TrackLineInput} from "../../../TrackWA/TableTrackWA/Category/CategoryBody/ExpenseRow/_common/TrackLineInput";

type tETWorkingTotalProps = {
  id: number;
  value: string;
  selectValue: string;
  trackLine: tExpenseListItem;
};

export const ETWorkingTotal: FC<tETWorkingTotalProps> = ({id, value, selectValue, trackLine}) => {
  const {isLoading, trackInputProps, select} = useChangeExpenseInputValue({
    id: id,
    type: "working",
    defaultValue: value || "",
    defaultSelectValue: selectValue,
    estimate: trackLine?.estimate_id ? String(trackLine?.estimate_id) : "default"
  });

  const isAmount = !!value || !!selectValue;
  return (
    <TotalCellWrap
      isValue={isAmount}
      showInputField={trackInputProps.showInputField}
      trackLine={trackLine}
      className="ETWorkingTotal"
      type="working"
    >
      <TrackLineInput {...trackInputProps} placeholder={"$0.00"} isLoading={isLoading}>
        <Select {...select.inputProps} />
      </TrackLineInput>
    </TotalCellWrap>
  );
};
