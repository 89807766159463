import {AxiosRequestConfig} from "axios";
import {
  iCreateCalendarFromTemplateParams,
  iCreateEventFormData,
  iGetCalendarSheetParams,
  tTemplateEventPayload
} from "./types";

const JOB = "job";
const JOB_CALENDAR = "job_calendar";

export const jobsCalendarApi = {
  getJobCalendar: (job_id: number): AxiosRequestConfig => ({
    url: `${JOB}/${job_id}/calendar`
  }),
  createJobCalendar: (data: {job_id: number; name: string}): AxiosRequestConfig => ({
    method: "post",
    url: JOB_CALENDAR,
    data
  }),
  // Display the specified resource.
  getJobCalendarDetails: (calendar_id: number): AxiosRequestConfig => ({
    url: `${JOB_CALENDAR}/${calendar_id}`
  }),
  updateJobCalendar: (
    calendar_id: number,
    data: {name: string; locked: boolean}
  ): AxiosRequestConfig => ({
    method: "post",
    url: `${JOB_CALENDAR}/${calendar_id}`,
    data
  }),
  deleteCalendar: (calendar_id: number): AxiosRequestConfig => ({
    url: `${JOB_CALENDAR}/${calendar_id}`,
    method: "delete"
  }),
  duplicateCalendar: (
    calendar_id: number,
    data?: {name?: string; locked?: boolean}
  ): AxiosRequestConfig => ({
    method: "post",
    url: `${JOB_CALENDAR}/${calendar_id}/duplicate`,
    data
  }),

  createCalendarFromTemplate: (
    template_id: number,
    data: iCreateCalendarFromTemplateParams
  ): AxiosRequestConfig => ({
    method: "post",
    url: `${JOB_CALENDAR}/template/${template_id}`,
    data
  }),
  getCalendarSheet: (
    job_calendar: number,
    params?: iGetCalendarSheetParams
  ): AxiosRequestConfig => ({
    url: `${JOB_CALENDAR}/${job_calendar}/sheet`,
    params
  }),
  createTemplateFromCalendar: (job_calendar: number, data: {name: string}): AxiosRequestConfig => ({
    method: "post",
    url: `${JOB_CALENDAR}/${job_calendar}/template`,
    data
  }),
  updateTemplateFromCalendar: (
    job_calendar: number,
    template: number,
    data?: {name: string}
  ): AxiosRequestConfig => ({
    method: "post",
    url: `${JOB_CALENDAR}/${job_calendar}/template/${template}`,
    data
  }),

  exportJobCalendar: (job_calendar: number, type: "pdf" | "ics"): AxiosRequestConfig => ({
    url: `${JOB_CALENDAR}/${job_calendar}/export/${type}`
  }),
  // events api
  createNewEvent: (data: iCreateEventFormData): AxiosRequestConfig => ({
    method: "post",
    url: `${JOB_CALENDAR}/event`,
    data
  }),
  showCalendarEvent: (job_calendar_event: number): AxiosRequestConfig => ({
    url: `${JOB_CALENDAR}/event/${job_calendar_event}`
  }),
  updateJobCalendarEvent: (
    job_calendar_event: number,
    data: iCreateEventFormData
  ): AxiosRequestConfig => ({
    method: "POST",
    url: `${JOB_CALENDAR}/event/${job_calendar_event}`,
    data
  }),
  deleteJobCalendarEvent: (job_calendar_event: number): AxiosRequestConfig => ({
    method: "DELETE",
    url: `${JOB_CALENDAR}/event/${job_calendar_event}`
  }),
  exportJobCalendarEvent: (
    job_calendar_event: number,
    type: "pdf" | "ics" = "ics"
  ): AxiosRequestConfig => ({
    url: `${JOB_CALENDAR}/event/${job_calendar_event}/export/${type}`
  }),
  saveToDropbox: (job_calendar_id: number, data: {file: any}): AxiosRequestConfig => ({
    method: "post",
    url: `${JOB_CALENDAR}/${job_calendar_id}/save_to_dropbox`,
    data
  }),
  /**
   * Templates
   */
  getJobCalendarTemplates: (): AxiosRequestConfig => ({
    url: `${JOB_CALENDAR}/templates`
  }),
  deleteJobCalendarTemplate: (template_id: number): AxiosRequestConfig => ({
    method: "DELETE",
    url: `${JOB_CALENDAR}/template/${template_id}`
  }),
  renameCalendarTemplate: (template_id: number, data: {name: string}): AxiosRequestConfig => ({
    method: "POST",
    url: `${JOB_CALENDAR}/template/${template_id}/rename`,
    data
  }),
  getTemplateInfo: (template_id: number, timezone: string): AxiosRequestConfig => ({
    url: `${JOB_CALENDAR}/template/${template_id}`,
    params: {timezone}
  }),
  createTemplateEvent: (template_id: number, data: tTemplateEventPayload): AxiosRequestConfig => ({
    method: "POST",
    url: `${JOB_CALENDAR}/template/${template_id}/event`,
    data
  }),
  updateTemplateEvent: (
    job_calendar_event_id: number,
    data: tTemplateEventPayload
  ): AxiosRequestConfig => {
    const url = `${JOB_CALENDAR}/template/event${
      job_calendar_event_id ? "/" + job_calendar_event_id : ""
    }`;

    return {
      url,
      method: "POST",
      data
    };
  },
  deleteJobCalendarTemplateEvent: (job_calendar_event: number): AxiosRequestConfig => ({
    method: "DELETE",
    url: `${JOB_CALENDAR}/template/event/${job_calendar_event}`
  })
};
