import {useEffect, useState} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {tCalendarTemplateInfo} from "api/jobs/calendar/types";
import {jobsCalendarApi} from "api/jobs/calendar";
import {createDatesByInterval, tCalendarTemplateEvent} from "../helpers";
import moment from "moment";

interface iUseTemplateCalendarProps {
  id: number;
  onClose: () => void;
}

export function useTemplateCalendar({id, onClose}: iUseTemplateCalendarProps) {
  const firstDay = moment().startOf("week");

  const getTemplateInfoQuery = useHttp();
  const [calendarInfo, setCalendarInfo] = useState<tCalendarTemplateInfo | null>(null);
  const [events, setEvents] = useState<tCalendarTemplateEvent[]>([]);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  useEffect(() => {
    getTemplateInfoQuery
      .call(jobsCalendarApi.getTemplateInfo(id, timezone))
      .then(({data: {data}}) => {
        const newSheets = data.events.map((event: tCalendarTemplateEvent) =>
          createDatesByInterval(event)
        );

        setEvents(newSheets);
        setCalendarInfo({
          ...data,
          events: newSheets
        });
      });
  }, []); // eslint-disable-line

  return {
    calendarInfo,
    getTemplateInfoQuery,
    setCalendarInfo,
    onClose,
    firstDay,
    events,
    setEvents
  };
}
