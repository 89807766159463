import {FC} from "react";
import {CallSheetBuildHeader} from "./CallSheetBuildHeader/CallSheetBuildHeader";
import {CallSheetBuildLocations} from "./CallSheetBuildLocations/CallSheetBuildLocations";
import {CallSheetBuildSchedule} from "./CallSheetBuildSchedule/CallSheetBuildSchedule";
import {CallSheetBuildContacts} from "./CallSheetBuildContacts/CallSheetBuildContacts";
import {useContextJobCallSheet} from "../context/JobCallSheetContext";
import {Loading} from "components/shared/Loading/Loading";
import "./CallSheetBuild.scss";

type tCallSheetBuildProps = {
  [key: string]: any;
};

export const CallSheetBuild: FC<tCallSheetBuildProps> = () => {
  const {isLoading, callSheetInfo} = useContextJobCallSheet();

  return (
    <div className={`CallSheetBuild ${callSheetInfo ? "" : "empty"}`}>
      {isLoading && <Loading type="inBlock" />}

      {callSheetInfo && !isLoading && (
        <>
          <CallSheetBuildHeader />
          <CallSheetBuildLocations />
          <CallSheetBuildSchedule />
          <CallSheetBuildContacts />
        </>
      )}
    </div>
  );
};
