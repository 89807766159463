import {FC} from "react";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import "./CrewTableHeader.scss";

type tCrewTableHeaderProps = {
  [key: string]: any;
};

const headerTitles = [
  "", //sidebar
  "Position",
  "Hold Status",
  "Hold Dates",
  "Rate",
  "Phone",
  "Email",
  "Billing",
  "Notes",
  "Dietary Restriction",
  "" //actions
];

export const CrewTableHeader: FC<tCrewTableHeaderProps> = () => {
  return (
    <>
      {headerTitles.map((item, i) => {
        return (
          <TextComponent key={i} size="13" weight="500" className="CrewTableHeaderT-cell">
            {item}
          </TextComponent>
        );
      })}
    </>
  );
};
