import {FC} from "react";
import {useContextAdvance} from "pages/Jobs/JobDetails/JobBudget/Advance/context";
import {AdvanceStatusLine} from "pages/Jobs/JobDetails/JobBudget/Advance/AdvanceStatusLine/AdvanceStatusLine";
import {AdvanceInvoiceBuild} from "pages/Jobs/JobDetails/JobBudget/Advance/AdvanceInvoiceBuild/AdvanceInvoiceBuild";
import {AdvanceInvoicePreview} from "pages/Jobs/JobDetails/JobBudget/Advance/AdvanceInvoicePreview/AdvanceInvoicePreview";
import {Loading} from "components/shared/Loading/Loading";

import "./AdvanceDocument.scss";

export const AdvanceDocument: FC = () => {
  const {advanceDetails, advanceList, advanceDetailsQuery} = useContextAdvance();
  const hasAdvance = !!advanceList.length;
  const isApproved = !!advanceDetails?.status;

  if (!hasAdvance) return null;

  return (
    <div className="AdvanceDocument">
      {advanceDetailsQuery.isLoading && <Loading type="inBlock" />}
      <AdvanceStatusLine />

      {!isApproved && <AdvanceInvoiceBuild />}
      {isApproved && <AdvanceInvoicePreview />}
    </div>
  );
};
