import {FC} from "react";
import {Icon} from "components/Icon/Icon";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import tooltipText from "./NotificationsMessage";
import {SwitchInput} from "components/shared/FormComponents/SwitchInput/SwitchInput";
import {tNotificationsSettingsPostData} from "api/notifications/types";
import { tBooleanDigits } from "api/common/types";

interface iAccountJobUpdate {
  jobUpdates: tBooleanDigits;
  updateNotificationHandler: (data: Partial<tNotificationsSettingsPostData>) => Promise<void>;
  [key: string]: any;
};

export const AccountJobUpdate: FC<iAccountJobUpdate> = ({
  jobUpdates,
  updateNotificationHandler
}) => {
  return (
    <div className="AccountNotifications-group">
      <div className="AccountNotifications-group-header">
        <h4 className="AccountNotifications-title">
          Job Updates
          <Tooltip
            classname="AccountNotifications-tooltip"
            message={tooltipText.jobUpdate}
            position="right"
          >
            <Icon
              icon="info"
              color="dark-gray"
              size="14"
              className="AccountNotifications-icon-info"
            />
          </Tooltip>
        </h4>

        <SwitchInput
          checked={jobUpdates ? true : false}
          value={String(jobUpdates)}
          onChange={e => updateNotificationHandler({job_updates: e.target.checked ? 1 : 0})}
        />
      </div>

      <div className="AccountNotifications-group-info">
        Assignments and updates to assigned jobs
      </div>
    </div>
  );
};
