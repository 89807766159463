import {FC} from "react";
import {tEstimateData} from "api/budget/estimate/types";
import {StyleSheet, Text, View} from "@react-pdf/renderer";
import {SectionTitle} from "./SectionTitle";
import {getUsMoneyFormat} from "helpers/formattingData";

interface iTotalEstimateSectionProps {
  estimate: tEstimateData;
}

export const TotalEstimateSection: FC<iTotalEstimateSectionProps> = ({estimate}) => {
  const values = [
    {label: "Estimated Production Costs", value: estimate.budgeted_costs},
    {label: "Production % on Budgeted Expenses", value: estimate.production_total},
    {label: "Insurance % on Budgeted Expenses", value: estimate.insurance},
    {label: "Green", value: estimate.green},
    {label: `Sales Tax (${estimate.sales_tax_percent}%)`, value: estimate.sales_tax}
  ];

  return (
    <View>
      <SectionTitle>Totals</SectionTitle>
      <View style={styles.feeSection}>
        {values.map(({label, value}) => {
          const isGreen = label === "Green";
          return (
            <View key={label} style={styles.feeRow}>
              <View style={isGreen ? styles.labelGreen : styles.label}>
                <Text>{label}</Text>
              </View>
              <View style={isGreen ? styles.valueGreen : styles.value}>
                <Text>{getUsMoneyFormat(value)}</Text>
              </View>
            </View>
          );
        })}

        <View style={styles.totalEstimateRow}>
          <Text>TOTAL ESTIMATE</Text>
          <Text style={styles.grandTotalValue}>{getUsMoneyFormat(estimate.grand_total)}</Text>
        </View>

        <View style={styles.feeRow}>
          <View style={styles.label}>
            <Text>Total Advance Required ({estimate.advance_required_percent}%)</Text>
          </View>
          <View style={styles.value}>
            <Text>{getUsMoneyFormat(estimate.advance_required)}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  label: {
    width: "70%",
    fontFamily: "Helvetica500",
    fontSize: "10px"
  },
  labelGreen: {
    width: "70%",
    fontFamily: "Helvetica500",
    fontSize: "10px",
    color: "#029457"
  },
  value: {
    width: "30%",
    fontFamily: "Helvetica500",
    fontSize: "10px",
    textAlign: "right"
  },
  valueGreen: {
    width: "30%",
    fontFamily: "Helvetica500",
    fontSize: "10px",
    textAlign: "right",
    color: "#029457"
  },
  feeSection: {
    width: "100%"
  },
  feeRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 6
  },
  totalEstimateRow: {
    margin: "9px 0",
    fontSize: "12px",
    textTransform: "uppercase",
    fontFamily: "Helvetica700",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  grandTotalValue: {
    fontSize: "15px",
    textTransform: "uppercase",
    fontFamily: "Helvetica700"
  }
});
