import {FC, Fragment} from "react";
import {FormWrap} from "components/shared/FormWrap/FormWrap";
import {TimeField} from "components/shared/FormComponents/DateRangePicker/TimeField";
import {RadioField} from "components/shared/FormComponents/RadioField/RadioField";
import {Select} from "components/shared/FormComponents/Select/Select";
import {CheckBoxGroupe} from "components/shared/FormComponents/CheckBoxGroupe/CheckBoxGroupe";
import {useBatchEdit} from "./useBatchEdit";
import {Icon} from "components/Icon/Icon";
import {SenderRecipientToolbar} from "../../CallSheetSenderRecipients/SenderRecipientToolbar/SenderRecipientToolbar";
import {SenderRecipientItem} from "../../CallSheetSenderRecipients/SenderRecipientItem/SenderRecipientItem";
import {tCallSHeetGroupContacts} from "api/callSheet/types";
import {useContextJobCallSheet} from "../../context/JobCallSheetContext";

import "./BatchEdit.scss";

interface iBatchEditProps {
  data: tCallSHeetGroupContacts;
  onClose: () => void;
}

export const BatchEdit: FC<iBatchEditProps> = ({data, onClose}) => {
  const {callSheetInfo} = useContextJobCallSheet();

  const {
    onCheckAll,
    formData,
    radioControl,
    onChangeControl,
    handleSubmit,
    time,
    onChangeTime,
    contacts,
    onSelectContact,
    isLoading
  } = useBatchEdit({data, onClose});

  const isCheckedAll = contacts.every((contact: any) => contact.selected);
  const isWarning = contacts.some((contact: any) => contact.selected && contact.agent && (radioControl.time || radioControl.location)); // prettier-ignore

  return (
    <div className="BatchEdit">
      <FormWrap
        idForm="batch-edit"
        submitTitle="save"
        headerTitle="batch edit"
        subtitle="select contacts and details to edit"
        type="titleCenterAndOneBtn"
        isLoading={isLoading}
      >
        <form id="batch-edit" onSubmit={handleSubmit}>
          <SenderRecipientToolbar
            showExpander={false}
            onCheckAll={onCheckAll}
            isCheckedAll={isCheckedAll}
          />

          <div className="BatchEdit-fields">
            {contacts?.map((contact, index) => {
              return (
                <Fragment key={contact.id}>
                  <SenderRecipientItem
                    id={contact.id}
                    name={contact.name}
                    position={contact.position}
                    onSelectContact={onSelectContact}
                    selected={contact.selected}
                  />
                  {contact?.agent && (
                    <SenderRecipientItem
                      id={contact.id}
                      name={contact.agent.name}
                      position="Agent"
                      onSelectContact={onSelectContact}
                      selected={contact.selected}
                    />
                  )}
                </Fragment>
              );
            })}
          </div>

          <h4 className="BatchEdit-title">SELECT DETAILS TO EDIT</h4>
          <div className="BatchEdit-grid">
            <div className="BatchEdit-grid-item">
              <CheckBoxGroupe
                onChange={onChangeControl}
                inputProps={{
                  name: "time"
                }}
                checkBoxList={[{id: 1, name: "Time"}]}
              />
              <TimeField
                type="start"
                time={time}
                onChange={onChangeTime}
                disabled={!radioControl.time}
              />
            </div>
            <div className="BatchEdit-grid-item">
              <CheckBoxGroupe
                inputProps={{
                  name: "location"
                }}
                onChange={onChangeControl}
                checkBoxList={[{id: 2, name: "Location"}]}
              />
              <Select
                {...formData.location.inputProps}
                targetKeys={{
                  value: "location_id",
                  label: "name"
                }}
                options={callSheetInfo?.attributes?.locations || []}
                disabled={!radioControl.location}
                errors={formData.location.errors}
                canSelectEmpty={true}
              />
            </div>
            <div className="BatchEdit-grid-item">
              <CheckBoxGroupe
                inputProps={{
                  name: "email"
                }}
                onChange={onChangeControl}
                checkBoxList={[{id: 3, name: "Email"}]}
              />
              <RadioField {...formData.email} disabled={!radioControl.email} />
            </div>
            <div className="BatchEdit-grid-item">
              <CheckBoxGroupe
                inputProps={{
                  name: "phone"
                }}
                onChange={onChangeControl}
                checkBoxList={[{id: 4, name: "Phone Number"}]}
              />
              <RadioField {...formData.phone} disabled={!radioControl.phone} />
            </div>
          </div>
          {isWarning && (
            <p className="BatchEdit-warning">
              <Icon icon="warning" color="orange" size="sm" />
              <span>Changes to time/location will not be applied to agents</span>
            </p>
          )}
        </form>
      </FormWrap>
    </div>
  );
};
