import {FC} from "react";
import "./Template.scss";

interface iTemplateProps {
  selected: {id: number; name: string}[];
  color: string;
}

export const Template: FC<iTemplateProps> = ({selected, color = ""}) => {
  const placeholders = Array(3).fill(null).map((_, i) => (<div key={i} className="item" />)); // prettier-ignore

  return (
    <div className="Template">
      <div className="Template-borderTop" style={{backgroundColor: color}} />

      <div className="Template-header">
        <div className="title">Esquire Photo</div>

        <div className="placeholders">
          <div className="circles">{placeholders}</div>
          <div className="rectangles">{placeholders}</div>
        </div>
      </div>

      <div className="Template-subHeader">
        <span style={{color}}>000000</span> | APPLE
      </div>

      <ul className="Template-navigation">
        <li className="item">Overview</li>
        {selected?.map((item: any) => {
          return (
            <li key={item.id} className="item">
              {item.name}
            </li>
          );
        })}
        <li className="item">Files</li>
      </ul>

      <div className="Template-body">
        <div /> <div />
      </div>
    </div>
  );
};
