import {FC, FormEvent} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {CheckBoxGroupe} from "components/shared/FormComponents/CheckBoxGroupe/CheckBoxGroupe";
import {useCheckBoxGroupe} from "hooks/inputHooks/useCheckBoxGroupe";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {jobsApi} from "api/jobs";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import "./AddJobSections.scss";

interface iAddJobSectionsProps {
  show: boolean;
  onClose(): void;
}

export const AddJobSections: FC<iAddJobSectionsProps> = ({show, onClose}) => {
  const {jobDetailsData, setJobDetails, sectionsQuery} = useContextJobDetails();

  const sections = useCheckBoxGroupe({
    isRequired: true,
    emptyErrText: "Please select at least one section"
  });

  const handleConfirm = async (event: FormEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!sections.checkValidity()) return;

    try {
      const sectionsPayload = sections.selectedCheckBoxes?.current.map(item => Number(item));
      const {
        data: {data}
      } = await sectionsQuery.call(
        jobsApi.addSectionsToJob(jobDetailsData!.id, {sections: sectionsPayload})
      );
      eventBus.dispatch("showToast", {text: "Sections added successfully"});
      setJobDetails(data);
      onClose();
    } catch (error) {
      const {msg} = apiErrorHandler(error); // eslint-disable-line
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const handleClose = () => {
    onClose();
  };

  const isDisabled = !!sections?.errors?.length;

  return (
    <ConfirmWindow
      onConfirm={handleConfirm}
      show={true}
      onClose={handleClose}
      title="Add Job Sections"
      isLoading={sectionsQuery?.isLoading}
      cancelBtn={{
        text: "Cancel"
      }}
      confirmBtn={{
        text: "Save",
        disabled: isDisabled
      }}
    >
      <form className="AddJobSections">
        <p className="AddJobSections-header">Select sections to add</p>
        <CheckBoxGroupe
          customClass="AddJobCheckbox"
          label=""
          // @ts-ignore
          checkBoxList={jobDetailsData?.available_sections}
          initValue={sections?.selectedCheckBoxes?.current}
          onChange={sections.checkBoxGroupeOnChange}
          errors={sections?.errors}
        />
      </form>
    </ConfirmWindow>
  );
};
