import {FC, Fragment, useState} from "react";
import {tHoldStatus} from "api/common/types";
import {tContactPosition} from "api/jobCrewContactPosition/type";
import {tCrewGroupContact} from "api/jobCrewGroup/types";
import {CrewTableGroupHeader} from "./CrewTableGroupHeader/CrewTableGroupHeader";
import {useContextJobCrew} from "../../JobCrewContext";
import {useChangeContactOrder} from "./useChangeContactOrder";
import {CrewTableRowInactive} from "./CrewTableRowInactive/CrewTableRowInactive";
import {CrewTableRowAgent} from "./CrewTableRowAgent/CrewTableRowAgent";
import {CrewTableRow} from "./CrewTableRow/CrewTableRow";

type tCrewTableBodyProps = {
  group: tCrewGroupContact;
  positionsList: Array<tContactPosition>;
  holdStatusList: Array<tHoldStatus>;
  index: number;
};

export const CrewTableBody: FC<tCrewTableBodyProps> = props => {
  const {group, positionsList, holdStatusList, index} = props;

  const {onlyActive} = useContextJobCrew();
  const [showContacts, setShowContacts] = useState(true);
  const {dropCallBack, setCurrContact, isLoading} = useChangeContactOrder(group);

  const contactList = onlyActive
    ? group?.contacts?.filter(item => !item?.inactive)
    : group?.contacts;

  return (
    <>
      <CrewTableGroupHeader
        group={group}
        setShowContacts={setShowContacts}
        showContacts={showContacts}
        showIcon={!!group?.contacts?.length}
        index={index}
      />

      {showContacts && (
        <>
          {contactList?.map((item, i) => {
            return (
              <Fragment key={item?.id}>
                <CrewTableRow
                  crewContact={item}
                  positionsList={positionsList}
                  holdStatusList={holdStatusList}
                  dropCallBack={dropCallBack}
                  isLoading={isLoading}
                  setCurrContact={setCurrContact}
                  groupId={group?.id}
                />

                {item?.agent && (
                  <CrewTableRowAgent agent={item?.agent} crewContact={item} groupId={group?.id} />
                )}
              </Fragment>
            );
          })}

          {onlyActive && !!group?.inactive_count && (
            <CrewTableRowInactive amount={group?.inactive_count} />
          )}
        </>
      )}
    </>
  );
};
