import {FC, useState} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {dropboxApi} from "api/dropbox";

import {ConfirmWindow} from "../ConfirmWindow/ConfirmWindow";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";

interface iDropboxAuthProps {
  show: boolean;
  onClose: () => void;
}

export const DropboxAuth: FC<iDropboxAuthProps> = ({show, onClose}) => {
  const dropboxAuthQuery = useHttp();

  const [isLoading, setIsLoading] = useState(false);
  const onAuthDropbox = async () => {
    setIsLoading(true);

    try {
      const response = await dropboxAuthQuery.call(dropboxApi.dropboxAuth());
      window.open(response.data.data.url, "_blank", "noopener,noreferrer");
    } catch (error: any) {
      console.log(error);
    } finally {
      onClose();
      setIsLoading(false);
    }
  };

  return (
    <ConfirmWindow
      show={show}
      className="DropboxAuth"
      title="Error!"
      onConfirm={onAuthDropbox}
      onClose={onClose}
      showCloseButton={true}
      confirmBtn={{
        text: "Sign in",
        color: "dark"
      }}
      cancelBtn={{
        text: "Cancel"
      }}
      isLoading={isLoading}
    >
      <TextComponent size="17" center weight="700">
        The file hasn't been saved. <br />
        You haven't logged in to Dropbox.
      </TextComponent>
      <br />
      <TextComponent size="17" center weight="700">
        Would you like to sign in now?
      </TextComponent>
    </ConfirmWindow>
  );
};
