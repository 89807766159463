import {FC, useState, MouseEvent} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {AddLineItems} from "../../_modals/AddLineItems";
import {useContextTrack} from "../../_context/TrackContext";
import {TrackCurrencyRates} from "../../_common/TrackCurrencyRates";

type tTrackWAMenuProps = {
  [key: string]: any;
};

type tModal = "currency" | "lineItem" | null;

export const TrackWAMenu: FC<tTrackWAMenuProps> = () => {
  const {getEstimateGroups} = useContextTrack();
  const [modal, setModal] = useState<tModal>(null);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const toggleMenu = (event: MouseEvent<HTMLElement> | null) => setAnchor(event?.currentTarget || null); // prettier-ignore

  const showModal = (modal: tModal) => {
    setAnchor(null);
    setModal(modal);
  };

  const onClose = () => setModal(null);

  const modalRender = {
    currency: <TrackCurrencyRates onClose={onClose} updateCallback={getEstimateGroups} />,
    lineItem: <AddLineItems onClose={onClose} />
  };

  return (
    <div className="TrackWAMenu">
      {modal && modalRender[modal]}

      <div className="EstimateManagement">
        <Button color="outline" size="sm" onClick={toggleMenu}>
          <Icon icon="menu" size="xsm" />
        </Button>

        <DropdownMenu
          className="EstimateManagement-dropdown"
          anchor={anchor}
          onClose={() => toggleMenu(null)}
        >
          <Button size="sm" width="fullWidth" onClick={() => showModal("lineItem")}>
            Add line Items
          </Button>
          <Button size="sm" width="fullWidth" onClick={() => showModal("currency")}>
            Currency rates
          </Button>
        </DropdownMenu>
      </div>
    </div>
  );
};
