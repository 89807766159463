import {useState} from "react";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {eventBus} from "EventBus/EventBus";
import {estimateApi} from "api/budget/estimate";
import {useContextExportBudget} from "../../context/ExportBudgetContext";
import {useContextJobBudget} from "../../context/JobBudgetContext";
import {getDateFormat} from "helpers/dates";

type tModel = "preview" | "approve";

export function useEstimateStatusLine() {
  const {exportInfo, getPdfData, updatePdfData} = useContextExportBudget();
  const {estimate} = useContextJobBudget();

  const saveExportInfoQuery = useHttp();
  const saveFileToDropboxQuery = useHttp();

  const [modal, setModal] = useState<tModel | null>(null);

  const onSaveExportInfo = async () => {
    if (!exportInfo || !estimate) return;
    let payload: any = Object.entries(exportInfo).reduce((acc, [key, value]) => {
      if (!value) return acc;
      return {...acc, [key]: value};
    }, {});

    const {label1, label2, text1, text2} = payload;
    const extended = [
      {label: label1, text: text1},
      {label: label2, text: text2}
    ];

    try {
      const {data} = await saveExportInfoQuery.call(
        estimateApi.updateExportInfo(estimate.id, {
          ...payload,
          extended,
          ...(payload?.job_number && {job_number: String(payload.job_number)}),
          ...(payload?.job_start_at && {job_start_at: getDateFormat(payload.job_start_at, "timestamp")}), // prettier-ignore
          ...(payload?.job_end_at && {job_end_at:  getDateFormat(payload.job_end_at, "timestamp")}) // prettier-ignore
        })
      );
      updatePdfData();
      eventBus.dispatch("showToast", {type: "success", text: data?.message});
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const onSaveToDropbox = async () => {
    if (!estimate?.id || !exportInfo) return;

    const fileData = await getPdfData("A4", exportInfo);

    if (!fileData?.blob) return console.log("The blob pdf hasn't being generated.");

    const formData: any = new FormData();
    formData.append("file", fileData.blob);

    try {
      const {data} = await saveFileToDropboxQuery.call(estimateApi.storeEstimateToDropbox(estimate.id, formData)); // prettier-ignore
      eventBus.dispatch("showToast", {type: "success", text: data?.message});
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const onShowPreview = async () => {
    await updatePdfData();
    setModal("preview");
  };

  return {
    modal,
    setModal,
    onShowPreview,
    onSaveToDropbox,
    onSaveExportInfo,
    isSavingExportInfo: saveExportInfoQuery.isLoading,
    isUploadingDropbox: saveFileToDropboxQuery.isLoading
  };
}
