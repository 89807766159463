import {FC, useState} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {CardLine} from "./CardLine";
import {EditCardForm} from "./EditCardForm";
import {ListLayout} from "../../../_common/ListLayout";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import {tPayIdsItem} from "api/common/types";
import {tCreditCard} from "api/budget/creditCardAndPayroll/types";
import {creditCardAndPayroll} from "api/budget/creditCardAndPayroll";
import {useHttp} from "hooks/httpServices/useHttp";

type tEditCreditCardProps = {
  onClose: () => void;
  card: tPayIdsItem;
};

export const EditCreditCard: FC<tEditCreditCardProps> = ({onClose, card}) => {
  const [showForm, setForm] = useState<boolean>(false);
  const [currCard, setCard] = useState<tCreditCard | null>(null);

  const closeForm = () => {
    setForm(false);
    setCard(null);
  };

  const setCurrCard = (data: tCreditCard) => {
    setCard(data);
    setForm(true);
  };

  const {data} = useHttp({onMountCall: true, config: creditCardAndPayroll?.getCreditCardTypes()});
  const cardsList = data?.data?.data;

  return (
    <ModalPopup size="sm" show={true} onClose={onClose} withoutPaddings>
      {showForm && (
        <EditCardForm
          onClose={onClose}
          goBack={closeForm}
          cardData={!!currCard ? currCard : undefined}
          cardsList={cardsList}
        />
      )}

      {!showForm && (
        <ListLayout
          headerTitle={"Edit CREDIT CARDS"}
          buttonNode={
            <Button color="outline" width="fullWidth" size="sm" onClick={() => setForm(true)}>
              <Icon icon="add" size="xsm" /> credit card
            </Button>
          }
        >
          {card?.target_items?.map(item => {
            return <CardLine key={item?.id} onEditCallback={() => setCurrCard(item)} card={item} />;
          })}
        </ListLayout>
      )}
    </ModalPopup>
  );
};
