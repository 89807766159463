import {tInputFilter} from "./inputsInterfaces";
import phoneCodes from "./phone_codes.json";
/**
 * Function executes value filtration
 * @param {string} val - string to filter
 * @param {tInputFilter[]} filters - filtering fns array
 * @return {string} - filtered value
 */
export const applyFilters = (val: string, filters: tInputFilter[]) => {
  let filteredVal = val;
  filters.forEach(filter => (filteredVal = filter(filteredVal)));
  return filteredVal;
};

export const prepareFiltersArr = (
  filters: Array<tInputFilter | keyof typeof inputFilters>
): tInputFilter[] => {
  const filtersArr: tInputFilter[] = [];
  filters.forEach(item => {
    const filter = typeof item === "string" ? inputFilters[item] : [item];
    filtersArr.push(...filter);
  });
  return filtersArr;
};

export const cutAndOnlyNumbers = ({value, maxLength}: {value: string; maxLength?: number}) => {
  // eslint-disable-next-line no-useless-escape
  let digitsValue = value.replace(/[^\d\.]/gm, "");
  return maxLength ? digitsValue.slice(0, maxLength) : digitsValue;
};

export const nameFilter = (value: string): string => {
  return value.replace(/[^\p{L}\s'`-]/gmu, "").replace(/\s\s+/gm, " ");
};

export const emailFilter = (value: string): string => {
  return value.replace(/[\<\>\(\)\[\]\\\{\}\,\;\:\"\s]/gm, ""); // eslint-disable-line
};

export const phoneFilter = <T>(value: T): string => {
  if (typeof value !== "string") return "";
  return value
    .replace(/\ {2,}/g, " ")
    .replace(/[^\d\.\-\ \(\)\+]+/g, "")
    .replace(/\ {2,}/g, " ");
};

const onlyDigitsAndOneDot = (value: string) => {
  // let val = value.replace(/[^0-9.]/, "");
  let filteredValue = value.replace(/[^\d.]+/g, "");

  const dotCount = (filteredValue.match(/\./g) || []).length;
  if (dotCount > 1) {
    const firstDotIndex = filteredValue.indexOf(".");
    const beforeFirstDot = filteredValue.slice(0, firstDotIndex);
    const afterFirstDot = filteredValue.slice(firstDotIndex + 1);
    filteredValue = `${beforeFirstDot}.${afterFirstDot.replace(/\./g, "")}`;
  }

  return filteredValue;
};

const onlyDigitsOneDotAndMinus = (value: string) => {
  let filteredValue = value.replace(/[^\d.-]+/g, "");

  // Remove any "-" minus signs that appear after the first character
  if (filteredValue.indexOf("-") > 0) {
    filteredValue = filteredValue.replace("-", "");
  }

  // Remove any additional "-" minus signs that appear before the first character
  if (filteredValue.lastIndexOf("-") > 0) {
    filteredValue = filteredValue.slice(filteredValue.lastIndexOf("-"));
  }

  const dotCount = (filteredValue.match(/\./g) || []).length;

  if (dotCount > 1) {
    const firstDotIndex = filteredValue.indexOf(".");
    const beforeFirstDot = filteredValue.slice(0, firstDotIndex);
    const afterFirstDot = filteredValue.slice(firstDotIndex + 1);
    filteredValue = `${beforeFirstDot}.${afterFirstDot.replace(/\./g, "")}`;
  }

  return filteredValue;
};

export const percent = (value: string) => {
  let val = onlyDigitsAndOneDot(value);
  return (val = +val >= 100 ? "100" : val);
};

export const percent99 = (value: string) => {
  let val = onlyDigitsAndOneDot(value);
  return (val = +val > 99.99 ? "99.99" : val);
};

export const onlyDigits = (value: string) => {
  let val = onlyDigitsAndOneDot(value);
  return val;
};

export const maxLengthTree = (value: string) => {
  return value?.slice(0, 3);
};

export const currencyFilter = (value: string) => {
  return onlyDigitsAndOneDot(value);
};

export const currencyFilterWithNegativeValue = (value: string) => {
  return onlyDigitsOneDotAndMinus(value);
};

export const maskPhone = (value: string | number) => {
  const clearedVal = String(value).replace(/\D/g, "");
  // console.log("%c 2", "color: orange; font-size: 16px; font-weight: bold; border-left: 5px solid orange", clearedVal);
  if (!clearedVal.length) return "+";

  let countryCode = "";

  for (let i = 1, matched = true; i <= clearedVal.length && matched; i++) {
    const curCode = clearedVal.slice(0, i);

    // console.log("loop" + i, curCode, phoneCodes.find(({code}) => {
    //   if(Array.isArray(code))
    //     return !!code.find(item => item.replace(/\D/g, "").indexOf(curCode) === 0);
    //   return code.replace(/\D/g, "").indexOf(curCode) === 0
    // })?.code);

    phoneCodes.find(({code}) => {
      if (Array.isArray(code))
        return !!code.find(item => item.replace(/\D/g, "").indexOf(curCode) === 0);
      return code.replace(/\D/g, "").indexOf(curCode) === 0;
    })
      ? (countryCode = curCode)
      : (matched = false);
  }

  // console.log("%c 2", "color: orange; font-size: 16px; font-weight: bold; border-left: 5px solid orange", countryCode, clearedVal);

  const phone = clearedVal.slice(countryCode.length);

  if (countryCode === "1") countryCode = "";

  if (!phone.length) return `+${countryCode}`;

  if (phone.length <= 3)
    // area
    return `+${countryCode} ${phone}`;

  if (phone.length <= 6)
    // first 3 numbers
    return `+${countryCode} ${phone.slice(0, 3)}.${phone.slice(3)}`;

  if (phone.length <= 8)
    // 4,5 numbers
    return `+${countryCode} ${phone.slice(0, 3)}.${phone.slice(3, 6)}.${phone.slice(6)}`;

  if (phone.length <= 10)
    // 6,7 numbers
    return `+${countryCode} ${phone.slice(0, 3)}.${phone.slice(3, 6)}.${phone.slice(
      6,
      8
    )}.${phone.slice(8)}`;

  if (phone.length > 10)
    return `+${countryCode} ${phone.slice(0, 3)}.${phone.slice(3, 6)}.${phone.slice(
      6,
      8
    )}.${phone.slice(8, 10)} ${phone.slice(10)}`;
};

export const inputFilters = {
  name: [nameFilter],
  first_name: [nameFilter],
  last_name: [nameFilter],
  email: [emailFilter],
  phone: [phoneFilter],
  percent: [percent],
  percent99: [percent99],
  onlyDigits: [onlyDigits],
  maxLengthTree: [maxLengthTree],
  currencyFilter: [currencyFilter],
  onlyDigitsOneDotAndMinus: [onlyDigitsOneDotAndMinus]
};
