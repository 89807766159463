const tooltipText = {
  notifications: (
    <div className="AccountNotifications-tooltip-element">
      You will be notified when your task…
      <ul>
        <li>Is assigned or unassigned</li>
        <li>Is completed</li>
        <li> Is deleted</li>
        <li>Has details changed</li>
        <li> Has a file added or removed</li>
        <li> Is overdue</li>
        <li> Has reached the reminder date</li>
      </ul>
    </div>
  ),
  priority: (
    <div className="AccountNotifications-tooltip-element">
      You will be notified when your task…
      <ul>
        <li> Is overdue</li>
        <li> Has reached the reminder date</li>
      </ul>
    </div>
  ),
  jobUpdate: (
    <div className="AccountNotifications-tooltip-element">
      You will be notified when your job…
      <ul>
        <li> Is assigned or unassigned</li>
        <li> Has primary details changed</li>
        <li> Has team members added or removed</li>
        <li> Is archived</li>
      </ul>
    </div>
  ),
  teamNotify: (
    <div className="AccountNotifications-tooltip-element">
      You will be notified when…
      <ul>
        <li> People are added or removed from your department</li>
        <li> An invitee signs up</li>
        <li> A user’s level changes</li>
      </ul>
    </div>
  )
};

export default tooltipText;
