import {FC, ReactNode} from "react";
import {Icon} from "components/Icon/Icon";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import "./DropdownSubmenu.scss";

type tDropdownSubmenuProps = {
  children: ReactNode;
  title: string;
  position?: "left" | "right";
  callBack?: () => void;
};

export const DropdownSubmenu: FC<tDropdownSubmenuProps> = ({
  children,
  title,
  position = "right",
  callBack
}) => {
  return (
    <TextComponent weight="700" size="10" className="DropdownSubmenu" uppercase onClick={callBack}>
      {title}
      <Icon icon="triangle-right" size="xxsm" color="light-gray" />

      <div className={`submenuWrap ${position}`}>
        <div className="submenu">{children}</div>
      </div>
    </TextComponent>
  );
};
