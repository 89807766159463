import {useEffect, useLayoutEffect, useRef, useState} from "react";
import {OverviewPipelineColumn, tColor} from "./OverviewPipelineColumn/OverviewPipelineColumn";
import {useResize} from "hooks/useResize";
import {tPipeline} from "api/dashboard/overview/types";

type tOverviewPipelineTable = {
  data: tPipeline[];
};

export const MIN_OVERVIEW_HEIGHT = 300;

export const OverviewPipelineTable = ({data}: tOverviewPipelineTable) => {
  const [bidding, working, wrap, done] = data;
  const [maxInitHight, setMaxInit] = useState(MIN_OVERVIEW_HEIGHT);
  const {height, minHeight, resizeHandler} = useResize({
    initHeightVal: maxInitHight,
    minHeight: 88
  });
  const refMainBlock = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const heightMainBlock = refMainBlock.current?.offsetHeight;
    heightMainBlock && heightMainBlock < maxInitHight && setMaxInit(heightMainBlock);
  }, [data]); // eslint-disable-line

  const [hiddenElement, setHiddenElements] = useState(0);
  const dataRef = useRef<Record<tColor, number> | {}>({});
  useEffect(() => {
    const {blue, gray, green, yellow} = dataRef.current as Record<tColor, number>;
    const hiddenElement = blue + gray + green + yellow;
    setHiddenElements(hiddenElement);
  }, [dataRef.current]); // eslint-disable-line

  return (
    <>
      <div
        className="OverviewJobsPipeline-columns"
        style={{height: height, minHeight}}
        ref={refMainBlock}
      >
        <OverviewPipelineColumn
          title="Bidding"
          color="gray"
          data={bidding}
          height={height}
          ref={dataRef}
        />
        <OverviewPipelineColumn
          title="Working"
          color="yellow"
          data={working}
          height={height}
          ref={dataRef}
        />
        <OverviewPipelineColumn
          title="Wrap & BILL"
          color="blue"
          data={wrap}
          height={height}
          ref={dataRef}
        />
        <OverviewPipelineColumn
          title="Done"
          color="green"
          data={done}
          height={height}
          ref={dataRef}
        />
      </div>

      <div className="OverviewJobsPipeline-divider" onMouseDown={resizeHandler}>
        {!!hiddenElement && <span className="hiddenElements">{hiddenElement} jobs hidden</span>}
      </div>
    </>
  );
};
