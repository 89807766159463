import {FC, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {
  iCallSheetsDeliveryConfirmation,
  iDeliveryInfo,
  tCallSheetContact
} from "api/callSheet/types";
import {CallSheetResend} from "./CallSheetResend";
import {CellLatestStatus} from "./CellLatestStatus";
import {CellLatestDelivery} from "./CellLatestDelivery";
import {CellMethods} from "./CellMethods";

import "./TrackingTableRow.scss";
import {AppTooltips} from "components/AppTooltips/AppTooltips";

interface iTrackingTableRowProps {
  deliveryInfo: iDeliveryInfo;
  isAgent?: boolean;
  confirmation?: iCallSheetsDeliveryConfirmation | null;
  contact: tCallSheetContact;
  className?: string;
  showTooltip?: boolean;
}

export const TrackingTableRow: FC<iTrackingTableRowProps> = ({
  contact,
  isAgent,
  className,
  confirmation,
  deliveryInfo,
  showTooltip
}: iTrackingTableRowProps) => {
  const [showModal, setShowModal] = useState(false);
  const onShowModal = () => setShowModal(true);

  const name = isAgent ? <span className="agent-name">{contact?.agent?.name}</span> : contact.name; // prettier-ignore

  const title = isAgent ? contact?.agent?.position : contact.position;

  const email = isAgent ? contact?.agent?.email?.value : contact?.email?.value;
  const phone = isAgent
    ? contact?.agent?.phone?.value
    : contact?.phone?.value;
    // ? formatPhoneNumber({value: contact?.agent?.phone?.value})
    // : formatPhoneNumber({value: contact?.phone?.value});
  const callTime = contact?.time || "";
  const location = contact.location?.name;

  const isFailedEmail = confirmation?.failed_methods?.includes("email");
  const isFailedSms = confirmation?.failed_methods?.includes("sms");

  const resendInitData = {
    ...deliveryInfo,
    ...(confirmation?.custom && {...confirmation?.custom})
  };

  return (
    <>
      <tr className={`TrackingTableRow ${className}`}>
        <td className="TrackingTableRow-cell name">{name}</td>
        <CellLatestDelivery
          contact={contact}
          deliveryInfo={deliveryInfo}
          isAgent={isAgent}
          confirmation={confirmation}
        />
        <CellMethods
          contact={contact}
          deliveryInfo={deliveryInfo}
          isAgent={isAgent}
          confirmation={confirmation}
        />
        <CellLatestStatus
          contact={contact}
          isAgent={isAgent}
          confirmation={confirmation}
          showTooltip={showTooltip}
        />

        <td className="TrackingTableRow-cell resend">
          <button onClick={onShowModal}>
            <Icon icon="sent" color="gray" />
          </button>
        </td>
        <td className="TrackingTableRow-cell rightSideTableCell title">{title}</td>
        <td
          className={`TrackingTableRow-cell rightSideTableCell emailSendErr ${
            isFailedEmail || !email ? "error" : "gray"
          }`}
        >
          {email || "Doesn't have email"}
          {showTooltip && <AppTooltips page="callSheetTrack" tooltip="Send ERRor" />}
        </td>
        <td
          className={`TrackingTableRow-cell rightSideTableCell ${
            isFailedSms || !phone ? "error" : "gray"
          }`}
        >
          {phone || "Doesn't have phone number"}
        </td>
        <td className="TrackingTableRow-cell rightSideTableCell gray">{callTime}</td>
        <td className="TrackingTableRow-cell rightSideTableCell gray">{location}</td>
      </tr>

      {showModal && (
        <CallSheetResend deliveryInfo={resendInitData} data={contact} setShowModal={setShowModal} />
      )}
    </>
  );
};
