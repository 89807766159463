import {tTrackTotalItems} from "api/budget/track/types";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {getDiffValueColor, getValue} from "../../_common/getAndFormatVal";

type tUseGetCategoryFooterCellsProps = {
  data: tTrackTotalItems;
};

export const useGetCategoryFooterCells = ({data}: tUseGetCategoryFooterCellsProps) => {
  const {total_actual, total_cost, total_difference, total_working} = data;

  const cells = (props: {className: string}) => [
    {
      component: (
        <TextComponent size="15" {...props}>
          Total
        </TextComponent>
      )
    },
    {
      component: (
        <TextComponent size="15" {...props}>
          {getValue(total_cost)}
        </TextComponent>
      ),
      colSpan: 5
    },
    {
      component: (
        <TextComponent weight="400" size="14" {...props}>
          {getValue(total_working)}
        </TextComponent>
      )
    },
    {
      component: (
        <TextComponent size="15" {...props}>
          {getValue(total_actual)}
        </TextComponent>
      )
    },
    {
      component: (
        <TextComponent
          weight="400"
          size="14"
          color={getDiffValueColor(total_difference)}
          {...props}
        >
          {getValue(total_difference)}
        </TextComponent>
      )
    },
    {
      component: null,
      colSpan: 5
    }
  ];

  return {cells};
};
