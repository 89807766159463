import {Dispatch, FC, SetStateAction, useState} from "react";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import {EventEdit} from "pages/Jobs/JobDetails/JobCalendar/modals/EventEdit/EventEdit";
import {jobEventModel, tEventModal} from "../../helpers";
import moment from "moment";
import {useContextJobCalendar} from "pages/Jobs/JobDetails/JobCalendar/context";

interface iEventCreatorProps {
  setEvents: Dispatch<SetStateAction<tEventModal[]>>;
}

export const EventCreator: FC<iEventCreatorProps> = ({setEvents}) => {
  const {activeCalendar} = useContextJobCalendar();
  const [addEvent, setAddEvent] = useState<tEventModal | null>(null);

  const onShowModal = () => {
    const event = {
      start_date: moment().add(1, "day").startOf("day"),
      end_date: moment().add(1, "day").endOf("day"),
      all_day: true
    };
    setAddEvent(jobEventModel(event, "events"));
  };

  return (
    <>
      <Button
        disabled={activeCalendar?.locked}
        onClick={onShowModal}
        className="SchedulerActions-burger"
        color="outline"
        size="sm"
      >
        <Icon icon="add" size="xsm" />
      </Button>
      {addEvent && (
        <EventEdit event={addEvent} isUpdating={false} onClose={() => setAddEvent(null)} />
      )}
    </>
  );
};
