import {FC, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {ListLineLayout} from "../common/ListLineLayout";
import {creditCardAndPayroll} from "api/budget/creditCardAndPayroll";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {tCreditCard} from "api/budget/creditCardAndPayroll/types";

type tPayrollCompanyLineProps = {
  onEditCallback: () => void;
  payrollItem: tCreditCard;
  onUpdateList?: () => void;
};

export const PayrollCompanyLine: FC<tPayrollCompanyLineProps> = ({
  onEditCallback,
  onUpdateList,
  payrollItem
}) => {
  const {call, isLoading} = useHttp();
  const [modal, setModal] = useState<"delete" | null>(null);
  const closeModal = () => setModal(null);

  const onRemove = async () => {
    try {
      await call(creditCardAndPayroll?.deletePayrollCompany(payrollItem?.id));
      setModal(null);
      onUpdateList?.();
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <ListLineLayout>
      <TextComponent weight="500" size="14" uppercase>
        {payrollItem?.name}
      </TextComponent>

      <Button onClick={onEditCallback}>
        <Icon icon="edit" size="md" color="gray" />
      </Button>
      <Button onClick={() => setModal("delete")}>
        <Icon icon="delete" size="md" color="gray" />
      </Button>

      <ConfirmWindow
        title="Delete this company?"
        subTitle={`This company will be permanently deleted.`}
        show={modal === "delete"}
        onClose={closeModal}
        onConfirm={onRemove}
        isLoading={isLoading}
        confirmBtn={{text: "Delete", color: "red"}}
        cancelBtn={{text: "cancel"}}
      />
    </ListLineLayout>
  );
};
