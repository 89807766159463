import {FC} from "react";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {useAdvanceSelection} from "./useAdvanceSelection";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {BudgetNewAdvanceForm} from "../../forms/BudgetNewAdvanceForm";
import {useContextAdvance} from "../context";
import {tAdvance} from "api/budget/advance/types";
import {useNavigate} from "react-router-dom";
import {sortByKey} from "helpers/misc";

import "./AdvanceSelection.scss";

interface iAdvanceSelectionProps {}

export const AdvanceSelection: FC<iAdvanceSelectionProps> = () => {
  const navigate = useNavigate();
  const {advanceList, advanceDetails, advanceDetailsQuery} = useContextAdvance();
  const {anchor, toggleMenu, modal, showModal, closeModal} = useAdvanceSelection();

  const onSelectAdvance = (item: tAdvance) => {
    toggleMenu(null);
    navigate(String(item.id));
  };

  return (
    <div className="AdvanceSelection">
      <Button
        color="outline"
        size="sm"
        onClick={toggleMenu}
        loading={advanceDetailsQuery.isLoading}
      >
        {advanceDetails?.name || "Select Advance"}
        <Icon size="xsm" icon={!!anchor ? "triangle-up" : "triangle-down"} />
      </Button>
      <DropdownMenu
        className="AdvanceSelection-dropdown"
        anchor={anchor}
        onClose={() => toggleMenu(null)}
      >
        {sortByKey(advanceList, "name").map(item => (
          <Button key={item.id} size="sm" width="fullWidth" onClick={() => onSelectAdvance(item)}>
            {item.name}
          </Button>
        ))}

        <hr />
        <Button size="sm" width="fullWidth" onClick={() => showModal("advance")}>
          Add Advance
        </Button>
      </DropdownMenu>

      <ModalPopup show={!!modal} size="w-528" withoutPaddings onClose={closeModal}>
        {modal === "advance" && <BudgetNewAdvanceForm closeModal={closeModal} />}
      </ModalPopup>
    </div>
  );
};
