import {DetailedHTMLProps, FC, ReactNode} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {Button, iButtonProps} from "components/shared/Button/Button";
import {iModalPopUpProps} from "../ModalPopup/ModalInterfaces";
import "./ConfirmWindow.scss";

interface iConfirmWindowProps extends iModalPopUpProps {
  title?: string;
  subTitle?: string;
  confirmBtn?: modalBtn;
  cancelBtn?: modalBtn;
  onConfirm?: (...v: any) => void;
  persistOnScreen?: boolean;
  className?: string;
  children?: ReactNode;
  isLoading?: boolean;
  size?: iModalPopUpProps["size"];
  warning?: string;
}

interface modalBtn
  extends DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  text?: string;
  color?: iButtonProps["color"];
  size?: iButtonProps["size"];
  disabled?: iButtonProps["disabled"];
  isLoading?: boolean;
}

/**
 *
 * @param title
 * @param onClose
 * @param show
 * @param cancelBtn
 * @param confirmBtn
 * @param onConfirm
 * @param persistOnScreen
 * @param className
 * @param showCloseButton
 * @param children
 * @param isLoading
 * @param subTitle
 * @param size
 * @param warning
 * @constructor
 */

export const ConfirmWindow: FC<iConfirmWindowProps> = ({
  title = "",
  onClose,
  show = false,
  cancelBtn,
  confirmBtn,
  onConfirm,
  persistOnScreen,
  className = "",
  showCloseButton = false,
  children,
  isLoading = false,
  subTitle,
  warning = "",
  size = "sm"
}) => {
  if (!show) return null;

  return (
    <ModalPopup
      size={size}
      onClose={onClose}
      persistOnScreen={persistOnScreen}
      show={show}
      showCloseButton={showCloseButton}
      className={`ConfirmWindow ${className}`}
    >
      <div className="ConfirmWindow-content content">
        {title && <h2 className="ConfirmWindow-title title">{title}</h2>}
        {subTitle && <div className="ConfirmWindow-subtitle">{subTitle}</div>}
        {warning && <div className="ConfirmWindow-error">{warning}</div>}

        {children}

        <div className="ConfirmWindow-footer footer">
          {cancelBtn && (
            <Button
              {...cancelBtn}
              onClick={onClose}
              size={cancelBtn?.size || "md"}
              color={cancelBtn?.color || "light"}
              disabled={isLoading || cancelBtn?.disabled}
              loading={confirmBtn?.isLoading}
            >
              {cancelBtn?.text || "Cancel"}
            </Button>
          )}
          <Button
            {...confirmBtn}
            onClick={onConfirm}
            size={confirmBtn?.size || "md"}
            color={confirmBtn?.color || "dark"}
            loading={isLoading || confirmBtn?.isLoading}
            disabled={confirmBtn?.disabled}
          >
            {confirmBtn?.text || "Confirm"}
          </Button>
        </div>
      </div>
    </ModalPopup>
  );
};
