import {useState} from "react";
import {useContextProfile} from "context";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {AccountSidebar} from "./AccountSidebar/AccountSidebar";
import {AccountPassword} from "./AccountPassword/AccountPassword";
import {AccountNotifications} from "./AccountNotifications/AccountNotifications";
import {AccountProfile} from "./AccountProfile/AccountProfile";
import {useAccount} from "./useAccount";
import {AccountLayoutTab} from "./AccountLayoutTab/AccountLayoutTab";
import {AccountSettings} from "./AccountNotifications/AccountSettings";
import {useContextApp} from "context/AppContext/AppContext";

import "./Account.scss";

const Account = () => {
  const {accountModal, setAccountModal} = useContextApp();
  const {updateLoading} = useContextProfile();
  const {changePass, notificationSettings} = useAccount();

  const [tab, setTab] = useState(0);

  if (!accountModal) return null;

  return (
    <ModalPopup
      size="lg"
      className="Account-modal"
      show={accountModal}
      onClose={() => setAccountModal(false)}
    >
      <div className="Account">
        <AccountSidebar tab={tab} handleClick={setTab} />

        {tab === 0 && (
          <AccountLayoutTab title="Email Notifications" withFooter={false}>
            <AccountNotifications notificationSettings={notificationSettings} />
          </AccountLayoutTab>
        )}

        {tab === 1 && (
          <AccountLayoutTab title="Edit Profile" isLoading={updateLoading}>
            <AccountProfile />
          </AccountLayoutTab>
        )}

        {tab === 2 && (
          <AccountLayoutTab title="Change Password" isLoading={changePass.isLoading}>
            <AccountPassword data={changePass} />
          </AccountLayoutTab>
        )}
        {tab === 3 && (
          <AccountLayoutTab title="Settings" withFooter={false}>
            <AccountSettings />
          </AccountLayoutTab>
        )}
      </div>
    </ModalPopup>
  );
};

export default Account;
