import {createRef, FC, forwardRef, useImperativeHandle, useRef, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {confirmNotification, createNewRowObj} from "./model";
import {tDynamicFieldWrapsProps} from "./types";
import {DynamicInputsGroup} from "./DynamicInputsGroup/DynamicInputsGroup";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import "./DynamicFieldsWrap.scss";

export const DynamicFieldsWrap: FC<tDynamicFieldWrapsProps> = forwardRef(
  (
    {dataList, setDataList, selectOptions, type, buttonName, title, className, notificationType, notificationData, emptyRowErrorMsg}, // prettier-ignore
    ref
  ) => {
    const dynamicFieldsRef = useRef([]);
    dynamicFieldsRef.current = dataList.map((_, i) => dynamicFieldsRef.current[i] ?? createRef());
    useImperativeHandle(ref, () => ({checkFieldsValidity}));

    const [emptyRowError, setEmptyRowErr] = useState(false);

    const checkFieldsValidity = () => {
      // @ts-ignore
      return dynamicFieldsRef?.current.filter(item => !item.current.checkValidity()).length > 0;
    };

    const addNewRow = () => {
      const lastItem = dataList[dataList.length - 1];
      if (!lastItem.value || !lastItem.name) {
        setEmptyRowErr(true);
        return;
      }
      if (checkFieldsValidity()) return;

      setDataList(prev => [...prev, createNewRowObj()]);
    };

    const [confirmWindow, setConfirmWindow] = useState("");
    const handleRemove = () => {
      emptyRowError && setEmptyRowErr(false);
      const id = confirmWindow;
      const filtered = dataList.filter(acc => id !== acc.uid);
      setDataList(filtered);
      setConfirmWindow("");
    };

    const onChangeHandler = (changedAcc: any) => {
      emptyRowError && setEmptyRowErr(false);

      const newList = dataList.map(acc => {
        return acc.uid === changedAcc.uid ? changedAcc : acc;
      });

      setDataList(newList);
    };
    return (
      <div className={`DynamicFieldsWrap-phone ${className}`}>
        {dataList.map((item, i) => {
          return (
            <DynamicInputsGroup
              key={item.uid}
              itemField={item}
              isFirstItem={i === 0}
              options={selectOptions}
              handleRemove={setConfirmWindow}
              ref={dynamicFieldsRef.current[i]}
              onChangeHandler={onChangeHandler}
              showDeleteBtn={dataList.length > 1}
              type={type}
              type_custom={item?.type_custom}
            />
          );
        })}

        {emptyRowError && (
          <div className="DynamicFieldsWrap-phone-emptyRowErr">
            {!!emptyRowErrorMsg && emptyRowErrorMsg}
            {!emptyRowErrorMsg && "You can't add new items before to fill previous ones"}
          </div>
        )}

        <Button type="button" color="outline" size="sm" onClick={addNewRow}>
          <Icon icon="add" size="14" color="black" />
          {buttonName}
        </Button>

        <ConfirmWindow
          show={!!confirmWindow}
          onClose={() => setConfirmWindow("")}
          onConfirm={handleRemove}
          confirmBtn={{text: "Delete", color: "red"}}
          cancelBtn={{text: "Cancel"}}
          title={title}
        >
          <div className="DynamicFieldsWrap-phone-confirmModal-body">
            {confirmNotification({
              notificationType,
              currItem: dataList?.find(item => item.uid === confirmWindow)?.value,
              notificationData: notificationData
            })}

            <p className="DynamicFieldsWrap-phone-confirmModal-warning">This can't be undone.</p>
          </div>
        </ConfirmWindow>
      </div>
    );
  }
);

/**
 
 const socPlatformRef = useRef<tCheckFieldsValidity>(null);

 const formData = {
    accounts: {
      checkValidity: () => !socPlatformRef?.current?.checkFieldsValidity()
    }
  };
  
  const [accounts, setAccounts] = useState<tDynamicFieldRowObj[]>([createNewRowObj()]);

  return (
     <DynamicFields
      ref={socPlatformRef}
      dataList={accounts}
      setDataList={setAccounts}
      selectOptions={accPlatformOptions}
    />
  )

 */
