import {FC, useState, MouseEvent} from "react";
import {LocationTableAddress} from "./LocationTableAddress";
import {LocationTableContacts} from "./LocationTableContacts";
import {iLocationsListItemResponse} from "api/location/types";
import {LocationsTableLocale} from "../LocationsTableLocale";
import {useContextLocations} from "../../context/LocationsContext";
import {LocationsModals} from "../../LocationsModals/LocationsModals";
import "./LocationsTableRow.scss";

interface iLocationsTableRowProps {
  data: iLocationsListItemResponse;
}

export const LocationsTableRow: FC<iLocationsTableRowProps> = ({data}) => {
  const {apiParams, getLocationsList} = useContextLocations();
  const [showModal, setShowModal] = useState<number | null>(null);

  const onCloseModal = (callback?: Function) => {
    callback?.();
    setShowModal(null);
  };

  const tagOnClick = (event: MouseEvent<any>, id: number) => {
    event.stopPropagation();
    apiParams.setApiParams({params: {locales: [String(id)]}});
  };

  return (
    <>
      <tr className="LocationsTableRow" onClick={() => setShowModal(data.id)}>
        <td className="LocationsTableRow-cell LocationsTableRow-cell-name">
          <span>{data?.name}</span>
        </td>
        <td className="LocationsTableRow-cell">{data?.rate}</td>
        <LocationTableAddress locationItem={data} />
        <LocationsTableLocale tagOnClick={tagOnClick} locales={data.locales} />
        <LocationTableContacts contacts={data.contacts} />
      </tr>

      {showModal && (
        <LocationsModals
          location={showModal}
          closeModal={onCloseModal}
          onEditCallback={getLocationsList}
        />
      )}
    </>
  );
};
