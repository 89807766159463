import {ButtonHTMLAttributes, DetailedHTMLProps, FC} from "react";
import {components, MenuListProps} from "react-select";
import {
  ReactSelect,
  tReactSelectProps
} from "components/shared/FormComponents/ReactSelect/ReactSelect";

import "./SelectWithButton.scss";

interface iSelectWithButtonProps extends tReactSelectProps<any, any> {
  buttonText: string;
  label?: string;
  buttonProps?: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
}

export const SelectWithButton: FC<iSelectWithButtonProps> = ({
  buttonText,
  buttonProps,
  label,
  ...props
}) => {
  const MenuList = (props: MenuListProps<any>) => {
    const hasOptions = !!props.options.length;

    return (
      <components.MenuList className={hasOptions ? "" : "is-empty"} {...props}>
        <div>{props.children}</div>

        <MenuButton>{buttonText}</MenuButton>
      </components.MenuList>
    );
  };

  const MenuButton = ({...props}) => (
    <button {...props} {...buttonProps} className="MenuListItem">
      {props.children}
    </button>
  );

  return (
    <div className="SelectWithButton">
      {label && <label className="SelectWithButton-label label">{label}</label>}
      <ReactSelect
        className="SelectWithButton-select"
        hideArrow={false}
        components={{MenuList}}
        {...props}
      />
    </div>
  );
};
