import {FC, useState} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {CardLine} from "./CardLine";
import {EditCardForm} from "./EditCardForm";
import {ListLayout} from "../common/ListLayout";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import {tCreditCard, tCreditCardTypes} from "api/budget/creditCardAndPayroll/types";
import {Loading} from "components/shared/Loading/Loading";
import {useHttp} from "hooks/httpServices/useHttp";
import {creditCardAndPayroll} from "api/budget/creditCardAndPayroll";

type tEditCreditCardProps = {
  onClose: () => void;
  cards: tCreditCardTypes[];
  isLoading?: boolean;
  onEditCallback?: () => void;
};

export const EditCreditCard: FC<tEditCreditCardProps> = ({
  onClose,
  onEditCallback,
  isLoading,
  cards
}) => {
  const [showForm, setForm] = useState<boolean>(false);
  const [currCard, setCard] = useState<tCreditCard | null>(null);

  const closeForm = () => {
    setForm(false);
    setCard(null);
  };

  const setCurrCard = (data: tCreditCard) => {
    setCard(data);
    setForm(true);
  };

  const {data} = useHttp({onMountCall: true, config: creditCardAndPayroll?.getCreditCardTypes()});
  const cardsList = data?.data?.data;

  return (
    <ModalPopup size="sm" show={true} onClose={onClose} withoutPaddings>
      {isLoading && <Loading type="inBlock" />}
      {showForm && (
        <EditCardForm
          onClose={onClose}
          goBack={closeForm}
          cardData={!!currCard ? currCard : undefined}
          onEditCallback={onEditCallback}
          cardsList={cardsList}
        />
      )}

      {!showForm && (
        <ListLayout
          headerTitle={"Edit CREDIT CARDS"}
          buttonNode={
            <Button color="outline" width="fullWidth" size="sm" onClick={() => setForm(true)}>
              <Icon icon="add" size="xsm" /> credit card
            </Button>
          }
        >
          {cards?.map(item => {
            return (
              <CardLine
                key={item?.id}
                updateList={onEditCallback}
                onEditCallback={() => setCurrCard(item)}
                card={item}
              />
            );
          })}
        </ListLayout>
      )}
    </ModalPopup>
  );
};
