import {useState} from "react";

interface iUseTextEditorProps {
  required?: boolean;
  customErrorMessage?: string;
  value?: string;
}

export function useTextEditor(props?: iUseTextEditorProps) {
  const [required, setRequired] = useState(props?.required || false);
  const [value, setValue] = useState(props?.value || "");
  const [errors, setErrors] = useState<string[]>([]);

  const errorMessage = props?.customErrorMessage || "The field is required";

  const checkValidity = (val: string) => {
    const newValue = val || value;

    if (required && !newValue) {
      setErrors([errorMessage]);
      return false;
    }

    !!errors?.length && setErrors([]);
    return true;
  };

  const onChange = (val: string) => {
    checkValidity(val);
    setValue(val);
  };

  return {
    setValue: setValue,
    value,
    onEditorChange: onChange,
    checkValidity,
    errors,
    required,
    setRequired,

    inputProps: {
      value,
      onEditorChange: onChange
    }
  };
}
