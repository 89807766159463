import {FC, useEffect, useState} from "react";
import TabFormLayout from "pages/Contacts/commonComponent/TabFormLayout/TabFormLayout";
import {CompanyFormMain} from "./CompanyForms/CompanyFormMain/CompanyFormMain";
import CompanyFormAddress from "./CompanyForms/CompanyFormAddress/CompanyFormAddress";
import {CompanyFormOther} from "./CompanyForms/CompanyFormOther/CompanyFormOther";
import {useCompanyForm} from "./useCompanyForm";
import {iCompanyDetails} from "api/contacts/companies/interfaces";
import {notValidForm} from "helpers/misc";
import {COMPANY_FORM_ID, getTabTitle, tabsName} from "./model";
import {CustomFieldsForm} from "components/shared/CustomFieldsForm/CustomFieldsForm";

import "./CompanyForm.scss";

interface iCompanyFormProps {
  closeEdit?: () => void;
  closeModal: () => void;
  company?: iCompanyDetails | null;
  onEditCallback?: Function;
  setIsChangedFormData?: (isChanged: boolean) => void;
}

export type tNotValidTabs = {
  "0": boolean;
  "1": boolean;
  "2": boolean;
};

export const CompanyForm: FC<iCompanyFormProps> = ({
  closeModal,
  closeEdit,
  company,
  onEditCallback,
  setIsChangedFormData
}) => {
  const [tab, setTab] = useState(0);
  const [notValidTabs, setNotValidTab] = useState<Partial<tNotValidTabs>>();

  const form = useCompanyForm({company, setNotValidTab, tab, onEditCallback, closeModal});
  const {isLoading, isChangedFormData} = form;
  const {onSubmit, mainFormData, addressFormData, otherFormData, customFormData} = form;

  useEffect(() => {
    setIsChangedFormData?.(isChangedFormData);
  }, [isChangedFormData]); // eslint-disable-line

  const isValid = (formData: { [k: string]: any }, tab: string) => {
    if (notValidForm(formData)) {
      setNotValidTab(prev => ({...prev, [tab]: true}));
      return;
    }
    setNotValidTab(prev => ({...prev, [tab]: false}));
    setTab(prev => prev + 1);
  };

  const toNextTab = () => {
    switch (tab) {
      case 0:
        isValid(mainFormData.formData, "0");
        break;
      case 1:
        const form = {...{addr: addressFormData.formData.address},...addressFormData.formData.billing_info}; // prettier-ignore
        isValid(form, "1");
        break;
      case 2:
        isValid(otherFormData.formData, "2");
        break;
    }
  };

  const tabs = [
    <CompanyFormMain toNextTab={toNextTab} onSubmit={onSubmit} formDataMain={mainFormData} />,
    <CompanyFormAddress toNextTab={toNextTab} onSubmit={onSubmit} addressFormData={addressFormData}/>, // prettier-ignore
    <CompanyFormOther toNextTab={toNextTab} onSubmit={onSubmit} otherFormData={otherFormData} />,
    <CustomFieldsForm id={COMPANY_FORM_ID} onSubmit={onSubmit} customFormData={customFormData} />
  ];

  return (
    <div className="CompanyForm">
      <TabFormLayout
        title={getTabTitle(company)}
        tabs={tabsName}
        setTab={setTab}
        activeTab={tab}
        onCloseModal={closeEdit ? closeEdit : closeModal}
        // for handling submit forms in tabs
        formId={COMPANY_FORM_ID}
        isDisabledButton={isLoading}
        notValidTabs={notValidTabs}
        favorite={form.favorite}
        setFavorite={form.setFavorite}
      >
        {tabs[tab]}
      </TabFormLayout>
    </div>
  );
};
