import {FC, Fragment} from "react";
import {StyleSheet, Text, View} from "@react-pdf/renderer";
import {getUsMoneyFormat} from "helpers/formattingData";
import {tTrack} from "api/budget/track/types";

interface iEstimateValuesTableProps {
  data: tTrack;
}

export const InvoiceValuesTable: FC<iEstimateValuesTableProps> = ({data}) => {
  const categories = data.categories;
  let estimates = new Set();
  categories.forEach(cat => {
    cat.cost_values.forEach(value => estimates.add(value?.estimate?.name));
  }, []);

  const isMultipleEstimate = estimates.size > 1;

  return (
    <View style={styles.container}>
      {categories.map(({category, cost_values, category_total}) => {
        const {id, name, code} = category;
        const {total_actual} = category_total;
        return (
          <View key={id}>
            <View style={styles.headerRow}>
              <Text style={styles.headerCode}>{code}</Text>
              <Text style={styles.headerName}>{name}</Text>
              <Text style={styles.headerTotal}>total</Text>
            </View>
            {cost_values.map(
              (
                {
                  cost_value: {
                    id,
                    code,
                    name,
                    suffix,
                    description,

                    discount_description
                  },
                  cost_value_expense_total: {total_actual},
                  estimate
                },
                index,
                array
              ) => {
                const estimateName = estimate?.name || "";
                const isShowEstimate =
                  (array?.[index - 1] && isMultipleEstimate && array[index - 1].estimate?.name) !==
                  estimateName;

                return (
                  <Fragment key={id}>
                    {isShowEstimate && <Text style={styles.estimateNameRow}>{estimateName}</Text>}

                    <View style={styles.tableRow}>
                      <Text style={styles.cellCode}>{code + suffix}</Text>
                      <View style={styles.cellName}>
                        <Text style={styles.name}>{name}</Text>
                        {description && <Text style={styles.description}>{description}</Text>}
                        {discount_description && (
                          <Text style={styles.discount}>{discount_description}</Text>
                        )}
                      </View>
                      <Text style={styles.cellTotal}>{getUsMoneyFormat(total_actual)}</Text>
                    </View>
                  </Fragment>
                );
              }
            )}

            <View style={styles.totalRow}>
              <Text style={styles.totalLabel}>Total</Text>
              <Text style={styles.totalValue}>{getUsMoneyFormat(total_actual)}</Text>
            </View>
          </View>
        );
      })}

      <View style={styles.actualTotalRow}>
        <Text style={styles.totalLabel}>Total actual costs</Text>
        <Text style={styles.totalValue}>{getUsMoneyFormat(data.grand_total.total_actual)}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: "1 1 auto"
  },
  headerRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    borderBottom: "2px solid black",
    paddingBottom: 2,
    marginBottom: "14px"
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: 8
  },
  totalRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    marginBottom: 32,
    marginTop: 16
  },
  actualTotalRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    marginBottom: 32,
    marginTop: 16
  },
  headerCode: {
    width: "10%",
    fontSize: 15,
    fontFamily: "Helvetica700",
    paddingBottom: 4
  },
  headerName: {
    width: "50%",
    fontSize: 15,
    fontFamily: "Helvetica700",
    textTransform: "uppercase",
    paddingBottom: 4
  },
  headerTotal: {
    width: "50%",
    fontFamily: "Helvetica500",
    textAlign: "right",
    textTransform: "uppercase",
    fontSize: 10,
    paddingBottom: 4
  },
  cellCode: {
    width: "10%",
    fontFamily: "Helvetica400",
    fontSize: 12
  },
  cellName: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    fontFamily: "Helvetica500",
    fontSize: 12
  },
  name: {fontFamily: "Helvetica500"},
  description: {color: "#7E7E80"},
  discount: {color: "#D2232A"},
  cellTotal: {
    width: "20%",
    fontFamily: "Helvetica400",
    textAlign: "right",
    textTransform: "uppercase",
    fontSize: 12
  },
  totalLabel: {
    fontFamily: "Helvetica700",
    fontSize: 13,
    textTransform: "uppercase",
    width: "30%"
  },
  totalValue: {
    fontFamily: "Helvetica700",
    fontSize: 13,
    textTransform: "uppercase",
    textAlign: "right",
    width: "15%"
  },
  estimateNameRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: 6,
    marginBottom: 6,
    textTransform: "uppercase",
    color: "#7E7E80",
    fontFamily: "Helvetica500",
    fontSize: 10,
    minHeight: 16
  }
});
