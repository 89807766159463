import {InputHTMLAttributes} from "react";
import {uid} from "helpers/misc";
import attachment from "assets/images/Attach.svg";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import "./AttachFile.scss";

type tAttachFile = {
  callBack: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
};
export const AttachFile = ({callBack, placeholder, inputProps}: tAttachFile) => {
  const id = uid();

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    callBack(e);
  };

  return (
    <label htmlFor={id} className="AttachFile">
      {!placeholder && <img src={attachment} alt="attachment" />}

      {!!placeholder && (
        <TextComponent weight="500" size="10" color="gray-20" className="AttachFile-placeholder">
          <span>{placeholder}</span>
          <img src={attachment} alt="attachment" />
        </TextComponent>
      )}

      <input
        {...inputProps}
        type="file"
        id={id}
        className="AttachFile-input"
        onChange={onChangeHandler}
      />
    </label>
  );
};
