import {FC} from "react";
import {ButtonGroup} from "components/ButtonGroup/ButtonGroup";
import {useParams, NavLink} from "react-router-dom";
import {routes} from "router/routes";
import {AppTooltips} from "components/AppTooltips/AppTooltips";
import "./TrackNavigation.scss";

type tTrackNavigationProps = {
  [key: string]: any;
};

export const TrackNavigation: FC<tTrackNavigationProps> = () => {
  const {id} = useParams();
  const {track, expenseList, wrapPDF, main} = routes.jobs.details.budget;
  const baseUrl = `/${routes?.jobs.main}/${id}/${main}/`;

  const links = [
    {url: track, title: "Work/Actual"},
    {url: expenseList, title: "Expense List"},
    {url: wrapPDF, title: "Wrap PDF"}
  ];

  return (
    <ButtonGroup className="TrackNavigation">
      {links.map(link => {
        return (
          <NavLink key={link.url} to={baseUrl + link.url}>
            {link.title}
          </NavLink>
        );
      })}

      <AppTooltips page="track" tooltip="Alternate views" chevronPosition="topRight" />
    </ButtonGroup>
  );
};
