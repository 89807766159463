import {FC} from "react";
import {Icon} from "components/Icon/Icon";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {tableTrackWAColumns} from "../../tableTrackWAHeadItems";
import {TooltipPortal} from "components/shared/TooltipPortal/TooltipPortal";
import "./HeaderFees.scss";

type tHeaderFeesProps = {
  title: string;
  tooltipText: string;
};

export const HeaderFees: FC<tHeaderFeesProps> = ({title, tooltipText}) => {
  return (
    <tr>
      <td colSpan={tableTrackWAColumns?.length}>
        <TextComponent className="HeaderFees" size="17" uppercase>
          <div className="HeaderFees-innerWrap">
            {title}
            <TooltipPortal
              text={tooltipText}
              arrowPosition="left"
              transformVertical="center"
              anchorVertical="center"
            >
              <Icon icon="info" size="sm" color="dark-gray" />
            </TooltipPortal>
          </div>
        </TextComponent>
      </td>
    </tr>
  );
};
