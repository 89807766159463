import {AxiosRequestConfig} from "axios";
import {iAddJobFormData, iGetJobsParams, iUpdateJobDetailsParams, tAddLinkToJob} from "./types";

const JOB = "job";
const LINK = "link";

export const jobsApi = {
  getJobs: (params?: iGetJobsParams): AxiosRequestConfig => ({
    url: `jobs`,
    method: "get",
    params
  }),
  addJob: (data: iAddJobFormData): AxiosRequestConfig => ({
    url: JOB,
    method: "post",
    data
  }),
  getJobDetails: (job_id: number): AxiosRequestConfig => ({
    url: `${JOB}/${job_id}`,
    method: "get"
  }),
  getPipelines: (): AxiosRequestConfig => ({
    url: "job_pipelines",
    method: "get"
  }),
  deleteJob: (id: number): AxiosRequestConfig => ({
    method: "delete",
    url: `${JOB}/${id}`
  }),

  // Link url
  addLinkToJob: (job_id: number, data: tAddLinkToJob): AxiosRequestConfig => ({
    url: `${JOB}/${job_id}/${LINK}`,
    method: "POST",
    data
  }),
  updateExternalLink: (
    job_id: number,
    link_id: number,
    data: tAddLinkToJob
  ): AxiosRequestConfig => ({
    url: `${JOB}/${job_id}/${LINK}/${link_id}`,
    method: "POST",
    data
  }),
  removeExternalLink: (job_id: number, link_id: number): AxiosRequestConfig => ({
    url: `${JOB}/${job_id}/${LINK}/${link_id}`,
    method: "DELETE"
  }),
  //Link job
  addJobLink: (job_id: number, data: {linked_job_id: number}): AxiosRequestConfig => ({
    url: `${JOB}/${job_id}/${LINK}/job`,
    method: "POST",
    data
  }),
  removeJobLink: (job_id: number, data: {linked_job_id: number}): AxiosRequestConfig => ({
    url: `${JOB}/${job_id}/${LINK}/job`,
    method: "DELETE",
    data
  }),
  setPipelineToJob: (job_id: number, data: {pipeline_id: number}): AxiosRequestConfig => ({
    method: "POST",
    url: `${JOB}/${job_id}/pipeline`,
    data
  }),
  updateJobDetails: (job_id: number, data: iUpdateJobDetailsParams): AxiosRequestConfig => ({
    method: "POST",
    url: `${JOB}/${job_id}`,
    data
  }),
  addSectionsToJob: (job_id: number, data: {sections: number[]}): AxiosRequestConfig => ({
    method: "POST",
    url: `${JOB}/${job_id}/sections`,
    data
  }),
  removeSectionsFromJob: (job_id: number, section: number): AxiosRequestConfig => ({
    method: "DELETE",
    url: `${JOB}/${job_id}/section/${section}`
  })
};
