import {FC} from "react";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {ContactBlock} from "../common/ContactBlock";
import {Title} from "../common/Title";

interface iJobContactsProps {
  [key: string]: any;
}

export const JobContacts: FC<iJobContactsProps> = () => {
  const {jobDetailsData} = useContextJobDetails(); // prettier-ignore
  if (!jobDetailsData) return null;
  const {people} = jobDetailsData;
  return (
    <div className="JobOverview-section">
      {!people?.length && (
        <>
          <Title text="Job Contacts" />
          <ContactBlock placeholder="Job contacts will appear here" fulWidth />
        </>
      )}

      {!!people?.length && (
        <>
          <Title text="Job Contacts" fullWidth />

          {people?.map(item => {
            // const email = item?.person?.emails?.find(item => item?.type_id === 3); // 3 is office, show only office email
            const email = item?.person?.emails?.[0];
            if (item.is_lead) return null;

            return (
              <ContactBlock
                key={item?.person?.id}
                title={`${item?.person?.first_name || ""} ${item?.person?.last_name || ""}`}
                subTitle={item?.title?.name}
                imgUrl={item?.person?.avatar_url || ""}
                contact={email?.value}
                id={item?.person?.id}

              />
            );
          })}
        </>
      )}
    </div>
  );
};
