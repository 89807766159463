import {FC} from "react";
import {jobTasksApi} from "api/jobs/tasks";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {TextComponent} from "../../common/TextComponent/TextComponent";
import {useContextJobTasks} from "../_context/JobTaskContext";

type tDeleteTasksGroupProps = {
  onClose: () => void;
  id: number;
  name?: string;
  type: "group" | "task";
};

export const DeleteTasksGroup: FC<tDeleteTasksGroupProps> = ({
  id,
  onClose,
  name,
  type = "group"
}) => {
  const {setTasks} = useContextJobTasks();
  const {call, isLoading} = useHttp();

  const calls: Record<tDeleteTasksGroupProps["type"], any> = {
    group: jobTasksApi.deleteGroup(id),
    task: jobTasksApi.deleteTask(id)
  };

  const onSubmit = async () => {
    if (!id) return;

    try {
      const res = await call(calls[type]);
      onClose();
      setTasks(res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  const title = type === "group" ? "Delete group?" : "Delete task?";
  const subtitle =
    type === "group" ? "This group and its tasks will be removed." : "This task will be removed.";

  return (
    <ConfirmWindow
      show={true}
      title={title}
      subTitle={subtitle}
      cancelBtn={{text: "Cancel", type: "button"}}
      onClose={onClose}
      confirmBtn={{text: "Delete", type: "submit", color: "red"}}
      onConfirm={onSubmit}
      isLoading={isLoading}
    >
      <TextComponent color="red" weight="400" size="14" center className="mb-40">
        This can’t be unndone.
      </TextComponent>
    </ConfirmWindow>
  );
};
