import {FC, useEffect, useRef, useState, ChangeEvent} from "react";
import {useOnClickOutside} from "hooks/useOnclickOutside";
import {fieldTypes} from "./useChangeContacts";

interface iManuallyEntered {
  placeholder: string;
  value: string | undefined;
  onBlur: (value: string, name: fieldTypes) => void;
  name: fieldTypes;
  className?: string;
}

export const ManuallyEntered: FC<iManuallyEntered> = ({
  onBlur,
  value,
  placeholder,
  name,
  className = "ManuallyEntered-inputRegular"
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [showInput, setShowInput] = useState(false);

  const [innerText, setInnerText] = useState(value);

  useOnClickOutside(inputRef, showLabel);

  const handleClickLabel = () => {
    setShowInput(true);
    setTimeout(() => {
      inputRef?.current?.focus();
    }, 0);
  };

  function showLabel() {
    setTimeout(() => {
      showInput && setShowInput(false);
    }, 0);
  }

  const handleBlur = (event: ChangeEvent<HTMLDivElement>) => {
    const value = event.currentTarget.innerText;
    onBlur(value, name);
  };

  const handleInput = (event: ChangeEvent<HTMLDivElement>) => {
    setInnerText(event.target.innerText);
  };

  const emptyValueClassName = !innerText ? "is-empty-value" : "";
  const inputClassName = showInput ? "input" : "";

  const getValue = () => {
    if (showInput) {
      return value || "";
    }
    return value || placeholder;
  };

  return (
    <div className="ManuallyEntered">
      <div
        style={{maxWidth: showInput ? "550px" : "auto"}}
        onClick={handleClickLabel}
        ref={inputRef}
        contentEditable={showInput}
        suppressContentEditableWarning={true}
        dangerouslySetInnerHTML={{__html: getValue()}}
        onInput={handleInput}
        onBlur={handleBlur}
        className={`${className} ${inputClassName} ${emptyValueClassName}`}
      />
    </div>
  );
};
