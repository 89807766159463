import {AxiosRequestConfig} from "axios";
import {tExpenseType, tInvoicePayload, tInvoiceStatusPayload, tPaymentPropPayload} from "./types";

const JOB_INVOICE = "job_invoice";

export const invoiceApi = {
  getInvoice: (jobId: number): AxiosRequestConfig => ({
    url: `job/${jobId}/invoice`
  }),
  generateInvoiceByEstimate: (data: {estimate_id: number}): AxiosRequestConfig => ({
    method: "POST",
    url: JOB_INVOICE,
    data
  }),
  updateAdvanceLatePaymentProperties: (
    jobAdvance: number,
    data: tPaymentPropPayload
  ): AxiosRequestConfig => ({
    method: "POST",
    url: `${JOB_INVOICE}/advance/${jobAdvance}/late_payment`,
    data
  }),
  getInvoiceById: (invoiceId: number): AxiosRequestConfig => ({
    url: `${JOB_INVOICE}/${invoiceId}`
  }),
  updateInvoice: (invoiceId: number, data: Partial<tInvoicePayload>): AxiosRequestConfig => ({
    method: "POST",
    url: `${JOB_INVOICE}/${invoiceId}`,
    data
  }),
  changeInvoiceStatus: (invoiceId: number, data: tInvoiceStatusPayload): AxiosRequestConfig => ({
    method: "POST",
    url: `${JOB_INVOICE}/${invoiceId}/status`,
    data
  }),
  resetExpenseSum: (invoiceId: number, expense: tExpenseType): AxiosRequestConfig => ({
    method: "POST",
    url: `${JOB_INVOICE}/${invoiceId}/reset/${expense}`
  }),
  saveInvoiceToDropbox: (invoiceId: number, data: {file: File}): AxiosRequestConfig => ({
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    url: `${JOB_INVOICE}/${invoiceId}/save_to_dropbox`,
    data
  })
};
