import {FC} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {ChapterHeader} from "../../OverlayTemplates/ChapterHeader/ChapterHeader";
import {Datepicker} from "components/shared/FormComponents/Datepicker/Datepicker";
import {TextareaField} from "components/shared/FormComponents/TextareaField/TextareaField";
import {SelectWithButton} from "../SelectWithButton/SelectWithButton";
import {AdvanceContactsModal} from "../modals/AdvanceContactsModal/AdvanceContactsModal";
import {useBudgetOverlayAdvanceDetails} from "./useBudgetOverlayAdvanceDetails";
import {useContextAdvance} from "../context";

import "./BudgetOverlayAdvanceDetails.scss";

interface iBudgetOverlayAdvanceDetailsProps {}

export const BudgetOverlayAdvanceDetails: FC<iBudgetOverlayAdvanceDetailsProps> = () => {
  const {advanceContacts, setAdvanceContacts, advanceDetails} = useContextAdvance();
  const {formData, editContacts, onChangeContact, setEditContacts, onSaveCallback} =
    useBudgetOverlayAdvanceDetails();

  const hasContacts = !!advanceContacts.length;

  return (
    <>
      <div className="BudgetOverlayAdvanceDetails">
        <ChapterHeader>Details</ChapterHeader>
        <InputField
          {...formData.invoice_number.inputProps}
          onBlur={onSaveCallback}
          name="invoice_number"
          label="Invoice no."
        />
        <Datepicker
          {...formData.date.inputProps}
          name="date"
          minDate={new Date()}
          label="Date"
          withIcon
        />
        <InputField
          {...formData.terms.inputProps}
          onBlur={onSaveCallback}
          name="terms"
          label="Terms"
        />
        <Datepicker
          {...formData.due_date.inputProps}
          name="date"
          minDate={new Date()}
          label="Due Date"
          withIcon
        />
        <SelectWithButton
          onChange={onChangeContact}
          value={advanceDetails?.advance_contact}
          label="Contacts"
          name="contact_id"
          options={advanceContacts}
          formatOptionLabel={item => item.name}
          buttonProps={{
            onClick: () => setEditContacts(true)
          }}
          buttonText={hasContacts ? "Edit advance contacts" : "Add advance contact"}
        />
        <TextareaField disabled {...formData.contact.inputProps} />
      </div>
      {editContacts && (
        <AdvanceContactsModal
          advanceContacts={advanceContacts}
          setAdvanceContacts={setAdvanceContacts}
          onClose={() => setEditContacts(false)}
        />
      )}
    </>
  );
};
