import {FC} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {JobSettingsTemplatesHeader} from "../JobSettingsTemplatesHeader/JobSettingsTemplatesHeader";
import {Loading} from "components/shared/Loading/Loading";
import {JobScheduler} from "./JobScheduler/JobScheduler";
import {TemplateCalendarContextProvider, useContextTemplateCalendar} from "./context";
import {tUseJobSettingsTemplatesModalData} from "../useJobSettingsTemplates";
import {tJobSettingsTemplatesModal} from "../JobSettingsTemplates";

import "./TemplateCalendar.scss";

type tTemplateCalendarProps = Partial<tUseJobSettingsTemplatesModalData> & {
  onClose: () => void;
  id: number;
  editName: (data: {id: number; name: string}, type: tJobSettingsTemplatesModal) => void;
};

const TemplateCalendarComponent: FC<tTemplateCalendarProps> = ({editName, name}) => {
  const {onClose, calendarInfo, getTemplateInfoQuery} = useContextTemplateCalendar();

  return (
    <ModalPopup show={true} onClose={onClose} className="TemplateCalendar-modal" size="fullScreen">
      {getTemplateInfoQuery.isLoading && <Loading type="inBlock" />}
      <JobSettingsTemplatesHeader
        title={`Edit calendar template “${name}”`}
        onRename={() => editName({id: calendarInfo!.id, name: calendarInfo!.name}, "calendar")}
        onClose={onClose}
      />
      {getTemplateInfoQuery?.data?.data?.data && <JobScheduler />}
    </ModalPopup>
  );
};

export const TemplateCalendar = (
  props: tTemplateCalendarProps & Partial<tUseJobSettingsTemplatesModalData>
) => {
  return (
    <TemplateCalendarContextProvider {...props}>
      <TemplateCalendarComponent {...props} />
    </TemplateCalendarContextProvider>
  );
};
