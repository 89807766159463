import {FC} from "react";
import sortIcon from "assets/svg/sortIcon.svg";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import {tEstimateGroup, tEstimateRowData} from "api/budget/estimate/types";
import {EstimateRowINput} from "../../_common/EstimateRowInput/EstimateRowINput";
import {useHttp} from "hooks/httpServices/useHttp";
import {estimateApi} from "api/budget/estimate";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {useContextJobBudget} from "../../../_context/JobBudgetContext";
import "./EstimateRowName.scss";

type tEstimateRowNameProps = {
  currItem: tEstimateRowData;
  setExpandRow: React.Dispatch<React.SetStateAction<boolean>>;
  expandRow: boolean;
  group: tEstimateGroup;
};

export const EstimateRowName: FC<tEstimateRowNameProps> = ({
  currItem,
  setExpandRow,
  expandRow,
  group
}) => {
  const iconName = expandRow ? "triangle-down" : "triangle-right";
  const toggleExpandRow = () => setExpandRow(prev => !prev);

  const {setEstimate} = useContextJobBudget();
  const {call, isLoading} = useHttp();

  const onSubmit = async (value: string) => {
    if (value === currItem?.name) return;

    try {
      const res = await call(estimateApi.updateLine(currItem.id, {name: value}));
      setEstimate(() => res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const canEdit = group.code === currItem.code;

  return (
    <div className="EstimateRowName">
      <img src={sortIcon} alt="sort icon" className="sortIcon" draggable="false" />
      <Button onClick={toggleExpandRow}>
        <Icon icon={iconName} size="xxsm" color="gray" />
      </Button>
      <span className="number">{`${currItem?.code}${currItem?.suffix}`}</span>

      <div className="EstimateRowName-wrapDescriptions">
        {!canEdit && (
          <TextComponent weight="500" size="14" truncate>
            {currItem?.name}
          </TextComponent>
        )}

        {canEdit && (
          <EstimateRowINput
            defaultValue={currItem?.name ? String(currItem?.name) : ""}
            placeholder={"Line Item Description"}
            onBlurCallBack={onSubmit}
            type="text"
            inputStatic
            isLoading={isLoading}
          />
        )}

        <TextComponent weight="400" size="13" color="gray-10" truncate>
          {currItem?.description}
        </TextComponent>
        <TextComponent weight="400" size="13" color="red" truncate>
          {currItem?.discount_description}
        </TextComponent>
        <TextComponent weight="400" size="13" color="gray-10" truncate>
          {currItem.private_memo && `(${currItem.private_memo})`}
        </TextComponent>
      </div>
    </div>
  );
};
