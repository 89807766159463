import {FC} from "react";
import {TemplateModal} from "components/shared/TemplateModal/TemplateModal";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {tJobSettingsTemplatesModal} from "./JobSettingsTemplates";
import {Loading} from "components/shared/Loading/Loading";
import {ListLine} from "../common/ListLine";
import {TextComponent} from "../../JobDetails/common/TextComponent/TextComponent";
import {tUseJobSettingsTemplatesModalData} from "./useJobSettingsTemplates";
import "./TemplatesListModal.scss";

interface iTemplatesListModalProps {
  onClose: () => void;
  modal: tJobSettingsTemplatesModal;
  isLoading: boolean;
  data: tData[];
  onShowModal: (data: tUseJobSettingsTemplatesModalData) => void;
  deleting: boolean;
  onRemove: (id: number) => void;
  showRenameWindow: (payload: {id: number; name: string}) => void;
}

type tData = {id: number; name: string; [key: string]: any};

export const TemplatesListModal: FC<iTemplatesListModalProps> = ({
  isLoading,
  modal,
  onClose,
  data,
  onShowModal,
  deleting,
  onRemove,
  showRenameWindow
}) => {
  return (
    <ModalPopup withoutPaddings show size="w-528" className="TemplatesListModal" onClose={onClose}>
      <TemplateModal title={`${modal} templates`}>
        {isLoading && <Loading type="inBlock" />}
        {!isLoading && !data.length && <TextComponent center>Hasn't templates yet</TextComponent>}
        {!isLoading &&
          data &&
          data.map(value => {
            return (
              <ListLine
                key={value.id}
                onRemove={onRemove}
                isLoading={deleting}
                onShowModal={onShowModal}
                data={value}
                templateType={modal}
                showRenameWindow={showRenameWindow}
              />
            );
          })}
      </TemplateModal>
    </ModalPopup>
  );
};
