import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useContextJobCrew} from "pages/Jobs/JobDetails/JobCrew/JobCrewContext";
import {updateContact} from "pages/Jobs/JobDetails/JobCrew/commonJobCrew/updateContact";
import {jobCrewContact, tCrewApi, tCrewApiNames} from "api/jobCrewContact";
import {AxiosRequestConfig} from "axios";
import {useHttp} from "hooks/httpServices/useHttp";

export type tChangeCrewValue<T extends tCrewApiNames> = {
  id: number;
  groupId: number | null;
  type: T;
  data?: tCrewApi[T];
  errorCallback?: () => void;
  successCallback?: (res: any) => void;
  showSuccessMessage?: boolean;
  blockUpdateState?: boolean;
};

export const useChangeCrewValue = () => {
  const {jobCrewGroupList: {setCrewGroupList}} = useContextJobCrew(); // prettier-ignore
  const {call, isLoading} = useHttp();

  const changeValue = async <T extends tCrewApiNames>(args: tChangeCrewValue<T>) => {
    const {id, data, type, groupId, errorCallback, successCallback, showSuccessMessage, blockUpdateState = false} = args; // prettier-ignore

    const calls: Record<tCrewApiNames, AxiosRequestConfig<any>> = {
      position: jobCrewContact.setPositionForContact(id, data as tCrewApi["position"]),
      phone: jobCrewContact.setDefaultPhone(id, data as tCrewApi["phone"]),
      email: jobCrewContact.setDefaultEmail(id, data as tCrewApi["email"]),
      billing: jobCrewContact.setBillingForContact(id, data as tCrewApi["billing"]),
      notes: jobCrewContact.setNotesForContact(id, data as tCrewApi["notes"]),
      hold: jobCrewContact.setHoldDateForContact(id, data as tCrewApi["hold"]),
      rate: jobCrewContact.setRate(id, data as tCrewApi["rate"]),
      linkAgent: jobCrewContact.linkAgentToContact(id, data as tCrewApi["linkAgent"]),
      unLinkAgent: jobCrewContact.unLinkAgentToContact(id),
      deleteContacts: jobCrewContact.deleteContacts(id, data as tCrewApi["deleteContacts"]),
      contactOrder: jobCrewContact.updateContactOrder(id, data as tCrewApi["contactOrder"])
    };

    try {
      const res = await call(calls[type]);
      showSuccessMessage && eventBus.dispatch("showToast", {text: res?.data?.message || "Success!"}); // prettier-ignore

      !groupId && !blockUpdateState && setCrewGroupList(() => res?.data?.data);

      groupId &&
        !blockUpdateState &&
        setCrewGroupList(prev => {
          return updateContact({
            prev,
            groupId,
            crewContactId: id,
            updatedContact: res?.data?.data
          });
        });

      successCallback?.(res);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
      errorCallback?.();
    }
  };

  return {changeValue, isLoading};
};
