import {FC} from "react";
import {CrewTableHeader} from "./CrewTableHeader";
import {CrewTableRow} from "./CrewTableRow/CrewTableRow";
import {useOffsetTop} from "hooks/useOffsetTop";
import {useContextJobCrew} from "../JobCrewContext";
import {Loading} from "components/shared/Loading/Loading";
import {useGetCommonLists} from "./useGetCommonLists";
import "./CrewTable.scss";

type tCrewTableProps = {};

export const CrewTable: FC<tCrewTableProps> = () => {
  const {jobCrewGroupList: {crewGroupList, isLoading}} = useContextJobCrew(); // prettier-ignore
  const {positionsList, holdStatusList} = useGetCommonLists();

  const {elementRef, offsetTop} = useOffsetTop();

  return (
    <div className="CrewTable" ref={elementRef} style={{height: `calc(100vh - ${offsetTop}px)`}}>
      {isLoading && <Loading type="inBlock" />}

      <CrewTableHeader />

      {!isLoading && (
        <>
          {crewGroupList?.map((group, index) => {
            return (
              <CrewTableRow
                key={group?.id}
                group={group}
                positionsList={positionsList}
                holdStatusList={holdStatusList}
                index={index}
              />
            );
          })}
        </>
      )}
    </div>
  );
};
