import {FC, useState} from "react";
import {Button} from "components/shared/Button/Button";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {useHttp} from "hooks/httpServices/useHttp";
import {estimateApi} from "api/budget/estimate";
import {tEstimateRowData} from "api/budget/estimate/types";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import "./EstimateRowActions.scss";
import {useContextJobBudget} from "../../../_context/JobBudgetContext";

type tEstimateRowActionsProps = {
  currItem: tEstimateRowData;
};

export const EstimateRowActions: FC<tEstimateRowActionsProps> = ({currItem}) => {
  const {setEstimate} = useContextJobBudget();

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  type actionType = "delete" | "clear" | "duplicate";
  const apiConf: Record<actionType, any> = {
    delete: () => estimateApi.deleteLine(currItem.id),
    clear: () => estimateApi.clearLine(currItem.id),
    duplicate: () => estimateApi.duplicateLine(currItem.id)
  };

  const {call, isLoading} = useHttp();
  const onSubmit = async (type: actionType) => {
    try {
      const res = await call(apiConf[type]());
      type !== "delete" && setMenuAnchor(null);
      setEstimate(() => res?.data?.data);
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const showClearBtn = [
    currItem.cost,
    currItem.currency_rate,
    currItem.quantity,
    currItem.time_count,
    currItem.time_unit,
    currItem.agency_percent,
    currItem.sales_tax,
    currItem.payroll,
    currItem.production_percent
  ].filter(item => !!item)?.length;

  return (
    <div className="EstimateRowActions">
      <Button onClick={showMenu} loading={isLoading} width="fullWidth">
        &#183;&#183;&#183;
      </Button>

      <DropdownMenu anchor={menuAnchor} onClose={() => setMenuAnchor(null)} btnHover>
        <Button
          size="sm"
          className="btn"
          onClick={() => onSubmit("duplicate")}
          disabled={isLoading}
        >
          Duplicate line
        </Button>
        {!!showClearBtn && (
          <Button size="sm" className="btn" onClick={() => onSubmit("clear")} disabled={isLoading}>
            Clear value
          </Button>
        )}
        {currItem.code.includes("100") && (
          <Button size="sm" className="btn" onClick={() => onSubmit("delete")} disabled={isLoading}>
            Delete line
          </Button>
        )}
      </DropdownMenu>
    </div>
  );
};
