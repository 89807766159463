import {FC, useEffect, useState} from "react";
import {InputCell} from "../InputCell/InputCell";
import {currencyFilter} from "hooks/useTextInput/filters";
import {typeField} from "./CurrencyRatesTable";
import "./CurrInputCell.scss";

type tCurrInputCellProps = {
  defaultValue: string;
  className?: string;
  updateList: (arg: any) => void;
  typeField: typeField;
};

export const CurrInputCell: FC<tCurrInputCellProps> = ({
  defaultValue,
  className = "",
  updateList,
  typeField
}) => {
  const [value, setValue] = useState(defaultValue || "");
  const onBlur = (value: string) => {
    updateList(value);
  };

  const onChange = (value: string) => {
    let val = value;
    if (typeField === "exchange_rate") {
      val = currencyFilter(value);
    }
    if (typeField === "hedge_percent") {
      val = currencyFilter(value);
      val = +val > 99.99 ? "99.99" : val;
    }

    setValue(val);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const placeholder = typeField === "exchange_rate" ? "0.0000" : "0.0%";

  return (
    <td className="CurrInputCell">
      <InputCell
        initValue={defaultValue || ""}
        value={value}
        setValue={onChange}
        callBack={onBlur}
        className={`CurrInputCell-input ${className}`}
        InputComponent="input"
        percent={!!value && typeField === "hedge_percent"}
        alwaysShowPlaceholder
        placeholder={placeholder}
      />
    </td>
  );
};
