import {FC} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {useContextJobTasks} from "../_context/JobTaskContext";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {tJobTasksTemplateDataTask} from "api/jobs/tasks/types";

type tDeleteTasksGroupProps = {
  onClose: () => void;
  id: number; //actually it's index not id
  name?: string;
  type: "group" | "task";
  groupName?: string;
};

export const DeleteTasksGroup: FC<tDeleteTasksGroupProps> = ({
  id,
  onClose,
  name,
  type = "group",
  groupName
}) => {
  const {tasks, updateTasksData, updateDataCall: {isLoading}} = useContextJobTasks(); // prettier-ignore

  const onSubmit = async () => {
    let newData: tJobTasksTemplateDataTask[] | undefined;
    if (type === "group") {
      newData = tasks?.data?.filter((_, i) => id !== i);
    } else {
      newData = tasks?.data?.map(item => {
        if (groupName === item?.name) {
          const newItem = item.tasks?.filter((_, i) => i !== id);

          return {...item, tasks: newItem};
        }
        return item;
      });
    }

    updateTasksData(newData, onClose);
  };

  const title = type === "group" ? "Delete group?" : "Delete task?";
  const subtitle =
    type === "group" ? "This group and its tasks will be removed." : "This task will be removed.";

  return (
    <ConfirmWindow
      show={true}
      title={title}
      subTitle={subtitle}
      onClose={onClose}
      onConfirm={onSubmit}
      isLoading={isLoading}
      cancelBtn={{text: "Cancel", type: "button"}}
      confirmBtn={{text: "Delete", type: "submit", color: "red"}}
    >
      <TextComponent color="red" weight="400" size="14" center className="mb-40">
        This can’t be unndone.
      </TextComponent>
    </ConfirmWindow>
  );
};
