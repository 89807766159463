import {FC, useState} from "react";
import sortIcon from "assets/svg/sortIcon.svg";
import {useContextJobTasks} from "../../../../_context/JobTaskContext";
import {useUpdateTaskData} from "../../../../_context/useUpdateTaskData";
import {InputCell} from "components/shared/InputCell/InputCell";
import "./TaskNameCell.scss";

type tTaskNameCellProps = {
  name: string;
  index: number;
  groupName?: string;
};

export const TaskNameCell: FC<tTaskNameCellProps> = ({name, index, groupName}) => {
  const {tasks, setTasks} = useContextJobTasks();
  const {updateTasksData, isLoading} = useUpdateTaskData();
  const [value, setValue] = useState(name || "");

  const setName = (val: string) => {
    const postData = tasks?.data?.map(item => {
      if (groupName === item?.name) {
        const newItem = item.tasks?.map((ent, i) => (index === i ? val : ent));
        return {...item, tasks: newItem};
      }
      return item;
    });

    updateTasksData(postData, setTasks);
  };

  return (
    <div className="TaskNameCell">
      <img src={sortIcon} alt="sort icon" className="TaskNameCell-sortIcon" draggable="false" />

      <InputCell
        value={value}
        setValue={setValue}
        isLoading={isLoading}
        InputComponent="input"
        placeholder="Add task name..."
        callBack={val => setName(val)}
      />
    </div>
  );
};
