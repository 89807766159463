import {FC} from "react";

import {StyleSheet, View, Text, Image} from "@react-pdf/renderer";
import logo from "assets/images/logo_text.png";

interface iHeader {
  text: string;
}

export const Header: FC<iHeader> = ({text, children}) => {
  return (
    <View style={styles.header}>
      <View style={styles.left}>
        <Image src={logo} />
      </View>
      <View style={styles.right}>
        <Text style={styles.title}>{text}</Text>
      </View>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: "29px"
  },
  left: {
    display: "flex",
    width: "55%"
    //    minHeight: "40px"
  },
  right: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "40%"
  },
  title: {
    fontSize: 17,
    marginBottom: "auto",
    fontFamily: "Helvetica700",
    textTransform: "uppercase"
  }
});
