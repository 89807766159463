import {FC} from "react";
import {BudgetOverlayFooter} from "../BudgetOverlayFooter/BudgetOverlayFooter";

import "./BudgetOverlayPageLayout.scss";

interface iBudgetOverlayPageLayoutProps {
  page: number;
  total: number;
  withFooter?: boolean;
}

export const BudgetOverlayPageLayout: FC<iBudgetOverlayPageLayoutProps> = ({
  page,
  total,
  children,
  withFooter = true
}) => {
  return (
    <div className="BudgetOverlayPageLayout">
      <div className="BudgetOverlayPageLayout-body">{children}</div>

      {withFooter && <BudgetOverlayFooter page={page} total={total} />}
    </div>
  );
};
