import {FC} from "react";
import {EventEdit} from "./EventEdit/EventEdit";
import {JobSchedulerComponent} from "./JobSchedulerComponent";
import {useJobScheduler} from "./useJobScheduler";
import "./JobScheduler.scss";

export type tSchedulerEvent = {
  start_date: string;
  end_date: string;
  name: string;
  type: string;
  id: number;
};

export const JobScheduler: FC = () => {
  const {
    events,
    addEvent,
    status,
    handleEmptyClick,
    clearEventId,
    setClearEventId,
    setEvents,
    handleCloseEventEdit,
    handleBeforeEventChange,
    onEditEvent
  } = useJobScheduler();

  const scriptIsLoaded = status === "ready";

  return (
    <>
      {scriptIsLoaded && (
        <JobSchedulerComponent
          key="job-calendar"
          events={events}
          addEvent={addEvent}
          handleEmptyClick={handleEmptyClick}
          handleBeforeEventChange={handleBeforeEventChange}
          onEditEvent={onEditEvent}
          clearEventId={clearEventId}
          setClearEventId={setClearEventId}
        />
      )}
      {addEvent && (
        <EventEdit setEvents={setEvents} event={addEvent} onClose={handleCloseEventEdit} />
      )}
    </>
  );
};
