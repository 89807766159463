import {FC} from "react";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {JobBudgetStatusBarLayout} from "../../JobBudgetStatusBarLayout/JobBudgetStatusBarLayout";
import {useAdvanceStatusLine} from "./useAdvanceStatusLine";
import moment from "moment";
import {ModalPreviewPdf} from "components/shared/ModalPreviewPdf/ModalPreviewPdf";
import {AdvanceInvoicePreview} from "../AdvanceInvoicePreview/AdvanceInvoicePreview";
import {AdvancePay} from "../modals/AdvancePay/AdvancePay";
import {AdvanceSend} from "../modals/AdvanceSend/AdvanceSend";
import {useContextAdvance} from "../context";
import {Loading} from "components/shared/Loading/Loading";
import {prepareName} from "helpers/fileNaming";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";

import "./AdvanceStatusLine.scss";

interface iAdvanceStatusLineProps {}

export const AdvanceStatusLine: FC<iAdvanceStatusLineProps> = () => {
  const {jobDetailsData} = useContextJobDetails();
  const {advanceDetails, previewFileData, onDownloadPdf} = useContextAdvance();
  const {
    anchor,
    toggleMenu,
    modal,
    setModal,
    toggleModal,
    saveAdvanceQuery,
    onSaveAdvance,
    onShowPreview,
    isLoadingPreview,
    onSaveToDropbox,
    isSavingToDropbox
  } = useAdvanceStatusLine();

  const isSent = !!advanceDetails?.sent_date;
  const isPaid = !!advanceDetails?.paid_date;
  const isApproved = isSent || isPaid;

  const getDate = (d: Date | string) => moment(d).format("MMM DD, YYYY");

  const closeModal = () => setModal(null);

  const statusRender = {
    0: null,
    1: (
      <>
        <Icon icon="sent" size="md" color="tangerine" />
        <span className="AdvanceStatusLine-sent">Sent</span>
        <span className="AdvanceStatusLine-date">
          {advanceDetails?.sent_date && getDate(advanceDetails?.sent_date)}
        </span>
        <Button size="sm" color="outline" onClick={() => setModal("pay")}>
          Mark as paid
        </Button>
      </>
    ),
    2: (
      <>
        <Icon className="paid-icon" icon="check" size="sm" color="white" />
        <span className="AdvanceStatusLine-paid">Paid</span>
        <span className="AdvanceStatusLine-date">
          {advanceDetails?.paid_date && getDate(advanceDetails?.paid_date)}
        </span>
      </>
    )
  };

  const fileName = prepareName(
    [
      moment().format("YYYYMMDD"),
      jobDetailsData?.name || "",
      String(jobDetailsData?.id) || "",
      advanceDetails?.name || ""
    ],
    "_ADVANCE.pdf"
  );
  return (
    <>
      <JobBudgetStatusBarLayout
        onSaveExportInfo={onSaveAdvance}
        isApproved={isApproved}
        onSaveToDropbox={onSaveToDropbox}
        onShowPreview={onShowPreview}
        isSaving={saveAdvanceQuery.isLoading && modal !== "preview"}
        isUploadingDropbox={isSavingToDropbox && modal !== "preview"}
        onDownloadPdf={onDownloadPdf}
      >
        <div className="AdvanceStatusLine">
          {advanceDetails && statusRender[advanceDetails.status]}

          {!isSent && !isPaid && (
            <>
              <Button onClick={toggleMenu} size="sm" color="outline">
                mark as <Icon icon={!!anchor ? "triangle-up" : "triangle-down"} size="xsm" />
              </Button>
              <DropdownMenu
                className="AdvanceStatusLine-markMenu"
                anchor={anchor}
                onClose={() => toggleMenu(null)}
              >
                <Button
                  width="fullWidth"
                  justifyContent="justifyContentStart"
                  size="sm"
                  onClick={() => toggleModal("send")}
                >
                  sent
                </Button>
                <Button
                  width="fullWidth"
                  justifyContent="justifyContentStart"
                  textAlign="left"
                  size="sm"
                  onClick={() => toggleModal("pay")}
                >
                  paid
                </Button>
              </DropdownMenu>
            </>
          )}
        </div>
      </JobBudgetStatusBarLayout>

      {(isLoadingPreview || modal === "preview") && (
        <ModalPreviewPdf
          withButton
          onClose={closeModal}
          pdfUrl={previewFileData?.url}
          isUploadingDropbox={isSavingToDropbox}
          saveOnDropbox={onSaveToDropbox}
          fileName={fileName}
        >
          {isLoadingPreview && <Loading type="inBlock" />}
          {modal === "preview" && <AdvanceInvoicePreview />}
        </ModalPreviewPdf>
      )}

      <AdvancePay show={modal === "pay"} onClose={closeModal} />
      <AdvanceSend show={modal === "send"} onClose={closeModal} />
    </>
  );
};
