import {FormEvent} from "react";
import {tEstimateGroup, tEstimateRowData, tPostEstimateLineDescription} from "api/budget/estimate/types"; // prettier-ignore
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {useHttp} from "hooks/httpServices/useHttp";
import {estimateApi} from "api/budget/estimate";
import {useTextInputReturn, useTextInput} from "hooks/useTextInput/useTextInput";
import {useContextJobBudget} from "../../_context/JobBudgetContext";

type tProps = {
  currItem: tEstimateRowData;
  group: tEstimateGroup;
};

export const useSubmitEstimateDescriptions = ({currItem, group}: tProps) => {
  const {setEstimate} = useContextJobBudget();
  const {call, isLoading} = useHttp();

  const form: Record<keyof tPostEstimateLineDescription, useTextInputReturn> = {
    description: useTextInput({value: currItem?.description, isRequired: false}),
    discount_description: useTextInput({value: currItem?.discount_description, isRequired: false}),
    private_memo: useTextInput({value: currItem?.private_memo, isRequired: false})
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const data: Partial<Record<keyof tPostEstimateLineDescription, string>> = {
        description: form.description.value,
        discount_description: form.discount_description.value,
        private_memo: form.private_memo.value
      };

      const res = await call(estimateApi.updateLineDescription(currItem.id, data));

      setEstimate(prev => {
        if (!prev) return prev;
        const newGroups = prev.category_values?.map(gr => {
          if (gr?.id === group?.id && !!gr?.cost_values?.length) {
            const newCostValues = gr.cost_values?.map(item => {
              if (item.id === currItem.id) {
                return res?.data?.data;
              }
              return item;
            });

            return {...gr, cost_values: newCostValues};
          }
          return gr;
        });
        return {...prev, category_values: newGroups};
      });
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return {onSubmit, isLoading, form};
};
