import {FC, useEffect, useState} from "react";
import {Button} from "components/shared/Button/Button";
import {iTeammateDetailsData, tTeammatePostData} from "api/team/types";
import {Deactivate} from "./Deactivate";
import {Loading} from "components/shared/Loading/Loading";
import {team} from "api/team";
import {useHttp} from "hooks/httpServices/useHttp";
import {TeamMemberForm, TEAM_FORM_ID} from "./TeamForm/TeamMemberForm";
import {useContextTeamDetails} from "../TeamDetailsContext";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {getApiError} from "helpers/getApiError";
import {useContextProfile} from "context";
import "./TeamMemberForms.scss";

interface iTeamMemberEditProps {
  onCloseModal: () => void;
  closeEditModal: () => void;
  teammateDetail: iTeammateDetailsData;
  onEditCallback?: Function;
}

export const TeamMemberEdit: FC<iTeamMemberEditProps> = ({
  teammateDetail,
  onCloseModal,
  closeEditModal,
  onEditCallback
}) => {
  const {userData, getUser} = useContextProfile();
  const {departmentsList, roleList, officeLocationList, getAdditionalFormData, additionalFormDataLoading} = useContextTeamDetails(); // prettier-ignore
  const [resMsg, setResMsg] = useState<{title: string; infoText: string} | null>(null);
  const isCurrUser = userData?.id === teammateDetail.id;
  const success = "Success !";
  const err = "OH UH !";

  useEffect(() => {
    getAdditionalFormData();
  }, []); // eslint-disable-line

  const {call, isLoading: updateLoading} = useHttp();
  const onUpdate = async (formData: tTeammatePostData, form: any) => {
    try {
      await call(team.updateTeamMate(teammateDetail.id, formData));
      setResMsg({title: success, infoText: `${formData.first_name}'s info has been updated`});
    } catch (error) {
      const {msg} = getApiError(error, form);
      setResMsg({title: err, infoText: msg || "Update error !"});
    }
  };

  const confirmHandler = () => {
    if (resMsg?.title === success) {
      onCloseModal();
      onEditCallback?.();
      isCurrUser && getUser();
    }
    resMsg?.title === err && setResMsg(null);
  };

  return (
    <div className="TeamMemberForms">
      <div className="TeamMemberForms-header">
        <h2 className="h2">Edit Account</h2>
      </div>

      <div className="TeamMemberForms-body">
        {additionalFormDataLoading && <Loading type="inBlock" />}
        {!additionalFormDataLoading && (
          <>
            <TeamMemberForm
              departmentsList={departmentsList}
              roleList={roleList}
              officeLocationList={officeLocationList}
              initData={teammateDetail}
              submitCallback={onUpdate}
              type="update"
            />

            {!isCurrUser && (
              <Deactivate teammateDetail={teammateDetail} onCloseModal={onCloseModal} />
            )}
          </>
        )}
      </div>

      <div className="TeamMemberForms-footer">
        <Button size="md" onClick={closeEditModal}>
          Cancel
        </Button>
        {!additionalFormDataLoading && (
          <Button size="md" color="light" form={TEAM_FORM_ID} type="submit" loading={updateLoading}>
            Save
          </Button>
        )}
      </div>

      <ConfirmWindow
        show={!!resMsg}
        title={resMsg?.title}
        confirmBtn={{text: "close"}}
        onConfirm={confirmHandler}
      >
        <div className="TeamMemberConfirmMessage">{resMsg?.infoText}</div>
      </ConfirmWindow>
    </div>
  );
};
