import {FC, useState, MouseEvent, useEffect} from "react";
import {Button} from "components/shared/Button/Button";
// import moment from "moment";
// import {formatQueryCalendarDateRange} from "../helpers";
// import {useContextJobCalendar} from "../context";

interface iJobSchedulerProps {
  scheduler: any;
  setModeState: (mode: string) => void;
}
export const JobSchedulerControls: FC<iJobSchedulerProps> = ({setModeState, scheduler}) => {
  // const {apiParams} = useContextJobCalendar();
  const {mode} = scheduler?.getState() || "month";
  const [tabActive, setTabActive] = useState("");

  useEffect(() => {
    setTabActive(mode);
  }, [mode]);

  const handleClickTodayButton = () => {
    // const {min_date, max_date} = formatQueryCalendarDateRange(new Date());
    // apiParams.setApiParams({params: {min_date, max_date}});
    scheduler._click.dhx_cal_today_button();
  };

  // function setParamDate(direction: "next" | "prev", step: "months" | "weeks" = "months") {
  //   const currentDateParam = moment(date).startOf(step === "months" ? "month" : "week");
  //   const params = {
  //     next: moment(currentDateParam).add(1, step).format("YYYY-MM-DD"),
  //     prev: moment(currentDateParam).subtract(1, step).format("YYYY-MM-DD")
  //   };
  //   apiParams.setApiParams({
  //     params: formatQueryCalendarDateRange(params[direction])
  //   });
  // }

  const handleClickPrevNavButton = () => {
    // const step = mode === "week" ? "weeks" : "months";
    // setParamDate("prev", step);
    scheduler._click.dhx_cal_prev_button();
  };

  const handleClickNextNavButton = () => {
    // const step = mode === "week" ? "weeks" : "months";
    //setParamDate("next", step);
    scheduler._click.dhx_cal_next_button();
  };

  const onChangeView = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    const name = event.currentTarget.name;
    setTabActive(name);
    setModeState(name);
    scheduler.setCurrentView(scheduler._date, name);
  };

  return (
    <div className="JobScheduler-controls ">
      <div className="JobScheduler-nav">
        <button
          onClick={handleClickPrevNavButton}
          className="JobScheduler-nav-button-prev"
          aria-label="Prev"
          disabled={mode === "agenda"}
        />
        <Button
          size="sm"
          color="outline"
          onClick={handleClickTodayButton}
          className="JobScheduler-nav-today"
        >
          Today
        </Button>

        <button
          onClick={handleClickNextNavButton}
          className="JobScheduler-nav-button-next"
          aria-label="Next"
          disabled={mode === "agenda"}
        />
      </div>
      <div className="JobScheduler-views">
        <button
          name="month"
          onClick={onChangeView}
          className={`JobScheduler-views-button ${tabActive === "month" ? "active" : ""}`}
        >
          Month
        </button>
        <button
          name="week"
          onClick={onChangeView}
          className={`JobScheduler-views-button ${tabActive === "week" ? "active" : ""}`}
        >
          week
        </button>
        <button
          name="agenda"
          onClick={onChangeView}
          className={`JobScheduler-views-button ${tabActive === "agenda" ? "active" : ""}`}
        >
          agenda
        </button>
      </div>
    </div>
  );
};
