import {tLinks} from "api/jobs/types";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import { eventBus } from "EventBus/EventBus";
import {copyToClipboard} from "helpers/supporters";
import {FC, MouseEvent} from "react";

type tCopyLInkPasswordProps = {
  link: tLinks;
};

export const CopyLInkPassword: FC<tCopyLInkPasswordProps> = ({link}) => {


  const copyPassword = (event: MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    event.stopPropagation();
    copyToClipboard(link?.password || "");
    eventBus.dispatch("showToast", {text: "Password was copied" });
  };

  const tooltipMsg =  <div>click to copy <br/> {link?.password}</div>; // prettier-ignore

  return (
    <Tooltip message={tooltipMsg} position={"top"} onClick={event => copyPassword(event)}>
      <Button className="LinkBlock-lock" onClick={event => copyPassword(event)}>
        <Icon icon="lock" size="sm" color="gray" />
      </Button>
    </Tooltip>
  );
};
