import { AxiosRequestConfig } from "axios";
import { iNotificationsParams, tNotificationsSettingsPostData } from "./types";

const notificationsUrl = "notifications";

export const notificationsApi = {
  getProfileNotifications: (params?: iNotificationsParams): AxiosRequestConfig => ({
    method: "get",
    url: notificationsUrl,
    params
  }),
  markAllNotificationsAsRead: (): AxiosRequestConfig => ({
    method: "post",
    url: `${notificationsUrl}/read`
  }),
  markNotificationAsRead: (notification_id: number): AxiosRequestConfig => ({
    method: "post",
    url: `notification/${notification_id}/read`
  }),
  updateNotificationSettings: (data: tNotificationsSettingsPostData): AxiosRequestConfig => ({
    method: "post",
    url: `${notificationsUrl}/setting`,
    data
  }),
  getNotificationsSettings: (): AxiosRequestConfig => ({
    method: "get",
    url: `${notificationsUrl}/setting`
  })
};
