import {FC} from "react";
import moment from "moment";
import {Icon} from "components/Icon/Icon";
import {TextComponent} from "../../common/TextComponent/TextComponent";

import "./StatusBarText.scss";

interface iStatusBarTextProps {
  schedule?: string | null;
  sentTime: string | null;
  hasDelivery: boolean;
  hasPrevConfirmations: boolean;
}

export const StatusBarText: FC<iStatusBarTextProps> = ({
  hasPrevConfirmations,
  hasDelivery,
  schedule,
  sentTime
}) => {
  if (!hasDelivery) return null;

  const tz = new Date()
    .toLocaleDateString("en-US", {
      day: "2-digit",
      timeZoneName: "short"
    })
    .slice(3, 7);

  const date =  moment(sentTime || schedule ).format("dddd, MMMM Do [at] h:mmA") + tz; // prettier-ignore

  if (hasDelivery && !schedule && !sentTime && !hasPrevConfirmations) {
    return (
      <TextComponent weight="500" size="14">
        Sending call sheet in progress
      </TextComponent>
    );
  }

  return (
    <div className="StatusBarText">
      {(sentTime || schedule) && (
        <>
          <Icon icon="sent" size="md" color={sentTime ? "green" : "tangerine"} />
          <TextComponent weight="500" size="14" color={sentTime ? "green-0" : "tangerine-0"}>
            {sentTime ? "Sent on" : "Scheduled for"} {date || ""}
          </TextComponent>
        </>
      )}
    </div>
  );
};
