import {FC, useState} from "react";
import {tJobTasksGroup} from "api/jobs/tasks/types";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {TableTaskRow} from "../TableTaskRow/TableTaskRow";
import {EditButton} from "./EditButton/EditButton";
import {TableTaskRowInit} from "../TableTaskRow/TableTaskRowInit";
import {jobTasksTableColumns} from "../jobTasksTableColumns";

type tTableGroupProps = {
  data: tJobTasksGroup;
};

export const TableGroup: FC<tTableGroupProps> = ({data}) => {
  const [showTasks, setShowTasks] = useState(true);

  return (
    <>
      <tr>
        <td colSpan={jobTasksTableColumns.length}>
          <div className="JobTaskTable-group">
            <Button className="groupToggleBtn" onClick={() => setShowTasks(prev => !prev)}>
              <Icon icon={showTasks ? "triangle-down" : "triangle-right"} size="xsm" />
            </Button>

            <TextComponent
              as="button"
              size="17"
              uppercase
              onClick={() => setShowTasks(prev => !prev)}
            >
              {data?.name}
            </TextComponent>

            <EditButton data={data} />
          </div>
        </td>
      </tr>

      {showTasks && (
        <>
          <TableTaskRow groupData={data} />
          <TableTaskRowInit data={data} />
        </>
      )}
    </>
  );
};
