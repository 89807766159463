import {FC, useEffect, useRef, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {useHttp} from "hooks/httpServices/useHttp";
import {FavRow} from "./FavRow";
import {Loading} from "components/shared/Loading/Loading";
import {favorites} from "api/favorites";
import {ContactsContextProvider} from "pages/Contacts/context/ContactsContext";
import {ContactsDetailsContextProvider} from "pages/Contacts/context/ContactsDetailsContext";
import {TeamContextProvider} from "pages/Team/TeamContext";
import {TeamDetailsContextProvider} from "pages/Team/TeamDetailsContext";
import {eventBus} from "EventBus/EventBus";
import {iFavoritesReturn} from "api/favorites/types";
import {useOnClickOutside} from "hooks/useOnclickOutside";
import {useContextApp} from "context";
import "./Favorites.scss";

export const Favorites: FC = () => {
  return (
    <ContactsContextProvider>
      <ContactsDetailsContextProvider>
        <TeamContextProvider>
          <TeamDetailsContextProvider>
            <FavoritesComponent />
          </TeamDetailsContextProvider>
        </TeamContextProvider>
      </ContactsDetailsContextProvider>
    </ContactsContextProvider>
  );
};

const FavoritesComponent: FC = () => {
  const {sidebar} = useContextApp();
  const [showList, setShowList] = useState(false);
  const {data, call, isLoading} = useHttp();

  const btnRef = useRef<HTMLDivElement>(null);
  const favouritesRef = useRef<HTMLDivElement | null>(null);
  const favoritesArr: iFavoritesReturn[] | [] = data?.data?.data || [];
  useOnClickOutside(favouritesRef, () => sidebar && setShowList(false));

  function getFavoritesList() {
    return call(favorites.getList());
  }

  useEffect(() => {
    eventBus.on("favoritesRefetch", getFavoritesList);
    return () => {
      eventBus.remove("favoritesRefetch", getFavoritesList);
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (showList || showList === undefined) call(favorites.getList());
  }, [showList]); // eslint-disable-line

  useEffect(() => {
    sidebar && showList && setShowList(false);
  }, [sidebar]); // eslint-disable-line

  const getPopUpListTop = () => {
    if (!sidebar || !btnRef?.current) return "0";
    return btnRef.current.getBoundingClientRect().top + "px";
  };

  const top = sidebar ? getPopUpListTop() : "0";

  const noFavorites = !favoritesArr?.length && !isLoading;

  return (
    <div className="Favorites" ref={favouritesRef}>
      <div className={`Favorites-btn-container ${showList ? "active" : ""}`} ref={btnRef}>
        <Button
          className={`Favorites-btn ${showList ? "active" : ""}`}
          onClick={() => setShowList(!showList)}
        >
          <Icon icon="star" className="Favorites-btn-icon btn-icon" />
          <span className="Favorites-btn-text">Favorites</span>
          <Icon
            icon="arrow-down"
            className={`Favorites-btn-arrow-icon btn-arrow-icon ${showList ? "up" : ""}`}
            size="md"
          />
        </Button>
      </div>
      {showList && (
        <div
          className={`${
            sidebar ? "Favorites-collapsedList-container" : "Favorites-list-container"
          } ${noFavorites ? "noFavorites" : ""}`}
          style={{...(sidebar && {top})}}
        >
          {favoritesArr?.map(item => (
            <FavRow data={item} key={item.id} />
          ))}
          {isLoading && !favoritesArr?.length && (
            <div className="Favorites-list-loader">
              <Loading type="inBlock" bgColor="dark" />
            </div>
          )}
          {noFavorites && <div>No favorites yet</div>}
        </div>
      )}
    </div>
  );
};
