import {FC, ReactNode, useEffect, useState} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {iTeammateDetailsData} from "api/team/types";
import {TeamMemberDetails} from "components/shared/Modals/TeamModals/TeamDetailInfo/TeamMemberDetails/TeamMemberDetails";
import {TeamMemberPending} from "components/shared/Modals/TeamModals/TeamDetailInfo/TeamMemberPending/TeamMemberPending";
import {Loading} from "components/shared/Loading/Loading";
import {TeamMemberEdit} from "pages/Team/TeamMemberForms/TeamMemberEdit";
import {tStatus} from "api/common/types";
import {useHttp} from "hooks/httpServices/useHttp";
import {team} from "api/team";
import "./TeamModals.scss";

interface iTeamModals {
  teammate: number | iTeammateDetailsData;
  closeTeamModal: () => void;
  onEditCallback?: Function;
}

export const TeamModals: FC<iTeamModals> = ({teammate, closeTeamModal, onEditCallback}) => {
  const [teammateDetail, setTeammateDetail] = useState<iTeammateDetailsData | null>(null);
  const [editModal, setEditModal] = useState(false);
  const {isLoading, call} = useHttp();

  const is = !!teammateDetail;
  const status = teammateDetail?.status?.name || "";
  const show = !isLoading && status && !editModal;

  useEffect(() => {
    if (!teammate) return;
    if (typeof teammate === "number") {
      call(team.getTeammateDetail(teammate)).then(({data: {data}}) => setTeammateDetail(data));
      return;
    }
    setTeammateDetail(teammate);
  }, [teammate]); // eslint-disable-line

  const openEdit = () => setEditModal(true);
  const closeEditModal = () => setEditModal(false);

  const closeModal = () => {
    setEditModal(false);
    closeTeamModal();
  };

  const modals: Partial<Record<tStatus["name"] | "Edit", ReactNode>> = {
    Active: is &&  <TeamMemberDetails teammateDetail={teammateDetail} type="active" openEdit={openEdit} onCloseModal={closeModal}/>, // prettier-ignore
    Deactivated: is && <TeamMemberDetails teammateDetail={teammateDetail} type="inactive" onCloseModal={closeModal} />, // prettier-ignore
    Pending: is && <TeamMemberPending teammateDetail={teammateDetail} />,
    Edit: is && <TeamMemberEdit onEditCallback={onEditCallback} onCloseModal={closeModal} closeEditModal={closeEditModal} teammateDetail={teammateDetail} /> // prettier-ignore
  };

  return (
    <ModalPopup
      className="TeamMemberModal"
      show={true}
      onClose={editModal ? closeEditModal : closeModal}
    >
      {isLoading && <Loading type="inBlock" />}
      {show && modals[status]}
      {editModal && modals["Edit"]}
    </ModalPopup>
  );
};
