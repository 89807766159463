// import {usePhoneInput} from "hooks/usePhoneInput";
import {useEffect} from "react";
import {tUseDynamicInputsGroupForm} from "./types";
import {useSelectInput} from "components/shared/FormComponents/SelectInput/useSelectInput";
import { useTextInput } from "hooks/useTextInput/useTextInput";

export const useDynamicInputsGroupForm = (props: tUseDynamicInputsGroupForm) => {
  const {options, isFirstItem, itemField, onChangeHandler} = props;

  const select = useSelectInput({
    options,
    value: itemField.id?.toString() || options?.[0]?.id,
    customValue: itemField?.type_custom || "",
    targetKeys: {value: "id", label: "name"},
    placeholder: "Select...",
    isRequired: !isFirstItem
  });

  const phone = useTextInput({
    validators: ["phone"],
    filters: ["phone"],
    isRequired: !isFirstItem,
    value: itemField.value
  });

  useEffect(() => {
    const changedAcc = {
      uid: itemField.uid,
      id: select.selected?.id || options?.[0]?.id,
      value: phone.value,
      name: select.selected?.name || options?.[0]?.name,
      type_custom: select.customInput.value
    };
    onChangeHandler(changedAcc);
    return;
  }, [select.value, select.customInput.value, select.selected, phone.value]); // eslint-disable-line

  return {select, phone};
};
