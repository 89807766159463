import {FC, useState} from "react";
import {ToggleEstimateDetailsButton} from "./ToggleEstimateDetailsButton/ToggleEstimateDetailsButton";
import {EstimateTotalCard} from "./EstimateTotalCard/EstimateTotalCard";
import {EstimateSidebarDetails} from "./EstimateSidebarDetails/EstimateSidebarDetails";
import {EstimateSidebarActions} from "./EstimateSIdebarActions/EstimateSIdebarActions";
import {useContextJobBudget} from "../../../context/JobBudgetContext";
import {EstimateListWrap} from "./EstimateListWrap/EstimateListWrap";
import {useContextProfile} from "context";
import "./EstimateSidebar.scss";

type tEstimateSidebarProps = {
  heightSidebar?: number;
};

export const EstimateSidebar: FC<tEstimateSidebarProps> = ({heightSidebar}) => {
  const {userData} = useContextProfile();
  const {estimate} = useContextJobBudget();
  const [showDetails, setShowDetails] = useState(false);
  const toggleShowDetails = () => setShowDetails(prev => !prev);

  const withTooltip = userData?.tooltips?.find(item => item === "PRODUCTION %") ? '' : 'withTooltip'; // prettier-ignore

  return (
    <div
      className={`EstimateSidebar ${showDetails ? "showDetails" : ""}`}
      style={{height: `calc(100vh - ${heightSidebar}px)`}}
    >
      <EstimateListWrap data={estimate} />

      <div className={`EstimateSidebar-wrapBottomPart ${withTooltip}`}>
        <ToggleEstimateDetailsButton
          showDetails={showDetails}
          toggleShowDetails={toggleShowDetails}
        />

        {showDetails && <EstimateSidebarDetails />}

        <EstimateTotalCard showDetails={showDetails} />

        {showDetails && !estimate?.approved_at && <EstimateSidebarActions />}
      </div>
    </div>
  );
};
