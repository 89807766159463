import {FC} from "react";
import {Tag} from "components/shared/Tag/Tag";
import {iBaseAccount, iEmail} from "api/interfaces";
import {DetailsTypeList} from "components/shared/DetailsTypeList/DetailsTypeList";
import {AccountsList} from "components/shared/AccountsList/AccountsList";
import {makeTypedList} from "helpers/misc";
import {iTag} from "api/tags/interfaces";

import "./PeopleDetailsGeneralInfo.scss";

interface iPeopleDetailsGeneralInfo {
  accounts: iBaseAccount[];
  phones: any[];
  emails: iEmail[];
  locales: iTag[];
  labels: iTag[];
}

export const PeopleDetailsGeneralInfo: FC<iPeopleDetailsGeneralInfo> = ({
  emails,
  phones,
  locales,
  labels,
  accounts
}) => {
  const emptySection = <span className="PeopleDetailsGeneralInfo-empty">n/a</span>;
  const isLocales = isValidArray(locales);
  const isLabels = isValidArray(labels);
  const isAccount = isValidArray(accounts);
  const isPhones = isValidArray(phones);
  const isEmails = isValidArray(emails);

  const phonesList = makeTypedList(
    phones.map(item => ({
      ...item,
      value: item.value
      // value: formatPhoneNumber({value: item.value})
    }))
  );

  const emailsList = makeTypedList(emails);

  function isValidArray<T extends Array<any>>(value: T) {
    return Array.isArray(value) && !!value.length;
  }

  return (
    <div className="PeopleDetailsGeneralInfo">
      {(isPhones || isEmails) && (
        <div className="PeopleDetailsGeneralInfo-section">
          {isPhones && <DetailsTypeList list={phonesList} title="Phone" />}
          {isEmails && <DetailsTypeList list={emailsList} title="Email" />}
        </div>
      )}

      {(isLocales || isLabels || isAccount) && (
        <div className="PeopleDetailsGeneralInfo-section">
          {(isLocales || isLabels) && (
            <>
              <h5 className="PeopleDetailsGeneralInfo-title">Tags</h5>
              <div
                className={`PeopleDetailsGeneralInfo-group ${
                  !isLocales || !isLabels ? "empty" : ""
                }`}
              >
                {isLocales && (
                  <div className="PeopleDetailsGeneralInfo-group-wrapper">
                    <div className={`PeopleDetailsGeneralInfo-label ${!isLocales ? "empty" : ""}`}>
                      Locales
                    </div>
                    {!isLocales && emptySection}
                    {isLocales && <TagsRow data={locales} />}
                  </div>
                )}

                {isLabels && (
                  <div className="PeopleDetailsGeneralInfo-group-wrapper">
                    <div className={`PeopleDetailsGeneralInfo-label ${!isLabels ? "empty" : ""}`}>
                      Labels
                    </div>
                    {!isLabels && emptySection}
                    {isLabels && <TagsRow data={labels} />}
                  </div>
                )}
              </div>
            </>
          )}

          {isAccount && (
            <>
              <h5 className="PeopleDetailsGeneralInfo-title">Accounts</h5>
              <AccountsList accountsList={accounts} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

const TagsRow = ({data}: {data: any[]}) => {
  return (
    <div className="PeopleDetailsGeneralInfo-value">
      {data.map(data => (
        <Tag key={data.id} size="lg">
          {data.name}
        </Tag>
      ))}
    </div>
  );
};
