import {FC, ReactNode} from "react";
import {tTrackExpenseLine} from "api/budget/track/types";
import {Button} from "components/shared/Button/Button";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {Loading} from "components/shared/Loading/Loading";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import "./DropdownCellWrap.scss";

type tDropdownCellWrapProps = {
  className?: string;
  trackLine: tTrackExpenseLine;
  isLoading: boolean;
  emptyBtnCallback?: () => void;
  children: ReactNode;
  menuAnchor: HTMLElement | null;
  setMenuAnchor: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  value: string;
  style?: React.CSSProperties | undefined;
};

export const DropdownCellWrap: FC<tDropdownCellWrapProps> = ({
  className,
  trackLine,
  isLoading,
  emptyBtnCallback,
  children,
  menuAnchor,
  setMenuAnchor,
  value,
  style
}) => {
  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  return (
    <div className={`${className} DropdownCellWrap`}>
      {isLoading && <Loading type="inBlock" customSize={0.5} />}

      <TextComponent
        onClick={showMenu}
        as="button"
        size="10"
        weight="500"
        uppercase
        color={!!trackLine?.pay_value ? "black-10" : "gray-20"}
        style={style}
        className="DropdownCellWrap-btn"
      >
        {value}
      </TextComponent>

      <DropdownMenu anchor={menuAnchor} onClose={() => setMenuAnchor(null)} withSubmenu btnHover>
        {children}

        {emptyBtnCallback && (
          <Button
            size="sm"
            className="btn"
            onClick={emptyBtnCallback}
            style={{color: "var(--gray-20)"}}
          >
            (Empty)
          </Button>
        )}
      </DropdownMenu>
    </div>
  );
};
