import {FC} from "react";
import {DepartmentList} from "components/shared/DepartmentList/DepartmentList";
import {tSchedulerEvent} from "../SchedulerDashboard";

interface iSchedulerEventBar {
  event: tSchedulerEvent;
}

export const SchedulerEventBar: FC<iSchedulerEventBar> = ({event}) => {
  return (
    <div className="SchedulerEventBar">
      {!!event?.departments?.length && (
        <div className="SchedulerEventBar-pipeline">
          <DepartmentList data={event?.departments} />
        </div>
      )}

      <div className="SchedulerEventBar-text">{event?.name}</div>
    </div>
  );
};
