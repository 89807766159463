import {FC, useEffect, useState} from "react";
import {tEstimateRowData} from "api/budget/estimate/types";
import {useHttp} from "hooks/httpServices/useHttp";
import {estimateApi} from "api/budget/estimate";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {EstimateCellWithDropdown} from "../../_common/EstimateCellWithDropdown/EstimateCellWithDropdown";
import {Loading} from "components/shared/Loading/Loading";
import {useContextJobBudget} from "../../../_context/JobBudgetContext";

type tEstimateRowPayRollProps = {
  currItem: tEstimateRowData;
};

export const EstimateRowPayRoll: FC<tEstimateRowPayRollProps> = ({currItem}) => {
  const {resources, setEstimate} = useContextJobBudget();
  const [value, setValue] = useState<tEstimateRowData["payroll"] | null>(null);

  useEffect(() => {
    setValue(currItem.payroll);
  }, [currItem]);

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const {call, isLoading} = useHttp();

  const onSubmit = async (value: tEstimateRowData["payroll"]) => {
    setMenuAnchor(null);
    setValue(() => value);
    if (!value) return;
    try {
      const res = await call(estimateApi.updateLine(currItem.id, {payroll_id: value.id}));
      setMenuAnchor(null);

      setEstimate(() => res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <div className="EstimateRowPayRoll posRelative">
      {isLoading && <Loading type="inBlock" bgColor="grey-40" customSize={0.5} />}

      <EstimateCellWithDropdown
        isData={!!value}
        listItemKey="name"
        disabled={isLoading}
        list={resources?.payrolls || []}
        callBack={value => onSubmit(value)}
        menuAnchor={menuAnchor}
        setMenuAnchor={setMenuAnchor}
      >
        {value?.name ? value.name : "none"}
      </EstimateCellWithDropdown>
    </div>
  );
};
