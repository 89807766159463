import {useState, useEffect} from "react";

export const useInfiniteScroll = (element: HTMLDivElement | null, callback: Function) => {
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    element?.addEventListener("scroll", handleScroll);
    return () => element?.removeEventListener("scroll", handleScroll);
  }, [element]); // eslint-disable-line

  useEffect(() => {
    if (!isFetching) return;
    callback().then(() => {
      setIsFetching(false);
    });
  }, [isFetching]); // eslint-disable-line

  function handleScroll() {
    if (
      element!.scrollHeight > element!.scrollTop + document.documentElement.offsetHeight ||
      isFetching
    ) {
      return;
    }

    setIsFetching(true);
  }

  return {isFetching, setIsFetching};
};
