import {useLayoutEffect, useState} from "react";
import {estimateApi} from "api/budget/estimate";
import {tEstimateData, tResourcesData} from "api/budget/estimate/types";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextTemplateLayout} from "../../_common/TemplateLayoutContext";

export type tUseJobBudgetReturn = ReturnType<typeof useJobBudget>;

interface iUseJobBudgetProps {}

export function useJobBudget(props?: iUseJobBudgetProps) {
  const {id} = useContextTemplateLayout();
  const [estimate, setEstimate] = useState<tEstimateData | null>(null);
  const {call, isLoading} = useHttp();
  const [hideEmptyLines, setHideEmptyLines] = useState(false);

  useLayoutEffect(() => {
    if (!id) return;
    getEstimate();
    getEstimateResource();
  }, []); // eslint-disable-line

  const emptyCostFilter = (currEstimate: tEstimateData) => {
    const filtered = currEstimate?.category_values
      .map(item => ({...item, cost_values: item?.cost_values.filter(item => !!item?.cost)}))
      .filter(item => !!item?.cost_values?.filter(item => !!item?.cost)?.length);

    return {
      ...currEstimate,
      category_values: filtered
    };
  };

  const getEstimate = () => {
    if (!id) return;
    return call(estimateApi.getEstimate(+id)).then(res => {
      const currEstimate = res?.data?.data as tEstimateData;
      !!currEstimate.approved_at && setEstimate(() => emptyCostFilter(currEstimate));
      !currEstimate.approved_at && setEstimate(currEstimate);
    });
  };

  //estimate resources
  const [resources, setResources] = useState<tResourcesData | null>(null);
  const {call: resourcesCall, isLoading: resourcesLoading} = useHttp();
  const getEstimateResource = () => {
    if (!id) return;
    return resourcesCall(estimateApi.getEstimateResources(+id)).then(res => {
      const currEstimate = res?.data?.data as tResourcesData;
      setResources(currEstimate);
    });
  };

  const needToFilterEmptyLines = hideEmptyLines && estimate && !estimate?.approved_at;
  return {
    getEstimate,
    estimate: needToFilterEmptyLines ? emptyCostFilter(estimate) : estimate,
    isLoading,
    setEstimate,
    resources,
    setResources,
    emptyCostFilter,
    resourcesLoading,
    setHideEmptyLines,
    hideEmptyLines
  };
}
