import {FC, useState} from "react";
import {Button} from "components/shared/Button/Button";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {useContextTrack} from "../../../_context/TrackContext";
import {Categorized} from "../../../_modals/Categorized/Categorized";
import {tableTrackWAColumns} from "../tableTrackWAHeadItems";
import {UploadExpensesFile} from "./UploadExpenseFile";
import "./CategorizedHeader.scss";

type tCategorizedHeaderProps = {
  [key: string]: any;
};

export const CategorizedHeader: FC<tCategorizedHeaderProps> = () => {
  const {trackData} = useContextTrack();
  const [showModal, setModal] = useState(false);

  return (
    <tr>
      <td colSpan={tableTrackWAColumns?.length}>
        <div className="CategorizedHeader">
          <TextComponent size="17" weight="700" uppercase>
            TO BE CATEGORIZED ({trackData?.expense_files?.length})
          </TextComponent>
          <Button color="outline" size="sm" onClick={() => setModal(true)}>
            Categorize
          </Button>

          <UploadExpensesFile />

          {showModal && (
            <Categorized onClose={() => setModal(false)} files={trackData?.expense_files || []} />
          )}
        </div>
      </td>
    </tr>
  );
};
