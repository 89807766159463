import {ComponentPropsWithoutRef, ElementType, PropsWithChildren} from "react";
import "./TextComponent.scss";

//spacings
type tSides = "t" | "b" | "r" | "l" | "x" | "y";
type tSpaceAmount = "4" | "8" | "12" | "16" | "20" | "24" | "26" | "28" | "32" | "36" | "40";
type paddings = `p-${tSpaceAmount}` | `p${tSides}-${tSpaceAmount}`;
type margins = `m-${tSpaceAmount}` | `m${tSides}-${tSpaceAmount}`;
//spacings

type tColors = "black-10" | "gray-10" | "gray-0" | "gray-20" | "red" | "white-0" | "green-0" | "green-30"| "tangerine-0"; // prettier-ignore

type AsProp<C extends ElementType> = {as?: C};
type PropsToOmit<C extends ElementType, P> = keyof (AsProp<C> & P);
type PolymorphicComponentsProps<C extends ElementType, Props = {}> = Props &
  AsProp<C> &
  Omit<ComponentPropsWithoutRef<C>, PropsToOmit<C, Props>>;

export type tText = {
  weight?: "700" | "500" | "400" | "300";
  size?: "26" | "20" | "17" | "15" | "14" | "13" | "11" | "10";
  color?: tColors;
  uppercase?: boolean;
  lowercase?: boolean;
  center?: boolean;
  nowrap?: boolean;
  h100?: boolean;
  w100?: boolean;
  truncate?: boolean;
  textAlign?: "left" | "center" | "right" | "";
  buttonFlex?: boolean;
  p?: Array<paddings> | paddings;
  m?: Array<margins> | margins;
  className?: string;
};

export const TextComponent = <C extends ElementType>({
  children,
  color = "black-10",
  weight = "700",
  size = "20",
  h100,
  w100,
  truncate,
  uppercase,
  lowercase,
  center,
  nowrap,
  className,
  textAlign = "",
  buttonFlex,
  as,
  p,
  m,
  mTEST,
  ...rest
}: PropsWithChildren<PolymorphicComponentsProps<C, tText>>) => {
  const height100 = h100 ? "h100" : "";
  const width100 = w100 ? "w100" : "";
  const align = center ? "center" : "";
  const truncateS = truncate ? "truncate" : "";
  const transform = !!uppercase ? "uppercase" : "";
  const transformLC = lowercase ? "lowercase" : "";
  const no_wrap = !!nowrap ? "nowrap" : "";
  const tAlign = textAlign ? `text-align-${textAlign}` : "";
  const btnFlex = buttonFlex ? "buttonFlex" : "";
  const padding = !!p ? (Array.isArray(p) ? `${p?.join(" ")}` : p) : "";
  const margin = !!m ? (Array.isArray(m) ? `${m?.join(" ")}` : m) : "";

  const classes = ['TextComponent',padding, margin, btnFlex, `size-${size}`, `weight-${weight}`, `color-${color}`, tAlign , align, transform, transformLC, no_wrap, truncateS, height100, width100, className ].filter(item => !!item); // prettier-ignore
  const Component = as || "div";

  return (
    <Component {...rest} className={classes.join(" ")}>
      {children}
    </Component>
  );
};
