import {CSSProperties, FC} from "react";
import "./Loading.scss";

interface iLoadingProps {
  className?: string;
  type?: "inBlock";
  bgColor?: "light" | "dark" | "transparent" | "grey-40";
  size?: "lg";
  customSize?: number; // px, rem, em...
  style?: CSSProperties;
  progressBar?: boolean;
  progress?: number;
}

export const Loading: FC<iLoadingProps> = ({
  className = "",
  type = "",
  bgColor = "light",
  size = "md",
  customSize,
  style = {},
  progressBar,
  progress = 0
}) => {
  const progressBarClass = progressBar ? "is-progress" : "";
  const progressValue = Math.ceil((180 / 100) * progress);
  return (
    <div
      className={`Loading ${bgColor} ${type} ${className} ${size} ${progressBarClass}`}
      style={{fontSize: `${customSize}rem`, ...style}}
    >
      {progressBar && (
        <div className="Loading-progressBar">
          <div className="circle">
            <div className="mask full" style={{transform: `rotate(${progressValue}deg)`}}>
              <div className="fill" style={{transform: `rotate(${progressValue}deg)`}} />
            </div>
            <div className="mask half">
              <div className="fill" style={{transform: `rotate(${progressValue}deg)`}} />
            </div>
            <div className="inside-circle">{progress}%</div>
          </div>

          <p className="Loading-progressBar-text">Loading</p>
        </div>
      )}
      {!progressBar && (
        <div className="Loading-block">
          <div />
          <div />
          <div />
        </div>
      )}
    </div>
  );
};
