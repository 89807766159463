import {useEffect} from "react";
import {useSelect} from "hooks/inputHooks";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {iPersonDetails} from "../../../interfaces";
import {useContextContactsDetails} from "pages/Contacts/context/ContactsDetailsContext";
import {useCheckBoxGroupe} from "hooks/inputHooks/useCheckBoxGroupe";
import {useSelectInput} from "components/shared/FormComponents/SelectInput/useSelectInput";

export type tUsePeopleFormOther = ReturnType<typeof usePeopleFormOther>;
interface iProps {
  person: iPersonDetails | undefined | null;
  tab: number;
}
export const usePeopleFormOther = ({person, tab}: iProps) => {
  const context = useContextContactsDetails();
  const {dietaryRestriction, relationships, departments, pronoun} = context;
  const {isLoadingOther, getAdditionalFormDataOther} = context;

  const departmentsInit = person?.departments.map(item => item?.id) || [];
  const dietaryRestrictionInit = person?.dietary_restrictions.map(item => item.id);

  useEffect(() => {
    tab === 3 && getAdditionalFormDataOther();
  }, [tab]); // eslint-disable-line

  useEffect(() => {
    !!pronoun.length && !formData.pronoun.options?.length && formData.pronoun.setOptions(pronoun);
  }, [pronoun]); // eslint-disable-line

  const formData = {
    relationshipToVersatile: useSelect({
      value: person?.versatile_relationship?.id?.toString() || "",
      isRequired: false,
      targetKeys: {value: "id", label: "name"}
    }),
    departments: useCheckBoxGroupe({isRequired: false, initValue: departmentsInit}),
    dietaryRestriction: useCheckBoxGroupe({
      isRequired: false,
      initValue: dietaryRestrictionInit,
      resetIfSelected: "6" // 6 it's id equal 'none'
    }),
    pronoun: useSelectInput({
      options: pronoun,
      isRequired: false,
      value: person?.pronoun?.id?.toString() || "",
      customValue: person?.pronoun_custom || "",
      targetKeys: {label: "name", value: "id"}
    }),
    otherRestrictions: useTextInput({
      value: person?.other_dietary_restriction ?? "",
      isRequired: false
    }),
    notes: useTextInput({
      value: person?.notes ?? "",
      isRequired: false
    }),
    travel: useTextInput({
      value: person?.travel ?? "",
      isRequired: false
    })
  };

  const isChanged = Object.values(formData).some(field => field.isChanged);

  return {
    formData,
    dietaryRestriction,
    pronoun,
    departments,
    relationships,
    isLoading: isLoadingOther,
    isChanged
  };
};
