import React from "react";
import "./SwitchInput.scss";

interface iSwitchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label?: string;
}

export const SwitchInput: React.FC<iSwitchInputProps> = ({
  className,
  label,
  disabled,
  ...otherProps
}) => {
  return (
    <div className={`SwitchInput ${className || ""}`}>
      {label && <div className="SwitchInput-label-text">{label || ""}</div>}
      <label className="SwitchInput-label">
        <input className="SwitchInput-input" disabled={disabled} {...otherProps} type="checkbox" />
        <span className={`SwitchInput-slider ${disabled ? "disabled" : ""}`} />
      </label>
    </div>
  );
};
