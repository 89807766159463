import {CSSProperties, FC, HTMLAttributes, MouseEvent, ReactNode, useState} from "react";
import {Portal} from "components/shared/Portal/Portal";
import {
  useFixedElementPosition,
  iUseFixedElementPosition,
  tPositionX,
  tPositionY
} from "hooks/useFixedElementPosition";

import "./TooltipPortal.scss";

export interface iTooltipPortal extends Partial<iUseFixedElementPosition> {
  className?: string;
  onClose?: (event: MouseEvent<HTMLElement>) => void;
  bodyProps?: HTMLAttributes<HTMLDivElement>;
  bodyStyles?: CSSProperties;
  arrowPosition: "top" | "bottom" | "left" | "right";
  text: ReactNode;

  anchorVertical?: tPositionY;
  anchorHorizontal?: tPositionX;
  transformVertical?: tPositionY;
  transformHorizontal?: tPositionX;
}

export const TooltipPortal: FC<iTooltipPortal> = ({
  children,
  className = "",
  anchorVertical = "bottom",
  transformVertical = "bottom",
  anchorHorizontal = "right",
  transformHorizontal = "right",
  bodyProps,
  bodyStyles,
  arrowPosition,
  text
}) => {
  const [tooltip, setTooltip] = useState<HTMLDivElement | null>(null);
  const isShow = Boolean(tooltip);

  const {setBodyRef, menuPosition} = useFixedElementPosition({
    anchorOrigin: {vertical: anchorVertical, horizontal: anchorHorizontal},
    transformOrigin: {vertical: transformVertical, horizontal: transformHorizontal},
    anchor: tooltip
  });

  const onShow = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setTooltip(event.currentTarget);
  };

  const onClose = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (event.target === event.currentTarget) setTooltip(null);
  };

  return (
    <>
      <div className="TooltipPortal-wrappedElement" onMouseEnter={onShow} onMouseLeave={onClose}>
        {children}
      </div>

      {isShow && (
        <Portal className={`TooltipPortal ${className}`}>
          <div
            className={`TooltipPortal-body body arrow-${arrowPosition} `}
            ref={newRef => setBodyRef(newRef)}
            style={{...bodyStyles, ...menuPosition}}
            {...bodyProps}
          >
            <div className="text">{text}</div>
          </div>
        </Portal>
      )}
    </>
  );
};

/**
 * USAGE exapmle
 */
/*
<TooltipPortal
  text={tooltipText}
  arrowPosition="left"
  transformVertical="center"
  anchorVertical="center"
>
  <Icon icon="info" size="sm" color="dark-gray" />
</TooltipPortal>
 */
