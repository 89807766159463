import {ChangeEvent, FC, useState} from "react";
import {AttachFile} from "components/AttachFIle/AttachFile";
import {Loading} from "components/shared/Loading/Loading";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {tJobTask} from "api/jobs/tasks/types";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {DeleteFileBtn} from "./DeleteFileBtn";
import doc from "assets/images/Doc.svg";
import {useContextJobTasks} from "pages/Jobs/JobDetails/JobTasks/_context/JobTaskContext";
import {ViewersWrap} from "components/Viewers/ViewersWrap/ViewersWrap";
import "./FileCell.scss";

type tFIleCellProps = {
  data: tJobTask;
};

export const FIleCell: FC<tFIleCellProps> = ({data}) => {
  const [modal, setModal] = useState(false);
  const {onSubmit, submitting, cellKeyRef, generateKey} = useContextJobTasks();
  const typeApi = "file";
  const currCellKey = generateKey(typeApi, data?.id);
  const isLoading = cellKeyRef?.current === currCellKey && submitting;

  const fileTypes = ".jpg, .png, .jpeg, .pdf";
  const attachFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const fd: any = new FormData();
    const file = e?.target?.files?.[0];
    fd.append("file", file);
    fd.append("file_type", fileTypes);

    onSubmit({taskId: data?.id, typeApi, data: fd});
  };

  const isFile = !!data?.file;
  const emptyClass = !isFile ? "empty" : "";
  return (
    <div className={`FileCell posRelative ${emptyClass}`}>
      {isLoading && <Loading type="inBlock" customSize={0.5} />}

      {!isFile && <AttachFile inputProps={{accept: fileTypes}} callBack={attachFile} />}

      {isFile && (
        <TextComponent as="button" onClick={() => setModal(true)} className="attachBtn">
          <img src={doc} alt="attachment" />
        </TextComponent>
      )}

      {modal && !!data?.file && (
        <ModalPopup size="md" show={true} onClose={() => setModal(false)} withoutPaddings>
          <ViewersWrap>
            <ViewersWrap.Main>
              <ViewersWrap.Viewer
                file={{
                  name: data?.file?.file_name,
                  url: data?.file?.file_url,
                  id: data?.file?.id
                }}
                onDeleteBtn={<DeleteFileBtn data={data} />}
              />
            </ViewersWrap.Main>
          </ViewersWrap>

          {/* <EditFile
            selectedFile={data?.file}
            onClose={() => setModal(false)}
            typeFile="additional"
            customDeleteChild={<DeleteFileBtn data={data} />}
            disabled={true}
          /> */}
        </ModalPopup>
      )}
    </div>
  );
};
