import {FC, useEffect, useRef, useState} from "react";
import {iJobReturn} from "api/jobs/types";
import {Icon} from "components/Icon/Icon";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {useDebounce} from "hooks/useDebounce";
import {useHttp} from "hooks/httpServices/useHttp";
import {useIsFirstRender} from "hooks/useIsFirstRender";
import {useOnClickOutside} from "hooks/useOnclickOutside";
import {useTextInputReturn} from "hooks/useTextInput/useTextInput";
import {jobsApi} from "api/jobs";
import "./SearchJob.scss";

type tSearchJobProps = {
  inputData: useTextInputReturn;
  setJob: React.Dispatch<React.SetStateAction<Partial<iJobReturn> | null>>;
};

export const SearchJob: FC<tSearchJobProps> = ({inputData, setJob}) => {
  const query = useDebounce(inputData?.value || "", 500);
  const {data, call, isLoading} = useHttp();
  const list = (data?.data?.data || []) as iJobReturn[];

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropdownRef, () => setShowDropdown(false));

  const isFirst = useIsFirstRender();
  useEffect(() => {
    if (!inputData.value || isFirst) return;
    call(jobsApi.getJobs({search: query}));
  }, [query]); // eslint-disable-line

  const onSelectValue = (item: iJobReturn) => {
    inputData.setValue(item?.name);
    inputData.setErrors([]);
    setJob(item);
    setShowDropdown(false);
  };

  return (
    <div className="SearchJob" onFocus={() => setShowDropdown(true)} ref={dropdownRef}>
      <Icon icon="search" size="lg" />
      <InputField
        {...inputData.inputProps}
        errors={inputData.errors}
        placeholder="Search jobs..."
      />

      {showDropdown && !!inputData?.value && (isLoading || !!list?.length) && (
        <ul className="SearchJob-list">
          {isLoading && <li className="SearchJob-listItem">Loading...</li>}

          {!isLoading &&
            list?.map((item, i) => {
              return (
                <li key={i} onClick={() => onSelectValue(item)} className="SearchJob-listItem">
                  {item?.name}
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
};
