import {FocusEvent, FC, useState, ChangeEvent} from "react";
import {tExpenseType, tInvoice} from "api/invoice/types";
import {ChapterHeader} from "../../../OverlayTemplates/ChapterHeader/ChapterHeader";
import {TextComponent, tText} from "../../../../common/TextComponent/TextComponent";
import {InputWithReload} from "./InputWithReload";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {LatePaymentFee} from "./LatePaymentFee";

import "./InvoiceTotals.scss";
import {filterMoneyWithMinus} from "../../../../../../../helpers/filters";
import {getUsMoneyFormat} from "../../../../../../../helpers/formattingData";
import {addMinusBefore$} from "helpers/misc";

interface iInvoiceTotalsProps {
  data: tInvoice;
  onResetExpense: (type: tExpenseType) => Promise<any>;
  updateInvoiceData: (invoice: Partial<tInvoice>) => void;
}

export const InvoiceTotals: FC<iInvoiceTotalsProps> = ({
  data,
  updateInvoiceData,
  onResetExpense
}) => {
  const [invoice, setInvoice] = useState(data);
  const {production, sales_tax, green, insurance, final_adjustment, actual_production_costs} =
    invoice;

  const getVal = (val: string | number | undefined) => {
    return !!val || val !== "-" ? val : 0;
  };

  const getNumber = (val: string | number | undefined) => {
    if (isNaN(Number(val))) return 0;
    return Number(val);
  };

  const onBlur = (event: FocusEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    let val = Number(filterMoneyWithMinus(value));
    if (isNaN(val)) val = 0;

    setInvoice(prev => ({...prev, [name]: val}));
    updateInvoiceData({...invoice, [name]: val});
  };

  const onFocus = ({target: {name, value}}: FocusEvent<HTMLInputElement>) => {
    if (value === "$0") {
      setInvoice(prev => ({...prev, [name]: ""}));
    } else {
      setInvoice(prev => ({...prev, [name]: filterMoneyWithMinus(value)}));
    }
  };

  const onChange = ({target: {name, value}}: ChangeEvent<HTMLInputElement>) => {
    let val: string | number | undefined = "";
    val = filterMoneyWithMinus(value);
    val !== undefined && setInvoice(prev => ({...prev, [name]: getVal(val)}));
  };

  const handleResetSum = async (type: tExpenseType) => {
    onResetExpense(type).then(data => {
      setInvoice(prev => {
        const newData = {...prev, ...data};
        updateInvoiceData({...newData});
        return newData;
      });
    });
  };

  function calculateTotal() {
    if (!invoice) return 0;
    const {green, final_adjustment, insurance, production, sales_tax, actual_production_costs} = invoice; // prettier-ignore
    let sum = getNumber(green) + getNumber(final_adjustment) + getNumber(insurance) + getNumber(production) + getNumber(sales_tax) + getNumber(actual_production_costs); // prettier-ignore

    invoice.advances.forEach(({amount_required, late_payment_amount}) => {
      sum = sum + getNumber(amount_required) + getNumber(late_payment_amount);
    });

    return sum;
  }

  return (
    <div className="InvoiceTotals">
      <ChapterHeader>Invoice TOTALS</ChapterHeader>
      <div className="InvoiceTotals-row">
        <InvoiceTotalsLabel>Actual Production Costs</InvoiceTotalsLabel>
        <span>{getUsMoneyFormat(actual_production_costs)}</span>
      </div>
      <div className="InvoiceTotals-row pl-20">
        <InvoiceTotalsLabel>Production % on Budgeted Expenses</InvoiceTotalsLabel>
        <InputWithReload
          // inputPrefixShadow={addMinusBefore$}
          value={production !== 0 || !production ? addMinusBefore$(String(production)) : "$0"}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          name="production"
          type="text"
          onClick={() => handleResetSum("production")}
        />
      </div>
      <div className="InvoiceTotals-row pl-20">
        <InvoiceTotalsLabel>Insurance % on Budgeted Expenses</InvoiceTotalsLabel>
        <InputWithReload
          // inputPrefixShadow={addMinusBefore$}
          value={insurance !== 0 ? addMinusBefore$(String(insurance)) : "$0"}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onClick={() => handleResetSum("insurance")}
          name="insurance"
          type="text"
        />
      </div>
      <div className="InvoiceTotals-row pl-20">
        <InvoiceTotalsLabel color="green-0">Green</InvoiceTotalsLabel>
        <InputWithReload
          // inputPrefixShadow={addMinusBefore$}
          onBlur={onBlur}
          onFocus={onFocus}
          onClick={() => handleResetSum("green")}
          value={green !== 0 ? addMinusBefore$(String(green)) : "$0"}
          onChange={onChange}
          name="green"
          type="text"
        />
      </div>
      <div className="InvoiceTotals-row pl-20 mb-14">
        <InvoiceTotalsLabel>Sales Tax</InvoiceTotalsLabel>
        <span>{getUsMoneyFormat(sales_tax)}</span>
      </div>
      <div className="InvoiceTotals-row pl-20">
        {invoice.advances.map(advance => {
          return <LatePaymentFee key={advance.id} data={advance} setInvoice={setInvoice} />;
        })}
      </div>
      <div className="InvoiceTotals-row">
        <InvoiceTotalsLabel>Final Adjustment</InvoiceTotalsLabel>
        <InputField
          name={"final_adjustment"}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          className="AdjustmentField"
          value={final_adjustment !== 0 ? addMinusBefore$(String(final_adjustment)) : "$0"}
          // inputPrefixShadow={addMinusBefore$}
          type="text"
        />
      </div>
      <div className="InvoiceTotals-row invoice-total">
        <TextComponent size="20" weight="700" uppercase>
          Invoice Total
        </TextComponent>
        <TextComponent size="20" weight="700" uppercase>
          {getUsMoneyFormat(calculateTotal())}
        </TextComponent>
      </div>
    </div>
  );
};

const InvoiceTotalsLabel: FC<tText> = ({children, ...props}) => {
  return (
    <TextComponent weight="500" color="black-10" size="14" {...props}>
      {children}
    </TextComponent>
  );
};
