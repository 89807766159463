import {jobTasksApi} from "api/jobs/tasks";
import {tJobTasksTemplateData} from "api/jobs/tasks/types";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextTemplateLayout} from "../../_common/TemplateLayoutContext";

export const useUpdateTaskData = () => {
  const {id} = useContextTemplateLayout();
  const updateDataCall = useHttp();

  const updateTasksData = async (
    newData: tJobTasksTemplateData["data"] | undefined,
    setTasks: React.Dispatch<React.SetStateAction<tJobTasksTemplateData | null>>,
    callBack?: () => void
  ) => {
    const res = await updateDataCall.call(jobTasksApi?.updateTemplateData(id, {data: newData}));

    setTasks(prev => {
      if (!prev) return prev;
      return {...prev, data: res?.data?.data};
    });
    // setTasks(prev => {
    //   if (!prev) return prev;
    //   if (!newData) return {...prev, data: []};
    //   return {...prev, data: newData};
    // });

    callBack?.();
  };

  return {updateTasksData, ...updateDataCall};
};
