import {useLayoutEffect, useState} from "react";
import {trackApi} from "api/budget/track";
import {tTrack} from "api/budget/track/types";
import {useHttp} from "hooks/httpServices/useHttp";
import {useLocation, useParams} from "react-router-dom";
import {common} from "api/common";
import {tPayStatus, tPayIdsItem, tQBStatus} from "api/common/types";
import {routes} from "router/routes";
import {useTrackCurrencyList} from "./useTrackCurrencyList";

export const useTrackWA = () => {
  const {id} = useParams();
  const {pathname} = useLocation();
  const {track, expenseList, wrapPDF} = routes.jobs.details.budget;
  const isTrack = [track, expenseList, wrapPDF].some(el => pathname.includes(el));
  const isTrackWithoutExpList = [track, wrapPDF].some(el => pathname.includes(el));

  // lists
  const payStatuses = useHttp();
  const payIds = useHttp();
  const [payIdList, setPayIdList] = useState<Array<tPayIdsItem>>();
  const qbStatuses = useHttp();

  const {call, isLoading} = useHttp();
  const [trackData, setTrackData] = useState<tTrack | null>(null);

  useLayoutEffect(() => {
    if (!isTrack) return;
    !payStatuses?.data && payStatuses.call(common.getPayStatuses());
    !payIds?.data &&
      payIds.call(common.getPayIds()).then(res => {
        setPayIdList(res?.data?.data);
      });
    !qbStatuses?.data && qbStatuses.call(common.getQBStatuses());
  }, [pathname]); // eslint-disable-line

  useLayoutEffect(() => {
    if (!isTrackWithoutExpList) return;
    setTrackData(null);
    getEstimateGroups();
  }, [pathname]); // eslint-disable-line

  const getEstimateGroups = () => {
    if (!id) return;
    return call(trackApi.getEstimateGroups(+id)).then(res => {
      const data = res?.data?.data;
      setTrackData(data);
    });
  };

  const {data: estimates} = useHttp({
    onMountCall: true,
    config: trackApi.getEstimateList(id ? +id : 0)
  });
  const categoryList = estimates?.data?.data?.map((item: any) => ({
    value: item?.id,
    label: `${item?.code} ${item?.name}`
  }));

  const currencyRates = useTrackCurrencyList({jobId: id ? +id : undefined});

  return {
    jobId: id,
    trackData,
    setTrackData,
    isLoading,
    getEstimateGroups,
    ...currencyRates,
    lists: {
      categoryList,
      payStatuses: {
        list: payStatuses?.data?.data?.data as Array<tPayStatus>,
        isLoading: payStatuses?.isLoading
      },
      qbStatuses: {
        list: qbStatuses?.data?.data?.data as Array<tQBStatus>,
        isLoading: qbStatuses?.isLoading
      },
      payIds: {
        list: payIdList,
        isLoading: payIds?.isLoading,
        setPayIdList
      }
    }
  };
};
