import Select, {GroupBase, Props, CSSObjectWithLabel} from "react-select";
import "./CustomReactSelect.scss";

type AdditionalProps = Partial<{
  className: string;
  label: string;
  isError?: boolean;
  errorMsg?: string;
}>;

export function CustomReactSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group> & AdditionalProps) {
  const {className = "", label, isError, menuPosition = "fixed", errorMsg, ...restProps} = props;
  const error = isError ? "error" : "";

  return (
    <div className={`CustomReactSelectWrap ${error}`}>
      {label && <div className="CustomReactSelectLabel">{label}</div>}

      <Select
        className={`CustomReactSelect ${className}`}
        menuPosition={menuPosition}
        styles={{...getStyles({isError})}}
        {...restProps}
      />

      {isError && (
        <span className="CustomReactSelectError">
          <span>{errorMsg || "This field can't be empty"}</span>
        </span>
      )}
    </div>
  );
}

const getStyles = ({isError}: {isError?: boolean}) => ({
  control: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    cursor: "pointer",
    boxShadow: "none",
    minHeight: "40px",
    color: "var(--black-0)",
    border: `1px solid ${isError ? "var(--red-0)" : "#cccccc"}`,
    "&:hover": {
      border: `1px solid ${isError ? "var(--red-0)" : "#cccccc"}`
    }
  }),
  indicatorSeparator: () => ({
    display: "none"
  }),
  dropdownIndicator: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    svg: {
      fill: `${isError ? "var(--red-0)" : "#7e7e80"}`,
      width: "17px"
    }
  }),
  indicatorsContainer: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    paddingRight: "9px"
  }),
  menu: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    boxShadow: "var(--light-shadow)",
    borderRadius: "3px",
    border: "1px solid var(--gray-30)"
  }),
  menuList: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    paddingTop: 0,
    maxHeight: "250px"
  }),
  option: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    backgroundColor: "var(--white-0)",
    color: "var(--black-0)",
    "&:hover": {
      color: "var(--black-0)",
      backgroundColor: "var(--gray-40)"
    }
  })
});
