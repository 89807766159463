import {FC} from "react";
import {tEstimateGroup, tEstimateRowData} from "api/budget/estimate/types";
import {EstimateRowINput} from "../../_common/EstimateRowInput/EstimateRowINput";
import {useHttp} from "hooks/httpServices/useHttp";
import {estimateApi} from "api/budget/estimate";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {useContextJobBudget} from "../../../_context/JobBudgetContext";
import "./EstimateRowProd.scss";

type tEstimateRowProdProps = {
  currItem: tEstimateRowData;
  group: tEstimateGroup;
};

export const EstimateRowProd: FC<tEstimateRowProdProps> = ({currItem, group}) => {
  const {setEstimate, estimate} = useContextJobBudget();
  const globalPercent = estimate?.global_production_percent ?? null;
  const groupPercent = group?.production_percent ?? null;

  const isGlobalPlaceholder = typeof groupPercent === "number" ? groupPercent : globalPercent;
  const placeholder = isGlobalPlaceholder ?? "15.0";
  const {call, isLoading} = useHttp();

  const onSubmit = async (value: string) => {
    try {
      const res = await call(
        estimateApi.updateLine(currItem.id, {
          production_percent: value && value !== "" ? +value : null
        })
      );
      setEstimate(() => res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const defVal = currItem.production_percent ?? null;

  return (
    <div className="EstimateRowProd">
      <EstimateRowINput
        // defaultValue={currItem.production_percent ? String(currItem.production_percent) : ""}
        defaultValue={!!defVal ? String(defVal) : ""}
        onBlurCallBack={onSubmit}
        placeholder={String(placeholder)}
        suffix={"%"}
        tooltipMsg="Overrides global and category prod %"
        type="percent"
        isLoading={isLoading}
      />
    </div>
  );
};
