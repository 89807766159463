import {FC} from "react";
import {Datepicker} from "components/shared/FormComponents/Datepicker/Datepicker";
import {useDatePicker} from "components/shared/FormComponents/Datepicker/useDatePicker";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {TextComponent} from "../../../../common/TextComponent/TextComponent";
import {useContextJobBudget} from "../../../context/JobBudgetContext";
import {advanceApi} from "api/budget/advance";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextAdvance} from "../../context";
import {advanceStatus} from "api/budget/advance/advanceStatus";
import {getDateFormat} from "helpers/dates";
import "./AdvancePay.scss";

interface iAdvancePayProps {
  show: boolean;
  onClose: () => void;
}

export const AdvancePay: FC<iAdvancePayProps> = ({show, onClose}) => {
  const {advanceId, setAdvanceDetails} = useContextAdvance();
  const {call, isLoading} = useHttp();

  const {estimate} = useContextJobBudget();
  const date = useDatePicker();

  const onChangeToPaid = async () => {
    if (!advanceId) return;

    try {
      const {
        data: {message, data}
      } = await call(
        advanceApi.changeAdvanceStatus(advanceId, {
          status: advanceStatus.paid,
          date: +getDateFormat(date.value, "timestamp")
        })
      );
      setAdvanceDetails(data);
      eventBus.dispatch("showToast", {text: message});
      onClose();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {text: msg, type: "error"});
    }
  };

  return (
    <ConfirmWindow
      className="AdvancePay"
      show={show}
      title="Mark as paid?"
      subTitle="Enter the advance paid date"
      onClose={onClose}
      onConfirm={onChangeToPaid}
      isLoading={isLoading}
      confirmBtn={{
        text: "Save"
      }}
      cancelBtn={{
        text: "Cancel"
      }}
    >
      <Datepicker {...date.inputProps} withIcon />

      <TextComponent weight="400" size="14" color="red" textAlign="center">
        {estimate?.name} will be permanently locked once this advance is marked as paid.
      </TextComponent>
    </ConfirmWindow>
  );
};
