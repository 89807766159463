import {FC, useState} from "react";
import {iJobDetailsDataReturn} from "api/jobs/types";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {useHttp} from "hooks/httpServices/useHttp";
import {jobsApi} from "api/jobs";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import {getDateRange} from "helpers/dates";
import {eventBus} from "EventBus/EventBus";
import "./JobBlock.scss";

type tJobBlockProps = {
  job: iJobDetailsDataReturn;
};

export const JobBlock: FC<tJobBlockProps> = ({job}) => {
  const {jobDetailsData, setJobDetails} = useContextJobDetails();

  const [confirmWindow, setConfirmWindow] = useState(false);
  const {call, isLoading} = useHttp();

  const removeLink = async () => {
    if (!jobDetailsData) return;

    try {
      const data = {linked_job_id: job?.id};
      const res = await call(jobsApi.removeJobLink(jobDetailsData?.id, data));
      eventBus.dispatch("showToast", {text: res?.data?.message});
      setJobDetails(res?.data?.data);
      setConfirmWindow(false);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error !"});
    }
  };

  const {start_at, end_at} = job;
  const jobDates = start_at && end_at ? getDateRange({start_at, end_at}) : null;

  return (
    <div className="JobBlock">
      <div className="JobBlock-title">{job?.name}</div>
      <div className="JobBlock-id">{job?.id}</div>
      <div className="JobBlock-date">{jobDates && jobDates.start + " - " + jobDates.end}</div>

      <Tooltip message={"unlink"} position={"top"} onClick={() => setConfirmWindow(true)}>
        <Button
          className="JobBlock-close"
          onClick={() => setConfirmWindow(true)}
          disabled={isLoading}
        >
          <Icon icon="close-sm" color="gray" />
        </Button>
      </Tooltip>

      <ConfirmWindow
        show={confirmWindow}
        onClose={() => setConfirmWindow(false)}
        onConfirm={removeLink}
        confirmBtn={{text: "Delete", color: "red"}}
        cancelBtn={{text: "Cancel"}}
        title={"Remove job link"}
        isLoading={isLoading}
      >
        <div className="DynamicFieldsWrap-confirmModal-body">
          <p>Are you sure you want to delete {job?.name} ?</p>
          <p className="DynamicFieldsWrap-confirmModal-warning">This can't be undone.</p>
        </div>
      </ConfirmWindow>
    </div>
  );
};
