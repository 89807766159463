import {FC} from "react";
import {ConfirmWindow} from "../ConfirmWindow/ConfirmWindow";

interface iModalConfirmCancelEditingProps {
  onClose: () => void;
  onConfirm: () => void;
  show: boolean;
}

export const ModalConfirmCancelEditing: FC<iModalConfirmCancelEditingProps> = ({
  show,
  onClose,
  onConfirm
}) => {
  return (
    <ConfirmWindow
      show={show}
      onClose={onClose}
      title="Close popup?"
      subTitle="Changes you made will not be saved."
      onConfirm={onConfirm}
      cancelBtn={{
        text: "cancel"
      }}
      confirmBtn={{
        text: "Close",
        color: "dark"
      }}
    />
  );
};
