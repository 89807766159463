import {FC, useEffect, useState} from "react";
import {Button} from "components/shared/Button/Button";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {tEstimateRowData} from "api/budget/estimate/types";
import {useContextJobBudget} from "pages/Jobs/JobDetails/JobBudget/context/JobBudgetContext";
import {useHttp} from "hooks/httpServices/useHttp";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {estimateApi} from "api/budget/estimate";
import {eventBus} from "EventBus/EventBus";
import {Loading} from "components/shared/Loading/Loading";
import {tTooltipPages, tTooltipTypes} from "components/AppTooltips/tooltips";
import {AppTooltips} from "components/AppTooltips/AppTooltips";
import "./EstimateRowSalesTax.scss";

type tEstimateRowSalesTaxProps = {
  currItem: tEstimateRowData;
  appTooltip?: {
    page: tTooltipPages;
    tooltip: tTooltipTypes;
  };
};
export const EstimateRowSalesTax: FC<tEstimateRowSalesTaxProps> = ({currItem, appTooltip}) => {
  const [value, setValue] = useState<tEstimateRowData["sales_tax"] | null>(null);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  useEffect(() => {
    setValue(currItem.sales_tax);
  }, [currItem]);

  const {setEstimate} = useContextJobBudget();
  const {call, isLoading} = useHttp();

  const onSubmit = async (value: tEstimateRowData["sales_tax"]) => {
    setMenuAnchor(null);
    setValue(() => value);
    if (value === null) return;

    try {
      const res = await call(estimateApi.updateLine(currItem.id, {sales_tax: value}));

      setMenuAnchor(null);
      setEstimate(() => res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <div className="EstimateRowSalesTax posRelative">
      {isLoading && <Loading type="inBlock" bgColor="grey-40" customSize={0.5} />}

      {appTooltip && <AppTooltips {...appTooltip} chevronPosition="topRight" />}

      <TextComponent
        as="button"
        size="10"
        onClick={showMenu}
        h100
        uppercase
        color={value === null ? "gray-20" : "black-10"}
        disabled={isLoading}
      >
        {value ? "on" : "off"}
      </TextComponent>

      <DropdownMenu anchor={menuAnchor} onClose={() => setMenuAnchor(null)} btnHover>
        <Button size="sm" onClick={() => onSubmit(true)}>
          on
        </Button>
        <Button size="sm" onClick={() => onSubmit(false)}>
          off
        </Button>
      </DropdownMenu>
    </div>
  );
};
