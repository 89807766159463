import {MouseEvent, Dispatch, SetStateAction} from "react";
import {tCallSheetContact, tCallSHeetGroupContacts} from "api/callSheet/types";

type tProps = {
  group: tCallSHeetGroupContacts;
  contact: tCallSheetContact;
  setContactGroup: Dispatch<SetStateAction<tCallSHeetGroupContacts[] | undefined>>;
};

export const useChangeContactRow = ({setContactGroup, group, contact}: tProps) => {
  const changeRow = (hide: "row" | "email" | "phone") => {
    setContactGroup(prev => {
      return prev?.map(item => {
        if (item?.id !== group?.id) return item;
        const newContacts = item?.contacts?.map(cont => {
          if (String(cont?.id) === String(contact?.id)) {
            return {
              ...cont,
              ...(hide === "row" && {
                selected: !cont?.selected
              }),
              ...(hide === "email" && {
                email: {
                  ...cont?.email,
                  show: !cont?.email?.show
                }
              }),
              ...(hide === "phone" && {
                phone: {
                  ...cont?.phone,
                  show: !cont?.phone?.show
                }
              })
            };
          }

          return cont;
        });

        return {...item, contacts: newContacts};
      });
    });
  };

  const onChangeRowWithPrevent = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    type: "email" | "phone"
  ) => {
    e.stopPropagation();
    changeRow(type);
  };

  return {changeRow, onChangeRowWithPrevent};
};
