import {ChangeEvent, FC, useState} from "react";
import {ChapterHeader} from "../../OverlayTemplates/ChapterHeader/ChapterHeader";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {Icon} from "components/Icon/Icon";
import {TextComponent} from "../../../common/TextComponent/TextComponent";
import {getUsMoneyFormat, getPercentFormat} from "helpers/formattingData";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useContextAdvance} from "../context";

import "./BudgetOverlayAdvance.scss";

interface iBudgetOverlayAdvanceProps {}

export const BudgetOverlayAdvance: FC<iBudgetOverlayAdvanceProps> = () => {
  const {advanceDetails, setAdvanceDetails} = useContextAdvance();
  const [percentEdit, setPercentEdit] = useState(true);

  const formData = {
    advance_percent: useTextInput({
      value: String(advanceDetails?.advance_percent),
      filters: ["percent"]
    }),
    amount_required: useTextInput({value: String(advanceDetails?.amount_required)}),
    late_payment_daily_percent: useTextInput({
      value: String(advanceDetails?.late_payment_daily_percent),
      filters: ["percent"]
    })
  };

  const updateAdvanceDetails = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setAdvanceDetails(prevState => (prevState ? {...prevState, [name]: value} : null));
  };

  const ChangeModeButton = () => (
    <button className="Exchange-button" onClick={() => setPercentEdit(prevState => !prevState)}>
      <Icon icon="arrow-right" color="gray" />
      <Icon icon="arrow-left" color="gray" />
    </button>
  );

  const onChangeAmountRequired = (event: ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    const percent = (Number(value) / Number(advanceDetails?.budget_total)) * 100;

    const {advance_percent, amount_required} = formData;

    if (percent > 100) {
      advance_percent.setValue("100");
      amount_required.setValue(String(advanceDetails?.budget_total));
      return;
    }
    amount_required.setValueWithFilters(value);
    advance_percent.setValueWithFilters(String(percent));
  };

  const onChangeAdvancePercent = (event: ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    const amount = String(((advanceDetails?.budget_total || 0) / 100) * +value);
    const {advance_percent, amount_required} = formData;
    amount_required.setValueWithFilters(amount);
    advance_percent.setValueWithFilters(value);
    setAdvanceDetails(prevState => (prevState ? {...prevState, amount_required: +amount} : null));
  };

  const paymentDaily = ((((advanceDetails?.budget_total || 0) / 100) * +formData.advance_percent.value) / 100) * +formData.late_payment_daily_percent.value; // prettier-ignore

  return (
    <div className="BudgetOverlayAdvance">
      <ChapterHeader>Advance</ChapterHeader>
      <div className="BudgetOverlayAdvance-body">
        <div className="BudgetOverlayAdvance-leftSide">
          <div />
          <TextComponent uppercase size="13" m="mb-16">
            Budget Total
          </TextComponent>

          <TextComponent size="14" weight="500">
            Production Costs
          </TextComponent>
          <TextComponent weight="400" size="14">
            {getUsMoneyFormat(advanceDetails?.budget_total || 0)}
          </TextComponent>
        </div>
        <div className="BudgetOverlayAdvance-rightSide">
          <TextComponent uppercase size="13" m="mb-16">
            Advance %
          </TextComponent>

          <TextComponent uppercase size="13" m="mb-16" textAlign="right">
            Amount Required Before SHOOT
          </TextComponent>

          {percentEdit && (
            <>
              <div className="Exchange">
                <InputField
                  value={formData.advance_percent.value}
                  onChange={onChangeAdvancePercent}
                  name="advance_percent"
                  onBlur={updateAdvanceDetails}
                  className="AdvanceInput"
                  suffix="%"
                />
                <ChangeModeButton />
              </div>

              <TextComponent weight="400" size="14" textAlign="right">
                {getUsMoneyFormat(formData.amount_required.value)}
              </TextComponent>
            </>
          )}
          {!percentEdit && (
            <>
              <TextComponent weight="400" size="14">
                {getPercentFormat(formData.advance_percent.value)}
              </TextComponent>
              <div className="ExchangeAmount">
                <ChangeModeButton />
                <InputField
                  {...formData.amount_required.inputProps}
                  onChange={onChangeAmountRequired}
                  name="amount_required"
                  onBlur={updateAdvanceDetails}
                />
              </div>
            </>
          )}

          <TextComponent uppercase size="15" m={["mb-24", "mt-40"]}>
            ADVANCE TOTAL (USD)
          </TextComponent>

          <TextComponent uppercase size="15" m={["mb-24", "mt-40"]} textAlign="right">
            {getUsMoneyFormat(formData.amount_required.value)}
          </TextComponent>

          {/* <div className="DailyCost">
            <TextComponent weight="500" size="14" nowrap>
              Late payment daily cost:
            </TextComponent>
            <InputField
              {...formData.late_payment_daily_percent.inputProps}
              name="late_payment_daily_percent"
              onBlur={updateAdvanceDetails}
              className="AdvanceInput"
              suffix="%"
            />
            <TextComponent weight="400" size="13">
              {getUsMoneyFormat(paymentDaily)}
            </TextComponent>
          </div> */}
        </div>
      </div>
    </div>
  );
};
