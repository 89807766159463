import {FC} from "react";
import {TextComponent} from "../../../common/TextComponent/TextComponent";
import "./ChapterHeader.scss";

interface iChapterHeaderProps {
  withLine?: boolean;
}

export const ChapterHeader: FC<iChapterHeaderProps> = ({withLine = true, children}) => {
  const withLineClassName = withLine ? "with-line" : "";
  return (
    <div className={`ChapterHeader ${withLineClassName}`}>
      <TextComponent size="17" uppercase>
        {children}
      </TextComponent>
    </div>
  );
};
