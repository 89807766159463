import {MouseEvent, useState} from "react";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {iJobDetailsDataReturn} from "api/jobs/types";

export function useJobDetailsHeader() {
  const {jobDetailsData, pipelines, setJobDetails} = useContextJobDetails();

  const [showEdit, setShowEdit] = useState(false);

  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const onShowPipelines = (event: MouseEvent<HTMLElement>) => setAnchor(event.currentTarget);

  const handleSelectedCallback = (data?: iJobDetailsDataReturn) => {
    setAnchor(null);
    if (!data) return;
    setJobDetails(data);
  };

  return {
    handleSelectedCallback,
    onShowPipelines,
    showEdit,
    setShowEdit,
    jobDetailsData,
    pipelines,
    anchor,
    setAnchor
  };
}
