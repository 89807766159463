import {useEffect, useState} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {iDeliveryInfo, tCallSheetLogItem} from "api/callSheet/types";
import {callSheet} from "api/callSheet";
import {useContextJobCallSheet} from "../../context/JobCallSheetContext";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {usePDF} from "hooks/usePDF";
import {prepareName} from "helpers/fileNaming";
import moment from "moment";
import {useContextJobDetails} from "../../../../context/JobDetailsContext/JobDetailsContext";

export function useCallSheetLog() {
  const {callSheetInfo} = useContextJobCallSheet();
  const {jobDetailsData} = useContextJobDetails();
  const callSheetDeliveryQuery = useHttp();
  const callSheetLogsQuery = useHttp();

  const [deliveryInfoList, setDeliveryInfoList] = useState<iDeliveryInfo[]>([]);
  const [activeDelivery, setActiveDelivery] = useState<iDeliveryInfo | null>(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const {createPdf} = usePDF({name: "CallName", templateID: ""});

  useEffect(() => {
    if (callSheetInfo?.id) {
      callSheetLogsQuery.call(callSheet.getCallSheetLogs(callSheetInfo.id));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getDeliveryDetails(id: number) {
    try {
      const {data: {data}} = await callSheetDeliveryQuery.call(callSheet.getCallSheetsDelivery(id)); // prettier-ignore
      return data;
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  }

  const onShowDetails = async (id: number) => {
    const info = deliveryInfoList.find(info => +info.id === +id);

    if (info) {
      setActiveDelivery(info);
      return;
    }

    const details = await getDeliveryDetails(id);
    setActiveDelivery(details);
    setDeliveryInfoList(prev => [...prev, details]);
  };

  const onCloseModal = () => {
    setActiveDelivery(null);
  };

  const onDownload = async (id: number) => {
    setIsDownloading(true);
    let info: iDeliveryInfo | undefined = deliveryInfoList.find(info => +info.id === +id);

    if (!info) {
      info = await getDeliveryDetails(id);
      setDeliveryInfoList(prevState => (info ? [...prevState, info] : prevState));
    }

    setActiveDelivery(info || null);
    setTimeout(async () => {
      const element = document.querySelector(".CallSheetLog-deliveryDetails .content");
      const file = await createPdf(element as HTMLElement);
      file &&
        file.save(
          prepareName(
            [
              jobDetailsData?.name || "",
              jobDetailsData?.artists?.[0]?.artist?.name?.split(" ")?.join("") || "",
              moment(callSheetInfo?.date).format("YYYYMMDD") || "",
              callSheetInfo?.name || ""
            ],
            "_CS.pdf"
          )
        );
      setIsDownloading(false);
      setActiveDelivery(null);
    }, 0);
  };

  return {
    data: callSheetLogsQuery?.data?.data?.data as Array<tCallSheetLogItem>,
    isLoading: callSheetLogsQuery.isLoading || callSheetDeliveryQuery.isLoading,
    onShowDetails,
    onDownload,
    activeDelivery,
    callSheetInfo,
    onCloseModal,
    isDownloading,
    setIsDownloading
  };
}
