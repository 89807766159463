import {FC, useEffect} from "react";
import {PayrollCompany} from "./JobSettingsPayroll/PayrollCompany";
import {useHttp} from "hooks/httpServices/useHttp";
import {creditCardAndPayroll} from "api/budget/creditCardAndPayroll";

interface iJobSettingsPayrollProps {
  onClose: (arg: null) => void;
}

export const JobSettingsPayroll: FC<iJobSettingsPayrollProps> = ({onClose}) => {
  const payrollsQuery = useHttp();

  useEffect(() => {
    getPayrolls();
  }, []); // eslint-disable-line

  const getPayrolls = () => {
    payrollsQuery.call(creditCardAndPayroll.getPayrollCompanies());
  };

  return (
    <PayrollCompany
      isLoading={payrollsQuery.isLoading}
      payrolls={payrollsQuery?.data?.data?.data}
      onClose={() => onClose(null)}
      onEditCallback={getPayrolls}
    />
  );
};
