import {FC, useState} from "react";
import {gridApi} from "api/jobs/grid";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {CreateGridForm} from "../GridForms/CreateGridForm";
import {useContextJobGrid} from "../JobGridContext";
import {SaveAsExistingTemplate} from "../GridForms/SaveAsExistingTemplate";
import {CreateNewTemplate} from "../GridForms/CreateNewTemplate";

type tMenuGridProps = {
  [key: string]: any;
};

export type tGridFormType = "rename" | "duplicate";

export const MenuGrid: FC<tMenuGridProps> = () => {
  const {grid, getGridList, setCurrGridId} = useContextJobGrid();
  const {call, isLoading} = useHttp();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  const [showForm, setShowForm] = useState<tGridFormType | null>(null);

  const deleteGrid = async () => {
    if (!grid?.id) return;

    try {
      const res = await call(gridApi.deleteGrid(grid.id));
      eventBus.dispatch("showToast", {text: res?.data?.message || "Success!"});
      setCurrGridId(null);
      getGridList();
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <div className="MenuGrid">
      <Button onClick={showMenu} size="sm" color="outline" disabled={isLoading}>
        <Icon icon="menu" size="xsm" />
      </Button>

      <DropdownMenu
        anchor={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        className="MenuGrid-dropdown"
      >
        <Button size="sm" onClick={() => setShowForm("rename")} disabled={isLoading}>
          Rename
        </Button>
        <Button size="sm" onClick={() => setShowForm("duplicate")} disabled={isLoading}>
          Duplicate
        </Button>
        <SaveAsExistingTemplate />
        <CreateNewTemplate />
        <Button size="sm" onClick={deleteGrid} loading={isLoading}>
          Delete
        </Button>
      </DropdownMenu>

      {!!showForm && (
        <CreateGridForm
          closeTemplateModal={() => setShowForm(null)}
          showTemplateModal={!!showForm}
          type={showForm}
        />
      )}
    </div>
  );
};
