import React, {FC} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {absRoutes, routes} from "router";
import {LoginPageLayout} from "layouts/LoginPageLayout/LoginPageLayout";

const Login = React.lazy(() => import("pages/Auth/Login/Login"));
const CheckInviteLink = React.lazy(() => import("pages/Auth/CheckInviteLink/CheckInviteLink"));
const Register = React.lazy(() => import("pages/Auth/Register/Register"));
const ForgotPass = React.lazy(() => import("pages/Auth/ForgotPassword/ForgotPassword"));
const ResetPass = React.lazy(() => import("pages/Auth/ResetPassword/ResetPassword"));

export const Auth: FC = () => (
  <Routes>
    <Route path="/" element={<LoginPageLayout />}>
      <Route path={routes.auth.login} element={<Login />} />
      <Route path={routes.auth.forgotPass} element={<ForgotPass />} />
      <Route path={routes.auth.resetPass} element={<ResetPass />} />
    </Route>
    <Route path={routes.auth.verification} element={<CheckInviteLink />} />
    <Route path={routes.auth.register} element={<Register />} />

    <Route index element={<Navigate to={absRoutes.auth.login} />} />
  </Routes>
);
