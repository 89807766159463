import {FC} from "react";
import {Page, Document, StyleSheet, Font} from "@react-pdf/renderer"; // @ts-ignore
import Helvetica700 from "assets/fonts/HelveticaNeue/HelveticaNeue-Bold.ttf"; // @ts-ignore
import Helvetica500 from "assets/fonts/HelveticaNeue/HelveticaNeue-Medium.ttf"; // @ts-ignore
import Helvetica400 from "assets/fonts/HelveticaNeue/HelveticaNeue.ttf";
import {Header} from "../../PdfTemplates/a4/Header";
import {EstimateSummaryPage} from "../../PdfTemplates/a4/EstimateSummaryPage";
import {Footer} from "../../PdfTemplates/a4/Footer";
import {HeaderBreakdown} from "../../PdfTemplates/a4/HeaderBreakdown";
import {EstimateValuesTable} from "../../PdfTemplates/a4/EstimateValuesTable";

import {Footer as FooterA2} from "../../PdfTemplates/a2/Footer";
import {HeaderBreakdown as HeaderBreakdownA2} from "../../PdfTemplates/a2/HeaderBreakdown";
import {EstimateValuesTable as EstimateValuesTableA2} from "../../PdfTemplates/a2/EstimateValuesTable";

import {iJobDetailsDataReturn} from "api/jobs/types";
import {tEstimateExportInfo} from "api/budget/estimate/types";

Font.register({
  family: "Helvetica500",
  src: Helvetica500
});

Font.register({
  family: "Helvetica400",
  src: Helvetica400
});

Font.register({
  family: "Helvetica700",
  src: Helvetica700
});

const styles = StyleSheet.create({
  A4: {
    padding: 35,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    height: "100%"
  },
  A3: {
    padding: 80,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    height: "100%"
  }
});

interface tEstimateOverlayDocumentProps {
  jobDetailsData: iJobDetailsDataReturn;
  exportInfo: tEstimateExportInfo;
  format?: "A4" | "A2";
}

export const EstimateOverlayDocument: FC<tEstimateOverlayDocumentProps> = ({
  exportInfo,
  jobDetailsData,
  format = "A4"
}) => {
  return (
    <Document>
      {format === "A4" && (
        <>
          <Page style={styles.A4}>
            <Header text="ESTIMATE summary" />
            <EstimateSummaryPage data={exportInfo} jobDetailsData={jobDetailsData} />
            <Footer />
          </Page>
          <Page style={styles.A4}>
            <HeaderBreakdown
              jobName={exportInfo.job_name}
              jobNumber={exportInfo.job_number}
              photographer={exportInfo.photographer}
              po={exportInfo.po}
              title="Invoice Breakdown"
            />
            <EstimateValuesTable data={exportInfo.estimate.category_values || []} />
            <Footer />
          </Page>
        </>
      )}
      {format === "A2" && (
        <>
          <Page size="A2" style={styles.A3}>
            <HeaderBreakdownA2
              jobName={exportInfo.job_name}
              jobNumber={exportInfo.job_number}
              photographer={exportInfo.photographer}
              po={exportInfo.po}
              title="ESTIMATE BREAKDOWN"
            />
            <EstimateValuesTableA2 data={exportInfo.estimate.category_values || []} />
            <FooterA2 />
          </Page>
        </>
      )}
    </Document>
  );
};
