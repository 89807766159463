import {FC, FormEvent} from "react";
import {TextareaField} from "components/shared/FormComponents/TextareaField/TextareaField";
import {FormWrap} from "components/shared/FormWrap/FormWrap";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {useHttp} from "hooks/httpServices/useHttp";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {notValidForm} from "helpers/misc";
import {useContextTrack} from "../_context/TrackContext";
import {trackApi} from "api/budget/track";
import {tTrackCostValue} from "api/budget/track/types";

type tEditDescriptionsProps = {
  onClose: () => void;
  trackCost: tTrackCostValue;
};

export const EditDescriptions: FC<tEditDescriptionsProps> = ({onClose, trackCost}) => {
  const {setTrackData} = useContextTrack();
  const {call, isLoading} = useHttp();
  const formId = "track-edit-descriptions";
  const {description, discount_description, private_memo} = trackCost;

  const form = {
    description: useTextInput({value: description || "", isRequired: false}),
    descriptionDiscount: useTextInput({value: discount_description || "", isRequired: false}),
    descriptionPrivetMemo: useTextInput({value: private_memo || "", isRequired: false})
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (notValidForm(form)) return;

    try {
      const data = {
        description: form.description.value,
        discount_description: form.descriptionDiscount.value,
        private_memo: form.descriptionPrivetMemo.value
      };

      const res = await call(trackApi.setCostDescriptions(trackCost.id, data));
      setTrackData(() => res?.data?.data);
      onClose();
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <ModalPopup
      size="w-528"
      show={true}
      onClose={onClose}
      className="TrackEditDescriptions"
      withoutPaddings
    >
      <FormWrap
        headerTitle="Edit descriptions"
        idForm={formId}
        type="titleCenterAndTwoBtn"
        cancelTitle="Cancel"
        onCancel={onClose}
        submitTitle="Save"
        withScroll
        isLoading={isLoading}
      >
        <form id={formId} onSubmit={handleSubmit}>
          <TextareaField
            {...form.description.inputProps}
            errors={form.description.errors}
            label="DESCRIPTION"
          />
          <TextareaField
            {...form.descriptionDiscount.inputProps}
            errors={form.descriptionDiscount.errors}
            label="Discount DESCRIPTION"
            placeholder="This text exports in red"
          />
          <TextareaField
            {...form.descriptionPrivetMemo.inputProps}
            errors={form.descriptionPrivetMemo.errors}
            label="Private Memo"
            placeholder="This text does not export"
          />
        </form>
      </FormWrap>
    </ModalPopup>
  );
};
