import {common} from "api/common";
import {tHoldStatus} from "api/common/types";
import {jobCrewContactPosition} from "api/jobCrewContactPosition";
import {tContactPosition} from "api/jobCrewContactPosition/type";
import {useHttp} from "hooks/httpServices/useHttp";

export const useGetCommonLists = () => {
  const {data} = useHttp({onMountCall: true, config: jobCrewContactPosition.getContactPositions()});
  const {data: hold} = useHttp({onMountCall: true, config: common.getHoldStatuses()});

  return {
    positionsList: data?.data?.data as Array<tContactPosition>,
    holdStatusList: hold?.data?.data as Array<tHoldStatus>
  };
};
