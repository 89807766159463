import {FC, useState} from "react";
import {Button} from "components/shared/Button/Button";
import {ManageFieldsForm} from "./ManageFieldsForm/ManageFieldsForm";

interface iAddFieldFormProps {
  getFieldList: () => Promise<any>;
  setHide: (is: boolean) => void;
}

export const AddFieldForm: FC<iAddFieldFormProps> = ({getFieldList, setHide}) => {
  const [showFormModal, setShowFormModal] = useState(false);

  const show = () => {
    setHide(true);
    setShowFormModal(true);
  };

  const hide = () => {
    setHide(false);
    setShowFormModal(false);
  };

  return (
    <>
      <Button size="sm" color="outline" onClick={show} className="ManageFieldsList-add">
        Add Field
      </Button>
      {showFormModal && (
        <ManageFieldsForm show={showFormModal} onClose={hide} getFieldList={getFieldList} />
      )}
    </>
  );
};
