import {FC} from "react";

interface iJobCalendarBody {
  schedulerRef: any;
  id: string;
  className: string;
}

export const JobCalendarBody: FC<iJobCalendarBody> = ({schedulerRef, id, className}) => {
  return (
    <div id={id} key="dhx-container" ref={schedulerRef} className={className}>
      <div className="dhx_cal_navline">
        <div className="dhx_cal_prev_button">&nbsp;</div>
        <div className="dhx_cal_next_button">&nbsp;</div>
        <div className="dhx_cal_today_button"></div>
        <div className="dhx_cal_date"></div>
        <div className="dhx_cal_tab" data-name="day_tab" style={{right: "204px"}}></div>
        <div className="dhx_cal_tab" data-name="week_tab" style={{right: "140px"}}></div>
        <div className="dhx_cal_tab" data-name="month_tab" style={{right: "76px"}}></div>
      </div>
      <div className="dhx_cal_header"></div>
      <div key="dhx-data" className="dhx_cal_data"></div>
    </div>
  );
};
