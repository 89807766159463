import axios from "axios";
const REACT_APP_MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

type tTypesParam = "place" | "address" | "country" | "postcode" | "region" | "district";
// type tPropertiesParam = ;

interface iParams {
  value?: string;
  limit?: number;
  proximity?: string;
  access_token?: string;
  types?: tTypesParam[];
  autocomplete?: boolean;
  fuzzyMatch?: boolean;
  // properties?: tPropertiesParam;
}

export const mapboxSearchAddress = async ({
  value = "",
  limit = 10,
  proximity = "ip",
  access_token = REACT_APP_MAPBOX_ACCESS_TOKEN,
  types = ["place", "address", "country", "postcode", "region", "district"],
  autocomplete = true,
  fuzzyMatch = false
}: // properties = {accuracy: "street"},
iParams) => {
  const valueString = decodeURI(`https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json`);
  return axios(valueString, {
    params: {limit, proximity, autocomplete, fuzzyMatch, types, access_token}
  });
};
