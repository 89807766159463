import {useHttp} from "hooks/httpServices/useHttp";
import {useEffect, useState} from "react";
import {useContextJobDetails} from "../../../../context/JobDetailsContext/JobDetailsContext";
import {invoiceApi} from "api/invoice";
import {tExpenseType, tInvoice} from "api/invoice/types";
import {tAdvanceContact} from "api/budget/advanceContact/types";
import {advanceContactApi} from "api/budget/advanceContact";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useGeneratePdfData} from "hooks/useGeneratePdfData";
import {ExpenseTable} from "../InvoiceBuild/ExpenseTable";
import {tTrack} from "api/budget/track/types";
import {trackApi} from "api/budget/track/index";
import {InvoicePreview} from "../InvoicePreview/InvoicePreview";
import PDFMerger from "pdf-merger-js/browser";
import {tTermsConditions} from "api/budget/termsConditions/types";
import {termsConditionsApi} from "api/budget/termsConditions";

export function useInvoice() {
  const {getInvoice, resetExpenseSum} = invoiceApi;
  const {jobDetailsData} = useContextJobDetails();

  const invoiceQuery = useHttp<{data: tInvoice}>();
  const advanceContactsQuery = useHttp<{data: tAdvanceContact[]}>();
  const resetExpenseSumQuery = useHttp();
  const expensesGroups = useHttp();
  const termsFileQuery = useHttp();

  const {generatePdfData} = useGeneratePdfData();

  const [invoice, setInvoice] = useState<tInvoice | null>(null);
  const [advanceContacts, setAdvanceContacts] = useState<tAdvanceContact[]>([]);
  const [expenseInvoice, setExpenseInvoice] = useState<{blob: Blob; url: string} | undefined>();
  const [track, setTrack] = useState<tTrack | null>(null);
  const [tackLoading, setTrackLoading] = useState(false);

  useEffect(() => {
    if (!jobDetailsData?.id) return;
    invoiceQuery
      .call(getInvoice(jobDetailsData?.id))
      .then(({data: {data}}) => {
        setInvoice(data);
        return data;
      })
      .then(invoice => {
        invoice && getExpenseInvoiceTablePdf(invoice);
      });

    advanceContactsQuery.call(advanceContactApi.getAdvanceContactList()).then(({data: {data}}) => {
      setAdvanceContacts(data);
    });
  }, []); // eslint-disable-line

  const getExpenseInvoiceTablePdf = async (invoice: tInvoice) => {
    if (!jobDetailsData?.id) return;
    setTrackLoading(true);
    try {
      const {
        data: {data}
      } = await expensesGroups.call(trackApi.getEstimateGroups(jobDetailsData.id));
      const track = clearEmptyExpensesRow(data);
      setTrack(track);
      const pdf = await generatePdfData(<ExpenseTable expenses={track} invoice={invoice} />);
      setExpenseInvoice(pdf);
      setTrackLoading(false);
    } catch (error: any) {
      setTrackLoading(false);
      console.log(error);
    }
  };

  const onResetExpense = async (type: tExpenseType) => {
    if (!invoice) return;
    try {
      const {
        data: {data}
      } = await resetExpenseSumQuery.call(resetExpenseSum(invoice.id, type));
      return {[type]: data[type]};
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      console.log("onResetExpense", msg);
    }
  };

  const updateInvoiceData = (invoice: Partial<tInvoice>) => {
    setInvoice(prev => (prev ? {...prev, ...invoice} : null));
  };

  const clearEmptyExpensesRow = (trackData: tTrack) => {
    const categories = trackData.categories.reduce((prev: any[], current): tTrack[] => {
      if (!!current?.cost_values?.length) {
        return [
          ...prev,
          {
            ...current,
            cost_values: current.cost_values
          }
        ];
      }
      return prev;
    }, []);

    return {
      ...trackData,
      categories
    };
  };

  const getPreviewPdf = async () => {
    if (!invoice || !jobDetailsData) return;
    let tr: tTrack | null = null;
    if (!track) {
      const {
        data: {data}
      } = await expensesGroups.call(trackApi.getEstimateGroups(jobDetailsData.id));
      const track = clearEmptyExpensesRow(data);
      tr = track;
      setTrack(track);
    }
    const data = track || tr;

    if (!data) return;

    try {
      const invoicePdf = (await generatePdfData(
        <InvoicePreview jobDetails={jobDetailsData} invoice={invoice} track={data} />
      )) as {blob: Blob; url: string};
      let termPdf = await getTermsFileBlob(invoice.term_condition);

      if (!termPdf) {
        return invoicePdf;
      }
      const mergedPdf = new PDFMerger();
      await mergedPdf.add(invoicePdf.blob);
      termPdf && (await mergedPdf.add(termPdf));
      const pdf = await mergedPdf.saveAsBlob();
      const url = URL.createObjectURL(pdf);
      return {blob: pdf, url};
    } catch (error: any) {
      console.log(error);
    }
  };

  const getTermsFileBlob = async (termConditions: tTermsConditions | null) => {
    if (!termConditions) return;
    try {
      const {data} = await termsFileQuery.call(termsConditionsApi.downloadTermsConditions(termConditions.id)); // prettier-ignore
      return new Blob([data]);
    } catch (error: any) {
      console.log(error);
    }
  };

  return {
    invoiceQuery,
    invoice,
    onResetExpense,
    updateInvoiceData,
    setInvoice,
    advanceContacts,
    setAdvanceContacts,
    expenseInvoice,
    track,
    tackLoading,
    getPreviewPdf,
    getExpenseInvoiceTablePdf
  };
}
