export const locationsTableColumn = [
  {
    id: "name",
    label: "Location",
    sort: true
  },
  {
    id: "rate",
    label: "Rate"
  },
  {
    id: "address",
    label: "Address"
  },
  {
    id: "locale",
    label: "Locale"
  },
  {
    id: "contacts",
    label: "Contacts"
  }
];
