import {FC} from "react";
import {ImgWithFallback} from "components/shared/ImgWithFallback/ImgWithFallback";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {Icon} from "components/Icon/Icon";
import "./AssigneeDropdownItem.scss";

type tAssigneeDropdownItemProps = {
  checked: boolean;
  name: string;
  imageUrl: string;
  callBack: () => void;
};

export const AssigneeDropdownItem: FC<tAssigneeDropdownItemProps> = props => {
  const {checked = false, name, imageUrl, callBack} = props;

  return (
    <TextComponent as="button" className="AssigneeDropdownItem" w100 onClick={callBack}>
      <ImgWithFallback src={imageUrl} fallBackType="avatar" className="AssigneeDropdownItem-img" />
      <TextComponent size="11" weight="500" truncate textAlign="left">
        {name}
      </TextComponent>

      <div className="pseudoCheckBox">{checked && <Icon icon="check" />}</div>
    </TextComponent>
  );
};
