import {FC} from "react";
import {Page, Document, StyleSheet, Text, View} from "@react-pdf/renderer";
import {tTrack} from "api/budget/track/types";
import {tInvoice} from "api/invoice/types";
import {Header} from "../../PdfTemplates/a4/Header";
import {Footer} from "../../PdfTemplates/a4/Footer";
import {ClientSection} from "../../PdfTemplates/a4/ClientSection";
import {AdvanceInvoiceDetails} from "../../PdfTemplates/a4/AdvanceInvoiceDetails";
import {JobDescriptionSection} from "../../PdfTemplates/a4/JobDescriptionSection";
import {CreativeSection} from "../../PdfTemplates/a4/CreativeSection";
import {iJobDetailsDataReturn} from "api/jobs/types";
import {ProductionCosts} from "./ProductionCosts";
import {InvoiceTotals} from "./InvoiceTotals";
import {SectionTitle} from "../../PdfTemplates/a4/SectionTitle";
import {HeaderBreakdown} from "../../PdfTemplates/a4/HeaderBreakdown";
import {InvoiceValuesTable} from "./InvoiceValuesTable";

interface iInvoicePreviewProps {
  track: tTrack;
  invoice: tInvoice;
  jobDetails: iJobDetailsDataReturn;
}

export const InvoicePreview: FC<iInvoicePreviewProps> = ({track, jobDetails, invoice}) => {
  return (
    <Document>
      <Page style={styles.container}>
        <Header text="Invoice" />
        <View style={styles.box}>
          <View style={styles.fullWidthRow}>
            <View style={styles.halfSection}>
              <ClientSection
                name={jobDetails?.client?.name || ""}
                address={jobDetails?.client?.address}
              />
            </View>
            <View style={styles.halfSection}>
              <AdvanceInvoiceDetails
                invoiceNumber={invoice.invoice_number}
                date={invoice.date}
                dueDate={invoice.due_date}
                terms={invoice.terms}
                advanceContact={invoice?.advance_contact}
              />
            </View>
          </View>
          <View style={styles.fullWidthRow}>
            <View style={styles.halfSection}>
              <JobDescriptionSection
                po={invoice.po}
                jobNumber={invoice.job_number}
                jobName={invoice.job_name}
                jobEndAt={invoice.job_end_at}
                jobStartAt={invoice.job_start_at}
                shootLocation={invoice.shoot_location}
                photographer={invoice.photographer}
                notes={invoice.notes}
                label1={invoice?.label1}
                label2={invoice?.label2}
                text1={invoice?.text1}
                text2={invoice?.text2}
              />
            </View>
            <View style={styles.halfSection}>
              <CreativeSection license={invoice.usage_license} creative={invoice.creative} />
            </View>
          </View>
          <View style={styles.fullWidthRow}>
            <ProductionCosts
              categories={track.categories}
              fees={track.payroll_fees}
              total={track.grand_total.total_actual}
            />
          </View>
          <View style={styles.fullWidthRow}>
            <InvoiceTotals invoice={invoice} />
          </View>
          <View style={styles.fullWidthRow}>
            <View style={styles.halfSection}>
              {invoice.term_condition && (
                <>
                  <SectionTitle>Terms and conditions</SectionTitle>
                  <Text style={styles.termsText}>Please see following pages.</Text>
                </>
              )}
            </View>
            <View style={styles.halfSection}>
              {invoice.bank_detail && (
                <>
                  <SectionTitle>Bank</SectionTitle>
                  <Text style={styles.value}>{invoice.bank_detail.details}</Text>
                </>
              )}
            </View>
          </View>
        </View>
        <Footer />
      </Page>
      <Page style={styles.container}>
        <HeaderBreakdown
          jobName={invoice.job_name}
          jobNumber={invoice.job_number}
          photographer={invoice.photographer}
          po={invoice.po}
          title="Invoice Breakdown"
        />
        <InvoiceValuesTable data={track} />
        <Footer />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 35,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    height: "100%"
  },
  box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%"
  },
  fullWidthRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%"
    // marginBottom: 20
  },
  halfSection: {
    width: "48%",
    marginBottom: 20
  },
  termsText: {
    color: "#7E7E80",
    fontSize: "10px"
  },
  termsBox: {
    display: "flex",
    flex: "1 1 auto"
  },
  value: {fontSize: 10, fontFamily: "Helvetica400", marginBottom: 4}
});
