import {FC} from "react";

export const Pencil: FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 14.5878L2.93881 15.5296L3.98146 14.487L2.0097 12.5152L0 14.5878ZM3.66499 7.52875C3.51015 7.6654 3.39791 7.84371 3.34163 8.04241C3.28536 8.2411 3.28745 8.45179 3.34763 8.64933L3.73146 9.90903L2.23352 11.4073L5.0638 14.2375L6.55968 12.7417L7.81674 13.1264C8.22056 13.2499 8.65938 13.1261 8.93879 12.8093L9.98438 11.5846L4.88616 6.4864L3.66499 7.52875ZM15.527 2.8023L13.6682 0.943481C13.0655 0.340836 12.0982 0.310542 11.4588 0.874363L5.60439 5.87317L10.5976 10.8667L15.5961 5.01199C16.1602 4.37259 16.1299 3.40524 15.527 2.8023Z"
        fill="currentColor"
      />
    </svg>
  );
};
