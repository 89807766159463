import {FormEvent, FC} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {Button} from "components/shared/Button/Button";
import {Loading} from "components/shared/Loading/Loading";
import {TeamFieldGroup} from "components/shared/FormComponents/TeamFieldGroup/TeamFieldGroup";
import {tUsePeopleFormWork} from "./usePeopleFormWork";
import {TagsSelectGroup} from "components/shared/FormComponents/TagsSelectGroup/TagsSelectGroup";
import {PEOPlE_FORM_ID} from "../../model";
import {CheckBoxGroupe} from "components/shared/FormComponents/CheckBoxGroupe/CheckBoxGroupe";
import {TagsFieldCreating} from "components/shared/FormComponents/TagsFieldCreating/TagsFieldCreating";

interface iPeopleFormWorkProps {
  toNextTab: () => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  workFormData: tUsePeopleFormWork;
}

export const PeopleFormWork: FC<iPeopleFormWorkProps> = ({toNextTab, onSubmit, workFormData}) => {
  const workForm = workFormData;
  const {formData, isLoading, person} = workForm;
  const personName = `${person?.first_name || ""} ${person?.last_name || ""}`;

  if (isLoading) return <Loading className="PeopleLoading-form" />;

  return (
    <form className="PeopleFormWork" id={PEOPlE_FORM_ID} onSubmit={onSubmit}>
      <div className="PeopleForm-col-2">
        <TagsSelectGroup
          {...formData.companies}
          personFirstName={person?.first_name}
          label="Company"
          buttonTitle="Add Company"
        />
        <TagsSelectGroup
          {...formData.agencies}
          personFirstName={person?.first_name}
          label="Artist Agency"
          buttonTitle="Add Agency"
          confirmBtnTitle="Unlink"
          confirmWindowTitle="Unlink Agency"
        />
      </div>

      <div className="PeopleForm-section">
        <h5 className="PeopleForm-section-title">Team</h5>
        <div className="PeopleForm-team">
          <TeamFieldGroup
            {...formData.team.inputProps}
            errors={formData.team.errors}
            personName={personName}
          />
        </div>
      </div>

      <div className="PeopleForm-section">
        <TagsFieldCreating
          {...formData.titles.inputProps}
          fieldProps={formData.titles}
          errors={formData.titles.errors}
          placeholder="Select or begin typing..."
          label="Titles"
        />
      </div>

      <div className="PeopleForm-section">
        <InputField
          {...formData.rate.inputProps}
          errors={formData.rate.errors}
          id="rate"
          label="Rate"
        />
      </div>

      <div className="PeopleForm-section-checkbox billingDefault">
        <CheckBoxGroupe
          label="Billing default"
          checkBoxList={[
            {id: 2, name: "Payroll"},
            {id: 1, name: "Invoice"}
          ]}
          initValue={formData.billing_default.selectedCheckBoxes.current}
          onChange={formData.billing_default.checkBoxGroupeOnChange}
          errors={formData.billing_default.errors}
        />
      </div>

      <Button type="button" color="light" className="PeopleForm-button-next" onClick={toNextTab}>
        Continue to Address & Billing Details
      </Button>
    </form>
  );
};
