import {FC, forwardRef, useEffect, useImperativeHandle} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {Select} from "components/shared/FormComponents/Select/Select";
import {tDynamicInputsGroupProps} from "./types";
import {tTypeDynamicFields} from "../types";
import {PhoneField} from "../../PhoneField/PhoneField";
import {useDynamicInputsGroupForm} from "./useDynamicInputsGroupForm";

export const DynamicInputsGroup: FC<tDynamicInputsGroupProps> = forwardRef(
  ({onChangeHandler, showDeleteBtn, handleRemove, isFirstItem, options, itemField, type}, ref) => {
    const {select, input, phone} = useDynamicInputsGroupForm({options, isFirstItem, itemField, type, onChangeHandler}); // prettier-ignore

    useEffect(() => {
      options?.length && select.setOptions(options);
    }, [options]); // eslint-disable-line

    useImperativeHandle(ref, () => ({checkValidity}));

    function checkValidity() {
      const isValidName = select.checkValidity();

      if (type === "phones") {
        const isValidPhones = phone.checkValidity();
        return isValidName && isValidPhones;
      }

      const isValidLink = input.checkValidity();
      return isValidName && isValidLink;
    }

    const labels: Record<tTypeDynamicFields, {input: string; select: string}> = {
      phones: {input: "", select: ""},
      emails: {input: "Email", select: ""},
      socPlatform: {input: "USERNAME", select: "PLATFORM"},
      locations: {input: "Label", select: "Location"}
    };

    const inputId = `input-field-${itemField.uid}`;
    const selectId = `select-field-${itemField.uid}`;
    const hasMultipleRow = isFirstItem ? "full-width" : "";

    return (
      <div className={`DynamicInputsGroup ${type}`}>
        {isFirstItem && (
          <div className={`labelRow ${hasMultipleRow}`}>
            <label htmlFor={inputId}>{labels[type].input}</label>
            <label htmlFor={selectId}>{labels[type].select}</label>
          </div>
        )}
        <div className="valueRow">
          {type !== "phones" && (
            <InputField {...input.inputProps} id={inputId} errors={input.errors} />
          )}

          {/* {type === "phones" && <PhoneField {...phone.inputProps} errors={phone.errors} />} */}
          {type === "phones" && <InputField {...phone.inputProps} errors={phone.errors} />}

          <Select
            {...select.inputProps}
            id={selectId}
            errors={select.errors}
            canSelectEmpty={true}
          />

          {showDeleteBtn && (
            <Button
              type="button"
              color="outline"
              size="sm"
              onClick={() => handleRemove(itemField.uid)}
            >
              <Icon icon="close" size="sm" color="gray" />
            </Button>
          )}
        </div>
      </div>
    );
  }
);
