import {useEffect, useState} from "react";
import {tJobSettingsTemplatesModal} from "./JobSettingsTemplates";
import {useHttp} from "hooks/httpServices/useHttp";
import {jobsCalendarApi} from "api/jobs/calendar";
import {estimateApi} from "api/budget/estimate";
import {jobCrew} from "api/jobCrew";
import {gridApi} from "api/jobs/grid";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {jobTasksApi} from "api/jobs/tasks";

export type tUseJobSettingsTemplatesModalData = {
  id: number;
  name: string;
  templateType: tJobSettingsTemplatesModal;
};

type tListItem = {id: number; name: string};

export function useJobSettingsTemplates() {
  const getListQuery = useHttp<{data: tListItem[]; message: string}>();
  const removeQuery = useHttp<{message: string}>();

  const [modal, setModal] = useState<tJobSettingsTemplatesModal | null>(null);
  const [modalData, setModalData] = useState<tUseJobSettingsTemplatesModalData | null>(null);
  const [renameModal, setRenameModal] = useState<tListItem | null>(null);
  const [list, setList] = useState<tListItem[]>([]);
  const [activeTemplate, setActiveTemplate] = useState<tListItem | null>(null);
  const onCloseModal = () => setModalData(null);

  const configs = {
    calendar: {
      getList: jobsCalendarApi.getJobCalendarTemplates,
      onRemove: jobsCalendarApi.deleteJobCalendarTemplate,
      onRename: jobsCalendarApi.renameCalendarTemplate
    },
    estimate: {
      getList: estimateApi.getEstimateTemplateList,
      onRemove: estimateApi.deleteEstimateTemplate,
      onRename: estimateApi.renameEstimateTemplate
    },
    crew: {
      getList: jobCrew.getJobCrewTemplates,
      onRemove: jobCrew.deleteCrewTemplate,
      onRename: jobCrew.renameCrewTemplate
    },
    task: {
      getList: jobTasksApi.getTaskTemplates,
      onRemove: jobTasksApi.deleteTemplate,
      onRename: jobTasksApi.renameTemplate
    },
    grid: {
      getList: gridApi.getGridTemplates,
      onRemove: gridApi.deleteGridTemplate,
      onRename: gridApi.renameGridTemplate
    }
  };

  useEffect(() => {
    if (modal) {
      getListQuery.call(configs[modal].getList()).then(({data: {data}}) => setList(data));
    }
  }, [modal]); // eslint-disable-line

  const onRemove = async (id: number) => {
    if (!modal) return;
    try {
      const {
        data: {message}
      } = await removeQuery.call(configs[modal].onRemove(id));
      setList(prev => prev.filter(value => value.id !== id));
      eventBus.dispatch("showToast", {text: message});
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const showRenameWindow = async (payload: {id: number; name: string}) => {
    setRenameModal(payload);
  };

  const onRenameCallback = (data: any) => {
    modalData && setModalData(prev => (prev ? {...prev, name: data.name} : null));
    setList(prev =>
      prev.map(value =>
        value.id === data.id
          ? {
              ...value,
              name: data.name
            }
          : value
      )
    );
  };

  return {
    showRenameWindow,
    modalData,
    setModalData,
    list,
    onRemove,
    getListQuery,
    removeQuery,
    modal,
    setModal,
    activeTemplate,
    setActiveTemplate,
    renameModal,
    setRenameModal,
    configs,
    onRenameCallback,
    onCloseModal
  };
}
