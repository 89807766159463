import {FC} from "react";
import {TextComponent} from "../../../common/TextComponent/TextComponent";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import "./AdvanceModalFormList.scss";

interface iAdvanceModalFormBodyProps {
  onEdit: (arg: any) => void;
  onDelete: (arg: any) => void;
  onAdd: (arg: "add") => void;
  data: any[];
  title: string;
  btnText: string;
}

export const AdvanceModalFormList: FC<iAdvanceModalFormBodyProps> = ({
  data,
  onEdit,
  onDelete,
  onAdd,
  title,
  btnText
}) => {
  return (
    <div className="AdvanceModalFormList">
      <div className="AdvanceModalFormList-header">
        <TextComponent size="20" uppercase>
          {title}
        </TextComponent>
      </div>
      <div className="AdvanceModalFormList-body">
        <ul className="AdvanceModalFormList-list">
          {!!data?.length &&
            data.map(item => (
              <li key={item.id} className="AdvanceModalFormList-listItem">
                <TextComponent key={item?.id} weight="500" size="14">
                  {item?.name}
                </TextComponent>
                <div>
                  <button className="AdvanceModalFormList-listButton" onClick={() => onEdit(item)}>
                    <Icon icon="edit" color="gray" />
                  </button>
                  <button
                    className="AdvanceModalFormList-listButton"
                    onClick={() => onDelete(item)}
                  >
                    <Icon icon="delete" color="gray" />
                  </button>
                </div>
              </li>
            ))}
        </ul>

        <Button width="fullWidth" size="sm" color="outline" onClick={() => onAdd("add")}>
          <Icon icon="add" size="xsm" /> <span>{btnText}</span>
        </Button>
      </div>
    </div>
  );
};
