import {createContext, FC, useContext, useEffect, useState} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {overviewApi} from "api/dashboard/overview";
import {useSetApiParams} from "hooks/useSetApiParams";
import {iJobReturn} from "api/jobs/types";
import {tPipeline} from "api/dashboard/overview/types";

const useOverviewList = () => {
  const {
    call: pipelinesCall,
    data: pipelinesData,
    isLoading: pipelinesIsLoading
  } = useHttp<{data: tPipeline[]}>();

  const [showPipelines, setShowPipelines] = useState(true);
  const pipelines = pipelinesData?.data?.data || [];

  const {call, data, ...props} = useHttp();
  const apiParams = useSetApiParams();

  useEffect(() => {
    getJobsPipelines();
  }, []); // eslint-disable-line

  useEffect(() => {
    getOverviewList();
  }, [apiParams?.searchParams]); // eslint-disable-line

  const getJobsPipelines = () => {
    pipelinesCall(overviewApi.getPipelines());
  };

  const getOverviewList = () => {
    const params = {
      ...apiParams.preparedParams,
      locales: apiParams?.preparedParams.locales?.split(",").map(item => +item)
    };

    return call(overviewApi.getJobs(params));
  };

  return {
    overviewList: data?.data?.data as Array<iJobReturn>,
    getOverviewList,
    data,
    apiParams,
    showPipelines,
    setShowPipelines,
    pipelines,
    pipelinesIsLoading,
    getJobsPipelines,
    ...props
  };
};

type tOverviewContext = ReturnType<typeof useOverviewList>;
const OverviewContext = createContext<tOverviewContext | null>(null);
OverviewContext.displayName = "OverviewContext";
export const OverviewContextProvider: FC = ({children}) => (
  <OverviewContext.Provider value={useOverviewList()}>{children}</OverviewContext.Provider>
);

/**
 * Provides team list
 * @returns (team list and functions for get this one)
 */
export const useContextOverview = () => {
  const context = useContext(OverviewContext);

  if (context === null) {
    throw new Error("<<< OverviewContext >>> must be used within a OverviewContext");
  }

  return context as tOverviewContext;
};
