import {useState} from "react";
import {tags} from "api/tags";
import {companies as companiesApi} from "api/contacts/companies";
import {iCompanyDetails} from "api/contacts/companies/interfaces";
import {people as peopleApi} from "api/contacts/people";
import {iPersonDetails} from "../../Contacts/People/interfaces";
import {contactsApi} from "api/contacts";
import {useHttp} from "hooks/httpServices/useHttp";
import {useSetApiParams} from "hooks/useSetApiParams";
import {checkExistingClientsParamData, getUnique, checkKeyExistsById} from "./helpers";
import {eventBus} from "EventBus/EventBus";
import {useContextJobsList} from "../context/JobsListContext/JobsListContext";

function getFullName(first: string, last: string): string {
  return `${first} ${last}`;
}
export type tUseFilter = ReturnType<typeof useFilter>;

export const useFilter = () => {
  const {
    localesOptions,
    labelsOptions,
    setArtistsOptions,
    setClientsOptions,
    setLabelsOptions,
    setLocalesOptions
  } = useContextJobsList();
  const apiParams = useSetApiParams();

  const [clients, setClients] = useState<{id: number; type: string}[]>([]);

  const filterApiData = {
    clients: useHttp(), // only for searching
    companies: useHttp(),
    people: useHttp(),
    artists: useHttp(),
    labels: useHttp(),
    locales: useHttp()
  };

  const filterData = {
    labels: apiParams?.preparedParams?.labels?.split(","),
    locales: apiParams?.preparedParams?.locales?.split(","),
    departments: apiParams?.preparedParams?.departments,
    pipelines: apiParams?.preparedParams?.pipelines,
    people: apiParams?.preparedParams?.people?.split(","),
    companies: apiParams?.preparedParams?.companies?.split(","),
    artists: apiParams?.preparedParams?.artists?.split(",")
  };

  const getFilterApiData = () => {
    const {preparedParams} = apiParams;

    // prevent rerender & update userdata
    if (checkExistingClientsParamData("companies", preparedParams, clients)) {
      const companiesId = {
        ids: preparedParams?.companies?.split(",").map(k => Number(k))
      };

      filterApiData.companies
        .call(companiesApi?.getCompaniesListById(companiesId))
        .then(({data: {data}}) => {
          setClients(prevState =>
            getUnique(
              [...prevState, ...data.map((item: iCompanyDetails) => ({...item, type: "company"}))],
              ["id", "name"]
            )
          );
        });
    }

    // prevent rerender & update userdata
    if (checkExistingClientsParamData("people", preparedParams, clients)) {
      const peopleId = {
        ids: preparedParams?.people?.split(",").map(k => Number(k))
      };

      filterApiData.people.call(peopleApi?.getPeopleListById(peopleId)).then(({data: {data}}) => {
        setClients(prevState =>
          getUnique(
            [
              ...prevState,
              ...data.map((item: iPersonDetails) => ({
                ...item,
                name: getFullName(item?.first_name || "", item?.last_name || ""),
                type: "person"
              }))
            ],
            ["id", "name"]
          )
        );
      });
    }

    // prevent rerender & updates actual artists
    if (checkKeyExistsById(preparedParams, filterApiData?.artists?.data?.data?.data, "artists")) {
      const artistsId = {
        ids: preparedParams?.artists?.split(",").map(k => Number(k))
      };
      filterApiData.artists
        .call(peopleApi?.getPeopleListById(artistsId))
        .then(({data: {data}}) => setArtistsOptions(data));
    }

    // prevent rerender & updates actual labels
    if (checkKeyExistsById(preparedParams, labelsOptions, "labels")) {
      const labelsId = {
        ids: preparedParams?.labels?.split(",").map(k => Number(k))
      };

      filterApiData.labels.call(tags.labels.get(labelsId)).then(({data: {data}}) => {
        setLabelsOptions(prevState => getUnique([...prevState, ...data], ["id"]));
      });
    }

    // prevent rerender & updates actual locales
    if (checkKeyExistsById(preparedParams, localesOptions, "locales")) {
      const localesId = {
        ids: preparedParams?.locales?.split(",").map(k => Number(k))
      };

      filterApiData.locales.call(tags.locales.get(localesId)).then(({data: {data}}) => {
        setLocalesOptions(prevState => getUnique([...prevState, ...data], ["id"]));
      });
    }
  };

  const onSearchClients = async (query: string) => {
    try {
      const response = await filterApiData?.clients?.call(contactsApi?.searchContacts({query}));
      setClientsOptions(response?.data?.data);
    } catch (error: any) {
      eventBus.dispatch("showToast", {type: "error", text: error?.data?.message});
    }
  };

  const onSearchLabels = (search: string) => {
    filterApiData.labels
      .call(tags.labels.get({search, search_type: "or"}))
      .then(({data: {data}}) => {
        setLabelsOptions(prevState => getUnique([...prevState, ...data], ["id"]));
      });
  };

  const onSearchLocals = (search: string) => {
    filterApiData.locales
      .call(tags.locales.get({search, search_type: "or"}))
      .then(({data: {data}}) => {
        setLocalesOptions(prevState => getUnique([...prevState, ...data], ["id"]));
      });
  };

  const onSearchArtists = (search: string) => {
    filterApiData?.artists?.call(peopleApi?.getList({search})).then(({data: {data}}) => {
      setArtistsOptions(prevState => getUnique([...prevState, ...data], ["id"]));
    });
  };

  const clearFilters = () => {
    apiParams.clearAllParams();
    setClients([]);
  };

  return {
    getFilterApiData,

    onSearchClients,
    onSearchArtists,
    onSearchLabels,
    onSearchLocals,
    filterApiData,
    filterData,
    setClients,
    clearFilters,
    clients
  };
};
