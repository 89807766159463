import moment from "moment";
import {tCalendarTemplateInfoEvent} from "api/jobs/calendar/types";

export type tCalendarTemplateEvent = tCalendarTemplateInfoEvent & {
  start_date: string;
  end_date: string;
  clearId?: number;
};

export function createIntervalByDates(event: tCalendarTemplateEvent) {
  const startPoint = moment().startOf("week");
  return {
    ...event,
    start_day: moment(event.start_date).diff(moment(startPoint), "day") + 1,
    end_day: moment(event.end_date).diff(moment(startPoint), "day") + 1
  };
}

export function createDatesByInterval(event: tCalendarTemplateEvent) {
  const startTime = {
    hour: moment(event.start_time).get("hour"),
    minute: moment(event.start_time).get("minute")
  };

  const endTime = {
    hour: moment(event.end_time).get("hour"),
    minute: moment(event.end_time).get("minute")
  };

  return {
    ...event,
    start_date: event.all_day
      ? moment()
          .startOf("week")
          .add(event.start_day - 1, "day")
          .toISOString()
      : moment()
          .startOf("week")
          .add(event.start_day - 1, "day")
          .set(startTime)
          .toISOString(),
    end_date: event.all_day
      ? moment()
          .startOf("week")
          .add(event.end_day, "day")
          .set({hour: 0, minute: 0, second: 0})
          .toISOString()
      : moment()
          .startOf("week")
          .add(event.end_day - 1, "day")
          .set(endTime)
          .toISOString()
  };
}
