import {useEffect, useState} from "react";
import {jobEventModel, tEventModal} from "../helpers";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextJobCalendar} from "../context";
import moment from "moment/moment";
import {jobsCalendarApi} from "api/jobs/calendar";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useScript} from "hooks/useScript";
import {eventBus} from "EventBus/EventBus";
import {getDateFormat} from "helpers/dates";

export function useJobScheduler() {
  const {status, removeScript} = useScript(
    "https://cdn.dhtmlx.com/scheduler/edge/dhtmlxscheduler.js"
  );
  const eventUpdateQuery = useHttp();
  const {calendarSheets, setCalendarSheets, activeCalendar} = useContextJobCalendar();

  const [addEvent, setAddEvent] = useState<tEventModal | null>(null);
  const [showEventDetails, setShowEventDetails] = useState<tEventModal | number | null>(null);
  const [clearEventId, setClearEventId] = useState<number | null>(null);

  useEffect(() => {
    return () => {
      setAddEvent(null);
      setShowEventDetails(null);
      removeScript();
    };
  }, []); // eslint-disable-line

  const checkValidityNewEvent = (date: Date) => {
    return moment(date).diff(moment(), "minutes") > 0;
  };

  const handleEmptyClick = (date: Date) => {
    const isValidStartDate = moment(date).diff(moment().startOf("day"), "minutes") >= 0;

    if (isValidStartDate && !addEvent) {
      setAddEvent({start_date: date.toDateString()});
    }
  };

  const handleBeforeEventChange = async (
    event: tEventModal,
    e: any,
    is_new: boolean,
    origin: tEventModal,
    allDay: boolean
  ) => {
    if (is_new && checkValidityNewEvent(event.start_date)) {
      setAddEvent({
        clearId: event.id,
        start_date: event.start_date,
        end_date: event.end_date,
        all_day: allDay
      });
      return;
    }

    const {id, all_day, location_id, description, name, end_date, start_date} = event;

    let formData = {
      job_calendar_id: activeCalendar?.id,
      name,
      start_date: getDateFormat(start_date, "timestamp"),
      end_date: getDateFormat(end_date, "timestamp"),
      all_day,
      ...(description && {description: description}),
      ...(location_id && {location_id: location_id})
    };

    try {
      const response = await eventUpdateQuery.call(jobsCalendarApi.updateJobCalendarEvent(id, formData)); // prettier-ignore
      const eventChanged = jobEventModel(response.data.data, event?.type || "events");
      setCalendarSheets(prev => prev.map(ev => (ev.id === eventChanged.id ? eventChanged : ev)));
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const onShowEventDetails = (id: string, events: tEventModal[]) => {
    const details = events.find((item: any) => String(item.id) === String(id));
    details && setShowEventDetails(details);
  };

  const handleCloseEventEdit = (id?: number) => {
    id && setClearEventId(id);
    setAddEvent(null);
  };

  const onEditEvent = (event: tEventModal) => setAddEvent(event);

  return {
    calendarSheets,
    setCalendarSheets,
    showEventDetails,
    setShowEventDetails,
    handleCloseEventEdit,
    setClearEventId,
    clearEventId,
    onShowEventDetails,
    status,
    handleBeforeEventChange,
    handleEmptyClick,
    addEvent,
    onEditEvent
  };
}
