import {FC} from "react";

export const Text: FC = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 3.50002V0.456543H0V3.50002H2.43478V1.67393H5.78261V12.3261H3.65217V13.5435H10.3478V12.3261H8.21739V1.67393H11.5652V3.50002H14Z"
        fill="currentColor"
      />
    </svg>
  );
};
