import {useState} from "react";

export const useApp = () => {
  const [sidebar, setSidebar] = useState(false);
  const [hubSearchWindow, setHubSearchWindow] = useState(false);
  const [notificationsWindow, setNotificationsWindow] = useState(false);
  const [accountModal, setAccountModal] = useState(false);

  return {
    sidebar,
    setSidebar,
    hubSearchWindow,
    setHubSearchWindow,
    notificationsWindow,
    setNotificationsWindow,
    accountModal,
    setAccountModal
  };
};
