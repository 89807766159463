import {FC, FormEvent} from "react";
import {Accordion} from "components/shared/Accordion/Accordion";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {TextareaField} from "components/shared/FormComponents/TextareaField/TextareaField";
// import {PhoneField} from "components/shared/FormComponents/PhoneField/PhoneField";
import {useContextProfile} from "context";
import {useAccountProfileForm} from "./useAccountProfileForm";
import {UserUploadFile} from "components/shared/UserUploadFile/UserUploadFile";
import {DynamicFieldsWrap} from "components/shared/FormComponents/DynamicFields/DynamicFieldsWrap";
import {AddressField} from "components/shared/FormComponents/AddressField/AddressField";
import {ACCOUNT_FORM_ID} from "../AccountLayoutTab/AccountLayoutTab";
import {generatePostData} from "./generatePostData";
import {useAccountFormValidation} from "./useAccountFormValidation";
import {SelectInput} from "components/shared/FormComponents/SelectInput/SelectInput";
import {DatepickerWithYear} from "components/shared/FormComponents/Datepicker/DatepickerWithYear";
import "./AccountProfile.scss";

interface iAccountProfile {}

export const AccountProfile: FC<iAccountProfile> = () => {
  const {updateUserData} = useContextProfile();
  const {form, avatar, setAvatar, socPlatformRef, accounts, setAccounts, accPlatformOptions} =
    useAccountProfileForm();
  const {changeShowTab, showTab, notValid} = useAccountFormValidation();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (notValid(form)) return;

    const data = generatePostData({accounts, avatar, form});
    updateUserData(data, form);
  };

  return (
    <form id={ACCOUNT_FORM_ID} className="AccountProfile" onSubmit={handleSubmit}>
      <Accordion title="About" isShown={showTab?.about} callBack={() => changeShowTab("about")}>
        <div className="AccountProfile-section">
          <UserUploadFile avatar={avatar} setAvatar={setAvatar} fallBackType="avatar" />

          <div className="AccountProfile-group-triple">
            <InputField
              label="Display Name"
              {...form.display_name.inputProps}
              errors={form.display_name.errors}
            />
            <SelectInput
              {...form.pronoun_id.inputProps}
              isCustomInput={form.pronoun_id.isCustomInput}
              customInput={form.pronoun_id.customInput}
              clearAll={form.pronoun_id.clearAll}
              errors={form.pronoun_id.errors}
              label="Pronouns"
              className="PeopleFormOther-select"
              // canSelectEmpty
            />
            <DatepickerWithYear
              {...form.birthday.inputProps}
              label="Birthday"
              placeholderText="mm/dd/yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              errors={form.birthday.errors}
            />
          </div>
          <TextareaField
            {...form.fun_facts.inputProps}
            errors={form.fun_facts.errors}
            label="Fun Facts"
          />
        </div>
      </Accordion>

      <Accordion
        title="Work and Social Accounts"
        isShown={showTab?.accounts}
        callBack={() => changeShowTab("accounts")}
      >
        <div className="AccountProfile-section">
          <DynamicFieldsWrap
            ref={socPlatformRef}
            dataList={accounts}
            setDataList={setAccounts}
            selectOptions={accPlatformOptions}
            type="socPlatform"
            buttonName="Add Account"
            title="Delete account?"
          />
        </div>
      </Accordion>

      <Accordion
        title="Personal Information"
        isShown={showTab?.personalInfo}
        callBack={() => changeShowTab("personalInfo")}
      >
        <div className="AccountProfile-section">
          <div className="AccountProfile-group-double">
            <InputField
              {...form.phone.inputProps}
              label="Mobile Phone*"
              errors={form.phone.errors}
            />
            <InputField
              {...form.personal_email.inputProps}
              label="Personal Email"
              errors={form.personal_email.errors}
            />
          </div>

          <AddressField {...form.home_address} />
        </div>
      </Accordion>

      <Accordion
        title="Emergency Contact"
        isShown={showTab?.emergency}
        callBack={() => changeShowTab("emergency")}
      >
        <div className="AccountProfile-section">
          <div className="AccountProfile-group-double">
            <InputField
              {...form.full_name.inputProps}
              label="Full Name"
              errors={form.full_name.errors}
            />
            <InputField
              {...form.relationship.inputProps}
              label="Relationship"
              errors={form.relationship.errors}
            />
          </div>
          <div className="AccountProfile-group-double">
            <InputField
              {...form.phoneEmergency.inputProps}
              label="Phone"
              errors={form.phoneEmergency.errors}
            />
            <InputField {...form.email.inputProps} label="Email" errors={form.email.errors} />
          </div>
        </div>
      </Accordion>
    </form>
  );
};
