import {FC} from "react";
import {JobBudgetHeaderLayout} from "../../JobBudgetHeaderLayout/JobBudgetHeaderLayout";
import {EstimateHeader} from "../EstimateHeader/EstimateHeader";
import {EstimateStatusLine} from "../EstimateStatusLine/EstimateStatusLine";
import EstimateOverlay from "../EstimateOverlay/EstimateOverlay";
import {EstimateExportBuilder} from "./EstimateExportBuilder/EstimateExportBuilder";
import {ExportBudgetProvider, useContextExportBudget} from "../../context/ExportBudgetContext";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {Loading} from "components/shared/Loading/Loading";

const EstimateExportComponent: FC = () => {
  const {exportInfo, exportInfoQuery, pdfData, pdfDataA2, onSaveExportInfoCallback} = useContextExportBudget(); // prettier-ignore
  const {jobDetailsData} = useContextJobDetails();
  const isApproved = !!exportInfo?.estimate?.approved_at;

  return (
    <>
      <JobBudgetHeaderLayout>
        <EstimateHeader />
      </JobBudgetHeaderLayout>
      <EstimateStatusLine />

      {!exportInfo || exportInfoQuery.isLoading ? (
        <Loading type="inBlock" />
      ) : (
        <>
          {isApproved && exportInfo?.estimate && jobDetailsData && (
            <EstimateOverlay pdfUrl={pdfData?.url} />
          )}
          {!isApproved && !exportInfoQuery.isLoading && exportInfo && (
            <EstimateExportBuilder
              exportInfo={exportInfo}
              pdfDataA2={pdfDataA2}
              onSaveExportInfoCallback={onSaveExportInfoCallback}
              jobDetailsData={jobDetailsData}
            />
          )}
        </>
      )}
    </>
  );
};

export const EstimateExport: FC = () => {
  return (
    <ExportBudgetProvider>
      <EstimateExportComponent />
    </ExportBudgetProvider>
  );
};
