import {useEffect, useRef, useState} from "react";
import {createNewRowObj} from "components/shared/FormComponents/DynamicFields/model";
import {createNewRowObj as createNewRowObjPhone} from "components/shared/FormComponents/DynamicFieldsPhone/model";
import {createNewRowObj as createNewRowObjEmail} from "components/shared/FormComponents/DynamicFieldsEmail/model";

import {tDynamicFieldRowObj} from "components/shared/FormComponents/DynamicFields/types";

import {tDynamicFieldRowObj as tDynamicFieldRowObjPhone} from "components/shared/FormComponents/DynamicFieldsPhone/types";
import {tDynamicFieldRowObj as tDynamicFieldRowObjEmail} from "components/shared/FormComponents/DynamicFieldsEmail/types";

import {uid} from "helpers/misc";
import {iPersonDetails} from "pages/Contacts/People/interfaces";
import {getAccountsDifference, getDifferenceDynamicFields} from "pages/Contacts/helpers";

interface iProps {
  person: iPersonDetails | null | undefined;
}
type tCheckFieldsValidity = {
  checkFieldsValidity: () => boolean;
};

// TODO need to improve
export const useDynamicFields = ({person}: iProps) => {
  const phonesRef = useRef<tCheckFieldsValidity>(null);
  const [phones, setPhones] = useState<tDynamicFieldRowObjPhone[]>([createNewRowObjPhone()]);

  const emailsRef = useRef<tCheckFieldsValidity>(null);
  const [emails, setEmails] = useState<tDynamicFieldRowObjEmail[]>([createNewRowObjEmail()]);

  const socPlatformRef = useRef<tCheckFieldsValidity>(null);
  const [accounts, setAccounts] = useState<tDynamicFieldRowObj[]>([createNewRowObj()]);

  useEffect(() => {
    if (person?.phones?.length) {
      setPhones(
        person.phones.map((item: any) => {
          return {
            uid: item.id || uid(),
            id: item?.type?.id,
            name: item?.type?.name,
            type_custom: item?.type_custom,
            value: item?.value
          };
        })
      );
    }

    if (person?.emails?.length) {
      setEmails(
        person.emails.map((item: any) => {
          return {
            uid: item.id || uid(),
            id: item?.type?.id,
            value: item?.value || "",
            name: item?.type?.name,
            type_custom: item?.type_custom || ""
          };
        })
      );
    }

    if (person?.accounts?.length) {
      setAccounts(
        person.accounts.map((item: any) => {
          return {
            uid: item.id || uid(),
            id: item?.platform?.id,
            value: item?.value || "",
            name: item?.platform?.name
          };
        })
      );
    }
  }, []); // eslint-disable-line

  const isChanged =
    getDifferenceDynamicFields(person?.phones || [], phones) ||
    getDifferenceDynamicFields(person?.emails || [], emails) ||
    getAccountsDifference(person?.accounts || [], accounts);

  return {
    phonesRef,
    phones,
    setPhones,
    isChanged,

    emailsRef,
    emails,
    setEmails,

    socPlatformRef,
    accounts,
    setAccounts
  };
};
