import React, {ChangeEvent, useState} from "react";
import {tFieldOption} from "./FieldSetup";
import {uid} from "helpers/misc";

type tProps = {
  fields: tFieldOption[];
  setFields: React.Dispatch<React.SetStateAction<tFieldOption[]>>;
  fieldOptionShape: tFieldOption;
  name: string;
};

export const useFieldOptionCRUD = ({fields, setFields, fieldOptionShape, name}: tProps) => {
  const [err, setErr] = useState("");
  const errorMessage = "This field can't be empty";
  const errMsg = "Input value should not be empty";

  const addNewOption = () => {
    if (fields.find(item => !item.value)) {
      setFields(prev =>
        prev.map(item => ({
          ...item,
          errors: !item?.value ? [errorMessage] : []
        }))
      );
      return setErr(errMsg);
    }

    if (!fields?.length) {
      setFields([fieldOptionShape]);
      return;
    }

    const order = fields[fields.length - 1].order + 1;
    const id = `${name.split(" ").join("_")}_${uid()}`;
    const newFields = [
      ...fields,
      {
        id,
        errors: [],
        value: "",
        order
      }
    ];
    setFields(newFields);
  };

  const onRemove = (id: string) => {
    err && setErr("");
    const newFields = fields.filter(value => value.id !== id);
    setFields([...newFields]);
  };

  const onChangeInputValue = (event: ChangeEvent<HTMLInputElement>) => {
    const {value, id} = event.target;
    err && value && setErr("");

    setFields(prev => {
      return prev?.map(item => {
        if (item.id === id) {
          item.value = value;
          item.errors = !!item.value ? [] : [errorMessage];
          return item;
        }

        return item;
      });
    });
  };

  return {
    onRemove,
    onChangeInputValue,
    addNewOption,
    err
  };
};
