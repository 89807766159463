import {FC, useState} from "react";
import {tTrackExpenseLine, typeFees} from "api/budget/track/types";
import {Button} from "components/shared/Button/Button";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {AdditionalFiles} from "../../../_modals/AdditionalFiles/AdditionalFiles";
import {DeleteExpense} from "../../../_modals/DeleteExpense";
import {MoveExpense} from "../../../_modals/MoveExpense";
import {ActionsBillAndReceipt} from "./ActionsBillAndReceipt";

type tActionsProps = {
  className?: string;
  trackLine: tTrackExpenseLine;
  typeFees?: typeFees;
};

export const Actions: FC<tActionsProps> = ({className = "", trackLine, typeFees}) => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  type modalTypes = "files" | "move" | "delete";
  const [typeModal, setModal] = useState<modalTypes | null>(null);
  const onCloseModal = () => setModal(null);
  const openModal = (type: modalTypes) => {
    setMenuAnchor(null);
    setModal(type);
  };

  return (
    <div className={`${className} Actions`}>
      <TextComponent onClick={showMenu} as="button" color="gray-10">
        &#183;&#183;&#183;
      </TextComponent>

      <DropdownMenu anchor={menuAnchor} onClose={() => setMenuAnchor(null)} btnHover>
        <Button size="sm" className="btn" onClick={() => openModal("files")}>
          Files
        </Button>

        <hr />
        <ActionsBillAndReceipt typeFees={typeFees} data={trackLine} />
        <hr />

        <Button size="sm" className="btn" onClick={() => openModal("move")}>
          Move
        </Button>
        <Button size="sm" className="btn" onClick={() => openModal("delete")}>
          Delete
        </Button>
      </DropdownMenu>

      {typeModal === "files" && <AdditionalFiles onClose={onCloseModal} trackLine={trackLine} />}
      {typeModal === "move" && (
        <MoveExpense onClose={onCloseModal} trackLine={trackLine} typeFees={typeFees} />
      )}
      {typeModal === "delete" && <DeleteExpense onClose={onCloseModal} trackLine={trackLine} />}
    </div>
  );
};
