import {DetailedHTMLProps, FC, ReactNode} from "react";
import "./ButtonGroup.scss";

interface iButtonGroup
  extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode;
  className?: string;
}

export const ButtonGroup: FC<iButtonGroup> = ({children, className = ""}) => {
  return <div className={`ButtonGroup ${className}`}>{children}</div>;
};
