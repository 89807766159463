import {AxiosRequestConfig} from "axios";

const job_crew = "job_crew";
const group = "group";

export const jobCrewGroup = {
  addNewJobCrewGroup: (data: {
    job_crew_id: number;
    name: string;
    position?: number;
  }): AxiosRequestConfig => ({
    url: `${job_crew}/${group}`,
    method: "POST",
    data
  }),
  getJobCrewGroup: (job_crew_contact_group_id: number): AxiosRequestConfig => ({
    url: `${job_crew}/${group}/${job_crew_contact_group_id}`,
    method: "GET"
  }),
  getJobCrewGroupList: (job_crew_id: number): AxiosRequestConfig => ({
    url: `${job_crew}/${job_crew_id}/groups`,
    method: "GET"
  }),
  updateJobCrewGroup: (
    job_crew_contact_group_id: number,
    data?: {name?: string; position?: number}
  ): AxiosRequestConfig => ({
    url: `${job_crew}/${group}/${job_crew_contact_group_id}`,
    method: "POST",
    data
  }),
  deleteJobCrewContactGroup: (job_crew_contact_group_id: number): AxiosRequestConfig => ({
    url: `${job_crew}/${group}/${job_crew_contact_group_id}`,
    method: "DELETE"
  }),
  addContactToCrewGroup: (
    job_crew_contact_group_id: number,
    data: {person_id: number}
  ): AxiosRequestConfig => ({
    url: `${job_crew}/${group}/${job_crew_contact_group_id}/contact`,
    method: "POST",
    data
  }),
  addContactListToCrewGroup: (
    job_crew_contact_group_id: number,
    data: {contacts: Array<number>}
  ): AxiosRequestConfig => ({
    url: `${job_crew}/${group}/${job_crew_contact_group_id}/contacts`,
    method: "POST",
    data
  })
};
