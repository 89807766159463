import {useState, FC, useEffect} from "react";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import {ContactFieldItem} from "./ContactFieldItem";
import {AxiosRequestConfig} from "axios";
import {useHttp} from "hooks/httpServices/useHttp";
import {tContactFieldGroupRow} from "./interfaces";
import {ConfirmWindow} from "../../ConfirmWindow/ConfirmWindow";
import "./ContactFieldGroup.scss";

interface iContactFieldGroupProps {
  value: tContactFieldGroupRow[];
  onChange: (val: tContactFieldGroupRow) => void;
  onRemoveRow: (id: string) => void;
  addNewRow: () => void;
  nameLabel?: string;
  relationshipLabel?: string;
  callTitle: () => AxiosRequestConfig;
  callOnCreateTitle: (data: {name: string}) => AxiosRequestConfig;
  errors?: string[];
  personNameKeys: string[];
  onInputChange: (arg: string) => void;
  isLoading: boolean;
  options: any[];
  name?: string;
}

export const ContactFieldGroup: FC<iContactFieldGroupProps> = ({
  value,
  onChange,
  onRemoveRow,
  addNewRow,
  nameLabel,
  relationshipLabel,
  callTitle,
  callOnCreateTitle,
  personNameKeys,
  errors = [],
  onInputChange,
  isLoading,
  options,
  name
}) => {
  const {data: relationshipOption, call: titleCall} = useHttp();

  const [activeItem, setActiveItem] = useState<any>(null);
  const lastElementIsEmpty = value.length && !value[value.length - 1]?.value?.person?.id;

  useEffect(() => {
    titleCall(callTitle());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchTitle = () => titleCall(callTitle());

  const personOptions = () => {
    return options.filter(
      (item: {id: number}) => !value.find(v => Number(v?.value?.person?.id) === Number(item.id))
    );
  };

  const showConfirmRemove = (data: any) => setActiveItem(data);
  const confirmRemove = () => {
    onRemoveRow(activeItem?.id);
    setActiveItem(null);
  };

  const hideRemoveModal = () => setActiveItem(null);

  return (
    <div className="ContactFieldGroup">
      {!!value.length && value?.[0]?.value && (
        <div className="ContactFieldGroup-labelRow">
          <label className="ContactFieldGroup-label">{nameLabel}</label>
          <label className="ContactFieldGroup-label">{relationshipLabel}</label>
        </div>
      )}

      {value?.map((item: any) => (
        <ContactFieldItem
          key={item.id}
          item={item}
          onChange={onChange}
          handleRemove={onRemoveRow}
          callOnCreateTitle={callOnCreateTitle}
          personOption={personOptions()}
          relationshipOption={relationshipOption?.data?.data}
          refetchTitle={refetchTitle}
          personNameKeys={personNameKeys}
          onInputChange={onInputChange}
          confirmRemove={showConfirmRemove}
          isLoading={isLoading}
        />
      ))}

      {!lastElementIsEmpty && (
        <Button
          className="ContactFieldGroup-button"
          size="sm"
          color="outline"
          type="button"
          onClick={addNewRow}
        >
          <Icon icon="add" size="sm" />
          <span>Add Contact</span>
        </Button>
      )}

      {errors.map((error, index) => (
        <p key={index} className="ContactFieldGroup-error">
          {error}
        </p>
      ))}

      {activeItem && (
        <ConfirmWindow
          show={!!activeItem}
          onClose={hideRemoveModal}
          onConfirm={confirmRemove}
          confirmBtn={{color: "red", text: "Delete"}}
          cancelBtn={{text: "Cancel"}}
          title="Unlink Contact"
        >
          <div className="PeopleForm-confirmWindow-body">
            <p>
              Are you sure you want to unlink {activeItem?.value?.person?.name} from location
              record?
            </p>
            <p className="PeopleForm-confirmWindow-warning">This can't be undone.</p>
          </div>
        </ConfirmWindow>
      )}
    </div>
  );
};
