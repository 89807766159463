import {AxiosRequestConfig} from "axios";

const job_crew = "job_crew";
const contact = "contact";
const position = "position";

export const jobCrewContactPosition = {
  getContactPositions: (): AxiosRequestConfig => ({
    url: `${job_crew}/${contact}/${position}s`,
    method: "GET"
  }),
  createContactPosition: (data: {name: string}): AxiosRequestConfig => ({
    url: `${job_crew}/${contact}/${position}`,
    method: "POST",
    data
  }),
  updateContactPosition: (
    job_crew_contact_position_id: number,
    data: {name: string}
  ): AxiosRequestConfig => ({
    url: `${job_crew}/${contact}/${position}/${job_crew_contact_position_id}`,
    method: "POST",
    data
  }),
  removeContactPosition: (job_crew_contact_position_id: number): AxiosRequestConfig => ({
    url: `${job_crew}/${contact}/${position}/${job_crew_contact_position_id}`,
    method: "DELETE"
  })
};
