import {useEffect, useState} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {jobsApi} from "api/jobs";
import {iPipelinesGroup} from "api/interfaces";
import {eventBus} from "EventBus/EventBus";
import {iJobDetailsDataReturn} from "api/jobs/types";
import {invoiceApi} from "api/invoice";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {getDateFormat} from "helpers/dates";
import {useContextBasicData} from "context/BasicDataContext/BasicDataContext";

export type tPipeline = {id: number; name: string; job_pipeline_group_id: number};

interface iUsePipelineSelection {
  jobData: iJobDetailsDataReturn | null;
  onSelectedPipelineCallback?: (job?: iJobDetailsDataReturn) => void;
}

export function usePipelineSelection({jobData, onSelectedPipelineCallback}: iUsePipelineSelection) {
  const {pipelines, getData} = useContextBasicData();
  const updateJobPipelineQuery = useHttp();
  const jobDetailsQuery = useHttp();
  const invoiceChangeStatusQuery = useHttp();
  const [activePipeline, setActivePipeline] = useState<tPipeline | null>(null);
  const [selectionLoading, setSelectionLoading] = useState(0);
  const [updatingStatus, setUpdatingStatus] = useState(false);

  const [loading, setLoading] = useState(false);
  const getPipelines = async () => {
    setLoading(true);
    try {
      await getData("pipelines");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };
  useEffect(() => {
    getPipelines();
  }, []); // eslint-disable-line

  const onSelectPipeline = async (id: number, item: any, date?: string) => {
    if (jobData?.pipeline?.id === id) {
      return onSelectedPipelineCallback?.();
    }

    if (!jobData) return;

    setSelectionLoading(id);

    const payload = {
      pipeline_id: id,
      ...(date && {pipeline_due_date: getDateFormat(date, "timestamp")})
    };

    try {
      const {
        data: {data}
      } = await updateJobPipelineQuery.call(jobsApi.setPipelineToJob(jobData.id, payload));
      eventBus.dispatch("showToast", {text: `The Pipeline “${item?.name}” has been set.`});
      setSelectionLoading(0);
      onSelectedPipelineCallback?.(data);
    } catch (error: any) {
      setSelectionLoading(0);
      eventBus.dispatch("showToast", {type: "error", text: error?.message});
    }
  };

  const onChangePipeLine = (id: number, item: tPipeline) => {
    // Bidding, Working, Wrap.All Paid,  Done
    if (item.job_pipeline_group_id !== 3 || id === 11) {
      onSelectPipeline(id, item);
      return;
    }
    setActivePipeline(item);
  };

  const onSavePipelineDate = (date: string) => {
    if (!activePipeline?.id) return;
    if (activePipeline?.id === 10 || activePipeline?.id === 12) {
      if (!jobData?.invoice?.id) {
        eventBus.dispatch("showToast", {type: "error", text: "Job doesn't have invoice"});
        return;
      }
      updateInvoiceStatus(jobData?.invoice?.id, activePipeline.id, date);
      return;
    }
    onSelectPipeline(activePipeline?.id, activePipeline, date);
  };

  async function updateInvoiceStatus(invoiceId: number, pipeId: number, date: string) {
    if (!jobData?.id) return;

    setUpdatingStatus(true);
    const status = pipeId === 10 ? 1 : 2;
    const payload = {
      date: getDateFormat(date, "timestamp"),
      status
    };

    try {
      const {
        data: {data, message}
      } = await invoiceChangeStatusQuery.call(invoiceApi.changeInvoiceStatus(invoiceId, payload));
      const response = await jobDetailsQuery.call(jobsApi.getJobDetails(jobData.id));
      setUpdatingStatus(false);
      eventBus.dispatch("showToast", {text: message});
      onSelectedPipelineCallback?.(response.data.data);
      return data;
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      setUpdatingStatus(false);
      eventBus.dispatch("showToast", {text: msg, type: "error"});
    }
  }

  return {
    selectionLoading,
    onChangePipeLine,
    pipelines: pipelines as iPipelinesGroup[],
    isLoading: loading,
    activePipeline,
    setActivePipeline,
    onSavePipelineDate,
    isLoadingCalendar:
      invoiceChangeStatusQuery.isLoading || updateJobPipelineQuery.isLoading || updatingStatus
  };
}
