import {FC, FormEvent, useEffect} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {TemplateModal} from "components/shared/TemplateModal/TemplateModal";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {notValidForm} from "helpers/misc";
import {useHttp} from "hooks/httpServices/useHttp";
import {estimateApi} from "api/budget/estimate";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {eventBus} from "EventBus/EventBus";
import {useNavigate, useParams} from "react-router-dom";
import {useContextJobBudget} from "../context/JobBudgetContext";
import {CustomReactSelect} from "components/shared/FormComponents/ReactSelect/CustomReactSelect";
import {useCustomReactSelect} from "components/shared/FormComponents/ReactSelect/useCustomReactSelect";

type tBudgetTemplateFormProps = {
  closeModal: () => void;
};

export const BudgetTemplateForm: FC<tBudgetTemplateFormProps> = ({closeModal}) => {
  const {id} = useParams();
  const navigate = useNavigate();

  const {getEstimateList} = useContextJobBudget();
  const {call, isLoading} = useHttp();
  const formId = "budget-load-template-form";

  const {data} = useHttp({onMountCall: true, config: estimateApi?.getEstimateTemplateList()});

  const formData = {
    name: useTextInput(),
    template: useCustomReactSelect({
      required: true,
      options: null
    })
  };

  useEffect(() => {
    data && formData?.template?.setOptions(data?.data?.data);
  }, [data]); // eslint-disable-line

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (notValidForm(formData) || !id) return;

    try {
      const response = await call(
        estimateApi.createEstimateFromTemplate(+formData?.template?.value?.id, {
          job_id: id,
          name: formData?.name?.value
        })
      );
      closeModal();
      navigate(`/jobs/${id}/budget/estimate/${response?.data?.data.id}`);
      await getEstimateList();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error, formData);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <TemplateModal
      formId={formId}
      title="Load template"
      secondaryButton={{text: "Cancel"}}
      primaryButton={{text: "Load template"}}
      onCancel={closeModal}
      isLoading={isLoading}
    >
      <form id={formId} className="BudgetEmptyState-form" onSubmit={handleSubmit}>
        <p className="label">Name your estimate</p>
        <InputField {...formData.name.inputProps} errors={formData.name.errors} />

        <p className="label">Choose a template</p>
        <CustomReactSelect
          getOptionLabel={item => item?.name}
          getOptionValue={item => String(item?.id)}
          {...formData?.template}
          label="ESTIMATE*"
          menuPlacement="auto"
          minMenuHeight={300}
        />
      </form>
    </TemplateModal>
  );
};
