import React, {FC} from "react";
import Person from "assets/images/person.png";
import DefaultImg from "assets/images/imagePlaceholder.png";

export interface iImgWithFallbackProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  src: string;
  fallBackType?: "default" | "avatar";
  fallBackSrc?: string;
  alt?: string;
}

export const ImgWithFallback: FC<iImgWithFallbackProps> = ({
  fallBackType = "default",
  fallBackSrc = "",
  src,
  alt = "alt",
  ...props
}) => {
  const fallbackImg = fallBackType === "default" ? DefaultImg : Person;
  return (
    <img
      {...props}
      src={src}
      alt={alt}
      onError={e => (e.currentTarget.src = fallBackSrc ? fallBackSrc : fallbackImg)}
    />
  );
};

/* How to use

 <ImgWithFallback
    className="PhotoField-image"
    src={photo}
    alt="avatar"
    fallBackType="avatar" or fallBackSrc={DefaultImg}
  />

*/
