import {FC, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {tEstimateRowData} from "api/budget/estimate/types";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {estimateApi} from "api/budget/estimate";
import {useContextJobBudget} from "pages/Jobs/JobDetails/JobBudget/context/JobBudgetContext";
import {getUsMoneyFormat} from "helpers/formattingData";
import "./EstimateRowTotal.scss";

type tEstimateRowTotalProps = {
  currItem: tEstimateRowData;
};

export const EstimateRowTotal: FC<tEstimateRowTotalProps> = ({currItem}) => {
  const {setEstimate} = useContextJobBudget();
  const [isVisible, setVisibility] = useState(true);
  const {call, isLoading} = useHttp();

  const visibilityToggler = async () => {
    setVisibility(prev => !prev);

    try {
      const res = await call(
        estimateApi.updateLine(currItem.id, {show_total: !currItem?.show_total})
      );
      setEstimate(() => res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const iconName = isVisible ? "eye" : "hide";
  return (
    <div className={`EstimateRowTotal ${!isVisible ? "hidden" : ""}`}>
      <Button onClick={visibilityToggler} disabled={isLoading}>
        <Icon icon={iconName} size="xsm" />
      </Button>

      <TextComponent
        size="14"
        weight="400"
        color={!!currItem.budget && currItem.show_total ? "black-10" : "gray-20"}
        className="EstimateRowBudget"
      >
        {!!currItem.total ? getUsMoneyFormat(currItem.total) : "0.00"}
      </TextComponent>
    </div>
  );
};
