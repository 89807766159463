import {FC} from "react";
import {iJobDetailsDataReturn} from "api/jobs/types";
import {tCallSheetInfo} from "api/callSheet/types";
import {CallSheetTemplate} from "../_templates/CallSheetTemplate/CallSheetTemplate";
import {CallSheetTemplateHeader} from "../_templates/CallSheetTemplate/CallSheetTemplateHeader";
import {getName} from "helpers/misc";
import {getDateFormat} from "helpers/dates";
import {CallSheetTemplateLocation} from "../_templates/CallSheetTemplate/CallSheetTemplateLocation";
import {CallSheetTemplateSchedule} from "../_templates/CallSheetTemplate/CallSheetTemplateSchedule";
import {CallSheetTemplateContacts} from "../_templates/CallSheetTemplate/CallSheetTemplateContacts";

import "./PDFTemplate.scss";

type tPDFTemplate = {
  id: string;
  jobDetailsData: iJobDetailsDataReturn | null;
  callSheetInfo: tCallSheetInfo | null;
  forDownloading?: boolean;
};

export const PDFTemplate: FC<tPDFTemplate> = ({
  id,
  callSheetInfo,
  jobDetailsData,
  forDownloading
}) => {
  const contactsList = callSheetInfo?.attributes?.groups?.filter(item => !!item.contacts.length) || []; // prettier-ignore

  const className = forDownloading ? "for-downloading" : "";

  return (
    <CallSheetTemplate id={id} className={`CallSheetPdfTemplate-template ${className}`}>
      <CallSheetTemplateHeader
        artist={getName(jobDetailsData?.artists?.[0]?.artist)}
        jobName={jobDetailsData?.name || ""}
        clientName={getName(jobDetailsData?.client)}
        date={getDateFormat(callSheetInfo?.date || "", "ddd, MMM DD, YYYY")}
        callSheetName={callSheetInfo?.name || ""}
      />
      <CallSheetTemplateLocation
        image={callSheetInfo?.image || ""}
        locations={callSheetInfo?.attributes?.locations || []}
      />
      <CallSheetTemplateSchedule scheduleList={callSheetInfo?.attributes?.schedule || []} />
      <CallSheetTemplateContacts contactsGroup={contactsList} />
    </CallSheetTemplate>
  );
};
