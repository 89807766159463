import {FC, ReactNode} from "react";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import "./ListLayout.scss";

type tListLayoutProps = {
  headerTitle: string;
  children: ReactNode;
  buttonNode?: ReactNode;
};

export const ListLayout: FC<tListLayoutProps> = ({headerTitle, children, buttonNode}) => {
  return (
    <div className="ListLayout">
      <TextComponent
        as="h3"
        size="20"
        uppercase
        textAlign="center"
        className="ListLayout-header"
        truncate
      >
        {headerTitle}
      </TextComponent>

      <div className="ListLayout-body">
        <ul className="ListLayout-list">{children}</ul>
      </div>

      <div className="ListLayout-footer">{buttonNode}</div>
    </div>
  );
};
