import {useEffect, useState} from "react";
import {callSheet} from "api/callSheet";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextJobCallSheet} from "../../context/JobCallSheetContext";
import {eventBus} from "EventBus/EventBus";

export const useUploadImage = () => {
  const {callSheetInfo, setCallSheetInfo} = useContextJobCallSheet();
  const [image, setImage] = useState("");

  const {call, isLoading} = useHttp();

  useEffect(() => {
    if (!image || image.includes("http")) return;
    onUploadImage();
  }, [image]); // eslint-disable-line

  useEffect(() => {
    setImage(callSheetInfo?.image || "");
  }, [callSheetInfo]);

  const onUploadImage = async () => {
    if (!callSheetInfo?.id || !image) return;

    const {
      data: {data, message}
    } = await call(callSheet.uploadImage(callSheetInfo.id, {image}));

    setCallSheetInfo(prev => ({...prev, image: data.image}));

    eventBus.dispatch("showToast", {text: message || "Call sheet successfully loaded!"});
    try {
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return {image, setImage, isLoading};
};
