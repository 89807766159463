import {FC, FormEvent, useState} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {Datepicker} from "components/shared/FormComponents/Datepicker/Datepicker";
import {Select} from "components/shared/FormComponents/Select/Select";
import {useSelect} from "hooks/inputHooks/useSelect";
import {notValidForm} from "helpers/misc";
import {generateHours, getDateFormat} from "helpers/dates";
import {TeamMemberFooter} from "components/shared/Modals/TeamModals/TeamDetailInfo/shared/TeamMemberFooter";
import {iTeammateDetailsData} from "api/team/types";
import {Button} from "components/shared/Button/Button";
import {useHttp} from "hooks/httpServices/useHttp";
import {team} from "api/team";
import {getApiError} from "helpers/getApiError";
import {useContextTeam} from "../TeamContext";
import {useDatePicker} from "components/shared/FormComponents/Datepicker/useDatePicker";
import {eventBus} from "EventBus/EventBus";
import "./Deactivate.scss";

interface iDeactivateProps {
  teammateDetail: iTeammateDetailsData;
  onCloseModal: () => void;
}

export const Deactivate: FC<iDeactivateProps> = ({teammateDetail, onCloseModal}) => {
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [resultModal, setResultModal] = useState(false);
  const {getTeamList, isLoading: teamLoading} = useContextTeam(); // prettier-ignore
  const {call, isLoading} = useHttp();

  const formData = {
    date: useDatePicker(),
    time: useSelect({options: generateHours(24, "H")})
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (notValidForm(formData)) return;

    try {
      const {date, time} = formData;
      const data = {
        id: teammateDetail.id,
        date: date?.value ? getDateFormat(date?.value, "timestamp") : undefined,
        time: time?.value || undefined
      };

      await call(team.deactivateTeammate(data));
      await getTeamList();
      setDeactivateModal(false);
      setResultModal(true);
    } catch (error) {
      const {msg} = getApiError(error, formData);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error !"});
    }
  };

  return (
    <>
      <TeamMemberFooter teammateDetail={teammateDetail} className="Deactivate-footer">
        <Button onClick={() => setDeactivateModal(true)}> Deactivate account </Button>
      </TeamMemberFooter>

      <ConfirmWindow
        show={deactivateModal}
        title="Deactivate account?"
        confirmBtn={{text: "Deactivate"}}
        cancelBtn={{text: "Cancel"}}
        onClose={() => setDeactivateModal(false)}
        onConfirm={onSubmit}
        isLoading={isLoading || teamLoading}
      >
        <p className="Deactivate-info">
          Enter a date and time below to schedule {teammateDetail?.display_name} account
          deactivation or leave empty to deactivate immediately.
        </p>

        <form className="Deactivate-form" onSubmit={onSubmit}>
          <Datepicker
            {...formData.date.inputProps}
            placeholderText="mm/dd/yyyy"
            errors={formData.date.errors}
          />
          <Select
            {...formData.time.inputProps}
            errors={formData.time.errors}
            canSelectEmpty={true}
          />
        </form>
      </ConfirmWindow>

      <ConfirmWindow
        show={resultModal}
        title="Deactivated"
        confirmBtn={{text: "close"}}
        onConfirm={() => onCloseModal()}
      >
        <div className="TeamMemberConfirmMessage">
          {teammateDetail.display_name} account has been deactivated.
        </div>
      </ConfirmWindow>
    </>
  );
};
