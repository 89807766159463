import {FC, useState} from "react";
import {tJobTask} from "api/jobs/tasks/types";
import {Button} from "components/shared/Button/Button";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {ImgWithFallback} from "components/shared/ImgWithFallback/ImgWithFallback";
import {AssigneeDropdownItem} from "./AssigneeDropdownItem";
import {useContextJobTasks} from "pages/Jobs/JobDetails/JobTasks/_context/JobTaskContext";
import {Loading} from "components/shared/Loading/Loading";
import {LetterAvatar} from "components/shared/LetterAvatar/LetterAvatar";
import "./AssigneeCell.scss";

type tAssigneeCellProps = {
  data?: tJobTask;
  isRowInit?: boolean;
};

export const AssigneeCell: FC<tAssigneeCellProps> = ({data, isRowInit = false}) => {
  const {team, onSubmit, submitting, cellKeyRef, generateKey} = useContextJobTasks();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!team?.length) return;
    setMenuAnchor(event.currentTarget);
  };

  const initData = !!data?.assignee?.length ? data?.assignee?.map(item => item?.id) : [];
  const [selectedItems, setSelectedItems] = useState<Array<number>>(initData);
  const [wasChanges, setWasChanges] = useState(false);

  const typeApi = "assignee";
  const currCellKey = generateKey(typeApi, data?.id);
  const isLoading = cellKeyRef?.current === currCellKey && submitting;

  const setAssignee = () => {
    if (!data?.id || !wasChanges) return;
    setWasChanges(false);

    onSubmit({taskId: data?.id, typeApi, data: {assignee: selectedItems}});
  };

  const onCloseDropdown = () => {
    setAssignee();
    setMenuAnchor(null);
  };

  const selectIds = (id: number) => {
    setWasChanges(true);

    setSelectedItems(prev => {
      if (!prev?.length) return [id];
      const isID = prev?.find(item => item === id);
      if (isID) return prev?.filter(item => item !== id);
      if (!isID) return [...prev, id];
      return prev;
    });
  };

  const emptyImg = !!data?.file?.file_url ? "" : "emptyImg";
  const emptyTeam = !!team?.length ? "" : "empty";
  return (
    <div className={`AssigneeCell posRelative ${emptyImg}`}>
      {isLoading && <Loading type="inBlock" customSize={0.5} />}
      {isRowInit && <ImgWithFallback src="" fallBackType="avatar" />}

      {!isRowInit && (
        <Button
          width="fullWidth"
          onClick={showMenu}
          className={`AssigneeCell-showMenuBtn ${emptyTeam}`}
        >
          {!data?.assignee?.length && (
            <ImgWithFallback src="" fallBackType="avatar" className="image" />
          )}

          {!!data?.assignee?.length &&
            data?.assignee?.map(item => {
              if (!item?.avatar_url) {
                return <LetterAvatar key={item?.id} display_name={item?.display_name} />;
              }
              return (
                <ImgWithFallback
                  key={item?.id}
                  src={item?.avatar_url || ""}
                  fallBackType="avatar"
                />
              );
            })}
        </Button>
      )}

      <DropdownMenu
        anchor={menuAnchor}
        onClose={onCloseDropdown}
        className="AssigneeCellDropdown"
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom"
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "bottom"
        }}
      >
        {team?.map(item => {
          return (
            <AssigneeDropdownItem
              key={item?.id}
              name={item?.display_name || ""}
              callBack={() => item?.id && selectIds(item?.id)}
              checked={!!selectedItems?.find(ent => ent === item?.id)}
              imageUrl={item?.avatar_url || ""}
            />
          );
        })}
      </DropdownMenu>
    </div>
  );
};
