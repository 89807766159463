import {useEffect, useState} from "react";
import {useDebounce} from "hooks/useDebounce";
import {useHttp} from "hooks/httpServices/useHttp";
import {AxiosRequestConfig} from "axios";
import {eventBus} from "EventBus/EventBus";

export type tUseSearchFieldReturn = ReturnType<typeof useSearchField>;

interface iUseSearchField {
  searchConfig: (params?: any) => AxiosRequestConfig;
  required?: boolean;
  searchKey: "query" | "search";
  value?: any;
}
export function useSearchField(props: iUseSearchField) {
  const {call, isLoading} = useHttp();
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState<any>();
  const [required] = useState(props?.required !== undefined ? props?.required : false);
  const [errors, setErrors] = useState<string[]>([]);

  const debounceValue = useDebounce(search, 400);

  useEffect(() => {
    if (props?.value) setValue(props?.value);
  }, [props?.value]);

  useEffect(() => {
    if (debounceValue) {
      onSearch(debounceValue);
    }
  }, [debounceValue]); // eslint-disable-line

  const onSearch = async (query: string) => {
    // query
    try {
      const response = await call(props.searchConfig({[props.searchKey]: query}));
      setOptions(response?.data?.data);
    } catch (error: any) {
      eventBus.dispatch("showToast", {type: "error", text: error?.data?.message});
    }
  };

  const onInputChange = (value: string) => setSearch(value);

  const onChange = (value: any) => {
    setValue(value);
    checkValidity(value);
  };

  const checkValidity = (val?: any) => {
    const newVal = val !== undefined ? val : value;
    const isInvalid = required && !newVal;
    isInvalid ? setErrors(["This field can't be empty"]) : setErrors([]);
    return !isInvalid;
  };

  return {
    isLoading,
    options,
    value,
    onChange,
    errors,
    setErrors,
    setValue,
    onInputChange,
    checkValidity
  };
}
