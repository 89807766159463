import {FC, useMemo} from "react";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {useEstimateSelection} from "./useEstimateSelection";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {BudgetEstimateForm} from "../../forms/BudgetEstimateForm/BudgetEstimateForm";
import {BudgetTemplateForm} from "../../forms/BudgetTemplateForm";
import {useContextJobBudget} from "../../context/JobBudgetContext";
import {DropdownSubmenu} from "components/shared/DropdownMenu/DropdownSubmenu";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {sortByKey} from "helpers/misc";

import "./EstimateSelection.scss";

export const EstimateSelection: FC = () => {
  const {estimateList, estimateLoading, estimate,  } = useContextJobBudget(); // prettier-ignore
  const {anchor, toggleMenu, modal, showModal, closeModal} = useEstimateSelection();
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const {id: jobId} = useParams();

  const menus = useMemo(() => {
    return sortByKey(estimateList || [], "name");
  }, [estimateList]);

  if (!estimate?.id) return null;

  const currEstimate = estimateList?.find(item => String(item.id) === String(estimate?.id));

  const changeEstimate = (id: number) => {
    toggleMenu(null);
    const url = pathname.includes("export") ? "/export" : "";
    navigate(`/jobs/${jobId}/budget/estimate/${id}${url}`);
  };

  return (
    <div className="EstimateSelection">
      <Button color="outline" size="sm" onClick={toggleMenu} loading={estimateLoading}>
        <span>{currEstimate?.name}</span>
        <Icon size="xsm" icon={!!anchor ? "triangle-up" : "triangle-down"} />
      </Button>
      <DropdownMenu
        className="EstimateSelection-dropdown"
        anchor={anchor}
        onClose={() => toggleMenu(null)}
        withSubmenu
        btnHover
      >
        {menus?.map(item => {
          return (
            <Button
              key={item?.id}
              size="sm"
              width="fullWidth"
              onClick={() => changeEstimate(item?.id)}
            >
              {item?.name}
            </Button>
          );
        })}

        <hr />
        <DropdownSubmenu title="Add Estimate" position="left">
          <Button size="sm" width="fullWidth" onClick={() => showModal("estimate")}>
            Blank estimate
          </Button>
          <Button size="sm" width="fullWidth" onClick={() => showModal("template")}>
            Load template
          </Button>
        </DropdownSubmenu>
      </DropdownMenu>

      <ModalPopup show={!!modal} size="w-528" withoutPaddings onClose={closeModal}>
        {modal === "estimate" && <BudgetEstimateForm closeModal={closeModal} />}
        {modal === "template" && <BudgetTemplateForm closeModal={closeModal} />}
      </ModalPopup>
    </div>
  );
};
