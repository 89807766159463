import {useSetApiParams} from "hooks/useSetApiParams";
import {MouseEvent} from "react";

// TODO need to improve
export const useCompanyTableRowFilters = (apiParams: ReturnType<typeof useSetApiParams>) => {
  const handleFilterLocales = (
    event?: MouseEvent<HTMLSpanElement, any>,
    tag?: {id: number; name: string}
  ) => {
    event?.stopPropagation();
    const params = {locales: [String(tag?.id)]};
    apiParams.setApiParams({...Object.fromEntries(apiParams?.searchParams), params});
  };

  const handleFilterLabels = (
    event?: MouseEvent<HTMLSpanElement, any>,
    tag?: {id: number; name: string}
  ) => {
    event?.stopPropagation();
    const params = {labels: [String(tag?.id)]};
    apiParams.setApiParams({...Object.fromEntries(apiParams?.searchParams), params});
  };

  // FIXME need to fix or delete
  const handleCompanyLocale = (
    event?: MouseEvent<HTMLSpanElement, any>,
    tag?: {id: number; name: string}
  ) => {
    event?.stopPropagation();
  };

  return {handleFilterLocales, handleFilterLabels, handleCompanyLocale};
};
