import {FC, MouseEvent, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {ListLineLayout} from "./ListLineLayout";
import {tJobSettingsTemplatesModal} from "../JobSettingsTemplates/JobSettingsTemplates";
import {tUseJobSettingsTemplatesModalData} from "../JobSettingsTemplates/useJobSettingsTemplates";

type tListLineProps = {
  onShowModal: (data: tUseJobSettingsTemplatesModalData) => void;
  data: tData;
  onRemove: (id: number) => void;
  isLoading: boolean;
  templateType: tJobSettingsTemplatesModal;
  showRenameWindow: (payload: {id: number; name: string}) => void;
};

type tData = {id: number; name: string; [key: string]: any};

export const ListLine: FC<tListLineProps> = ({
  templateType,
  onShowModal,
  data,
  onRemove,
  isLoading,
  showRenameWindow
}) => {
  const [modal, setModal] = useState<"delete" | null>(null);
  const closeModal = () => setModal(null);

  const onClickLine = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onShowModal({...data, templateType: templateType});
  };

  const onRename = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    showRenameWindow({id: data.id, name: data.name});
  };

  const onDelete = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setModal("delete");
  };

  return (
    <>
      <ListLineLayout onClick={onClickLine}>
        <TextComponent weight="500" size="14" uppercase>
          {data.name}
        </TextComponent>

        <Button onClick={onRename}>
          <Icon icon="edit" size="md" color="gray" />
        </Button>
        <Button onClick={onDelete}>
          <Icon icon="delete" size="md" color="gray" />
        </Button>
      </ListLineLayout>

      <ConfirmWindow
        title="DELETE TEMPLATE?"
        subTitle="This template will be permanentely deleted."
        warning="This can’t be unndone."
        show={modal === "delete"}
        onClose={closeModal}
        onConfirm={() => onRemove(data.id)}
        confirmBtn={{text: "Delete", color: "red"}}
        cancelBtn={{text: "cancel"}}
        isLoading={isLoading}
      />
    </>
  );
};
