import {FC, useState} from "react";
import {iAddress} from "api/common/types";
import {ImgWithFallback} from "components/shared/ImgWithFallback/ImgWithFallback";
import {Title} from "./Title";
import {LocationsModals} from "pages/Locations/LocationsModals/LocationsModals";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import "./LocationCard.scss";

interface iLocationCardProps {
  imgUrl: string;
  title: string;
  address: iAddress;
  id: number;
  locationType: string;
}

export const LocationCard: FC<iLocationCardProps> = ({
  imgUrl,
  title,
  address,
  id,
  locationType
}) => {
  const {updateJobDetails} = useContextJobDetails();
  const [showModal, setShowModal] = useState(false);

  const locType = !!locationType ? locationType : "";
  const city = !!address?.city ? `- ${address?.city}` : "";
  const transformState = address?.state
    ?.split(" ")
    ?.map(item => item?.[0]?.charAt(0)?.toUpperCase())
    ?.join("");
  const state = !!transformState ? `, ${transformState}` : "";

  const addr = `${locType} ${city}${state}`;

  return (
    <div className="LocationCard" onClick={() => setShowModal(true)}>
      <div className="imageWrap">
        <ImgWithFallback src={imgUrl} className={imgUrl ? "image" : "image empty"} />
      </div>

      <Title text={title} />
      <Title text={addr} size="sm" />

      {showModal && (
        <LocationsModals
          location={id}
          closeModal={() => setShowModal(false)}
          onEditCallback={updateJobDetails}
        />
      )}
    </div>
  );
};
