import {FC, MouseEvent} from "react";
import {Icon} from "components/Icon/Icon";
import {tCategoryCost} from "api/budget/estimate/types";
import "./BudgetTypesEditLine.scss";

interface iBudgetTypesEditLineProps {
  name: string;
  code: string;
  id: number;
  onDelete: (id: number) => void;
  onEdit: (data: tCategoryCost) => void;
}

export const BudgetTypesEditLine: FC<iBudgetTypesEditLineProps> = ({
  id,
  name,
  code,
  onDelete,
  onEdit
}) => {
  const handleDelete = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onDelete(id);
  };

  return (
    <div className="BudgetTypesEditLine" onClick={() => onEdit({name, code, id})}>
      {code && <div className="BudgetTypesEditLine-code">{code}</div>}
      <div className="BudgetTypesEditLine-name">{name}</div>
      <div className="BudgetTypesEditLine-actions" onClick={() => onEdit({name, code, id})}>
        <button className="BudgetTypesEditLine-button">
          <Icon icon="edit" color="gray" />
        </button>
        <button className="BudgetTypesEditLine-button" onClick={handleDelete}>
          <Icon icon="delete" color="gray" />
        </button>
      </div>
    </div>
  );
};
