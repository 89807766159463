import {CSSProperties, FC, ReactNode} from "react";
import "./DragAndDropWrap.scss";

export type tDraggingItem = {[key: string]: any; id: number | string};

export type tDragAndDropWrapProps = {
  children: ReactNode;
  className?: string;
  draggingItem: tDraggingItem;
  dropCallBack: (draggingItem: tDraggingItem) => void;
  setCurrItem: React.Dispatch<React.SetStateAction<tDraggingItem | null>>;
  isLoading?: boolean;
  blockDragging?: boolean;
  as?: "div" | "tr";
  style?: CSSProperties;
};

export const DragAndDropWrap: FC<tDragAndDropWrapProps> = ({
  children,
  draggingItem,
  className = "",
  dropCallBack,
  setCurrItem,
  isLoading,
  blockDragging,
  as = "div",
  style
}) => {
  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, selectedItem: tDraggingItem) => {
    if (isLoading) return;
    setCurrItem(selectedItem);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, selectedItem: tDraggingItem) => {
    e.preventDefault();
    (e.currentTarget as HTMLDivElement).classList.remove("grabbing");

    dropCallBack(selectedItem);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    (e.currentTarget as HTMLDivElement).classList.add("grabbing");
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    (e.currentTarget as HTMLDivElement).classList.remove("grabbing");
  };

  const Component = as;
  const isTable = as === "tr" ? "table" : "";
  return (
    <Component
      className={`DragAndDropWrap ${isLoading ? "loading" : ""} ${className} ${isTable}`}
      draggable={blockDragging !== undefined ? blockDragging : true}
      onDragOver={handleDragOver}
      onDrop={e => handleDrop(e, draggingItem)}
      onDragEnd={handleDragEnd}
      onDragLeave={handleDragEnd}
      onDragStart={e => handleDragStart(e, draggingItem)}
      style={style}
    >
      {children}
    </Component>
  );
};
