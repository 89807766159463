import {FC, forwardRef, useEffect, useImperativeHandle} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {tDynamicInputsGroupProps} from "./types";
import {tTypeDynamicFields} from "../types";
import {useDynamicInputsGroupForm} from "./useDynamicInputsGroupForm";
import {SelectInput} from "../../SelectInput/SelectInput";

export const DynamicInputsGroup: FC<tDynamicInputsGroupProps> = forwardRef(
  ({onChangeHandler, showDeleteBtn, handleRemove, isFirstItem, options, itemField, type}, ref) => {
    const {select, input} = useDynamicInputsGroupForm({
      options,
      isFirstItem,
      itemField,
      type,
      onChangeHandler
    });

    useEffect(() => {
      options?.length && select.setOptions(options);
    }, [options]); // eslint-disable-line

    useImperativeHandle(ref, () => ({checkValidity}));

    function checkValidity() {
      const isValidName = select.checkValidity();

      const isValidLink = input.checkValidity();
      return isValidName && isValidLink;
    }

    const labels: Record<tTypeDynamicFields, {input: string; select: string}> = {
      emails: {input: "Email", select: ""}
    };

    return (
      <div className={`DynamicInputsGroup email ${type}`}>
        {isFirstItem && (
          <label className="subtitle" htmlFor="email-field">
            {labels[type].input}
          </label>
        )}

        <div className="row">
          <InputField {...input.inputProps} id="email-field" errors={input.errors} />
          <SelectInput
            {...select.inputProps}
            isCustomInput={select.isCustomInput}
            customInput={select.customInput}
            clearAll={select.clearAll}
            errors={select.errors}
            canSelectEmpty={true}
          />

          {showDeleteBtn && (
            <Button
              type="button"
              color="outline"
              size="sm"
              onClick={() => handleRemove(itemField.uid)}
            >
              <Icon icon="close" size="sm" color="gray" />
            </Button>
          )}
        </div>
      </div>
    );
  }
);
