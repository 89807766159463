import {FC} from "react";
import {Button} from "components/shared/Button/Button";
import {useContextJobTasks} from "../../_context/JobTaskContext";
import {FilterLayout} from "components/FilterLayout/FilterLayout";
import {ReactSelect} from "components/shared/FormComponents/ReactSelect/ReactSelect";

type tOption = {id: string; name: string};

export const TaskFilterForm: FC = () => {
  const {apiParams, clearAllParams, statuses, team} = useContextJobTasks();

  const paramsAssignee = apiParams?.preparedParams?.["filter[assignee]"]?.split(",") || [];
  const paramsStatus = apiParams?.preparedParams?.["filter[status_id]"]?.split(",") || [];
  const getSelectedTag = (options: tOption[], selected: string[]) => {
    if (!selected?.length || !options?.length) return [];
    return options.filter((item: tOption) =>
      selected?.find(ent => Number(ent) === Number(item.id))
    );
  };

  const assigneeList = team?.map(item => ({id: item.id?.toString() || "", name: item?.display_name || ""})); // prettier-ignore
  const statusList = statuses?.map(item => ({id: item.id?.toString() || "", name: item?.name || ""})); // prettier-ignore

  const filters = {
    "filter[status_id]": {
      value: getSelectedTag(statusList, paramsStatus),
      onChange: (option: tOption) => {
        const params = {"filter[status_id]": option?.id};
        apiParams.setApiParams({...Object.fromEntries(apiParams.searchParams), params});
      }
    },
    "filter[assignee]": {
      value: getSelectedTag(assigneeList, paramsAssignee),
      onChange: (option: tOption[]) => {
        const opt = option?.length ? option.map((item: tOption) => item.id) : [];
        const params = {"filter[assignee]": opt};
        apiParams.setApiParams({...Object.fromEntries(apiParams.searchParams), params});
      }
    }
  };

  return (
    <FilterLayout>
      <div className="label">Status</div>
      <ReactSelect
        {...filters["filter[status_id]"]}
        placeholder="Select..."
        options={statusList}
        getOptionLabel={(opt: tOption) => opt?.name}
        getOptionValue={(opt: tOption) => opt?.id}
        menuPosition="fixed"
      />

      <div className="label">Assignee</div>
      <ReactSelect
        {...filters["filter[assignee]"]}
        placeholder="Select..."
        options={assigneeList}
        getOptionLabel={(opt: tOption) => opt?.name}
        getOptionValue={(opt: tOption) => opt?.id}
        isMulti
        menuPosition="fixed"
      />

      <Button size="sm" color="light" onClick={() => clearAllParams()}>
        Clear Filters
      </Button>
    </FilterLayout>
  );
};
