import {FC, useState, useEffect} from "react";
import {NavLink, useParams, useLocation, useNavigate} from "react-router-dom";
import {Icon} from "components/Icon/Icon";
import {AddJobSections} from "../../JobDetailsModals/AddJobSections/AddJobSections";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {routes} from "router/routes";
import {AppTooltips} from "components/AppTooltips/AppTooltips";
import "./JobDetailsNav.scss";

type tNavItem = {title: string; to: string};
const order = ["overview", "budget", "calendar", "crew", "grids", "call sheets", "tasks", "files"];

export const JobDetailsNav: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {id} = useParams();
  const {jobDetailsData} = useContextJobDetails();
  const [showAddSection, setShowAddSection] = useState(false);
  const [navigation, setNavigation] = useState<tNavItem[]>([]);

  const defaultNavItems = {
    overview: {title: "overview", to: `/jobs/${id}/overview`},
    files: {title: "files", to: `/jobs/${id}/files`}
  };

  useEffect(() => {
    if (!navigation?.length) return;
    const hasAccess = navigation.find(section => location.pathname.includes(section.title.split(" ").join("-").toLowerCase())); // prettier-ignore
    if (!hasAccess) navigate(routes.jobs.details.overview);
  }, [navigation, location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!jobDetailsData) return;
    const {sections} = jobDetailsData;
    let newNav: tNavItem[] = [defaultNavItems.overview];
    let ordered: tNavItem[] = [];

    sections.forEach((item, index, array) => {
      const path = `/jobs/${id}/${item.name.split(" ").join("-").toLowerCase()}`;
      newNav = [...newNav, {title: item.name, to: path}];
      if (index === array.length - 1) newNav = [...newNav, defaultNavItems.files];
    });

    order.forEach((item, index) => {
      const el = newNav.find(i => i.title.toLowerCase() === item);
      if (!el) return ordered;
      ordered[index] = el;
    });

    setNavigation(ordered.filter(i => i));
  }, [jobDetailsData?.sections]); // eslint-disable-line

  const hasAvailableSections = !!jobDetailsData?.available_sections?.length;

  return (
    <>
      <nav className="JobDetailsNav">
        <AppTooltips page="overview" tooltip="Job Sections" />

        {navigation.map(({title, to}) => {
          return (
            <NavLink
              key={title}
              className={({isActive}) => `JobDetailsNav-item ${isActive ? "active" : ""}`}
              to={to}
            >
              {title}
            </NavLink>
          );
        })}
        {hasAvailableSections && (
          <button className="JobDetailsNav-add" onClick={() => setShowAddSection(true)}>
            <Icon icon="add" size="sm" />
          </button>
        )}
      </nav>
      {showAddSection && (
        <AddJobSections show={showAddSection} onClose={() => setShowAddSection(false)} />
      )}
    </>
  );
};
