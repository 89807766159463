import {FormEvent, useEffect, useState} from "react";
import {trackApi} from "api/budget/track";
import {tCategorizedPostData, tTrackCostLineFile} from "api/budget/track/types";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {notValidForm} from "helpers/misc";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextTrack} from "../../_context/TrackContext";
import {useCategorizedForm} from "./useCategorizedForm";
import {eventBus} from "EventBus/EventBus";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {createFileFromUrlData} from "helpers/files/createFileFromUrlData";

type tProps = {
  currFile: tTrackCostLineFile | undefined;
  onClose?: () => void;
  form: ReturnType<typeof useCategorizedForm>;
  files: tTrackCostLineFile[];
  setSelectedFile: React.Dispatch<React.SetStateAction<tTrackCostLineFile>>;
};

export type tSubmitCategorizedForm = ReturnType<typeof useSubmitCategorizedForm>;

export const useSubmitCategorizedForm = ({form, currFile, files, setSelectedFile}: tProps) => {
  const {jobDetailsData} = useContextJobDetails();
  const {setTrackData, trackData} = useContextTrack();
  const {call, isLoading} = useHttp();
  const [fileLoading, setFileLoading] = useState(false);

  useEffect(() => {
    setSelectedFile(files?.[0]);
  }, [trackData]); // eslint-disable-line

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (notValidForm(form) || !jobDetailsData?.id) return;
    setFileLoading(true);

    const data: tCategorizedPostData = {
      estimate_id: form?.estimate?.value?.id ? form?.estimate?.value?.id : 0,
      line_number: form?.lineNumber?.value ? form?.lineNumber?.value?.id : 0,
      currency_code: form?.currency?.value?.code || "",
      pay_id: form?.payID ? form?.payID?.value?.id : 0,
      pay_target_id: form?.payID ? form?.payID?.value?.value : undefined,
      total_amount: form?.totalAmount?.value ? +form?.totalAmount?.value : 0,
      vendor_name: form?.vendor?.value ? form?.vendor?.value : "",
      ...(form?.expenseDescription?.value && {
        description: form?.expenseDescription?.value || ""
      }),
      ...(form?.payStatus?.value?.id && {
        pay_status_id: form?.payStatus?.value?.id.toString() || ""
      }),
      ...(form?.qbStatus?.value?.id && {
        qb_status_id: form?.qbStatus?.value?.id.toString() || ""
      })
    };

    const fd: any = new FormData();
    for (const key in data) {
      const currKey = key as keyof typeof data;
      fd.append(currKey, data[currKey]);
    }

    try {
      if (!!currFile) {
        const file = await createFileFromUrlData(currFile.file_url, currFile?.file_name || "");
        fd.append("expense_file_content", file);
        fd.append("expense_file_id", currFile?.id);
      }

      const res = await call(trackApi?.setCategorizedExpenseWithFile(jobDetailsData?.id, fd));
      res?.data?.data && setTrackData(res?.data?.data);

      Object.values(form).forEach(input => input.onReset());
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    } finally {
      setFileLoading(false);
    }
  };

  return {onSubmit, isLoading: isLoading || fileLoading};
};
