import {FC, useState} from "react";
import TagsList from "components/shared/TagsList/TagsList";
import {iContactsPeople} from "../../interfaces";
import {TagButton} from "components/shared/TagButton/TagButton";
import {useSetApiParams} from "hooks/useSetApiParams";
import {PeopleModals} from "components/shared/Modals/PeopleModals/PeopleModals";
import {ImgWithFallback} from "components/shared/ImgWithFallback/ImgWithFallback";
import {FavoriteCell} from "components/shared/Cells/FavoriteCell/FavoriteCell";
import {usePeopleTableRowFilters} from "./usePeopleTableRowFilters";
import {CompanyModals} from "components/shared/Modals/CompanyModals/CompanyModals";
import {PhoneCell} from "components/shared/Cells/PhoneCell/PhoneCell";
import {favorites} from "api/favorites";
import {Tag} from "components/shared/Tag/Tag";

import "./PeopleTableRow.scss";

interface iPeopleTableRowProps {
  person: iContactsPeople;
  apiParams: ReturnType<typeof useSetApiParams>;
  getCompaniesCallback: () => void;
  getPeopleCallback: () => void;
  isFirstItem?: boolean;
}

export const PeopleTableRow: FC<iPeopleTableRowProps> = ({
  person,
  apiParams,
  getCompaniesCallback,
  getPeopleCallback,
  isFirstItem
}) => {
  const [showModal, setShowModal] = useState(false);
  const closePeopleModal = () => setShowModal(false);

  const {id, in_favorite, name, labels} = person;
  const {locales, emails, avatar_url, phones, companies} = person;

  const {handleFilterLabels, handleFilterLocale} = usePeopleTableRowFilters(apiParams);
  const {companiesModal, closeCompaniesModal, handleCompanyLocale} = usePeopleTableRowFilters(apiParams); // prettier-ignore

  return (
    <>
      {showModal && (
        <PeopleModals
          person={person?.id}
          closePeopleModal={closePeopleModal}
          onEditCallback={getPeopleCallback}
        />
      )}
      {companiesModal && (
        <CompanyModals
          company={companiesModal}
          closeCompaniesModal={closeCompaniesModal}
          onEditCallback={getCompaniesCallback}
        />
      )}

      <tr className="PeopleTableRow" onClick={() => setShowModal(true)}>
        <FavoriteCell
          icon="heart"
          in_favorite={in_favorite}
          addConfig={favorites.addPerson(id)}
          removeConfig={favorites.removePerson(id)}
          showTooltip={isFirstItem ? {page: "people", tooltip: "PREFERRED CONTACTS"} : undefined}
        />
        <td className="PeopleTableRow-cell">
          <div className="PeopleTableRow-cell-avatar-wrapper">
            <ImgWithFallback
              src={avatar_url || ""}
              alt="contact's avatar"
              className="PeopleTableRow-cell-avatar"
              fallBackType="avatar"
            />
            <div className="PeopleTableRow-cell-name">
              <span className="PeopleTableRow-cell-name-value">{name}</span>
            </div>
          </div>
        </td>

        <td className="PeopleTableRow-cell">
          <TagsList tags={labels} tooltipText="Filter" handleClick={handleFilterLabels} />
        </td>

        <td className="PeopleTableRow-cell">
          <TagsList tags={locales} tooltipText="Filter" handleClick={handleFilterLocale} />
        </td>

        <td className="PeopleTableRow-cell column">
          {!!emails?.length && (
            <a
              className="PeopleTableRow-cell-link"
              key={id}
              href={`mailto:${emails[0]?.value}`}
              onClick={event => event.stopPropagation()}
            >
              {emails[0]?.value}
            </a>
          )}
        </td>

        <td
          className="PeopleTableRow-cell PeopleTableRow-cell-phone"
          onClick={event => event.stopPropagation()}
        >
          {!!phones?.length && <PhoneCell phones={phones} />}
        </td>

        <td className="PeopleTableRow-cell PeopleTableRow-cell-companies">
          {companies?.map((item, index, array) => {
            if (index > 2) return null;
            if (index > 1) {
              return (
                <Tag key={item.id} onClick={event => event.stopPropagation()} size="sm">
                  +{array.length - 2}
                </Tag>
              );
            }

            return (
              <TagButton
                key={item.id}
                size="sm"
                onClick={event => handleCompanyLocale(event, item)}
                color="dark"
              >
                {item?.name}
              </TagButton>
            );
          })}
        </td>
      </tr>
    </>
  );
};
