import {createContext, FC, useContext} from "react";
import {useJobCallSheet} from "./useJobCallSheet";

type tJobCallSheetContext = ReturnType<typeof useJobCallSheet>;

const JobCallSheetContext = createContext<tJobCallSheetContext | null>(null);
JobCallSheetContext.displayName = "JobCallSheetContext";

export const JobCallSheetProvider: FC = ({children}) => {
  const value = useJobCallSheet();
  return <JobCallSheetContext.Provider value={value}>{children}</JobCallSheetContext.Provider>;
};

export const useContextJobCallSheet = () => {
  const context = useContext(JobCallSheetContext);

  if (context === null) {
    throw new Error("<<< JobCallSheetContext >>> must be used within a JobCallSheetProvider");
  }

  return context as tJobCallSheetContext;
};
