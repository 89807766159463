import {FC, useState} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {ListLayout} from "../common/ListLayout";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import {PayrollCompanyLine} from "./PayrollCompanyLine";
import {PayrollCompanyEditForm} from "./PayrollCompanyEditForm";
import {tCreditCard} from "api/budget/creditCardAndPayroll/types";
import {Loading} from "components/shared/Loading/Loading";

type tPayrollCompanyProps = {
  onClose: () => void;
  payrolls: tCreditCard[];
  isLoading: boolean;
  onEditCallback?: () => void;
};

export const PayrollCompany: FC<tPayrollCompanyProps> = ({
  onClose,
  isLoading,
  onEditCallback,
  payrolls
}) => {
  const [showForm, setForm] = useState(false);
  const [companyData, setCompanyData] = useState<null | tCreditCard>(null);

  const closeForm = () => {
    setForm(false);
    setCompanyData(null);
  };

  const setCurrCompany = (data: tCreditCard) => {
    setCompanyData(data);
    setForm(true);
  };

  return (
    <ModalPopup size="sm" show={true} onClose={onClose} withoutPaddings>
      {isLoading && <Loading type="inBlock" />}
      {showForm && (
        <PayrollCompanyEditForm
          onClose={onClose}
          goBack={closeForm}
          companyData={!!companyData ? companyData : undefined}
          onEditCallback={onEditCallback}
        />
      )}

      {!showForm && (
        <ListLayout
          headerTitle={"Edit payroll companies"}
          buttonNode={
            <Button color="outline" width="fullWidth" size="sm" onClick={() => setForm(true)}>
              <Icon icon="add" size="xsm" /> payroll company
            </Button>
          }
        >
          {payrolls?.map(item => {
            return (
              <PayrollCompanyLine
                key={item?.id}
                onEditCallback={() => setCurrCompany(item)}
                payrollItem={item}
                onUpdateList={onEditCallback}
              />
            );
          })}
        </ListLayout>
      )}
    </ModalPopup>
  );
};
