import {Routes, Route, Navigate} from "react-router-dom";
import {absRoutes} from "router/helpers";
import {routes} from "router/routes";
import {People} from "./People/People";
import {Companies} from "./Companies/Companies";
import {ContactsContextProvider} from "./context/ContactsContext";
import {ContactsDetailsContextProvider} from "./context/ContactsDetailsContext";
import {TeamContextProvider} from "pages/Team/TeamContext";

export const Contacts = () => {
  return (
    <ContactsContextProvider>
      <TeamContextProvider>
        <ContactsDetailsContextProvider>
          <ContactsComponent />
        </ContactsDetailsContextProvider>
      </TeamContextProvider>
    </ContactsContextProvider>
  );
};

export const ContactsComponent = () => {
  return (
    <Routes>
      <Route path={routes.contacts.people} element={<People />} />
      <Route path={routes.contacts.companies} element={<Companies />} />
      <Route index element={<Navigate to={absRoutes.contacts.people} />} />
    </Routes>
  );
};
