import {useLayoutEffect, useRef, useState} from "react";
import {tAppTooltipsProps} from "./AppTooltips";

type Props = {
  chevronPosition: tAppTooltipsProps["chevronPosition"];
  addToX?: number; //in px
  addToY?: number; //in px
  parentId?: string; // wrapper's id with scroll
};

export type tReturnPosition = ReturnType<typeof useCalculatePosition>;

//FIXME need to improve
export const useCalculatePosition = ({addToX, addToY, chevronPosition, parentId}: Props) => {
  const ref = useRef<any | null>(null);
  const [rect, setRect] = useState<DOMRect | undefined>();

  useLayoutEffect(() => {
    const setPosition = () => {
      const newData = ref?.current?.getBoundingClientRect();
      setRect(newData);
    };

    const parentScroll = document.getElementById(parentId ?? "");
    parentScroll?.addEventListener("scroll", setPosition);

    setTimeout(setPosition, 0);

    return () => {
      parentScroll?.removeEventListener("scroll", setPosition);
    };
  }, []); // eslint-disable-line

  const halfRootWidth = !!rect ? rect?.width / 2 : 0;
  const chevronWidth = 16;
  const chevronHeight = 8;
  const x = addToX || 0;
  const y = addToY || 0;

  const getTransformValue = () => {
    if (chevronPosition === "bottomRight") {
      return `translate(calc(-100% + ${halfRootWidth}px + ${chevronWidth}px + ${x}px), calc(-100% + -${chevronHeight}px + ${y}px))`;
    }
    if (chevronPosition === "topLeft") {
      // return `translate(calc(-100% + ${halfRootWidth}px + ${chevronWidth}px + ${x}px), calc(-100% + -${chevronHeight}px + ${y}px))`;
      return `translate(${x}px, ${y}px)`;
    }
  };

  const st = {
    position: "fixed",
    zIndex: 999999999,
    left: `${rect?.left}px`,
    top: `${rect?.top}px`,
    transform: getTransformValue()
  };
  const styles = !!rect ? st : {};

  return {styles, ref, chevronPosition};
};
