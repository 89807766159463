import {CallSheetHeader} from "./CallSheetHeader/CallSheetHeader";
import {EmptyState} from "./EmptyState";
import {JobCallSheetProvider, useContextJobCallSheet} from "./context/JobCallSheetContext";
import {Outlet} from "react-router-dom";
import {CallSheetStatusBar} from "./CallSheetStatusBar/CallSheetStatusBar";
import "./JobCallSheets.scss";
import {Loading} from "components/shared/Loading/Loading";

export default function JobCallSheets() {
  return (
    <JobCallSheetProvider>
      <JobCallSheetsComponent />
    </JobCallSheetProvider>
  );
}

function JobCallSheetsComponent() {
  const {
    callSheetListInfo: {isLoading},
    callSheetList
  } = useContextJobCallSheet();

  const isCallSheets = !!callSheetList?.length;

  return (
    <div className="JobCallSheet posRelative">
      {isLoading && <Loading type="inBlock" />}
      {!isCallSheets && !isLoading && <EmptyState />}
      {isCallSheets && (
        <>
          <CallSheetHeader />
          <CallSheetStatusBar />
          <Outlet />
        </>
      )}
    </div>
  );
}
