// name, department, title, email, phone, location, role, status, or created_at
export const teamTableColumn = [
  {
    id: "name",
    label: "Name",
    sort: true
  },
  {
    id: "department",
    label: "Dept",
    sort: true
  },
  {
    id: "title",
    label: "Title",
    sort: true
  },
  {
    id: "email",
    label: "Email",
    sort: true
  },
  {
    id: "phone",
    label: "Mobile",
    sort: true
  },
  {
    id: "location",
    label: "Location",
    sort: true
  },
  {
    id: "role",
    label: "Role",
    sort: true
  },
  {
    id: "status",
    label: "Status",
    sort: true
  },
  {
    id: "created_at",
    label: "Date Added",
    sort: true
  }
];
