import {createContext, FC, useContext} from "react";
import {useJobDetails} from "./useJobDetails";

export type tJobDetailsContext = ReturnType<typeof useJobDetails>;

const JobDetailsContext = createContext<tJobDetailsContext | null>(null);
JobDetailsContext.displayName = "JobDetailsContext";

export const JobDetailsContextProvider: FC = ({children}) => {
  const value = useJobDetails();
  return <JobDetailsContext.Provider value={value}>{children}</JobDetailsContext.Provider>;
};

export const useContextJobDetails = () => {
  const context = useContext(JobDetailsContext);

  if (context === null) {
    throw new Error("<<< JobDetailsContext >>> must be used within a JobDetailsContextProvider");
  }

  return context as tJobDetailsContext;
};
