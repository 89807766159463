import {tExpenseListItem} from "api/budget/track/types";
import {useSetApiParams} from "hooks/useSetApiParams";
import {toBool} from "./helpers";

export const useGenerateSearchParams = () => {
  const apiParams = useSetApiParams();
  // console.log("params", apiParams?.preparedParams);

  const searchParams: Partial<Record<keyof tExpenseListItem, any>> = {
    alert: apiParams?.preparedParams?.alert,
    cost_value: apiParams?.preparedParams?.line_numbers,
    additional_files: apiParams?.preparedParams?.additional_files,
    actual_file: apiParams?.preparedParams?.actual_file,
    bill_state: apiParams?.preparedParams?.bill_state,
    green: apiParams?.preparedParams?.green,
    pay_value: apiParams?.preparedParams?.pay_value,
    qb_status: apiParams?.preparedParams?.qb_status,
    pay_status: apiParams?.preparedParams?.pay_status,
    qb_state: apiParams?.preparedParams?.qb_state,
    w8_file: apiParams?.preparedParams?.w8_file,
    w9_file: apiParams?.preparedParams?.w9_file,
    ach_file: apiParams?.preparedParams?.ach_file,
    wire_file: apiParams?.preparedParams?.wire_file
  };

  const filters: Partial<Record<keyof tExpenseListItem, any>> = {};
  Object.entries(searchParams).forEach(item => {
    filters[item[0] as keyof tExpenseListItem] = toBool(item[1]);

    if (
      //select inputs
      item[0] === "cost_value" ||
      item[0] === "pay_value" ||
      item[0] === "qb_status" ||
      item[0] === "pay_status"
    ) {
      filters[item[0] as keyof tExpenseListItem] = item[1];
    }
  });

  return {searchParams, apiParams, filters};
};
