import {FC} from "react";
import {Button} from "components/shared/Button/Button";
import moment from "moment";
import {useCallSheetLog} from "./useCallSheetLog";
import {Loading} from "components/shared/Loading/Loading";
import {CallSheetMsg} from "../../_templates/CallSheetMsg/CallSheetMsg";
import {CallSheetTemplate} from "../../_templates/CallSheetTemplate/CallSheetTemplate";
import {CallSheetTemplateHeader} from "../../_templates/CallSheetTemplate/CallSheetTemplateHeader";
import {getDateFormat} from "../../../../../../helpers/dates";
import {CallSheetTemplateLocation} from "../../_templates/CallSheetTemplate/CallSheetTemplateLocation";
import {CallSheetTemplateSchedule} from "../../_templates/CallSheetTemplate/CallSheetTemplateSchedule";
import {CallSheetTemplateContacts} from "../../_templates/CallSheetTemplate/CallSheetTemplateContacts";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {useContextJobDetails} from "../../../../context/JobDetailsContext/JobDetailsContext";
import {getName} from "helpers/misc";

import "./CallSheetLog.scss";

interface iCallSheetLogProps {}

export const CallSheetLog: FC<iCallSheetLogProps> = () => {
  const {jobDetailsData} = useContextJobDetails();
  const {
    data = [],
    isLoading,
    callSheetInfo,
    activeDelivery,
    isDownloading,
    onCloseModal,
    onShowDetails,
    onDownload
  } = useCallSheetLog();

  function getTimezoneAbbr(date: Date | string) {
    return new Date(date)
      .toLocaleDateString("en-US", {
        day: "2-digit",
        timeZoneName: "short"
      })
      .slice(4, 7);
  }

  return (
    <div className="CallSheetLog">
      <div className="CallSheetLog-header">Call sheet delivery log</div>
      <ul className="CallSheetLog-list">
        {(isLoading || isDownloading) && <Loading type="inBlock" />}
        {data?.map(({id, created_at}) => (
          <li key={id} className="CallSheetLog-item">
            <div className="CallSheetLog-item-date">
              {moment(created_at).format("MMM DD, YYYY")}
            </div>
            <div className="CallSheetLog-item-time">
              {moment(created_at).format("hh:mmA z")} {getTimezoneAbbr(created_at)}
            </div>
            <Button size="sm" onClick={() => onShowDetails(id)}>
              view
            </Button>
            <Button size="sm" onClick={() => onDownload(id)}>
              download
            </Button>
          </li>
        ))}
      </ul>

      <ModalPopup
        className={`CallSheetLog-deliveryDetails ${isDownloading ? "isDownloading" : ""}`}
        show={!!activeDelivery}
        onClose={() => onCloseModal()}
        size="xlg"
        withoutPaddings
      >
        <CallSheetMsg msg={activeDelivery?.message as string} />

        <CallSheetTemplate>
          <CallSheetTemplateHeader
            artist={getName(jobDetailsData?.artists?.[0]?.artist)}
            clientName={jobDetailsData?.client?.name || ""}
            jobName={jobDetailsData?.name || ""}
            callSheetName={callSheetInfo?.name || ""}
            date={getDateFormat(callSheetInfo?.date || "", "ddd, MMM DD, YYYY")}
          />
          <CallSheetTemplateLocation
            image={callSheetInfo?.image || ""}
            locations={callSheetInfo?.attributes?.locations || []}
          />
          <CallSheetTemplateSchedule scheduleList={callSheetInfo?.attributes?.schedule || []} />

          <CallSheetTemplateContacts contactsGroup={activeDelivery?.groups || []} />
        </CallSheetTemplate>
      </ModalPopup>
    </div>
  );
};
