import {createContext, FC, useContext} from "react";
import {useExpenseLIst} from "./useExpenseLIst";

type tExpenseListContext = ReturnType<typeof useExpenseLIst>;
const ExpenseListContext = createContext<tExpenseListContext | null>(null);
ExpenseListContext.displayName = "ExpenseListContext";

export const ExpenseListProvider: FC = ({children}) => {
  return (
    <ExpenseListContext.Provider value={useExpenseLIst()}>{children}</ExpenseListContext.Provider>
  );
};

export const useContextExpenseList = () => {
  const context = useContext(ExpenseListContext);

  if (context === null) {
    throw new Error("<<< ExpenseListContext >>> must be used within a ExpenseListProvider");
  }

  return context as tExpenseListContext;
};
