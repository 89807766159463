import {AxiosRequestConfig} from "axios";
import {tTermsConditionsPostData} from "./types";

const TERMS_CONDITIONS = "terms_conditions";
const TERM_CONDITION = "term_condition";

export const termsConditionsApi = {
  getTermsConditionsList: (): AxiosRequestConfig => ({
    url: TERMS_CONDITIONS
  }),
  createTermsConditions: (data: tTermsConditionsPostData): AxiosRequestConfig => ({
    method: "POST",
    url: TERM_CONDITION,
    data
  }),
  updateTermsConditions: (
    termsConditionsId: number,
    data: tTermsConditionsPostData
  ): AxiosRequestConfig => ({
    method: "POST",
    url: `${TERM_CONDITION}/${termsConditionsId}`,
    data
  }),
  deleteTermsConditions: (termsConditionsId: number): AxiosRequestConfig => ({
    method: "DELETE",
    url: `${TERM_CONDITION}/${termsConditionsId}`
  }),
  downloadTermsConditions: (termsConditionsId: number): AxiosRequestConfig => ({
    responseType: "blob",
    url: `${TERM_CONDITION}/${termsConditionsId}/download`
  })
};
