export const jobsTableColumn = [
  {
    id: "favorite",
    label: `Favorite`,
    sort: false
  },
  {
    id: "department",
    label: "Dept",
    sort: true
  },
  {
    id: "id",
    label: "Job #",
    sort: true
  },
  {
    id: "name",
    label: "Job Name",
    sort: true
  },
  {
    id: "pipeline",
    label: "Pipeline",
    sort: true
  },
  {
    id: "team",
    label: "Team",
    sort: false
  },
  {
    id: "dates",
    label: "Dates",
    sort: false
  },
  {
    id: "client",
    label: "Client",
    sort: false
  },
  {
    id: "artist",
    label: "Artist",
    sort: false
  },
  {
    id: "locations",
    label: "Locations",
    sort: false
  },
  {
    id: "locale",
    label: "Locale",
    sort: false
  },
  {
    id: "button",
    label: "",
    sort: false
  }
];
