import {useState} from "react";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import {Link, useNavigate} from "react-router-dom";
import {eventBus} from "EventBus/EventBus";
import {jobsApi} from "api/jobs";
import {JobSections} from "./steps/JobSections/JobSections";
import {absRoutes} from "router";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {JobDetails} from "./steps/JobDetails/JobDetails";
import {useTextInput, useTextInputReturn} from "hooks/useTextInput/useTextInput";
import {lengthValidation} from "hooks/useTextInput/validators";
import "./CreateNewJob.scss";

type tFormKey = "name" | "client_name" | "client_type" | "client_id" | "departments" | "sections";
export type CreateNewJobForm = Record<tFormKey, useTextInputReturn>;

export default function CreateNewJob() {
  const {call, isLoading} = useHttp();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const nextStep = () => setStep(prev => prev + 1);

  const form: CreateNewJobForm = {
    name: useTextInput({validators: [lengthValidation(2)]}),
    client_name: useTextInput(),
    client_type: useTextInput(),
    client_id: useTextInput(),
    departments: useTextInput(),
    sections: useTextInput()
  };

  const stepsCallBack = async (lastFormData?: {sections?: string}) => {
    if (step === 1) {
      lastFormData?.sections && form.sections.setValue(lastFormData?.sections);

      const formData = {
        name: form.name.value,
        client_id: +form.client_id.value,
        client_type: form.client_type.value,
        departments: form.departments.value.split(",").map(item => +item),
        sections: lastFormData?.sections?.split(",").map(item => +item)
      };

      try {
        const res = await call(jobsApi.addJob(formData));
        const id = res?.data?.data?.id;
        eventBus.dispatch("showToast", {text: "Job added!"});
        navigate(`${absRoutes.jobs.main}/${id}`);
      } catch (error) {
        const {msg} = apiErrorHandler(error, form);
        eventBus.dispatch("showToast", {type: "error", text: msg || "Error"});
      }

      return;
    }

    nextStep();
  };

  const buildSteps = [
    <JobDetails stepsCallBack={stepsCallBack} form={form} />,
    <JobSections stepsCallBack={stepsCallBack} form={form} isLoading={isLoading} />
  ];

  return (
    <div className="CreateNewJob">
      <div className="CreateNewJob-header">
        <div className="btnBackWrap">
          {step !== 0 && (
            <Button onClick={() => setStep(prev => prev - 1)} className="back" disabled={!step}>
              <Icon icon="arrow-left" color="gray" size="lg" />
            </Button>
          )}
        </div>

        <div className="steps">
          {buildSteps.map((_, i) => {
            return <div key={i} className={`stepTile ${i === step ? "active" : ""}`} />;
          })}
        </div>

        <Link to={absRoutes.jobs.main} className="close">
          <Icon icon="close" color="gray" size="lg" />
        </Link>
      </div>

      {buildSteps[step]}
    </div>
  );
}
