import {ChangeEvent, FC, useEffect, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {useSearchParams} from "react-router-dom";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {useIsFirstRender} from "hooks/useIsFirstRender";
import {useDebounce} from "hooks/useDebounce";
import {iPaginationProps} from "./types";
import {PerPage} from "./PerPage";
import "./Pagination.scss";

/**
 * How to use: `{apiData && <Pagination meta={apiData.data.meta} />}`
 * @returns ReactComponent
 */

export const Pagination: FC<iPaginationProps> = ({meta}) => {
  const [searchParams, setParams] = useSearchParams();
  const [numPage, setNumPage] = useState("");
  const [value, setValue] = useState(String(meta?.current_page));
  const debouncedNumPage = useDebounce(numPage, 500);
  const isFirst = useIsFirstRender();

  const setPageParams = (perPageVal?: string) => {
    if (perPageVal) {
      setParams({...Object.fromEntries(searchParams), per_page: perPageVal, page: "1"});
      setValue("1");
    } else {
      numPage && setParams({...Object.fromEntries(searchParams), page: numPage});
    }
  };

  useEffect(() => {
    setPageParams();
  }, [debouncedNumPage]); // eslint-disable-line

  useEffect(() => {
    if (isFirst) return;
    setNumPage(value);
  }, [value]); // eslint-disable-line

  useEffect(() => {
    if (isFirst) return;
    !Object.fromEntries(searchParams).hasOwnProperty("page") && setValue("1");
  }, [searchParams]); // eslint-disable-line

  useEffect(() => {
    if (
      Object.fromEntries(searchParams).hasOwnProperty("page") &&
      +Object.fromEntries(searchParams)["page"] > meta?.last_page
    ) {
      setParams({...Object.fromEntries(searchParams), page: String(meta?.last_page)});
      setValue(String(meta?.last_page));
    }
  }, [searchParams]); // eslint-disable-line

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const formattedVal = e.target.value.replace(/\D|^0/g, "");
    let val = +formattedVal > meta?.last_page ? String(meta?.last_page) : formattedVal;
    setValue(val);
  };

  const goPrev = () => setValue(String(+value > 0 ? +value - 1 : 1));
  const goNext = () => setValue(String(+value + 1));

  const disablePrevBtn = +value <= 1;
  const disableNextBtn = +value === meta?.last_page;

  return (
    <div className="Pagination">
      <PerPage meta={meta} setPageParams={setPageParams} />

      <Button size="sm" color="outline" onClick={goPrev} disabled={disablePrevBtn}>
        <Icon icon="arrow-left" size="md" />
      </Button>

      <InputField value={value} onChange={changeHandler} className="Pagination-input" />
      <div className="Pagination-slash">/</div>
      <div className="Pagination-lastPage">{meta?.last_page}</div>

      <Button size="sm" color="outline" onClick={goNext} disabled={disableNextBtn}>
        <Icon icon="arrow-right" size="md" />
      </Button>
    </div>
  );
};
