import {FC, MouseEvent, useState} from "react";
import {iContactsEntity, Contact} from "api/location/types";
import {PeopleModals} from "components/shared/Modals/PeopleModals/PeopleModals";
import {TagButtonsListStacked} from "components/shared/TagButtonsListStacked/TagButtonsListStacked";

interface iLocationTableContactsProps {
  contacts: iContactsEntity[];
}

type tContact = Contact & {name: string};

export const LocationTableContacts: FC<iLocationTableContactsProps> = props => {
  const [showContact, setShowContact] = useState<number | null>(null);

  const closePeopleModal = () => setShowContact(null);

  const showContactDetails = (event: MouseEvent<HTMLButtonElement>, item: tContact) => {
    event.stopPropagation();
    setShowContact(item.id);
  };

  const preventPropagation = (event: MouseEvent<HTMLDivElement>) => event.stopPropagation();

  const contacts = props.contacts.reduce((acc: tContact[], curr) => {
    return [
      ...acc,
      {...curr.contact, name: `${curr.contact?.first_name || ""} ${curr.contact?.last_name || ""}`}
    ];
  }, []);

  return (
    <>
      <td className="LocationsTableRow-cell contacts">
        <TagButtonsListStacked
          data={contacts}
          onClick={showContactDetails}
          buttonsProps={{size: "sm"}}
          maxWidth={460}
        />

        {showContact && (
          <div onClick={preventPropagation}>
            <PeopleModals person={showContact} closePeopleModal={closePeopleModal} />
          </div>
        )}
      </td>
    </>
  );
};
