import {FC} from "react";
import {TagButton} from "components/shared/TagButton/TagButton";
import "./JobLocationTag.scss";

interface iJobLocationTagProps {
  value: {id: number; name: string}[];
  onRemove: (id: number | undefined) => void;
}

export const JobLocationTag: FC<iJobLocationTagProps> = ({value, onRemove}) => {
  if (!value?.length) return null;

  return (
    <div className="JobLocationTag">
      {value.map(({id, name}: any) => {
        return (
          <TagButton color="light" key={id} id={id} onRemove={onRemove}>
            {name}
          </TagButton>
        );
      })}
    </div>
  );
};
