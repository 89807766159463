import {FC, Fragment} from "react";
import {CallSheetBuildContactsHeader} from "./CallSheetBuildContactsHeader/CallSheetBuildContactsHeader";
import {CallSheetBuildContactAgent} from "./CallSheetBuildContactListItem/CallSheetBuildContactAgent";
import {CallSheetBuildContactManuallyEntered} from "./CallSheetBuildContactListItem/CallSheetBuildContactManuallyEntered/CallSheetBuildContactManuallyEntered";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {CallSheetBuildContact} from "./CallSheetBuildContactListItem/CallSheetBuildContact/CallSheetBuildContact";
import {useContextJobCallSheet} from "../../context/JobCallSheetContext";
import {uid} from "helpers/misc";
import {ShowGroupBtn} from "./CallSheetBuildContactListItem/CHCommon/ShowGroupBtn";
import {tCallSHeetGroupContacts} from "api/callSheet/types";

import "./CallSheetBuildContacts.scss";

type tCallSheetBuildContactsProps = {
  [key: string]: any;
};

export type tSetContactGroupCallback = (
  groups: tCallSHeetGroupContacts[] | undefined
) => tCallSHeetGroupContacts[] | [];

export const CallSheetBuildContacts: FC<tCallSheetBuildContactsProps> = () => {
  const {callSheetInfo, setCallSheetInfo} = useContextJobCallSheet();

  function setContactGroupCallback(cb: tSetContactGroupCallback) {
    const data = cb(callSheetInfo?.attributes?.groups);
    setCallSheetInfo(prev => ({
      ...prev,
      attributes: {
        ...prev?.attributes,
        groups: data
      }
    }));
  }

  return (
    <div className="CallSheetBuildContacts">
      {callSheetInfo?.attributes?.groups?.map((group, i) => {
        const isFirstGroup = i === 0;

        if (!!group?.hidden) {
          return (
            <ShowGroupBtn key={group?.id} group={group} setContactGroup={setContactGroupCallback} />
          );
        }

        return (
          <Fragment key={group?.id}>
            <CallSheetBuildContactsHeader
              group={group}
              isFirstGroup={isFirstGroup}
              setContactGroup={setContactGroupCallback}
            />

            {group?.contacts?.map((item, index) => {
              const showTooltip = index === 0 && isFirstGroup;
              return (
                <Fragment key={item?.id || uid()}>
                  {typeof item?.id === "number" && (
                    <>
                      <CallSheetBuildContact
                        contact={item}
                        group={group}
                        showTooltip={showTooltip}
                        setContactGroup={setContactGroupCallback}
                      />
                      {item?.agent && <CallSheetBuildContactAgent contact={item} />}
                    </>
                  )}

                  {typeof item?.id === "string" && (
                    <>
                      <TextComponent uppercase weight="500" size="10" className="manuallyDivider">
                        MANUALLY ENTERED
                      </TextComponent>

                      <CallSheetBuildContactManuallyEntered
                        id={item?.id}
                        group={group}
                        contact={item}
                        setContactGroup={setContactGroupCallback}
                      />
                    </>
                  )}
                </Fragment>
              );
            })}
          </Fragment>
        );
      })}
    </div>
  );
};
