import {tExpenseListItem} from "api/budget/track/types";
import {useGenerateSearchParams} from "./useGenerateSearchParams";

export const useFilterList = () => {
  const {filters} = useGenerateSearchParams();

  const filterList = (list: Array<tExpenseListItem>) => {
    if (!list?.length) return [];
    const newList = [...list];

    return newList?.filter(item => {
      for (const currKey in filters) {
        const key = currKey as keyof tExpenseListItem;

        if (filters[key] !== undefined) {
          // keys from useFIltersExpenseList and useGenerateSearchParams
          if (key === "w8_file" && !!item?.w8_file !== filters[key]) return false;
          if (key === "w9_file" && !!item?.w9_file !== filters[key]) return false;
          if (key === "ach_file" && !!item?.ach_file !== filters[key]) return false;
          if (key === "wire_file" && !!item?.wire_file !== filters[key]) return false;

          if (key === "qb_status" && !filterItem(key, item?.qb_status?.id)) return false;
          if (key === "pay_status" && !filterItem(key, item?.pay_status?.id)) return false;
          if (key === "pay_value" && !filterItem(key, item?.pay_value?.payId?.id)) return false;
          if (key === "cost_value" && !filterItem(key, item?.cost_value?.id)) return false;

          // if (key === "additional_files" && !!item[key]?.length !== filters[key]) return false;
          if (key === "actual_file" && !!item[key] !== filters[key]) return false;

          if (key === "bill_state") {
            return item[key] !== filters[key] ? item : false;
          }

          if (typeof item[key] === "boolean" && item[key] !== filters[key]) return false;
        }
      }

      return true;
    });
  };

  const filterItem = (key: keyof tExpenseListItem, item: number | undefined) => {
    return filters?.[key]?.split(",").find((ent: number | string) => +ent === item);
  };

  return {filterList};
};
