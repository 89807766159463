import {ChangeEvent, FC, FormEvent} from "react";
import {Button} from "components/shared/Button/Button";
import {CheckBoxGroupe} from "components/shared/FormComponents/CheckBoxGroupe/CheckBoxGroupe";
import {Template} from "./Template";
import {departments} from "../JobDetails/Department/Departments";
import {useJobSections} from "./useJobSections";
import {Loading} from "components/shared/Loading/Loading";
import {CreateNewJobForm} from "../../CreateNewJob";
import "./JobSections.scss";

interface iJobSectionsProps {
  stepsCallBack: (formData?: {sections: string}) => void;
  form: CreateNewJobForm;
  isLoading: boolean;
}

export const JobSections: FC<iJobSectionsProps> = ({stepsCallBack, form, isLoading}) => {
  const {sections, selected, list, getSectionsLoading, setSelected} = useJobSections({form});

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, checked} = e.target;
    let newSections = sections.selectedCheckBoxes.current;

    const isSelected = checked && value;
    isSelected && (newSections = [...sections.selectedCheckBoxes.current, +value]);
    !isSelected && (newSections = sections.selectedCheckBoxes.current?.filter(item => item !== +value)); // prettier-ignore

    const newSelected = list.filter(item => newSections.includes(item.id) && item);

    setSelected(newSelected);
    sections.selectedCheckBoxes.current = newSections;
    form.sections.setValue(newSections.join(","));
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const sectionsString = sections.selectedCheckBoxes.current?.join(",");

    stepsCallBack({sections: sectionsString});
  };

  const color = departments.find(item => +item.id === +form.departments.value);
  return (
    <div className="JobSections">
      <form className="JobSections-form" onSubmit={onSubmit}>
        <div className="title">Job Sections</div>
        <div className="subTitle">
          <div> Edit the sections to include.</div>
          <div> This can be changed after the job’s created.</div>
        </div>

        {getSectionsLoading && (
          <div className="loaderWrap">
            <Loading type="inBlock" />
          </div>
        )}

        {!getSectionsLoading && (
          <CheckBoxGroupe
            checkBoxList={list}
            initValue={sections.selectedCheckBoxes.current}
            onChange={onChange}
            errors={sections.errors}
            customClass="sectionCheckboxes"
          />
        )}

        <Button size="lg" color="dark" loading={isLoading}>
          CREATE JOB
        </Button>

        <div className="info">
          <div>The file section is created automatically.</div>
          <div>A Dropbox folder will be generated based on the job’s deparment.</div>
        </div>
      </form>

      <Template selected={selected} color={color?.color || ""} />
    </div>
  );
};
