import {FC} from "react";
import {Button} from "components/shared/Button/Button";
import {TextareaField} from "components/shared/FormComponents/TextareaField/TextareaField";
import {tEstimateGroup, tEstimateRowData} from "api/budget/estimate/types"; // prettier-ignore
import {useContextJobBudget} from "pages/Jobs/JobDetails/JobBudget/context/JobBudgetContext";
import {useSubmitEstimateDescriptions} from "./useSubmitEstimateDescriptions";
import "./EstimateRowExpanded.scss";

type tEstimateRowExpandedProps = {
  currItem: tEstimateRowData;
  group: tEstimateGroup;
  close: () => void;
};

export const EstimateRowExpanded: FC<tEstimateRowExpandedProps> = ({currItem, group, close}) => {
  const {estimate} = useContextJobBudget();
  const {onSubmit, isLoading, form} = useSubmitEstimateDescriptions({currItem, group});

  const isApproved = !!estimate?.approved_at;
  return (
    <form onSubmit={onSubmit} className={`EstimateRowExpanded ${isApproved ? "isApproved" : ""}`}>
      <div className="EstimateRowExpanded-inputsWrap">
        <TextareaField
          {...form.description.inputProps}
          errors={form.description.errors}
          disabled={isApproved}
          label="Description"
        />
        <TextareaField
          {...form.discount_description.inputProps}
          errors={form.discount_description.errors}
          disabled={isApproved}
          placeholder={isApproved ? "" : "This text exports in red"}
          label="Discount Description"
        />
        <TextareaField
          {...form.private_memo.inputProps}
          errors={form.private_memo.errors}
          disabled={isApproved}
          label="Private memo"
          placeholder={isApproved ? "" : "This text does not export"}
        />
      </div>

      {!isApproved && (
        <div className="EstimateRowExpanded-btnWrap">
          <Button color="outline" size="sm" type="button" disabled={isLoading} onClick={close}>
            Cancel
          </Button>
          <Button color="light" size="sm" type="submit" loading={isLoading}>
            Save
          </Button>
        </div>
      )}
    </form>
  );
};
