import {FC, useState} from "react";
import {tCrewContact} from "api/jobCrewGroup/types";
import {Button} from "components/shared/Button/Button";
import {useContextJobCrew} from "../../../JobCrewContext";
import {jobCrewContact} from "api/jobCrewContact";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import {updateContact} from "../../../commonJobCrew/updateContact";
import {eventBus} from "EventBus/EventBus";
import "./RowBilling.scss";

type tRowBillingProps = {
  crewContact: tCrewContact;
  groupId: number;
};

export const RowBilling: FC<tRowBillingProps> = ({crewContact, groupId}) => {
  const {billing, contact} = crewContact;
  const {jobCrewGroupList: {setCrewGroupList}} = useContextJobCrew(); // prettier-ignore
  const {call, isLoading} = useHttp();

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const changeBilling = async (billing: Array<number>) => {
    setMenuAnchor(null);

    try {
      const res = await call(jobCrewContact.setBillingForContact(crewContact?.id, {billing}));
      setCrewGroupList(prev => {
        return updateContact({
          prev,
          groupId,
          crewContactId: crewContact?.id,
          updatedContact: res?.data?.data
        });
      });
      eventBus.dispatch("showToast", {text: res?.data?.message || "Success!"});
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  const bill = billing?.length === 1 ? billing?.[billing?.length - 1]?.name : "both";
  const showDefaultBilling = !billing?.length && !!contact?.billing_default?.length;
  const showSelectDefaultBilling = !billing?.length && !contact?.billing_default?.length;

  return (
    <div className="RowBilling">
      {showSelectDefaultBilling && (
        <Tooltip message="Open the contact record to add a billing default" position="top">
          <TextComponent size="10" weight="500" uppercase nowrap color="red">
            no default
          </TextComponent>
        </Tooltip>
      )}

      {showDefaultBilling && (
        <Button
          size="sm"
          disabled={isLoading}
          hidePadding="horizontal"
          // onClick={showMenu}
          className="defaultBtn"
          btnType="text"
        >
          {contact?.billing_default?.length === 2 && "both"}
          {contact?.billing_default?.length < 2 &&
            contact?.billing_default?.[contact?.billing_default?.length - 1]?.name}
        </Button>
      )}

      {!!billing?.length && (
        <Button
          //   onClick={showMenu}
          size="sm"
          disabled={isLoading}
          hidePadding="horizontal"
          btnType="text"
        >
          {!!billing?.length && bill}
        </Button>
      )}

      <DropdownMenu
        anchor={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        transformOrigin={{
          horizontal: "right"
        }}
      >
        <Button size="sm" onClick={() => changeBilling([2])} disabled={isLoading}>
          payroll
        </Button>
        <Button size="sm" onClick={() => changeBilling([1])} disabled={isLoading}>
          invoice
        </Button>
        <Button size="sm" onClick={() => changeBilling([1, 2])} disabled={isLoading}>
          both
        </Button>
      </DropdownMenu>
    </div>
  );
};
