import {ChangeEvent, FC} from "react";
import {Select} from "components/shared/FormComponents/Select/Select";
import {iField} from "api/fields/types";

interface iCustomSelectProps {
  field: any;
  onChange: (value: iField) => void;
}

export const CustomSelect: FC<iCustomSelectProps> = ({field, onChange}) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onChange({...field, value: event.target.value});
  };

  return (
    <Select
      value={field?.value || ""}
      onChange={handleChange}
      options={field.options || []}
      targetKeys={{
        value: "value",
        label: "name"
      }}
      label={field.name}
      canSelectEmpty
    />
  );
};
