import {FC} from "react";
import {iTooltipProps, Tooltip} from "components/shared/Tooltip/Tooltip";
import {TextComponent, tText} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {ConditionalWrap} from "components/ConditionalWrap/ConditionalWrap";

type tTextWithTooltipProps = {
  text: string;
  textProps?: tText;
  tooltips?: iTooltipProps;
  as?: React.ElementType<any>;
};

export const TextWithTooltip: FC<tTextWithTooltipProps> = ({text, textProps, tooltips, as}) => {
  const textComp = (
    <TextComponent as={as} size="10" weight="500" uppercase nowrap {...textProps}>
      {text}
    </TextComponent>
  );

  return (
    <ConditionalWrap
      if={!!tooltips}
      wrapper={() => (
        <Tooltip message={tooltips?.message} position="top" {...tooltips}>
          {textComp}
        </Tooltip>
      )}
    >
      {textComp}
    </ConditionalWrap>
  );
};
