import {FC} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {ImgWithFallback} from "components/shared/ImgWithFallback/ImgWithFallback";
import {iLocationDetailsResponse} from "api/location/types";

interface iLocationDetailsHeaderProps {
  data: iLocationDetailsResponse;
  editLocation: () => void;
};

export const LocationDetailsHeader: FC<iLocationDetailsHeaderProps> = ({data, editLocation}) => {
  let emptyImageClassName = data?.photo_url ? "LocationDetails-header-contain-img" : "";

  return (
    <>
      <div className={`LocationDetails-header ${emptyImageClassName}`}>
        <ImgWithFallback
          className="LocationDetails-image"
          src={data?.photo_url || ""}
          alt="location"
        />

        <div className="LocationDetails-image-overlay" />
        <Button size="md" color="light" onClick={editLocation}>
          <Icon icon="edit" size="sm" /> <span>Edit</span>
        </Button>
        <h1 className="LocationDetails-header-title">{data?.name}</h1>
      </div>
    </>
  );
};
