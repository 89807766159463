import {FC} from "react";
import {Outlet} from "react-router-dom";
import {Sidebar} from "components/Sidebar/Sidebar";
import Notifications from "components/Notifications/Notifications";
import {Onboarding} from "components/Onboarding/Onboarding";
import "./PageLayout.scss";

export const PAGE_LAYOUT_ID = "page-layout-id";

const PageLayout: FC = () => {
  return (
    <div className="PageLayout">
      <Sidebar />
      <Notifications />
      <div className="PageLayout-main" id={PAGE_LAYOUT_ID}>
        <Outlet />
      </div>

      <Onboarding />
    </div>
  );
};

export default PageLayout;
