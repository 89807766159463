import {CSSProperties, MouseEvent, RefObject, useState} from "react";
import {iTooltipProps} from "./Tooltip";

type tHandleMouseEnter = {
  event: MouseEvent<HTMLDivElement>;
  messageRef: RefObject<HTMLDivElement>;
};

interface iUseTooltip {
  messageStyles: CSSProperties | undefined;
  handleMouseEnter: ({event, messageRef}: tHandleMouseEnter) => void;
  handleMouseLeave: () => void;
}

const showingMessageStyles: CSSProperties = {
  visibility: "visible",
  opacity: 1,
  zIndex: 2
};

export function useTooltip(position: iTooltipProps["position"]): iUseTooltip {
  const arrowHeight = 6;
  const [messageStyles, setMessageStyles] = useState<CSSProperties | undefined>();

  const handleMouseEnter = ({event, messageRef}: tHandleMouseEnter) => {
    const rootPosition: DOMRect | undefined = event.currentTarget.getBoundingClientRect();
    const messagePosition: DOMRect | undefined = messageRef?.current?.getBoundingClientRect();

    if (!rootPosition || !messagePosition) return;

    const {width: messageWidth, height: messageHeight} = messagePosition;
    const {left, right, top, width, height} = rootPosition;

    const horizontalCentering = (width - messageWidth) / 2 + left;
    const verticalCentering = (height - messageHeight) / 2 + top;

    switch (position) {
      case "bottom":
        const bottomPosition = {
          left: horizontalCentering + "px",
          top: arrowHeight + top + height + "px",
          ...showingMessageStyles
        };
        setMessageStyles(bottomPosition);
        break;

      case "top":
        const topPosition = {
          left: horizontalCentering + "px",
          top: top - (messageHeight + arrowHeight) + "px",
          ...showingMessageStyles
        };
        setMessageStyles(topPosition);
        break;

      case "left":
        const leftPosition = {
          left: left - (messageWidth + arrowHeight) + "px",
          top: verticalCentering + "px",
          ...showingMessageStyles
        };
        setMessageStyles(leftPosition);
        break;

      case "right":
        const rightPosition = {
          left: arrowHeight + right + "px",
          top: verticalCentering + "px",
          ...showingMessageStyles
        };
        setMessageStyles(rightPosition);
        break;

      default:
        break;
    }
  };

  const handleMouseLeave = () => setMessageStyles(undefined);

  return {messageStyles, handleMouseEnter, handleMouseLeave};
}
