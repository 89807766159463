import {FC, useEffect} from "react";
import {iAddToast, iToastListItem} from "components/shared/ToastMessage/interfaces";
import {Icon} from "components/Icon/Icon";
import {useToastMessages} from "components/shared/ToastMessage/useToastMessages";
import {eventBus} from "EventBus/EventBus";

import "./ToastMessage.scss";

export interface iToastMessageProps {
  toastList?: iToastListItem[];
  position?: "top-center" | "top-right" | "bottom-right" | "top-left" | "bottom-left";
}

export const ToastMessage: FC<iToastMessageProps> = ({position = "top-center"}) => {
  const {messages, addToast, removeToast, handleMouseEnter, handleMouseLeave} = useToastMessages(); // prettier-ignore

  useEffect(() => {
    eventBus.on("showToast", ({detail}: {detail: iAddToast}) => addToast(detail));
    return () => {
      eventBus.remove("showToast", ({detail}: any) => addToast(detail));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!messages?.length) return null;

  return (
    <>
      <div className={`ToastMessage ${position}`}>
        {messages.map(({id, icon, text}) => (
          <div
            key={id}
            id={id}
            className={`ToastMessage-toast ${position}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="ToastMessage-image">{icon}</div>
            <div>
              <p className="ToastMessage-text">{text}</p>
            </div>
            <button className="ToastMessage-close" onClick={() => removeToast(id)}>
              <Icon icon="close" size="sm" />
            </button>
          </div>
        ))}
      </div>
    </>
  );
};
