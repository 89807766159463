import {FC, Fragment, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {useContextJobBudget} from "../../_context/JobBudgetContext";

type tEmptyLinesTogglerProps = {};

export const EmptyLinesToggler: FC<tEmptyLinesTogglerProps> = () => {
  const {estimate, hideEmptyLines, setHideEmptyLines} = useContextJobBudget();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const emptyLinesToggler = () => {
    setMenuAnchor(null);
    setHideEmptyLines(prev => !prev);
  };

  if (estimate?.approved_at) return <div className="EstimateTableHeader-cell" />;

  const title = hideEmptyLines ? "Hide" : "Show";
  const titleSelect = hideEmptyLines ? "Show" : "Hide";

  return (
    <Fragment>
      <div className="EstimateTableHeader-cell">
        <Button size="sm" className="btn" onClick={showMenu}>
          {title} empty lines
          <Icon icon="triangle-down" size="xxsm" color="gray" />
        </Button>
      </div>

      <DropdownMenu anchor={menuAnchor} onClose={() => setMenuAnchor(null)}>
        <Button size="sm" className="btn" onClick={emptyLinesToggler}>
          {titleSelect} empty lines
        </Button>
      </DropdownMenu>
    </Fragment>
  );
};
