import React, {FC} from "react";
import {Accordion} from "components/shared/Accordion/Accordion";
import {PeopleDetailsHeader} from "./PeopleDetailsHeader/PeopleDetailsHeader";
import {PeopleDetailsGeneralInfo} from "./PeopleDetailsJobs/PeopleDetailsGeneralInfo/PeopleDetailsGeneralInfo";
import {iPersonDetails} from "pages/Contacts/People/interfaces";
import {tModalNames} from "interfaces/commonInterfaces";
import {Loading} from "components/shared/Loading/Loading";
import {parseAddress} from "helpers/misc";
import {ModalInfoSection} from "components/shared/ModalInfoSection/ModalInfoSection";
import {DepartmentList} from "components/shared/DepartmentList/DepartmentList";
import {TagButtonList} from "components/shared/TagButtonList/TagButtonList";
import {TagsGroup} from "components/shared/TagsGroup/TagsGroup";
import {TagButton} from "components/shared/TagButton/TagButton";
import {useContextProfile} from "context";
import {PeopleDetailsJobs} from "./PeopleDetailsJobs/PeopleDetailsJobs";
import {convertDataToBoolean} from "components/shared/Modals/helpers/helpers";
import {parseCustomFieldData} from "components/shared/CustomFieldsForm/parseCustomFieldData";

import "./PeopleDetails.scss";

interface iPeopleDetails {
  setModal?: (modalType: tModalNames) => void;
  person: iPersonDetails;
  isLoading?: boolean;
  showEditForm?: () => void;
  setTeamModal?: React.Dispatch<any>;
  className?: string;
}

export const PeopleDetails: FC<iPeopleDetails> = ({
  person,
  isLoading,
  showEditForm,
  setTeamModal,
  className = ""
}) => {
  const {userData} = useContextProfile();

  const {first_name, middle_name, last_name, avatar_url, accounts, phones, emails} = person;
  const {locales, labels, companies, agencies, titles, rate, address, billing_info} = person;
  const {billing_default, departments, dietary_restrictions, legal_name} = person;
  const {other_dietary_restriction, pronoun, notes, travel, in_favorite} = person;
  const {versatile_relationship, team, linked_team, locations, jobs, pronoun_custom} = person;
  const {custom_fields} = person;

  const personName = `${first_name ?? ""}${middle_name ? " " + middle_name : ""}${
    last_name ? " " + last_name : ""
  }`;

  const companiesTagsData =
    companies?.map(({id, name}) => ({value: String(id), label: name})) || [];
  const agenciesTagsData =
    agencies?.map(({id, name}) => ({
      value: String(id),
      label: name
    })) || [];

  const teamTagsData =
    team?.map(({user, user_relationship}) => ({
      id: user.id,
      name: user.first_name ?? "" + " " + user.last_name ?? "", // eslint-disable-line
      tooltip: user_relationship.name
    })) || [];

  const templates = {
    workDetails: [
      {
        labelClass: "PeopleDetails-align-self-center",
        label: "Company",
        value: !!companies?.length && (
          <TagsGroup
            tags={companiesTagsData}
            elementClass="tag-link tag-link__lg tag-link__company"
          />
        )
      },
      {
        labelClass: "PeopleDetails-align-self-center",
        label: "Artist Agency",
        value: !!agencies?.length && (
          <TagsGroup
            tags={agenciesTagsData}
            elementClass="tag-link tag-link__lg tag-link__company"
          />
        )
      },
      {
        labelClass: "PeopleDetails-align-self-center",
        label: "Team",
        value: !!team?.length && <TagButtonList onClick={teamClickHandler} list={teamTagsData} />
      },
      {label: "Title", value: titles?.length && titles.map(({name}) => name).join(", ")},
      {label: "Rate", value: rate},
      {
        label: "Billing default",
        value: billing_default?.length && billing_default?.map(item => item?.name).join(", ")
      }
    ],
    addressAndBilling: [
      {label: "Home address", value: address && parseAddress(address).string},
      {
        label: "Billing name",
        value: billing_info
          ? `${billing_info?.first_name || ""} ${billing_info?.last_name || ""}`
          : null
      },
      {label: "Billing email", value: billing_info?.email},
      {
        label: "Billing phone",
        value: billing_info?.phone
        // value: formatPhoneNumber({
        //   value: billing_info?.phone
        // })
      },
      {
        label: "Billing address",
        value: billing_info?.address && parseAddress(billing_info?.address).string
      }
    ],
    otherDetails: [
      {label: "Relationship", value: versatile_relationship?.name},
      {
        label: "Departments",
        value: departments.length && (
          <DepartmentList
            data={departments?.map(dep => ({id: dep?.id, avatar: null, name: dep?.name}))}
          />
        )
      },
      {
        label: "Dietary Restrictions",
        value: dietary_restrictions?.map(({name}) => name).join(", ")
      },
      {label: "Other Restrictions", value: other_dietary_restriction},
      {label: "Pronouns", value: pronoun_custom || pronoun?.name},
      {label: "Travel", value: travel},
      {label: "Notes", value: notes}
    ],
    linkedVia: [
      {
        label: "Team",
        value: !!linked_team?.length && <LinkedTeamRender data={linked_team} />
      },
      {
        label: "Locations",
        value: !!locations?.length && <LocationRender data={locations} />
      },
      {
        label: "Jobs",
        value: !!jobs?.length && <PeopleDetailsJobs data={jobs} />
      }
    ],
    customFields: parseCustomFieldData(custom_fields)
  };

  function teamClickHandler(id: number) {
    if (userData?.role?.name !== "Admin") return;
    setTeamModal?.(person?.team.find(user => user?.user.id === Number(id)));
  }

  if (isLoading || !person?.id) return <Loading type="inBlock" />;

  return (
    <div className={`PeopleDetails ${className}`}>
      <PeopleDetailsHeader
        showEditForm={showEditForm}
        name={personName}
        avatar={avatar_url}
        sub_name={legal_name || ""}
        isFavorite={in_favorite}
        id={person.id}
        type="people"
      />

      <PeopleDetailsGeneralInfo
        accounts={accounts}
        phones={phones}
        emails={emails}
        locales={locales}
        labels={labels}
      />

      {convertDataToBoolean(templates.workDetails) && (
        <Accordion title="Work Details" className="PeopleDetails-Accordion">
          <ModalInfoSection list={templates.workDetails} />
        </Accordion>
      )}

      {convertDataToBoolean(templates.addressAndBilling) && (
        <Accordion title="Address & Billing" className="PeopleDetails-Accordion">
          <ModalInfoSection list={templates.addressAndBilling} />
        </Accordion>
      )}

      {convertDataToBoolean(templates.otherDetails) && (
        <Accordion title="Other Details" className="PeopleDetails-Accordion">
          <ModalInfoSection list={templates.otherDetails} />
        </Accordion>
      )}

      {convertDataToBoolean(templates.customFields) && (
        <Accordion title="Custom Details" className="PeopleDetails-Accordion">
          <ModalInfoSection list={templates.customFields} />
        </Accordion>
      )}

      {convertDataToBoolean(templates.linkedVia) && (
        <Accordion title="Linked Via" className="PeopleDetails-Accordion">
          <ModalInfoSection list={templates.linkedVia} />
        </Accordion>
      )}
    </div>
  );
};

const LinkedTeamRender = ({data}: any) => {
  if (!data) return null;
  return (
    <div className="TagsGroup team">
      {data?.map((item: any) => (
        <TagButton key={item?.id}>
          {item?.first_name || ""} {item?.last_name || ""}
        </TagButton>
      ))}
    </div>
  );
};

const LocationRender = ({data}: any) => {
  if (!data) return null;

  return (
    <div className="TagsGroup locations">
      {data?.map(({id, name}: any) => (
        <TagButton key={id} color="light">
          {name}
        </TagButton>
      ))}
    </div>
  );
};
