import {FC, FormEvent, useEffect} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextJobBudget} from "pages/Jobs/JobDetails/JobBudget/context/JobBudgetContext";
import {estimateApi} from "api/budget/estimate";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {Icon} from "components/Icon/Icon";
import "./EstimateGroupRow.scss";

type tEstimateGroupRowProps = {
  title: string;
  value: string | null;
  groupId: number;
  showListToggler?: () => void;
  showList?: boolean;
};

export const EXPANDED_BUDGET_LIST = "expanded-budget-list";

export const EstimateGroupRow: FC<tEstimateGroupRowProps> = props => {
  const {title, value, groupId, showListToggler, showList} = props;

  const {setEstimate, estimate} = useContextJobBudget();
  const placeholder = estimate?.global_production_percent ?? "15.0";

  const {call, isLoading} = useHttp({cancelRequest: false});

  const form = {
    percent: useTextInput({value: value ?? "", filters: ["percent"]})
  };

  useEffect(() => {
    form.percent.setValue(value ?? "");
  }, [value]); // eslint-disable-line

  const onSubmit = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    updatePercent();
  };

  const updatePercent = async () => {
    if (value === form.percent.value) return;
    if (value === null && !form.percent.value) return;

    try {
      const res = await call(
        estimateApi.updateGroupPercent(groupId, {
          production_percent:
            form.percent.value && form.percent.value !== "" ? +form.percent.value : null
        })
      );
      setEstimate(() => res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <div
      className={`EstimateGroupRow ${showList ? EXPANDED_BUDGET_LIST : ""}`}
      id={`estimateGroup-${groupId}`}
    >
      <TextComponent
        size="17"
        as="button"
        textAlign="left"
        onClick={showListToggler}
        className="toggler"
      >
        {title} {showList !== undefined && <Icon icon={showList ? "arrow-up" : "arrow-down"} />}
      </TextComponent>

      {!estimate?.approved_at && (
        <>
          <TextComponent weight="500" size="13">
            Production %
          </TextComponent>

          <Tooltip message="Overrides global prod %" position="bottom">
            <form onSubmit={onSubmit} className="posRelative">
              <InputField
                {...form.percent.inputProps}
                suffix={"%"}
                onBlur={updatePercent}
                placeholder={`${placeholder}%`}
                isLoading={isLoading}
              />
            </form>
          </Tooltip>
        </>
      )}
    </div>
  );
};
