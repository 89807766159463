import {createContext, FC, useContext} from "react";
import {useWrapPDF} from "./useWrapPDF";

type tWrapPDFContext = ReturnType<typeof useWrapPDF>;
const WrapPDFContext = createContext<tWrapPDFContext | null>(null);
WrapPDFContext.displayName = "WrapPDFContext";

export const WrapPDFProvider: FC = ({children}) => {
  return <WrapPDFContext.Provider value={useWrapPDF()}>{children}</WrapPDFContext.Provider>;
};

export const useContextWrapPDF = () => {
  const context = useContext(WrapPDFContext);

  if (context === null) {
    throw new Error("<<< WrapPDFContext >>> must be used within a WrapPDFProvider");
  }

  return context as tWrapPDFContext;
};
