import {FC, useEffect, useState} from "react";
import sortIcon from "assets/svg/sortIcon.svg";
import {tJobTask} from "api/jobs/tasks/types";
import {InputCell} from "components/shared/InputCell/InputCell";
import {useContextJobTasks} from "pages/Jobs/JobDetails/JobTasks/_context/JobTaskContext";
import "./TaskNameCell.scss";

type tTaskNameCellProps = {
  data: tJobTask;
};

export const TaskNameCell: FC<tTaskNameCellProps> = ({data}) => {
  const [value, setValue] = useState("");
  const {onSubmit, submitting, cellKeyRef, generateKey} = useContextJobTasks();

  useEffect(() => {
    !!data?.name && setValue(data?.name);
  }, [data]);

  const typeApi = "name";
  const currCellKey = generateKey(typeApi, data?.id);
  const isLoading = cellKeyRef?.current === currCellKey && submitting;

  const setName = (value: string) => {
    onSubmit({
      typeApi,
      taskId: data?.id,
      data: {name: value},
      errorCallback: () => setValue(data?.name)
    });
  };

  return (
    <div className="TaskNameCell">
      <img src={sortIcon} alt="sort icon" className="TaskNameCell-sortIcon" draggable="false" />

      <InputCell
        value={value}
        setValue={setValue}
        isLoading={isLoading}
        initValue={data?.name || ""}
        placeholder="Add task name..."
        callBack={val => setName(val)}
      />
    </div>
  );
};
