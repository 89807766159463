import {FC} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {Select} from "components/shared/FormComponents/Select/Select";
import {AddressFieldSearch} from "./AddressFieldSearch";
import {tUseAddressField} from "./useAddressField";
import "./AddressField.scss";

export interface iAddressFieldProps extends tUseAddressField {
  searchLabel?: string;
  searchPlaceholder?: string;
}

export const AddressField: FC<iAddressFieldProps> = ({
  addressData,
  manualEntry,
  changeEntryMethod,
  selectedAddress,
  handleChangeAddress,
  errors,
  isUsaSelected,
  options,
  setOptions,
  address,
  searchLabel,
  searchPlaceholder = "Search address..."
}) => {
  const stateLabel = isUsaSelected ? "State" : "Region";

  return (
    <div className={`AddressField ${manualEntry ? "AddressField-manual" : ""}`}>
      <button type="button" className="AddressField-selectOption" onClick={changeEntryMethod}>
        {manualEntry ? "Search address" : "Manual Entry"}
      </button>

      {!manualEntry && (
        <AddressFieldSearch
          address={address}
          selectedAddress={selectedAddress}
          handleChangeAddress={handleChangeAddress}
          options={options}
          setOptions={setOptions}
          changeEntryMethod={changeEntryMethod}
          errors={errors}
          searchLabel={searchLabel}
          searchPlaceholder={searchPlaceholder}
        />
      )}
      {manualEntry && (
        <>
          <div className="AddressField-apartment">
            <InputField
              {...addressData.street.inputProps}
              errors={addressData.street.errors}
              label="Street address"
            />
            <InputField
              {...addressData.apartment.inputProps}
              errors={addressData.apartment.errors}
              label="Suite/fl/unit"
            />
          </div>

          <div className="AddressField-region">
            <InputField
              {...addressData.city.inputProps}
              errors={addressData.city.errors}
              label="City"
            />
            {isUsaSelected && (
              <Select
                {...addressData.state.inputProps}
                errors={addressData.state.errors}
                label={stateLabel}
                placeholder="Select..."
              />
            )}
            {!isUsaSelected && (
              <InputField
                {...addressData.region.inputProps}
                errors={addressData.region.errors}
                label={stateLabel}
              />
            )}

            <InputField
              {...addressData.post_code.inputProps}
              errors={addressData.post_code.errors}
              label={isUsaSelected ? "zip" : "Postal code"}
            />
          </div>

          <Select
            label="Country"
            {...addressData.country.inputProps}
            errors={addressData.country.errors}
            placeholder="Select..."
          />
        </>
      )}
    </div>
  );
};
