import {tResponseType} from "api/budget/track";
import {routes} from "router/routes";
import {useLocation} from "react-router-dom";

export const useResponseType = () => {
  const {pathname} = useLocation();
  const isExpense = pathname?.includes(routes?.jobs?.details?.budget?.expenseList);
  const response_type: tResponseType = isExpense ? "list" : "groups";

  return {response_type, isExpense};
};
