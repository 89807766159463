import {FC, useState} from "react";
import {Datepicker} from "components/shared/FormComponents/Datepicker/Datepicker";
import {useDatePicker} from "components/shared/FormComponents/Datepicker/useDatePicker";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {getDateFormat} from "helpers/dates";
import {useContextInvoice} from "../../context/InvoiceContext";
import {invoiceApi} from "api/invoice";
import {invoiceStatus} from "../../invoiceStatus";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";

import "./InvoicePay.scss";

interface iInvoicePayProps {
  show: boolean;
  onClose: () => void;
}

export const InvoicePay: FC<iInvoicePayProps> = ({show, onClose}) => {
  const {invoice, setInvoice} = useContextInvoice();
  const {jobDetailsData, updateJobDetails} = useContextJobDetails();

  const {call} = useHttp();
  // const pipelineQuery = useHttp();

  const [isLoading, setIsLoading] = useState(false);

  const date = useDatePicker();

  const onChangeToPaid = async () => {
    if (!invoice || !jobDetailsData?.id) return;
    setIsLoading(true);
    try {
      const {
        data: {message, data}
      } = await call(
        invoiceApi.changeInvoiceStatus(invoice.id, {
          status: invoiceStatus.paid,
          date: getDateFormat(date.value, "timestamp")
        })
      );
      await updateJobDetails();
      setInvoice(data);
      eventBus.dispatch("showToast", {text: message});
      onClose();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {text: msg, type: "error"});
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ConfirmWindow
      className="InvoicePay"
      show={show}
      title="Mark as paid?"
      subTitle="Enter the invoice paid date. This will advance the job’s pipeline to “PAID”"
      onClose={onClose}
      onConfirm={onChangeToPaid}
      isLoading={isLoading}
      confirmBtn={{
        text: "Save"
      }}
      cancelBtn={{
        text: "Cancel"
      }}
    >
      <Datepicker {...date.inputProps} withIcon />
    </ConfirmWindow>
  );
};
