import {Dispatch, SetStateAction, FC} from "react";
import {Icon} from "components/Icon/Icon";
import {Checkbox} from "components/shared/FormComponents/Checkbox/Checkbox";
import "./SenderRecipientHeader.scss";

interface iSenderRecipientHeaderProps {
  id: number;
  name: string;
  isExpanded?: boolean;
  onToggle: Dispatch<SetStateAction<number[]>>;
  data: any;
  onSelectGroup: (event: any, id: number) => void;
  showExpandButton?: boolean;
}

export const SenderRecipientHeader: FC<iSenderRecipientHeaderProps> = ({
  id,
  onToggle,
  isExpanded,
  name,
  data,
  onSelectGroup,
  showExpandButton = true
}) => {
  const handleToggle = () =>
    onToggle(prev => {
      if (prev.includes(id)) {
        return prev.filter(item => item !== id);
      } else {
        return [...prev, id];
      }
    });

  const isChecked = data?.contacts?.every((item: any) => item.selected);

  return (
    <div className="SenderRecipientHeader">
      <Checkbox
        label={name}
        checked={isChecked}
        onChange={event => onSelectGroup(event, data?.id)}
      />
      {showExpandButton && (
        <button type="button" className="SenderRecipientHeader-button" onClick={handleToggle}>
          <Icon icon={isExpanded ? "triangle-up" : "triangle-down"} size="xsm" />
        </button>
      )}
    </div>
  );
};
