import {FC, useState} from "react";
import {tExpenseLineFileData} from "api/budget/track/types";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {useExpenseLineFile} from "./useExpenseLineFile";
import {AttachFile} from "components/AttachFIle/AttachFile";
import {Loading} from "components/shared/Loading/Loading";
import doc from "assets/images/Doc.svg";
import {FIle} from "pages/Jobs/JobDetails/JobBudget/Track/_modals/File/FIle";
import "./AttachFileWrap.scss";

type tAttachFileWrapProps = {
  className?: string;
  id: number;
  placeholder?: string;
  file: any;
  file_type: tExpenseLineFileData["file_type"];
  blockAdd?: boolean;
};

export const AttachFileWrap: FC<tAttachFileWrapProps> = ({
  className = "",
  id,
  placeholder,
  file,
  file_type,
  blockAdd
}) => {
  const {attachFile, isLoading} = useExpenseLineFile();
  const [modal, setModal] = useState(false);
  const closeModal = () => setModal(false);

  const onAddFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    attachFile(e, id, file_type);
  };

  // const onSaveImageChanges = async (file: any) => {
  //   await attachFile(file, id, file_type, true);
  // };

  const isFile = Array.isArray(file) ? !!file?.length : !!file;
  if (blockAdd) return <div className="AttachFileWrap" />;

  return (
    <TextComponent className={`AttachFileWrap ${className} posRelative`}>
      {isLoading && <Loading type="inBlock" customSize={0.5} />}

      {!isFile && (
        <AttachFile
          inputProps={{
            accept: ".jpg, .png, .jpeg, .pdf",
            multiple: file_type === "actual" ? true : false
          }}
          callBack={onAddFile}
          placeholder={placeholder || ""}
        />
      )}

      {isFile && (
        <TextComponent as="button" onClick={() => setModal(true)} className="showModalBtn">
          <img src={doc} alt="attachment" />
        </TextComponent>
      )}

      {modal && (
        <FIle
          selectedFile={file}
          files={file}
          onClose={closeModal}
          typeFile={file_type}
          expenseLineId={id}
          // onSaveChanges={onSaveImageChanges}
        />
      )}
    </TextComponent>
  );
};
