import {FC} from "react";
import {tCrewContact} from "api/jobCrewGroup/types";
import {DeleteContact} from "./DeleteContact";
import {AddAgent} from "./AddAgent";
import {ShowDetailsContact} from "./ShowDetailsContact";
import "./RowActions.scss";

type tRowActionsProps = {
  crewContact: tCrewContact;
  typeRow: "agent" | "contact";
  groupId: number;
};

export const RowActions: FC<tRowActionsProps> = ({crewContact, typeRow, groupId}) => {
  const {agent} = crewContact;

  return (
    <div className="RowActions">
      {!agent && <AddAgent crewContact={crewContact} groupId={groupId} />}
      <ShowDetailsContact crewContact={crewContact} isAgent={typeRow === "agent"} />
      <DeleteContact typeRow={typeRow} crewContact={crewContact} groupId={groupId} />
    </div>
  );
};
