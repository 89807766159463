import {useEffect, useMemo, useState} from "react";
import {iStateOption} from "components/shared/FormComponents/TagsField/TagsField";
import {iUseTagsInput} from "./interfaces";
import {MultiValue} from "react-select";

/**
 * use for one multiple select-search field
 * @param props
 */
export const useTags = (props?: iUseTagsInput) => {
  const [value, setValue] = useState<iStateOption[]>(props?.value || []);
  const [errors, setErrors] = useState<string[]>(props?.errors || []);
  const [validateOnChange, setValidateOnChange] = useState(!!props?.validateOnChange);
  const [isValid, setIsValid] = useState(props?.isValid !== undefined ? props.isValid : true);
  const [isDirty, setIsDirty] = useState(false);
  const [isRequired] = useState(props?.isRequired !== undefined ? props.isRequired : true);
  const emptyErr = props?.emptyErrText ? props?.emptyErrText : "This field can't be empty";

  useEffect(() => {
    const val = (value as iStateOption[]).map(({id}) => id);
    if (!validateOnChange) {
      props?.onChangeCallback?.(val);
      return;
    }
    checkValidity();
    props?.onChangeCallback?.(val);
  }, [value]); // eslint-disable-line

  const checkValidity = (val?: MultiValue<iStateOption>): boolean => {
    const newValue = val !== undefined ? val : value;
    if (isRequired && !newValue.length) {
      setErrors([emptyErr]);
      setIsValid(false);
      return false;
    }
    setIsValid(true);
    setErrors([]);
    return true;
  };
  /**
   * Interceptor to validate input on first blur if user was interacting with it
   * @callback {FocusEventHandler<HTMLInputElement>} onBlur
   * @returns void
   */
  const onBlur = () => {
    if (isDirty && !validateOnChange) {
      setValidateOnChange(true);
      checkValidity();
    }
    props?.onBlurCallback?.();
    setIsDirty(true);
  };

  const onChange = (newValue: any[]) => {
    setValue(newValue);
    !isDirty && setIsDirty(true);
    checkValidity(newValue);
  };

  const validateAndSet = (value: any[]) => {
    // setValue(value);
    setValidateOnChange(true);
    checkValidity(value);
  };

  const isChanged = () => {
    const propArray = props?.value || [];
    const values = value;
    if (!propArray?.length && values?.[0] && !values?.[0]?.id && !values?.[0]?.value) return false;
    if (propArray.length !== values.length) return true;
    return !!values.filter(
      value => !propArray?.some(prop => prop.name === value.name && prop.id === value.id)
    ).length;
  };

  const preparedValues = useMemo(() => value.map(({id}) => Number(id)), [value]);

  return {
    value,
    setValue: validateAndSet,
    preparedValues,

    onChange,
    onBlur,
    errors,
    setErrors,
    isValid,
    setIsValid,
    isDirty,
    setIsDirty,
    isChanged: isChanged(),
    checkValidity,
    validateOnChange,
    setValidateOnChange,

    inputProps: {value, onChange, onBlur}
  };
};
