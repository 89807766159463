import {useEffect, useState} from "react";
import {useScript} from "hooks/useScript";
import {useContextTemplateCalendar} from "../context";
import {createIntervalByDates, tCalendarTemplateEvent} from "../helpers";
import {jobsCalendarApi} from "api/jobs/calendar";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {useHttp} from "hooks/httpServices/useHttp";
import moment from "moment";
import {getDateFormat} from "helpers/dates";

export function useJobScheduler() {
  const eventUpdateQuery = useHttp();

  const {status, removeScript} = useScript(
    "https://cdn.dhtmlx.com/scheduler/edge/dhtmlxscheduler.js"
  );
  const {events, setEvents} = useContextTemplateCalendar();

  const [addEvent, setAddEvent] = useState<
    Partial<tCalendarTemplateEvent> | {start_date: Date} | null
  >(null);
  const [showEventDetails, setShowEventDetails] = useState<tCalendarTemplateEvent | number | null>(
    null
  );
  const [clearEventId, setClearEventId] = useState<number | null>(null);

  useEffect(() => {
    return () => {
      setAddEvent(null);
      setShowEventDetails(null);
      removeScript();
    };
  }, []); // eslint-disable-line

  const handleEmptyClick = (date: Date) => {
    const newDate = {start_date: date};
    setAddEvent(prev => (prev ? {...prev, start_date: date} : newDate));
  };

  const handleBeforeEventChange = async (
    event: tCalendarTemplateEvent,
    e: any,
    is_new: boolean,
    origin: tCalendarTemplateEvent,
    allDay: boolean
  ) => {
    if (is_new) {
      setAddEvent({
        clearId: event.id,
        start_date: event.start_date,
        end_date: event.end_date,
        all_day: allDay
      });
      return;
    }

    await updateEvent(event);
  };

  async function updateEvent(event: tCalendarTemplateEvent) {
    const {name, all_day, end_date, start_date} = event;

    const {start_day, end_day} = createIntervalByDates(event);
    const payload = {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      name,
      all_day,
      description: event.description,
      start_day,
      end_day,
      start_time: getDateFormat(
        all_day ? moment().set({hour: 0, minute: 0, second: 0}) : start_date,
        "timestamp"
      ),
      end_time: getDateFormat(
        all_day ? moment(end_date).endOf("day").add(1, "minute") : end_date,
        "timestamp"
      )
    };

    try {
      const {
        data: {data}
      } = await eventUpdateQuery.call(
        jobsCalendarApi.updateTemplateEvent(event.id, payload as any)
      );

      setEvents(prev =>
        prev.map(ev => {
          if (ev.id === data.id) {
            return {
              ...event,
              ...createIntervalByDates(data)
            };
          }
          return ev;
        })
      );
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {text: msg, type: "error"});
    }
  }

  const handleCloseEventEdit = (id?: number) => {
    id && setClearEventId(id);
    setAddEvent(null);
  };

  const onEditEvent = (event: tCalendarTemplateEvent) => {
    setAddEvent(event);
  };

  return {
    events,
    showEventDetails,
    setShowEventDetails,
    handleCloseEventEdit,
    setEvents,
    setClearEventId,
    clearEventId,
    status,
    handleBeforeEventChange,
    handleEmptyClick,
    addEvent,
    onEditEvent
  };
}
