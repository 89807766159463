import {CSSProperties, FC, useState} from "react";
import {tUseChangeExpenseValue, useChangeExpenseValue} from "../../../_common/useChangeExpenseValue"; // prettier-ignore
import {InputCell, tInputCellProps} from "components/shared/InputCell/InputCell";

type tETInputCell = {
  id: number;
  value: string;
  type: tUseChangeExpenseValue["type"];
  className?: string;
  placeholder?: string;
  prefix?: string;
  style?: CSSProperties;
  inputType?: tInputCellProps["InputComponent"];
  manySeparator?: boolean;
};

export const ETInputCell: FC<tETInputCell> = props => {
  const {id, value: initValue, type, className = "", placeholder, prefix, style, inputType, manySeparator} = props; // prettier-ignore

  const [value, setValue] = useState(initValue || "");
  const {isLoading, onChange} = useChangeExpenseValue({
    id: id,
    type,
    errorCallback: () => setValue(initValue || "")
  });

  return (
    <InputCell
      initValue={initValue || ""}
      value={value}
      setValue={setValue}
      isLoading={isLoading}
      placeholder={placeholder}
      callBack={val => onChange(val)}
      className={`${className}`}
      alwaysShowPlaceholder
      withBorder
      style={style}
      InputComponent={inputType}
      prefix={prefix}
      manySeparator={manySeparator}
    />
  );
};
