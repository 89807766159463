import {tSchedulerEvent} from "../JobScheduler";
import {renderToString} from "react-dom/server";
import {EventWeekBarText, SchedulerBarTime, SchedulerEventBar} from "../SchedularCommons";
import moment from "moment/moment";
import {tCalendarTemplateEvent} from "../../helpers";

export function jobSchedulerTemplate(
  scheduler: any,
  events: tCalendarTemplateEvent[],
  initMode: string
) {
  if (events) {
    scheduler.templates.tooltip_text = (start: Date, end: Date, event: tSchedulerEvent) => {
      return `<div class="SchedulerDashboard-tooltip">${event.name}</div>`;
    };
    scheduler.templates.event_bar_text = (start: Date, end: Date, event: any) => {
      return renderToString(<SchedulerEventBar event={event} />);
    };
    scheduler.templates.event_bar_date = (start: Date, end: Date, event: any) => {
      return renderToString(<SchedulerBarTime event={event} />);
    };

    scheduler.templates.event_header = (start: any, end: any, ev: any) => {
      return ev?.name || "Event name";
    };
    scheduler.templates.event_text = (start: any, end: any) => {
      return renderToString(<EventWeekBarText start={start} end={end} />);
    };
    scheduler.templates.hour_scale = (date: any) => {
      if (moment)
        return renderToString(<div className="week-hours-scale">{moment(date).format("h A")}</div>);
    };
  }

  scheduler.date.add_agenda = (date: any, inc: any) => {
    return scheduler.date.add(date, inc, "month");
  };

  scheduler.templates.week_date_class = () => "week-date-class";
  scheduler.config.header = [initMode];

  scheduler.clearAll();
  scheduler.updateView();
  scheduler.parse(events);
}
