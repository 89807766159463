import {FC, Fragment} from "react";
import {StyleSheet, Text, View} from "@react-pdf/renderer";
import {getUsMoneyFormat} from "helpers/formattingData";
import {SectionTitle} from "../../PdfTemplates/a4/SectionTitle";
import {tInvoice} from "api/invoice/types";

interface iProductionCostsProps {
  invoice: tInvoice;
}

export const InvoiceTotals: FC<iProductionCostsProps> = ({invoice}) => {
  const {
    production,
    actual_production_costs,
    green,
    final_adjustment,
    sales_tax,
    insurance,
    advances
  } = invoice;
  return (
    <View style={styles.container}>
      <SectionTitle>Invoice totals</SectionTitle>
      <View style={styles.row}>
        <Text style={styles.label}>Actual Production Costs</Text>
        <Text style={styles.value}>{getUsMoneyFormat(actual_production_costs)}</Text>
      </View>
      <View style={styles.gutterRow}>
        <Text style={styles.label}>Production % on Budgeted Expenses</Text>
        <Text style={styles.value}>{getUsMoneyFormat(production)}</Text>
      </View>
      <View style={styles.gutterRow}>
        <Text style={styles.label}>Insurance % on Budgeted Expenses</Text>
        <Text style={styles.value}>{getUsMoneyFormat(insurance)}</Text>
      </View>
      <View style={styles.gutterRow}>
        <Text style={styles.greenLabel}>Green</Text>
        <Text style={styles.value}>{getUsMoneyFormat(green)}</Text>
      </View>
      <View style={styles.gutterRow}>
        <Text style={styles.label}>Sales Tax</Text>
        <Text style={styles.value}>{getUsMoneyFormat(sales_tax)}</Text>
      </View>

      {advances.map(
        ({
          id,
          invoice_number,
          late_payment_days,
          late_payment_amount,
          amount_required,
          late_payment_fees,
          late_payment_daily
        }) => {
          return (
            <Fragment key={id}>
              <View style={styles.row}>
                <Text
                  style={styles.label}
                >{`Advance Payment on Invoice No. ${invoice_number}`}</Text>
                <Text style={styles.value}>{getUsMoneyFormat(amount_required)}</Text>
              </View>
              {late_payment_fees && (
                <View style={styles.gutterRow}>
                  <Text style={styles.label}>
                    Late Payment Fee, {late_payment_days} days @{" "}
                    {getUsMoneyFormat(late_payment_daily)}
                  </Text>
                  <Text style={styles.value}>{getUsMoneyFormat(late_payment_amount)}</Text>
                </View>
              )}
            </Fragment>
          );
        }
      )}

      <View style={styles.row}>
        <Text style={styles.label}>Final Adjustments</Text>
        <Text style={styles.value}>{getUsMoneyFormat(final_adjustment)}</Text>
      </View>
      <View style={styles.totalRow}>
        <Text style={styles.totalRowTitle}>Invoice total</Text>
        <Text style={styles.totalRowValue}>USD {getUsMoneyFormat(invoice.invoice_total)}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  row: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8
  },
  gutterRow: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 8,
    paddingLeft: 16
  },
  label: {
    fontSize: 12,
    fontFamily: "Helvetica500"
  },
  greenLabel: {
    color: "#029457",
    fontSize: 12,
    fontFamily: "Helvetica500"
  },
  value: {
    fontSize: 12
  },
  totalRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 8,
    marginBottom: 16
  },
  totalRowTitle: {
    fontFamily: "Helvetica700",
    fontSize: 18,
    textTransform: "uppercase"
  },
  totalRowValue: {
    fontFamily: "Helvetica700",
    fontSize: 18,
    textAlign: "right",
    width: "250px"
  }
});
