import {FC, useEffect} from "react";
import {Button} from "components/shared/Button/Button";
import {Select} from "components/shared/FormComponents/Select/Select";
import {useIsFirstRender} from "hooks/useIsFirstRender";
import {useContextTeam} from "pages/Team/TeamContext";
import {useFilterInputs} from "./useFilterInputs";
import {tTeamListParams} from "api/team/types";
import {FilterLayout} from "components/FilterLayout/FilterLayout";

export const Filter: FC = () => {
  const {setApiParams, clearAllParams} = useContextTeam();
  const isFirstRender = useIsFirstRender();
  const {filters} = useFilterInputs();
  const {role_id, departments, status_id} = filters;

  useEffect(() => {
    if (isFirstRender) return;
    const apiParams: Partial<Record<keyof tTeamListParams, string | string[]>> = {};
    Object.entries(filters).forEach(([key, item]) => {
      const currKey = key as keyof tTeamListParams;
      item.selected && (apiParams[currKey] = String(item.selected.id));
      !item.selected && (apiParams[currKey] = apiParams[currKey] = "");
    });

    setApiParams({params: apiParams, removePagination: true});
  }, [departments.value, role_id.value, status_id.value]); // eslint-disable-line

  const clear = () => {
    clearAllParams();
    Object.entries(filters).forEach(([_, item]) => item.clearAll());
  };

  return (
    <FilterLayout>
      <div className="label">Department</div>
      <Select {...departments.inputProps} canSelectEmpty={true} />

      <div className="label">Role</div>
      <Select {...role_id.inputProps} canSelectEmpty={true} />

      <div className="label">Status</div>
      <Select {...status_id.inputProps} canSelectEmpty={true} />

      <Button size="sm" color="light" onClick={clear}>
        Clear Filters
      </Button>
    </FilterLayout>
  );
};
