import {FC, useRef} from "react";
import {tTooltipPages, tTooltipTypes} from "./tooltips";
import {Button} from "components/shared/Button/Button";
import {useHideTooltip} from "./useHideTooltip";
import {CloseTooltipButtons} from "./CloseTooltipButtons";
import {createPortal} from "react-dom";
import "./AppTooltips.scss";

export type tAppTooltipsProps = {
  page: tTooltipPages;
  tooltip: tTooltipTypes;
  className?: string;
  chevronPosition?: "topLeft" | "topRight" | "bottomRight" | "bottomLeft";
  positionStyles?: Object;
  withAnimation?: boolean;
};

export const AppTooltips: FC<tAppTooltipsProps> = ({
  page,
  tooltip,
  className = "",
  chevronPosition = "topLeft",
  positionStyles,
  withAnimation
}) => {
  const {onCloseTooltip, isLoading, currPage, hiddenTooltips} = useHideTooltip({page, tooltip});

  const isSingleTooltipOnPage = currPage?.length === 1;
  const currTooltip = currPage?.find(item => item?.title === tooltip);
  const currTooltipIndex = currPage?.findIndex(item => item?.title === tooltip);
  const isLastStep = currPage?.length - 1 === currTooltipIndex;

  const isHiddenTooltip = hiddenTooltips?.find(item => item === currTooltip?.title);

  const prevItem = currPage[currTooltipIndex - 1];
  const isHiddenPrevItem = hiddenTooltips?.find(item => item === prevItem?.title);

  if (!!isHiddenTooltip) return null;
  if (!!prevItem && !isHiddenPrevItem) return null;

  const anim = withAnimation ? "withAnimation" : "";
  const component = (
    <div className={`AppTooltips ${chevronPosition} ${anim} ${className}`} style={positionStyles}>
      <div className="AppTooltips-header">
        <h3 className="AppTooltips-title">{currTooltip?.title}</h3>

        <CloseTooltipButtons
          isLoading={isLoading}
          isLastStep={isLastStep}
          onCloseTooltip={onCloseTooltip}
          isSingleTooltipOnPage={isSingleTooltipOnPage}
        />
      </div>

      <p className="AppTooltips-body">{currTooltip?.body}</p>

      {!isSingleTooltipOnPage && (
        <div className="AppTooltips-footer">
          {currPage?.map((item, i) => {
            const active = tooltip === item?.title ? "active" : "";
            return <div key={i} className={`AppTooltips-step ${active}`} />;
          })}

          {!isLastStep && (
            <Button className="AppTooltips-skipAllBtn" onClick={() => onCloseTooltip("all")}>
              skip all
            </Button>
          )}
        </div>
      )}
    </div>
  );

  const isPositionStyles = !!positionStyles && !!Object.keys(positionStyles)?.length;
  return (
    <>
      {!isPositionStyles && component}
      {!!isPositionStyles && createPortal(component, document.body)}
    </>
  );
};
