import {FC, FormEvent} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {jobTasksApi} from "api/jobs/tasks";
import {useContextJobTasks} from "../_context/JobTaskContext";
import {useHttp} from "hooks/httpServices/useHttp";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {notValidForm} from "helpers/misc";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";

type tRenameTasksGroupProps = {
  onClose: () => void;
  name: string;
  id: number;
};

export const RenameTasksGroup: FC<tRenameTasksGroupProps> = ({onClose, name, id}) => {
  const {setTasks} = useContextJobTasks();
  const {call, isLoading} = useHttp();
  const FORM_ID = "rename-job-task-group-form-id";

  const form = {
    name: useTextInput({value: name})
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (notValidForm(form) || !id) return;

    try {
      const res = await call(jobTasksApi.renameGroup(id, {name: form.name.value}));
      onClose();
      setTasks(res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <ConfirmWindow
      show={true}
      title="Rename group"
      cancelBtn={{text: "Cancel", type: "button"}}
      onClose={onClose}
      confirmBtn={{text: "rename", type: "submit", form: FORM_ID}}
      isLoading={isLoading}
    >
      <form onSubmit={onSubmit} id={FORM_ID}>
        <InputField {...form.name.inputProps} errors={form.name.errors} />
      </form>
    </ConfirmWindow>
  );
};
