import {FC} from "react";
import {UnderConstruction} from "components/shared/UnderConstruction/UnderConstruction";
import {Button} from "components/shared/Button/Button";
import {useContextJobDetails} from "../../context/JobDetailsContext/JobDetailsContext";
import "./JobSchedule.scss";

interface iJobScheduleProps {}

export const JobSchedule: FC<iJobScheduleProps> = () => {
  const {removeSection, removeSectionQuery} = useContextJobDetails();

  return (
    <div className="JobSchedule">
      <UnderConstruction />
      <Button
        className="EmptyPageState-remove"
        onClick={() => removeSection()}
        disabled={removeSectionQuery.isLoading}
      >
        Remove schedule section
      </Button>
    </div>
  );
};
