import {FC, useState} from "react";
import {Loading} from "components/shared/Loading/Loading";
import {usePipelineSelection} from "./usePipelineSelection";
import {iJobDetailsDataReturn} from "api/jobs/types";
import {Popover} from "components/shared/Popover/Popover";
import {PipelineCalendar} from "./PipelineCalendar/PipelineCalendar";

import "./PipelineSelection.scss";

interface iPipelineSelection {
  jobData: any | null;
  onSelectedPipelineCallback?: (job?: iJobDetailsDataReturn) => void;
}

export const PipelineSelection: FC<iPipelineSelection> = ({
  jobData,
  onSelectedPipelineCallback
}) => {
  const [selectionRef, setSelectionRef] = useState<HTMLDivElement | null>(null);

  const {
    pipelines,
    isLoading,
    onChangePipeLine,
    activePipeline,
    setActivePipeline,
    onSavePipelineDate,
    isLoadingCalendar,
    selectionLoading
  } = usePipelineSelection({
    jobData,
    onSelectedPipelineCallback
  });

  return (
    <div className="PipelineSelection" ref={ref => setSelectionRef(ref)}>
      {(!pipelines || isLoading) && <Loading type="inBlock" />}
      {!!pipelines?.length &&
        pipelines.map(item => {
          return (
            <div key={item.id} className="PipelineSelection-column">
              <span className="PipelineSelection-header">{item.name}</span>
              {item.pipelines.map(item => (
                <button
                  key={item.id}
                  disabled={!!selectionLoading}
                  className="PipelineSelection-item"
                  onClick={() => onChangePipeLine(item.id, item)}
                >
                  {selectionLoading === item.id && <Loading type="inBlock" />}
                  {item.name}
                </button>
              ))}
            </div>
          );
        })}

      <Popover
        anchor={!!activePipeline ? selectionRef : null}
        onClose={() => setActivePipeline(null)}
        bodyStyles={{
          overflow: "visible"
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
      >
        <PipelineCalendar
          name={activePipeline?.name}
          dueDate={jobData?.pipeline_due_date}
          endDate={jobData?.end_at}
          onSave={onSavePipelineDate}
          onCancel={() => setActivePipeline(null)}
          isLoading={isLoadingCalendar}
        />
      </Popover>
    </div>
  );
};
