import {FC} from "react";
import {ChapterHeader} from "../../../OverlayTemplates/ChapterHeader/ChapterHeader";
import {tEstimateData} from "api/budget/estimate/types";
import {getUsMoneyFormat, getPercentFormat} from "helpers/formattingData";

import "./EstimateTotal.scss";

interface iEstimateTotalProps {
  estimate: tEstimateData;
}

export const EstimateTotal: FC<iEstimateTotalProps> = ({estimate}) => {
  const {
    production_total,
    green,
    sales_tax,
    sales_tax_percent,
    grand_total,
    advance_required_percent,
    advance_required,
    budgeted_costs,
    insurance
  } = estimate;

  //  const getExpenseOnPercent = (percent: number) => getUsMoneyFormat((budgeted_costs * percent) / 100); // prettier-ignore

  return (
    <div className="EstimateTotal">
      <ChapterHeader>Total</ChapterHeader>
      <table>
        <tbody>
          <tr>
            <td>Estimated Production Costs</td>
            <td>{getUsMoneyFormat(budgeted_costs)}</td>
          </tr>
          <tr>
            <td>Production % on Budgeted Expenses</td>
            <td>{getUsMoneyFormat(production_total)}</td>
          </tr>
          <tr>
            <td>Insurance % on Budgeted Expenses</td>
            <td>{getUsMoneyFormat(insurance)}</td>
          </tr>
          <tr className="green">
            <td>Green</td>
            <td>{getUsMoneyFormat(green)}</td>
          </tr>
          <tr>
            <td>Sales Tax ({getPercentFormat(sales_tax_percent)})</td>
            <td>{getUsMoneyFormat(sales_tax)}</td>
          </tr>
          <tr className="total">
            <td>TOTAL ESTIMATE</td>
            <td>USD {getUsMoneyFormat(grand_total)}</td>
          </tr>
          <tr>
            <td>Total Advance Required ({getPercentFormat(advance_required_percent)})</td>
            <td>{getUsMoneyFormat(advance_required)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
