import {FC, useState} from "react";
import {useSetApiParamsReturn} from "hooks/useSetApiParams";
import "./FilterButtons.scss";

interface iFilterButtons {
  apiParams: useSetApiParamsReturn;
  isLoading?: boolean;
}

const buttons = ["active", "done", "all"];

export const FilterButtons: FC<iFilterButtons> = ({apiParams, isLoading}) => {
  const [activeItem, setActiveItem] = useState("all");

  const onChangeParams = (type: string) => {
    const paramsValue: Record<typeof type, string | null> = {
      all: null,
      done: "1",
      active: "0"
    };

    const params = {done: paramsValue[type]};
    apiParams.setApiParams({params});
    setActiveItem(type);
  };

  return (
    <div className="FilterButtons button-group">
      {buttons.map(title => (
        <button
          key={title}
          onClick={() => onChangeParams(title)}
          className={`FilterButtons-btn ${activeItem === title ? "active" : ""} `}
          disabled={isLoading}
        >
          {title}
        </button>
      ))}
    </div>
  );
};
