import React, {useState} from "react";

type tUseAdjustWidth = {
  initWidth: number;
  minWidthVal: number;
  className?: string;
};

export type tReturnTypeUseAdjustWIdth = ReturnType<typeof useAdjustWidth>;

export const useAdjustWidth = ({
  initWidth,
  minWidthVal,
  className = "adjustingWidth"
}: tUseAdjustWidth) => {
  const [width, setWidth] = useState(initWidth);
  const minWidth = minWidthVal;

  const resizeHandler = (
    mouseDownEvent: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    initWidth?: number | undefined
  ) => {
    if (!initWidth) return;
    const startSize = initWidth;
    const startPosition = mouseDownEvent.pageX;

    document?.body?.classList.add(className);
    document.body.style.cursor = "col-resize";
    document.body.style.userSelect = "none";

    function onMouseMove(mouseMoveEvent: MouseEvent) {
      const newWidth = startSize - startPosition + mouseMoveEvent.pageX;

      if (newWidth < minWidth) return setWidth(minWidth);
      setWidth(newWidth);
    }

    function onMouseUp() {
      document.body.removeEventListener("mousemove", onMouseMove);
      document.body.style.cursor = "default";
      document.body.style.userSelect = "auto";
      document?.body?.classList.remove(className);
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, {once: true});
  };

  return {resizeHandler, width, minWidth, setWidth};
};
