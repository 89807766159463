import {FC, useEffect, useState} from "react";
import {TableHeadItem} from "components/Table/TableHeadItem";
import {useOffsetTop} from "hooks/useOffsetTop";
import {useContextTrack} from "../../_context/TrackContext";
import {tableTrackWAColumns} from "./tableTrackWAHeadItems";
import {Category} from "./Category/Category";
import {Loading} from "components/shared/Loading/Loading";
import {CategorizedHeader} from "./CategorizedHeader/CategorizedHeader";
import {GrandTotal} from "./GrandTotal/GrandTotal";
import {OtherFees} from "./Fees/OtherFees";
import {PayrollFees} from "./Fees/PayrollFees";
import "./TableTrackWA.scss";

export const TableTrackWA: FC = () => {
  const {trackData, isLoading} = useContextTrack();
  const {elementRef, offsetTop} = useOffsetTop();
  const [showFees, setFeesVisibility] = useState(false);
  const togglePayroll = () => setFeesVisibility(prev => !prev);

  const [dif, setDif] = useState<number | null>(null);
  useEffect(() => {
    //FIXME need to improve
    setTimeout(() => {
      const tf = document?.getElementById("tb-2");
      if (!!elementRef?.current && tf?.clientHeight) {
        const dif = elementRef?.current?.clientHeight - (tf?.clientHeight + 40);
        setDif(dif < 0 ? dif : null);
      }
    }, 0);
  }, [showFees, trackData]); // eslint-disable-line

  const renderHeaderList = () =>
    tableTrackWAColumns.map(item => <TableHeadItem key={item.id} data={item} />);

  const renderBodyList = () => {
    return (
      <>
        <CategorizedHeader />

        {trackData?.categories?.map((track, i) => {
          return <Category key={track?.category?.id} index={i} track={track} />;
        })}
      </>
    );
  };

  return (
    <div className="TableTrackWA" ref={elementRef} style={{height: `calc(100vh - ${offsetTop}px)`}}>
      {isLoading && <Loading type="inBlock" />}

      <table className={`Table TrackWaTable`}>
        <thead>
          <tr className="Table-header-row">{renderHeaderList()}</tr>
        </thead>
        <tbody className="Table-body">{renderBodyList()}</tbody>

        {showFees && (
          <tbody className="Table-body2" id={"tb-2"} style={{bottom: dif + "px"}}>
            <PayrollFees trackData={trackData} />
            <OtherFees trackData={trackData} />
          </tbody>
        )}

        <tfoot className="Table-footer" id="table-footer">
          <GrandTotal togglePayroll={togglePayroll} data={trackData?.grand_total} />
        </tfoot>
      </table>

      {/* <Table
        className={`TrackWaTable`}
        headerRowChildren={renderHeaderList()}
        bodyChildren={renderBodyList()}
        footerChildren={
          <>
            {showFees && <PayrollFees trackData={trackData} />}
            {showFees && <OtherFees trackData={trackData} />}
            <GrandTotal togglePayroll={togglePayroll} data={trackData?.grand_total} />
          </>
        }
      /> */}
    </div>
  );
};
