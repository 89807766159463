import {FC, useEffect} from "react";
import {estimateApi} from "api/budget/estimate";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextJobBudget} from "pages/Jobs/JobDetails/JobBudget/context/JobBudgetContext";
import {useTextInput} from "hooks/useTextInput/useTextInput";

type tGlobalPercentProps = {
  [key: string]: any;
};

export const GlobalPercent: FC<tGlobalPercentProps> = () => {
  const {estimate, setEstimate} = useContextJobBudget();
  const {call, isLoading} = useHttp();

  const form = {
    percent: useTextInput({
      value: String(estimate?.global_production_percent) || "",
      filters: ["percent"]
    })
  };

  useEffect(() => {
    form.percent.setValue(
      estimate?.global_production_percent ? String(estimate?.global_production_percent) : ""
    );
  }, [estimate?.global_production_percent]); // eslint-disable-line

  const updatePercent = async () => {
    if (estimate?.global_production_percent === +form.percent.value || !estimate?.id) return;

    try {
      const res = await call(
        estimateApi.updateTotalDetails(estimate?.id, {
          global_production_percent: +form.percent.value
        })
      );
      setEstimate(() => res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key === "Enter" && updatePercent();
  };

  return (
    <InputField
      typeField="horizontalLabel"
      label={"Global production %"}
      {...form.percent.inputProps}
      placeholder="15.0%"
      suffix="%"
      errors={form.percent.errors}
      onBlur={updatePercent}
      onKeyPress={onKeyPress}
      isLoading={isLoading}
    />
  );
};
