import {useEffect, useState} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {useSetApiParams} from "hooks/useSetApiParams";
import {jobsApi} from "api/jobs";
import {iJobReturn} from "api/jobs/types";
import {useIsFirstRender} from "hooks/useIsFirstRender";
import {useLocation} from "react-router-dom";
import {routes} from "router/routes";
import {useContextBasicData} from "context/BasicDataContext/BasicDataContext";

export const useJobsList = () => {
  const {departments, pipelines, getData} = useContextBasicData();
  const apiParams = useSetApiParams();
  const {pathname} = useLocation();
  const {call, data, ...props} = useHttp();

  const [clientsOptions, setClientsOptions] = useState<any[]>([]);
  const [artistsOptions, setArtistsOptions] = useState<any[]>([]);

  const [labelsOptions, setLabelsOptions] = useState<any[]>([]);
  const [localesOptions, setLocalesOptions] = useState<any[]>([]);

  useEffect(() => {
    getJobsList();
    getData("departments");
    getData("pipelines");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isFirst = useIsFirstRender();
  const notMainJobPage = pathname?.includes(routes?.jobs?.main + "/");

  useEffect(() => {
    if (isFirst || notMainJobPage) return;
    getJobsList();
  }, [apiParams?.searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const getJobsList = async () => {
    if (notMainJobPage) {
      return await call(jobsApi.getJobs());
    }

    const favorite = apiParams?.preparedParams?.favorite;
    let nextParams = {};
    let arrayParams = ["departments", "pipelines", "people", "companies", "artists", "labels", "locales"]; // prettier-ignore

    Object.entries(apiParams?.preparedParams).forEach(([key, value]) => {
      if (arrayParams.includes(key)) {
        nextParams = {...nextParams, [key]: value?.split(",").map((item: any) => Number(item))};
        return;
      }
      nextParams = {...nextParams, [key]: value};
    });

    const params = {
      ...nextParams,
      ...(apiParams?.preparedParams?.favorite && {favorite})
    };

    await call(jobsApi.getJobs(params));
  };

  return {
    jobsListData: data?.data?.data as iJobReturn[],
    getJobsList,
    data,
    apiParams,
    departments,
    pipelines,
    clientsOptions,
    setClientsOptions,
    artistsOptions,
    setArtistsOptions,
    labelsOptions,
    setLabelsOptions,
    localesOptions,
    setLocalesOptions,
    ...props
  };
};
