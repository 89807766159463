import {ChangeEvent, useState} from "react";
import {tContactsType} from "../HeaderButtons/HeaderButtons";
import {people as peopleApi} from "api/contacts/people/index";
import {companies as companiesApi} from "api/contacts/companies/index";
import {useHttp} from "hooks/httpServices/useHttp";
import {parseCsv} from "helpers/parsers/parseCsv";
import {fields} from "api/fields";
import {eventBus} from "EventBus/EventBus";
import {iField} from "api/fields/types";
// import {parseVcf} from "helpers/parsers/parseVcf";
import {vCardParse as parseVcf} from "helpers/parsers/vCardParser/vCardParse";

export interface iUseImportModal {
  type: tContactsType;
  onClose: () => void;
  onUpdateList?: () => void;
}

export const useImportModal = ({type, onClose, onUpdateList}: iUseImportModal) => {
  const {call} = useHttp();
  const fieldsQuery = useHttp();

  const [contacts, setContacts] = useState<any[][]>([]);
  const [filename, setFilename] = useState("");
  const [sizeWarning, setSizeWarning] = useState("");
  const [resultStatus, setResultStatus] = useState<{
    status: "success" | "error" | null;
    message: JSX.Element | string;
  }>({
    status: null,
    message: ""
  });
  const importRequest = {
    people: peopleApi.importPeopleContacts,
    companies: companiesApi.importCompaniesContacts
  };

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any | null>(null);

  const parsers = {
    "text/csv": parseCsv,
    "text/x-vcard": parseVcf,
    "text/vcard": parseVcf
  };

  const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const {
      data: {data}
    } = await fieldsQuery.call(fields.getFields(type === "companies" ? "company" : "person"));
    getCsvFromFile(event, data);
  };

  const getCsvFromFile = (event: ChangeEvent<HTMLInputElement>, customFields: iField) => {
    setErrors(null);
    setResultStatus({message: "", status: null});
    const file: File | undefined = event.target.files?.[0];
    if (!file) return new Error("File doesn't exist");
    const reader: FileReader = new FileReader();
    const fileParser: Function = parsers[file?.type as keyof typeof parsers];

    setFilename(file.name);

    reader.onload = e => {
      const nextArray: {
        data: any[];
        errorMessage: string;
        errors: any;
      } = fileParser?.(e?.target?.result as string, type, customFields);
      const status = nextArray?.data?.length ? "success" : "error";
      setResultStatus({status, message: nextArray?.errorMessage || statusMessages[status]});
      let chunks: any[][] = [];
      while (nextArray?.data.length > 0) {
        const chunk = nextArray?.data.splice(0, 100);
        chunks = [...chunks, chunk];
      }
      if (nextArray?.errors && !!Object.keys(nextArray?.errors).length) {
        setErrors(nextArray?.errors);
      }

      // if (chunks?.length > 2) {
      //   setSizeWarning("The import file is too big. Importing can take some time.");
      // }
      setContacts(chunks);
      event.target.value = "";
    };
    reader.readAsText(file);
  };

  const onImport = async () => {
    setIsLoading(true);
    setErrors(null);
    let currentChunk: any = null;
    try {
      const requestConfig = importRequest[type];
      for await (const [index, value] of contacts.entries()) {
        currentChunk = value;
        await call(requestConfig(value));
        setProgress(Math.ceil((100 / contacts.length) * (index + 1)));
      }
      eventBus.dispatch("showToast", {text: "Contacts have been imported successfully."});
      onUpdateList?.();
      handleClose();
    } catch (error: any) {
      if (error?.response?.status === 500) {
        eventBus.dispatch("showToast", {type: "error", text: error?.response?.statusText});
      } else {
        // mostly works for 422 code
        // and happen when file has some problems with data
        const errors = parseImportRequestErrors(error, currentChunk);
        setResultStatus({status: "error", message: <div>{errors}</div>});
        eventBus.dispatch("showToast", {type: "error", text: error?.response?.data?.message});
      }
      setContacts([]); // reset parsed data
      setFilename(""); // reset uploaded file
      setSizeWarning("");
    } finally {
      setIsLoading(false);
      setProgress(0);
      // onUpdateList?.();
    }
  };

  function parseImportRequestErrors(error: any, currentChunk: any): JSX.Element[] {
    return Object.entries(error?.response?.data?.errors).map(([key, value]: any) => {
      const index = key.split(".")[0];
      const contactRecord = currentChunk ? currentChunk[index] : "";
      let name =
        contactRecord &&
        (contactRecord?.name || contactRecord?.first_name || "" + contactRecord?.last_name || "");
      return (
        <p>
          Record with name <strong>{name}</strong> has error: {value}
        </p>
      );
    });
  }
  const handleClose = () => {
    setContacts([]);
    setFilename("");
    setResultStatus({message: "", status: null});
    setSizeWarning("");
    onClose();
    setProgress(0);
    setErrors(null);
  };

  return {
    handleClose,
    onImport,
    contacts,
    setContacts,
    onChange,
    isLoading,
    statusMessages,
    filename,
    resultStatus,
    sizeWarning,
    setSizeWarning,
    progress,
    setProgress,
    errors,
    setErrors,
    isLoadingFields: fieldsQuery.isLoading
  };
};

const statusMessages = {
  success: "The file has been successfully parsed and prepared for upload.",
  error: "Error! Something went wrong. The file wasn't uploaded."
};
