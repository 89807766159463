import {FC} from "react";
import {tJobTask} from "api/jobs/tasks/types";
import {useDatePicker} from "components/shared/FormComponents/Datepicker/useDatePicker";
import {getDateFormat} from "helpers/dates";
import {DateCell} from "../_common/DateCell";
import {wasDateChanges} from "../_common/wasDateChanges";
import {useContextJobTasks} from "../../../_context/JobTaskContext";

type tReminderCellProps = {
  data: tJobTask;
};

export const ReminderCell: FC<tReminderCellProps> = ({data}) => {
  const {onSubmit, submitting, cellKeyRef, generateKey} = useContextJobTasks();
  const formData = {
    date: useDatePicker({initValue: data?.reminder || "", isRequired: true, withPlaceholder: true})
  };

  const typeApi = "reminder";
  const currCellKey = generateKey(typeApi, data?.id);
  const isLoading = cellKeyRef?.current === currCellKey && submitting;

  const setRemainder = async () => {
    const initDate = data?.reminder;
    const value = formData?.date?.value;
    if (!data?.id || wasDateChanges(initDate, value)) return;

    const date = getDateFormat(formData?.date?.value, "timestamp");
    onSubmit({taskId: data?.id, typeApi, data: {date}});
  };

  return (
    <DateCell
      date={data?.reminder || ""}
      {...formData.date.inputProps}
      dateFormat="MMM D, LT"
      placeholder="SET REMINDER..."
      errors={formData.date.errors}
      onSubmit={setRemainder}
      showTimeInput
      isLoading={isLoading}
      formData={formData}
    />
  );
};
