import {FC} from "react";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {Button} from "components/shared/Button/Button";
import {useAdvanceManagement} from "./useAdvanceManagement";
import {Icon} from "components/Icon/Icon";
import {AdvanceRename} from "../modals/AdvanceRename";
import {AdvanceDelete} from "../modals/AdvanceDelete";
import {useContextAdvance} from "../context";

import "./AdvanceManagement.scss";

interface iAdvanceManagementProps {}

export const AdvanceManagement: FC<iAdvanceManagementProps> = () => {
  const {advanceDetails} = useContextAdvance();
  const {anchor, toggleMenu, modal, onClose, setModal, onChangeToSent, onRemoveStatus} =
    useAdvanceManagement();

  const modalRender = {
    rename: <AdvanceRename onClose={onClose} />,
    delete: <AdvanceDelete onClose={onClose} />
  };

  const isPaid = advanceDetails?.paid_date;
  const isSent = advanceDetails?.sent_date;

  return (
    <>
      {modal && modalRender[modal]}
      <div className="AdvanceManagement">
        <Button color="outline" size="sm" onClick={toggleMenu}>
          <Icon icon="menu" size="xsm" />
        </Button>
        <DropdownMenu
          className="AdvanceManagement-dropdown"
          anchor={anchor}
          onClose={() => toggleMenu(null)}
        >
          <Button size="sm" width="fullWidth" onClick={() => setModal("rename")}>
            Rename
          </Button>

          {isPaid && (
            <Button size="sm" width="fullWidth" onClick={onChangeToSent}>
              change status to "sent"
            </Button>
          )}

          {(isPaid || isSent) && (
            <Button size="sm" width="fullWidth" onClick={onRemoveStatus}>
              remove status
            </Button>
          )}

          <Button size="sm" width="fullWidth" onClick={() => setModal("delete")}>
            Delete
          </Button>
        </DropdownMenu>
      </div>
    </>
  );
};
