import {FC} from "react";
import {Button} from "components/shared/Button/Button";
import {jsPDF} from "jspdf";
import {useContextJobDetails} from "../../../../context/JobDetailsContext/JobDetailsContext";
import {useContextJobCalendar} from "../../context";
import html2canvas from "html2canvas";
import moment from "moment";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {jobsCalendarApi} from "api/jobs/calendar";
import {useHttp} from "hooks/httpServices/useHttp";
import {prepareName} from "helpers/fileNaming";

import "./CalendarPdfExport.scss";

type tSaveType = "download" | "saveToDropbox";

interface iCalendarPdfExportProps {
  onClose: () => void;
  setIsLoading: (isLoading: tSaveType | null) => void;
  isLoading: tSaveType | null;
  setModal?: (modal: "dropboxAuth") => void;
}

export const CalendarPdfExport: FC<iCalendarPdfExportProps> = ({
  isLoading,
  setIsLoading,
  onClose,
  setModal
}) => {
  const {call} = useHttp();
  // @ts-ignore
  const scheduler = window?.scheduler;

  const {activeCalendar} = useContextJobCalendar();
  const {jobDetailsData} = useContextJobDetails();

  const exportToDropbox = async (file: any) => {
    if (!activeCalendar?.id) return;
    try {
      const fileBlob = file.output("blob");
      const formData: any = new FormData();
      formData.append("file", fileBlob);

      const response = await call(jobsCalendarApi.saveToDropbox(activeCalendar?.id, formData));
      eventBus.dispatch("showToast", {text: response.data.message});
    } catch (error: any) {
      if (error?.response?.status === 403) {
        setModal?.("dropboxAuth");
        return;
      }
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  function unsetBodyWrapperStyles() {
    const pageWrapper = document.querySelector(".JobsDetailsLayout-body") as HTMLElement;

    pageWrapper.style.overflowY = "auto";
    pageWrapper.style.overflowX = "unset";
  }
  async function generateImage() {
    const schedulerContainerElement = document.querySelector("#JobSchedulerContainer") as HTMLElement; // prettier-ignore
    const schedulerElement = document.querySelector(".JobScheduler-calendar") as HTMLElement;
    const header = document.querySelector(".CalendarPdfTemplate-header") as HTMLElement; // height 64px
    const headerJob = document.querySelector(".CalendarPdfTemplate-job") as HTMLElement;
    const headerDate = document.querySelector(".CalendarPdfTemplate-date") as HTMLElement;
    const pageWrapper = document.querySelector(".JobsDetailsLayout-body") as HTMLElement;

    header.style.display = "flex";

    pageWrapper.style.overflow = "visible";
    schedulerContainerElement.prepend(header);
    schedulerContainerElement.style.width = "1440px";
    schedulerContainerElement.style.height = "960px";
    schedulerElement.style.maxWidth = "initial";
    schedulerElement.style.width = "1440px"; // 1440
    schedulerElement.style.height = "960px"; // 1024

    const exportDocumentDate = scheduler
      ? moment(scheduler?.getState()?.date).add("day", 2).format("MMMM YYYY")
      : "";

    const timeZone = new Date(scheduler?.getState()?.date)
      .toLocaleDateString("en-US", {
        day: "2-digit",
        timeZoneName: "short"
      })
      .slice(4, 7);

    const job = `${jobDetailsData?.name} ${jobDetailsData?.id}`;
    const headerData = `${exportDocumentDate} (${timeZone})`;

    headerJob.innerText = job;
    headerDate.innerText = headerData;

    scheduler.updateView();

    let imgWidth = 295;
    let imgHeight = 210;

    const canvas = await html2canvas(schedulerContainerElement, {scale: 2});

    header.style.display = "none";
    schedulerContainerElement.style.width = "100%";
    schedulerContainerElement.style.height = "100%";

    schedulerElement.style.maxWidth = "calc(100% - 64px)";
    schedulerElement.style.width = "100%";
    schedulerElement.style.height = "100%";

    onClose();
    const imgData = canvas.toDataURL("img/png");
    const pdf = new jsPDF("l", "mm", [imgWidth, imgHeight], true);
    return pdf.addImage(imgData, "PNG", 5, 5, imgWidth - 10, imgHeight - 10);
  }

  const onDownloadFile = async (file: any) => {
    await file.save(
      prepareName(
        [activeCalendar?.name || "", jobDetailsData?.name || "", String(jobDetailsData?.id) || ""],
        "_CALENDAR.pdf"
      )
    );
  };

  const exportFile = async (type: tSaveType) => {
    const isDropbox = type === "saveToDropbox";
    setIsLoading(type);
    const prevMode = scheduler?.getState().mode;
    try {
      prevMode !== "month" && (await scheduler.setCurrentView(scheduler._date, "month"));

      const file = await generateImage();

      prevMode !== "month" && scheduler.setCurrentView(scheduler._date, prevMode);
      if (isDropbox) {
        await exportToDropbox(file);
      } else {
        await onDownloadFile(file);
      }
    } catch (error: any) {
      console.log(error);
      eventBus.dispatch("showToast", {type: "error", text: error?.message});
    } finally {
      unsetBodyWrapperStyles();
      setTimeout(() => {
        setIsLoading(null);
      }, 150);
    }
  };

  return (
    <>
      <Button loading={isLoading === "download"} onClick={() => exportFile("download")} size="sm">
        Save as PDF
      </Button>
      <Button
        loading={isLoading === "saveToDropbox"}
        onClick={() => exportFile("saveToDropbox")}
        size="sm"
      >
        SAVE to dropbox
      </Button>

      <div className="CalendarPdfTemplate-header">
        {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
        <h2 className="CalendarPdfTemplate-job" />
        {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
        <h2 className="CalendarPdfTemplate-date" />
      </div>
    </>
  );
};
