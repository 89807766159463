import {FC, useState} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {fields} from "api/fields";
import {iField} from "api/fields/types";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {Loading} from "components/shared/Loading/Loading";
import {ManageFieldsDelete} from "./ManageFieldsDelete";
import {AddFieldForm} from "./AddFieldForm";
import {EditFieldForm} from "./EditFieldForm";
import {useLocation} from "react-router-dom";
import "./ManageFields.scss";

interface iManageFieldsProps {
  show: boolean;
  onClose: () => void;
}

export const ManageFields: FC<iManageFieldsProps> = ({show, onClose}) => {
  const {pathname} = useLocation();
  const isPeoplePage = pathname.includes("people");

  const {call, data, isLoading, error, isError} = useHttp({
    config: fields.getFields(isPeoplePage ? "person" : "company"),
    onMountCall: true
  });
  const errorMessage = error?.response?.data?.message;
  const [hide, setHide] = useState(false);
  const fieldList = data?.data?.data as Array<iField>;
  const getFieldList = () => call(fields.getFields(isPeoplePage ? "person" : "company"));

  return (
    <ModalPopup
      size="sm"
      show={show}
      onClose={onClose}
      showCloseButton={false}
      className="ManageFieldsList"
      containerClass={`${hide ? "hidden" : ""}`}
      withoutPaddings
    >
      {!!errorMessage && isError && <p className="ManageFieldsList-error">{errorMessage}</p>}
      {isLoading && <Loading type="inBlock" />}

      {fieldList && !isLoading && (
        <>
          <h2 className="ManageFieldsList-title">Manage Fields</h2>

          <ul className="ManageFieldsList-list">
            {!fieldList?.length && <li className="ManageFieldsList-list-item">Nothing yet !</li>}

            {fieldList?.map(field => (
              <li key={field?.id} className="ManageFieldsList-list-item">
                <span className="title">{field?.name}</span>
                <EditFieldForm field={field} getFieldList={getFieldList} setHide={setHide} />
                <ManageFieldsDelete field={field} getFieldList={getFieldList} setHide={setHide} />
              </li>
            ))}
          </ul>

          <AddFieldForm getFieldList={getFieldList} setHide={setHide} />
        </>
      )}
    </ModalPopup>
  );
};
