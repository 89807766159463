import {FC, useState} from "react";
import {DropdownSubmenu} from "components/shared/DropdownMenu/DropdownSubmenu";
import {Button} from "components/shared/Button/Button";
import {EditCreditCard} from "pages/Jobs/JobDetails/JobBudget/Track/_modals/payRollAndCreditCards/EditCreditCard/EditCreditCard";
import {tPayIdsItem} from "api/common/types";

type tPayCreditCardProps = {
  card: tPayIdsItem;
  callBack: (id?: number) => void;
};

export const PayCreditCard: FC<tPayCreditCardProps> = ({card, callBack}) => {
  const [showModal, setModal] = useState(false);
  return (
    <>
      <DropdownSubmenu title="Credit cards">
        {card?.target_items?.map(item => {
          const {type, holder_initials, last_four_digits} = item;
          const name = `${type?.name ?? ""} ${holder_initials ?? ""} ${last_four_digits ?? ""}`;
          return (
            <Button key={item?.id} size="sm" className="btn" onClick={() => callBack(item?.id)}>
              {name}
            </Button>
          );
        })}

        <hr />
        <Button size="sm" className="btn" onClick={() => setModal(true)}>
          Edit CCS
        </Button>
      </DropdownSubmenu>

      {showModal && <EditCreditCard onClose={() => setModal(false)} card={card} />}
    </>
  );
};
