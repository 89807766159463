import {useEffect, useRef, RefObject} from "react";
import {useLocation} from "react-router-dom";
import {useNotifications} from "./useNotificiations";
import {tReturnNotificationData} from "api/notifications/types";
import {NotificationsItem} from "./NotificationsItem/NotificationsItem";
import {NotificationsHeader} from "./NotificationsHeader/NotificationsHeader";
import {Loading} from "components/shared/Loading/Loading";
import {useInfiniteScroll} from "hooks/useInfiniteScroll";
import {getNotificationPeriodTime} from "./helpers";
import {useContextApp} from "context/AppContext/AppContext";
import "./Notifications.scss";

export type tNotification = tReturnNotificationData & {period?: string};

const Notifications = () => {
  const location = useLocation();
  const {notificationsWindow, setNotificationsWindow, sidebar} = useContextApp();
  const bodyRef: RefObject<HTMLDivElement> = useRef(null);
  const notificationRef: RefObject<HTMLDivElement> = useRef(null);

  const {notifications, data, loadMoreNotifications, setNotifications, markAllAsRead} = useNotifications(); // prettier-ignore
  const loadingMore = data?.data?.meta
    ? data?.data?.meta?.last_page > data?.data?.meta?.current_page
    : false;

  useInfiniteScroll(bodyRef?.current, loadMoreNotifications);

  useEffect(() => {
    hideNotificationsWindow();
  }, [location]); // eslint-disable-line

  // show window and show opening animation
  useEffect(() => {
    if (notificationsWindow) {
      notificationRef?.current?.classList.add("show");
      return;
    } else {
      notificationRef?.current?.classList.remove("show");
    }
  }, [notificationsWindow]);

  // change notification container position if sidebar collapse
  useEffect(() => {
    if (sidebar) {
      notificationRef?.current?.classList.add("Notifications-attach-to-left");
      return;
    }

    notificationRef?.current?.classList.remove("Notifications-attach-to-left");
  }, [sidebar, notificationsWindow]);

  function hideNotificationsWindow() {
    notificationRef?.current?.classList.remove("show");
    setTimeout(() => {
      setNotificationsWindow(false);
    }, 0);
  }

  return (
    <div ref={notificationRef} className="Notifications">
      <div className="Notifications-overlay" onClick={hideNotificationsWindow} />
      <div className="Notifications-content">
        {!notifications && <Loading type="inBlock" />}

        <NotificationsHeader
          notificationsData={notifications}
          markAllAsRead={markAllAsRead}
          setNotificationsWindow={hideNotificationsWindow}
        />
        <div ref={bodyRef} className="Notifications-body">
          {notifications?.length === 0 && (
            <div className="Notifications-body-warning">You don't have any messages yet</div>
          )}

          {notifications?.map(
            (notification: tNotification, index: number, array: tNotification[]) => (
              <NotificationsItem
                key={notification.id}
                {...notification}
                period={getNotificationPeriodTime(notification.created_at, index, array)}
                setNotifications={setNotifications}
              />
            )
          )}

          {loadingMore && <div className="Notifications-loadingMore">Loading more ...</div>}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
