import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useEffect} from "react";
import {tUseDynamicInputsGroupForm} from "./types";
import {useSelectInput} from "../../SelectInput/useSelectInput";

export const useDynamicInputsGroupForm = (props: tUseDynamicInputsGroupForm) => {
  const {options, isFirstItem, itemField, type, onChangeHandler} = props;

  const select = useSelectInput({
    options,
    value: itemField.id?.toString() || options?.[0]?.id,
    customValue: itemField?.type_custom || "",
    targetKeys: {value: "id", label: "name"},
    placeholder: "Select...",
    isRequired: !isFirstItem
  });

  const input = useTextInput({
    value: itemField.value,
    isRequired: !isFirstItem,
    ...(type === "emails" && {validators: ["email"]})
  });

  useEffect(() => {
    const changedAcc = {
      uid: itemField.uid,
      id: select.selected?.id || options?.[0]?.id,
      value: input.value,
      name: select.selected?.name || options?.[0]?.name,
      type_custom: select?.customInput.value || "",
      ...(select.selected?.address && {address: select.selected?.address})
    };

    onChangeHandler(changedAcc);
  }, [select.value, select.selected, select.customInput.value, input.value]); // eslint-disable-line

  return {select, input};
};
