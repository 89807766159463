import {FC, FormEvent} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {useHttp} from "hooks/httpServices/useHttp";
import {notValidForm} from "helpers/misc";
import {useSelect} from "hooks/inputHooks";
import {TemplateModal} from "components/shared/TemplateModal/TemplateModal";
import {jobCrew} from "api/jobCrew";
import {Loading} from "components/shared/Loading/Loading";
import {Select} from "components/shared/FormComponents/Select/Select";
import {useGetCrewTemplates} from "../commonJobCrew/useGetCrewTemplates";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useContextJobCrew} from "../JobCrewContext";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {TextComponent} from "../../common/TextComponent/TextComponent";

import {eventBus} from "EventBus/EventBus";
import "./LoadFromTemplate.scss";

type tLoadFromTemplateProps = {
  closeTemplateModal: () => void;
  showTemplateModal: boolean;
};

export const LoadFromTemplate: FC<tLoadFromTemplateProps> = ({
  showTemplateModal,
  closeTemplateModal
}) => {
  const {jobDetailsData} = useContextJobDetails(); // prettier-ignore
  const id = jobDetailsData?.id;
  const {jobCrew: {getJobCrew}} = useContextJobCrew(); // prettier-ignore
  const FORM_ID = "load-template-form-id";
  const {call, isLoading: submitting} = useHttp();

  const form = {
    job_id: useSelect({
      options: [],
      targetKeys: {value: "id", label: "name"},
      placeholder: "Select...",
      isRequired: true
    })
  };

  const {templateList, isLoading} = useGetCrewTemplates(form.job_id);
  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (notValidForm(form)) return;
    if (!id) return;

    try {
      const res = await call(jobCrew.createCrewFromTemplate(+form.job_id.value, {job_id: id}));
      eventBus.dispatch("showToast", {text: res?.data?.message || "Success!"});
      getJobCrew();
    } catch (error) {
      const {msg} = apiErrorHandler(error, form);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <ModalPopup
      className="LoadFromTemplate"
      show={showTemplateModal}
      onClose={closeTemplateModal}
      size="w-528"
      withoutPaddings
    >
      {isLoading && (
        <div className="emptyBlock">
          <Loading type="inBlock" />
        </div>
      )}

      {!isLoading && !templateList?.length && (
        <TextComponent uppercase className="emptyBlock">
          You have no templates yet !
        </TextComponent>
      )}

      {!isLoading && !!templateList?.length && (
        <form className="LoadFromTemplate-form" onSubmit={onSubmit} id={FORM_ID}>
          <TemplateModal
            formId={FORM_ID}
            title="Load Template"
            subTitle="Choose a template"
            primaryButton={{text: "Load Crew List"}}
            secondaryButton={{text: "Cancel"}}
            onCancel={closeTemplateModal}
            isLoading={submitting}
          >
            <Select {...form.job_id.inputProps} errors={form.job_id.errors} canSelectEmpty={true} />
          </TemplateModal>
        </form>
      )}
    </ModalPopup>
  );
};
