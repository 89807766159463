import {FC, useState} from "react";
import {AddJobLinkForm} from "./AddJobLinkForm/AddJobLinkForm";
import {AddUrlLinkForm} from "./AddUrlLinkForm/AddUrlLinkForm";
import {LinksPlaceholder} from "./LinksPlaceholder";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {LinksList} from "./LinksList";
import "./Links.scss";

interface iLinksProps {
  [key: string]: any;
}

export const Links: FC<iLinksProps> = () => {
  const {jobDetailsData} = useContextJobDetails();
  const [modal, setModal] = useState<"job" | "url" | null>(null);

  if (!jobDetailsData) return null;
  const {jobs, links} = jobDetailsData;
  const isLinks = !!jobs?.length || !!links?.length;

  const onCloseModal = () => setModal(null);

  return (
    <div className="Links">
      {!isLinks && <LinksPlaceholder setModal={setModal} />}
      {isLinks && <LinksList setModal={setModal} />}

      {modal === "url" && <AddUrlLinkForm show={true} onCloseModal={onCloseModal} />}
      {modal === "job" && <AddJobLinkForm show={true} onCloseModal={onCloseModal} />}
    </div>
  );
};
