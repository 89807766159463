import {FC, useState, MouseEvent} from "react";
import {Popover} from "components/shared/Popover/Popover";
import {Button} from "components/shared/Button/Button";
import {SwitchInput} from "components/shared/FormComponents/SwitchInput/SwitchInput";
import {useSetApiParams} from "hooks/useSetApiParams";
import {LIST_OPTIONS, tListOptionsItem} from "./listViewOptions";
import "./ExpenseListViewOptions.scss";

type tExpenseListViewOptionsProps = {
  onClose?: () => void;
};

export const HIDDEN_COLUMNS = "hiddenColumns";

export const ExpenseListViewOptions: FC<tExpenseListViewOptionsProps> = () => {
  const [showFilter, setShowFilter] = useState<HTMLElement | null>(null); // prettier-ignore

  const apiParams = useSetApiParams();
  type tCheckedItem = {id: string; checked: boolean};

  const defaultValues = apiParams?.preparedParams?.[HIDDEN_COLUMNS];
  let list: Array<tCheckedItem> = defaultValues?.split(",")?.map(item => ({id: item, checked: true})) || []; // prettier-ignore

  const updateParams = (checkedItem: tCheckedItem) => {
    if (checkedItem?.checked) list?.push(checkedItem);
    if (!checkedItem?.checked) list = list?.filter(item => item?.id !== checkedItem?.id);

    const params = {[HIDDEN_COLUMNS]: list.length ? list.map((item: tCheckedItem) => item.id) : []};
    apiParams.setApiParams({...Object.fromEntries(apiParams.searchParams), params});
  };

  const getDefValue = (id: tCheckedItem["id"]) => !!list?.find(item => item?.id === id);

  const showNotification = list?.length || null;
  const showPopover = (event: MouseEvent<HTMLButtonElement>) => setShowFilter(event.currentTarget);

  return (
    <>
      <Button onClick={showPopover} size="sm" color={"outline"} className="btnOptions">
        {!!showNotification
          ? `${showNotification} View options ${showNotification > 1 ? "s" : ""} applied`
          : "View options"}
      </Button>

      <Popover anchor={showFilter} onClose={() => setShowFilter(null)}>
        <div className="ExpenseListViewOptions">
          {Object.entries(LIST_OPTIONS)?.map(ent => {
            const item = ent[1] as tListOptionsItem;

            return (
              <SwitchInput
                key={item?.id}
                value={item?.id}
                label={item?.label}
                checked={getDefValue(item?.id)}
                className="ExpenseListViewOptions-switcher"
                onChange={e => updateParams({id: e.target.value, checked: !!e.target.checked})}
              />
            );
          })}

          <Button
            size="sm"
            color="light"
            className="ExpenseListViewOptions-button"
            onClick={() => apiParams?.setApiParams({params: {[HIDDEN_COLUMNS]: []}})}
            width="fullWidth"
          >
            Clear Filters
          </Button>
        </div>
      </Popover>
    </>
  );
};
