import {FC} from "react";
import {ImgWithFallback} from "components/shared/ImgWithFallback/ImgWithFallback";
import {Button, iButtonProps} from "components/shared/Button/Button";
import {Icon, iPropsIcon} from "../../Icon/Icon";
import "./EmptyPageState.scss";

interface tButton extends iButtonProps {
  text?: string;
}

interface iEmptyPageStateProps {
  img?: string;
  text: string;
  primaryButton?: tButton;
  secondaryButton?: tButton;
  removeButton?: tButton;
  isLoading?: boolean;
  icon?: iPropsIcon;
}

export const EmptyPageState: FC<iEmptyPageStateProps> = ({
  img,
  primaryButton,
  secondaryButton,
  removeButton,
  text,
  isLoading,
  icon,
  children
}) => {
  return (
    <div className="EmptyPageState">
      {img && <ImgWithFallback className="EmptyPageState-image" src={img} />}
      {icon && <Icon {...icon} />}
      <p className="EmptyPageState-text">{text}</p>
      {children}
      <div className="EmptyPageState-buttons">
        {primaryButton?.text && (
          <Button size="md" color="dark" {...primaryButton} disabled={isLoading}>
            {primaryButton?.text}
          </Button>
        )}

        {secondaryButton?.text && (
          <Button size="md" color="dark" {...secondaryButton} disabled={isLoading}>
            {secondaryButton?.text}
          </Button>
        )}
      </div>

      <Button className="EmptyPageState-remove" {...removeButton} disabled={isLoading}>
        {removeButton?.text}
      </Button>
    </div>
  );
};
