import {useState} from "react";
import {tAddDeliveryPostData, tCallSHeetGroupContacts} from "api/callSheet/types";
import {useHttp} from "hooks/httpServices/useHttp";
import {callSheet} from "api/callSheet";
import {useContextJobCallSheet} from "../../context/JobCallSheetContext";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {getDeliveryContactsId} from "../../helpers";
import {useLocation, useNavigate, useParams} from "react-router-dom";

interface iUseCallSheetSender {
  onClose?: () => void;
}

export function useCallSheetSender(props?: iUseCallSheetSender) {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const {callSheetId, callSheetInfo, setDeliveryInfo} = useContextJobCallSheet();
  const callSheetGroups =
    callSheetInfo?.attributes?.groups?.filter(item => !!item.contacts.length && !item.hidden) || [];

  const {call, isLoading} = useHttp();

  const [step, setStep] = useState(0);
  const [groups, setGroups] = useState<Array<tCallSHeetGroupContacts>>(callSheetGroups);

  const onRecipientSubmitCallback = (groups: Array<tCallSHeetGroupContacts>) => {
    setGroups(groups);
    setStep(1);
  };

  const onSubmitCallback = async (formData: any) => {
    if (!callSheetId) return;

    const groupsFiltered = groups
      .map(item => ({
        ...item,
        contacts: item.contacts.filter(contact => contact.selected)
      }))
      .filter(group => !!group.contacts.length);

    const payload: tAddDeliveryPostData = {
      contacts: getDeliveryContactsId(groupsFiltered),
      ...formData
    };

    try {
      const {
        data: {message, data}
      } = await call(callSheet.addDelivery(+callSheetId, payload));
      setDeliveryInfo(data);
      if (data?.schedule && location.pathname?.includes("call-sheets/track")) {
        navigate(`/jobs/${params?.id}/call-sheets`);
      }

      eventBus.dispatch("showToast", {text: message || "Success !"});
      props?.onClose?.();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error !"});
    }
  };

  return {step, setStep, groups, setGroups, onRecipientSubmitCallback, onSubmitCallback, isLoading};
}
