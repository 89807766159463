import {useState} from "react";
import moment from "moment";

type tValue = [Date | null, Date | null];

type tUseDatePicker = {
  value?: {
    startDate: Date | null;
    endData: Date | null;
  };
  errors?: string[];
  isRequired?: boolean;
  customErrMsg?: string;
};

export const useDatepickerRangeSingle = (props?: tUseDatePicker) => {
  const [value, setValue] = useState<tValue>(
    props?.value
      ? [props.value.startDate || moment().toDate(), props.value.endData || moment().toDate()]
      : [moment().add(1, "hour").toDate(), moment().add(2, "hour").toDate()]
  );
  const [err, setErrors] = useState<string[]>(props?.errors || []);

  const onChange = (value: tValue) => {
    err.length && setErrors([]);
    setValue(value);
  };

  const checkValidity = () => {
    if (props?.isRequired && value.some(v => !v)) {
      const errMsg = props?.customErrMsg || "The date field is required";
      setErrors([errMsg]);
      return false;
    }

    if (props?.isRequired && moment(value[0]).isAfter(value[1])) {
      // console.log(value);
      setErrors(["The start date must be before the end date"]);
      return false;
    }
    return true;
  };

  return {
    value,
    errors: err,
    setErrors,
    checkValidity,
    inputProps: {
      value,
      onChange
    }
  };
};
