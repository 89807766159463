import {FC, useEffect, useState} from "react";
import {Button} from "components/shared/Button/Button";
import {useContextJobCallSheet} from "../context/JobCallSheetContext";
import {callSheet} from "api/callSheet";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {usePDF} from "hooks/usePDF";
import {eventBus} from "EventBus/EventBus";
import {prepareName} from "helpers/fileNaming";
import moment from "moment";
import {PDFTemplate} from "./PDFTemplate";
import {tCallSheetInfo} from "api/callSheet/types";

import "./CallSheetPdfTemplate.scss";

interface iCallSheetPdfTemplateProps {
  withExportButtons?: boolean;
  onClose?: () => void;
  exportingType?: "download" | "saveToDropbox";
  setShowModal?: (show: "dropboxAuth") => void;
}

export const CallSheetPdfTemplate: FC<iCallSheetPdfTemplateProps> = ({
  withExportButtons = true,
  exportingType,
  onClose,
  setShowModal
}) => {
  const templateID = "pdf-template-id";
  const downloadingTemplateId = "downloading-template-id";

  const saveToDropboxQuery = useHttp();

  const {jobDetailsData} = useContextJobDetails();
  const {callSheetInfo, proofedCallSheetInfo} = useContextJobCallSheet();
  const [isSavingToDropbox, setIsSavingToDropbox] = useState(false);

  const {isDownloading, onDownloadPdf, createPdf} = usePDF({
    name: prepareName(
      [
        jobDetailsData?.name || "",
        jobDetailsData?.artists?.[0]?.artist?.name?.split(" ")?.join("") || "",
        moment(proofedCallSheetInfo?.date).format("YYYYMMDD") || "",
        proofedCallSheetInfo?.name || ""
      ],
      "_CS.pdf"
    ),
    templateID: downloadingTemplateId
  });

  useEffect(() => {
    if (exportingType === "download") onDownloadPdf().finally(() => onClose?.());
    if (exportingType === "saveToDropbox") {
      saveOnDropbox().finally(() => onClose?.());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const modal = document.querySelector(".CallSheetPdfTemplate-modal.ModalPopUp-portal");
    setTimeout(() => {
      modal?.scrollTo(0, 0);
    }, 5);
  }, []);

  async function saveOnDropbox() {
    if (!proofedCallSheetInfo?.id) return;
    setIsSavingToDropbox(true);
    try {
      const file: any = await createPdf();
      const fileBlob = file.output("blob");
      const formData: any = new FormData();
      formData.append("file", fileBlob);

      const response = await saveToDropboxQuery.call(callSheet.saveToDropbox(proofedCallSheetInfo.id, formData)); // prettier-ignore
      eventBus.dispatch("showToast", {message: response.data.message});
    } catch (error: any) {
      if (error?.response?.status === 403) {
        setShowModal?.("dropboxAuth");
        return;
      }
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {message: msg || error?.message, type: "error"});
    } finally {
      setIsSavingToDropbox(false);
    }
  }

  const isInvisible = exportingType ? "isInvisible" : "";

  function getSheetsWithVisibleGroups(sheets: tCallSheetInfo | null) {
    if (!sheets) return null;
    return {
      ...sheets,
      attributes: {
        ...sheets?.attributes,
        groups: sheets?.attributes?.groups?.filter(v => !v.hidden)
      }
    };
  }

  return (
    <>
      <div className={`CallSheetPdfTemplate ${isInvisible}`}>
        {withExportButtons && (
          <>
            <div className="CallSheetPdfTemplate-buttons">
              <Button
                disabled={isSavingToDropbox}
                onClick={onDownloadPdf}
                loading={isDownloading}
                size="md"
                color="lightHoverLight"
              >
                Download PDF
              </Button>
              <Button
                disabled={isDownloading}
                loading={isSavingToDropbox}
                onClick={saveOnDropbox}
                size="md"
                color="lightHoverLight"
              >
                Save to dropbox
              </Button>
            </div>
          </>
        )}

        <PDFTemplate
          id={templateID}
          callSheetInfo={getSheetsWithVisibleGroups(callSheetInfo)}
          jobDetailsData={jobDetailsData}
        />

        <PDFTemplate
          forDownloading
          id={downloadingTemplateId}
          callSheetInfo={getSheetsWithVisibleGroups(proofedCallSheetInfo)}
          jobDetailsData={jobDetailsData}
        />
      </div>
    </>
  );
};
