import {FC, useEffect} from "react";
import {BudgetOverlayPageLayout} from "../../OverlayTemplates/BudgetOverlayPageLayout/BudgetOverlayPageLayout";
import {BudgetOverlayTitle} from "../../OverlayTemplates/BudgetOverlayTitle/BudgetOverlayTitle";
import {useContextJobDetails} from "../../../../context/JobDetailsContext/JobDetailsContext";
import {useContextInvoice} from "../context/InvoiceContext";
import {InvoiceBuildAdvance} from "./InvoiceBuildAdvance/InvoiceBuildAdvance";
import {EstimateJobEdit} from "../../Estimate/EstimateExport/EstimateExportBuilder/EstimateJobEdit";
import {InvoiceProductionCosts} from "./InvoiceProductionCosts/InvoiceProductionCosts";
import {useContextTrack} from "../../Track/_context/TrackContext";
import {InvoiceTotals} from "./InvoiceTotals/InvoiceTotals";
import {tInvoice} from "api/invoice/types";
import {BudgetOverlayTermsBank} from "../../OverlayTemplates/BudgetOverlayTermsBank/BudgetOverlayTermsBank";
import {PdfViewer} from "components/shared/PdfViewer/PdfViewer";

import "./InvoiceBuild.scss";

export const InvoiceBuild: FC = () => {
  const {jobDetailsData} = useContextJobDetails();
  const {trackData, getEstimateGroups} = useContextTrack();
  const {invoice, updateInvoiceData, onResetExpense, expenseInvoice} = useContextInvoice();

  useEffect(() => {
    if (!trackData) getEstimateGroups();
  }, [trackData]); // eslint-disable-line

  if (!invoice || !jobDetailsData) return null;

  const onSaveExportInfoCallback = (invoice: Partial<tInvoice>) => updateInvoiceData(invoice);
  const onSaveJobDetails = (data: any) => {
    const details = {
      job_name: data.job_name.value,
      job_number: data.job_number.value,
      po: data.po.value,
      creative: data.creative.value,
      notes: data.notes.value,
      shoot_location: data.shoot_location.value,
      photographer: data.photographer.value,
      usage_license: data.usage_license.value,
      job_start_at: data.dates.value[0],
      job_end_at: data.dates.value[1],
      label1: data?.label1?.value,
      text1: data?.text1?.value,
      label2: data?.label2?.value,
      text2: data?.text2?.value
    };

    updateInvoiceData(details);
  };
  return (
    <div className="InvoiceBuild">
      <BudgetOverlayPageLayout page={1} total={1}>
        <BudgetOverlayTitle>Invoice</BudgetOverlayTitle>
        <EstimateJobEdit
          jobDetailsData={jobDetailsData}
          exportInfo={invoice}
          onSaveExportInfoCallback={onSaveJobDetails}
        >
          <InvoiceBuildAdvance />
        </EstimateJobEdit>
        <InvoiceProductionCosts data={trackData} />
        <InvoiceTotals
          onResetExpense={onResetExpense}
          data={invoice}
          updateInvoiceData={updateInvoiceData}
        />

        <BudgetOverlayTermsBank
          bankDetails={invoice.bank_detail}
          termCondition={invoice.term_condition}
          updateData={onSaveExportInfoCallback}
        />
      </BudgetOverlayPageLayout>

      <PdfViewer blobUrl={expenseInvoice?.url || ""} />
    </div>
  );
};
