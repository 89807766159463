import {FC} from "react";
import {Button} from "components/shared/Button/Button";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextJobTasks} from "../../../_context/JobTaskContext";
import {tMoveTasksGroupDirection} from "api/jobs/tasks/types";
import {jobTasksApi} from "api/jobs/tasks";

type tMoveTasksGroupProps = {
  groupId: number;
  onClose: () => void;
};

export const MoveTasksGroup: FC<tMoveTasksGroupProps> = ({groupId, onClose}) => {
  const {tasks, setTasks} = useContextJobTasks();
  const {call, isLoading} = useHttp();

  const moveTo = async (direction: tMoveTasksGroupDirection) => {
    try {
      const res = await call(jobTasksApi.moveGroup(groupId, direction));
      setTasks(res?.data?.data);
      onClose();
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  const isFirstItem = groupId === tasks?.groups?.[0]?.id;
  const isLastItem = groupId === tasks?.groups[tasks?.groups?.length - 1]?.id;
  return (
    <>
      {!isFirstItem && (
        <Button size="sm" onClick={() => moveTo("up")} disabled={isLoading}>
          Move up
        </Button>
      )}
      {!isLastItem && (
        <Button size="sm" onClick={() => moveTo("down")} disabled={isLoading}>
          Move down
        </Button>
      )}
      {!isFirstItem && (
        <Button size="sm" onClick={() => moveTo("top")} disabled={isLoading}>
          Move to top
        </Button>
      )}
      {!isLastItem && (
        <Button size="sm" onClick={() => moveTo("bottom")} disabled={isLoading}>
          Move to bottom
        </Button>
      )}
    </>
  );
};
