import {FC, ChangeEvent} from "react";
import {Checkbox} from "components/shared/FormComponents/Checkbox/Checkbox";
import {Icon} from "components/Icon/Icon";
import "./SenderRecipientToolbar.scss";

interface iSenderRecipientToolbarProps {
  expanded?: number[];
  toggleAll?: () => void;
  onCheckAll: (event: ChangeEvent<HTMLInputElement>) => void;
  isCheckedAll: boolean;
  showExpander?: boolean;
}

export const SenderRecipientToolbar: FC<iSenderRecipientToolbarProps> = ({
  expanded,
  toggleAll,
  onCheckAll,
  isCheckedAll,
  showExpander = true
}) => {
  return (
    <div className="SenderRecipientToolbar">
      <div className="SenderRecipientToolbar-left">
        <Checkbox
          className="SenderRecipientToolbar-checkbox"
          label="all"
          onChange={onCheckAll}
          showTitle={false}
          checked={isCheckedAll}
        />
        <Icon icon="triangle-down" size="xsm" />
      </div>

      {showExpander && (
        <button onClick={toggleAll} type="button" className="SenderRecipientToolbar-button">
          {expanded?.length ? "collapse" : "expand"} all
        </button>
      )}
    </div>
  );
};
