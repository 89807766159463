import {FC, useEffect, useState} from "react";
import {tEstimateRowData} from "api/budget/estimate/types";
import {useContextJobBudget} from "pages/Jobs/JobDetails/JobBudget/context/JobBudgetContext";
import {useHttp} from "hooks/httpServices/useHttp";
import {estimateApi} from "api/budget/estimate";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {EstimateCellWithDropdown} from "../../_common/EstimateCellWithDropdown/EstimateCellWithDropdown";
import {Loading} from "components/shared/Loading/Loading";
import "./EstimateRowCurrency.scss";

type tEstimateRowCurrencyProps = {
  currItem: tEstimateRowData;
};

export const EstimateRowCurrency: FC<tEstimateRowCurrencyProps> = ({currItem}) => {
  const {resources, setEstimate} = useContextJobBudget();
  const [value, setValue] = useState<tEstimateRowData["currency_rate"] | null>(null);

  useEffect(() => {
    setValue(currItem.currency_rate);
  }, [currItem]);

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const {call, isLoading} = useHttp();

  const onSubmit = async (value: tEstimateRowData["currency_rate"]) => {
    setMenuAnchor(null);
    setValue(() => value);
    if (!value?.id) return;

    try {
      const res = await call(estimateApi.updateLine(currItem.id, {currency_id: value.id}));

      setMenuAnchor(null);
      setEstimate(() => res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <div className="EstimateRowCurrency posRelative">
      {isLoading && <Loading type="inBlock" bgColor="grey-40" customSize={0.5} />}

      <EstimateCellWithDropdown
        isData={!!value}
        listItemKey="code"
        disabled={isLoading}
        list={resources?.currency_rate?.filter(item => !!item?.exchange_rate) || []}
        callBack={value => onSubmit(value)}
        menuAnchor={menuAnchor}
        setMenuAnchor={setMenuAnchor}
      >
        {value?.code ? value.code : "usd"}
      </EstimateCellWithDropdown>
    </div>
  );
};
