import {FC} from "react";
import {tTrackFeesItem, typeFees} from "api/budget/track/types";
import {CategoryFooter} from "../Category/CategoryFooter/CategoryFooter";
import {CostValueRow} from "../_common/CostValueRow/CostValueRow";
import {useCostValueRowCells} from "./useCostValueRowCells";
import {BodyFeesExpenseList} from "./BodyFeesExpenseList";

type tBodyFeesProps = {
  data: tTrackFeesItem | undefined;
  title: string;
  typeFees: typeFees;
};

export const BodyFees: FC<tBodyFeesProps> = ({data, title, typeFees}) => {
  const {cells} = useCostValueRowCells({data, title});

  if (!data) return null;
  const {total_actual, total_difference, total_working, total_budget} = data;

  return (
    <>
      <CostValueRow cells={cells} />

      {!!data && <BodyFeesExpenseList feesItem={data} typeFees={typeFees} />}

      {!!data?.expense_lines?.length && (
        <CategoryFooter
          data={{total_actual, total_cost: total_budget, total_difference, total_working}}
        />
      )}
    </>
  );
};
