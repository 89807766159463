import {FC, useState, MouseEvent} from "react";
import {Button} from "components/shared/Button/Button";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {JobBlock} from "../../common/JobBlock";
import {LinkBlock} from "../../common/LinkBlock/LinkBlock";
import {Title} from "../../common/Title";
import {Icon} from "components/Icon/Icon";

type tLinksListProps = {
  setModal: React.Dispatch<React.SetStateAction<"job" | "url" | null>>;
};

export const LinksList: FC<tLinksListProps> = ({setModal}) => {
  const [menu, setMenu] = useState<HTMLElement | null>(null);

  const {jobDetailsData} = useContextJobDetails();
  if (!jobDetailsData) return null;
  const {jobs, links} = jobDetailsData;

  const showModal = (model: "url" | "job") => {
    setModal(model);
    setMenu(null);
  };

  return (
    <>
      <div className="Links-header">
        <Title text="Links" />

        <Button
          size="sm"
          color="outline"
          onClick={(event: MouseEvent<HTMLButtonElement>) => setMenu(event.currentTarget)}
        >
          Add link
          <Icon icon="triangle-up" className={`Links-triangle ${menu === null ? "" : "active"}`} />
        </Button>
        <DropdownMenu
          anchor={menu}
          onClose={() => setMenu(null)}
          className="Links-dropdown"
          transformOrigin={{
            horizontal: "right"
          }}
        >
          <Button size="sm" onClick={() => showModal("url")}>
            URl
          </Button>
          <Button size="sm" onClick={() => showModal("job")}>
            JOB
          </Button>
        </DropdownMenu>
      </div>

      {!!links?.length && (
        <div className="Links-section">
          <Title text="External" size="sm" fullWidth />

          {links?.map((link, i) => {
            return <LinkBlock key={i} link={link} />;
          })}
        </div>
      )}

      {!!jobs?.length && (
        <div className="Links-section">
          <Title text="Jobs" size="sm" fullWidth />

          {jobs?.map((job, i) => {
            return <JobBlock key={i} job={job} />;
          })}
        </div>
      )}
    </>
  );
};
