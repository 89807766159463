import {FC, useState} from "react";
import moment from "moment";
import {tCrewContact} from "api/jobCrewGroup/types";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextJobCrew} from "../../../JobCrewContext";
import {jobCrewContact} from "api/jobCrewContact";
import {getDateFormat, getDateRange} from "helpers/dates";
import {DateRangePicker} from "components/shared/FormComponents/DateRangePicker/DateRangePicker";
import {useDateRangePicker} from "hooks/useDateRangePicker";
import {Button} from "components/shared/Button/Button";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {notValidForm} from "helpers/misc";
import {getHoldColor} from "../../../commonJobCrew/getHoldColor";
import {Loading} from "components/shared/Loading/Loading";
import {eventBus} from "EventBus/EventBus";
import "./RowDates.scss";

type tRowDatesProps = {
  crewContact: tCrewContact;
};

export const RowDates: FC<tRowDatesProps> = ({crewContact}) => {
  const {hold, id} = crewContact;
  const {jobCrewGroupList: {setCrewGroupList}} = useContextJobCrew(); // prettier-ignore
  const {call, isLoading} = useHttp();

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const formData = {
    dates: useDateRangePicker({
      startDate: !!hold?.start_at ? String(hold?.start_at) : undefined,
      endDate: hold?.end_at ? String(hold?.end_at) : undefined
    })
  };

  const onClose = () => {
    const {startDate, endDate} = formData.dates;
    const isValid = !moment(startDate).isSame(hold?.start_at) || !moment(endDate).isSame(hold?.end_at); // prettier-ignore
    isValid && onSubmit();
    !isValid && setMenuAnchor(null);
  };

  const onSubmit = async () => {
    if (notValidForm(formData)) return;

    try {
      const data = {
        hold_status_id: hold?.status?.id as number,
        start_at: getDateFormat(formData.dates.startDate, "timestamp") as number,
        end_at: getDateFormat(formData.dates.endDate, "timestamp") as number
      };
      const res = await call(jobCrewContact.setHoldDateForContact(id, data));
      setCrewGroupList(() => res?.data?.data);
      setMenuAnchor(null);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      setMenuAnchor(null);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  if (!hold?.status?.with_dates) return null;

  const placeholder = <span className="RowDates-placeholder">Select dates...</span>;
  const isDate = !!hold?.start_at && !!hold?.end_at;
  const date = isDate && getDateRange({start_at: String(hold?.start_at), end_at: String(hold?.end_at)}); // prettier-ignore

  return (
    <div className={`RowDates ${menuAnchor ? "open" : ""}`}>
      <Button
        size="sm"
        onClick={showMenu}
        hidePadding="horizontal"
        btnType="text"
        disabled={isLoading}
      >
        {!date && placeholder}
        {date && <span style={getHoldColor(hold?.status)}>{date?.period}</span>}
      </Button>

      <DropdownMenu
        anchor={menuAnchor}
        onClose={onClose}
        transformOrigin={{
          horizontal: "right"
        }}
        className="RowDates-dropDown"
      >
        {isLoading && <Loading type="inBlock" />}
        <DateRangePicker {...formData.dates} separator="to" />
      </DropdownMenu>
    </div>
  );
};
