import {FC, Fragment} from "react";
import {TrackingTableRow} from "../TrackingTableRow/TrackingTableRow";
import {tCallSheetContact, tCallSHeetGroupContacts, iDeliveryInfo} from "api/callSheet/types";

interface iTrackingTableBodyProps {
  group: tCallSHeetGroupContacts;
  deliveryInfo: iDeliveryInfo;
  isFirstGroup?: boolean;
}

export const TrackingTableBody: FC<iTrackingTableBodyProps> = ({
  group,
  deliveryInfo,
  isFirstGroup
}) => {
  if (!group) return null;
  const {confirmations} = deliveryInfo;

  return (
    <>
      <tr className="TrackingTableRow group-header">
        <td>{group?.name}</td>
        <td colSpan={9} />
      </tr>

      {group?.contacts?.map((item: tCallSheetContact, index: number) => {
        const agent = item?.agent;
        const confirmation = confirmations.find(ctn => String(ctn.contact_id) === String(item.id));

        return (
          <Fragment key={item.id}>
            <TrackingTableRow
              contact={item}
              deliveryInfo={deliveryInfo}
              className={index === 0 ? "first" : ""}
              showTooltip={isFirstGroup && index === 0}
              confirmation={confirmation}
            />
            {agent && (
              <TrackingTableRow
                isAgent
                contact={item}
                deliveryInfo={deliveryInfo}
                confirmation={confirmation}
              />
            )}
          </Fragment>
        );
      })}
    </>
  );
};
