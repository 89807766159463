import {FC} from "react";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {getUsMoneyFormat} from "helpers/formattingData";
import "./EstimateSideBarListItem.scss";

type tEstimateSideBarLisItemProps = {
  title: string;
  percent: string;
  value: number;
};

export const EstimateSideBarLisItem: FC<tEstimateSideBarLisItemProps> = ({
  title,
  percent,
  value
}) => {
  return (
    <div className="EstimateSideBarLisItem">
      <TextComponent size="13" weight="500">
        {title}
      </TextComponent>

      <TextComponent size="13" weight="400" color="gray-10">
        {percent}
      </TextComponent>

      <TextComponent size="14" weight="500" color={value ? "black-10" : "gray-20"}>
        {!!value && getUsMoneyFormat(value)}
        {!value && "$0.00"}
      </TextComponent>
    </div>
  );
};
