import React, {FC} from "react";
import {Outlet} from "react-router-dom";
import "./FullPageLayout.scss";

interface iFullPageLayoutProps {}

export const FullPageLayout: FC<iFullPageLayoutProps> = () => {
  return (
    <div className="FullPageLayout">
      <Outlet />
    </div>
  );
};
