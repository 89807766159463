import React, {FC, useEffect, useState} from "react";
import Select, {OnChangeValue} from "react-select";
import {tUseAddressField} from "./useAddressField";
import {useDebounce} from "hooks/useDebounce";
import {components} from "react-select";
import {mapboxSearchAddress} from "api/mapbox";
import {markSearchStringInText} from "helpers/supporters";
import "./AddressFieldSearch.scss";

type tProps = Pick<
  tUseAddressField,
  | "selectedAddress"
  | "address"
  | "handleChangeAddress"
  | "errors"
  | "changeEntryMethod"
  | "options"
  | "setOptions"
>;

interface iAddressFieldSearchProps extends tProps {
  searchLabel?: string;
  searchPlaceholder?: string;
}

export const AddressFieldSearch: FC<iAddressFieldSearchProps> = ({
  selectedAddress,
  handleChangeAddress,
  errors,
  options,
  setOptions,
  address,
  searchLabel = "Address",
  searchPlaceholder
}) => {
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const debounceValue = useDebounce(search, 250);

  useEffect(() => {
    if (debounceValue && debounceValue.length > 1) {
      onSearch(debounceValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  const onSearch = async (value: string) => {
    try {
      const response = await mapboxSearchAddress({value});
      setOptions(response?.data?.features);
    } catch (error: any) {
      console.log(error?.message);
    } finally {
      setIsSearching(false);
    }
  };

  const handleInputChange = (v: string) => setSearch(v);
  const onChange = (value: OnChangeValue<any, any>) => {
    if (!value) setSearch("");
    handleChangeAddress(value);
  };

  const suite = address?.apartment ? ", " + address?.apartment : "";

  return (
    <div className={`AddressFieldSearch ${!!errors.length ? "error" : ""}`}>
      <label className="AddressFieldSearch-label">{searchLabel}</label>

      <Select
        isClearable
        inputValue={search}
        isLoading={isSearching}
        placeholder={searchPlaceholder}
        filterOption={() => true}
        value={selectedAddress}
        options={options}
        onInputChange={handleInputChange}
        onChange={onChange}
        className="AddressFieldSearch-control"
        classNamePrefix="AddressFieldSearch-control"
        onMenuClose={() => setIsSearching(false)}
        getOptionLabel={(option: {place_name: string}) => option?.place_name}
        getOptionValue={(option: {place_name: string}) => option?.place_name}
        formatOptionLabel={(option: any, meta) => {
          if (meta?.context === "value") {
            let str = option?.place_name;

            if (suite && option?.street) {
              let addressArray = option?.place_name?.split(", ");
              addressArray[0] = addressArray[0] + suite;
              return addressArray.join(", ");
            }
            return str;
          }
          return option?.place_name;
        }}
        components={{
          Option: props => (
            <components.Option {...props}>
              <span
                dangerouslySetInnerHTML={markSearchStringInText({
                  search: search,
                  text: props.label,
                  classname: "searched-text"
                })}
              />
            </components.Option>
          ),
          NoOptionsMessage: props => (
            <components.NoOptionsMessage {...props}>
              <span>No results found.</span>
            </components.NoOptionsMessage>
          )
        }}
      />
      {errors.map(error => (
        <p key={error} className="AddressFieldSearch-error-message">
          {error}
        </p>
      ))}
    </div>
  );
};
