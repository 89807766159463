import {Dispatch, FC, SetStateAction} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {FormWrap} from "components/shared/FormWrap/FormWrap";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {useAdvanceContactsModal} from "./useAdvanceContactsModal";
import {AdvanceModalFormList} from "../../AdvanceModalFormBody/AdvanceModalFormList";
import {tAdvanceContact} from "api/budget/advanceContact/types";

import "./AdvanceContactsModal.scss";
import {Loading} from "../../../../../../../components/shared/Loading/Loading";
// import {PhoneField} from "components/shared/FormComponents/PhoneField/PhoneField";
// import { maskPhone } from "hooks/useTextInput/filters";

interface iAdvanceContactsModalProps {
  onClose: () => void;
  advanceContacts: tAdvanceContact[];
  setAdvanceContacts: Dispatch<SetStateAction<tAdvanceContact[]>>;
  isLoading?: boolean;
}

export const AdvanceContactsModal: FC<iAdvanceContactsModalProps> = ({
  advanceContacts,
  setAdvanceContacts,
  onClose,
  isLoading
}) => {
  const {
    modal,
    formData,
    showModal,
    onDelete,
    onShowEdit,
    onShowDelete,
    handleSubmit,
    createContactQuery,
    deleteContactQuery,
    editContactQuery,
    activeContact
  } = useAdvanceContactsModal({setAdvanceContacts});

  const showFormWrap = modal === "add" || modal === "edit";
  const hasContacts = !!advanceContacts.length;

  return (
    <>
      <ModalPopup
        size="w-528"
        show={true}
        onClose={onClose}
        className="AdvanceContactsModal"
        withoutPaddings
        showCloseButton={!modal}
      >
        {isLoading && <Loading type="inBlock" />}
        {!modal && (
          <AdvanceModalFormList
            onEdit={onShowEdit}
            onDelete={onShowDelete}
            onAdd={showModal}
            data={advanceContacts}
            title={hasContacts ? "Edit ADVANCE CONTACTS" : "Advance contacts"}
            btnText="contact"
          />
        )}

        {showFormWrap && (
          <FormWrap
            headerTitle={modal === "add" ? "ADD NEW ADVANCE CONTACT" : "EDIT ADVANCE CONTACT"}
            idForm="term-form"
            type="titleCenterAndOneBtn"
            submitTitle="Save"
            onBack={() => showModal(null)}
            isLoading={createContactQuery.isLoading || editContactQuery.isLoading}
          >
            <form id="term-form" onSubmit={handleSubmit}>
              <InputField
                {...formData.name.inputProps}
                errors={formData.name.errors}
                label="name"
              />
              {/* <PhoneField
                // value={maskPhone(formData.phone.inputProps.value)}
                {...formData.phone.inputProps}
                label="phone number"
                errors={formData.phone.errors}
              /> */}
              <InputField
                {...formData.phone.inputProps}
                value={formData.phone.inputProps.value}
                // value={maskPhone(formData.phone.inputProps.value)}
                errors={formData.phone.errors}
                label="phone number"
              />
              <InputField
                {...formData.email.inputProps}
                errors={formData.email.errors}
                label="email"
              />
            </form>
          </FormWrap>
        )}
      </ModalPopup>

      <ConfirmWindow
        title="Delete Contact?"
        subTitle={`${activeContact?.name} will be permanently deleted.`}
        warning="This can’t be undone."
        show={modal === "delete"}
        onClose={() => showModal(null)}
        onConfirm={onDelete}
        isLoading={deleteContactQuery.isLoading}
        confirmBtn={{
          text: "Delete",
          color: "red"
        }}
        cancelBtn={{
          text: "cancel"
        }}
      />
    </>
  );
};
