import {tEstimateRowINputProps} from "./types";

export const rowInputFilter = (value: string, type: tEstimateRowINputProps["type"]) => {
  let val = value;

  if (type === "text") return val;

  if (type === "percent") {
    val = value.replace(/[^0-9.]/, "");
    val = +val > 100 ? "100" : val;
    return val;
  }

  val = value.replace(/[^0-9.]/, "");
  return val;
};
