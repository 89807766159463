import {FC, useState} from "react";
import {EmptyPageState} from "components/shared/EmptyPageState/EmptyPageState";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import tasks from "assets/images/TaskList.svg";
import {CreateNewTask} from "./_modals/CreateNewTask";
import {CreateNEwTaskFromTemplate} from "./_modals/CreateNEwTaskFromTemplate";

type tJobTaskEmptyStateProps = {
  [key: string]: any;
};

type tModals = "add" | "fromTemplate" | null;

export const JobTaskEmptyState: FC<tJobTaskEmptyStateProps> = () => {
  const [modal, setModal] = useState<tModals>(null);
  const {removeSection, removeSectionQuery} = useContextJobDetails();
  const onCloseModal = () => setModal(null);
  const onRemoveSection = () => removeSection();

  const modals = {
    add: <CreateNewTask onClose={onCloseModal} />,
    fromTemplate: <CreateNEwTaskFromTemplate onClose={onCloseModal} />
  };

  return (
    <>
      <EmptyPageState
        img={tasks}
        text="How do you want to start your first task list?"
        primaryButton={{text: "Start blank", onClick: () => setModal("add")}}
        secondaryButton={{text: "LOAD TEMPLATE", onClick: () => setModal("fromTemplate")}}
        removeButton={{text: "REMOVE TASK SECTION", onClick: onRemoveSection}}
        isLoading={removeSectionQuery.isLoading}
      />

      {modal && modals[modal]}
    </>
  );
};
