import {AxiosRequestConfig} from "axios";
import {tTagsGetListParams} from "./interfaces";

const LABEL = "label";
const LOCALE = "locale";

export const tags = {
  labels: {
    get: (params?: tTagsGetListParams): AxiosRequestConfig => ({
      url: "labels",
      params
    }),
    add: (data: {name: string}): AxiosRequestConfig => ({
      method: "POST",
      url: LABEL,
      data
    }),
    update: (id: number, data: {name: string}): AxiosRequestConfig => ({
      method: "POST",
      url: `${LABEL}/${id}`,
      data
    }),
    delete: (id: number): AxiosRequestConfig => ({
      method: "DELETE",
      url: `${LABEL}/${id}`
    }),
    getLabelAssociation: (id: number): AxiosRequestConfig => ({
      method: "GET",
      url: `${LABEL}/${id}/association`
    })
  },
  locales: {
    get: (params?: tTagsGetListParams): AxiosRequestConfig => ({
      url: "locales",
      params
    }),
    add: (data: {name: string}): AxiosRequestConfig => ({
      method: "post",
      url: LOCALE,
      data
    }),
    update: (locale_id: number, data: {name: string}): AxiosRequestConfig => ({
      method: "post",
      url: `${LOCALE}/${locale_id}`,
      data
    }),
    delete: (locale_id: number): AxiosRequestConfig => ({
      method: "delete",
      url: `${LOCALE}/${locale_id}`
    }),
    getLocaleAssociation: (id: number): AxiosRequestConfig => ({
      method: "GET",
      url: `${LOCALE}/${id}/association`
    })
  }
};
