import {FC} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {useContextJobCalendar} from "../../context";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {jobsCalendarApi} from "api/jobs/calendar";
import {useHttp} from "hooks/httpServices/useHttp";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {useNavigate, useParams} from "react-router-dom";

interface iJobCalendarDuplicateProps {
  onClose: () => void;
}

export const JobCalendarDuplicate: FC<iJobCalendarDuplicateProps> = ({onClose}) => {
  const navigate = useNavigate();
  const {id, calendarId} = useParams();

  const {call, isLoading} = useHttp();
  const {activeCalendar, setActiveCalendar, getCalendarList, calendarsListQuery} = useContextJobCalendar(); // prettier-ignore

  const name = useTextInput({value: activeCalendar?.name});

  const onDuplicate = async () => {
    if (!name.checkValidity() || !calendarId) return;
    const payload = {
      name: name.value,
      locked: activeCalendar?.locked
    };
    try {
      const {
        data: {data, message}
      } = await call(jobsCalendarApi.duplicateCalendar(Number(calendarId), payload));
      await getCalendarList(Number(id));
      setActiveCalendar(data);
      navigate(`/jobs/${id}/calendar/${data.id}`);
      eventBus.dispatch("showToast", {text: message});
      onClose();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <ConfirmWindow
      show={true}
      className="JobCalendarDuplicate"
      onClose={onClose}
      title="Duplicate calendar"
      subTitle="Enter a new name"
      cancelBtn={{
        text: "Cancel",
        onClick: onClose
      }}
      confirmBtn={{
        text: "Duplicate"
      }}
      onConfirm={onDuplicate}
      isLoading={isLoading || calendarsListQuery.isLoading}
    >
      <InputField {...name.inputProps} errors={name.errors} />
    </ConfirmWindow>
  );
};
