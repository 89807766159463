import {FC} from "react";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import {Icon} from "components/Icon/Icon";
import {
  iCallSheetsDeliveryConfirmation,
  iDeliveryInfo,
  tCallSheetContact
} from "api/callSheet/types";

interface iCellMethodsProps {
  isAgent?: boolean;
  confirmation?: iCallSheetsDeliveryConfirmation | null;
  deliveryInfo: iDeliveryInfo;
  contact: tCallSheetContact;
}

export const CellMethods: FC<iCellMethodsProps> = ({
  isAgent,
  contact,
  confirmation,
  deliveryInfo
}) => {
  const methodsArray = confirmation?.custom?.methods || deliveryInfo.methods;
  const methods = !isAgent && contact.agent ? "" : methodsArray.map((item, index) => {
        if(index === 1 || item.name === "sms") return item.name.toUpperCase()
        return item.name
    }).join("/"); // prettier-ignore
  const errorMethodsText = !!confirmation?.failed_methods?.length
    ? confirmation?.failed_methods?.join(" + ") + " failed"
    : "";

  if (!isAgent && contact?.agent) return <td className="TrackingTableRow-cell method" />;

  return (
    <td className="TrackingTableRow-cell method">
      {methods}
      {errorMethodsText && (
        <Tooltip message={errorMethodsText}>
          <Icon icon="overdue" color="red" size="xsm" />
        </Tooltip>
      )}
    </td>
  );
};
