import {FC} from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import {ButtonGroup} from "components/ButtonGroup/ButtonGroup";
import {EstimateSelection} from "../EstimateSelection/EstimateSelection";
import {EstimateManagement} from "../EstimateManagement/EstimateManagement";
import {EstimateSync} from "./EstimateSync";
import {AppTooltips} from "components/AppTooltips/AppTooltips";
import {useContextJobBudget} from "../../context/JobBudgetContext";

interface iEstimateHeaderProps {}

export const EstimateHeader: FC<iEstimateHeaderProps> = () => {
  const {estimateId} = useContextJobBudget();
  const location = useLocation();
  const {id} = useParams();
  const urlArray = location.pathname.split("/").filter(r => r !== "" && r !== "/");
  const isExport = urlArray.includes("export");

  return (
    <>
      <EstimateSync />
      <ButtonGroup className="EstimateHeaderBtnGroup">
        <Link className={isExport ? "" : "active"} to={`/jobs/${id}/budget/estimate/${estimateId}`}>
          Build
        </Link>
        <Link
          className={`${isExport ? "active" : ""}`}
          to={`/jobs/${id}/budget/estimate/${estimateId}/export`}
        >
          Export
        </Link>

        <AppTooltips page="estimate" tooltip="Export The Estimate" />
      </ButtonGroup>
      <EstimateSelection />
      <EstimateManagement />
    </>
  );
};
