import {FC} from "react";
import {Button} from "components/shared/Button/Button";
import {useNavigate} from "react-router-dom";
import {absRoutes} from "router/helpers";
import {JobSettings} from "../JobSettings/JobSettings";

export const JobsHeaderButtons: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <JobSettings />
      <Button size="md" color="dark" onClick={() => navigate(absRoutes.jobs.createNewJob)}>
        Add Job
      </Button>
    </>
  );
};
