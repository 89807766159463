import React, {ChangeEvent, SelectHTMLAttributes, ReactElement} from "react";
import {Icon} from "components/Icon/Icon";
import {InputField} from "../InputField/InputField";
import {useTextInputReturn} from "hooks/useTextInput/useTextInput";

import "./SelectInput.scss";

/**
 * Select component params interface
 */
export interface iSelectInputProps<T>
  extends Omit<SelectHTMLAttributes<HTMLSelectElement>, "value" | "onChange"> {
  value?: string | number | undefined;
  onChange?: (e: ChangeEvent<HTMLSelectElement>, v?: T) => void;
  options?: T[] | undefined;
  canSelectEmpty?: boolean;
  targetKeys?: {value: string; label: string};
  label?: string;
  dataTestId?: string;
  className?: string;
  errors?: string[];
  refProp?: React.Ref<HTMLSelectElement>;
  clearAll?: () => void;
  customFieldProps?: SelectHTMLAttributes<HTMLInputElement>;
  customInput: useTextInputReturn;
  isCustomInput: boolean;
}

/**
 * Renders select input
 * @return {React.ReactElement} React.ReactElement
 */

export function SelectInput<T extends {id: number | string}>({
  label,
  value,
  onChange,
  dataTestId,
  options,
  canSelectEmpty,
  className,
  errors = [],
  targetKeys = {value: "value", label: "label"},
  refProp,
  clearAll,
  customFieldProps,
  customInput,
  isCustomInput,
  ...props
}: iSelectInputProps<T>): ReactElement {
  const isError = !!errors.length ? "error" : "";

  return (
    <div className={`SelectInput ${className || ""}`}>
      {label && <label className={`SelectInput-label label ${isError}`}>{label}</label>}

      <div className="SelectInput-icon-container">
        {!isCustomInput && (
          <>
            <select
              ref={refProp}
              name={props?.name}
              className={`SelectInput-input input ${
                !value ? "SelectInput-placeholder" : ""
              } ${isError}`}
              value={value}
              onChange={onChange}
              placeholder="Select..."
              disabled={props?.disabled}
              {...props}
            >
              {props.placeholder && (
                <>
                  {!canSelectEmpty && (
                    <option disabled hidden value="">
                      {props.placeholder}
                    </option>
                  )}
                  {canSelectEmpty && <option value="">{props.placeholder}</option>}
                </>
              )}

              {!props.placeholder && canSelectEmpty && <option value="">{"Select ..."}</option>}
              {Array.isArray(options) &&
                options.map((item: any) => {
                  const value = item?.[targetKeys?.value];
                  const label = item?.[targetKeys?.label];

                  return (
                    <option className="SelectInput-option" value={value} key={item?.id || value}>
                      {label}
                    </option>
                  );
                })}
            </select>

            <Icon icon="arrow-down" size="md" color="dark-gray" className="SelectInput-icon icon" />
          </>
        )}

        {isCustomInput && (
          <>
            <InputField
              {...customInput.inputProps}
              errors={customInput?.errors}
              {...customFieldProps}
            />
            <button
              onClick={clearAll}
              type="button"
              className="SelectInput-icon icon SelectInput-button"
            >
              <Icon icon="close" size="sm" color="gray" />
            </button>
          </>
        )}
      </div>

      {errors.map((error, i) => (
        <span key={i} className="SelectInput-error-message">
          <span>{error}</span>
        </span>
      ))}
    </div>
  );
}
