import React, {useState} from "react";

type tUseResize = {
  initHeightVal: number;
  minHeight: number;
};

// TODO need to improve changing height
export const useResize = ({initHeightVal, minHeight}: tUseResize) => {
  const [height, setHeight] = useState(initHeightVal);

  const resizeHandler = (mouseDownEvent: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const startSize = height;
    const startPosition = mouseDownEvent.pageY;

    document.body.style.cursor = "row-resize";

    function onMouseMove(mouseMoveEvent: MouseEvent) {
      const newHeight = startSize - startPosition + mouseMoveEvent.pageY;

      if (newHeight < minHeight) return;
      setHeight(newHeight);
    }

    function onMouseUp() {
      document.body.removeEventListener("mousemove", onMouseMove);
      document.body.style.cursor = "default";
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, {once: true});
  };

  return {
    resizeHandler,
    setHeight,
    height,
    minHeight
  };
};

/** How to use */

// export const OverviewPipelineTable = () => {
//   const {height, resizeHandler} = useResize({initHeightVal: 300, minHeight: 100});

//   return (
//     <>
//       <div className="OverviewJobsPipeline-columns" style={{height, minHeight: 100}}>
//         <OverviewPipelineColumn title="Bidding" color="gray" data={mockBidding} />
//         <OverviewPipelineColumn title="Working" color="yellow" data={mockWorking} />
//         <OverviewPipelineColumn title="Wrap & BILL" color="blue" data={mockWrapBill} />
//         <OverviewPipelineColumn title="Done" color="green" data={mockDone} />
//       </div>

//       <div className="OverviewJobsPipeline-divider" onMouseDown={resizeHandler}>
//         <span className="hiddenElements">9 jobs hidden</span>
//       </div>
//     </>
//   );
// };
