import {useEffect} from "react";
import {trackApi} from "api/budget/track";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useTrackLineInput} from "./useTrackLineInput";
import {useSelect} from "hooks/inputHooks/useSelect";
import {eventBus} from "EventBus/EventBus";
import {useResponseType} from "pages/Jobs/JobDetails/JobBudget/Track/_common/useResponseType";
import {useContextTrack} from "pages/Jobs/JobDetails/JobBudget/Track/_context/TrackContext";
import {useContextExpenseList} from "pages/Jobs/JobDetails/JobBudget/Track/ExpenseList/context/ExpenseListContext";

export type tUseChangeExpenseInputValue = {
  id: number;
  defaultValue?: string;
  defaultSelectValue?: string;
  type: "actual" | "working";
  estimate?: string;
};

export const useChangeExpenseInputValue = (props: tUseChangeExpenseInputValue) => {
  const {response_type, isExpense} = useResponseType();
  const {defaultValue, id, type, defaultSelectValue} = props;
  const {setTrackData, trackCurrList} = useContextTrack();
  const {setExpenseList} = useContextExpenseList();

  // const currRates = trackData?.rates?.[props?.estimate || "default"];
  const currRates = trackCurrList?.filter(item => !!item?.exchange_rate);

  const {call, isLoading} = useHttp();

  const calls = (value: string, value2?: string) => {
    return {
      actual: trackApi.setActualAmount(id, {amount: value, currency_code: value2, response_type}),
      working: trackApi.setWorkingDetail(id, {amount: value, currency_code: value2, response_type}) // prettier-ignore
    };
  };

  const trackInputProps = useTrackLineInput({
    defaultValue: defaultValue || "",
    onBlurCallBack: onChange
  });

  const select = useSelect({
    options: currRates?.map(item => ({value: item.code, label: item.code})),
    value: defaultSelectValue || "USD",
    placeholder: "USD",
    isRequired: true
  });

  useEffect(() => {
    if (currRates?.length) {
      const list = currRates?.map(item => ({value: item.code, label: item.code}));
      select?.setOptions(list);
      // select?.setSelected(list?.[USD_INDEX ?? 0]);
    }
  }, [currRates?.length]); // eslint-disable-line

  async function onChange(value: string) {
    // if (value === defaultValue && select?.selected?.value === defaultSelectValue) return;
    if (
      value === defaultValue &&
      (select?.selected?.value === defaultSelectValue ||
        (select?.selected?.value === "USD" && !defaultSelectValue))
    )
      return;

    try {
      const res = await call(calls(value, select?.selected?.value)[type]);
      isExpense && setExpenseList?.(() => res?.data?.data);
      !isExpense && setTrackData(() => res?.data?.data);
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  }

  return {trackInputProps, isLoading, select, onChange};
};
