import {FC, ReactNode, MouseEvent} from "react";
import {Button, iButtonProps} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import "./FormWrap.scss";

export interface iFormWrapProps {
  headerTitle?: string;
  subtitle?: string;
  cancelTitle?: string;
  submitTitle: string | ReactNode;
  onCancel?: () => void;
  isLoading?: boolean;
  idForm: string;
  type?: "titleCenterAndOneBtn" | "titleCenterAndTwoBtn";
  withBackIcon?: boolean;
  onBack?: () => void;
  firstButLight?: boolean;
  customButton?: ReactNode;
  mainButtonColor?: iButtonProps["color"];
  withScroll?: boolean;
  disabled?: boolean;
  footer?: ReactNode;
}

export const FormWrap: FC<iFormWrapProps> = props => {
  const {
    children,
    headerTitle,
    cancelTitle = "Cancel",
    onCancel,
    submitTitle,
    withScroll = true,
    disabled,
    footer
  } = props;
  const {isLoading, idForm, type = "", onBack, subtitle = "", mainButtonColor = "light"} = props;

  const cancelHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onCancel?.();
  };

  return (
    <div className={`FormWrap ${type} ${withScroll ? "withScroll" : ""} `}>
      {headerTitle && (
        <div className="FormWrap-header">
          {onBack && (
            <button className="FormWrap-header-button" onClick={onBack}>
              <Icon icon="arrow-left" color="light-gray" size="lg" />
            </button>
          )}
          <h2 className="FormWrap-header-title">{headerTitle}</h2>
          <p className="FormWrap-header-subtitle">{subtitle}</p>
        </div>
      )}

      <div className="FormWrap-main">{children}</div>

      <div className="FormWrap-footer">
        {footer}
        {onCancel && (
          <Button size="md" onClick={cancelHandler} disabled={isLoading}>
            {cancelTitle}
          </Button>
        )}

        <Button
          disabled={disabled}
          color={mainButtonColor}
          size="md"
          type="submit"
          loading={isLoading}
          form={idForm}
        >
          {submitTitle}
        </Button>
      </div>
    </div>
  );
};
