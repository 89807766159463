import {FC, FormEvent} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {TagsField} from "components/shared/FormComponents/TagsField/TagsField";
import {Button} from "components/shared/Button/Button";
import {Loading} from "components/shared/Loading/Loading";
import {UserUploadFile} from "components/shared/UserUploadFile/UserUploadFile";
import {tUseCompanyMainForm} from "./useCompanyFormMain";
import {DynamicFieldsWrap} from "components/shared/FormComponents/DynamicFields/DynamicFieldsWrap";
import {COMPANY_FORM_ID} from "../../model";
import {DeleteCompany} from "./DeleteCompany";
import {DynamicFieldsWrap as DynamicFieldsWrapPhone} from "components/shared/FormComponents/DynamicFieldsPhone/DynamicFieldsWrap";
import {DynamicFieldsWrap as DynamicFieldsWrapEmail} from "components/shared/FormComponents/DynamicFieldsEmail/DynamicFieldsWrap";

interface iCompanyFormMainProps {
  toNextTab: () => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  formDataMain: tUseCompanyMainForm;
}

export const CompanyFormMain: FC<iCompanyFormMainProps> = ({toNextTab, onSubmit, formDataMain}) => {
  const {formData, localesOptions, labelsOptions} = formDataMain;
  const {isLoading, company, setCompanyPhoto, companyPhoto} = formDataMain;

  const {phonesOptions, phones, setPhones, phonesRef} = formDataMain;
  const {emailsOptions, emails, setEmails, emailsRef} = formDataMain;
  const {accPlatformOptions, accounts, setAccounts, socPlatformRef} = formDataMain;

  const id = company?.id;
  const creatorName = `${company?.creator.first_name} ${company?.creator.last_name}`;

  if (isLoading) return <Loading className="CompaniesLoading-form" />;

  function filterOption<T extends {name: string; id: number}>(arr: T[]) {
    return arr.filter(p => p.name.toLowerCase() !== "home");
  }

  return (
    <form className="CompanyFormMain" id={COMPANY_FORM_ID} onSubmit={onSubmit}>
      <UserUploadFile avatar={companyPhoto} setAvatar={setCompanyPhoto} />
      <div className="CompanyForm-section">
        <InputField
          {...formData.name.inputProps}
          errors={formData.name.errors}
          id="company_name"
          label="Company Name"
        />
        <InputField
          {...formData.website.inputProps}
          errors={formData.website.errors}
          id="website"
          label="Website"
        />
      </div>

      <div className="CompanyForm-section">
        <h5 className="CompanyForm-section-title">Phone</h5>
        <DynamicFieldsWrapPhone
          ref={phonesRef}
          dataList={phones as any}
          setDataList={setPhones}
          selectOptions={filterOption(phonesOptions)}
          type="phones"
          buttonName="Add Phone"
          title="Delete phone number?"
          notificationType="deletePhone"
          notificationData={`${company?.name || ""}`}
        />
      </div>

      <div className="CompanyForm-section">
        <h5 className="CompanyForm-section-title">Email</h5>
        <DynamicFieldsWrapEmail
          ref={emailsRef}
          dataList={emails as any}
          setDataList={setEmails}
          selectOptions={filterOption(emailsOptions)}
          type="emails"
          buttonName="Add Email"
          title="Delete email?"
        />
      </div>

      <div className="CompanyForm-section">
        <h5 className="CompanyForm-section-title">Tags</h5>
        <TagsField
          {...formData.locales.inputProps}
          errors={formData.locales.errors}
          options={localesOptions}
          placeholder=""
          label="Locales"
        />
        <TagsField
          {...formData.labels.inputProps}
          errors={formData.labels.errors}
          options={labelsOptions}
          placeholder=""
          label="Labels"
        />
      </div>

      <div className="CompanyForm-section">
        <h5 className="CompanyForm-section-title">Accounts</h5>
        <DynamicFieldsWrap
          ref={socPlatformRef}
          dataList={accounts}
          setDataList={setAccounts}
          selectOptions={accPlatformOptions}
          type="socPlatform"
          buttonName="Add Account"
          title="Delete account?"
        />
      </div>

      {id ? (
        <DeleteCompany id={id} created={{date: company?.created_at, name: creatorName}} />
      ) : (
        <Button color="light" className="CompanyForm-next" onClick={toNextTab}>
          CONTINUE TO ADDRESS & BILLING
        </Button>
      )}
    </form>
  );
};
