import {AxiosRequestConfig} from "axios";
import {iSearchCitiesParams, tCurrencyRates} from "./types";

export const common = {
  getPronouns: (): AxiosRequestConfig => ({url: "pronouns"}),
  getPlatforms: () => ({url: "platforms"}),
  getDepartments: () => ({url: "departments"}),
  getRoles: () => ({url: "roles"}),
  getOfficeLocations: () => ({url: "office_locations"}),
  getStatuses: () => ({url: "statuses"}),
  getDietaryRestrictions: () => ({url: "dietary_restrictions"}),
  getUserRelationships: () => ({url: "user_relationships"}),
  addUserRelationships: (data: {name: string}):AxiosRequestConfig => ({method: "post", url: "user_relationship", data}), // prettier-ignore
  getVersatileRelationships: () => ({url: "versatile_relationships"}),
  getAgencies: () => ({url: "agencies"}),
  searchCities: (params?: iSearchCitiesParams): AxiosRequestConfig => ({url: "city", params}),
  getCountries: (): AxiosRequestConfig => ({url: "countries"}),
  getUsStates: (): AxiosRequestConfig => ({url: "states"}),
  getEmailTypes: () => ({url: "email_types"}),
  getPhoneTypes: () => ({url: "phone_types"}),
  getSections: () => ({url: "job/sections"}),
  getHoldStatuses: () => ({url: "hold_statuses"}),
  getCurrencyRates: (): AxiosRequestConfig => ({url: `job_rates/defaults`}),
  editCurrencyRates: (data: tCurrencyRates[]): AxiosRequestConfig => ({
    url: `job_rates/defaults`,
    method: "POST",
    data
  }),
  // estimate's lists
  getPayStatuses: () => ({url: "job_estimate/pay_statuses"}),
  getQBStatuses: () => ({url: "job_estimate/qb_statuses"}),
  getPayIds: () => ({url: "job_estimate/pay_id"})
};
