import {FC, useState} from "react";
import {ExportModal} from "pages/Contacts/commonComponent/ExportModal/ExportModal";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {people as peopleApi} from "api/contacts/people";
import {companies as companiesApi} from "api/contacts/companies";
import {ImgWithFallback} from "components/shared/ImgWithFallback/ImgWithFallback";
import "./PeopleDetailsHeader.scss";

interface iPeopleDetailsHeaderProps {
  id: number | undefined;
  showEditForm?: () => void;
  avatar?: string | null | undefined;
  name: string;
  sub_name?: string;
  isFavorite: boolean;
  website?: string | null;
  type: "people" | "companies";
}

export const PeopleDetailsHeader: FC<iPeopleDetailsHeaderProps> = ({
  avatar,
  name,
  sub_name,
  showEditForm,
  id,
  isFavorite,
  website,
  type
}) => {
  const [showExportModal, setShowExportModal] = useState(false);

  const exportConfig = {
    companies: companiesApi.exportCompanyById,
    people: peopleApi.exportPersonById
  };
  return (
    <div className="PeopleDetailsHeader">
      <ImgWithFallback
        src={avatar || ""}
        fallBackType="avatar"
        className="PeopleDetailsHeader-img"
        alt="contact person avatar"
      />

      <div className="PeopleDetailsHeader-main">
        <h1 className="PeopleDetailsHeader-main-title">
          {name}
          {isFavorite && <span className="PeopleDetailsHeader-status">Preferred</span>}
        </h1>

        {website && (
          <a
            href={website}
            target="_blank"
            className="PeopleDetailsHeader-main-subtitle"
            rel="noreferrer"
          >
            {website}
          </a>
        )}

        {sub_name && <div className="PeopleDetailsHeader-main-subtitle">{sub_name}</div>}
      </div>

      <div className="PeopleDetailsHeader-buttons">
        {showEditForm && (
          <Button size="md" color="light" onClick={showEditForm}>
            <Icon icon="edit" size="sm" /> Edit
          </Button>
        )}

        <Button size="md" color="light" onClick={() => setShowExportModal(true)}>
          <Icon icon="export-secondary" size="sm" />
          Export
        </Button>
      </div>

      <ExportModal
        id={id}
        type={type}
        exportRequest={exportConfig[type]}
        onClose={() => setShowExportModal(false)}
        show={showExportModal}
        title="Export As..."
      />
    </div>
  );
};
