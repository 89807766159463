import {tFieldGroupRow} from "./interfaces";
import {uid} from "../../../../helpers/misc";
import {tOption} from "./TeamFieldGroupItem/TeamFieldGroupItem";

export interface iTeamRow {
  person: tOption;
  relationship: tOption;
}
const defaultOptions = {
  value: "",
  label: ""
};
export const createTeamNewRowObject = (props?: iTeamRow): tFieldGroupRow => ({
  id: uid(),
  value: {
    person: props?.person ?? defaultOptions,
    relationship: props?.relationship ?? defaultOptions
  },
  errors: [],
  isValid: false,
  isDirty: !!props?.person
});
