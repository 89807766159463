import {useState} from "react";
import {components, OptionProps} from "react-select";
import "./CustomOption.scss";

type Props = OptionProps & {
  data: {
    id: number;
    custom: boolean;
    label: string;
    value: string;
    subMenuOptions: Array<{label: string; value: string}>;
  };
};

export const CustomOption = (props: Props) => {
  const [submenu, setSubmenu] = useState(false);

  const handleOption = () => {
    setSubmenu(prev => !prev);
  };

  const handleSubOption = (item: any) => {
    props?.setValue({...item, id: data?.id}, "select-option");
  };

  const {data} = props;

  if (!data?.custom) return <components.Option {...props} />;

  const isOptions = !!data?.subMenuOptions?.length;
  return (
    <div onClick={handleOption} className="CustomOption">
      <div className="CustomOption-label"> {data.label}</div>

      {submenu && (
        <div className="CustomOption-submenu">
          {!isOptions && <div className="CustomOption-option noOption">No options</div>}

          {isOptions &&
            data?.subMenuOptions?.map(item => {
              return (
                <div
                  key={item?.value}
                  className="CustomOption-option"
                  onClick={() => handleSubOption(item)}
                >
                  {item?.label}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};
