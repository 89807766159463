import {FC} from "react";
import {AppTooltips} from "components/AppTooltips/AppTooltips";
import {LIST_OPTIONS} from "../../_modals/ExpenseListViewOptions/listViewOptions";
import {expenseListTableTitles} from "./expenseListTableTitles";
import {useCalculatePosition} from "components/AppTooltips/useCalculatePosition";
import {getUsMoneyFormat} from "helpers/formattingData";

type tExpenseTableFooterProps = {
  hiddenColumns: string[];
  totalData: {
    wDetails: number;
    aDetails: number;
    charged: number;
  };
};

export const ExpenseTableFooter: FC<tExpenseTableFooterProps> = ({hiddenColumns, totalData}) => {
  const {styles, chevronPosition, ref} = useCalculatePosition({
    parentId: "expense-table-wrap-id",
    chevronPosition: "bottomRight",
    addToY: -8
  });

  return (
    <tr className="ExpenseTableRowFooter">
      {expenseListTableTitles?.map(item => {
        if (hiddenColumns?.find(hItems => hItems === item?.viewOptionId)) return null;

        return (
          <th key={item?.id} className="ExpenseTableRowFooter-cell">
            {item?.viewOptionId === LIST_OPTIONS?.description?.id && "Total"}
            {item?.viewOptionId === LIST_OPTIONS?.wTotal?.id &&
              `${getUsMoneyFormat(totalData.wDetails)}`}
            {item?.viewOptionId === LIST_OPTIONS?.aTotal?.id &&
              `${getUsMoneyFormat(totalData.aDetails)}`}
            {item?.viewOptionId === LIST_OPTIONS?.charged?.id && (
              <span ref={ref}>
                {`${getUsMoneyFormat(totalData.charged)}`}
                <AppTooltips
                  page="expenseList"
                  tooltip="ACCOUNTING TOTAL"
                  chevronPosition={chevronPosition}
                  positionStyles={styles}
                  withAnimation
                />
              </span>
            )}
          </th>
        );
      })}
    </tr>
  );
};
