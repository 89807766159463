import {FC, useState} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {useHttp} from "hooks/httpServices/useHttp";
import moment from "moment";
import {eventBus} from "EventBus/EventBus";
import {useSetApiParams} from "hooks/useSetApiParams";
import {locationApi} from "api/location";
import {useContextLocations} from "pages/Locations/context/LocationsContext";
import {Checkbox} from "components/shared/FormComponents/Checkbox/Checkbox";
import "./ExportModalLocations.scss";

interface iExportModalLocationsProps {
  id?: number | undefined;
  onClose: () => void;
  show: boolean;
  title?: string;
}

export const ExportModalLocations: FC<iExportModalLocationsProps> = ({
  show = true,
  onClose,
  title = ""
}) => {
  const {locationList} = useContextLocations();
  const apiParams = useSetApiParams();
  const {call, isLoading} = useHttp();
  const [exportAll, setExportAll] = useState(false);

  const filename = (type: string) => {
    return `${type}_contacts_${moment().format("MM_DD")}.csv`;
  };

  const onExportContacts = async () => {
    const params = {
      ...apiParams?.preparedParams,
      locales: apiParams?.preparedParams.locales?.split(",").map(v => +v)
    };

    try {
      const {
        data: {url}
      } = await call(locationApi.exportLocations(exportAll ? {} : params));
      const link = document.createElement("a");
      link.href = url;
      link.download = filename("locations");
      link.click();
      link.remove();

      eventBus.dispatch("showToast", {text: "Locations were exported successfully."});
      onClose();
    } catch (error: any) {
      eventBus.dispatch("showToast", {type: "error", text: error?.message});
    }
  };

  const onChange = () => setExportAll(v => !v);

  const locationsLength = locationList?.data?.data?.data?.length || 0;
  const subtitle = `Export ${locationsLength} displayed location${locationsLength > 1 ? "s" : ""}`;

  return (
    <ConfirmWindow
      className="ExportModalLocations"
      title={title}
      show={show}
      onClose={onClose}
      subTitle={exportAll ? "" : subtitle}
      confirmBtn={{text: "Export"}}
      cancelBtn={{text: "Cancel"}}
      onConfirm={onExportContacts}
      isLoading={isLoading}
    >
      <div className="ExportModalLocations-body">
        <Checkbox checked={exportAll} label="Export all locations" onChange={onChange} />
      </div>
    </ConfirmWindow>
  );
};
