import {useLayoutEffect, useRef, useState} from "react";

export const useOffsetTop = (deps?: any) => {
  const [offsetTop, setOffsetTop] = useState<number>();
  const elementRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const offsetTop = elementRef.current?.getBoundingClientRect()?.top;
    offsetTop && setOffsetTop(offsetTop);
  }, [deps]);

  return {offsetTop, elementRef};
};
