import {FC} from "react";
import {tUserAccounts} from "../../../api/team/types";
import {tIcon, Icon} from "components/Icon/Icon";
import "./AccountsList.scss";

interface iAccountsList {
  accountsList: Pick<tUserAccounts, "platform" | "value" | "link">[];
}

export const AccountsList: FC<iAccountsList> = ({accountsList}) => {
  if (!accountsList.length) return null;

  return (
    <div className="AccountsList">
      {accountsList?.map(({platform, value, link}, i) => (
          <div className="AccountsList-item" key={i}>
            <Icon icon={platform?.name as tIcon} color="dark-gray" />
            <a href={link} target="_blank" rel="noreferrer" className="AccountsList-link">
              {value}
            </a>
          </div>
        ))}
    </div>
  );
};
