import {expenseListTableTitles} from "../expenseListTableTitles";
import {useAdjustWidth} from "hooks/useAdjustWidth";

export type tAdjustWidth = {
  [x: string]: {
    resizeHandler: (mouseDownEvent: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    width: number;
    minWidth: number;
  };
};

export const useCreateTableColWidth = () => {
  const minWidthVal = 20;
  const adjustWidth = {
    // id's number from useCellsList
    [expenseListTableTitles[1].id]: useAdjustWidth({initWidth: 0, minWidthVal}),
    [expenseListTableTitles[2].id]: useAdjustWidth({initWidth: 140, minWidthVal}),
    [expenseListTableTitles[3].id]: useAdjustWidth({initWidth: 300, minWidthVal}),
    [expenseListTableTitles[4].id]: useAdjustWidth({initWidth: 340, minWidthVal}),
    // [expenseListTableTitles[5].id]: useAdjustWidth({initWidth: 320, minWidthVal: 120}),
    [expenseListTableTitles[17].id]: useAdjustWidth({initWidth: 300, minWidthVal})
  };

  return {adjustWidth};
};
