import {FC} from "react";
import {Icon} from "components/Icon/Icon";
import {ReactSelect} from "components/shared/FormComponents/ReactSelect/ReactSelect";
import {tViewersFile} from "../types";
import "./FileNavigation.scss";

interface iFileNavigationProps {
  files: Array<tViewersFile> | undefined;
  selectedFile: tViewersFile | undefined;
  changeFile: (file: tViewersFile) => void;
}

export const FileNavigation: FC<iFileNavigationProps> = props => {
  const {files = [], selectedFile, changeFile} = props;

  const onNavItem = (position: number, filesArr?: tViewersFile[]) => {
    const actualFiles = filesArr || files;
    if (!actualFiles) return;
    const currentIndex = actualFiles?.findIndex(file => file.id === selectedFile?.id) + position;
    changeFile?.(actualFiles[currentIndex]);
  };

  const onChangeFile = (e: any) => {
    changeFile(e);
  };

  if (!files) return null;

  const prevIsDisabled =
    files?.findIndex(value => value.id === selectedFile?.id) === 0 || !files?.length;
  const nextIsDisabled = files?.findIndex(value => value.id === selectedFile?.id) === files.length - 1; // prettier-ignore

  return (
    <div className="FileNavigation">
      <button disabled={prevIsDisabled} onClick={() => onNavItem(-1)}>
        <Icon color="dark-gray" size="xsm" icon="triangle-left" />
      </button>

      <ReactSelect
        options={files}
        value={selectedFile ?? null}
        getOptionLabel={(label: tViewersFile) => label?.name || "expenses_file"}
        hideArrow={false}
        menuPlacement="top"
        onChange={onChangeFile}
      />

      <button disabled={nextIsDisabled} onClick={() => onNavItem(1)}>
        <Icon color="dark-gray" size="xsm" icon="triangle-right" />
      </button>
    </div>
  );
};
