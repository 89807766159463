import {FC} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {Button} from "components/shared/Button/Button";

import "./ModalPreviewPdf.scss";

interface iModalPreviewPdfProps {
  onClose: () => void;
  withButton?: boolean;
  saveOnDropbox?: () => void;
  pdfUrl?: string;
  isUploadingDropbox?: boolean;
  fileName: string;
}

export const ModalPreviewPdf: FC<iModalPreviewPdfProps> = ({
  onClose,
  withButton = true,
  saveOnDropbox,
  pdfUrl,
  children,
  isUploadingDropbox,
  fileName
}) => {
  return (
    <ModalPopup
      size="xlg"
      withoutPaddings
      show={true}
      onClose={onClose}
      className="ModalPreviewPdf"
    >
      <div className="ModalPreviewPdf-toolbar">
        {withButton && (
          <>
            <a
              className={`Button md light ${pdfUrl ? "" : "disabled"}`}
              href={pdfUrl}
              download={fileName}
            >
              Download pdf
            </a>
            <Button onClick={saveOnDropbox} loading={isUploadingDropbox} size="md" color="light">
              Save to Dropbox
            </Button>
          </>
        )}
      </div>
      {children}
    </ModalPopup>
  );
};
