import {ToastTemplates} from "./ToastTemplates";
import {useState, MouseEvent} from "react";
import {iAddToast, iToastListItem} from "./interfaces";
import {uid} from "helpers/misc";

const TIMEOUT = 4000;

type tTimeout = ReturnType<typeof setTimeout>;
type tMessageModel = iToastListItem & {timeout: tTimeout | null};

export const useToastMessages = () => {
  const [messages, setMessages] = useState<tMessageModel[]>([]);

  const removeToast = (toastId: string) => {
    setMessages(prevState => prevState.filter(item => item.id !== toastId));
  };

  const addToast = ({type = "success", text}: iAddToast) => {
    const id = uid();
    const newToast = {...ToastTemplates[type], id, ...(text && {text})};

    setMessages(prevState => [
      ...prevState,
      {...newToast, timeout: setTimeout(() => {removeToast(id)}, TIMEOUT)} // prettier-ignore
    ]);
  };

  const handleMouseLeave = (event: MouseEvent<HTMLDivElement>) => {
    const {id} = event.currentTarget;
    setMessages(prevState =>
      prevState.map(item => {
        return item.id === id ? {...item, timeout: setTimeout(() => removeToast(item.id), TIMEOUT)} : item; // prettier-ignore
      })
    );
  };

  const handleMouseEnter = (event: MouseEvent<HTMLDivElement>) => {
    const {id} = event.currentTarget;
    setMessages(prevState =>
      prevState.map(item => {
        item?.timeout && clearTimeout(item.timeout);
        return item.id === id ? {...item, timeout: null} : item;
      })
    );
  };

  return {
    messages,
    addToast,
    removeToast,
    handleMouseEnter,
    handleMouseLeave
  };
};
