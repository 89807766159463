import {FC, MouseEvent, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {useContextJobGrid} from "../JobGridContext";
import {CreateGridForm} from "../GridForms/CreateGridForm";
import {DropdownSubmenu} from "components/shared/DropdownMenu/DropdownSubmenu";
import {CreateGridFromTemplate} from "../GridForms/CreateGridFromTemplate";

type tSelectGridProps = {};
type formType = "create" | "fromTemplate";

export const SelectGrid: FC<tSelectGridProps> = () => {
  const {gridList, grid, setCurrGridId} = useContextJobGrid();
  const [showForm, setShowForm] = useState<formType | null>(null);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const toggleMenu = (event: MouseEvent<HTMLElement> | null) => setAnchor(event?.currentTarget || null); // prettier-ignore

  const showModal = (type: formType) => {
    setShowForm(type);
    setAnchor(null);
  };

  const forms = {
    create: (
      <CreateGridForm closeTemplateModal={() => setShowForm(null)} showTemplateModal={!!showForm} />
    ),
    fromTemplate: <CreateGridFromTemplate closeTemplateModal={() => setShowForm(null)} />
  };

  return (
    <div className="SelectGrid">
      <Button color="outline" size="sm" onClick={toggleMenu} className="SelectGrid-btn">
        <span>{grid?.name}</span>
        <Icon size="xsm" icon={!!anchor ? "triangle-up" : "triangle-down"} />
      </Button>

      <DropdownMenu
        className="EstimateSelection-dropdown"
        anchor={anchor}
        onClose={() => toggleMenu(null)}
        withSubmenu
        btnHover
      >
        {gridList?.map(item => {
          return (
            <Button
              key={item.id}
              size="sm"
              width="fullWidth"
              onClick={() => setCurrGridId(item.id)}
            >
              {item.name}
            </Button>
          );
        })}

        <hr />

        <DropdownSubmenu title=" Add new grid" position="left">
          <Button size="sm" width="fullWidth" onClick={() => showModal("create")}>
            Blank grid
          </Button>
          <Button size="sm" width="fullWidth" onClick={() => showModal("fromTemplate")}>
            Load template
          </Button>
        </DropdownSubmenu>
      </DropdownMenu>

      {!!showForm && forms[showForm]}
    </div>
  );
};
