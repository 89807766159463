import {tTeamListParams} from "api/team/types";
import {useSelect} from "hooks/inputHooks";
import {useContextTeamDetails} from "pages/Team/TeamDetailsContext";
import {useSearchParams} from "react-router-dom";

type ArrList = Array<{id: number; name: string}>;

export const useFilterInputs = () => {
  const {departmentsList, roleList, statusList} = useContextTeamDetails();
  const [searchParams] = useSearchParams();

  function getSelectDefaultVal<T extends ArrList>(list: T, paramName: keyof tTeamListParams) {
    const val = list?.find(item => String(item.id) === searchParams.get(paramName));
    return val && String(val?.id);
  }

  console.log("Render: useFilterInputs");

  const filters = {
    departments: useSelect({
      options: departmentsList,
      targetKeys: {value: "id", label: "name"},
      value: getSelectDefaultVal(departmentsList, "departments")
    }),
    role_id: useSelect({
      options: roleList,
      targetKeys: {value: "id", label: "name"},
      value: getSelectDefaultVal(roleList, "role_id")
    }),
    status_id: useSelect({
      options: statusList,
      targetKeys: {value: "id", label: "name"},
      value: getSelectDefaultVal(statusList, "status_id")
    })
  };

  return {filters};
};
