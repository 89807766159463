import {FC, useEffect, useState} from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {components, SingleValue} from "react-select";
import {useHttp} from "hooks/httpServices/useHttp";
import {Icon} from "components/Icon/Icon";
import {tFieldGroupRow} from "../interfaces";
import {useContextContactsDetails} from "pages/Contacts/context/ContactsDetailsContext";
import {common} from "api/common";
import "./TeamFieldGroupItem.scss";
import {eventBus} from "EventBus/EventBus";

export type tOption = {
  value: string;
  label: string;
};
interface iTeamFieldItemProps {
  item: tFieldGroupRow;
  onChange: (data: tFieldGroupRow) => void;
  handleRemove: (id: string, confirm?: boolean) => void;
  selectData: tOption[];
  onClearSelect: (id: string) => void;
  onInputChange: (value: string) => void;
  isItemsError: boolean;
}
export const TeamFieldGroupItem: FC<iTeamFieldItemProps> = ({
  item,
  onChange,
  handleRemove,
  selectData,
  onClearSelect,
  onInputChange,
  isItemsError
}) => {
  const {userRelationships} = useContextContactsDetails();
  const {call: getRelationships} = useHttp();
  const {call: callCreating, isLoading: creatingIsLoading} = useHttp();
  const defaultPerson = item?.value?.person as unknown as tOption;
  const [person, setPerson] = useState<tOption | null>(defaultPerson || null);
  const [title, setTitle] = useState<tOption | null>(item.value.relationship ?? null);
  const [options, setOptions] = useState<tOption[]>(userRelationships);

  useEffect(() => {
    if (!userRelationships) return;
    setOptions(formatRelationships(userRelationships));
  }, [userRelationships]);

  function formatRelationships(data: any[]) {
    if (!data || !data.length) return [];
    return data.map(({id, name}: any) => ({
      value: id,
      label: name
    }));
  }

  useEffect(() => {
    if (!person?.value || !title?.value) return;
    const personValue = {
      value: person.value,
      label: person.label
    };
    const relationship = {
      value: title.value,
      label: title.label
    };
    onChange({
      ...item,
      value: {
        person: personValue,
        relationship
      }
    });
  }, [person, title]); // eslint-disable-line

  const handleChangeUser = (user: any | null) => {
    if (!user) return;
    setPerson({value: user.id, label: `${user?.first_name} ${user?.last_name}`});
  };

  const handleChangeRelationship = (option: SingleValue<tOption | null>) => {
    if (!option?.value) onClearSelect(item.id);
    setTitle(option);
  };

  const onCreateOption = async (value: string) => {
    try {
      const response = await callCreating(common.addUserRelationships({name: value}));
      const {
        data: {data}
      } = await getRelationships(common.getUserRelationships());

      const newTitles = formatRelationships(data);
      setOptions(newTitles);
      const createdItem = newTitles.find((item: tOption) => item.label === value);
      createdItem && setTitle(createdItem);
      eventBus.dispatch("showToast", {text: response?.data?.message});
    } catch (error: any) {
      eventBus.dispatch("showToast", {type: "error", text: error?.data?.message});
    }
  };

  const handleBlur = () => {
    handleRemove(item.id, true);
  };

  const isErrorPersonSearchClassname = isItemsError ? "is-error" : "";

  return (
    <div className="FieldItem">
      {person?.value && (
        <div className="FieldItem-row">
          <div className="FieldItem-name">
            <span>{person?.label}</span>
            <button type="button" onClick={() => handleRemove(item.id)}>
              <Icon icon="close" size="sm" />
            </button>
          </div>
          <div className={`FieldItem-relationship ${!!item?.errors.length ? "with-error" : ""}`}>
            <CreatableSelect
              isClearable
              onCreateOption={onCreateOption}
              getOptionLabel={(option: any) => {
                if (option?.__isNew__) {
                  return `Press enter to add new title "${option?.value}"`;
                }
                return option.label;
              }}
              getOptionValue={(option: any) => {
                return option?.value;
              }}
              value={title}
              options={options}
              onChange={handleChangeRelationship}
              className="FieldItem-selectCreate"
              classNamePrefix="FieldItem-select"
              isLoading={creatingIsLoading}
              isDisabled={creatingIsLoading}
            />
            <div className="FieldItem-error">
              {!creatingIsLoading && item?.errors?.map(error => <span key={error}>{error}</span>)}
            </div>
          </div>
        </div>
      )}

      {!item.value.person.value && !person?.value && (
        <>
          <Select
            isClearable={false}
            placeholder="Find contact to link as a team member"
            onBlur={handleBlur}
            autoFocus={true}
            onChange={handleChangeUser}
            onInputChange={onInputChange}
            className={`FieldItem-personSelect ${isErrorPersonSearchClassname}`}
            classNamePrefix="FieldItem-select"
            options={selectData}
            getOptionLabel={(option: any) => `${option?.first_name} ${option?.last_name}`}
            components={{
              NoOptionsMessage: props => (
                <components.NoOptionsMessage {...props}>
                  <span>No results found.</span>
                </components.NoOptionsMessage>
              )
            }}
          />
        </>
      )}
    </div>
  );
};
