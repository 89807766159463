import {FC, useState} from "react";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {EmptyPageState} from "components/shared/EmptyPageState/EmptyPageState";
import budgetImg from "assets/images/Budget.svg";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {BudgetEstimateForm} from "../forms/BudgetEstimateForm/BudgetEstimateForm";
import {BudgetTemplateForm} from "../forms/BudgetTemplateForm";
import "./BudgetEmptyState.scss";

type tEmptyStateProps = {
  [key: string]: any;
};

export const EmptyState: FC<tEmptyStateProps> = () => {
  const {removeSection, removeSectionQuery} = useContextJobDetails();
  const onRemoveSection = () => removeSection();
  const [showModal, setShowModal] = useState<"estimate" | "template" | null>(null);
  const closeModal = () => setShowModal(null);

  return (
    <>
      <EmptyPageState
        img={budgetImg}
        text="How do you want to start building your budget?"
        primaryButton={{text: "Load estimate type", onClick: () => setShowModal("estimate")}}
        secondaryButton={{text: "Load Template", onClick: () => setShowModal("template")}}
        removeButton={{text: "REMOVE BUDGET SECTION", onClick: onRemoveSection}}
        isLoading={removeSectionQuery.isLoading}
      />

      {!!showModal && (
        <ModalPopup show={!!showModal} onClose={closeModal} withoutPaddings size="w-528">
          {showModal === "estimate" && <BudgetEstimateForm closeModal={closeModal} />}
          {showModal === "template" && <BudgetTemplateForm closeModal={closeModal} />}
        </ModalPopup>
      )}
    </>
  );
};
