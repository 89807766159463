interface iCutAndOnlyNumbers {
  value: string;
  maxLength: number;
}

/**
 *
 * @param value
 * @param maxLength
 */
export const cutAndOnlyNumbers = ({value, maxLength}: iCutAndOnlyNumbers) => {
  let digitsValue = value.replace(/\D/g, "");
  if (maxLength) {
    return digitsValue.slice(0, maxLength);
  }
  return digitsValue;
};

/**
 *
 * @param value
 */
export const nameFilter = (value: string): string => {
  return value.replace(/[^\p{L}\s'`-]/gmu, "").replace(/\s\s+/gm, " ");
};

/**
 *
 * @param value
 */
export const emailFilter = (value: string): string => {
  return value.replace(/[\<\>\(\)\[\]\\\{\}\,\;\:\"\s]/gm, ""); // eslint-disable-line
};

/**
 *
 * @param str
 */
export const phoneFilter = <T>(str: T, type?: "dots") => {
  if (typeof str !== "string") return false;
  let x: any = str.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,4})/);

  if (type === "dots") {
    return !x[2] ? x[1] : +x[1] + "." + x[2] + (x[3] ? "." + x[3] : "");
  }

  return !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
};

export const toFormatPhone = (str: string) => {
  const x: any = str.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return !x[2] ? x[1] : "" + x[1] + "." + x[2] + (x[3] ? "." + x[3] : "");
};

export const filterMoney = (v: string | number): string | number | undefined => {
  let val: string | number = String(v).replace(/[^\d\.]/g, "");

  // has more than 2 dots / separators
  if (Array.from(val.matchAll(/\./g)).length > 1) return;

  // empty string
  // number with dot at the end
  // cents
  if (!val || val.match(/\d\.(\d{1,2})?$/)?.length) return val;

  // is NaN
  // has more than 2 digits after dot / separator
  if (isNaN(Number(val)) || val.match(/\.\d{3,}/)) return;

  // if user starts input with dot we automatically put 0 before
  if (val === ".") return "0.";
  return Number(val);
};

export const filterMoneyWithMinus = (v: string | number): string | number | undefined => {
  let val: string | number = String(v).replace(/[^\d\.-]/g, "");

  // if (val.match(/^0+[1-9]+/)) val = val.replace(/^0+/, "");

  if (val.match(/^\-?0+[1-9]+/)) {
    val = val.match(/^\-/) ? "-" + val.slice(1).replace(/^0+/, "") : val.replace(/^0+/, "");
  }

  if (Array.from(val.matchAll(/\./g)).length > 1) val = val.replace(/\d+\.\d{0,2}/, "");

  if (val.includes("-")) {
    val = "-" + val.replace(/-/g, "");
  }

  return val;
};
