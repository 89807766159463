import { AxiosRequestConfig } from "axios";
import { tLoginPostData, tRegisterFullPostData, iResetPassPostDate } from "./types";

const register = "register";

export const auth = {
  checkInviteUrl: (invite_hash: string) => ({
    url: `${register}?invite_hash=${invite_hash}`
  }),
  register: ({ invite_hash, data }: tRegisterFullPostData): AxiosRequestConfig => ({
    url: `${register}/${invite_hash}`,
    method: "POST",
    data
  }),
  login: (data: tLoginPostData): AxiosRequestConfig => ({
    url: "login",
    method: "POST",
    data
  }),
  logout: (): AxiosRequestConfig => ({
    url: "logout",
    method: "POST"
  }),
  forgotPass: (username: string): AxiosRequestConfig => ({
    url: "forgot",
    method: "POST",
    data: { username }
  }),
  resetPass: (data: iResetPassPostDate): AxiosRequestConfig => ({
    url: "password-reset",
    method: "POST",
    data
  })
};
