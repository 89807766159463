import {FC} from "react";
import {BudgetTypes} from "./BudgetTypes/BudgetTypes";

interface iJobSettingsBudgetTypesProps {
  onClose: (arg: null) => void;
}

export const JobSettingsBudgetTypes: FC<iJobSettingsBudgetTypesProps> = ({onClose}) => {
  return <BudgetTypes onClose={() => onClose(null)} />;
};
