import {AxiosRequestConfig} from "axios";

const DROPBOX = "dropbox";

export const dropboxApi = {
  dropboxAuth: (): AxiosRequestConfig => ({
    url: `${DROPBOX}/auth`
  }), // return data: {url: "https://..."}
  getDropboxAccountId: (): AxiosRequestConfig => ({
    url: `${DROPBOX}/auth/uid`
  }), // return data: {uid: 1231123}
  dropboxLogout: (data: {uid: string}): AxiosRequestConfig => ({
    method: "post",
    url: `${DROPBOX}/logout`,
    data
  }),
  saveToken: (code: string): AxiosRequestConfig => ({
    url: DROPBOX,
    params: {code}
  })
};
