import {FC} from "react";
import {Table} from "components/Table/Table";
import {TableHeadItem} from "components/Table/TableHeadItem";
import {expenseListTableTitles} from "./expenseListTableTitles";
import {useContextExpenseList} from "../context/ExpenseListContext";
import {ExpenseTableRow} from "./ExpenseTableRow";
import {Loading} from "components/shared/Loading/Loading";
import {useSortTableColumns} from "./_helpers/useSortTableColumns";
import {useFilterList} from "./_helpers/useFilterList";
import {HIDDEN_COLUMNS} from "../../_modals/ExpenseListViewOptions/ExpenseListViewOptions";
import {useSetApiParams} from "hooks/useSetApiParams";
import {ExpenseTableFooter} from "./ExpenseTableFooter";
import {useCreateTableColWidth} from "./_helpers/useCreateTableColWidth";
import {useOffsetTop} from "hooks/useOffsetTop";
import "./ExpenseTable.scss";

export const ExpenseTable: FC = () => {
  const {expenseList, isLoading} = useContextExpenseList();
  const {sortList} = useSortTableColumns();
  const {filterList} = useFilterList();
  const {adjustWidth} = useCreateTableColWidth();
  const {elementRef, offsetTop} = useOffsetTop();

  const apiParams = useSetApiParams();
  const hiddenColumns = apiParams?.preparedParams?.[HIDDEN_COLUMNS]?.split(",");

  const renderHeaderList = () =>
    expenseListTableTitles?.map((item, i) => {
      if (hiddenColumns?.find(hItems => hItems === item?.viewOptionId)) return null;
      return (
        <TableHeadItem
          key={item.id}
          data={item}
          tableHeigh={elementRef?.current?.clientHeight}
          adjustWidth={{...adjustWidth?.[expenseListTableTitles[i].id]}}
        />
      );
    });

  const totalData = {wDetails: 0, aDetails: 0, charged: 0};

  const renderBodyList = () => {
    if (!expenseList?.list?.length) return null;

    return filterList(sortList(expenseList?.list))?.map(expense => {
      totalData["wDetails"] = totalData.wDetails + (+expense?.working_details?.amount || 0);
      totalData["aDetails"] = totalData.aDetails + (+expense?.actual_details?.amount || 0);
      totalData["charged"] = totalData.charged + (+expense?.charged || 0);

      return (
        <ExpenseTableRow
          key={expense?.id}
          expense={expense}
          hiddenColumns={hiddenColumns}
          adjustWidth={adjustWidth}
        />
      );
    });
  };

  return (
    <div
      id="expense-table-wrap-id"
      ref={elementRef}
      className="ExpenseTableWrap posRelative"
      style={{height: `calc(100vh - ${offsetTop}px)`}}
    >
      {isLoading && <Loading type="inBlock" />}

      {!isLoading && (
        <Table
          className="ExpenseTable"
          headerRowChildren={renderHeaderList()}
          bodyChildren={renderBodyList()}
          footerChildren={
            <ExpenseTableFooter totalData={totalData} hiddenColumns={hiddenColumns} />
          }
        />
      )}
    </div>
  );
};
