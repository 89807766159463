import React, {FC} from "react";
import {ImgWithFallback} from "../ImgWithFallback/ImgWithFallback";
import "./AvatarGroup.scss";

interface iAvatarGroup {
  data: any[];
  nameKeys?: "name" | "first_name" | "last_name" | "display_name";
  showAllAvatars?: boolean;
}

export const AvatarGroup: FC<iAvatarGroup> = ({data, nameKeys = "name", showAllAvatars}) => {
  if (!data) return null;

  const getFirstNameLetters = (name: string) =>
    name.split(" ")?.[0]?.slice(0, 1) || "" + name.split(" ")?.[1]?.slice(0, 1) || "";

  const WithImage = ({avatar_url}: {avatar_url: string}) => (
    <ImgWithFallback
      className="AvatarGroup-item AvatarGroup-item-withImg"
      src={avatar_url || ""}
      fallBackType="avatar"
      alt="avatar"
    />
  );

  const WithLetters = ({name}: {name: string}) => (
    <span className=" AvatarGroup-item AvatarGroup-item-withLetters">{name}</span>
  );

  const WithCounting = ({count}: {count: string}) => (
    <span className=" AvatarGroup-item AvatarGroup-item-counting">{count}</span>
  );

  return (
    <div className="AvatarGroup">
      {data.map((member, index, array) => {
        if (!showAllAvatars && index > 2) return null;

        if (!showAllAvatars && array?.length > 3 && index === 2) {
          return <WithCounting key={member.id} count={`+${String(array?.length - 2)}`} />;
        }

        if (!member?.avatar_url) {
          return (
            <WithLetters key={member.id} name={getFirstNameLetters(member?.[nameKeys] || "")} />
          );
        }

        if (member?.avatar_url) {
          return <WithImage key={member.id} avatar_url={member?.avatar_url} />;
        }
        return null;
      })}
    </div>
  );
};
