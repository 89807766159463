export type tTooltipPages =
  | "dashboard"
  | "people"
  | "overview"
  | "estimate"
  | "track"
  | "expenseList"
  | "calendar"
  | "callSheet"
  | "callSheetTrack";

export type tTooltipTypes = typeof tooltipTitles[number];

export const tooltips: Record<tTooltipPages, {title: tTooltipTypes; body: string}[]> = {
  dashboard: [
    {
      title: "FAVORITING",
      body: "Mark a job with a star to have it appear in the side navigation’s “FAVORITES” list."
    }
  ],
  people: [
    {
      title: "CONTACT TYPES",
      body: "Contacts are split into people and companies. Use the tabs to change views."
    },
    {
      title: "PREFERRED CONTACTS",
      body: "Preferred contacts are marked with a heart."
    }
  ],
  overview: [
    {
      title: "Job Sections",
      body: "Use the tabs to switch between job sections. Additional sections can be added with the “+”."
    }
  ],
  estimate: [
    {
      title: "BUILD THE ESTIMATE",
      body: "Complete line items fields to build the estimate. Once the estimate is marked as approved, the other tabs unlock to track expenses and generate invoices."
    },
    {
      title: "Export The Estimate",
      body: "Generate an estimate summary in the export tab to share with clients."
    },
    {
      title: "QUICK NAVigation",
      body: "Clicking on a category scrolls the right panel to your selection."
    },
    {
      title: "Sales Tax + PAYROLL",
      body: "Complete the sales tax and/or payroll dropdowns to include the line item in the total calculations."
    },
    {
      title: "PRODUCTION %",
      body: "This global production % can be overridden by entering a different percentage into a category or line item. The calculated percentage % appears below."
    }
  ],
  track: [
    {
      title: "TRACK EXPENSES",
      body: "Line items from approved estimates appear in “TRACK”. Click the “+” to add an expense to this line item."
    },
    {
      title: "Alternate views",
      body: "The “WORK/ACTUAL” view totals expenses under line items and shows how much is left in the budget. The “EXPENSE LIST” view displays expense lines in a list view that can be filtered and sorted. The “WRAP PDF” view formats line totals and receipts into an exportable document."
    }
  ],
  expenseList: [
    {
      title: "ACCOUNTING TOTAL",
      body: "This total amount includes “do not bill” expenses and updates based on the filtered view."
    }
  ],
  calendar: [
    {
      title: "CALENDAR VIEWS",
      body: "Calendar events, dated crew holds and dated tasks can all be viewed in this calendar."
    }
  ],
  callSheet: [
    {
      title: "SELECT CONTACTS",
      body: "These contacts are synced from the crew list. Use the checkboxes to add or remove people from this call sheet."
    },
    {
      title: "ONE-TIME CONTACTS",
      body: "This button lets you manually enter details for a one-time call sheet recipient. "
    },
    {
      title: "HIDING DETAILS",
      body: "Click emails and phone numbers to hide  them from the exported file."
    }
  ],
  callSheetTrack: [
    {
      title: "CONFIRMATION STATUS",
      body: "Contacts that have confirmed their latest call sheet appear in green. Views and confirmations of previously sent copies appear in orange."
    },
    {
      title: "Send ERRor",
      body: "Emails and numbers that couldn’t be reached appear in red. Update the contact’s details and use the resend button on this screen to try again."
    }
  ]
};

export const tooltipTitles = [
  "FAVORITING",
  "CONTACT TYPES",
  "PREFERRED CONTACTS",
  "Job Sections",
  "BUILD THE ESTIMATE",
  "Export The Estimate",
  "QUICK NAVigation",
  "Sales Tax + PAYROLL",
  "PRODUCTION %",
  "TRACK EXPENSES",
  "Alternate views",
  "ACCOUNTING TOTAL",
  "CALENDAR VIEWS",
  "SELECT CONTACTS",
  "ONE-TIME CONTACTS",
  "HIDING DETAILS",
  "CONFIRMATION STATUS",
  "Send ERRor"
] as const;
