import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useSyncData} from "hooks/useSyncData";
import {SyncData} from "pages/Jobs/JobDetails/common/SyncData/SyncData";
import {useContextExpenseList} from "../context/ExpenseListContext";
import {useParams} from "react-router-dom";
import {getDateFormat} from "helpers/dates";
import {trackApi} from "api/budget/track";

export const ExpenseSync = () => {
  const {id} = useParams();
  const {expenseList, setExpenseList} = useContextExpenseList();
  const {call, isLoading} = useHttp({cancelRequest: false});

  useSyncData({callBack: syncTrack, deps: [expenseList]});

  async function syncTrack() {
    if (!id) return;

    try {
      const date = expenseList?.updater_info?.updater_at || new Date();
      const time = getDateFormat(date, "timestampWithUTC");
      const res = await call(trackApi.syncEstimateList(+id, time, "list"));
      res?.data?.data && setExpenseList(res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  }
  return (
    <SyncData
      name={expenseList?.updater_info?.updater?.name || ""}
      updateCallback={syncTrack}
      isLoading={isLoading}
    />
  );
};
