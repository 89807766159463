import {useEffect} from "react";
import {jobTasksApi} from "api/jobs/tasks";
import {useHttp} from "hooks/httpServices/useHttp";

export const useGetTasksTemplates = ({select}: {select: any}) => {
  const {data, ...props} = useHttp({config: jobTasksApi.getTaskTemplates(), onMountCall: true});
  const templateList = data?.data?.data as Array<any>;

  useEffect(() => {
    !!data && select.setOptions(data?.data?.data);
  }, [data]); // eslint-disable-line

  return {templateList, ...props};
};
