import {FC} from "react";
import {ModalPopup} from "../ModalPopup/ModalPopup";
import {TemplateModal} from "../TemplateModal/TemplateModal";
import {useCurrencyRates} from "./useCurrencyRates";
import {CurrencyRatesTable} from "./CurrencyRatesTable";
import {Loading} from "../Loading/Loading";
import {ConfirmWindow} from "../ConfirmWindow/ConfirmWindow";

import "./CurrencyRates.scss";
import {tCurrencyRates} from "api/common/types";

export interface iCurrencyRatesProps {
  onClose: () => void;
  type: "default" | "estimateTemplate" | "trackCurrency";
  templateId?: number;
  jobId?: number;
  customList?: tCurrencyRates[];
  updateCallback?: (arg: any) => void;
  blockDelete?: boolean;
  blockUpdateValue?: boolean;
}

export const CurrencyRates: FC<iCurrencyRatesProps> = ({
  onClose,
  updateCallback,
  type,
  templateId,
  customList,
  blockDelete,
  blockUpdateValue,
  jobId
}) => {
  const {
    onSave,
    currency,
    onAddCurrency,
    currencyData,
    hasNewCurrency,
    isLoading,
    submitting,
    onDeleteCurrency,
    confirmDelete,
    setCurrency,
    setConfirmDelete
  } = useCurrencyRates({type, templateId, customList, updateCallback, jobId});

  return (
    <>
      <ModalPopup
        show={true}
        className="CurrencyRates"
        onClose={onClose}
        size="w-528"
        withoutPaddings
      >
        <TemplateModal
          title="Currency Rates"
          primaryButton={{
            text: "Save",
            onClick: onSave
            // disabled: !hasNewCurrency
          }}
          secondaryButton={{text: "cancel", onClick: onClose}}
          isLoading={submitting}
        >
          {isLoading && <Loading type="inBlock" />}

          <CurrencyRatesTable
            data={currency}
            onAddCurrency={onAddCurrency}
            currencyData={currencyData}
            onDeleteCurrency={setConfirmDelete}
            blockDelete={blockDelete}
            setCurrency={setCurrency}
            blockUpdateValue={blockUpdateValue}
          />
        </TemplateModal>
      </ModalPopup>

      <ConfirmWindow
        show={!!confirmDelete}
        onClose={() => setConfirmDelete("")}
        onConfirm={onDeleteCurrency}
        title="Delete currency?"
        subTitle={`“${confirmDelete}” will be permanently deleted.`}
        isLoading={submitting}
        confirmBtn={{
          text: "delete",
          color: "red"
        }}
        cancelBtn={{
          text: "cancel"
        }}
      />
    </>
  );
};
