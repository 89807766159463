import {FC, useEffect} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {TagsField} from "components/shared/FormComponents/TagsField/TagsField";
import {Select} from "components/shared/FormComponents/Select/Select";
import {TextareaField} from "components/shared/FormComponents/TextareaField/TextareaField";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {Button} from "components/shared/Button/Button";
import {useLocationForm} from "./useLocationForm";
import {RadioField} from "components/shared/FormComponents/RadioField/RadioField";
import {ContactFieldGroup} from "components/shared/FormComponents/ContactFieldGroup/ContactFieldGroup";
import {titles as titlesApi} from "api/titles";
import {ImgWithFallback} from "components/shared/ImgWithFallback/ImgWithFallback";
import moment from "moment";
import {Loading} from "components/shared/Loading/Loading";
import {AddressField} from "components/shared/FormComponents/AddressField/AddressField";
import {iLocationDetailsResponse} from "api/location/types";
import "./LocationForm.scss";
import {ImageCrop} from "components/shared/ImageCrop/ImageCrop";

interface iLocationFormProps {
  location?: number | iLocationDetailsResponse | null;
  onCloseModal: (callback?: Function) => void;
  closeEditModal?: () => void;
  onEditCallback?: Function;
  formDataIsChanged?: (status: boolean) => void;
}

export const LocationForm: FC<iLocationFormProps> = ({
  location,
  onCloseModal,
  closeEditModal,
  onEditCallback,
  formDataIsChanged
}) => {
  const createNewLocation: boolean = !location;

  const {
    formData,
    localesData,
    handleSubmit,
    confirmDeleteLocation,
    isLoadingAdding,
    isLoadingEditing,
    locationDetails,
    image,
    showConfirmDeleteLocation,
    setShowConfirmDeleteLocation,
    deleteLocationResult,
    setDeleteLocationResult,
    isLoadingDelete,
    setImage,
    showRemoveConfirm,
    isChangedFormData
  } = useLocationForm({location, onCloseModal, onEditCallback});

  useEffect(() => {
    formDataIsChanged?.(isChangedFormData);
  }, [isChangedFormData]); // eslint-disable-line

  if (location && !locationDetails) return <Loading type="inBlock" />;

  return (
    <>
      <form className="LocationForm" onSubmit={handleSubmit}>
        <h2 className="LocationForm-header">
          {locationDetails?.name && `Edit ${locationDetails?.name}`}
          {createNewLocation && "Add Location"}
        </h2>
        <div className="LocationForm-body">
          <div className="LocationForm-upload">
            <ImgWithFallback
              src={String(image) || ""}
              className="LocationForm-image"
              alt="location"
            />
            {/* <ImageCropper onChangeImage={setImage} /> */}
            <ImageCrop onChangeImage={setImage} aspect="landscape" />
          </div>

          <div className="LocationForm-section">
            <InputField
              label="Name"
              {...formData.name.inputProps}
              errors={formData.name.errors}
              className="InputField-name"
              name="name"
            />
            <InputField
              {...formData.rate.inputProps}
              errors={formData.rate.errors}
              label="Rate"
              className="InputField-rate"
              name="rate"
            />
            <div className="LocationForm-radio-group">
              <RadioField
                {...formData.permit_required}
                title="Permit REQUIRED"
                errors={formData.permit_required.errors}
              />
            </div>
          </div>
          <div className="LocationForm-section">
            <InputField
              {...formData.website.inputProps}
              errors={formData.website.errors}
              label="Website"
              name="website"
            />
          </div>
          <div className="LocationForm-section">
            <Select
              {...formData.location_type_id.inputProps}
              label="Location Type"
              placeholder="Select..."
              errors={formData.location_type_id.errors}
              name="location_type_id"
            />
          </div>
          <div className="LocationForm-section">
            <AddressField {...formData.address} searchPlaceholder="Search address..." />
          </div>

          <div className="LocationForm-section">
            {/* @ts-ignore */}
            <TagsField
              {...formData.locales.inputProps}
              errors={formData.locales.errors}
              label="Locales"
              options={localesData?.data?.data}
              placeholder="Add locale..."
              name="locales"
            />
          </div>
          <div className="LocationForm-section">
            <ContactFieldGroup
              {...formData.contacts}
              errors={formData.contacts.errors}
              nameLabel="Contact"
              relationshipLabel="Title"
              callTitle={titlesApi.get}
              callOnCreateTitle={titlesApi.addNewTitle}
              personNameKeys={["first_name", "last_name"]}
            />
          </div>

          <div className="LocationForm-section LocationForm-notes">
            <TextareaField
              {...formData.notes.inputProps}
              label="Notes"
              errors={formData.notes.errors}
              name="notes"
            />
          </div>

          {locationDetails && (
            <div className="LocationForm-section LocationForm-section-delete">
              <p>
                {locationDetails?.creator &&
                  `Created on ${moment(locationDetails?.created_at).format("MMM DD, YYYY")} by 
                ${locationDetails?.creator?.display_name}`}
              </p>
              <Button
                type="button"
                className="LocationForm-button-remove"
                onClick={() => showRemoveConfirm(locationDetails.id)}
              >
                Delete Location
              </Button>
            </div>
          )}
        </div>
        <div className="LocationForm-footer">
          <Button
            type="button"
            onClick={() => (closeEditModal ? closeEditModal() : onCloseModal())}
            className="LocationForm-button-cancel"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="light"
            size="md"
            className="LocationForm-button-save"
            loading={isLoadingEditing || isLoadingAdding}
          >
            Save
          </Button>
        </div>
      </form>
      <ConfirmWindow
        show={!!showConfirmDeleteLocation}
        onClose={() => setShowConfirmDeleteLocation(null)}
        onConfirm={confirmDeleteLocation}
        confirmBtn={{
          color: "red",
          text: "Delete"
        }}
        title="Delete Location?"
        cancelBtn={{text: "Cancel"}}
        isLoading={isLoadingDelete}
      >
        <div className="LocationForm-confirmModal-body">
          <p>This location will be permanently removed from the location directory.</p>
          <p className="LocationForm-confirmModal-warning">This can’t be undone.</p>
        </div>
      </ConfirmWindow>

      <ConfirmWindow
        show={deleteLocationResult}
        onClose={() => setDeleteLocationResult(false)}
        confirmBtn={{
          color: "dark",
          text: "Got it"
        }}
        title="Can’t be Deleted"
        onConfirm={() => setDeleteLocationResult(false)}
      >
        <div className="LocationForm-confirmModal-body">
          <p>
            This location is linked to the following jobs. It must be removed before it can be
            deleted.
          </p>
          <ul className="LocationForm-delete-list">
            {locationDetails?.jobs?.map(item => {
              return (
                <li key={item.id}>
                  <span className="LocationForm-delete-list-number">{item.id}</span> {item.name}
                </li>
              );
            })}
          </ul>
        </div>
      </ConfirmWindow>
    </>
  );
};
