import {createContext, FC, useContext} from "react";
import {useExportBudget, tUseExportBudgetReturn} from "./useExportBudget";

type tExportBudgetContext = tUseExportBudgetReturn;
const ExportBudgetContext = createContext<tExportBudgetContext | null>(null);
ExportBudgetContext.displayName = "ExportBudgetContext";

export const ExportBudgetProvider: FC = ({children}) => {
  return (
    <ExportBudgetContext.Provider value={useExportBudget()}>
      {children}
    </ExportBudgetContext.Provider>
  );
};

export const useContextExportBudget = () => {
  const context = useContext(ExportBudgetContext);

  if (context === null) {
    throw new Error("<<< ExportBudgetContext >>> must be used within a ExportBudgetProvider");
  }

  return context as tExportBudgetContext;
};
