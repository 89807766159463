import {ChangeEvent, FC, useEffect, useState} from "react";
import {SwitchInput} from "components/shared/FormComponents/SwitchInput/SwitchInput";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {getUsMoneyFormat} from "helpers/formattingData";
import {TextComponent} from "../../../../common/TextComponent/TextComponent";
import {tInvoice, tInvoiceAdvance, tPaymentPropPayload} from "api/invoice/types";
import {invoiceApi} from "api/invoice";
import {useHttp} from "hooks/httpServices/useHttp";

interface iLatePaymentFeeProps {
  setInvoice: (adv: tInvoice) => void;
  data: tInvoiceAdvance;
}

export const LatePaymentFee: FC<iLatePaymentFeeProps> = ({data, setInvoice}) => {
  const {call, isLoading} = useHttp();
  const {id} = data;

  const [advancePaymentOn, setAdvancePaymentOn] = useState(data.late_payment_fees);
  const [value, setValue] = useState(data.late_payment_days);
  const [amount, setAmount] = useState(data.late_payment_amount);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(Number(event.target.value));
  };

  const handleChangeSwitch = async (event: ChangeEvent<HTMLInputElement>) => {
    const {name, checked} = event.target;
    setAdvancePaymentOn(event.target.checked);
    const invoice = await updateAdvance({[name]: checked});
    setInvoice(invoice);
  };

  useEffect(() => {
    setAmount(Number(value) * Number(data.late_payment_daily));
  }, [value]); // eslint-disable-line

  const updateAdvance = async (value: tPaymentPropPayload) => {
    return call(invoiceApi.updateAdvanceLatePaymentProperties(id, value)).then(({data: {data}}) => {
      return data;
    });
  };

  const onBlur = async (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    const invoice = await updateAdvance({[name]: value});
    setInvoice(invoice);
  };

  const invoiceNumber = `Advance Payment on Invoice No. ${data.invoice_number}`;

  return (
    <div className="LatePaymentFee-wrap">
      <div className="LatePaymentFee">
        <TextComponent weight="500" color="black-10" size="14">
          {invoiceNumber}
        </TextComponent>
        <span>{getUsMoneyFormat(data.amount_required)}</span>
      </div>
      <div className={`LatePaymentFee ${advancePaymentOn ? "" : "disabled"}`}>
        <div className="LatePaymentFee-input">
          <SwitchInput
            name="late_payment_fees"
            checked={advancePaymentOn}
            onChange={handleChangeSwitch}
          />
          <TextComponent weight="500" color="black-10" size="14">
            Late Payment Fee,
          </TextComponent>
          <InputField
            name="late_payment_days"
            onBlur={onBlur}
            disabled={!advancePaymentOn || isLoading}
            value={value}
            onChange={onChange}
            type="number"
          />
          <TextComponent weight="500" color="black-10" size="14">
            days @ {getUsMoneyFormat(data.late_payment_daily)}
          </TextComponent>
        </div>
        <span className="LatePaymentFee-value">{getUsMoneyFormat(amount)}</span>
      </div>
    </div>
  );
};
