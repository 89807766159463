import {FC, useState} from "react";
import {tTrackExpenseLine} from "api/budget/track/types";
import sortIcon from "assets/svg/sortIcon.svg";
import {useChangeExpenseValue} from "pages/Jobs/JobDetails/JobBudget/Track/_common/useChangeExpenseValue";
import {InputCell} from "components/shared/InputCell/InputCell";
import "./ExpenseRowName.scss";

type tExpenseRowNameProps = {
  className?: string;
  trackLine: tTrackExpenseLine;
};

export const ExpenseRowName: FC<tExpenseRowNameProps> = ({className, trackLine}) => {
  const [value, setValue] = useState(trackLine?.vendor_name || "");
  const {isLoading, onChange} = useChangeExpenseValue({
    id: trackLine?.id,
    type: "vendor_name",
    errorCallback: () => setValue(trackLine?.vendor_name || "")
  });

  return (
    <div className={`${className} ExpenseRowName`}>
      <div className="ExpenseRowName-sortIconWrap">
        <img src={sortIcon} alt="sort icon" className="ExpenseRowName-sortIcon" draggable="false" />
      </div>

      <InputCell
        initValue={trackLine?.vendor_name || ""}
        value={value}
        setValue={setValue}
        isLoading={isLoading}
        placeholder="Vendor"
        callBack={val => onChange(val)}
        className={`${className} ExpenseRowName-name`}
        alwaysShowPlaceholder
        withBorder
      />
    </div>
  );
};
