import {useState} from "react";
import {uid} from "helpers/misc";

interface iUseLocationDynamicInputProps {
  sheetLocations?: any[];
}

export function useLocationDynamicInput(props?: iUseLocationDynamicInputProps) {
  const [emptyRowError, setEmptyRowError] = useState(false);
  const [values, setValues] = useState<any[]>(
    props?.sheetLocations || [
      {
        id: uid(),
        name: "",
        label: "",
        location_id: "",
        address: {}
      }
    ]
  );
  const [showConfirmRemove, setShowConfirmRemove] = useState<any | null>(null);

  const onCreateNewRow = () => {
    if (!checkValidity()) {
      setEmptyRowError(true);
      return;
    }
    setEmptyRowError(false);

    setValues(prev => [
      ...prev,
      {
        id: uid(),
        name: "",
        label: "",
        location_id: "",
        address: {}
      }
    ]);
  };

  const onChangeInputRow = (item: any) => {
    let newVal = values.map(v => (v.id === item.id ? item : v));
    checkValidity(newVal);
  };

  const onRemoveRow = () => {
    setValues(prev => {
      const newValues = prev.filter(i => i.id !== showConfirmRemove.id);
      if (!newValues?.length) {
        return [
          {
            id: uid(),
            name: "",
            label: "",
            location_id: "",
            address: {}
          }
        ];
      }

      return newValues;
    });
    setShowConfirmRemove(null);
  };

  function checkValidity(val?: any) {
    let arr = val || values;
    let newValues = arr.map((value: any, i: number) => {
      let errors: string[] = [];

      if (i === 0) {
        //if location not required
        if (!value?.location_id) errors = [];
        return {...value, errors};
      }

      if (!value?.label || !value?.location_id) errors = ["This field can't be empty"];
      return {...value, errors};
    });

    setValues(newValues);

    const isValid = newValues.every((v: any) => !v.errors.length);

    isValid && setEmptyRowError(false);
    return isValid;
  }

  return {
    onRemoveRow,
    onChangeInputRow,
    emptyRowError,
    onCreateNewRow,
    values,
    showConfirmRemove,
    setShowConfirmRemove,
    checkValidity
  };
}
