import {FC} from "react";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import "./GridHeader.scss";

type tGridHeaderProps = {};

export const GridHeader: FC<tGridHeaderProps> = () => {
  return (
    <div className="GridHeader">
      <TextComponent size="20" weight="700" uppercase>
        Grids
      </TextComponent>
    </div>
  );
};
