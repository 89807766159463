import {FC, useLayoutEffect} from "react";
import {useContextTeamDetails} from "pages/Team/TeamDetailsContext";
import {Filter} from "./Filter";
import {FilterLayout} from "components/FilterLayout/FilterLayout";

export const TeamTableFilter: FC = () => {
  const {getAdditionalFormData, isLoadingFilter} = useContextTeamDetails();
  useLayoutEffect(() => {
    getAdditionalFormData("filter");
  }, []); // eslint-disable-line

  if (isLoadingFilter) return <FilterLayout children="Loading" />;
  return <Filter />;
};
