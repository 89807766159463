import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {FC} from "react";
import {crewTableTitles} from "../../crewTableTitles";
import "./CrewTableRowInactive.scss";

type tCrewTableRowInactiveProps = {
  amount: number;
};

export const CrewTableRowInactive: FC<tCrewTableRowInactiveProps> = ({amount}) => {
  return (
    <tr className="CrewTableRowInactive">
      <td colSpan={crewTableTitles?.length}>
        <TextComponent
          uppercase
          weight="500"
          color="gray-10"
          size="10"
          className="CrewTableRowInactive-text"
        >
          {amount} inactive contact{amount > 1 ? "s" : ""}
        </TextComponent>
      </td>
    </tr>
  );
};
