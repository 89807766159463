import {useContextJobCrew} from "../JobCrewContext";
import {MainHeader} from "./MainHeader/MainHeader";
import {SecondaryHeader} from "./SecondaryHeader/SecondaryHeader";
import "./CrewHeader.scss";

export const CrewHeader = () => {
  const {showSelectContactIcon} = useContextJobCrew();

  return (
    <div className="CrewHeader">
      {!showSelectContactIcon && <MainHeader />}
      {showSelectContactIcon && <SecondaryHeader />}
    </div>
  );
};
