import {FC, useState} from "react";
import {ChapterHeader} from "../ChapterHeader/ChapterHeader";
import {SelectWithButton} from "../../Advance/SelectWithButton/SelectWithButton";
import {AdvanceTermsModal} from "../../Advance/modals/AdvanceTermsModal/AdvanceTermsModal";
import {AdvanceBankModal} from "../../Advance/modals/AdvanceBankModal/AdvanceBankModal";
import {useBudgetOverlayTermsBank} from "./useBudgetOverlayTermsBank";
import {tBankDetails} from "api/budget/bankDetails/types";
import {TextComponent} from "../../../common/TextComponent/TextComponent";
import {tTermsConditions} from "api/budget/termsConditions/types";

import "./BudgetOverlayTermsBank.scss";

interface iBudgetOverlayTermsBankProps {
  bankDetails: tBankDetails | null;
  termCondition: tTermsConditions | null;
  updateData: (data: {bank_detail: tBankDetails} | {term_condition: tTermsConditions}) => void;
}

type tModal = "terms" | "bank";

export const BudgetOverlayTermsBank: FC<iBudgetOverlayTermsBankProps> = ({
  bankDetails,
  termCondition,
  updateData
}) => {
  const {termsList, bankList, setTermsList, setBankList} = useBudgetOverlayTermsBank();
  const [modal, setModal] = useState<tModal | null>(null);

  const onChangeBank = (bank: tBankDetails) => updateData({bank_detail: bank});

  // const onChangeTerms = (term: tTermsConditions) => updateData({term_condition: term});

  return (
    <>
      <div className="BudgetOverlayTermsBank">
        <div>
          {/* <ChapterHeader>Terms and conditions</ChapterHeader>
          <SelectWithButton
            onChange={onChangeTerms}
            value={termCondition}
            options={termsList}
            placeholder="Select terms attachment"
            buttonText={
              !!termsList.length ? "Edit terms and conditions" : "ADD NEW TERMS AND CONDITIONS"
            }
            formatOptionLabel={item => item.name}
            buttonProps={{
              onClick: () => setModal("terms")
            }}
          /> */}
        </div>
        <div>
          <ChapterHeader>Bank</ChapterHeader>
          <SelectWithButton
            onChange={onChangeBank}
            value={bankDetails}
            options={bankList}
            placeholder="Insert bank details"
            buttonText={!!bankList.length ? "Edit bank details" : "Add bank details"}
            formatOptionLabel={item => item.name}
            buttonProps={{
              onClick: () => setModal("bank")
            }}
          />
          <TextComponent className="BudgetOverlayTermsBank-textWrap" weight="400" size="13" as="p">
            {bankDetails?.details || ""}
          </TextComponent>
        </div>
      </div>

      {modal === "terms" && (
        <AdvanceTermsModal
          termsList={termsList}
          setTermsList={setTermsList}
          onClose={() => setModal(null)}
        />
      )}
      {modal === "bank" && (
        <AdvanceBankModal
          bankList={bankList}
          setBankList={setBankList}
          onClose={() => setModal(null)}
        />
      )}
    </>
  );
};
