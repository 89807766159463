import {ReactNode, MouseEvent, useState} from "react";
import {TagButton, iTagButton} from "components/shared/TagButton/TagButton";
import {iBaseOptions} from "api/common/types";

import "./TagButtonsListStacked.scss";

interface iTagButtonsListStacked<T> {
  data: T[];
  emptyData?: ReactNode;
  onClick(event: MouseEvent<any, any>, item: T): void;
  maxWidth?: number;
  buttonsProps?: Partial<iTagButton>;
}

export const TagButtonsListStacked = <T extends iBaseOptions>({
  data = [],
  onClick,
  emptyData = null,
  maxWidth = 170,
  buttonsProps
}: iTagButtonsListStacked<T>) => {
  const [rowWidth, setRowWidth] = useState<number[]>([]);

  if (!data.length) return <>{emptyData}</>;

  function calculateWidth(el: HTMLButtonElement | null) {
    if (!el || !el?.offsetWidth || data?.length === rowWidth?.length) return;
    setRowWidth(prev => [...prev, el.offsetWidth]);
  }

  function getRowWidthByIndex(index: number) {
    return rowWidth.reduce((acc, curr, idx) => (idx <= index ? acc + curr : acc), 0);
  }

  const onPreventEvents = (event: MouseEvent<HTMLButtonElement>) => event.stopPropagation();

  return (
    <div className="TagButtonsListStacked">
      {data.map((item, index, array) => {
        if (getRowWidthByIndex(index) + 34 > maxWidth) return null;

        if (getRowWidthByIndex(index + 1) + 34 > maxWidth) {
          return (
            <TagButton key={item.id} onClick={onPreventEvents} {...buttonsProps}>
              +{array?.length - index}
            </TagButton>
          );
        }

        return (
          <TagButton
            ref={element => calculateWidth(element)}
            key={item.id}
            onClick={event => onClick(event, item)}
            {...buttonsProps}
          >
            {item.name}
          </TagButton>
        );
      })}
    </div>
  );
};
