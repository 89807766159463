import {useSelect} from "hooks/inputHooks";
// import {usePhoneInput} from "hooks/usePhoneInput";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useEffect} from "react";
import {tUseDynamicInputsGroupForm} from "./types";

export const useDynamicInputsGroupForm = (props: tUseDynamicInputsGroupForm) => {
  const {options, isFirstItem, itemField, type, onChangeHandler} = props;

  const select = useSelect({
    options,
    value: itemField.id?.toString() || "",
    targetKeys: {value: "id", label: "name"},
    placeholder: "Select...",
    isRequired: !isFirstItem
  });

  const input = useTextInput({
    value: itemField.value,
    isRequired: !isFirstItem,
    ...(type === "emails" && {validators: ["email"]})
  });

  const phone = useTextInput({
    validators: ["phone"],
    filters: ["phone"],
    isRequired: !isFirstItem,
    value: itemField.value
  });

  useEffect(() => {
    if (type === "phones") {
      const changedAcc = {
        uid: itemField.uid,
        id: select.selected?.id || null,
        value: phone.value,
        name: select.selected?.name || null
      };
      onChangeHandler(changedAcc);
      return;
    }

    const changedAcc = {
      uid: itemField.uid,
      id: select.selected?.id || null,
      value: input.value,
      name: select.selected?.name || null,
      ...(select.selected?.address && {address: select.selected?.address})
    };

    onChangeHandler(changedAcc);
  }, [select.value, select.selected, input.value, phone.value]); // eslint-disable-line

  return {select, input, phone};
};
