import {createRef, ForwardedRef, MutableRefObject, useEffect, useRef} from "react";
import {tPipeline} from "api/dashboard/overview/types";
import {tColor} from "./OverviewPipelineColumn";

type tUseCountHiddenElements = {
  data: tPipeline;
  height?: number;
  color: tColor;
  ref: ForwardedRef<any>;
};

export const useCountHiddenElements = ({data, height, color, ref}: tUseCountHiddenElements) => {
  const blockRef = useRef<HTMLDivElement | null>(null);
  const dynamicFieldsRef = useRef([]);
  dynamicFieldsRef.current = data?.jobs?.map((_, i) => dynamicFieldsRef.current[i] ?? createRef());

  useEffect(() => {
    const blockHeight = blockRef.current ? blockRef.current?.offsetTop + blockRef.current?.clientHeight : 0; // prettier-ignore

    const hiddenElements = dynamicFieldsRef.current.filter((item: any) => {
      const elementHeight = item?.current?.offsetTop + item?.current?.offsetHeight / 2;
      return elementHeight > blockHeight;
    }).length;

    const data = {
      ...(ref as MutableRefObject<any>).current,
      [color]: hiddenElements
    };

    ref && ((ref as MutableRefObject<any>).current = data);
  }, [height]); // eslint-disable-line

  return {dynamicFieldsRef, blockRef};
};
