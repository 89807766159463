import {FC} from "react";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import gray from "assets/images/Leaf_gray.svg";
import green from "assets/images/Leaf_green.svg";
import {useHttp} from "hooks/httpServices/useHttp";
import {trackApi} from "api/budget/track";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {Loading} from "components/shared/Loading/Loading";
import {useContextTrack} from "../../_context/TrackContext";
import {useContextExpenseList} from "../../ExpenseList/context/ExpenseListContext";
import {useResponseType} from "../useResponseType";

type tGreenProps = {
  className?: string;
  id: number;
  value: boolean;
};

export const Green: FC<tGreenProps> = ({className = "", id, value}) => {
  const {setTrackData} = useContextTrack();
  const {setExpenseList} = useContextExpenseList();
  const {isExpense, response_type} = useResponseType();
  const {call, isLoading} = useHttp();

  const greenToggler = async () => {
    try {
      const res = await call(trackApi.setGreenState(id, {state: !value, response_type}));
      isExpense && setExpenseList(res?.data?.data);
      !isExpense && setTrackData(res?.data?.data);
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const isGreen = !!value;
  return (
    <TextComponent
      as={"button"}
      onClick={greenToggler}
      className={`${className} Green posRelative`}
    >
      {isLoading && <Loading type="inBlock" customSize={0.5} />}
      <img src={isGreen ? green : gray} alt="green" />
    </TextComponent>
  );
};
