import {FC} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {ChapterHeader} from "../../../OverlayTemplates/ChapterHeader/ChapterHeader";
import {Datepicker} from "components/shared/FormComponents/Datepicker/Datepicker";
import {TextareaField} from "components/shared/FormComponents/TextareaField/TextareaField";
import {SelectWithButton} from "../../../Advance/SelectWithButton/SelectWithButton";
import {AdvanceContactsModal} from "../../../Advance/modals/AdvanceContactsModal/AdvanceContactsModal";
import {useInvoiceBuildAdvance} from "./useInvoiceBuildAdvance";
import {useContextInvoice} from "../../context/InvoiceContext";

import "./InvoiceBuildAdvance.scss";

interface iInvoiceBuildAdvanceProps {}

export const InvoiceBuildAdvance: FC<iInvoiceBuildAdvanceProps> = () => {
  const {advanceContacts, setAdvanceContacts, invoice} = useContextInvoice();
  const {formData, editContacts, onChangeContact, setEditContacts, onSaveCallback} =
    useInvoiceBuildAdvance();

  const hasContacts = !!advanceContacts.length;

  return (
    <>
      <div className="InvoiceBuildAdvance">
        <ChapterHeader>Details</ChapterHeader>
        <InputField
          {...formData.invoice_number.inputProps}
          onBlur={onSaveCallback}
          name="invoice_number"
          label="Invoice no."
        />
        <Datepicker
          {...formData.date.inputProps}
          name="date"
          minDate={new Date()}
          label="Date"
          withIcon
        />
        <InputField
          {...formData.terms.inputProps}
          onBlur={onSaveCallback}
          name="terms"
          label="Terms"
        />
        <Datepicker
          {...formData.due_date.inputProps}
          name="date"
          minDate={new Date()}
          label="Due Date"
          withIcon
          isClearable
        />
        <SelectWithButton
          onChange={onChangeContact}
          value={invoice?.advance_contact}
          label="Contacts"
          name="contact_id"
          options={advanceContacts}
          formatOptionLabel={(item: any) => item.name}
          buttonProps={{
            onClick: () => setEditContacts(true)
          }}
          buttonText={hasContacts ? "Edit advance contacts" : "Add advance contact"}
        />
        <TextareaField disabled {...formData.contact.inputProps} />
      </div>
      {editContacts && (
        <AdvanceContactsModal
          advanceContacts={advanceContacts}
          setAdvanceContacts={setAdvanceContacts}
          onClose={() => setEditContacts(false)}
        />
      )}
    </>
  );
};
