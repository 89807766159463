import {FC, useEffect, useState} from "react";
import {tJobTasksTemplateDataTask} from "api/jobs/tasks/types";
import {TaskNameCell} from "./TasksRowCells/TaskNameCell/TaskNameCell";
import {DragAndDropWrap} from "components/DragAndDropWrap/DragAndDropWrap";
import {useDragTask} from "./useDragTask";
import {ActionsCell} from "./TasksRowCells/ActionsCell/ActionsCell";
import "./TableTaskRow.scss";

type tTableTaskRowProps = {
  groupData: tJobTasksTemplateDataTask;
};

export const TableTaskRow: FC<tTableTaskRowProps> = ({groupData}) => {
  const [data, setData] = useState<tJobTasksTemplateDataTask>(groupData);
  const {dropCallBack, setCurrItem, isLoading} = useDragTask({setData, data});

  useEffect(() => {
    setData(groupData);
  }, [groupData]);

  return (
    <>
      {data?.tasks?.map((task, i) => {
        return (
          <DragAndDropWrap
            key={task}
            draggingItem={{id: i, task}}
            setCurrItem={setCurrItem}
            dropCallBack={item => dropCallBack(item, data?.tasks || [])}
            className={`TableTaskRow`}
            isLoading={isLoading}
            as="tr"
          >
            <td className="TableTaskRow-cell nameCell">
              <TaskNameCell groupName={groupData?.name} name={task} index={i} />
            </td>
            <td className="TableTaskRow-cell actions">
              <ActionsCell index={i} groupName={groupData?.name} />
            </td>
          </DragAndDropWrap>
        );
      })}
    </>
  );
};
