import {FC} from "react";
import "./FilterLayout.scss";

type tFilterLayoutProps = {
  className?: string;
};

export const FilterLayout: FC<tFilterLayoutProps> = ({children, className = ""}) => {
  return <div className={`FilterLayout ${className}`}>{children}</div>;
};
