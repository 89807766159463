import {useEffect, useState, ChangeEvent} from "react";
import {uid} from "helpers/misc";
import {useSearchField} from "hooks/useSearchField";
import {people as peopleApi} from "api/contacts/people";
import {iJobContactsFieldRow} from "./types";
import {iJobDetailsDataReturn} from "api/jobs/types";
import {iTitlesResponse} from "interfaces/dashboardJobs";

export type tUseJobContactsFieldReturn = ReturnType<typeof useJobContactsField>;

interface iUseJobContactsFieldProps {
  titles: iTitlesResponse[];
  required?: boolean;
  values?: iJobDetailsDataReturn["people"];
}

// this field always required
export function useJobContactsField(props: iUseJobContactsFieldProps) {
  const [values, setValues] = useState<iJobContactsFieldRow[]>([]);
  const [searchingPersons, setSearchingPersons] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [required, setRequired] = useState(props?.required !== undefined ? props.required : true);

  useEffect(() => {
    if (props?.values) {
      const newValues: any = props.values.map(item => {
        const id = uid();
        return {
          uid: id,
          person: item.person,
          title: item.title,
          lead: {value: item.is_lead ? id : ""}
        };
      });
      setValues(newValues);
    }
  }, [props?.values]);

  const person = useSearchField({
    required: true,
    searchConfig: peopleApi.getList,
    searchKey: "search"
  });

  const field: iJobContactsFieldRow = {
    uid: uid(),
    person: null,
    title: null,
    lead: {value: ""}
  };

  useEffect(() => {
    if (!person.value) return;
    setValues(prev => {
      const personData = person?.value?.[values.length];
      if (!prev.length) {
        return [{...field, person: personData, lead: {value: field.uid}}];
      }
      return [...prev, {...field, person: personData}];
    });
    setSearchingPersons(false);
  }, [person?.value]); // eslint-disable-line react-hooks/exhaustive-deps

  const onRemove = (contactRowId: string) => {
    setValues(prev => prev.filter(row => row.uid !== contactRowId));
    person.setValue(null);
  };

  const onChangeTitle = (title: iJobContactsFieldRow["title"], contactRowId: string) => {
    setValues(prev =>
      prev.map(row => {
        if (row.uid === contactRowId) return {...row, title};
        return row;
      })
    );
    setErrors([]);
  };

  const onChangeLead = (event: ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;

    if (value) setErrors([]);

    setValues(prev =>
      prev.map(row => {
        if (row.uid === value) return {...row, lead: {value}};
        return {...row, lead: {value: ""}};
      })
    );
  };

  const addContact = () => {
    if (!values?.length) {
      setSearchingPersons(true);
      return;
    }
    setSearchingPersons(true);
  };

  const checkValidity = () => {
    const isEmpty = !values.length; // doesnt have any contacts

    if (isEmpty && !required) {
      setErrors([]);
      return true;
    }

    if (isEmpty) {
      setErrors([errorMessages.empty]);
      return false;
    }

    if (values.every(row => !row.lead.value)) {
      // when didn't select lead
      setErrors([errorMessages.lead]);
      return false;
    }

    setErrors([]);
    return true;
  };

  return {
    values,
    titles: props.titles,
    setValues,
    onRemove,
    person,
    searchingPersons,
    setSearchingPersons,
    checkValidity,
    errors,
    setErrors,
    addContact,
    onChangeTitle,
    onChangeLead,
    setRequired
  };
}

const errorMessages = {
  emptyPrev: "You should fill all fields before adding new contact.",
  lead: "Lead field can't be empty.",
  empty: "This field is required.",
  required: "All fields should be filled."
};
