import {FC, useState, MouseEvent} from "react";
import {Button} from "components/shared/Button/Button";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {JobSettingsAdvanceContacts} from "./JobSettingsAdvanceContacts";
import {JobSettingsBankDetails} from "./JobSettingsBankDetails";
import {JobSettingsTermsConditions} from "./JobSettingsTermsConditions";
import {JobSettingsTags} from "./JobSettingsTags";
import {JobSettingsTemplates} from "./JobSettingsTemplates/JobSettingsTemplates";
import {JobSettingsBudgetTypes} from "./JobSettingsBudgetTypes/JobSettingsBudgetTypes";
import {JobSettingsCreditCard} from "./JobSettingsCreditCard";
import {JobSettingsPayroll} from "./JobSettingsPayroll";
import {JobSettingsCurrency} from "./JobSettingsCurrency";
import {Icon} from "components/Icon/Icon";

import "./JobSettings.scss";

interface iJobSettingsProps {}

type tModal =
  | "advanceContacts"
  | "bankDetails"
  | "budgetTypes"
  | "creditCards"
  | "defaultCurrencyRates"
  | "payrollCompanies"
  | "labels"
  | "locales"
  | "termsAndConditions";

export const JobSettings: FC<iJobSettingsProps> = () => {
  const [modal, setModal] = useState<tModal | null>(null);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const showMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const onShowModal = (modal: tModal) => {
    setMenuAnchor(null);
    setModal(modal);
  };

  const modalRender = {
    advanceContacts: <JobSettingsAdvanceContacts onClose={setModal} />,
    bankDetails: <JobSettingsBankDetails onClose={setModal} />,
    budgetTypes: <JobSettingsBudgetTypes onClose={setModal} />,
    creditCards: <JobSettingsCreditCard onClose={setModal} />,
    defaultCurrencyRates: <JobSettingsCurrency onClose={setModal} />,
    payrollCompanies: <JobSettingsPayroll onClose={setModal} />,
    labels: <JobSettingsTags type="label" onClose={setModal} />,
    locales: <JobSettingsTags type="locale" onClose={setModal} />,
    termsAndConditions: <JobSettingsTermsConditions onClose={setModal} />
  };

  return (
    <>
      {modal && modalRender[modal]}
      <div className="JobSettings">
        <Button onClick={showMenu} color="light" size="md" textAlign="center">
          Settings
        </Button>
        <DropdownMenu
          withSubmenu
          className="JobSettings"
          anchor={menuAnchor}
          onClose={() => setMenuAnchor(null)}
        >
          <Button
            className="menu-item"
            onClick={() => onShowModal("advanceContacts")}
            width="fullWidth"
            justifyContent="justifyContentStart"
            size="md"
          >
            Advance Contacts
          </Button>
          <Button
            className="menu-item"
            onClick={() => onShowModal("bankDetails")}
            justifyContent="justifyContentStart"
            size="md"
            width="fullWidth"
          >
            Bank Details
          </Button>
          <Button
            className="menu-item"
            onClick={() => onShowModal("budgetTypes")}
            justifyContent="justifyContentStart"
            size="md"
            width="fullWidth"
          >
            BUDGET TYPES
          </Button>
          <Button
            className="menu-item"
            justifyContent="justifyContentStart"
            size="md"
            width="fullWidth"
            onClick={() => onShowModal("creditCards")}
          >
            Credit Cards
          </Button>
          {/* <Button
            className="menu-item"
            justifyContent="justifyContentStart"
            size="md"
            width="fullWidth"
            onClick={() => onShowModal("defaultCurrencyRates")}
          >
            Default Currency Rates
          </Button> */}
          <Button
            className="menu-item"
            justifyContent="justifyContentStart"
            size="md"
            width="fullWidth"
            onClick={() => onShowModal("payrollCompanies")}
          >
            Payroll companies
          </Button>
          <span className="Button md fullWidth menu-item menu-item-nested">
            <span>Tags</span> <Icon icon="triangle-right" size="xsm" color="gray" />
            <span className="NestedMenu tags">
              <Button
                className="menu-item"
                justifyContent="justifyContentStart"
                size="md"
                width="fullWidth"
                onClick={() => onShowModal("labels")}
              >
                Labels
              </Button>
              <Button
                className="menu-item"
                justifyContent="justifyContentStart"
                size="md"
                width="fullWidth"
                onClick={() => onShowModal("locales")}
              >
                Locales
              </Button>
            </span>
          </span>

          <JobSettingsTemplates />

          <Button
            className="menu-item"
            onClick={() => onShowModal("termsAndConditions")}
            justifyContent="justifyContentStart"
            size="md"
            width="fullWidth"
          >
            Terms & Conditions
          </Button>
        </DropdownMenu>
      </div>
    </>
  );
};
