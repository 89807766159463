import {FC} from "react";
import {Button} from "components/shared/Button/Button";
import {useContextWrapPDF} from "../context";

import "./StatusBarLayout.scss";

export const StatusBarLayout: FC = () => {
  const {onSaveToDropbox, isSavingToDropbox, url} = useContextWrapPDF();
  // const {jobDetailsData} = useJobDetails();

  // const fileName = prepareName(
  //   [moment().format("YYYYMMDD"), jobDetailsData?.name || "", String(jobDetailsData?.id) || ""],
  //   "_WRAP_DOC.pdf"
  // );

  return (
    // <div className="StatusBarLayout">
    // <a href={url || ""} className="Button sm outline" download={fileName}>
    //   download pdf
    // </a>
    //   <Button size="sm" color="outline" loading={isSavingToDropbox} onClick={onSaveToDropbox}>
    //     save to dropbox
    //   </Button>
    // </div>
    <Button
      size="sm"
      color="outline"
      style={{marginRight: "8px"}}
      loading={isSavingToDropbox}
      onClick={onSaveToDropbox}
      disabled={!url}
    >
      save to dropbox
    </Button>
  );
};
