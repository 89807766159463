import {FC, FormEvent} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {Button} from "components/shared/Button/Button";
import {AddressField} from "components/shared/FormComponents/AddressField/AddressField";
import {PhoneField} from "components/shared/FormComponents/PhoneField/PhoneField";
import {tUseCompanyFormAddress} from "./useCompanyFormAddress";
import {COMPANY_FORM_ID} from "../../model";

interface iCompanyFormAddress {
  toNextTab: () => void;
  addressFormData: tUseCompanyFormAddress;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

const CompanyFormAddress: FC<iCompanyFormAddress> = ({toNextTab, addressFormData, onSubmit}) => {
  const {formData} = addressFormData;

  return (
    <form className="CompanyFormAddress" id={COMPANY_FORM_ID} onSubmit={onSubmit}>
      <div className="CompanyForm-section">
        <h5 className="CompanyForm-section-title">Address</h5>
        <AddressField
          {...formData.address}
          searchLabel="Address"
          searchPlaceholder="Search address..."
        />
      </div>
      <div className="CompanyForm-section">
        <h5 className="CompanyForm-section-title">Billing Information</h5>
        <div className="CompanyForm-col-2">
          <InputField
            {...formData.billing_info.first_name.inputProps}
            errors={formData.billing_info.first_name.errors}
            id="first_name"
            label="First Name"
          />
          <InputField
            {...formData.billing_info.last_name.inputProps}
            errors={formData.billing_info.last_name.errors}
            id="last_name"
            label="Last Name"
          />
        </div>
        <div className="CompanyForm-col-2">
          <InputField
            {...formData.billing_info.email.inputProps}
            errors={formData.billing_info.email.errors}
            id="email"
            label="Email"
          />
          <InputField
            {...formData.billing_info.phone.inputProps}
            errors={formData.billing_info.phone.errors}
            label="Phone"
          />
        </div>
        <AddressField
          {...formData.billing_info.address}
          searchLabel="Billing address"
          searchPlaceholder="Search address..."
        />
      </div>

      <Button color="light" className="CompanyForm-next" onClick={toNextTab} type="button">
        Continue to Other Details
      </Button>
    </form>
  );
};

export default CompanyFormAddress;
