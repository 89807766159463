import {FormEvent, useEffect, useState} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {locationApi} from "api/location";
import {common} from "api/common";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useRadio} from "hooks/useRadio/useRadio";
import {useSelect} from "hooks/inputHooks";
import {useTagsField} from "hooks/useTagsField/useTagsField";
import {notValidForm} from "helpers/misc";
import {getApiError} from "helpers/getApiError";
import {iLocationDetailsResponse, iLocationPostData} from "api/location/types";
import {useContactFieldGroup} from "components/shared/FormComponents/ContactFieldGroup/useContactFieldGroup";
import {useContextLocations} from "../context/LocationsContext";
import {useAddressField} from "components/shared/FormComponents/AddressField/useAddressField";
import {eventBus} from "EventBus/EventBus";

interface iUseLocationForm {
  location?: number | iLocationDetailsResponse | null;
  onCloseModal: (callback?: Function) => void;
  onEditCallback?: Function;
}

const permitRequiredProps = [
  {
    name: "permit_required",
    label: "Yes",
    value: "yes"
  },
  {name: "permit_required", label: "No", value: "no"}
];

export const useLocationForm = (props?: iUseLocationForm) => {
  const {localesData} = useContextLocations();
  const {location_types} = locationApi;

  const {data: locationDataTypes, call: callLocationTypes} = useHttp();
  const {call: callDeleteLocation, isLoading: isLoadingDelete} = useHttp();

  const {call: callAddLocation, isLoading: isLoadingAdding} = useHttp();
  const {call: callEditLocation, isLoading: isLoadingEditing} = useHttp();
  const {call: callGetLocation, isLoading: isLoadingGetLocation} = useHttp();
  const {call: callCountries} = useHttp();

  const [locationDetails, setLocationDetails] = useState<iLocationDetailsResponse | null>(null);

  const [showConfirmDeleteLocation, setShowConfirmDeleteLocation] = useState<number | null>(null);
  const [showConfirmDeleteContact, setShowConfirmDeleteContact] = useState<number | null>(null);
  const [deleteLocationResult, setDeleteLocationResult] = useState(false);
  const [image, setImage] = useState<string | ArrayBuffer | undefined>(
    typeof props?.location === "object" ? props?.location?.photo_url : ""
  );

  const locationDataToForm =
    typeof props?.location === "object"
      ? {
          name: props?.location?.name,
          rate: props?.location?.rate,
          permit_required: props?.location?.permit_required ? "yes" : "no",
          website: props?.location?.website,
          location_type_id: String(props?.location?.location_type?.id),
          contacts:
            props?.location?.contacts?.map((item: any) => ({
              id: item.contact.id,
              value: {
                person: {
                  id: item.contact.id,
                  name: item.contact.first_name + " " + item.contact.last_name
                },
                title: {
                  id: item.title.id,
                  name: item.title.name
                }
              }
            })) || [],
          locales: props?.location?.locales,
          address: props?.location?.address,
          notes: props?.location?.notes,
          coordinates: props?.location?.coordinates
        }
      : {};

  let formData = {
    name: useTextInput({value: locationDataToForm?.name || ""}),
    rate: useTextInput({
      value: locationDataToForm?.rate || "",
      isRequired: false,
    }),
    permit_required: useRadio({
      isRequired: false,
      fields: permitRequiredProps,
      value: locationDataToForm?.permit_required || ""
    }),
    website: useTextInput({
      isRequired: false,
      validators: ["website"],
      value: locationDataToForm?.website || ""
    }),
    location_type_id: useSelect({
      isRequired: false,
      targetKeys: {value: "id", label: "name"},
      value: locationDataToForm?.location_type_id || ""
    }),
    contacts: useContactFieldGroup({
      isRequired: false,
      // @ts-ignore
      value: locationDataToForm?.contacts
    }),
    locales: useTagsField({
      value: locationDataToForm?.locales,
      isRequired: false
    }),
    address: useAddressField({
      address: locationDataToForm?.address,
      raw: locationDataToForm?.address?.raw,
      coordinates: locationDataToForm?.coordinates,
      isRequired: false,
    }),
    notes: useTextInput({
      value: locationDataToForm?.notes || "",
      isRequired: false
    })
  };

  const isChangedFormData = Object.values(formData).some(field => field?.isChanged);

  useEffect(() => {
    callLocationTypes(location_types.get());
    callCountries(common.getCountries()).then(({data: {data}}) => formData.address.setCountries(data)); // prettier-ignore
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!props?.location) return;

    if (typeof props?.location === "number") {
      callGetLocation(locationApi.location.get(props?.location)).then(({data: {data}}) => {
        setLocationDetails(data);
      });
      return;
    }
    setLocationDetails(props?.location);
  }, [props?.location]); // eslint-disable-line

  useEffect(() => {
    if (locationDataTypes?.data?.data?.length) {
      formData?.location_type_id.setOptions(locationDataTypes?.data?.data);
    }
  }, [locationDataTypes?.data?.data]); // eslint-disable-line

  const confirmDeleteLocation = async () => {
    if (!showConfirmDeleteLocation) return null;
    try {
      const {data} = await callDeleteLocation(
        locationApi.location.delete(showConfirmDeleteLocation)
      );
      eventBus.dispatch("showToast", {text: data?.message});
      props?.onCloseModal(props?.onEditCallback?.());
    } catch (error: any) {
      eventBus.dispatch("showToast", {type: "error", text: error?.data?.message});
    } finally {
      setShowConfirmDeleteLocation(null);
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (notValidForm(formData)) return;

    const {
      address,
      location_type_id,
      name,
      notes,
      contacts,
      website,
      permit_required,
      locales,
      rate
    } = formData;

    const coordinates = address?.coordinates?.lat && {
      lat: address?.coordinates?.lat,
      lng: address?.coordinates?.lng
    };

    const postData: iLocationPostData = {
      name: name.value,
      location_type_id: location_type_id.value,
      permit_required: permit_required?.value === "yes",
      rate: rate.value,
      locales: locales.value.map(item => item.id),
      contacts: contacts.value.map(item => ({
        person_id: Number(item.value?.person?.id),
        title_id: Number(item.value?.title?.id)
      })),
      address: address.address,
      //optional parameters
      ...(notes?.value && {notes: notes.value}),
      ...(coordinates && {...coordinates}),
      ...(website?.value && {website: website?.value}),
      ...(image && image !== locationDetails?.photo_url && {photo: String(image)})
    };

    try {
      const {data} = locationDetails?.id
        ? await callEditLocation(locationApi.location.put(postData, Number(locationDetails?.id)))
        : await callAddLocation(locationApi.location.post(postData));
      eventBus.dispatch("showToast", {text: data?.message});
      props?.onCloseModal(props?.onEditCallback?.());
    } catch (error) {
      const {msg} = getApiError(error, formData);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const showRemoveConfirm = (id: number) => {
    if (!!locationDetails?.jobs?.length) {
      setDeleteLocationResult(true);
      return;
    }

    setShowConfirmDeleteLocation(id);
  };
  return {
    formData,
    handleSubmit,
    confirmDeleteLocation,
    localesData,
    locationDataTypes,
    isLoadingAdding,
    isLoadingEditing,
    isLoadingGetLocation,
    locationDetails,
    image,
    setImage,
    showConfirmDeleteLocation,
    setShowConfirmDeleteLocation,
    showConfirmDeleteContact,
    setShowConfirmDeleteContact,
    deleteLocationResult,
    setDeleteLocationResult,
    isLoadingDelete,
    showRemoveConfirm,
    isChangedFormData
  };
};
