import {createContext, FC, useContext} from "react";
import {useJobBudget, tUseJobBudgetReturn} from "./useJobBudget";

type tJobBudgetContext = tUseJobBudgetReturn;
const JobBudgetContext = createContext<tJobBudgetContext | null>(null);
JobBudgetContext.displayName = "JobBudgetContext";

export const JobBudgetProvider: FC = ({children}) => {
  return <JobBudgetContext.Provider value={useJobBudget()}>{children}</JobBudgetContext.Provider>;
};

export const useContextJobBudget = () => {
  const context = useContext(JobBudgetContext);

  if (context === null) {
    throw new Error("<<< JobBudgetContext >>> must be used within a JobBudgetProvider");
  }

  return context as tJobBudgetContext;
};
