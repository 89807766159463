import {FC, useState, MouseEvent} from "react";
import {jobsApi} from "api/jobs";
import {tLinks} from "api/jobs/types";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import {eventBus} from "EventBus/EventBus";

type tRemoveLinkProps = {
  link: tLinks;
};

export const RemoveLink: FC<tRemoveLinkProps> = ({link}) => {
  const {jobDetailsData, setJobDetails} = useContextJobDetails();

  const [confirmWindow, setConfirmWindow] = useState(false);
  const toggleConfirmWindow = (e: any) => {
    e.stopPropagation();
    setConfirmWindow(prev => !prev);
  };

  const {call, isLoading} = useHttp();

  const removeLink = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!jobDetailsData) return;
    try {
      const res = await call(jobsApi.removeExternalLink(jobDetailsData?.id, link?.id));
      setJobDetails(res?.data?.data);
      eventBus.dispatch("showToast", {text: res?.data?.message});
      setConfirmWindow(false);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error !"});
    }
  };

  return (
    <>
      <Tooltip
        message={"delete"}
        position={"top"}
        onClick={toggleConfirmWindow}
        classname="removeTooltip"
      >
        <Button className="LinkBlock-close" onClick={toggleConfirmWindow} disabled={isLoading}>
          <Icon icon="close-sm" color="gray" />
        </Button>
      </Tooltip>

      <ConfirmWindow
        show={confirmWindow}
        onClose={toggleConfirmWindow}
        onConfirm={removeLink}
        confirmBtn={{text: "Delete", color: "red"}}
        cancelBtn={{text: "Cancel"}}
        title={"Remove link"}
        isLoading={isLoading}
      >
        <div className="DynamicFieldsWrap-confirmModal-body">
          <p>Are you sure you want to delete {link?.title}?</p>
          <p className="DynamicFieldsWrap-confirmModal-warning">This can't be undone.</p>
        </div>
      </ConfirmWindow>
    </>
  );
};
