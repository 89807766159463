import {FC, useState} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {ManageTagsList} from "../ManageTagsList/ManageTagsList";
import {ManageTagsForm} from "../ManageTagsForm/ManageTagsForm";
import {AxiosRequestConfig} from "axios";
import {useHttp} from "hooks/httpServices/useHttp";
import {eventBus} from "EventBus/EventBus";
import {Loading} from "components/shared/Loading/Loading";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {tags} from "api/tags";
import "./ManageTags.scss";

interface iManageLabelsProps {
  show: boolean;
  onClose: () => void;
  type: "locale" | "label";
  config: {
    get: () => AxiosRequestConfig;
    add: (data: any) => AxiosRequestConfig;
    update: (id: number, data: {name: string}) => AxiosRequestConfig;
    delete: (id: number) => AxiosRequestConfig;
  };
}

type tModal = "add" | "edit" | "delete";
type tItem = {id: number; name: string};

export const ManageTags: FC<iManageLabelsProps> = ({type, config, show, onClose}) => {
  const {data, call, isLoading} = useHttp({config: config.get(), onMountCall: true});
  const associationsCall = useHttp();
  const {isLoading: isLoadingDelete, call: callDelete} = useHttp();
  const [associations, setAssociations] = useState(0);

  const [activeItem, setActiveItem] = useState<tItem | null>(null);
  const [modal, setModal] = useState<tModal | null>(null);

  const showEditModal = (data: tItem) => {
    setActiveItem(data);
    setModal("edit");
  };

  const showDeleteModal = async (item: tItem) => {
    if (!item?.id) return;

    const apiCallConfig = {
      locale: tags.locales.getLocaleAssociation,
      label: tags.labels.getLabelAssociation
    };
    try {
      setActiveItem(item);
      setModal("delete");

      const {data: {count}} = await associationsCall.call(apiCallConfig[type](item.id)); // prettier-ignore
      setAssociations(count);
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const onDelete = async () => {
    if (!activeItem?.id) return;
    try {
      const response = await callDelete(config.delete(activeItem.id));
      setAssociations(0);
      closeModal();
      updateList();
      eventBus.dispatch("showToast", {text: response?.data?.message});
    } catch (error: any) {
      eventBus.dispatch("showToast", {type: "error", text: error?.message});
    }
  };

  const showAddModal = () => setModal("add");

  const closeModal = () => {
    setActiveItem(null);
    setModal(null);
  };

  const updateList = async () => await call(config.get());

  const isShowingFormModal = (modal === "edit" && Boolean(activeItem)) || modal === "add";
  const isShowingDeleteModal = Boolean(modal === "delete") && Boolean(activeItem?.id);

  return (
    <>
      <ManageTagsList
        show={show}
        onClose={onClose}
        onEdit={showEditModal}
        onDelete={showDeleteModal}
        showAddModal={showAddModal}
        type={type}
        data={data?.data?.data}
        isLoading={isLoading}
      />
      <ConfirmWindow
        title={`Delete this ${type}?`}
        confirmBtn={{text: "Delete", color: "red"}}
        cancelBtn={{
          text: "cancel"
        }}
        className="ManageLabels-deleteModal"
        show={isShowingDeleteModal}
        onClose={closeModal}
        onConfirm={onDelete}
        isLoading={isLoadingDelete}
      >
        {associationsCall.isLoading && <Loading type="inBlock" />}
        <div className="ManageLabels-deleteModal-body">
          <p>
            Deleting “{activeItem?.name}” will remove the {type} from {associations} associated
            contacts.
          </p>
          <p className="ManageLabels-deleteModal-warning">This cannot be undone.</p>
        </div>
      </ConfirmWindow>

      {isShowingFormModal && (
        <ManageTagsForm
          show={isShowingFormModal}
          data={activeItem}
          title={activeItem ? `Edit ${type}` : `Add ${type}`}
          placeholder={`${type} Name`}
          onClose={closeModal}
          addConfig={config.add}
          updateConfig={config.update}
          updateList={updateList}
        />
      )}
    </>
  );
};
