import {FC, SetStateAction, Dispatch} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {FormWrap} from "components/shared/FormWrap/FormWrap";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {DateRangePicker} from "components/shared/FormComponents/DateRangePicker/DateRangePicker";
import {TextareaField} from "components/shared/FormComponents/TextareaField/TextareaField";
import {Checkbox} from "components/shared/FormComponents/Checkbox/Checkbox";
import {TagButton} from "components/shared/TagButton/TagButton";
import {JobTagsField} from "pages/Jobs/JobDetails/JobDetailsModals/EditJobDetails/JobTagsField/JobTagsField";
import {tEventModal} from "pages/Jobs/JobDetails/JobCalendar/helpers";
import {useEventEdit} from "./useEventEdit";
import "./EventEdit.scss";

interface iEventEditProps {
  event: tEventModal;
  setShowEventDetails?: Dispatch<SetStateAction<tEventModal | null>>;
  onClose: (clearId?: number) => void;
  isUpdating: boolean;
}

export const EventEdit: FC<iEventEditProps> = ({
  event,
  setShowEventDetails,
  isUpdating,
  onClose
}) => {
  const {formData, createEventQuery, eventUpdateQuery, handleSubmit, allDay, setAllDay} =
    useEventEdit({event, setShowEventDetails, isUpdating, onClose});

  const onRemoveLocation = () => formData.location.onChange([]);
  const handleChangeCheckbox = () => setAllDay((prev: boolean) => !prev);
  const handleClose = () => (event?.clearId ? onClose(event.clearId) : onClose());
  return (
    <ModalPopup
      showCloseButton={false}
      className="EventEdit"
      withoutPaddings
      show={Boolean(event)}
      onClose={handleClose}
    >
      <FormWrap
        idForm="event-edit-form"
        submitTitle="Save"
        onCancel={handleClose}
        isLoading={createEventQuery.isLoading || eventUpdateQuery.isLoading}
      >
        <form id="event-edit-form" onSubmit={handleSubmit}>
          <InputField {...formData.name.inputProps} errors={formData.name.errors} label="Title" />
          <DateRangePicker
            {...formData.dates}
            startPickerProps={{
              minDate: new Date()
            }}
            endPickerProps={{
              minDate: new Date(formData.dates.startDate || "")
            }}
            withTime={!allDay}
            separator="to"
          />
          <Checkbox
            checked={allDay}
            onChange={handleChangeCheckbox}
            className="EventEdit-checkbox"
            label="All day"
          />
          <TextareaField
            {...formData.description.inputProps}
            errors={formData.description.errors}
            label="Description"
          />

          {!!formData.location.value.length && (
            <TagButton color="light" onRemove={onRemoveLocation}>
              {formData.location?.value?.[0]?.name}
            </TagButton>
          )}

          {!formData.location.value.length && (
            <JobTagsField
              {...formData.location}
              addButtonText="add location"
              valueKey="id"
              labelKey="name"
              menuPlacement="top"
            />
          )}
        </form>
      </FormWrap>
    </ModalPopup>
  );
};
