import {eventBus} from "EventBus/EventBus";
import {useDebounce} from "hooks/useDebounce";
import {useHttp} from "hooks/httpServices/useHttp";
import {useEffect, useState} from "react";
import {iUseTagsField} from "./interfaces";
import {tags as tagsApi} from "api/tags";

type tOption = {
  id: number;
  name: string;
};

export function useTagsField<T extends tOption>(props?: iUseTagsField<T>) {
  const {isLoading, call} = useHttp();
  const [options, setOptions] = useState<T[]>(props?.options || []);
  const [value, setValue] = useState<T[]>(props?.value || []);

  const [errors, setErrors] = useState(props?.errors || []);
  const [validateOnChange, setValidateOnChange] = useState(!!props?.validateOnChange);

  const [isValid, setIsValid] = useState(props?.isValid !== undefined ? props.isValid : false);
  const [isRequired, setIsRequired] = useState(
    props?.isRequired !== undefined ? props.isRequired : true
  );
  const [isDirty, setIsDirty] = useState(false);
  const [search, setSearch] = useState("");

  const emptyErr = props?.emptyErrText ? props?.emptyErrText : "This field can't be empty";

  const debounceValue = useDebounce(search, 300);

  useEffect(() => {
    if (debounceValue) {
      onSearch(debounceValue);
    }
  }, [debounceValue]); // eslint-disable-line

  const onInputChange = (value: string) => setSearch(value);

  useEffect(() => {
    if (!validateOnChange) {
      props?.onChangeCallback?.();
      return;
    }
    checkValidity();
    props?.onChangeCallback?.();
  }, [value]); // eslint-disable-line

  useEffect(() => {
    isRequired && isDirty && checkValidity();
    !isRequired &&
      isDirty &&
      errors.includes(emptyErr) &&
      setErrors(errors.filter(err => err !== emptyErr));
  }, [isRequired]); // eslint-disable-line

  const onSearch = async (query: string) => {
    try {
      const response = await call(tagsApi.locales.get({search: query, search_type: "and"}));
      setOptions(response?.data?.data);
    } catch (error: any) {
      eventBus.dispatch("showToast", {type: "error", text: error?.data?.message});
    }
  };

  const onFocus = (value: T[]) => {
    props?.onFocusCallback?.(value);
  };

  const onChange = (val: T[]) => {
    setValue(val);
    checkValidity(val);
    !isDirty && setIsDirty(true);
  };

  const validateAndSet = (val: T[]) => {
    setValue(val);
    setValidateOnChange(true);
    checkValidity(val);
  };

  const checkValidity = (val?: T[]) => {
    const newErrors: string[] = [];
    const newValue = !val ? value : val;

    if (isRequired && !newValue?.length) {
      newErrors.push(emptyErr);
    }

    if (!isRequired || newValue?.length) {
      setErrors([]);
      setIsValid(true);
      return true;
    }

    setErrors(newErrors);
    setIsValid(!newErrors?.length);
    return !newErrors?.length;
  };

  function isChanged() {
    if (!props?.value && !value?.length) return false;
    if (props?.value?.length !== value?.length) return true;

    return !props?.value?.filter(propLocales => !!value?.find(val => val?.id === propLocales?.id))
      .length;
  }

  return {
    value,
    setValue: validateAndSet,
    onChange,
    onFocus,
    errors,
    setErrors,
    isValid,
    setIsValid,
    isRequired,
    setIsRequired,
    isDirty,
    checkValidity,
    validateOnChange,
    setValidateOnChange,
    isLoading,
    isChanged: isChanged(),
    inputProps: {
      onInputChange,
      value,
      onChange,
      options
    }
  };
}
