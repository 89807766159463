import {AxiosRequestConfig} from "axios";
import {tTitlesParams} from "./types";

export const titles = {
  get: (params?: tTitlesParams): AxiosRequestConfig => ({
    url: "titles",
    method: "get",
    params
  }),
  addNewTitle: (data: {name: string}): AxiosRequestConfig => ({
    url: "title",
    method: "post",
    data
  })
};
