import {useEffect, useState} from "react";
import {useDebounce} from "./useDebounce";
import {AxiosRequestConfig} from "axios";
import {useHttp} from "./httpServices/useHttp";
import {iBaseOptions} from "api/common/types";
interface iUseTagsSelectGroup {
  value?: iBaseOptions[];
  isRequired?: boolean;
  errors?: string[];
  searchConfig: ({search}: {search: string}) => AxiosRequestConfig;
}
export const useTagsSelectGroup = (props: iUseTagsSelectGroup) => {
  const {call, isLoading} = useHttp();
  const [isShowRemoveWindow, setIsShowRemoveWindow] = useState(false);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState<any[]>([]);
  const [value, setValue] = useState<any[]>(props?.value ?? []);
  const [deletingValue, setDeletingValue] = useState<any>();
  const [errors, setErrors] = useState<string[]>(props?.errors ?? []);

  const debounceValue = useDebounce(search, 250);

  useEffect(() => {
    if (debounceValue) {
      onSearch(debounceValue);
    }
  }, [debounceValue]); // eslint-disable-line

  const hideRemoveWindow = () => setIsShowRemoveWindow(false);

  const onSearch = async (value: string) => {
    try {
      const response = await call(props.searchConfig({search: value}));
      setOptions(response?.data?.data);
    } catch (error: any) {
      console.log(error?.message);
    }
  };

  const onInputChange = (v: string) => setSearch(v);

  const removeTag = (id: string) => {
    setDeletingValue(value.find(item => item.id === Number(id)));
    setIsShowRemoveWindow(true);
  };

  const confirmRemove = () => {
    setValue(prev => prev.filter(({id}) => id !== deletingValue.id));
    setIsShowRemoveWindow(false);
  };

  const checkValidity = () => {
    if (props.isRequired && !value.length) {
      setErrors(["This field is required"]);
      return false;
    }
    setErrors([]);
    return true;
  };

  const onChange = (val: any[]) => setValue(val);

  function isChanged() {
    const propArray = props?.value || [];
    const values = value;

    if (!propArray?.length && values?.[0] && !values?.[0]?.id && !values?.[0]?.value) return false;
    if (propArray.length !== values.length) return true;

    return !!values.filter(
      value => !propArray?.some(prop => prop.id === value.id && prop.value === value.value)
    ).length;
  }

  return {
    removeTag,
    isShowRemoveWindow,
    hideRemoveWindow,
    confirmRemove,
    value,
    checkValidity,
    onInputChange,
    onChange,
    options,
    setOptions,
    errors,
    setErrors,
    deletingValue,
    isLoading,
    isChanged: isChanged()
  };
};
