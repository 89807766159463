import {useEffect} from "react";
import {useRadio} from "hooks/useRadio/useRadio";
import {tExpenseListItem} from "api/budget/track/types";
import {useGenerateSearchParams} from "../../ExpenseList/ExpenseTable/_helpers/useGenerateSearchParams";
import {generateRadioList} from "../../ExpenseList/ExpenseTable/_helpers/helpers";
import {tOptions, useGetFIlterLists} from "./useGetFIlterLists";

export const useFIltersExpenseList = () => {
  const {apiParams, searchParams} = useGenerateSearchParams();
  const {alert, bill_state, green, qb_state, w8_file, w9_file, ach_file, wire_file, actual_file} = searchParams; // prettier-ignore
  const {getSelectedTag, payIdList, payIdsArr, lineNumberList, lineNumbersArr, qbArr, qbList, payStatusArr, payStatusList} = useGetFIlterLists(); // prettier-ignore

  const filterControl = {
    alert: useRadio({fields: generateRadioList({name: "alert"}), value: alert ? alert : ""}),
    lineNumber: {
      value: getSelectedTag(lineNumberList, lineNumbersArr),
      onChange: (option: tOptions[]) => {
        const params = {line_numbers: option.length ? option.map((item: tOptions) => item.id) : []};
        apiParams.setApiParams({...Object.fromEntries(apiParams.searchParams), params});
      }
    },
    pay_value: {
      value: getSelectedTag(payIdList, payIdsArr),
      onChange: (option: tOptions[]) => {
        const params = {pay_value: option.length ? option.map((item: tOptions) => item.id) : []};
        apiParams.setApiParams({...Object.fromEntries(apiParams.searchParams), params});
      }
    },
    // additional_files: useRadio({
    //   fields: generateRadioList({name: "additional_files", label: "Yes", label2: "No"}),
    //   value: additional_files ? additional_files : ""
    // }),
    actual_file: useRadio({
      fields: generateRadioList({name: "actual_file", label: "Yes", label2: "No"}),
      value: actual_file ? actual_file : ""
    }),
    bill_state: useRadio({
      fields: generateRadioList({name: "bill_state"}),
      value: bill_state ? bill_state : ""
    }),
    green: useRadio({
      fields: generateRadioList({name: "green"}),
      value: green ? green : ""
    }),
    qb_status: {
      value: getSelectedTag(qbList, qbArr),
      onChange: (option: tOptions[]) => {
        const params = {qb_status: option.length ? option.map((item: tOptions) => item.id) : []};
        apiParams.setApiParams({...Object.fromEntries(apiParams.searchParams), params});
      }
    },
    pay_status: {
      value: getSelectedTag(payStatusList, payStatusArr),
      onChange: (option: tOptions[]) => {
        const params = {pay_status: option.length ? option.map((item: tOptions) => item.id) : []};
        apiParams.setApiParams({...Object.fromEntries(apiParams.searchParams), params});
      }
    },
    qb_state: useRadio({
      fields: generateRadioList({name: "qb_state"}),
      value: qb_state ? qb_state : ""
    }),
    w8_file: useRadio({
      fields: generateRadioList({name: "w8_file", label: "Yes", label2: "No"}),
      value: w8_file ? w8_file : ""
    }),
    w9_file: useRadio({
      fields: generateRadioList({name: "w9_file", label: "Yes", label2: "No"}),
      value: w9_file ? w9_file : ""
    }),
    ach_file: useRadio({
      fields: generateRadioList({name: "ach_file", label: "Yes", label2: "No"}),
      value: ach_file ? ach_file : ""
    }),
    wire_file: useRadio({
      fields: generateRadioList({name: "wire_file", label: "Yes", label2: "No"}),
      value: wire_file ? wire_file : ""
    })
  };

  useEffect(() => {
    const params: Partial<Record<keyof tExpenseListItem, any>> = {
      alert: filterControl.alert.value,
      // additional_files: filterControl?.additional_files?.value,
      actual_file: filterControl?.actual_file?.value,
      bill_state: filterControl?.bill_state?.value,
      green: filterControl?.green?.value,
      qb_state: filterControl?.qb_state?.value,
      w8_file: filterControl?.w8_file?.value,
      w9_file: filterControl?.w9_file?.value,
      ach_file: filterControl?.ach_file?.value,
      wire_file: filterControl?.wire_file?.value
    };

    apiParams.setApiParams({...Object.fromEntries(apiParams.searchParams), params});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterControl.alert.value,
    filterControl?.bill_state?.value,
    filterControl?.green?.value,
    // filterControl?.additional_files?.value,
    filterControl?.actual_file?.value,
    filterControl?.qb_state?.value,
    filterControl?.w8_file?.value,
    filterControl?.w9_file?.value,
    filterControl?.ach_file?.value,
    filterControl?.wire_file?.value
  ]);

  const clearFilters = () => {
    filterControl.alert.setValue("");
    // filterControl.additional_files.setValue("");
    filterControl.actual_file.setValue("");
    filterControl.bill_state.setValue("");
    filterControl.green.setValue("");
    filterControl.qb_state.setValue("");
    filterControl.w8_file.setValue("");
    filterControl.w9_file.setValue("");
    filterControl.ach_file.setValue("");
    filterControl.wire_file.setValue("");
    apiParams?.clearAllParams();
  };

  return {filterControl, clearFilters, lineNumberList, payIdList, qbList, payStatusList};
};
