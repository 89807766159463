import {useContextJobCalendar} from "../../context";
import {useHttp} from "hooks/httpServices/useHttp";
import {useState} from "react";
import {jobsCalendarApi} from "api/jobs/calendar";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {saveDataFromFile} from "helpers/files/saveDataFromFile";
import {tEventModal} from "../../helpers";
import {eventBus} from "EventBus/EventBus";

interface iUseEventDetailsProps {
  data: tEventModal;
  onClose: () => void;
}

export function useEventDetails({data, onClose}: iUseEventDetailsProps) {
  const {activeCalendar, getCalendarSheet, setCalendarSheets} = useContextJobCalendar();
  const {call} = useHttp();
  const exportEventQuery = useHttp();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const isLocked = activeCalendar?.locked;

  const onConfirmDelete = () => setConfirmDelete(true);

  const onDelete = async () => {
    setIsLoading(true);
    try {
      const response = await call(jobsCalendarApi.deleteJobCalendarEvent(data.id));
      activeCalendar?.id && (await getCalendarSheet(activeCalendar.id));
      setCalendarSheets(prev => prev.filter(ev => ev.id !== data.id));
      eventBus.dispatch("showToast", {text: response?.data?.message});
      setIsLoading(false);
      onClose();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
      setIsLoading(false);
    }
  };

  const onExport = async () => {
    try {
      const response = await exportEventQuery.call(jobsCalendarApi.exportJobCalendarEvent(data.id));
      await saveDataFromFile(response?.data, `${data.name}.ics`);
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return {
    onExport,
    onDelete,
    onConfirmDelete,
    isLocked,
    isLoading,
    confirmDelete,
    setConfirmDelete
  };
}
