import {FC} from "react";
import {tTrackCostValue, tTrackFeesItem} from "api/budget/track/types";
import {trackApi} from "api/budget/track";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useParams} from "react-router-dom";
import {useContextTrack} from "../../../../_context/TrackContext";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {TooltipPortal} from "components/shared/Tooltip/TooltipPortal";
import {Icon} from "components/Icon/Icon";
import {AppTooltips} from "components/AppTooltips/AppTooltips";
import {Button} from "components/shared/Button/Button";
import {Loading} from "components/shared/Loading/Loading";
import {useCalculatePosition} from "components/AppTooltips/useCalculatePosition";
import "./CostValueRowName.scss";

type tCostValueRowNameProps = {
  className?: string;
  trackCost: Partial<tTrackCostValue>;
  typePayroll?: tTrackFeesItem["target_name"];
  isFirstItem?: boolean;
};

export const CostValueRowName: FC<tCostValueRowNameProps> = ({
  className = "",
  trackCost,
  typePayroll,
  isFirstItem
}) => {
  const {id} = useParams();
  const {setTrackData} = useContextTrack();
  const {call, isLoading} = useHttp();

  const {styles, chevronPosition, ref} = useCalculatePosition({
    parentId: "expense-table-wrap-id",
    chevronPosition: "topLeft",
    addToY: 25,
    addToX: -38
  });

  const addNewLine = async () => {
    try {
      let res: any;

      if (typePayroll && id) {
        res = await call(trackApi.addExpenseLIneForPayrollFee(+id, {target_name: typePayroll}));
      }

      if (!!trackCost.id) {
        res = await call(trackApi.addExpanseLine(trackCost.id));
      }

      res?.data?.data && setTrackData(res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <div className={`${className} CostValueRowName`}>
      <TextComponent weight="400" size="14">
        {trackCost?.code}
        {trackCost?.suffix}
      </TextComponent>

      <div className="descriptions">
        <TooltipPortal message={trackCost?.name} position="top" maxWidth={256}>
          <TextComponent weight="500" size="14" truncate as="div">
            {trackCost?.name}
          </TextComponent>
        </TooltipPortal>

        <TextComponent weight="400" size="13" color="gray-10" truncate>
          {trackCost?.description}
        </TextComponent>
        <TextComponent weight="400" size="13" color="red" truncate>
          {trackCost?.discount_description}
        </TextComponent>
        <TextComponent weight="400" size="13" color="gray-10" truncate>
          {trackCost?.private_memo}
        </TextComponent>
      </div>

      <Button onClick={addNewLine} className="CostValueRowName-addLineBtn">
        {isLoading && <Loading type="inBlock" customSize={0.5} />}
        <Icon icon="add" color="gray" size="xsm" />
      </Button>
      <div ref={ref}>
        {isFirstItem && (
          <AppTooltips
            page="track"
            tooltip="TRACK EXPENSES"
            chevronPosition={chevronPosition}
            positionStyles={styles}
          />
        )}
      </div>
    </div>
  );
};
