import {FC} from "react";
import "./CallSheetMsg.scss";

type tCallSheetMsgProps = {
  msg: string;
};

export const CallSheetMsg: FC<tCallSheetMsgProps> = ({msg}) => {
  return <div className="CallSheetMsg" dangerouslySetInnerHTML={{__html: msg}} />;
};
