import {pageTabObj} from "components/Header/Header";
import {absRoutes} from "router/helpers";

export const tabs: pageTabObj[] = [
  {
    label: "People",
    url: absRoutes.contacts.people,
    showTooltip: {page: "people", tooltip: "CONTACT TYPES"}
  },
  {label: "Companies", url: absRoutes.contacts.companies}
];
