import {FC} from "react";
import {StyleSheet, View} from "@react-pdf/renderer";
import {ClientSection} from "./ClientSection";
import {iJobDetailsDataReturn} from "api/jobs/types";
import {JobDescriptionSection} from "./JobDescriptionSection";
import {CreativeSection} from "./CreativeSection";
import {tEstimateExportInfo} from "api/budget/estimate/types";
import {EstimatedProductionCosts} from "./EstimatedProductionCosts";
import {ConfirmationSection} from "./ConfirmationSection";
import {TotalEstimateSection} from "./TotalEstimateSection";

interface iEstimateSummaryPageProps {
  jobDetailsData: iJobDetailsDataReturn;
  data: tEstimateExportInfo;
}

export const EstimateSummaryPage: FC<iEstimateSummaryPageProps> = ({data, jobDetailsData}) => {
  if (!data || !jobDetailsData) return null;
  return (
    <View style={styles.container}>
      <View style={styles.halfSection}>
        <ClientSection
          name={jobDetailsData?.client?.name || ""}
          address={jobDetailsData?.client?.address}
        />
      </View>

      <View style={styles.jobDetailsRow}>
        <View style={styles.halfSection}>
          <JobDescriptionSection
            po={data.po}
            jobNumber={data.job_number}
            jobName={data.job_name}
            jobEndAt={data.job_end_at || null}
            jobStartAt={data.job_start_at || null}
            shootLocation={data.shoot_location}
            photographer={data.photographer}
            notes={data.notes}
            label1={data?.extended?.[0]?.label}
            label2={data?.extended?.[1]?.label}
            text1={data?.extended?.[0]?.text}
            text2={data?.extended?.[1]?.text}
          />
        </View>
        <View style={styles.halfSection}>
          <CreativeSection license={data.usage_license} creative={data.creative} />
        </View>
      </View>

      {/* <View style={styles.fullWidthRow}> */}
      <EstimatedProductionCosts data={data.estimate} />
      {/* </View> */}

      <View style={styles.fullWidthRow}>
        <View style={styles.halfSection}>
          <ConfirmationSection jobDetailsData={jobDetailsData} />
        </View>
        <View style={styles.halfSection}>
          <TotalEstimateSection estimate={data.estimate} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  fullWidthRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    marginBottom: 20
  },
  jobDetailsRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%"
    // marginBottom: 20
  },
  halfSection: {
    display: "flex",
    flexDirection: "row",
    width: "48%",
    marginBottom: 20
  }
});
