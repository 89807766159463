import {useState, FormEvent, Dispatch, SetStateAction, useEffect} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {tBankDetails} from "api/budget/bankDetails/types";
import {eventBus} from "EventBus/EventBus";
import {bankDetailsApi} from "api/budget/bankDetails";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {notValidForm} from "helpers/misc";

interface iUseAdvanceBankModalProps {
  setBankList: Dispatch<SetStateAction<tBankDetails[]>>;
}

type tModal = "edit" | "add" | "delete";

export function useAdvanceBankModal({setBankList}: iUseAdvanceBankModalProps) {
  const deleteBankQuery = useHttp();
  const createBankQuery = useHttp();
  const editBankQuery = useHttp();

  const [modal, setModal] = useState<tModal | null>(null);
  const [activeBank, setActiveBank] = useState<tBankDetails | null>(null);

  const formData = {
    name: useTextInput({
      value: activeBank?.name || "",
      isRequired: true
    }),
    details: useTextInput({
      value: activeBank?.details || "",
      isRequired: true
    })
  };

  useEffect(() => {
    if (modal === "edit" && activeBank) {
      formData.name.setValue(activeBank.name);
      formData.details.setValue(activeBank.details);
    }

    if (modal === "add") {
      formData.name.onReset();
      formData.details.onReset();
    }
  }, [modal, activeBank]); // eslint-disable-line react-hooks/exhaustive-deps

  const onShowDelete = (bank: tBankDetails) => {
    setActiveBank(bank);
    setModal("delete");
  };

  const onShowEdit = (bank: tBankDetails) => {
    setModal("edit");
    setActiveBank(bank);
  };

  const onDelete = async () => {
    if (!activeBank) return;
    try {
      const {data: { message}} = await deleteBankQuery.call(bankDetailsApi.removeBankDetails(activeBank.id)); // prettier-ignore

      //const isSelectedBank = advanceDetails?.bank_detail?.id === activeBank.id;
      //isSelectedBank && setAdvanceDetails((prevState) => (prevState ? {...prevState, bank_detail: null} : null)); // prettier-ignore

      setBankList(prevState => prevState.filter(bank => +bank.id !== +activeBank.id));
      eventBus.dispatch("showToast", {text: message});

      showModal(null);
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {text: msg, type: "error"});
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (notValidForm(formData)) return;

    const isEditing = !!activeBank;

    const payload = {
      name: formData.name.value,
      details: formData.details.value
    };

    try {
      const {
        data: {data, message}
      } = isEditing
        ? await editBankQuery.call(bankDetailsApi.updateBankDetails(activeBank.id, payload))
        : await createBankQuery.call(bankDetailsApi.createBankDetails(payload));

      if (isEditing) {
        setBankList((prevState: tBankDetails[]) => prevState.map(bank => (+bank.id === +data.id ? data : bank))); // prettier-ignore
        // setAdvanceDetails(prev => (prev ? {...prev, bank_detail: data} : null));
      } else {
        setBankList((prevState: tBankDetails[]) => [...prevState, data]);
        // setAdvanceDetails(prev => (prev ? {...prev, bank_detail: data} : null));
      }

      eventBus.dispatch("showToast", {text: message});
      showModal(null);
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {text: msg, type: "error"});
    }
  };

  const showModal = (type: tModal | null) => {
    setModal(type);
    !type && setActiveBank(null);
  };

  return {
    editBankQuery,
    deleteBankQuery,
    createBankQuery,

    modal,
    showModal,
    setModal,
    onDelete,
    handleSubmit,
    formData,
    onShowEdit,
    onShowDelete,
    activeBank
  };
}
