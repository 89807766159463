import {FC, useEffect, useState} from "react";
import {JobFilesHeader} from "./JobFilesHeader/JobFilesHeader";
import {JobFilesList} from "./JobFilesList/JobFilesList";
import {JobFilesListHeader} from "./JobFilesListHeader/JobFilesListHeader";
import {Route, Routes, useParams, useLocation, Link} from "react-router-dom";
import {allIn} from "helpers/misc";
import {routes} from "router/routes";
import {DropboxAuthorize} from "./DropboxAuthorize/DropboxAuthorize";
import {useHttp} from "hooks/httpServices/useHttp";
import {jobFilesApi} from "api/jobs/files";
import {iJobFilesReturn} from "api/jobs/files/types";
import {dropboxApi} from "api/dropbox";
import {Loading} from "components/shared/Loading/Loading";
import {Icon} from "components/Icon/Icon";
import "./JobFiles.scss";

interface iJobFilesProps {}

export const JobFiles: FC<iJobFilesProps> = () => {
  const location = useLocation();
  const params = useParams();
  const jobFilesQuery = useHttp();
  const dropboxAuthQuery = useHttp();

  const [sortBy, setSortBy] = useState<"asc" | "desc">("asc");
  const [data, setData] = useState<iJobFilesReturn["entries"] | null>(null);
  const [authUrl, setAuthUrl] = useState("");
  const [isSigned, setIsSigned] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    dropboxAuthQuery.call(dropboxApi.dropboxAuth()).then(({data: {data}}) => {
      setAuthUrl(data.url);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const path = params["*"] || "/";
    jobFilesQuery
      .call(jobFilesApi.getJobFiles(Number(params.id), path))
      .then(({data}) => {
        setData(data.entries);
        setIsSigned(true);
        errorMessage && setErrorMessage("");
      })
      .catch((error: any) => {
        if (error.response.data.message === "Path not found") {
          setErrorMessage(error.response.data.message);
          setIsSigned(true);
          return;
        }
        setIsSigned(false);
      });
  }, [params, location]); // eslint-disable-line react-hooks/exhaustive-deps

  const pathArray = location.pathname.split("/");
  const backPath = pathArray.splice(0, pathArray.length - 1).join("/");
  const isEmptyData = !data?.length;

  return (
    <div className="JobFiles">
      {!data && jobFilesQuery.isLoading && <Loading type="inBlock" />}
      {!isSigned && <DropboxAuthorize authUrl={authUrl} />}
      {isSigned && (
        <>
          <JobFilesHeader data={data} />
          <JobFilesListHeader sortBy={sortBy} onSort={setSortBy} />
          {errorMessage && (
            <div className="JobFiles-error">
              <Link to={backPath} className="JobFiles-error-back">
                <Icon icon="arrow-left" size="lg" />
                Go back
              </Link>
              <p className="JobFiles-error-text">{errorMessage}</p>
            </div>
          )}

          {isEmptyData && !jobFilesQuery.isLoading && (
            <h3 className="JobFiles-emptyMessage">Files haven't been added to Dropbox yet.</h3>
          )}
          {data && (
            <Routes>
              <Route
                index
                element={
                  <JobFilesList isLoading={jobFilesQuery.isLoading} sortBy={sortBy} data={data} />
                }
              />
              <Route
                path={allIn(routes.jobs.details.files.directory)}
                element={
                  <JobFilesList isLoading={jobFilesQuery.isLoading} sortBy={sortBy} data={data} />
                }
              />
            </Routes>
          )}
        </>
      )}
    </div>
  );
};
