import {FC, useEffect, useState, FocusEvent} from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {components, SingleValue} from "react-select";
import {useHttp} from "hooks/httpServices/useHttp";
import {Icon} from "components/Icon/Icon";
import {AxiosRequestConfig} from "axios";
import {tContactFieldGroupRow} from "./interfaces";
import {eventBus} from "EventBus/EventBus";
import "./ContactFieldItem.scss";

type tOption = {
  name: string;
  id: string;
};

interface iContactFieldItemProps {
  item: tContactFieldGroupRow;
  onChange: (data: tContactFieldGroupRow) => void;
  confirmRemove: (item: any) => void;
  handleRemove: (id: string) => void;
  refetchTitle: () => Promise<any>;
  callOnCreateTitle: (data: {name: string}) => AxiosRequestConfig;
  relationshipOption: tOption[];
  personOption: tOption[];
  personNameKeys: string[]; // example ["first_name", "last_name"]
  onInputChange: (str: string) => void;
  isLoading: boolean;
}

export const ContactFieldItem: FC<iContactFieldItemProps> = ({
  item,
  onChange,
  handleRemove,
  personOption,
  relationshipOption,
  callOnCreateTitle,
  refetchTitle,
  personNameKeys,
  confirmRemove,
  onInputChange,
  isLoading
}) => {
  const {call: callCreating, isLoading: creatingIsLoading} = useHttp();
  const defaultPerson = item?.value?.person as unknown as tOption;
  const defaultTitle = item?.value?.title as unknown as tOption;
  const [person, setPerson] = useState<tOption | null>(defaultPerson || null);
  const [title, setTitle] = useState<tOption | null>(defaultTitle || null);

  const returnPersonName = (data: any) => {
    return personNameKeys.map(k => data[k]).join(" ");
  };

  useEffect(() => {
    if (person) {
      onChange({
        ...item,
        value: {
          person: {
            id: Number(person?.id),
            name: person?.name
          },
          title: {
            id: title?.id ? Number(title?.id) : 0,
            name: title?.name ? title?.name : ""
          }
        }
      });
    }
  }, [person, title]); // eslint-disable-line

  const handleChangeUser = (user: any) => {
    setPerson({id: user.id, name: `${user.first_name} ${user.last_name}`});
  };

  const handleChangeRelationship = (option: SingleValue<tOption | null>) => {
    setTitle(option);
  };

  const onCreateOption = async (value: string) => {
    try {
      const response = await callCreating(callOnCreateTitle({name: value}));
      const {
        data: {data}
      } = await refetchTitle();

      const createdItem = data.find((item: tOption) => item.name === value);
      setTitle(createdItem);
      eventBus.dispatch("showToast", {text: response?.data?.message});
    } catch (error: any) {
      eventBus.dispatch("showToast", {type: "error", text: error?.data?.message});
    }
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      return handleRemove(item.id);
    }
  };

  return (
    <div className="ContactFieldItem">
      {person?.id && (
        <div className="ContactFieldItem-row">
          <div className="ContactFieldItem-name">
            <span>{person?.name}</span>
            <button type="button" onClick={() => confirmRemove(item)}>
              <Icon icon="close" size="sm" />
            </button>
          </div>
          <div className="ContactFieldItem-relationship">
            <CreatableSelect
              isClearable
              onCreateOption={onCreateOption}
              getOptionLabel={(option: any) => {
                if (option?.__isNew__) {
                  return `Press enter to add new title "${option?.value}"`;
                }
                return option.name;
              }}
              getOptionValue={(option: any) => {
                return option?.id;
              }}
              value={title}
              options={relationshipOption}
              onChange={handleChangeRelationship}
              className="ContactFieldItem-selectCreate"
              classNamePrefix="ContactFieldItem-select"
              isLoading={creatingIsLoading}
              isDisabled={creatingIsLoading}
            />

            {!creatingIsLoading && (
              <div className="ContactFieldItem-error">
                {item?.errors?.map(error => (
                  <span key={error}>{error}</span>
                ))}{" "}
              </div>
            )}
          </div>
        </div>
      )}

      {!item.value && !person?.id && (
        <Select
          autoFocus
          onBlur={handleBlur}
          placeholder="Find contact to link"
          value={person}
          onChange={handleChangeUser}
          options={personOption}
          onInputChange={onInputChange}
          isLoading={isLoading}
          className="ContactFieldItem-personSelect"
          classNamePrefix="ContactFieldItem-select"
          getOptionValue={option => option.id}
          getOptionLabel={(option: any) => `${option.first_name} ${option.last_name}`}
          formatOptionLabel={(option: any) => returnPersonName(option)}
          components={{
            NoOptionsMessage: props => (
              <components.NoOptionsMessage {...props}>
                <span>No results found.</span>
              </components.NoOptionsMessage>
            )
          }}
        />
      )}
    </div>
  );
};
