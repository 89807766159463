import {FC} from "react";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {Title} from "../common/Title";
import {ContactBlock} from "../common/ContactBlock";
import {TextComponent} from "../../common/TextComponent/TextComponent";

interface iDetailsProps {
  [key: string]: any;
}

export const Details: FC<iDetailsProps> = () => {
  const {jobDetailsData} = useContextJobDetails();
  if (!jobDetailsData) return null;
  const {description, notes} = jobDetailsData;

  const isContent = description || notes;
  return (
    <div className={`JobOverview-details ${isContent ? "isContent" : ""}`}>
      <Title text="Details" fullWidth />
      {!description && !notes && (
        <ContactBlock placeholder="Job description and notes will appear here" textCenter />
      )}

      {description && (
        <>
          <TextComponent weight="400" size="11" uppercase color="gray-0">
            Description
          </TextComponent>
          <TextComponent weight="400" size="13">
            {description}
          </TextComponent>
        </>
      )}

      {notes && (
        <>
          <TextComponent weight="400" size="11" uppercase color="gray-0" className="notes">
            Notes
          </TextComponent>
          <TextComponent weight="400" size="13">
            {notes}
          </TextComponent>
        </>
      )}
    </div>
  );
};
