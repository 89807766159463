import {useState} from "react";
import {Datepicker} from "components/shared/FormComponents/Datepicker/Datepicker";
import {Button} from "components/shared/Button/Button";

import moment from "moment";

import "./PipelineCalendar.scss";

interface iProps {
  onSave: (date: string) => void;
  onCancel?: () => void;
  endDate: string | null | undefined;
  dueDate: string | null | undefined;
  name: string | undefined;
  isLoading: boolean;
}

export const PipelineCalendar = ({
  endDate,
  dueDate,
  isLoading,
  onSave,
  onCancel,
  name = ""
}: iProps) => {
  const pipelineName: any = name?.includes("W/") ? "wrap" : name?.toLowerCase();
  const calendarTitle: any = {
    "to client": "When was the invoice sent?",
    "all paid": "When was payment received?",
    wrap: "When is the wrap due?"
  };

  const title = pipelineName ? calendarTitle[pipelineName] : "";
  const isWrapDue = pipelineName === "wrap";
  const initDueDate = dueDate || endDate;
  const initDate = isWrapDue ? moment(initDueDate).add(dueDate ? 0 : 22, "day") : moment();

  const [value, setValue] = useState(initDate.toISOString());
  const [days, setDays] = useState(dueDate ? moment(dueDate).diff(endDate, "day") : 22);

  const handleSave = () => onSave(value);

  const onChange = (v: string) => {
    const difference = moment(v).diff(endDate, "day");
    setValue(v);
    setDays(difference);
  };

  const dueDateDifferrence = isWrapDue ? `${days} days` : "";

  return (
    <div className="PipelineCalendar">
      <h6 className="PipelineCalendar-title">{title}</h6>
      <Datepicker
        inputValueCalculation={dueDateDifferrence}
        onChange={onChange}
        value={value}
        className="PipelineCalendar-picker"
        open={true}
      />
      <div className="PipelineCalendar-buttons">
        <Button size="sm" color="outline" className="PipelineCalendar-cancel" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          loading={isLoading}
          size="sm"
          color="light"
          className="PipelineCalendar-save"
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
