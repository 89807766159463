import {useState} from "react";
import {ButtonGroup} from "components/ButtonGroup/ButtonGroup";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {TextComponent} from "../../../common/TextComponent/TextComponent";
import {useContextJobCrew} from "../../JobCrewContext";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {AddGroup} from "./AddGroup";
import {SaveNewTemplate} from "./SaveNewTemplate";
import {SaveAsExistingTemplate} from "./SaveAsExistingTemplate";

export const MainHeader = () => {
  const {
    setShowSelectContactIcon,
    jobCrewGroupList: {crewGroupList},
    onlyActive,
    setOnlyActive
  } = useContextJobCrew();

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const isContacts = !!crewGroupList?.find(item => !!item?.contacts?.length);
  return (
    <>
      <TextComponent uppercase className="title">
        Crew
      </TextComponent>

      {isContacts && (
        <ButtonGroup>
          <Button className={!onlyActive ? "active" : ""} onClick={() => setOnlyActive(false)}>
            All
          </Button>
          <Button className={onlyActive ? "active" : ""} onClick={() => setOnlyActive(true)}>
            Active only
          </Button>
        </ButtonGroup>
      )}

      <AddGroup />

      <Button onClick={showMenu} size="sm" color="outline">
        <Icon icon="menu" size="xsm" />
      </Button>

      <DropdownMenu anchor={menuAnchor} onClose={() => setMenuAnchor(null)} btnHover>
        <Button size="sm" onClick={() => setShowSelectContactIcon(prev => !prev)}>
          Select contacts
        </Button>

        <hr className="dropdownDivider" />

        {isContacts && (
          <>
            <SaveNewTemplate />
            <SaveAsExistingTemplate />
          </>
        )}
      </DropdownMenu>
    </>
  );
};
