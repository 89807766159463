import {FC, useEffect} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import {useChangeOrder} from "./useChangeOrder";
import {useFieldOptionCRUD} from "./useFieldOptionCRUD";
import "./FieldSetup.scss";

let fieldOptionShape = {id: "1", value: "", order: 0, errors: []};
export type tFieldOption = {
  id: string;
  value: string;
  order: number;
  errors: string[];
};

type tProps = {
  fields: tFieldOption[];
  setFields: React.Dispatch<React.SetStateAction<tFieldOption[]>>;
  name: string;
};

export const FieldSetup: FC<tProps> = ({fields, setFields, name}) => {
  const {toOrderArray, handleDrag, handleDrop} = useChangeOrder({fields, setFields});
  const {err, addNewOption, onChangeInputValue, onRemove, } = useFieldOptionCRUD({name, fieldOptionShape, fields, setFields}); // prettier-ignore

  useEffect(() => {
    return () => setFields([]);
  }, []); // eslint-disable-line

  return (
    <div className="FieldSetup">
      <div className="FieldSetup-body">
        {toOrderArray(fields || []).map((value: any, i) => {
          const showDeleteBtn = fields.length > 1;
          return (
            <div
              key={value.id}
              className="FieldSetup-row"
              draggable={true}
              id={value.id}
              onDragOver={event => event.preventDefault()}
              onDrop={() => handleDrop(value.id)}
              onDragStart={() => handleDrag(value.id)}
            >
              <button className="FieldSetup-button-ordering">
                <span className="FieldSetup-button-ordering-line" />
              </button>

              <InputField
                id={value.id}
                value={value?.value}
                errors={value?.errors}
                onChange={onChangeInputValue}
              />

              {showDeleteBtn && (
                <button onClick={() => onRemove(value.id)} className="FieldSetup-remove-button">
                  <Icon icon="close" size="sm" color="gray" />
                </button>
              )}
            </div>
          );
        })}

        {!!err && <div className="err">{err}</div>}
      </div>

      <Button
        className="FieldSetup-button button__add"
        onClick={addNewOption}
        color="outline"
        size="sm"
        type="button"
      >
        <Icon icon="add" size="sm" />
        Add Option
      </Button>
    </div>
  );
};
