import {FC, useState} from "react";
import {Button} from "components/shared/Button/Button";
import {PeopleModals} from "components/shared/Modals/PeopleModals/PeopleModals";
import {CompanyModals} from "components/shared/Modals/CompanyModals/CompanyModals";
import {JobDetailsModal} from "components/shared/Modals/JobDetailsModal/JobDetailsModal";
import {iFavoritesReturn} from "api/favorites/types";
import "./FavRow.scss";

interface iFavRows {
  data: iFavoritesReturn;
}

export const FavRow: FC<iFavRows> = ({data}) => {
  const [showModal, setShowModal] = useState(false);

  if (!data?.job) return null;
  let text = `${data?.job?.id} ${data?.job?.name}`;

  return (
    <>
      <Button className="FavRow" onClick={() => setShowModal(!showModal)}>
        {text}
      </Button>
      {showModal &&
        (("person" === data.type && data?.person && (
          <PeopleModals person={data.person} closePeopleModal={() => setShowModal(false)} />
        )) ||
          ("company" === data.type && data?.company && (
            <CompanyModals company={data.company} closeCompaniesModal={() => setShowModal(false)} />
          )) ||
          ("job" === data.type && data?.job && (
            <JobDetailsModal job={data.job?.id} onModalClose={() => setShowModal(false)} />
          )))}
    </>
  );
};
