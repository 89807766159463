import {FC, useState} from "react";
import {tCrewContact} from "api/jobCrewGroup/types";
import {tContactPosition} from "api/jobCrewContactPosition/type";
import {Button} from "components/shared/Button/Button";
import {SelectCell} from "../_common/SelectCell/SelectCell";
import {useChangeCrewValue} from "../_common/useChangeCrewValue";

type tRowPositionsProps = {
  crewContact: tCrewContact;
  positionsList: Array<tContactPosition>;
  groupId: number;
};

export const RowPositions: FC<tRowPositionsProps> = ({crewContact, groupId}) => {
  const {position} = crewContact;
  const {changeValue, isLoading} = useChangeCrewValue();

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const changePosition = async (posId: number) => {
    setMenuAnchor(null);

    changeValue({type: "position", id: crewContact?.id, groupId, data: {position_id: posId}});
  };

  return (
    <SelectCell
      isLoading={isLoading}
      menuAnchor={menuAnchor}
      setMenuAnchor={setMenuAnchor}
      btnChildren={position?.name}
      blockOpenMenu={!crewContact?.contact?.titles?.length}
    >
      {crewContact?.contact?.titles?.map(item => {
        return (
          <Button
            key={item?.id}
            size="sm"
            onClick={() => changePosition(item?.id)}
            disabled={isLoading}
          >
            {item?.name}
          </Button>
        );
      })}
    </SelectCell>
  );
};
