import {FC, useEffect, useState} from "react";
import {tCrewContact} from "api/jobCrewGroup/types";
import {InputCell} from "components/shared/InputCell/InputCell";
import {useChangeCrewValue} from "../_common/useChangeCrewValue";
import "./RowRate.scss";

type tRowRateProps = {
  crewContact: tCrewContact;
  groupId: number;
};

export const RowRate: FC<tRowRateProps> = ({crewContact, groupId}) => {
  const {contact} = crewContact;
  const defValue = crewContact?.rate ? crewContact?.rate : contact?.rate;
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(defValue);
  }, [crewContact]); // eslint-disable-line

  const {changeValue, isLoading} = useChangeCrewValue();

  const setNotesForContact = async (val: string) => {
    if (value === crewContact?.rate) return;
    changeValue({
      type: "rate",
      id: crewContact?.id,
      groupId,
      data: {rate: val},
      successCallback: (res: any) => !res?.data?.data?.rate && setValue(contact?.rate),
      errorCallback: () => setValue(defValue || "")
    });
  };

  const isDefaultVal = !!crewContact?.rate ? "" : "default";
  return (
    <InputCell
      value={value}
      setValue={setValue}
      isLoading={isLoading}
      InputComponent="input"
      placeholder="Add rate"
      initValue={defValue || ""}
      className={`CrewCellRate ${isDefaultVal}`}
      callBack={val => setNotesForContact(val)}
    />
  );
};
