import {FC} from "react";
import {PdfViewer} from "components/shared/PdfViewer/PdfViewer";

import "./EstimateOverlay.scss";

interface iEstimateOverlayProps {
  pdfUrl: string;
  scale?: number;
}

const EstimateOverlay: FC<iEstimateOverlayProps> = ({pdfUrl, scale}) => {
  return (
    <div className="EstimateOverlay">
      <PdfViewer scale={scale} blobUrl={pdfUrl} />
    </div>
  );
};

export default EstimateOverlay;
