import {FC, ReactNode} from "react";
import {tExpenseListItem, tTrackExpenseLine} from "api/budget/track/types";
import {AttachFileWrap} from "./AttachFileWrap";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {Icon} from "components/Icon/Icon";
import "./TotalCellWrap.scss";
import {getUsMoneyFormat} from "helpers/formattingData";

type tTotalCellWrapProps = {
  className?: string;
  trackLine: tTrackExpenseLine | tExpenseListItem;
  isValue: boolean;
  showInputField: () => void;
  children: ReactNode;
  type: "working" | "actual";
};

export const TotalCellWrap: FC<tTotalCellWrapProps> = props => {
  const {className = "", trackLine, isValue, showInputField, children, type} = props;
  const {actual_file, working_file} = trackLine;

  const totalValue = (type: "actual_details" | "working_details") => (
    <>
      {trackLine?.[type]?.currency_code !== "USD" && (
        <span className="defaultValue">
          {`(${getUsMoneyFormat(trackLine?.[type]?.amount, trackLine?.[type]?.currency_code)})`}{" "}
        </span>
      )}
      <span
        className={!trackLine?.bill_state && type === "actual_details" ? "notBill" : ""}
      >{`${getUsMoneyFormat(trackLine?.[type]?.default_currency_amount)}`}</span>
    </>
  );

  return (
    <div className={`${className} TotalCellWrap posRelative`}>
      {type === "working" && (
        <AttachFileWrap id={trackLine?.id} file={working_file} file_type={"working"} />
      )}

      {type === "actual" && (
        <AttachFileWrap
          id={trackLine?.id}
          file={actual_file}
          file_type={"actual"}
          blockAdd={!trackLine?.receipt_required}
        />
      )}

      {!isValue && (
        <TextComponent
          as="button"
          size="10"
          weight="700"
          color="gray-20"
          buttonFlex
          uppercase
          onClick={showInputField}
          nowrap
          className="TotalCellWrap-btn"
        >
          <Icon icon="add" color="light-gray" size="xsm" /> {type}
        </TextComponent>
      )}

      {isValue && (
        <TextComponent
          as="button"
          size="14"
          weight="400"
          onClick={showInputField}
          nowrap
          className="TotalCellWrap-btn"
        >
          {type === "actual" && totalValue("actual_details")}
          {type === "working" && totalValue("working_details")}
        </TextComponent>
      )}

      {children}
    </div>
  );
};
