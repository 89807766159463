import {FC} from "react";
import {RadioField} from "components/shared/FormComponents/RadioField/RadioField";
import {Button} from "components/shared/Button/Button";
import {TagsField} from "components/shared/FormComponents/TagsField/TagsField";
import {useContactsTableFilter} from "pages/Contacts/commonComponent/ContactsTableFilter/useContactsTableFilter";

import "./ContactsTableFilter.scss";

const ContactsTableFilter: FC = () => {
  const {filterControl, onChangeInput, clearFilters, localesOption, labelsOption} =
    useContactsTableFilter();

  return (
    <div className="ContactsTableFilter">
      <div className="ContactsTableFilter-row">
        <div className="ContactsTableFilter-label">Favorite</div>
        <RadioField {...filterControl.favorite} />
      </div>
      <div className="ContactsTableFilter-row">
        <div className="ContactsTableFilter-label">Label</div>
        <TagsField
          {...filterControl.labels}
          placeholder="Search labels"
          options={labelsOption}
          name="labels"
          getOptionValue={value => value.id}
          onInputChange={value => onChangeInput(value, "labels")}
        />
      </div>
      <div className="ContactsTableFilter-row">
        <div className="ContactsTableFilter-label">Locale</div>
        <TagsField
          {...filterControl.locales}
          placeholder="Search Locales"
          options={localesOption}
          name="locales"
          getOptionValue={value => value.id}
          onInputChange={value => onChangeInput(value, "locales")}
        />
      </div>

      <Button size="sm" color="light" className="ContactsTableFilter-button" onClick={clearFilters}>
        Clear Filters
      </Button>
    </div>
  );
};

export default ContactsTableFilter;
