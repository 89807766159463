import {FC, FormEvent} from "react";
import {jobTasksApi} from "api/jobs/tasks";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {Loading} from "components/shared/Loading/Loading";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {notValidForm} from "helpers/misc";
import {useSelect} from "hooks/inputHooks/useSelect";
import {useHttp} from "hooks/httpServices/useHttp";
import {TextComponent} from "../../common/TextComponent/TextComponent";
import {useContextJobTasks} from "../_context/JobTaskContext";
import {Select} from "components/shared/FormComponents/Select/Select";
import {useGetTasksTemplates} from "./useGetTasksTemplates";

type tLoadTemplateProps = {
  onClose: () => void;
};

export const LoadTemplate: FC<tLoadTemplateProps> = ({onClose}) => {
  const {tasks, setTasks} = useContextJobTasks();
  const {call, isLoading: submitting} = useHttp();
  const FORM_ID = "load-tasks-template-form-id";

  const form = {
    templateId: useSelect({
      options: [],
      targetKeys: {value: "id", label: "name"},
      placeholder: "Select...",
      isRequired: true
    })
  };

  const {templateList, isLoading} = useGetTasksTemplates({select: form?.templateId});

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (notValidForm(form) || !tasks?.id) return;

    try {
      const res = await call(jobTasksApi.loadTemplate(tasks?.id, +form.templateId.value));

      onClose();
      setTasks(res?.data?.data);
      eventBus.dispatch("showToast", {type: "success", text: res?.data?.message || "Success!"});
    } catch (error) {
      const {msg} = apiErrorHandler(error, form);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <ConfirmWindow
      show={true}
      title="LOAD TEMPLATE"
      cancelBtn={{text: "Cancel", type: "button"}}
      confirmBtn={{text: "Load", type: "submit", form: FORM_ID}}
      onClose={onClose}
      isLoading={submitting}
    >
      {isLoading && (
        <div className="emptyBlock">
          <Loading type="inBlock" />
        </div>
      )}

      {!isLoading && !templateList?.length && (
        <TextComponent center className="mb-40">
          You have no templates yet !
        </TextComponent>
      )}

      {!isLoading && !!templateList?.length && (
        <form onSubmit={onSubmit} id={FORM_ID}>
          <Select
            {...form.templateId.inputProps}
            errors={form.templateId.errors}
            canSelectEmpty={true}
          />

          <TextComponent weight="400" size="13" color="gray-10" textAlign="center">
            Templated items will appear below your current tasks.
          </TextComponent>
        </form>
      )}
    </ConfirmWindow>
  );
};
