import {FC} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {useTextInputReturn} from "hooks/useTextInput/useTextInput";

import "./CurrencyRatesInputRow.scss";
import {CODE_EXIST_ERR, EMPTY_CURRENCY} from "./useCurrencyRates";

interface iCurrencyRatesInputRowProps {
  currencyData: {[key: string]: useTextInputReturn};
}

export const CurrencyRatesInputRow: FC<iCurrencyRatesInputRowProps> = ({currencyData}) => {
  const pl = 0;
  const isExistCodeErr = currencyData.code?.errors?.includes(CODE_EXIST_ERR) ? "err" : "";
  const isEmptyErr = currencyData.code?.errors?.includes(EMPTY_CURRENCY) ? "err" : "";

  return (
    <tr className="CurrencyRatesInputRow CurrencyRatesTable-row">
      <td>
        <span>
          <InputField
            {...currencyData.currency.inputProps}
            // errors={currencyData.name.errors}
            placeholder="Currency Name"
          />
        </span>
      </td>
      <td className={`${isExistCodeErr} ${isEmptyErr}`}>
        <span>
          <InputField
            {...currencyData.code.inputProps}
            // errors={currencyData.code.errors}
            placeholder="Code"
          />
        </span>
      </td>
      <td>
        <span>
          <InputField
            {...currencyData.exchange_rate.inputProps}
            //   errors={currencyData.rate.errors}
            className="text-right"
            placeholder={String(pl.toFixed(4))}
          />
        </span>
      </td>
      <td>
        <span>
          <InputField
            {...currencyData.hedge_percent.inputProps}
            //   errors={currencyData.hedge.errors}
            className="text-right"
            placeholder="0.0%"
          />
        </span>
      </td>
    </tr>
  );
};
