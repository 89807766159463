import {tTrack} from "api/budget/track/types";

export const generateTrackList = (trackData: tTrack | null) => {
  const linesList: Array<{
    id: string;
    value: number;
    label: string;
    code: number;
    estimate: number;
  }> = [];

  trackData?.categories?.forEach(item => {
    item.cost_values?.forEach(cost => {
      // if (linesList?.find(fi => fi?.label === `${cost.cost_value?.code}${cost.cost_value?.suffix} ${cost.cost_value?.name}`)) return; // prettier-ignore

      linesList.push({
        id: cost?.cost_value?.id?.toString(),
        value: cost?.cost_value?.id,
        label: `${cost.cost_value?.code}${cost.cost_value?.suffix} ${cost.cost_value?.name}`,
        code: +cost.cost_value?.code,
        estimate: cost?.estimate?.id || 0
      });
    });
  });

  return linesList?.sort((a, b) => a.code - b.code);
};
