import {CSSProperties, FC, ReactNode} from "react";
import "./CrewTableCell.scss";

type tCrewTableCellProps = {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
};

export const CrewTableCell: FC<tCrewTableCellProps> = ({children, className = "", style}) => {
  return (
    <td className={`CrewTableCell ${className}`} style={style}>
      {children}
    </td>
  );
};
