import {FC, useState} from "react";
import {tTrackCostLineFile, tTrackExpenseLine} from "api/budget/track/types";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {FIle} from "../File/FIle";
import {ListLineLayout} from "../../_common/ListLineLayout";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {trackApi} from "api/budget/track";
import {useContextTrack} from "../../_context/TrackContext";
import {useHttp} from "hooks/httpServices/useHttp";
import {eventBus} from "EventBus/EventBus";
import {useContextExpenseList} from "../../ExpenseList/context/ExpenseListContext";
import {useResponseType} from "../../_common/useResponseType";
import {downloadFileFromUrl} from "components/Viewers/fileHelpers";
import {getBaseURL} from "hooks/useApi";

type tFileLineProps = {
  file: tTrackCostLineFile;
  trackLine: tTrackExpenseLine;
};

export const FileLine: FC<tFileLineProps> = ({file}) => {
  const {setTrackData} = useContextTrack();
  const {setExpenseList} = useContextExpenseList();
  const {isExpense, response_type} = useResponseType();
  const {call, isLoading} = useHttp();
  const [modal, setModal] = useState<"delete" | "show" | null>(null);
  const closeModal = () => setModal(null);
  const [loading, setLoading] = useState(false);

  const onDownload = async () => {
    const URL_WITH_PROXY = `${getBaseURL()}asset?url=${file.file_url}`;
    setLoading(true);
    await downloadFileFromUrl({url: URL_WITH_PROXY});
    setLoading(false);
  };

  const onRemove = async () => {
    try {
      const res = await call(trackApi?.deleteAdditionalFile(file?.id, response_type));
      isExpense && setExpenseList(res?.data?.data);
      !isExpense && setTrackData(res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <ListLineLayout>
      <TextComponent weight="400" size="14" truncate>
        {file?.file_name}
      </TextComponent>

      <Button onClick={() => setModal("show")}>
        <Icon icon="eye" size="sm" color="gray" />
      </Button>
      <Button onClick={onDownload} disabled={loading}>
        <Icon icon="download" size="sm" color="gray" />
      </Button>
      <Button onClick={() => setModal("delete")}>
        <Icon icon="delete" size="md" color="gray" />
      </Button>

      {modal === "show" && (
        <FIle selectedFile={file} onClose={closeModal} typeFile="additional" disabled={true} />
      )}

      <ConfirmWindow
        title="Delete File?"
        subTitle={`${file?.file_name} will be permanently deleted.`}
        show={modal === "delete"}
        onClose={closeModal}
        onConfirm={onRemove}
        isLoading={isLoading}
        confirmBtn={{text: "Delete", color: "red"}}
        cancelBtn={{text: "cancel"}}
      />
    </ListLineLayout>
  );
};
