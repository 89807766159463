export const trackingTableColumn = [
  {
    id: "name",
    label: "",
    sort: false
  },
  {
    id: "latest_delivery",
    label: "Latest Delivery",
    sort: false
  },
  {
    id: "method",
    label: "Method",
    sort: false
  },
  {
    id: "status",
    label: "Latest Status",
    sort: false
  },
  {
    id: "resend",
    label: "Resend",
    sort: false
  },
  {
    id: "title",
    label: "Title",
    sort: false
  },
  {
    id: "email",
    label: "Work Email",
    sort: false
  },
  {
    id: "phone",
    label: "Mobile Phone",
    sort: false
  },
  {
    id: "time",
    label: "Call Time",
    sort: false
  },
  {
    id: "location",
    label: "Location",
    sort: false
  }
];
