import {ChangeEvent, useEffect, useState} from "react";
import {useTextInput} from "hooks/useTextInput/useTextInput";

type tOptions = {id?: number | string; [key: string]: any};
export interface iUseSelectInputProps<T extends tOptions> {
  value?: string;
  options: Array<T>;
  onChangeCallback?: (e: ChangeEvent<HTMLSelectElement>, v?: T) => void;
  isRequired?: boolean;
  placeholder?: string;
  targetKeys?: {value: string; label: string};
  emptyErrText?: string;
  customValue?: string;
}

export const useSelectInput = <T extends tOptions>(props?: iUseSelectInputProps<T>) => {
  const defaultRequired = false;

  const [value, setValue] = useState(props?.value || "");
  const [selected, setSelected] = useState<T>();
  const [isValid, setIsValid] = useState(
    props?.isRequired !== undefined ? !props.isRequired : !defaultRequired
  );
  const [isDirty, setIsDirty] = useState(!!props?.value);
  const [isRequired, setIsRequired] = useState(
    props?.isRequired !== undefined ? props.isRequired : defaultRequired
  );
  const [canSelectEmpty, setCanSelectEmpty] = useState(
    props?.isRequired !== undefined ? !props.isRequired : !defaultRequired
  );

  const [isCustomInput, setIsCustomInput] = useState(false);

  const customInput = useTextInput({value: props?.customValue, isRequired: true});

  const [options, setOptions] = useState<Array<T> | undefined>(props?.options);
  const [errors, setErrors] = useState<string[]>([]);

  const emptyErr = props?.emptyErrText ? props?.emptyErrText : "This field can't be empty";

  // useEffect(() => {
  //   if (!options?.length && !!props?.options?.length) {
  //     setOptions(options);
  //   }
  // }, [props?.options, options]);

  useEffect(() => {
    if (!options?.length) return;
    // @ts-ignore
    const selected: any = options?.find(i => String(i?.id) === String(value));
    setIsCustomInput(selected?.name?.toLowerCase() === "other");
  }, [value, options]);

  useEffect(() => {
    if (!!value) {
      const selectedValue = options?.find(
        item => getNestedProp(item, props?.targetKeys?.value || "value") === value
      );
      setSelected(selectedValue);
    }
  }, [options]); // eslint-disable-line

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();

    const selectedValue = options?.find(
      item => getNestedProp(item, props?.targetKeys?.value || "value") === e.target.value
    );

    setValue(e.target.value);
    setSelected(selectedValue);
    checkValidity(e.target.value);
    !isDirty && setIsDirty(true);
    props?.onChangeCallback?.(e, selectedValue);
  };

  const updateValue = (v: string) => {
    setValue(v);
    setIsDirty(true);
    setIsValid(!!v);
  };

  const checkValidity = (val?: string) => {
    const newVal = val === undefined ? value : val;
    const customErrors = errors.filter(err => err !== emptyErr);
    const newErrors: string[] = [];

    if (isCustomInput) {
      return customInput.checkValidity();
    }

    if (!isRequired && !newVal) {
      setErrors(customErrors);
      setIsValid(true);
      return true;
    }

    if (isRequired && !newVal) newErrors.push(emptyErr);

    setErrors(newErrors);
    setIsValid(!newErrors?.length);
    return !newErrors?.length;
  };

  const clearAll = () => {
    setValue("");
    setSelected(undefined);
    setIsCustomInput(false);
    customInput.setValue("");
  };

  const isChanged = () => {
    const propsValue = props?.value || "";
    if (props?.customValue) return props.customValue !== customInput.value;
    return propsValue !== value;
  };

  return {
    value,
    setValue: updateValue,

    isValid,
    setIsValid,

    isDirty,
    setIsDirty,

    isRequired,
    setIsRequired,

    canSelectEmpty,
    setCanSelectEmpty,

    options,
    setOptions,

    checkValidity,

    errors,
    setErrors,

    selected,
    setSelected,

    onChange,
    clearAll,
    isChanged: isChanged(),

    isCustomInput,
    customInput,

    inputProps: {
      value,
      onChange,
      options: options
        ? prepareOptionsForSelect(options, props?.targetKeys || defaultTargetKeys)
        : [{id: 1, ...defaultTargetKeys}],
      canSelectEmpty: !isRequired
    }
  };
};

const defaultTargetKeys = {value: "value", label: "label"};

function prepareOptionsForSelect<T extends tOptions>(
  data: Array<T>,
  targetKeys: iUseSelectInputProps<T>["targetKeys"]
) {
  const inputOptions: Array<{id: number | string; value: string; label: string}> = [];
  data.forEach((item, i) =>
    inputOptions.push({
      id: item?.id || i,
      value: getNestedProp(item, targetKeys?.value as string),
      label: getNestedProp(item, targetKeys?.label as string)
    })
  );
  return inputOptions;
}

function getNestedProp<T>(data: T, path: string) {
  if (typeof data === "string") return data;

  const pathArr = path.split(".") as Array<keyof T>;
  let curKey: any;

  for (let i = 0; i < pathArr.length; i++) {
    const curLevelObj = i === 0 ? data : curKey;

    if (typeof curLevelObj === "object" && curLevelObj.hasOwnProperty(pathArr[i]))
      curKey = curLevelObj[pathArr[i]];
    else return null;
  }
  return curKey?.toString();
}
