import {FC} from "react";
import {tReturnNotificationData} from "api/notifications/types";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import "./NotificationsHeader.scss";

interface iNotificationsHeader {
  markAllAsRead: () => void;
  setNotificationsWindow: (arg: boolean) => void;
  notificationsData: tReturnNotificationData[] | null;
};

export const NotificationsHeader: FC<iNotificationsHeader> = ({
  notificationsData,
  markAllAsRead,
  setNotificationsWindow
}) => {
  return (
    <div className="NotificationsHeader">
      {!!notificationsData?.length && (
        <Button disabled={!notificationsData?.length} onClick={() => markAllAsRead()}>
          Mark All As Read
        </Button>
      )}
      <Button onClick={() => setNotificationsWindow(false)} className="NotificationsHeader-close">
        <Icon icon="close" size="sm" color="dark-gray" />
      </Button>
    </div>
  );
};
