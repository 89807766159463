import React, {FC, useEffect, useState} from "react";
import {iTagsFieldProps, TagsField} from "components/shared/FormComponents/TagsField/TagsField";
import {ActionMeta} from "react-select";
import {useDebounce} from "hooks/useDebounce";
import {iStateOption} from "components/shared/FormComponents/TagsField/TagsField";

type tLabelKeys = "first_name" | "last_name" | "name";

interface iFilterSearchingProps extends Omit<iTagsFieldProps, "value"> {
  id: string;
  placeholder: string;
  label: string;
  onChange: (value: iStateOption[], actionMeta: ActionMeta<iStateOption>) => void;
  name: string;
  value: string[];
  options: iStateOption[];
  onSearch: (value: string) => void;
  labelKey: tLabelKeys[];
}

export const FilterSearching: FC<iFilterSearchingProps> = ({
  id,
  placeholder,
  label,
  onChange,
  name,
  value,
  options,
  onSearch,
  labelKey,
  ...props
}) => {
  const [search, setSearch] = useState("");

  const debounceValue = useDebounce(search, 200);

  useEffect(() => {
    if (debounceValue) {
      onSearch(debounceValue);
    }
  }, [debounceValue]); // eslint-disable-line

  const handleInputChange = (value: string) => setSearch(value);

  const selectedValue = () => {
    if (!options?.length || !value?.length) return [];

    return options.filter((item: any) => {
      return value?.find((option: any) => Number(option) === Number(item.id));
    });
  };

  return (
    <>
      <label htmlFor={id} className="JobsTableFilter-label">
        {label}
      </label>
      <TagsField
        id={id}
        name={name}
        className="JobsTableFilter-value"
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        value={selectedValue()}
        labelKey={labelKey}
        onInputChange={handleInputChange}
        {...props}
      />
    </>
  );
};
