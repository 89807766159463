import {useState} from "react";
import {callSheet} from "api/callSheet";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useContextJobCallSheet} from "../context/JobCallSheetContext";
import {useHttp} from "hooks/httpServices/useHttp";
import {eventBus} from "EventBus/EventBus";
import {tUpdateDeliveryPostData} from "api/callSheet/types";

type tModal = "sender" | "edit" | "unschedule" | "log" | "preview" | "dropboxAuth";

export function useCallSheetStatusBar() {
  const {callSheetInfo, deliveryInfo, setDeliveryInfo, getDeliveryInfo, setProofedCallSheetInfo} =
    useContextJobCallSheet();

  const {call: removeScheduleCall, isLoading: removeScheduleLoading} = useHttp();
  const {call: updateScheduleCall, isLoading: updateScheduleLoading} = useHttp();
  const {call: saveCall, isLoading: saveIsLoading} = useHttp();

  const [showModal, setShowModal] = useState<tModal | null>(null);

  const onSaveCallSheet = async () => {
    if (!callSheetInfo || !callSheetInfo?.id) return;
    try {
      const {
        data: {data, message}
      } = await saveCall(callSheet.updateCallSheet(callSheetInfo.id, callSheetInfo));
      setProofedCallSheetInfo(data);
      eventBus.dispatch("showToast", {text: message || "Call sheet successfully saved!"});
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  const onRemoveSchedule = async () => {
    if (!deliveryInfo?.id) return;
    try {
      const {
        data: {message, data}
      } = await removeScheduleCall(callSheet.unscheduleDelivery(deliveryInfo.id));

      if (data) {
        setDeliveryInfo(data);
      } else {
        setDeliveryInfo(prev =>
          prev ? {...prev, schedule: null, status: {id: 3, name: "unscheduled"}} : null
        );
      }
      eventBus.dispatch("showToast", {text: message || "Call sheet successfully removed!"}); // prettier-ignore
      setShowModal(null);
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  const onUpdateDelivery = async (payload: tUpdateDeliveryPostData) => {
    if (!deliveryInfo?.id) return;

    try {
      const {data: {data, message}} = await updateScheduleCall(
        callSheet.updateDelivery(deliveryInfo.id, payload)
      ); // prettier-ignore
      eventBus.dispatch("showToast", {text: message || "Call sheet successfully updated!"}); // prettier-ignore
      setDeliveryInfo(data);
      setShowModal(null);

      setTimeout(() => {
        getDeliveryInfo();
      }, 500);
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return {
    onRemoveSchedule,
    onSaveCallSheet,
    setShowModal,
    deliveryInfo,
    showModal,
    saveIsLoading,
    removeScheduleLoading,
    updateScheduleLoading,
    onUpdateDelivery
  };
}
