import {FC} from "react";
import {Tag} from "components/shared/Tag/Tag";
import {ModalInfoSection} from "components/shared/ModalInfoSection/ModalInfoSection";
import {TagButton} from "components/shared/TagButton/TagButton";
import {iJobDetailsDataReturn} from "api/jobs/types";
import {Link} from "react-router-dom";
import {getDateRange} from "helpers/dates";
import {AvatarGroup} from "components/shared/AvatarGroup/AvatarGroup";
import {DepartmentList} from "components/shared/DepartmentList/DepartmentList";
import moment from "moment";
import {useContextApp} from "context";

import "./JobDetails.scss";

interface iJobDetails {
  data: iJobDetailsDataReturn;
  onModalClose?: (...p: any) => void;
}

export const JobDetails: FC<iJobDetails> = ({data, onModalClose}) => {
  const {setHubSearchWindow} = useContextApp();
  const {id, name, start_at, team, end_at, locales, description, client, artists, locations} = data;
  const {departments, people, pipeline, ad_agency, assigned_tasks} = data;

  const isDonePipeline = pipeline?.group?.name === "Done";

  const onViewJobDetails = () => {
    onModalClose?.();
    setHubSearchWindow(false);
  };

  const dates = start_at && end_at ? getDateRange({start_at, end_at}) : {dataPeriod: ""};

  const ClientRender = client?.name && (
    <TagButton color="dark" size="md">
      {client?.name}
    </TagButton>
  );

  const ArtistRender =
    !!artists?.length &&
    artists.map(artist => {
      return (
        <TagButton size="md" className="JobDetails-body-tag" key={artist.artist.id}>
          {artist?.artist?.name}
        </TagButton>
      );
    });

  const ContactsRender =
    !!people?.length &&
    people.map(person => (
      <TagButton size="md" className="JobDetails-body-tag" key={person.person.id}>
        {person.person?.name}
      </TagButton>
    ));

  const LocationRender =
    !!locations?.length &&
    locations.map(({name}, i) => (
      <TagButton size="md" className="JobDetails-body-tag" key={i}>
        {name}
      </TagButton>
    ));

  const DepartmentRender = !!departments?.length && <DepartmentList data={departments} />;
  const PipelineRender = (
    <div
      className={`JobDetails-pipeline ${
        pipeline ? "" : "empty-pipeline"
      } ${pipeline?.group?.name?.toLowerCase()}`}
    >
      {pipeline?.name || "Pipeline"}
    </div>
  );

  const AgencyRender = ad_agency?.name && (
    <TagButton color="dark" size="md">
      {ad_agency?.name}
    </TagButton>
  );

  return (
    <div className="JobDetails">
      <div className="JobDetails-header">
        <div className="JobDetails-header-upper">
          <h1 className="JobDetails-title">{name}</h1>
          <AvatarGroup data={team} nameKeys="display_name" showAllAvatars />
        </div>
        <div className="JobDetails-header-lower">
          <div className="JobDetails-shortDesc">
            <div className="JobDetails-shortDescItem">{id}</div>
            {dates?.dataPeriod && (
              <>
                <span className="JobDetails-shortDescSeparator">|</span>
                <div className="JobDetails-shortDescItem">{dates.dataPeriod}</div>
              </>
            )}
            {!!locales?.length && <span className="JobDetails-shortDescSeparator">|</span>}
            <div>
              {locales.map(({id, name}) => (
                <Tag size="sm" key={id} className="JobDetails-header-tag">
                  {name}
                </Tag>
              ))}
            </div>
          </div>
        </div>
        <div className="JobDetails-header-rightSection">
          <Link
            to={`/jobs/${data?.id}/overview`}
            onClick={onViewJobDetails}
            className="Button sm light"
          >
            View job
          </Link>
        </div>
      </div>

      <div className="JobDetails-body">
        {!!assigned_tasks?.length && !isDonePipeline && (
          <div className="JobDetails-tasks">
            <div className="JobDetails-tasks-label">TASKS DUE SOON</div>
            <table className="JobDetails-tasksTable">
              <tbody>
                {assigned_tasks.map(task => {
                  const status = task?.status?.name || "";
                  return (
                    <tr key={task.id} className="JobDetails-tasksRow">
                      <td className="JobDetails-tasksRow-name">{task.name}</td>
                      <td className={`JobDetails-tasksRow-status ${status}`}>{status}</td>
                      <td className="JobDetails-tasksRow-date">
                        {task?.due_date ? moment(task.due_date).format("MMM DD") : "n/a"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

        <ModalInfoSection
          className="JobDetails-list"
          list={[
            {
              label: "pipeline",
              value: PipelineRender
            },
            {
              label: "department",
              value: DepartmentRender
            },
            {
              label: "client",
              value: ClientRender
            },

            {label: "ad agency", value: AgencyRender},
            {
              label: "contacts",
              value: ContactsRender
            },
            {
              label: "artists",
              value: ArtistRender
            },
            {
              label: "locations",
              value: LocationRender
            },
            {
              label: "description",
              value: description
            }
          ]}
        />
      </div>
    </div>
  );
};
