import {FC, useState} from "react";
import {tTrackCostValue} from "api/budget/track/types";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {Button} from "components/shared/Button/Button";
import {EditDescriptions} from "../../../_modals/EditDescriptions";

type tCostValueActionsProps = {
  className?: string;
  trackCost: tTrackCostValue;
};

export const CostValueActions: FC<tCostValueActionsProps> = ({className, trackCost}) => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  type modalTypes = "descriptions";
  const [typeModal, setModal] = useState<modalTypes | null>(null);
  const onCloseModal = () => setModal(null);
  const openModal = (type: modalTypes) => {
    setMenuAnchor(null);
    setModal(type);
  };

  return (
    <div className={`TrackWATableRowActions ${className}`}>
      <TextComponent onClick={showMenu} as="button" color="gray-10">
        &#183;&#183;&#183;
      </TextComponent>

      <DropdownMenu anchor={menuAnchor} onClose={() => setMenuAnchor(null)}>
        <Button size="sm" className="btn" onClick={() => openModal("descriptions")}>
          Edit Description
        </Button>
      </DropdownMenu>

      {typeModal === "descriptions" && (
        <EditDescriptions trackCost={trackCost} onClose={onCloseModal} />
      )}
    </div>
  );
};
