import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

export function connectSentry() {
  if (process.env.REACT_APP_ENV !== "production") return;

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0
  });
}
