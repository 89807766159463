import {useSetApiParams} from "hooks/useSetApiParams";
import {useContextExpenseList} from "../../ExpenseList/context/ExpenseListContext";
import {useContextTrack} from "../../_context/TrackContext";

export type tOptions = {
  id: string;
  name: string;
  [key: string]: any;
};

export const useGetFIlterLists = () => {
  const apiParams = useSetApiParams();

  const {filterList} = useContextExpenseList();
  const lineNumbersArr = apiParams?.preparedParams?.line_numbers?.split(",") || [];

  const {lists: {payIds, qbStatuses, payStatuses}} = useContextTrack(); // prettier-ignore
  const payIdList = payIds?.list?.map(item => ({id: item?.id?.toString(), name: item?.name})) || [];
  const payIdsArr = apiParams?.preparedParams?.pay_value?.split(",") || [];

  const qbList = qbStatuses?.list?.map(item => ({id: item?.id?.toString(), name: item?.name})) || []; // prettier-ignore
  const qbArr = apiParams?.preparedParams?.qb_status?.split(",") || [];

  const payStatusList = payStatuses?.list?.map(item => ({id: item?.id?.toString(), name: item?.name})) || []; // prettier-ignore
  const payStatusArr = apiParams?.preparedParams?.pay_status?.split(",") || [];

  const getSelectedTag = (options: tOptions[], selected: string[]) => {
    if (!selected?.length || !options?.length) return [];
    return options.filter((item: any) => {
      return selected?.find((locale: string) => Number(locale) === Number(item.id));
    });
  };

  return {
    getSelectedTag,
    payIdList,
    payIdsArr,
    lineNumberList: filterList,
    lineNumbersArr,
    qbArr,
    qbList,
    payStatusArr,
    payStatusList
  };
};
