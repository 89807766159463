import {FC} from "react";
import {TextComponent} from "../TextComponent/TextComponent";
import "./SyncData.scss";

type tSyncDataProps = {
  name: string;
  updateCallback: () => void;
  isLoading: boolean;
};

export const SyncData: FC<tSyncDataProps> = ({name, updateCallback, isLoading}) => {
  return (
    <div className="SyncData">
      {name && (
        <TextComponent weight="500" size="10" color="gray-10" nowrap>
          Last update: {name}
        </TextComponent>
      )}

      <TextComponent weight="500" size="10" color="gray-10" nowrap>
        <span>Syncs every 60s </span>
        <TextComponent
          as="button"
          weight="500"
          size="10"
          color="gray-10"
          onClick={updateCallback}
          disabled={isLoading}
        >
          SYNC NOW
        </TextComponent>
      </TextComponent>
    </div>
  );
};
