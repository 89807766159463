import {FC, ReactNode} from "react";
import "./ListLineLayout.scss";

type tListLineLayoutProps = {
  children: ReactNode;
};

export const ListLineLayout: FC<tListLineLayoutProps> = ({children}) => {
  return <div className="ListLineLayout">{children}</div>;
};
