import Papa from "papaparse";
import {
  parseEmailPhone,
  parseBillingInfo,
  parseMultipleColumns,
  parseSocialMedia,
  parseTags,
  parseCustomFields
} from "./helpers";
import {parsingDataItemModel, tParsingDataItemModel} from "./dataItemModel";
import {iField} from "api/fields/types";

const socialMediaHeaderKeys = ["facebook", "instagram", "linkedin", "twitter"];
const personNameHeaderKeys = ["first_name", "middle_name", "last_name", "legal_name"];
const tagsHeaderKeys = ["title", "locales", "labels", "dietary restrictions"];
const plainDataHeaderKeys = ["notes", "travel", "rate"];
const otherDietaryRestrictions = ["other dietary restriction", "other dietary restrictions"];

export type tParseCsvType = "companies" | "people" | "locations";

export const parseCsv = (data: string, type: tParseCsvType, customFields: iField[] = []) => {
  let contactData: any = {
    companies: [],
    people: [],
    locations: [],
    errors: {}
  };

  const cFields = customFields.reduce((prev, current: iField) => {
    return {
      ...prev,
      [`custom ${current.name.toLowerCase()}`]: {
        ...current
      }
    };
  }, {} as {[key: string]: iField});

  let results = Papa.parse(data);
  const headerRow = results?.data?.[0] as string[];
  const rows: any = results?.data?.slice(1);

  if (type === "people" && !headerRow.includes("First Name")) {
    return {
      errors: null,
      data: [],
      errorMessage:
        "You tried to import people's contacts with the incorrect file.\n" +
        "Please, try again with the correct file."
    };
  }
  if (type === "companies" && !headerRow.includes("Company Name")) {
    return {
      errors: null,
      data: [],
      errorMessage:
        "You tried to import companies' contacts with the incorrect file.\n" +
        "Please, try again with the correct file."
    };
  }
  if (type === "locations" && !headerRow.includes("Location Name")) {
    return {
      errors: null,
      data: [],
      errorMessage:
        "You tried to import locations' contacts with the incorrect file.\n" +
        "Please, try again with the correct file."
    };
  }

  rows.forEach((row: any, rowIndex: number) => {
    if (row.every((v: string) => !v)) return;
    headerRow
      .map(r => r.toLowerCase())
      .forEach((header, headerIndex) => {
        if (!contactData?.[type]?.[rowIndex]) {
          contactData[type][rowIndex] = JSON.parse(JSON.stringify(parsingDataItemModel[type]));
        }

        if (header in cFields) {
          if (!row[headerIndex]) return;
          parseCustomFields(header, headerIndex, rowIndex, header, row, contactData, type, cFields);
          return;
        }

        if (header.includes("company name") || header.includes("location name")) {
          contactData[type][rowIndex].name = row[headerIndex];
        }

        if (header === "address") {
          if (!row[headerIndex]) return;
          contactData[type][rowIndex].address.raw = row[headerIndex];
        }

        if (otherDietaryRestrictions.find(key => key === header)) {
          if (!row[headerIndex]) return;
          contactData[type][rowIndex]["other_dietary_restriction"] = row[headerIndex];
        }

        if (plainDataHeaderKeys.find(key => key === header)) {
          if (!row[headerIndex]) return;
          const key = header.split(" ").join("_");
          contactData[type][rowIndex][key] = row[headerIndex];
        }

        if (tagsHeaderKeys.find(key => key === header) || header.includes("departments")) {
          if (!row[headerIndex]) return;
          let headerKey = header.split(" ").join("_");
          if (header.includes("departments")) headerKey = "departments";
          if (header === "title") headerKey = "titles";
          parseTags(headerIndex, rowIndex, header, row, contactData, type, headerKey);
        }

        if (header === "website") {
          if (!row[headerIndex]) return;
          contactData[type][rowIndex].website = row[headerIndex];
        }
        if (header.includes("phone") && !header.includes("billing")) {
          parseEmailPhone("phones", headerIndex, rowIndex, header, row, contactData, type);
        }
        if (header.includes("email") && !header.includes("billing")) {
          parseEmailPhone("emails", headerIndex, rowIndex, header, row, contactData, type);
        }
        if (!!socialMediaHeaderKeys.filter(key => header.includes(key)).length) {
          parseSocialMedia(header, headerIndex, rowIndex, row, contactData, type);
        }
        if (header.includes("billing")) parseBillingInfo(header, headerIndex, rowIndex, row, contactData, type); // prettier-ignore
        if (header.includes("preferred")) contactData[type][rowIndex].preferred = !!row[headerIndex]; // prettier-ignore
        if (personNameHeaderKeys.find(key => key === header.split(" ").join("_"))) {
          if (!row[headerIndex]) {
            if (header === "first name" || header === "last name") {
              const name = contactData[type][rowIndex]?.first_name;
              contactData["errors"] = {
                ...contactData["errors"],
                [rowIndex]: {
                  ...contactData["errors"][rowIndex],
                  [name]: "last name can't be empty"
                }
              };
              return;
            }
            return;
          }
          const headerKey = header.split(" ").join("_");
          contactData[type][rowIndex][headerKey] = row[headerIndex];
        }
        if (header.includes("company") && header !== "company name") {
          parseMultipleColumns(headerIndex, rowIndex, header, row, contactData, type, "companies", 1); // prettier-ignore
        }
        if (header.includes("artist agency")) {
          parseMultipleColumns(headerIndex, rowIndex, header, row, contactData, type, "agencies", 2); // prettier-ignore
        }
        if (header.includes("team")) {
          header.includes("relationship")
            ? parseMultipleColumns(headerIndex, rowIndex, header, row, contactData, type, "team", 1, "user_relationship_id") // prettier-ignore
            : parseMultipleColumns(headerIndex, rowIndex, header, row, contactData, type, "team", 1, "user_id") // prettier-ignore
        }
        if (header.includes("relationship to versatile")) {
          contactData[type][rowIndex]["versatile_relationship_id"] = row[headerIndex];
        }
        if (header.includes("pronoun")) {
          contactData[type][rowIndex]["pronoun_id"] = row[headerIndex];
        }
        if (header.includes("contact name") || header.includes("contact title")) {
          const isName = header.includes("contact name");
          isName
            ? parseMultipleColumns(headerIndex,rowIndex, header, row, contactData, type, "contacts", 2,"person_id") // prettier-ignore
            : parseMultipleColumns(headerIndex,rowIndex, header, row, contactData, type, "contacts", 2,"title_id"); // prettier-ignore
        }
        if (header === "location type") {
          contactData[type][rowIndex]["location_type_id"] = row[headerIndex];
        }
        if (header === "permit needed") {
          contactData[type][rowIndex]["permit_required"] = !!row[headerIndex];
        }
        if (header === "image url") {
          contactData[type][rowIndex]["photo_external_url"] = row[headerIndex];
        }
      });
  });

  const preparedData = contactData[type].map(
    (
      contact:
        | tParsingDataItemModel["people"]
        | tParsingDataItemModel["companies"]
        | tParsingDataItemModel["locations"]
    ) => {
      return Object.entries(contact).reduce((previousValue, [key, value]) => {
        if (!value) return previousValue;
        if (type === "locations" && key === "locales") return {...previousValue, [key]: value};
        if (Array.isArray(value) && !value?.filter(k => k).length) return previousValue;

        if (key === "address" && !value?.raw) return previousValue;
        if (key === "billing_info") {
          let newValue = {};
          const billingInfo: any = value;
          if (billingInfo?.address?.raw) {
            newValue = {
              address: {
                raw: billingInfo?.address?.raw
              }
            };
          }
          Object.entries(billingInfo).forEach(([key, val]) => {
            if (typeof val === "string" && val) {
              if (key === "billing_email") {
                return (newValue = {...newValue, email: val});
              }

              if (key === "billing_phone") {
                return (newValue = {...newValue, phone: val});
              }
              newValue = {...newValue, [key]: val};
            }
          });

          if (!!Object.values(newValue).length) {
            return {...previousValue, [key]: newValue};
          }

          return previousValue;
        }
        if (type === "locations" && key === "contacts") {
          const contacts = value.filter(
            (ctc: {person_id: string; title_id: string}) =>
              ctc.title_id && ctc.person_id?.split(" ").length > 1
          );

          return {...previousValue, contacts};
        }
        return {...previousValue, [key]: value};
      }, {});
    }
  );

  return {
    data: preparedData,
    errorMessage: "",
    errors: isEmptyObject(contactData.errors) ? null : contactData.errors
  };
};

function isEmptyObject(obj: any) {
  return !Object.keys(obj).length;
}
