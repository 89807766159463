import { iExportContactsParams, iGetPeopleListParams, } from "./interfaces";
import { AxiosRequestConfig } from "axios";
import { iContacts } from "../types";

const PERSON = "person";
const PEOPLE = "people";
const ARTIST = "artist";

export const people = {
  getList: (params?: iGetPeopleListParams): AxiosRequestConfig => ({
    url: "people",
    params
  }),
  // FIXME need to check iAddPerson
  create: (data: Partial<iContacts>): AxiosRequestConfig => ({
    method: "POST",
    url: PERSON,
    data
  }),
  // FIXME need to check iAddPerson
  update: (data: Partial<iContacts>, id: number): AxiosRequestConfig => ({
    method: "POST",
    url: `${PERSON}/${id}`,
    data
  }),
  delete: (id: number): AxiosRequestConfig => ({
    method: "DELETE",
    url: `${PERSON}/${id}`
  }),
  getPersonDetails: (id: number): AxiosRequestConfig => ({
    url: `${PERSON}/${id}`
  }),
  exportPeople: (params: iExportContactsParams): AxiosRequestConfig => ({
    method: "GET",
    url: `${PEOPLE}/export`,
    params
  }),
  exportPersonById: (params: iExportContactsParams, person_id: number) => ({
    url: `${PERSON}/${person_id}/export`,
    params
  }),
  importPeopleContacts: (data: iContacts[]): AxiosRequestConfig => ({
    method: "post",
    url: `${PEOPLE}/import`,
    data
  }),
  getPeopleListById: (params: { ids?: number[] }): AxiosRequestConfig => ({
    url: `${PEOPLE}/list`,
    params
  }),
  getAgentsOfArtistAgency: (artist_id: number): AxiosRequestConfig => ({
    url: `${ARTIST}/${artist_id}/agents`
  })
};
