import {iUpdateProfileData} from "api/profile/types";
import {tDynamicFieldRowObj} from "components/shared/FormComponents/DynamicFields/types";
import {getDateFormat} from "helpers/dates";

type tProps = {
  accounts: tDynamicFieldRowObj[];
  avatar: string;
  form: any;
};

export const generatePostData = ({accounts, form, avatar}: tProps) => {
  const data: iUpdateProfileData = {
    ...(!avatar.includes("http") && {avatar}),
    display_name: form.display_name.value,
    pronoun_id: +form.pronoun_id.value,
    ...(form?.pronoun_id?.customInput?.value && {
      pronoun_custom: form?.pronoun_id?.customInput?.value
    }),
    birthday: getDateFormat(form.birthday.value, "timestamp"),
    fun_facts: form.fun_facts.value,
    accounts: accounts
      .map(item => ({
        ...(typeof item?.uid === "number" && {id: item.uid}),
        value: item.value || "",
        platform: item.id || undefined
      }))
      .filter(item => !!item?.value),
    phone: form.phone?.value || "",
    personal_email: form?.personal_email.value,
    home_address: {
      country_iso: form.home_address?.address.country_iso,
      post_code: form.home_address?.address.post_code,
      state: form.home_address?.address.state,
      street: form.home_address?.address.street,
      city: form.home_address?.address.city,
      apartment: form.home_address?.address.apartment
    },
    emergency_contact: {
      email: form?.email.value,
      full_name: form?.full_name?.value,
      phone: form.phoneEmergency?.value || "",
      relationship: form.relationship.value
    }
  };

  return data;
};
