import {FC, ReactNode, useState} from "react";
import {Button} from "components/shared/Button/Button";
import {useContextJobCrew} from "../../JobCrewContext";
import {MoveContacts} from "./MoveContacts";
import {RemoveContacts} from "./RemoveContacts";
import { eventBus } from "EventBus/EventBus";


type tCallActionBtnProps = {
  type: "move" | "remove";
  children: ReactNode;
  className?: string;
};

export const CallActionBtn: FC<tCallActionBtnProps> = ({type, children, className}) => {
  const {selectedContacts} = useContextJobCrew();
  const [showModal, setModal] = useState(false);

  const openModal = () => {
    if (!selectedContacts?.length) {
      return eventBus.dispatch("showToast", { type: "error", text: "You should select at least one contact!" });
    }

    setModal(true);
  };

  return (
    <>
      <Button
        size="sm"
        color="outline"
        onClick={openModal}
        className={`${className ? className : ""}`}
      >
        {children}
      </Button>

      {showModal && (
        <>
          {type === "move" && <MoveContacts setModal={setModal} />}
          {type === "remove" && <RemoveContacts setModal={setModal} />}
        </>
      )}
    </>
  );
};
