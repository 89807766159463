import {AxiosRequestConfig} from "axios";

const jobGrid = "job_grid";

export const gridApi = {
  getGridList: (jobId: number): AxiosRequestConfig => ({
    url: `job/${jobId}/grids`
  }),
  createNewGrid: (data: {job_id: number; name: string}): AxiosRequestConfig => ({
    url: jobGrid,
    method: "POST",
    data
  }),
  createNewGridFromTemplate: (
    idTemplate: number,
    data: {job_id: number; name: string}
  ): AxiosRequestConfig => ({
    url: `${jobGrid}/template/${idTemplate}`,
    method: "POST",
    data
  }),
  getGrid: (jobGridId: number): AxiosRequestConfig => ({
    url: `${jobGrid}/${jobGridId}`
  }),
  renameGrid: (jobGridId: number, data: {name: string}): AxiosRequestConfig => ({
    url: `${jobGrid}/${jobGridId}/rename`,
    method: "POST",
    data
  }),
  duplicateGrid: (jobGridId: number, data: {name: string}): AxiosRequestConfig => ({
    url: `${jobGrid}/${jobGridId}/duplicate`,
    method: "POST",
    data
  }),
  deleteGrid: (jobGridId: number): AxiosRequestConfig => ({
    url: `${jobGrid}/${jobGridId}/delete`,
    method: "DELETE"
  }),
  /**
   * Templates
   */
  getGridTemplates: (): AxiosRequestConfig => ({
    method: "GET",
    url: `${jobGrid}/templates`
  }),
  deleteGridTemplate: (template_id: number): AxiosRequestConfig => ({
    method: "delete",
    url: `${jobGrid}/template/${template_id}/delete`
  }),
  renameGridTemplate: (template_id: number, data: {name: string}): AxiosRequestConfig => ({
    method: "POST",
    url: `${jobGrid}/template/${template_id}/rename`,
    data
  }),
  createTemplate: (jobGridId: number, data: {name: string}): AxiosRequestConfig => {
    return {
      url: `${jobGrid}/${jobGridId}/template`,
      method: "POST",
      data
    };
  },
  saveAsExistingTemplate: (jobGridId: number, template: string): AxiosRequestConfig => {
    return {
      url: `${jobGrid}/${jobGridId}/template/${template}`,
      method: "POST"
    };
  }
};
