import {createContext, FC, useContext} from "react";
import {team} from "api/team";
import {iTeamListItemReturnData, tTeamListParams} from "api/team/types";
import {useHttp} from "hooks/httpServices/useHttp";
import {useSetApiParams} from "hooks/useSetApiParams";

const useTeamList = () => {
  const {call, data, ...props} = useHttp();
  const apiParams = useSetApiParams();

  const getTeamList = () => {
    const {preparedParams} = apiParams;

    const params: tTeamListParams = {
      ...preparedParams,
      ...(preparedParams?.departments && {departments: preparedParams?.departments?.split(",")})
    };

    return call(team.getTeamList(params));
  };

  return {
    teamListData: data?.data?.data as Array<iTeamListItemReturnData>,
    getTeamList,
    data,
    ...apiParams,
    ...props
  };
};

type tTeamContext = ReturnType<typeof useTeamList>;
const TeamContext = createContext<tTeamContext | null>(null);
TeamContext.displayName = "TeamContext";
export const TeamContextProvider: FC = ({children}) => (
  <TeamContext.Provider value={useTeamList()}>{children}</TeamContext.Provider>
);

/**
 * Provides team list
 * @returns (team list and functions for get this one)
 */
export const useContextTeam = () => {
  const context = useContext(TeamContext);

  if (context === null) {
    throw new Error("<<< TeamContext >>> must be used within a TeamContextProvider");
  }

  return context as tTeamContext;
};
