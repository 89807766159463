import {FC, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {SectionHeader} from "../../CallSheetBuildCommon/SectionHeader";
import {tCallSHeetGroupContacts} from "api/callSheet/types";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {BatchEdit} from "../../../modals/BatchEdit/BatchEdit";
import {uid} from "helpers/misc";
import {manuallyEnteredContact} from "./manuallyEnteredContact";
import {AppTooltips} from "components/AppTooltips/AppTooltips";

type tCallSheetBuildContactsHeaderProps = {
  group: tCallSHeetGroupContacts;
  setContactGroup: any;
  isFirstGroup?: boolean;
};

export const CallSheetBuildContactsHeader: FC<tCallSheetBuildContactsHeaderProps> = ({
  group,
  isFirstGroup,
  setContactGroup
}) => {
  const [shoeEdit, setShowEdit] = useState(false);

  const addRowHandler = () => {
    setContactGroup((prev: tCallSHeetGroupContacts[]) => {
      return prev?.map(item => {
        if (item?.id !== group?.id) return item;
        const newContacts = item?.contacts
          ? [...item.contacts, {id: uid(), ...manuallyEnteredContact}]
          : [];
        return {...item, contacts: newContacts};
      });
    });
  };

  const removeGroupHandler = () => {
    setContactGroup((prev: tCallSHeetGroupContacts[]) => {
      return prev?.map(item => {
        if (item?.id === group?.id) {
          return {...item, hidden: !item?.hidden};
        }
        return item;
      });
    });
  };

  return (
    <>
      <SectionHeader title={group?.name} className="CallSheetBuildContactsHeader">
        <Button color="outline" size="sm" onClick={addRowHandler}>
          <Icon icon="add" size="xsm" />
          Manual row
        </Button>
        <Button color="outline" size="sm" onClick={() => setShowEdit(true)}>
          Batch edit
        </Button>
        <Button color="outline" size="sm" onClick={removeGroupHandler}>
          Remove group
        </Button>

        {isFirstGroup && <AppTooltips page="callSheet" tooltip="ONE-TIME CONTACTS" />}
      </SectionHeader>

      {shoeEdit && (
        <ModalPopup show={true} onClose={() => setShowEdit(false)} withoutPaddings size="w-528">
          <BatchEdit data={group} onClose={() => setShowEdit(false)} />
        </ModalPopup>
      )}
    </>
  );
};
