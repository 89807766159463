import {AxiosRequestConfig} from "axios";

const JOB = "job";

export const jobFilesApi = {
  getJobFiles: (jobId: number, path: string): AxiosRequestConfig => ({
    url: `${JOB}/${jobId}/files`,
    params: {path}
  }),
  getSharedUrl: (jobId: number, params: {path: string}): AxiosRequestConfig => ({
    url: `${JOB}/${jobId}/files/share`,
    params
  }),
  downloadFileFromDropbox: (jobId: number, params: {path: string}): AxiosRequestConfig => ({
    responseType: "blob",
    url: `${JOB}/${jobId}/files/download`,
    params
  })
};
