import {useContextAdvance} from "../context";
import {useEffect, useState} from "react";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useDatePicker} from "components/shared/FormComponents/Datepicker/useDatePicker";
import {tAdvanceContact} from "api/budget/advanceContact/types";

interface iUseBudgetOverlayAdvanceDetailsProps {}

export function useBudgetOverlayAdvanceDetails(props?: iUseBudgetOverlayAdvanceDetailsProps) {
  const {advanceDetails, setAdvanceDetails} = useContextAdvance();

  const [editContacts, setEditContacts] = useState(false);

  const formData = {
    invoice_number: useTextInput({value: advanceDetails?.invoice_number}),
    date: useDatePicker({initValue: advanceDetails?.date || new Date().toString()}),
    terms: useTextInput({value: advanceDetails?.terms}),
    due_date: useDatePicker({initValue: advanceDetails?.due_date || new Date().toString()}),
    contact: useTextInput({
      value: advanceDetails?.advance_contact?.name
        ? generateContactInfo(advanceDetails.advance_contact)
        : ""
    })
  };

  useEffect(() => {
    updateAdvanceDetails("date", formData.date.value);
  }, [formData.date.value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateAdvanceDetails("due_date", formData.due_date.value);
  }, [formData.due_date.value]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeContact = (contact: tAdvanceContact) => {
    setAdvanceDetails(prevState => (prevState ? {...prevState, advance_contact: contact} : null));
    formData.contact.setValue(generateContactInfo(contact));
  };

  function generateContactInfo(contact: tAdvanceContact) {
    // const phone = formatPhoneNumber({value: contact?.phone});
    const phone = contact?.phone;
    return `${contact.name}\r\n${phone}\r\n${contact.email}`;
  }

  const onSaveCallback = (event: any) => {
    const {name, value} = event.target;
    updateAdvanceDetails(name, value);
  };

  function updateAdvanceDetails(key: any, value: any) {
    setAdvanceDetails(prevState => (prevState ? {...prevState, [key]: value} : null));
  }

  return {
    formData,
    editContacts,
    setEditContacts,
    onSaveCallback,
    onChangeContact
  };
}
