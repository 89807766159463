import {FC, useState} from "react";
import {people} from "api/contacts/people";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextContacts} from "pages/Contacts/context/ContactsContext";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {getApiError} from "helpers/getApiError";
import {getDateFormat} from "helpers/dates";
import {eventBus} from "EventBus/EventBus";

interface iDeletePersonProps {
  id: number;
  created?: {
    date?: string | null;
    name?: string;
  };
}

export const DeletePerson: FC<iDeletePersonProps> = ({id, created}) => {
  const {people: {getPeopleList}} = useContextContacts(); // prettier-ignore
  const [confirmDelete, setConfirmDelete] = useState(false);
  const {call, isLoading} = useHttp();

  const deletePerson = async () => {
    try {
      const response = await call(people.delete(id));
      await getPeopleList();
      eventBus.dispatch("showToast", {text: response?.data?.message});
    } catch (err) {
      const {msg} = getApiError(err);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error !"});
    }
  };

  const closeConfirmDeleteWindow = () => setConfirmDelete(false);
  const onDeletePerson = () => setConfirmDelete(true);

  return (
    <>
      <div className="PeopleForm-footer">
        <p className="PeopleForm-info">
          Created {created?.date && `on ${getDateFormat(created.date, "MMM DD, YYYY")} `}
          {created?.name && `by ${created.name}`}{" "}
        </p>
        <button type="button" className="PeopleForm-footer--delete-button" onClick={onDeletePerson}>
          Delete Person
        </button>
      </div>

      <ConfirmWindow
        title="Do you really want to delete person?"
        show={confirmDelete}
        confirmBtn={{text: "Delete", color: "red"}}
        cancelBtn={{text: "Cancel"}}
        isLoading={isLoading}
        onClose={closeConfirmDeleteWindow}
        onConfirm={deletePerson}
      />
    </>
  );
};
