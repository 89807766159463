import {FC} from "react";
import "./Resizer.scss";

type tResizerProps = {
  resizeHandler: (mouseDownEvent: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  dividerHeight?: number;
};

export const Resizer: FC<tResizerProps> = ({resizeHandler, dividerHeight}) => {
  return (
    <div className="Resizer" onMouseDown={e => resizeHandler?.(e)}>
      <div style={{height: `${dividerHeight}px`}} />
    </div>
  );
};
