import {useEffect, useState} from "react";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {useHttp} from "hooks/httpServices/useHttp";
import {trackApi} from "api/budget/track";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";

export function useWrapPDF() {
  const {jobDetailsData} = useContextJobDetails();
  const {call, isLoading} = useHttp({disableTimeout: true});
  const [url, setUrl] = useState("");

  const getWrapPdfUrl = async () => {
    if (!jobDetailsData?.id) {
      return eventBus.dispatch("showToast", {type: "error", text: "Job id not found"});
    }
    try {
      const res = await call(trackApi?.getWrapPDF(jobDetailsData?.id));
      setUrl(res?.data?.path);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      console.log("msg --->", msg);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  useEffect(() => {
    getWrapPdfUrl();
  }, []); // eslint-disable-line

  const {call: callToDropbox} = useHttp();
  const [savingToDropbox, setSaving] = useState(false);
  const onSaveToDropbox = async () => {
    if (!jobDetailsData?.id) return;
    setSaving(true);
    try {
      await callToDropbox(trackApi.saveTrackToDropbox(jobDetailsData?.id));
      setSaving(false);
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
      setSaving(false);
    }
  };

  return {
    url,
    setUrl,
    isLoading,
    onSaveToDropbox,
    isSavingToDropbox: savingToDropbox
  };
}

// import {useEffect, useState} from "react";
// import {useContextTrack} from "../../_context/TrackContext";
// import {useGeneratePdfData} from "hooks/useGeneratePdfData";
// import {WrapPDFDocument} from "../WrapPDFDocument/WrapPDFDocument";
// import {tTrack, tTrackCategoriesItem} from "api/budget/track/types";
// import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
// import {useHttp} from "hooks/httpServices/useHttp";
// import {trackApi} from "api/budget/track";
// import {apiErrorHandler} from "helpers/apiErrorHandler";
// import {eventBus} from "EventBus/EventBus";

// export function useWrapPDF() {
//   const {call, isLoading} = useHttp();
//   const {trackData} = useContextTrack();
//   const {jobDetailsData} = useContextJobDetails();

//   const [url, setUrl] = useState("");
//   const [blob, setBlob] = useState<Blob | null>(null);

//   const {generatePdfData} = useGeneratePdfData();

//   useEffect(() => {
//     if (!trackData || url) return;

//     const cats = trackData.categories.reduce((prev: tTrackCategoriesItem[], current) => {
//       if (!!current?.cost_values?.length) {
//         return [
//           ...prev,
//           {
//             ...current,
//             cost_values: current.cost_values
//           }
//         ];
//       }
//       return prev;
//     }, []);

//     const data = {
//       ...trackData,
//       categories: cats
//     };

//     createPdf(data);
//   }, [trackData, url]); // eslint-disable-line

//   async function createPdf(data: tTrack) {
//     if (!jobDetailsData) return;
//     const pdf = await generatePdfData(
//       <WrapPDFDocument jobDetails={jobDetailsData} trackData={data} />
//     );
//     if (pdf?.url) {
//       setUrl(pdf.url);
//       setBlob(pdf.blob);
//     }
//     return pdf;
//   }

//   const onSaveToDropbox = async () => {
//     if (!jobDetailsData?.id) return;
//     try {
//       const payload: any = new FormData();
//       payload.append("file", blob);
//       await call(trackApi.saveTrackToDropbox(jobDetailsData?.id, payload));
//     } catch (error: any) {
//       const {msg} = apiErrorHandler(error);
//       eventBus.dispatch("showToast", {type: "error", text: msg});
//     }
//   };

//   return {
//     url,
//     setUrl,
//     onSaveToDropbox,
//     isSavingToDropbox: isLoading
//   };
// }
