import {FC} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {tEditEstimateTotalDetailsForm} from "./useUpdateTotalEstimate";

type tInputsGrandTotalProps = {
  form: tEditEstimateTotalDetailsForm;
};

export const InputsGrandTotal: FC<tInputsGrandTotalProps> = ({form}) => {
  return (
    <>
      <TextComponent size="15" uppercase m={["mb-8", "mt-24"]}>
        Grand total
      </TextComponent>

      <InputField
        typeField="horizontalLabel"
        label="Insurance %"
        {...form.insurance_percent.inputProps}
        suffix="%"
        errors={form.insurance_percent.errors}
      />
      <InputField
        typeField="horizontalLabel"
        label="Green"
        {...form.green_percent.inputProps}
        suffix="%"
        errors={form.green_percent.errors}
      />
      <InputField
        typeField="horizontalLabel"
        label="Sales Tax"
        {...form.sales_tax_percent.inputProps}
        suffix="%"
        errors={form.sales_tax_percent.errors}
      />
    </>
  );
};
