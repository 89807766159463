import {FC, forwardRef, useEffect, useImperativeHandle} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {tDynamicInputsGroupProps} from "./types";
import {tTypeDynamicFields} from "../types";
import {PhoneField} from "components/shared/FormComponents/PhoneField/PhoneField";
import {useDynamicInputsGroupForm} from "./useDynamicInputsGroupForm";
import {SelectInput} from "components/shared/FormComponents/SelectInput/SelectInput";
import { InputField } from "../../InputField/InputField";

export const DynamicInputsGroup: FC<tDynamicInputsGroupProps> = forwardRef(
  (
    {
      onChangeHandler,
      showDeleteBtn,
      handleRemove,
      type_custom,
      isFirstItem,
      options,
      itemField,
      type
    },
    ref
  ) => {
    const {select, phone} = useDynamicInputsGroupForm({options, isFirstItem, itemField, type, onChangeHandler, type_custom}); // prettier-ignore

    useEffect(() => {
      options?.length && select.setOptions(options);
    }, [options]); // eslint-disable-line

    useImperativeHandle(ref, () => ({checkValidity}));

    function checkValidity() {
      const isValidName = select.checkValidity();
      const isValidPhones = phone.checkValidity();

      return isValidName && isValidPhones;
    }

    const labels: Record<tTypeDynamicFields, {input: string; select: string}> = {
      phones: {input: "", select: ""},
      emails: {input: "Email", select: ""},
      socPlatform: {input: "USERNAME", select: "PLATFORM"},
      locations: {input: "Label", select: "Location"}
    };

    return (
      <div className={`DynamicInputsGroup-phone ${type}`}>
        <InputField {...phone.inputProps} errors={phone.errors} />
        {/* <PhoneField {...phone.inputProps} errors={phone.errors} /> */}

        <SelectInput
          {...select.inputProps}
          isCustomInput={select.isCustomInput}
          customInput={select.customInput}
          clearAll={select.clearAll}
          label={isFirstItem ? labels[type].select : ""}
          errors={select.errors}
          canSelectEmpty={false}
        />

        {showDeleteBtn && (
          <Button
            type="button"
            color="outline"
            size="sm"
            onClick={() => handleRemove(itemField.uid)}
          >
            <Icon icon="close" size="sm" color="gray" />
          </Button>
        )}
      </div>
    );
  }
);
