import {iAddress} from "api/common/types";
import {iOptions} from "hooks/useTagsGroup";
import {RefObject} from "react";
import {iEmail, iPhone} from "api/interfaces";

export const STORAGE_KEY = "vrstl_info";
export const INVITE_LINK_DATA = "inviteData";

export const getStorageData = () => {
  return window.localStorage.getItem(STORAGE_KEY)
    ? JSON.parse(window.localStorage.getItem(STORAGE_KEY) || "{}")
    : JSON.parse(window.sessionStorage.getItem(STORAGE_KEY) || "{}");
};

export const removeStorageData = () => {
  return window.localStorage.getItem(STORAGE_KEY)
    ? window.localStorage.removeItem(STORAGE_KEY)
    : window.sessionStorage.removeItem(STORAGE_KEY);
};

export function recObjIterator(obj: {[k: string]: any}, itemModifier: (v: any, o: object) => any) {
  const resObj: {[k: string]: any} = {};

  for (let i in obj) {
    if (typeof obj[i] === "object" && obj[i] !== null && !Array.isArray(obj[i])) {
      resObj[i] = recObjIterator(obj[i], itemModifier);
    } else {
      resObj[i] = itemModifier(i, obj);
    }
  }

  return resObj;
}

export const allIn = (route: string, suffix: string = "/*") => route + suffix;

/**
 *
 * @param data
 * @param scrollToFirstError
 * Scroll to first error if scrollToFirstError = true
 * formData keys should be the same as the id of the form fields
 */
export function notValidForm<T extends {[k: string]: any}>(data: T, scrollToFirstError = false) {
  const invalidFields = Object.entries(data).filter(([key, value]) => !value?.checkValidity());

  if (!!invalidFields?.length && scrollToFirstError) {
    const firstInvalidKey = invalidFields?.[0]?.[0];
    const firstInvalidInput = document.querySelector(`#${firstInvalidKey}`);
    firstInvalidInput?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start"
    });
  }

  return invalidFields.length > 0;
}

export const uid = function () {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};
export const createTagsOptions = (val?: iOptions[]) =>
  val?.map(({label, value}) => ({id: uid(), value: {label, value}})) ?? [];

export const parseAddress = (address: iAddress, showCountry?: boolean) => {
  if (!address) return {string: "", fullAddress: null, addressWithMarkup: null};
  let addressString: string[] = [];

  if (!address?.city || !address?.street || !address?.country) {
    return {
      string: address?.raw || "",
      fullAddress: {
        apartment: "",
        country: "",
        street: "",
        city: "",
        post_code: "",
        state: ""
      }
    };
  }

  delete address.raw;

  Object.entries(address).forEach(([key, value], index, arr) => {
    if (!value || key === "id") return;

    if (typeof value !== "string" && value?.nicename)
      return showCountry && addressString.push(value?.nicename);

    if (typeof value === "string") {
      addressString.push(value);
    }
  });

  return {
    string: addressString.join(", "),
    fullAddress: address
  };
};

type tInitMapbox = {
  container: RefObject<HTMLElement> | string;
  lng?: number;
  lat?: number;
  zoom?: number;
  callback?: Function;
  markerColor?: string;
};
export const initMapbox = ({
  container,
  lng,
  lat,
  zoom = 12,
  callback,
  markerColor = "#d2232a"
}: tInitMapbox) => {
  // @ts-ignore
  let mapboxgl = window.mapboxgl;
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

  const mapContainer = typeof container === "object" ? container?.current : container;

  const mapInstance = new mapboxgl.Map({
    container: mapContainer,
    style: "mapbox://styles/mapbox/streets-v11",
    center: [lng, lat],
    zoom: zoom
  });

  new mapboxgl.Marker({color: markerColor}).setLngLat([lng, lat]).addTo(mapInstance);

  callback?.(mapInstance);
};

interface iList {
  [key: string]: {id: number; value: string}[];
}
export const makeTypedList = (list: iPhone[] | iEmail[]) => {
  const newList = {} as iList;
  list.forEach(item => {
    if (newList[item.type_name]) newList[item.type_name].push({id: item.id, value: item.value});
    else newList[item.type_name] = [{id: item.id, value: item.value}];
  });
  return newList;
};

export const getName = (object: {[key: string]: any} | undefined | null) => {
  if (!object) return "";
  return object?.name || `${object?.first_name} ${object?.last_name}`;
};

export const returnSIfSeveralItems = (arr: Array<any> | null) => {
  return arr?.length && arr?.length > 1 ? "s" : "";
};

export function toCapitalize(value: string = "") {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function sortByKey<T>(arr: T[], key: keyof T): T[] {
  if (typeof key !== "string") {
    console.error("sortByKey: key is wrong");
    return arr;
  }
  return arr.sort((a, b) => {
    return String(a[key]).localeCompare(String(b[key]));
  });
}

export function addMinusBefore$(val: string) {
  if(val === '') return val;
  const isMinus = val?.includes("-");
  const value = isMinus ? `-$${val?.slice(1)}` : `$${val}`;
  return value;
}
