import {FC} from "react";
import {StyleSheet, Text} from "@react-pdf/renderer";

export const Label: FC = ({children, style, ...props}: any) => {
  return (
    <Text style={styles.text} {...props}>
      {children}
    </Text>
  );
};

export const Value: FC = ({children, ...props}: any) => {
  return (
    <Text style={styles.value} {...props}>
      {children}
    </Text>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: 10,
    fontFamily: "Helvetica700",
    textTransform: "uppercase",
    marginBottom: 4,
    maxWidth: "100px",
    width: "50%"
  },
  value: {fontSize: 10, fontFamily: "Helvetica400", width: "50%", marginBottom: 4}
});
