import {FC} from "react";
import {Accordion} from "components/shared/Accordion/Accordion";
import {iCompanyDetails} from "api/contacts/companies/interfaces";
import {ModalInfoSection} from "components/shared/ModalInfoSection/ModalInfoSection";
import {parseAddress} from "helpers/misc";
import {DepartmentList} from "components/shared/DepartmentList/DepartmentList";
import {CompanyDetailsJobs} from "./CompanyDetailsJobs/CompanyDetailsJobs";
import {TagButton} from "components/shared/TagButton/TagButton";
import {PeopleDetailsGeneralInfo} from "../../PeopleModals/PeopleDetails/PeopleDetailsJobs/PeopleDetailsGeneralInfo/PeopleDetailsGeneralInfo";
import {PeopleDetailsHeader} from "../../PeopleModals/PeopleDetails/PeopleDetailsHeader/PeopleDetailsHeader";
import {convertDataToBoolean} from "../../helpers/helpers";
import {parseCustomFieldData} from "components/shared/CustomFieldsForm/parseCustomFieldData";
import "./CompanyDetails.scss";

interface iCompanyDetailsProps {
  showEditForm: () => void;
  company: iCompanyDetails;
  showPersonDetails: (id: number) => void;
}

export const CompanyDetails: FC<iCompanyDetailsProps> = ({showEditForm, company}) => {
  const {avatar_url, name, website, labels, locales, emails, accounts, address} = company;
  const {billing_info, notes, departments, jobs, contacts, phones, in_favorite} = company;
  const {custom_fields} = company;

  const templates = {
    addressAndBilling: [
      {label: "Office address", value: address && parseAddress(address).string},
      {
        label: "Billing name",
        value: `${billing_info?.first_name ? billing_info?.first_name : ""}${
          billing_info?.last_name ? " " + billing_info?.last_name : ""
        }`
      },
      {label: "Billing email", value: billing_info?.email},
      {
        label: "Billing phone",
        value: billing_info?.phone
        // value: formatPhoneNumber({
        //   value: billing_info?.phone
        // })
      },
      {
        label: "Billing address",
        value: billing_info?.address && parseAddress(billing_info?.address).string
      }
    ],
    otherDetails: [
      {
        label: "Departments",
        value: departments?.length && (
          <DepartmentList
            data={departments?.map(dep => ({id: dep?.id, avatar: null, name: dep?.name}))}
          />
        )
      },
      {label: "Notes", value: notes}
    ],
    linkedVia: [
      {
        label: "Contacts",
        value: !!contacts?.length && <ContactsRender data={contacts} />
      },

      {
        label: "Jobs",
        value: !!jobs?.length && <CompanyDetailsJobs data={jobs} />
      }
    ],
    customFields: parseCustomFieldData(custom_fields)
  };

  return (
    <div className="CompanyDetails">
      <PeopleDetailsHeader
        avatar={avatar_url}
        name={name}
        website={website}
        isFavorite={in_favorite}
        showEditForm={showEditForm}
        id={company.id}
        type="companies"
      />

      <PeopleDetailsGeneralInfo
        accounts={accounts}
        phones={phones}
        emails={emails}
        locales={locales}
        labels={labels}
      />

      {convertDataToBoolean(templates.addressAndBilling) && (
        <Accordion title="Address & Billing" className="CompanyDetails-Accordion">
          <ModalInfoSection list={templates.addressAndBilling} />
        </Accordion>
      )}

      {convertDataToBoolean(templates.otherDetails) && (
        <Accordion title="Other Details" className="CompanyDetails-Accordion">
          <ModalInfoSection list={templates.otherDetails} />
        </Accordion>
      )}

      {convertDataToBoolean(templates.customFields) && (
        <Accordion title="Custom Details" className="CompanyDetails-Accordion">
          <ModalInfoSection list={templates.customFields} />
        </Accordion>
      )}

      {convertDataToBoolean(templates.linkedVia) && (
        <Accordion title="Linked Via" className="CompanyDetails-Accordion">
          <ModalInfoSection list={templates.linkedVia} />
        </Accordion>
      )}
    </div>
  );
};

const ContactsRender = ({data}: any) => {
  if (!data) return null;

  return (
    <div className="TagsGroup companies">
      {data?.map(({id, first_name, last_name}: any) => (
        <TagButton key={id}>{`${first_name} ${last_name}`}</TagButton>
      ))}
    </div>
  );
};
