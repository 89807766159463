import {FC} from "react";
import {
  InputField,
  InterfaceInputField
} from "components/shared/FormComponents/InputField/InputField";
import {Icon} from "components/Icon/Icon";

import "./InputWithReload.scss";

interface iInputWithReloadProps extends InterfaceInputField {
  onClick?: () => void;
}

export const InputWithReload: FC<iInputWithReloadProps> = ({onClick, ...props}) => {
  return (
    <div className="InputWithReload">
      <button className="InputWithReload-button" onClick={onClick}>
        <Icon icon="reload" />
      </button>
      <InputField {...props} />
    </div>
  );
};
