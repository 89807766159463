import {FC, useState} from "react";
import {EstimateRowName} from "./EstimateRowName/EstimateRowName";
import {EstimateRowActions} from "./EstimateRowActions/EstimateRowActions";
import {EstimateRowExpanded} from "../EstimateRowExpanded/EstimateRowExpanded";
import {EstimateRowCurrency} from "./EstimateRowCurrency/EstimateRowCurrency";
import {EstimateRowUnit} from "./EstimateRowUnit/EstimateRowUnit";
import {EstimateRowSalesTax} from "./EstimateRowSalesTax/EstimateRowSalesTax";
import {EstimateRowPayRoll} from "./EstimateRowPayRoll/EstimateRowPayRoll";
import {EstimateRowBudget} from "./EstimateRowBudget/EstimateRowBudget";
import {EstimateRowCost} from "./EstimateRowCost/EstimateRowCost";
import {EstimateRowTotal} from "./EstimateRowTotal/EstimateRowTotal";
import {EstimateRowProd} from "./EstimateRowProd/EstimateRowProd";
import {EstimateRowAGY} from "./EstimateRowAGY/EstimateRowAGY";
import {EstimateRowQty} from "./EstimateRowQty/EstimateRowQty";
import {EstimateRowTime} from "./EstimateRowTime/EstimateRowTime";
import {DragAndDropWrap, tDraggingItem} from "components/DragAndDropWrap/DragAndDropWrap";
import {tEstimateGroup, tEstimateRowData} from "api/budget/estimate/types";
import {useContextJobBudget} from "../../_context/JobBudgetContext";
import "./EstimateRow.scss";

type tEstimateRowProps = {
  currItem: tEstimateRowData;
  group: tEstimateGroup;
  dropCallBack: (draggingItem: tDraggingItem) => void;
  setCurrItem: React.Dispatch<React.SetStateAction<tDraggingItem | null>>;
  isLoading?: boolean;
};

export const EstimateRow: FC<tEstimateRowProps> = ({
  currItem,
  group,
  dropCallBack,
  setCurrItem,
  isLoading
}) => {
  const {estimate} = useContextJobBudget();
  const isApproved = estimate?.approved_at ? "approved" : "";

  const [expandRow, setExpandRow] = useState(false);
  const close = () => setExpandRow(false);

  const cells = [
    <EstimateRowName
      currItem={currItem}
      group={group}
      expandRow={expandRow}
      setExpandRow={setExpandRow}
    />,
    <EstimateRowCost currItem={currItem} />,
    <EstimateRowCurrency currItem={currItem} />,
    <EstimateRowBudget currItem={currItem} />,
    <EstimateRowQty currItem={currItem} />,
    <EstimateRowTime currItem={currItem} />,
    <EstimateRowUnit currItem={currItem} />,
    <EstimateRowAGY currItem={currItem} />,
    <EstimateRowTotal currItem={currItem} />,
    <EstimateRowSalesTax currItem={currItem} />,
    <EstimateRowPayRoll currItem={currItem} />,
    <EstimateRowProd currItem={currItem} group={group} />,
    <EstimateRowActions currItem={currItem} />
  ];
  return (
    <>
      {cells.map((cell, i) => {
        if (i === 0) {
          return (
            <DragAndDropWrap
              key={i}
              draggingItem={currItem}
              setCurrItem={setCurrItem}
              dropCallBack={item => dropCallBack(item)}
              isLoading={isLoading}
              className={`EstimateRow ${expandRow ? "expanded" : ""} ${isApproved}`}
              blockDragging={estimate?.approved_at ? false : true}
            >
              {cell}
            </DragAndDropWrap>
          );
        }
        return (
          <div key={i} className={`EstimateRow ${expandRow ? "expanded" : ""} ${isApproved}`}>
            {cell}
          </div>
        );
      })}

      {expandRow && <EstimateRowExpanded currItem={currItem} group={group} close={close} />}
    </>
  );
};
