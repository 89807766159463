import {AxiosRequestConfig} from "axios";
import {tApiDataJobTask, tJobTaskPostData, tJobTasksSearchParams, tJobTasksTemplateData, tMoveTasksGroupDirection, tTypesApiJobTask} from "./types"; // prettier-ignore

const job = "job";

export const jobTasksApi = {
  saveAsNewTemplate: (jobTaskListId: number, data: {name: string}): AxiosRequestConfig => ({
    url: `job_task_list/${jobTaskListId}/template`,
    method: "POST",
    data
  }),
  saveAsExistingTemplate: (jobTaskListId: number, template_id: number): AxiosRequestConfig => ({
    url: `job_task_list/${jobTaskListId}/template/${template_id}`,
    method: "POST"
  }),
  renameTemplate: (template_id: number, data: {name: string}): AxiosRequestConfig => ({
    url: `tasks_template/${template_id}/rename`,
    method: "POST",
    data
  }),
  loadTemplate: (jobTaskListId: number, template_id: number): AxiosRequestConfig => ({
    url: `job_task_list/${jobTaskListId}/template/${template_id}/load`,
    method: "POST"
  }),
  deleteTemplate: (templateId: number): AxiosRequestConfig => ({
    url: `tasks_template/${templateId}`,
    method: "DELETE"
  }),
  updateTemplateData: (
    templateId: number,
    data?: {data?: tJobTasksTemplateData["data"]}
  ): AxiosRequestConfig => ({
    url: `tasks_template/${templateId}/data`,
    method: "POST",
    data
  }),
  getTemplateData: (template_id: number): AxiosRequestConfig => ({
    url: `tasks_template/${template_id}`
  }),
  getTaskList: (jobId: number, params?: tJobTasksSearchParams): AxiosRequestConfig => ({
    url: `${job}/${jobId}/task_list`,
    params
  }),
  getTaskTemplates: (): AxiosRequestConfig => ({
    url: `${job}/task_list_templates`
  }),
  createTaskList: (jobId: number, data: {name: string}): AxiosRequestConfig => ({
    url: `${job}/${jobId}/task_list`,
    method: "POST",
    data
  }),
  createTaskListFromTemplate: (
    jobId: number,
    templateId: number,
    data: {name: string}
  ): AxiosRequestConfig => ({
    url: `${job}/${jobId}/tasks_template/${templateId}/load`,
    method: "POST",
    data
  }),
  createGroupTasks: (jobTaskListId: number, data: {name: string}): AxiosRequestConfig => ({
    url: `job_task_list/${jobTaskListId}/group`,
    method: "POST",
    data
  }),
  renameGroup: (jobTaskGroupId: number, data: {name: string}): AxiosRequestConfig => ({
    url: `job_task_group/${jobTaskGroupId}/rename`,
    method: "POST",
    data
  }),
  deleteGroup: (jobTaskGroupId: number): AxiosRequestConfig => ({
    url: `job_task_group/${jobTaskGroupId}`,
    method: "DELETE"
  }),
  moveGroup: (jobTaskGroupId: number, direction: tMoveTasksGroupDirection): AxiosRequestConfig => ({
    url: `job_task_group/${jobTaskGroupId}/${direction}`,
    method: "POST"
  }),
  /**
   * Task's row
   */
  createTask: (
    jobTaskGroupId: number,
    data: tJobTaskPostData,
    params?: tJobTasksSearchParams
  ): AxiosRequestConfig => ({
    url: `job_task_group/${jobTaskGroupId}/task`,
    method: "POST",
    data,
    params
  }),
  changeTaskValue: (
    jobTaskId: number,
    typeApi: tTypesApiJobTask,
    data: tApiDataJobTask[tTypesApiJobTask],
    params?: any
  ): AxiosRequestConfig => ({
    url: `job_task/${jobTaskId}/${typeApi}`,
    method: "POST",
    data,
    params
  }),
  deleteTask: (jobTaskID: number): AxiosRequestConfig => ({
    url: `job_task/${jobTaskID}`,
    method: "DELETE"
  }),
  detachFile: (jobTaskId: number): AxiosRequestConfig => ({
    url: `job_task/${jobTaskId}/file`,
    method: "DELETE"
  }),
  getTasksStatuses: (): AxiosRequestConfig => ({
    url: `job_task_statuses`
  })
};
