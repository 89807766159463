import {FC, useState} from "react";
import {jobTasksApi} from "api/jobs/tasks";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextJobTasks} from "../../_context/JobTaskContext";
import {tJobTasksGroup} from "api/jobs/tasks/types";
import {AssigneeCell} from "./TasksRowCells/AssigneeCell/AssigneeCell";
import {InputCell} from "components/shared/InputCell/InputCell";
import {useSetApiParams} from "hooks/useSetApiParams";

type tTableTaskRowInitProps = {
  data: tJobTasksGroup;
};

export const TableTaskRowInit: FC<tTableTaskRowInitProps> = ({data}) => {
  const {setTasks} = useContextJobTasks();
  const [value, setValue] = useState("");
  const {call, isLoading} = useHttp({cancelRequest: false});

  const apiParams = useSetApiParams();

  const onSubmit = async (id: number, submitData?: any) => {
    if (!value) return;

    try {
      const {preparedParams} = apiParams;
      const params: any = {
        ...preparedParams,
        "filter[assignee]": apiParams?.preparedParams["filter[assignee]"]?.split(",")
      };

      const res = await call(jobTasksApi.createTask(id, {name: submitData}, params));
      setTasks(res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <tr className="TableTaskRow">
      <td className="TableTaskRow-cell name rowInitName">
        <InputCell
          value={value}
          setValue={setValue}
          isLoading={isLoading}
          needToCleanValueAfterSubmit
          placeholder="Add task name..."
          callBack={val => onSubmit(data?.id, val)}
        />
      </td>
      <td className="TableTaskRow-cell assignee">
        <AssigneeCell isRowInit />
      </td>
      <td className="TableTaskRow-cell status"></td>
      <td className="TableTaskRow-cell date"></td>
      <td className="TableTaskRow-cell note"></td>
      <td className="TableTaskRow-cell reminder"></td>
      <td className="TableTaskRow-cell file"></td>
      <td className="TableTaskRow-cell actions"></td>
    </tr>
  );
};
