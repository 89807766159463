import {FC, FormEvent, useState} from "react";
import {people} from "api/contacts/people";
import {jobCrewContact} from "api/jobCrewContact";
import {Button} from "components/shared/Button/Button";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {SearchField} from "components/shared/FormComponents/SearchField/SearchField";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useSearchField} from "hooks/useSearchField";
import {useContextJobCrew} from "../../../JobCrewContext";
import {tCrewContact} from "api/jobCrewGroup/types";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import {LinkIcon} from "assets/icons/Link";
import {updateContact} from "../../../commonJobCrew/updateContact";
import {eventBus} from "EventBus/EventBus";

type tAddAgentProps = {
  crewContact: tCrewContact;
  groupId: number;
};

export const AddAgent: FC<tAddAgentProps> = ({crewContact, groupId}) => {
  const {jobCrewGroupList: {setCrewGroupList}} = useContextJobCrew(); // prettier-ignore
  const {call, isLoading} = useHttp();
  const [showModal, setModal] = useState(false);
  const FORM_ID = "add-agent-form-id";

  const formData = {
    agent_id: useSearchField({
      required: true,
      searchConfig: people.getList,
      searchKey: "search",
      value: ""
    })
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const agent_id = +formData.agent_id.value?.id;
      const res = await call(jobCrewContact.linkAgentToContact(crewContact.id, {agent_id}));

      setCrewGroupList(prev => {
        return updateContact({
          prev,
          groupId,
          crewContactId: crewContact?.id,
          updatedContact: res?.data?.data
        });
      });
      eventBus.dispatch("showToast", {text: res?.data?.message || "Success!"});
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <>
      <Tooltip message="Link agent" position="top" onClick={() => setModal(true)}>
        <Button onClick={() => setModal(true)}>
          <LinkIcon />
        </Button>
      </Tooltip>

      {showModal && (
        <ConfirmWindow
          show={true}
          title="Link agent"
          subTitle="Search for a contact to link"
          cancelBtn={{text: "Cancel", type: "button"}}
          onClose={() => setModal(false)}
          confirmBtn={{text: "Save", type: "submit", form: FORM_ID}}
          isLoading={isLoading}
        >
          <form onSubmit={onSubmit} id={FORM_ID}>
            <SearchField
              {...formData.agent_id}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              placeholder="Search contacts..."
            />
          </form>
        </ConfirmWindow>
      )}
    </>
  );
};
