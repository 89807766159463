import {FC} from "react";
import {tExpenseListItem, tTrackExpenseLine} from "api/budget/track/types";
import {Button} from "components/shared/Button/Button";
import {useContextTrack} from "../../../_context/TrackContext";
import {PayCreditCard} from "./PayCreditCard";
import {PayPayroll} from "./PayPayroll";
import {useDropdownCellWrap} from "../DropdownCellWrap/useDropdownCellWrap";
import {DropdownCellWrap} from "../DropdownCellWrap/DropdownCellWrap";

type tPayProps = {
  className?: string;
  trackLine: tTrackExpenseLine | tExpenseListItem;
};

export const Pay: FC<tPayProps> = ({className = "", trackLine}) => {
  const {lists: {payIds: {list}}} = useContextTrack(); // prettier-ignore
  const {isLoading, menuAnchor, setMenuAnchor, changeValue} = useDropdownCellWrap({
    trackLine,
    type: "pay"
  });

  const getValue = () => {
    if (!!trackLine?.pay_value?.target) {
      const {holder_initials, last_four_digits, name} = trackLine?.pay_value?.target;

      return `${name ?? ""} ${holder_initials ?? ""} ${last_four_digits ?? ""}`;
    }

    return trackLine?.pay_value?.payId?.name ?? "PAY ID";
  };

  return (
    <DropdownCellWrap
      trackLine={trackLine}
      isLoading={isLoading}
      menuAnchor={menuAnchor}
      setMenuAnchor={setMenuAnchor}
      className={`${className}`}
      value={getValue()}
      emptyBtnCallback={() => changeValue(null)}
    >
      {list?.map(item => {
        if (item?.target_type === "credit_card") {
          return (
            <PayCreditCard key={item?.id} card={item} callBack={id => changeValue(item?.id, id)} />
          );
        }

        if (item?.target_type === "payroll_company") {
          return (
            <PayPayroll key={item?.id} payroll={item} callBack={id => changeValue(item?.id, id)} />
          );
        }

        return (
          <Button key={item?.id} size="sm" className="btn" onClick={() => changeValue(item?.id)}>
            {item?.name}
          </Button>
        );
      })}
    </DropdownCellWrap>
  );
};
