import {useState, FormEvent, ChangeEvent} from "react";
import {useRadio} from "hooks/useRadio/useRadio";
import {useContextJobCallSheet} from "../../context/JobCallSheetContext";
import {useHttp} from "hooks/httpServices/useHttp";
import {useSelect} from "hooks/inputHooks";
import {notValidForm} from "helpers/misc";
import {tCallSHeetGroupContacts} from "api/callSheet/types";
import {callSheet as callSheetApi} from "api/callSheet";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";

interface iUseBatchEditProps {
  data: tCallSHeetGroupContacts;
  onClose: () => void;
}

export function useBatchEdit(props: iUseBatchEditProps) {
  const {call, isLoading} = useHttp();
  const {callSheetInfo, setCallSheetInfo, callSheetId} = useContextJobCallSheet();
  const [time, setTime] = useState<Date>(new Date());
  const [contacts, setContacts] = useState<tCallSHeetGroupContacts["contacts"]>(props.data.contacts); // prettier-ignore

  const [radioControl, setRadioControl] = useState({
    time: false,
    email: false,
    location: false,
    phone: false
  });

  const formData = {
    email: useRadio({fields: emailFields, isRequired: false}),
    phone: useRadio({fields: phoneFields, isRequired: false}),
    location: useSelect()
  };

  const onChangeControl = (event: ChangeEvent<HTMLInputElement>) => {
    const key = event.target.name as keyof typeof formData | "time";

    if (key === "time") {
      setRadioControl({...radioControl, [event.target.name]: event.target.checked});
      return;
    }

    if (!key) return;

    formData[key].setIsRequired(event.target.checked);

    if (!event.target.checked) {
      formData[key].setErrors([]);
      formData[key].setValue("");
    }

    setRadioControl({...radioControl, [event.target.name]: event.target.checked});
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (notValidForm(formData) || !callSheetId) return;
    const {email, phone, location} = radioControl;

    function findLocationDetails(id: string) {
      if (!callSheetInfo?.attributes?.locations) return console.log("Locations were not found.");

      const location = callSheetInfo.attributes.locations.find(item => String(item.location_id) === String(id)); // prettier-ignore

      if (!location) return console.log("Location was not found.");
      return location;
    }

    function getTime({hours, minutes, zone}: any) {
      return `${hours}:${minutes} ${zone.toUpperCase()}`;
    }

    const contactsPostData = contacts.map(contact => {
      const emailValue = emailValues[formData.email.value as keyof typeof emailValues];
      const phoneValue = phoneValues[formData.phone.value as keyof typeof phoneValues];

      const contactSelectedProp = props.data.contacts.find(
        item => String(item.id) === String(contact.id)
      );

      if (!contact.selected) return {...contact, selected: contactSelectedProp?.selected || true};
      // console.log({
      //   location,
      //   value: formData.location.value,
      //   contactLocation: contact.location,
      //   curr: findLocationDetails(formData.location.value)
      // });
      return {
        ...contact,
        ...{
          email: contact.email
            ? {...contact.email, show: email ? emailValue : contact?.email?.show}
            : null
        },
        ...{
          phone: contact.phone
            ? {...contact.phone, show: phone ? phoneValue : contact?.phone?.show}
            : null
        },
        location: location ? findLocationDetails(formData.location.value) : contact.location,
        time: radioControl?.time ? getTime(time) : contact.time,
        selected: contactSelectedProp?.selected || true
      };
    });

    let postData = {
      ...callSheetInfo,
      attributes: {
        ...callSheetInfo?.attributes,
        groups: callSheetInfo?.attributes?.groups?.map(
          item =>
            String(item.id) === String(props.data.id)
            ? {
                id: props.data.id,
                hidden: item.hidden,
                name: props.data.name,
                contacts: contactsPostData
              }
            : item // prettier-ignore
        )
      }
    };

    try {
      const {data: {data, message}} = await call(callSheetApi.updateCallSheet(+callSheetId, postData as unknown as any)); // prettier-ignore
      setCallSheetInfo(data);
      eventBus.dispatch("showToast", {text: message});
      props.onClose();
    } catch (e: any) {
      const {msg} = apiErrorHandler(e);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const onChangeTime = (time: any) => setTime(time);

  const onSelectContact = (event: ChangeEvent<HTMLInputElement>, id: number | string) => {
    setContacts(prev => {
      return prev.map(contact => {
        return String(contact.id) === String(id)
          ? {...contact, selected: !contact.selected}
          : contact;
      });
    });
  };

  const onCheckAll = (event: ChangeEvent<HTMLInputElement>) => {
    setContacts(prev => prev.map(contact => ({...contact, selected: event.target.checked})));
  };

  return {
    formData,
    onChangeControl,
    radioControl,
    handleSubmit,
    time,
    onChangeTime,
    onSelectContact,
    onCheckAll,
    contacts,
    isLoading
  };
}

const emailFields = [
  {name: "show_email", value: "show_email", label: "Show"},
  {name: "hide_email", value: "hide_email", label: "Hide"}
];

const phoneFields = [
  {name: "show_phone", value: "show_phone", label: "Show"},
  {name: "hide_phone", value: "hide_phone", label: "Hide"}
];

const emailValues = {
  show_email: true,
  hide_email: false
};

const phoneValues = {
  show_phone: true,
  hide_phone: false
};
