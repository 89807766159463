import {FC, useEffect} from "react";
import {TagButton} from "components/shared/TagButton/TagButton";
import {getName} from "helpers/misc";
import {ReactSelect} from "components/shared/FormComponents/ReactSelect/ReactSelect";
import {companies as companiesApi} from "api/contacts/companies";
import {useHttp} from "hooks/httpServices/useHttp";
import {iJobArtisFieldRow} from "./types";
import {iPeopleListItemReturnData} from "api/contacts/people/interfaces";
import "./JobArtistItem.scss";

interface iJobArtistItemProps extends iJobArtisFieldRow {
  onRemove: (contactRowId: string) => void;
  handleChange: <T>(value: T, key: string, contactRowId: string) => void;
  titles?: Array<any>;
}

export const JobArtistItem: FC<iJobArtistItemProps> = props => {
  const {call, isLoading, data} = useHttp();
  const {person, title, uid, titles} = props;
  const {agency, agent, handleChange} = props;

  const agentData: iPeopleListItemReturnData[] = data?.data?.data || [];

  useEffect(() => {
    if (agency?.id) call(companiesApi.getCompanyContacts(agency.id));
  }, [agency?.id]); // eslint-disable-line

  const handleChangeAgency = (agency: any) => {
    handleChange(null, "agent", uid);
    handleChange(agency, "agency", uid);
  };

  const agentOptions = agentData?.filter(item => item?.id !== person?.id) || [];
  const isEmptyAgents = agentOptions.length === 0 || !agentOptions;

  return (
    <>
      <div className="JobArtistItem">
        <TagButton
          buttonProps={{
            type: "button"
          }}
          onRemove={() => props?.onRemove(props.uid)}
        >
          {getName(person)}
        </TagButton>
        <div className={!!title?.errors?.length ? "JobArtistItem-item error" : ""}>
          <label className="label">Title</label>
          <ReactSelect
            value={title}
            name="title"
            onChange={title => handleChange(title, "title", uid)}
            getOptionLabel={option => option.name}
            options={titles}
            placeholder={!titles?.length && "Doesn't have titles"}
            isDisabled={!titles?.length}
          />
          {!!title?.errors &&
            title?.errors?.map((error: string) => (
              <span className="JobArtistItem-error" key={error}>
                {error}
              </span>
            ))}
        </div>
        <div>
          <label className="label">Artist Agency</label>
          <ReactSelect
            isClearable
            value={agency}
            name="agency"
            onChange={agency => handleChangeAgency(agency)}
            getOptionLabel={option => option.name}
            options={person?.agencies}
            placeholder={!person?.agencies?.length ? "Doesn't have agency" : "Select..."}
            isDisabled={!person?.agencies?.length}
          />
        </div>
        <div>
          <label className="label">Agent</label>
          <ReactSelect
            isClearable
            value={agent?.id ? agent : null}
            name="agent"
            onChange={agent => handleChange(agent, "agent", uid)}
            getOptionLabel={option => `${option.first_name} ${option.last_name}`}
            options={agentOptions}
            isDisabled={isEmptyAgents}
            placeholder={!!agentOptions?.length ? "Select..." : "No agents found"}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};
