import {FC, forwardRef} from "react";
import DatePicker, {ReactDatePickerProps} from "react-datepicker";
import {Icon} from "components/Icon/Icon";

import "react-datepicker/dist/react-datepicker.css";
import "./DatepickerRangeSingle.scss";

type tValue = [Date | null, Date | null];

interface iDatepickerRangeSingleProps
  extends Partial<Omit<ReactDatePickerProps, "value" | "onChange">> {
  onChange: (date: tValue) => void;
  value: tValue;
  label?: string;
  className?: string;
  errors?: string[];
  dateFormat?: string;
  withIcon?: boolean;
  handleCalendarClose?: () => void;
}

export const DatepickerRangeSingle: FC<iDatepickerRangeSingleProps> = ({
  errors = [],
  className = "",
  label = "",
  withIcon,
  value,
  onChange,
  handleCalendarClose,
  ...props
}) => {
  if (!onChange) return null;

  const isError = !!errors.length ? "error" : "";
  const InputWithIcon = forwardRef((props: any, ref: any) => (
    <div ref={ref} className="DatepickerRangeSingle-inputWrap wrap" onClick={props.onClick}>
      {withIcon && <Icon icon="calendar" color="dark-gray" />}
      <input type="text" {...props} readOnly />
    </div>
  ));

  const handleChange = (date: tValue) => onChange(date);

  return (
    <div className={`DatepickerRangeSingle ${className} ${withIcon ? "withIcon" : ""}`}>
      {label && <span className={`DatepickerRangeSingle-label label ${isError}`}>{label}</span>}

      <DatePicker
        {...props}
        selectsRange={true}
        startDate={value[0]}
        endDate={value[1]}
        className={`DatepickerRangeSingle-input input ${isError}`}
        onChange={date => handleChange(date)}
        maxDate={new Date("02-29-2099")}
        customInput={<InputWithIcon />}
        onCalendarClose={handleCalendarClose}
      />

      {errors.map((error, i) => (
        <span key={i} className="DatepickerRangeSingle-error-message error-message">
          {error}
        </span>
      ))}
    </div>
  );
};
