import {tFullForm} from "./useCompanyForm";
import {iAddCompany} from "api/contacts/companies/interfaces";
import {iPayloadAddress, iPayloadBillingInfo} from "../../interfaces";
import {tDynamicFieldRowObj} from "components/shared/FormComponents/DynamicFields/types";

export function generateFormData(fullForm: tFullForm): Partial<iAddCompany> {
  const {mainFormData, addressFormData, otherFormData} = fullForm;

  //mainFormData
  const {name, locales, labels, phones, emails, accounts, website} = mainFormData.formData;
  const {companyPhoto} = mainFormData;
  //addressFormData
  const {address, billing_info} = addressFormData.formData;
  //otherFormData
  const {departments, notes} = otherFormData.formData;

  const isValue = (list: tDynamicFieldRowObj[]) => {
    if (list?.length === 1) return true;
    return list
      ?.map(item => ({platform: item.id || 0, value: item.value || ""}))
      .filter(item => !!item.platform && !!item.value)?.length;
  };

  const billingInfo: Partial<iPayloadBillingInfo> = {
    first_name: billing_info.first_name.value,
    last_name: billing_info.last_name.value,
    email: billing_info.email.value,
    phone: billing_info.phone?.value,
    address: {
      country_iso: billing_info.address.address.country_iso,
      post_code: billing_info.address.address.post_code,
      state: billing_info.address.address.state,
      street: billing_info.address.address.street,
      city: billing_info.address.address.city,
      apartment: billing_info.address.address.apartment
    }
  };
  const companyAddress: Partial<iPayloadAddress> = {
    country_iso: address.address.country_iso,
    post_code: address.address.post_code,
    state: address.address.state,
    street: address.address.street,
    city: address.address.city,
    apartment: address.address.apartment
  };
  const websiteValue = website.value.replace(/^https?:\/\//, "");
  return {
    //main form
    ...(!companyPhoto.includes("http") && companyPhoto && {avatar: companyPhoto}),
    name: name.value,
    website: website.value,
    ...(websiteValue && {website: website.value}),
    ...(!!isValue(phones?.value) && {
      phones: phones?.value
        .map(item => ({
          ...(typeof item?.uid === "number" && {id: item.uid}),
          value: item?.value || "",
          type_id: item?.id ? +item?.id : undefined,
          type_custom: item.type_custom || ""
        }))
        .filter(item => !!item?.value)
    }),
    ...(!!isValue(emails.value) && {
      emails: emails.value
        .map(item => ({
          ...(typeof item?.uid === "number" && {id: item.uid}),
          value: item.value || "",
          type_id: item.id || undefined,
          type_custom: item.type_custom || ""
        }))
        .filter(item => !!item?.value)
    }),
    locales: locales.value.map(({id}) => +id),
    labels: labels.value.map(({id}) => +id),
    ...(!!isValue(accounts.value) && {
      accounts: accounts.value
        .map(item => ({
          ...(typeof item?.uid === "number" && {id: item.uid}),
          value: item.value || "",
          platform: item.id || undefined
        }))
        .filter(item => !!item?.value)
    }),

    //address & billing
    billing_info: billingInfo,
    address: companyAddress,
    // ...(!!Object.values(billingInfo).length && {billing_info: billingInfo}),
    // ...(!!Object.values(companyAddress).length && {address: companyAddress}),

    //other form
    departments: departments.selectedCheckBoxes.current.map(item => +item),
    notes: notes.value
  };
}
