import {FC, useState} from "react";
import {ManageTags} from "../JobsModals/ManageTags/ManageTags";
import {ManageTagsForm} from "../JobsModals/ManageTagsForm/ManageTagsForm";
import {tags as tagsApi} from "api/tags";

interface iJobSettingsTagsProps {
  onClose: (arg: null) => void;
  type: "label" | "locale" | null;
}

type tModal = "label" | "locale";

export const JobSettingsTags: FC<iJobSettingsTagsProps> = ({onClose, type}) => {
  const [addModal, setAddModal] = useState<tModal | null>(null);

  const onCloseModal = () => onClose(null);
  const closeAddModal = () => setAddModal(null);

  const configs = {
    label: {
      get: tagsApi.labels.get,
      add: tagsApi.labels.add,
      update: tagsApi.labels.update,
      delete: tagsApi.labels.delete
    },
    locale: {
      get: tagsApi.locales.get,
      add: tagsApi.locales.add,
      update: tagsApi.locales.update,
      delete: tagsApi.locales.delete
    }
  };

  return (
    <div className="JobSettingsTags">
      {type && (
        <ManageTags
          show={Boolean(type)}
          config={configs[type]}
          type={type}
          onClose={onCloseModal}
        />
      )}

      {addModal && (
        <ManageTagsForm
          show={Boolean(addModal)}
          title={`Add ${addModal}`}
          placeholder={`${addModal} Name`}
          onClose={closeAddModal}
          addConfig={configs[addModal].add}
          updateConfig={configs[addModal].update}
        />
      )}
    </div>
  );
};
