import {useState, CSSProperties, useEffect} from "react";

type tPositionY = "top" | "center" | "bottom";
type tPositionX = "left" | "center" | "right";

interface iUseFixedElementPosition {
  anchor: HTMLElement | null;
  anchorOrigin?: {vertical?: tPositionY; horizontal?: tPositionX};
  transformOrigin?: {vertical?: tPositionY; horizontal?: tPositionX};
}

function useFixedElementPosition({
  anchor,
  anchorOrigin = {vertical: "bottom", horizontal: "right"},
  transformOrigin = {vertical: "bottom", horizontal: "left"}
}: iUseFixedElementPosition) {
  const [bodyRef, setBodyRef] = useState<HTMLDivElement | null>(null);
  const [menuPosition, setMenuPosition] = useState<CSSProperties>({});

  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const transformX = transformOrigin.horizontal as tPositionX;
  const transformY = transformOrigin.vertical as tPositionY;
  const anchorX = anchorOrigin.horizontal as tPositionX;
  const anchorY = anchorOrigin.vertical as tPositionY;

  useEffect(() => {
    if (!anchor || !bodyRef) {
      setMenuPosition({});
      return;
    }

    let anchorPosition = anchor.getBoundingClientRect();
    let elementPosition = bodyRef.getBoundingClientRect();

    const anchorXProp = {
      center: anchorPosition.left + anchorPosition.width / 2,
      left: anchorPosition.left,
      right: anchorPosition.right
    };

    const anchorYProp = {
      top: anchorPosition.top,
      center: anchorPosition.top + anchorPosition.height / 2,
      bottom: anchorPosition.bottom
    };

    function getHorizontal(xPosAnchor: tPositionX) {
      let left = anchorXProp[xPosAnchor];
      if (transformX === "left") left = left - elementPosition.width;
      if (transformX === "center") left = left - elementPosition.width / 2;
      return left;
    }

    function getVertical(yPosAnchor: tPositionY) {
      let top = anchorYProp[yPosAnchor];
      if (transformY === "top") top = top - elementPosition.height;
      if (transformY === "center") top = top - elementPosition.height / 2;
      return top;
    }

    function preparePosition(source: CSSProperties): CSSProperties {
      return Object.entries(source).reduce((prev, [key, value]) => {
        if (!value) return prev;
        return {...prev, [key]: value + "px"};
      }, {});
    }

    const position: {left: number; top: number; bottom?: number} = {
      left: getHorizontal(anchorX),
      top: getVertical(anchorY)
    };

    if (position.left + elementPosition.width > screenWidth) {
      position.left = anchorPosition.right - elementPosition.width;
    }

    if (position.left < 0) {
      position.left = anchorPosition.left;
    }

    // if dropdown menu over top window
    if (position?.top < 0) {
      position.top = 15;
      setMenuPosition(preparePosition(position));
      return;
    }

    if (position.top + elementPosition.height > screenHeight) {
      position.top = 0;
      position.bottom = 15;
      setMenuPosition(preparePosition(position));
      return;
    }

    setMenuPosition(preparePosition(position));
  }, [anchor, bodyRef, screenWidth, screenHeight]); // eslint-disable-line

  return {
    setBodyRef,
    menuPosition
  };
}

export {useFixedElementPosition};
export type {iUseFixedElementPosition, tPositionY, tPositionX};
