import {useEffect, FC, ReactElement} from "react";
import {Portal} from "components/shared/Portal/Portal";
import {useModal} from "./useModal";
import {Icon} from "components/Icon/Icon";
import {iModalPopUpProps} from "./ModalInterfaces";
import "./ModalPopup.scss";

/**
 * Modal pop up component for most of content
 * @controller @controller @controller**useModal** provides all necessary data and functionality to operate data for current component
 * @returns @returns @returns{React.ReactElement} React.ReactElement
 */
export const ModalPopup: FC<iModalPopUpProps> = ({
  children,
  onClose,
  show,
  className = "",
  containerClass = "",
  persistOnScreen,
  showCloseButton = true,
  size = "md",
  withoutPaddings
}): ReactElement | null => {
  const {modalContent, handleClose, handleKeyPress, closeFocusInModal, setPersist} = useModal({
    show,
    onClose,
    persistOnScreen
  });

  useEffect(() => {
    if (show) {
      setTimeout(() => modalContent?.current?.focus(), 10);
    }
  }, [show]); // eslint-disable-line

  useEffect(() => setPersist(!!persistOnScreen), [persistOnScreen]); // eslint-disable-line

  if (!show) return null;

  return (
    <Portal className={`ModalPopUp-portal portal ${className} `}>
      <div tabIndex={0} onFocus={closeFocusInModal} />
      <div
        className="ModalPopUp-backdrop backdrop"
        role="dialog"
        aria-modal="true"
        onKeyDown={handleKeyPress}
        onClick={handleClose}
        data-testid="ModalPopUp-wrapper"
      />

      <div className={`ModalPopUp-container container ${size} ${containerClass}`}>
        {showCloseButton && (
          <button
            aria-label="Close modal pop up"
            className="ModalPopUp-close"
            onClick={handleClose}
            data-testid="modal-close-btn"
          >
            <Icon icon="close" color="light-gray" size="lg" />
          </button>
        )}
        <div
          tabIndex={0}
          ref={modalContent}
          className={`ModalPopUp-content content  ${withoutPaddings ? "withoutPaddings" : ""}`}
        >
          {children}
        </div>
      </div>
      <div tabIndex={0} onFocus={closeFocusInModal} />
    </Portal>
  );
};
