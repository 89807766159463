import {CSSProperties, FC, HTMLAttributes, MouseEvent} from "react";
import {Portal} from "components/shared/Portal/Portal";
import {useFixedElementPosition, iUseFixedElementPosition} from "hooks/useFixedElementPosition";

import "./Popover.scss";

export interface iPopover extends iUseFixedElementPosition {
  className?: string;
  onClose: (event: MouseEvent<HTMLElement>) => void;
  bodyProps?: HTMLAttributes<HTMLDivElement>;
  backdropProps?: HTMLAttributes<HTMLDivElement>;
  customPosition?: {bottom?: string; top?: string; left?: string; right?: string};
  bodyStyles?: CSSProperties;
  withArror?: boolean;
  withPadding?: boolean;
  arrowPosition?: any;
}

export const Popover: FC<iPopover> = ({
  children,
  onClose,
  anchor,
  className = "",
  anchorOrigin = {vertical: "bottom", horizontal: "right"},
  transformOrigin = {vertical: "bottom", horizontal: "left"},
  backdropProps,
  customPosition,
  bodyProps,
  bodyStyles,
  withPadding
}) => {
  const {setBodyRef, menuPosition} = useFixedElementPosition({
    anchorOrigin,
    transformOrigin,
    anchor
  });

  const isShow = Boolean(anchor);
  if (!isShow) return null;

  const customPos = customPosition ? customPosition : menuPosition;
  // const withPaddings = withPadding ? "withPaddings" : "";
  return (
    <Portal className={`Popover ${className}`}>
      <div className="Popover-backdrop backdrop" onClick={onClose} {...backdropProps} />

      <div
        className="Popover-body body"
        ref={newRef => setBodyRef(newRef)}
        style={{...bodyStyles, ...customPos}}
        {...bodyProps}
      >
        {children}
      </div>
    </Portal>
  );
};

/*
state:
const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
  setMenuAnchor(event.currentTarget);
};


component:
<Button onClick={showMenu} size="md" color="outline">
  <Icon icon="menu" size="xsm" />
</Button>

<DropdownMenu anchor={menuAnchor} onClose={() => setMenuAnchor(null)}>
  <Button size="sm">Duplicate job</Button>
  <Button size="sm">Save as template</Button>
  <Button size="sm">Delete</Button>
</DropdownMenu>
 */
