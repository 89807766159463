import {FC} from "react";
import "./ModalInfoSectionWrap.scss";

type tModalInfoSectionWrapProps = {
  [key: string]: any;
};

export const ModalInfoSectionWrap: FC<tModalInfoSectionWrapProps> = ({children}) => {
  return <div className="ModalInfoSectionWrap">{children}</div>;
};
