import {FC, FormEvent} from "react";
import {FormWrap} from "components/shared/FormWrap/FormWrap";
import {Select} from "components/shared/FormComponents/Select/Select";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {notValidForm} from "helpers/misc";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextTrack} from "../../../_context/TrackContext";
import {useCreditCardForm} from "./useCreditCardForm";
import {creditCardAndPayroll} from "api/budget/creditCardAndPayroll";
import {tCreditCard} from "api/budget/creditCardAndPayroll/types";
import {updatePayIdData} from "../updatePayIdData";

type tEditCardFormProps = {
  goBack: () => void;
  onClose: () => void;
  cardData?: tCreditCard;
  cardsList: any;
};

export const EditCardForm: FC<tEditCardFormProps> = ({goBack, onClose, cardData, cardsList}) => {
  const {lists: {payIds: {setPayIdList}}} = useContextTrack(); // prettier-ignore
  const {call, isLoading} = useHttp();
  const formId = "track-edit-credit-card";

  const {form} = useCreditCardForm({cardData, cardsList});

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (notValidForm(form)) return;

    try {
      const data = {
        holder_initials: form?.initials?.value,
        last_four_digits: form?.digits?.value,
        type_id: +form?.type?.value
      };

      let res: any;

      if (!cardData) {
        res = await call(creditCardAndPayroll?.addCreditCard(data));
      }

      if (cardData && cardData?.id) {
        res = await call(creditCardAndPayroll?.updateCreditCard(cardData?.id, data));
      }

      setPayIdList(prev =>
        updatePayIdData({
          data: prev,
          newItem: res?.data?.data,
          updatedKey: "credit_card",
          updateType: cardData ? "update" : "add"
        })
      );

      goBack();
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <FormWrap
      headerTitle={cardData ? "Edit CREDIT CARD" : "Add CREDIT CARD"}
      idForm={formId}
      type="titleCenterAndTwoBtn"
      mainButtonColor="outline"
      submitTitle={"Save"}
      cancelTitle="Cancel"
      onCancel={onClose}
      onBack={goBack}
      withBackIcon
      isLoading={isLoading}
    >
      <form id={formId} onSubmit={onSubmit}>
        <Select label="type" {...form.type.inputProps} canSelectEmpty errors={form.type.errors} />
        <InputField
          label="Owner’s INITIALs"
          {...form.initials.inputProps}
          errors={form.initials.errors}
        />
        <InputField label="Last 4 Digits" {...form.digits.inputProps} errors={form.digits.errors} />
      </form>
    </FormWrap>
  );
};
