import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {useContextJobCrew} from "../../JobCrewContext";
import {CallActionBtn} from "./CallActionBtn";

export const SecondaryHeader = () => {
  const {closeSecondaryHeader, selectedContacts} = useContextJobCrew();

  const selected = selectedContacts?.length ? selectedContacts?.length : 0;
  return (
    <>
      <TextComponent uppercase className="title">
        Select Contacts
      </TextComponent>

      {!!selected && (
        <TextComponent color="gray-10" size="13" weight="400">
          {selected} selected
        </TextComponent>
      )}

      <CallActionBtn type="move" className="moveToBtn">
        Move to
      </CallActionBtn>

      <CallActionBtn type="remove">Remove</CallActionBtn>

      <Button className="closeSecondaryHeaderBtn" onClick={closeSecondaryHeader}>
        <Icon icon="close" color="gray" size="md" />
      </Button>
    </>
  );
};
