import {FC} from "react";
import {iAddress} from "api/common/types";

interface iAddressProps {
  address: iAddress;
  withCountry?: boolean;
}

export const Address: FC<iAddressProps> = ({address, withCountry = false}) => {
  if (!address) return null;
  const {street, city, state, post_code, country, raw} = address;

  if (!street && !city && !country) {
    return <span>{raw}</span>;
  }

  return (
    <>
      {street || ""} <br />
      {city ? city + ", " : ""} {state ? state + ", " : ""}
      {post_code ? post_code : ""}
      {withCountry ? ", " + country?.nicename : ""}
    </>
  );
};
