import {ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useRef, useState} from "react";
import moment from "moment";
import {SchedulerActions} from "./SchedulerActions/SchedulerActions";
import {JobSchedulerControls} from "./JobSchedulerControls";
import {JobSchedulerFilters} from "./JobSchedulerFilters";
import {AgendaView} from "./AgendaView/AgendaView";
import {tEventModal} from "../helpers";
import {jobSchedulerInit} from "./schedulerInitHelpers/jobSchedulerInit";
import {jobSchedulerTemplate} from "./schedulerInitHelpers/jobSchedulerTemplate";
import {Icon} from "components/Icon/Icon";
import {JobCalendarBody} from "./JobCalendarBody";
import {Loading} from "components/shared/Loading/Loading";
import {eventBus} from "EventBus/EventBus";
import {useContextJobCalendar} from "../context";

interface iJobSchedulerComponentProps {
  events: any;
  handleEmptyClick: (date: Date) => void;
  handleBeforeEventChange: (
    event: tEventModal,
    e: ChangeEvent<HTMLInputElement>,
    is_new: boolean,
    original: any,
    all_day: boolean
  ) => void;
  onShowEventDetails: (id: string, events: tEventModal[]) => void;
  clearEventId: number | null;
  setClearEventId: (id: number | null) => void;
  addEvent: tEventModal;
  setEvents: Dispatch<SetStateAction<tEventModal[]>>;
}

type tExportType = "download" | "saveToDropbox";

export const JobSchedulerComponent: FC<iJobSchedulerComponentProps> = ({
  events,
  handleEmptyClick,
  handleBeforeEventChange,
  onShowEventDetails,
  clearEventId,
  setClearEventId,
  addEvent,
  setEvents
}) => {
  const initMode = "month";
  // @ts-ignore
  const scheduler = window.scheduler;
  const {mode, date} = scheduler?.getState();
  const {activeCalendar} = useContextJobCalendar();
  let schedulerRef = useRef<HTMLDivElement>(null);
  const [headerTitle, setHeaderTitle] = useState("");
  const [modeState, setModeState] = useState<any>(initMode);
  const [isLoading, setIsLoading] = useState<tExportType | null>(null);

  const isLocked = activeCalendar?.locked;

  useEffect(() => {
    setModeState(mode);
    const layout = document.querySelector(".JobsDetailsLayout-body") as HTMLElement;
    const schedulerElement = document.querySelector(".JobScheduler-calendar") as HTMLElement;
    const dataBody = document.querySelector(".JobScheduler .dhx_cal_data") as HTMLElement;
    const data = document.querySelector(".JobScheduler-data") as HTMLElement;
    dataBody.style.overflowY = "visible";

    if (mode === "week") {
      layout.style.overflow = "hidden";
      schedulerElement.style.maxWidth = `calc(100% - 52px)`;
      schedulerElement.style.right = -10 + "px";
      return;
    } else {
      layout.style.minHeight = "unset";
      dataBody.style.height = "100%";
      data.style.minHeight = "100%";
      layout.style.overflow = "auto";
      schedulerElement.style.maxWidth = `calc(100% - 64px)`;
      schedulerElement.style.right = "auto";
      scheduler._$initialized && scheduler?.updateView();

      return;
    }
  }, [mode]); // eslint-disable-line

  useEffect(() => {
    if (mode !== "agenda") return;
    const currentDateParam = moment(date).startOf("month");
    setHeaderTitle(moment(currentDateParam).format("MMMM YYYY"));
  }, [mode, date]);

  useEffect(() => {
    if (scheduler._$initialized) {
      scheduler._$initialized = false;
      scheduler.checkEvent("onEmptyClick") && scheduler.detachAllEvents();
    }
    initSchedulerEvents(isLocked);
  }, [events, addEvent, isLocked]); // eslint-disable-line

  // clear uncreated event
  useEffect(() => {
    if (!scheduler || !clearEventId) return;
    scheduler.deleteEvent(clearEventId);
    setClearEventId(null);
  }, [scheduler, clearEventId, setClearEventId]);

  const initSchedulerEvents = (isLocked?: boolean) => {
    if (scheduler._$initialized) return;
    scheduler.attachEvent(
      "onBeforeEventChanged",
      (event: tEventModal, e: ChangeEvent<HTMLInputElement>, is_new: boolean, original: any) => {
        if (event.type === "holds" || event.type === "tasks") return false;
        if (isLocked) {
          eventBus.dispatch("showToast", {type: "error", text: "Calendar is locked!"});
          return false;
        }
        const isValidStartDate = moment(event.start_date).diff(moment(), "minutes") > 0;
        if (!isLocked && isValidStartDate) {
          const all_day = !!Object.keys(original).length;
          handleBeforeEventChange(event, e, is_new, original, all_day);
          return true;
        }
        eventBus.dispatch("showToast", {
          type: "error",
          text: "The event start time must be after the current time."
        });
        return false;
      }
    );

    scheduler.attachEvent("onEmptyClick", (date: Date) => {
      const isValidStartDate = moment(date).diff(moment().startOf("day"), "minutes") >= 0;

      if (isLocked) return false;
      if (!isLocked && isValidStartDate) {
        handleEmptyClick(date);
        return true;
      }
    });

    scheduler.attachEvent("onBeforeLightbox", () => false);
    scheduler.attachEvent("onDblClick", () => false);
    scheduler.attachEvent("onClick", (id: string) => {
      onShowEventDetails(id, events);
      return true;
    });

    scheduler._$initialized = true;
  };

  useEffect(() => {
    jobSchedulerInit(scheduler, schedulerRef, setHeaderTitle, initMode);
  }, []); // eslint-disable-line

  useEffect(() => {
    jobSchedulerTemplate(scheduler, events, initMode);
  }, [events]); // eslint-disable-line

  const isAgendaView = modeState === "agenda";

  return (
    <>
      <div key="dhx-wrap" className={`JobScheduler ${modeState} `}>
        {isLoading && <Loading className="JobScheduler-loader" type="inBlock" />}
        <div className="JobScheduler-toolbarRow ActionsRow">
          <h2 className="h2 JobScheduler-title">{headerTitle}</h2>
          <SchedulerActions
            setEvents={setEvents}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </div>

        {isLocked && (
          <div className="JobScheduler-toolbarRow Locked">
            <div className="LockedWarning">
              <Icon icon="lock" color="red" size="sm" />
              <p className="LockedWarning-text">This calendar is locked</p>
            </div>
            <div className="LockedAdvice">
              <p>To edit events, click “UNLOCK” from the calendar menu.</p>
            </div>
          </div>
        )}

        <div className="JobScheduler-toolbarRow ControlRow">
          <JobSchedulerControls scheduler={scheduler} setModeState={setModeState} />
          <JobSchedulerFilters />
        </div>

        {isAgendaView && <AgendaView onShowEventDetails={onShowEventDetails} events={events} />}

        <div
          id="JobSchedulerContainer"
          className={`JobScheduler-data ${isAgendaView ? "hide" : ""}`}
        >
          <JobCalendarBody
            id="JobScheduler-calendar"
            className="JobScheduler-calendar dhx_cal_container"
            schedulerRef={schedulerRef}
          />
        </div>
      </div>
    </>
  );
};
