import {FC, createContext, useContext} from "react";
import {useBasicData} from "./useBasicData";

type tBasicDataContext = ReturnType<typeof useBasicData>;
const BasicDataContext = createContext<tBasicDataContext | null>(null);
BasicDataContext.displayName = "BasicDataContext";
export const BasicDataContextProvider: FC = ({children}) => (
  <BasicDataContext.Provider value={useBasicData()}>{children}</BasicDataContext.Provider>
);

/**
 * Provides all profile information
 * @returns (get and update profile info + logout)
 */
export const useContextBasicData = () => {
  const context = useContext(BasicDataContext);

  if (context === null) {
    throw new Error("<<< BasicDataContext >>> must be used within a BasicDataContextProvider");
  }

  return context as tBasicDataContext;
};
