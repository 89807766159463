import {FC} from "react";
import {ImgWithFallback} from "components/shared/ImgWithFallback/ImgWithFallback";
import {TextComponent} from "../../common/TextComponent/TextComponent";
import crew from "assets/images/CrewGray.svg";
import {useContextJobCrew} from "../JobCrewContext";
import {Loading} from "components/shared/Loading/Loading";
import "./StartBlank.scss";

type tStartBlankProps = {
  [key: string]: any;
};

export const StartBlank: FC<tStartBlankProps> = () => {
  const {jobCrewGroupList: {isLoading}} = useContextJobCrew(); // prettier-ignore
  return (
    <div className="StartBlank">
      {isLoading && <Loading type="inBlock" />}

      <ImgWithFallback src={crew} fallBackType="avatar" />
      <TextComponent color="gray-10" weight="300" size="20">
        Crew members will appear here
      </TextComponent>
    </div>
  );
};
