import {FC} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {useHttp} from "hooks/httpServices/useHttp";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {eventBus} from "EventBus/EventBus";
import {notValidForm} from "helpers/misc";
import {useDatePicker} from "components/shared/FormComponents/Datepicker/useDatePicker";
import {Datepicker} from "components/shared/FormComponents/Datepicker/Datepicker";
import moment from "moment";
import {estimateApi} from "api/budget/estimate";
import {useContextJobBudget} from "pages/Jobs/JobDetails/JobBudget/context/JobBudgetContext";
import {getDateFormat} from "helpers/dates";
import {tEstimateData} from "api/budget/estimate/types";
import "./ApproveEstimateForm.scss";

type tApproveEstimateFormProps = {
  onClose: () => void;
};

export const ApproveEstimateForm: FC<tApproveEstimateFormProps> = ({onClose}) => {
  const {estimate, setEstimate, emptyCostFilter, getEstimateList} = useContextJobBudget();
  const {call, isLoading} = useHttp();

  const form = {
    date: useDatePicker({initValue: moment().toISOString(), isRequired: true})
  };

  const onConfirm = async () => {
    if (notValidForm(form) || !estimate?.id) return;

    try {
      const res = await call(
        estimateApi.estimateApprove(estimate.id, {
          approved_at: getDateFormat(form.date.value, "timestamp")
        })
      );
      getEstimateList();
      onClose();
      const currEstimate = res?.data?.data as tEstimateData;
      setEstimate(() => emptyCostFilter(currEstimate));
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <ConfirmWindow
      show={true}
      title="Mark as approved?"
      subTitle="Enter the estimate approval date"
      onClose={onClose}
      className="ApproveEstimateForm"
      onConfirm={onConfirm}
      confirmBtn={{text: "Confirm"}}
      cancelBtn={{text: "Cancel"}}
      isLoading={isLoading}
    >
      <Datepicker
        {...form.date.inputProps}
        placeholderText="M/DD/YY"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        errors={form.date.errors}
        dateFormat="M/DD/YY"
        withIcon
        className="ApproveEstimateForm-date"
      />

      <TextComponent color="red" size="14" weight="400">
        This estimate will be locked from edits.
      </TextComponent>
    </ConfirmWindow>
  );
};
