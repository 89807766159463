import {ChangeEvent, FC, useEffect} from "react";
import {useRadio} from "hooks/useRadio/useRadio";
import {RadioField} from "../../FormComponents/RadioField/RadioField";
import {useSetApiParamsReturn} from "hooks/useSetApiParams";
import {CheckBoxGroupe} from "../../FormComponents/CheckBoxGroupe/CheckBoxGroupe";
import {useCheckBoxGroupe} from "hooks/inputHooks/useCheckBoxGroupe";
import {useContextProfile} from "context";

interface iSchedulerFiltersProps {
  apiParams: useSetApiParamsReturn;
}

const departmentsList = [
  // FIXME need to recheck !!!! maybe to get from BE
  {id: 1, name: "Production", avatar_url: null},
  {id: 2, name: "Post Production", avatar_url: null},
  {id: 3, name: "Equipment", avatar_url: null}
  // {id: 4, name: "Accounting", avatar_url: null},
  // {id: 5, name: "Office", avatar_url: null},
  // {id: 6, name: "Marketing", avatar_url: null},
];

export const SchedulerFilters: FC<iSchedulerFiltersProps> = props => {
  const {userData} = useContextProfile();
  const isPrivilegedUser = userData?.role?.name === "Admin" || userData?.role?.name === "User+";
  const {apiParams} = props;

  //assignee
  const assignedFilter = apiParams?.preparedParams?.assigned;
  const assignFilter = {
    showingFilter: useRadio({
      value: assignedFilter ? (assignedFilter === "true" ? "assigned" : "all") : "all",
      fields: [
        {label: "All", name: "showingFilter", value: "all"},
        {label: "Assigned Only", name: "showingFilter", value: "assigned"}
      ]
    })
  };

  useEffect(() => {
    const {value} = assignFilter.showingFilter;
    const assigned = !(!value || value === "all");

    const params = {assigned};
    apiParams.setApiParams({...Object.fromEntries(apiParams.searchParams), params});
  }, [assignFilter.showingFilter.value]); // eslint-disable-line

  //departments
  const initValueDepartments = apiParams?.preparedParams?.departments?.split(",");
  const departments = useCheckBoxGroupe({initValue: initValueDepartments});

  const onChangeCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, checked} = e.target;
    const prevParams = apiParams?.preparedParams?.departments?.split(",") || [];
    let newArrDep = prevParams;

    const isSelected = checked && value;
    isSelected && (newArrDep = [...prevParams, value]);
    !isSelected && (newArrDep = prevParams?.filter(item => item !== value));

    const params = {departments: newArrDep};
    apiParams.setApiParams({...Object.fromEntries(apiParams.searchParams), params});
  };

  return (
    <div id="scheduler-filters" className="SchedulerDashboard-filter">
      {isPrivilegedUser && (
        <div className="SchedulerDashboard-filter-row">
          <label className="SchedulerDashboard-filter-label">Show</label>
          <RadioField {...assignFilter.showingFilter} />
        </div>
      )}

      <CheckBoxGroupe
        customClass="SchedulerDashboard-filter-row"
        label="Depts"
        checkBoxList={departmentsList}
        initValue={departments.selectedCheckBoxes.current}
        onChange={onChangeCheck}
      />
    </div>
  );
};
