import React, {FC, ReactNode} from "react";
import InProgressImage from "assets/images/page-in-progress.png";
import "./UnderConstruction.scss";

interface iUnderConstructionProps {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
}

export const UnderConstruction: FC<iUnderConstructionProps> = ({
  title = "COMING SOON",
  subtitle = "The page is under construction.",
  children
}) => {
  return (
    <div className="UnderConstruction">
      <img src={InProgressImage} alt="under construction" />
      <h1 className="UnderConstruction-title">{title}</h1>
      <p className="UnderConstruction-info">{subtitle}</p>
      {children}
    </div>
  );
};
