import {FC} from "react";
import {Page, Document, StyleSheet, Font, Text, View} from "@react-pdf/renderer"; // @ts-ignore
import Helvetica700 from "assets/fonts/HelveticaNeue/HelveticaNeue-Bold.ttf"; // @ts-ignore
import Helvetica500 from "assets/fonts/HelveticaNeue/HelveticaNeue-Medium.ttf"; // @ts-ignore
import Helvetica400 from "assets/fonts/HelveticaNeue/HelveticaNeue.ttf";
import {Header} from "../../PdfTemplates/a4/Header";
import {Footer} from "../../PdfTemplates/a4/Footer";

import {iJobDetailsDataReturn} from "api/jobs/types";
import {tAdvanceDetails} from "api/budget/advance/types";
import {ClientSection} from "../../PdfTemplates/a4/ClientSection";
import {JobDescriptionSection} from "../../PdfTemplates/a4/JobDescriptionSection";
import {CreativeSection} from "../../PdfTemplates/a4/CreativeSection";
import {AdvanceInvoiceDetails} from "../../PdfTemplates/a4/AdvanceInvoiceDetails";
import {AdvanceDetails} from "../../PdfTemplates/a4/AdvanceDetails";
import {SectionTitle} from "../../PdfTemplates/a4/SectionTitle";

Font.register({
  family: "Helvetica500",
  src: Helvetica500
});
Font.register({
  family: "Helvetica400",
  src: Helvetica400
});
Font.register({
  family: "Helvetica700",
  src: Helvetica700
});

const styles = StyleSheet.create({
  container: {
    padding: 35,
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "space-between",
    width: "100%",
    height: "100%"
  },
  box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%"
  },
  fullWidthRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%"
    // marginBottom: 20
  },
  halfSection: {
    width: "48%",
    marginBottom: 20
  },
  termsText: {
    color: "#7E7E80",
    fontSize: "10px"
  },
  termsBox: {
    display: "flex",
    flex: "1 1 auto"
  },
  value: {fontSize: 10, fontFamily: "Helvetica400", marginBottom: 4},
  footer: {
    marginTop: "auto"
  }
});

interface tAdvanceInvoicePreviewDocumentProps {
  jobDetailsData: iJobDetailsDataReturn;
  advanceDetails: tAdvanceDetails;
}

export const AdvanceInvoicePreviewDocument: FC<tAdvanceInvoicePreviewDocumentProps> = ({
  advanceDetails,
  jobDetailsData
}) => {
  return (
    <Document>
      <Page style={styles.container} size="A4" orientation="portrait">
        <Header text="Advance Invoice" />
        <View style={styles.box}>
          <View style={styles.fullWidthRow}>
            <View style={styles.halfSection}>
              <ClientSection
                name={jobDetailsData?.client?.name || ""}
                address={jobDetailsData?.client?.address}
              />
            </View>
            <View style={styles.halfSection}>
              <AdvanceInvoiceDetails
                invoiceNumber={advanceDetails.invoice_number}
                date={advanceDetails.date}
                dueDate={advanceDetails.due_date}
                terms={advanceDetails.terms}
                advanceContact={advanceDetails?.advance_contact}
              />
            </View>
          </View>
          <View style={styles.fullWidthRow}>
            <View style={styles.halfSection}>
              <JobDescriptionSection
                po={advanceDetails.po}
                jobNumber={advanceDetails.job_number}
                jobName={advanceDetails.job_name}
                jobEndAt={advanceDetails.job_end_at}
                jobStartAt={advanceDetails.job_start_at}
                shootLocation={advanceDetails.shoot_location}
                photographer={advanceDetails.photographer}
                notes={advanceDetails.notes}
                label1={advanceDetails?.extended?.[0]?.label}
                label2={advanceDetails?.extended?.[1]?.label}
                text1={advanceDetails?.extended?.[0]?.text}
                text2={advanceDetails?.extended?.[1]?.text}
              />
            </View>
            <View style={styles.halfSection}>
              {advanceDetails.bank_detail && (
                <>
                  <SectionTitle>Bank</SectionTitle>
                  <Text style={styles.value}>{advanceDetails.bank_detail.details}</Text>
                </>
              )}
              <CreativeSection
                license={advanceDetails.usage_license}
                creative={advanceDetails.creative}
              />
            </View>
          </View>
          <View style={styles.fullWidthRow}>
            <AdvanceDetails data={advanceDetails} />
          </View>
        </View>
        <View style={styles.footer}>
          <Footer />
        </View>
      </Page>
      {/* {(advanceDetails.bank_detail || advanceDetails.term_condition) && (
        <Page style={styles.container} size="A4" orientation="portrait">
          <Header text="Advance Invoice" />
          <View style={styles.termsBox}>
            <View style={styles.fullWidthRow}>
              <View style={styles.halfSection}>
                {advanceDetails.term_condition && (
                  <>
                    <SectionTitle>Terms and conditions</SectionTitle>
                    <Text style={styles.termsText}>Please see following pages.</Text>
                  </>
                )}
              </View>
              <View style={styles.halfSection}>
                {advanceDetails.bank_detail && (
                  <>
                    <SectionTitle>Bank</SectionTitle>
                    <Text style={styles.value}>{advanceDetails.bank_detail.details}</Text>
                  </>
                )}
              </View>
            </View>
          </View>
          <Footer />
        </Page>
      )} */}
    </Document>
  );
};
