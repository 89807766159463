import {FC} from "react";
import {RowActions} from "./RowActions/RowActions";
import {RowName} from "./RowName/RowName";
import {tCrewContact} from "api/jobCrewGroup/types";
import {tContactPosition} from "api/jobCrewContactPosition/type";
import {RowPositions} from "./RowPositions/RowPositions";
import {RowStatus} from "./RowStatus/RowStatus";
import {tHoldStatus} from "api/common/types";
import {RowDates} from "./RowDates/RowDates";
import {RowBilling} from "./RowBilling/RowBilling";
import {RowPhones} from "./RowPhones/RowPhones";
import {RowEmails} from "./RowEmails/RowEmails";
import {RowDietary} from "./RowDietary/RowDietary";
import {RowNotes} from "./RowNotes/RowNotes";
import {useContextJobCrew} from "../../JobCrewContext";
import {RowRate} from "./RowRate/RowRate";

type tCrewTableRowRegularProps = {
  crewContact: tCrewContact;
  positionsList: Array<tContactPosition>;
  holdStatusList: Array<tHoldStatus>;
  groupId: number;
};

export const CrewTableRowRegular: FC<tCrewTableRowRegularProps> = ({
  crewContact,
  positionsList,
  holdStatusList,
  groupId
}) => {
  const {contact, agent} = crewContact;
  const hasAgent = !!agent ? "hasAgent" : "";
  const {showSelectContactIcon} = useContextJobCrew();
  const isSelecting = showSelectContactIcon ? "selecting" : "";

  return (
    <>
      <div className={`CrewTableCell ${hasAgent} CrewTableCell-firstRowItem ${isSelecting}`}>
        <RowName crewContact={crewContact} />
      </div>
      <div className={`CrewTableCell ${hasAgent}`}>
        <RowPositions crewContact={crewContact} positionsList={positionsList} groupId={groupId} />
      </div>
      <div className={`CrewTableCell ${hasAgent}`}>
        <RowStatus crewContact={crewContact} holdStatusList={holdStatusList} />
      </div>
      <div className={`CrewTableCell ${hasAgent}`}>
        <RowDates crewContact={crewContact} />
      </div>
      <div className={`CrewTableCell ${hasAgent} rowRate`}>
        <RowRate contact={contact} crewContact={crewContact} groupId={groupId} />
      </div>
      <div className={`CrewTableCell ${hasAgent}`}>
        <RowPhones crewContact={crewContact} groupId={groupId} />
      </div>
      <div className={`CrewTableCell ${hasAgent} emails`}>
        <RowEmails crewContact={crewContact} groupId={groupId} />
      </div>
      <div className={`CrewTableCell ${hasAgent}`}>
        <RowBilling crewContact={crewContact} groupId={groupId} />
      </div>
      <div className={`CrewTableCell ${hasAgent} rowNotes`}>
        <RowNotes crewContact={crewContact} groupId={groupId} />
      </div>
      <div className={`CrewTableCell ${hasAgent}`}>
        <RowDietary crewContact={crewContact} />
      </div>
      <div className={`CrewTableCell ${hasAgent}`}>
        <RowActions crewContact={crewContact} typeRow="contact" groupId={groupId} />
      </div>
    </>
  );
};
