import {ReactNode, forwardRef, MouseEvent, ButtonHTMLAttributes} from "react";
import {Icon} from "components/Icon/Icon";
import "./TagButton.scss";

export interface iTagButton {
  children: ReactNode;
  size?: "lg" | "md" | "sm";
  color?: "dark" | "neutral" | "light" | "outline";
  className?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onRemove?: (id?: number) => void;
  id?: number;
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
}

export const TagButton = forwardRef<HTMLButtonElement, iTagButton>(
  (
    {children, color = "neutral", size = "md", className = "", onClick, onRemove, id, buttonProps},
    ref
  ) => {
    return (
      <button
        ref={ref}
        type="button"
        className={`TagButton ${size} ${color} ${className}`}
        onClick={onClick}
        {...buttonProps}
      >
        <span className="TagButton-child">{children}</span>
        {onRemove && (
          <span className="TagButton-icon" onClick={() => onRemove(id)}>
            <Icon icon="close" color="black" size="sm" />
          </span>
        )}
      </button>
    );
  }
);
