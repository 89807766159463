import {useEffect, useState} from "react";
import {uid} from "helpers/misc";
import {useSearchField} from "hooks/useSearchField";
import {people as peopleApi} from "api/contacts/people";
import {iJobArtisFieldRow} from "./types";
import {iJobDetailsDataReturn} from "api/jobs/types";

export type tUseJobArtistFieldReturn = ReturnType<typeof useJobArtistField>;

interface iUseJobArtistFieldProps {
  required?: boolean;
  values?: iJobDetailsDataReturn["artists"];
}

export function useJobArtistField(props?: iUseJobArtistFieldProps) {
  const [values, setValues] = useState<iJobArtisFieldRow[]>([]);
  const [searchingPersons, setSearchingPersons] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [required] = useState(props?.required !== undefined ? props.required : true);

  useEffect(() => {
    if (props?.values) {
      const newValue: any = props.values.map(({artist, title, agent, artist_agency}) => {
        const id = uid();
        return {
          uid: id,
          title,
          agent,
          person: artist,
          agency: artist_agency
        };
      });

      setValues(newValue);
    }
  }, [props?.values]);

  const person = useSearchField({
    required: true,
    searchConfig: peopleApi.getList,
    searchKey: "search"
  });

  const field: iJobArtisFieldRow = {
    uid: uid(),
    person: null,
    title: null,
    agency: null,
    agent: null
  };

  useEffect(() => {
    if (!person.value) return;

    setValues(prev => [
      ...prev,
      {
        ...field,
        person: person?.value?.[values.length],
        title: person?.value?.[values.length]?.titles?.[0] || null
      }
    ]);
    setSearchingPersons(false);
  }, [person?.value]); // eslint-disable-line react-hooks/exhaustive-deps

  const onRemove = (contactRowId: string) => {
    const newValues = values.filter(row => row.uid !== contactRowId);
    checkValidity(newValues);
    setValues(newValues);
    person.setValue(null);
  };

  const handleChange = <T>(value: T, key: string, rowId: string) => {
    setValues(prev =>
      prev.map(row => {
        if (row.uid === rowId) {
          return {...row, [key]: value};
        }
        return row;
      })
    );
    setErrors([]);
  };

  const addContact = () => {
    if (!values?.length) {
      setSearchingPersons(true);
      return;
    }
    const isValid = checkValidity();
    if (isValid) setSearchingPersons(true);
    else setErrors([errorMessages.emptyPrev]);
  };

  const checkValidity = (val?: iJobArtisFieldRow[]) => {
    const newValue = val !== undefined ? val : values;
    const isEmpty = !newValue.length; // doesnt have any contacts

    if (isEmpty && !required) {
      setErrors([]);
      return true;
    }

    if (isEmpty) {
      setErrors([errorMessages.empty]);
      return false;
    }
    const hasEmptyTitle = newValue.some(row => !row?.title?.id);

    if (hasEmptyTitle) {
      // when has empty title
      setValues(prevState =>
        prevState.map((value: any) => ({
          ...value,
          title: {...value?.title, errors: value?.title?.id ? [] : [errorMessages.empty]}
        }))
      );
      return false;
    }

    if (newValue.some(row => !row?.title?.id)) {
      const newValues = newValue.map((value) => ({
        ...value,
        title: value?.title?.id ? { ...value.title, errors: [] } : { errors: [errorMessages.empty] }
      }))
      setValues(newValues as iJobArtisFieldRow[]);
      return false
    } // prettier-ignore

    setErrors([]);
    return true;
  };

  return {
    values,
    setValues,
    onRemove,
    person,
    searchingPersons,
    setSearchingPersons,
    checkValidity,
    errors,
    setErrors,
    addContact,
    handleChange
  };
}

const errorMessages = {
  emptyPrev: "You should fill all fields before adding new artist.",
  empty: "This field is required.",
  required: "All fields should be filled."
};
