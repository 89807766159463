import {FC} from "react";
import "./LetterAvatar.scss";

interface iLetterAvatar {
  last_name?: string;
  first_name?: string;
  abbr?: string;
  display_name?: string | null;
};

export const LetterAvatar: FC<iLetterAvatar> = ({
  last_name = "",
  first_name = "",
  abbr = "",
  display_name
}) => {
  const getAvatarLetter = (first_name: string, last_name: string) => first_name.charAt(0) + last_name.charAt(0);
  const avatarLetter = abbr ? abbr : getAvatarLetter(first_name, last_name);

  if (display_name) {
    const name = display_name?.toUpperCase().split(" ").map(name => name.charAt(0)).join(""); // prettier-ignore
    return <div className="LetterAvatar">{name}</div>;
  };

  return <div className="LetterAvatar">{avatarLetter}</div>;
};
