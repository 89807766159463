import {createContext, FC, useContext} from "react";

interface iUseTemplateLayoutProps {
  id: number;
  onClose: () => void;
  name: string;
}

export function useTemplateLayout({id, onClose, name}: iUseTemplateLayoutProps) {
  return {id, onClose, name};
}

export type tUseTemplateLayout = ReturnType<typeof useTemplateLayout>;

export type tTemplateLayoutContext = tUseTemplateLayout;

const TemplateLayoutContext = createContext<tTemplateLayoutContext | null>(null);
TemplateLayoutContext.displayName = "TemplateLayoutContext";

export const TemplateLayoutContextProvider: FC<{id: number; name: string; onClose: () => void}> = ({
  id,
  onClose,
  name,
  children
}) => {
  const value = useTemplateLayout({id, name, onClose});

  return <TemplateLayoutContext.Provider value={value}>{children}</TemplateLayoutContext.Provider>;
};

export const useContextTemplateLayout = () => {
  const context = useContext(TemplateLayoutContext);

  if (context === null) {
    throw new Error(
      "<<< useContextTemplateLayout >>> must be used within a TemplateLayoutContextProvider"
    );
  }

  return context as tTemplateLayoutContext;
};
