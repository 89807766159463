import {useState} from "react";
import {tDraggingItem} from "components/DragAndDropWrap/DragAndDropWrap";
import {tEstimateGroup} from "api/budget/estimate/types";
import {useHttp} from "hooks/httpServices/useHttp";
import {estimateApi} from "api/budget/estimate";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useContextJobBudget} from "../../../context/JobBudgetContext";

type tProps = {
  setData: React.Dispatch<React.SetStateAction<tEstimateGroup>>;
};

export const useChangeOrderTableRow = ({setData}: tProps) => {
  const {call, isLoading} = useHttp();
  const {setEstimate} = useContextJobBudget();
  const [currItem, setCurrItem] = useState<tDraggingItem | null>(null);

  const dropCallBack = (draggingItem: tDraggingItem, group: tEstimateGroup) => {
    if (currItem?.order === draggingItem?.order) return;
    const newIndex = group?.cost_values?.findIndex(item => item?.id === draggingItem?.id) as number;

    setData(prev => {
      const newArrLines = [...prev?.cost_values?.filter(item => item?.id !== currItem?.id)];
      currItem && newArrLines.splice(newIndex, 0, currItem as any);
      prev.cost_values = newArrLines;
      return prev;
    });

    currItem?.id && onChangeOrder(currItem.id, draggingItem.order);
  };

  const onChangeOrder = async (rowId: number | string, order: number) => {
    try {
      const res = await call(estimateApi.changeLineOrder(rowId, {order}));
      setEstimate(() => res?.data?.data);
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return {
    setCurrItem,
    dropCallBack,
    currItem,
    isLoading
  };
};
