import {FC} from "react";
import {StyleSheet, Text, View} from "@react-pdf/renderer";
import {SectionTitle} from "./SectionTitle";
import {Label, Value} from "./common";
import {getDateRange} from "helpers/dates";

interface iJobDescriptionSectionProps {
  jobStartAt: any | string | null;
  jobEndAt: any | string | null;
  jobName: string;
  jobNumber: string;
  photographer: string;
  po: string;
  shootLocation: string;
  notes: string;
  label1?: string;
  label2?: string;
  text1?: string;
  text2?: string;
}

export const JobDescriptionSection: FC<iJobDescriptionSectionProps> = ({
  jobStartAt,
  jobEndAt,
  jobNumber,
  photographer,
  po,
  shootLocation,
  jobName,
  notes,
  label1,
  label2,
  text1,
  text2
}) => {
  const date = getDateRange({
    start_at: jobStartAt || new Date(),
    end_at: jobEndAt || new Date(),
    monthFormat: "MMMM"
  });

  const dataMap = [
    {
      label: "Job Name",
      value: jobName || ""
    },
    {
      label: "Job number",
      value: jobNumber || ""
    },
    {
      label: "PHOTOGRAPHER",
      value: photographer || ""
    },
    {
      label: "PO",
      value: po || ""
    },
    {
      label: "shoot Location",
      value: shootLocation || ""
    },
    {
      label: "shoot Dates",
      value: `${date.periodStart}-${date.periodEnd}, ${new Date(jobEndAt || "").getFullYear()}`
    },
    {
      label: label1,
      value: text1
    },
    {
      label: label2,
      value: text2
    }
  ];

  return (
    <View>
      <SectionTitle>JOB DESCRIPTION</SectionTitle>
      {dataMap.map((item, i) => {
        if (!item?.label || !item?.label) return null;
        return (
          <View key={i} style={styles.row}>
            <Label>{item.label}</Label>
            <Value>{item.value}</Value>
          </View>
        );
      })}
      {notes && (
        <>
          <SectionTitle stylesObject={styles.withMarginTop12}>Notes</SectionTitle>
          <Text style={styles.text}> {notes}</Text>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%"
  },
  halfSection: {
    width: "45%"
  },
  row: {
    display: "flex",
    flexDirection: "row"
  },
  withMarginTop12: {
    marginTop: 12
  },
  text: {
    color: "#7E7E80",
    fontFamily: "Helvetica400",
    fontSize: "10px"
  }
});
