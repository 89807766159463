import {FC} from "react";
import {tCrewContact} from "api/jobCrewGroup/types";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import "./RowDietary.scss";

type tRowDietaryProps = {
  crewContact: tCrewContact;
};

export const RowDietary: FC<tRowDietaryProps> = ({crewContact}) => {
  const {contact} = crewContact;

  const showComma = !!contact?.dietary_restrictions?.length && !!contact?.other_dietary_restriction;
  return (
    <TextComponent size="13" color="gray-10" weight="400" className="RowDietary">
      {contact?.dietary_restrictions?.map(item => item?.name).join(", ")}
      {showComma && ", "}
      {contact?.other_dietary_restriction}
    </TextComponent>
  );
};
