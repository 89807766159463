import {FC, MouseEvent, useState} from "react";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {JobCalendarRename} from "pages/Jobs/JobDetails/JobCalendar/modals/JobCalendarRename/JobCalendarRename";
import {SaveAsNewTemplate} from "pages/Jobs/JobDetails/JobCalendar/modals/SaveAsNewTemplate/SaveAsNewTemplate";
import {JobCalendarDuplicate} from "pages/Jobs/JobDetails/JobCalendar/modals/JobCalendarDuplicate/JobCalendarDuplicate";
import {JobCalendarDelete} from "pages/Jobs/JobDetails/JobCalendar/modals/JobCalendarDelete/JobCalendarDelete";
import {SaveAsExistingTemplate} from "pages/Jobs/JobDetails/JobCalendar/modals/SaveAsExistingTemplate/SaveAsExistingTemplate";
import {useLockCalendar} from "./useLockCalendar";
import {useContextJobCalendar} from "../../context";
import {saveDataFromFile} from "helpers/files/saveDataFromFile";
import {jobsCalendarApi} from "api/jobs/calendar";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {CalendarPdfExport} from "./CalendarPdfExport";
import {eventBus} from "EventBus/EventBus";
import {DropboxAuth} from "components/shared/DropboxAuth/DropboxAuth";

interface iCalendarManagementProps {
  setIsLoading: (isLoading: "download" | "saveToDropbox" | null) => void;
  isLoading: "download" | "saveToDropbox" | null;
}

type tModal = "rename" | "delete" | "duplicate" | "new" | "existing" | "dropboxAuth";

export const CalendarManagement: FC<iCalendarManagementProps> = ({isLoading, setIsLoading}) => {
  const {call, isLoading: icsLoading} = useHttp();
  const {activeCalendar} = useContextJobCalendar();
  const {onLock, isLoading: lockIsLoading} = useLockCalendar({closeAllMenu: closeMenu});

  const [menu, setMenu] = useState<HTMLElement | null>(null);
  const [modal, setModal] = useState<tModal | null>(null);

  const showMenu = (event: MouseEvent<HTMLButtonElement>) => setMenu(event.currentTarget);

  function closeMenu() {
    setMenu(null);
  }

  const showModal = (v: tModal) => {
    closeMenu();
    setModal(v);
  };

  const onClose = () => setModal(null);

  const saveAsIcs = async () => {
    try {
      const response = await call(jobsCalendarApi.exportJobCalendar(activeCalendar!.id, "ics"));
      await saveDataFromFile(response?.data, `${activeCalendar!.name}.ics`);
      closeMenu();
      eventBus.dispatch("showToast", {text: response?.data?.message});
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const ModalRender = {
    rename: <JobCalendarRename onClose={onClose} />,
    delete: <JobCalendarDelete onClose={onClose} />,
    duplicate: <JobCalendarDuplicate onClose={onClose} />,
    new: <SaveAsNewTemplate onClose={onClose} />,
    existing: <SaveAsExistingTemplate onClose={onClose} />,
    dropboxAuth: <DropboxAuth show={true} onClose={onClose} />
  };

  return (
    <div className="CalendarManagement">
      <Button size="sm" color="outline" onClick={showMenu}>
        <Icon icon="menu" size="xsm" />
      </Button>
      <DropdownMenu anchor={menu} onClose={closeMenu} className="CalendarActionsMenu">
        <Button onClick={() => showModal("rename")} size="sm">
          Rename
        </Button>
        <Button onClick={onLock} size="sm" loading={lockIsLoading}>
          {activeCalendar?.locked ? "Unlock" : "Lock"}
        </Button>
        <Button onClick={() => showModal("duplicate")} size="sm">
          Duplicate
        </Button>
        <Button onClick={() => showModal("new")} size="sm">
          SAVE AS New TEMPLATE
        </Button>
        <Button onClick={() => showModal("existing")} size="sm">
          Save as existing template
        </Button>
        <div className="CalendarActionsMenu-nestedMenuAnchor Button sm ">
          <span>Export</span>
          <Icon icon="triangle-right" size="xsm" color="light-gray" />
          <div className="submenu CalendarManagement">
            <Button onClick={saveAsIcs} size="sm" loading={icsLoading}>
              Save as ICS
            </Button>
            <CalendarPdfExport
              onClose={closeMenu}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setModal={setModal}
            />
          </div>
        </div>

        <Button onClick={() => showModal("delete")} size="sm">
          DELETE
        </Button>
      </DropdownMenu>

      {modal && ModalRender[modal]}
    </div>
  );
};
