import {FC} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";

type tCloseTooltipButtonsProps = {
  isSingleTooltipOnPage: boolean;
  isLoading: boolean;
  onCloseTooltip: (type: "single" | "all") => Promise<void>;
  isLastStep?: boolean;
};

export const CloseTooltipButtons: FC<tCloseTooltipButtonsProps> = ({
  isLoading,
  isLastStep,
  onCloseTooltip,
  isSingleTooltipOnPage
}) => {
  return (
    <>
      {isSingleTooltipOnPage && (
        <button
          disabled={isLoading}
          onClick={() => onCloseTooltip("single")}
          className="AppTooltips-closeIcon"
        >
          <Icon icon="close" size="sm" />
        </button>
      )}

      {!isSingleTooltipOnPage && (
        <Button
          size="sm"
          disabled={isLoading}
          onClick={() => onCloseTooltip("single")}
          className="AppTooltips-changeStepBtn"
        >
          {isLastStep ? "Done" : "Next"}
        </Button>
      )}
    </>
  );
};
