import {tDynamicFieldRowObj as tDynamicFieldRowObjPhone} from "components/shared/FormComponents/DynamicFieldsPhone/types";
import {tDynamicFieldRowObj as tDynamicFieldRowObjEmail} from "components/shared/FormComponents/DynamicFieldsEmail/types";

export function getDifferenceDynamicFields<
  T extends {
    type_id: number;
    type_name: string;
    value: string;
    type: {id: number; name: string};
    type_custom: string | null;
  }
>(propArray: T[], values: tDynamicFieldRowObjPhone[] | tDynamicFieldRowObjEmail[]) {
  if (!propArray?.length && values?.[0] && !values?.[0]?.id && !values?.[0]?.value) return false;
  if (propArray.length !== values.length) return true;

  return !!values.filter(
    value =>
      !propArray?.some(prop =>
        prop.value === value.value && prop.type.name === "Other"
          ? prop.type_custom === value.type_custom
          : prop.type_name === value.name
      )
  ).length;
}

export function getAccountsDifference(propsAccount: any[] = [], avalueAccounts: any[]) {
  if (
    !propsAccount?.length &&
    avalueAccounts?.[0] &&
    !avalueAccounts?.[0]?.id &&
    !avalueAccounts?.[0]?.value
  ) {
    return false;
  }
  if (propsAccount.length !== avalueAccounts.length) return true;
  return !!avalueAccounts.filter(
    value =>
      !propsAccount?.some(prop => prop.platform.name === value.name && prop.value === value.value)
  ).length;
}
