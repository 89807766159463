import {FC, FormEvent, useState} from "react";
import {gridApi} from "api/jobs/grid";
import {Button} from "components/shared/Button/Button";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {Loading} from "components/shared/Loading/Loading";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {notValidForm} from "helpers/misc";
import {useSelect} from "hooks/inputHooks/useSelect";
import {useHttp} from "hooks/httpServices/useHttp";
import {Select} from "components/shared/FormComponents/Select/Select";
import {TextComponent} from "../../common/TextComponent/TextComponent";
import {useContextJobGrid} from "../JobGridContext";
import {useGetTemplateList} from "./useGetTemplateList";

export const SaveAsExistingTemplate: FC = () => {
  const [showModal, setModal] = useState(false);
  return (
    <>
      <Button size="sm" onClick={() => setModal(true)}>
        Save as existing template
      </Button>
      {showModal && <SaveAsExistingTemplateForm setModal={setModal} />}
    </>
  );
};

type tSaveAsExistingTemplateFormProps = {setModal: React.Dispatch<React.SetStateAction<boolean>>};

const SaveAsExistingTemplateForm: FC<tSaveAsExistingTemplateFormProps> = ({setModal}) => {
  const {grid} = useContextJobGrid(); // prettier-ignore
  const {call, isLoading: submitting} = useHttp();
  const FORM_ID = "save_grid_as_existing_template_form_id";

  const form = {
    template: useSelect({isRequired: true, options: []})
  };

  const {isLoading, templateList} = useGetTemplateList(form);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (notValidForm(form) || !grid?.id) return;

    try {
      const res = await call(gridApi.saveAsExistingTemplate(grid?.id, form?.template?.value));
      eventBus.dispatch("showToast", {text: res?.data?.message || "Success!"});
      setModal(false);
    } catch (error) {
      const {msg} = apiErrorHandler(error, form);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <ConfirmWindow
      show={true}
      title="Save as existing template"
      subTitle="Choose a template to replace"
      cancelBtn={{text: "Cancel", type: "button"}}
      onClose={() => setModal(false)}
      confirmBtn={{text: "save", type: "submit", form: FORM_ID}}
      isLoading={submitting}
    >
      {isLoading && (
        <div className="emptyBlock">
          <Loading type="inBlock" />
        </div>
      )}

      {!isLoading && !templateList?.length && (
        <TextComponent center className="mb-40">
          You have no templates yet !
        </TextComponent>
      )}

      {!isLoading && !!templateList?.length && (
        <form className="AddGroup" onSubmit={onSubmit} id={FORM_ID}>
          <Select
            {...form.template.inputProps}
            errors={form.template.errors}
            canSelectEmpty={true}
          />
        </form>
      )}
    </ConfirmWindow>
  );
};
