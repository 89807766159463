import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {ETWorkingTotal} from "./_cells/ETWorkingTotal";
import {ETActualTotal} from "./_cells/ETActualTotal";
import {ETAlert} from "./_cells/ETAlert";
import {Green} from "../../_common/_tableParts/Green";
import {QuickPay} from "../../_common/_tableParts/QuickPay";
import {PayStatus} from "../../_common/_tableParts/PayStatus";
import {QBStatus} from "../../_common/_tableParts/QBStatus";
import {Pay} from "../../_common/_tableParts/Pay/Pay";
import {Note} from "../../_common/_tableParts/Note";
import {AdditionalFiles} from "../../_common/_tableParts/AdditionalFiles";
import {Actions} from "../../_common/_tableParts/Actions/Actions";
import {ETInputCell} from "./_cells/ETInputCell";
import {FileCellsItem} from "../../_common/_tableParts/FileCells/FileCellsItem";
import {expenseListTableTitles} from "./expenseListTableTitles";
import {tExpenseListItem} from "api/budget/track/types";

export const useCellsList = (expense: tExpenseListItem) => {
  const cells = {
    [expenseListTableTitles[0].id]: <ETAlert id={expense?.id} state={!!expense?.alert} />,
    [expenseListTableTitles[1].id]: (
      <TextComponent as="div" weight="400" size="14" nowrap className="ETName">
        {expense?.cost_value?.code}
        {expense?.cost_value?.suffix} {expense?.cost_value?.name}
      </TextComponent>
    ),
    [expenseListTableTitles[2].id]: (
      <TextComponent as="div" weight="400" size="14" nowrap className="ETEstimate">
        {expense?.estimate?.name}
      </TextComponent>
    ),
    [expenseListTableTitles[3].id]: (
      <ETInputCell
        id={expense?.id}
        value={expense?.vendor_name || ""}
        type="vendor_name"
        placeholder="Vendor"
        style={{minWidth: "300px"}}
      />
    ),
    [expenseListTableTitles[4].id]: (
      <ETInputCell
        id={expense?.id}
        value={expense?.description || ""}
        type="description"
        placeholder="Description"
        style={{minWidth: "340px"}}
      />
    ),
    [expenseListTableTitles[5].id]: (
      <ETWorkingTotal
        id={expense?.id}
        value={expense?.working_details?.amount?.toString()}
        selectValue={expense?.working_details?.currency_code}
        trackLine={expense}
      />
    ),
    [expenseListTableTitles[6].id]: (
      <ETActualTotal
        id={expense?.id}
        value={expense?.actual_details?.amount?.toString()}
        selectValue={expense?.actual_details?.currency_code}
        trackLine={expense}
      />
    ),
    [expenseListTableTitles[7].id]: (
      <ETInputCell
        id={expense?.id}
        value={expense?.charged?.toString() || ""}
        type="charged"
        placeholder="0.00"
        className="ETCharged"
        inputType="input"
        manySeparator
      />
    ),
    [expenseListTableTitles[8].id]: (
      <Green id={expense?.id} value={expense?.green} className="ETGreen" />
    ),
    [expenseListTableTitles[9].id]: <Pay className="ETSelectCell" trackLine={expense} />,
    [expenseListTableTitles[10].id]: <QBStatus className="ETSelectCell" trackLine={expense} />,
    [expenseListTableTitles[11].id]: <PayStatus trackLine={expense} className="ETSelectCell" />,
    [expenseListTableTitles[12].id]: (
      <FileCellsItem file={expense?.w8_file} file_type="w8" data={expense} isExpense />
    ),
    [expenseListTableTitles[13].id]: (
      <FileCellsItem file={expense?.w9_file} file_type="w9" data={expense} isExpense />
    ),
    [expenseListTableTitles[14].id]: (
      <FileCellsItem file={expense?.ach_file} file_type="ach" data={expense} isExpense />
    ),
    [expenseListTableTitles[15].id]: (
      <FileCellsItem file={expense?.wire_file} file_type="wire" data={expense} isExpense />
    ),
    [expenseListTableTitles[16].id]: (
      <QuickPay className="ETQuickPay" id={expense?.id} value={expense?.qb_state} />
    ),
    [expenseListTableTitles[17].id]: (
      <Note id={expense?.id} value={expense?.note || ""} style={{minWidth: "300px"}} />
    ),
    [expenseListTableTitles[18].id]: (
      <AdditionalFiles className="ETAdditionalFiles" data={expense} />
    ),
    [expenseListTableTitles[19].id]: <Actions className="ETActions" trackLine={expense} />
  };

  return {cells};
};
