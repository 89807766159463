import {tTrackExpenseLine, typeFees} from "api/budget/track/types";
import {ExpenseRowName} from "./_cells/ExpenseRowName/ExpenseRowName";
import {ExpenseRowDescription} from "./_cells/ExpenseRowDescription/ExpenseRowDescription";
import {ExpenseRowWorking} from "./_cells/ExpenseRowWorking";
import {ExpenseRowActual} from "./_cells/ExpenseRowActual";
import {
  ExpenseRowDifference,
  ExpenseRowDifference2
} from "./_cells/ExpenseRowDifference/ExpenseRowDifference";
import {FileCellsItem} from "pages/Jobs/JobDetails/JobBudget/Track/_common/_tableParts/FileCells/FileCellsItem";

type tProps = {
  typeFees?: typeFees;
};

export const useGetExpenseRowCells = ({typeFees}: tProps) => {
  const cells = (props: {className: string; trackLine: tTrackExpenseLine}) => [
    {
      component: <ExpenseRowName {...props} />
    },
    {
      component: <ExpenseRowDescription {...props} />,
      colSpan: 5
    },
    {
      component: <ExpenseRowWorking {...props} />
    },
    {
      component: <ExpenseRowActual {...props} />
    },
    {
      component: <ExpenseRowDifference {...props} typeFees={typeFees} />,
      colSpan: 1
    },
    {
      component: (
        <FileCellsItem
          key={"w8"}
          data={props?.trackLine}
          file={props?.trackLine?.w8_file}
          file_type={"w8"}
        />
      )
    },
    {
      component: (
        <FileCellsItem
          key={"w9"}
          data={props?.trackLine}
          file={props?.trackLine?.w9_file}
          file_type={"w9"}
        />
      )
    },
    {
      component: (
        <FileCellsItem
          key={"ach"}
          data={props?.trackLine}
          file={props?.trackLine?.ach_file}
          file_type={"ach"}
        />
      )
    },
    {
      component: (
        <FileCellsItem
          key={"wire"}
          data={props?.trackLine}
          file={props?.trackLine?.wire_file}
          file_type={"wire"}
        />
      )
    },
    {
      component: <ExpenseRowDifference2 {...props} typeFees={typeFees} />,
      colSpan: 1
    }
  ];

  return {cells};
};
