import {renderToString} from "react-dom/server";
import moment from "moment/moment";

export function jobSchedulerInit(scheduler: any, schedulerRef: any, initMode: string) {
  scheduler.skin = "material";
  scheduler.xy.nav_height = 0;

  // const state = scheduler.getState();
  const startPoint = moment().startOf("week");

  scheduler.templates.week_date = (start: Date) => {
    const dateToStr_func = scheduler.date.date_to_str(scheduler.config.month_date);
    return dateToStr_func(start);
  };

  scheduler.templates.week_scale_date = (date: any) => {
    let dayNumber = moment(date);

    let dayDifference = dayNumber.diff(startPoint, "day") + 1;

    return renderToString(<span className="week-scale-bar">Day {dayDifference}</span>);
  };

  scheduler.attachEvent("onTemplatesReady", function () {
    scheduler.templates.agenda_date = scheduler.templates.month_date;
  });

  scheduler.config.mark_now = true;
  scheduler.config.now_date = new Date();
  scheduler.config.dblclick_create = false;
  scheduler.config.start_on_monday = false;
  scheduler.config.container_autoresize = true;
  scheduler.config.update_render = false;
  scheduler.config.server_utc = true;
  scheduler.init(schedulerRef.current, new Date(), initMode, "%D");
}
