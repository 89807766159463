import React, {useState, useRef} from "react";
import {Crop, PixelCrop} from "react-image-crop";
import {blobToBase64, centerAspectCrop, generateCanvas} from "./helpers";
import "react-image-crop/dist/ReactCrop.css";
import {useCompressImg} from "hooks/useCompressImg";

type tUseImageCrop = {
  onChangeImage: (img: string) => void;
  aspectImage: number | undefined;
};

export default function useImageCrop({onChangeImage, aspectImage}: tUseImageCrop) {
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const {onChangeHandler} = useCompressImg({
    options: {maxSizeMB: 1.8, alwaysKeepResolution: true, initialQuality: 1}
  });

  const [compressing, setCompressing] = useState(false);
  async function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCompressing(true);
      const file: any = await onChangeHandler(e.target.files[0]);
      setCrop(undefined); // Makes crop preview update between images.

      const reader = new FileReader();
      reader.addEventListener("load", () => setImgSrc(reader.result?.toString() || ""));
      reader.readAsDataURL(file);
      setCompressing(false);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspectImage) {
      const {width, height} = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspectImage));
    }
  }

  const clearState = () => {
    setImgSrc("");
    setScale(1);
  };

  const [cropping, setCropping] = useState(false);
  const onSaveImage = async () => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      setCropping(true);
      await generateCanvas(imgRef.current, previewCanvasRef.current, completedCrop, scale);
      previewCanvasRef.current.toBlob(async blob => {
        if (!blob) {
          throw new Error("Failed to create blob");
        }
        if (blobUrlRef.current) {
          URL.revokeObjectURL(blobUrlRef.current);
        }
        blobUrlRef.current = URL.createObjectURL(blob);
        const image: any = await blobToBase64(blob);
        onChangeImage(image);
        setCropping(false);
        clearState();
      });
    }
  };

  return {
    onSelectFile,
    imgSrc,
    onImageLoad,
    imgRef,
    scale,
    setCompletedCrop,
    completedCrop,
    previewCanvasRef,
    crop,
    setCrop,
    clearState,
    onSaveImage,
    setScale,
    cropping,
    compressing
  };
}
