import {FC} from "react";
import {iTableProps} from "./types";
import {Loading} from "components/shared/Loading/Loading";

import "./Table.scss";

export const TableComponent: FC<iTableProps> = ({
  headerRowChildren,
  bodyChildren,
  footerChildren,
  className = ""
}) => {
  return (
    <table className={`Table ${className}`}>
      <thead>
        <tr className="Table-header-row">{headerRowChildren}</tr>
      </thead>
      <tbody className="Table-body">{bodyChildren}</tbody>
      {footerChildren && (
        <tfoot className="Table-footer" id="table-footer">
          {footerChildren}
        </tfoot>
      )}
    </table>
  );
};

export const Table: FC<iTableProps> = ({scroll, isLoading, wrapperStyles, ...props}) => {
  return scroll ? (
    <div className="TableWrapper posRelative" style={wrapperStyles}>
      {isLoading && <Loading type="inBlock" bgColor="transparent" />}
      <TableComponent {...props} />
    </div>
  ) : (
    <TableComponent {...props} />
  );
};
