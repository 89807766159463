import {ReactNode, useState} from "react";
import {ImgWithFallback} from "components/shared/ImgWithFallback/ImgWithFallback";
import {Loading} from "components/shared/Loading/Loading";
import {PdfPage} from "../PDFJSDist/PDFView";
import {useGetPdfFromUrl} from "./useGetPdfFromUrl";
import {tViewersFile} from "../types";
import {ViewerToolbar} from "../ViewerToolbar/ViewerToolbar";
import {DeleteFile} from "../DeleteFile/DeleteFile";
import {formFileObj} from "../fileHelpers";
import "./Viewer.scss";

type tViewerProps = {
  file: Partial<tViewersFile> | undefined;
  pdf?: boolean;
  hideToolbar?: boolean;
  onDeleteBtn?: ReactNode;
  uploadButton?: ReactNode;
};

export const Viewer = (props: tViewerProps) => {
  const {file, onDeleteBtn, pdf = false, hideToolbar, uploadButton} = props;
  const isPDF = pdf ? true : file?.url?.includes(".pdf");
  const {loading, pages} = useGetPdfFromUrl({url: isPDF ? file?.url : undefined});
  const defaultScale = 1;
  const [scale, setScale] = useState(defaultScale);
  const noFile = !file?.url;

  return (
    <div className="Viewer">
      {loading && <Loading type="inBlock" />}

      {!loading && (
        <>
          {!hideToolbar && (
            <ViewerToolbar
              setScale={setScale}
              defScale={defaultScale}
              file={{url: file?.url, name: file?.name}}
              onDeleteBtn={onDeleteBtn}
              uploadButton={uploadButton}
              hideLeftTools={noFile}
              hideRightSide={noFile}
            />
          )}

          {!isPDF && (
            <div className={`Viewer-imgWrap ${noFile ? "placeholder" : "a"}`}>
              <ImgWithFallback
                style={{transform: `scale(${scale})`}}
                className="Viewer-img"
                src={file?.url || ""}
              />
            </div>
          )}

          {isPDF && (
            <div className="Viewer-pagesWrap">
              {pages?.map((item, i) => {
                return (
                  <PdfPage page={item} scale={scale} style={{"--scale-factor": scale}} key={i} />
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};

Viewer.DeleteBtn = DeleteFile;
Viewer.formatFromTrack = formFileObj;
