import {FC, Fragment} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {getName} from "helpers/misc";
import {getDateRange} from "helpers/dates";
import {AvatarGroup} from "components/shared/AvatarGroup/AvatarGroup";
import {EditJobDetails} from "pages/Jobs/JobDetails/JobDetailsModals/EditJobDetails/EditJobDetails";
import {Popover} from "components/shared/Popover/Popover";
import {PipelineSelection} from "components/shared/PipelineSelection/PipelineSelection";
import {Link} from "react-router-dom";
import {absRoutes} from "router/helpers";
import {useJobDetailsHeader} from "./useJobDetailsHeader";
import "./JobDetailsHeader.scss";

export const JobDetailsHeader: FC = () => {
  const {
    onShowPipelines,
    handleSelectedCallback,
    showEdit,
    setShowEdit,
    jobDetailsData,
    anchor,
    setAnchor
  } = useJobDetailsHeader();

  if (!jobDetailsData) return null;

  const {start_at, end_at, team, departments, name, id, locales, artists, pipeline} = jobDetailsData; // prettier-ignore
  const jobDates =
    start_at && end_at
      ? getDateRange({start_at, end_at})
      : {periodStart: "", periodEnd: "", period: ""};
  const date = jobDates.periodStart === jobDates.periodEnd ? jobDates.periodEnd : jobDates.period; // prettier-ignore
  const jobGeneralInfo = [
    {value: id, name: "id"},
    {value: getName(jobDetailsData?.client), name: "clientName"},
    {value: locales?.[0]?.name, name: "localeName"},
    {value: start_at && end_at ? date : "Date Pending", name: date ? "dates" : "empty"},
    {value: getName(artists?.[0]?.artist), name: "artistName"}
  ].filter(({value}) => value);

  const pipeLineClassName = `pipelines ${
    pipeline ? "" : "empty-pipeline"
  } ${pipeline?.group?.name?.toLowerCase()}`;

  return (
    <>
      <div className={`JobDetailsHeader ${departments?.[0]?.name}`}>
        <Link to={absRoutes.dashboard.main} className="JobDetailsHeader-backButton">
          <Icon icon="arrow-left" />
        </Link>

        <h1 className="JobDetailsHeader-title">{name}</h1>
        <ul className="JobDetailsHeader-info">
          {jobGeneralInfo.map(({value, name}, index) => {
            const isSeparator = index !== 0;
            return (
              <Fragment key={value}>
                {isSeparator && <span className="JobDetailsHeader-infoItemSeparator" />}
                <li className={`JobDetailsHeader-infoItem ${name}`}>{value}</li>
              </Fragment>
            );
          })}
        </ul>

        <div className="JobDetailsHeader-controls">
          {!!team?.length && <AvatarGroup data={team} nameKeys="display_name" />}

          <Button onClick={onShowPipelines} className={pipeLineClassName} size="sm">
            {pipeline?.name || "Pipeline"}
          </Button>
          <Popover anchor={anchor} onClose={() => setAnchor(null)}>
            <PipelineSelection
              jobData={jobDetailsData}
              onSelectedPipelineCallback={handleSelectedCallback}
            />
          </Popover>

          <Button onClick={() => setShowEdit(true)} size="sm" color="outline">
            Edit details
          </Button>
        </div>
      </div>

      {showEdit && <EditJobDetails job={jobDetailsData} onClose={() => setShowEdit(false)} />}
    </>
  );
};
