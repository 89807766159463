import {FC} from "react";
import {Loading} from "components/shared/Loading/Loading";
import {GridHeader} from "./GridHeader/GridHeader";
import {Grid} from "./Grid/Grid";
import {JobGridContextProvider, useContextJobGrid} from "./JobGridContext";
import "./JobGrids.scss";

interface iJobGridsProps {}

export default function JobGrids() {
  return (
    <JobGridContextProvider>
      <JobGridsComponent />
    </JobGridContextProvider>
  );
}

export const JobGridsComponent: FC<iJobGridsProps> = () => {
  const {isLoading, grid} = useContextJobGrid();

  return (
    <div className="JobGrids">
      {isLoading && <Loading type="inBlock" />}

      {!isLoading && grid && (
        <>
          <GridHeader />
          <Grid />
        </>
      )}
    </div>
  );
};
