import {FC} from "react";

export const Twitter: FC = () => {
  return (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5921 1.70076C17.3093 1.82618 17.0186 1.93203 16.7215 2.01797C17.0732 1.62023 17.3413 1.15223 17.505 0.640101C17.5417 0.525308 17.5037 0.399657 17.4093 0.32458C17.3151 0.249444 17.1841 0.240444 17.0804 0.301935C16.4495 0.676101 15.7689 0.944997 15.0552 1.10224C14.3363 0.399773 13.3599 0 12.3505 0C10.2199 0 8.4864 1.73339 8.4864 3.86401C8.4864 4.03181 8.49703 4.19869 8.51805 4.36324C5.87409 4.1311 3.41605 2.83156 1.72812 0.761049C1.66797 0.687249 1.5753 0.647475 1.48042 0.655082C1.38549 0.662514 1.30019 0.716049 1.25223 0.798326C0.90988 1.38576 0.728894 2.05763 0.728894 2.74122C0.728894 3.67228 1.06131 4.55567 1.64852 5.24594C1.46997 5.1841 1.2967 5.10682 1.13134 5.01502C1.04256 4.9656 0.934209 4.96636 0.84601 5.01693C0.757752 5.06751 0.702417 5.16052 0.700094 5.2622C0.699687 5.27932 0.699687 5.29645 0.699687 5.31381C0.699687 6.70358 1.44767 7.95481 2.59125 8.63678C2.493 8.62697 2.39482 8.61274 2.29727 8.5941C2.1967 8.57488 2.09329 8.61013 2.02547 8.68683C1.95754 8.76347 1.93506 8.87031 1.96636 8.96786C2.38965 10.2894 3.47946 11.2615 4.79694 11.5578C3.70423 12.2422 2.45462 12.6007 1.14283 12.6007C0.869119 12.6007 0.593836 12.5846 0.324418 12.5528C0.190579 12.5368 0.0626057 12.6159 0.0170252 12.7431C-0.0285553 12.8705 0.0197542 13.0124 0.133618 13.0854C1.81882 14.166 3.7674 14.7371 5.76859 14.7371C9.70268 14.7371 12.1637 12.8819 13.5355 11.3256C15.2461 9.38505 16.2271 6.81646 16.2271 4.27853C16.2271 4.1725 16.2255 4.06543 16.2223 3.95871C16.8971 3.45024 17.4782 2.83487 17.951 2.12759C18.0228 2.02017 18.0151 1.87821 17.9318 1.77932C17.8488 1.68038 17.7103 1.64839 17.5921 1.70076Z"
        fill="currentColor"
      />
    </svg>
  );
};
