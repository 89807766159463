import {FC} from "react";
import {JobBudgetStatusBarLayout} from "../../JobBudgetStatusBarLayout/JobBudgetStatusBarLayout";
import {Button} from "components/shared/Button/Button";
import {useEstimateStatusLine} from "./useEstimateStatusLine";
import moment from "moment/moment";
import {Icon} from "components/Icon/Icon";
import {ModalPreviewPdf} from "components/shared/ModalPreviewPdf/ModalPreviewPdf";
import EstimateOverlay from "../EstimateOverlay/EstimateOverlay";
import {ApproveEstimateForm} from "../EstimateBuild/EstimateSidebar/EstimateSIdebarActions/ApproveEstimateForm";
import {useContextJobDetails} from "../../../../context/JobDetailsContext/JobDetailsContext";
import {useContextExportBudget} from "../../context/ExportBudgetContext";
import {prepareName} from "helpers/fileNaming";
import {useContextJobBudget} from "pages/Jobs/JobDetails/JobBudget/context/JobBudgetContext";

import "./EstimateStatusLine.scss";

interface iEstimateStatusLineProps {}

export const EstimateStatusLine: FC<iEstimateStatusLineProps> = () => {
  const {jobDetailsData} = useContextJobDetails();
  const {estimate} = useContextJobBudget();
  const {exportInfo, onDownloadPdf, pdfData} = useContextExportBudget();
  const {
    modal,
    setModal,
    onSaveExportInfo,
    isSavingExportInfo,
    onSaveToDropbox,
    isUploadingDropbox,
    onShowPreview
  } = useEstimateStatusLine();

  const date = exportInfo?.estimate?.approved_at
    ? moment(exportInfo?.estimate?.approved_at).format("MMM DD, YYYY")
    : "";

  const closeModal = () => setModal(null);

  const fileName = prepareName(
    [
      moment().format("YYYYMMDD"),
      jobDetailsData?.name || "",
      String(jobDetailsData?.id) || "",
      estimate?.name || ""
    ],
    "_ESTIMATE.pdf"
  );

  return (
    <>
      <JobBudgetStatusBarLayout
        onSaveExportInfo={onSaveExportInfo}
        onDownloadPdf={onDownloadPdf}
        isApproved={!!exportInfo?.estimate?.approved_at}
        onShowPreview={onShowPreview}
        isSaving={isSavingExportInfo}
        isUploadingDropbox={!modal && isUploadingDropbox}
        onSaveToDropbox={onSaveToDropbox}
      >
        <div className="EstimateStatusLine">
          {!!exportInfo?.estimate?.approved_at && (
            <>
              <Icon className="approved-icon" icon="check" size="sm" color="white" />
              <span className="EstimateStatusLine-approved">Approved</span>
              <span className="EstimateStatusLine-date">{date}</span>
            </>
          )}
          {!exportInfo?.estimate?.approved_at && (
            <Button size="sm" color="outline" onClick={() => setModal("approve")}>
              mark as approved
            </Button>
          )}
        </div>
      </JobBudgetStatusBarLayout>

      {modal === "approve" && <ApproveEstimateForm onClose={closeModal} />}
      {modal === "preview" && jobDetailsData && exportInfo?.estimate && (
        <ModalPreviewPdf
          saveOnDropbox={onSaveToDropbox}
          isUploadingDropbox={isUploadingDropbox}
          pdfUrl={pdfData?.url}
          onClose={closeModal}
          fileName={fileName}
        >
          <EstimateOverlay pdfUrl={pdfData?.url} />
        </ModalPreviewPdf>
      )}
    </>
  );
};
