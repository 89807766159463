import {FC, FormEvent, useEffect, useState} from "react";
import {Button} from "components/shared/Button/Button";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {Departments} from "./Department/Departments";
import {SearchClient} from "./SearchClient/SearchClient";
import {notValidForm} from "helpers/misc";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useIsFirstRender} from "hooks/useIsFirstRender";
import {iSearchingContactResponse} from "api/contacts/people/interfaces";
import {CreateNewJobForm} from "../../CreateNewJob";
import "./JobDetails.scss";

interface iJobDetailsProps {
  stepsCallBack: () => void;
  form: CreateNewJobForm;
}

export const JobDetails: FC<iJobDetailsProps> = ({stepsCallBack, form}) => {
  const searchVal = useTextInput({
    value: !!form.client_name.value ? form.client_name.value : "",
    validators: ["valueLength"]
  });
  const [selectedClient, setClient] = useState<Partial<iSearchingContactResponse> | null>(null); // prettier-ignore
  const [wasChanges, setWasChanges] = useState(false);

  const isFirst = useIsFirstRender();
  useEffect(() => {
    if (isFirst) return;
    setWasChanges(true);
  }, [searchVal]); // eslint-disable-line

  useEffect(() => {
    if (isFirst) return;
    if (!!form.client_name.value && form.client_name.value !== searchVal.value) {
      setClient(null);
      form.client_id.setValue("");
      form.client_type.setValue("");
    }
  }, [wasChanges]); // eslint-disable-line

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const {departments, name, client_type, client_id, client_name} = form;
    if (notValidForm({departments, name, searchVal})) return;

    if (
      (!selectedClient?.id && !form.client_id.value) ||
      (!selectedClient?.type && !form.client_type.value)
    )
      return searchVal.setErrors(["You should select from list !"]);

    client_type.setValue(selectedClient?.type || form.client_type.value);
    client_name.setValue(selectedClient?.name || form.client_name.value);
    client_id.setValue(!!selectedClient?.id ? String(selectedClient.id) : form.client_id.value);

    stepsCallBack();
  };

  return (
    <form id="JobDetailsForm" className="JobDetailsForm" onSubmit={onSubmit}>
      <div className="title">JOB DETAILS</div>
      <div className="subTitle">Name your job, select a client and assign a department</div>

      <div className="JobDetailsForm-label">Job name</div>
      <InputField {...form.name.inputProps} errors={form.name.errors} />

      <div className="JobDetailsForm-label">Client</div>
      <SearchClient inputData={searchVal} setClient={setClient} />

      <div className="JobDetailsForm-label">Departments</div>
      <Departments inputData={form.departments} error={form.departments.errors[0]} />

      <Button form="JobDetailsForm" size="lg" color="dark" type="submit">
        NEXT
      </Button>
    </form>
  );
};
