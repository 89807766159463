import moment from "moment";

export const generateHours = (maxHour = 24, format: "h" | "H" = "h") => {
  let hoursArray: Array<{id: string; label: string; value: string; valueHour: string}> = [];
  moment.locale("en");

  for (let hour = 0; hour < maxHour; hour++) {
    const time = moment({hour}).format("h:mm A");
    hoursArray = [
      ...hoursArray,
      {
        id: time,
        label: time,
        value: moment(time, [`${format}:mm A`])
          .unix()
          .toString(),
        // valueHour: moment({hour}).format(`${format}`),
        valueHour: moment({hour}).utc().format(`${format}`)
      }
    ];
  }

  return hoursArray;
};

export const generateTimeInterval = ({
  maxHour,
  format,
  startTimeInMinutes,
  interval
}: {
  maxHour: number;
  startTimeInMinutes: number;
  format: "h" | "H";
  interval: number;
}) => {
  let hoursArray: Array<{id: string; label: string; value: string; valueHour: string}> = [];
  moment.locale("en");

  for (let minutes = 0; minutes < maxHour * 60; minutes += interval) {
    const time = moment()
      .set({hour: 0, minutes: startTimeInMinutes})
      .add({minutes})
      .format("h:mm A");

    hoursArray = [
      ...hoursArray,
      {
        id: time,
        label: time,
        value: moment(time, [`${format}:mm A`]).format(`${format}:mmA`),
        valueHour: moment().set({hour: 0, minutes}).format(`${format}`)
      }
    ];
  }

  return hoursArray;
};

export const generateHoursMinutes = () => {
  return Array.from(
    {
      length: 48
    },
    (_, hour) => {
      const time = moment({
        hour: Math.floor(hour / 2),
        minutes: hour % 2 === 0 ? 0 : 30
      }).format("hh:mm");

      return {
        id: `${time}:${hour > 24 ? "PM" : "AM"}`,
        period: hour > 24 ? "PM" : "AM",
        label: `${time} ${hour > 24 ? "PM" : "AM"}`
      };
    }
  ).splice(2);
};

//dates
type tFormats =
  | "timestamp"
  | "timestampWithUTC"
  | "timestampUTC"
  | "MMM DD, YYYY"
  | "MMM D, YYYY"
  | "L"
  | "ddd, MMM DD, YYYY"
  | "millisecond"
  | "timestampStart"
  | "timestampEnd";

export const getDateFormat = (date: Date | string | null | moment.Moment, format: tFormats) => {
  if (!date) return "";

  const formats: Record<tFormats, any> = {
    timestamp: moment(date).unix().toString(),
    timestampStart: moment(date).startOf("day").unix().toString(),
    timestampEnd: moment(date).endOf("day").unix().toString(),
    millisecond: String(moment(date).unix() * 1000),
    timestampWithUTC: moment.utc(date).unix(),
    "MMM DD, YYYY": moment(date).format("MMM DD, YYYY"),
    "MMM D, YYYY": moment(date).format("MMM D, YYYY"),
    L: moment(date).format("L"),
    "ddd, MMM DD, YYYY": moment(date).format("ddd, MMM DD, YYYY"),
    timestampUTC: moment(date).utc(true).unix()
  };

  return formats[format];
};
export const isOverdue = (date: moment.MomentInput): false | number => {
  const diff = moment(date).diff(moment().utc(), "days");
  return diff < 0 ? Math.abs(diff) : false;
};

// prettier-ignore
export const getDaysBetween = ({start, end}: {start: moment.MomentInput; end: moment.MomentInput}) => {
    const totalDays = moment(end).diff(moment(start), "days");
    const currDay = moment().utc().diff(moment(start), "days");
    return {currDay, totalDays};
};

/**
 * @param {moment.MomentInput} date
 * @param start_at
 * @param end_at
 * @param format
 */
export const getDateRange = ({
  start_at,
  end_at,
  format,
  monthFormat = "MMM"
}: {
  start_at: string | Date | number;
  end_at: string | Date | number;
  format?: string | undefined;
  monthFormat?: string;
}) => {
  //FIXME
  moment.suppressDeprecationWarnings = true;
  let periodStart = ``;
  let periodEnd = ``;

  // if (!start_at || !end_at) return {start: "", end: "", period: "", periodStart, periodEnd};

  const start = moment(start_at ? start_at : new Date()).format(format || "MMM DD, YYYY");
  const end = moment(end_at ? end_at : new Date()).format(format || "MMM DD, YYYY");

  if (moment(start_at).isSame(end_at, "day")) {
    const endIsSameYear = moment().isSame(end_at, "year");

    periodStart = moment(start_at).format(endIsSameYear ? "MMM DD" : "MMM DD, YYYY");
    periodEnd = moment(end_at).format(endIsSameYear ? "MMM DD" : "MMM DD, YYYY");

    const dataPeriod = !!moment(periodStart).isSame(periodEnd, "day")
      ? periodStart
      : `${periodStart}-${periodEnd}`;

    return {
      start,
      end,
      period: `${periodStart} - ${periodEnd}`,
      periodStart,
      periodEnd,
      dataPeriod
    };
  }

  const startYear = moment(start).year();
  const endYear = moment(end).year();
  const startMonth = moment(start).format(monthFormat);
  const endMonth = moment(end).format(monthFormat);
  const startDate = moment(start).date();
  const endDate = moment(end).date();
  const currentYear = new Date().getFullYear();

  let periodStartYear = `${startYear !== endYear && startYear !== currentYear ? ", " + startYear : ""}`; // prettier-ignore
  let periodEndYear = `${startYear !== endYear || startYear !== currentYear ? ", " + endYear : ""}`; // prettier-ignore
  let periodEndMonth = `${startYear !== endYear || startYear !== currentYear || startMonth !== endMonth ? endMonth + " " : ""}`; // prettier-ignore

  periodStart = `${startMonth} ${startDate}${periodStartYear}`;
  periodEnd = `${periodEndMonth}${endDate}${periodEndYear}`;

  const dataPeriod = !!moment(periodStart).isSame(periodEnd, "day")
    ? periodStart
    : `${periodStart}-${periodEnd}`;

  return {start, end, period: `${periodStart}-${periodEnd}`, periodStart, periodEnd, dataPeriod};
};
