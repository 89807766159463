import {FC, useState} from "react";
import {tTrackCategoriesItem} from "api/budget/track/types";
import {CategoryFooter} from "./CategoryFooter/CategoryFooter";
import {CategoryHeader} from "./CategoryHeader/CategoryHeader";
import {CategoryBody} from "./CategoryBody/CategoryBody";

type tCategoryProps = {
  track: tTrackCategoriesItem;
  index: number;
};

export const Category: FC<tCategoryProps> = ({track, index}) => {
  const [showList, setShowList] = useState(index === 0 ? true : false);
  const showListToggler = () => setShowList(prev => !prev);

  return (
    <>
      <CategoryHeader
        title={`${track?.category?.code} ${track?.category?.name}`}
        showListToggler={showListToggler}
        showList={showList}
      />

      {track?.cost_values?.map((trackCost, i) => {
        const isFirstItem = index === 0 && i === 0;
        return <CategoryBody key={i} trackCost={trackCost} isFirstItem={isFirstItem} />;
      })}

      <CategoryFooter data={track?.category_total} />
    </>
  );
};
