export const companiesTableColumn = [
  {
    id: "preferred",
    label: "",
    sort: false
  },
  {
    id: "name",
    label: "Company",
    sort: true
  },
  {
    id: "address",
    label: "Address",
    sort: false
  },
  {
    id: "labels",
    label: "Labels",
    sort: false
  },
  {
    id: "locale",
    label: "Locale",
    sort: false
  },
  {
    id: "email",
    label: "Email",
    sort: false
  },
  {
    id: "phone",
    label: "Phone",
    sort: false
  }
];
