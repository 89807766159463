import {useState, FormEvent, Dispatch, SetStateAction, useEffect, ChangeEvent} from "react";
import {tTermsConditions} from "api/budget/termsConditions/types";
import {useHttp} from "hooks/httpServices/useHttp";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {termsConditionsApi} from "api/budget/termsConditions";
import {notValidForm} from "helpers/misc";

interface iUseAdvanceTermsModalProps {
  setTermsList: Dispatch<SetStateAction<tTermsConditions[]>>;
}

type tModal = "edit" | "add" | "delete";

export function useAdvanceTermsModal({setTermsList}: iUseAdvanceTermsModalProps) {
  const deleteTermQuery = useHttp();
  const createTermQuery = useHttp();
  const editTermQuery = useHttp();

  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string[]>([]);

  const [modal, setModal] = useState<tModal | null>(null);
  const [activeTerm, setActiveTerm] = useState<tTermsConditions | null>(null);

  const emptyError = "This field can't be empty";

  const formData = {
    name: useTextInput()
  };

  useEffect(() => {
    if (modal === "edit" && activeTerm) {
      formData.name.setValue(activeTerm.name);
    } else {
      formData.name.value && formData.name.setValue("");
      formData.name.setIsValid(true);
      formData.name.setErrors([]);
    }
  }, [modal, activeTerm]); // eslint-disable-line react-hooks/exhaustive-deps

  const onDelete = async () => {
    if (!activeTerm) return;
    try {
      const {
        data: {message}
      } = await deleteTermQuery.call(termsConditionsApi.deleteTermsConditions(activeTerm.id));
      //
      // const isSelectedTerm = advanceDetails?.term_condition?.id === activeTerm.id;
      // isSelectedTerm && setAdvanceDetails(prevState => (prevState ? {...prevState, term_condition: null} : null)); // prettier-ignore

      setTermsList(prevState => prevState.filter(term => +term.id !== +activeTerm.id));
      eventBus.dispatch("showToast", {text: message});
      showModal(null);
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {text: msg, type: "error"});
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const isEditing = !!activeTerm;

    if (notValidForm(formData) || (!isEditing && !file)) {
      setFileError([emptyError]);
      return;
    }

    const payload = new FormData();

    file && payload.append("file", file);
    payload.append("name", formData.name.value);

    try {
      const {data: {message, data}} = isEditing ?
        await editTermQuery.call(termsConditionsApi.updateTermsConditions(activeTerm.id, payload)) :
        await createTermQuery.call(termsConditionsApi.createTermsConditions(payload)); // prettier-ignore

      if (isEditing) {
        setTermsList(prevState => prevState.map(term => (+term.id === +activeTerm?.id ? data : term))); // prettier-ignore
      } else {
        setTermsList(prevState => [...prevState, data]);
      }

      eventBus.dispatch("showToast", {text: message});
      showModal(null);
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {text: msg, type: "error"});
    }
  };

  const showModal = (type: tModal | null) => {
    setModal(type);
    !type && setActiveTerm(null);
    setFile(null);
    setFileError([]);
  };

  const onShowDelete = (terms: tTermsConditions) => {
    setActiveTerm(terms);
    setModal("delete");
  };

  const onShowEdit = (terms: tTermsConditions) => {
    setActiveTerm(terms);
    setModal("edit");
  };

  const onChangeFile = (event: ChangeEvent<HTMLInputElement> | null) => {
    if (!event?.target?.files?.[0]) {
      setFile(null);
      setFileError([emptyError]);
    } else {
      setFile(event?.target?.files?.[0]);
      setFileError([]);
    }
  };

  return {
    deleteTermQuery,
    createTermQuery,
    editTermQuery,
    activeTerm,
    setActiveTerm,
    modal,
    showModal,
    setModal,
    onDelete,
    handleSubmit,
    formData,
    file,
    fileError,
    setFile,
    onShowEdit,
    onShowDelete,
    onChangeFile
  };
}
