import {Dispatch, FC, FormEvent, SetStateAction} from "react";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {Icon} from "components/Icon/Icon";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {Loading} from "components/shared/Loading/Loading";
import {notValidForm} from "helpers/misc";

import "./CreatingLine.scss";

interface iCreatingLineProps {
  placeholder: string;
  onRemove: Dispatch<SetStateAction<boolean>>;
  onSave: (payload: {name: string; code: string}) => void;
  isLoading: boolean;
}

export const CreatingLine: FC<iCreatingLineProps> = ({
  onSave,
  isLoading,
  placeholder,
  onRemove
}) => {
  const formData = {
    name: useTextInput(),
    code: useTextInput()
  };

  const handleSave = async (event: FormEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (notValidForm(formData)) return;

    await onSave({
      name: formData.name.value,
      code: formData.code.value
    });
  };

  const errorMessage = [
    formData.name.errors?.[0] ? "Name field can't be empty" : "",
    formData.code.errors?.[0] ? "Code field can't be empty" : ""
  ].filter(k => k);

  return (
    <form id="new-line-form" className="CreatingLine">
      {isLoading && <Loading type="inBlock" />}
      <div className="CreatingLine-row">
        <div className="CreatingLine-input code">
          <InputField
            {...formData.code.inputProps}
            errors={formData.code.errors}
            placeholder="Code"
          />
        </div>
        <div className="CreatingLine-input">
          <InputField
            {...formData.name.inputProps}
            errors={formData.name.errors}
            placeholder={placeholder}
          />
        </div>
        <div className="CreatingLine-actions">
          <button onClick={handleSave} form="new-line-form">
            <Icon icon="check" color="green" size="sm" />
          </button>
          <button onClick={() => onRemove(false)}>
            <Icon icon="close" color="red" size="sm" />
          </button>
        </div>
      </div>
      {errorMessage.map(error => {
        return (
          <div key={error} className="CreatingLine-input error">
            {error}
          </div>
        );
      })}
    </form>
  );
};
