import {AxiosRequestConfig} from "axios";
import {
  tCategorizedPostData,
  tCustomExpenseLine,
  tExpenseLineFileData,
  tTrackFeesItem
} from "./types";
import {tCurrencyRates} from "api/common/types";

const job = "job";
const expenseCost = "estimate_expense_cost";
const expenseLine = "estimate_expense_line";

export type tResponseType = "groups" | "list";

// /job/{job_id}/currency_rates [GET|POST]
export const trackApi = {
  getWrapPDF: (jobId: number): AxiosRequestConfig => ({
    url: `${job}/${jobId}/wrap`
  }),
  getTrackCurrencyList: (jobId: number): AxiosRequestConfig => ({
    url: `${job}/${jobId}/currency_rates`
  }),
  setTrackCurrencyList: (jobId: number, data: tCurrencyRates[]): AxiosRequestConfig => ({
    method: "POST",
    url: `${job}/${jobId}/currency_rates`,
    data
  }),
  getEstimateList: (jobId: number): AxiosRequestConfig => ({
    url: `${job}/${jobId}/estimate_categories`
  }),
  //expense list
  getExpenseList: (jobId: number): AxiosRequestConfig => ({
    url: `${job}/${jobId}/estimate_expense_list`
  }),
  syncEstimateList: (
    jobId: number,
    timestamp: string,
    response_type: tResponseType = "groups"
  ): AxiosRequestConfig => ({
    url: `${job}/${jobId}/estimate_expenses/sync/${timestamp}?response_type=${response_type}`
  }),
  setExpenseLineAlert: (
    expenseLineId: number,
    data: {state: boolean; response_type?: tResponseType}
  ): AxiosRequestConfig => ({
    url: `estimate_expense_line/${expenseLineId}/alert`,
    method: "POST",
    data
  }),
  setExpenseCharged: (
    expenseLineId: number,
    data: {charged: number; response_type?: tResponseType}
  ): AxiosRequestConfig => ({
    url: `estimate_expense_line/${expenseLineId}/charged`,
    method: "POST",
    data
  }),
  setBillState: (
    expenseLineId: number,
    data: {state: boolean; response_type?: tResponseType}
  ): AxiosRequestConfig => ({
    url: `estimate_expense_line/${expenseLineId}/bill_state`,
    method: "POST",
    data
  }),
  setReceiptRequired: (
    expenseLineId: number,
    data: {state: boolean; response_type?: tResponseType}
  ): AxiosRequestConfig => ({
    url: `estimate_expense_line/${expenseLineId}/receipt_required`,
    method: "POST",
    data
  }),
  //track wa
  getEstimateGroups: (jobId: number): AxiosRequestConfig => ({
    url: `${job}/${jobId}/estimate_expense_groups`
  }),
  uploadExpenseFile: (jobId: number, data: {expense: File}): AxiosRequestConfig => ({
    url: `${job}/${jobId}/expense`,
    method: "POST",
    data
  }),
  updateExpenseFile: (fileId: number, data: {expense: File}): AxiosRequestConfig => ({
    url: `job_expense/${fileId}/update`,
    method: "POST",
    data
  }),
  addExpenseLIneForPayrollFee: (
    jobId: number,
    data: {
      target_name: tTrackFeesItem["target_name"];
    }
  ): AxiosRequestConfig => ({
    url: `${job}/${jobId}/add_expense_line`,
    method: "POST",
    data
  }),
  setCategorizedExpense: (
    jobExpenseFileId: number,
    data: tCategorizedPostData
  ): AxiosRequestConfig => ({
    url: `job_expense/${jobExpenseFileId}/categorize`,
    method: "POST",
    data
  }),
  setCategorizedExpenseWithFile: (
    jobId: number,
    data: tCategorizedPostData & Partial<{expense_file_content: File; expense_file_id: number}>
  ): AxiosRequestConfig => ({
    url: `job/${jobId}/job_expense_categorize`,
    method: "POST",
    data
  }),
  deleteExpenseFile: (
    jobExpenseFileId: number,
    response_type: tResponseType = "groups"
  ): AxiosRequestConfig => ({
    url: `job_expense/${jobExpenseFileId}?response_type=${response_type}`,
    method: "DELETE"
  }),
  setCostDescriptions: (
    estimateCostId: number,
    data: {
      description?: string;
      discount_description?: string;
      private_memo?: string;
    }
  ): AxiosRequestConfig => ({
    url: `${expenseCost}/${estimateCostId}/description`,
    method: "POST",
    data
  }),
  addExpanseLine: (estimateCostId: number): AxiosRequestConfig => ({
    url: `${expenseCost}/${estimateCostId}/add_expense_line`,
    method: "POST"
  }),
  addCustomExpanseLine: (jobId: number, data: tCustomExpenseLine): AxiosRequestConfig => ({
    url: `job/${jobId}/estimate_expense_cost`,
    method: "POST",
    data
  }),
  //Expense line actions
  uploadAdditionalFile: (
    estimateLineId: number,
    data: {file: File; response_type?: tResponseType}
  ): AxiosRequestConfig => ({
    url: `${expenseLine}/${estimateLineId}/additional_file`,
    method: "POST",
    data
  }),
  deleteAdditionalFile: (
    fileId: number,
    response_type: tResponseType = "groups"
  ): AxiosRequestConfig => ({
    url: `estimate_expense_line_additional_file/${fileId}?response_type=${response_type}`,
    method: "DELETE"
  }),
  setVendorName: (
    estimateLineId: number,
    data: {vendor_name: string; response_type?: tResponseType}
  ): AxiosRequestConfig => ({
    url: `${expenseLine}/${estimateLineId}/vendor_name`,
    method: "POST",
    data
  }),
  setExpenseLineOrder: (estimateLineId: number, data: {order: number}): AxiosRequestConfig => ({
    url: `${expenseLine}/${estimateLineId}/order`,
    method: "POST",
    data
  }),
  setDescriptionToLine: (
    estimateLineId: number,
    data: {description: string; response_type?: tResponseType}
  ): AxiosRequestConfig => ({
    url: `${expenseLine}/${estimateLineId}/description`,
    method: "POST",
    data
  }),
  setWorkingDetail: (
    estimateLineId: number,
    data: {amount: string; currency_code?: string; response_type?: tResponseType}
  ): AxiosRequestConfig => ({
    url: `${expenseLine}/${estimateLineId}/working`,
    method: "POST",
    data
  }),
  deleteWorking: (estimateLineId: number): AxiosRequestConfig => ({
    url: `${expenseLine}/${estimateLineId}/working`,
    method: "DELETE"
  }),
  setActualAmount: (
    estimateLineId: number,
    data: {amount: string; currency_code?: string; response_type?: tResponseType}
  ): AxiosRequestConfig => ({
    url: `${expenseLine}/${estimateLineId}/actual`,
    method: "POST",
    data
  }),
  deleteExpenseLine: (
    estimateLineId: number,
    response_type: tResponseType = "groups"
  ): AxiosRequestConfig => ({
    url: `${expenseLine}/${estimateLineId}?response_type=${response_type}`,
    method: "DELETE"
  }),
  deleteActualAmount: (estimateLineId: number): AxiosRequestConfig => ({
    url: `${expenseLine}/${estimateLineId}/actual`,
    method: "DELETE"
  }),
  setFileToLine: (estimateLineId: number, data: tExpenseLineFileData): AxiosRequestConfig => ({
    url: `${expenseLine}/${estimateLineId}/attach_file`,
    headers: {"Content-Type": "multipart/form-data"},
    method: "POST",
    data
  }),
  deleteFileFromLine: (
    estimateLineId: number | undefined,
    data: tExpenseLineFileData
  ): AxiosRequestConfig => ({
    url: `${expenseLine}/${estimateLineId}/detach_file`,
    method: "DELETE",
    data
  }),
  setGreenState: (
    estimateLineId: number,
    data: {state: boolean; response_type?: tResponseType}
  ): AxiosRequestConfig => ({
    url: `${expenseLine}/${estimateLineId}/green`,
    method: "POST",
    data
  }),
  setQBState: (
    estimateLineId: number,
    data: {state: boolean; response_type?: tResponseType}
  ): AxiosRequestConfig => ({
    url: `${expenseLine}/${estimateLineId}/qb_state`,
    method: "POST",
    data
  }),
  setQBStatus: (
    estimateLineId: number,
    data: {qb_status_id: number | null; response_type?: tResponseType}
  ): AxiosRequestConfig => ({
    url: `${expenseLine}/${estimateLineId}/qb_status`,
    method: "POST",
    data
  }),
  setPayStatus: (
    estimateLineId: number,
    data: {pay_status_id: number | null; target_id?: number; response_type?: tResponseType}
  ): AxiosRequestConfig => ({
    url: `${expenseLine}/${estimateLineId}/pay_status`,
    method: "POST",
    data
  }),
  setNote: (
    estimateLineId: number,
    data: {note: string; response_type?: tResponseType}
  ): AxiosRequestConfig => ({
    url: `${expenseLine}/${estimateLineId}/note`,
    method: "POST",
    data
  }),
  setPayId: (
    estimateLineId: number,
    data: {pay_id: number | null; target_id?: number; response_type?: tResponseType}
  ): AxiosRequestConfig => ({
    url: `${expenseLine}/${estimateLineId}/pay_id`,
    method: "POST",
    data
  }),
  moveExpenseLine: (
    estimateLineId: number,
    data: {line_number: string; response_type?: tResponseType}
  ): AxiosRequestConfig => ({
    url: `${expenseLine}/${estimateLineId}/move`,
    method: "POST",
    data
  }),
  // saveTrackToDropbox: (jobId: number, data: {file: File}): AxiosRequestConfig => ({
  saveTrackToDropbox: (jobId: number): AxiosRequestConfig => ({
    url: `job/${jobId}/track/save_to_dropbox`,
    headers: {"Content-Type": "multipart/form-data"},
    method: "POST"
    // data
  })
};
