import {FC} from "react";
import {StyleSheet, View, Text} from "@react-pdf/renderer";
import {SectionTitle} from "./SectionTitle";
import {iJobDetailsDataReturn} from "api/jobs/types";
import {parseAddress} from "helpers/misc";
import {iAddress} from "api/common/types";

interface iClientSectionProps {
  address: iAddress;
  name: iJobDetailsDataReturn["name"];
}

export const ClientSection: FC<iClientSectionProps> = ({name, address}) => {
  const addressString = address ? parseAddress(address).string : "";

  if (!name) return null;

  return (
    <View style={styles.container}>
      <SectionTitle>Client</SectionTitle>
      <View style={styles.col}>
        <Text style={styles.label}>{name}</Text>
        <Text style={styles.value}>{addressString}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  col: {
    display: "flex",
    flexDirection: "column",
    width: "45%"
  },
  label: {
    fontFamily: "Helvetica500",
    fontSize: "10px",
    marginBottom: 3
  },
  value: {
    fontFamily: "Helvetica400",
    fontSize: "10px"
  }
});
