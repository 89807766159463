import {CallSheetTemplate} from "pages/Jobs/JobDetails/JobCallSheets/_templates/CallSheetTemplate/CallSheetTemplate";
import {CallSheetTemplateContacts} from "pages/Jobs/JobDetails/JobCallSheets/_templates/CallSheetTemplate/CallSheetTemplateContacts";
import {CallSheetTemplateHeader} from "pages/Jobs/JobDetails/JobCallSheets/_templates/CallSheetTemplate/CallSheetTemplateHeader";
import {CallSheetTemplateLocation} from "pages/Jobs/JobDetails/JobCallSheets/_templates/CallSheetTemplate/CallSheetTemplateLocation";
import {CallSheetTemplateSchedule} from "pages/Jobs/JobDetails/JobCallSheets/_templates/CallSheetTemplate/CallSheetTemplateSchedule";
import {CallSheetMsg} from "../Jobs/JobDetails/JobCallSheets/_templates/CallSheetMsg/CallSheetMsg";
import {CallSheetMsgWrap} from "./CallSheetMsgWrap/CallSheetMsgWrap";
import {Loading} from "components/shared/Loading/Loading";
import {useHttp} from "hooks/httpServices/useHttp";
import {useLocation} from "react-router-dom";
import {callSheet} from "api/callSheet";
import {tDeliveryConfirmationInfo} from "api/callSheet/types";
import {getDateFormat} from "helpers/dates";
import moment from "moment";

import "./CallSheetConfirm.scss";

export default function CallSheetConfirm() {
  const {pathname} = useLocation();
  const token = pathname?.split("/").slice(-1)?.[0];

  const {isLoading, error, data} = useHttp({
    config: callSheet.getDeliveryConfirmationInfo(token),
    onMountCall: true
  });

  const callSheetInfo = data?.data?.data as tDeliveryConfirmationInfo;

  const templateID = "pdf-template-confirm-id";
  const contactsGroup = callSheetInfo?.call_sheet?.attributes?.groups?.filter(
    ({hidden}) => !hidden
  );
  return (
    <div className="CallSheetConfirm" id={templateID}>
      {isLoading && <Loading />}

      {!isLoading && error && (
        <h1 style={{color: "white"}}>{error?.response?.data?.message || "Error!"}</h1>
      )}

      {!isLoading && !error && data && (
        <>
          <CallSheetMsgWrap
            filename={[
              callSheetInfo?.job?.name || "",
              callSheetInfo?.artist?.name?.split(" ")?.join("") || "",
              moment(callSheetInfo?.call_sheet?.date).format("YYYYMMDD") || "",
              callSheetInfo?.call_sheet?.name || ""
            ]}
            templateID={templateID}
            name={callSheetInfo?.contact?.name}
            token={token}
            isSent={callSheetInfo?.status?.name === "Confirmed"}
          >
            <CallSheetMsg msg={callSheetInfo?.delivery?.message} />
          </CallSheetMsgWrap>

          <CallSheetTemplate>
            <CallSheetTemplateHeader
              artist={callSheetInfo?.artist?.name}
              clientName={callSheetInfo?.client?.name}
              jobName={callSheetInfo?.job?.name}
              callSheetName={callSheetInfo?.call_sheet?.name || ""}
              date={getDateFormat(callSheetInfo?.call_sheet?.date || "", "ddd, MMM DD, YYYY")}
            />
            <CallSheetTemplateLocation
              image={callSheetInfo?.call_sheet?.image || ""}
              locations={callSheetInfo?.call_sheet?.attributes?.locations || []}
            />
            <CallSheetTemplateSchedule
              scheduleList={callSheetInfo?.call_sheet?.attributes?.schedule || []}
            />

            <CallSheetTemplateContacts contactsGroup={contactsGroup || []} />
          </CallSheetTemplate>
        </>
      )}
    </div>
  );
}
