import {FC, useState} from "react";
import {Button} from "components/shared/Button/Button";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {ImgWithFallback} from "components/shared/ImgWithFallback/ImgWithFallback";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {EditLocationsForm} from "./EditLocationsForm";
import {SectionHeader} from "../CallSheetBuildCommon/SectionHeader";
import {useContextJobCallSheet} from "../../context/JobCallSheetContext";
import {useUploadImage} from "./useUploadImage";
import {ImageCrop} from "components/shared/ImageCrop/ImageCrop";
import "./CallSheetBuildLocations.scss";

type tCallSheetBuildLocationsProps = {
  [key: string]: any;
};

export const CallSheetBuildLocations: FC<tCallSheetBuildLocationsProps> = () => {
  const {callSheetInfo} = useContextJobCallSheet();
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const {image, setImage} = useUploadImage();

  return (
    <div className="CallSheetBuildLocations">
      <div>
        <SectionHeader title="Locations">
          <Button color="outline" size="sm" onClick={() => setShowModal(true)}>
            Edit
          </Button>
        </SectionHeader>

        {callSheetInfo?.attributes?.locations?.map(item => {
          return (
            <div
              key={item?.id || item?.location_id}
              className="CallSheetBuildLocations-locationItem"
            >
              <TextComponent uppercase size="15">
                {item?.label}
              </TextComponent>
              <TextComponent weight="400" color="gray-10" size="15">
                {item?.name}
              </TextComponent>

              {!!item?.address?.raw && (
                <TextComponent weight="400" color="gray-10" size="15">
                  {item?.address?.raw}
                </TextComponent>
              )}

              {!item?.address?.raw && (
                <>
                  <TextComponent weight="400" color="gray-10" size="15">
                    {item?.address?.street}
                  </TextComponent>
                  <TextComponent weight="400" color="gray-10" size="15">
                    {item?.address?.city}
                  </TextComponent>
                </>
              )}
            </div>
          );
        })}
      </div>

      <div className={`CallSheetBuildLocations-upload ${image ? "" : "placeholder"}`}>
        <ImgWithFallback
          id="CallSheetBuildLocations-image"
          src={String(image) || ""}
          className={`uploadImage`}
          alt="location"
        />
        {/* <ImageCropper
          onChangeImage={setImage}
          aspect={640 / 480}
          isLoading={isLoading}
          imageQuality="original"
        /> */}
        <ImageCrop onChangeImage={setImage} aspect="landscape" />
      </div>

      {showModal && (
        <ModalPopup show={showModal} onClose={closeModal} withoutPaddings size="w-528">
          <EditLocationsForm closeModal={closeModal} />
        </ModalPopup>
      )}
    </div>
  );
};
