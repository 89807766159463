import {FC} from "react";

export const Reload: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7184 6.03047C17.073 4.17422 14.6789 3 12 3C7.02891 3 3 7.02891 3 12H5.25C5.25 8.26992 8.26992 5.25 12 5.25C14.0566 5.25 15.8813 6.18164 17.1152 7.63359L14.9988 9.75H21V3.74883L18.7184 6.03047ZM12 18.75C9.94336 18.75 8.11875 17.8184 6.88477 16.3664L9.00117 14.25H3V20.2512L5.28164 17.9695C6.92695 19.8258 9.32461 21 12 21C16.9711 21 21 16.9711 21 12H18.75C18.75 15.7301 15.7301 18.75 12 18.75Z"
        fill="#BDBDBF"
      />
    </svg>
  );
};
