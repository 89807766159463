import {FC} from "react";
import {Button} from "components/shared/Button/Button";
import {tCallSHeetGroupContacts} from "api/callSheet/types";
import "./ShowGroupBtn.scss";

type tShowGroupBtnProps = {
  group: tCallSHeetGroupContacts;
  setContactGroup: any;
};

export const ShowGroupBtn: FC<tShowGroupBtnProps> = ({group, setContactGroup}) => {
  const toggleHandler = () => {
    setContactGroup((prev: tCallSHeetGroupContacts[]) => {
      return prev?.map(item => {
        if (item?.id === group?.id) {
          return {...item, hidden: !item?.hidden};
        }
        return item;
      });
    });
  };

  return (
    <div className="ShowGroupBtn">
      <Button color="outline" size="sm" onClick={toggleHandler} className="btn">
        Add {group?.name || ""}
      </Button>
    </div>
  );
};
