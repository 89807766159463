import {useHttp} from "hooks/httpServices/useHttp";
import {jobsCalendarApi} from "api/jobs/calendar";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useContextJobCalendar} from "../../context";
import {eventBus} from "EventBus/EventBus";
import {useParams} from "react-router-dom";

interface iUseLockCalendar {
  closeAllMenu: () => void;
}

export function useLockCalendar({closeAllMenu}: iUseLockCalendar) {
  const {id} = useParams();
  const {activeCalendar, calendarsListQuery, getCalendarList} = useContextJobCalendar();
  const {call, isLoading} = useHttp();

  const onLock = async () => {
    if (!id || !activeCalendar) return;

    const payload = {
      name: activeCalendar.name,
      locked: !activeCalendar.locked
    };

    try {
      const {
        data: {data}
      } = await call(jobsCalendarApi.updateJobCalendar(Number(activeCalendar.id), payload));
      // const responseList = await getCalendarList(Number(id));
      await getCalendarList(Number(id));
      // setCalendarsList(responseList?.data?.data);
      // setActiveCalendar(data);
      const message = data.locked ? "Calendar locked" : "Calendar unlocked";
      eventBus.dispatch("showToast", {text: message});
      closeAllMenu();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return {
    onLock,
    isLoading: calendarsListQuery.isLoading || isLoading
  };
}
