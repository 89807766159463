import {AxiosRequestConfig} from "axios";

const person = "person";
const company = "company";
const favorite = "favorite";
const JOB = "job";

export const favorites = {
  getList: (): AxiosRequestConfig => ({
    url: "/favorites"
  }),
  addPerson: (id: number): AxiosRequestConfig => ({
    method: "POST",
    url: `${person}/${id}/${favorite}/add`
  }),
  removePerson: (id: number): AxiosRequestConfig => ({
    method: "POST",
    url: `${person}/${id}/${favorite}/remove`
  }),
  addCompany: (id: number): AxiosRequestConfig => ({
    method: "POST",
    url: `${company}/${id}/${favorite}/add`
  }),
  removeCompany: (id: number): AxiosRequestConfig => ({
    method: "POST",
    url: `${company}/${id}/${favorite}/remove`
  }),
  addJob: (id: number): AxiosRequestConfig => ({
    method: "POST",
    url: `${JOB}/${id}/favorite/add`
  }),
  removeJob: (id: number): AxiosRequestConfig => ({
    method: "POST",
    url: `${JOB}/${id}/favorite/remove`
  })
};
