import {FC} from "react";
import {iTeammateDetailsData} from "api/team/types";
import {DeleteInvite} from "./DeleteInvite";
import {ResentInvite} from "./ResentInvite";
import {TeamMemberHeader} from "../shared/TeamMemberHeader";
import {TeamMemberFooter} from "../shared/TeamMemberFooter";
import {ModalInfoSection} from "components/shared/ModalInfoSection/ModalInfoSection";
import {getDateFormat} from "helpers/dates";

interface iTeamMemberPendingProps {
  teammateDetail: iTeammateDetailsData;
}

export const TeamMemberPending: FC<iTeamMemberPendingProps> = ({teammateDetail}) => {
  const {status, role, notes, start_date} = teammateDetail;
  return (
    <div className="TeamMemberPending">
      <TeamMemberHeader teammateDetail={teammateDetail}>
        <DeleteInvite teammateDetail={teammateDetail} />
        <ResentInvite teammateDetail={teammateDetail} />
      </TeamMemberHeader>

      <ModalInfoSection
        title="INTERNAL"
        list={[
          {label: "Status", value: status?.name, valueClass: "capitalize"},
          {label: "Role", value: role?.name, valueClass: "capitalize"},
          {label: "Notes", value: notes},
          {label: "Start Date", value: getDateFormat(start_date, "MMM DD, YYYY")}
        ]}
      />

      <TeamMemberFooter teammateDetail={teammateDetail} />
    </div>
  );
};
