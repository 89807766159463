import {FC, useEffect, useRef, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {useTextInputReturn} from "hooks/useTextInput/useTextInput";
import {useDebounce} from "hooks/useDebounce";
import {useHttp} from "hooks/httpServices/useHttp";
import {useOnClickOutside} from "hooks/useOnclickOutside";
import {ShowModalsButtons} from "./ShowModalsButtons";
import {useIsFirstRender} from "hooks/useIsFirstRender";
import {contactsApi} from "api/contacts";
import {iSearchingContactResponse} from "api/contacts/people/interfaces";
import "./SearchClient.scss";

interface iSearchClientProps {
  inputData: useTextInputReturn;
  setClient: React.Dispatch<React.SetStateAction<Partial<iSearchingContactResponse> | null>>;
}

export const SearchClient: FC<iSearchClientProps> = ({inputData, setClient}) => {
  const query = useDebounce(inputData?.value || "", 500);
  const {data, call, isLoading} = useHttp();
  const list = (data?.data?.data || []) as iSearchingContactResponse[];

  const [modalType, setModal] = useState<"createPerson" | "createCompany" | null>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropdownRef, () => !modalType && setShowDropdown(false));

  const isFirst = useIsFirstRender();
  useEffect(() => {
    // if (!inputData.value || isFirst || !query?.trim()) return;
    // call(contactsApi.searchContacts({query: query?.trim()}));
    getQuery();
  }, [query]); // eslint-disable-line

  const getQuery = async () => {
    if (!inputData.value?.trim() && !isFirst) {
      inputData.setValue(inputData?.value?.trim());
    }

    if (!inputData.value || isFirst || !query?.trim()) return;
    await call(contactsApi.searchContacts({query: query?.trim()}));
  };

  const onSelectValue = (item: iSearchingContactResponse) => {
    inputData.setValue(item?.name);
    inputData.setErrors([]);
    setClient(item);
    setShowDropdown(false);
  };

  const onEditCallback = (data: any) => {
    onSelectValue(data?.data?.data as iSearchingContactResponse);
  };

  return (
    <div className="SearchClient" onFocus={() => setShowDropdown(true)} ref={dropdownRef}>
      <Icon icon="searchIcon" size="lg" />
      <InputField
        {...inputData.inputProps}
        errors={inputData.errors}
        placeholder="Search contacts..."
      />

      {showDropdown && (
        <div className="SearchClient-dropdown">
          <ul className="SearchClient-list">
            {isLoading && <li className="SearchClient-listItem">Loading...</li>}

            {!isLoading &&
              inputData?.value &&
              list?.map((item, i) => {
                return (
                  <li key={i} onClick={() => onSelectValue(item)} className="SearchClient-listItem">
                    {item?.name}
                  </li>
                );
              })}
          </ul>

          <ShowModalsButtons
            modalType={modalType}
            setModal={setModal}
            onEditCallback={onEditCallback}
          />
        </div>
      )}
    </div>
  );
};
