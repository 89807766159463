import {FC, FormEvent} from "react";
import {CustomSingleInput} from "./CustomSingleInput";
import {CustomCheckbox} from "./CustomCheckbox";
import {CustomSelect} from "./CustomSelect";
import {CustomDatepicker} from "./CustomDatepicker";
import {CustomTextArea} from "./CustomTextArea";
import {tUseCustomFieldsForm} from "./useCustomFieldsForm";
import "./CustomFieldsForm.scss";

interface iPeopleFormOtherProps {
  id: string;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  customFormData: tUseCustomFieldsForm;
}

export const CustomFieldsForm: FC<iPeopleFormOtherProps> = ({id, customFormData, onSubmit}) => {
  return (
    <form className="CustomFieldsForm" id={id} onSubmit={onSubmit}>
      {customFormData.fields.map((field: any) => {
        if (field.type === "checkbox") {
          return <CustomCheckbox key={field.id} field={field} onChange={customFormData.onChange} />;
        }
        if (field.type === "text") {
          return <CustomTextArea key={field.id} field={field} onChange={customFormData.onChange} />;
        }
        if (field.type === "single") {
          return <CustomSingleInput key={field.id} field={field} onChange={customFormData.onChange} />; // prettier-ignore
        }

        if (field.type === "dropdown") {
          return <CustomSelect key={field.id} field={field} onChange={customFormData.onChange} />;
        }

        return <CustomDatepicker key={field.id} field={field} onChange={customFormData.onChange} />;
      })}
    </form>
  );
};
