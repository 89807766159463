import {iToastTemplates} from "./interfaces";
import {Icon} from "components/Icon/Icon";

export const ToastTemplates: iToastTemplates = {
  success: {
    text: "Success",
    icon: <Icon icon="success" color="green" />,
    type: "success"
  },
  error: {
    text: "Error",
    icon: <Icon icon="error" color="red" />,
    type: "error"
  },
  info: {
    text: "Info",
    icon: <Icon icon="info" color="gray" />,
    type: "info"
  },
  warning: {
    text: "Warning",
    icon: <Icon icon="warning" color="yellow" />,
    type: "warning"
  }
};
