import {DetailedHTMLProps, FC} from "react";
import {Download} from "./icons/Download";
import {SearchIcon} from "./icons/SearchIcon";
import {Minus} from "./icons/Minus";
import {Pencil} from "./icons/Pencil";
import {Text} from "./icons/Text";
import {Reupload} from "./icons/Reupload";
import {Budget} from "./icons/Budget";
import {Reload} from "./icons/Reload";
import {Twitter} from "./icons/Twitter";
import {Linkedin} from "./icons/Linkedin";
import {Settings} from "./icons/Settings";
import {Heart} from "./icons/Heart";

import "./Icon.scss";

export type tIcon =
  | "sort"
  | "close-lg"
  | "close-sm"
  | "close"
  | "collapse"
  | "contact"
  | "dashboard"
  | "delete"
  | "drag"
  | "dropbox"
  | "edit"
  | "error"
  | "expand"
  | "export"
  | "export-secondary"
  | "external"
  | "eye"
  | "Facebook"
  | "hide"
  | "info"
  | "Instagram"
  | "jobs"
  | "landscape-image"
  | "location"
  | "lock"
  | "logout"
  | "menu"
  | "notifications"
  | "password"
  | "profile"
  | "reactivate"
  | "resend"
  | "search"
  | "sent"
  | "star"
  | "success"
  | "team"
  | "triangle-down"
  | "triangle-left"
  | "triangle-right"
  | "triangle-up"
  | "unlink"
  | "warning"
  | "account"
  | "add"
  | "arrow-back"
  | "arrow-down"
  | "arrow-left"
  | "arrow-right"
  | "arrow-up"
  | "calendar"
  | "check"
  | "overdue"
  | "reminder"
  | "send"
  | "person-default"
  | "Twitter"
  | "Linkedin"
  | "heart";

type tIconSize = "xxsm" | "xsm" | "sm" | "md" | "lg" | "14";
type tColor =
  | "gray"
  | "dark-gray"
  | "light-gray"
  | "black"
  | "white"
  | "yellow"
  | "red"
  | "green"
  | "orange"
  | "tangerine";

const additionalIcons = {
  download: <Download />,
  minus: <Minus />,
  pencil: <Pencil />,
  text: <Text />,
  reupload: <Reupload />,
  searchIcon: <SearchIcon />,
  budget: <Budget />,
  reload: <Reload />,
  LinkedIn: <Linkedin />,
  Twitter: <Twitter />,
  settings: <Settings />,
  heart: <Heart />
};

export interface iPropsIcon
  extends DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  className?: string;
  icon: tIcon | keyof typeof additionalIcons;
  size?: tIconSize;
  color?: tColor;
}

/**
 * @param {tIcon} icon - icon type
 *      for sort icon pass className with sort directions 'asc' | 'desc'
 * @param {String} className - icon className
 * @param {tIconSize} size - icon size - "xxsm" - 8px | "xsm" - 12px | "sm" - 16px | "md" - 24px | "lg" - 32px
 * @param {tColor} color - icon color - "gray" | "dark-gray" | "light-gray" | "black" | "white" | "yellow" | "red" | "green" | "orange
 * @return JSX.Element
 */
export const Icon: FC<iPropsIcon> = ({icon, size = "md", className = "", color = "", ...props}) => {
  const i = icon as keyof typeof additionalIcons & tIcon;
  if (additionalIcons[i]) {
    return (
      <i {...props} className={`icon icon__${size} ${color} icon-${i} ${className}`}>
        {additionalIcons[i]}
      </i>
    );
  }

  return <i {...props} className={`icon icon__${size} ${color} icon-${i} ${className}`} />;
};
