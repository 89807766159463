import {FC, FormEvent, useState} from "react";
import {jobCrewGroup} from "api/jobCrewGroup";
import {Button} from "components/shared/Button/Button";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {useHttp} from "hooks/httpServices/useHttp";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {notValidForm} from "helpers/misc";
import {tCrewGroupContact} from "api/jobCrewGroup/types";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {eventBus} from "EventBus/EventBus";
import {useContextJobCrew} from "pages/Jobs/JobDetails/JobCrew/JobCrewContext";

type tRenameGroupProps = {
  group: tCrewGroupContact;
  closeEditMenu: () => void;
};

export const RenameGroup: FC<tRenameGroupProps> = ({group, closeEditMenu}) => {
  const {jobCrewGroupList: {setCrewGroupList}} = useContextJobCrew(); // prettier-ignore
  const {call, isLoading} = useHttp();
  const [showModal, setModal] = useState(false);
  const FORM_ID = "rename-group_form_id";

  const form = {
    name: useTextInput({value: group?.name})
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (notValidForm(form)) return;

    try {
      const res = await call(jobCrewGroup.updateJobCrewGroup(group?.id, {name: form.name.value}));

      setCrewGroupList(() => res?.data?.data);
      eventBus.dispatch("showToast", {text: res?.data?.message || "Group name updated!"});
      closeEditMenu();
    } catch (error) {
      const {msg} = apiErrorHandler(error, form);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <>
      <Button size="sm" onClick={() => setModal(true)} btnType="text">
        Rename
      </Button>

      {showModal && (
        <ConfirmWindow
          show={true}
          title="Rename group"
          cancelBtn={{text: "Cancel", type: "button"}}
          onClose={() => setModal(false)}
          confirmBtn={{text: "rename", type: "submit", form: FORM_ID}}
          isLoading={isLoading}
        >
          <form className="AddGroup" onSubmit={onSubmit} id={FORM_ID}>
            <InputField {...form.name.inputProps} errors={form.name.errors} />
          </form>
        </ConfirmWindow>
      )}
    </>
  );
};
