import {FC} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {SectionHeader} from "../CallSheetBuildCommon/SectionHeader";
import {generateRow} from "./generateRow";
import {tScheduleRow} from "api/callSheet/types";

type tCallSheetBuildScheduleHeaderProps = {
  setSchedule: React.Dispatch<React.SetStateAction<tScheduleRow[] | undefined>>;
};

export const CallSheetBuildScheduleHeader: FC<tCallSheetBuildScheduleHeaderProps> = ({
  setSchedule
}) => {
  const addRows = (amount: 1 | 5) => {
    setSchedule(prev => [...(prev || []), ...generateRow(amount)]);
  };

  const removeEmptyRowsHandler = () => {
    setSchedule(prev => {
      return prev?.filter(item => {
        const isFirstRowEmpty = !item[0].time && !item[0].text;
        const isSecondRowEmpty = !item[1].time && !item[1].text;
        const isRowEmpty = isFirstRowEmpty && isSecondRowEmpty;

        return !isRowEmpty && item;
      });
    });
  };

  return (
    <SectionHeader title="Schedule">
      <Button color="outline" size="sm" onClick={removeEmptyRowsHandler}>
        Remove empty rows
      </Button>
      <Button color="outline" size="sm" onClick={() => addRows(1)}>
        <Icon icon="add" size="xsm" />
        Row
      </Button>
      <Button color="outline" size="sm" onClick={() => addRows(5)}>
        <Icon icon="add" size="xsm" /> 5 Rows
      </Button>
    </SectionHeader>
  );
};
