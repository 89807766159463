import {FC} from "react";
import "./CostValueRow.scss";

type tCostValueRowProps = {
  cells: {
    component: JSX.Element;
    className?: string;
    colSpan?: number;
  }[];
  isFirstItem?: boolean;
};

export const CostValueRow: FC<tCostValueRowProps> = ({cells, isFirstItem}) => {
  return (
    <tr className={`CostValueRow ${isFirstItem ? "firstRow" : ""}`}>
      {cells?.map((item, i) => {
        const cls = item?.className ? item?.className : "";
        return (
          <td key={i} className={`${cls}`} colSpan={!!item?.colSpan ? item?.colSpan : 1}>
            {item?.component}
          </td>
        );
      })}
    </tr>
  );
};
