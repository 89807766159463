import {FC, FormEvent, useState, useEffect} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {Select} from "components/shared/FormComponents/Select/Select";
import {useSelect} from "hooks/inputHooks";
import {FieldSetup, tFieldOption} from "./FieldSetup";
import {useHttp} from "hooks/httpServices/useHttp";
import {fields as fieldsApi} from "api/fields";
import {iField, tFieldPostData, tFieldTypes} from "api/fields/types";
import {getApiError} from "helpers/getApiError";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {notValidForm, uid} from "helpers/misc";
import {Loading} from "components/shared/Loading/Loading";
import {eventBus} from "EventBus/EventBus";
import {useLocation} from "react-router-dom";

import "./ManageFieldsForm.scss";

interface iManageFieldsFormProps {
  show: boolean;
  onClose: () => void;
  getFieldList: () => Promise<any>;
  field?: {id: number} & Partial<iField>;
}

type tTypesOptions = {
  has_options: false;
  label: string;
  value: string;
};

let fieldOptionShape = (id: string) => ({id: id, value: "", order: 0, errors: []});

export const ManageFieldsForm: FC<iManageFieldsFormProps> = props => {
  const errorMessage = "This field can't be empty";
  const {pathname} = useLocation();
  const isPeoplePage = pathname.includes("people");

  const {call, isLoading} = useHttp();

  const {field, show, onClose, getFieldList} = props;
  const [fields, setFields] = useState<tFieldOption[]>([fieldOptionShape(uid())]);

  const {data, isLoading: typesLoading} = useHttp({config: fieldsApi.getFieldTypes(), onMountCall: true}); // prettier-ignore
  const fieldsTypeList = data?.data?.data as Array<tTypesOptions>;

  const formData = {
    name: useTextInput({value: field ? field?.name : ""}),
    type: useSelect({isRequired: true, value: field ? field?.type : ""})
  };

  const currType = formData.type.value as tFieldTypes;
  const isDropdownFieldType = currType === "checkbox" || currType === "dropdown";

  useEffect(() => {
    if (field?.type) return;
    if (!!fieldsTypeList?.length) {
      //formData.type.setValue(fieldsTypeList?.[0].value);
    }
  }, [fieldsTypeList]); // eslint-disable-line

  useEffect(() => {
    setFields([fieldOptionShape(uid())]);
  }, [currType]);

  const checkOptionFieldsValidity = (options: tFieldOption[]) => {
    const newOptions = options.map(item => ({...item, errors: !!item.value?.length ? [] : [errorMessage]})) // prettier-ignore
    setFields(newOptions);
    return newOptions.filter(item => item.errors.length).length === 0;
  };

  useEffect(() => {
    if (!field) return;
    const fields = field?.options?.map((item, i) => ({id: item?.value, value: item.name, order: i, errors: []})) || []; // prettier-ignore
    setFields(fields);
  }, [field]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (isDropdownFieldType && !checkOptionFieldsValidity(fields)) return;
    if (notValidForm(formData)) return;

    try {
      const postData: tFieldPostData = {
        name: formData.name.value || "",
        type: formData?.type?.value,
        fiellable_type: isPeoplePage ? "person" : "company",
        ...(isDropdownFieldType && {
          options: fields.map(item => ({
            value: item?.id || `${item.value.split(" ").join("_")}_${uid()}`,
            name: item.value
          }))
        })
      };
      const res = !field && (await call(fieldsApi.addNewField(postData)));
      const resUpdate = field && (await call(fieldsApi.updateField(field?.id, postData)));

      onClose();
      getFieldList();
      eventBus.dispatch("showToast", {
        text: typeof res !== "boolean" && res ? res?.data?.message : resUpdate?.data?.message
      });
    } catch (error) {
      const {msg} = getApiError(error, formData);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <ConfirmWindow
      show={show}
      onClose={onClose}
      onConfirm={onSubmit}
      cancelBtn={{text: "Cancel"}}
      confirmBtn={{text: "Save"}}
      title={field ? "Edit Field" : "Add Field"}
      className="ManageFieldsForm"
      isLoading={isLoading}
      withoutPaddings
    >
      {isDropdownFieldType && (
        <div className="ManageFieldsForm-note">
          This field will be {field ? "updated" : "added"} across all contacts
        </div>
      )}
      <form onSubmit={onSubmit} className="ManageFieldsForm-body">
        {typesLoading && (
          <div className="loaderWrap">
            <Loading type="inBlock" />
          </div>
        )}

        <InputField {...formData.name.inputProps} label="NAME" errors={formData.name.errors} />
        <Select
          {...formData.type.inputProps}
          label="Field Type"
          options={fieldsTypeList}
          errors={formData.type.errors}
          disabled={!!field}
          placeholder="Select field type"
        />

        {isDropdownFieldType && (
          <FieldSetup name={formData.name.value} fields={fields} setFields={setFields} />
        )}
      </form>
    </ConfirmWindow>
  );
};
