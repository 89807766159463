import {tCallSHeetGroupContacts, tCallSheetList} from "api/callSheet/types";

export function onSortName(arr: tCallSheetList[]): tCallSheetList[] {
  return arr.sort((a, b) => a.name.localeCompare(b.name));
}

export function getDeliveryContactsId(groups: tCallSHeetGroupContacts[]): (number | string)[] {
  return groups.reduce((acc: (number | string)[], group: tCallSHeetGroupContacts) => {
    return [...acc, ...group.contacts.filter(item => item.selected).map(contact => contact.id)];
  }, []);
}
