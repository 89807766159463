import {FC, Fragment} from "react";
import {tTrack} from "api/budget/track/types";
import {Page, Document, StyleSheet, Text, View} from "@react-pdf/renderer";
import {Footer} from "../../PdfTemplates/a2/Footer";
import {HeaderBreakdown} from "../../PdfTemplates/a2/HeaderBreakdown";
import {tInvoice} from "api/invoice/types";
import {getUsMoneyFormat} from "helpers/formattingData";

interface iExpenseTableProps {
  expenses: tTrack;
  invoice: tInvoice;
}

export const ExpenseTable: FC<iExpenseTableProps> = ({expenses, invoice}) => {
  let estimates = new Set();
  expenses.categories.forEach(cat => {
    cat.cost_values.forEach(value => estimates.add(value?.estimate?.name));
  }, []);

  const isMultipleEstimate = estimates.size > 1;

  return (
    <Document>
      <Page size="A2" style={styles.container}>
        <HeaderBreakdown
          jobName={invoice.job_name}
          jobNumber={invoice.job_number}
          photographer={invoice.photographer}
          po={invoice.po}
          title="Invoice breakdown"
        />
        <View style={styles.body}>
          {expenses.categories.map(cat => {
            return (
              <View key={cat.category.id}>
                <View style={styles.header}>
                  <Text style={styles.headerCode}>{cat.category.code}</Text>
                  <Text style={styles.headerName}>{cat.category.name}</Text>
                  <Text style={styles.headerTotal}>total</Text>
                </View>
                {cat.cost_values.map((value, index, array) => {
                  const isShowEstimate =
                    (array?.[index - 1] &&
                      isMultipleEstimate &&
                      array[index - 1]?.estimate?.name) !== value?.estimate?.name;

                  return (
                    <Fragment key={value.cost_value.id}>
                      {isShowEstimate && index === 0 && (
                        <View>
                          <Text style={styles.estimateNameRow}>{value?.estimate?.name}</Text>
                        </View>
                      )}
                      <View key={value.cost_value.id} style={styles.row}>
                        <Text style={styles.rowCode}>
                          {value.cost_value.code + value.cost_value.suffix}
                        </Text>
                        <View style={styles.rowName}>
                          <Text style={styles.cellName}>{value.cost_value.name}</Text>
                          {value.cost_value.description && (
                            <Text style={styles.cellDescription}>
                              {value.cost_value.description}
                            </Text>
                          )}
                          {value.cost_value.discount_description && (
                            <Text style={styles.cellDiscount}>
                              {value.cost_value.discount_description}
                            </Text>
                          )}
                        </View>

                        <Text style={styles.rowTotal}>
                          {getUsMoneyFormat(value.cost_value_expense_total.total_actual)}
                        </Text>
                      </View>
                    </Fragment>
                  );
                })}
                <View style={styles.totalRow}>
                  <Text style={styles.totalRowTitle}>Total</Text>
                  <Text style={styles.totalRowValue}>
                    {getUsMoneyFormat(cat.category_total.total_actual)}
                  </Text>
                </View>
              </View>
            );
          })}

          <View style={styles.grandTotalRow}>
            <Text style={styles.totalRowTitle}>TOTAL ACTUAL COSTS</Text>
            <Text style={styles.totalRowValue}>
              {getUsMoneyFormat(expenses.grand_total.total_actual)}
            </Text>
          </View>
        </View>

        <Footer />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 80,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%"
  },
  body: {
    flex: "1 1 100%"
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    paddingBottom: 5,
    borderBottom: "2px solid black",
    marginBottom: 20
  },
  headerCode: {
    display: "flex",
    flexDirection: "row",
    fontFamily: "Helvetica700",
    fontSize: 17,
    width: "7%"
  },
  headerName: {
    display: "flex",
    flexDirection: "row",
    fontFamily: "Helvetica700",
    textTransform: "uppercase",
    fontSize: 17,
    width: "70%"
  },
  headerTotal: {
    display: "flex",
    flexDirection: "row",
    fontFamily: "Helvetica700",
    textAlign: "right",
    justifyContent: "flex-end",
    textTransform: "uppercase",
    fontSize: 10,
    width: "23%"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: 8
  },
  rowCode: {display: "flex", flexDirection: "row", fontSize: 14, width: "7%"},
  rowName: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    fontSize: 14
  },
  cellName: {
    fontFamily: "Helvetica500"
  },
  cellDescription: {
    color: "#7E7E80"
  },
  cellDiscount: {
    color: "#D2232A"
  },
  rowTotal: {
    display: "flex",
    flexDirection: "row",
    width: "23%",
    fontSize: 14,
    justifyContent: "flex-end",
    textAlign: "right"
  },
  grandTotalRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: 42
  },
  totalRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: 16,
    marginBottom: 42
  },
  totalRowTitle: {
    fontSize: 15,
    fontFamily: "Helvetica700",
    textTransform: "uppercase"
  },
  totalRowValue: {fontSize: 15, fontFamily: "Helvetica700", textAlign: "right", width: 170},
  estimateNameRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: 6,
    marginBottom: 6,
    textTransform: "uppercase",
    color: "#7E7E80",
    fontFamily: "Helvetica500",
    fontSize: 10,
    minHeight: 16
  }
});
