import {FC} from "react";
import {AdvanceSelection} from "../AdvanceSelection/AdvanceSelection";
import {AdvanceManagement} from "../AdvanceManagement/AdvanceManagement";

interface iAdvanceHeaderProps {}

export const AdvanceHeader: FC<iAdvanceHeaderProps> = () => {
  return (
    <>
      <AdvanceSelection />
      <AdvanceManagement />
    </>
  );
};
