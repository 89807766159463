import {FC, InputHTMLAttributes} from "react";
import {Icon, iPropsIcon} from "components/Icon/Icon";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {Loading} from "components/shared/Loading/Loading";
import {uid} from "helpers/misc";
import "./UploadFileButton.scss";

type tUploadFileButtonProps = {
  callBack: (e: React.ChangeEvent<HTMLInputElement>) => void;
  title?: string;
  icon?: iPropsIcon;
  isLoading?: boolean;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
};

export const UploadFileButton: FC<tUploadFileButtonProps> = ({
  callBack,
  title = "Upload file",
  icon,
  isLoading,
  inputProps
}) => {
  const id = uid();
  return (
    <TextComponent
      htmlFor={id}
      as="label"
      size="10"
      className="UploadFileButton posRelative"
      uppercase
    >
      {isLoading && <Loading type="inBlock" customSize={0.5} />}
      {icon && <Icon {...icon} />}
      {title}
      <input {...inputProps} type="file" onChange={callBack} id={id} />
    </TextComponent>
  );
};
