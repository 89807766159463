import {FC, useEffect, useState} from "react";
import {tTrackCostValuesItem} from "api/budget/track/types";
import {CostValueRow} from "./CostValueRow/CostValueRow";
import {ExpenseRow} from "./ExpenseRow/ExpenseRow";
import {useChangeExpenseRowOrder} from "../../useChangeExpenseRowOrder";
import "./CategoryBody.scss";

type tCategoryBodyProps = {
  trackCost: tTrackCostValuesItem;
  isFirstItem?: boolean;
};

export const CategoryBody: FC<tCategoryBodyProps> = ({trackCost, isFirstItem}) => {
  const [data, setData] = useState<tTrackCostValuesItem>(trackCost);
  const {dropCallBack, setCurrItem, isLoading} = useChangeExpenseRowOrder({setData});

  useEffect(() => {
    setData(trackCost);
  }, [trackCost]);

  return (
    <>
      <CostValueRow
        trackCost={data?.cost_value}
        estimate={data?.estimate?.name || ""}
        costValueExpenseTotal={data?.cost_value_expense_total}
        isFirstItem={isFirstItem}
      />

      {data?.expense_lines?.map(trackLine => {
        return (
          <ExpenseRow
            key={trackLine?.id}
            trackLine={trackLine}
            setCurrItem={setCurrItem}
            isLoading={isLoading}
            dropCallBack={currItem => dropCallBack(currItem, trackCost)}
          />
        );
      })}
    </>
  );
};
