import {ChangeEvent, FC, useMemo} from "react";
import {CheckBoxGroupe} from "components/shared/FormComponents/CheckBoxGroupe/CheckBoxGroupe";
import {iField} from "api/fields/types";

interface iCustomCheckboxProps {
  field: iField;
  onChange: (value: iField) => void;
}

export const CustomCheckbox: FC<iCustomCheckboxProps> = ({field, onChange}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {checked, value} = event.target;
    const prevValue = field?.value || [];
    const newValue = checked
      ? [...prevValue, field.options.find((i: any) => i.value === value)?.value]
      : field?.value?.filter((v: string) => v !== value) || [];

    onChange({...field, value: newValue});
  };

  const options = useMemo(() => {
    if (!field?.options) return [];
    return field.options.map(option => ({...option, id: option.value}));
  }, [field?.options]);

  return (
    <CheckBoxGroupe
      initValue={field?.value}
      label={field.name}
      checkBoxList={options}
      onChange={handleChange}
    />
  );
};
