import {CSSProperties, FC, useEffect, useRef, useState} from "react";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {Loading} from "components/shared/Loading/Loading";
import {getUsMoneyFormat} from "helpers/formattingData";
import "./InputCell.scss";

export type tInputCellProps = {
  value: string;
  placeholder?: string;
  isLoading?: boolean;
  callBack: (value: string) => void;
  className?: string;
  needToCleanValueAfterSubmit?: boolean;
  InputComponent?: "input" | "textarea";
  // setValue: React.Dispatch<React.SetStateAction<string>>;
  setValue: (value: string) => void;
  initValue?: string;
  style?: CSSProperties;
  alwaysShowPlaceholder?: boolean;
  withBorder?: boolean;
  prefix?: string;
  truncate?: boolean;
  manySeparator?: boolean;
  percent?: boolean;
};

export const InputCell: FC<tInputCellProps> = props => {
  const {value, placeholder = "", isLoading = false, callBack, className = "", withBorder, prefix, truncate} = props; // prettier-ignore
  const {alwaysShowPlaceholder, needToCleanValueAfterSubmit, InputComponent, setValue, initValue, style, manySeparator, percent} = props; // prettier-ignore
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef<(HTMLInputElement & HTMLTextAreaElement) | null>(null);

  const [height, setHeight] = useState("auto");
  const textRef = useRef<HTMLDivElement | null>(null);

  const showInputField = () => {
    setShowInput(true);
    if (!textRef?.current?.clientHeight) return;
    setHeight(textRef?.current?.clientHeight + "px");
  };

  useEffect(() => {
    if (showInput && inputRef) {
      inputRef?.current?.focus();
      inputRef?.current?.setSelectionRange(value?.length, value?.length);
    }
  }, [showInput]); // eslint-disable-line

  const onSubmit = () => {
    setShowInput(false);
    if (initValue === value) return;
    setHeight("auto");

    callBack(value);
    needToCleanValueAfterSubmit && setValue("");
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    e.key === "Enter" && onSubmit();
  };

  const Component = InputComponent || "textarea";
  const customClass = value ? "InputCell-text" : "InputCell-placeholder";
  const showPlaceholder = alwaysShowPlaceholder ? "showPlaceholder" : "";
  const withBorderInput = withBorder ? "withBorder" : "";
  const truncateClass = truncate ? "truncate" : "";

  const pre = prefix ? prefix : "";

  const displayVal = () => {
    if (manySeparator) {
      return getUsMoneyFormat(value);
    }
    if (percent) {
      return `${value}%`;
    }

    return value;
  };

  return (
    <div
      className={`InputCell ${className} ${showPlaceholder} ${truncateClass} `}
      ref={textRef}
      // style={{minHeight: textRef?.current?.clientHeight + "px", ...style}}
      style={{minHeight: height, ...style}}
    >
      {isLoading && <Loading type="inBlock" customSize={0.5} />}

      {!showInput && (
        <TextComponent
          as="div"
          size="13"
          weight="400"
          color={value ? "black-10" : "gray-10"}
          className={needToCleanValueAfterSubmit ? "InputCell-text" : customClass}
          onClick={showInputField}
          w100
        >
          {pre}
          {displayVal() || placeholder}
        </TextComponent>
      )}

      {showInput && (
        <Component
          // style={{minHeight: textRef?.current?.clientHeight + "px"}}
          style={{minHeight: height}}
          ref={inputRef}
          className={`InputCell-input ${withBorderInput}`}
          onFocus={() => setShowInput(true)}
          onBlur={onSubmit}
          onKeyPress={onKeyPress}
          value={value}
          onChange={e => setValue(e.target.value)}
          disabled={isLoading}
          // placeholder={placeholder}
        />
      )}
    </div>
  );
};
