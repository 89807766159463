import {FC} from "react";
import {tTrack} from "api/budget/track/types";
import {ChapterHeader} from "../../../OverlayTemplates/ChapterHeader/ChapterHeader";
import {getUsMoneyFormat} from "helpers/formattingData";
import "./InvoiceProductionCosts.scss";

interface iInvoiceProductionCostsProps {
  data: tTrack | null;
}

export const InvoiceProductionCosts: FC<iInvoiceProductionCostsProps> = ({data}) => {
  if (!data) return null;
  const {categories, payroll_fees} = data;

  return (
    <div className="InvoiceProductionCosts">
      <ChapterHeader>Production costs</ChapterHeader>

      {categories
        .filter(cat => cat.category_total.total_actual)
        .map(({category: {id, code, name}, category_total: {total_actual}}) => {
          return (
            <div key={id} className="InvoiceProductionCosts-row">
              <div>{code}</div>
              <div className="InvoiceProductionCosts-row-name">{name}</div>
              <div className="InvoiceProductionCosts-row-total">
                {getUsMoneyFormat(total_actual)}
              </div>
            </div>
          );
        })}

      {Object.values(payroll_fees).map(({target_name, total_actual}) => {
        return (
          <div key={target_name} className="InvoiceProductionCosts-row">
            <div />
            <div className="InvoiceProductionCosts-row-name">
              {target_name.split("_").join(" ")}
            </div>
            <div className="InvoiceProductionCosts-row-total">{getUsMoneyFormat(total_actual)}</div>
          </div>
        );
      })}
      <div className="InvoiceProductionCosts-totalRow">
        <div className="InvoiceProductionCosts-totalRow-name">Total Costs </div>
        <div className="InvoiceProductionCosts-totalRow-total">
          {getUsMoneyFormat(data?.total?.total_actual)}
        </div>
      </div>
    </div>
  );
};
