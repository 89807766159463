import {FC} from "react";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {ContactBlock} from "../common/ContactBlock";
import {Title} from "../common/Title";

interface iLeadContactProps {
  [key: string]: any;
}

export const LeadContact: FC<iLeadContactProps> = () => {
  const {jobDetailsData} = useContextJobDetails();
  const people = jobDetailsData?.people;

  const leadContact = people?.find(item => item?.is_lead);

  if (!leadContact) return null;
  return (
    <div className="LeadContact">
      <Title text="Lead Contact" />
      <ContactBlock
        title={`${leadContact?.person?.first_name || ""} ${leadContact?.person?.last_name || ""}`}
        subTitle={leadContact?.title?.name}
        imgUrl={leadContact?.person?.avatar_url || ""}
        id={leadContact?.person?.id}
      />
    </div>
  );
};
