import {FC, useState} from "react";
import {LetterAvatar} from "components/shared/LetterAvatar/LetterAvatar";
import {iTeamListItemReturnData} from "api/team/types";
import {TeamModals} from "components/shared/Modals/TeamModals/TeamModals";
import {DepartmentList} from "components/shared/DepartmentList/DepartmentList";
import {getDateFormat} from "helpers/dates";
import {useContextTeam} from "../TeamContext";
import {ButtonCopyClipboard} from "components/shared/ButtonCopyClipboard/ButtonCopyClipboard";
import "./TeamTableRow.scss";

interface iTeamTableRowProps {
  teammate: iTeamListItemReturnData;
}

export const TeamTableRow: FC<iTeamTableRowProps> = ({teammate}) => {
  const {getTeamList} = useContextTeam();
  const [showModal, setShowModal] = useState<number | null>(null);
  const closeTeamModal = (callback?: Function) => {
    callback?.();
    setShowModal(null);
  };

  const {id, title, display_name, email, phone, role} = teammate;
  const {status, created_at, home_address, departments} = teammate;
  const statusName = status?.name?.toLowerCase() || "";

  const location =
    home_address?.country?.iso === "US" ? home_address?.state : home_address?.country?.nicename;

  return (
    <>
      <tr key={id} className={`TeamTableRow ${statusName}`} onClick={() => setShowModal(id)}>
        <td className="TeamTableRow-cell name">
          <LetterAvatar display_name={display_name} />
          <div className="text">{display_name}</div>
        </td>
        <td className="TeamTableRow-cell">
          <DepartmentList
            data={departments?.map(dep => ({id: dep?.id, avatar: null, name: dep?.name}))}
          />
        </td>
        <td className="TeamTableRow-cell capitalize">
          <span className="title">{title}</span>
        </td>
        <td className="TeamTableRow-cell">
          <a className="email" href={`mailto:${email}`} onClick={e => e.stopPropagation()}>
            {email}
          </a>
        </td>
        <td className="TeamTableRow-cell phone">
          <span className="number">{phone}</span>
          {/* <span className="number">{formatPhoneNumber({value: phone, separator: "-"})}</span> */}
          {phone && <ButtonCopyClipboard elementForCopy={phone} prefixToastMessage="Number" />}
        </td>
        <td className="TeamTableRow-cell capitalize">{location || ""}</td>
        <td className="TeamTableRow-cell capitalize">{role?.name}</td>
        <td className={`TeamTableRow-cell capitalize ${statusName}`}>{status?.name}</td>
        <td className="TeamTableRow-cell">
          {created_at && getDateFormat(created_at, "MMM D, YYYY")}
        </td>
      </tr>

      {showModal && (
        <TeamModals
          teammate={showModal}
          closeTeamModal={closeTeamModal}
          onEditCallback={getTeamList}
        />
      )}
    </>
  );
};
