import {FC, useEffect, useState} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {iCompanyDetails} from "api/contacts/companies/interfaces";
import {useHttp} from "hooks/httpServices/useHttp";
import {companies} from "api/contacts/companies";
import {CompanyDetails} from "components/shared/Modals/CompanyModals/CompanyDetails/CompanyDetails";
import {CompanyForm} from "pages/Contacts/Companies/CompanyForm/CompanyForm";
import {PeopleModals} from "components/shared/Modals/PeopleModals/PeopleModals";
import {Loading} from "components/shared/Loading/Loading";
import {ModalConfirmCancelEditing} from "components/shared/ModalConfirmCancelEditing/ModalConfirmCancelEditing";
import "./CompanyModals.scss";

interface iCompaniesModals {
  company: number | iCompanyDetails;
  closeCompaniesModal: () => void;
  onEditCallback?: Function;
}

export const CompanyModals: FC<iCompaniesModals> = ({
  company,
  closeCompaniesModal,
  onEditCallback
}) => {
  const {call} = useHttp();
  const [companyDetails, setCompanyDetails] = useState<iCompanyDetails | null>(null);
  const [person, setPerson] = useState<number | null>(null);
  const [editModal, setEditModal] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);

  useEffect(() => {
    if (!company) return;
    if (typeof company === "number") {
      call(companies.getCompanyDetails(company)).then(({data: {data}}) => setCompanyDetails(data));
      return;
    }
    setCompanyDetails(company);
  }, [company]); // eslint-disable-line

  const closeModal = () => {
    setEditModal(false);
    closeCompaniesModal();
  };

  const onEditClose = () => {
    if (isChanged) {
      setConfirmClose(true);
    } else {
      setEditModal(false);
    }
  };

  const onConfrimCloseEdit = () => {
    setConfirmClose(false);
    setEditModal(false);
  };

  const handleEditCallback = (response: any) => {
    onEditCallback?.();
    const newDetails = response?.data?.data;
    setCompanyDetails(newDetails);
  };

  return (
    <>
      <ModalPopup
        className="CompanyModals"
        show={true}
        onClose={editModal ? onEditClose : closeModal}
      >
        {!companyDetails && <Loading type="inBlock" />}

        {companyDetails && !editModal && (
          <CompanyDetails
            showEditForm={() => setEditModal(true)}
            company={companyDetails}
            showPersonDetails={(id: number) => setPerson(id)}
          />
        )}

        {editModal && (
          <CompanyForm
            company={companyDetails}
            closeEdit={onEditClose}
            closeModal={closeModal}
            onEditCallback={handleEditCallback}
            setIsChangedFormData={setIsChanged}
          />
        )}

        {person && <PeopleModals person={person} closePeopleModal={() => setPerson(null)} />}
      </ModalPopup>
      <ModalConfirmCancelEditing
        show={confirmClose}
        onConfirm={onConfrimCloseEdit}
        onClose={() => setConfirmClose(false)}
      />
    </>
  );
};
