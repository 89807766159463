import {FC, FocusEvent, SetStateAction, Dispatch, useEffect, useRef, useState} from "react";
import {tCallSheetContact, tCallSheetContactLocation, tCallSHeetGroupContacts} from "api/callSheet/types"; // prettier-ignore
import {useContextJobCallSheet} from "pages/Jobs/JobDetails/JobCallSheets/context/JobCallSheetContext";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {Button} from "components/shared/Button/Button";

type tLocationCellProps = {
  group: tCallSHeetGroupContacts;
  contact: tCallSheetContact;
  setContactGroup: Dispatch<SetStateAction<tCallSHeetGroupContacts[] | undefined>>;
};

export const LocationCell: FC<tLocationCellProps> = ({group, contact, setContactGroup}) => {
  const {callSheetInfo} = useContextJobCallSheet();
  const refInput = useRef<HTMLInputElement>(null);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const showMenu = (event: FocusEvent<HTMLInputElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  useEffect(() => {
    refInput.current && (refInput.current.value = contact?.location?.name || "");
  }, []); // eslint-disable-line

  useEffect(() => {
    refInput.current && (refInput.current.value = contact?.location?.name || "");
  }, [contact?.location?.name]);

  const setLocation = (location: tCallSheetContactLocation) => {
    changeLocation(location?.name, location?.id);
    refInput.current && (refInput.current.value = location?.name || "");
    refInput?.current?.focus();
    setMenuAnchor(null);
  };

  const onBlurHandler = () => {
    if (refInput?.current?.value === contact?.location?.name) return;
    changeLocation(refInput?.current?.value || "", null);
  };

  function changeLocation(value: string, id: number | null) {
    setContactGroup(prev => {
      return prev?.map(item => {
        if (item?.id !== group?.id) return item;
        const newContacts = item?.contacts?.map(cont =>
          String(cont?.id) === String(contact?.id)
            ? {
                ...cont,
                location: {id: id, name: value}
              }
            : cont
        );
        return {...item, contacts: newContacts};
      });
    });
  }

  return (
    <div className="LocationCell">
      <input
        type="text"
        className="LocationCell-input"
        onFocus={showMenu}
        onBlur={onBlurHandler}
        ref={refInput}
        placeholder="add location"
      />

      <DropdownMenu anchor={menuAnchor} onClose={() => setMenuAnchor(null)}>
        <Button size="sm" onClick={() => setLocation({id: null, name: "N/A"})}>
          N/A
        </Button>

        {callSheetInfo?.attributes?.locations?.map(item => {
          return (
            <Button
              key={item?.id}
              size="sm"
              // @ts-ignore
              onClick={() => setLocation({id: item?.id, name: item?.name})}
            >
              {item?.name}
            </Button>
          );
        })}
      </DropdownMenu>
    </div>
  );
};
