import {AxiosRequestConfig} from "axios";
import {tCreditCardPostData} from "./types";

export const creditCardAndPayroll = {
  //Credit card
  getCreditCardTypes: (): AxiosRequestConfig => ({
    url: "credit_card_types"
  }),
  getCreditCards: (): AxiosRequestConfig => ({
    url: "credit_cards"
  }),
  addCreditCard: (data: tCreditCardPostData): AxiosRequestConfig => ({
    url: "credit_cards",
    method: "POST",
    data
  }),
  updateCreditCard: (creditCarId: number, data: tCreditCardPostData): AxiosRequestConfig => ({
    url: `credit_card/${creditCarId}`,
    method: "POST",
    data
  }),
  deleteCreditCard: (creditCarId: number): AxiosRequestConfig => ({
    url: `credit_card/${creditCarId}`,
    method: "DELETE"
  }),
  //Payroll companies
  getPayrollCompanies: (): AxiosRequestConfig => ({
    url: "payroll_companies"
  }),
  addPayrollCompany: (data: {name: string}): AxiosRequestConfig => ({
    url: "payroll_companies",
    method: "POST",
    data
  }),
  updatePayrollCompany: (payrollCompanyId: number, data: {name: string}): AxiosRequestConfig => ({
    url: `payroll_company/${payrollCompanyId}`,
    method: "POST",
    data
  }),
  deletePayrollCompany: (payrollCompanyId: number): AxiosRequestConfig => ({
    url: `payroll_company/${payrollCompanyId}`,
    method: "DELETE"
  })
};
