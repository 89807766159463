import {FC} from "react";
import {Button} from "components/shared/Button/Button";
import {ContactBlock} from "../../common/ContactBlock";
import {Title} from "../../common/Title";

type tLinksPlaceholderProps = {
  setModal: React.Dispatch<React.SetStateAction<"job" | "url" | null>>;
};

export const LinksPlaceholder: FC<tLinksPlaceholderProps> = ({setModal}) => {
  return (
    <>
      <Title text="Links" fullWidth />
      <ContactBlock placeholder="Links will appear here" textCenter />
      <div className="Links-buttonWrap">
        <Button color="outline" size="sm" onClick={() => setModal("url")}>
          Add url
        </Button>
        <Button color="outline" size="sm" onClick={() => setModal("job")}>
          Add job
        </Button>
      </div>
    </>
  );
};
