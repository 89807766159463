export const LogoText = () => {
  return (
    <svg
      id="logo-text"
      className="logo-text"
      viewBox="0 0 530 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.05 36.2292H17.25L24.33 0.939177H34.33L23.33 48.6692H11L0 0.939177H10.3L17.05 36.2292Z"
        fill="currentColor"
      />
      <path
        d="M64.51 8.81918H47.51V20.0592H63.51V27.9992H47.51V40.7792H65.18V48.6692H37.84V0.939177H64.51V8.81918Z"
        fill="currentColor"
      />
      <path
        d="M87.78 0.939177C91.78 0.939177 94.9 1.93918 97.14 3.93918C99.38 5.93918 100.493 9.02584 100.48 13.1992C100.596 15.9474 99.9232 18.6715 98.54 21.0492C97.8607 22.0768 96.9777 22.9541 95.9457 23.6267C94.9136 24.2992 93.7544 24.7528 92.54 24.9592V25.0992C94.519 25.2514 96.3861 26.0773 97.83 27.4392C98.9367 28.6392 99.6267 30.5758 99.9 33.2492C99.99 34.1492 100.05 35.1092 100.1 36.1592C100.15 37.2092 100.19 38.3792 100.23 39.6692C100.32 42.2092 100.46 44.1492 100.63 45.4892C100.697 46.0469 100.888 46.5824 101.189 47.0566C101.49 47.5308 101.894 47.9314 102.37 48.2292V48.6292H91.92C91.4345 47.9579 91.1189 47.179 91 46.3592C90.8576 45.4652 90.7674 44.5637 90.73 43.6592L90.46 34.4992C90.4658 32.9159 89.9763 31.3704 89.06 30.0792C88.21 29.0092 86.79 28.4792 84.78 28.4792H79.62V48.6692H70V0.939177H87.78ZM83.63 21.7892C85.5593 21.9223 87.4702 21.3422 89 20.1592C90.25 19.0592 90.87 17.2292 90.87 14.6392C90.8567 10.2125 88.6233 7.99918 84.17 7.99918H79.62V21.7892H83.63Z"
        fill="currentColor"
      />
      <path
        d="M114.92 35.1692C114.914 36.1755 115.005 37.18 115.19 38.1692C115.335 39.0047 115.678 39.7934 116.19 40.4692C116.718 41.1244 117.407 41.6309 118.19 41.9392C119.179 42.329 120.237 42.5127 121.3 42.4792C122.762 42.4899 124.18 41.9794 125.3 41.0392C125.898 40.4826 126.362 39.7973 126.657 39.035C126.951 38.2727 127.069 37.4535 127 36.6392C127.022 35.6944 126.876 34.7532 126.57 33.8592C126.264 33.0548 125.77 32.3349 125.13 31.7592C124.346 31.0535 123.455 30.4763 122.49 30.0492C121.172 29.4468 119.82 28.9226 118.44 28.4792C116.528 27.8646 114.674 27.0816 112.9 26.1392C111.438 25.3611 110.113 24.3476 108.98 23.1392C107.934 21.9869 107.15 20.6225 106.68 19.1392C106.186 17.472 105.956 15.7376 106 13.9992C106 9.27918 107.32 5.74918 110 3.43918C112.68 1.12918 116.15 -0.000823235 120.74 -0.000823235C122.732 -0.0204141 124.718 0.214828 126.65 0.699177C128.356 1.12342 129.952 1.90763 131.33 2.99918C132.665 4.0636 133.722 5.43623 134.41 6.99918C135.187 8.8493 135.562 10.8433 135.51 12.8492V14.1792H126.29C126.402 12.3523 125.981 10.5322 125.08 8.93918C124.632 8.30967 124.027 7.80851 123.325 7.48567C122.624 7.16283 121.849 7.02957 121.08 7.09918C120.168 7.06908 119.259 7.22562 118.41 7.55918C117.752 7.83555 117.167 8.25987 116.7 8.79918C116.277 9.3142 115.98 9.91999 115.83 10.5692C115.677 11.2519 115.6 11.9495 115.6 12.6492C115.555 13.95 115.881 15.2367 116.54 16.3592C117.16 17.3592 118.493 18.2825 120.54 19.1292L128 22.3292C129.583 22.9948 131.086 23.8368 132.48 24.8392C133.549 25.6165 134.484 26.5621 135.25 27.6392C135.929 28.6586 136.404 29.7995 136.65 30.9992C136.929 32.3139 137.063 33.6552 137.05 34.9992C137.05 40.0392 135.59 43.7058 132.67 45.9992C129.75 48.2925 125.683 49.4425 120.47 49.4492C115.03 49.4492 111.14 48.2658 108.8 45.8992C106.46 43.5325 105.29 40.1325 105.29 35.6992V33.7592H114.92V35.1692Z"
        fill="currentColor"
      />
      <path
        d="M163.06 0.939177L175.69 48.6692H165.69L163.49 38.5792H150.75L148.55 48.6692H138.55L151.16 0.939177H163.06ZM161.79 30.6892L157.17 9.22918H157L152.39 30.6892H161.79Z"
        fill="currentColor"
      />
      <path
        d="M203.3 8.81918H192.87V48.6692H183.25V8.81918H172.82V0.939177H203.3V8.81918Z"
        fill="currentColor"
      />
      <path d="M207.92 0.939177H217.54V48.6692H207.92V0.939177Z" fill="currentColor" />
      <path d="M224.7 0.939177H234.32V40.7792H251.37V48.6692H224.7V0.939177Z" fill="currentColor" />
      <path
        d="M282.32 8.81918H265.32V20.0592H281.32V27.9992H265.32V40.7792H283V48.6692H255.65V0.939177H282.32V8.81918Z"
        fill="currentColor"
      />
      <path
        d="M311.6 35.1692C311.595 36.1755 311.685 37.18 311.87 38.1692C312.015 39.0047 312.358 39.7934 312.87 40.4692C313.398 41.1244 314.087 41.6309 314.87 41.9392C315.859 42.3279 316.917 42.5116 317.98 42.4792C319.442 42.4899 320.86 41.9794 321.98 41.0392C322.597 40.4851 323.077 39.7962 323.384 39.0261C323.691 38.256 323.816 37.4255 323.75 36.5992C323.772 35.6544 323.626 34.7132 323.32 33.8192C323.014 33.0148 322.52 32.2949 321.88 31.7192C321.096 31.0135 320.205 30.4363 319.24 30.0092C317.938 29.4098 316.602 28.8856 315.24 28.4392C313.325 27.8246 311.467 27.0415 309.69 26.0992C308.23 25.3216 306.909 24.3079 305.78 23.0992C304.728 21.9499 303.94 20.5848 303.47 19.0992C302.951 17.4225 302.701 15.6741 302.73 13.9192C302.73 9.19918 304.05 5.66918 306.68 3.35918C309.31 1.04918 312.83 -0.000823235 317.42 -0.000823235C319.415 -0.0197383 321.404 0.215487 323.34 0.699177C325.038 1.13036 326.625 1.91402 328 2.99918C329.332 4.06421 330.386 5.43689 331.07 6.99918C331.852 8.84778 332.227 10.8429 332.17 12.8492V14.1792H323C323.112 12.3523 322.691 10.5322 321.79 8.93918C321.342 8.30966 320.737 7.80851 320.035 7.48567C319.334 7.16283 318.559 7.02957 317.79 7.09918C316.878 7.06908 315.969 7.22562 315.12 7.55918C314.464 7.83833 313.879 8.26223 313.41 8.79918C312.987 9.3142 312.69 9.91998 312.54 10.5692C312.387 11.2519 312.31 11.9495 312.31 12.6492C312.265 13.95 312.591 15.2367 313.25 16.3592C313.87 17.3592 315.203 18.2825 317.25 19.1292L324.67 22.3392C326.254 23.0025 327.757 23.8447 329.15 24.8492C330.219 25.6265 331.154 26.5721 331.92 27.6492C332.601 28.6648 333.08 29.8022 333.33 30.9992C333.609 32.3139 333.743 33.6552 333.73 34.9992C333.73 40.0392 332.27 43.7058 329.35 45.9992C326.43 48.2925 322.363 49.4425 317.15 49.4492C311.71 49.4492 307.823 48.2658 305.49 45.8992C303.157 43.5325 301.993 40.1325 302 35.6992V33.7592H311.62L311.6 35.1692Z"
        fill="currentColor"
      />
      <path
        d="M366.49 8.81918H356.06V48.6692H346.43V8.81918H336V0.939177H366.49V8.81918Z"
        fill="currentColor"
      />
      <path
        d="M379.66 34.2292C379.655 35.3367 379.732 36.443 379.89 37.5392C380.021 38.4543 380.316 39.3383 380.76 40.1492C381.178 40.8689 381.778 41.4656 382.5 41.8792C383.353 42.3356 384.313 42.5565 385.28 42.5192C386.254 42.564 387.222 42.3428 388.08 41.8792C388.791 41.4614 389.381 40.8651 389.79 40.1492C390.234 39.3383 390.529 38.4543 390.66 37.5392C390.818 36.443 390.895 35.3367 390.89 34.2292V0.939177H400.52V34.2292C400.599 36.7952 400.167 39.3514 399.25 41.7492C398.517 43.575 397.346 45.1925 395.84 46.4592C394.433 47.6093 392.775 48.4111 391 48.7992C389.134 49.2066 387.23 49.4111 385.32 49.4092C383.41 49.4146 381.505 49.2337 379.63 48.8692C377.857 48.5345 376.186 47.7916 374.75 46.6992C373.227 45.4734 372.051 43.8702 371.34 42.0492C370.387 39.5551 369.932 36.8983 370 34.2292V0.939177H379.63L379.66 34.2292Z"
        fill="currentColor"
      />
      <path
        d="M422.85 0.939177C425.639 0.81995 428.415 1.37929 430.94 2.56918C432.952 3.63402 434.613 5.25969 435.72 7.24918C436.931 9.52669 437.711 12.0083 438.02 14.5692C438.44 17.7382 438.641 20.9325 438.62 24.1292C438.66 27.787 438.406 31.4422 437.86 35.0592C437.482 37.7604 436.582 40.3622 435.21 42.7192C434.025 44.6754 432.284 46.2349 430.21 47.1992C427.698 48.2605 424.985 48.7622 422.26 48.6692H407.07V0.939177H422.85ZM421 41.5792C422.409 41.6431 423.81 41.3435 425.07 40.7092C426.177 40.0471 427.035 39.0383 427.51 37.8392C428.195 36.1558 428.604 34.3729 428.72 32.5592C428.92 30.3792 429.02 27.6592 429.02 24.3992C429.02 21.6792 428.93 19.2958 428.75 17.2492C428.635 15.498 428.264 13.7731 427.65 12.1292C427.169 10.8691 426.301 9.79417 425.17 9.05918C423.833 8.32003 422.315 7.97339 420.79 8.05918H416.7V41.5792H421Z"
        fill="currentColor"
      />
      <path d="M445.44 0.939177H455.07V48.6692H445.44V0.939177Z" fill="currentColor" />
      <path
        d="M461.72 14.8092C461.972 12.0925 462.721 9.44523 463.93 6.99918C465.028 4.85 466.736 3.07251 468.84 1.88918C471.554 0.67586 474.493 0.0487147 477.465 0.0487147C480.437 0.0487147 483.376 0.67586 486.09 1.88918C488.194 3.07251 489.902 4.85 491 6.99918C492.219 9.45892 492.969 12.1245 493.21 14.8592C493.556 18.1811 493.723 21.5193 493.71 24.8592C493.723 28.1991 493.556 31.5372 493.21 34.8592C492.964 37.5706 492.214 40.2125 491 42.6492C489.914 44.7795 488.2 46.5249 486.09 47.6492C483.359 48.7974 480.427 49.3889 477.465 49.3889C474.503 49.3889 471.571 48.7974 468.84 47.6492C466.73 46.5249 465.016 44.7795 463.93 42.6492C462.717 40.2014 461.967 37.55 461.72 34.8292C461.374 31.5072 461.207 28.1691 461.22 24.8292C461.207 21.4826 461.374 18.1378 461.72 14.8092ZM471.08 32.9992C471.168 34.8574 471.484 36.6978 472.02 38.4792C472.348 39.6896 473.048 40.7668 474.02 41.5592C475.057 42.1938 476.249 42.5296 477.465 42.5296C478.681 42.5296 479.873 42.1938 480.91 41.5592C481.882 40.7668 482.582 39.6896 482.91 38.4792C483.446 36.6978 483.762 34.8574 483.85 32.9992C484.01 30.7492 484.08 27.9992 484.08 24.8092C484.08 21.6192 484.01 18.8892 483.85 16.6592C483.758 14.8014 483.442 12.9614 482.91 11.1792C482.587 9.95782 481.887 8.86945 480.91 8.06918C479.873 7.43457 478.681 7.09874 477.465 7.09874C476.249 7.09874 475.057 7.43457 474.02 8.06918C473.043 8.86945 472.343 9.95782 472.02 11.1792C471.488 12.9614 471.172 14.8014 471.08 16.6592C470.92 18.8892 470.85 21.5992 470.85 24.8092C470.85 28.0192 470.92 30.7392 471.08 32.9992Z"
        fill="currentColor"
      />
      <path
        d="M507.15 35.1692C507.144 36.1755 507.235 37.18 507.42 38.1692C507.56 39.0061 507.904 39.7958 508.42 40.4692C508.948 41.1244 509.637 41.631 510.42 41.9392C511.409 42.3298 512.467 42.5136 513.53 42.4792C514.992 42.4899 516.41 41.9794 517.53 41.0392C518.147 40.4851 518.627 39.7962 518.934 39.0261C519.241 38.256 519.366 37.4255 519.3 36.5992C519.319 35.6538 519.17 34.7126 518.86 33.8192C518.557 33.0154 518.067 32.2955 517.43 31.7192C516.646 31.0135 515.755 30.4363 514.79 30.0092C513.471 29.4085 512.119 28.8843 510.74 28.4392C508.825 27.8246 506.967 27.0415 505.19 26.0992C503.732 25.3185 502.411 24.3052 501.28 23.0992C500.231 21.9496 499.446 20.5843 498.98 19.0992C498.456 17.4236 498.206 15.6744 498.24 13.9192C498.24 9.19918 499.55 5.66918 502.18 3.35918C504.81 1.04918 508.37 -0.000823235 513 -0.000823235C514.995 -0.0203917 516.984 0.214843 518.92 0.699177C520.612 1.12873 522.194 1.91268 523.56 2.99918C524.895 4.0636 525.952 5.43623 526.64 6.99918C527.417 8.8493 527.792 10.8433 527.74 12.8492V14.1792H518.51C518.619 12.3537 518.203 10.5351 517.31 8.93918C516.862 8.30967 516.257 7.80851 515.555 7.48567C514.854 7.16282 514.079 7.02957 513.31 7.09918C512.395 7.06996 511.483 7.22644 510.63 7.55918C509.975 7.83561 509.393 8.26005 508.93 8.79918C508.507 9.3142 508.21 9.91999 508.06 10.5692C507.902 11.2511 507.825 11.9492 507.83 12.6492C507.79 13.9482 508.112 15.2328 508.76 16.3592C509.793 17.6543 511.184 18.6174 512.76 19.1292L520.19 22.3392C521.77 23.0046 523.269 23.8466 524.66 24.8492C525.732 25.6263 526.671 26.5719 527.44 27.6492C528.132 28.6625 528.621 29.8001 528.88 30.9992C529.154 32.3146 529.289 33.6555 529.28 34.9992C529.28 40.0392 527.82 43.7058 524.9 45.9992C521.98 48.2925 517.913 49.4425 512.7 49.4492C507.26 49.4492 503.37 48.2658 501.03 45.8992C498.69 43.5325 497.52 40.1325 497.52 35.6992V33.7592H507.15V35.1692Z"
        fill="currentColor"
      />
    </svg>
  );
};
