import { AxiosRequestConfig } from "axios";
import { iSearchingContactParams, iSearchingContactResponse } from "./people/interfaces";

export const contactsApi = {
  searchContacts: (
    params: iSearchingContactParams
  ): AxiosRequestConfig<iSearchingContactResponse> => ({
    url: "contacts/search",
    params
  })
};
