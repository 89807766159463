import {FC, FormEvent} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {FormWrap} from "components/shared/FormWrap/FormWrap";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {notValidForm} from "helpers/misc";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useHttp} from "hooks/httpServices/useHttp";
import {creditCardAndPayroll} from "api/budget/creditCardAndPayroll";
import {tCreditCard} from "api/budget/creditCardAndPayroll/types";

type tPayrollCompanyEditFormProps = {
  goBack: () => void;
  onClose: () => void;
  companyData?: tCreditCard;
  onEditCallback?: () => void;
};

export const PayrollCompanyEditForm: FC<tPayrollCompanyEditFormProps> = ({
  goBack,
  onClose,
  companyData,
  onEditCallback
}) => {
  const {call, isLoading} = useHttp();
  const formId = "track-edit-payroll-company";

  const form = {
    name: useTextInput({
      value: companyData ? companyData?.name : "",
      validators: ["name"],
      filters: ["name"]
    })
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (notValidForm(form)) return;

    try {
      const data = {name: form?.name?.value || ""};

      if (!companyData) {
        await call(creditCardAndPayroll?.addPayrollCompany(data));
      }

      if (companyData) {
        await call(creditCardAndPayroll?.updatePayrollCompany(companyData?.id, data));
      }
      onEditCallback?.();
      goBack();
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <FormWrap
      headerTitle={companyData ? "Edit Payroll Company" : "Add Payroll Company"}
      idForm={formId}
      type="titleCenterAndTwoBtn"
      mainButtonColor="outline"
      submitTitle={"Save"}
      cancelTitle="Cancel"
      onCancel={onClose}
      onBack={goBack}
      withBackIcon
      isLoading={isLoading}
    >
      <form id={formId} onSubmit={onSubmit}>
        <InputField
          label="Payroll Company Name"
          {...form.name.inputProps}
          errors={form.name.errors}
        />
      </form>
    </FormWrap>
  );
};
