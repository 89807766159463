import {FC, useState} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {useContextJobCallSheet} from "../context/JobCallSheetContext";
import {callSheet} from "api/callSheet";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {eventBus} from "EventBus/EventBus";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";

interface iCallSheetRenameProps {
  onClose: () => void;
}

export const CallSheetRename: FC<iCallSheetRenameProps> = ({onClose}) => {
  const {jobDetailsData} = useContextJobDetails();
  const {callSheetInfo, callSheetId, getCallSheetList, setCallSheetInfo} = useContextJobCallSheet();
  const {call} = useHttp();
  const [isLoading, setIsLoading] = useState(false);

  const name = useTextInput({
    isRequired: true,
    validators: [lengthValidation(3)],
    value: callSheetInfo?.name || ""
  });

  const onConfirm = async () => {
    if (!name.checkValidity() || !callSheetId) return;
    setIsLoading(true);
    try {
      const res = await call(callSheet.updateCallSheet(+callSheetId, {name: name.value}));
      setCallSheetInfo(res?.data?.data); //FIXME need to improve
      jobDetailsData && getCallSheetList(jobDetailsData?.id);
      eventBus.dispatch("showToast", {
        text: res?.data?.message || "Call sheet successfully updated!"
      });
      onClose();
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ConfirmWindow
      title="RENAME CALL SHEET"
      subTitle="Enter a new name"
      show={true}
      onClose={onClose}
      className="CallSheetRename"
      onConfirm={onConfirm}
      confirmBtn={{
        text: "Rename"
      }}
      cancelBtn={{
        text: "Cancel"
      }}
      isLoading={isLoading}
    >
      <InputField {...name.inputProps} errors={name.errors} />
    </ConfirmWindow>
  );
};

function lengthValidation(minLength: number) {
  return {
    checkFn: (v: string) => Boolean(v?.length >= minLength),
    error: "The call sheet name must be at least 3 characters long"
  };
}
