import {FC} from "react";
import {Text, View, StyleSheet} from "@react-pdf/renderer";
import {tAdvanceDetails} from "api/budget/advance/types";
import {SectionTitle} from "./SectionTitle";
import {Label, Value} from "./common";
import {getUsMoneyFormat, getPercentFormat} from "helpers/formattingData";

interface iAdvanceDetailsProps {
  data: tAdvanceDetails;
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    width: "100%"
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },

  flexEnd: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between"
  },
  label: {
    fontSize: 10,
    fontFamily: "Helvetica700",
    textTransform: "uppercase",
    marginBottom: 12
  },
  labelSemi: {
    fontSize: 11,
    fontFamily: "Helvetica500"
  },
  value: {
    fontSize: 10,
    fontFamily: "Helvetica400",
    marginBottom: 12
  },
  total: {
    fontSize: 10,
    fontFamily: "Helvetica700",
    textTransform: "uppercase",
    marginBottom: 12
  },
  hr: {
    width: "47%",
    marginLeft: "auto",
    display: "flex",
    border: "1px solid #EDEDF0",
    marginBottom: 12
  },
  column30: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "30%"
  },
  column25: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "23%"
  },
  column23: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "25%"
  },
  column22: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "22%"
  },
  bottom24: {
    marginBottom: "24px"
  },
  advanceTotalRow: {
    width: "47%",
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  halfRow: {
    width: "47%",
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row"
  },
  costProduction: {
    fontSize: 10,
    fontFamily: "Helvetica700",
    textTransform: "uppercase",
    marginBottom: 4
  }
});

export const AdvanceDetails: FC<iAdvanceDetailsProps> = ({data}) => {
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <SectionTitle>Advance</SectionTitle>
      </View>
      <View style={styles.row}>
        <View style={styles.column23} />
        <View style={styles.column30}>
          <Label>Budget Total</Label>
        </View>
        <View style={styles.column25}>
          <Label>Advance %</Label>
        </View>
        <View style={{...styles.column22, ...styles.flexEnd}}>
          <Text style={styles.label}>Amount Required Before SHOOT</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.column23}>
          <Text style={styles.costProduction}>Production Costs</Text>
          {/* <Label>Production Costs</Label> */}
        </View>
        <View style={styles.column30}>
          <Value>{getUsMoneyFormat(data.budget_total)}</Value>
        </View>
        <View style={styles.column25}>
          <Value>{getPercentFormat(data.advance_percent)}</Value>
        </View>
        <View style={{...styles.column22, ...styles.flexEnd}}>
          <Text style={styles.value}>{getUsMoneyFormat(data.amount_required)}</Text>
        </View>
      </View>

      <View style={styles.advanceTotalRow}>
        <Text style={styles.total}>ADVANCE TOTAL (USD)</Text>
        <Text style={styles.total}>{getUsMoneyFormat(data.amount_required)}</Text>
      </View>
      <View style={styles.hr} />
      {/* <View style={{...styles.halfRow, ...styles.bottom24}}>
        <View>
          <Text style={styles.labelSemi}>
            Late payment daily cost ({getPercentFormat(data.late_payment_daily_percent)}):{" "}
            {getUsMoneyFormat(data.late_payment_daily)}
          </Text>
        </View>
      </View> */}
    </View>
  );
};
