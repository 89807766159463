export const peopleTableColumn = [
  {
    id: "preferred",
    label: "Favorite",
    sort: false
  },
  {
    id: "name",
    label: "Name",
    sort: true
  },
  {
    id: "labels",
    label: "Labels",
    sort: false
  },
  {
    id: "locale",
    label: "Locale",
    sort: false
  },
  {
    id: "email",
    label: "Email",
    sort: false
  },
  {
    id: "phone",
    label: "Phone",
    sort: false
  },
  {
    id: "company",
    label: "Company",
    sort: true
  }
];
