import {useEffect, useLayoutEffect, useRef, useState} from "react";
import * as pdfjs from "pdfjs-dist";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const useGetPdfFromUrl = ({url}: {url: string | undefined}) => {
  const pdfRef = useRef<any>();
  const [numberOfPage, setNumberOfPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [pages, setPages] = useState<Array<any>>([]);
  const [refresh, setRefresh] = useState(url);

  useLayoutEffect(() => {
    if (!url) return;
    if (url && url !== refresh) {
      setRefresh(url);
      pdfRef.current = null;
      setPages([]);
    }

    const loadingTask = pdfjs.getDocument(url).promise;

    const getPdfFromUrl = async () => {
      setLoading(true);
      try {
        const pdf = await loadingTask;
        pdfRef.current = pdf;
        setNumberOfPage(pdf.numPages);

        await pdf.getPage(1);
      } catch (reason) {
        console.error(reason);
      } finally {
        setLoading(false);
      }
    };

    getPdfFromUrl();
  }, [url]); // eslint-disable-line

  useEffect(() => {
    if (!url) return;
    if (loading && numberOfPage < 1) return;

    const getPagesList = async () => {
      let count = 0;
      setLoadingPage(true);
      for (let index = 0; index < numberOfPage; index++) {
        await getPdfPage(index)?.then((page: any) => {
          setPages(prev => {
            const next = [...prev];
            // @ts-ignore
            next[index] = page;

            return next;
          });
        });
        count = index;
      }

      count === numberOfPage - 1 && setLoadingPage(false);
    };

    getPagesList();
  }, [loading]); // eslint-disable-line

  const getPdfPage = (page: number) => {
    if (!pdfRef.current) return;
    return pdfRef.current.getPage(page + 1);
  };

  const isLoading = loading || loadingPage;

  return {getPdfPage, numberOfPage, loading: isLoading, pages};
};
