import {useState} from "react";
import {tDraggingItem} from "components/DragAndDropWrap/DragAndDropWrap";
import {tJobTask, tJobTasksGroup} from "api/jobs/tasks/types";
import {useContextJobTasks} from "../../_context/JobTaskContext";

type tProps = {
  setData?: React.Dispatch<React.SetStateAction<tJobTasksGroup>>;
};

export const useDragTask = ({setData}: tProps) => {
  const {onSubmit, submitting, cellKeyRef, generateKey} = useContextJobTasks();
  const [currItem, setCurrItem] = useState<tDraggingItem | null>(null);

  const typeApi = "order";
  const currCellKey = currItem?.id && generateKey(typeApi, +currItem.id);
  const isLoading = cellKeyRef?.current === currCellKey && submitting;

  const dropCallBack = (draggingItem: tDraggingItem, tasks: Array<tJobTask>) => {
    if (currItem?.order === draggingItem?.order || isLoading) return;

    const newIndex = tasks?.findIndex(item => {
      return item?.id === draggingItem?.id;
    }) as number;

    setData?.(prev => {
      const newArrLines = [...prev?.tasks?.filter(item => item?.id !== currItem?.id)];

      currItem && newArrLines.splice(newIndex, 0, currItem as any);
      return {...prev, tasks: newArrLines};
    });

    currItem?.id && onSubmit({taskId: +currItem.id, typeApi, data: {order: newIndex + 1}});
  };

  return {
    setCurrItem,
    dropCallBack,
    currItem,
    isLoading
  };
};
