import {getUsMoneyFormat} from "helpers/formattingData";

export const getValue = (val: number | undefined, removeSeparators: boolean = false) => {
  if (!removeSeparators) {
    return getUsMoneyFormat(val || 0);
  }

  if (!!val && val < 0) {
    return `-$${Math.abs(val)?.toFixed(2)}`;
  }

  return val ? `$${val?.toFixed(2)}` : "$0.00";
};

export const getDiffValueColor = (val: number) => {
  if (val === 0) return "black-10";
  if (val > 0) return "red";
  return "green-30";
};
