import {FC} from "react";
import {DepartmentList} from "components/shared/DepartmentList/DepartmentList";
import {Icon} from "components/Icon/Icon";
import {iJobReturn} from "api/jobs/types";
import {TagButton} from "components/shared/TagButton/TagButton";
import {TablePipelineCell} from "./TablePipelineCell/TablePipelineCell";
import {AvatarGroup} from "components/shared/AvatarGroup/AvatarGroup";
import {favorites} from "api/favorites";
import {FavoriteCell} from "components/shared/Cells/FavoriteCell/FavoriteCell";
import {PeopleModals} from "components/shared/Modals/PeopleModals/PeopleModals";
import {CompanyModals} from "components/shared/Modals/CompanyModals/CompanyModals";
import {getDateRange} from "helpers/dates";
import {TagsButtonRow} from "./TagsButtonRow/TagsButtonRow";
import {Link} from "react-router-dom";
import {LocationsModals} from "pages/Locations/LocationsModals/LocationsModals";
import {JobDetailsModal} from "components/shared/Modals/JobDetailsModal/JobDetailsModal";
import {TableLocaleCell} from "./TableLocaleCell";
import {useJobsTableRow} from "./useJobsTableRow";
import "./JobsTableRow.scss";

interface iJobsTableRowProps {
  data: iJobReturn;
  toggleIsFavorite?: (id: string) => void;
  onEditCallback?: Function;
  id: number;
  isFirstRow?: boolean;
  onUpdatePipelineCallback?: () => void;
  isDashboard?: boolean;
}

export const JobsTableRow: FC<iJobsTableRowProps> = ({
  data,
  onEditCallback,
  isFirstRow,
  onUpdatePipelineCallback,
  isDashboard
}) => {
  const {
    jobData,
    onShowDetails,
    onSelectedPipelineCallback,
    showClientDetails,
    showLocationDetails,
    filterByTag,
    clientDetails,
    setClientDetails,
    locationDetails,
    setLocationDetails,
    showDetails,
    setShowDetails
  } = useJobsTableRow({onUpdatePipelineCallback, data});

  const {id, artists, team, departments, locations, start_at, end_at} = jobData;
  const {in_favorite, name, pipeline, client, locales} = jobData;

  const EmptyData = () => <span className="JobsTableRow-cell-empty">-</span>;
  const hasDate = start_at && end_at;
  const dateRange = hasDate
    ? getDateRange({start_at, end_at})
    : {period: "", periodStart: "", periodEnd: ""};

  const date = dateRange.periodStart === dateRange.periodEnd ? dateRange.periodEnd : dateRange.period; // prettier-ignore

  return (
    <>
      <tr className="JobsTableRow" onClick={onShowDetails}>
        <FavoriteCell
          refetch
          icon="star"
          addConfig={favorites.addJob(id)}
          removeConfig={favorites.removeJob(id)}
          in_favorite={in_favorite}
          showTooltip={isFirstRow ? {page: "dashboard", tooltip: "FAVORITING"} : undefined}
        />
        <td className="JobsTableRow-cell">
          {!departments?.length && <EmptyData />}
          <DepartmentList data={departments} stacked />
        </td>
        <td className="JobsTableRow-cell job-number">{id}</td>
        <td className="JobsTableRow-cell job-name">{name}</td>
        <TablePipelineCell
          job={data}
          data={pipeline}
          onSelectedPipelineCallback={onSelectedPipelineCallback}
        />
        <td className="JobsTableRow-cell">
          {team?.length ? <AvatarGroup data={team} nameKeys="display_name" /> : <EmptyData />}
        </td>
        <td className="JobsTableRow-cell dates">
          {!dateRange && <EmptyData />}
          {dateRange && date}
        </td>
        <td className="JobsTableRow-cell">
          {!client && <EmptyData />}
          {client && (
            <TagButton size="sm" color="dark" onClick={event => showClientDetails(event, client)}>
              {client?.name}
            </TagButton>
          )}
        </td>
        <td className="JobsTableRow-cell">
          <TagsButtonRow
            data={artists}
            onClickDetailsList={showClientDetails}
            artist
            emptyData={<EmptyData />}
            size="sm"
          />
        </td>
        <td className="JobsTableRow-cell">
          <TagsButtonRow
            data={locations}
            onClickDetailsList={showLocationDetails}
            emptyData={<EmptyData />}
            size="sm"
            color="light"
            popoverProps={{
              transformOrigin: {
                horizontal: "left",
                vertical: "bottom"
              }
            }}
          />
        </td>
        <TableLocaleCell
          onClick={(event, item) => (!isDashboard ? filterByTag(event, item.id) : {})}
          data={locales}
          emptyData={<EmptyData />}
        />

        <td className="JobsTableRow-cell">
          <Link className="showDetailsButton" to={`/jobs/${id}/overview`}>
            <Icon icon="arrow-right" />
          </Link>
        </td>
      </tr>

      {clientDetails?.client_type === "person" && (
        <PeopleModals
          person={clientDetails?.id}
          closePeopleModal={() => setClientDetails(null)}
          onEditCallback={onEditCallback}
        />
      )}

      {clientDetails?.client_type === "company" && (
        <CompanyModals
          company={clientDetails?.id}
          onEditCallback={onEditCallback}
          closeCompaniesModal={() => setClientDetails(null)}
        />
      )}

      {locationDetails && (
        <LocationsModals location={locationDetails} closeModal={() => setLocationDetails(null)} />
      )}

      {showDetails && <JobDetailsModal job={data.id} onModalClose={() => setShowDetails(false)} />}
    </>
  );
};
