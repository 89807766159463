import {FC, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextJobCrew} from "../../../JobCrewContext";
import {jobCrewContact} from "api/jobCrewContact";
import {tCrewContact} from "api/jobCrewGroup/types";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import {updateContact} from "../../../commonJobCrew/updateContact";
import {eventBus} from "EventBus/EventBus";

type tDeleteContactProps = {
  crewContact: tCrewContact;
  typeRow: "agent" | "contact";
  groupId: number;
};

export const DeleteContact: FC<tDeleteContactProps> = ({crewContact, typeRow, groupId}) => {
  const {jobCrewGroupList: {setCrewGroupList}, jobCrew: {crew}} = useContextJobCrew(); // prettier-ignore
  const {call, isLoading} = useHttp();
  const [showModal, setModal] = useState(false);

  const onSubmit = async () => {
    try {
      let res: any;

      if (typeRow === "agent") {
        res = await call(jobCrewContact.unLinkAgentToContact(crewContact.id));
        setCrewGroupList(prev => {
          return updateContact({
            prev,
            groupId,
            crewContactId: crewContact?.id,
            updatedContact: res?.data?.data
          });
        });
      }

      if (typeRow === "contact") {
        res = await call(jobCrewContact.deleteContacts(crew?.id, {contacts: [crewContact.id]}));
        setCrewGroupList(() => res?.data?.data);
      }
      eventBus.dispatch("showToast", {text: res?.data?.message || "Success!"});
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  const title = typeRow === "agent" ? "Unlink agent?" : "Delete contact?";
  const subTitle =
    typeRow === "agent" ? "This agent will be unlinked." : "This contact will be removed.";

  return (
    <>
      <Tooltip
        message="Remove"
        position="top"
        classname="removeContactTooltip"
        onClick={() => setModal(true)}
      >
        <Button onClick={() => setModal(true)}>
          <Icon size="sm" color="gray" icon="close" />
        </Button>
      </Tooltip>

      {showModal && (
        <ConfirmWindow
          show={true}
          title={title}
          subTitle={subTitle}
          cancelBtn={{text: "Cancel", type: "button"}}
          onClose={() => setModal(false)}
          confirmBtn={{text: "Delete", type: "submit", color: "red"}}
          onConfirm={onSubmit}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
