import React, {ChangeEvent, FC, useState} from "react";
import {useTextInputReturn} from "hooks/useTextInput/useTextInput";
import "./Department.scss";

type tProps = {
  inputData: useTextInputReturn;
  error?: string;
};

type tLabels = "Production" | "Post Production" | "Equipment";
type tDepartment = {id: number; label: tLabels; labelABBR: string; color: string};

export const departments: tDepartment[] = [
  {id: 1, label: "Production", labelABBR: "PR", color: `var(--orange-0)`},
  {id: 2, label: "Post Production", labelABBR: "PP", color: `var(--purple-0)`},
  {id: 3, label: "Equipment", labelABBR: "EQ", color: `var(--blue-0)`}
];

export const Departments: FC<tProps> = ({inputData, error}) => {
  const [selected, setSelected] = useState("");
  const onChangeDepartment = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSelected(selected === value ? "" : value);
    inputData.setValue(selected === value ? "" : value);
  };

  return (
    <>
      <div className="Departments">
        {departments.map(item => {
          return (
            <Department
              id={item.id}
              key={item.label}
              label={item.label}
              labelABBR={item.labelABBR}
              color={item?.color}
              inputData={{
                onChange: onChangeDepartment,
                checked: inputData.value === String(item.id)
              }}
            />
          );
        })}
      </div>
      {error && <div className="Departments-error">Department should be selected!</div>}
    </>
  );
};

interface iDepartmentProps {
  inputData?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>; // prettier-ignore
  label: string;
  labelABBR: string;
  customClass?: string;
  color?: string;
  id: number;
}

const Department: FC<iDepartmentProps> = ({
  inputData,
  label,
  labelABBR,
  customClass = "",
  color = "",
  id
}) => {
  return (
    <div className={`Department ${customClass}`}>
      <input id={label} value={id} type="radio" {...inputData} className="input" />
      <label htmlFor={label} className="label" style={{color}}>
        <div className="label-abbr" style={{backgroundColor: color}}>
          {labelABBR}
        </div>
        <div className="label-title">{label}</div>
      </label>
    </div>
  );
};
