import {FC, useState} from "react";
import {tCrewContact} from "api/jobCrewGroup/types";
import {Button} from "components/shared/Button/Button";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {useContextJobCrew} from "../../../JobCrewContext";
import {useHttp} from "hooks/httpServices/useHttp";
import {jobCrewContact} from "api/jobCrewContact";
import {updateContact} from "../../../commonJobCrew/updateContact";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import "./RowEmail.scss";

type tRowEmailsProps = {
  crewContact: tCrewContact;
  groupId: number;
  isAgent?: boolean;
};

export const RowEmails: FC<tRowEmailsProps> = ({crewContact, groupId, isAgent}) => {
  const {jobCrewGroupList: {setCrewGroupList}} = useContextJobCrew(); // prettier-ignore
  const typeContact = isAgent ? "agent" : "contact";
  const contact = crewContact[typeContact];
  const {default_email} = crewContact;
  const {call, isLoading} = useHttp();

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const changeValue = async (id: number) => {
    setMenuAnchor(null);

    try {
      const res = await call(
        jobCrewContact.setDefaultEmail(crewContact?.id, {default_email_id: id})
      );

      setCrewGroupList(prev => {
        return updateContact({
          prev,
          groupId,
          crewContactId: crewContact?.id,
          updatedContact: res?.data?.data
        });
      });
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  const email =
    !!default_email?.value && !isAgent ? default_email?.value : contact?.emails?.[0]?.value;
  return (
    <div className="RowEmails">
      <Button
        //    onClick={showMenu}
        size="sm"
        disabled={isLoading || isAgent}
        hidePadding="horizontal"
        btnType="text"
      >
        {email || ""}
      </Button>

      <DropdownMenu
        anchor={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        transformOrigin={{
          horizontal: "right"
        }}
      >
        {contact?.emails?.map(item => {
          return (
            <Button
              key={item?.id}
              size="sm"
              onClick={() => changeValue(item?.id)}
              disabled={isLoading}
            >
              {item?.value}
            </Button>
          );
        })}
      </DropdownMenu>
    </div>
  );
};
