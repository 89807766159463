import {FC} from "react";
import {StyleSheet, View, Text} from "@react-pdf/renderer";

export const Footer: FC = () => {
  return (
    <View fixed>
      <View style={styles.footer}>
        <View style={styles.left}>
          <StyledText>Versatile Studios</StyledText>
          <StyledText>866 6th Ave, 8th Fl, New York, NY 10001, USA</StyledText>
          <StyledText>+1 212.685.4384</StyledText>
        </View>
        <View style={styles.right}>
          <StyledText
            render={({pageNumber, totalPages}: any) => `Page ${pageNumber} of ${totalPages}`}
            fixed
          />
          <StyledText>www.versatilestudios.com</StyledText>
        </View>
      </View>
    </View>
  );
};

const StyledText = (props: any) => <Text style={styles.styledText} {...props} />;

const styles = StyleSheet.create({
  footer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #BDBDBF",
    paddingTop: "8px",
    marginTop: "32px"
  },
  left: {display: "flex", flexDirection: "column", width: "50%"},
  right: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "space-between",
    width: "50%"
  },
  styledText: {
    fontSize: "13px",
    color: "#7E7E80",
    fontFamily: "Helvetica400",
    marginBottom: "4px"
  }
});
