import {AxiosRequestConfig} from "axios";

const job = "job";
const job_crew = "job_crew";
const template = "template";

export const jobCrew = {
  getCrew: (jobId: number): AxiosRequestConfig => ({
    url: `${job}/${jobId}/${job_crew}`,
    method: "GET"
  }),
  addCrew: (data: {job_id: number}): AxiosRequestConfig => ({
    url: `${job_crew}`,
    method: "POST",
    data
  }),
  createCrewFromTemplate: (template_id: number, data: {job_id: number}): AxiosRequestConfig => ({
    url: `${job_crew}/${template}/${template_id}`,
    method: "POST",
    data
  }),
  deleteJobCrewTemplate: (job_crew_id: number): AxiosRequestConfig => ({
    url: `${job_crew}/${job_crew_id}`,
    method: "DELETE"
  }),
  deleteCrew: (job_crew_id: number): AxiosRequestConfig => ({
    url: `${job_crew}/${job_crew_id}`,
    method: "DELETE"
  }),
  getJobCrewTemplates: (): AxiosRequestConfig => ({
    url: `${job_crew}/${template}s`,
    method: "GET"
  }),
  deleteCrewTemplate: (template_id: number): AxiosRequestConfig => ({
    url: `${job_crew}/${template}/${template_id}`,
    method: "DELETE"
  }),
  updateCrewTemplate: (job_crew_id: number, template_id: number): AxiosRequestConfig => ({
    url: `${job_crew}/${job_crew_id}/${template}/${template_id}`,
    method: "POST"
  }),
  createNewCrewTemplate: (job_crew_id: number, data: {name: string}): AxiosRequestConfig => ({
    url: `${job_crew}/${job_crew_id}/${template}`,
    method: "POST",
    data
  }),
  addCrewTemplate: (job_crew_id: number, data: {name: string}): AxiosRequestConfig => ({
    url: `${job_crew}/${job_crew_id}`,
    method: "POST",
    data
  }),
  renameCrewTemplate: (template_id: number, data: {name: string}): AxiosRequestConfig => ({
    method: "POST",
    url: `${job_crew}/template/${template_id}/rename`,
    data
  })
};
