export const crewTableTitles = [
  {
    id: "empty",
    label: ``
  },
  {
    id: "Position",
    label: "Position"
  },
  {
    id: "Hold Status",
    label: "Hold Status"
  },
  {
    id: "Hold Dates",
    label: "Hold Dates"
  },
  {
    id: "Rate",
    label: "Rate"
  },
  {
    id: "Phone",
    label: "Phone"
  },
  {
    id: "Email",
    label: "Email"
  },
  {
    id: "Billing",
    label: "Billing"
  },
  {
    id: "Notes",
    label: "Notes"
  },
  {
    id: "Dietary Restriction",
    label: "Dietary Restriction"
  },
  {
    id: "actions",
    label: ""
  }
];
