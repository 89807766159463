export const copyToClipboard = (text: string) => navigator.clipboard.writeText(text);

/**
 * makes html string from url search params
 * @param {string} search - search to change
 * @param {string} text - text to add
 * @param {string} classname - classname to add
 * @return {string} = html string
 */

export const markSearchStringInText = ({
  search,
  text,
  classname
}: {
  search: string;
  text: string;
  classname: string;
}) => {
  const re = new RegExp(`${search}`, "gmi");

  return {
    __html: search ? text.replace(re, `<span class="${classname}">$&</span>`) : text
  };
};
