import {useState} from "react";
import {tCrewContact, tCrewGroupContact} from "api/jobCrewGroup/types";
import {useContextJobCrew} from "../../JobCrewContext";
import {useHttp} from "hooks/httpServices/useHttp";
import {jobCrewContact} from "api/jobCrewContact";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {tDraggingItem} from "components/DragAndDropWrap/DragAndDropWrap";

export const useChangeContactOrder = (group: tCrewGroupContact) => {
  const { jobCrewGroupList: { setCrewGroupList, getJobCrewGroupList } } = useContextJobCrew(); // prettier-ignore

  const {call, isLoading} = useHttp();
  const changeContactOrder = async (order: number, id: number | string) => {
    try {
      await call(jobCrewContact.updateContactOrder(id, {order}));
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
      getJobCrewGroupList();
    }
  };

  const [currContact, setCurrContact] = useState<tDraggingItem | null>(null);
  const dropCallBack = (draggingItem: {[key: string]: any; id: number | string}) => {
    const newIndex = group?.contacts?.findIndex(item => item?.id === draggingItem?.id) as number;

    setCrewGroupList(prev => {
      return prev.map(item => {
        if (item?.id === group?.id && !!item?.contacts?.length) {
          const newContArr = [...item?.contacts?.filter(item => item?.id !== currContact?.id)];
          currContact && newContArr.splice(newIndex, 0, currContact as tCrewContact);
          item.contacts = newContArr;
        }
        return item;
      });
    });

    currContact?.id && changeContactOrder(newIndex + 1, currContact.id);
  };

  return {
    isLoading,
    dropCallBack,
    setCurrContact
  };
};
