import {FC, useEffect, useState} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {useHttp} from "hooks/httpServices/useHttp";
import {jobsApi} from "api/jobs";
import {Loading} from "components/shared/Loading/Loading";
import {iJobDetailsDataReturn} from "api/jobs/types";
import {JobDetails} from "./JobDetails";

import "./JobDetailsModal.scss";

interface iJobDetailsModal {
  job: number | iJobDetailsDataReturn | undefined | null;
  onModalClose?: (...p: any) => void;
  loading?: boolean;
}

export const JobDetailsModal: FC<iJobDetailsModal> = ({job, loading, onModalClose}) => {
  const [data, setData] = useState<iJobDetailsDataReturn | null>(null);
  const {call, isLoading} = useHttp();

  useEffect(() => {
    if (!job) return;
    const id = typeof job === "number" ? job : job.id;
    call(jobsApi.getJobDetails(id)).then(({data: {data}}) => setData(data));
  }, []); // eslint-disable-line

  return (
    <ModalPopup className="JobDetailsModal" show={true} onClose={onModalClose} withoutPaddings>
      {(isLoading || loading) && <Loading type="inBlock" />}
      {data && !isLoading && <JobDetails data={data} onModalClose={onModalClose} />}
    </ModalPopup>
  );
};
