import {FC} from "react";
import {Datepicker} from "components/shared/FormComponents/Datepicker/Datepicker";
import {iField} from "api/fields/types";

interface iCustomSingleInputProps {
  field: iField;
  onChange: (value: iField) => void;
}

export const CustomDatepicker: FC<iCustomSingleInputProps> = ({field, onChange}) => {
  const handleChange = (date: string) => {
    onChange({
      ...field,
      value: date
    });
  };

  return (
    <Datepicker
      value={field?.value}
      onChange={handleChange}
      label={field.name}
      placeholderText=""
      withIcon
    />
  );
};
