import {toCapitalize} from "helpers/misc";
import {toClearPhoneValue} from "helpers/parsers/helpers";

export const parseVcf = (fileString: string) => {
  let response: any = {
    errors: null,
    data: [],
    errorMessage: ""
  };

  const vcfString = fileString.split("\r\n");

  function parseVcfString(arr: string[]) {
    let vcfArr: any = [];
    let step = 0;
    arr.forEach(value => {
      if (value === "BEGIN:VCARD" || value === "VERSION:") return;
      if (value === "END:VCARD") return (step = step + 1);

      const item = vcfArr[step] || {};
      const regex = (pattern: RegExp) => new RegExp(pattern);

      if (regex(/(\bN):(?:[a-zA-Z ]+)/g).test(value)) {
        vcfArr[step] = {...item, name: value.replace("N:", "").replace(/;/g, "")};
        return;
      }

      if (regex(/(\bFN):(?:[a-zA-Z ]+)/g).test(value)) {
        const name = value.replace("FN:", "").split(" ");
        const firstName = name?.[0];
        const restName = name?.slice(1);

        vcfArr[step] = {
          ...item,
          first_name: firstName,
          last_name: name?.length > 1 ? restName?.join(" ") : ""
        };
        return;
      }

      if (value.includes("TEL;TYPE=")) {
        let phones: any = vcfArr?.[step]?.phones || [];
        const type = value.split(";");
        phones = [
          ...phones,
          {
            type_id: toCapitalize(type[1].replace("TYPE=", "")),
            value: toClearPhoneValue(type[2].replace("VALUE=uri:tel:", ""))
          }
        ];
        vcfArr[step] = {...item, phones};
        return;
      }

      if (value.includes("NOTE:")) {
        vcfArr[step] = {...vcfArr[step], notes: value.replace("NOTE:", "")};
        return;
      }

      if (value.includes("EMAIL;TYPE")) {
        let emails: any = vcfArr?.[step]?.emails || [];
        const valueSplited = value.split(":");

        emails = [
          ...emails,
          {
            type_id: toCapitalize(valueSplited[0].replace("EMAIL;TYPE=", "")),
            value: valueSplited.at(-1)
          }
        ];
        vcfArr[step] = {...item, emails};
        return;
      }

      if (value.includes("ADR;TYPE=")) {
        vcfArr[step] = {
          ...item,
          address: {
            raw: value.split(":")?.[1].replace(/;/g, "")
          }
        };
        return;
      }
      if (value.includes("TITLE:")) {
        vcfArr[step] = {
          ...item,
          titles: [value.replace("TITLE:", "")]
        };
        return;
      }
    });

    return vcfArr;
  }

  response.data = parseVcfString(vcfString);
  return response;
};
