import {FC, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {ManageFieldsForm} from "./ManageFieldsForm/ManageFieldsForm";
import {iField} from "api/fields/types";

interface iEditFieldFormProps {
  getFieldList: () => Promise<any>;
  setHide: (is: boolean) => void;
  field: iField;
}

export const EditFieldForm: FC<iEditFieldFormProps> = ({getFieldList, field, setHide}) => {
  const [activeField, setActiveField] = useState<iField | null>(null);

  const show = () => {
    setHide(true);
    setActiveField(field);
  };

  const hide = () => {
    setHide(false);
    setActiveField(null);
  };

  return (
    <>
      <button onClick={show} className="ManageFieldsList-list-btn">
        <Icon icon="edit" color="black" size="sm" />
      </button>
      {activeField && (
        <ManageFieldsForm
          field={activeField}
          show={!!activeField}
          onClose={hide}
          getFieldList={getFieldList}
        />
      )}
    </>
  );
};
