import {Dispatch, FC, SetStateAction} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {TextComponent} from "../../../../common/TextComponent/TextComponent";
import {FormWrap} from "components/shared/FormWrap/FormWrap";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {useAdvanceTermsModal} from "./useAdvanceTermsModal";
import UploadField from "components/shared/FormComponents/UploadField/UploadField";
import {AdvanceModalFormList} from "../../AdvanceModalFormBody/AdvanceModalFormList";
import {tTermsConditions} from "api/budget/termsConditions/types";
import {Loading} from "components/shared/Loading/Loading";

import "./AdvanceTermsModal.scss";

interface iAdvanceTermsModalProps {
  termsList: tTermsConditions[];
  setTermsList: Dispatch<SetStateAction<tTermsConditions[]>>;
  onClose: () => void;
  isLoading?: boolean;
}

export const AdvanceTermsModal: FC<iAdvanceTermsModalProps> = ({
  onClose,
  termsList,
  setTermsList,
  isLoading
}) => {
  const {
    deleteTermQuery,
    createTermQuery,
    editTermQuery,
    formData,
    modal,
    showModal,
    onDelete,
    handleSubmit,
    file,
    fileError,
    activeTerm,
    onShowEdit,
    onShowDelete,
    onChangeFile
  } = useAdvanceTermsModal({
    setTermsList
  });

  const showFormWrap = modal === "add" || modal === "edit";

  return (
    <>
      <ModalPopup
        size="w-528"
        show={true}
        onClose={onClose}
        className="AdvanceTermsModal"
        withoutPaddings
        showCloseButton={!modal}
      >
        {isLoading && <Loading type="inBlock" />}
        {!modal && (
          <AdvanceModalFormList
            onEdit={onShowEdit}
            onDelete={onShowDelete}
            onAdd={showModal}
            data={termsList}
            title={!!termsList?.length ? "Edit Terms AND CONDITIONS" : "Terms AND CONDITIONS"}
            btnText="TERMS AND CONDITIONS"
          />
        )}

        {showFormWrap && (
          <FormWrap
            headerTitle={
              modal === "add" ? "ADD NEW TERMS AND CONDITIONS" : "Edit Terms AND CONDITIONS"
            }
            idForm="term-form"
            type="titleCenterAndOneBtn"
            submitTitle="Save"
            onBack={() => showModal(null)}
            isLoading={editTermQuery?.isLoading || createTermQuery?.isLoading}
          >
            <form id="term-form" onSubmit={handleSubmit}>
              <InputField
                {...formData.name.inputProps}
                errors={formData.name.errors}
                label="Title"
              />
              <div className="AdvanceTermsModal-uploadRow">
                {(file?.name || activeTerm?.file_name) && (
                  <TextComponent weight="500" size="14">
                    {file?.name || activeTerm?.file_name}
                  </TextComponent>
                )}
                <UploadField
                  onChange={onChangeFile}
                  buttonText={modal === "edit" ? "Upload new PDF" : "Upload  PDF"}
                  errors={fileError}
                />
              </div>
            </form>
          </FormWrap>
        )}
      </ModalPopup>

      <ConfirmWindow
        title="Delete Terms?"
        subTitle={`“${activeTerm?.name}” terms and conditions will be permanently deleted`}
        warning="This can’t be undone."
        show={modal === "delete"}
        onClose={() => showModal(null)}
        onConfirm={onDelete}
        isLoading={deleteTermQuery.isLoading}
        confirmBtn={{
          text: "Delete",
          color: "red"
        }}
        cancelBtn={{
          text: "cancel"
        }}
      />
    </>
  );
};
