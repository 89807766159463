import {useEffect, useState} from "react";
import {common} from "api/common";
import {useCheckBoxGroupe} from "hooks/inputHooks/useCheckBoxGroupe";
import {useHttp} from "hooks/httpServices/useHttp";

type tProps = {
  form: any;
};

export const useJobSections = ({form}: tProps) => {
  const {data, isLoading: getSectionsLoading} = useHttp({config: common.getSections(), onMountCall: true}); // prettier-ignore
  const list = (data?.data?.data as {id: number; name: string}[]) || [];

  const isFormVal = !!form.sections.value.length;
  const selectedSections = form.sections.value.split(",").map((item: string) => +item);
  const allList = list.map(item => +item.id);
  const defaultSelectedSections = form.departments.value === "1" ? allList : [1,2,3]; // prettier-ignore

  const initSelected = isFormVal ? selectedSections : defaultSelectedSections;
  const initSelectedList = list.filter(item => initSelected.includes(item.id) && item);

  const sections = useCheckBoxGroupe({isRequired: false, initValue: initSelected});
  const [selected, setSelected] = useState(initSelectedList);

  useEffect(() => {
    if (data) {
      setSelected(initSelectedList);
      sections.selectedCheckBoxes.current = initSelected;
    }
  }, [data]); // eslint-disable-line

  return {sections, selected, list, getSectionsLoading, setSelected};
};
