import {getBaseURL} from "hooks/useApi";

export async function createFileFromUrlData(url: string, filename = "") {
  try {
    const proxy = getBaseURL() + "asset";

    let response = await fetch(`${proxy}?url=${url}`);

    let data = await response.blob();
    let metadata = {type: "image/jpeg"};

    return new File([data], filename, metadata);
  } catch (error) {
    console.log(error);
  }
}
