import {FC, FormEvent} from "react";
import {jobsApi} from "api/jobs";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {notValidForm} from "helpers/misc";
import {useHttp} from "hooks/httpServices/useHttp";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {FormWrap} from "components/shared/FormWrap/FormWrap";
import {tLinks} from "api/jobs/types";
import {eventBus} from "EventBus/EventBus";
import "./AddUrlLinkForm.scss";

interface iAddUrlLinkFormProps {
  onCloseModal: () => void;
  show: boolean;
  data?: tLinks;
}

export const AddUrlLinkForm: FC<iAddUrlLinkFormProps> = ({onCloseModal, show, data}) => {
  const {jobDetailsData, jobDetailsQuery, setJobDetails} = useContextJobDetails(); // prettier-ignore
  const id = jobDetailsData?.id;
  const {call, isLoading: submitting} = useHttp();
  const FORM_ID = "add-url-link-form-id";

  const form = {
    title: useTextInput({value: data?.title || "", validators: ["name"]}),
    link: useTextInput({value: data?.link || "", validators: ["url"]}),
    password: useTextInput({
      value: data?.password || "",
      // validators: ["password"],
      isRequired: false
    })
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (notValidForm(form)) return;
    if (!id) return;
    try {
      const formData = {
        title: form.title.value,
        link: form.link.value,
        password: form.password.value
      };

      let res: any;
      !data && (res = await call(jobsApi.addLinkToJob(id, formData)));
      data && (res = await call(jobsApi.updateExternalLink(id, data?.id, formData)));
      setJobDetails(res?.data?.data);
      onCloseModal();
      eventBus.dispatch("showToast", {text: res?.data?.message});
    } catch (error) {
      const {msg} = apiErrorHandler(error, form);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error !"});
    }
  };

  const submitTitle = data ? "Update link" : "Add link";
  const headerTitle = data ? "Update url link" : "Add url link";
  return (
    <ModalPopup className="LinkModal" show={show} onClose={onCloseModal} withoutPaddings>
      <FormWrap
        idForm={FORM_ID}
        isLoading={jobDetailsQuery.isLoading || submitting}
        onCancel={onCloseModal}
        submitTitle={submitTitle}
        headerTitle={headerTitle}
      >
        <form className="AddUrlLinkForm" onSubmit={onSubmit} autoComplete="off" id={FORM_ID}>
          <InputField {...form.title.inputProps} label="Title" errors={form.title.errors} />
          <InputField {...form.link.inputProps} label="Link" errors={form.link.errors} />
          <InputField
            label="Password (optional)"
            {...form.password.inputProps}
            errors={form.password.errors}
          />
        </form>
      </FormWrap>
    </ModalPopup>
  );
};
