import {useState, FormEvent, useEffect, Dispatch, SetStateAction} from "react";
import {notValidForm} from "helpers/misc";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useHttp} from "hooks/httpServices/useHttp";
import {advanceContactApi} from "api/budget/advanceContact";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {tAdvanceContact} from "api/budget/advanceContact/types";

type tModal = "edit" | "add" | "delete";

type tUseAdvanceContactsModalProps = {
  setAdvanceContacts: Dispatch<SetStateAction<tAdvanceContact[]>>;
};

export function useAdvanceContactsModal({setAdvanceContacts}: tUseAdvanceContactsModalProps) {
  const createContactQuery = useHttp();
  const editContactQuery = useHttp();
  const deleteContactQuery = useHttp();

  const [modal, setModal] = useState<tModal | null>(null);
  const [activeContact, setActiveContact] = useState<tAdvanceContact | null>(null);

  const formData = {
    name: useTextInput({
      value: activeContact?.name || "",
      validators: ["name"],
      filters: ["name"]
    }),
    // phone: usePhoneInput({
    //   validators: ["phone"],
    //   filters: ["phone"],
    //   value: activeContact?.phone || ""
    // }),
    phone: useTextInput({
      value: activeContact?.phone || "",
      validators: ["phone"],
      filters: ["phone"]
    }),
    email: useTextInput({
      value: activeContact?.email || "",
      validators: ["email"],
      filters: ["email"]
    })
  };

  useEffect(() => {
    if (modal === "edit" && activeContact) {
      formData.name.setValue(activeContact.name);
      formData.email.setValue(activeContact.email);
      formData.phone.setValue(activeContact.phone);
      return;
    }
  }, [modal, activeContact]); // eslint-disable-line react-hooks/exhaustive-deps

  const onShowDelete = (contact: tAdvanceContact) => {
    setActiveContact(contact);
    setModal("delete");
  };

  const onShowEdit = (contact: tAdvanceContact) => {
    setActiveContact(contact);
    setModal("edit");
  };

  const onDelete = async () => {
    if (!activeContact) return;

    try {
      const {data: {message}} = await deleteContactQuery.call(advanceContactApi.deleteAdvanceContact(activeContact.id)); // prettier-ignore
      eventBus.dispatch("showToast", {text: message});

      setAdvanceContacts(prevState => prevState.filter(contact => +contact.id !== +activeContact.id)); // prettier-ignore
      showModal(null);
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {text: msg, type: "error"});
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (notValidForm(formData)) return;

    const payload = {
      name: formData.name.value,
      phone: formData.phone.value,
      email: formData.email.value
    };
    const isEditing = !!activeContact;

    try {
      const {
        data: {message, data}
      } = isEditing
        ? await editContactQuery.call(advanceContactApi.updateAdvanceContact(activeContact.id, payload))
        : await createContactQuery.call(advanceContactApi.createAdvanceContact(payload)); // prettier-ignore

      if (isEditing) {
        setAdvanceContacts(prevState => prevState.map(item => +item.id === +activeContact.id ? data: item)); // prettier-ignore
      } else {
        setAdvanceContacts(prevState => [...prevState, data]);
      }

      eventBus.dispatch("showToast", {text: message});
      showModal(null);
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {text: msg, type: "error"});
    }
  };

  const showModal = (t: tModal | null) => {
    Object.values(formData).forEach(input => input.onReset());
    setModal(t);
    setActiveContact(null);
  };

  return {
    formData,
    modal,
    showModal,
    setModal,
    onDelete,
    onShowEdit,
    onShowDelete,
    handleSubmit,
    createContactQuery,
    deleteContactQuery,
    editContactQuery,
    activeContact
  };
}
