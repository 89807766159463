import {FC} from "react";
import "./DropboxAuthorize.scss";

interface iDropboxAuthorizeProps {
  authUrl: string;
}

export const DropboxAuthorize: FC<iDropboxAuthorizeProps> = ({authUrl = ""}) => {
  return (
    <div className="DropboxAuthorize">
      <p className="DropboxAuthorize-text">You haven't been authorized in Dropbox yet. </p>
      <p className="DropboxAuthorize-text">Please, follow the link below and authorize. </p>

      <a rel="noreferrer" href={authUrl} target="_blank" className="DropboxAuthorize-link">
        Sign-in Dropbox
      </a>
    </div>
  );
};
