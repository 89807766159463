import {FormEvent, FC} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {Button} from "components/shared/Button/Button";
import {AddressField} from "components/shared/FormComponents/AddressField/AddressField";
import {tUsePeopleFormAddress} from "./usePeopleFormAddress";
import {PEOPlE_FORM_ID} from "../../model";

interface iPeopleFormAddressProps {
  toNextTab: () => void;
  onSubmit: (fe: FormEvent<HTMLFormElement>) => void;
  addressFormData: tUsePeopleFormAddress;
};

export const PeopleFormAddress: FC<iPeopleFormAddressProps> = ({
  toNextTab,
  onSubmit,
  addressFormData
}) => {
  const {formData} = addressFormData;

  return (
    <form className="PeopleFormAddress" id={PEOPlE_FORM_ID} onSubmit={onSubmit}>
      <div className="PeopleForm-section">
        <h5 className="h5 PeopleForm-section-title">Address</h5>
        <AddressField {...formData.address} searchLabel="Home Address" />
      </div>
      <div className="PeopleForm-section">
        <h5 className="h5 PeopleForm-section-title">Billing Information</h5>
        <div className="PeopleForm-col-2">
          <InputField
            {...formData.billing_info.first_name.inputProps}
            errors={formData.billing_info.first_name.errors}
            id="first_name"
            label="First Name"
          />
          <InputField
            {...formData.billing_info.last_name.inputProps}
            errors={formData.billing_info.last_name.errors}
            id="last_name"
            label="Last Name"
          />
        </div>
        <div className="PeopleForm-col-2">
          <InputField
            {...formData.billing_info.email.inputProps}
            errors={formData.billing_info.email.errors}
            id="email"
            label="Email"
          />
          <InputField
            {...formData.billing_info.phone.inputProps}
            errors={formData.billing_info.phone.errors}
            label="Phone"
          />
          {/* <PhoneField
            {...formData.billing_info.phone.inputProps}
            errors={formData.billing_info.phone.errors}
            label="Phone"
          /> */}
        </div>

        <AddressField {...formData.billing_info.address} searchLabel="Billing Address" />
      </div>

      <Button type="button" color="light" className="PeopleForm-button-next" onClick={toNextTab}>
        Continue to Other Details
      </Button>
    </form>
  );
};
