import {FC} from "react";
import {getDateRange} from "helpers/dates";
import {iLocationJobs} from "api/location/types";
import "./CompanyDetailsJobs.scss";

interface iCompanyDetailsJobs {
  data: iLocationJobs[];
}

export const CompanyDetailsJobs: FC<iCompanyDetailsJobs> = ({data}) => {
  return (
    <table className="CompanyDetailsJobs">
      <tbody>
        {data.map(job => {
          const EmptyData = () => <span className="cell-empty">-</span>;
          const hasDate = job?.start_at && job?.end_at;
          const dateRange = hasDate && getDateRange({start_at: job.start_at, end_at: job.end_at});

          return (
            <tr key={job.id} className="CompanyDetailsJobs-row">
              <td className="CompanyDetailsJobs-number">{job.id}</td>
              <td>{job.name}</td>
              <td>
                {!dateRange && <EmptyData />}
                {dateRange && <span>{dateRange.period}</span>}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
