export const moneyFormat = (currency: string = "USD") => {
  let curr: any;
  try {
    const a = new Intl.NumberFormat("en-US", {style: "currency", currency});
    curr = a;
  } catch (error) {
    curr = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"});
  }
  return curr;
};

export const getUsMoneyFormat = (value: number | string, currency?: string) =>
  moneyFormat(currency)?.format(Number(value));

export const percentFormat = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
});

export const getPercentFormat = (value: number | string) =>
  percentFormat.format(Number(value) / 100);

export const getInputMoneyFormat = (v: number | string) => {
  if (String(v).match(/\d\.$/) !== null) return v; // if number ends with . it won't be formatted
  const val = Number(v);
  return !isNaN(val) ? val.toLocaleString("en-US", {style: "decimal"}) : val;
};
