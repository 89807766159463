import {JSXElementConstructor, ReactElement, useState} from "react";
import {pdf} from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";

interface iUseGeneratePdfDataProps {
  isLoading?: boolean;
}

export function useGeneratePdfData(props?: iUseGeneratePdfDataProps) {
  const [isLoading, setIsLoading] = useState(
    typeof props?.isLoading === "boolean" ? props.isLoading : true
  );
  const [url, setUrl] = useState("");
  const [blob, setBlob] = useState<Blob | null>(null);

  async function generatePdfData(
    document: ReactElement<ReactPDF.DocumentProps, string | JSXElementConstructor<any>> | undefined
  ): Promise<{blob: Blob; url: string} | undefined> {
    try {
      const blob = await pdf(document).toBlob();
      const fileUrl = URL.createObjectURL(blob);
      setUrl(fileUrl);
      setIsLoading(false);
      setBlob(blob);

      return {
        blob,
        url: fileUrl
      };
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return {
    generatePdfData,
    isLoading,
    pdfData: {
      url,
      blob
    }
  };
}
