import {FC, useRef} from "react";
import {Icon} from "components/Icon/Icon";
import {useSearchParams} from "react-router-dom";
import {iTableHeadItemProps, tTableParams} from "./types";
import {Resizer} from "./Resizer/Resizer";
import {tReturnTypeUseAdjustWIdth} from "hooks/useAdjustWidth";

export type tPTable = {
  tableHeigh?: number;
  adjustWidth?: tReturnTypeUseAdjustWIdth;
};

export const TableHeadItem: FC<iTableHeadItemProps & Partial<tPTable>> = ({
  data,
  adjustWidth,
  tableHeigh
}) => {
  const {id, label, sort} = data;
  const [searchParams, setSearchParams] = useSearchParams();
  const sortDirection = searchParams.get("sort_direction");
  const sortField = searchParams.get("sort_field");
  const cellRef = useRef<HTMLTableHeaderCellElement | null>(null);

  const isSortDirection = sortDirection && sortField === id;
  const sort_direction = sortDirection === "asc" ? "desc" : "asc";

  const isFav = id === "favorite" || id === "preferred";
  const favoriteColumnClassName = isFav ? "favorite" : "";

  const setParams = (sort_field: string) => {
    let params: Partial<Record<keyof tTableParams, string>>;

    if (sortDirection === "desc") {
      params = Object.fromEntries(searchParams);
      delete params["sort_field"];
      delete params["sort_direction"];
    } else {
      params = {
        ...Object.fromEntries(searchParams),
        sort_field,
        sort_direction
      };
    }

    delete params["page"];
    setSearchParams(params);
  };

  const className = label.replace(" ", "-").toLowerCase();
  const favIcon = id === "favorite" ? <Icon icon="star" /> : <Icon icon="heart" />;
  const withResize = !!adjustWidth?.resizeHandler ? "withResize" : "";
  const isSort = sort ? "sort" : "";
  const widthStyles = !!adjustWidth?.width
    ? {maxWidth: adjustWidth?.width + "px", width: adjustWidth?.width + "px"}
    : undefined;

  return (
    <th
      key={id}
      ref={cellRef}
      className={`TableHeadItem ${className} ${favoriteColumnClassName} ${isSort} ${withResize}`}
      style={widthStyles}
    >
      {isFav && favIcon}
      {!sort && !isFav && <span>{label}</span>}

      {sort && !isFav && (
        <button className="button-sort" onClick={() => setParams(id)}>
          {label}
          <Icon icon="sort" className={isSortDirection ? sort_direction : ""} />
        </button>
      )}

      {adjustWidth?.resizeHandler && (
        <Resizer
          resizeHandler={e => adjustWidth?.resizeHandler?.(e, cellRef?.current?.clientWidth)}
          dividerHeight={tableHeigh}
        />
      )}
    </th>
  );
};
