import {FC} from "react";
import {tTrackExpenseLine, typeFees} from "api/budget/track/types";
import {Actions} from "pages/Jobs/JobDetails/JobBudget/Track/_common/_tableParts/Actions/Actions";
import {AdditionalFiles} from "pages/Jobs/JobDetails/JobBudget/Track/_common/_tableParts/AdditionalFiles";
import {Green} from "pages/Jobs/JobDetails/JobBudget/Track/_common/_tableParts/Green";
import {Note} from "pages/Jobs/JobDetails/JobBudget/Track/_common/_tableParts/Note";
import {Pay} from "pages/Jobs/JobDetails/JobBudget/Track/_common/_tableParts/Pay/Pay";
import {PayStatus} from "pages/Jobs/JobDetails/JobBudget/Track/_common/_tableParts/PayStatus";
import {QBStatus} from "pages/Jobs/JobDetails/JobBudget/Track/_common/_tableParts/QBStatus";
import {QuickPay} from "pages/Jobs/JobDetails/JobBudget/Track/_common/_tableParts/QuickPay";
import "./ExpenseRowDifference.scss";

type tExpenseRowDifferenceProps = {
  className?: string;
  trackLine: tTrackExpenseLine;
  typeFees?: typeFees;
};

export const ExpenseRowDifference: FC<tExpenseRowDifferenceProps> = ({
  className = "",
  trackLine,
  typeFees
}) => {
  return (
    <div className={`${className} ExpenseRowDifference`}>
      <Green id={trackLine?.id} value={trackLine?.green} className="differenceItem" />
      <Pay className="differenceItem" trackLine={trackLine} />
      <QBStatus className="differenceItem" trackLine={trackLine} />
      <PayStatus className="differenceItem" trackLine={trackLine} />
      {/** <FileCells data={trackLine} />  //w8, w9, ACH, Wire */}
      {/* <QuickPay className="differenceItem" id={trackLine?.id} value={trackLine?.qb_state} />
      <Note className="differenceItem" id={trackLine?.id} value={trackLine?.note || ""} />
      <AdditionalFiles className="differenceItem" data={trackLine} />
      <Actions className="differenceItem" trackLine={trackLine} typeFees={typeFees} /> */}
    </div>
  );
};

export const ExpenseRowDifference2: FC<tExpenseRowDifferenceProps> = ({
  className = "",
  trackLine,
  typeFees
}) => {
  return (
    <div className={`${className} ExpenseRowDifference`}>
      {/* <Green id={trackLine?.id} value={trackLine?.green} className="differenceItem" />
      <Pay className="differenceItem" trackLine={trackLine} />
      <QBStatus className="differenceItem" trackLine={trackLine} />
      <PayStatus className="differenceItem" trackLine={trackLine} /> */}
      {/** <FileCells data={trackLine} />  //w8, w9, ACH, Wire */}
      <QuickPay className="differenceItem" id={trackLine?.id} value={trackLine?.qb_state} />
      <Note className="differenceItem" id={trackLine?.id} value={trackLine?.note || ""} />
      <AdditionalFiles className="differenceItem" data={trackLine} />
      <Actions className="differenceItem" trackLine={trackLine} typeFees={typeFees} />
    </div>
  );
};
