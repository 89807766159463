import {FC} from "react";
import {TextComponent} from "../../common/TextComponent/TextComponent";
import {SelectGrid} from "./SelectGrid";
import {MenuGrid} from "./MenuGrid";
import "./GridHeader.scss";

type tGridHeaderProps = {};

export const GridHeader: FC<tGridHeaderProps> = () => {
  return (
    <div className="GridHeader">
      <TextComponent size="20" weight="700" uppercase>
        Grids
      </TextComponent>

      <SelectGrid />

      <MenuGrid />
    </div>
  );
};
