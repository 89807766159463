import {FC, useState} from "react";
import {tTrackExpenseLine} from "api/budget/track/types";
import {InputCell} from "components/shared/InputCell/InputCell";
import {useChangeExpenseValue} from "../../../../../../../_common/useChangeExpenseValue";
import "./ExpenseRowDescription.scss";

type tExpenseRowDescriptionProps = {
  className?: string;
  trackLine: tTrackExpenseLine;
};

export const ExpenseRowDescription: FC<tExpenseRowDescriptionProps> = ({
  className = "",
  trackLine
}) => {
  const [value, setValue] = useState(trackLine?.description || "");
  const {isLoading, onChange} = useChangeExpenseValue({
    id: trackLine?.id,
    type: "description",
    errorCallback: () => setValue(trackLine?.description || "")
  });

  return (
    <InputCell
      initValue={trackLine?.description || ""}
      value={value}
      setValue={setValue}
      isLoading={isLoading}
      placeholder="Description"
      callBack={val => onChange(val)}
      className={`ExpenseRowDescription ${className}`}
      alwaysShowPlaceholder
      withBorder
    />
  );
};
