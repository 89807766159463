import {FC, useState, MouseEvent} from "react";
import {tCompanyPick} from "api/contacts/companies/interfaces";
import {useSetApiParams} from "hooks/useSetApiParams";
import {FavoriteCell} from "components/shared/Cells/FavoriteCell/FavoriteCell";
import {favorites} from "api/favorites";
import {ImgWithFallback} from "components/shared/ImgWithFallback/ImgWithFallback";
import {useCompanyTableRowFilters} from "./useCompanyTableRowFilters";
import {CompanyModals} from "components/shared/Modals/CompanyModals/CompanyModals";
import TagsList from "components/shared/TagsList/TagsList";
import {PhoneCell} from "components/shared/Cells/PhoneCell/PhoneCell";
import {Address} from "components/shared/Address/Address";
import "./CompaniesTableRow.scss";

interface iCompaniesTableRowProps {
  company: tCompanyPick;
  apiParams: ReturnType<typeof useSetApiParams>;
  getCompaniesCallback: () => void;
}
export const CompaniesTableRow: FC<iCompaniesTableRowProps> = ({
  company,
  apiParams,
  getCompaniesCallback
}) => {
  const [showModal, setShowModal] = useState(false);
  const closeCompaniesModal = () => setShowModal(false);

  const {id, in_favorite, avatar_url, name, address} = company;
  const {labels, locales, phones, emails} = company;

  const {handleFilterLabels, handleFilterLocales} = useCompanyTableRowFilters(apiParams); // prettier-ignore

  const handleClickTableRow = () => setShowModal(true);
  const handleClickTableCell = (e: MouseEvent<HTMLTableCellElement>) => e.stopPropagation();

  return (
    <>
      {showModal && (
        <CompanyModals
          company={company.id}
          closeCompaniesModal={closeCompaniesModal}
          onEditCallback={getCompaniesCallback}
        />
      )}

      <tr className="CompaniesTableRow" onClick={handleClickTableRow}>
        <FavoriteCell
          icon="heart"
          addConfig={favorites.addCompany(id)}
          removeConfig={favorites.removeCompany(id)}
          in_favorite={in_favorite}
        />
        <td className="CompaniesTableRow-cell">
          <div className="CompaniesTableRow-cell-avatar-wrapper">
            <ImgWithFallback
              src={avatar_url || ""}
              alt="contact's avatar"
              className="CompaniesTableRow-cell-avatar"
              fallBackType="avatar"
            />
            <span className="CompaniesTableRow-cell-name">{name}</span>
          </div>
        </td>
        <td className="CompaniesTableRow-cell address">
          <Address address={address} />
        </td>
        <td className="CompaniesTableRow-cell">
          <TagsList
            tooltipText="Filter"
            tooltipPosition="right"
            tags={labels}
            handleClick={handleFilterLabels}
          />
        </td>
        <td className="CompaniesTableRow-cell">
          <TagsList
            tooltipText="Filter"
            tooltipPosition="right"
            tags={locales}
            handleClick={handleFilterLocales}
          />
        </td>
        <td className="CompaniesTableRow-cell" onClick={handleClickTableCell}>
          {!!emails.length && (
            <a className="CompaniesTableRow-cell-email" href={`mailto:${emails[0].value}`}>
              {emails[0].value}
            </a>
          )}
        </td>
        <td className="CompaniesTableRow-cell">
          {!!phones?.length && <PhoneCell phones={phones} />}
        </td>
      </tr>
    </>
  );
};
