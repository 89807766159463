import {FC, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {AddTaskGroupForm} from "../_modals/AddTaskGroupForm";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import "./JobTasksHeader.scss";

type tModals = "addGroup";
export const JobTasksHeader: FC = () => {
  const [modal, setModal] = useState<tModals | null>(null);
  const onClose = () => setModal(null);

  const onSetModal = (modalType: tModals) => {
    setModal(modalType);
  };

  const modals: Record<tModals, any> = {
    addGroup: <AddTaskGroupForm onClose={onClose} />
  };

  return (
    <div className="JobTasksHeader">
      <TextComponent uppercase className="title">
        Tasks
      </TextComponent>

      <Button
        color="outline"
        size="sm"
        className="addGroupBtn"
        onClick={() => onSetModal("addGroup")}
      >
        <Icon icon="add" color="black" size="xsm" />
        Group
      </Button>

      {!!modal && modals[modal]}
    </div>
  );
};
