import {useState} from "react";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import {getBaseURL} from "./useApi";

type Props = {
  name: string | undefined;
  templateID: string;
  imgWidth?: number;
  orientation?: "p" | "l";
};

export const usePDF = ({name = "", imgWidth = 208, orientation = "p", templateID}: Props) => {
  const [isDownloading, setIsDownloading] = useState(false);

  // create jsPDF instance from HTML element
  const createPdf = async (imgElement: NodeList | HTMLElement | null = null) => {
    try {
      const input = imgElement || (document.getElementById(templateID) as HTMLElement);
      if (!input) new Error("No pdf template found");

      let canvas: HTMLCanvasElement;
      const isListOfElements = input instanceof NodeList;

      let imgHeight = 0;

      const html2canvasOptions = {
        proxy: getBaseURL() + "asset"
      };

      if (isListOfElements) {
        canvas = await html2canvas(input[0] as HTMLElement, html2canvasOptions);
        imgHeight = (canvas.height * imgWidth) / canvas.width;
      } else {
        canvas = await html2canvas(input, html2canvasOptions);
        imgHeight = (canvas.height * imgWidth) / canvas.width;
      }

      const getImageData = (canvas: HTMLCanvasElement) => canvas.toDataURL("img/png");
      const pdf = new jsPDF(orientation, "mm", [imgWidth, imgHeight], true);

      const addImageToPdf = (canvas: HTMLCanvasElement) => {
        const imgData = getImageData(canvas);
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      };

      const createImageForList = async (input: NodeList) => {
        for (let i = 0; i < input.length; i++) {
          canvas = await html2canvas(input[i] as HTMLElement, html2canvasOptions);

          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          const imgData = getImageData(canvas);

          pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
          if (i < input.length - 1) pdf.addPage();
        }
      };

      if (isListOfElements) {
        await createImageForList(input);
      } else {
        addImageToPdf(canvas);
      }

      return pdf;
    } catch (error) {
      console.log(error);
    }
  };

  const createFilename = () => {
    return `${name || new Date().toDateString()}.pdf`;
  };

  // create jsPDF and save file as download
  const onDownloadPdf = async () => {
    try {
      setIsDownloading(true);
      const file = await createPdf();
      await file?.save(createFilename());
    } catch (error) {
      console.log(error);
    } finally {
      setIsDownloading(false);
    }
  };

  return {onDownloadPdf, isDownloading, createPdf, templateID};
};
