import {Icon} from "components/Icon/Icon";
import {MIN_OVERVIEW_HEIGHT, OverviewPipelineTable} from "./OverviewPipelineTable";
import {useContextOverview} from "../OverviewContext";
import "./OverviewJobsPipeline.scss";

export const OverviewJobsPipeline = () => {
  const {showPipelines, pipelines, setShowPipelines, pipelinesIsLoading} = useContextOverview();

  const show = showPipelines && !pipelinesIsLoading && !!pipelines?.length;

  const handleToggleShowPipelines = () => setShowPipelines(prev => !prev);

  return (
    <div className="OverviewJobsPipeline">
      <div className="OverviewJobsPipeline-text" onClick={handleToggleShowPipelines}>
        <h5 className="OverviewJobsPipeline-text-title">Jobs Pipeline</h5>
        <Icon
          icon="arrow-down"
          size="xsm"
          color="gray"
          className={`OverviewJobsPipeline-text-icon ${showPipelines ? "active" : ""}`}
        />
      </div>

      {pipelinesIsLoading && (
        //12 resizer height
        <div style={{height: MIN_OVERVIEW_HEIGHT + 12}} className="loading">
          Loading...
        </div>
      )}

      {show && <OverviewPipelineTable data={pipelines} />}
    </div>
  );
};
