import {FC} from "react";
import ReactCrop from "react-image-crop";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import useImageCrop from "./useImageCrop";
import {getCropperAspects, tAspectImageCrop} from "../ImageCrop/helpers";
import {UploadBtn} from "./UploadBtn";
import "react-image-crop/dist/ReactCrop.css";
import "./ImageCrop.scss";

export type tImageCropperProps = {
  onChangeImage: (img: string) => void;
  aspect?: tAspectImageCrop;
};

export const ImageCrop: FC<tImageCropperProps> = ({onChangeImage, aspect}) => {
  const aspectImage = getCropperAspects(aspect);
  const {
    onSelectFile,
    imgSrc,
    onImageLoad,
    imgRef,
    scale,
    setCompletedCrop,
    crop,
    setCrop,
    clearState,
    onSaveImage,
    previewCanvasRef,
    completedCrop,
    setScale,
    cropping,
    compressing
  } = useImageCrop({onChangeImage, aspectImage});

  return (
    <>
      <UploadBtn onSelectFile={onSelectFile} loading={compressing} />

      {!!imgSrc && (
        <div className="ImageCrop-wrap">
          <div className="ImageCrop">
            <button className="ImageCrop-close" onClick={clearState}>
              <Icon icon="close" color="white" size="lg" />
            </button>

            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={c => setCompletedCrop(c)}
              aspect={aspectImage}
              className="Cropper"
              circularCrop={aspect === "circle"}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{transform: `scale(${scale})`}}
                onLoad={onImageLoad}
              />
            </ReactCrop>

            <div className="ImageCrop-control">
              <div className="ImageCrop-control-scale">
                <InputField
                  type="range"
                  step="0.001"
                  max="5"
                  value={scale}
                  disabled={!imgSrc}
                  onChange={e => setScale(Number(e.target.value))}
                />
              </div>

              <div className="ImageCrop-control-button">
                <Button
                  onClick={clearState}
                  size="md"
                  color="outline"
                  type="button"
                  disabled={cropping}
                >
                  Cancel
                </Button>
                <Button
                  onClick={onSaveImage}
                  size="md"
                  color="dark"
                  type="button"
                  loading={cropping}
                >
                  Save
                </Button>
              </div>

              <div style={{visibility: "hidden"}}>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    width: completedCrop?.width,
                    height: completedCrop?.height
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
