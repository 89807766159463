import {FC, useState} from "react";
import {team} from "api/team";
import {iTeammateDetailsData} from "api/team/types";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {useHttp} from "hooks/httpServices/useHttp";
import {getApiError} from "helpers/getApiError";
import {useContextTeam} from "../../../../../../pages/Team/TeamContext";
import {eventBus} from "EventBus/EventBus";

interface iDeleteInviteProps {
  teammateDetail: iTeammateDetailsData;
}

export const DeleteInvite: FC<iDeleteInviteProps> = ({teammateDetail}) => {
  const {getTeamList} = useContextTeam();
  const [showConfirmWindow, setConfirmWindow] = useState(false);
  const {call, isLoading} = useHttp();

  const closeConfirmModal = () => setConfirmWindow(false);

  const onDeleteInvite = async () => {
    try {
      const res = await call(team.deleteTeammate(teammateDetail.id));
      const successMsg = res?.data?.message;
      eventBus.dispatch("showToast", {text: successMsg || "Success !"});
      await getTeamList();
    } catch (error) {
      const {msg} = getApiError(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error !"});
    }
  };
  return (
    <>
      <Button size="md" color="light" onClick={() => setConfirmWindow(true)}>
        <Icon icon="close" color="black" size="sm" /> Delete Invite
      </Button>

      <ConfirmWindow
        show={showConfirmWindow}
        title="Delete Invite ?"
        confirmBtn={{text: "Delete Invite"}}
        cancelBtn={{text: "Cancel"}}
        onConfirm={onDeleteInvite}
        onClose={closeConfirmModal}
        isLoading={isLoading}
      >
        <div className="TeamMemberConfirmMessage">
          Are you really want to remove {teammateDetail?.display_name}?
        </div>
      </ConfirmWindow>
    </>
  );
};
