import {useState} from "react";

type tUseDatePicker = {
  initValue?: string | undefined | null;
  errors?: string[];
  isRequired?: boolean;
  customErrMsg?: string;
  withPlaceholder?: boolean;
};

export const useDatePicker = (props?: tUseDatePicker) => {
  const initVal = !!props?.withPlaceholder ? '' : new Date().toDateString(); // prettier-ignore
  const [value, setValue] = useState(props?.initValue || initVal);
  const [err, setErrors] = useState<string[]>(props?.errors || []);

  const onChange = (value: string) => {
    err.length && setErrors([]);
    setValue(value);
  };

  const checkValidity = () => {
    if (props?.isRequired && !value) {
      const errMsg = props?.customErrMsg || "The date field is required";
      setErrors([errMsg]);
      return false;
    }
    return true;
  };

  return {
    value,
    errors: err,
    setErrors,
    checkValidity,
    inputProps: {
      value,
      onChange
    }
  };
};
