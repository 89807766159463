import {ChangeEvent, FC, useState} from "react";
import {tEstimateType} from "api/budget/estimate/types";
import {tBudgetTypesReturn} from "../useBudgetTypes";
import {BudgetTypesListItem} from "../BudgetTypesListItem/BudgetTypesListItem";
import {CreatingLine} from "../CreatingLine/CreatingLine";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {useHttp} from "hooks/httpServices/useHttp";
import {estimateApi} from "api/budget/estimate";
import {SwitchInput} from "components/shared/FormComponents/SwitchInput/SwitchInput";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {Loading} from "components/shared/Loading/Loading";

import "./BudgetTypeCategoryList.scss";

interface iBudgetTypeCategoryListProps {
  data: tEstimateType;
  onSetStep: tBudgetTypesReturn["setStepData"];
  setBudgetTypeList: tBudgetTypesReturn["setBudgetTypeList"];
}

export const BudgetTypeCategoryList: FC<iBudgetTypeCategoryListProps> = ({
  data,
  setBudgetTypeList,
  onSetStep
}) => {
  const createQuery = useHttp();
  const defaultTaxQuery = useHttp();
  const [creating, setCreating] = useState(false);
  const [defaultTax, setDefaultTax] = useState(data.tax_default);

  const handleClick = (id: number) =>
    onSetStep(prev => ({
      ...prev,
      categories: {
        id,
        name: data?.categories?.find(d => d.id === id)?.name || ""
      },
      step: "costs"
    }));
  const onAddLine = () => setCreating(true);
  const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: {checked}
    } = event;

    setDefaultTax(checked);

    try {
      const {
        data: {message}
      } = await defaultTaxQuery.call(estimateApi.setTaxDefault(data.id, {tax_default: checked}));
      setBudgetTypeList(prev =>
        prev.map(value =>
          value.id === data.id
            ? {
                ...value,
                tax_default: checked
              }
            : value
        )
      );
      eventBus.dispatch("showToast", {text: message});
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
      setDefaultTax(prev => !prev);
    }
  };

  const onCreateCategory = async (payload: {name: string; code: string}) => {
    try {
      const {
        data: {data: category, message}
      } = await createQuery.call(estimateApi.addEstimateCategory(data!.id, payload));
      setBudgetTypeList(prev =>
        prev.map(estimate => {
          return estimate.id === data.id
            ? {
                ...estimate,
                categories: [...estimate.categories, category]
              }
            : estimate;
        })
      );
      setCreating(false);
      eventBus.dispatch("showToast", {text: message});
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <div className="BudgetTypeCategoryList">
      <div className="BudgetTypeCategoryList-slider">
        {defaultTaxQuery.isLoading && <Loading type="inBlock" />}
        <TextComponent weight="700" size="15" uppercase>
          Sales tax default
        </TextComponent>
        <SwitchInput disabled={!data.categories?.length} checked={defaultTax} onChange={onChange} />
      </div>
      {!data.categories?.length && (
        <TextComponent className="BudgetTypeCategoryList-warning" textAlign="center">
          Budget type hasn't any categories yet
        </TextComponent>
      )}
      {data.categories.map(cat => {
        return (
          <BudgetTypesListItem
            key={cat.id}
            code={cat.code}
            id={cat.id}
            name={cat.name}
            handleClick={handleClick}
          />
        );
      })}
      {creating && (
        <CreatingLine
          onSave={onCreateCategory}
          placeholder="Category Name"
          onRemove={setCreating}
          isLoading={createQuery.isLoading}
        />
      )}
      {!creating && (
        <Button
          onClick={onAddLine}
          className="BudgetTypes-addBtn"
          size="sm"
          width="fullWidth"
          color="outline"
        >
          <Icon icon="add" size="xsm" />
          Category
        </Button>
      )}
    </div>
  );
};
