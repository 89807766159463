import {FC} from "react";

type tDownloadProps = {
  [key: string]: any;
};

export const Download: FC<tDownloadProps> = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M13.1429 7.42859L8.00002 12.5715L2.85723 7.42859L4.5715 5.71432L6.8572 8.00002V0H9.1429V8.00002L11.4286 5.71432L13.1429 7.42859Z"
        fill="currentColor"
      />
      <path d="M16 13.7143H0V16H16V13.7143Z" fill="currentColor" />
    </svg>
  );
};
