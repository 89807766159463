import {FC, useState} from "react";
import {tCrewContact} from "api/jobCrewGroup/types";
import {Button} from "components/shared/Button/Button";
import {SelectCell} from "../_common/SelectCell/SelectCell";
import {useChangeCrewValue} from "../_common/useChangeCrewValue";
import {getSuffix} from "../_common/getSuffix";
import {TextWithTooltip} from "components/TextWitchTooltip/TextWithTooltip";

type tRowPhonesProps = {
  crewContact: tCrewContact;
  groupId: number;
  isAgent?: boolean;
};

export const RowPhones: FC<tRowPhonesProps> = ({crewContact, groupId, isAgent}) => {
  const typeContact = isAgent ? "agent" : "contact";
  const contact = crewContact[typeContact];
  const {default_phone} = crewContact;
  const {changeValue, isLoading} = useChangeCrewValue();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const changePhone = async (id: number) => {
    setMenuAnchor(null);
    changeValue({type: "phone", id: crewContact?.id, groupId, data: {default_phone_id: id}});
  };

  const phone = !!default_phone?.value && !isAgent ? default_phone : contact?.phones?.[0];
  // const btnVal = formatPhoneNumber({value: phone?.value}) + getSuffix(phone?.type, 7);
  const btnVal =  phone?.value + getSuffix(phone?.type, 7);

  return (
    <>
      {!!phone && (
        <SelectCell
          isLoading={isLoading}
          menuAnchor={menuAnchor}
          setMenuAnchor={setMenuAnchor}
          btnChildren={!!phone ? btnVal : ""}
          blockOpenMenu={isAgent ? true : contact?.phones && contact?.phones?.length < 2}
        >
          {contact?.phones?.map(item => {
            return (
              <Button
                key={item?.id}
                size="sm"
                onClick={() => changePhone(item?.id)}
                disabled={isLoading}
              >
                {item?.value} {getSuffix(item?.type, 7)}
                {/* {formatPhoneNumber({value: item?.value})} {getSuffix(item?.type, 7)} */}
              </Button>
            );
          })}
        </SelectCell>
      )}

      {!phone && (
        <TextWithTooltip
          text="no phone"
          textProps={{color: "gray-10", p: "px-12"}}
          tooltips={{message: "Open the contact record to add a phone"}}
        />
      )}
    </>
  );
};
