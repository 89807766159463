import {FC, useState} from "react";
import {tCrewContact} from "api/jobCrewGroup/types";
import {Button} from "components/shared/Button/Button";
import {SelectCell} from "../_common/SelectCell/SelectCell";
import {useChangeCrewValue} from "../_common/useChangeCrewValue";
import {getSuffix} from "../_common/getSuffix";
import {TextWithTooltip} from "components/TextWitchTooltip/TextWithTooltip";

type tRowEmailsProps = {
  crewContact: tCrewContact;
  groupId: number;
  isAgent?: boolean;
};

export const RowEmails: FC<tRowEmailsProps> = ({crewContact, groupId, isAgent}) => {
  const typeContact = isAgent ? "agent" : "contact";
  const contact = crewContact[typeContact];
  const {default_email} = crewContact;
  const {changeValue, isLoading} = useChangeCrewValue();

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const changeEmail = async (id: number) => {
    setMenuAnchor(null);
    changeValue({type: "email", id: crewContact?.id, groupId, data: {default_email_id: id}});
  };

  const email = !!default_email?.value && !isAgent ? default_email : contact?.emails?.[0];

  return (
    <>
      {!!email && (
        <SelectCell
          isLoading={isLoading}
          menuAnchor={menuAnchor}
          setMenuAnchor={setMenuAnchor}
          btnChildren={email?.value + getSuffix(email?.type, 8)}
          blockOpenMenu={isAgent ? true : !!contact?.emails && contact?.emails?.length < 2}
        >
          {contact?.emails?.map(item => {
            return (
              <Button
                key={item?.id}
                size="sm"
                onClick={() => changeEmail(item?.id)}
                disabled={isLoading}
              >
                {`${item?.value} ${getSuffix(item?.type, 8)}`}
              </Button>
            );
          })}
        </SelectCell>
      )}

      {!email && (
        <TextWithTooltip
          as="span"
          text="no email"
          textProps={{color: "gray-10", p: "px-12"}}
          tooltips={{message: "Open the contact record to add a email"}}
        />
      )}
    </>
  );
};
