import {FC, useState} from "react";
import {tCrewContact} from "api/jobCrewGroup/types";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {tContactPosition} from "api/jobCrewContactPosition/type";
import {Button} from "components/shared/Button/Button";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextJobCrew} from "../../../JobCrewContext";
import {jobCrewContact} from "api/jobCrewContact";
import {updateContact} from "../../../commonJobCrew/updateContact";
import {eventBus} from "EventBus/EventBus";
import "./RowPositions.scss";

type tRowPositionsProps = {
  crewContact: tCrewContact;
  positionsList: Array<tContactPosition>;
  groupId: number;
};

export const RowPositions: FC<tRowPositionsProps> = ({crewContact, positionsList, groupId}) => {
  const {position} = crewContact;
  const {jobCrewGroupList: {setCrewGroupList}} = useContextJobCrew(); // prettier-ignore
  const {call, isLoading} = useHttp();

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const changePosition = async (pos: {id: number; name: string}) => {
    setMenuAnchor(null);

    try {
      const res = await call(
        jobCrewContact.setPositionForContact(crewContact?.id, {position_id: pos?.id})
      );

      setCrewGroupList(prev => {
        return updateContact({
          prev,
          groupId,
          crewContactId: crewContact?.id,
          updatedContact: res?.data?.data
        });
      });
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <div className="RowPositions">
      <Button
        onClick={showMenu}
        size="sm"
        disabled={isLoading}
        hidePadding="horizontal"
        btnType="text"
      >
        {position?.name}
      </Button>

      <DropdownMenu
        anchor={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        transformOrigin={{
          horizontal: "right"
        }}
      >
        {crewContact?.contact?.titles?.map(item => {
          return (
            <Button
              key={item?.id}
              size="sm"
              onClick={() => changePosition(item)}
              disabled={isLoading}
            >
              {item?.name}
            </Button>
          );
        })}
        {/* {positionsList?.map(item => {
          return (
            <Button
              key={item?.id}
              size="sm"
              onClick={() => changePosition(item)}
              disabled={isLoading}
            >
              {item?.name}
            </Button>
          );
        })} */}
      </DropdownMenu>
    </div>
  );
};
