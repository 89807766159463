import {FC} from "react";
import {tHoldStatus} from "api/common/types";
import {tContactPosition} from "api/jobCrewContactPosition/type";
import {tCrewContact} from "api/jobCrewGroup/types";
import {DragAndDropWrap} from "components/DragAndDropWrap/DragAndDropWrap";
import {CrewTableCell} from "../CrewTableCell/CrewTableCell";
import {RowName} from "../CrewTableCells/RowName/RowName";
import {RowPositions} from "../CrewTableCells/RowPositions/RowPositions";
import {RowStatus} from "../CrewTableCells/RowStatus/RowStatus";
import {RowDates} from "../CrewTableCells/RowDates/RowDates";
import {RowRate} from "../CrewTableCells/RowRate/RowRate";
import {RowPhones} from "../CrewTableCells/RowPhones/RowPhones";
import {RowEmails} from "../CrewTableCells/RowEmails/RowEmails";
import {RowBilling} from "../CrewTableCells/RowBilling/RowBilling";
import {RowNotes} from "../CrewTableCells/RowNotes/RowNotes";
import {RowDietary} from "../CrewTableCells/RowDietary/RowDietary";
import {RowActions} from "../CrewTableCells/RowActions/RowActions";
import {tUseChangeContactOrder} from "../useChangeContactOrder";
import {useContextJobCrew} from "../../../JobCrewContext";
import "./CrewTableRow.scss";

type tCrewTableRowProps = {
  crewContact: tCrewContact;
  positionsList: Array<tContactPosition>;
  holdStatusList: Array<tHoldStatus>;
  groupId: number;
} & tUseChangeContactOrder;

export const CrewTableRow: FC<tCrewTableRowProps> = props => {
  const {widthStyles} = useContextJobCrew();
  const {crewContact, groupId, holdStatusList, positionsList} = props;
  const {dropCallBack, isLoading, setCurrContact} = props;

  const cells = (props: {crewContact: tCrewContact}) => {
    return [
      {cell: <RowName {...props} />},
      {cell: <RowPositions {...props} positionsList={positionsList} groupId={groupId} />},
      {cell: <RowStatus {...props} holdStatusList={holdStatusList} />},
      {cell: <RowDates {...props} />},
      {cell: <RowRate {...props} groupId={groupId} />},
      {cell: <RowPhones {...props} groupId={groupId} />},
      {cell: <RowEmails {...props} groupId={groupId} />},
      {cell: <RowBilling {...props} groupId={groupId} />},
      {cell: <RowNotes {...props} groupId={groupId} />},
      {cell: <RowDietary {...props} />},
      {cell: <RowActions {...props} typeRow="contact" groupId={groupId} />}
    ];
  };

  const hasAgent = !!crewContact?.agent ? "hasAgent" : "";
  return (
    <DragAndDropWrap
      className={`CrewTableRow ${hasAgent}`}
      draggingItem={crewContact}
      setCurrItem={setCurrContact}
      dropCallBack={dropCallBack}
      isLoading={isLoading}
      as="tr"
    >
      {cells({crewContact})?.map((cell, i) => {
        const width = widthStyles(i)?.width ? widthStyles(i)?.width + "px" : undefined;
        return (
          <CrewTableCell key={i} style={{maxWidth: width, minWidth: width}}>
            {cell?.cell}
          </CrewTableCell>
        );
      })}
    </DragAndDropWrap>
  );
};
