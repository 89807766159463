import {FC} from "react";
import logo from "assets/images/VersatileStudios.svg";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import "./CallSheetTemplateHeader.scss";

type tCallSheetTemplateHeaderProps = {
  callSheetName: string;
  date: string;
  jobName: string;
  clientName: any;
  artist: any;
};

export const CallSheetTemplateHeader: FC<tCallSheetTemplateHeaderProps> = props => {
  const {artist, clientName, date, jobName, callSheetName} = props;

  return (
    <div className="CallSheetTemplateHeader">
      <img src={logo} alt="logo" />

      <div className="CallSheetTemplateHeader-titleDate">
        <TextComponent size="20" uppercase>
          {callSheetName}
        </TextComponent>
        <TextComponent>{date}</TextComponent>
      </div>

      <div className="CallSheetTemplateHeader-jobInfo">
        <TextComponent uppercase size="26">
          {clientName}
        </TextComponent>
        <TextComponent uppercase size="26">
          {jobName}
        </TextComponent>
        <TextComponent uppercase size="26">
          {artist}
        </TextComponent>
      </div>
    </div>
  );
};
