import {FC} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import {TimeCell} from "../CHCommon/TimeCell";
import {tCallSheetContact, tCallSHeetGroupContacts} from "api/callSheet/types";
import {Checkbox} from "components/shared/FormComponents/Checkbox/Checkbox";
import {LocationCell} from "../CHCommon/LocationCell";
import {useChangeContactRow} from "./useCHangeContactRow";
import {AppTooltips} from "components/AppTooltips/AppTooltips";
import "./CallSheetBuildContact.scss";

type tCallSheetBuildContactListProps = {
  group: tCallSHeetGroupContacts;
  contact: tCallSheetContact;
  setContactGroup: any;
  showTooltip?: boolean;
};

export const CallSheetBuildContact: FC<tCallSheetBuildContactListProps> = ({
  group,
  contact,
  showTooltip,
  setContactGroup
}) => {
  const isHidden = contact?.selected ? "" : "hidden";
  const isHiddenPhone = contact?.phone?.show ? "" : "hidden";
  const isHiddenEmail = contact?.email?.show ? "" : "hidden";

  const {changeRow, onChangeRowWithPrevent} = useChangeContactRow({
    contact,
    group,
    setContactGroup
  });

  return (
    <>
      <div className={`CSContact ${isHidden} ${showTooltip ? "showTooltip" : ""}`}>
        <Checkbox
          id={String(contact?.id)}
          label={contact?.name}
          value={contact?.name}
          className="CSContact-checkbox"
          checked={!!contact?.selected}
          onChange={() => changeRow("row")}
        />
        {showTooltip && <AppTooltips page="callSheet" tooltip="SELECT CONTACTS" />}
      </div>
      <div className={`CSContact ${isHidden}`}>{contact?.position}</div>
      <div className={`CSContact ${isHidden}`}>
        <div className={`emails ${isHiddenEmail} ${showTooltip ? "showTooltip" : ""}`}>
          <Tooltip
            message={isHiddenEmail ? "reveal" : "hide"}
            onClick={() => changeRow("email")}
            position="top"
          >
            {contact?.email?.value}
            {contact?.email?.value && (
              <Button onClick={e => onChangeRowWithPrevent(e, "email")}>
                <Icon icon={isHiddenEmail ? "eye" : "hide"} color={"gray"} size="sm" />
              </Button>
            )}
          </Tooltip>

          {showTooltip && <AppTooltips page="callSheet" tooltip="HIDING DETAILS" />}
        </div>
      </div>
      <div className={`CSContact ${isHidden}`}>
        <div className={`phones ${isHiddenPhone}`}>
          <Tooltip
            message={isHiddenPhone ? "reveal" : "hide"}
            onClick={() => changeRow("phone")}
            position="top"
          >
            {/* {formatPhoneNumber({value: contact?.phone?.value})} */}
            {contact?.phone?.value}
            {contact?.phone?.value && (
              <Button onClick={e => onChangeRowWithPrevent(e, "phone")}>
                <Icon icon={isHiddenPhone ? "eye" : "hide"} color={"gray"} size="sm" />
              </Button>
            )}
          </Tooltip>
        </div>
      </div>

      <div className={`CSContact ${isHidden}`}>
        <TimeCell group={group} contact={contact} setContactGroup={setContactGroup} />
      </div>
      <div className={`CSContact ${isHidden}`}>
        <LocationCell group={group} contact={contact} setContactGroup={setContactGroup} />
      </div>
      <div className={`CSContact ${isHidden}`} />
    </>
  );
};
