import {FC, ReactNode, useState} from "react";
import {tJobTasksTemplateDataTask} from "api/jobs/tasks/types";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {RenameTasksGroup} from "../../../_modals/RenameTasksGroup";
import {DeleteTasksGroup} from "../../../_modals/DeleteTasksGroup";
import {useContextJobTasks} from "../../../_context/JobTaskContext";
import {MoveTasksGroup} from "./MoveTasksGroup";

type tEditButtonProps = {
  data: tJobTasksTemplateDataTask;
  index: number;
};

type tModals = "rename" | "delete";

export const EditButton: FC<tEditButtonProps> = ({data, index}) => {
  const {tasks} = useContextJobTasks();
  const [modal, setModal] = useState<tModals | null>(null);

  const [menu, setMenu] = useState<HTMLElement | null>(null);
  const showMenuContact = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenu(event.currentTarget);
  };

  const onCloseMenu = () => setMenu(null);
  const onCloseModal = () => setModal(null);
  const showModal = (type: tModals) => {
    setModal(type);
    onCloseMenu();
  };

  const modals: Record<tModals, ReactNode> = {
    rename: <RenameTasksGroup onClose={onCloseModal} name={data?.name} id={index} />,
    delete: <DeleteTasksGroup onClose={onCloseModal} name={data?.name} id={index} type="group" />
  };

  return (
    <>
      <Button className="groupEditBtn" color="outline" size="sm" onClick={showMenuContact}>
        <Icon icon="edit" size="xsm" />
      </Button>

      <DropdownMenu anchor={menu} onClose={onCloseMenu}>
        <Button size="sm" onClick={() => showModal("rename")}>
          Rename
        </Button>
        {!!tasks && tasks?.data?.length > 1 && (
          <>
            <Button size="sm" onClick={() => showModal("delete")}>
              Delete
            </Button>

            <hr />
            <MoveTasksGroup groupId={index} onClose={onCloseMenu} />
          </>
        )}
      </DropdownMenu>

      {!!modal && modals[modal]}
    </>
  );
};
