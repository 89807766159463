import {FC, ReactNode, MouseEvent, useState} from "react";
import {iTagButton, TagButton} from "components/shared/TagButton/TagButton";
import {Popover, iPopover} from "components/shared/Popover/Popover";
import "./TagsButtonRow.scss";

type tItem = {
  id: number;
  name?: string;
  artist?: {name?: string; id: number};
};

interface iTagsButtonRow extends Omit<iTagButton, "children"> {
  data: tItem[];
  emptyData: ReactNode;
  artist?: boolean;
  onClickDetailsList?: (event: MouseEvent<HTMLButtonElement>, item: tItem) => void;
  popoverProps?: Partial<iPopover>;
}

export const TagsButtonRow: FC<iTagsButtonRow> = ({
  data,
  emptyData,
  artist,
  onClickDetailsList,
  popoverProps,
  ...tagsButtonProps
}) => {
  const [showPopover, setShowPopover] = useState<HTMLElement | null>(null); // prettier-ignore

  if (!data.length) return <>{emptyData}</>;

  const getName = (props: tItem) => (artist ? props?.artist?.name : props?.name);
  const onClickButtonList = (event: MouseEvent<HTMLButtonElement>, item: any) => {
    event.stopPropagation();
    setShowPopover(null);
    onClickDetailsList?.(event, item);
  };

  const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowPopover(event.currentTarget);
  };

  const onHidePopover = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setShowPopover(null);
  };

  return (
    <>
      {data?.map((item: tItem, index) => {
        if (index === 1) {
          return (
            <TagButton
              key={item?.id || item?.artist?.id}
              onClick={handleOpenPopover}
              className="TagsButtonRow"
              {...tagsButtonProps}
            >
              <span>multiple</span>
              <span className="counting">{data.length}</span>
            </TagButton>
          );
        }

        if (data?.length === 1) {
          return (
            <TagButton
              onClick={event => onClickButtonList?.(event, artist ? item?.artist : item)}
              key={item?.id || item?.artist?.id}
              className="TagsButtonRow"
              {...tagsButtonProps}
            >
              {getName(item)}
            </TagButton>
          );
        }

        return null;
      })}

      <Popover {...popoverProps} anchor={showPopover} onClose={onHidePopover}>
        <span className="TagsButtonRow-detailsList">
          {data.map(val => {
            return (
              <TagButton
                key={val?.artist?.id || val?.id}
                onClick={event => onClickButtonList?.(event, artist ? val?.artist : val)}
                {...tagsButtonProps}
              >
                {getName(val)}
              </TagButton>
            );
          })}
        </span>
      </Popover>
    </>
  );
};
