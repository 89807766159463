import {useState} from "react";
import {tCrewContact, tCrewGroupContact} from "api/jobCrewGroup/types";
import {useContextJobCrew} from "../../JobCrewContext";
import {tDraggingItem} from "components/DragAndDropWrap/DragAndDropWrap";
import {useChangeCrewValue} from "./CrewTableCells/_common/useChangeCrewValue";

export type tUseChangeContactOrder = ReturnType<typeof useChangeContactOrder>;

export const useChangeContactOrder = (group: tCrewGroupContact) => {
  const { jobCrewGroupList: { setCrewGroupList } } = useContextJobCrew(); // prettier-ignore
  const {changeValue, isLoading} = useChangeCrewValue();

  const [currContact, setCurrContact] = useState<tDraggingItem | null>(null);
  const dropCallBack = (draggingItem: {[key: string]: any; id: number | string}) => {
    const newIndex = group?.contacts?.findIndex(item => item?.id === draggingItem?.id) as number;

    setCrewGroupList(prev => {
      return prev.map(item => {
        if (item?.id === group?.id && !!item?.contacts?.length) {
          const newContArr = [...item?.contacts?.filter(item => item?.id !== currContact?.id)];
          currContact && newContArr.splice(newIndex, 0, currContact as tCrewContact);
          item.contacts = newContArr;
        }
        return item;
      });
    });

    currContact?.id &&
      changeValue({
        type: "contactOrder",
        id: +currContact.id,
        groupId: group?.id,
        data: {order: newIndex + 1},
        blockUpdateState: true
      });
  };

  return {
    isLoading,
    dropCallBack,
    setCurrContact
  };
};
