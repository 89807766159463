import {tTrackFeesItem} from "api/budget/track/types";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {CostValueRowName} from "../_common/CostValueRowName/CostValueRowName";
import {getDiffValueColor, getValue} from "../_common/getAndFormatVal";

type tProps = {
  data: tTrackFeesItem | undefined;
  title: string;
};

export const useCostValueRowCells = ({data, title}: tProps) => {
  const estimateName = data?.expenses?.length === 1 ? data?.expenses?.[0]?.name : "All Estimates";

  const withBorder = "CostValueRow-cell borderRight";
  const withAlign = "CostValueRow-cell alignRight";
  const all = "CostValueRow-cell alignRight borderRight";

  const cells = [
    {
      component: (
        <CostValueRowName
          className={withBorder}
          trackCost={{name: title, code: `—`}}
          typePayroll={data?.target_name}
        />
      )
    },
    {
      component: (
        <TextComponent weight="500" size="10" uppercase className={withBorder} nowrap>
          {estimateName}
        </TextComponent>
      ),
      colSpan: 4
    },
    {
      component: (
        <TextComponent weight="700" size="15" className={all}>
          {getValue(data?.total_budget)}
        </TextComponent>
      )
    },
    {
      component: (
        <TextComponent weight="400" size="14" className={all}>
          {getValue(data?.total_working)}
        </TextComponent>
      )
    },
    {
      component: (
        <TextComponent weight="700" size="15" className={all}>
          {getValue(data?.total_actual)}
        </TextComponent>
      )
    },
    {
      component:
        // prettier-ignore
        <TextComponent weight="400" size="14" color={getDiffValueColor(data?.total_difference || 0)} className={withAlign}>
          {getValue(data?.total_difference)}
        </TextComponent>,
      className: "difference"
    },
    {
      component: <div className="CostValueRow-cell" />,
      colSpan: 5
    }
  ];

  return {cells};
};
