import {createContext, FC, useContext} from "react";
import {useTemplateCalendar} from "./useTemplateCalendar";

export type tUseTemplateCalendar = ReturnType<typeof useTemplateCalendar>;

export type tTemplateCalendarContext = tUseTemplateCalendar;

const TemplateCalendarContext = createContext<tTemplateCalendarContext | null>(null);
TemplateCalendarContext.displayName = "TemplateCalendarContext";

export const TemplateCalendarContextProvider: FC<{id: number; onClose: () => void}> = ({
  id,
  onClose,
  children
}) => {
  const value = useTemplateCalendar({id, onClose});

  return (
    <TemplateCalendarContext.Provider value={value}>{children}</TemplateCalendarContext.Provider>
  );
};

export const useContextTemplateCalendar = () => {
  const context = useContext(TemplateCalendarContext);

  if (context === null) {
    throw new Error("<<< JobsContext >>> must be used within a JobsContextProvider");
  }

  return context as tTemplateCalendarContext;
};
