import {Header} from "components/Header/Header";
import {tabs} from "../contactsNavigation";
import {HeaderButtons} from "../commonComponent/HeaderButtons/HeaderButtons";
import {CompaniesTableRow} from "./CompaniesTable/CompaniesTableRow/CompaniesTableRow";
import ContactsTableFilter from "../commonComponent/ContactsTableFilter/ContactsTableFilter";
import {TableToolbar} from "components/TableToolbar/TableToolbar";
import {Table} from "components/Table/Table";
import {Pagination} from "components/Pagination/Pagination";
import {useApiListNotification} from "hooks/useApiListNotification/useApiListNotification";
import {TableHeadItem} from "components/Table/TableHeadItem";
import {companiesTableColumn} from "./CompaniesTable/companiesTableColumn";
import {useContextContacts} from "../context/ContactsContext";
import "./Companies.scss";

export const Companies = () => {
  const {
    companies: {
      getCompaniesList,
      companiesListMeta,
      isLoading,
      companies,
      apiParams,
      isError,
      error
    },
    people: {getPeopleList}
  } = useContextContacts();

  const {element} = useApiListNotification({isLoading, isError, error, list: companies});

  const renderHeaderList = () =>
    companiesTableColumn.map(item => <TableHeadItem key={item.id} data={item} />);

  const renderBodyList = () => {
    if (!companies?.length && !isError) return element;
    return companies?.map(company => (
      <CompaniesTableRow
        getCompaniesCallback={getCompaniesList}
        key={company?.id}
        company={company}
        apiParams={apiParams}
      />
    ));
  };

  return (
    <div className="Companies">
      <Header title="Contacts" tabs={tabs}>
        <HeaderButtons
          type="companies"
          getCompaniesCallback={getCompaniesList}
          getContactsCallback={getPeopleList}
        />
      </Header>

      <div className="content-padding">
        <TableToolbar
          titleChildren={!!companies?.length ? `Showing ${companies?.length} companies` : ""}
          children={<ContactsTableFilter />}
        />
        <Table
          isLoading={isLoading}
          scroll
          headerRowChildren={renderHeaderList()}
          bodyChildren={renderBodyList()}
        />

        {companiesListMeta && <Pagination meta={companiesListMeta} />}
      </div>
    </div>
  );
};
