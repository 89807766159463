import {Link, useLocation} from "react-router-dom";
import {LogoText} from "assets/icons";
import {useContextApp, useContextProfile} from "context";
import {absRoutes} from "router/helpers";
import {Icon, tIcon} from "components/Icon/Icon";
import {Favorites} from "./Favorites/Favorites";
import "./Sidebar.scss";

export const Sidebar = () => {
  const {pathname} = useLocation();
  const {userData} = useContextProfile();
  const {sidebar, setSidebar, setHubSearchWindow, setNotificationsWindow, setAccountModal} =
    useContextApp();

  const isAdmin = userData?.role?.name === "Admin";
  const isPrivilegedUser = userData?.role?.name === "Admin" || userData?.role?.name === "User+";

  const activeNavItemClassName = (url: string) => (pathname.includes(url) ? "active" : "");

  const sideBarList = [
    {
      label: "Dashboard",
      icon: "dashboard",
      path: absRoutes.dashboard.overview,
      className: activeNavItemClassName(absRoutes.dashboard.main)
    },
    {
      label: "Jobs",
      icon: "jobs",
      path: absRoutes.jobs.main,
      className: activeNavItemClassName(absRoutes.jobs.main)
    },
    {
      label: "Contacts",
      icon: "contact",
      path: absRoutes.contacts.people,
      className: activeNavItemClassName(absRoutes.contacts.main)
    },
    {
      label: "Locations",
      icon: "location",
      path: absRoutes.locations.main,
      className: activeNavItemClassName(absRoutes.locations.main)
    }
  ];

  return (
    <div className={`Sidebar ${sidebar ? "Sidebar-collapsed" : ""}`}>
      <div className="Sidebar-header">
        <div className="Sidebar-logo">
          <LogoText />
        </div>

        <button className="Sidebar-button-collapse" onClick={() => setSidebar(prev => !prev)}>
          <Icon
            icon="arrow-left"
            className={`Sidebar-button-collapse-icon ${sidebar ? "collapsed" : ""}`}
          />
        </button>

        {sideBarList.map(item => {
          if (!isPrivilegedUser && item.label === "Jobs") return null;
          return (
            <Link to={item.path} className={`Sidebar-nav-item ${item.className}`} key={item.label}>
              <Icon icon={item.icon as tIcon} className="Sidebar-nav-item-icon" />
              <span className="Sidebar-nav-text">{item.label}</span>
            </Link>
          );
        })}

        <Favorites />
      </div>

      <div className="Sidebar-footer">
        <button className="Sidebar-nav-item" onClick={() => setHubSearchWindow(prev => !prev)}>
          <Icon icon="search" className="Sidebar-button__search" />
          <span className="Sidebar-nav-text">Search hub</span>
        </button>
        <button className="Sidebar-nav-item" onClick={() => setNotificationsWindow(prev => !prev)}>
          <Icon icon="notifications" />
          <span className="Sidebar-nav-text">Notifications</span>
        </button>
        {isAdmin && (
          <Link
            to={absRoutes.team.main}
            className={`Sidebar-nav-item ${activeNavItemClassName(absRoutes.team.main)}`}
          >
            <Icon icon="team" />
            <span className="Sidebar-nav-text">Team</span>
          </Link>
        )}
        <button onClick={() => setAccountModal(true)} className="Sidebar-nav-item">
          <Icon icon="account" />
          <span className="Sidebar-nav-text">Account</span>
        </button>
      </div>
    </div>
  );
};
