import {FC, useEffect} from "react";
import {Header} from "components/Header/Header";
import {TeamTableFilter} from "./TeamTableFilter/TeamTableFilter";
import {Table} from "components/Table/Table";
import {TableToolbar} from "components/TableToolbar/TableToolbar";
import {TeamContextProvider, useContextTeam} from "./TeamContext";
import {TeamDetailsContextProvider} from "./TeamDetailsContext";
import {Invite} from "./Invite";
import {useApiListNotification} from "hooks/useApiListNotification/useApiListNotification";
import {Pagination} from "components/Pagination/Pagination";
import {teamTableColumn} from "./TeamTable/teamTableColumn";
import {TableHeadItem} from "components/Table/TableHeadItem";
import {TeamTableRow} from "./TeamTable/TeamTableRow";
import {absRoutes} from "router";
import {useSetApiParams} from "hooks/useSetApiParams";
import {useLocation} from "react-router-dom";
import "./Team.scss";

export default function Team() {
  return (
    <TeamContextProvider>
      <TeamDetailsContextProvider>
        <TeamComponent />
      </TeamDetailsContextProvider>
    </TeamContextProvider>
  );
}

const TeamComponent: FC = () => {
  const {teamListData, isLoading, isError, error, data, getTeamList} = useContextTeam();
  const {element} = useApiListNotification({isLoading, isError, error, list: teamListData});

  const apiParams = useSetApiParams();
  const history = useLocation();
  useEffect(() => {
    if (!history?.pathname?.includes(absRoutes.team.main)) return;
    getTeamList();
  }, [apiParams?.searchParams]); // eslint-disable-line

  const renderHeaderList = () =>
    teamTableColumn.map(item => <TableHeadItem key={item.id} data={item} />);

  const renderBodyList = () => {
    if (!teamListData?.length && !isError) return element;
    return teamListData?.map(teammate => <TeamTableRow key={teammate?.id} teammate={teammate} />);
  };

  return (
    <div className="Team">
      <Header title="VERSATILE TEAM" children={<Invite />} />

      <div className="content-padding">
        <TableToolbar
          titleChildren={!!teamListData?.length ? `Showing ${teamListData?.length} Accounts` : ""}
          children={<TeamTableFilter />}
        />

        <Table
          isLoading={isLoading}
          headerRowChildren={renderHeaderList()}
          bodyChildren={renderBodyList()}
          scroll
        />

        {data && <Pagination meta={data.data.meta} />}
      </div>
    </div>
  );
};
