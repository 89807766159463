import {FC} from "react";
import {BudgetOverlayPageLayout} from "../../OverlayTemplates/BudgetOverlayPageLayout/BudgetOverlayPageLayout";
import {BudgetOverlayTitle} from "../../OverlayTemplates/BudgetOverlayTitle/BudgetOverlayTitle";
import {BudgetOverlayJobDetails} from "../../OverlayTemplates/BudgetOverlayJobDetails/BudgetOverlayJobDetails";
import {BudgetOverlayAdvanceDetails} from "../BudgetOverlayAdvanceDetails/BudgetOverlayAdvanceDetails";
import {BudgetOverlayAdvance} from "../BudgetOverlayAdvance/BudgetOverlayAdvance";
import {BudgetOverlayTermsBank} from "../../OverlayTemplates/BudgetOverlayTermsBank/BudgetOverlayTermsBank";
import {useContextAdvance} from "../context";
import {useContextJobDetails} from "../../../../context/JobDetailsContext/JobDetailsContext";
import {tBankDetails} from "api/budget/bankDetails/types";
import {tTermsConditions} from "api/budget/termsConditions/types";

interface iAdvanceInvoiceBuildProps {}

export const AdvanceInvoiceBuild: FC<iAdvanceInvoiceBuildProps> = () => {
  const {jobDetailsData} = useContextJobDetails();
  const {advanceDetails, setAdvanceDetails} = useContextAdvance();

  if (!advanceDetails || !jobDetailsData) return null;

  const onUpdateData = (data: {bank_detail: tBankDetails} | {term_condition: tTermsConditions}) => {
    setAdvanceDetails(prev => (prev ? {...prev, ...data} : null));
  };
  return (
    <div className="AdvanceInvoiceBuild">
      <BudgetOverlayPageLayout page={1} total={1}>
        <BudgetOverlayTitle>ADVANCE Invoice</BudgetOverlayTitle>
        <BudgetOverlayJobDetails
          client={jobDetailsData?.client}
          advanceDetails={advanceDetails}
          //bank
          bankDetails={advanceDetails.bank_detail}
          updateData={onUpdateData}
        >
          <BudgetOverlayAdvanceDetails />
        </BudgetOverlayJobDetails>
        <BudgetOverlayAdvance />
        {/* <BudgetOverlayTermsBank
          bankDetails={advanceDetails.bank_detail}
          termCondition={advanceDetails.term_condition}
          updateData={onUpdateData}
        /> */}
      </BudgetOverlayPageLayout>
    </div>
  );
};
