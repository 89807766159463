import {FC} from "react";
import {JobBudgetHeaderLayout} from "../JobBudgetHeaderLayout/JobBudgetHeaderLayout";
import {StatusBarLayout} from "./StatusBarLayout/StatusBarLayout";
import {InvoiceProvider, useContextInvoice} from "./context/InvoiceContext";
import {InvoiceEmptyState} from "./InvoiceEmptyState/InvoiceEmptyState";
import {Loading} from "components/shared/Loading/Loading";
import {useContextJobBudget} from "../context/JobBudgetContext";
import {InvoiceBuild} from "./InvoiceBuild/InvoiceBuild";
import {InvoicePreviewOverlay} from "./InvoicePreviewOverlay/InvoicePreviewOverlay";

import "./Invoice.scss";
import {useOffsetTop} from "hooks/useOffsetTop";

type tInvoiceProps = {
  [key: string]: any;
};

export const InvoiceComponent: FC<tInvoiceProps> = () => {
  const {estimateList} = useContextJobBudget();
  const {invoiceQuery, invoice, tackLoading} = useContextInvoice();
  const {elementRef, offsetTop} = useOffsetTop();

  const approvedEstimates = estimateList?.filter(estimate => !!estimate?.approved_at);
  const isApproved = !!invoice?.paid_date || !!invoice?.sent_date;

  const isLoading = tackLoading || invoiceQuery.isLoading;
  return (
    <>
      <JobBudgetHeaderLayout />
      <div ref={elementRef} style={{height: `calc(100vh - ${offsetTop}px)`, overflowY: "auto"}}>
        {invoice && <StatusBarLayout />}
        <div className={`Invoice ${!isLoading && !invoice ? "empty" : ""} `}>
          {isLoading && <Loading type="inBlock" />}
          {!invoice && <InvoiceEmptyState estimates={approvedEstimates} />}
          {invoice && !isApproved && <InvoiceBuild />}
          {invoice && isApproved && <InvoicePreviewOverlay />}
        </div>
      </div>
    </>
  );
};

export const Invoice = () => (
  <InvoiceProvider>
    <InvoiceComponent />
  </InvoiceProvider>
);
