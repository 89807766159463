import {FC} from "react";
import {Button} from "components/shared/Button/Button";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";

type tEstimateCellWithDropdownProps = {
  children: React.ReactNode;
  disabled: boolean;
  isData: boolean;
  list: Array<any>;
  listItemKey: string;
  callBack: (arg: any) => void;
  menuAnchor: HTMLElement | null;
  setMenuAnchor: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
};

export const EstimateCellWithDropdown: FC<tEstimateCellWithDropdownProps> = ({
  children,
  disabled,
  isData,
  list,
  callBack,
  listItemKey,
  menuAnchor,
  setMenuAnchor
}) => {
  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  return (
    <>
      <TextComponent
        as="button"
        size="10"
        onClick={showMenu}
        uppercase
        h100
        w100
        color={isData ? "black-10" : "gray-20"}
        disabled={disabled ? disabled : false}
      >
        {children}
      </TextComponent>

      <DropdownMenu anchor={menuAnchor} onClose={() => setMenuAnchor(null)} btnHover>
        {list.map(item => {
          return (
            <Button size="sm" key={item.id} onClick={() => callBack(item)}>
              {item?.[listItemKey]}
            </Button>
          );
        })}
      </DropdownMenu>
    </>
  );
};
