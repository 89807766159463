import {FC, FormEvent} from "react";
import {useSelect} from "hooks/inputHooks";
import {useHttp} from "hooks/httpServices/useHttp";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {notValidForm} from "helpers/misc";
import {TemplateModal} from "components/shared/TemplateModal/TemplateModal";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {Select} from "components/shared/FormComponents/Select/Select";
import {Loading} from "components/shared/Loading/Loading";
import {useGetEstimateTypes} from "./useGetEstimateTypes";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {estimateApi} from "api/budget/estimate";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {eventBus} from "EventBus/EventBus";
import {useContextJobBudget} from "../../context/JobBudgetContext";
import {useNavigate, useParams} from "react-router-dom";

import "./BudgetEstimateForm.scss";

type tBudgetEstimateFormProps = {
  closeModal: () => void;
};

export const BudgetEstimateForm: FC<tBudgetEstimateFormProps> = ({closeModal}) => {
  const {id} = useParams();
  const navigate = useNavigate();

  const {jobDetailsData} = useContextJobDetails();
  const {getEstimateList, estimateListLoading} = useContextJobBudget();
  const {call, isLoading} = useHttp();
  const formId = "budget-load-estimate-form";

  const formData = {
    name: useTextInput(),
    template: useSelect({isRequired: true, options: []})
  };

  const {isFetching, selectedTypeList} = useGetEstimateTypes(formData);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (notValidForm(formData) || !jobDetailsData?.id || !selectedTypeList?.value) return;

    try {
      const payload = {
        job_id: jobDetailsData.id,
        name: formData.name.value,
        type_id: selectedTypeList.value
      };

      const {
        data: {data}
      } = await call(estimateApi.createEstimate(payload));
      closeModal();
      navigate(`/jobs/${id}/budget/estimate/${data.id}`);
      await getEstimateList();
    } catch (error) {
      const {msg} = apiErrorHandler(error, formData);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error !"});
    }
  };

  return (
    <TemplateModal
      formId={formId}
      title="Load estimate type"
      secondaryButton={{text: "Cancel"}}
      primaryButton={{text: "LOAD ESTIMATE TYPE"}}
      onCancel={closeModal}
      isLoading={estimateListLoading || isLoading}
    >
      {isFetching && <Loading type="inBlock" />}

      <form id={formId} className="BudgetEstimateForm-form" onSubmit={handleSubmit}>
        <p className="label">Name your estimate</p>
        <InputField {...formData.name.inputProps} errors={formData.name.errors} />

        <p className="label">Choose an estimate type</p>
        <Select
          {...formData.template.inputProps}
          errors={formData.template.errors}
          canSelectEmpty={true}
        />

        {!!selectedTypeList && (
          <div className="BudgetEstimateForm-listWrap">
            {selectedTypeList?.categories?.map(item => {
              return (
                <div key={item.id} className="BudgetEstimateForm-listItem">
                  <TextComponent size="13" weight="400">
                    {item?.code}
                  </TextComponent>
                  <TextComponent size="13" weight="500">
                    {item?.name}
                  </TextComponent>
                </div>
              );
            })}
          </div>
        )}
      </form>
    </TemplateModal>
  );
};
