import {MouseEvent, useState} from "react";

type tModal =
  | "rename"
  | "duplicate"
  | "saveAsNew"
  | "saveAsExisting"
  | "delete"
  | "currency"
  | null;

export function useEstimateManagement() {
  const [modal, setModal] = useState<tModal>(null);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const toggleMenu = (event: MouseEvent<HTMLElement> | null) => setAnchor(event?.currentTarget || null); // prettier-ignore
  const closeMenu = () => setAnchor(null);

  const showModal = (modal: tModal) => {
    setAnchor(null);
    setModal(modal);
  };

  const onClose = () => setModal(null);

  return {anchor, toggleMenu, modal, onClose, showModal, closeMenu};
}
