import {FC, ReactNode, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {usePDF} from "hooks/usePDF";
import {Loading} from "components/shared/Loading/Loading";
import {eventBus} from "EventBus/EventBus";
import {callSheet} from "api/callSheet";
import {prepareName} from "helpers/fileNaming";

import "./CallSheetMsgWrap.scss";

type tCallSheetMsgWrapProps = {
  name: string;
  children: ReactNode;
  templateID: string;
  token: string;
  isSent?: boolean;
  filename: string[];
};

export const CallSheetMsgWrap: FC<tCallSheetMsgWrapProps> = ({
  children,
  templateID,
  name,
  token,
  isSent,
  filename
}) => {
  const [showNotification, setShowNotification] = useState(isSent);
  const {call, isLoading} = useHttp();
  const {isDownloading, onDownloadPdf} = usePDF({
    name: prepareName(filename, "_CS.pdf"),
    templateID
  });

  const onConfirm = async () => {
    try {
      const res = await call(callSheet?.confirmDelivery(token));
      setShowNotification(true);
      eventBus.dispatch("showToast", {text: res?.data?.message || "Success!"});
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  const onDownload = async () => {
    const root = document?.getElementById(templateID);

    try {
      root?.classList?.add("isDownload");
      await onDownloadPdf();
    } catch (error) {
      console.log(error);
    } finally {
      root?.classList?.remove("isDownload");
    }
  };

  if (isDownloading) return <Loading />;

  return (
    <div className="CallSheetMsgWrap">
      <header className={`CallSheetMsgWrap-header ${showNotification ? "notificationHeader" : ""}`}>
        {!showNotification && (
          <>
            <div className="title">
              <div>Hi {name},</div>
              <div>Please confirm receipt of this call sheet.</div>
            </div>

            <Button className="btn" onClick={onConfirm} loading={isLoading}>
              Click to confirm
            </Button>
          </>
        )}

        {showNotification && (
          <>
            <div className="iconWrap">
              <Icon icon="check" />
            </div>
            <div className="title">
              <div> We’ve received your confirmation.</div>
              <div>Thank you!</div>
            </div>
            <Button color="light" size="md" onClick={() => onDownload()} loading={isDownloading}>
              Download call sheet
            </Button>
          </>
        )}
      </header>

      {children}
    </div>
  );
};
