import {FC} from "react";
import {Outlet} from "react-router-dom";
import {LogoText as IconMenu} from "assets/icons/LogoText";
import "./LoginPageLayout.scss";

export const LoginPageLayout: FC = () => (
  <div className="LoginPageLayout">
    <div className="LoginPageLayout-header">
      <IconMenu />
    </div>
    <div className="LoginPageLayout-body">
      <Outlet />
    </div>
    <div className="LoginPageLayout-footer">
      <a
        className="LoginPageLayout-note"
        href="mailto:info@theversatilehub.com"
        target="_blank"
        rel="noreferrer"
      >
        Need help? Contact the administrator at info@theversatilehub.com
      </a>
    </div>
  </div>
);
