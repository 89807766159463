import {FC, useState} from "react";
import {trackApi} from "api/budget/track";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useParams} from "react-router-dom";
import {UploadFileButton} from "../../../_common/UploadFileButton";
import {useContextTrack} from "../../../_context/TrackContext";
import {eventBus} from "EventBus/EventBus";
import {useCompressImg} from "hooks/useCompressImg";

type tUploadExpensesFileProps = {
  [key: string]: any;
};

export const UploadExpensesFile: FC<tUploadExpensesFileProps> = () => {
  const {id} = useParams();
  const {setTrackData} = useContextTrack();
  const {call} = useHttp({disableTimeout: true});

  const [submitting, setSubmitting] = useState(false);
  const {onChangeHandler} = useCompressImg();

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e?.target?.files;
    if (!id || !files?.length) return;

    setSubmitting(true);
    const fd: any = new FormData();
    for (let i = 0; i < files.length; i++) {
      const compressed = await onChangeHandler(files?.[i]);
      fd.append("expense[]", compressed, files?.[i]?.name);
    }

    try {
      const res = await call(trackApi.uploadExpenseFile(+id, fd));
      res?.data?.data && setTrackData(res?.data?.data);
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <UploadFileButton
      inputProps={{
        accept: ".jpg, .png, .jpeg, .pdf",
        multiple: true
      }}
      callBack={onUpload}
      title="UPLOAD Expenses"
      isLoading={submitting}
    />
  );
};
