import React, {Dispatch, FC, ReactNode} from "react";
import {Button} from "components/shared/Button/Button";
import {tNotValidTabs} from "pages/Contacts/People/PeopleForm/PeopleForm";
import {Icon} from "components/Icon/Icon";
import "./TabFormLayout.scss";

interface iTabFormLayoutProps {
  title: string;
  children: ReactNode;
  withFooter?: boolean;
  setTab: (tab: number) => void;
  formId: string;
  tabs: string[];
  activeTab: number;
  onCloseModal?: () => void;
  isDisabledButton?: boolean;
  notValidTabs?: Partial<tNotValidTabs> | undefined;
  primaryButtonTitle?: string;
  favorite: boolean;
  setFavorite: Dispatch<React.SetStateAction<boolean>>;
}

/**
 *
 * @param tabs
 * @param setTab
 * @param activeTab
 * @param formId
 * @param withFooter
 * @param title
 * @param children
 * @param onCloseModal
 * @param isDisabledButton
 * @param notValidTabs
 * @param primaryButtonTitle
 * @param favorite
 * @param setFavorite
 */
const TabFormLayout: FC<iTabFormLayoutProps> = ({
  tabs,
  setTab,
  activeTab,
  formId = "",
  withFooter = true,
  title,
  children,
  onCloseModal,
  isDisabledButton = false,
  notValidTabs,
  primaryButtonTitle,
  favorite,
  setFavorite
}) => {
  return (
    <div className="TabFormLayout">
      <div className="TabFormLayout-header">
        <h2 className="TabFormLayout-header-title">{title}</h2>
        <Button
          onClick={() => setFavorite(prev => !prev)}
          size="sm"
          color="dark"
          className={favorite ? "" : "unselected"}
        >
          Mark as Preferred
        </Button>
      </div>
      <div className="tab">
        {tabs.map((tab, index) => {
          const isActive: boolean = index === activeTab;
          const currInx = String(index) as keyof tNotValidTabs;
          const isNotValid = notValidTabs?.[currInx] === true;
          return (
            <button
              key={tab}
              type="button"
              onClick={() => setTab(index)}
              className={`tab-item ${isActive ? "active" : ""}`}
            >
              {isNotValid && <Icon color="red" icon="info" />}
              {tab}
            </button>
          );
        })}
      </div>

      {children}

      {withFooter && (
        <div className="TabFormLayout-footer">
          <Button size="md" type="button" onClick={onCloseModal} disabled={isDisabledButton}>
            Cancel
          </Button>
          <Button size="md" color="light" type="submit" form={formId} loading={isDisabledButton}>
            {primaryButtonTitle ? primaryButtonTitle : "save"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default TabFormLayout;
