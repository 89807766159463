import {ChangeEvent, FC, useEffect, useRef, useState} from "react";
import {tCallSheetContact, tCallSHeetGroupContacts} from "api/callSheet/types";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {TimeCell} from "../CHCommon/TimeCell";
import {LocationCell} from "../CHCommon/LocationCell";
import {emailFilter, phoneFilter} from "helpers/filters";
import {fieldTypes, useChangeContacts} from "./useChangeContacts";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {ManuallyEntered} from "./ManuallyEntered";

import "./CallSheetBuildContactManuallyEntered.scss";

type tCallSheetBuildContactManuallyEnteredProps = {
  id: string;
  group: tCallSHeetGroupContacts;
  contact: tCallSheetContact;
  setContactGroup: any;
};

export const CallSheetBuildContactManuallyEntered: FC<
  tCallSheetBuildContactManuallyEnteredProps
> = ({group, contact, setContactGroup}) => {
  const {changeGroup, removeRowHandler, confirmDelete, setConfirmDelete} = useChangeContacts({
    contact,
    group,
    setContactGroup
  });

  const [values, setValues] = useState({
    name: contact?.name || "",
    email: contact?.email?.value || "",
    position: contact?.position || "",
    phone: contact?.phone?.value || ""
  });

  useEffect(() => {
    setValues(prev => ({...prev, name: contact?.name}));
  }, [contact?.name]);

  useEffect(() => {
    setValues(prev => ({...prev, email: contact?.email?.value}));
  }, [contact?.email?.value]);

  useEffect(() => {
    setValues(prev => ({...prev, position: contact?.position}));
  }, [contact?.position]);

  useEffect(() => {
    setValues(prev => ({...prev, phone: contact?.phone?.value}));
  }, [contact?.phone?.value]);

  const onChangeHandler = (value: string, name: fieldTypes) => {
    if (name === "phone") {
      value = phoneFilter(value, "dots");
    }

    if (name === "email") {
      value = emailFilter(value);
    }

    setValues(prev => ({...prev, [name]: value}));
  };

  const onBlur = (value: string, name: fieldTypes) => {
    changeGroup(name, value);
  };

  return (
    <>
      <ManuallyEntered
        name="name"
        placeholder="name"
        value={values["name"]}
        onBlur={onBlur}
        className="ManuallyEntered-name"
      />
      <ManuallyEntered
        name="position"
        placeholder="title"
        value={values["position"]}
        onBlur={onBlur}
      />
      <ManuallyEntered name="email" placeholder="email" value={values["email"]} onBlur={onBlur} />
      <div className="ManuallyEntered">
        <input
          autoComplete="off"
          name="phone"
          placeholder="000.000.000"
          value={values["phone"]}
          onChange={event => onChangeHandler(event.target.value, "phone")}
          onBlur={event => onBlur(event.target.value, "phone")}
          className="ManuallyEntered-inputRegular"
        />
      </div>
      <div className="ManuallyEntered">
        <TimeCell group={group} contact={contact} setContactGroup={setContactGroup} />
      </div>
      <div className="ManuallyEntered">
        <LocationCell group={group} contact={contact} setContactGroup={setContactGroup} />
      </div>
      <div className="ManuallyEntered">
        <Button onClick={() => setConfirmDelete(true)}>
          <Icon icon="close" size="sm" color="gray" />
        </Button>
      </div>

      <ConfirmWindow
        title="Delete ROW?"
        subTitle="This contact will be removed."
        confirmBtn={{
          text: "Remove",
          color: "red"
        }}
        cancelBtn={{
          text: "cancel"
        }}
        show={confirmDelete}
        onConfirm={removeRowHandler}
        onClose={() => setConfirmDelete(false)}
      />
    </>
  );
};
