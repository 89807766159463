import {FC, useState} from "react";
import {tExpenseLineFileData, tTrackCostLineFile} from "api/budget/track/types";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {trackApi} from "api/budget/track";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextTrack} from "pages/Jobs/JobDetails/JobBudget/Track/_context/TrackContext";
import {useContextExpenseList} from "pages/Jobs/JobDetails/JobBudget/Track/ExpenseList/context/ExpenseListContext";
import {useResponseType} from "pages/Jobs/JobDetails/JobBudget/Track/_common/useResponseType";
import {tViewerBtnProps, ViewerBtn} from "../ViewerBtn/ViewerBtn";

export type tDeleteFileProps = {
  file: tTrackCostLineFile;
  typeFile?: "expenseFile" | "additional" | tExpenseLineFileData["file_type"];
  expenseLineId?: number;
  onClose?: () => void;
  onDeleteCallback?: (file: tTrackCostLineFile | undefined) => void;
  btnProps?: Partial<tViewerBtnProps>;
};

export const DeleteFile: FC<tDeleteFileProps> = ({
  file,
  onDeleteCallback,
  typeFile,
  expenseLineId,
  onClose,
  btnProps
}) => {
  const {setTrackData} = useContextTrack();
  const {setExpenseList} = useContextExpenseList();
  const {isExpense, response_type} = useResponseType();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const {call, isLoading} = useHttp();

  const calls = {
    additional: trackApi?.deleteAdditionalFile(file?.id, response_type),
    expenseFile: trackApi?.deleteExpenseFile(file?.id, response_type),
    lineFile: trackApi?.deleteFileFromLine(expenseLineId || undefined, {
      file_type: typeFile as tExpenseLineFileData["file_type"],
      response_type,
      ...(file?.id && {id: file?.id})
    })
  };

  const onDelete = async () => {
    try {
      let res: any;

      if (typeFile === "expenseFile" || typeFile === "additional") {
        res = await call(calls[typeFile]);
        setConfirmDelete(false); //close only confirmation window
      } else {
        if (!expenseLineId) return;

        res = await call(calls["lineFile"]);
        onClose?.(); //close whole modal
      }
      if (typeFile === "expenseFile") {
        onDeleteCallback?.(res?.data?.data.expense_files?.[0]);
      }

      if (isExpense) {
        setExpenseList(res?.data?.data);
        setConfirmDelete(false);
      } else {
        setTrackData(res?.data?.data);
        setConfirmDelete(false);
      }
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };
  return (
    <>
      <ViewerBtn
        color="dark-gray"
        size="md"
        icon="delete"
        onClick={() => setConfirmDelete(true)}
        {...btnProps}
      />

      <ConfirmWindow
        title="Delete Expense Image?"
        subTitle="This image will be permanently deleted."
        show={confirmDelete}
        onConfirm={onDelete}
        onClose={() => setConfirmDelete(false)}
        cancelBtn={{text: "Cancel"}}
        confirmBtn={{text: "delete", color: "red"}}
        isLoading={isLoading}
      />
    </>
  );
};
