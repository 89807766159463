import {FC} from "react";
import {ReactSelect} from "components/shared/FormComponents/ReactSelect/ReactSelect";
import {components, ControlProps, GroupBase, Props} from "react-select";
import {Icon} from "components/Icon/Icon";
import "./SearchField.scss";

type SelectProps<
  Option,
  IsMulti extends boolean = any,
  Group extends GroupBase<Option> = GroupBase<Option>
> = Props<Option, IsMulti, Group> & {};

interface iSearchFieldProps extends SelectProps<any> {
  label?: string;
  errors?: string[];
}

export const SearchField: FC<iSearchFieldProps> = ({label, errors = [], ...props}) => {
  const isErrorClassName = !!errors?.length ? "is-error" : "";

  return (
    <div className={`SearchField ${isErrorClassName}`} id={props?.id}>
      {label && <label className="SearchField-label label">{label}</label>}
      <ReactSelect
        components={{
          Control
        }}
        {...props}
      />
      {errors?.map(error => (
        <span key={error} className="SearchField-error-message">
          {error}
        </span>
      ))}
    </div>
  );
};

const Control = ({children, ...props}: ControlProps<any, false>) => {
  return (
    <components.Control {...props}>
      <span className="SearchField-searchIcon">
        <Icon icon="search" />
      </span>
      {children}
    </components.Control>
  );
};
