import {FC} from "react";

export const Heart: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M14.9352 6.14648C13.6958 6.14648 12.5577 6.82942 11.9508 7.84106C11.3438 6.82946 10.2056 6.14648 8.96644 6.14648C7.04428 6.14648 5.47607 7.71455 5.47607 9.63685C5.47607 11.7109 6.56355 13.7848 8.63759 15.6058C10.1804 16.9463 11.6726 17.705 11.7485 17.7303L11.9761 17.8314L12.2037 17.7303C12.2795 17.7051 13.7717 16.9463 15.3146 15.6058C17.3886 13.7848 18.4761 11.7109 18.4761 9.63685C18.4256 7.71455 16.8576 6.14648 14.9353 6.14648H14.9352Z"
        fill="currentColor"
      />
    </svg>
  );
};
