import {iAddCompany, iGetListParams} from "./interfaces";
import {AxiosRequestConfig} from "axios";
import {iExportContactsParams} from "../people/interfaces";

const COMPANIES = "companies";
const COMPANY = "company";

export const companies = {
  getList: (params?: iGetListParams): AxiosRequestConfig => ({
    url: COMPANIES,
    params
  }),
  import: (data: any): AxiosRequestConfig => ({
    method: "POST",
    url: `${COMPANIES}/import`,
    data
  }),
  exportCompanies: (params: iExportContactsParams): AxiosRequestConfig => ({
    url: `${COMPANIES}/export`,
    params
  }),
  exportCompanyById: (params: iExportContactsParams, company_id: number) => ({
    url: `${COMPANY}/${company_id}/export`,
    params
  }),
  create: (data: Partial<iAddCompany>): AxiosRequestConfig => ({
    method: "POST",
    url: COMPANY,
    data
  }),
  update: (data: Partial<iAddCompany>, id: number): AxiosRequestConfig => ({
    method: "POST",
    url: `${COMPANY}/${id}`,
    data
  }),
  delete: (id: number): AxiosRequestConfig => ({
    method: "DELETE",
    url: `${COMPANY}/${id}`
  }),
  getCompanyDetails: (id: number): AxiosRequestConfig => ({
    url: `${COMPANY}/${id}`
  }),
  addToFavorite: (id: number): AxiosRequestConfig => ({
    method: "POST",
    url: `${COMPANY}/${id}/favorite/add`
  }),
  removeFromFavorite: (id: number): AxiosRequestConfig => ({
    method: "POST",
    url: `${COMPANY}/${id}/favorite/remove`
  }),
  importCompaniesContacts: (data: iAddCompany[]): AxiosRequestConfig => ({
    method: "post",
    url: "companies/import",
    data
  }),
  getCompaniesListById: (params: {ids: number[]}): AxiosRequestConfig => ({
    url: `${COMPANIES}/list`,
    params
  }),
  getCompanyContacts: (company_id: number): AxiosRequestConfig => ({
    url: `${COMPANY}/${company_id}/contacts`
  })
};
