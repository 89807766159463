import {useEffect, useLayoutEffect, useState} from "react";
import {tAdvance, tAdvanceDetails} from "api/budget/advance/types";
import {useHttp} from "hooks/httpServices/useHttp";
import {advanceApi} from "api/budget/advance";
import {useNavigate, useParams} from "react-router-dom";
import {advanceContactApi} from "api/budget/advanceContact";
import {termsConditionsApi} from "api/budget/termsConditions";
import PDFMerger from "pdf-merger-js/browser";
import {useGeneratePdfData} from "hooks/useGeneratePdfData";
import {AdvanceInvoicePreviewDocument} from "../AdvanceInvoicePreview/AdvanceInvoicePreviewDocument";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {tAdvanceContact} from "api/budget/advanceContact/types";
import {useContextJobBudget} from "../../context/JobBudgetContext";
import moment from "moment/moment";
import {prepareName} from "helpers/fileNaming";
import {sortByKey} from "helpers/misc";

export type tUseAdvanceReturn = ReturnType<typeof useAdvance>;

export function useAdvance() {
  const navigate = useNavigate();
  const {id: jobId, advanceId} = useParams();
  const {jobDetailsData} = useContextJobDetails();
  const {getEstimateList} = useContextJobBudget();

  const advanceListQuery = useHttp();
  const advanceDetailsQuery = useHttp();
  const advanceContactsQuery = useHttp();
  const termsFileQuery = useHttp();

  const [advanceList, setAdvanceList] = useState<tAdvance[]>([]);
  const [advanceDetails, setAdvanceDetails] = useState<tAdvanceDetails | null>(null);
  const [advanceContacts, setAdvanceContacts] = useState<tAdvanceContact[]>([]);

  const [previewFileData, setPreviewFileData] = useState<{blob: Blob; url: string} | null>(null);

  const {generatePdfData} = useGeneratePdfData();

  useEffect(() => {
    if (advanceId) {
      setAdvanceDetails(null);
      advanceDetailsQuery.call(advanceApi.getAdvanceInfo(advanceId)).then(({data: {data}}) => {
        setAdvanceDetails(data);
        createPdfPreviewData(data);
      });
    }
  }, [advanceId]); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    getEstimateList();
    advanceContactsQuery.call(advanceContactApi.getAdvanceContactList()).then(({data: {data}}) => setAdvanceContacts(data)); // prettier-ignore
    jobId && advanceListQuery.call(advanceApi.getAdvanceList(jobId)).then(({data: {data}}) => {
      setAdvanceList(sortByKey(data, "name"));
      !!data?.length &&!advanceId && navigate(String(data?.[0]?.id))
    }); // prettier-ignore
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function createPdfPreviewData(details: tAdvanceDetails) {
    if (!jobDetailsData || !details) return;
    try {
      const advancePdf = await generatePdfData(
        <AdvanceInvoicePreviewDocument advanceDetails={details} jobDetailsData={jobDetailsData} />
      );
      if (!advancePdf) return;
      const termPdf = await getTermsFileBlob(details);
      const mergedPdf = new PDFMerger();
      await mergedPdf.add(advancePdf.blob);
      termPdf && (await mergedPdf.add(termPdf));
      const pdf = await mergedPdf.saveAsBlob();
      const url = URL.createObjectURL(pdf);
      const pdfData = {
        blob: pdf,
        url
      };
      setPreviewFileData(pdfData);
      return pdfData;
    } catch (error) {
      console.log(error);
    }
  }

  async function getTermsFileBlob(details: tAdvanceDetails) {
    if (!details.term_condition) return;
    try {
      const {data} = await termsFileQuery.call(termsConditionsApi.downloadTermsConditions(details.term_condition.id)); // prettier-ignore
      return new Blob([data]);
    } catch (error: any) {
      console.log(error);
    }
  }

  async function onDownloadPdf() {
    if (!advanceDetails) return;
    const name = prepareName(
      [
        moment().format("YYYYMMDD"),
        jobDetailsData?.name || "",
        String(jobDetailsData?.id) || "",
        advanceDetails?.name || ""
      ],
      "_ADVANCE.pdf"
    );
    const pdfData = await createPdfPreviewData(advanceDetails);
    if (!pdfData?.url) return;
    const link = document.createElement("a");
    link.href = pdfData.url;
    link.download = name;
    link.click();
    link.remove();
  }

  return {
    advanceId,
    advanceList,
    setAdvanceList,
    advanceDetails,
    setAdvanceDetails,
    setAdvanceContacts,
    advanceContacts,
    previewFileData,
    createPdfPreviewData,
    onDownloadPdf,

    advanceListQuery,
    advanceDetailsQuery,
    advanceContactsQuery
  };
}
