import {FC, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {AddContact} from "./actions/AddContact";
import {tCrewGroupContact} from "api/jobCrewGroup/types";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {AddExistingContact} from "./actions/AddExistingContact/AddExistingContact";
import {RenameGroup} from "./actions/RenameGroup";
import {DeleteGroup} from "./actions/DeleteGroup";
import {MoveGroup} from "./actions/MoveGroup";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {crewTableTitles} from "../../crewTableTitles";
import "./CrewTableGroupHeader.scss";

type tCrewTableGroupHeaderProps = {
  setShowContacts: React.Dispatch<React.SetStateAction<boolean>>;
  showIcon: boolean;
  showContacts: boolean;
  group: tCrewGroupContact;
  index: number;
};

export const CrewTableGroupHeader: FC<tCrewTableGroupHeaderProps> = props => {
  const {group, showIcon, showContacts, setShowContacts} = props;
  const togglerRowVisibility = () => setShowContacts(prev => !prev);

  const [menuContact, setMenuContact] = useState<HTMLElement | null>(null);
  const showMenuContact = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuContact(event.currentTarget);
  };

  const [menuEdit, setMenuEdit] = useState<HTMLElement | null>(null);
  const showMenuEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuEdit(event.currentTarget);
  };

  const iconName = showContacts ? "triangle-down" : "triangle-right";

  const closeEditMenu = () => setMenuEdit(null);

  return (
    <tr>
      <td colSpan={crewTableTitles?.length}>
        <div className="CrewTableGroupHeader">
          <Button
            onClick={togglerRowVisibility}
            className={`CrewTableGroupHeader-togglerBtn ${!showIcon ? "hideIcon" : ""}`}
          >
            <Icon icon={iconName} size="xsm" />
            <TextComponent size="17" uppercase>
              {group?.name}
            </TextComponent>
          </Button>

          <div className="CrewTableGroupHeader-menu">
            <Button onClick={showMenuContact} size="sm" color="outline">
              <Icon icon="add" size="xsm" /> Contact
            </Button>

            <DropdownMenu anchor={menuContact} onClose={() => setMenuContact(null)} btnHover>
              <AddContact group={group} />
              <AddExistingContact group={group} />
            </DropdownMenu>

            <Button onClick={showMenuEdit} size="sm" color="outline">
              <Icon icon="edit" size="xsm" />
            </Button>

            <DropdownMenu anchor={menuEdit} onClose={closeEditMenu} btnHover>
              <RenameGroup group={group} closeEditMenu={closeEditMenu} />
              <DeleteGroup group={group} />
              <hr />
              <MoveGroup group={group} closeEditMenu={closeEditMenu} />
            </DropdownMenu>
          </div>
        </div>
      </td>
    </tr>
  );
};
