import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {AddGroup} from "./AddGroup";

export const MainHeader = () => {
  return (
    <>
      <TextComponent uppercase className="title">
        Crew
      </TextComponent>
      <AddGroup />
    </>
  );
};
