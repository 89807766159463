import {tHoldStatus} from "api/common/types";

export const getHoldColor = (item: tHoldStatus) => {
  switch (item?.name) {
    case "Released":
    case "Not available":
      return {color: "var(--red-0)"};
    case "Confirmed":
      return {color: "var(--green-10)"};
    case "Holding":
      return {color: "var(--tangerine-0"};
    case "Reached out":
      return {color: "var(--black-10)"};
    default:
      return {color: "var(--black-10)"};
  }
};
