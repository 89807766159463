import {FC} from "react";
import {Outlet} from "react-router-dom";
import {useContextJobDetails} from "../../context/JobDetailsContext/JobDetailsContext";
import {Loading} from "components/shared/Loading/Loading";
import {JobDetailsHeader} from "./JobDetailsHeader/JobDetailsHeader";
import {JobDetailsNav} from "./JobDetailsNav/JobDetailsNav";
import "./JobDetailsLayout.scss";

export const JobDetailsLayout: FC = () => {
  const {jobDetailsData} = useContextJobDetails();

  if (!jobDetailsData) return <Loading />;
  return (
    <div className="JobsDetailsLayout">
      <JobDetailsHeader />
      <JobDetailsNav />
      <div className="JobsDetailsLayout-body">{jobDetailsData && <Outlet />}</div>
    </div>
  );
};
