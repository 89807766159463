import React, {useState} from "react";
import {tCallSheetContact, tCallSHeetGroupContacts} from "api/callSheet/types";

type tProps = {
  group: tCallSHeetGroupContacts;
  contact: tCallSheetContact;
  setContactGroup: React.Dispatch<React.SetStateAction<tCallSHeetGroupContacts[] | undefined>>;
};

export type fieldTypes = "name" | "position" | "email" | "phone" | "time" | "location";

export const useChangeContacts = ({setContactGroup, group, contact}: tProps) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  function changeGroup(type: fieldTypes, value: string) {
    setContactGroup(prev => {
      return prev?.map(item => {
        if (item?.id !== group?.id) return item;

        const newContacts = item?.contacts?.map(cont => {
          if (cont?.id === contact?.id) {
            return {
              ...cont,
              ...(type === "name" && {
                name: value
              }),
              ...(type === "position" && {
                position: value
              }),
              ...(type === "email" && {
                email: {
                  ...cont?.email,
                  ...(value !== "undefined" && {value})
                }
              }),
              ...(type === "phone" && {
                phone: {
                  ...cont?.phone,
                  ...(value !== "undefined" && {value})
                }
              })
            };
          }

          return cont;
        });

        return {...item, contacts: newContacts};
      });
    });
  }

  const removeRowHandler = () => {
    setContactGroup(prev => {
      return prev?.map(item => {
        if (item?.id !== group?.id) return item;
        const newContacts = item.contacts?.filter(
          filerCont => String(filerCont?.id) !== String(contact?.id)
        );
        return {...item, contacts: newContacts};
      });
    });
  };

  return {
    changeGroup,
    removeRowHandler,
    confirmDelete,
    setConfirmDelete
  };
};
