import {FC, FormEvent} from "react";
import {gridApi} from "api/jobs/grid";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {TemplateModal} from "components/shared/TemplateModal/TemplateModal";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {TextComponent} from "../../common/TextComponent/TextComponent";
import {eventBus} from "EventBus/EventBus";
import {notValidForm} from "helpers/misc";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useSelect} from "hooks/inputHooks";
import {Select} from "components/shared/FormComponents/Select/Select";
import {useGetTemplateList} from "./useGetTemplateList";
import {Loading} from "components/shared/Loading/Loading";
import {useContextJobGrid} from "../JobGridContext";

type tCreateGridFromTemplateProps = {
  closeTemplateModal: () => void;
};

export const CreateGridFromTemplate: FC<tCreateGridFromTemplateProps> = ({closeTemplateModal}) => {
  const {jobDetailsData} = useContextJobDetails();
  const {getGridList, setCurrGridId} = useContextJobGrid();
  const id = jobDetailsData?.id;

  const {call, isLoading: submitting} = useHttp();
  const FORM_ID = "template-grid-form-id";

  const form = {
    name: useTextInput({value: ""}),
    template: useSelect({isRequired: true, options: []})
  };

  const {isLoading, templateList} = useGetTemplateList(form);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const templateId = form?.template?.value;

    if (notValidForm(form) || !id || !templateId) return;

    try {
      const res = await call(
        gridApi.createNewGridFromTemplate(+templateId, {job_id: id, name: form?.name?.value})
      );
      await getGridList();
      setCurrGridId(res?.data?.data?.id);
      eventBus.dispatch("showToast", {text: res?.data?.message || "Success!"});
      closeTemplateModal();
    } catch (error) {
      const {msg} = apiErrorHandler(error, form);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <ModalPopup
      show={true}
      className="LoadFromTemplate"
      onClose={closeTemplateModal}
      withoutPaddings
      size="w-528"
    >
      <form className="LoadFromTemplate-form posRelative" onSubmit={onSubmit} id={FORM_ID}>
        <TemplateModal
          formId={FORM_ID}
          title="New FROM TEMPLATE"
          primaryButton={{text: "LOAD TEMPLATE"}}
          secondaryButton={{text: "Cancel"}}
          onCancel={closeTemplateModal}
          isLoading={submitting}
        >
          {isLoading && (
            <div className="emptyBlock">
              <Loading type="inBlock" />
            </div>
          )}

          {!isLoading && !templateList?.length && (
            <TextComponent center className="mb-40">
              You have no templates yet !
            </TextComponent>
          )}

          {!isLoading && !!templateList?.length && (
            <>
              <TextComponent weight="400" size="14" center m="mb-16">
                Name your grid
              </TextComponent>
              <InputField {...form.name.inputProps} errors={form.name.errors} />
              <TextComponent weight="400" size="14" center m="mb-16">
                Choose a template
              </TextComponent>
              <Select {...form.template.inputProps} errors={form.template.errors} canSelectEmpty />
            </>
          )}
        </TemplateModal>
      </form>
    </ModalPopup>
  );
};
