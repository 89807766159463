import {FC} from "react";
import {tExpenseListItem, tTrackExpenseLine} from "api/budget/track/types";
import {Button} from "components/shared/Button/Button";
import {useContextTrack} from "../../_context/TrackContext";
import {tQBStatus} from "api/common/types";
import {useDropdownCellWrap} from "./DropdownCellWrap/useDropdownCellWrap";
import {DropdownCellWrap} from "./DropdownCellWrap/DropdownCellWrap";

type tQBStatusProps = {
  className?: string;
  trackLine: tTrackExpenseLine | tExpenseListItem;
};

const colors: Record<tQBStatus["name"], string> = {
  Expensify: `var(--blue-40)`,
  "In QB": `var(--green-10)`,
  "N/A": `var(--black-0)`,
  "Not Entered": "var(--red-0)",
  Pending: "var(--orange-0)"
};

export const QBStatus: FC<tQBStatusProps> = ({className = "", trackLine}) => {
  const {lists: {qbStatuses: {list}}} = useContextTrack(); // prettier-ignore
  const {isLoading, menuAnchor, setMenuAnchor, changeValue} = useDropdownCellWrap({
    trackLine,
    type: "qbStatus"
  });

  const color = !!trackLine?.qb_status ? colors[trackLine?.qb_status?.name] : "var(--gray-20)";
  return (
    <DropdownCellWrap
      trackLine={trackLine}
      isLoading={isLoading}
      menuAnchor={menuAnchor}
      setMenuAnchor={setMenuAnchor}
      className={`${className}`}
      value={trackLine?.qb_status?.name ?? "qb status"}
      emptyBtnCallback={() => changeValue(null)}
      style={{color}}
    >
      {list?.map(item => {
        return (
          <Button
            key={item?.id}
            size="sm"
            className="btn"
            onClick={() => changeValue(item?.id)}
            style={{color: colors[item?.name]}}
          >
            {item?.name}
          </Button>
        );
      })}
    </DropdownCellWrap>
  );
};
