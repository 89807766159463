import {FC} from "react";
import {JobBudgetHeaderLayout} from "../../JobBudgetHeaderLayout/JobBudgetHeaderLayout";
import {TrackNavigation} from "../_common/TrackNavigation";
import {StatusBarLayout} from "./StatusBarLayout/StatusBarLayout";
import {WrapOverlay} from "./WrapOverlay";
import {WrapPDFProvider} from "./context";
// import {HeaderMenu} from "./HeaderMenu";

type tWrapPDFProps = {
  [key: string]: any;
};

export const WrapPDFComponent: FC<tWrapPDFProps> = () => {
  return (
    <>
      <JobBudgetHeaderLayout>
        <StatusBarLayout />
        <TrackNavigation />
        {/* <HeaderMenu /> */}
      </JobBudgetHeaderLayout>
      {/* <StatusBarLayout /> */}
      <WrapOverlay />
    </>
  );
};

export const WrapPDF = () => (
  <WrapPDFProvider>
    <WrapPDFComponent />
  </WrapPDFProvider>
);
