import {FC, useState} from "react";
import {tCrewContact} from "api/jobCrewGroup/types";
import {Button} from "components/shared/Button/Button";
import {tHoldStatus} from "api/common/types";
import {getHoldColor} from "pages/Jobs/JobDetails/JobCrew/commonJobCrew/getHoldColor";
import {SelectCell} from "../_common/SelectCell/SelectCell";
import {useChangeCrewValue} from "../_common/useChangeCrewValue";
import "./RowStatus.scss";

type tRowStatusProps = {
  crewContact: tCrewContact;
  holdStatusList: Array<tHoldStatus>;
};

export const RowStatus: FC<tRowStatusProps> = ({crewContact, holdStatusList}) => {
  const {hold} = crewContact;
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const {changeValue, isLoading} = useChangeCrewValue();

  const changeHoldStatus = async (id: number | null) => {
    setMenuAnchor(null);
    changeValue({type: "hold", id: crewContact?.id, groupId: null, data: {hold_status_id: id}});
  };

  const placeholder = <span className="RowStatus-placeholder">Select...</span>;
  return (
    <SelectCell
      className={`RowStatus ${!!menuAnchor ? "open" : ""}`}
      isLoading={isLoading}
      menuAnchor={menuAnchor}
      setMenuAnchor={setMenuAnchor}
      btnChildren={
        <>
          {hold && <span style={getHoldColor(hold?.status)}>{hold?.status?.name}</span>}
          {!hold && placeholder}
        </>
      }
    >
      <Button size="sm" style={{color: "var(--gray-10)"}} onClick={() => changeHoldStatus(null)}>
        None
      </Button>

      {holdStatusList?.map(item => {
        return (
          <Button
            key={item?.id}
            size="sm"
            style={getHoldColor(item)}
            onClick={() => changeHoldStatus(item.id)}
            disabled={isLoading}
          >
            {item?.name}
          </Button>
        );
      })}
    </SelectCell>
  );
};
