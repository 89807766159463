import {FC, FormEvent} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {eventBus} from "EventBus/EventBus";
import {estimateApi} from "api/budget/estimate";
import {useContextJobBudget} from "../../context/JobBudgetContext";
import {useNavigate, useParams} from "react-router-dom";

interface iEstimateDuplicateProps {
  onClose: () => void;
}

export const EstimateDuplicate: FC<iEstimateDuplicateProps> = ({onClose}) => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {estimate, getEstimateList} = useContextJobBudget();
  const {call, isLoading} = useHttp();

  const name = useTextInput({value: estimate?.name || ""});

  const onConfirm = async (event: FormEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (name.value.length < 3 || !estimate?.id) {
      name.setErrors(["The estimate name must be at least 3 characters long"]);
      return;
    }

    try {
      const {
        data: {data}
      } = await call(estimateApi.duplicateEstimate(estimate?.id, {name: name.value}));
      onClose();
      navigate(`/jobs/${id}/budget/estimate/${data.id}`);
      getEstimateList();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      name.setErrors([msg]);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <ConfirmWindow
      isLoading={isLoading}
      show={true}
      onConfirm={onConfirm}
      onClose={onClose}
      title="Duplicate Estimate"
      subTitle="Enter a new name"
      confirmBtn={{
        text: "Duplicate"
      }}
      cancelBtn={{
        text: "Cancel"
      }}
    >
      <InputField {...name.inputProps} errors={name.errors} />
    </ConfirmWindow>
  );
};
