import {FC} from "react";
import {Datepicker} from "components/shared/FormComponents/Datepicker/Datepicker";
import {Loading} from "components/shared/Loading/Loading";
import "./DateCell.scss";

type tDateCellProps = {
  date?: string;
  formData: any;
  onSubmit: () => void;
  placeholder?: string;
  showTimeInput?: boolean;
  dateFormat: string;
  errors?: string[];
  isLoading?: boolean;
};

export const DateCell: FC<tDateCellProps> = props => {
  const {date, formData, onSubmit, isLoading, placeholder, showTimeInput, dateFormat} = props;
  const emptyClass = !date ? "emptyClass" : "";
  const withTime = showTimeInput ? "withTime" : "";

  return (
    <div className={`DateCell ${emptyClass} ${withTime}`}>
      {isLoading && <Loading type="inBlock" customSize={0.5} />}

      <Datepicker
        {...formData?.date.inputProps}
        dateFormat={dateFormat}
        errors={formData?.date.errors}
        onCalendarClose={onSubmit}
        placeholderText={placeholder}
        showTimeInput={showTimeInput}
      />
    </div>
  );
};
