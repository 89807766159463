import {FC, useState} from "react";
import {jobCrewGroup} from "api/jobCrewGroup";
import {Button} from "components/shared/Button/Button";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextJobCrew} from "../../../JobCrewContext";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {tCrewGroupContact} from "api/jobCrewGroup/types";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {eventBus} from "EventBus/EventBus";

type tDeleteGroupProps = {
  group: tCrewGroupContact;
};

export const DeleteGroup: FC<tDeleteGroupProps> = ({group}) => {
  const {jobCrewGroupList: {setCrewGroupList}} = useContextJobCrew(); // prettier-ignore
  const {call, isLoading} = useHttp();
  const [showModal, setModal] = useState(false);

  const deleteGroup = async () => {
    try {
      const res = await call(jobCrewGroup.deleteJobCrewContactGroup(group?.id));
      setCrewGroupList(() => res?.data?.data);
      eventBus.dispatch("showToast", {text: res?.data?.message || "Group successfully deleted!"});
      setModal(false);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <>
      <Button size="sm" onClick={() => setModal(true)}>
        Delete
      </Button>

      {showModal && (
        <ConfirmWindow
          show={true}
          title={`Delete ${group?.name} group?`}
          subTitle="This group and its contacts will be removed."
          cancelBtn={{text: "Cancel", type: "button"}}
          onClose={() => setModal(false)}
          confirmBtn={{text: "Delete", type: "submit", color: "red"}}
          onConfirm={deleteGroup}
          isLoading={isLoading}
        >
          <TextComponent color="red" weight="400" size="14" center className="mb-40">
            This can’t be unndone.
          </TextComponent>
        </ConfirmWindow>
      )}
    </>
  );
};
