import {FC} from "react";
import {SwitchInput} from "components/shared/FormComponents/SwitchInput/SwitchInput";
import {Select} from "components/shared/FormComponents/Select/Select";
import {tNotificationsSettingsPostData} from "api/notifications/types";
import {generateHours} from "helpers/dates";
import {tBooleanDigits} from "api/common/types";

interface iAccountDailyUpdate {
  dailySummaryUpdate: tBooleanDigits;
  dailySummaryTime: string;
  dailySummaryFrom: string;
  updateNotificationHandler: (data: Partial<tNotificationsSettingsPostData>) => Promise<void>;
}

export const AccountDailyUpdate: FC<iAccountDailyUpdate> = ({
  dailySummaryUpdate,
  dailySummaryTime,
  dailySummaryFrom,
  updateNotificationHandler
}) => {
  return (
    <div className="AccountNotifications-group">
      <div className="AccountNotifications-group-header">
        <h4 className="AccountNotifications-title">Daily Summary</h4>
        <SwitchInput
          checked={dailySummaryUpdate ? true : false}
          value={String(dailySummaryUpdate)}
          onChange={e =>
            updateNotificationHandler({daily_summary_updates: e.target.checked ? 1 : 0})
          }
        />
      </div>

      <div className="AccountNotifications-group-info">
        Summary of all assigned task, project and team updates
      </div>

      <div className="AccountNotifications-group-body AccountNotifications-daily">
        <span className="AccountNotifications-daily-label">Send at</span>
        <Select
          placeholder="7pm"
          value={dailySummaryTime}
          onChange={e => {
            updateNotificationHandler({
              daily_summary_time: +e.target.value
            });
          }}
          options={generateHours(24, "H")}
          targetKeys={{value: "valueHour", label: "label"}}
          className="AccountNotifications-daily-select-time"
        />
        <span className="AccountNotifications-daily-label">for notifications from</span>
        <Select
          placeholder="Today"
          value={dailySummaryFrom}
          onChange={e =>
            updateNotificationHandler({
              daily_summary_from: e.target.value
            })
          }
          options={[
            {id: "today", label: "Today"},
            {id: "yesterday", label: "Yesterday"}
          ]}
          targetKeys={{value: "id", label: "label"}}
          className="AccountNotifications-daily-select-day"
        />
      </div>
    </div>
  );
};
