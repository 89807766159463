import {FC} from "react";
import "./UploadBtn.scss";
import {Loading} from "../Loading/Loading";

type tUploadBtnProps = {
  onSelectFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  loading?: boolean;
};

export const UploadBtn: FC<tUploadBtnProps> = ({onSelectFile, loading}) => {
  return (
    <>
      <label htmlFor="inputUpload" className={`Button sm Upload-btn`}>
        {loading && <Loading type="inBlock" customSize={0.5} />}
        Upload PHOTO
      </label>
      <input
        id="inputUpload"
        type="file"
        accept="image/*"
        className="Upload-input"
        onChange={onSelectFile}
      />
    </>
  );
};
