import {FC} from "react";
import "./DetailsTypeList.scss";

interface iListItem {
  id: number;
  value: string;
}
interface iDetailsTypeList {
  list: {[key: string]: iListItem[]};
  title: string;
}

export const DetailsTypeList: FC<iDetailsTypeList> = ({list, title}) => {
  return (
    <>
      <h5 className="DetailsTypeList-title">
        {title}{" "}
        {!Object.keys(list).length && <span className="DetailsTypeList-empty-value">n/a</span>}
      </h5>
      <div className="DetailsTypeList-group-wrapper">
        {Object.entries(list).map(([key, value]) => (
          <div key={key} className="DetailsTypeList-group">
            <div className="DetailsTypeList-label">{key}</div>
            <div className="DetailsTypeList-value">
              {value.map(({value}, index, array) => (
                <span key={value} className="DetailsTypeList-value-element">
                  {value}
                  {!!array[index + 1] && ", "}
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
