import {FC} from "react";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {ContactBlock} from "../common/ContactBlock";
import {Title} from "../common/Title";

interface iClientProps {
  [key: string]: any;
}

export const Client: FC<iClientProps> = () => {
  const {jobDetailsData} = useContextJobDetails();
  if (!jobDetailsData) return null;

  const {client} = jobDetailsData;

  const address = `${client?.address?.city || ""}${
    client?.address?.state ? ", " + client?.address?.state : ""
  }`;
  return (
    <div className="Client">
      <Title text="Client" />
      <ContactBlock
        title={`${client?.name || ""}`}
        subTitle={address}
        imgUrl={client?.avatar_url || ""}
        id={client?.id}
        // @ts-ignore
        type={client?.type}
      />
    </div>
  );
};
