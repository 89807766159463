import {FC, useState} from "react";
import {tCrewContact} from "api/jobCrewGroup/types";
import {Button} from "components/shared/Button/Button";
import {tHoldStatus} from "api/common/types";
import {getHoldColor} from "../../../commonJobCrew/getHoldColor";
import "./RowStatus.scss";

type tRowStatusProps = {
  crewContact: tCrewContact;
  holdStatusList: Array<tHoldStatus>;
};

export const RowStatus: FC<tRowStatusProps> = ({crewContact, holdStatusList}) => {
  const {hold} = crewContact;
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  return (
    <div className={`RowStatus ${menuAnchor ? "open" : ""}`}>
      <Button onClick={showMenu} size="sm" hidePadding="horizontal" btnType="text">
        {hold && <span style={getHoldColor(hold?.status)}>{hold?.status?.name}</span>}
      </Button>
    </div>
  );
};
