import {createContext, FC, useContext} from "react";
import {useLocations} from "./useLocations";

type tLocationsContext = ReturnType<typeof useLocations>;
const LocationsContext = createContext<tLocationsContext | null>(null);
LocationsContext.displayName = "LocationsContext";

export const LocationsContextProvider: FC = ({children}) => {
  const value = useLocations();
  return <LocationsContext.Provider value={value}>{children}</LocationsContext.Provider>;
};

export const useContextLocations = () => {
  const context = useContext(LocationsContext);

  if (context === null) {
    throw new Error("<<< LocationsContext >>> must be used within a LocationsContextProvider");
  }

  return context as tLocationsContext;
};
