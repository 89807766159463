import {createContext, FC, useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {eventBus} from "EventBus/EventBus";
import {jobTasksApi} from "api/jobs/tasks";
import {tApiDataJobTask, tJobTasks, tTaskStatus, tTypesApiJobTask} from "api/jobs/tasks/types";
import {useSetApiParams} from "hooks/useSetApiParams";
import {useIsFirstRender} from "hooks/useIsFirstRender";

type tJobTaskApiData<T extends tTypesApiJobTask> = {
  taskId: number;
  typeApi: T;
  data: tApiDataJobTask[T];
  errorCallback?: () => void;
};

const useJobTasks = () => {
  const {jobDetailsData} = useContextJobDetails();
  const id = jobDetailsData?.id;
  const team = jobDetailsData?.team || [];

  const [tasks, setTasks] = useState<tJobTasks | null>(null);
  const {call, isLoading, isError, error} = useHttp({cancelRequest: false});

  const {data} = useHttp({
    config: jobTasksApi.getTasksStatuses(),
    onMountCall: true
  });
  const statuses = data?.data?.data as Array<tTaskStatus>;
  const apiParams = useSetApiParams();

  const getTasks = async () => {
    if (!id) return;
    const {preparedParams} = apiParams;
    const params: any = {
      ...preparedParams,
      "filter[assignee]": apiParams?.preparedParams["filter[assignee]"]?.split(",")
    };

    try {
      const res = await call(jobTasksApi.getTaskList(+id, params));
      setTasks(res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error getting tasks!"});
    }
  };

  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    !isFirstRender && getTasks();
  }, [apiParams?.searchParams]); // eslint-disable-line

  useLayoutEffect(() => {
    getTasks();
  }, []); // eslint-disable-line

  const changeTask = useHttp({cancelRequest: false});
  const cellKeyRef = useRef("");
  const generateKey = (typeApi: string, taskId: number | undefined) => `${typeApi}-${taskId}`;
  const onSubmit = async <T extends tTypesApiJobTask>(args: tJobTaskApiData<T>) => {
    const {taskId, typeApi, data, errorCallback} = args;

    cellKeyRef.current = generateKey(typeApi, taskId);
    const {preparedParams} = apiParams;
    const params: any = {
      ...preparedParams,
      "filter[assignee]": apiParams?.preparedParams["filter[assignee]"]?.split(",")
    };

    try {
      const res = await changeTask.call(jobTasksApi.changeTaskValue(taskId, typeApi, data, params));
      setTasks(res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
      errorCallback?.();
    } finally {
      cellKeyRef.current = "";
    }
  };

  return {
    tasks,
    isLoading,
    error,
    isError,
    setTasks,
    team,
    statuses,
    apiParams,
    ...apiParams,
    onSubmit,
    submitting: changeTask.isLoading,
    generateKey,
    cellKeyRef
  };
};

type tJobTasksContext = ReturnType<typeof useJobTasks>;
const JobTasksContext = createContext<tJobTasksContext | null>(null);
JobTasksContext.displayName = "JobTasksContext";
export const JobTasksContextProvider: FC = ({children}) => (
  <JobTasksContext.Provider value={useJobTasks()}>{children}</JobTasksContext.Provider>
);

export const useContextJobTasks = () => {
  const context = useContext(JobTasksContext);

  if (context === null) {
    throw new Error("<<< JobTasksContext >>> must be used within a JobTasksContextProvider");
  }

  return context as tJobTasksContext;
};
