import {FC, FormEvent} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {Select} from "components/shared/FormComponents/Select/Select";
import {TemplateModal} from "components/shared/TemplateModal/TemplateModal";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {notValidForm} from "helpers/misc";
import {useSelect} from "hooks/inputHooks";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextJobBudget} from "../context/JobBudgetContext";
import {advanceApi} from "api/budget/advance";
import {eventBus} from "EventBus/EventBus";
import {useContextAdvance} from "../Advance/context";
import {useNavigate} from "react-router-dom";

type tBudgetNewAdvanceFormProps = {
  closeModal: () => void;
};

export const BudgetNewAdvanceForm: FC<tBudgetNewAdvanceFormProps> = ({closeModal}) => {
  const navigate = useNavigate();
  const {estimateList} = useContextJobBudget();
  const {setAdvanceList} = useContextAdvance();

  const {call, isLoading} = useHttp();
  const formId = "budget-new-advance-form";

  const formData = {
    name: useTextInput({
      isRequired: true,
      validators: [lengthValidation(3)]
    }),
    estimate: useSelect({
      isRequired: true,
      options: estimateList.filter(item => item.approved_at),
      targetKeys: {label: "name", value: "id"}
    })
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (notValidForm(formData)) return;

    const payload = {name: formData.name.value, estimate_id: formData.estimate.value};

    try {
      const {
        data: {data, message}
      } = await call(advanceApi.createAdvance(payload));
      eventBus.dispatch("showToast", {text: message, type: "success"});
      setAdvanceList(prev => [...prev, data]);
      navigate(String(data.id));
      closeModal();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {text: msg, type: "error"});
    }
  };

  return (
    <TemplateModal
      formId={formId}
      title="New Advance"
      secondaryButton={{text: "Cancel"}}
      primaryButton={{text: "Add advance invoice"}}
      onCancel={closeModal}
      isLoading={isLoading}
    >
      <form id={formId} className="BudgetEmptyState-form" onSubmit={handleSubmit}>
        <p className="label">Name your advance</p>
        <InputField
          {...formData.name.inputProps}
          placeholder="Name"
          errors={formData.name.errors}
        />

        <p className="label">Choose a estimate</p>
        <Select
          {...formData.estimate.inputProps}
          errors={formData.estimate.errors}
          canSelectEmpty={true}
        />
      </form>
    </TemplateModal>
  );
};

function lengthValidation(minLength: number) {
  return {
    checkFn: (v: string) => Boolean(v?.length >= minLength),
    error: "The advance name must be at least 3 characters long"
  };
}
