import {FC} from "react";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {Button} from "components/shared/Button/Button";
import {estimateApi} from "api/budget/estimate";
import {useHttp} from "hooks/httpServices/useHttp";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {useContextJobBudget} from "../../_context/JobBudgetContext";

type tHiddenCostValueProps = {};

export const HiddenCostValue: FC<tHiddenCostValueProps> = () => {
  const {estimate, setEstimate} = useContextJobBudget();
  const {call, isLoading} = useHttp();
  const isApproved = !!estimate?.approved_at;

  const onRevealAll = async () => {
    if (!estimate?.id) return;

    try {
      const res = await call(estimateApi.revealAllCostValue(estimate.id));
      setEstimate(res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <>
      {!isApproved && !!estimate?.hidden_total_items_count && (
        <TextComponent
          weight="400"
          size="13"
          color="gray-10"
          className="EstimateTotalCard-hiddenItems"
        >
          {estimate?.hidden_total_items_count} line items hidden
          <Button className="togglerBtn" onClick={onRevealAll} disabled={isLoading}>
            reveal all
          </Button>
        </TextComponent>
      )}
    </>
  );
};
