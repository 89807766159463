import {FC, FormEvent} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {FormWrap} from "components/shared/FormWrap/FormWrap";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {notValidForm} from "helpers/misc";
import {useContextJobCallSheet} from "../../context/JobCallSheetContext";
import {callSheet} from "api/callSheet";
import {useHttp} from "hooks/httpServices/useHttp";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";

type tEditTitleFormProps = {
  closeModal: () => void;
};

export const EditTitleForm: FC<tEditTitleFormProps> = ({closeModal}) => {
  const {jobDetailsData} = useContextJobDetails();
  const {callSheetInfo, callSheetId, getCallSheetList, setCallSheetInfo} = useContextJobCallSheet();
  const {call, isLoading} = useHttp();
  const FORM_ID = "edi-call-sheet-title-form-id";

  const form = {
    title: useTextInput({
      value: callSheetInfo?.name || "",
      isRequired: true
    })
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (notValidForm(form) || !callSheetId || !jobDetailsData) return;

    try {
      const {
        data: {data, message}
      } = await call(callSheet.updateCallSheet(+callSheetId, {name: form?.title?.value}));
      setCallSheetInfo(data);
      getCallSheetList(jobDetailsData.id);
      eventBus.dispatch("showToast", {text: message || "Call sheet successfully updated!"});
      closeModal();
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <FormWrap
      idForm={FORM_ID}
      submitTitle="Save"
      headerTitle="Edit title"
      type="titleCenterAndOneBtn"
      isLoading={isLoading}
    >
      <form className="CreateNewCallSheet" id={FORM_ID} onSubmit={onSubmit}>
        <InputField {...form.title.inputProps} label="Title" errors={form.title.errors} />
      </form>
    </FormWrap>
  );
};
