import {FC, useState} from "react";
import {Document, Page, pdfjs} from "react-pdf";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./PdfViewer.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface iPdfViewerProps {
  blobUrl: string;
  scale?: number;
  id?: string;
  isLoaded?: (arg: boolean) => void;
}

export const PdfViewer: FC<iPdfViewerProps> = ({id, isLoaded, blobUrl, scale = 1}) => {
  const [pages, setPages] = useState<number[]>([]);

  const onDocumentLoadSuccess = (pdf: any) => {
    setPages([...Array(pdf.numPages).keys()].map(i => i + 1));
  };

  if (!blobUrl) return null;
  return (
    <div className="PdfViewer" id={id}>
      <Document file={blobUrl} onLoadSuccess={onDocumentLoadSuccess}>
        {pages.map((page, index, array) => {
          const isLastPage = array.length === index + 1;
          return (
            <Page
              onRenderSuccess={() => isLoaded && isLastPage && isLoaded(true)}
              scale={scale}
              key={page}
              pageNumber={page}
              className="PdfViewer-page"
            />
          );
        })}
      </Document>
    </div>
  );
};
