import {FC, FormEvent} from "react";
import {TextareaField} from "components/shared/FormComponents/TextareaField/TextareaField";
import {FormWrap} from "components/shared/FormWrap/FormWrap";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {Select} from "components/shared/FormComponents/Select/Select";
import {useSelect} from "hooks/inputHooks/useSelect";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {notValidForm} from "helpers/misc";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextTrack} from "../_context/TrackContext";
import {trackApi} from "api/budget/track";
import {useParams} from "react-router-dom";
import {tCustomExpenseLine} from "api/budget/track/types";

type tAddLineItemsProps = {
  onClose: () => void;
};

export const AddLineItems: FC<tAddLineItemsProps> = ({onClose}) => {
  const {id} = useParams();
  const {setTrackData, lists: {categoryList}} = useContextTrack(); // prettier-ignore
  const {call, isLoading} = useHttp();
  const formId = "track-edit-descriptions";

  const form = {
    category: useSelect({
      options: categoryList,
      value: "",
      isRequired: true
    }),
    name: useTextInput({
      value: "",
      validators: ["name"],
      filters: ["name"]
    }),
    description: useTextInput({value: "", isRequired: false}),
    descriptionDiscount: useTextInput({value: "", isRequired: false}),
    descriptionPrivetMemo: useTextInput({value: "", isRequired: false})
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (notValidForm(form) || !id) return;

    try {
      const data: tCustomExpenseLine = {
        category_id: +form?.category?.value,
        name: form.name.value,
        ...(!!form.description.value && {description: form.description.value}),
        ...(!!form.descriptionDiscount.value && {
          discount_description: form.descriptionDiscount.value
        }),
        ...(!!form.descriptionPrivetMemo.value && {private_memo: form.descriptionPrivetMemo.value})
      };

      const res = await call(trackApi.addCustomExpanseLine(+id, data));
      res?.data?.data && setTrackData(res?.data?.data);
      onClose();
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <ModalPopup size="w-528" show={true} onClose={onClose} withoutPaddings>
      <FormWrap
        headerTitle="Add line item"
        idForm={formId}
        type="titleCenterAndTwoBtn"
        cancelTitle="Cancel"
        onCancel={onClose}
        submitTitle="Save"
        withScroll
        isLoading={isLoading}
      >
        <form id={formId} onSubmit={handleSubmit}>
          <Select
            label="Category*"
            {...form.category.inputProps}
            canSelectEmpty
            errors={form.category.errors}
          />
          <InputField label="Name*" {...form.name.inputProps} errors={form.name.errors} />
          <TextareaField
            {...form.description.inputProps}
            errors={form.description.errors}
            label="DESCRIPTION"
          />
          <TextareaField
            {...form.descriptionDiscount.inputProps}
            errors={form.descriptionDiscount.errors}
            label="Discount DESCRIPTION"
            placeholder="This text exports in red"
          />
          <TextareaField
            {...form.descriptionPrivetMemo.inputProps}
            errors={form.descriptionPrivetMemo.errors}
            label="Private Memo"
            placeholder="This text does not export"
          />
        </form>
      </FormWrap>
    </ModalPopup>
  );
};
