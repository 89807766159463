import {ChangeEvent, FC, Fragment, useEffect, useState} from "react";
import {CallSheetBuildScheduleHeader} from "./CallSheetBuildScheduleHeader";
import {useContextJobCallSheet} from "../../context/JobCallSheetContext";
import "./CallSheetBuildSchedule.scss";

type tCallSheetBuildScheduleProps = {
  [key: string]: any;
};

export const CallSheetBuildSchedule: FC<tCallSheetBuildScheduleProps> = () => {
  const {callSheetInfo, setCallSheetInfo} = useContextJobCallSheet();
  const [schedule, setSchedule] = useState(callSheetInfo?.attributes?.schedule);

  useEffect(() => {
    setSchedule(callSheetInfo?.attributes?.schedule);
  }, [callSheetInfo?.attributes?.schedule]);

  const saveToState = () => {
    setCallSheetInfo(prev => {
      return {
        ...prev,
        attributes: {
          ...prev?.attributes,
          schedule: [...(schedule || [])]
        }
      };
    });
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>, row: number, col: number) => {
    const {value, name} = e.target;

    setSchedule(prev => {
      return prev?.map((item, rowPrevIndex) => {
        if (rowPrevIndex !== row) return item;

        return item?.map((colItem, colPrevIndex) => {
          if (colPrevIndex === col) return {...colItem, [name]: value};
          return colItem;
        });
      });
    });
  };

  return (
    <div className="CallSheetBuildSchedule" onBlur={saveToState}>
      <CallSheetBuildScheduleHeader setSchedule={setSchedule} />

      <div className="CallSheetSchedule">
        {schedule?.map((item, row) => {
          return (
            <Fragment key={row}>
              {item.map((item, col) => {
                return (
                  <Fragment key={col}>
                    {col > 0 && <div className="divider" />}
                    <input
                      autoComplete="off"
                      name="time"
                      value={item?.time}
                      onChange={e => onChangeHandler(e, row, col)}
                      className="input time"
                    />
                    <input
                      autoComplete="off"
                      name="text"
                      value={item?.text}
                      onChange={e => onChangeHandler(e, row, col)}
                      className="input text"
                    />
                  </Fragment>
                );
              })}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
