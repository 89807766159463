import {FC} from "react";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import {CurrencyRatesInputRow} from "./CurrencyRatesInputRow";
// import {notValidForm} from "helpers/misc";
import {useTextInputReturn} from "hooks/useTextInput/useTextInput";
import {tCurrencyRates} from "api/common/types";
import {CurrInputCell} from "./CurrInputCell";
import "./CurrencyRatesTable.scss";

interface iCurrencyRatesTableProps {
  data: tCurrencyRates[];
  onAddCurrency: () => void;
  currencyData: {[key: string]: useTextInputReturn};
  onDeleteCurrency: (name: string) => void;
  blockDelete?: boolean;
  setCurrency: (value: React.SetStateAction<tCurrencyRates[]>) => void;
  blockUpdateValue?: boolean;
}
export type typeField = "exchange_rate" | "hedge_percent";

export const CurrencyRatesTable: FC<iCurrencyRatesTableProps> = ({
  onAddCurrency,
  currencyData,
  data,
  onDeleteCurrency,
  blockDelete,
  setCurrency,
  blockUpdateValue
}) => {
  const currencies = data?.sort((a, b) => a.currency.localeCompare(b.currency));

  type arg = {value: string; id: number | undefined; typeField: typeField};
  const updateList = async ({value, id, typeField}: arg) => {
    if (!id) return;

    setCurrency(prev => {
      const newData = prev?.map(item => {
        if (item?.id === id) {
          return {...item, [typeField]: +value};
        }
        return item;
      });
      return newData;
    });
  };

  return (
    <>
      <table className="CurrencyRatesTable">
        <tbody>
          <tr className="CurrencyRatesTable-head">
            <th>currency</th>
            <th>code</th>
            <th>exchange rate</th>
            <th>hedge %</th>
          </tr>

          {currencies.map(({id, currency, code, exchange_rate, hedge_percent, is_default}) => {
            return (
              <tr key={code} className="CurrencyRatesTable-row">
                <td className="CurrencyNameCell">
                  {currency}
                  {!is_default && !blockDelete && (
                    <Button onClick={() => onDeleteCurrency(currency)}>
                      <Icon icon={"delete"} color="gray" />
                    </Button>
                  )}
                </td>
                <td>{code?.toUpperCase()}</td>
                {blockUpdateValue && (
                  <>
                    <td className="text-right">{exchange_rate.toFixed(4)}</td>
                    <td className="text-right">{hedge_percent.toFixed(2)}%</td>
                  </>
                )}
                {!blockUpdateValue && (
                  <>
                    <CurrInputCell
                      typeField="exchange_rate"
                      defaultValue={exchange_rate.toFixed(4)}
                      updateList={value => updateList({value, id, typeField: "exchange_rate"})}
                    />
                    <CurrInputCell
                      typeField="hedge_percent"
                      defaultValue={hedge_percent.toFixed(2)}
                      updateList={value => updateList({value, id, typeField: "hedge_percent"})}
                    />
                  </>
                )}
              </tr>
            );
          })}
          <CurrencyRatesInputRow currencyData={currencyData} />
        </tbody>
      </table>

      <Button
        onClick={onAddCurrency}
        // disabled={isValid}
        className="CurrencyRatesTable-addButton"
        color="outline"
        size="sm"
        type="button"
      >
        <Icon icon="add" size="sm" />
        <span>Add currency</span>
      </Button>
    </>
  );
};
