import {FC, useState} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {LocationForm} from "pages/Locations/LocationForm/LocationForm";
import {Button} from "components/shared/Button/Button";
import {useContextLocations} from "../context/LocationsContext";
import {ModalConfirmCancelEditing} from "components/shared/ModalConfirmCancelEditing/ModalConfirmCancelEditing";

export const AddLocation: FC = () => {
  const {getLocationsList} = useContextLocations();
  const [addModal, setAddModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const onCloseModal = (callback?: Function) => {
    callback?.();
    setAddModal(false);
  };

  const closeEditModal = () => {
    isChanged ? setConfirmModal(true) : setAddModal(false);
  };

  return (
    <>
      <Button onClick={() => setAddModal(true)} size="md" color="dark">
        Add Location
      </Button>

      <ModalPopup className="LocationModal" show={addModal} onClose={closeEditModal}>
        <LocationForm
          onCloseModal={onCloseModal}
          onEditCallback={getLocationsList}
          formDataIsChanged={setIsChanged}
          closeEditModal={closeEditModal}
        />
      </ModalPopup>

      <ModalConfirmCancelEditing
        show={confirmModal}
        onConfirm={() => {
          setAddModal(false);
          setConfirmModal(false);
        }}
        onClose={() => setConfirmModal(false)}
      />
    </>
  );
};
