import {tCallSheetLocation} from "api/callSheet/types";
import {ImgWithFallback} from "components/shared/ImgWithFallback/ImgWithFallback";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {FC} from "react";
import {SectionHeader} from "../../CallSheetBuild/CallSheetBuildCommon/SectionHeader";
import {Loading} from "components/shared/Loading/Loading";
import "./CallSheetTemplateLocation.scss";

type tCallSheetTemplateLocationProps = {
  image: string | undefined;
  locations: Array<tCallSheetLocation>;
  isLoading?: boolean;
};

export const CallSheetTemplateLocation: FC<tCallSheetTemplateLocationProps> = ({
  image,
  locations,
  isLoading
}) => {
  return (
    <div className="CallSheetTemplateLocation">
      <div>
        <SectionHeader title="Locations" />

        {locations.map(item => {
          return (
            <div key={item?.id} className="CallSheetTemplateLocation-locationItem">
              <TextComponent uppercase size="15">
                {item?.label}
              </TextComponent>
              <TextComponent weight="400" color="gray-10" size="15">
                {item?.name}
              </TextComponent>
              <TextComponent weight="400" color="gray-10" size="15">
                {item?.address?.street}
              </TextComponent>
              <TextComponent weight="400" color="gray-10" size="15">
                {item?.address?.city}
              </TextComponent>
            </div>
          );
        })}
      </div>

      <div className={`CallSheetTemplateLocation-upload ${image ? "image" : "placeholder"}`}>
        {isLoading && <Loading type="inBlock" />}
        <ImgWithFallback
          id="CallSheetTemplateLocation-image"
          src={String(image) || ""}
          className="uploadImage"
          alt="location"
        />
      </div>
    </div>
  );
};
