import {FC, MouseEvent, useState} from "react";
import {iPipeline} from "api/interfaces";
import {PipelineSelection} from "components/shared/PipelineSelection/PipelineSelection";
import {Popover} from "components/shared/Popover/Popover";
import {iJobDetailsDataReturn} from "api/jobs/types";
import {Loading} from "components/shared/Loading/Loading";
import {useHttp} from "hooks/httpServices/useHttp";
import {jobsApi} from "api/jobs/index";
import moment from "moment";
import {iJobReturn} from "api/jobs/types";

import "./TablePipelineCell.scss";

interface iTablePipelineCell {
  data: iPipeline;
  job: number | iJobDetailsDataReturn | iJobReturn | null | undefined;
  className?: string;
  onSelectedPipelineCallback?: (pipeline: iJobDetailsDataReturn) => void;
}

export const TablePipelineCell: FC<iTablePipelineCell> = ({
  onSelectedPipelineCallback,
  data,
  job,
  className = ""
}) => {
  const {call, isLoading} = useHttp();
  const [jobDetails, setJobDetails] = useState<iJobDetailsDataReturn | iJobReturn | null>(
    typeof job !== "number" && job ? job : null
  );
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const groupName = data?.group?.name?.toLowerCase();
  const pipelineName = data?.name?.toLowerCase()?.split(" ")?.join("_");

  const showMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);

    if (!jobDetails && typeof job === "number") {
      call(jobsApi.getJobDetails(job)).then(({data: {data}}) => setJobDetails(data));
    }
  };

  const preventEvents = (event: MouseEvent<HTMLTableDataCellElement>) => event.stopPropagation();

  const handleSelectedCallback = (job?: iJobDetailsDataReturn) => {
    setAnchor(null);
    if (!job) return;
    onSelectedPipelineCallback?.(job);
    setJobDetails(job);
  };

  const getPipelineDate = () => {
    if (data?.name?.toLowerCase() === "to client" && jobDetails?.invoice?.sent_date) {
      return ` ${moment(jobDetails.invoice.sent_date).format("DD/MM/YY")}`;
    }
    if (data?.name?.toLowerCase() === "Received" && jobDetails?.invoice?.paid_date) {
      return ` ${moment(jobDetails.invoice.paid_date).format("DD/MM/YY")}`;
    }
    return "";
  };

  let overdue = jobDetails?.pipeline_due_date
    ? moment().diff(moment(jobDetails.pipeline_due_date), "day")
    : moment().diff(moment(jobDetails?.end_at || new Date()), "day");

  const showOverdue = jobDetails?.pipeline?.group?.name !== "Done" && overdue > 0;

  return (
    <td className={`TablePipelineCell ${className}`} onClick={preventEvents}>
      <button className={`TablePipelineCell-btn ${groupName} ${pipelineName}`} onClick={showMenu}>
        <span>
          {data?.name} {!showOverdue && getPipelineDate()}
        </span>
        {/* {showOverdue && (
          <span className="TablePipelineCell-overdue">
            {overdue}d <br /> ovd
          </span>
        )} */}
      </button>

      <Popover
        withPadding
        anchor={anchor}
        transformOrigin={{
          horizontal: "center",
          vertical: "bottom"
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom"
        }}
        onClose={() => setAnchor(null)}
      >
        {isLoading && <Loading type="inBlock" />}

        <PipelineSelection
          jobData={jobDetails}
          onSelectedPipelineCallback={handleSelectedCallback}
        />
      </Popover>
    </td>
  );
};
