import {FC} from "react";
import {TagButton} from "../TagButton/TagButton";
import {Tooltip} from "../Tooltip/Tooltip";
import "./TagButtonList.scss";

interface iTagButtonList<T> {
  list: T[];
  onClick?: (id: number) => void;
}

export function TagButtonList<T extends {id: number; name: string; tooltip?: string}>({
  list,
  onClick
}: iTagButtonList<T>) {
  return (
    <div className="TagButtonList">
      {list.map(({name, id, tooltip}) =>
        tooltip ? (
          <Tooltip key={id} message={tooltip} position="bottom">
            <RenderTag id={id} name={name} onClick={onClick} />
          </Tooltip>
        ) : (
          <RenderTag id={id} name={name} onClick={onClick} />
        )
      )}
    </div>
  );
}

type tRenderTag = {
  id: number;
  name: string;
  onClick?: (id: number) => void;
};

const RenderTag: FC<tRenderTag> = ({id, name, onClick}) => {
  return (
    <TagButton
      key={id}
      size="md"
      className="tag-link tag-link__lg tag-link-style-secondary"
      onClick={() => onClick?.(id)}
    >
      {name}
    </TagButton>
  );
};
