import {FC} from "react";

type ConditionalWrapProps = {
  if: boolean;
  wrapper: (children: React.ReactNode) => JSX.Element;
};

export const ConditionalWrap: FC<ConditionalWrapProps> = ({if: condition, wrapper, children}) => {
  return condition ? wrapper(children) : <>{children}</>;
};
