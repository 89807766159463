import {FC, FormEvent, useState} from "react";
import {people} from "api/contacts/people";
import {Button} from "components/shared/Button/Button";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {SearchField} from "components/shared/FormComponents/SearchField/SearchField";
import {useSearchField} from "hooks/useSearchField";
import {tCrewContact} from "api/jobCrewGroup/types";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import {LinkIcon} from "assets/icons/Link";
import {useChangeCrewValue} from "../_common/useChangeCrewValue";

type tAddAgentProps = {
  crewContact: tCrewContact;
  groupId: number;
};

export const AddAgent: FC<tAddAgentProps> = ({crewContact, groupId}) => {
  const [showModal, setModal] = useState(false);
  const FORM_ID = "add-agent-form-id";
  const {changeValue, isLoading} = useChangeCrewValue();

  const formData = {
    agent_id: useSearchField({
      required: true,
      searchConfig: people.getList,
      searchKey: "search",
      value: ""
    })
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const agent_id = +formData.agent_id.value?.id;
    changeValue({
      type: "linkAgent",
      id: crewContact?.id,
      groupId,
      data: {agent_id},
      showSuccessMessage: true
    });
  };

  return (
    <>
      <Tooltip message="Link agent" position="top" onClick={() => setModal(true)}>
        <Button onClick={() => setModal(true)}>
          <LinkIcon />
        </Button>
      </Tooltip>

      {showModal && (
        <ConfirmWindow
          show={true}
          title="Link agent"
          subTitle="Search for a contact to link"
          cancelBtn={{text: "Cancel", type: "button"}}
          onClose={() => setModal(false)}
          confirmBtn={{text: "Save", type: "submit", form: FORM_ID}}
          isLoading={isLoading}
        >
          <form onSubmit={onSubmit} id={FORM_ID}>
            <SearchField
              {...formData.agent_id}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              placeholder="Search contacts..."
            />
          </form>
        </ConfirmWindow>
      )}
    </>
  );
};
