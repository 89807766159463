import {ChangeEvent, FC} from "react";
import {TextareaField} from "components/shared/FormComponents/TextareaField/TextareaField";
import {iField} from "api/fields/types";

interface iCustomTextAreaProps {
  field: any;
  onChange: (value: iField) => void;
}

export const CustomTextArea: FC<iCustomTextAreaProps> = ({field, onChange}) => {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange({...field, value: event.target.value});
  };

  return <TextareaField label={field.name} value={field?.value || ""} onChange={handleChange} />;
};
