import {FC, useState} from "react";
import {tExpenseListItem, tTrackExpenseLine} from "api/budget/track/types";
import doc from "assets/images/Doc_gray.svg";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {AdditionalFiles as AdditionalFilesModal} from "../../_modals/AdditionalFiles/AdditionalFiles";

type tAdditionalFilesProps = {
  className?: string;
  data: tTrackExpenseLine | tExpenseListItem;
};

export const AdditionalFiles: FC<tAdditionalFilesProps> = ({className = "", data}) => {
  const addFiles = data?.additional_files?.length;
  const [showModal, setModal] = useState(false);

  if (!addFiles) return <div className={`${className} AdditionalFiles`} />;
  return (
    <TextComponent
      className={`${className} AdditionalFiles`}
      as={"button"}
      onClick={() => setModal(true)}
      size="11"
      weight="400"
      color="gray-20"
    >
      {!!addFiles && (
        <>
          {addFiles}&nbsp; <img src={doc} alt="attachment" />
        </>
      )}

      {showModal && <AdditionalFilesModal onClose={() => setModal(false)} trackLine={data} />}
    </TextComponent>
  );
};
