import {FC, useEffect, useState} from "react";
import {tJobTasksGroup} from "api/jobs/tasks/types";
import {TaskNameCell} from "./TasksRowCells/TaskNameCell/TaskNameCell";
import {AssigneeCell} from "./TasksRowCells/AssigneeCell/AssigneeCell";
import {StatusCell} from "./TasksRowCells/StatusCell/StatusCell";
import {DateCell} from "./TasksRowCells/DateCell";
import {ReminderCell} from "./TasksRowCells/ReminderCell";
import {FIleCell} from "./TasksRowCells/FIleCell/FIleCell";
import {NoteCell} from "./TasksRowCells/NoteCell/NoteCell";
import {DragAndDropWrap} from "components/DragAndDropWrap/DragAndDropWrap";
import {useDragTask} from "./useDragTask";
import {ActionsCell} from "./TasksRowCells/ActionsCell/ActionsCell";
import "./TableTaskRow.scss";

type tTableTaskRowProps = {
  groupData: tJobTasksGroup;
};

export const TableTaskRow: FC<tTableTaskRowProps> = ({groupData}) => {
  const [data, setData] = useState<tJobTasksGroup>(groupData);
  const {dropCallBack, setCurrItem, isLoading} = useDragTask({setData});

  useEffect(() => {
    setData(groupData);
  }, [groupData]);

  return (
    <>
      {data?.tasks?.map(task => {
        return (
          <DragAndDropWrap
            as="tr"
            key={task?.id}
            draggingItem={task}
            setCurrItem={setCurrItem}
            dropCallBack={item => dropCallBack(item, data?.tasks)}
            className={`TableTaskRow`}
            isLoading={isLoading}
          >
            <td className="TableTaskRow-cell name">
              <TaskNameCell data={task} />
            </td>
            <td className="TableTaskRow-cell assignee">
              <AssigneeCell data={task} />
            </td>
            <td className="TableTaskRow-cell status">
              <StatusCell data={task} />
            </td>
            <td className="TableTaskRow-cell date">
              <DateCell data={task} />
            </td>
            <td className="TableTaskRow-cell note">
              <NoteCell data={task} />
            </td>
            <td className="TableTaskRow-cell reminder">
              <ReminderCell data={task} />
            </td>
            <td className="TableTaskRow-cell file">
              <FIleCell data={task} />
            </td>
            <td className="TableTaskRow-cell actions">
              <ActionsCell id={task?.id} />
            </td>
          </DragAndDropWrap>
        );
      })}
    </>
  );
};
