import {FC} from "react";
import {Icon} from "components/Icon/Icon";
import {TemplatesListModal} from "./TemplatesListModal";
import {tUseJobSettingsTemplatesModalData, useJobSettingsTemplates} from "./useJobSettingsTemplates"; // prettier-ignore
import {TemplateCalendar} from "./TemplateCalendar/TemplateCalendar";
import {TextComponent} from "../../JobDetails/common/TextComponent/TextComponent";
import {TemplateRename} from "./TemplateRename";
import {TemplateLayout} from "./_common/TemplateLayout";
import JobCrew from "./TemplateCrew/JobCrew";
import {Estimate} from "./TemplateEstimate/EstimateBuild";
import JobGrids from "./TemplateGrid/JobGrids";
import JobTasks from "./TemplateTasks/JobTasks";
import "./JobSettingsTemplates.scss";

const templateList = ["calendar", "crew", "grid", "estimate", "task"] as const;
export type tJobSettingsTemplatesModal = typeof templateList[number];

export const JobSettingsTemplates: FC = () => {
  const {
    getListQuery,
    showRenameWindow,
    modalData,
    setModalData,
    list,
    removeQuery,
    modal,
    setModal,
    onRemove,
    configs,
    renameModal,
    setRenameModal,
    onRenameCallback,
    onCloseModal
  } = useJobSettingsTemplates();

  const templateEditModalRender = (props: tUseJobSettingsTemplatesModalData) => {
    const pr = {...props, onClose: onCloseModal, editName: showRenameWindow};
    const onRename = () => showRenameWindow({id: props?.id, name: props?.name});

    return {
      calendar: <TemplateCalendar {...pr} />,
      estimate: <TemplateLayout {...pr} editName={onRename} children={<Estimate />} />,
      task: <TemplateLayout {...pr} editName={onRename} children={<JobTasks />} />,
      crew: <TemplateLayout {...pr} editName={onRename} children={<JobCrew />} />,
      grid: <TemplateLayout {...pr} editName={onRename} children={<JobGrids />} />
    };
  };

  return (
    <div className="JobSettingsTemplates">
      <div className="Button menu-item menu-item-nested fullWidth md">
        <span>Templates</span> <Icon icon="triangle-right" size="xsm" color="gray" />
        <span className="NestedMenu templates">
          {templateList.map(item => {
            return (
              <TextComponent
                key={item}
                onClick={() => setModal(item)}
                className="Button fullWidth md menu-item justifyContentStart"
              >
                {item}
              </TextComponent>
            );
          })}
        </span>
      </div>

      {!!modalData && templateEditModalRender(modalData)[modalData.templateType]}

      {modal && (
        <TemplatesListModal
          onShowModal={setModalData}
          isLoading={getListQuery.isLoading}
          deleting={removeQuery.isLoading}
          data={list}
          modal={modal}
          onClose={() => setModal(null)}
          onRemove={onRemove}
          showRenameWindow={showRenameWindow}
        />
      )}

      {renameModal && modal && (
        <TemplateRename
          onClose={() => setRenameModal(null)}
          id={renameModal.id}
          name={renameModal.name}
          onEditCallback={onRenameCallback}
          config={configs[modal].onRename}
        />
      )}
    </div>
  );
};
