import {FC} from "react";
import {ChapterHeader} from "../../../OverlayTemplates/ChapterHeader/ChapterHeader";
import {TextComponent} from "../../../../common/TextComponent/TextComponent";

import "./EstimateConfirmation.scss";

interface iEstimateConfirmationProps {
  clientName: string;
}

export const EstimateConfirmation: FC<iEstimateConfirmationProps> = ({clientName = ""}) => {
  return (
    <div className="EstimateConfirmation">
      <ChapterHeader>Confirmation</ChapterHeader>
      <p className="EstimateConfirmation-text">
        I have reviewed this estimate from Versatile Studios and approve as is.
      </p>
      <p className="EstimateConfirmation-text">
        An advance on estimated costs is due no later than 5 days after estimate approval.
      </p>

      <div className="EstimateConfirmation-signBox">
        <div className="sign">
          <TextComponent className="sign-title" uppercase size="10">
            Signed:
          </TextComponent>
          <TextComponent uppercase size="10">
            For and on behalf of: {clientName}
          </TextComponent>
        </div>
        <div className="name">
          <TextComponent uppercase size="10">
            Name
          </TextComponent>
        </div>
        <div className="date">
          <TextComponent uppercase size="10">
            Date
          </TextComponent>
        </div>
      </div>
    </div>
  );
};
