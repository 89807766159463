import {FC, useState, MouseEvent} from "react";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {ImportModal} from "../ImportModal/ImportModal";
import {ExportModal} from "../ExportModal/ExportModal";
import {ManageFields} from "../ManageFields/ManageFields";
import {Button} from "components/shared/Button/Button";
import {tModalNames} from "interfaces/commonInterfaces";
import {people as peopleApi} from "api/contacts/people";
import {companies as companiesApi} from "api/contacts/companies";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {PeopleForm} from "pages/Contacts/People/PeopleForm/PeopleForm";
import {CompanyForm} from "pages/Contacts/Companies/CompanyForm/CompanyForm";
import {Icon} from "components/Icon/Icon";
import {ModalConfirmCancelEditing} from "components/shared/ModalConfirmCancelEditing/ModalConfirmCancelEditing";
import {useContextContacts} from "pages/Contacts/context/ContactsContext";

import "./HeaderButtons.scss";

export type tContactsType = "companies" | "people";

interface iHeaderButtonsProps {
  type: "companies" | "people";
  getCompaniesCallback?: () => void;
  getContactsCallback?: () => void;
}

export const HeaderButtons: FC<iHeaderButtonsProps> = ({
  type,
  getCompaniesCallback,
  getContactsCallback
}) => {
  const {companies, people} = useContextContacts();
  const [modalType, setModal] = useState<tModalNames>(null);
  const [menu, setMenu] = useState<HTMLElement | null>(null);
  const [isChanged, setIsChanged] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);
  const formModals = ["createPerson", "createCompany", "editForm"];

  const listLength = type === "companies" ? companies?.companies?.length : people?.people?.length;

  const closeModal = () => {
    if (isChanged && modalType && formModals.includes(modalType)) {
      setConfirmClose(true);
    } else {
      setModal(null);
    }
  };

  const personUpdateCallback = () => {
    getContactsCallback?.();
    setModal(null);
  };

  const companyUpdateCallback = () => {
    getCompaniesCallback?.();
    setModal(null);
  };

  const openImport = () => setModal("import");
  const openExport = () => setModal("export");
  const openManageField = () => setModal("manageField");
  const openCreatePerson = () => {
    setModal("createPerson");
    setMenu(null);
  };

  const openCreateCompany = () => {
    setModal("createCompany");
    setMenu(null);
  };

  const onConfirmCloseEdit = () => {
    setConfirmClose(false);
    setModal(null);
  };

  const exportRequest = {
    companies: companiesApi.exportCompanies,
    people: peopleApi.exportPeople
  };

  return (
    <>
      <Button onClick={openImport} size="md" color="light">
        Import
      </Button>
      <Button size="md" color="light" onClick={openExport}>
        Export
      </Button>
      <Button onClick={openManageField} size="md" color="light">
        Manage Fields
      </Button>

      <Button
        size="md"
        color="dark"
        onClick={(event: MouseEvent<HTMLButtonElement>) => setMenu(event.currentTarget)}
      >
        add contact
        <Icon icon="arrow-up" className={`HeaderButtons-icon ${menu === null ? "" : "active"}`} />
      </Button>

      <DropdownMenu
        anchor={menu}
        onClose={() => setMenu(null)}
        transformOrigin={{
          horizontal: "right"
        }}
        className="HeaderButtons-dropdown"
      >
        <Button onClick={openCreatePerson} size="md">
          Add Person
        </Button>
        <Button onClick={openCreateCompany} size="md">
          Add Company
        </Button>
      </DropdownMenu>

      <ImportModal // import people
        type={type}
        onClose={closeModal}
        show={modalType === "import"}
        title={`Import ${type}`}
        subtitle="Browse for a CSV or vCard file."
        onUpdateList={type === "companies" ? companyUpdateCallback : personUpdateCallback}
      />

      <ExportModal // export all people info
        type={type}
        exportRequest={exportRequest[type]}
        onClose={closeModal}
        show={modalType === "export"}
        title={`Export ${type}`}
        subtitle={`Export ${listLength} displayed ${type}`}
      />

      {modalType === "manageField" && <ManageFields show onClose={closeModal} />}

      <ModalPopup
        className="PeopleFormModal"
        show={modalType === "createPerson"}
        onClose={closeModal}
      >
        <PeopleForm
          closeModal={closeModal}
          onEditCallback={personUpdateCallback}
          setIsChangedFormData={setIsChanged}
        />
      </ModalPopup>

      <ModalPopup
        className="CompanyForm-modal"
        show={modalType === "createCompany"}
        onClose={closeModal}
      >
        <CompanyForm
          closeModal={closeModal}
          onEditCallback={companyUpdateCallback}
          setIsChangedFormData={setIsChanged}
        />
      </ModalPopup>

      <ModalConfirmCancelEditing
        show={confirmClose}
        onConfirm={onConfirmCloseEdit}
        onClose={() => setConfirmClose(false)}
      />
    </>
  );
};
