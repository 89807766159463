import axios from "axios";
import {tTrackCostLineFile} from "api/budget/track/types";
import {eventBus} from "EventBus/EventBus";

export const downloadFileFromUrl = async (file: {url: string | undefined; name?: string}) => {
  if (!file?.url) {
    eventBus.dispatch("showToast", {type: "error", text: "Failed to download file"});
    return false;
  }

  try {
    const response = await axios({
      url: file?.url,
      method: "GET",
      responseType: "blob" // important
    });

    const url_1 = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url_1;
    link.setAttribute("download", `${file?.name ? file?.name : file?.url}`);
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
    return true;
  } catch (error) {
    eventBus.dispatch("showToast", {type: "error", text: "Failed to download file"});
    return false;
  }
};

export async function createFileFromUrlData(url: string | undefined, filename = "") {
  if (!url) return console.log("No url");

  try {
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {type: "image/jpeg"};
    return new File([data], filename, metadata);
  } catch (error: any) {
    console.log(error?.message);
  }
}

export const saveDataFromFile = async (file: any, filename: string) => {
  if (!file) return console.log("No file");

  const blob = new Blob([file]);
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);

  link.download = filename;
  link.click();
  link.remove();
};

export const openOnBrowserViewer = async (
  url: string | undefined,
  filename: string = "fileName"
) => {
  if (!url) return console.log("No url");

  const link = document.createElement("a");
  link.href = url;
  // link.download = filename || "";
  link.target = "_blank";
  link.click();
  link.remove();
};

export const formFileObj = (selectedFile: tTrackCostLineFile) => {
  return {
    name: selectedFile?.file_name,
    url: selectedFile?.file_url,
    id: selectedFile?.id
  };
};
