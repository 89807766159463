import React, {DetailedHTMLProps, FC} from "react";
import "./Button.scss";

export interface iButtonProps
  extends DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  color?: "dark" | "light" | "red" | "outline" | "lightHoverLight";
  size?: "lg" | "md" | "sm";
  width?: "fullWidth";
  loading?: boolean;
  className?: string;
  hidePadding?: "horizontal";
  btnType?: "text";
  textAlign?: "left" | "center" | "right";
  justifyContent?: "justifyContentStart" | "justifyContentEnd";
}

export const Button: FC<iButtonProps> = ({
  color = "",
  size = "",
  className = "",
  width = "",
  loading = false,
  hidePadding = "",
  children,
  btnType = "",
  textAlign = "",
  justifyContent = " ",
  ...props
}) => {
  const loadingClassName = loading ? "loading" : "";
  return (
    <button
      className={`Button ${size} ${color} ${loadingClassName} ${width} ${className} ${hidePadding} ${btnType} ${textAlign} ${justifyContent}`}
      {...props}
    >
      {children}
    </button>
  );
};
