import {iField} from "api/fields/types";
import moment from "moment";

type tInfoItem = {value: string; label: string};
type tValue = {value: string; name: string};
type tField = iField & {value: string | tValue | tValue[]};

export function parseCustomFieldData(fields: tField[]) {
  if (!fields || (Array.isArray(fields) && !fields.length)) return [{label: "", value: ""}];

  return fields.reduce(
    (prevValue: tInfoItem[], {type, name, options, value}: tField): tInfoItem[] => {
      if (type === "dropdown") {
        return [
          ...prevValue,
          {value: options.find(opt => String(opt.value) === String(value))?.name, label: name}
        ];
      }

      if (Array.isArray(value)) {
        const values = value
          .reduce((previousValue: string[], currentValue) => {
            const value = options.find(option => option.value === currentValue);
            if (value) {
              return [...previousValue, value.name];
            }
            return previousValue || [];
          }, [])
          .join(", ");
        return [...prevValue, {value: values, label: name}];
      }

      if (typeof value === "string") {
        const newValue = type === "date" ? moment(value).format("MMM DD, YYYY") : value;
        return [...prevValue, {label: name, value: newValue}];
      }

      return prevValue ? prevValue : [];
    },
    []
  );
}
