import {MouseEvent, useState} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {advanceApi} from "api/budget/advance";
import {useContextAdvance} from "../context";
import {advanceStatus} from "api/budget/advance/advanceStatus";
import {getDateFormat} from "helpers/dates";

type tModal = "rename" | "delete" | null;

export function useAdvanceManagement() {
  const {advanceDetails, setAdvanceDetails} = useContextAdvance();
  const changeToSentQuery = useHttp();
  const removeStatusQuery = useHttp();

  const [modal, setModal] = useState<tModal>(null);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const toggleMenu = (event: MouseEvent<HTMLElement> | null) => setAnchor(event?.currentTarget || null); // prettier-ignore

  const onClose = () => {
    setModal(null);
  };

  const onChangeToSent = async () => {
    setAnchor(null);
    if (!advanceDetails) return;

    try {
      const {
        data: {message, data}
      } = await changeToSentQuery.call(advanceApi.changeAdvanceStatus(advanceDetails.id, {
        status: advanceStatus.sent,
        date: getDateFormat(advanceDetails?.sent_date || advanceDetails?.paid_date, "timestamp")
      })); // prettier-ignore
      setAdvanceDetails(data);
      eventBus.dispatch("showToast", {text: message});
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {text: msg, type: "error"});
    }
  };

  const onRemoveStatus = async () => {
    setAnchor(null);
    if (!advanceDetails) return;
    try {
      const {
        data: {message, data}
      } = await removeStatusQuery.call(
        advanceApi.changeAdvanceStatus(advanceDetails.id, {
          date: getDateFormat(new Date(), "timestamp"),
          status: advanceStatus.none
        })
      );
      setAdvanceDetails(data);
      eventBus.dispatch("showToast", {text: message});
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {text: msg, type: "error"});
    }
  };

  return {
    anchor,
    toggleMenu,
    modal,
    onClose,
    setModal,
    onChangeToSent,
    onRemoveStatus
  };
}
