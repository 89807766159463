import {useLocation} from "react-router-dom";

export const useIsActiveUrlFromArr = () => {
  const location = useLocation();

  const isActiveUrl = (urls: Array<string>) => {
    const currUrl = location.pathname.split("/").slice(-1)[0];
    return new RegExp(urls.join("|")).test(currUrl);
  };

  return {isActiveUrl};
};
