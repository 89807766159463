import {FC, useState, ReactNode, ChangeEvent, useEffect} from "react";
import {Icon} from "components/Icon/Icon";
import "./Accordion.scss";

interface iAccordion {
  title: string;
  isShown?: boolean;
  className?: string;
  children: ReactNode;
  handleAccordion?: (status: boolean) => void;
  callBack?: () => any;
}

export const Accordion: FC<iAccordion> = ({
  isShown = true,
  className = "",
  title,
  children,
  callBack
}) => {
  const [isOpen, setIsOpen] = useState(isShown);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsOpen(event.target.checked);
    callBack?.();
  };

  useEffect(() => {
    setIsOpen(isShown);
  }, [isShown]);

  return (
    <div className={`Accordion ${className}`}>
      <input
        id={title}
        className="Accordion-input"
        type="checkbox"
        checked={isOpen}
        onChange={handleChange}
      />
      <label htmlFor={title} className="Accordion-header">
        <h4 className="Accordion-header-title">{title}</h4>
        <Icon 
          icon="triangle-right"
          size="xsm" 
          color="black" 
          className={`Accordion-header-icon ${isOpen ? "active" : ""}`}
        />
      </label>
      <div className="Accordion-body">{children}</div>
    </div>
  );
};
