import {FC} from "react";
import {Outlet} from "react-router-dom";
import {JobBudgetHeaderLayout} from "../JobBudgetHeaderLayout/JobBudgetHeaderLayout";
import {AdvanceHeader} from "./AdvanceHeader/AdvanceHeader";
import {AdvanceEmptyState} from "./AdvanceEmptyState/AdvanceEmptyState";
import {AdvanceProvider, useContextAdvance} from "./context";
import {Loading} from "components/shared/Loading/Loading";

import "./Advance.scss";

const AdvanceComponent: FC = () => {
  const {advanceList, advanceListQuery} = useContextAdvance();

  const isLoading = advanceListQuery.isLoading;

  const hasAdvance = !!advanceList.length;

  return (
    <>
      <JobBudgetHeaderLayout>{hasAdvance && <AdvanceHeader />}</JobBudgetHeaderLayout>
      {isLoading && <Loading type="inBlock" />}
      {!isLoading && !hasAdvance && <AdvanceEmptyState />}
      <Outlet />
    </>
  );
};

export const Advance: FC = () => {
  return (
    <AdvanceProvider>
      <AdvanceComponent />
    </AdvanceProvider>
  );
};
