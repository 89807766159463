import {FC} from "react";
import {StyleSheet, Text, View} from "@react-pdf/renderer";
import {SectionTitle} from "./SectionTitle";
import {Label} from "./common";

interface iCreativeSectionProps {
  license: string;
  creative: string;
}

export const CreativeSection: FC<iCreativeSectionProps> = ({license, creative}) => {
  if (!creative && !license) return null;
  return (
    <View>
      <SectionTitle>Creative & USAGE</SectionTitle>
      {creative && (
        <>
          <Label>Creative</Label>
          <Text style={{...styles.text, ...styles.withBottomPadding}}>{creative}</Text>
        </>
      )}
      {license && (
        <>
          <Label>USAGE LICENSE</Label>
          <Text style={styles.text}>{license}</Text>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    color: "#7E7E80",
    fontFamily: "Helvetica400",
    fontSize: "10px"
  },
  withBottomPadding: {
    marginBottom: 10
  }
});
