import {FC} from "react";
import {Header} from "components/Header/Header";
import {tabs} from "../contactsNavigation";
import {HeaderButtons} from "../commonComponent/HeaderButtons/HeaderButtons";
import {PeopleTableRow} from "./PeopleTable/PeopleTableRow/PeopleTableRow";
import {TableToolbar} from "components/TableToolbar/TableToolbar";
import ContactsTableFilter from "../commonComponent/ContactsTableFilter/ContactsTableFilter";
import {Table} from "components/Table/Table";
import {Pagination} from "components/Pagination/Pagination";
import {useApiListNotification} from "hooks/useApiListNotification/useApiListNotification";
import {TableHeadItem} from "components/Table/TableHeadItem";
import {peopleTableColumn} from "./PeopleTable/peopleTableColumn";
import {useContextContacts} from "../context/ContactsContext";
import "./People.scss";
import {useContextProfile} from "context";

export const People: FC = () => {
  const {
    companies: {getCompaniesList},
    people: {people, peopleListMeta, isError, isLoading, error, apiParams, getPeopleList}
  } = useContextContacts();
  const {userData} = useContextProfile();
  const isTooltip = !userData?.tooltips?.includes("PREFERRED CONTACTS") && !!people?.length;

  const renderHeaderList = () =>
    peopleTableColumn.map(item => <TableHeadItem key={item.id} data={item} />);

  const {element} = useApiListNotification({isLoading, isError, error, list: people});

  const renderBodyList = () => {
    if (!people?.length && !isError) return element;

    return people.map((person, i) => (
      <PeopleTableRow
        key={person.id}
        person={person}
        apiParams={apiParams}
        getCompaniesCallback={getCompaniesList}
        getPeopleCallback={getPeopleList}
        isFirstItem={i === 0}
      />
    ));
  };

  return (
    <div className="People">
      <Header title="Contacts" tabs={tabs}>
        <HeaderButtons type="people" getContactsCallback={getPeopleList} />
      </Header>

      <div className="content-padding">
        <TableToolbar
          titleChildren={!!people?.length ? `Showing ${people?.length} people` : ""}
          children={<ContactsTableFilter />}
        />
        <Table
          isLoading={isLoading}
          scroll
          headerRowChildren={renderHeaderList()}
          bodyChildren={renderBodyList()}
          wrapperStyles={isTooltip ? {minHeight: "200px"} : undefined}
        />

        {peopleListMeta && <Pagination meta={peopleListMeta} />}
      </div>
    </div>
  );
};
