import {ReactNode} from "react";
import {DeleteFile} from "../DeleteFile/DeleteFile";
import {formFileObj} from "../fileHelpers";
import {FileNavigation} from "../FileNavigation/FileNavigation";
import {Viewer} from "../Viewer/Viewer";
import "./ViewersWrap.scss";

type tViewersWrapProps = {
  children: ReactNode;
  fullHeight?: boolean;
};

export const ViewersWrap = ({children, fullHeight}: tViewersWrapProps) => {
  const fh = fullHeight ? "fullHeight" : "";
  return <div className={`ViewersWrap ${fh}`}>{children}</div>;
};

type tProps = {
  children: ReactNode;
};

const Main = ({children}: tProps) => {
  return <div className="ViewersWrap-main">{children}</div>;
};

const Footer = ({children}: tProps) => {
  return <div className="ViewersWrap-footer">{children}</div>;
};

ViewersWrap.Main = Main;
ViewersWrap.Footer = Footer;
ViewersWrap.Viewer = Viewer;
ViewersWrap.FileNavigation = FileNavigation;
ViewersWrap.DeleteBtn = DeleteFile;
ViewersWrap.formatFromTrack = formFileObj;
