import {FC, useState, MouseEvent} from "react";
import {Button} from "components/shared/Button/Button";
import {useLocation} from "react-router-dom";
import {Popover} from "components/shared/Popover/Popover";
import {TaskFilterForm} from "./TaskFilterForm";

type tJobTasksFiltersProps = {
  [key: string]: any;
};

export const JobTasksFilters: FC<tJobTasksFiltersProps> = () => {
  const [showFilter, setShowFilter] = useState<HTMLButtonElement | null>(null); // prettier-ignore

  const {search} = useLocation();
  const regex = /\?|(sort_field|sort_direction)=[a-z0-9]+&?/g;
  const showNotification = search.replace(regex, "").split("&").filter(item => !!item).length; // prettier-ignore
  const onShowPopover = (event: MouseEvent<HTMLButtonElement>) =>
    setShowFilter(event.currentTarget);
  return (
    <>
      <Button color="outline" size="sm" onClick={onShowPopover}>
        {!!showNotification
          ? `${showNotification} Filter${showNotification > 1 ? "s" : ""} applied`
          : "Filters"}
      </Button>

      <Popover anchor={showFilter} onClose={() => setShowFilter(null)}>
        <TaskFilterForm />
      </Popover>
    </>
  );
};
