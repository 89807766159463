import {useLocation} from "react-router-dom";
import {HIDDEN_COLUMNS} from "../ExpenseListViewOptions/ExpenseListViewOptions";

export const useGetAppliedFilters = () => {
  const {search} = useLocation();
  const regex = /\?|(per_page|page|sort_field|sort_direction)=[a-z0-9]+&?/g;
  const appliedFiltersLength = search.replace(regex, "").split("&").filter(item => {
    if(item.includes(HIDDEN_COLUMNS)) return false;
    return !!item;
  }).length; // prettier-ignore

  const appliedFilters = !!appliedFiltersLength
    ? `${appliedFiltersLength} Filter${appliedFiltersLength > 1 ? "s" : ""} applied`
    : "Filter";

  return {appliedFilters};
};
