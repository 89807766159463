import {FC} from "react";
import {tTrackCostValue, tTrackCostValuesItem} from "api/budget/track/types";
import {useGetCostValueCells} from "./useGetCostValueCells";
import {CostValueRow as CostValueRowWrap} from "../../../_common/CostValueRow/CostValueRow";

type tCostValueRowProps = {
  trackCost: tTrackCostValue;
  costValueExpenseTotal: tTrackCostValuesItem["cost_value_expense_total"];
  estimate: string;
  isFirstItem?: boolean;
};

export const CostValueRow: FC<tCostValueRowProps> = ({
  trackCost,
  estimate,
  costValueExpenseTotal,
  isFirstItem
}) => {
  const {cells} = useGetCostValueCells({trackCost, estimate, costValueExpenseTotal, isFirstItem});

  return <CostValueRowWrap cells={cells} isFirstItem={isFirstItem} />;
};
