import {FC, useState} from "react";
import {tCrewContact} from "api/jobCrewGroup/types";
import {Button} from "components/shared/Button/Button";
import {SelectCell} from "../_common/SelectCell/SelectCell";
import {useChangeCrewValue} from "../_common/useChangeCrewValue";
import "./RowBilling.scss";
import {TextWithTooltip} from "components/TextWitchTooltip/TextWithTooltip";

type tRowBillingProps = {
  crewContact: tCrewContact;
  groupId: number;
};

export const RowBilling: FC<tRowBillingProps> = ({crewContact, groupId}) => {
  const {billing, contact} = crewContact;
  const {changeValue, isLoading} = useChangeCrewValue();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const changeBilling = async (billing: Array<number>) => {
    setMenuAnchor(null);

    changeValue({type: "billing", id: crewContact?.id, groupId, data: {billing}});
  };

  const bill = billing?.length === 1 ? billing?.[billing?.length - 1]?.name : "both";
  const showDefaultBilling = !billing?.length && !!contact?.billing_default?.length;
  const showSelectDefaultBilling = !billing?.length && !contact?.billing_default?.length;

  const showBillingComponent = (
    <>
      {contact?.billing_default?.length === 2 && "both"}
      {contact?.billing_default?.length < 2 &&
        contact?.billing_default?.[contact?.billing_default?.length - 1]?.name}
    </>
  );

  const btnChildren = (
    <>
      {showDefaultBilling && <span className="defaultValue">{showBillingComponent}</span>}
      {!!billing?.length && bill}
    </>
  );

  const options = [
    {title: "payroll", value: [2]},
    {title: "invoice", value: [1]},
    {title: "both", value: [1, 2]}
  ];

  return (
    <>
      {!showSelectDefaultBilling && (
        <SelectCell
          isLoading={isLoading}
          menuAnchor={menuAnchor}
          setMenuAnchor={setMenuAnchor}
          btnChildren={btnChildren}
          className="RowBilling"
        >
          {options?.map(item => {
            return (
              <Button key={item?.title} size="sm" onClick={() => changeBilling(item?.value)}>
                {item?.title}
              </Button>
            );
          })}
        </SelectCell>
      )}

      {showSelectDefaultBilling && (
        <TextWithTooltip
          text="no default"
          textProps={{color: "red", p: "px-12"}}
          tooltips={{message: "Open the contact record to add a billing default"}}
        />
      )}
    </>
  );
};
