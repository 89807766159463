import imageCompression, {Options} from "browser-image-compression";
//https://www.npmjs.com/package/browser-image-compression
type useCompressImgProps = {
  options?: Options;
};

export const useCompressImg = (props?: useCompressImgProps) => {
  const options: Options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 2000,
    initialQuality: 0.5,
    useWebWorker: true,
    ...props?.options
  };

  const onChangeHandler = async (file: File | undefined) => {
    if (!file) return console.log("no image");
    if (file?.type?.includes("pdf")) return file;

    // console.log("originalFile instanceof Blob", file instanceof Blob); // true
    // console.log(`originalFile size ${file?.size / 1024 / 1024} MB`);

    try {
      const compressedFile = await imageCompression(file, options);
      // console.log("compressedFile", compressedFile);

      // console.log("compressedFile instanceof Blob", compressedFile instanceof Blob); // true
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

      return compressedFile;
    } catch (error) {
      console.log("file", error);
      return error;
    }
  };

  return {onChangeHandler};
};
