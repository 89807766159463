import {FC, FormEvent} from "react";
import {TextareaField} from "components/shared/FormComponents/TextareaField/TextareaField";
import {Loading} from "components/shared/Loading/Loading";
import {CheckBoxGroupe} from "components/shared/FormComponents/CheckBoxGroupe/CheckBoxGroupe";
import {tUseCompanyFormOther} from "./useCompanyFormOther";
import {COMPANY_FORM_ID} from "../../model";
import {Button} from "components/shared/Button/Button";

interface iCompanyFormOtherProps {
  otherFormData: tUseCompanyFormOther;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  toNextTab: () => void;
}

export const CompanyFormOther: FC<iCompanyFormOtherProps> = ({
  toNextTab,
  onSubmit,
  otherFormData
}) => {
  const {formData, isLoading, departments} = otherFormData;

  if (isLoading) return <Loading className="CompaniesLoading-form" />;

  return (
    <>
      <form className="CompanyFormOther" id={COMPANY_FORM_ID} onSubmit={onSubmit}>
        <div className="CompanyForm-section">
          <div className="CompanyForm-section-checkbox">
            <CheckBoxGroupe
              label="Associated Department(s)"
              checkBoxList={departments}
              initValue={formData.departments.selectedCheckBoxes.current}
              onChange={formData.departments.checkBoxGroupeOnChange}
              errors={formData.departments.errors}
            />
          </div>

          <TextareaField
            {...formData.notes.inputProps}
            errors={formData.notes.errors}
            id="notes"
            label="Notes"
          />
        </div>

        <Button color="light" className="CompanyForm-next" onClick={toNextTab} type="button">
          CONTINUE TO CUSTOM DETAILS
        </Button>
      </form>
    </>
  );
};
