import React, {FC, useEffect, useState} from "react";
import {TagsField} from "components/shared/FormComponents/TagsField/TagsField";
import {useDebounce} from "hooks/useDebounce";
import {iStateOption} from "components/shared/FormComponents/TagsField/TagsField";

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

type tClientType = "people" | "companies";

interface iFilterSearchingClientsProps {
  id: string;
  placeholder: string;
  label: string;
  name: string;
  options: iStateOption[];
  onSearch: (value: string) => void;
  apiParams: any;
  clients: {id: number; type: string}[];
  setClients: (arg: any[]) => void;
  isLoading: boolean;
}

export const FilterSearchingClients: FC<iFilterSearchingClientsProps> = ({
  id,
  placeholder,
  label,
  name,
  options,
  onSearch,
  apiParams,
  clients,
  setClients,
  isLoading,
  ...props
}) => {
  const [search, setSearch] = useState("");
  const debounceValue = useDebounce(search, 350);

  useEffect(() => {
    if (debounceValue) {
      onSearch(debounceValue);
    }
  }, [debounceValue]); // eslint-disable-line

  const handleInputChange = (value: string) => setSearch(value);

  const handleChangeClientTags = (value: iStateOption[]) => {
    let companies: number[] = [];
    let people: number[] = [];

    value?.forEach(item => {
      if (item.type === "company") {
        companies = [...companies, Number(item.id)];
      } else {
        people = [...people, Number(item.id)];
      }
    });
    const params: PartialRecord<tClientType, number[]> = {people, companies};
    apiParams.setApiParams({params});
    setClients(value);
  };

  return (
    <>
      <label htmlFor={id} className="JobsTableFilter-label">
        {label}s
      </label>
      <TagsField
        id={id}
        name={name}
        className="JobsTableFilter-value"
        options={options}
        placeholder={placeholder}
        onChange={handleChangeClientTags}
        value={clients}
        labelKey={["name"]}
        valueKey={["id", "type"]}
        onInputChange={handleInputChange}
        isLoading={isLoading}
        {...props}
      />
    </>
  );
};
