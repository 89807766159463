import {FC, Fragment} from "react";
import {tExpenseListItem} from "api/budget/track/types";
import {expenseListTableTitles} from "./expenseListTableTitles";
import {ATTACHED_FILE_ID} from "../../_modals/ExpenseListViewOptions/listViewOptions";
import {useCellsList} from "./useCellsList";
import {tAdjustWidth} from "./_helpers/useCreateTableColWidth";

type tExpenseTableRowProps = {
  expense: tExpenseListItem;
  hiddenColumns: string[];
  adjustWidth: tAdjustWidth;
};

export const ExpenseTableRow: FC<tExpenseTableRowProps> = ({
  expense,
  hiddenColumns,
  adjustWidth
}) => {
  const {cells} = useCellsList(expense);

  const widthStyles = (index: number) => {
    return {
      width: adjustWidth?.[expenseListTableTitles?.[index].id]?.width || "auto",
      minWidth: adjustWidth?.[expenseListTableTitles?.[index].id]?.minWidth || "auto"
    };
  };

  const cellsList = expenseListTableTitles?.map(item => {
    return {
      ...item,
      component: cells[item?.id]
    };
  });

  return (
    <tr key={expense.id} className="ExpenseTableRow">
      {cellsList?.map((item, i) => {
        const width = widthStyles(i)?.width ? widthStyles(i)?.width + "px" : undefined;

        if (hiddenColumns?.find(hItems => hItems === item?.viewOptionId)) return null;

        if (item?.viewOptionId === ATTACHED_FILE_ID) {
          return <Fragment key={item?.id}>{item?.component}</Fragment>;
        }

        return (
          <td
            key={item?.id}
            className="ExpenseTableRow-cell"
            style={{maxWidth: width, minWidth: width}}
          >
            {item?.component}
          </td>
        );
      })}
    </tr>
  );
};
