import {useState, MouseEvent} from "react";
import {iJobDetailsDataReturn, iJobReturn} from "api/jobs/types";
import {iPersonDetails} from "pages/Contacts/People/interfaces";
import {useSetApiParams} from "hooks/useSetApiParams";

type tClientType = {id: number; client_type: "person" | "company"};

interface iUseJobsTableRow {
  data: iJobReturn;
  onUpdatePipelineCallback?: () => void;
}

export function useJobsTableRow({onUpdatePipelineCallback, data}: iUseJobsTableRow) {
  const apiParams = useSetApiParams();

  const [clientDetails, setClientDetails] = useState<tClientType | null>(null);
  const [locationDetails, setLocationDetails] = useState<number | null>(null);
  const [showDetails, setShowDetails] = useState(false);
  const [jobData, setJobData] = useState<iJobReturn>(data);

  const onSelectedPipelineCallback = (job: iJobDetailsDataReturn) => {
    setJobData(prev => ({
      ...prev,
      pipeline: job?.pipeline,
      ...(job?.pipeline_due_date && {
        pipeline_due_date: job?.pipeline_due_date
      }),
      ...(job?.invoice && {
        invoice: job?.invoice
      })
    }));
    onUpdatePipelineCallback?.();
  };

  const onShowDetails = (event: MouseEvent<HTMLElement, any>) => {
    event.stopPropagation();
    setShowDetails(true);
  };

  const filterByTag = (event: MouseEvent<HTMLElement, any>, id: number) => {
    event.stopPropagation();
    apiParams.setApiParams({params: {locales: [String(id)]}});
  };

  const showClientDetails = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    item: any
  ) => {
    event.stopPropagation();

    const details = {id: item.id as unknown as iPersonDetails, client_type: item.type};
    setClientDetails(details as any);
  };

  const showLocationDetails = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    item: any
  ) => {
    event.stopPropagation();
    setLocationDetails(item.id);
  };

  return {
    locationDetails,
    setLocationDetails,
    jobData,
    setJobData,
    onShowDetails,
    filterByTag,
    showClientDetails,
    showLocationDetails,
    onSelectedPipelineCallback,
    showDetails,
    setShowDetails,
    clientDetails,
    setClientDetails
  };
}
