import {FC, useEffect} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {creditCardAndPayroll} from "api/budget/creditCardAndPayroll";
import {EditCreditCard} from "./JobSettingsCreditCard/EditCreditCard";

interface iJobSettingsCreditCardProps {
  onClose: (arg: null) => void;
}

export const JobSettingsCreditCard: FC<iJobSettingsCreditCardProps> = ({onClose}) => {
  const cardsQuery = useHttp();

  useEffect(() => {
    getCards();
  }, []); // eslint-disable-line

  const getCards = () => {
    cardsQuery.call(creditCardAndPayroll.getCreditCards());
  };

  return (
    <EditCreditCard
      isLoading={cardsQuery.isLoading}
      onClose={() => onClose(null)}
      cards={cardsQuery?.data?.data?.data}
      onEditCallback={getCards}
    />
  );
};
