import {ChangeEvent, FC} from "react";
import {tNotificationsSettingsPostData} from "api/notifications/types";
import {Icon} from "components/Icon/Icon";
import {Select} from "components/shared/FormComponents/Select/Select";
import {SwitchInput} from "components/shared/FormComponents/SwitchInput/SwitchInput";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import tooltipText from "./NotificationsMessage";
import {tBooleanDigits} from "api/common/types";

interface iAccountUpdateTask {
  taskUpdates: tBooleanDigits;
  taskUpdatesPriority: string;
  updateNotificationHandler: (data: Partial<tNotificationsSettingsPostData>) => Promise<void>;
}

export const AccountUpdateTask: FC<iAccountUpdateTask> = ({
  taskUpdates,
  taskUpdatesPriority,
  updateNotificationHandler
}) => {
  const msg = taskUpdatesPriority === "priority" ? tooltipText.priority : tooltipText.notifications;

  const onChangeNotifications = (e: ChangeEvent<HTMLInputElement>) => {
    updateNotificationHandler({task_updates: e.target.checked ? 1 : 0});
  };

  return (
    <div className="AccountNotifications-group">
      <div className="AccountNotifications-group-header">
        <h4 className="AccountNotifications-title">
          Task Updates
          <Tooltip classname="AccountNotifications-tooltip" message={msg} position="right">
            <Icon
              icon="info"
              color="dark-gray"
              size="14"
              className="AccountNotifications-icon-info"
            />
          </Tooltip>
        </h4>
        <SwitchInput
          checked={!!taskUpdates}
          value={String(taskUpdates)}
          onChange={onChangeNotifications}
        />
      </div>

      <div className="AccountNotifications-group-info">
        Assignments and updates to assigned tasks
      </div>

      <div className="AccountNotifications-group-body">
        <Select
          disabled={!taskUpdates}
          className="AccountNotifications-task-select"
          placeholder="All task updates"
          value={taskUpdatesPriority}
          onChange={e => updateNotificationHandler({task_updates_priority: e.target.value})}
          options={[
            {id: "notifications", label: "All tasks updates"},
            {id: "priority", label: "High priority updates only"}
            // {id: "update", label: " Updates Only"}
          ]}
          targetKeys={{value: "id", label: "label"}}
        />
      </div>
    </div>
  );
};
