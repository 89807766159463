import {useEffect, useState, FC} from "react";
import {tUseHttpReturn, useHttp} from "hooks/httpServices/useHttp";
import {notificationsApi} from "api/notifications";
import {getApiError} from "helpers/getApiError";
import {tEmails, tNotificationsSettingsPostData} from "api/notifications/types";
import {AccountUpdateEmails} from "./AccountUpdateEmails";
import {AccountUpdateTask} from "./AccountUpdateTask";
import {AccountJobUpdate} from "./AccountJobUpdate";
import {AccountTeamUpdate} from "./AccountTeamUpdate";
import {AccountDailyUpdate} from "./AccountDailyUpdate";
import {Loading} from "components/shared/Loading/Loading";
import {eventBus} from "EventBus/EventBus";
import {tBooleanDigits} from "api/common/types";
import * as moment from "moment-timezone";
import "./AccountNotifications.scss";

interface iAccountNotifications {
  notificationSettings: tUseHttpReturn;
}

export const AccountNotifications: FC<iAccountNotifications> = ({notificationSettings}) => {
  const {call: callUpdate, isLoading: updateLoading} = useHttp();
  const isLoading = notificationSettings?.isLoading || updateLoading;

  const [emails, setEmails] = useState<Array<tEmails>>([]);
  const [taskUpdates, setTaskUpdates] = useState<tBooleanDigits>(0);
  const [taskUpdatesPriority, setTaskUpdatesPriority] = useState("");
  const [jobUpdates, setJobUpdates] = useState<tBooleanDigits>(0);
  const [teamUpdates, setTeamUpdates] = useState<tBooleanDigits>(0);
  const [dailySummaryUpdate, setDailySummaryUpdate] = useState<tBooleanDigits>(0);
  const [dailySummaryTime, setDailySummaryTime] = useState("");
  const [dailySummaryFrom, setDailySummaryFrom] = useState("");

  const getNotificationSettingsHandler = async () => {
    await notificationSettings.call(notificationsApi.getNotificationsSettings());
  };

  useEffect(() => {
    !notificationSettings?.data && getNotificationSettingsHandler();
  }, [notificationSettings?.data]); // eslint-disable-line

  useEffect(() => {
    if (notificationSettings?.data) {
      const settings = notificationSettings?.data?.data?.data as tNotificationsSettingsPostData;
      setEmails(settings?.emails);
      setTaskUpdates(settings?.task_updates ? 1 : 0);
      setTaskUpdatesPriority(settings?.task_updates_priority || "");
      setJobUpdates(settings?.job_updates ? 1 : 0);
      setTeamUpdates(settings?.team_updates ? 1 : 0);
      setDailySummaryUpdate(settings?.daily_summary_updates ? 1 : 0);
      setDailySummaryTime(String(settings?.daily_summary_time) || "");
      setDailySummaryFrom(settings?.daily_summary_from || "");
    }
  }, [notificationSettings?.data]);

  const updateNotificationHandler = async (data: Partial<tNotificationsSettingsPostData>) => {
    try {
      const daily_summary_timezone = moment.tz.guess();
      const postData = {emails, daily_summary_timezone, ...data};
      await callUpdate(notificationsApi.updateNotificationSettings(postData));
      await getNotificationSettingsHandler();
      eventBus.dispatch("showToast", {text: "Updated successfully !"});
    } catch (error) {
      const {msg} = getApiError(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <div className="AccountNotifications posRelative">
      {isLoading && <Loading type="inBlock" />}

      <AccountUpdateEmails emails={emails} updateNotificationHandler={updateNotificationHandler} />

      <AccountUpdateTask
        taskUpdates={taskUpdates}
        taskUpdatesPriority={taskUpdatesPriority}
        updateNotificationHandler={updateNotificationHandler}
      />

      <AccountJobUpdate
        jobUpdates={jobUpdates}
        updateNotificationHandler={updateNotificationHandler}
      />

      <AccountTeamUpdate
        teamUpdates={teamUpdates}
        updateNotificationHandler={updateNotificationHandler}
      />

      <AccountDailyUpdate
        dailySummaryUpdate={dailySummaryUpdate}
        dailySummaryTime={dailySummaryTime}
        dailySummaryFrom={dailySummaryFrom}
        updateNotificationHandler={updateNotificationHandler}
      />
    </div>
  );
};
