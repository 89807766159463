import {FC, FormEvent} from "react";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {useContextJobTasks} from "../_context/JobTaskContext";

type tAddTasksGroupFormProps = {onClose: () => void};

export const AddTaskGroupForm: FC<tAddTasksGroupFormProps> = ({onClose}) => {
  const {tasks, updateTasksData, updateDataCall: {isLoading}} = useContextJobTasks(); // prettier-ignore
  const FORM_ID = "add-tasks-group-form-id";

  const form = {
    name: useTextInput()
  };

  const onSubmit = (e: FormEvent) => {
    e?.preventDefault();
    const newData = tasks?.data
      ? [{name: form.name.value, tasks: []}, ...tasks.data]
      : [{name: form.name.value, tasks: []}];

    updateTasksData(newData, onClose);
  };

  return (
    <ConfirmWindow
      show={true}
      title="Create group"
      subTitle="Enter a name"
      cancelBtn={{text: "Cancel", type: "button"}}
      onClose={onClose}
      confirmBtn={{text: "save", type: "submit", form: FORM_ID}}
      isLoading={isLoading}
    >
      <form className="AddGroup" onSubmit={onSubmit} id={FORM_ID}>
        <InputField {...form.name.inputProps} errors={form.name.errors} />
      </form>
    </ConfirmWindow>
  );
};
