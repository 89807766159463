import {useEffect, useRef} from "react";
import * as pdfjs from "pdfjs-dist";

type Props = {
  page: any;
  scale: number;
};
export const useGeneratePage = ({page, scale}: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const textLayerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!page || !canvasRef.current || !textLayerRef.current) {
      return;
    }

    const viewport = page.getViewport({scale});

    // Prepare canvas using PDF page dimensions
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    // Render PDF page into canvas context
    const renderContext = {
      canvasContext: context,
      viewport
    };

    page.render(renderContext).promise.then(() => {
      // console.log("Page rendered");
    });

    page.getTextContent().then((textContent: any) => {
      pdfjs.renderTextLayer({
        textContentSource: textContent,
        // @ts-ignore
        container: textLayerRef.current,
        viewport,
        textDivs: []
      });
    });
  }, [page, scale]); // eslint-disable-line

  return {canvasRef, textLayerRef};
};

// CANVAS draw init functionality

// import {useEffect, useRef} from "react";
// import * as pdfjs from "pdfjs-dist";

// type Props = {
//   page: any;
//   scale: number;
// };

// export const useGeneratePage = ({page, scale}: Props) => {
//   const canvasRef = useRef<HTMLCanvasElement>(null);
//   const textLayerRef = useRef<HTMLDivElement>(null);

//   useEffect(() => {
//     if (!page || !canvasRef.current || !textLayerRef.current) {
//       return;
//     }

//     const viewport = page.getViewport({scale});

//     // Prepare canvas using PDF page dimensions
//     const canvas = canvasRef.current;
//     const context = canvas.getContext("2d");
//     canvas.height = viewport.height;
//     canvas.width = viewport.width;

//     // Render PDF page into canvas context
//     const renderContext = {
//       canvasContext: context,
//       viewport
//     };

//     page.render(renderContext).promise.then(() => {
//       // console.log("Page rendered");
//       setListeners();
//     });

//     page.getTextContent().then((textContent: any) => {
//       pdfjs.renderTextLayer({
//         textContentSource: textContent,
//         // @ts-ignore
//         container: textLayerRef.current,
//         viewport,
//         textDivs: []
//       });
//     });

//     const setListeners = () => {
//       let isDrawing = false;
//       let lastX = 0;
//       let lastY = 0;

//       const startDrawing = (event: MouseEvent) => {
//         isDrawing = true;
//         lastX = event.offsetX;
//         lastY = event.offsetY;
//       };

//       const stopDrawing = () => {
//         isDrawing = false;
//       };

//       const drawLine = (event: MouseEvent) => {
//         if (!isDrawing) return;
//         const context = canvas.getContext("2d");
//         console.log("yup");
//         if (!context) return;

//         context.beginPath();
//         context.moveTo(lastX, lastY);
//         context.lineTo(event.offsetX, event.offsetY);
//         context.strokeStyle = "black";
//         context.lineWidth = 1;
//         context.stroke();

//         lastX = event.offsetX;
//         lastY = event.offsetY;
//       };

//       canvas.addEventListener("mousedown", startDrawing);
//       canvas.addEventListener("mousemove", drawLine);
//       canvas.addEventListener("mouseup", stopDrawing);
//     };
//   }, [page, scale]);

//   return {canvasRef, textLayerRef};
// };
