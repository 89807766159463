import {ChangeEvent, FC} from "react";
import {Checkbox} from "components/shared/FormComponents/Checkbox/Checkbox";
import {ImgWithFallback} from "components/shared/ImgWithFallback/ImgWithFallback";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {useContextJobCrew} from "../../../JobCrewContext";
import {tCrewContact} from "api/jobCrewGroup/types";
import sortIcon from "assets/svg/sortIcon.svg";
import "./RowName.scss";

type tRowNameProps = {
  crewContact: tCrewContact;
};

export const RowName: FC<tRowNameProps> = ({crewContact}) => {
  const {contact, id} = crewContact;

  const {showSelectContactIcon, setSelectedContact} = useContextJobCrew();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {checked, value} = e.target;
    checked && setSelectedContact(prev => (prev?.length ? [...prev, id] : [id]));
    !checked && setSelectedContact(prev => prev?.filter(item => +item !== +value) || null);
  };

  return (
    <label className="RowName" htmlFor={String(id)}>
      {showSelectContactIcon && (
        <Checkbox label={String(id)} value={id} className="RowName-checkbox" onChange={onChange} />
      )}

      {!showSelectContactIcon && (
        <img src={sortIcon} alt="sort icon" className="sortIcon" draggable="false" />
      )}

      <div className={`RowName-imgWrap`}>
        <ImgWithFallback src={contact?.avatar_url || ""} fallBackType="avatar" draggable="false" />
      </div>

      <TextComponent weight="500" size="14">
        {contact?.first_name} {contact?.last_name}
      </TextComponent>
    </label>
  );
};
