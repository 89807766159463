import {FC, useState, MouseEvent} from "react";
import {AddLineItems} from "../../_modals/AddLineItems";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {useExportExpensesCsv} from "./useExportExpensesCsv";
import {useContextExpenseList} from "../context/ExpenseListContext";
import {tExpenseList} from "api/budget/track/types";
import {useContextJobDetails} from "../../../../../context/JobDetailsContext/JobDetailsContext";
import {TrackCurrencyRates} from "../../_common/TrackCurrencyRates";

type tExpenseMenuProps = {
  [key: string]: any;
};

type tModal = "currency" | "lineItem" | null;

export const ExpenseMenu: FC<tExpenseMenuProps> = () => {
  const {expenseList, getExpenseList} = useContextExpenseList();
  const {jobDetailsData} = useContextJobDetails();
  const {saveFile} = useExportExpensesCsv();
  const [modal, setModal] = useState<tModal>(null);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const toggleMenu = (event: MouseEvent<HTMLElement> | null) => setAnchor(event?.currentTarget || null); // prettier-ignore
  const closeMenu = () => setAnchor(null);

  const showModal = (modal: tModal) => {
    setAnchor(null);
    setModal(modal);
  };

  const onClose = () => setModal(null);

  const onExportFile = (list: tExpenseList | undefined) => {
    const fileName = `${jobDetailsData?.name}_${
      jobDetailsData?.id
    }_expense_list_${new Date().toDateString()}`;

    closeMenu();

    saveFile(list, fileName);
  };

  const modalRender = {
    currency: <TrackCurrencyRates onClose={onClose} updateCallback={getExpenseList} />,
    lineItem: <AddLineItems onClose={onClose} />
  };

  return (
    <div className="ExpenseMenu">
      {modal && modalRender[modal]}

      <Button color="outline" size="sm" onClick={toggleMenu}>
        <Icon icon="menu" size="xsm" />
      </Button>

      <DropdownMenu
        className="EstimateManagement-dropdown"
        anchor={anchor}
        onClose={() => toggleMenu(null)}
      >
        <Button size="sm" width="fullWidth" onClick={() => showModal("lineItem")}>
          Add line Items
        </Button>
        <Button size="sm" width="fullWidth" onClick={() => onExportFile(expenseList)}>
          Export CSV
        </Button>
        <Button size="sm" width="fullWidth" onClick={() => showModal("currency")}>
          Currency rates
        </Button>
      </DropdownMenu>
    </div>
  );
};
