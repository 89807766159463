import {FC} from "react";
import {tEstimateRowData} from "api/budget/estimate/types";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {getUsMoneyFormat} from "helpers/formattingData";
import "./EstimateRowBudget.scss";

type tEstimateRowBudgetProps = {
  currItem: tEstimateRowData;
};

export const EstimateRowBudget: FC<tEstimateRowBudgetProps> = ({currItem}) => {
  return (
    <div className="EstimateRowBudget">
      <TextComponent size="14" weight="400" color={!!currItem.budget ? "black-10" : "gray-20"}>
        {!!currItem.budget ? getUsMoneyFormat(currItem.budget) : "0.00"}
      </TextComponent>
    </div>
  );
};
