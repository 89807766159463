import {FC, useState} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {tTrackExpenseLine} from "api/budget/track/types";
import {FileLine} from "./FileLine";
import {ListLayout} from "../../_common/ListLayout";
import {UploadFileButton} from "../../_common/UploadFileButton";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {trackApi} from "api/budget/track";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextTrack} from "../../_context/TrackContext";
import {eventBus} from "EventBus/EventBus";
import {useContextExpenseList} from "../../ExpenseList/context/ExpenseListContext";
import {useResponseType} from "../../_common/useResponseType";
import {useCompressImg} from "hooks/useCompressImg";

type tAdditionalFilesProps = {
  onClose: () => void;
  trackLine: tTrackExpenseLine;
};

export const AdditionalFiles: FC<tAdditionalFilesProps> = ({onClose, trackLine}) => {
  const {setTrackData} = useContextTrack();
  const {setExpenseList} = useContextExpenseList();
  const {isExpense, response_type} = useResponseType();
  const {call, isLoading} = useHttp();
  const [submitting, setSubmitting] = useState(false);

  const {onChangeHandler} = useCompressImg();

  const uploadFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubmitting(true);

    const fd: any = new FormData();

    const file = e?.target?.files?.[0];
    const compressed = await onChangeHandler(file);
    fd.append("file", compressed, file?.name);
    fd.append("response_type", response_type);

    try {
      const res = await call(trackApi?.uploadAdditionalFile(trackLine?.id, fd));
      isExpense && setExpenseList(res?.data?.data);
      !isExpense && setTrackData(res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ModalPopup size="w-528" show={true} onClose={onClose} withoutPaddings>
      <ListLayout
        headerTitle={"Additional files"}
        buttonNode={
          <UploadFileButton
            callBack={uploadFiles}
            icon={{icon: "add", size: "xsm"}}
            isLoading={isLoading || submitting}
            inputProps={{
              accept: ".jpg, .png, .jpeg, .pdf"
            }}
          />
        }
      >
        {trackLine?.additional_files?.map(file => {
          return <FileLine key={file.id} file={file} trackLine={trackLine} />;
        })}
      </ListLayout>
    </ModalPopup>
  );
};
