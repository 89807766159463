import {FC, useEffect, useState} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {iPersonDetails} from "pages/Contacts/People/interfaces";
import {people as peopleApi} from "api/contacts/people";
import {TeamDetailsContextProvider} from "pages/Team/TeamDetailsContext";
import {PeopleModalsModel} from "./PeopleModalsModel";
import "./PeopleModals.scss";

interface iPeopleModalsProps {
  person: number | iPersonDetails;
  closePeopleModal?: () => void;
  onEditCallback?: Function;
}

export const PeopleModals: FC<iPeopleModalsProps> = ({
  onEditCallback,
  person,
  closePeopleModal
}) => {
  return (
    <TeamDetailsContextProvider>
      <PeopleModalsComponent
        person={person}
        closePeopleModal={closePeopleModal}
        onEditCallback={onEditCallback}
      />
    </TeamDetailsContextProvider>
  );
};

const PeopleModalsComponent: FC<iPeopleModalsProps> = ({
  onEditCallback,
  person,
  closePeopleModal
}) => {
  const {call} = useHttp();
  const [personDetails, setPersonDetails] = useState<iPersonDetails[]>([]);
  const [editedPerson, setEditedPerson] = useState<iPersonDetails | null>(null);
  const [editedTeammate, setEditedTeammate] = useState<iPersonDetails | null>(null);

  useEffect(() => {
    if (!person) return;
    if (typeof person === "number") {
      call(peopleApi.getPersonDetails(person)).then(({data: {data}}) => setPersonDetails([data]));
      return;
    }
    setPersonDetails([person]);
  }, [person]); // eslint-disable-line

  const showEditForm = (person: iPersonDetails) => setEditedPerson(person);

  const closeEditForm = () => setEditedPerson(null);

  const closeEditTeammateForm = () => setEditedTeammate(null);

  const showTeamModals = ({user}: {user: iPersonDetails}) => {
    setPersonDetails(prevState => [...prevState, user]);
  };

  const handleEditCallback = (response: any) => {
    if (personDetails.length > 1) {
      const newDetails = personDetails.slice(0, -1);
      setPersonDetails(newDetails);
    }
    closeEditForm();
    const newDetails = response?.data?.data;
    setPersonDetails(prev => prev.map(ct => (+ct.id === +newDetails.id ? newDetails : ct)));
    onEditCallback?.();
  };

  const closeModal = () => {
    if (personDetails.length > 1) {
      const newDetails = personDetails.slice(0, -1);
      setPersonDetails(newDetails);
      return;
    }
    closePeopleModal?.();
  };

  const lastActivePerson = personDetails[personDetails.length - 1];
  const isSecondOpenedWindow = personDetails.length > 1;

  return (
    <>
      <PeopleModalsModel
        show={true}
        closeEditModal={closeEditForm}
        closeModal={closeModal}
        personDetails={personDetails?.[0]}
        editedPerson={editedPerson}
        onEditCallback={handleEditCallback}
        showTeamModals={showTeamModals}
        showEditForm={showEditForm}
      />

      {isSecondOpenedWindow && (
        <PeopleModalsModel
          show={true}
          closeEditModal={closeEditTeammateForm}
          closeModal={closeModal}
          personDetails={lastActivePerson}
          editedPerson={editedTeammate}
          onEditCallback={handleEditCallback}
          showTeamModals={showTeamModals}
          showEditForm={teammate => setEditedTeammate(teammate)}
          className="is-second-modal"
        />
      )}
    </>
  );
};
