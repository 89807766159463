import {FC} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import "./ToggleEstimateDetailsButton.scss";

type tToggleEstimateDetailsButtonProps = {
  toggleShowDetails: () => void;
  showDetails: boolean;
};

export const ToggleEstimateDetailsButton: FC<tToggleEstimateDetailsButtonProps> = ({
  toggleShowDetails,
  showDetails
}) => {
  return (
    <Button className="ToggleEstimateDetailsButton" onClick={toggleShowDetails}>
      <Icon icon={showDetails ? "arrow-down" : "arrow-up"} color="gray" />
      <TextComponent color="gray-10" size="10" weight="500">
        {showDetails && "Hide details"}
        {!showDetails && "Show details"}
      </TextComponent>
      <Icon icon={showDetails ? "arrow-down" : "arrow-up"} color="gray" />
    </Button>
  );
};
