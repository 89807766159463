import {FC} from "react";
import {StyleSheet, View, Text, Image} from "@react-pdf/renderer";
import logo from "assets/images/logo_text.png";

interface iHeaderBreakdownProps {
  jobName: string;
  jobNumber: string;
  photographer: string;
  po: string;
  title: string;
}

export const HeaderBreakdown: FC<iHeaderBreakdownProps> = ({
  jobName,
  jobNumber,
  photographer,
  po,
  title,
  ...props
}) => {
  return (
    <View {...props}>
      <View style={styles.footer}>
        <View style={styles.left}>
          <Image src={logo} />
        </View>
        <View style={styles.right}>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.label}>Job name</Text>
          <Text style={styles.value}>{jobName}</Text>
          <Text style={styles.label}>Job Number</Text>
          <Text style={styles.value}>{jobNumber}</Text>
          <Text style={styles.label}>Photographer</Text>
          <Text style={styles.value}>{photographer}</Text>
          <Text style={styles.label}>PO</Text>
          <Text style={styles.value}>{po}</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  footer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 41
  },
  left: {
    display: "flex",
    flexDirection: "column",
    width: "55%"
  },
  right: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "37%"
  },
  title: {
    fontSize: 26,
    marginBottom: 17,
    fontFamily: "Helvetica700",
    width: "100%",
    textTransform: "uppercase"
  },
  value: {
    fontSize: 13,
    marginBottom: 4,
    fontFamily: "Helvetica400",
    maxWidth: "100px",
    width: "45%"
  },
  label: {
    fontSize: 13,
    fontFamily: "Helvetica700",
    textTransform: "uppercase",
    width: "55%",
    marginBottom: 4
  }
});
