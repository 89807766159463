import {FC, FormEvent, useRef} from "react";
import {FormWrap} from "components/shared/FormWrap/FormWrap";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {useContextJobCallSheet} from "../../context/JobCallSheetContext";
import {callSheet} from "api/callSheet";
import {useHttp} from "hooks/httpServices/useHttp";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {LocationDynamicInput} from "./LocationDynamicInput/LocationDynamicInput";
import {useLocationDynamicInput} from "./LocationDynamicInput/useLocationDynamicInput";

type tEditLocationsFormProps = {
  closeModal: () => void;
};

export const EditLocationsForm: FC<tEditLocationsFormProps> = ({closeModal}) => {
  const FORM_ID = "call-sheet-edit-locations-form-id";
  const {callSheetInfo, setCallSheetInfo} = useContextJobCallSheet();
  const {jobDetailsData} = useContextJobDetails();
  const locationList = useRef(jobDetailsData?.locations?.map(item => ({id: item?.id, name: item?.name, address: item?.address}))); // prettier-ignore

  const {
    values,
    setShowConfirmRemove,
    showConfirmRemove,
    onRemoveRow,
    onCreateNewRow,
    onChangeInputRow,
    emptyRowError,
    checkValidity
  } = useLocationDynamicInput({
    sheetLocations: callSheetInfo?.attributes?.locations || []
  });

  const {call, isLoading} = useHttp();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!checkValidity() || !callSheetInfo?.id) return;

    const payload = values?.map(item => {
      delete item.errors;
      return item;
    });

    try {
      const {
        data: {message, data}
      } = await call(
        callSheet.updateCallSheet(callSheetInfo?.id, {
          attributes: {
            ...callSheetInfo?.attributes,
            locations: payload
          }
        })
      );

      setCallSheetInfo(data);
      closeModal();
      eventBus.dispatch("showToast", {
        text: message || "Call sheet successfully loaded!"
      });
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <FormWrap
      idForm={FORM_ID}
      submitTitle="Save"
      headerTitle="Edit locations"
      type="titleCenterAndOneBtn"
      isLoading={isLoading}
    >
      <form className="CreateNewCallSheet" id={FORM_ID} onSubmit={onSubmit}>
        <LocationDynamicInput
          values={values}
          emptyRowError={emptyRowError}
          onChangeInputRow={onChangeInputRow}
          onCreateNewRow={onCreateNewRow}
          onRemoveRow={onRemoveRow}
          setShowConfirmRemove={setShowConfirmRemove}
          showConfirmRemove={showConfirmRemove}
          options={locationList?.current || []}
        />
      </form>
    </FormWrap>
  );
};
