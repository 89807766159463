import {createContext, FC, useContext} from "react";
import {useTrackWA} from "./useTrackWA";

type tTrackContext = ReturnType<typeof useTrackWA>;
const TrackContext = createContext<tTrackContext | null>(null);
TrackContext.displayName = "TrackContext";

export const TrackProvider: FC = ({children}) => {
  return <TrackContext.Provider value={useTrackWA()}>{children}</TrackContext.Provider>;
};

export const useContextTrack = () => {
  const context = useContext(TrackContext);

  if (context === null) {
    throw new Error("<<< TrackContext >>> must be used within a TrackProvider");
  }

  return context as tTrackContext;
};
