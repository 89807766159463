import {createContext, FC, useContext} from "react";
import {useInvoice} from "./useInvoice";

type tInvoiceContext = ReturnType<typeof useInvoice>;
const InvoiceContext = createContext<tInvoiceContext | null>(null);
InvoiceContext.displayName = "InvoiceContext";

export const InvoiceProvider: FC = ({children}) => {
  return <InvoiceContext.Provider value={useInvoice()}>{children}</InvoiceContext.Provider>;
};

export const useContextInvoice = () => {
  const context = useContext(InvoiceContext);

  if (context === null) {
    throw new Error("<<< InvoiceContext >>> must be used within a InvoiceProvider");
  }

  return context as tInvoiceContext;
};
