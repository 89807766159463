import {ExpenseTable} from "./ExpenseTable/ExpenseTable";
import {ExpenseHeader} from "./ExpenseHeader/ExpenseHeader";

export default function ExpenseList() {
  return (
    <>
      <ExpenseHeader />
      <ExpenseTable />
    </>
  );
}
