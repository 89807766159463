import {ChangeEvent, FC} from "react";
import {Select} from "components/shared/FormComponents/Select/Select";

interface iOption {
  id: number;
  value: string;
  label: string;
}

interface iProps {
  id: string;
  name: string;
  option: iOption[];
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  value: string;
  label: string;
}

export const FilterSelect: FC<iProps> = ({id, label, name, option, value, onChange}) => {
  return (
    <>
      <label htmlFor={id} className="JobsTableFilter-label">
        {label}
      </label>
      <Select
        canSelectEmpty
        id={id}
        name={name}
        className="JobsTableFilter-value"
        value={value || ""}
        targetKeys={{value: "id", label: "name"}}
        onChange={onChange}
        options={option}
        placeholder="Select..."
      />
    </>
  );
};
