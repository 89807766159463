import React, {useState} from "react";
import {tTrackCostValuesItem, tTrackFeesItem} from "api/budget/track/types";
import {tDraggingItem} from "components/DragAndDropWrap/DragAndDropWrap";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextTrack} from "../../_context/TrackContext";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {trackApi} from "api/budget/track";
import {eventBus} from "EventBus/EventBus";

type tProps = {
  setData?: React.Dispatch<React.SetStateAction<tTrackCostValuesItem>>;
  setPayrollData?: React.Dispatch<React.SetStateAction<tTrackFeesItem>>;
};

export const useChangeExpenseRowOrder = ({setData, setPayrollData}: tProps) => {
  const {call, isLoading} = useHttp();
  const {setTrackData} = useContextTrack();
  const [currItem, setCurrItem] = useState<tDraggingItem | null>(null);

  const dropCallBack = (draggingItem: tDraggingItem, trackCost: tTrackCostValuesItem) => {
    if (currItem?.order === draggingItem?.order || isLoading) return;

    const newIndex = trackCost?.expense_lines?.findIndex(item => {
      return item?.id === draggingItem?.id;
    }) as number;

    setData?.(prev => {
      const newArrLines = [...prev?.expense_lines?.filter(item => item?.id !== currItem?.id)];

      currItem && newArrLines.splice(newIndex, 0, currItem as any);
      return {...prev, expense_lines: newArrLines};
    });

    currItem?.id && onChangeOrder(currItem.id, newIndex + 1);
  };

  const dropCallBackPayroll = (draggingItem: tDraggingItem, payrollItem: tTrackFeesItem) => {
    if (currItem?.order === draggingItem?.order || isLoading) return;

    const newIndex = payrollItem?.expense_lines?.findIndex(item => {
      return item?.id === draggingItem?.id;
    }) as number;

    setPayrollData?.(prev => {
      const newArrLines = [...prev?.expense_lines?.filter(item => item?.id !== currItem?.id)];

      currItem && newArrLines.splice(newIndex, 0, currItem as any);
      return {...prev, expense_lines: newArrLines};
    });

    currItem?.id && onChangeOrder(currItem.id, newIndex + 1);
  };

  const onChangeOrder = async (lineId: number | string, order: number) => {
    try {
      const res = await call(trackApi.setExpenseLineOrder(+lineId, {order}));
      setTrackData(() => res?.data?.data);
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return {
    setCurrItem,
    dropCallBack,
    dropCallBackPayroll,
    currItem,
    isLoading
  };
};
