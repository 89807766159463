import {useNavigate, useSearchParams} from "react-router-dom";

type tSetApiParams<T> = {
  params?: T;
  removePagination?: boolean;
};

export type useSetApiParamsReturn = ReturnType<typeof useSetApiParams>;

export const useSetApiParams = () => {
  const [searchParams, setParams] = useSearchParams();
  const navigate = useNavigate();

  function setApiParams<T>({params, removePagination = true}: tSetApiParams<T>) {
    const newParams = {...Object.fromEntries(searchParams), ...params};

    Object.entries(newParams).forEach(([key, val]) => {
      let currKey = key as keyof typeof params;
      if (removePagination) {
        // @ts-ignore
        delete newParams["page"];
        // @ts-ignore
        // delete newParams["per_page"];
      }

      if (!val || (Array.isArray(val) && !val?.length)) {
        delete newParams[currKey];
      }

      if (Array.isArray(val) && val.length) {
        // @ts-ignore
        newParams[currKey] = val.join(",");
      }
    });
    // TODO need to check initialize var
    var queryString = Object.keys(newParams)
      .map(key => key + "=" + newParams[key as keyof typeof params])
      .join("&");
    navigate({search: decodeURIComponent(queryString)}, {replace: true});
  }

  const clearAllParams = () => setParams({});

  const preparedParams = Object.fromEntries(searchParams);
  return {setApiParams, searchParams, preparedParams, clearAllParams};
};
