import {ChangeEvent, FC} from "react";
import {TextComponent} from "../../../common/TextComponent/TextComponent";
import {Checkbox} from "components/shared/FormComponents/Checkbox/Checkbox";
import "./SenderRecipientItem.scss";

interface iSenderRecipientItemProps {
  id: number | string;
  position: string;
  name: string;
  selected: boolean | undefined;
  onSelectContact: (event: ChangeEvent<HTMLInputElement>, id: number | string) => void;
}

export const SenderRecipientItem: FC<iSenderRecipientItemProps> = ({
  id,
  name,
  position,
  selected,
  onSelectContact
}) => {
  return (
    <div className={`SenderRecipientItem ${position || ""}`}>
      {position === "Agent" ? <span className="SenderRecipientItem-dot" /> : ""}
      <Checkbox
        label={name}
        value={id}
        checked={selected}
        onChange={event => onSelectContact(event, id)}
      />
      <TextComponent size="13" weight="400" color="gray-0" nowrap>
        {position}
      </TextComponent>
    </div>
  );
};
