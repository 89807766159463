import {FC, useState} from "react";
import {useContextJobTasks} from "../../_context/JobTaskContext";
import {tJobTasksTemplateDataTask} from "api/jobs/tasks/types";
import {useUpdateTaskData} from "../../_context/useUpdateTaskData";
import {InputCell} from "components/shared/InputCell/InputCell";

type tTableTaskRowInitProps = {
  data: tJobTasksTemplateDataTask;
};

export const TableTaskRowInit: FC<tTableTaskRowInitProps> = ({data}) => {
  const {tasks, setTasks} = useContextJobTasks();
  const {updateTasksData, isLoading} = useUpdateTaskData();
  const [value, setValue] = useState("");

  const setName = (val: string) => {
    const postData = tasks?.data?.map(item => {
      if (data?.name === item?.name) {
        const newItem = item?.tasks ? [val, ...item?.tasks] : [val];
        return {...item, tasks: newItem};
      }
      return item;
    });

    updateTasksData(postData, setTasks);
  };

  return (
    <tr className="TableTaskRow">
      <td className="TableTaskRow-cell nameCell rowInitName">
        <InputCell
          value={value}
          setValue={setValue}
          callBack={val => setName(val)}
          placeholder="Add task name..."
          isLoading={isLoading}
          needToCleanValueAfterSubmit
          InputComponent="input"
        />
      </td>
      <td className="TableTaskRow-cell actions"></td>
    </tr>
  );
};
