import {FC} from "react";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";

import "./JobSettingsTemplatesHeader.scss";

interface iJobSettingsTemplatesHeaderProps {
  title: string;
  onRename: () => void;
  onSave?: () => void;
  onClose: () => void;
}

export const JobSettingsTemplatesHeader: FC<iJobSettingsTemplatesHeaderProps> = ({
  title,
  onSave,
  onRename,
  onClose
}) => {
  return (
    <div className="JobSettingsTemplatesHeader">
      <TextComponent weight="700" size="17" color="white-0" uppercase>
        {title}
      </TextComponent>
      <div className="JobSettingsTemplatesHeader-buttonsGroup">
        <Button onClick={onRename} color="dark" size="md">
          Rename
        </Button>
        {onSave && (
          <Button onClick={onSave} className="save" color="light" size="md">
            Save
          </Button>
        )}
        <button onClick={onClose}>
          <Icon color="gray" icon="close" />
        </button>
      </div>
    </div>
  );
};
