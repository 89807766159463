import {tExpenseListItem} from "api/budget/track/types";
import {useSetApiParams} from "hooks/useSetApiParams";
import {alphabetically} from "./helpers";

export const useSortTableColumns = () => {
  const apiParams = useSetApiParams();

  const sortList = (list: Array<tExpenseListItem>) => {
    if (!list?.length) return [];
    const newList = [...list];

    if ("sort_field" in apiParams?.preparedParams) {
      const isASC = apiParams?.preparedParams?.sort_direction === "asc";
      const sortBy = apiParams?.preparedParams?.sort_field; // expenseListTableTitles ids

      return newList?.sort((a: any, b: any) => {
        const name1 = typeof a?.[sortBy] === "string" ? a?.[sortBy]?.toLowerCase() : a?.[sortBy];
        const name2 = typeof b?.[sortBy] === "string" ? b?.[sortBy]?.toLowerCase() : b?.[sortBy];

        if (sortBy === "estimate" || sortBy === "qb_status" || sortBy === "pay_status") {
          return alphabetically({ascending: isASC, a: name1?.name, b: name2?.name});
        }

        if (sortBy === "pay_value") {
          return alphabetically({ascending: isASC, a: name1?.payId?.name, b: name2?.payId?.name});
        }

        if (sortBy === "line_number") {
          return alphabetically({ascending: isASC, a: name1?.code, b: name2?.code});
        }

        if (sortBy === "working_details" || sortBy === "actual_details") {
          return alphabetically({ascending: isASC, a: name1?.amount, b: name2?.amount});
        }

        return alphabetically({ascending: isASC, a: name1, b: name2});
      });
    }

    return list;
  };

  return {sortList};
};
