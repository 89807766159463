import {trackApi} from "api/budget/track";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextExpenseList} from "../ExpenseList/context/ExpenseListContext";
import {useResponseType} from "./useResponseType";
import {useContextTrack} from "../_context/TrackContext";

export type tUseChangeExpenseValue = {
  id: number;
  type: "note" | "description" | "vendor_name" | "actual" | "working" | "charged";
  errorCallback?: () => void;
};

export const useChangeExpenseValue = (props: tUseChangeExpenseValue) => {
  const {response_type, isExpense} = useResponseType();
  const {id, type, errorCallback} = props;
  const {setTrackData} = useContextTrack();
  const {setExpenseList} = useContextExpenseList();

  const {call, isLoading} = useHttp();

  const calls = (value: string, value2?: string) => {
    return {
      vendor_name: trackApi.setVendorName(id, {vendor_name: value, response_type}),
      note: trackApi.setNote(id, {note: value, response_type}),
      description: trackApi.setDescriptionToLine(id, {description: value, response_type}),
      actual: trackApi.setActualAmount(id, {amount: value, currency_code: value2, response_type}),
      working: trackApi.setWorkingDetail(id, {amount: value, currency_code: value2, response_type}), // prettier-ignore
      charged: trackApi.setExpenseCharged(id, {charged: +value, response_type})
    };
  };

  async function onChange(value: string) {
    try {
      const res = await call(calls(value)[type]);
      isExpense && setExpenseList?.(() => res?.data?.data);
      !isExpense && setTrackData(() => res?.data?.data);
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
      errorCallback?.();
    }
  }

  return {onChange, isLoading};
};
