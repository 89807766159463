import {FC, FormEvent, useEffect, useState} from "react";
import {jobsApi} from "api/jobs";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {useHttp} from "hooks/httpServices/useHttp";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {FormWrap} from "components/shared/FormWrap/FormWrap";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {notValidForm} from "helpers/misc";
import {SearchJob} from "./SearchJob";
import {eventBus} from "EventBus/EventBus";
import {iSearchingContactResponse} from "api/contacts/people/interfaces";
import "./AddJobLinkForm.scss";

interface iAddJobLinkFormProps {
  onCloseModal: () => void;
  show: boolean;
}

export const AddJobLinkForm: FC<iAddJobLinkFormProps> = ({onCloseModal, show}) => {
  const {jobDetailsData, jobDetailsQuery, setJobDetails} = useContextJobDetails(); // prettier-ignore
  const id = jobDetailsData?.id;
  const [selectedJob, setJob] = useState<Partial<iSearchingContactResponse> | null>(null); // prettier-ignore
  const {call, isLoading: submitting} = useHttp();
  const FORM_ID = "add-job-link-form-id";

  const form = {
    linked_job_id: useTextInput({value: ""})
  };

  useEffect(() => {
    selectedJob?.name !== form.linked_job_id.value && setJob(null);
  }, [form.linked_job_id.value]); // eslint-disable-line

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (notValidForm(form)) return;
    if (!selectedJob?.id) {
      return form.linked_job_id.setErrors(["You should select from list !"]);
    }
    if (!id) return;
    try {
      const data = {
        linked_job_id: selectedJob.id
      };

      const res = await call(jobsApi.addJobLink(id, data));
      setJobDetails(res?.data?.data);
      onCloseModal();
      eventBus.dispatch("showToast", {text: res?.data?.message});
    } catch (error) {
      const {msg} = apiErrorHandler(error, form);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error !"});
    }
  };

  return (
    <ModalPopup className="LinkModal" show={show} onClose={onCloseModal} withoutPaddings>
      <FormWrap
        idForm={FORM_ID}
        isLoading={jobDetailsQuery.isLoading || submitting}
        onCancel={onCloseModal}
        submitTitle="Add link"
        headerTitle="Add job link"
      >
        <form className="AddJobLinkForm" onSubmit={onSubmit} id={FORM_ID}>
          <SearchJob inputData={form.linked_job_id} setJob={setJob} />
        </form>
      </FormWrap>
    </ModalPopup>
  );
};
