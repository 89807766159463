import {FC} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import {LocationInput} from "./LocationInput";

import "./LocationDynamicInput.scss";

interface iLocationDynamicInputProps {
  options: any[];
  values: any[];
  onChangeInputRow: (arg: any) => void;
  setShowConfirmRemove: (arg: any) => void;
  onCreateNewRow: any;
  emptyRowError: boolean;
  showConfirmRemove?: any;
  onRemoveRow: any;
}

export const LocationDynamicInput: FC<iLocationDynamicInputProps> = ({
  options = [],
  setShowConfirmRemove,
  onChangeInputRow,
  emptyRowError,
  values,
  onRemoveRow,
  showConfirmRemove,
  onCreateNewRow
}) => {
  const selectOptions = options.filter(l => !values.find(a => a.location_id === l.id));

  return (
    <div className="LocationDynamicInput">
      <div className="LocationDynamicInput-labelRow">
        <label className="LocationDynamicInput-label">Label </label>
        <label className="LocationDynamicInput-label">Location</label>
      </div>

      {values?.map(value => (
        <LocationInput
          key={value.id}
          value={value}
          options={selectOptions}
          onChangeInputRow={onChangeInputRow}
          onRemoveRow={(item: any) => setShowConfirmRemove(item)}
          hasRemoveButton={values?.length > 1}
        />
      ))}
      {emptyRowError && (
        <div className="DynamicFieldsWrap-emptyRowErr">
          You can't add new items before to fill previous ones
        </div>
      )}
      {!!selectOptions?.length && (
        <Button type="button" color="outline" size="sm" onClick={onCreateNewRow}>
          <Icon icon="add" size="14" />
          Location
        </Button>
      )}

      <ConfirmWindow
        show={!!showConfirmRemove}
        onClose={() => setShowConfirmRemove("")}
        onConfirm={onRemoveRow}
        confirmBtn={{text: "Delete", color: "red"}}
        cancelBtn={{text: "Cancel"}}
        title="Remove location"
        warning="This can't be undone."
      >
        <div className="DynamicFieldsWrap-confirmModal-body">
          <p>Are you sure you want to delete {showConfirmRemove?.label}</p>
        </div>
      </ConfirmWindow>
    </div>
  );
};
