import {CSSProperties, FC, useRef, MutableRefObject, useState, useEffect} from "react";
import {tEventModal} from "pages/Jobs/JobDetails/JobCalendar/helpers";
import {useContextJobCalendar} from "pages/Jobs/JobDetails/JobCalendar/context";
import moment from "moment";
import {getDateFormat} from "helpers/dates";

import "./AgendaView.scss";

interface iAgendaViewProps {
  onShowEventDetails: (id: string, events: tEventModal[]) => void;
  events: tEventModal[];
}

export const AgendaView: FC<iAgendaViewProps> = ({onShowEventDetails}) => {
  let rowRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const {getCalendarSheet, calendarSheets} = useContextJobCalendar();
  const [todayPosition, setTodayPosition] = useState<CSSProperties>({});

  useEffect(() => {
    if (!rowRef?.current) return;
    const rowSize = rowRef.current.getBoundingClientRect();
    const height = rowSize.height;
    const hours = new Date().getHours();

    const top = (hours * height) / 24 + "px";
    setTodayPosition({top});
  }, []);

  const generatedEvents = generateAgendaData(calendarSheets || []);
  const emptyEvents = !Object.keys(generatedEvents)?.length;
  const isLoading = !calendarSheets;

  useEffect(() => {
    if (!calendarSheets) {
      getCalendarSheet();
    }
  }, []); // eslint-disable-line

  return (
    <div className="AgendaView">
      {isLoading && <div className="AgendaView-loading">Loading...</div>}
      {!isLoading && emptyEvents && <p className="AgendaView-emptyState">Hasn't events yet</p>}
      {!isLoading &&
        generatedEvents.map(([key, value]: any) => {
          return (
            <div
              key={key}
              className="AgendaView-row"
              id={key}
              ref={r => (isSameDay(value[0].start_date) ? (rowRef.current = r) : null)}
            >
              {isSameDay(value[0].start_date) && (
                <span style={todayPosition} className="AgendaView-todayLine" />
              )}
              {value.map((event: tEventModal, index: number) => {
                const {id, type, name, all_day, start_date, end_date} = event;
                const dateNumber = start_date ? new Date(start_date).getDate() : "";
                const dayMonth = start_date ? moment(start_date).format("MMM, ddd") : "";
                const formatTime = (d: Date) => moment(d).format("hh:mm A").split(" ").join("");
                const time = `${formatTime(start_date)} - ${formatTime(end_date)}`;
                // const year = moment().diff(moment(start_date), "years");
                const isHolds = type === "holds";

                return (
                  <div
                    key={`${type}-${name}`}
                    className="AgendaView-event"
                    onClick={() => onShowEventDetails(id, calendarSheets || [])}
                  >
                    <div className="AgendaView-date">
                      {index === 0 && (
                        <>
                          <div className="AgendaView-date-number">{dateNumber}</div>
                          <div className="AgendaView-date-day">{dayMonth}</div>
                        </>
                      )}
                    </div>

                    <div className={`AgendaView-time ${type}`}>
                      {all_day && "All day"}
                      {isHolds && "hold"}
                      {!all_day && !isHolds && time}
                    </div>
                    <div className="AgendaView-name">{name}</div>
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
};

type tGeneratedAgendaData = [string, tEventModal[]][];
type tEventsOrder = tEventModal & {order: number};
function generateAgendaData(events: tEventsOrder[]): tGeneratedAgendaData {
  let data: {[key: string]: tEventModal[]} = {};
  events.forEach((event, index, array) => {
    const isSamePrev = moment(event.start_date).isSame(array?.[index - 1]?.start_date, "days");
    const isSameNext = moment(event.start_date).isSame(array?.[index + 1]?.start_date, "days");
    const day = moment(event.start_date).format("DD");
    const order = getDateFormat(event.start_date, "timestamp");
    event = {...event, order};
    if (index === 0 || !isSamePrev) {
      data = {...data, [day]: [event]};
      return;
    }
    if (isSameNext || (isSamePrev && !isSameNext)) {
      data = {...data, [day]: [...data[day], event]};
      return;
    }
  });
  return Object.entries(data).sort((a, b) => {
    return Number(a[1][0].order) - Number(b[1][0].order);
  });
}

function isSameDay(date: Date) {
  return moment().isSame(date, "day");
}
