import {createContext, FC, useContext, useEffect, useState} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {jobCrewGroup} from "api/jobCrewGroup";
import {tCrewGroupContact} from "api/jobCrewGroup/types";
import {useContextTemplateLayout} from "../_common/TemplateLayoutContext";

const useJobCrew = () => {
  const {call: callGroup, data: dataGroup, ...propsGroup} = useHttp();
  const {id, ...data} = useContextTemplateLayout();
  const [crewGroupList, setCrewGroupList] = useState<Array<tCrewGroupContact>>([]);

  useEffect(() => {
    id && getJobCrewGroupList(id);
  }, [id]); // eslint-disable-line

  useEffect(() => {
    if (!!selectedContacts?.length || showSelectContactIcon) {
      closeSecondaryHeader();
    }
  }, [crewGroupList]); // eslint-disable-line

  const getJobCrewGroupList = (crewId?: number) => {
    closeSecondaryHeader();
    const currId = crewId ? crewId : id;
    return callGroup(jobCrewGroup.getJobCrewGroupList(currId)).then(res => {
      const data = res?.data?.data as Array<tCrewGroupContact>;
      setCrewGroupList(data);
    });
  };

  const [selectedContacts, setSelectedContact] = useState<Array<number> | null>(null);
  const [showSelectContactIcon, setShowSelectContactIcon] = useState(false);
  const closeSecondaryHeader = () => {
    setSelectedContact(null);
    setShowSelectContactIcon(false);
  };

  const [onlyActive, setOnlyActive] = useState(false);
  const {call: onMoveGroup, isLoading: moveGroupSubmitting} = useHttp();

  return {
    onlyActive,
    setOnlyActive,
    selectedContacts,
    setSelectedContact,
    showSelectContactIcon,
    setShowSelectContactIcon,
    closeSecondaryHeader,
    jobCrew: {
      data,
      crew: {id, ...data} as any
    },
    jobCrewGroupList: {
      dataGroup,
      ...propsGroup,
      getJobCrewGroupList,
      crewGroupList,
      setCrewGroupList
    },
    isLoading: propsGroup.isLoading,
    onMoveGroup,
    moveGroupSubmitting
  };
};

type tJobCrewContext = ReturnType<typeof useJobCrew>;
const JobCrewContext = createContext<tJobCrewContext | null>(null);
JobCrewContext.displayName = "JobCrewContext";
export const JobCrewContextProvider: FC = ({children}) => (
  <JobCrewContext.Provider value={useJobCrew()}>{children}</JobCrewContext.Provider>
);

export const useContextJobCrew = () => {
  const context = useContext(JobCrewContext);

  if (context === null) {
    throw new Error("<<< JobCrewContext >>> must be used within a JobCrewContextProvider");
  }

  return context as tJobCrewContext;
};
