import {FC, FormEvent} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {eventBus} from "EventBus/EventBus";
import {useContextJobBudget} from "../../context/JobBudgetContext";
import {notValidForm} from "helpers/misc";
import {estimateApi} from "api/budget/estimate";

interface iEstimateSaveAsNewTemplateProps {
  onClose: () => void;
}

export const EstimateSaveAsNewTemplate: FC<iEstimateSaveAsNewTemplateProps> = ({onClose}) => {
  const {estimate} = useContextJobBudget();
  const id = estimate?.id;
  const {call, isLoading} = useHttp();
  const FORM_ID = "save-as-new-estimate-template-form-id";

  const form = {
    name: useTextInput()
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (notValidForm(form) || !id) return;

    try {
      const res = await call(estimateApi.createNewTemplate(id, {name: form.name.value}));

      onClose();
      eventBus.dispatch("showToast", {type: "success", text: res?.data?.message || "Success!"});
    } catch (error) {
      const {msg} = apiErrorHandler(error, form);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <ConfirmWindow
      show={true}
      title="SAVE AS NEW TEMPLATE"
      subTitle="Name your template"
      cancelBtn={{text: "Cancel", type: "button"}}
      confirmBtn={{text: "save", type: "submit", form: FORM_ID}}
      onClose={onClose}
      isLoading={isLoading}
    >
      <form onSubmit={onSubmit} id={FORM_ID}>
        <InputField {...form.name.inputProps} errors={form.name.errors} />
      </form>
    </ConfirmWindow>
  );
};
