import {ChangeEvent, FC, useEffect} from "react";
import {Button} from "components/shared/Button/Button";
import {useFilter} from "./useFilter";
import {FilterFavorite} from "./Filters/FilterFavorite";
import {ActionMeta} from "react-select";
import {FilterSelect} from "./Filters/FilterSelect";
import {FilterSearching} from "./Filters/FilterSearching";
import {iStateOption} from "components/shared/FormComponents/TagsField/TagsField";
import {FilterSearchingClients} from "./Filters/FilterSearchingClients";
import {useSetApiParams} from "hooks/useSetApiParams";
import {useContextJobsList} from "../context/JobsListContext/JobsListContext";

import "./JobsTableFilter.scss";

export const JobsTableFilter: FC = () => {
  const apiParams = useSetApiParams();

  const {departments, pipelines, clientsOptions, localesOptions, artistsOptions} =
    useContextJobsList();

  const {
    filterApiData,
    onSearchClients,
    onSearchArtists,
    // onSearchLabels,
    onSearchLocals,
    clearFilters,
    getFilterApiData,
    filterData,
    clients,
    setClients
  } = useFilter();

  useEffect(() => {
    getFilterApiData();
  }, []); // eslint-disable-line

  const handleChangeSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const {value, name} = event.target;
    const params = {[name]: value ? [value] : ""};
    apiParams.setApiParams({params});
  };

  const handleChangeTags = (value: iStateOption[], actionMeta: ActionMeta<iStateOption>) => {
    const params = {[actionMeta.name as string]: value.map(opt => opt.id)};
    apiParams.setApiParams({params});
  };

  // get all pipelines from pipeline groups
  function getAllPipelines(data: any[]) {
    if (!data) return [];
    let pipelines: any = [];
    data?.forEach(group => {
      group?.pipelines?.forEach((pipe: any) => {
        pipelines = [...pipelines, pipe];
      });
    });
    return pipelines.sort((a: any, b: any) => a.name.localeCompare(b.name));
  }

  return (
    <div className="JobsTableFilter">
      <div className="JobsTableFilter-grid">
        <FilterFavorite
          onChange={apiParams.setApiParams}
          value={apiParams?.preparedParams?.favorite}
        />
        <FilterSelect
          id="filter-departments"
          name="departments"
          label="Department"
          option={departments}
          value={filterData?.departments}
          onChange={handleChangeSelect}
        />
        <FilterSelect
          id="filter-pipeline"
          name="pipelines"
          label="Pipeline"
          option={getAllPipelines(pipelines)}
          value={filterData?.pipelines}
          onChange={handleChangeSelect}
        />
        <FilterSearchingClients
          id="filter-client"
          name="client"
          label="Client"
          placeholder="Search clients"
          onSearch={onSearchClients}
          clients={clients}
          setClients={setClients}
          options={clientsOptions}
          apiParams={apiParams}
          isLoading={filterApiData?.clients?.isLoading}
        />
        <FilterSearching
          id="filter-artist"
          name="artists"
          label="Artist"
          placeholder="Search artists"
          value={filterData?.artists}
          onChange={handleChangeTags}
          onSearch={onSearchArtists}
          options={artistsOptions}
          labelKey={["first_name", "last_name"]}
          isLoading={filterApiData?.artists?.isLoading}
        />

        {/* <FilterSearching
          id="filter-labels"
          name="labels"
          label="Label"
          placeholder="Search labels"
          value={filterData.labels}
          onChange={handleChangeTags}
          onSearch={onSearchLabels}
          options={labelsOptions}
          labelKey={["name"]}
          isLoading={filterApiData?.labels?.isLoading}
        /> */}
        <FilterSearching
          id="filter-locales"
          name="locales"
          label="Locale"
          placeholder="Search locales"
          value={filterData.locales}
          onChange={handleChangeTags}
          onSearch={onSearchLocals}
          options={localesOptions}
          labelKey={["name"]}
          isLoading={filterApiData?.labels?.isLoading}
        />
      </div>
      <Button size="sm" color="light" onClick={clearFilters} className="JobsTableFilter-button">
        CLEAR FILTERS
      </Button>
    </div>
  );
};
