import {FC} from "react";
import {BudgetTypesListItem} from "../BudgetTypesListItem/BudgetTypesListItem";
import {tEstimateType} from "api/budget/estimate/types";
import {Icon} from "components/Icon/Icon";
import {TextComponent} from "../../../../JobDetails/common/TextComponent/TextComponent";
import {tBudgetTypesReturn} from "../useBudgetTypes";
import "./TypesList.scss";

interface iTypesListProps {
  data: tEstimateType[];
  onSetStep: tBudgetTypesReturn["setStepData"];
}

export const TypesList: FC<iTypesListProps> = ({data, onSetStep}) => {
  const handleClick = (id: number) => {
    onSetStep(prev => ({
      ...prev,
      budgetTypes: {id, name: data?.find(d => d.id === id)?.name || ""},
      step: "categories"
    }));
  };
  return (
    <div className="TypesList">
      {data.map(category => {
        return (
          <BudgetTypesListItem
            key={category.id}
            id={category.id}
            name={category.name}
            handleClick={handleClick}
          />
        );
      })}
      <div className="TypesList-warning">
        <Icon icon="warning" color="yellow" />
        <TextComponent size="11" color="gray-0" weight="400">
          Changes to the budget masters will not be reflected in previous templates. Please create
          new templates for the latest version.
        </TextComponent>
      </div>
    </div>
  );
};
