import {useEffect, useState} from "react";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useDatePicker} from "components/shared/FormComponents/Datepicker/useDatePicker";
import {tAdvanceContact} from "api/budget/advanceContact/types";
import {useContextInvoice} from "../../context/InvoiceContext";

export function useInvoiceBuildAdvance() {
  const {invoice, setInvoice} = useContextInvoice();

  const [editContacts, setEditContacts] = useState(false);

  const formData = {
    invoice_number: useTextInput({value: invoice?.invoice_number}),
    date: useDatePicker({initValue: invoice?.date || new Date().toString()}),
    terms: useTextInput({value: invoice?.terms}),
    due_date: useDatePicker({initValue: invoice?.due_date, withPlaceholder: true}),
    contact: useTextInput({
      value: invoice?.advance_contact?.name ? generateContactInfo(invoice.advance_contact) : ""
    })
  };

  useEffect(() => {
    updateAdvanceDetails("date", formData.date.value);
  }, [formData.date.value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateAdvanceDetails("due_date", formData.due_date.value);
  }, [formData.due_date.value]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeContact = (contact: tAdvanceContact) => {
    setInvoice(prevState => (prevState ? {...prevState, advance_contact: contact} : null));
    formData.contact.setValue(generateContactInfo(contact));
  };

  function generateContactInfo(contact: tAdvanceContact) {
    return `${contact.name}\r\n${contact.phone}\r\n${contact.email}`;
  }

  const onSaveCallback = (event: any) => {
    const {name, value} = event.target;
    updateAdvanceDetails(name, value);
  };

  function updateAdvanceDetails(key: any, value: any) {
    setInvoice(prevState => (prevState ? {...prevState, [key]: value} : null));
  }

  return {
    formData,
    editContacts,
    setEditContacts,
    onSaveCallback,
    onChangeContact
  };
}
