import {tTrackCostValue, tTrackCostValuesItem} from "api/budget/track/types";
import {getUsMoneyFormat} from "helpers/formattingData";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {CostValueActions} from "../../../_common/CostValueActions";
import {CostValueRowName} from "../../../_common/CostValueRowName/CostValueRowName";
import {getDiffValueColor, getValue} from "../../../_common/getAndFormatVal";

type tUseGetCostValueCellsProps = {
  trackCost: tTrackCostValue;
  estimate: string;
  costValueExpenseTotal: tTrackCostValuesItem["cost_value_expense_total"];
  isFirstItem?: boolean;
};

export const useGetCostValueCells = ({
  estimate,
  trackCost,
  costValueExpenseTotal,
  isFirstItem
}: tUseGetCostValueCellsProps) => {
  const {total_actual, total_difference, total_working} = costValueExpenseTotal;

  const timeCount = !!trackCost?.time_count ? trackCost?.time_count : "";
  const timeUnitName = !!trackCost?.time_unit?.name ? trackCost?.time_unit?.name : "";

  const cells = [
    {
      component: (
        <CostValueRowName
          className="CostValueRow-cell borderRight"
          trackCost={trackCost}
          isFirstItem={isFirstItem}
        />
      )
    },
    {
      component:
        //prettier-ignore
        <TextComponent weight="500" size="10" uppercase className="CostValueRow-cell borderRight" nowrap> 
          {estimate}
        </TextComponent>
    },
    {
      component: (
        <TextComponent weight="400" size="14" className="CostValueRow-cell">
          {trackCost?.quantity && trackCost?.quantity}
        </TextComponent>
      )
    },
    {
      component: (
        <TextComponent weight="400" size="14" className="CostValueRow-cell">
          {/* {getValue(trackCost?.cost)} */}
          {getUsMoneyFormat(trackCost?.cost, trackCost?.currency_rate?.code)}
        </TextComponent>
      )
    },
    {
      component: (
        <TextComponent weight="400" size="14" lowercase className="CostValueRow-cell" nowrap>
          {`${timeCount} ${timeUnitName}`}
        </TextComponent>
      )
    },
    {
      component:
        // prettier-ignore
        <TextComponent weight="700" size="15"className="CostValueRow-cell alignRight borderRight">
          {getValue(trackCost?.total)}
        </TextComponent>
    },
    {
      component:
        // prettier-ignore
        <TextComponent weight="400" size="14" className="CostValueRow-cell alignRight borderRight">
          {getValue(total_working)}
        </TextComponent>
    },
    {
      component:
        // prettier-ignore
        <TextComponent weight="700" size="15" className="CostValueRow-cell alignRight borderRight">
          {getValue(total_actual)}
        </TextComponent>
    },
    {
      component:
        // prettier-ignore
        <TextComponent weight="400" size="14" color={getDiffValueColor(total_difference)} className="CostValueRow-cell alignRight">
          {getValue(total_difference)}
        </TextComponent>,
      className: "difference"
    },
    {
      component: (
        <CostValueActions trackCost={trackCost} className="CostValueRow-cell alignRight" />
      ),
      colSpan: 5
    }
  ];

  return {cells};
};
