import {FC} from "react";
import {StyleSheet, Text, View} from "@react-pdf/renderer";
import {getPercentFormat, getUsMoneyFormat} from "helpers/formattingData";
import {tEstimateData} from "api/budget/estimate/types";

interface iEstimateValuesTableProps {
  data: tEstimateData["category_values"];
}

const styles = StyleSheet.create({
  container: {
    flex: "1 1 auto"
  },
  table: {
    // @ts-ignore
    display: "table",
    width: "100%",
    marginBottom: "32px"
  },
  // header
  tableHeaderRow: {
    width: "100%",
    flexDirection: "row",
    borderBottom: "2px solid black",
    paddingBottom: "2px",
    marginBottom: "14px"
  },
  tableCellHeader: {
    fontSize: "8px", // 10px
    fontFamily: "Helvetica500",
    textTransform: "uppercase",
    margin: "auto 0"
  },
  tableHeaderCellTitle: {
    marginBottom: 4,
    fontSize: "13px",
    fontWeight: 700,
    textTransform: "uppercase",
    fontFamily: "Helvetica700"
  },
  /// table body
  tableRow: {
    width: "100%",
    flexDirection: "row",
    marginBottom: "8px"
  },
  tableColCode: {
    width: "7%"
  },
  tableColName: {
    width: "33%"
  },
  tableCol: {
    width: "10%"
  },
  tableColCost: {
    width: "18%"
  },
  tableColSm: {
    width: "5%"
  },
  tableCoAgency: {
    width: "8%"
  },
  tableColTotal: {
    width: "20%",
    textAlign: "right"
  },
  tableCell: {
    margin: "0 0",
    fontSize: "11px",
    fontFamily: "Helvetica400"
  },
  tableCellGroupTotal: {
    margin: "12px 0 auto",
    fontSize: "13px",
    textTransform: "uppercase",
    fontFamily: "Helvetica700"
  },
  centerText: {
    textAlign: "center",
    width: "100%"
  }
});

export const EstimateValuesTable: FC<iEstimateValuesTableProps> = ({data}) => {
  if (!data) return null;
  return (
    <View style={styles.container}>
      {data.map(({id, cost_values, total, name, code}) => {
        return (
          <View key={id} style={styles.table}>
            <View style={styles.tableHeaderRow}>
              <View style={styles.tableColCode}>
                <Text style={styles.tableHeaderCellTitle}>{code}</Text>
              </View>
              <View style={styles.tableColName}>
                <Text style={styles.tableHeaderCellTitle}>{name}</Text>
              </View>
              <View style={styles.tableColSm}>
                <Text style={styles.tableCellHeader}>qty</Text>
              </View>
              <View style={styles.tableColCost}>
                <Text style={styles.tableCellHeader}>cost</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellHeader}>time</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={{...styles.tableCellHeader, ...styles.centerText}}>agency %</Text>
              </View>
              <View style={styles.tableColTotal}>
                <Text style={styles.tableCellHeader}>total</Text>
              </View>
            </View>
            {cost_values.map(item => (
              <View key={item.id} style={styles.tableRow}>
                <View style={styles.tableColCode}>
                  <Text style={styles.tableCell}>{item.code + item.suffix}</Text>
                </View>
                <View style={styles.tableColName}>
                  <Text style={styles.tableCell}>{item.name}</Text>
                  {!!item?.description && (
                    <Text style={{...styles.tableCell, color: "#7E7E80"}}>{item?.description}</Text>
                  )}
                  {!!item?.discount_description && (
                    <Text style={{...styles.tableCell, color: "#D2232A"}}>
                      {item?.discount_description}
                    </Text>
                  )}
                </View>
                <View style={styles.tableColSm}>
                  <Text style={styles.tableCell}>{item.quantity}</Text>
                </View>
                <View style={styles.tableColCost}>
                  <Text style={styles.tableCell}>
                    {getUsMoneyFormat(item.cost || 0, item?.currency_rate?.code)}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {item.time_count} {item?.time_unit?.name}
                  </Text>
                </View>
                <View style={styles.tableCoAgency}>
                  <Text style={{...styles.tableCell, ...styles.centerText}}>
                    {item.agency_percent ? `${getPercentFormat(item.agency_percent)}` : ""}
                  </Text>
                </View>
                <View style={styles.tableColTotal}>
                  <Text style={styles.tableCell}>{getUsMoneyFormat(item.total || 0)}</Text>
                </View>
              </View>
            ))}

            <View style={styles.tableRow}>
              <View style={styles.tableColCode}>
                <Text style={styles.tableCell} />
              </View>
              <View style={styles.tableColName}>
                <Text style={styles.tableCell} />
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell} />
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell} />
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellGroupTotal}>Total</Text>
              </View>
              <View style={styles.tableCol} />
              <View style={styles.tableColTotal}>
                <Text style={styles.tableCellGroupTotal}>{getUsMoneyFormat(total)}</Text>
              </View>
            </View>
          </View>
        );
      })}
    </View>
  );
};
