import {useState, ChangeEvent} from "react";
import {iRadioItemProps} from "components/shared/FormComponents/RadioField/RadioField";

interface iUseRadioProps {
  fields: iRadioItemProps[];
  value?: string;
  errors?: string[];
  isRequired?: boolean;
  isValid?: boolean;
}

export const useRadio = (props: iUseRadioProps) => {
  const [value, setValue] = useState(props?.value || "");
  const [errors, setErrors] = useState(props?.errors || []);
  const [isRequired, setIsRequired] = useState(
    props?.isRequired !== undefined ? props.isRequired : true
  );
  const [isValid, setIsValid] = useState(props?.isValid !== undefined ? props?.isValid : false);

  const onClick = () => setValue("");

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    checkValidity(event.target.value);
  };

  const checkValidity = (val?: string) => {
    const newValue = val || value;
    let newErrors: string[] = [];

    if (isRequired && !newValue) {
      newErrors.push("This field can't be empty.");
      setErrors(newErrors);
      setIsValid(!newErrors?.length);
      return false;
    }
    newErrors = [];
    setIsValid(!newErrors?.length);
    setErrors(newErrors);
    return !newErrors?.length;
  };

  const initValue = (value: string) => setValue(value);

  return {
    fields: props.fields,
    setValue: initValue,
    onChange,
    onClick,

    value,
    errors,
    setErrors,
    isRequired,
    setIsRequired,
    checkValidity,
    isValid,
    setIsValid,
    isChanged: props?.value !== value,
    inputProps: {
      onChange,
      onClick,
      value
    }
  };
};

/*
const formData = {
 permit_required: useRadio({isRequired: false, fields: [
  {
    name: "permit_required",
    label: "Yes",
    value: "yes"
  },
  {name: "permit_required", label: "No", value: "no"}
]}),

}
 */
