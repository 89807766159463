import {FC, FormEvent} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {eventBus} from "EventBus/EventBus";
import {notValidForm} from "helpers/misc";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {jobTasksApi} from "api/jobs/tasks";
import {useContextJobTasks} from "../_context/JobTaskContext";

type tAddTasksGroupFormProps = {onClose: () => void};

export const AddTaskGroupForm: FC<tAddTasksGroupFormProps> = ({onClose}) => {
  const {tasks, setTasks} = useContextJobTasks();
  const {call, isLoading} = useHttp();
  const FORM_ID = "add-tasks-group-form-id";

  const form = {
    name: useTextInput()
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (notValidForm(form) || !tasks?.id) return;

    try {
      const res = await call(jobTasksApi.createGroupTasks(tasks?.id, {name: form.name.value}));

      onClose();
      setTasks(res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error, form);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <ConfirmWindow
      show={true}
      title="Create group"
      subTitle="Enter a name"
      cancelBtn={{text: "Cancel", type: "button"}}
      onClose={onClose}
      confirmBtn={{text: "save", type: "submit", form: FORM_ID}}
      isLoading={isLoading}
    >
      <form className="AddGroup" onSubmit={onSubmit} id={FORM_ID}>
        <InputField {...form.name.inputProps} errors={form.name.errors} />
      </form>
    </ConfirmWindow>
  );
};
