import {MouseEvent, useState} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {invoiceApi} from "api/invoice";
import {useContextInvoice} from "../context/InvoiceContext";
import {getDateFormat} from "helpers/dates";

type tModal = "pay" | "send" | "preview";

export function useStatusBarLayout() {
  const {invoice, setInvoice, getPreviewPdf} = useContextInvoice();
  const saveInvoiceQuery = useHttp();
  const saveToDropboxQuery = useHttp();
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [modal, setModal] = useState<tModal | null>(null);
  const [pdf, setPdf] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSavingToDropbox, setIsSavingToDropbox] = useState(false);

  const toggleMenu = (event: MouseEvent<HTMLButtonElement> | null) => {
    setAnchor(event?.currentTarget || null);
  };

  const toggleModal = (m: tModal | null) => {
    toggleMenu(null);
    setModal(m);
  };

  const onSave = async () => {
    if (!invoice?.id) return;
    const {bank_detail, creative, date, due_date, final_adjustment} = invoice;
    const {green, insurance, invoice_number, job_name, job_number} = invoice;
    const {term_condition, terms, usage_license, job_start_at, job_end_at} = invoice;
    const {notes, paid_date, photographer, po, production, advance_contact} = invoice;

    const {label1, label2, text1, text2} = invoice;
    const extended = [
      {label: label1, text: text1},
      {label: label2, text: text2}
    ];

    const payload: any = {
      green,
      insurance,
      invoice_number,
      paid_date,
      production,
      date: getDateFormat(date, "timestamp"),
      due_date: getDateFormat(due_date, "timestamp") ?? null,
      job_start_at: getDateFormat(job_start_at, "timestampUTC"),
      job_end_at: getDateFormat(job_end_at, "timestampUTC"),
      final_adjustment: +final_adjustment,
      ...(creative && {creative}),
      ...(job_name && {job_name}),
      ...(notes && {notes}),
      ...(photographer && {photographer}),
      ...(po && {po}),
      ...(terms && {terms}),
      ...(usage_license && {usage_license}),
      ...(job_number && {job_number: String(job_number)}),
      ...(term_condition?.id && {term_condition_id: term_condition?.id}),
      ...(bank_detail?.id && {bank_id: bank_detail?.id}),
      ...(advance_contact?.id && {advance_contact_id: advance_contact?.id}),
      extended,
      label1,
      label2,
      text1,
      text2
    };

    try {
      const {
        data: {data, message}
      } = await saveInvoiceQuery.call(invoiceApi.updateInvoice(invoice.id, payload));
      const ext = {
        label1: data?.extended?.[0]?.label,
        label2: data?.extended?.[1]?.label,
        text1: data?.extended?.[0]?.text,
        text2: data?.extended?.[1]?.text
      };
      setInvoice({...data, ...ext});
      eventBus.dispatch("showToast", {text: message});
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const onShowPreview = async () => {
    setIsLoading(true);
    try {
      setModal("preview");
      const pdfData = await getPreviewPdf();
      setPdf(pdfData);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSaveToDropbox = async () => {
    if (!invoice?.id) return;
    setIsSavingToDropbox(true);
    try {
      const pdf = await getPreviewPdf();
      if (!pdf) return;
      const payload: any = new FormData();
      payload.append("file", pdf.blob);
      const {
        data: {message}
      } = await saveToDropboxQuery.call(invoiceApi.saveInvoiceToDropbox(invoice.id, payload));
      eventBus.dispatch("showToast", {text: message});
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    } finally {
      setIsSavingToDropbox(false);
    }
  };

  return {
    onShowPreview,
    toggleMenu,
    modal,
    onSaveToDropbox,
    pdf,
    setPdf,
    anchor,
    toggleModal,
    onSave,
    isSaving: saveInvoiceQuery.isLoading,
    isSavingToDropbox: isSavingToDropbox,
    isLoading
  };
}
