import {FC} from "react";
import {tExpenseListItem, tTrackExpenseLine} from "api/budget/track/types";
import {Button} from "components/shared/Button/Button";
import {useContextTrack} from "../../_context/TrackContext";
import {tPayStatus} from "api/common/types";
import {useDropdownCellWrap} from "./DropdownCellWrap/useDropdownCellWrap";
import {DropdownCellWrap} from "./DropdownCellWrap/DropdownCellWrap";

type tPayStatusProps = {
  className?: string;
  trackLine: tTrackExpenseLine | tExpenseListItem;
};

const colors: Record<tPayStatus["name"], string> = {
  Missing: `var(--black-0)`,
  Paid: `var(--green-10)`,
  Unpaid: "var(--red-0)",
  Pending: "var(--orange-0)",
  "N/A": `var(--black-0)`
};

export const PayStatus: FC<tPayStatusProps> = ({className = "", trackLine}) => {
  const {lists: {payStatuses: {list}}} = useContextTrack(); // prettier-ignore
  const {isLoading, menuAnchor, setMenuAnchor, changeValue} = useDropdownCellWrap({
    trackLine,
    type: "payStatus"
  });

  const color = !!trackLine?.pay_status ? colors[trackLine?.pay_status?.name] : "var(--gray-20)";
  return (
    <DropdownCellWrap
      trackLine={trackLine}
      isLoading={isLoading}
      menuAnchor={menuAnchor}
      setMenuAnchor={setMenuAnchor}
      className={`${className}`}
      value={trackLine?.pay_status?.name ?? "pay status"}
      emptyBtnCallback={() => changeValue(null)}
      style={{color}}
    >
      {list?.map(item => {
        return (
          <Button
            key={item?.id}
            size="sm"
            className="btn"
            onClick={() => changeValue(item?.id)}
            style={{color: colors[item?.name]}}
          >
            {item?.name}
          </Button>
        );
      })}
    </DropdownCellWrap>
  );
};
