import {FC} from "react";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {Icon} from "components/Icon/Icon";
import {getDateFormat} from "helpers/dates";
import {HiddenCostValue} from "./HiddenCostValue";
import {getUsMoneyFormat} from "helpers/formattingData";
import {useContextJobBudget} from "../../_context/JobBudgetContext";
import "./EstimateTotalCard.scss";

type tEstimateTotalCardProps = {
  showDetails: boolean;
};

export const EstimateTotalCard: FC<tEstimateTotalCardProps> = ({showDetails}) => {
  const {estimate} = useContextJobBudget();

  const isApproved = !!estimate?.approved_at;
  return (
    <div className={`EstimateTotalCard ${isApproved ? "approved" : ""}`}>
      <TextComponent uppercase size="17" className="EstimateTotalCard-header">
        <span>Grand total</span>{" "}
        <span> {!!estimate?.grand_total ? getUsMoneyFormat(estimate?.grand_total) : "$0.00"}</span>
      </TextComponent>

      <HiddenCostValue />

      {showDetails && (
        <div className="EstimateTotalCard-detailsInfo">
          <TextComponent size="13" weight="500">
            Advance Required
          </TextComponent>
          <TextComponent size="13" weight="400" color="gray-10">
            ({estimate?.advance_required_percent}%)
          </TextComponent>
          <TextComponent size="14" weight="500">
            {!!estimate?.advance_required ? getUsMoneyFormat(estimate?.advance_required) : "$0.00"}
          </TextComponent>
        </div>
      )}

      {isApproved && (
        <div className="approvedFooter">
          <div className="iconWrap">
            <Icon icon="check" color="green" size="xsm" />
          </div>

          <TextComponent size="13" weight="700" color="white-0" uppercase>
            approved
          </TextComponent>

          <TextComponent size="13" weight="400" color="white-0">
            {getDateFormat(estimate.approved_at, "MMM DD, YYYY")}
          </TextComponent>
        </div>
      )}
    </div>
  );
};
