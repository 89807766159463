import {FC} from "react";
import {TagButton} from "components/shared/TagButton/TagButton";
import {getName} from "helpers/misc";
import {RadioField} from "components/shared/FormComponents/RadioField/RadioField";
import {ReactSelect} from "components/shared/FormComponents/ReactSelect/ReactSelect";
import {iJobContactsFieldRow} from "./types";
import "./JobsContactItem.scss";

interface iJobsContactItemProps extends iJobContactsFieldRow {
  index: number;
  onRemove: (contactRowId: string) => void;
  onChangeTitle: (title: iJobContactsFieldRow["title"], contactRowId: string) => void;
  onChangeLead: any;
}

export const JobsContactItem: FC<iJobsContactItemProps> = props => {
  const {person, title, lead, index, onChangeLead, uid} = props;
  const isShownLabel = index === 0;

  const isErrorTitle = !!title?.errors?.length;
  const errorClassName = isErrorTitle ? "error" : "";

  return (
    <div className={`JobsContactItem ${errorClassName}`}>
      <TagButton onRemove={() => props?.onRemove(props.uid)}>{getName(person)}</TagButton>
      <div>
        {isShownLabel && <label className="label">Title</label>}
        <ReactSelect
          value={title}
          onChange={title => props.onChangeTitle(title, uid)}
          getOptionLabel={option => option.name}
          options={person?.titles || []}
        />
        {isErrorTitle &&
          title?.errors?.map((error: string) => (
            <span className="JobsContactItem-error" key={error}>
              {error}
            </span>
          ))}
      </div>
      <div>
        {isShownLabel && <label className="label">Lead</label>}
        <RadioField
          inputProps={{
            onChange: e => onChangeLead(e, uid)
          }}
          value={lead.value}
          fields={[{name: "lead", value: uid, label: ""}]}
        />
      </div>
    </div>
  );
};
