import {useLayoutEffect, useState} from "react";
import {trackApi} from "api/budget/track";
import {useHttp} from "hooks/httpServices/useHttp";
import {useLocation, useParams} from "react-router-dom";
import {tExpenseList} from "api/budget/track/types";
import {routes} from "router/routes";

export const useExpenseLIst = () => {
  const {id} = useParams();
  const [expenseList, setExpenseList] = useState<tExpenseList>();
  const {call, isLoading} = useHttp();
  const {pathname} = useLocation();
  const expPage = routes.jobs.details.budget?.expenseList;

  useLayoutEffect(() => {
    setExpenseList(undefined);
    if (!id || !pathname?.includes(expPage)) return;
    getExpenseList();
  }, [pathname]); // eslint-disable-line

  const getExpenseList = () => {
    if (!id) return;
    call(trackApi.getExpenseList(+id)).then(res => setExpenseList(res?.data?.data));
  };

  const lineNumbersList = () => {
    const linesList = expenseList?.list?.map(item => {
      return {
        id: item?.cost_value?.id?.toString(),
        value: item?.cost_value?.id,
        label: `${item?.cost_value?.code}${item?.cost_value?.suffix} ${item.cost_value?.name}`,
        name: `${item?.cost_value?.code}${item?.cost_value?.suffix} ${item.cost_value?.name}`,
        code: +item?.cost_value?.code
      };
    });

    return linesList?.sort((a, b) => a.code - b.code) || [];
  };

  const filterList = () => {
    const list: ReturnType<typeof lineNumbersList> = [];

    lineNumbersList()?.forEach(item => {
      if (list?.find(fi => fi?.id === item?.id)) return;
      list?.push(item);
    });

    return list;
  };

  return {
    expenseList,
    setExpenseList,
    isLoading,
    lineNumberList: lineNumbersList(),
    filterList: filterList(),
    getExpenseList
  };
};
