import {FC} from "react";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {PdfViewer} from "components/shared/PdfViewer/PdfViewer";
import {useContextAdvance} from "../context";

import "./AdvanceInvoicePreview.scss";

export const AdvanceInvoicePreview: FC = () => {
  const {jobDetailsData} = useContextJobDetails();
  const {previewFileData} = useContextAdvance();

  if (!jobDetailsData || !previewFileData) return null;
  const {url} = previewFileData;

  return (
    <div className="AdvanceInvoicePreview">
      <PdfViewer blobUrl={url}   />
    </div>
  );
};
