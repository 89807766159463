export const convertDataToBoolean = <T extends Array<{ label: string; value: any }>>(list: T) => {
   const newSet: boolean[] = [];

   list.forEach(item => {
      if (Array.isArray(item.value)) {
         return newSet.push(!!item.value?.length);
      }

      list.map(item => newSet.push(!!item.value));
   });
   return newSet.includes(true);
}