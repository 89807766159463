import {CurrencyRates} from "components/shared/CurrencyRates/CurrencyRates";
import {FC} from "react";
import {useContextTrack} from "../_context/TrackContext";
import {tCurrencyRates} from "api/common/types";

type tTrackCurrencyRatesProps = {
  onClose: () => void;
  updateCallback: () => void;
};

export const TrackCurrencyRates: FC<tTrackCurrencyRatesProps> = ({onClose, updateCallback}) => {
  const {setTrackCurrRates, trackCurrList, jobId} = useContextTrack();

  const updateCurrencies = (list: tCurrencyRates[]) => {
    updateCallback();
    setTrackCurrRates(() => {
      return list;
    });
  };

  return (
    <CurrencyRates
      onClose={onClose}
      type="trackCurrency"
      customList={trackCurrList}
      updateCallback={updateCurrencies}
      jobId={jobId ? +jobId : undefined}
    />
  );
};
