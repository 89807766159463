import {createContext, FC, useContext} from "react";
import {useAdvance, tUseAdvanceReturn} from "./useAdvance";

type tAdvanceContext = tUseAdvanceReturn;
const AdvanceContext = createContext<tAdvanceContext | null>(null);
AdvanceContext.displayName = "AdvanceContext";

export const AdvanceProvider: FC = ({children}) => {
  return <AdvanceContext.Provider value={useAdvance()}>{children}</AdvanceContext.Provider>;
};

export const useContextAdvance = () => {
  const context = useContext(AdvanceContext);

  if (context === null) {
    throw new Error("<<< AdvanceContext >>> must be used within a AdvanceProvider");
  }

  return context as tAdvanceContext;
};
