import {useEffect} from "react";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useTeamFieldGroup} from "components/shared/FormComponents/TeamFieldGroup/useTeamFieldGroup";
import {iPersonDetails} from "../../../interfaces";
import {useContextContactsDetails} from "../../../../context/ContactsDetailsContext";
import {iStateOption} from "components/shared/FormComponents/TagsField/TagsField";
import {useTagsSelectGroup} from "hooks/useTagsSelectGroup";
import {companies as companiesApi} from "api/contacts/companies";
import {useCheckBoxGroupe} from "hooks/inputHooks/useCheckBoxGroupe";
import {useTagsFieldCreating} from "components/shared/FormComponents/TagsFieldCreating/useTagsFieldCreating";

interface iProps {
  person: iPersonDetails | null | undefined;
  tab: number;
}

export type tUsePeopleFormWork = ReturnType<typeof usePeopleFormWork>;
export const usePeopleFormWork = ({person, tab}: iProps) => {
  const {titles, userRelationships} = useContextContactsDetails();
  const {isLoadingWork, getAdditionalFormDataWork} = useContextContactsDetails();

  useEffect(() => {
    if (!titles.data || !userRelationships.length) {
      tab === 1 && getAdditionalFormDataWork();
    }
  }, [tab]); // eslint-disable-line

  const formData = {
    companies: useTagsSelectGroup({
      value: person?.companies,
      searchConfig: companiesApi.getList
    }),
    agencies: useTagsSelectGroup({
      value: person?.agencies?.map(({id, name}) => ({
        id,
        name
      })),
      searchConfig: companiesApi.getList
    }),
    titles: useTagsFieldCreating({
      isRequired: false,
      value: person?.titles.map(({id, name}) => ({id: String(id), name}))
    }),
    rate: useTextInput({
      value: person?.rate ?? "",
      isRequired: false
    }),
    team: useTeamFieldGroup({
      isRequired: false,
      team:
        person?.team?.map(({user: {id, first_name, last_name}, user_relationship}) => ({
          id,
          first_name,
          last_name,
          user_relationship_id: user_relationship.id,
          user_relationship_name: user_relationship.name
        })) ?? [],
      relationships: userRelationships
    }),
    billing_default: useCheckBoxGroupe({
      isRequired: false,
      initValue: person?.billing_default?.map(item => item?.id)
    })
  };

  const isChanged = Object.values(formData).some(field => field.isChanged);
  return {
    formData,
    titlesOptions: titles.data?.data?.data.map(({id, name}: {id: number; name: string}) => ({
      id: String(id),
      name
    })) as iStateOption[],

    isLoading: isLoadingWork,
    person,

    isChanged
  };
};
