import {CSSProperties, FC, useState} from "react";
import {useChangeExpenseValue} from "../useChangeExpenseValue";
import {InputCell} from "components/shared/InputCell/InputCell";

type tNoteProps = {
  className?: string;
  id: number;
  value: string;
  style?: CSSProperties;
};

export const Note: FC<tNoteProps> = ({className = "", id, value: initValue, style}) => {
  const [value, setValue] = useState(initValue || "");
  const {isLoading, onChange} = useChangeExpenseValue({
    id: id,
    type: "note",
    errorCallback: () => setValue(initValue || "")
  });

  return (
    <InputCell
      initValue={initValue || ""}
      value={value}
      setValue={setValue}
      isLoading={isLoading}
      placeholder="Note"
      callBack={val => onChange(val)}
      className={`${className} Note`}
      alwaysShowPlaceholder
      withBorder
      style={style}
    />
  );
};
