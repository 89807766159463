import {FC} from "react";
import {tCrewContact} from "api/jobCrewGroup/types";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {iCompaniesContact} from "api/contacts/types";
import {CrewTableCell} from "../CrewTableCell/CrewTableCell";
import {RowNameAgent} from "../CrewTableCells/RowName/RowNameAgent";
import {RowPhones} from "../CrewTableCells/RowPhones/RowPhones";
import {RowEmails} from "../CrewTableCells/RowEmails/RowEmails";
import {RowActions} from "../CrewTableCells/RowActions/RowActions";
import {useContextJobCrew} from "../../../JobCrewContext";

type tCrewTableRowAgentProps = {
  agent: iCompaniesContact;
  crewContact: tCrewContact;
  groupId: number;
};

export const CrewTableRowAgent: FC<tCrewTableRowAgentProps> = ({agent, crewContact, groupId}) => {
  const {widthStyles} = useContextJobCrew();
  const agentCell = (
    <TextComponent weight="500" size="10" uppercase color="black-10" className="agentPosition">
      Agent
    </TextComponent>
  );

  const cells = [
    {cell: <RowNameAgent agent={agent} />},
    {cell: agentCell},
    {cell: null},
    {cell: null},
    {cell: null},
    {cell: <RowPhones crewContact={crewContact} groupId={groupId} isAgent />},
    {cell: <RowEmails crewContact={crewContact} groupId={groupId} isAgent />},
    {cell: null},
    {cell: null},
    {cell: null},
    {cell: <RowActions crewContact={crewContact} typeRow="agent" groupId={groupId} />}
  ];

  return (
    <tr className="CrewTableRowAgent">
      {cells.map((cell, i) => {
        const width = widthStyles(i)?.width ? widthStyles(i)?.width + "px" : undefined;
        return (
          <CrewTableCell key={i} className="agentsCell" style={{maxWidth: width, minWidth: width}}>
            {cell?.cell}
          </CrewTableCell>
        );
      })}
    </tr>
  );
};
