import {FC, ReactNode} from "react";
import {Button} from "components/shared/Button/Button";
import {useContextApp} from "context";
import "./AccountLayoutTab.scss";

interface iAccountLayoutTab {
  title: string;
  children: ReactNode;
  withFooter?: boolean;
  isLoading?: boolean;
}

/**
 *
 * @param withFooter
 * @param title
 * @param children
 * @constructor
 */

export const ACCOUNT_FORM_ID = "account_form_id";

export const AccountLayoutTab: FC<iAccountLayoutTab> = ({
  withFooter = true,
  title,
  children,
  isLoading
}) => {
  const {setAccountModal} = useContextApp();
  return (
    <div className="AccountLayoutTab">
      <div className="AccountLayoutTab-header">
        <h2>{title}</h2>
      </div>
      <div className="AccountLayoutTab-body">{children}</div>
      {withFooter && (
        <div className="AccountLayoutTab-footer">
          <Button
            size="md"
            onClick={() => setAccountModal(false)}
            className="AccountLayoutTab-footer-cancel"
          >
            Cancel
          </Button>
          <Button size="md" color="light" type="submit" form={ACCOUNT_FORM_ID} loading={isLoading}>
            save
          </Button>
        </div>
      )}
    </div>
  );
};
