import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {getDateFormat} from "helpers/dates";
import {useHttp} from "hooks/httpServices/useHttp";
import {SyncData} from "pages/Jobs/JobDetails/common/SyncData/SyncData";
import {useContextTrack} from "../../_context/TrackContext";
import {useSyncData} from "hooks/useSyncData";
import {trackApi} from "api/budget/track";
import {useParams} from "react-router-dom";

export const TrackSync = () => {
  const {id} = useParams();
  const {trackData, setTrackData} = useContextTrack();
  const {call, isLoading} = useHttp({cancelRequest: false});

  useSyncData({callBack: syncTrack, deps: [trackData]});

  async function syncTrack() {
    if (!id) return;

    try {
      const date = trackData?.updater_info?.updater_at || new Date();
      const time = getDateFormat(date, "timestampWithUTC");
      const res = await call(trackApi.syncEstimateList(+id, time));
      res?.data?.data && setTrackData(res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  }
  return (
    <SyncData
      name={trackData?.updater_info?.updater?.name || ""}
      updateCallback={syncTrack}
      isLoading={isLoading}
    />
  );
};
