import {FC} from "react";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {useStatusBarLayout} from "./useStatusBarLayout";
import {ModalPreviewPdf} from "components/shared/ModalPreviewPdf/ModalPreviewPdf";
import {PdfViewer} from "components/shared/PdfViewer/PdfViewer";
import {Loading} from "components/shared/Loading/Loading";
import {InvoiceSend} from "./InvoiceSend/InvoiceSend";
import {InvoicePay} from "./InvoicePay/InvoicePay";
import {useContextInvoice} from "../context/InvoiceContext";
import moment from "moment/moment";
import {prepareName} from "helpers/fileNaming";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";

import "./StatusBarLayout.scss";

export const StatusBarLayout: FC = () => {
  const {invoice} = useContextInvoice();
  const {jobDetailsData} = useContextJobDetails();
  const {
    toggleMenu,
    modal,
    pdf,
    isLoading,
    onSaveToDropbox,
    isSavingToDropbox,
    onShowPreview,
    isSaving,
    toggleModal,
    anchor,
    onSave
  } = useStatusBarLayout();

  const getDate = (d: Date | string) => moment(d).format("MMM DD, YYYY");

  const statusRender: any = {
    0: null,
    1: (
      <>
        <Icon icon="sent" size="md" color="tangerine" />
        <span className="StatusBarLayout-sent">Sent</span>
        <span className="StatusBarLayout-date">
          {invoice?.sent_date && getDate(invoice?.sent_date)}
        </span>
        <Button size="sm" color="outline" onClick={() => toggleModal("pay")}>
          Mark as paid
        </Button>
      </>
    ),
    2: (
      <>
        <Icon className="paid-icon" icon="check" size="sm" color="white" />
        <span className="StatusBarLayout-paid">Paid</span>
        <span className="StatusBarLayout-date">
          {invoice?.paid_date && getDate(invoice?.paid_date)}
        </span>
      </>
    )
  };

  const isSent = !!invoice?.sent_date;
  const isPaid = !!invoice?.paid_date;

  const fileName = prepareName(
    [moment().format("YYYYMMDD"), jobDetailsData?.name || "", String(jobDetailsData?.id) || ""],
    "_INVOICE.pdf"
  );

  return (
    <>
      <div className="StatusBarLayout">
        {/* <div className="StatusBarLayout-left">
          {invoice && <div className="StatusBarLayout-status">{statusRender[invoice.status]}</div>}

          {!isSent && !isPaid && (
            <>
              <Button onClick={toggleMenu} size="sm" color="outline">
                mark as <Icon icon={!!anchor ? "triangle-up" : "triangle-down"} size="xsm" />
              </Button>
              <DropdownMenu
                className="AdvanceStatusLine-markMenu"
                anchor={anchor}
                onClose={() => toggleMenu(null)}
              >
                <Button
                  width="fullWidth"
                  justifyContent="justifyContentStart"
                  size="sm"
                  onClick={() => toggleModal("send")}
                >
                  sent
                </Button>
                <Button
                  width="fullWidth"
                  justifyContent="justifyContentStart"
                  size="sm"
                  onClick={() => toggleModal("pay")}
                >
                  paid
                </Button>
              </DropdownMenu>
            </>
          )}
        </div> */}

        <div className="StatusBarLayout-buttonGroup" style={{marginLeft: "auto"}}>
          {(!isPaid || !isSent) && (
            <Button loading={isSaving} onClick={onSave} size="sm" color="outline">
              save
            </Button>
          )}
          <Button onClick={onShowPreview} size="sm" color="outline">
            preview
          </Button>
        </div>
      </div>

      {modal === "preview" && (
        <ModalPreviewPdf
          saveOnDropbox={onSaveToDropbox}
          isUploadingDropbox={isSavingToDropbox}
          pdfUrl={pdf?.url}
          onClose={() => toggleModal(null)}
          fileName={fileName}
        >
          {isLoading ? <Loading type="inBlock" /> : <PdfViewer blobUrl={pdf?.url} />}
        </ModalPreviewPdf>
      )}

      <InvoiceSend show={modal === "send"} onClose={() => toggleModal(null)} />
      <InvoicePay show={modal === "pay"} onClose={() => toggleModal(null)} />
    </>
  );
};
