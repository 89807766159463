import {ChangeEvent, useState} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {parseCsv, tParseCsvType} from "helpers/parsers/parseCsv";
import {parseVcf} from "helpers/parsers/parseVcf";
import {locationApi} from "api/location";

import {eventBus} from "EventBus/EventBus";

interface iUseImportModal {
  onClose: () => void;
  onUpdateList?: () => void;
  type: tParseCsvType;
}

export const useImportModal = ({onClose, onUpdateList, type}: iUseImportModal) => {
  const {call} = useHttp();

  const [locations, setLocations] = useState<any[]>([]);
  const [filename, setFilename] = useState("");
  const [sizeWarning, setSizeWarning] = useState("");
  const [resultStatus, setResultStatus] = useState<{
    status: "success" | "error" | null;
    message: JSX.Element | string;
  }>({
    status: null,
    message: ""
  });

  const parsers = {
    "text/csv": parseCsv,
    "text/x-vcard": parseVcf
  };

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any | null>(null);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => getCsvFromFile(event);

  const getCsvFromFile = (event: ChangeEvent<HTMLInputElement>) => {
    setErrors(null);
    setResultStatus({message: "", status: null});
    const file: File | undefined = event.target.files?.[0];
    if (!file) return new Error("File doesn't exist");
    const reader: FileReader = new FileReader();
    const fileParser: Function = parsers[file?.type as keyof typeof parsers];
    setFilename(file.name);

    reader.onload = e => {
      const nextArray: {
        data: any[];
        errorMessage: string;
        errors: any;
      } = fileParser?.(e?.target?.result as string, type);
      const status = nextArray?.data?.length ? "success" : "error";
      setResultStatus({status, message: nextArray?.errorMessage || statusMessages[status]});
      let chunks: any[][] = [];
      while (nextArray?.data.length > 0) {
        const chunk = nextArray?.data.splice(0, 50);
        chunks = [...chunks, chunk];
      }
      if (nextArray?.errors && !!Object.keys(nextArray?.errors).length) {
        setErrors(nextArray?.errors);
      }
      // if (chunks?.length > 1) {
      //   setSizeWarning("The import file is too big. Importing can take some time.");
      // }
      setLocations(chunks);
      event.target.value = "";
    };
    reader.readAsText(file);
  };

  const onImport = async () => {
    setIsLoading(true);
    let currentChunk: any = null;
    try {
      for await (const [index, value] of locations.entries()) {
        currentChunk = value;
        await call(locationApi.importLocations(value));
        setProgress(Math.ceil((100 / locations.length) * (index + 1)));
      }
      eventBus.dispatch("showToast", {text: "Contacts were imported successfully"});
      handleClose();
    } catch (error: any) {
      if (error?.response?.status === 500) {
        eventBus.dispatch("showToast", {type: "error", text: error?.response?.statusText});
        handleClose();
      } else {
        // mostly works for 422 code
        // and happen when file has some problems with data
        const errors: JSX.Element[] = Object.entries(error?.response?.data?.errors).map(
          ([key, value]) => {
            const index = key.split(".")[0];
            const contactRecord = currentChunk ? currentChunk[index] : "";
            let name =
              contactRecord &&
              (contactRecord?.name ||
                contactRecord?.first_name ||
                "" + contactRecord?.last_name ||
                "");
            return (
              <p>
                Record with name <strong>{name}</strong> has error: {value}
              </p>
            );
          }
        );
        setResultStatus({status: "error", message: <div>{errors}</div>});
        eventBus.dispatch("showToast", {type: "error", text: error?.response?.data?.message});
      }
      setLocations([]); // reset parsed data
      setFilename(""); // reset uploaded file
      setSizeWarning("");
    } finally {
      setIsLoading(false);
      setProgress(0);
      onUpdateList?.();
    }
  };

  const handleClose = () => {
    setLocations([]);
    setFilename("");
    setResultStatus({message: "", status: null});
    setSizeWarning("");
    onClose();
    setProgress(0);
    setErrors(null);
  };

  return {
    handleClose,
    onImport,
    locations,
    setLocations,
    onChange,
    isLoading,
    statusMessages,
    filename,
    resultStatus,
    sizeWarning,
    setSizeWarning,
    progress,
    setProgress,
    errors,
    setErrors
  };
};

const statusMessages = {
  success: "The file has been successfully parsed and prepared for upload.",
  error: "Error! Something went wrong. The file wasn't uploaded."
};
