import {FC, FormEvent} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {notValidForm} from "helpers/misc";
import {useContextJobCrew} from "../../JobCrewContext";
import {useSelect} from "hooks/inputHooks";
import {Select} from "components/shared/FormComponents/Select/Select";
import {jobCrewContact} from "api/jobCrewContact";
import {eventBus} from "EventBus/EventBus";

type tMoveContactsProps = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MoveContacts: FC<tMoveContactsProps> = ({setModal}) => {
  const {jobCrewGroupList: {crewGroupList, setCrewGroupList}, selectedContacts} = useContextJobCrew(); // prettier-ignore
  const FORM_ID = "move_contact_form_id";
  const {call, isLoading} = useHttp();

  const form = {
    groups: useSelect({
      options: crewGroupList?.map(item => ({value: item?.id, label: item?.name})),
      placeholder: "Select..."
    })
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (notValidForm(form) || !selectedContacts?.length) return;

    try {
      const contacts = selectedContacts?.map(item => +item);
      const res = await call(
        jobCrewContact.moveContactToGroup({contacts, group_id: +form.groups.value})
      );

      setCrewGroupList(() => res?.data?.data);
      setModal(false);
      eventBus.dispatch("showToast", {text: res?.data?.message || "Success!"});
    } catch (error) {
      const {msg} = apiErrorHandler(error, form);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <ConfirmWindow
      show={true}
      title="Move to group?"
      cancelBtn={{text: "Cancel", type: "button"}}
      onClose={() => setModal(false)}
      confirmBtn={{text: "Move contacts", type: "submit", form: FORM_ID}}
      isLoading={isLoading}
    >
      <form onSubmit={onSubmit} id={FORM_ID}>
        <Select
          {...form.groups.inputProps}
          errors={form.groups.errors}
          canSelectEmpty={true}
          className="mb-40"
        />
      </form>
    </ConfirmWindow>
  );
};
