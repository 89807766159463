import {FC} from "react";
import {tEstimateRowData} from "api/budget/estimate/types";
import {EstimateRowINput} from "../../_common/EstimateRowInput/EstimateRowINput";
import {useHttp} from "hooks/httpServices/useHttp";
import {estimateApi} from "api/budget/estimate";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {useContextJobBudget} from "../../../_context/JobBudgetContext";
import "./EstimateRowCost.scss";

type tEstimateRowCostProps = {
  currItem: tEstimateRowData;
};

export const EstimateRowCost: FC<tEstimateRowCostProps> = ({currItem}) => {
  const {setEstimate} = useContextJobBudget();
  const {call, isLoading} = useHttp();

  const onSubmit = async (value: string) => {
    try {
      const res = await call(estimateApi.updateLine(currItem.id, {cost: +value}));
      setEstimate(() => res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <div className="EstimateRowCost">
      <EstimateRowINput
        defaultValue={currItem?.cost ? String(currItem?.cost) : ""}
        onBlurCallBack={onSubmit}
        placeholder={"0.00"}
        currency={"USD"}
        isLoading={isLoading}
      />
    </div>
  );
};
