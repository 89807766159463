import {tCrewContact, tCrewGroupContact} from "api/jobCrewGroup/types";

type tArgs = {
  prev: tCrewGroupContact[];
  groupId: number;
  crewContactId: number;
  updatedContact: tCrewContact;
};

export const updateContact = ({prev, groupId, crewContactId, updatedContact}: tArgs) => {
  const newList = prev.map(item => {
    if (item?.id === groupId && !!item?.contacts?.length) {
      item.contacts = item.contacts?.map(item => {
        if (item?.id === crewContactId) {
          item = updatedContact;
        }
        return item;
      });
    }
    return item;
  });

  return newList;
};
