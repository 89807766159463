import {Dispatch, FC, SetStateAction} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {FormWrap} from "components/shared/FormWrap/FormWrap";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {useAdvanceBankModal} from "./useAdvanceBankModal";
import {AdvanceModalFormList} from "../../AdvanceModalFormBody/AdvanceModalFormList";
import {TextareaField} from "components/shared/FormComponents/TextareaField/TextareaField";
import {tBankDetails} from "api/budget/bankDetails/types";
import {Loading} from "components/shared/Loading/Loading";

import "./AdvanceBankModal.scss";

interface iAdvanceBankModalProps {
  bankList: tBankDetails[];
  setBankList: Dispatch<SetStateAction<tBankDetails[]>>;
  onClose: () => void;
  isLoading?: boolean;
}

export const AdvanceBankModal: FC<iAdvanceBankModalProps> = ({
  bankList,
  setBankList,
  isLoading,
  onClose
}) => {
  const {
    modal,
    editBankQuery,
    deleteBankQuery,
    createBankQuery,
    onShowEdit,
    onShowDelete,
    formData,
    showModal,
    onDelete,
    handleSubmit,
    activeBank
  } = useAdvanceBankModal({setBankList});

  const showFormWrap = modal === "add" || modal === "edit";

  return (
    <>
      <ModalPopup
        size="w-528"
        show={true}
        onClose={onClose}
        className="AdvanceBankModal"
        withoutPaddings
        showCloseButton={!modal}
      >
        {isLoading && <Loading type="inBlock" />}
        {!modal && (
          <AdvanceModalFormList
            onEdit={onShowEdit}
            onDelete={onShowDelete}
            onAdd={showModal}
            data={bankList}
            title={!!bankList?.length ? "Edit bank details" : "Bank details"}
            btnText="bank details"
          />
        )}

        {showFormWrap && (
          <FormWrap
            headerTitle={modal === "add" ? "Add bank details" : "Edit bank details"}
            idForm="bankInfoForm"
            type="titleCenterAndOneBtn"
            submitTitle="Save"
            isLoading={editBankQuery?.isLoading || createBankQuery?.isLoading}
            onBack={() => showModal(null)}
          >
            <form id="bankInfoForm" onSubmit={handleSubmit}>
              <InputField
                {...formData.name.inputProps}
                errors={formData.name.errors}
                label="Name"
              />
              <TextareaField
                {...formData.details.inputProps}
                errors={formData.details.errors}
                label="BANK DETAILS"
              />
            </form>
          </FormWrap>
        )}
      </ModalPopup>

      <ConfirmWindow
        title="Delete Bank?"
        subTitle={`“${activeBank?.name}” will be permanently deleted.`}
        warning="This can’t be undone."
        show={modal === "delete"}
        onClose={() => showModal(null)}
        onConfirm={onDelete}
        isLoading={deleteBankQuery?.isLoading}
        confirmBtn={{
          text: "Delete",
          color: "red"
        }}
        cancelBtn={{
          text: "cancel"
        }}
      />
    </>
  );
};
