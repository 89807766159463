import {Loading} from "components/shared/Loading/Loading";
import React from "react";
import "./InputField.scss";

export interface InterfaceInputField extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  errors?: string[];
  note?: string;
  className?: string;
  refProp?: React.Ref<HTMLInputElement>;
  typeField?: "horizontalLabel";
  suffix?: string;
  prefix?: string;
  isLoading?: boolean;
  inputPrefixShadow?: (value: string) => string;
}

export const InputField: React.FC<InterfaceInputField> = ({
  label,
  errors = [],
  className = "",
  note,
  refProp,
  typeField = "",
  suffix = "",
  isLoading,
  prefix = "",
  inputPrefixShadow,
  ...props
}) => {
  return (
    <div className={`InputField ${className} ${!!errors.length ? "error" : ""} ${typeField}`}>
      {label && (
        <label className="InputField-label label" htmlFor={props?.id}>
          {label}
        </label>
      )}

      {!suffix && !prefix && !inputPrefixShadow && (
        <input ref={refProp} {...props} className="InputField-input input" />
      )}

      {!!suffix && (
        <div className={`inputWrap`}>
          {isLoading && <Loading type="inBlock" bgColor="grey-40" customSize={0.5} />}
          <span className="inputShadow">
            {props?.value}
            {/*//FIXME need to improve */}
            {typeof props?.value !== "undefined" && props?.value !== null && props?.value !== ""
              ? suffix
              : ""}
          </span>
          <input ref={refProp} {...props} className="InputField-input input" />
        </div>
      )}

      {!!inputPrefixShadow && (
        <div className="inputWrap prefix">
          {isLoading && <Loading type="inBlock" bgColor="grey-40" customSize={0.5} />}
          <span className="inputShadow">{inputPrefixShadow(props?.value?.toString() || "")}</span>
          <input ref={refProp} {...props} className="InputField-input input" />
        </div>
      )}

      {!!prefix && (
        <div className="inputWrap prefix">
          {isLoading && <Loading type="inBlock" bgColor="grey-40" customSize={0.5} />}
          <span className="inputShadow">
            {!!props?.value && prefix}
            {props?.value && props?.value}
          </span>
          <input ref={refProp} {...props} className="InputField-input input" />
        </div>
      )}

      {errors.map((error, i) => (
        <span key={i} className="InputField-error-message error">
          {error}
        </span>
      ))}
      {note && <p className="InputField-note note">{note}</p>}
    </div>
  );
};
