import {ChangeEvent, forwardRef, ReactNode, useRef, useState} from "react";
import {Loading} from "components/shared/Loading/Loading";
import {useTrackLineInput} from "./useTrackLineInput";
import "./TrackLineInput.scss";

type tTrackLineInputProps = Omit<
  ReturnType<typeof useTrackLineInput>,
  "inputRef" | "showInputField"
> & {
  isLoading: boolean;
  className?: string;
  placeholder?: string;
  children?: ReactNode;
  as?: "input" | "textarea";
  autoResize?: boolean;
};

export const TrackLineInput = forwardRef<HTMLInputElement, tTrackLineInputProps>((props, ref) => {
  const {onChange, onKeyPress, onFocus, children, as, autoResize} = props;
  const {value, showInput, isLoading, className = "", placeholder = ""} = props;
  const inputRef = useRef<any>();
  const [width, setWidth] = useState<number | null>();
  const Component = as || "input";

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    autoResize && setWidth(e.target.value.length);
    onChange(e);
  };

  return (
    <>
      {isLoading && <Loading type="inBlock" bgColor="grey-40" customSize={0.5} />}

      {showInput && (
        <div
          className={`TrackLineInput ${className} ${!!children ? "withSelect" : ""}`}
          onFocus={onFocus}
          ref={ref}
          style={{width: width && autoResize ? `calc(${width}ch + 124px)` : "auto"}}
        >
          <Component
            ref={inputRef}
            className="TrackLineInput-input"
            value={value}
            onChange={onChangeHandler}
            onKeyPress={onKeyPress}
            placeholder={placeholder}
          />

          {children}
        </div>
      )}
    </>
  );
});
