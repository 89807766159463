import {FC, useState, ReactNode, useRef, useEffect} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {ReactSelect} from "components/shared/FormComponents/ReactSelect/ReactSelect";
import {tUseJobTagsFieldReturn} from "./useJobTagsField";
import {components, ControlProps, MenuPlacement} from "react-select";

import "./JobTagsField.scss";

interface iJobTagsFieldProps extends tUseJobTagsFieldReturn {
  title?: string;
  menuPlacement?: MenuPlacement;
  tagsRow?: ReactNode;
  valueKey?: string;
  labelKey: string;
  addButtonText?: string;

  alwaysShowSelect?: boolean;
  selectPlaceholder?: string;
  withSearchIcon?: boolean;
  tagsPosition?: "bottom";
  id?: string;
  blockAddButton?: boolean;
}

export const JobTagsField: FC<iJobTagsFieldProps> = ({
  title,
  menuPlacement,
  tagsRow,
  labelKey,
  valueKey = "id",
  addButtonText,

  alwaysShowSelect,
  selectPlaceholder,
  withSearchIcon,
  tagsPosition = "",
  blockAddButton = false,
  ...props
}) => {
  const {onChange, errors, options, value, handleInputChange, isLoading} = props;

  const [showSelect, setShowSelect] = useState(!!alwaysShowSelect);

  const handleChange = (option: any) => {
    if (option) !alwaysShowSelect && setShowSelect(false);
    onChange(option);
  };
  const isErrorClassName = !!errors && errors.length > 0 ? "error" : "";

  return (
    <div className={`JobTagsField ${isErrorClassName} ${tagsPosition}`} id={props?.id}>
      {title && <label className="label">{title}</label>}

      {tagsRow}

      {showSelect && (
        <ReactSelect
          isMulti
          value={value}
          options={options}
          onChange={handleChange}
          components={withSearchIcon ? {Control, MultiValue} : {MultiValue}}
          onInputChange={handleInputChange}
          getOptionLabel={item => item[labelKey]}
          getOptionValue={item => item[valueKey]}
          isLoading={isLoading}
          menuPlacement={menuPlacement}
          placeholder={selectPlaceholder}
          hideSelectedOptions
          isClearable={false}
        />
      )}

      {!showSelect && !blockAddButton && (
        <Button color="outline" size="sm" onClick={() => setShowSelect(prev => !prev)}>
          <Icon icon="add" size="xsm" />
          {addButtonText}
        </Button>
      )}

      {!!errors?.length &&
        errors.map(error => (
          <span key={error} className="JobTagsField-error">
            {error}
          </span>
        ))}
    </div>
  );
};

const MultiValue = ({selectProps, index}: any) => {
  const {placeholder, inputValue} = selectProps;
  if (index > 0 || inputValue) return <span />;
  return <span className="JobTagsField-MultiValue-placeholder">{placeholder}</span>;
};

const Control = ({children, ...props}: ControlProps<any, false>) => {
  return (
    <components.Control {...props}>
      <span className="SearchField-searchIcon">
        <Icon icon="search" />
      </span>
      {children}
    </components.Control>
  );
};
