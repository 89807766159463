import {FC, TextareaHTMLAttributes} from "react";
import "./TextareaField.scss";

interface iTextareaField extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  errors?: string[];
  className?: string;
}

export const TextareaField: FC<iTextareaField> = ({
  className = "",
  label,
  errors = [],
  ...props
}) => {
  const errorClassname = !!errors?.length ? "error" : "";
  return (
    <div className={`TextareaField ${errorClassname} ${className}`}>
      {label && <label className="TextareaField-label label">{label}</label>}
      <textarea {...props} className="TextareaField-input input" />
      {errors.map((error, index) => (
        <span key={index} className="TextareaField-error-message">
          {error}
        </span>
      ))}
    </div>
  );
};
