import {ChangeEvent, useState} from "react";
import {AxiosRequestConfig} from "axios";
import {useHttp} from "hooks/httpServices/useHttp";
import {getApiError} from "helpers/getApiError";
import {eventBus} from "EventBus/EventBus";

interface iUseFavoriteCellProps {
  addConfig: AxiosRequestConfig;
  removeConfig: AxiosRequestConfig;
  in_favorite: boolean;
  refetch?: boolean;
}

export const useFavoriteCell = (props: iUseFavoriteCellProps) => {
  const {addConfig, removeConfig, in_favorite} = props;
  const {call, isLoading} = useHttp();
  const [isFavorite, setIsFavorite] = useState(in_favorite);

  const handleOnClick = async (event: ChangeEvent<EventTarget>) => {
    event.stopPropagation();

    try {
      const res = await call(isFavorite ? removeConfig : addConfig);
      setIsFavorite(prev => !prev);
      eventBus.dispatch("showToast", {text: res?.data?.message || "Success!"});
      props?.refetch && eventBus.dispatch("favoritesRefetch");
    } catch (e) {
      const {msg} = getApiError(e);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return {
    isFavorite,
    isLoading,
    handleOnClick
  };
};
