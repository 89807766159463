import {FC, useState} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {CategorizedForm} from "./CategorizedForm";
import {tTrackCostLineFile} from "api/budget/track/types";
import {useCategorizedForm} from "./useCategorizedForm";
import {useSubmitCategorizedForm} from "./useSubmitCategorizedForm";
import {tViewersFile} from "components/Viewers/types";
import {ViewersWrap} from "components/Viewers/ViewersWrap/ViewersWrap";
import "./Categorized.scss";

type tCategorizedProps = {
  onClose: () => void;
  files: tTrackCostLineFile[];
};

export const Categorized: FC<tCategorizedProps> = ({onClose, files}) => {
  const [selectedFile, setSelectedFile] = useState<tTrackCostLineFile>(files?.[0]);
  const changeFile = (file: tViewersFile) => {
    if (!file.url || !file?.id) return console.log("file not valid");
    setSelectedFile({file_url: file.url, id: file?.id, file_name: file?.name});
  };

  const form = useCategorizedForm();
  const {onSubmit, isLoading} = useSubmitCategorizedForm({
    form,
    currFile: selectedFile,
    onClose,
    files,
    setSelectedFile
  });

  const preparedFile = ViewersWrap.formatFromTrack(selectedFile);
  return (
    <ModalPopup size="w-1230" show={true} onClose={onClose} className="Categorized" withoutPaddings>
      <CategorizedForm
        currFile={selectedFile}
        form={form}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />

      <ViewersWrap>
        <ViewersWrap.Main>
          <ViewersWrap.Viewer
            file={preparedFile}
            onDeleteBtn={<ViewersWrap.DeleteBtn file={selectedFile} typeFile="expenseFile" />}
          />
        </ViewersWrap.Main>

        {!!files?.length && (
          <ViewersWrap.Footer>
            <ViewersWrap.FileNavigation
              files={files?.map(file => ViewersWrap.formatFromTrack(file))}
              selectedFile={preparedFile}
              changeFile={changeFile}
            />
          </ViewersWrap.Footer>
        )}
      </ViewersWrap>

      {/* <EditFile
        // onSaveChanges={onSaveFile}
        files={files}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        total={0}
        typeFile="expenseFile"
      /> */}
    </ModalPopup>
  );
};
