import {forwardRef} from "react";
import {iJobReturn} from "api/jobs/types";
import {getDateFormat, getDaysBetween, isOverdue} from "helpers/dates";
import "./JobPipeline.scss";

interface iJobPipeline {
  data: iJobReturn;
}
export const JobPipeline = forwardRef(({data}: iJobPipeline, ref: React.ForwardedRef<any>) => {
  const {name, id, pipeline, start_at, end_at} = data;

  const renderDates = () => {
    // if (isOverdue(end_at)) return <div>{isOverdue(end_at)} days overdue</div>;
    if (pipeline?.name.includes("To Client")) return <div>{getDateFormat(end_at, "L")}</div>;
    if (pipeline?.name.includes("W/")) {
      const date = getDaysBetween({start: start_at, end: end_at});
      return (<div>Day {date.currDay || 0} of {date.totalDays || 0}</div>); // prettier-ignore
    }
  };

  return (
    <div className="JobPipeline" ref={ref}>
      <div className="JobPipeline--name-wrapper">
        <span className="JobPipeline--id">{id}</span>
        <span className="JobPipeline--name">{name}</span>
      </div>

      <div className="JobPipeline--status-wrapper">
        {pipeline?.name && <span className="JobPipeline--status">{pipeline?.name}</span>}
        {/* <span className={`JobPipeline--progress ${isOverdue(end_at) ? "overdue" : ""}`}> */}
        <span className={`JobPipeline--progress`}>
          {renderDates()}
        </span>
      </div>
    </div>
  );
});
