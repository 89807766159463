import React, {FC, ReactNode, useState} from "react";
import {Button} from "components/shared/Button/Button";
import {Popover} from "components/shared/Popover/Popover";
import {useLocation} from "react-router-dom";
import "./TableToolbar.scss";

interface iTableToolbar {
  titleChildren: ReactNode;
  children: ReactNode;
  typeFilter?: "popover" | "customChildren";
}

export const TableToolbar: FC<iTableToolbar> = ({
  titleChildren,
  children,
  typeFilter = "popover"
}) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };

  const {search} = useLocation();
  const regex = /\?|(per_page|page|sort_field|sort_direction)=[a-z0-9]+&?/g;
  const showNotification = search.replace(regex, "").split("&").filter(item => !!item).length; // prettier-ignore

  return (
    <div className="TableToolbar">
      <h5 className="TableToolbar-title">{titleChildren}</h5>

      {typeFilter === "customChildren" && children}
      {typeFilter === "popover" && (
        <>
          <Button onClick={showMenu} size="sm" color={showNotification ? "outline" : "light"}>
            {!!showNotification
              ? `${showNotification} Filter${showNotification > 1 ? "s" : ""} applied`
              : "Filter"}
          </Button>
          <Popover
            anchor={anchor}
            onClose={() => setAnchor(null)}
            transformOrigin={{
              horizontal: "left",
              vertical: "bottom"
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom"
            }}
          >
            {children}
          </Popover>
        </>
      )}
    </div>
  );
};
