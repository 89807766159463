// const errors: tObj = {
//   "emails.0.value": ["The emails value must be valid"],
//   "emails.0.type_id": ["The emails type_id must be valid"],
//   "emails.1.value": ["The emails 1 value must be valid"],
//   "billing_info.address.city": ["The city must be valid"],
//   "billing_info.address.region": ["The region must be valid"],
//   "address.0.city": ["The city must be valid"],
//   first_name: ["The first name field is required"]
// };

function isObject(value: any) {
  return !!(value && typeof value === "object" && !Array.isArray(value));
}

// function isDigit(val: string) {
//   return Boolean(val.match(/^[0-9]/gm));
// }

/**
 * Deep merge two objects.
 * @param target
 * @param sources
 */
export function mergeDeep(target: any, ...sources: any): any {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, {[key]: {}});
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, {[key]: source[key]});
      }
    }
  }

  return mergeDeep(target, ...sources);
}

/**
 * Parse API error and return error message and form errors.
 * @param arr
 * @param value
 */
export function createNestedObject(arr: string[], value: any) {
  let obj = {};

  arr.reduce((acc: {[key: string]: any}, val: string, index, arr) => {
    let nextValue = index === arr.length - 1 ? value : {}; // if last element, set value. value: string[]
    return (acc[val] = nextValue);
  }, obj);

  return obj;
}

/**
 * Parse API error and return error message and form errors.
 * @return {msg: string, errors: {[key: string]: string[]}}
 * @param error
 * @param formData
 */
export function apiErrorHandler(error: any, formData?: any) {
  const apiErrors = error?.response?.data?.errors;
  const errMsg = error?.response?.data?.message;

  if (!apiErrors) return {apiErrors, msg: errMsg || "Error!"};

  const isErrors = apiErrors && !!Object.keys(apiErrors).length;

  let errors: {[key: string]: any} = {};

  Object.entries(apiErrors).forEach(([key, value]) => {
    if (!key.includes(".")) {
      errors = {...errors, [key]: value};
      return;
    }

    let keyAsArr = key.split(".");
    const newErr = createNestedObject(keyAsArr, value);
    errors = mergeDeep(errors, newErr);
  });

  isErrors &&
    Object.entries(errors).forEach(([key, value]) => {
      // for nested errors
      if (isObject(value)) {
        Object.entries(value).forEach(([k, v]) => {
          formData?.[key]?.[k]?.setErrors && formData?.[key]?.[k]?.setErrors(v);
          formData?.[key]?.[k]?.setIsValid && formData?.[key]?.[k]?.setIsValid(false);
        });
        return;
      }
      formData?.[key]?.setErrors(value as string[]);
      formData?.[key]?.setIsValid && formData?.[key]?.setIsValid(false);
    });

  return {errors, apiErrors, msg: error?.response?.data?.message};
}
