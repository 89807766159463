import {ReactNode, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {downloadFileFromUrl, openOnBrowserViewer} from "../fileHelpers";
import {tViewersFile} from "../types";
import {getBaseURL} from "hooks/useApi";
import "./ViewerToolbar.scss";

type tViewerToolbar = {
  file: tViewersFile;
  children?: ReactNode;
  onDeleteBtn?: ReactNode;
  hideLeftTools?: boolean;
  hideRightSide?: boolean;
  setScale: React.Dispatch<React.SetStateAction<number>>;
  defScale: number;
  uploadButton?: ReactNode;
};

export const ViewerToolbar = ({
  file,
  onDeleteBtn,
  hideLeftTools = false,
  hideRightSide = false,
  setScale,
  defScale,
  uploadButton
}: tViewerToolbar) => {
  const [loading, setLoading] = useState(false);
  const onDownload = async () => {
    const URL_WITH_PROXY = `${getBaseURL()}asset?url=${file.url}`;
    setLoading(true);
    await downloadFileFromUrl({url: URL_WITH_PROXY});
    setLoading(false);
  };

  const onViewFull = () => {
    openOnBrowserViewer(file?.url, file?.name);
  };

  return (
    <div className="ViewerToolbar">
      {!hideLeftTools && (
        <div>
          <button onClick={() => setScale(v => v + 0.1)}>
            <Icon color="dark-gray" size="sm" icon="add" />
          </button>
          <button onClick={() => setScale(defScale)}>
            <Icon color="dark-gray" size="sm" icon="reload" />
          </button>
          <button onClick={() => setScale(v => v - 0.1)}>
            <Icon color="dark-gray" size="sm" icon="minus" />
          </button>
        </div>
      )}

      <div>
        {uploadButton}
        {!hideRightSide && (
          <>
            <button onClick={onViewFull}>View</button>
            <button onClick={onDownload} disabled={loading}>
              <Icon color="dark-gray" size="md" icon="download" />
            </button>

            {onDeleteBtn}
          </>
        )}
      </div>
    </div>
  );
};
