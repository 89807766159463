import {FC, useEffect} from "react";
import {ChapterHeader} from "../../../OverlayTemplates/ChapterHeader/ChapterHeader";
import {TextareaField} from "components/shared/FormComponents/TextareaField/TextareaField";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {DatepickerRangeSingle} from "components/shared/FormComponents/DatepickerRangeSingle/DatepickerRangeSingle";
import {parseAddress} from "helpers/misc";
import {TextComponent} from "../../../../common/TextComponent/TextComponent";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useDatepickerRangeSingle} from "components/shared/FormComponents/DatepickerRangeSingle/useDatepickerRangeSingle";
import {iJobDetailsDataReturn} from "api/jobs/types";
import {tEstimateExportInfo} from "api/budget/estimate/types";
import moment from "moment";
import {tInvoice} from "api/invoice/types";

import "./EstimateJobEdit.scss";

interface iEstimateJobEditProps {
  jobDetailsData: iJobDetailsDataReturn;
  exportInfo: tEstimateExportInfo | tInvoice;
  onSaveExportInfoCallback: (formData: any) => void;
}

export const EstimateJobEdit: FC<iEstimateJobEditProps> = ({
  jobDetailsData,
  exportInfo,
  onSaveExportInfoCallback,
  children
}) => {
  const ext = exportInfo?.extended;
  const first = ext?.[0] as {label: any; text: any};
  const second = ext?.[1] as {label: any; text: any};

  const formData = {
    job_name: useTextInput({value: exportInfo?.job_name}),
    job_number: useTextInput({value: exportInfo?.job_number}),
    po: useTextInput({value: exportInfo?.po}),
    creative: useTextInput({value: exportInfo?.creative}),
    notes: useTextInput({value: exportInfo?.notes}),
    shoot_location: useTextInput({value: exportInfo?.shoot_location}),
    photographer: useTextInput({value: exportInfo?.photographer}),
    usage_license: useTextInput({value: exportInfo?.usage_license}),
    label1: useTextInput({value: first?.label}),
    text1: useTextInput({value: first?.text}),
    label2: useTextInput({value: second?.label}),
    text2: useTextInput({value: second?.text}),
    dates: useDatepickerRangeSingle({
      value: {
        startDate: moment(exportInfo?.job_start_at || new Date()).toDate(),
        endData: moment(exportInfo?.job_end_at || new Date()).toDate()
      }
    })
  };

  const {job_name, job_number, po, creative, notes, shoot_location, photographer, usage_license, dates} = formData; // prettier-ignore
  const {label1, label2, text1, text2} = formData;
  useEffect(() => {
    onSaveExportInfoCallback(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    job_name.value,
    job_number.value,
    po.value,
    creative.value,
    notes.value,
    shoot_location.value,
    photographer.value,
    usage_license.value,
    dates.value,
    label1?.value,
    label2?.value,
    text1?.value,
    text2?.value
  ]);

  if (!exportInfo) return null;

  const clientAddress = jobDetailsData?.client?.address ? parseAddress(jobDetailsData?.client?.address).string : ""; // prettier-ignore

  return (
    <div className="EstimateJobEdit">
      <div className="EstimateJobEdit-client">
        <ChapterHeader>Client</ChapterHeader>
        <div className="row">
          <div>
            <TextComponent weight="500" size="13" uppercase>
              {jobDetailsData?.client?.name}
            </TextComponent>
            <p>{clientAddress}</p>
          </div>
        </div>
      </div>
      {children ? children : <div />}
      <div className="EstimateJobEdit-job">
        <ChapterHeader>Job Description</ChapterHeader>
        <InputField {...job_name.inputProps} name="job_name" label="Job name" />
        <InputField {...job_number.inputProps} name="job_number" label="Job number" />
        <InputField {...photographer.inputProps} name="photographer" label="Photographer" />
        <InputField {...po.inputProps} name="po" label="PO" />
        <InputField {...shoot_location.inputProps} name="shoot_location" label="Shoot location" />
        <DatepickerRangeSingle {...dates.inputProps} label="Shoot dates" withIcon />

        <div className="EstimateJobEdit-jobLabels">
          <InputField placeholder="Label" {...label1?.inputProps} />
          <InputField placeholder="Text" {...text1?.inputProps} />
          <InputField placeholder="Label" {...label2?.inputProps} />
          <InputField placeholder="Text" {...text2?.inputProps} />
        </div>
        {/*---------------------------------*/}
        <ChapterHeader>Notes</ChapterHeader>
        <TextareaField {...notes.inputProps} name="notes" />
      </div>
      <div className="EstimateJobEdit-creative">
        <ChapterHeader>Creative & usage</ChapterHeader>
        <TextareaField {...creative.inputProps} label="Creative" name="creative" />
        <TextareaField {...usage_license.inputProps} label="Usage license" name="usage_license" />
      </div>
    </div>
  );
};
