import {FC} from "react";
import moment from "moment";
import {tCalendarTemplateEvent} from "../helpers";

interface iSchedulerEventBar {
  event: tCalendarTemplateEvent;
}

interface iEventWeekBarText {
  start: Date;
  end: Date;
}

function getCalendarEventTime(date: Date | string) {
  return moment(date).format("hh:mm A").split(" ").join("");
}

export const SchedulerEventBar: FC<iSchedulerEventBar> = ({event}) => {
  return <div className="event-bar-text">{event?.name}</div>;
};

export const SchedulerBarTime: FC<iSchedulerEventBar> = ({event}) => {
  const time = getCalendarEventTime(event?.start_date);

  return (
    <div className="event-bar-time">
      <span className="event-bar-time-status" />
      <span className="event-bar-time-time">{time}</span>
      <span className="event-bar-time-text">{event?.name}</span>
    </div>
  );
};

export const EventWeekBarText: FC<iEventWeekBarText> = ({start, end}) => {
  return (
    <span className="event-weekBar-text">{`${moment(start)
      .format("hh:mm A")
      .split(" ")
      .join("")} - ${moment(end).format("hh:mm A").split(" ").join("")}`}</span>
  );
};
