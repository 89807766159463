import {FC, Fragment} from "react";
import {tScheduleRow} from "api/callSheet/types";
import {SectionHeader} from "../../CallSheetBuild/CallSheetBuildCommon/SectionHeader";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import "./CallSheetTemplateSchedule.scss";

type tCallSheetTemplateScheduleProps = {
  scheduleList: Array<tScheduleRow>;
};

export const CallSheetTemplateSchedule: FC<tCallSheetTemplateScheduleProps> = ({scheduleList}) => {
  const isEmpty = !scheduleList.filter(item => item?.some(row => row.time || row.text))?.length;

  if (isEmpty) return null;

  return (
    <div className="CallSheetTemplateSchedule">
      <SectionHeader title="Schedule" />

      <div className="CallSheetSchedule">
        {scheduleList?.map((item, row) => {
          return (
            <Fragment key={row}>
              {item.map((item, col) => {
                return (
                  <Fragment key={col}>
                    <TextComponent weight="700" color="black-10" size="15">
                      {item?.time}
                    </TextComponent>
                    <TextComponent weight="400" color="gray-10" size="15">
                      {item?.text}
                    </TextComponent>
                  </Fragment>
                );
              })}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
