import {useContextLocations} from "pages/Locations/context/LocationsContext";
import {useEffect, useState} from "react";
import {useDebounce} from "hooks/useDebounce";
import {tags} from "api/tags";
import {useHttp} from "hooks/httpServices/useHttp";

export function useLocationsTableFilter() {
  const {localesData, apiParams} = useContextLocations();
  const {call} = useHttp();
  const [search, setSearch] = useState("");

  const locArr = apiParams.preparedParams?.locales?.split(",") || [];
  const localesTags = localesData?.data?.data;

  const debounceLocales = useDebounce(search, 300);

  useEffect(() => {
    const localesRequestPayload = apiParams?.preparedParams?.locales?.split(",");
    !!localesRequestPayload?.length &&
      call(tags.locales.get({ids: localesRequestPayload.map(v => +v)}));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (debounceLocales) {
      onSearchLocales(debounceLocales);
    }
  }, [debounceLocales]); // eslint-disable-line

  const selected = () => {
    if (!locArr.length || !localesTags) return [];
    return localesTags.filter((item: any) => {
      return locArr?.find((locale: any) => Number(locale) === item.id);
    });
  };

  const onSearchLocales = (search: string) => {
    call(tags.locales.get({search, search_type: "and"}));
  };

  const filterControl = {
    value: selected(),
    onChange: (option: any) => {
      const params = {locales: option.length ? option.map((item: any) => item.id) : []};
      apiParams.setApiParams({params});
    }
  };

  const onChangeInput = (value: string) => {
    setSearch(value);
  };

  return {filterControl, options: localesData?.data?.data || [], onChangeInput};
}
