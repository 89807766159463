import {useEffect, useState} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {AxiosRequestConfig} from "axios";
import {useDebounce} from "hooks/useDebounce";
import {eventBus} from "EventBus/EventBus";

type tOptions = {
  id: number;
  name: string;
};

export type tUseJobTagsFieldReturn = ReturnType<typeof useJobTagsField>;

interface iUseJobsLocationsFieldProps<T> {
  value?: T[];
  apiConfig?: (arg?: any) => AxiosRequestConfig<any>;
  options?: T[];
  required?: boolean;
  searchKey?: "query" | "search";
  additionalParams?: {[key: string]: any};
}
// TODO when api will be ready
// add searching by location name

export function useJobTagsField<T extends tOptions>(props: iUseJobsLocationsFieldProps<T>) {
  const {isLoading, call} = useHttp();
  const [options, setOptions] = useState<T[]>(props?.options || []);
  const [value, setValue] = useState<T[]>(props?.value || []);
  const [search, setSearch] = useState("");
  const [errors, setErrors] = useState<string[]>([]);
  const [isValid, setIsValid] = useState(true);
  const [required, setRequired] = useState(props?.required !== undefined ? props?.required : true);

  const emptyErr = "This field can't be empty";

  const debounceValue = useDebounce(search, 400);

  useEffect(() => {
    if (debounceValue) {
      onSearch(debounceValue);
    }
  }, [debounceValue]); // eslint-disable-line

  const onChange = (option: any) => {
    setValue(option);
    checkValidity(option);
  };

  const onRemove = (id?: number) => {
    const newValue = value.filter((item: any) => item.id !== id);
    setValue(newValue);
    checkValidity(newValue);
  };

  const handleInputChange = (value: string) => setSearch(value);

  const onSearch = async (query: string) => {
    if (!props.apiConfig) return;
    try {
      const params = {
        ...(!!props?.additionalParams && props.additionalParams),
        ...(props?.searchKey && {[props?.searchKey]: query})
      };
      const response = await call(props.apiConfig(params));
      setOptions(response?.data?.data);
    } catch (error: any) {
      eventBus.dispatch("showToast", {type: "error", text: error?.data?.message});
    }
  };

  const checkValidity = (val?: T[]) => {
    const newValue = val === undefined ? value : val;

    if (required && !newValue.length) {
      setErrors([emptyErr]);
      setIsValid(false);
      return false;
    }
    setIsValid(true);
    setErrors([]);
    return true;
  };

  return {
    handleInputChange,
    checkValidity,
    isLoading,
    options,
    value,
    setValue,
    onChange,
    onRemove,
    setErrors,
    errors,
    required,
    setRequired,
    isValid
  };
}
