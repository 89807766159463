import {useHttp} from "hooks/httpServices/useHttp";
import {Dispatch, FormEvent, SetStateAction, useState} from "react";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {notValidForm} from "helpers/misc";
import moment from "moment/moment";
import {createDatesByInterval, tCalendarTemplateEvent} from "../../helpers";
import {tTemplateEventPayload} from "api/jobs/calendar/types";
import {jobsCalendarApi} from "api/jobs/calendar";
import {getDateFormat} from "helpers/dates";
import {useContextTemplateCalendar} from "../../context";
import {useSelect} from "hooks/inputHooks";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";

interface iUseEventEditProps {
  event: tCalendarTemplateEvent;
  setEvents: Dispatch<SetStateAction<tCalendarTemplateEvent[]>>;
  onClose: (clearId?: number) => void;
}

export function useEventEdit({event, setEvents, onClose}: iUseEventEditProps) {
  const createEventQuery = useHttp();
  const eventUpdateQuery = useHttp();
  const deleteEventQuery = useHttp();

  const {calendarInfo} = useContextTemplateCalendar();

  const [startTime, setStartTime] = useState(
    event?.start_date ? moment(event.start_date) : moment().set({hour: 8, minutes: 0}).toDate()
  );

  const [endTime, setEndTime] = useState(
    event?.end_date ? moment(event.end_date) : moment().set({hour: 17, minutes: 0}).toDate()
  );

  const [allDay, setAllDay] = useState(event?.all_day !== undefined ? event.all_day : false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const defaultDay = moment(event?.start_date).diff(moment().startOf("week"), "days") + 1;

  let formData = {
    name: useTextInput({value: event?.name}),
    start_day: useSelect({
      value: event?.start_day ? String(event?.start_day) : String(defaultDay),
      isRequired: true
    }),
    end_day: useSelect({
      value: event?.start_day ? String(event?.end_day) : String(defaultDay),
      isRequired: true
    }),
    description: useTextInput({value: event?.description || "", isRequired: false})
  };

  function setTime(time: {hours: string; minutes: string; zone: string; type: string}) {
    return {
      hours: time.zone === "pm" ? Number(time.hours) + 12 : Number(time.hours),
      minutes: Number(time.minutes),
      seconds: 0
    };
  }

  const handleSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (notValidForm(formData)) return;

    const {name, description, start_day, end_day} = formData;

    let start_time = allDay ? moment().set({hour: 0, minute: 0, second: 0}) : startTime;
    let end_time = allDay ? moment(endTime).endOf("day").add(1, "minute") : endTime;

    const payload = {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      name: name.value,
      start_day: Number(start_day.value),
      end_day: Number(end_day.value),
      start_time: getDateFormat(start_time, "timestamp"),
      end_time: getDateFormat(end_time, "timestamp"),
      ...(description && {description: description.value}),
      all_day: allDay
    };

    try {
      const {message} = event?.id ? await updateEvent(payload) : await createEvent(payload);
      eventBus.dispatch("showToast", {text: message});
      onClose();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {text: msg, type: "error"});
    }
  };

  const onChangeStartTime = (value: any) => {
    setStartTime(moment().set(setTime(value)).toDate());
  };
  const onChangeEndTime = (value: any) => {
    setEndTime(moment().set(setTime(value)).toDate());
  };

  const createEvent = async (
    payload: tTemplateEventPayload
  ): Promise<{message: string; data: any}> => {
    const {
      data: {data, message}
    } = await createEventQuery.call(jobsCalendarApi.createTemplateEvent(calendarInfo!.id, payload));
    setEvents(prev => [...prev, createDatesByInterval(data)]);
    return {data, message};
  };

  const updateEvent = async (
    payload: tTemplateEventPayload
  ): Promise<{message: string; data: any}> => {
    const {data: {data, message}} = await eventUpdateQuery.call(jobsCalendarApi.updateTemplateEvent(event.id, payload)); // prettier-ignore
    setEvents(prev =>
      prev.map(ev => {
        if (ev.id === data.id) {
          return createDatesByInterval(data);
        }
        return ev;
      })
    );
    return {data, message};
  };

  const onDeleteEvent = async () => {
    try {
      const {
        data: {message}
      } = await deleteEventQuery.call(jobsCalendarApi.deleteJobCalendarTemplateEvent(event.id));
      setEvents(events => events.filter(evt => evt.id !== event.id));
      eventBus.dispatch("showToast", {text: message});
      onClose();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {text: msg, type: "error"});
    }
  };

  return {
    formData,
    createEventQuery,
    eventUpdateQuery,
    deleteEventQuery,
    handleSubmit,
    allDay,
    setAllDay,
    startTime,
    endTime,
    onChangeStartTime,
    onChangeEndTime,
    confirmDelete,
    setConfirmDelete,
    onDeleteEvent
  };
}
