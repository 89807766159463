import {AxiosRequestConfig} from "axios";
import {tAdvanceDetailsPostData, tCreateAdvancePayload} from "./types";
import {advanceStatus} from "./advanceStatus";

const JOB = "job";
const JOB_ADVANCE = "job_advance";

export const advanceApi = {
  getAdvanceList: (jobId: string | number): AxiosRequestConfig => ({
    url: `${JOB}/${jobId}/job_advances`
  }),
  createAdvance: (data: tCreateAdvancePayload): AxiosRequestConfig => ({
    method: "POST",
    url: JOB_ADVANCE,
    data
  }),
  getAdvanceInfo: (advanceId: number | string): AxiosRequestConfig => ({
    url: `${JOB_ADVANCE}/${advanceId}`
  }),
  renameAdvance: (advanceId: number | string, data: {name: string}): AxiosRequestConfig => ({
    method: "POST",
    url: `${JOB_ADVANCE}/${advanceId}/rename`,
    data
  }),
  deleteAdvance: (advanceId: number | string): AxiosRequestConfig => ({
    method: "DELETE",
    url: `${JOB_ADVANCE}/${advanceId}`
  }),
  changeAdvanceStatus: (
    advanceId: number | string,
    data: {status: advanceStatus; date: string | number}
  ): AxiosRequestConfig => ({
    method: "POST",
    url: `${JOB_ADVANCE}/${advanceId}/status`,
    data
  }),
  updateAdvanceDetails: (
    advanceId: number | string,
    data: Partial<tAdvanceDetailsPostData>
  ): AxiosRequestConfig => ({
    method: "POST",
    url: `${JOB_ADVANCE}/${advanceId}`,
    data
  }),
  saveAdvanceToDropbox: (advanceId: number, data: {file: File}): AxiosRequestConfig => ({
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    url: `${JOB_ADVANCE}/${advanceId}/save_to_dropbox`,
    data
  })
};
