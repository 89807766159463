import {FC, ReactNode} from "react";
import {Button} from "components/shared/Button/Button";

import "./JobBudgetStatusBarLayout.scss";

interface iJobBudgetStatusBarLayoutProps {
  isApproved?: boolean;
  children?: ReactNode;
  onShowPreview?: () => void;
  onSaveToDropbox?: () => void;
  onDownloadPdf?: () => void;
  onSaveExportInfo: () => void;
  isUploadingDropbox?: boolean;
  isSaving?: boolean;
}

export const JobBudgetStatusBarLayout: FC<iJobBudgetStatusBarLayoutProps> = ({
  isApproved = false,
  onShowPreview,
  onSaveToDropbox,
  children,
  onDownloadPdf,
  onSaveExportInfo,
  isSaving,
  isUploadingDropbox
}) => {
  return (
    <div className="JobBudgetStatusBarLayout">
      <div className="JobBudgetStatusBarLayout-left">{children}</div>
      <div className="JobBudgetStatusBarLayout-right">
        {!isApproved && (
          <Button size="sm" color="outline" loading={isSaving} onClick={onSaveExportInfo}>
            save
          </Button>
        )}
        {!isApproved && (
          <Button size="sm" color="outline" onClick={onShowPreview}>
            preview
          </Button>
        )}

        <Button size="sm" color="outline" onClick={onDownloadPdf}>
          download pdf
        </Button>
        <Button loading={isUploadingDropbox} size="sm" color="outline" onClick={onSaveToDropbox}>
          save to dropbox
        </Button>
      </div>
    </div>
  );
};
