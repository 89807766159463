import {FC, useEffect, useState} from "react";
import {AdvanceBankModal} from "../JobDetails/JobBudget/Advance/modals/AdvanceBankModal/AdvanceBankModal";
import {tBankDetails} from "api/budget/bankDetails/types";
import {bankDetailsApi} from "api/budget/bankDetails";
import {useHttp} from "hooks/httpServices/useHttp";

interface iJobSettingsBankDetailsProps {
  onClose: (arg: null) => void;
}

export const JobSettingsBankDetails: FC<iJobSettingsBankDetailsProps> = ({onClose}) => {
  const bankListQuery = useHttp();
  const [bankList, setBankList] = useState<tBankDetails[]>([]);

  useEffect(() => {
    bankListQuery
      .call(bankDetailsApi.getBankDetailsList())
      .then(({data: {data}}) => setBankList(data));
  }, []); // eslint-disable-line

  return (
    <AdvanceBankModal
      bankList={bankList}
      setBankList={setBankList}
      onClose={() => onClose(null)}
      isLoading={bankListQuery.isLoading}
    />
  );
};
