import {useEffect, useState} from "react";
import {tCalendar, tCalendarParams} from "api/dashboard/calendar/type";
import {getDateFormat} from "helpers/dates";
import {useHttp} from "hooks/httpServices/useHttp";
import {useSetApiParams} from "hooks/useSetApiParams";
import {calendar} from "api/dashboard/calendar";
import {useContextProfile} from "context";
import moment from "moment";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";

export const useCalendar = () => {
  // @ts-ignore
  const scheduler = window?.scheduler;

  const {call, data, isLoading} = useHttp<{data: tCalendar[]}>();
  const {userData} = useContextProfile();
  const isPrivilegedUser = userData?.role?.name === "Admin" || userData?.role?.name === "User+";

  const [isDownloading, setIsDownloading] = useState(false);

  const list = data?.data?.data;
  const apiParams = useSetApiParams();

  const onExport = async () => {
    setIsDownloading(true);

    const toolbar = document.querySelector(".SchedulerDashboard-toolbar") as HTMLElement;
    const controls = document.querySelector("#scheduler-controls") as HTMLElement;
    const filters = document.querySelector("#scheduler-filters") as HTMLElement;
    const contentBody = document.querySelector("#contentBody") as HTMLElement;

    const name = `${moment().format("YYYY_MM_DD")}_DASHBOARD_CALENDAR.pdf`;
    contentBody.style.width = "1440px";
    contentBody.style.height = "960px";
    controls.style.display = "none";
    filters.style.display = "none";
    toolbar.style.display = "flex";

    let imgWidth = 295;
    let imgHeight = 210;

    scheduler.updateView();

    let canvas: HTMLCanvasElement = await html2canvas(contentBody, {scale: 2});
    const imgData = canvas.toDataURL("img/png");
    const pdf = new jsPDF("l", "mm", [imgWidth, imgHeight], true);

    pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
    pdf.save(name);
    controls.style.display = "flex";
    filters.style.display = "block";

    toolbar.style.display = "grid";

    contentBody.style.width = "100%";
    contentBody.style.height = "auto";

    setIsDownloading(false);
  };

  useEffect(() => {
    const initDate = getDateFormat(moment().startOf("month").format("YYYY-MM-DD hh:mm"), 'timestamp'); // prettier-ignore
    const {preparedParams} = apiParams;
    const params: tCalendarParams = {
      ...preparedParams,
      date: preparedParams?.date ? preparedParams?.date : initDate,
      ...(!isPrivilegedUser && {assigned: 1}),
      ...(preparedParams?.assigned && {assigned: preparedParams?.assigned ? 1 : 0}),
      ...(preparedParams?.departments && {departments: preparedParams?.departments?.split(",")})
    };

    call(calendar.getCalendarList(params));
  }, [apiParams?.searchParams, isPrivilegedUser]); // eslint-disable-line

  return {list, apiParams, onExport, isDownloading, isLoadingData: isLoading};
};
