import {tFullForm} from "./usePeopleForm";
import {iPayloadAddress, iPayloadBillingInfo} from "../../interfaces";
import {iContacts} from "api/contacts/types";
import {tDynamicFieldRowObj} from "components/shared/FormComponents/DynamicFields/types";

// FIXME need to check iAddPerson
export function generateFormData(fullForm: tFullForm): Partial<iContacts> {
  const {mainFormData, workFormData, addressFormData, otherFormData} = fullForm;

  //mainFormData
  const {first_name, last_name, middle_name, legal_name} = mainFormData.formData;
  const {locales, labels, phones, emails, accounts} = mainFormData.formData;
  const {personPhoto} = mainFormData;
  //workFormData
  const {titles, rate, team, companies, agencies, billing_default} = workFormData.formData;
  //addressFormData
  const {address, billing_info} = addressFormData.formData;
  //otherFormData
  const {notes, travel, pronoun, dietaryRestriction} = otherFormData.formData;
  const {departments, otherRestrictions, relationshipToVersatile} = otherFormData.formData;

  const isValue = (list: tDynamicFieldRowObj[]) => {
    if (list?.length === 1) return true;
    return list
      ?.map(item => ({platform: item.id || 0, value: item.value || ""}))
      .filter(item => !!item.platform && !!item.value)?.length;
  };

  const billingInfo: Partial<iPayloadBillingInfo> = {
    first_name: billing_info.first_name.value,
    last_name: billing_info.last_name.value,
    email: billing_info.email.value,
    phone: billing_info.phone?.value,
    address: {
      country_iso: billing_info.address.address.country_iso,
      post_code: billing_info.address.address.post_code,
      state: billing_info.address.address.state,
      street: billing_info.address.address.street,
      city: billing_info.address.address.city,
      apartment: billing_info.address.address.apartment
    }
  };
  const personAddress: Partial<iPayloadAddress> = {
    country_iso: address.address.country_iso,
    post_code: address.address.post_code,
    state: address.address.state,
    street: address.address.street,
    city: address.address.city,
    apartment: address.address.apartment
  };

  return {
    //main form
    ...(!personPhoto.includes("http") && personPhoto && {avatar: personPhoto}),
    first_name: first_name.value,
    last_name: last_name.value,
    middle_name: middle_name.value,
    legal_name: legal_name.value,

    ...(!!isValue(phones?.value) && {
      phones: phones?.value
        .map(item => ({
          ...(typeof item?.uid === "number" && {id: item.uid}),
          value: item?.value || "",
          type_id: item?.id ? +item?.id : undefined,
          type_custom: item.type_custom || ""
        }))
        .filter(item => !!item?.value)
    }),
    ...(!!isValue(emails?.value) && {
      emails: emails.value
        .map(item => ({
          ...(typeof item?.uid === "number" && {id: item.uid}),
          value: item.value || "",
          type_id: item.id || undefined,
          type_custom: item.type_custom || ""
        }))
        .filter(item => !!item?.value)
    }),
    locales: locales.value.map(({id}) => Number(id)),
    labels: labels.value.map(({id}) => Number(id)),
    ...(!!isValue(accounts.value) && {
      accounts: accounts.value
        .map(item => ({
          ...(typeof item?.uid === "number" && {id: item.uid}),
          value: item.value || "",
          platform: item.id || undefined
        }))
        .filter(item => !!item?.value)
    }),

    // work form
    companies: companies.value.map(({id}) => Number(id)),
    agencies: agencies.value.map(({id}) => Number(id)),
    titles: !!titles.value?.length ? titles.value.map(({id}) => +id) : [],
    rate: rate.value,
    team: team.value.map(({value: {person, relationship}}) => ({
      user_id: +person.value,
      user_relationship_id: +relationship.value
    })),
    // @ts-ignore
    billing_default: billing_default?.selectedCheckBoxes?.current,

    //address & billing form
    billing_info: billingInfo,
    address: personAddress,
    // ...(!!Object.values(billingInfo).length && {billing_info: billingInfo}),
    // ...(!!Object.values(personAddress).length && {address: personAddress}),

    //other form
    // @ts-ignore
    versatile_relationship_id:
      +relationshipToVersatile.value > 0 ? +relationshipToVersatile.value : "",
    departments: departments.selectedCheckBoxes.current.map(item => +item),
    dietary_restrictions: dietaryRestriction.selectedCheckBoxes.current.map(item => +item),
    travel: travel.value,
    notes: notes.value,
    other_dietary_restriction: otherRestrictions.value,
    ...(pronoun.value && {
      pronoun_id: +pronoun.value,
      ...(pronoun.customInput.value && {pronoun_custom: pronoun.customInput.value})
    })
  };
}
