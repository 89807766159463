import {Dispatch, FC, FormEvent, SetStateAction} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {useHttp} from "hooks/httpServices/useHttp";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";

interface iTemplateRenameProps {
  onClose: () => void;
  id: number | null | undefined;
  name: string | null | undefined;
  onEditCallback?: Dispatch<SetStateAction<any>>;
  config: any;
}

export const TemplateRename: FC<iTemplateRenameProps> = ({
  name,
  onEditCallback,
  id,
  onClose,
  config
}) => {
  const idForm = "rename-form-id";
  const {call, isLoading} = useHttp();
  const nameValue = useTextInput({value: name || ""});

  const onRename = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!id || !nameValue.checkValidity()) return;

    try {
      const {
        data: {message}
      } = await call(config(id, {name: nameValue?.value}));
      onEditCallback?.({id, name: nameValue.value});
      eventBus.dispatch("showToast", {text: message});
      onClose();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {text: msg, type: "error"});
    }
  };

  return (
    <ConfirmWindow
      isLoading={isLoading}
      show={true}
      onClose={onClose}
      onConfirm={onRename}
      title="Rename template"
      subTitle="Enter a new name"
      confirmBtn={{
        text: "save",
        form: idForm
      }}
      cancelBtn={{
        text: "cancel"
      }}
    >
      <form onSubmit={onRename} id={idForm}>
        <InputField {...nameValue.inputProps} errors={nameValue.errors} />
      </form>
    </ConfirmWindow>
  );
};
