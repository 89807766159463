import {FC} from "react";

export const Reupload: FC = () => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7184 3.53047C14.073 1.67422 11.6789 0.5 9 0.5C4.02891 0.5 0 4.52891 0 9.5H2.25C2.25 5.76992 5.26992 2.75 9 2.75C11.0566 2.75 12.8813 3.68164 14.1152 5.13359L11.9988 7.25H18V1.24883L15.7184 3.53047ZM9 16.25C6.94336 16.25 5.11875 15.3184 3.88477 13.8664L6.00117 11.75H0V17.7512L2.28164 15.4695C3.92695 17.3258 6.32461 18.5 9 18.5C13.9711 18.5 18 14.4711 18 9.5H15.75C15.75 13.2301 12.7301 16.25 9 16.25Z"
        fill="currentColor"
      />
    </svg>
  );
};
