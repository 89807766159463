import {FC, useState} from "react";
import {Button} from "components/shared/Button/Button";
import {ImportModal} from "./ImportModal/ImportModal";
import {ExportModalLocations} from "pages/Locations/ImportLocation/ExportModalLocations/ExportModalLocations";
import {useContextLocations} from "../context/LocationsContext";

type tModal = "import" | "export";

export const ImportLocation: FC = () => {
  const {getLocationsList} = useContextLocations();
  const [modal, setModal] = useState<tModal | null>(null);

  return (
    <>
      <Button size="md" color="light" onClick={() => setModal("import")}>
        Import
      </Button>
      <Button size="md" color="light" onClick={() => setModal("export")}>
        Export
      </Button>
      {modal === "import" && (
        <ImportModal
          type="locations"
          show={true}
          title="import locations"
          subtitle="Browse for a CSV file."
          onClose={() => setModal(null)}
          onUpdateList={getLocationsList}
        />
      )}
      {modal === "export" && (
        <ExportModalLocations show={true} title="export locations" onClose={() => setModal(null)} />
      )}
    </>
  );
};
