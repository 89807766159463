import {Dispatch, FC, SetStateAction} from "react";
import moment from "moment";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import {Button} from "components/shared/Button/Button";
import personDefaultImg from "assets/images/person.png";
import {getPeriodTime} from "../helpers";
import {Icon} from "components/Icon/Icon";
import {ImgWithFallback} from "../../shared/ImgWithFallback/ImgWithFallback";
import {tNotification} from "../Notifications";
import {Loading} from "components/shared/Loading/Loading";
import {notificationsApi} from "api/notifications";
import {eventBus} from "EventBus/EventBus";
import {useHttp} from "hooks/httpServices/useHttp";
import {tReturnNotificationData} from "api/notifications/types";

import "./NotificationsItem.scss";

interface iNotificationsItem extends tNotification {
  setNotifications: Dispatch<SetStateAction<tReturnNotificationData[] | null>>;
}

export const NotificationsItem: FC<iNotificationsItem> = ({
  id,
  created_at,
  title,
  read,
  sub_title,
  period,
  initiator,
  type,
  target_title,
  setNotifications
}) => {
  const {call, isLoading} = useHttp();

  const periodTime = getPeriodTime(created_at);
  const generateTimeFormat = (
    format: string,
    initialDate: tNotification["created_at"] = created_at
  ) => moment(initialDate).format(format);
  const time =
    (periodTime === "Today" || periodTime === "Yesterday") && generateTimeFormat("h:mm A"); // return time: "12:13 PM"
  const timeWithDay = periodTime === "Previous Week" && generateTimeFormat("ddd h:mm A"); // return date: "Mon 12:13 PM"
  const timeWithDate = periodTime === "Earlier" && generateTimeFormat("MMM DD, h:mm A"); // return date: "Oct 11, 12:13 PM"

  const showAvatar = type !== "reminder" && type !== "task_overdue";
  const showReminder = type === "reminder" || type === "task_overdue";

  const markAsRead = async () => {
    try {
      await call(notificationsApi.markNotificationAsRead(id));
      setNotifications(prev => prev!.filter(item => item.id !== id));
      eventBus.dispatch("showToast", {text: "Notification was marked as read."});
    } catch (error: any) {
      eventBus.dispatch("showToast", {type: "error", text: error?.message});
    }
  };

  return (
    <div key={id} className="NotificationsItem">
      {isLoading && <Loading type="inBlock" />}
      {period && <div className="NotificationsItem-period">{period}</div>}
      <div className="NotificationsItem-header">
        <div className="NotificationsItem-info">{time || timeWithDay || timeWithDate}</div>
        {!read && (
          <Tooltip message="Mark as read" position="right">
            <Button onClick={markAsRead} className="NotificationsItem-button-action" />
          </Tooltip>
        )}
      </div>
      <div className="NotificationsItem-title">{title}</div>
      <div className="NotificationsItem-info">{target_title}</div>
      <div className="NotificationsItem-footer">
        {showReminder && (
          <Icon icon="reminder" size="md" color={`${type === "reminder" ? "yellow" : "red"}`} />
        )}
        {showAvatar && (
          <ImgWithFallback
            className="NotificationsItem-avatar avatar"
            src={initiator?.avatar_url || ""}
            fallBackSrc={personDefaultImg}
            alt="user avatar"
          />
        )}
        <span className="NotificationsItem-subtitle">{sub_title}</span>
      </div>
    </div>
  );
};
