import {FC} from "react";
import {useBudgetTypes} from "./useBudgetTypes";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {TemplateModal} from "components/shared/TemplateModal/TemplateModal";
import {TypesList} from "./TypesList/TypesList";
import {Loading} from "components/shared/Loading/Loading";
import {BudgetTypeCategoryList} from "./BudgetTypeCategoryList/BudgetTypeCategoryList";
import {BudgetTypeCostsList} from "./BudgetTypeCostsList/BudgetTypeCostsList";

import "./BudgetTypes.scss";

interface iBudgetTypesProps {
  onClose: () => void;
}

export const BudgetTypes: FC<iBudgetTypesProps> = ({onClose}) => {
  const {budgetTypeList, setBudgetTypeList, setCosts, costs, isLoading, stepData, setStepData} =
    useBudgetTypes();

  const currentBudgetType = budgetTypeList?.find(({id}) => id === stepData?.budgetTypes?.id); // prettier-ignore
  const currentCategory = currentBudgetType?.categories.find(
    cat => cat.id === stepData?.categories?.id
  );

  return (
    <ModalPopup
      size="w-528"
      show={true}
      onClose={onClose}
      className="BudgetTypes"
      withoutPaddings
      showCloseButton
    >
      {isLoading && <Loading type="inBlock" />}
      {stepData.step === "budgetTypes" && (
        <TemplateModal title="Edit BUDGET TYPES" subTitle="Select ONE">
          <TypesList data={budgetTypeList} onSetStep={setStepData} />
        </TemplateModal>
      )}

      {stepData.step === "categories" && currentBudgetType && (
        <TemplateModal
          onBack={() => setStepData(prev => ({...prev, step: "budgetTypes"}))}
          title={currentBudgetType.name}
          subTitle="Select a Category"
        >
          <BudgetTypeCategoryList
            data={currentBudgetType}
            setBudgetTypeList={setBudgetTypeList}
            onSetStep={setStepData}
          />
        </TemplateModal>
      )}

      {stepData.step === "costs" && stepData?.categories && (
        <TemplateModal
          onBack={() => setStepData(prev => ({...prev, step: "categories"}))}
          title={`${currentBudgetType?.name} - ${currentCategory?.name}`}
          subTitle="EDIT LINE ITEMS"
        >
          <BudgetTypeCostsList
            categoryId={stepData?.categories?.id}
            data={costs[stepData?.categories?.id]}
            title={`${currentCategory?.code} ${currentCategory?.name}`}
            setData={setCosts}
            onSetStep={setStepData}
            currentBudgetType={currentBudgetType}
            setBudgetTypeList={setBudgetTypeList}
          />
        </TemplateModal>
      )}
    </ModalPopup>
  );
};
