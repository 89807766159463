import {ChangeEvent, FC, useEffect, useRef, useState} from "react";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import {getUsMoneyFormat} from "helpers/formattingData";
import {Loading} from "components/shared/Loading/Loading";
import {tEstimateRowINputProps} from "./types";
import {rowInputFilter} from "./filters";
import "./EstimateRowINput.scss";

export const EstimateRowINput: FC<tEstimateRowINputProps> = props => {
  const {defaultValue, onBlurCallBack, placeholder = "", prefix, suffix} = props;
  const {tooltipMsg, currency, type = "currency", inputStatic, isLoading} = props;

  const [value, setValue] = useState(defaultValue);
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const showInputField = () => setShowInput(true);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    showInput && inputRef && inputRef?.current?.focus();
  }, [showInput]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    let {value} = e.target;
    const val = rowInputFilter(value, type) || "";
    setValue(val);
  };

  const onBlur = () => {
    setShowInput(false);

    const wasChanges = !!value && value !== defaultValue;
    wasChanges && onBlurCallBack(value);
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key === "Enter" && onBlur();
  };

  const onFocus = () => {
    setShowInput(true);
  };

  const comp = (
    <TextComponent
      as={"div"}
      weight="400"
      size="14"
      color={!!value ? "black-10" : "gray-20"}
      truncate
    >
      {prefix && prefix}
      {!value && placeholder}
      {!!value && !currency && value}
      {!!value && currency && getUsMoneyFormat(value, currency)}
      {suffix && suffix}
    </TextComponent>
  );

  const hideText = inputStatic && showInput;
  return (
    <div
      className={`EstimateRowINput ${inputStatic ? "inputStatic" : ""} ${
        !!value ? "" : "placeholder"
      }`}
      onClick={showInputField}
    >
      {isLoading && <Loading type="inBlock" bgColor="grey-40" customSize={0.5} />}

      {tooltipMsg && !hideText && (
        <Tooltip message={tooltipMsg} position="bottom">
          {comp}
        </Tooltip>
      )}

      {!tooltipMsg && !hideText && comp}

      {showInput && (
        <input
          ref={inputRef}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          className="EstimateRowINput-input"
          onKeyPress={onKeyPress}
        />
      )}
    </div>
  );
};
