import {FC} from "react";
import {tCallSheetContact} from "api/callSheet/types";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";

type tCallSheetBuildContactAgentProps = {
  contact: tCallSheetContact;
};

export const CallSheetBuildContactAgent: FC<tCallSheetBuildContactAgentProps> = ({contact}) => {
  const isHidden = contact?.selected ? "" : "hidden";
  return (
    <>
      <div className={`CSAgent name ${isHidden}`}>
        <TextComponent uppercase size="15">
          &bull; {contact?.agent?.name}
        </TextComponent>
      </div>
      <div className={`CSAgent ${isHidden}`}>
        <TextComponent weight="400" size="14">
          {contact?.agent?.position}
        </TextComponent>
      </div>
      <div className={`CSAgent ${isHidden}`}>
        <TextComponent weight="400" size="14">
          {contact?.agent?.email?.value}
        </TextComponent>
      </div>
      <div className={`CSAgent ${isHidden}`}>
        <TextComponent weight="400" size="14">
          {contact?.agent?.phone?.value}
          {/* {formatPhoneNumber({value: contact?.agent?.phone?.value})} */}
        </TextComponent>
      </div>
      <div className="CSAgent" />
      <div className="CSAgent" />
      <div className="CSAgent" />
    </>
  );
};
