import {useEffect, useState} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {
  iCalendarListItemReturn,
  iCalendarSheetItemReturn,
  iJobCalendarTemplatesListItemReturn
} from "api/jobs/calendar/types";
import {jobsCalendarApi} from "api/jobs/calendar";
import {useSetApiParams} from "hooks/useSetApiParams";
import {generateSchedulerEvents} from "../helpers";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {tSchedulerEvent} from "pages/Jobs/JobDetails/JobCalendar/JobScheduler/JobScheduler";
import {useParams, useNavigate} from "react-router-dom";
import {sortByKey} from "helpers/misc";

export function useJobCalendar() {
  const {calendarId, id} = useParams();
  const navigate = useNavigate();
  const apiParams = useSetApiParams();

  const {data: calendarTemplatesList, call: templatesCall} = useHttp<{
    data: iJobCalendarTemplatesListItemReturn[];
  }>({
    config: jobsCalendarApi.getJobCalendarTemplates(),
    onMountCall: true
  });
  const calendarsListQuery = useHttp<{data: iCalendarListItemReturn[]}>();
  const calendarDetailsQuery = useHttp<{data: iCalendarSheetItemReturn}>(); // There are events

  const [activeCalendar, setActiveCalendar] = useState<iCalendarListItemReturn | null>(null);
  const [calendarSheets, setCalendarSheets] = useState<tSchedulerEvent[]>([]);
  const [calendarsList, setCalendarsList] = useState<iCalendarListItemReturn[]>([]);

  useEffect(() => {
    if (!id) return;

    getCalendarList(+id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // update calendar list when apiParams or selected calendar change
  useEffect(() => {
    if (calendarId) getCalendarSheet(+calendarId);
  }, [calendarId, apiParams?.searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCalendarList = async (id: number) => {
    try {
      const {
        data: {data}
      } = await calendarsListQuery.call(jobsCalendarApi.getJobCalendar(id));
      const list = sortByKey(data, "name");

      setCalendarsList(list);

      if (!!list?.length) {
        setActiveCalendar(list[0]);
        navigate(`${list[0]?.id}`);
        return;
      }

      if (calendarId) {
        navigate(`/jobs/${id}/calendar`);
      }
    } catch (error: any) {
      console.log(`getCalendarList: `, error);
    }
  };
  const getTemplates = async () => templatesCall(jobsCalendarApi.getJobCalendarTemplates()); // prettier-ignore

  const getCalendarSheet = async (id?: number) => {
    if (!calendarId) return;

    const currentCalendarId = id || +calendarId;
    const {preparedParams} = apiParams;

    const params = {
      ...preparedParams,
      ...(preparedParams?.entities && {entities: preparedParams?.entities.split(",")})
    };

    try {
      const {data: {data}} = await calendarDetailsQuery.call(jobsCalendarApi.getCalendarSheet(currentCalendarId, params as any)); // prettier-ignore
      setCalendarSheets(generateSchedulerEvents(data));
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      console.log(msg);
    }
  };

  return {
    calendarsList,
    setCalendarsList,
    calendarTemplatesList: calendarTemplatesList?.data?.data as iJobCalendarTemplatesListItemReturn[], // prettier-ignore
    getCalendarList,
    getCalendarSheet,
    apiParams,
    calendarDetailsQuery,
    calendarsListQuery,
    activeCalendar,
    setActiveCalendar,
    getTemplates,
    calendarSheets,
    setCalendarSheets
  };
}
