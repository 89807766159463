import {ChangeEvent, useRef, useState} from "react";

type tUseCheckBoxGroupe = {
  isRequired?: boolean;
  emptyErrText?: string;
  initValue?: Array<string | number>;
  controlledState?: boolean;
  resetIfSelected?: string;
};

export const useCheckBoxGroupe = (props?: tUseCheckBoxGroupe) => {
  const selectedCheckBoxes = useRef<Array<string | number>>(props?.initValue || []);
  const [errors, setErrors] = useState<string[]>([]);
  const [isDirty, setIsDirty] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [val, setValue] = useState<Array<string | number>>(props?.initValue || []);

  const initValue = props?.initValue || [];
  const emptyErr = props?.emptyErrText ? props?.emptyErrText : "This field can't be empty";

  const checkBoxGroupeOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, checked} = e.target;

    if (props?.resetIfSelected === value) {
      selectedCheckBoxes.current = [props.resetIfSelected];
      setValue([props.resetIfSelected]);
      const changed = compareValues(props?.initValue || [], selectedCheckBoxes.current || []);
      setIsChanged(changed);
      checkValidity();
      return;
    }

    if (checked) {
      selectedCheckBoxes.current.push(value);
      (props?.controlledState || props?.resetIfSelected) &&
        setValue(prev => {
          // console.log("prev", prev);
          const arr = prev.filter(item => +item !== +value);
          return [...arr, value];
        });
    } else {
      const filtered = selectedCheckBoxes.current.filter(item => item.toString() !== value);
      selectedCheckBoxes.current = filtered;
      (props?.controlledState || props?.resetIfSelected) && setValue(filtered);
    }

    if (!!props?.resetIfSelected) {
      const filterReset = selectedCheckBoxes.current.filter(
        item => item.toString() !== props?.resetIfSelected
      );
      selectedCheckBoxes.current = filterReset;
      setValue(filterReset);
    }

    const changed = compareValues(props?.initValue || [], selectedCheckBoxes.current || []);
    setIsChanged(changed);
    checkValidity();
  };

  function compareValues(propArray: any[], values: any[]) {
    if (propArray.length !== values.length) return true;
    return !!values?.filter(value => !propArray.some(prop => String(prop) === String(value)))
      .length;
  }

  const checkValidity = () => {
    if (props?.isRequired && !selectedCheckBoxes.current.length) {
      setErrors([emptyErr]);
      !isDirty && setIsDirty(true);
      return false;
    } else {
      errors.length && setErrors([]);
      return true;
    }
  };

  return {
    selectedCheckBoxes,
    checkBoxGroupeOnChange,
    checkValidity,
    errors,
    initValue,
    isChanged,
    val,
    setValue
  };
};
