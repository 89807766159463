import {AxiosRequestConfig} from "axios";
import {tDeactivateTeammate, tTeamListParams, tTeammatePostData} from "./types";

const user = "user";
const invite = "invite";

export const team = {
  getTeamList: (params?: tTeamListParams): AxiosRequestConfig => ({
    url: "users",
    params
  }),
  getTeammateDetail: (id: number): AxiosRequestConfig => ({
    url: `${user}/${id}`
  }),
  inviteTeammate: (data: tTeammatePostData): AxiosRequestConfig => ({
    url: `${user}/${invite}`,
    method: "POST",
    data
  }),
  updateTeamMate: (id: number, data: tTeammatePostData): AxiosRequestConfig => ({
    method: "POST",
    url: `${user}/${id}`,
    data
  }),
  deleteTeammate: (id: number): AxiosRequestConfig => ({
    url: `${user}/${id}/${invite}`,
    method: "DELETE"
  }),
  resentInvite: (id: number): AxiosRequestConfig => ({
    url: `${user}/${id}/${invite}/resend`,
    method: "POST"
  }),
  reactivateTeammate: (id: number, email: string): AxiosRequestConfig => ({
    url: `${user}/${id}/reactivate`,
    method: "POST",
    data: {email}
  }),
  deactivateTeammate: (data: tDeactivateTeammate): AxiosRequestConfig => ({
    url: `${user}/${data.id}/deactivate`,
    method: "POST",
    data: {
      time: data?.time,
      date: data?.date
    }
  })
};
