import {tTrackFeesItem, typeFees} from "api/budget/track/types";
import {FC, useEffect, useState} from "react";
import {ExpenseRow} from "../Category/CategoryBody/ExpenseRow/ExpenseRow";
import {useChangeExpenseRowOrder} from "../useChangeExpenseRowOrder";

type tBodyFeesExpenseListProps = {
  feesItem: tTrackFeesItem;
  typeFees: typeFees;
};

export const BodyFeesExpenseList: FC<tBodyFeesExpenseListProps> = ({feesItem, typeFees}) => {
  const [data, setPayrollData] = useState<tTrackFeesItem>(feesItem);
  const {dropCallBackPayroll, setCurrItem, isLoading} = useChangeExpenseRowOrder({setPayrollData});

  useEffect(() => {
    setPayrollData(feesItem);
  }, [feesItem]);

  return (
    <>
      {data?.expense_lines?.map(trackLine => {
        return (
          <ExpenseRow
            key={trackLine?.id}
            trackLine={trackLine}
            setCurrItem={setCurrItem}
            isLoading={isLoading}
            dropCallBack={currItem => dropCallBackPayroll(currItem, data)}
            typeFees={typeFees}
          />
        );
      })}
    </>
  );
};
