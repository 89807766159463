import {AxiosRequestConfig} from "axios";
import {tAdvanceContactPostData} from "./types";

const ADVANCE_CONTACTS = "advance_contacts";
const ADVANCE_CONTACT = "advance_contact";

export const advanceContactApi = {
  getAdvanceContactList: (): AxiosRequestConfig => ({
    url: ADVANCE_CONTACTS
  }),
  getAdvanceContactDetails: (advanceContactId: number): AxiosRequestConfig => ({
    url: `${ADVANCE_CONTACT}/${advanceContactId}`
  }),
  createAdvanceContact: (data: tAdvanceContactPostData): AxiosRequestConfig => ({
    method: "POST",
    url: ADVANCE_CONTACT,
    data
  }),
  updateAdvanceContact: (
    advanceContactId: number,
    data: tAdvanceContactPostData
  ): AxiosRequestConfig => ({
    method: "POST",
    url: `${ADVANCE_CONTACT}/${advanceContactId}`,
    data
  }),
  deleteAdvanceContact: (advanceContactId: number): AxiosRequestConfig => ({
    method: "DELETE",
    url: `${ADVANCE_CONTACT}/${advanceContactId}`
  })
};
