import {FC, ReactNode, MouseEvent, useRef} from "react";
import {Tag} from "components/shared/Tag/Tag";
import {iBaseOptions} from "api/common/types";
import "./TagsRow.scss";

interface iTagsRowList {
  data: iBaseOptions[];
  emptyData: ReactNode;
  onClick(event: MouseEvent<any, any>, item: iBaseOptions): void;
  maxWidth?: number;
}

export const TagsRow: FC<iTagsRowList> = ({data, onClick, emptyData}) => {
  const MAX_WIDTH = 200;
  let rowWidth = useRef([0]);
  if (!data.length) return <>{emptyData}</>;

  function calculateWidth(el: HTMLDivElement) {
    if (!el || !el?.offsetWidth || data?.length === rowWidth?.current?.length) return;
    const prevWidth = rowWidth.current;
    rowWidth.current = [...prevWidth, el.offsetWidth];
  }

  function getRowWidthByIndex(index: number) {
    const currentRow = rowWidth.current;
    return currentRow.reduce((accu, curr, idx) => (idx <= index ? accu + curr : accu), 0);
  }

  return (
    <div className="TagsRow">
      {data?.map((item, index, array) => {
        if (getRowWidthByIndex(index) + 33 > MAX_WIDTH) {
          return null;
        }

        if (getRowWidthByIndex(index + 1) + 33 > MAX_WIDTH) {
          return (
            <Tag color="outline" key={item.id}>
              +{array?.length - index}
            </Tag>
          );
        }

        return (
          <Tag ref={calculateWidth} key={item?.id} onClick={event => onClick(event, item)}>
            {item?.name}
          </Tag>
        );
      })}
    </div>
  );
};
