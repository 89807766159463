import {FC, ReactNode, useEffect, useState} from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import {routes} from "router/routes";
import {useIsActiveUrlFromArr} from "hooks/useIsActiveUrlFromArr";
import {useContextJobBudget} from "../context/JobBudgetContext";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import {AppTooltips} from "components/AppTooltips/AppTooltips";
import "./JobBudgetHeaderLayout.scss";

interface iJobBudgetHeaderLayoutProps {
  children?: ReactNode;
}

export const JobBudgetHeaderLayout: FC<iJobBudgetHeaderLayoutProps> = ({children}) => {
  const {estimateList, estimate: est} = useContextJobBudget();
  const {id, estimateId} = useParams();
  const {pathname} = useLocation();
  const {isActiveUrl} = useIsActiveUrlFromArr();

  const {main, advance, track, expenseList, wrapPDF, invoice, estimate} = routes.jobs.details.budget; // prettier-ignore
  const estimateRoute = estimate.main;

  const baseUrl = `/${routes?.jobs.main}/${id}/${main}`;

  const estimateDefaultId = estimateId || estimateList?.[0]?.id;
  const links = [
    {
      url: `estimate/${estimateDefaultId}`,
      title: "Estimate",
      isActive: pathname?.includes("export") || pathname?.includes(estimateRoute)
    },
    {
      url: "advance",
      title: "Advance",
      isActive: pathname?.includes(advance.main)
    },
    {
      url: track,
      title: "Track",
      isActive: isActiveUrl([track, expenseList, wrapPDF])
    },
    {
      url: invoice,
      title: "Invoice",
      isActive: isActiveUrl([invoice])
    }
  ];

  const [isApproved, setIsApproved] = useState(!!estimateList?.find(item => item?.approved_at));
  useEffect(() => {
    setIsApproved(!!estimateList?.find(item => item?.approved_at));
  }, [estimateList]);

  const isEstimatePage = !!pathname?.includes(estimate?.main);
  const notApprovedUrl = pathname?.includes("export")
    ? `/estimate/${est?.id}/export`
    : `/estimate/${est?.id}`;

  return (
    <div className="JobBudgetHeaderLayout">
      <div className="JobBudgetHeaderLayout-leftSide">
        <h2 className="JobBudgetHeaderLayout-title">Budget</h2>
        <nav className="JobBudgetHeaderLayout-nav">
          {links.map((link, i) => {
            const goTo = !!link.url ? `${baseUrl}/${link.url}` : baseUrl;
            const disabled = isApproved ? "" : "disabled";
            const active = link.isActive ? "active" : "";
            const showTooltip = !isApproved && i !== 0;

            return (
              <Link
                key={link.title}
                className={`JobBudgetHeaderLayout-navItem ${active} ${disabled}`}
                to={isApproved ? goTo : baseUrl + notApprovedUrl}
              >
                {showTooltip && (
                  <Tooltip message="Approve estimate to unlock">{link.title}</Tooltip>
                )}
                {!showTooltip && link.title}

                {isEstimatePage && <AppTooltips page="estimate" tooltip="BUILD THE ESTIMATE" />}
              </Link>
            );
          })}
        </nav>
      </div>
      <div className="JobBudgetHeaderLayout-rightSide">{children}</div>
    </div>
  );
};
