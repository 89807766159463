import {FC, FormEvent} from "react";
import {jobTasksApi} from "api/jobs/tasks";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {notValidForm} from "helpers/misc";
import {useHttp} from "hooks/httpServices/useHttp";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useContextJobTasks} from "../_context/JobTaskContext";
import {TextComponent} from "../../common/TextComponent/TextComponent";

type tSaveAsNewTemplateProps = {
  onClose: () => void;
};

export const SaveAsNewTemplate: FC<tSaveAsNewTemplateProps> = ({onClose}) => {
  const {tasks} = useContextJobTasks();
  const {call, isLoading} = useHttp();
  const FORM_ID = "save-as-new-tasks-template-form-id";

  const form = {
    name: useTextInput()
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (notValidForm(form) || !tasks?.id) return;

    try {
      const res = await call(jobTasksApi.saveAsNewTemplate(tasks?.id, {name: form.name.value}));

      onClose();
      eventBus.dispatch("showToast", {type: "success", text: res?.data?.message || "Success!"});
    } catch (error) {
      const {msg} = apiErrorHandler(error, form);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <ConfirmWindow
      show={true}
      title="SAVE AS NEW TEMPLATE"
      subTitle="Name your template"
      cancelBtn={{text: "Cancel", type: "button"}}
      confirmBtn={{text: "save", type: "submit", form: FORM_ID}}
      onClose={onClose}
      isLoading={isLoading}
    >
      <form onSubmit={onSubmit} id={FORM_ID}>
        <InputField {...form.name.inputProps} errors={form.name.errors} />

        <TextComponent weight="400" size="13" color="gray-10" textAlign="center">
          Store groups and tasks names to be loaded into another job.
        </TextComponent>
      </form>
    </ConfirmWindow>
  );
};
