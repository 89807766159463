import {FC, FormEvent} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {Select} from "components/shared/FormComponents/Select/Select";
import {TextareaField} from "components/shared/FormComponents/TextareaField/TextareaField";
import {CheckBoxGroupe} from "components/shared/FormComponents/CheckBoxGroupe/CheckBoxGroupe";
import {Loading} from "components/shared/Loading/Loading";
import {tUsePeopleFormOther} from "./usePeopleFormOther";
import {PEOPlE_FORM_ID} from "../../model";
import {SelectInput} from "components/shared/FormComponents/SelectInput/SelectInput";
import {Button} from "components/shared/Button/Button";

interface iPeopleFormOtherProps {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  otherFormData: tUsePeopleFormOther;
  toNextTab: () => void;
}

export const PeopleFormOther: FC<iPeopleFormOtherProps> = ({
  onSubmit,
  toNextTab,
  otherFormData
}) => {
  const { formData, departments, relationships, dietaryRestriction, isLoading } = otherFormData; // prettier-ignore

  if (isLoading) return <Loading className="PeopleLoading-form" />;

  return (
    <form className="PeopleFormOther" id={PEOPlE_FORM_ID} onSubmit={onSubmit}>
      <div className="PeopleForm-section">
        <Select
          {...formData.relationshipToVersatile.inputProps}
          errors={formData.relationshipToVersatile.errors}
          id="relationship_to_versatile"
          label="relationship to versatile"
          options={relationships}
          targetKeys={{label: "name", value: "id"}}
          className="PeopleFormOther-select"
        />

        <div className="PeopleForm-section-checkbox">
          <CheckBoxGroupe
            label="Associated Department(s)"
            checkBoxList={departments}
            initValue={formData.departments.selectedCheckBoxes.current}
            onChange={formData.departments.checkBoxGroupeOnChange}
            errors={formData.departments.errors}
          />
        </div>

        <div className="PeopleForm-section-checkbox">
          <CheckBoxGroupe
            label="Dietary Restrictions"
            checkBoxList={dietaryRestriction}
            initValue={formData.dietaryRestriction.selectedCheckBoxes.current}
            onChange={formData.dietaryRestriction.checkBoxGroupeOnChange}
            errors={formData.dietaryRestriction.errors}
          />
        </div>

        <InputField
          {...formData.otherRestrictions.inputProps}
          errors={formData.otherRestrictions.errors}
          id="other_dietary_restrictions"
          label="Other Dietary Restrictions"
        />

        <SelectInput
          {...formData.pronoun.inputProps}
          isCustomInput={formData.pronoun.isCustomInput}
          customInput={formData.pronoun.customInput}
          clearAll={formData.pronoun.clearAll}
          errors={formData.pronoun.errors}
          label="Pronouns"
          className="PeopleFormOther-select"
        />

        <TextareaField
          {...formData.travel.inputProps}
          errors={formData.travel.errors}
          id="travel"
          label="Travel"
        />

        <TextareaField
          {...formData.notes.inputProps}
          errors={formData.notes.errors}
          id="notes"
          label="Notes"
        />
      </div>
      <Button type="button" color="light" className="PeopleForm-button-next" onClick={toNextTab}>
        CONTINUE TO CUSTOM DETAILS
      </Button>
    </form>
  );
};
