import {FC} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {useContextJobCalendar} from "../../context";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {jobsCalendarApi} from "api/jobs/calendar";
import {useHttp} from "hooks/httpServices/useHttp";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {useParams} from "react-router-dom";

interface iJobCalendarRenameProps {
  onClose: () => void;
}

export const JobCalendarRename: FC<iJobCalendarRenameProps> = ({onClose}) => {
  const {id, calendarId} = useParams();
  const {call, isLoading} = useHttp();
  const {activeCalendar,setActiveCalendar, getCalendarList, calendarsListQuery} = useContextJobCalendar(); // prettier-ignore

  const name = useTextInput({value: activeCalendar?.name});

  const onRename = async () => {
    if (!name.checkValidity() || !activeCalendar?.id) return;
    const payload = {
      name: name.value,
      locked: activeCalendar?.locked
    };
    try {
      const {
        data: {data, message}
      } = await call(jobsCalendarApi.updateJobCalendar(Number(calendarId), payload));
      await getCalendarList(Number(id));
      setActiveCalendar(data);
      eventBus.dispatch("showToast", {text: message});
      onClose();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <ConfirmWindow
      show={true}
      className="JobCalendarRename"
      onClose={onClose}
      title="Rename calendar"
      subTitle="Enter a new name"
      cancelBtn={{
        text: "Cancel",
        onClick: onClose
      }}
      confirmBtn={{
        text: "Rename"
      }}
      onConfirm={onRename}
      isLoading={isLoading || calendarsListQuery.isLoading}
    >
      <InputField {...name.inputProps} errors={name.errors} />
    </ConfirmWindow>
  );
};
