import {FC, useRef} from "react";
import {Editor} from "@tinymce/tinymce-react";

import "./TextEditor.scss";

const REACT_APP_TINY_KEY = process.env.REACT_APP_TINY_KEY;

interface iTextEditorProps {
  onEditorChange: (v: string) => void;
  errors?: string[];
  value?: string;
  editorProps?: any;
}

export const TextEditor: FC<iTextEditorProps> = ({
  editorProps,
  errors = [],
  value,
  onEditorChange
}) => {
  const editorRef = useRef(null);

  const isError = !!errors?.length ? "error" : "";

  return (
    <div className={`TextEditor ${isError}`}>
      <Editor
        textareaName="text-editor"
        apiKey={REACT_APP_TINY_KEY}
        // @ts-ignore
        onInit={(evt, editor) => (editorRef.current = editor)}
        value={value}
        onEditorChange={onEditorChange}
        init={{
          menubar: false,
          plugins: ["link", "lists"],
          fontsize_formats: "8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 26pt 28pt 32pt",
          toolbar:
            "bold italic underline | forecolor | fontsize | alignleft aligncenter " +
            "alignright alignjustify | bullist | link "
        }}
        {...editorProps}
      />
      {isError &&
        errors.map(error => (
          <p key={error} className="error-message">
            {error}
          </p>
        ))}
    </div>
  );
};
