import moment from "moment";
import {getDateFormat} from "helpers/dates";
import {tSchedulerEvent} from "./JobScheduler/JobScheduler";
import {iCalendarSheetItemReturn} from "api/jobs/calendar/types";

export function formatQueryCalendarDateRange(date: string | Date) {
  const min = moment(date).startOf("month").subtract(8, "days").toDate();
  const max = moment(date).endOf("month").add(8, "days").toDate();

  return {
    min_date: getDateFormat(min, "timestamp"),
    max_date: getDateFormat(max, "timestamp")
  };
}

export type tEventType = "events" | "holds" | "tasks";
export type tEventModal = ReturnType<typeof jobEventModel> & {id: number};
export function jobEventModel(item: any, type: tEventType) {
  let name = "";
  let start_date = "";
  let end_date = "";
  let text = "";

  if (type === "holds") {
    name = `${item?.first_name} ${item?.last_name}`;
    start_date = item?.start_date || moment();
    end_date = item?.end_date || moment();
  }

  if (!type || type === "events") {
    name = item?.name || "";
    start_date = item.start_date;
    end_date = item.end_date;
    text = item?.description || "";
  }

  if (type === "tasks") {
    if (!item?.due_date) return null;
    start_date = item.due_date;
    end_date = item.due_date;
    text = item?.notes || "";
    name = item?.name;
  }

  return {
    ...item,
    name,
    start_date,
    end_date,
    type,
    text
  };
}

export function generateSchedulerEvents(
  calendarSheet: iCalendarSheetItemReturn
): tSchedulerEvent[] {
  let events: tSchedulerEvent[] = [];

  Object.entries(calendarSheet).forEach(([key, value]: any) => {
    value.forEach((item: iCalendarSheetItemReturn) => {
      const event = jobEventModel(item, key);
      if (!event) return;
      events = [...events, event];
    });
  });

  return events;
}
