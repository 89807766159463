import {FC, ReactNode} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {tJobSettingsTemplatesModal} from "../JobSettingsTemplates";
import {JobSettingsTemplatesHeader} from "../JobSettingsTemplatesHeader/JobSettingsTemplatesHeader";
import {TemplateLayoutContextProvider} from "./TemplateLayoutContext";
import "./TemplateLayout.scss";

export type tTemplateLayoutChildren = Omit<tTemplateLayoutProps, "children" | "type">;
type tTemplateLayoutProps = {
  onClose: () => void;
  id: number;
  name: string;
  editName: (data: {id: number; name: string}) => void;
  templateType: tJobSettingsTemplatesModal;
  children: ReactNode;
};

export const TemplateLayout: FC<tTemplateLayoutProps> = props => {
  const {onClose, id, name, editName, children, templateType} = props;

  return (
    <TemplateLayoutContextProvider {...props}>
      <ModalPopup show={true} size="fullScreen" withoutPaddings className="TemplateLayout">
        <JobSettingsTemplatesHeader
          title={`Edit ${templateType} template “${name}”`}
          onRename={() => editName({id, name})}
          onClose={onClose}
        />
        {children}
      </ModalPopup>
    </TemplateLayoutContextProvider>
  );
};
