import {FC} from "react";
import {tDepartment} from "api/common/types";
import "./DepartmentList.scss";

interface iDepartmentList {
  data: tDepartment[];
  stacked?: boolean;
}

export const DepartmentList: FC<iDepartmentList> = ({data = [], stacked = false}) => {
  const departments = {
    Production: "pr",
    Post: "pp",
    Equipment: "eq",
    Accounting: "a",
    Office: "o",
    Marketing: "m"
  };

  return (
    <div className={`DepartmentList ${stacked ? "stacked" : ""}`}>
      {data.map(department => (
          <span key={department.id} className={`DepartmentList-item item ${department.name}`}>
            {departments[department.name]}
          </span>
        ))}
    </div>
  );
};
