import {LIST_OPTIONS} from "../../_modals/ExpenseListViewOptions/listViewOptions";

export const expenseListTableTitles = [
  {
    id: "firstItemId",
    label: ``,
    sort: false
  },
  {
    id: "cost_value",
    label: "Line number",
    sort: true,
    viewOptionId: LIST_OPTIONS?.lineNumber?.id
  },
  {
    id: "estimate",
    label: "Estimate",
    sort: true,
    viewOptionId: LIST_OPTIONS?.estimate?.id
  },
  {
    id: "vendor_name",
    label: "Vendor",
    sort: true,
    viewOptionId: LIST_OPTIONS?.vendor?.id
  },
  {
    id: "description",
    label: "Description",
    sort: true,
    viewOptionId: LIST_OPTIONS?.description?.id
  },
  {
    id: "working_details",
    label: "Working total",
    sort: true,
    viewOptionId: LIST_OPTIONS?.wTotal?.id
  },
  {
    id: "actual_details",
    label: "Actual total",
    sort: true,
    viewOptionId: LIST_OPTIONS?.aTotal?.id
  },
  {
    id: "charged",
    label: "Charged",
    sort: true,
    viewOptionId: LIST_OPTIONS?.charged?.id
  },
  {
    id: "green",
    label: "Green",
    sort: false,
    viewOptionId: LIST_OPTIONS?.green?.id
  },
  {
    id: "pay_value",
    label: "Pay ID",
    sort: true,
    viewOptionId: LIST_OPTIONS?.payId?.id
  },
  {
    id: "qb_status",
    label: "QB Status",
    sort: true,
    viewOptionId: LIST_OPTIONS?.qbStatus?.id
  },
  {
    id: "pay_status",
    label: "Pay Status",
    sort: true,
    viewOptionId: LIST_OPTIONS?.payStatus?.id
  },
  {
    id: "W8",
    label: "W8",
    sort: false,
    viewOptionId: LIST_OPTIONS?.attachedFile?.id
  },
  {
    id: "W9",
    label: "W9",
    sort: false,
    viewOptionId: LIST_OPTIONS?.attachedFile?.id
  },
  {
    id: "ACH",
    label: "ACH",
    sort: false,
    viewOptionId: LIST_OPTIONS?.attachedFile?.id
  },
  {
    id: "WIRE",
    label: "WIRE",
    sort: false,
    viewOptionId: LIST_OPTIONS?.attachedFile?.id
  },
  {
    id: "QP",
    label: "QP",
    sort: false,
    viewOptionId: LIST_OPTIONS?.qPay?.id
  },
  {
    id: "Note",
    label: "Note",
    sort: false,
    viewOptionId: LIST_OPTIONS?.note?.id
  },
  {
    id: "files",
    label: "",
    sort: false
  },
  {
    id: "actions",
    label: "",
    sort: false
  }
];
