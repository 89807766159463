import {gridApi} from "api/jobs/grid";
import {useHttp} from "hooks/httpServices/useHttp";
import {useEffect} from "react";

export const useGetTemplateList = (form: any) => {
  const {data, isLoading} = useHttp({config: gridApi?.getGridTemplates(), onMountCall: true}); // prettier-ignore
  const templateList = data?.data?.data;

  useEffect(() => {
    data &&
      form?.template?.setOptions(
        data?.data?.data?.map((item: any) => ({value: item?.id, label: item?.name}))
      );
  }, [data]); // eslint-disable-line

  return {templateList, isLoading};
};
