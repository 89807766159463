import {FC, useEffect, useState} from "react";
import {AdvanceContactsModal} from "../JobDetails/JobBudget/Advance/modals/AdvanceContactsModal/AdvanceContactsModal";
import {useHttp} from "hooks/httpServices/useHttp";
import {tAdvanceContact} from "api/budget/advanceContact/types";
import {advanceContactApi} from "api/budget/advanceContact";

interface iJobSettingsAdvanceContacts {
  onClose: (arg: null) => void;
}

export const JobSettingsAdvanceContacts: FC<iJobSettingsAdvanceContacts> = ({onClose}) => {
  const advanceContactsQuery = useHttp();
  const [contacts, setContacts] = useState<tAdvanceContact[]>([]);

  useEffect(() => {
    advanceContactsQuery
      .call(advanceContactApi.getAdvanceContactList())
      .then(({data: {data}}) => setContacts(data));
  }, []); // eslint-disable-line

  return (
    <AdvanceContactsModal
      onClose={() => onClose(null)}
      advanceContacts={contacts}
      setAdvanceContacts={setContacts}
      isLoading={advanceContactsQuery.isLoading}
    />
  );
};
