import {FC} from "react";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {Button} from "components/shared/Button/Button";
import {useEstimateManagement} from "./useEstimateManagement";
import {Icon} from "components/Icon/Icon";
import {EstimateRename} from "../modals/EstimateRename";
import {EstimateDuplicate} from "../modals/EstimateDuplicate";
import {EstimateSaveAsNewTemplate} from "../modals/EstimateSaveAsNewTemplate";
import {EstimateSaveAsExistingTemplate} from "../modals/EstimateSaveAsExistingTemplate";
import {EstimateDelete} from "../modals/EstimateDelete";
import {CurrencyRates} from "components/shared/CurrencyRates/CurrencyRates";
import {RemoveApproval} from "./RemoveApproval";
import {useContextJobBudget} from "../../context/JobBudgetContext";

import "./EstimateManagement.scss";

interface iEstimateManagementProps {}

export const EstimateManagement: FC<iEstimateManagementProps> = () => {
  const {resources, setResources, estimate, getEstimate} = useContextJobBudget();
  const {anchor, toggleMenu, modal, onClose, showModal, closeMenu} = useEstimateManagement();
  const isApproved = !!estimate?.approved_at;

  const updateResources = (list: any) => {
    getEstimate();
    setResources((prev: any) => {
      return {...prev, currency_rate: list || []};
    });
  };

  const modalRender = {
    rename: <EstimateRename onClose={onClose} />,
    duplicate: <EstimateDuplicate onClose={onClose} />,
    saveAsNew: <EstimateSaveAsNewTemplate onClose={onClose} />,
    saveAsExisting: <EstimateSaveAsExistingTemplate onClose={onClose} />,
    delete: <EstimateDelete onClose={onClose} />,
    currency: (
      <CurrencyRates
        onClose={onClose}
        type="estimateTemplate"
        customList={resources?.currency_rate}
        updateCallback={updateResources}
        blockDelete={!!estimate?.approved_at}
        templateId={estimate?.id}
        blockUpdateValue={isApproved}
      />
    )
  };

  return (
    <>
      {modal && modalRender[modal]}

      <div className="EstimateManagement">
        <Button color="outline" size="sm" onClick={toggleMenu}>
          <Icon icon="menu" size="xsm" />
        </Button>
        <DropdownMenu
          className="EstimateManagement-dropdown"
          anchor={anchor}
          onClose={() => toggleMenu(null)}
        >
          <Button size="sm" width="fullWidth" onClick={() => showModal("rename")}>
            Rename
          </Button>
          <Button size="sm" width="fullWidth" onClick={() => showModal("duplicate")}>
            Duplicate
          </Button>
          <Button size="sm" width="fullWidth" onClick={() => showModal("saveAsNew")}>
            Save as new template
          </Button>
          <Button size="sm" width="fullWidth" onClick={() => showModal("saveAsExisting")}>
            Save as existing template
          </Button>
          <Button size="sm" width="fullWidth" onClick={() => showModal("delete")}>
            Delete
          </Button>
          <hr />
          <Button size="sm" width="fullWidth" onClick={() => showModal("currency")}>
            Currency rates
          </Button>

          <RemoveApproval onClose={closeMenu} />
        </DropdownMenu>
      </div>
    </>
  );
};
