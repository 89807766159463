import {useState, useEffect, useMemo} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useSelect} from "hooks/inputHooks";
import {common as commonApi} from "api/common";
import {iAddress, iCountryListItem} from "api/common/types";
import {mapboxSearchAddress} from "api/mapbox";

export type tUseAddressField = ReturnType<typeof useAddressField>;

interface iUseAddressField {
  address?: iAddress;
  isRequired?: boolean;
  onChange?: (data: any) => void;
  countryOptions?: Array<iCountryListItem>;
  raw?: string | null;
  coordinates?: {lat: number | null; lng: number | null} | undefined | null;
}

const addressModel = {
  country: "",
  post_code: "",
  city: "",
  street: "",
  state: "",
  region: "",
  coordinates: {}
};

export function useAddressField(props?: iUseAddressField) {
  const {call: callStates, data: statesData} = useHttp();

  const [errors, setErrors] = useState<string[]>([]);
  const [isRequired, setIsRequired] = useState(props?.isRequired !== undefined ? props?.isRequired : true); // prettier-ignore

  const [manualEntry, setManualEntry] = useState(Boolean(props?.address?.street));
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const [options, setOptions] = useState([]); // for AddressFieldSearch
  const [isDirty, setIsDirty] = useState(false);
  const [coordinates, setCoordinates] = useState<any>(props?.coordinates || {lat: null, lng: null});

  const addressData = {
    street: useTextInput({
      isRequired,
      value: props?.address?.street
    }),
    apartment: useTextInput({value: props?.address?.apartment, isRequired: false}),
    city: useTextInput({
      isRequired,
      value: props?.address?.city
    }),
    post_code: useTextInput({
      isRequired,
      value: props?.address?.post_code
    }),
    country: useSelect({
      isRequired,
      value: props?.address?.country?.iso,
      targetKeys: {value: "iso", label: "name"}
    }),
    // for USA
    state: useSelect({
      isRequired,
      value: props?.address?.state
    }),
    // for other countries
    region: useTextInput({
      isRequired,
      value: props?.address?.state
    })
  };

  const isDirtyManualEntry = Object.values(addressData).some(v => v.isDirty);
  const isUsaSelected = addressData.country.value === "US";

  useEffect(() => {
    if (props?.raw && !props?.address?.city) {
      const raw = props?.raw?.replace(/\s+/g, " ").trim();
      mapboxSearchAddress({value: raw}).then(({data}) => {
        handleChangeAddress(data.features?.[0]);
      });
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (isDirtyManualEntry && !isDirty) {
      setIsDirty(true);
    }
  }, [isDirtyManualEntry, isDirty]);

  // get states if US country selected
  useEffect(() => {
    if (!statesData?.data?.data && addressData.country.value === "US") {
      callStates(commonApi.getUsStates());
    }
  }, [statesData?.data?.data, addressData.country.value]); // eslint-disable-line

  useEffect(() => {
    if (Array.isArray(props?.countryOptions)) {
      addressData.country.setOptions(props?.countryOptions);
    }
  }, [props?.countryOptions]); // eslint-disable-line

  useEffect(() => {
    if (statesData?.data?.data && isUsaSelected) {
      addressData.state.setOptions(statesData?.data?.data);
    }
  }, [statesData?.data?.data, isUsaSelected]); // eslint-disable-line

  const checkValidity = () => {
    if (manualEntry) {
      const addressDataToFilter: Partial<typeof addressData> = addressData;
      isUsaSelected ? delete addressDataToFilter?.region : delete addressDataToFilter?.state;
      const invalidFields = Object.values(addressDataToFilter).filter(value => !value?.checkValidity()); // prettier-ignore
      return !invalidFields.length;
    } else {
      let errors = !selectedAddress && isRequired ? ["This field can't be empty"] : [];
      setErrors(errors);
      return !errors.length;
    }
  };

  const clearAddressData = () => {
    Object.entries(addressData).forEach(([key, value]) => {
      value.setValue("");

      if (key === "country") {
        value.setErrors([]);
        return;
      }
      value.setErrors([]);
    });
  };

  // when address search field is selected fills all manual inputs
  const handleChangeAddress = (option: any | null) => {
    clearAddressData();
    if (!option) {
      setOptions([]);
      return setSelectedAddress(null);
    }

    if (!option?.context) return;

    const {context, place_type, place_name, text, address} = option;

    const isUsCountry = Boolean(
      context?.find((value: {id: string; short_code: string}) => {
        return value?.id?.includes("country") && value.short_code === "us";
      })
    );

    let newAddress = {
      ...addressModel,
      place_name,
      coordinates: {lat: option?.center?.[1], lng: option?.center?.[0]}
    };

    setCoordinates({lat: option?.center?.[1], lng: option?.center?.[0]});

    if (place_type?.includes("address")) {
      const value = `${address ? address + " " : ""}${text}`;
      newAddress.street = value;
      addressData.street.setValue(value);
    }

    context?.forEach((item: any) => {
      const value = item?.text;

      if (item.id.includes("country")) {
        newAddress.country = item?.short_code?.toUpperCase();
        addressData.country.setValue(item?.short_code?.toUpperCase());
      }
      if (item.id.includes("postcode")) {
        newAddress.post_code = value;
        addressData.post_code.setValue(value);
      }
      if (item.id.includes("place")) {
        newAddress.city = value;
        addressData.city.setValue(value);
      }
      if (item.id.includes("region")) {
        if (isUsCountry) {
          newAddress.state = value;
          addressData.state.setValue(value);
        } else {
          newAddress.region = value;
          addressData.region.setValue(value);
        }
      }
    });

    !manualEntry && setManualEntry(true);
    setSelectedAddress({...newAddress});
    !isDirty && setIsDirty(true);
  };

  const setValue = (address: iAddress, coordinates?: {lat: number | null; lng: number | null}) => {
    const isUsa = address?.country?.iso === "US";
    !manualEntry && setManualEntry(true);

    setCoordinates(coordinates);
    coordinates?.lat && setSelectedAddress({coordinates});

    Object.entries(address).forEach(([key, value]) => {
      if (key === "country" && typeof value !== "string") {
        addressData.country.setValue(String(value?.iso));
        return;
      }
      const newValue = value as string;
      if (key === "state") {
        isUsa ? addressData.state.setValue(newValue) : addressData.region.setValue(newValue);
        return;
      }

      addressData[key as keyof typeof addressData]?.setValue(newValue || "");
    });
  };

  const changeEntryMethod = () => {
    if (manualEntry) {
      let shouldUpdate = false;
      Object.entries(addressData).forEach(([key, item]) => {
        if (shouldUpdate || key === "apartment") return;
        if (isUsaSelected && key === "state" && selectedAddress?.[key] !== item?.value) {
          shouldUpdate = true;
          return;
        }
        if (!isUsaSelected && key === "region" && selectedAddress?.[key] !== item?.value) {
          shouldUpdate = true;
          return;
        }
        if (selectedAddress?.[key] !== item?.value) {
          shouldUpdate = true;
        }
      });
      shouldUpdate && setSelectedAddress(null);
    }
    setErrors([]);
    setManualEntry(pv => !pv);
  };

  const address = useMemo(() => {
    return {
      street: addressData.street.value,
      city: addressData.city.value,
      state: isUsaSelected ? addressData.state.value : addressData.region.value,
      post_code: addressData.post_code.value,
      country_iso: addressData?.country?.value,
      ...(addressData.apartment.value && {apartment: addressData.apartment.value})
    };
    // eslint-disable-next-line
  }, [
    addressData.street.value,
    addressData.city.value,
    addressData.state.value,
    addressData.region.value,
    addressData.post_code.value,
    addressData?.country?.value,
    addressData.apartment.value
  ]);

  useEffect(() => {
    props?.onChange?.(address);
  }, [address]); // eslint-disable-line

  const setCountries = (countries: Array<iCountryListItem>) => {
    if (countries) {
      addressData.country.setOptions(countries);
    }
  };

  const isChanged = () => {
    let status = false;
    let propsAddress = Object.entries(props?.address ? props?.address : []);

    if (!propsAddress?.length && !!Object.values(address)?.filter(k => k).length) {
      return true;
    }

    for (let [key, value] of propsAddress) {
      // @ts-ignore
      // eslint-disable-next-line no-negated-in-lhs
      if (!key in address) {
        return;
      }
      // @ts-ignore
      if (key === "country" && value?.iso !== address?.country_iso) {
        status = true;
        break;
      }
      // @ts-ignore
      if (address?.[key] && address?.[key] !== value) {
        status = true;
        break;
      }
    }

    return status;
  };

  return {
    addressData,
    countries: props?.countryOptions,
    setCountries,
    checkValidity,
    setValue,

    coordinates,

    options,
    setOptions,

    manualEntry,
    setManualEntry,
    changeEntryMethod,

    selectedAddress,
    handleChangeAddress,

    errors,
    setErrors,

    isDirty,
    setIsDirty,

    isRequired,
    setIsRequired,

    isUsaSelected,
    isChanged: isChanged(),

    address,
    value: address
  };
}
