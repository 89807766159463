import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useAddressField} from "components/shared/FormComponents/AddressField/useAddressField";
import {iPersonDetails} from "../../../interfaces";
import {useHttp} from "hooks/httpServices/useHttp";
import {common} from "api/common";
import {usePhoneInput} from "hooks/usePhoneInput";
import {lengthValidation} from "hooks/useTextInput/validators";

export type tUsePeopleFormAddress = ReturnType<typeof usePeopleFormAddress>;

interface iProps {
  person: iPersonDetails | undefined | null;
}
export const usePeopleFormAddress = ({person}: iProps) => {
  const {data: countriesData} = useHttp({config: common.getCountries(), onMountCall: true});

  const formData = {
    billing_info: {
      first_name: useTextInput({
        isRequired: false,
        value: person?.billing_info?.first_name || "",
        validators: [lengthValidation(2)]
      }),
      last_name: useTextInput({
        value: person?.billing_info?.last_name || "",
        validators: [lengthValidation(2)],
        isRequired: false
      }),
      email: useTextInput({
        value: person?.billing_info?.email || "",
        validators: ["email"],
        filters: ["email"],
        isRequired: false
      }),
      phone: useTextInput({
        validators: ["phone"],
        filters: ["phone"],
        isRequired: false,
        value: person?.billing_info?.phone || ""
      }),
      address: useAddressField({
        isRequired: false,
        countryOptions: countriesData?.data?.data,
        address: person?.billing_info?.address,
        raw: person?.billing_info?.address?.raw
      })
    },
    address: useAddressField({
      isRequired: false,
      countryOptions: countriesData?.data?.data,
      address: person?.address,
      raw: person?.address?.raw
    })
  };

  const isChanged = Object.values(formData.billing_info).some(field => field.isChanged) || formData.address.isChanged; // prettier-ignore

  return {formData, isChanged};
};
