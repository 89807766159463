import React, {FC} from "react";
import {Icon, iPropsIcon} from "components/Icon/Icon";
import "./ViewerBtn.scss";

export type tViewerBtnProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  icon: iPropsIcon["icon"];
  color?: iPropsIcon["color"];
  size?: iPropsIcon["size"];
};

export const ViewerBtn: FC<tViewerBtnProps> = ({onClick, icon, color = "gray", size = "md"}) => {
  return (
    <button className="ViewerBtn" onClick={onClick}>
      <Icon color={color} size={size} icon={icon} />
    </button>
  );
};
