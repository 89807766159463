import {FC} from "react";
import {tTrackTotalItems} from "api/budget/track/types";
import {useGetCategoryFooterCells} from "./useGetCategoryFooterCells";
import "./CategoryFooter.scss";

type tCategoryFooterProps = {
  data: tTrackTotalItems;
};

export const CategoryFooter: FC<tCategoryFooterProps> = ({data}) => {
  const {cells} = useGetCategoryFooterCells({data});

  return (
    <tr className="CategoryFooter">
      {cells({className: "CategoryFooter-cell"}).map((item, i) => {
        return (
          <td key={i} colSpan={!!item.colSpan ? item.colSpan : 1}>
            {item.component}
          </td>
        );
      })}
    </tr>
  );
};
