import {FC} from "react";
import {Header} from "components/Header/Header";
import {AddLocation} from "./AddLocation/AddLocation";
import {LocationsTableRow} from "./LocationsTable/LocationsTableRow/LocationsTableRow";
import {LocationsTableFilter} from "./LocationsTable/LocationsTableFilter/LocationsTableFilter";
import {Table} from "components/Table/Table";
import {TableToolbar} from "components/TableToolbar/TableToolbar";
import {iLocationsListItemResponse} from "api/location/types";
import {LocationsContextProvider, useContextLocations} from "./context/LocationsContext";
import {Pagination} from "components/Pagination/Pagination";
import {useApiListNotification} from "hooks/useApiListNotification/useApiListNotification";
import {TableHeadItem} from "components/Table/TableHeadItem";
import {locationsTableColumn} from "./LocationsTable/locationsTableColumn";
import {TeamDetailsContextProvider} from "pages/Team/TeamDetailsContext";
import {TeamContextProvider} from "pages/Team/TeamContext";
import {ContactsContextProvider} from "pages/Contacts/context/ContactsContext";
import {ContactsDetailsContextProvider} from "pages/Contacts/context/ContactsDetailsContext";
import {ImportLocation} from "./ImportLocation/ImportLocation";

import "./Locations.scss";

const LocationsComponent: FC = () => {
  const {locationList} = useContextLocations();
  const {data, isLoading, isError, error} = locationList;
  const list = data?.data?.data || [];

  const {element} = useApiListNotification({isLoading, isError, error, list});

  const renderHeaderList = () => {
    return locationsTableColumn.map(item => <TableHeadItem key={item.id} data={item} />);
  };

  const renderBodyList = () => {
    if (!list?.length && !isError) return element;
    return list?.map((location: iLocationsListItemResponse) => (
      <LocationsTableRow key={location?.id} data={location} />
    ));
  };

  return (
    <div className="Locations pos">
      <Header
        title="Location Directory"
        children={
          <>
            <ImportLocation />
            <AddLocation />
          </>
        }
      />

      <div className="content-padding">
        <TableToolbar
          titleChildren={!!list?.length ? `Showing ${list?.length} locations` : ""}
          children={<LocationsTableFilter />}
        />

        <Table
          scroll
          isLoading={isLoading}
          headerRowChildren={renderHeaderList()}
          bodyChildren={renderBodyList()}
        />

        {data && <Pagination meta={data?.data?.meta} />}
      </div>
    </div>
  );
};

const Locations = () => (
  <LocationsContextProvider>
    <ContactsContextProvider>
      <ContactsDetailsContextProvider>
        <TeamContextProvider>
          <TeamDetailsContextProvider>
            <LocationsComponent />
          </TeamDetailsContextProvider>
        </TeamContextProvider>
      </ContactsDetailsContextProvider>
    </ContactsContextProvider>
  </LocationsContextProvider>
);

export default Locations;
