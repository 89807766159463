import {AxiosRequestConfig} from "axios";
import {changePassData, iUpdateProfileData} from "./types";

const profile = "profile";

export const profileInfo = {
  getUserProfile: () => ({
    url: profile
  }),
  updateUserProfile: (data: iUpdateProfileData): AxiosRequestConfig => ({
    url: profile,
    method: "POST",
    data
  }),
  changePassword: (data: changePassData): AxiosRequestConfig => ({
    url: `${profile}/change-password`,
    method: "POST",
    data
  }),
  setOnboardingDone: (): AxiosRequestConfig => ({
    url: `${profile}/onboarding`,
    method: "POST"
  }),
  setTooltips: (data: {tooltips: Array<string>}): AxiosRequestConfig => ({
    url: `${profile}/tooltips`,
    method: "POST",
    data
  }),
  resetTooltips: (): AxiosRequestConfig => ({
    url: `${profile}/tooltips`,
    method: "DELETE"
  })
};
