import user from "assets/images/user.svg";
import bag from "assets/images/bag.svg";
import home from "assets/images/home.svg";
import squares from "assets/images/squares.svg";

export const steps = [
  {
    icons: [bag, user, home],
    title: "",
    body: "The Versatile Studios Hub is where you’ll find everything related to company jobs, contacts and locations.",
    buttonTitle: "Next"
  },
  {
    icons: [bag],
    title: "Jobs",
    body: "Jobs let you build estimates, track expenses, manage crew contacts, generate call sheets, organize team tasks and much more!",
    buttonTitle: "Next"
  },
  {
    icons: [squares],
    title: "Dashboard",
    body: "The dashboard is an overview of the jobs assigned to you. Use the calendar view to see what’s coming up or click on a job to see its details.",
    buttonTitle: "Done"
  }
] as const;
