import {useEffect, useState} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {tEstimateExportInfo, tEstimateGroup} from "api/budget/estimate/types";
import {estimateApi} from "api/budget/estimate";
import {useContextJobBudget} from "./JobBudgetContext";
import {useGeneratePdfData} from "hooks/useGeneratePdfData";
import {EstimateOverlayDocument} from "pages/Jobs/JobDetails/JobBudget/Estimate/EstimateOverlay/EstimateOverlayDocument";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import moment from "moment";
import {prepareName} from "helpers/fileNaming";

export type tUseExportBudgetReturn = ReturnType<typeof useExportBudget>;

export function useExportBudget() {
  const exportInfoQuery = useHttp();
  const {estimate} = useContextJobBudget();
  const {jobDetailsData} = useContextJobDetails();
  const {generatePdfData, pdfData} = useGeneratePdfData();
  const {generatePdfData: generatePdfDataA2, pdfData: pdfDataA2} = useGeneratePdfData();
  const [exportInfo, setExportInfo] = useState<tEstimateExportInfo | null>(null);

  useEffect(() => {
    if (estimate?.id) {
      setExportInfo(null);
      exportInfoQuery
        .call(estimateApi.getExportInfo(estimate?.id))
        .then(({data: {data}}) => {
          const info = getClearEstimateExportInfo(data);
          setExportInfo(info);
          pdfData?.url && updatePdfData(info);
        })
        .catch(error => console.log(error));
    }
  }, [estimate?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!pdfData?.url) {
      updatePdfData();
    }
  }, [jobDetailsData, exportInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  async function updatePdfData(info?: tEstimateExportInfo) {
    if (!exportInfo || !jobDetailsData) return;
    if (!exportInfo.estimate.approved_at) {
      generatePdfDataA2(<EstimateOverlayDocument format="A2" jobDetailsData={jobDetailsData} exportInfo={info || exportInfo} />); // prettier-ignore
    }
    generatePdfData(<EstimateOverlayDocument format="A4" jobDetailsData={jobDetailsData} exportInfo={info || exportInfo} />); // prettier-ignore
  }

  function getPdfData(format: "A4" | "A2", data: tEstimateExportInfo) {
    if (!jobDetailsData || !exportInfo) return;
    return generatePdfData(<EstimateOverlayDocument  format={format} jobDetailsData={jobDetailsData} exportInfo={data} />); // prettier-ignore
  }

  const onSaveExportInfoCallback = (formData: any) => {
    let payload: any = Object.entries(formData).reduce((acc, [key, item]: any) => {
      if (key === "dates") {
        return {...acc, job_start_at: item.value?.[0], job_end_at: item.value?.[1]};
      }
      return {...acc, [key]: item.value};
    }, {});

    payload["extended"] = [
      {label: payload?.label1, text: payload.text1},
      {label: payload?.label2, text: payload.text2}
    ];

    setExportInfo(prevState => (prevState ? {...prevState, ...payload} : null));
  };

  const onDownloadPdf = async () => {
    const name = prepareName(
      [
        moment().format("YYYYMMDD"),
        jobDetailsData?.name || "",
        String(jobDetailsData?.id) || "",
        estimate?.name || ""
      ],
      "_ESTIMATE.pdf"
    );
    if (!exportInfo) return console.log("exportInfo doesn't exist");
    const fileData = await getPdfData("A4", exportInfo);
    if (!fileData?.url) return console.log("fileData?.url doesn't exist");
    const link = document.createElement("a");
    link.href = fileData.url;
    link.download = name;
    link.click();
    link.remove();
  };

  useEffect(() => {
    setExportInfo(prevState =>
      prevState
        ? {
            ...prevState,
            estimate: {...prevState.estimate, approved_at: estimate?.approved_at || null}
          }
        : null
    );
  }, [estimate?.approved_at]);

  return {
    pdfData,
    pdfDataA2,
    exportInfo,
    setExportInfo,
    exportInfoQuery,
    onSaveExportInfoCallback,
    updatePdfData,
    onDownloadPdf,
    getPdfData
  };
}

function getClearEstimateExportInfo(data: tEstimateExportInfo) {
  return {
    ...data,
    estimate: {
      ...data.estimate,
      category_values: data.estimate.category_values.reduce<tEstimateGroup[]>((acc, item) => {
        const notEmptyCostValues = item?.cost_values?.filter(item => !item?.is_empty);
        const emptyItem = !notEmptyCostValues?.length;

        if (emptyItem) return acc;
        return [...acc, {...item, cost_values: notEmptyCostValues}];
      }, [])
    }
  };
}
