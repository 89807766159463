import {useEffect, useState} from "react";
import {tTermsConditions} from "api/budget/termsConditions/types";
import {tBankDetails} from "api/budget/bankDetails/types";
import {useHttp} from "hooks/httpServices/useHttp";
import {termsConditionsApi} from "api/budget/termsConditions";
import {bankDetailsApi} from "api/budget/bankDetails";

export function useBudgetOverlayTermsBank() {
  const termsListQuery = useHttp();
  const bankListQuery = useHttp();

  const [termsList, setTermsList] = useState<tTermsConditions[]>([]);
  const [bankList, setBankList] = useState<tBankDetails[]>([]);

  useEffect(() => {
    termsListQuery.call(termsConditionsApi.getTermsConditionsList()).then(({data: {data}}) => setTermsList(data)); // prettier-ignore
    bankListQuery.call(bankDetailsApi.getBankDetailsList()).then(({data: {data}}) => setBankList(data)); // prettier-ignore
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {termsList, setTermsList, bankList, setBankList};
}
