import {FC} from "react";
import {BudgetOverlayPageLayout} from "../../../OverlayTemplates/BudgetOverlayPageLayout/BudgetOverlayPageLayout";
import {BudgetOverlayTitle} from "../../../OverlayTemplates/BudgetOverlayTitle/BudgetOverlayTitle";
import {EstimateJobEdit} from "./EstimateJobEdit";
import {EstimateProductionCosts} from "./EstimateProductionCosts";
import {EstimateConfirmation} from "./EstimateConfirmation";
import {EstimateTotal} from "./EstimateTotal";
import EstimateOverlay from "../../EstimateOverlay/EstimateOverlay";

import "./EstimateExportBuilder.scss";

interface iEstimateExportBuilderProps {
  exportInfo: any;
  onSaveExportInfoCallback: any;
  pdfDataA2: any;
  jobDetailsData: any;
}

export const EstimateExportBuilder: FC<iEstimateExportBuilderProps> = ({
  exportInfo,
  onSaveExportInfoCallback,
  pdfDataA2,
  jobDetailsData
}) => {
  if (!exportInfo || !jobDetailsData) return null;

  return (
    <div className="EstimateExportBuilder">
      <BudgetOverlayPageLayout page={1} total={1}>
        <BudgetOverlayTitle>ESTIMATE SUMMARY</BudgetOverlayTitle>
        <EstimateJobEdit
          jobDetailsData={jobDetailsData}
          exportInfo={exportInfo}
          onSaveExportInfoCallback={onSaveExportInfoCallback}
        />
        <EstimateProductionCosts estimate={exportInfo.estimate} />
        <div className="EstimateExportBuilder-twoColumn">
          <EstimateConfirmation clientName={jobDetailsData?.client?.name || ""} />
          <EstimateTotal estimate={exportInfo.estimate} />
        </div>
      </BudgetOverlayPageLayout>
      {pdfDataA2?.url && <EstimateOverlay pdfUrl={pdfDataA2.url} />}
    </div>
  );
};
