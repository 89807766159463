import {FC} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {tEditEstimateTotalDetailsForm} from "./useUpdateTotalEstimate";

type tInputsBudgetCostsProps = {
  form: tEditEstimateTotalDetailsForm;
};

export const InputsBudgetCosts: FC<tInputsBudgetCostsProps> = ({form}) => {
  return (
    <>
      <TextComponent size="15" uppercase m="mb-8">
        BUDGETED COSTS
      </TextComponent>

      <InputField
        typeField="horizontalLabel"
        label="Crew Payroll Fee"
        {...form.crew_payroll_fee_percent.inputProps}
        suffix="%"
        errors={form.crew_payroll_fee_percent.errors}
      />

      <InputField
        typeField="horizontalLabel"
        label="Talent Payroll Fee"
        {...form.talent_payroll_fee_percent.inputProps}
        suffix="%"
        errors={form.talent_payroll_fee_percent.errors}
      />

      <InputField
        typeField="horizontalLabel"
        label="Union Crew Payroll Fee"
        {...form.union_crew_payroll_fee_percent.inputProps}
        suffix="%"
        errors={form.union_crew_payroll_fee_percent.errors}
      />

      <InputField
        typeField="horizontalLabel"
        label="Union Talent Payroll Fee"
        {...form.union_talent_payroll_fee_percent.inputProps}
        suffix="%"
        errors={form.union_talent_payroll_fee_percent.errors}
      />
    </>
  );
};
