import {AxiosRequestConfig} from "axios";

const job_crew = "job_crew";
const contact = "contact";

export type tCrewApiNames = keyof tCrewApi;
export type tCrewApi = {
  position: {position_id: number};
  phone: {default_phone_id: number};
  email: {default_email_id: number};
  billing: {billing: Array<number>};
  notes: {notes: string};
  hold: {hold_status_id: number | null; start_at?: number; end_at?: number};
  rate: {rate: string};
  linkAgent: {agent_id: number};
  unLinkAgent: undefined;
  deleteContacts: {contacts: Array<number>};
  contactOrder: {order: number};
};

export const jobCrewContact = {
  linkAgentToContact: (
    job_crew_contact_id: number,
    data: tCrewApi["linkAgent"]
  ): AxiosRequestConfig => ({
    url: `${job_crew}/${contact}/${job_crew_contact_id}/agent`,
    method: "POST",
    data
  }),
  unLinkAgentToContact: (job_crew_contact_id: number): AxiosRequestConfig => ({
    url: `${job_crew}/${contact}/${job_crew_contact_id}/agent`,
    method: "DELETE"
  }),
  deleteContacts: (job_crew_id: number, data: tCrewApi["deleteContacts"]): AxiosRequestConfig => ({
    url: `${job_crew}/${job_crew_id}/contacts`,
    method: "DELETE",
    data
  }),
  setPositionForContact: (
    job_crew_contact_id: number,
    data: tCrewApi["position"]
  ): AxiosRequestConfig => ({
    url: `${job_crew}/${contact}/${job_crew_contact_id}/position`,
    method: "POST",
    data
  }),
  setDefaultPhone: (job_crew_contact_id: number, data: tCrewApi["phone"]): AxiosRequestConfig => ({
    url: `${job_crew}/${contact}/${job_crew_contact_id}/default_phone`,
    method: "POST",
    data
  }),
  setDefaultEmail: (job_crew_contact_id: number, data: tCrewApi["email"]): AxiosRequestConfig => ({
    url: `${job_crew}/${contact}/${job_crew_contact_id}/default_email`,
    method: "POST",
    data
  }),
  setRate: (job_crew_contact_id: number, data: tCrewApi["rate"]): AxiosRequestConfig => ({
    url: `${job_crew}/${contact}/${job_crew_contact_id}/rate`,
    method: "POST",
    data
  }),
  setHoldDateForContact: (
    job_crew_contact_id: number,
    data: tCrewApi["hold"]
  ): AxiosRequestConfig => ({
    url: `${job_crew}/${contact}/${job_crew_contact_id}/hold`,
    method: "POST",
    data
  }),
  setBillingForContact: (
    job_crew_contact_id: number,
    data: tCrewApi["billing"]
  ): AxiosRequestConfig => ({
    url: `${job_crew}/${contact}/${job_crew_contact_id}/billing`,
    method: "POST",
    data
  }),
  setNotesForContact: (
    job_crew_contact_id: number,
    data: tCrewApi["notes"]
  ): AxiosRequestConfig => ({
    url: `${job_crew}/${contact}/${job_crew_contact_id}/notes`,
    method: "POST",
    data
  }),
  updateContactOrder: (
    job_crew_contact_id: number | string,
    data: tCrewApi["contactOrder"]
  ): AxiosRequestConfig => ({
    url: `${job_crew}/${contact}/${job_crew_contact_id}/order`,
    method: "POST",
    data
  }),
  moveContactToGroup: (data: {contacts: Array<number>; group_id: number}): AxiosRequestConfig => ({
    url: `${job_crew}/${contact}/move`,
    method: "POST",
    data
  })
};
