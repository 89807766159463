import {FC} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {useContextJobCalendar} from "../../context";
import {jobsCalendarApi} from "api/jobs/calendar";
import {useHttp} from "hooks/httpServices/useHttp";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useSelect} from "hooks/inputHooks";
import {Select} from "components/shared/FormComponents/Select/Select";
import {eventBus} from "EventBus/EventBus";
import {useParams} from "react-router-dom";

interface iSaveAsExistingTemplateProps {
  onClose: () => void;
}

export const SaveAsExistingTemplate: FC<iSaveAsExistingTemplateProps> = ({onClose}) => {
  const {calendarId} = useParams();
  const {call, isLoading} = useHttp();
  const {getTemplates, calendarTemplatesList} = useContextJobCalendar();

  const template = useSelect({isRequired: true, value: String(calendarTemplatesList?.[0]?.id)});

  const onSave = async () => {
    if (!template.checkValidity() || !calendarId) return;

    try {
      await call(jobsCalendarApi.updateTemplateFromCalendar(Number(calendarId), Number(template.value))); // prettier-ignore
      getTemplates();
      eventBus.dispatch("showToast", {text: "Template saved"});
      onClose();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <ConfirmWindow
      show={true}
      className="SaveAsExistingTemplate"
      onClose={onClose}
      title="SAVE AS EXISTING TEMPLATE"
      subTitle="Choose a template to replace"
      cancelBtn={{
        text: "Cancel",
        onClick: onClose
      }}
      confirmBtn={{
        text: "Save"
      }}
      onConfirm={onSave}
      isLoading={isLoading}
    >
      <Select
        {...template.inputProps}
        targetKeys={{value: "id", label: "name"}}
        options={calendarTemplatesList}
        errors={template.errors}
      />
    </ConfirmWindow>
  );
};
