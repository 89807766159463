import {useEffect} from "react";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {iCompanyDetails} from "api/contacts/companies/interfaces";
import {useCheckBoxGroupe} from "hooks/inputHooks/useCheckBoxGroupe";
import {useContextBasicData} from "context/BasicDataContext/BasicDataContext";

export type tUseCompanyFormOther = ReturnType<typeof useCompanyFormOther>;
interface iProps {
  company?: iCompanyDetails | null;
  tab: number;
}
export const useCompanyFormOther = ({company, tab}: iProps) => {
  const {departments, getData} = useContextBasicData();

  const departmentsInit = company?.departments.map(item => item?.id) || [];

  useEffect(() => {
    if (tab === 2) {
      !departments?.length && getData("departments");
    }
  }, [tab]); // eslint-disable-line

  const formData = {
    departments: useCheckBoxGroupe({isRequired: false, initValue: departmentsInit}),
    notes: useTextInput({
      value: company?.notes ?? "",
      isRequired: false
    })
  };
  const isChanged = Object.values(formData).some(field => field.isChanged);

  return {formData, isLoading: !departments?.length, departments, isChanged};
};
