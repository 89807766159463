import {createContext, FC, useContext, useEffect, useLayoutEffect, useState} from "react";
import {gridApi} from "api/jobs/grid";
import {tJobGrid} from "api/jobs/grid/types";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {eventBus} from "EventBus/EventBus";
import {useNavigate, useParams} from "react-router-dom";
import {routes} from "router";

export const useGetJobGrid = () => {
  const {jobDetailsData} = useContextJobDetails();
  const id = jobDetailsData?.id;
  const navigate = useNavigate();
  const {main, details} = routes?.jobs;
  const mainGrindPath = `/${main}/${id}/${details?.grids?.main}`;
  const gridId = useParams()?.[routes?.jobs?.details?.grids?.gridId?.slice(1)];

  const {call: callList, isLoading: listLoading} = useHttp({cancelRequest: false});
  const [gridList, setGridList] = useState<Array<tJobGrid> | null>(null);

  const [grid, setGrid] = useState<tJobGrid | null>(null);
  const [currGridId, setCurrGridId] = useState<number | null>(null);
  const {call, isLoading} = useHttp();

  useLayoutEffect(() => {
    getGridList();
  }, []); // eslint-disable-line

  const getGridList = async () => {
    if (!id) return;

    try {
      const res = await callList(gridApi.getGridList(id));
      const data = res?.data?.data as Array<tJobGrid>;
      setGridList(() => data);

      if (!data?.[0]?.id) {
        navigate(mainGrindPath);
        return setGrid(null);
      }

      if (!!gridId) {
        setCurrGridId(+gridId);
        return navigate(gridId);
      }

      setCurrGridId(data?.[0]?.id);
      navigate(data?.[0]?.id.toString());
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error getting grid list!"});
      setGrid(null);
      navigate(mainGrindPath);
    }
  };

  const getGrid = async () => {
    if (!currGridId) return;
    try {
      const res = await call(gridApi.getGrid(currGridId));
      const gridData = res?.data?.data;
      setGrid(gridData);
      if (gridData?.id.toString() !== gridId?.toString()) {
        navigate(gridData?.id.toString());
      }
    } catch (error) {
      // const {msg} = apiErrorHandler(error);
      // eventBus.dispatch("showToast", {type: "error", text: msg || "Error getting grid!"});
      if (gridList?.[0]?.id) {
        setCurrGridId(gridList?.[0]?.id);
        navigate(gridList?.[0]?.id.toString());
      }
      !gridList?.[0]?.id && navigate(mainGrindPath);
    }
  };

  useEffect(() => {
    getGrid();
  }, [currGridId]); // eslint-disable-line

  return {grid, setGrid, gridList, setGridList, listLoading, isLoading, setCurrGridId, getGridList};
};

type tJobGridContext = ReturnType<typeof useGetJobGrid>;
const JobGridContext = createContext<tJobGridContext | null>(null);
JobGridContext.displayName = "JobGridContext";
export const JobGridContextProvider: FC = ({children}) => (
  <JobGridContext.Provider value={useGetJobGrid()}>{children}</JobGridContext.Provider>
);

export const useContextJobGrid = () => {
  const context = useContext(JobGridContext);

  if (context === null) {
    throw new Error("<<< JobGridContext >>> must be used within a JobGridContextProvider");
  }

  return context as tJobGridContext;
};
