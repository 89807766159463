import {FC} from "react";
import {FormWrap} from "components/shared/FormWrap/FormWrap";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {InputsBudgetCosts} from "./InputsBudgetCosts";
import {InputsGrandTotal} from "./InputsGrandTotal";
import {useUpdateTotalEstimate} from "./useUpdateTotalEstimate";
import "./EditEstimateForm.scss";

type tEditEstimateFormProps = {
  onClose: () => void;
};

export const EditEstimateForm: FC<tEditEstimateFormProps> = ({onClose}) => {
  const {onSubmit, FORM_ID, form, isLoading} = useUpdateTotalEstimate({onClose});

  return (
    <ModalPopup show={true} onClose={onClose} withoutPaddings size="w-528">
      <FormWrap
        idForm={FORM_ID}
        headerTitle="Edit total details"
        submitTitle="Save"
        cancelTitle="Cancel"
        onCancel={onClose}
        isLoading={isLoading}
        type="titleCenterAndTwoBtn"
      >
        <form className="EditEstimateForm" onSubmit={onSubmit} id={FORM_ID}>
          <InputsBudgetCosts form={form} />

          <InputsGrandTotal form={form} />

          <TextComponent size="15" uppercase m={["mb-8", "mt-24"]}>
            Advance
          </TextComponent>

          <InputField
            typeField="horizontalLabel"
            label="Advance Required"
            {...form.advance_required_percent.inputProps}
            errors={form.advance_required_percent.errors}
            suffix="%"
          />
        </form>
      </FormWrap>
    </ModalPopup>
  );
};
