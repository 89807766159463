import {FC} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {Icon} from "components/Icon/Icon";
import {Loading} from "components/shared/Loading/Loading";
import {Button} from "components/shared/Button/Button";
import "./ManageTagsList.scss";

type tItem = {id: number; name: string};

interface iManageTagsListProps {
  show: boolean;
  onClose: () => void;
  onEdit: (item: tItem) => void;
  onDelete: (item: tItem) => void;
  showAddModal: () => void;
  data?: tItem[];
  type: "locale" | "label";
  isLoading: boolean;
}

export const ManageTagsList: FC<iManageTagsListProps> = ({
  show,
  onClose,
  onEdit = () => {},
  showAddModal = () => {},
  onDelete,
  data = [],
  type,
  isLoading
}) => {
  return (
    <ModalPopup show={show} onClose={onClose} className="ManageTagsList-modal">
      <div className="ManageTagsList">
        {(!data || isLoading) && <Loading type="inBlock" />}
        {data && (
          <>
            <h2 className="ManageTagsList-title">Manage {type}s</h2>
            <ul className="ManageTagsList-list">
              {data.map(item => (
                <li key={item.id} className="ManageTagsList-list-item">
                  <span>{item.name}</span>
                  <div className="ManageTagsList-list-actions">
                    <Button onClick={() => onEdit(item)}>
                      <Icon icon="edit" size="md" color="gray" />
                    </Button>
                    <Button onClick={() => onDelete(item)}>
                      <Icon icon="delete" size="md" color="gray" />
                    </Button>
                  </div>
                </li>
              ))}
            </ul>
            <div className="ManageTagsList-footer">
              <Button onClick={showAddModal} color="outline" size="sm">
                <Icon icon="add" size="xsm" /> {type}
              </Button>
            </div>
          </>
        )}
      </div>
    </ModalPopup>
  );
};
