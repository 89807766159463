import {FC} from "react";
import {tUseJobTagsFieldReturn} from "pages/Jobs/JobDetails/JobDetailsModals/EditJobDetails/JobTagsField/useJobTagsField";
import {TagButton} from "components/shared/TagButton/TagButton";

type tTagsRowProps = {
  contacts: tUseJobTagsFieldReturn;
};

export const TagsRow: FC<tTagsRowProps> = ({contacts}) => {
  return (
    <div className="TagsRow">
      {contacts.value?.map(item => {
        return (
          <TagButton
            color="neutral"
            size="sm"
            key={item?.id}
            id={item?.id}
            onRemove={contacts.onRemove}
          >
            {item?.name}
          </TagButton>
        );
      })}
    </div>
  );
};
