import {FC, ReactNode} from "react";
import {iTeammateDetailsData} from "api/team/types";
import {ImgWithFallback} from "components/shared/ImgWithFallback/ImgWithFallback";
import {LetterAvatar} from "components/shared/LetterAvatar/LetterAvatar";
import {DepartmentList} from "components/shared/DepartmentList/DepartmentList";
import "./TeamMemberHeader.scss";

interface iTeamMemberHeader {
  teammateDetail: iTeammateDetailsData;
  children: ReactNode;
  className?: string;
}

export const TeamMemberHeader: FC<iTeamMemberHeader> = ({
  teammateDetail,
  children,
  className = ""
}) => {
  const {avatar_url, display_name, email, departments} = teammateDetail;
  const fullName = `${teammateDetail?.first_name || ""} ${teammateDetail?.last_name || ""}`;

  return (
    <div className={`TeamMemberHeader ${className}`}>
      {avatar_url && (
        <ImgWithFallback
          src={teammateDetail?.avatar_url || ""}
          fallBackType="avatar"
          className="TeamMemberHeader-avatar"
        />
      )}

      {!avatar_url && <LetterAvatar display_name={fullName || display_name || ""} />}

      <div className="TeamMemberHeader-main">
        <h1 className="TeamMemberHeader-main-title">
          {fullName || display_name || ""}
          <DepartmentList
            data={departments?.map(dep => ({id: dep?.id, avatar: null, name: dep?.name}))}
          />
        </h1>

        <div className="TeamMemberHeader-main-subtitle">{teammateDetail?.home_address?.city}</div>
        {/*{showDivider && " • "}*/}
        <div className="TeamMemberHeader-main-subtitle">{teammateDetail?.title}</div>

        <div className="TeamMemberHeader-main-subtitle">
          {email && <a href={`mailto:${email}`}>{email}</a>}
        </div>
      </div>

      <div className="TeamMemberHeader-children">{children}</div>
    </div>
  );
};
