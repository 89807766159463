import {FC, Fragment} from "react";
import {tExpenseLineFileData, tExpenseListItem, tTrackExpenseLine} from "api/budget/track/types";
import {AttachFileWrap} from "../../../TrackWA/TableTrackWA/Category/CategoryBody/ExpenseRow/_common/AttachFileWrap";

type tFileCellsItemProps = {
  data: tTrackExpenseLine | tExpenseListItem;
  isExpense?: boolean;
  file: any;
  file_type: tExpenseLineFileData["file_type"];
};

export const FileCellsItem: FC<tFileCellsItemProps> = ({data, isExpense, file, file_type}) => {
  const currItem = (
    <AttachFileWrap
      id={data?.id}
      className="differenceItem"
      file={file}
      file_type={file_type}
      placeholder={file_type.toUpperCase()}
    />
  );

  return (
    <Fragment>
      {isExpense && (
        <td key={file_type} className="ExpenseTableRow-cell">
          {currItem}
        </td>
      )}

      {!isExpense && currItem}
    </Fragment>
  );
};
