import {FC, MouseEvent} from "react";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import {iTooltipProps} from "components/shared/Tooltip/Tooltip";
import {Icon} from "components/Icon/Icon";
import {iOptions} from "hooks/useTagsGroup";
import "./TagsGroup.scss";

interface iTagsGroup {
  tags?: iOptions[];
  elementClass: string;
  wrapperClass?: string;
  tooltipText?: string;
  tooltipPosition?: iTooltipProps["position"];
  handleClick?: (id: string, event?: MouseEvent<HTMLSpanElement>) => void;
  removeItem?: (id: string) => void;
  errors?: string[];
}

export const TagsGroup: FC<iTagsGroup> = ({
  tags = [],
  elementClass,
  wrapperClass,
  tooltipText = "",
  tooltipPosition,
  handleClick,
  removeItem
}) => {
  return (
    <div className={`TagsGroup ${wrapperClass}`}>
      {tags.map(({label, value}) => (
        <span
          onClick={e => handleClick && handleClick(value, e)}
          className={`${elementClass} ${Boolean(removeItem) ? "TagsGroup-has-btn" : ""}`}
          key={value}
        >
          {!!tooltipText && (
            <Tooltip message={tooltipText} position={tooltipPosition}>
              {label}
            </Tooltip>
          )}

          {!tooltipText && label}

          {removeItem && (
            <button type="button" onClick={() => removeItem(value)}>
              <Icon icon="close" size="sm" />
            </button>
          )}
        </span>
      ))}
    </div>
  );
};
