import {createContext, FC, useContext} from "react";
import {usePeople} from "./usePeople";
import {useCompanies} from "./useCompanies";

type tContactsContext = {
  people: ReturnType<typeof usePeople>;
  companies: ReturnType<typeof useCompanies>;
};
const ContactsContext = createContext<tContactsContext | null>(null);
ContactsContext.displayName = "ContactsContext";
export const ContactsContextProvider: FC = ({children}) => {
  const people = usePeople();
  const companies = useCompanies();
  return (
    <ContactsContext.Provider value={{people, companies}}>{children}</ContactsContext.Provider>
  );
};

/**
 * Provides team list
 * @returns (team list and functions for get this one)
 */
export const useContextContacts = () => {
  const context = useContext(ContactsContext);

  if (context === null) {
    throw new Error("<<< ContactsContext >>> must be used within a ContactsContextProvider");
  }

  return context as tContactsContext;
};
