import {FC, createContext, useContext} from "react";
import {useApp} from "./useApp";

type tAppContext = ReturnType<typeof useApp>;
const AppContext = createContext<tAppContext | null>(null);
AppContext.displayName = "AppContext";
export const AppContextProvider: FC = ({children}) => (
  <AppContext.Provider value={useApp()}>{children}</AppContext.Provider>
);

export const useContextApp = () => {
  const context = useContext(AppContext);

  if (context === null) {
    throw new Error("<<< AppContext >>> must be used within a AppContextProvider");
  }

  return context as tAppContext;
};
