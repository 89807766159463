import {FC} from "react";
import {tNotificationsSettingsPostData} from "api/notifications/types";
import {Icon} from "components/Icon/Icon";
import {SwitchInput} from "components/shared/FormComponents/SwitchInput/SwitchInput";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import tooltipText from "./NotificationsMessage";
import { tBooleanDigits } from "api/common/types";

interface iAccountTeamUpdate {
  teamUpdates: tBooleanDigits;
  updateNotificationHandler: (data: Partial<tNotificationsSettingsPostData>) => Promise<void>;
};

export const AccountTeamUpdate: FC<iAccountTeamUpdate> = ({
  teamUpdates,
  updateNotificationHandler
}) => {
  return (
    <div className="AccountNotifications-group">
      <div className="AccountNotifications-group-header">
        <h4 className="AccountNotifications-title">
          Team Updates
          <Tooltip
            classname="AccountNotifications-tooltip"
            message={tooltipText.teamNotify}
            position="right"
          >
            <Icon
              icon="info"
              color="dark-gray"
              size="14"
              className="AccountNotifications-icon-info"
            />
          </Tooltip>
        </h4>
        <SwitchInput
          checked={teamUpdates ? true : false}
          value={String(teamUpdates)}
          onChange={e => updateNotificationHandler({team_updates: e.target.checked ? 1 : 0})}
        />
      </div>
      <div className="AccountNotifications-group-info">Updates to your team</div>
    </div>
  );
};
