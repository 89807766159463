import {createContext, FC, useContext} from "react";
import {team} from "api/team";
import {iTeammateDetailsData} from "api/team/types";
import {useHttp} from "hooks/httpServices/useHttp";
import {common} from "api/common";
import {iBaseOptions, tDepartment, tRole, tStatus} from "api/common/types";

const useTeamDetails = () => {
  const teamMate = useHttp();
  const getTeamMate = (id: number) => teamMate.call(team.getTeammateDetail(id));

  const departments = useHttp();
  const roles = useHttp();
  const officeLocation = useHttp();
  const status = useHttp();

  const getAdditionalFormData = (type: "forms" | "filter" = "forms") => {
    !departments.data && departments.call(common.getDepartments());
    !roles.data && roles.call(common.getRoles());
    if (type === "forms") {
      !officeLocation.data && officeLocation.call(common.getOfficeLocations());
    }
    if (type === "filter") {
      !status.data && status.call(common.getStatuses());
    }
  };

  const isLoading = roles.isLoading || officeLocation.isLoading || departments.isLoading;
  const isLoadingFilter = roles.isLoading || departments.isLoading || status.isLoading;
  return {
    teammateDetail: teamMate?.data?.data?.data as iTeammateDetailsData,
    getTeamMate,
    teamMate,

    departments,
    roles,
    officeLocation,
    departmentsList: departments?.data?.data?.data as tDepartment[],
    roleList: roles?.data?.data?.data as tRole[],
    officeLocationList: officeLocation?.data?.data?.data as iBaseOptions[],
    statusList: status?.data?.data?.data as tStatus[],
    getAdditionalFormData,
    additionalFormDataLoading: isLoading,
    isLoadingFilter
  };
};

type tTeamDetailsContext = ReturnType<typeof useTeamDetails>;
const TeamDetailsContext = createContext<tTeamDetailsContext | null>(null);
TeamDetailsContext.displayName = "TeamDetailsContext";
export const TeamDetailsContextProvider: FC = ({children}) => (
  <TeamDetailsContext.Provider value={useTeamDetails()}>{children}</TeamDetailsContext.Provider>
);

/**
 * Provides teamDetails info and list for checkboxes and radio button groupe
 * @returns (teammate details, checkboxes, radio button data info and function for call it)
 */
export const useContextTeamDetails = () => {
  const context = useContext(TeamDetailsContext);

  if (context === null) {
    throw new Error("<<< TeamDetailsContext >>> must be used within a TeamContextProvider");
  }

  return context as tTeamDetailsContext;
};
