import {team} from "api/team";
import {iTeammateDetailsData} from "api/team/types";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {eventBus} from "EventBus/EventBus";
import {getApiError} from "helpers/getApiError";
import {notValidForm} from "helpers/misc";
import {useHttp} from "hooks/httpServices/useHttp";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useContextTeam} from "pages/Team/TeamContext";
import {FC, useState} from "react";

interface iReactivate {
  teammateDetail: iTeammateDetailsData;
  onCloseModal: () => void;
}

export const Reactivate: FC<iReactivate> = ({teammateDetail, onCloseModal}) => {
  const [confirmReactivateModal, setConfirmReactivateModal] = useState(false);
  const {getTeamList, isLoading: teamLoading} = useContextTeam();
  const [resultModal, setResultModal] = useState(false);
  const {call, isLoading} = useHttp();
  const {display_name} = teammateDetail;
  const email = useTextInput({value: teammateDetail?.email || "", validators: ["email"]});

  const confirmReactivate = async () => {
    if (notValidForm({email})) return;

    try {
      await call(team.reactivateTeammate(teammateDetail.id, email.value));
      setConfirmReactivateModal(false);
      setResultModal(true);
    } catch (error) {
      const {msg} = getApiError(error, {email});
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error !"});
    }
  };

  const successConfirmCallback = () => {
    onCloseModal();
    getTeamList();
  };

  return (
    <>
      <Button
        size="md"
        color="light"
        className="TeamMemberDeactivated-header-button"
        onClick={() => setConfirmReactivateModal(true)}
      >
        <Icon icon="reactivate" color="black" size="sm" /> Reactivate Account
      </Button>

      <ConfirmWindow
        show={confirmReactivateModal}
        title="Reactivate Account?"
        confirmBtn={{text: "Reactivate"}}
        cancelBtn={{text: "cancel"}}
        onConfirm={confirmReactivate}
        onClose={() => setConfirmReactivateModal(false)}
        isLoading={isLoading || teamLoading}
      >
        <div className="TeamMemberConfirmMessage">
          <p>Instructions to log in to Versatile Hub will be sent to the email below.</p>
          <InputField {...email.inputProps} placeholder="Email" errors={email.errors} />
        </div>
      </ConfirmWindow>

      <ConfirmWindow
        show={resultModal}
        title="Reactivated"
        confirmBtn={{text: "close"}}
        onConfirm={successConfirmCallback}
      >
        <div className="TeamMemberConfirmMessage">
          {display_name} account has been reactivated and an email has been sent to {email.value}.
        </div>
      </ConfirmWindow>
    </>
  );
};
