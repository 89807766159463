import {EmptyState} from "./EmptyState/EmptyState";
import {StartBlank} from "./StartBlank/StartBlank";
import {CrewHeader} from "./CrewHeader/CrewHeader";
import {JobCrewContextProvider, useContextJobCrew} from "./JobCrewContext";
import {Loading} from "components/shared/Loading/Loading";
import {CrewTable} from "./Table/CrewTable";
import "./JobCrew.scss";

export default function JobCrew() {
  return (
    <JobCrewContextProvider>
      <JobCrewComponent />
    </JobCrewContextProvider>
  );
}

function JobCrewComponent() {
  const {jobCrew: {isLoading, crew}, jobCrewGroupList: {crewGroupList}} = useContextJobCrew(); // prettier-ignore

  return (
    <div className="JobCrew">
      {isLoading && <Loading type="inBlock" />}

      {!crew && !isLoading && <EmptyState />}

      {!!crew && !isLoading && (
        <>
          <CrewHeader />
          {!crewGroupList?.length && <StartBlank />}
          {!!crewGroupList?.length && <CrewTable />}
        </>
      )}
    </div>
  );
}
