import {FC} from "react";
import {tEstimateRowData} from "api/budget/estimate/types";
import {EstimateRowINput} from "../../_common/EstimateRowInput/EstimateRowINput";
import {useContextJobBudget} from "pages/Jobs/JobDetails/JobBudget/context/JobBudgetContext";
import {useHttp} from "hooks/httpServices/useHttp";
import {estimateApi} from "api/budget/estimate";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";

type tEstimateRowAGYProps = {
  currItem: tEstimateRowData;
};

export const EstimateRowAGY: FC<tEstimateRowAGYProps> = ({currItem}) => {
  const {setEstimate} = useContextJobBudget();
  const {call, isLoading} = useHttp();

  const onSubmit = async (value: string) => {
    try {
      const res = await call(estimateApi.updateLine(currItem.id, {agency_percent: +value}));
      setEstimate(() => res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <div className="EstimateRowAGY">
      <EstimateRowINput
        defaultValue={currItem.agency_percent ? String(currItem.agency_percent) : ""}
        onBlurCallBack={onSubmit}
        placeholder="0.0"
        suffix={"%"}
        type="percent"
        isLoading={isLoading}
      />
    </div>
  );
};
