import {FC, FormEvent, useState} from "react";
import {jobCrewGroup} from "api/jobCrewGroup";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {notValidForm} from "helpers/misc";
import {useHttp} from "hooks/httpServices/useHttp";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useContextJobCrew} from "../../JobCrewContext";
import {eventBus} from "EventBus/EventBus";

export const AddGroup = () => {
  const [showModal, setModal] = useState(false);
  return (
    <>
      <Button color="outline" size="sm" className="addGroupBtn" onClick={() => setModal(true)}>
        <Icon icon="add" color="black" size="xsm" />
        Group
      </Button>
      {showModal && <AddGroupForm setModal={setModal} />}
    </>
  );
};

type tAddGroupFormProps = {setModal: React.Dispatch<React.SetStateAction<boolean>>};
export const AddGroupForm: FC<tAddGroupFormProps> = ({setModal}) => {
  const {jobCrew: {crew: {id}}, jobCrewGroupList: {setCrewGroupList}} = useContextJobCrew(); // prettier-ignore
  const {call, isLoading} = useHttp();
  const FORM_ID = "add-group_form_id";

  const form = {
    name: useTextInput()
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (notValidForm(form)) return;

    try {
      const res = await call(
        jobCrewGroup.addNewJobCrewGroup({job_crew_id: id, name: form.name.value})
      );

      setModal(false);
      setCrewGroupList(prev => [...prev, res?.data?.data]);
      eventBus.dispatch("showToast", {
        text: res?.data?.message || `Group ${form.name.value} added!`
      });
    } catch (error) {
      const {msg} = apiErrorHandler(error, form);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <ConfirmWindow
      show={true}
      title="Create group"
      subTitle="Enter a name"
      cancelBtn={{text: "Cancel", type: "button"}}
      onClose={() => setModal(false)}
      confirmBtn={{text: "save", type: "submit", form: FORM_ID}}
      isLoading={isLoading}
    >
      <form className="AddGroup" onSubmit={onSubmit} id={FORM_ID}>
        <InputField {...form.name.inputProps} errors={form.name.errors} />
      </form>
    </ConfirmWindow>
  );
};
