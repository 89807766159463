import {FC, useEffect, useState} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {Button} from "components/shared/Button/Button";
import {useHttp} from "hooks/httpServices/useHttp";
import {team} from "api/team";
import {useContextTeamDetails} from "../TeamDetailsContext";
import {getApiError} from "helpers/getApiError";
import {tTeammatePostData} from "api/team/types";
import {Loading} from "components/shared/Loading/Loading";
import {TeamMemberForm, TEAM_FORM_ID} from "./TeamForm/TeamMemberForm";
import {useContextTeam} from "../TeamContext";
import "./TeamMemberForms.scss";

interface iTeamMemberInviteProps {
  onCloseModal: () => void;
}

export const TeamMemberInvite: FC<iTeamMemberInviteProps> = ({onCloseModal}) => {
  const {getTeamList} = useContextTeam();
  const {departmentsList, roleList, officeLocationList, getAdditionalFormData, additionalFormDataLoading} = useContextTeamDetails(); // prettier-ignore
  const [resMsg, setResMsg] = useState<{title: string; infoText: string} | null>(null);
  const success = "Success !";
  const err = "OH UH !";

  useEffect(() => {
    getAdditionalFormData();
  }, []); // eslint-disable-line

  const {call, isLoading: updateLoading} = useHttp();
  const onInvite = async (formData: tTeammatePostData, form: any) => {
    try {
      await call(team.inviteTeammate(formData));
      setResMsg({title: success, infoText: `${formData.first_name} has been sent a link to sign up for The Versatile Hub.`}); // prettier-ignore
    } catch (error) {
      const {msg} = getApiError(error, form);
      // FIXME conditional statement for dev only
      if (msg === "Error sending invite email") {
        await getTeamList();
        onCloseModal();
        return;
      }

      setResMsg({title: err, infoText: msg || "Update error !"});
    }
  };

  const confirmHandler = () => {
    if (resMsg?.title === success) {
      getTeamList();
      onCloseModal();
    }

    resMsg?.title === err && setResMsg(null);
  };

  return (
    <div className="TeamMemberForms">
      <div className="TeamMemberForms-header">
        <h2 className="h2">Invite Employee</h2>
      </div>

      <div className="TeamMemberForms-body">
        {additionalFormDataLoading && <Loading type="inBlock" />}
        {!additionalFormDataLoading && (
          <TeamMemberForm
            departmentsList={departmentsList}
            roleList={roleList}
            officeLocationList={officeLocationList}
            submitCallback={onInvite}
            type="invite"
          />
        )}
      </div>

      <div className="TeamMemberForms-footer">
        <Button size="md" onClick={onCloseModal}>
          Cancel
        </Button>
        {!additionalFormDataLoading && (
          <Button size="md" color="light" form={TEAM_FORM_ID} type="submit" loading={updateLoading}>
            Send Invite
          </Button>
        )}
      </div>

      <ConfirmWindow
        show={!!resMsg}
        title={resMsg?.title}
        confirmBtn={{text: "close"}}
        onConfirm={confirmHandler}
      >
        <div className="TeamMemberConfirmMessage">{resMsg?.infoText}</div>
      </ConfirmWindow>
    </div>
  );
};
