import {useEffect, useLayoutEffect, useState} from "react";
import {estimateApi} from "api/budget/estimate";
import {tEstimateData, tEstimateListItem, tResourcesData} from "api/budget/estimate/types";
import {useHttp} from "hooks/httpServices/useHttp";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {routes} from "router";
import {sortByKey} from "helpers/misc";
import {tCallSheetList} from "api/callSheet/types";
import {common} from "api/common";

export type tUseJobBudgetReturn = ReturnType<typeof useJobBudget>;

export function useJobBudget() {
  const {pathname} = useLocation();
  const {id, estimateId} = useParams();
  const estimateRoute = routes?.jobs?.details?.budget?.estimate?.main;
  const navigate = useNavigate();
  const {call, data, isLoading} = useHttp<{data: tCallSheetList[]}>();

  const [estimate, setEstimate] = useState<tEstimateData | null>(null);
  const {call: estimateCall, isLoading: estimateLoading} = useHttp();
  const [hideEmptyLines, setHideEmptyLines] = useState(false);

  useLayoutEffect(() => {
    getCurrRates();

    const isEstimatePage =
      pathname.includes("estimate") ||
      pathname
        .split("/")
        .filter(v => v !== "/")
        .at(-1) === "budget";

    getEstimateList().then(response => {
      if (response && response?.data?.data?.length && isEstimatePage && !estimateId) {
        const id = sortByKey(response.data.data, "name")[0].id;
        navigate(`estimate/${id}`);
      }

      estimateExistsCheck(estimateId, response?.data?.data);
    });
  }, []); // eslint-disable-line

  const getEstimateList = async () => {
    if (!id) return;
    return call(estimateApi.getEstimateList(+id));
  };

  useEffect(() => {
    if (!estimateId) return;
    getEstimate();
    getEstimateResource();
  }, [estimateId]); // eslint-disable-line

  function estimateExistsCheck(estimateId: string | undefined, estimatesList: any[] | undefined) {
    if (!estimatesList?.length || !estimateId) return;
    if (!estimatesList.find(v => String(v.id) === estimateId)) {
      navigate(`/jobs/${id}/budget/estimate/${estimatesList?.[0]?.id}`);
    }
  }

  const emptyCostFilter = (currEstimate: tEstimateData) => {
    const filtered = currEstimate?.category_values
      .map(item => ({...item, cost_values: item?.cost_values.filter(item => !item?.is_empty)}))
      .filter(item => !!item?.cost_values?.filter(item => !item?.is_empty)?.length);

    return {
      ...currEstimate,
      category_values: filtered
    };
  };

  const getEstimate = () => {
    if (!estimateId) return;
    return estimateCall(estimateApi.getEstimate(+estimateId)).then(res => {
      const currEstimate = res?.data?.data as tEstimateData;
      !!currEstimate.approved_at && setEstimate(() => emptyCostFilter(currEstimate));
      !currEstimate.approved_at && setEstimate(currEstimate);
    });
  };

  // estimate resources
  const [resources, setResources] = useState<tResourcesData | null>(null);
  const {call: resourcesCall, isLoading: resourcesLoading} = useHttp();
  const getEstimateResource = () => {
    if (!estimateId) return;
    return resourcesCall(estimateApi.getEstimateResources(+estimateId)).then(res => {
      const currEstimate = res?.data?.data as tResourcesData;
      setResources(currEstimate);
    });
  };

  const [currRates, setCurrRates] = useState<tResourcesData["currency_rate"] | null>(null);
  const {call: currRateCell, isLoading: currRateCellLoading} = useHttp();
  const getCurrRates = () => {
    return currRateCell(common.getCurrencyRates()).then(res => {
      const currRates = res?.data?.data as tResourcesData["currency_rate"];
      setCurrRates(currRates);
    });
  };

  const needToFilterEmptyLines = hideEmptyLines && estimate && !estimate?.approved_at;

  return {
    estimateList: data?.data?.data as Array<tEstimateListItem>,
    estimateListLoading: isLoading,
    getEstimateList,
    getEstimate,
    estimate: needToFilterEmptyLines ? emptyCostFilter(estimate) : estimate,
    estimateLoading,
    estimateId,
    setEstimate,
    resources,
    setResources,
    emptyCostFilter,
    currRates,
    resourcesLoading: resourcesLoading && currRateCellLoading,
    setHideEmptyLines,
    hideEmptyLines,
    estimateRoute
  };
}
