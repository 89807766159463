import React, {FC, MouseEvent} from "react";
import {Tag} from "components/shared/Tag/Tag";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import {iLocationDetailsResponse} from "api/location/types";

interface iLocationsTableLocaleProps {
  locales: iLocationDetailsResponse["locales"];
  tagOnClick: (event: MouseEvent<HTMLDivElement, any>, id: number) => void;
  showDetails?: () => void;
}

export const LocationsTableLocale: FC<iLocationsTableLocaleProps> = ({
  locales,
  tagOnClick,
  showDetails
}) => {
  return (
    <td className="LocationsTableRow-cell LocationsTableRow-cell-locale" onClick={showDetails}>
      <div className="LocationsTableRow-tagsRow">
        {locales?.map((locale, index) => {
          if (index > 3) return null;
          if (index === 3) {
            return <Tag key={locale.pivot.location_id + index}>+{locales?.length - 3}</Tag>;
          }
          return (
            <Tag
              key={locale.pivot.location_id + index}
              onClick={event => tagOnClick(event, locale.id)}
            >
              <Tooltip message="Filter" position="right">
                {locale.name}
              </Tooltip>
            </Tag>
          );
        })}
      </div>
    </td>
  );
};
