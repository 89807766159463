import {FC, ReactNode} from "react";
import "./JobBudgetHeaderLayout.scss";

interface iJobBudgetHeaderLayoutProps {
  children?: ReactNode;
}

export const JobBudgetHeaderLayout: FC<iJobBudgetHeaderLayoutProps> = ({children}) => {
  return (
    <div className="JobBudgetHeaderLayout">
      <div className="JobBudgetHeaderLayout-leftSide">
        <h2 className="JobBudgetHeaderLayout-title">Estimate</h2>
      </div>
      <div className="JobBudgetHeaderLayout-rightSide">{children}</div>
    </div>
  );
};
