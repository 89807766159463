import {FC, Fragment} from "react";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {ContactBlock} from "../common/ContactBlock";
import {Title} from "../common/Title";
import AgencyImg from "assets/images/AgencyImg.svg";

interface iArtistsProps {
  [key: string]: any;
}

export const Artists: FC<iArtistsProps> = () => {
  const {jobDetailsData} = useContextJobDetails();

  if (!jobDetailsData) return null;
  const {artists} = jobDetailsData;
  return (
    <div className="JobOverview-section">
      {!artists?.length && (
        <>
          <Title text="Artists" fullWidth />
          <ContactBlock placeholder="Artist contacts will appear here" fulWidth />
        </>
      )}

      {!!artists?.length &&
        artists?.map((item, i) => {
          const agencyAddress = `${item?.artist_agency?.address?.city || ""}${
            item?.artist_agency?.address?.state ? ", " + item?.artist_agency?.address?.state : ""
          }`;

          return (
            <Fragment key={i}>
              <Title text="Artists" />
              <Title text="Agency" size="sm" />
              <Title text="Agent" size="sm" />

              <ContactBlock
                subTitle={item?.artist?.titles?.map(item => item?.name).join(", ")}
                contact={item?.artist?.emails?.[0]?.value}
                title={`${item?.artist?.first_name || ""} ${item?.artist?.last_name || ""}`}
                imgUrl={item?.artist?.avatar_url || ""}
                id={item?.artist?.id}
              />
              {item?.artist_agency ? (
                <ContactBlock
                  title={`${item?.artist_agency?.name || ""}`}
                  subTitle={agencyAddress}
                  imgUrl={item?.artist_agency?.avatar_url || AgencyImg}
                  id={item?.artist_agency?.id}
                  type="company"
                />
              ) : (
                <div />
              )}

              {item?.agent ? (
                <ContactBlock
                  title={`${item?.agent?.first_name || ""} ${item?.agent?.last_name || ""}`}
                  subTitle="Agent"
                  contact={item?.agent?.emails?.[0]?.value}
                  imgUrl={item?.agent?.avatar_url || ""}
                  id={item?.agent?.id}
                />
              ) : (
                <div />
              )}
            </Fragment>
          );
        })}
    </div>
  );
};
