import {FC, MouseEvent} from "react";
import {copyToClipboard} from "helpers/supporters";
import { eventBus } from "EventBus/EventBus";
import "./ButtonCopyClipboard.scss";

interface iButtonCopyClipboard {
  elementForCopy: string;
  prefixToastMessage: string;
  className?: string;
};

export const ButtonCopyClipboard: FC<iButtonCopyClipboard> = ({elementForCopy, prefixToastMessage, className}) => {
  const handleCopyPhone = async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    await copyToClipboard(elementForCopy);
    eventBus.dispatch("showToast", {text: `${prefixToastMessage} was copied: ${elementForCopy}` });
  };

  return (
    <button
      onClick={handleCopyPhone}
      className={`ButtonCopyClipboard ${className ? className : ""}`}
    >
      Copy
    </button>
  );
};
