import {CSSProperties, FC, ReactNode} from "react";
import {Button} from "components/shared/Button/Button";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {Loading} from "components/shared/Loading/Loading";
import "./SelectCell.scss";

type tSelectCellProps = {
  children: ReactNode;
  btnChildren: ReactNode;
  menuAnchor: HTMLElement | null;
  setMenuAnchor: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  isLoading?: boolean;
  blockOpenMenu?: boolean;
  className?: string;
  onCloseCallback?: () => void;
  dropdownCustomClass?: string;
  style?: CSSProperties;
};

export const SelectCell: FC<tSelectCellProps> = props => {
  const {children, isLoading, btnChildren, menuAnchor, dropdownCustomClass = ""} = props;
  const {blockOpenMenu, setMenuAnchor, className = "", onCloseCallback, style} = props;

  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (blockOpenMenu) return;
    setMenuAnchor(event.currentTarget);
  };

  const onCloseMenu = () => {
    setMenuAnchor(null);
    onCloseCallback?.();
  };

  const blockOpenMenuClass = blockOpenMenu ? "blockOpenMenu" : "";
  return (
    <div className={`SelectCell ${className} ${blockOpenMenuClass}`} style={style}>
      {isLoading && <Loading type="inBlock" customSize={0.5} />}

      <Button size="sm" btnType="text" onClick={showMenu} className="SelectCell-showMenuBtn">
        {btnChildren}
      </Button>

      <DropdownMenu
        btnHover
        anchor={menuAnchor}
        onClose={onCloseMenu}
        className={`${dropdownCustomClass}`}
        anchorOrigin={{horizontal: "left", vertical: "bottom"}}
        transformOrigin={{horizontal: "right", vertical: "bottom"}}
      >
        {children}
      </DropdownMenu>
    </div>
  );
};
