import {recObjIterator} from "helpers/misc";
import {routes} from "./routes";

// FIXME NEED TO CHECK. doesnt work properly
export function makeAbsRoute(key: string, obj: {[k: string]: any}) {
  if (key === "main") return "/" + obj[key];
  return "main" in obj ? "/" + obj["main"] + "/" + obj[key] : obj[key];
}

/**
 * Easy placeholder replace helper for generating api request urls
 * @param {string} template - template string to parse
 * @param {{[p: string]: string | number}} dataObj - object with keys as placeholder name and values to replace name in template
 * @param {string} delimiter - placeholder start delimiter
 * @return {string}
 * @usage makeURL("/foo/:id/hello/world/:name", {id: 1, name: "John"}) = /foo/1/hello/world/John
 */
export const parseURLParams = (
  template: string,
  dataObj: {[k: string]: string | number},
  delimiter: string = ":"
) => {
  let result = template;
  Object.keys(dataObj).forEach(key => {
    result = result.replace(delimiter + key, String(dataObj[key]));
  });
  return result;
};

// FIXME NEED TO CHECK. fix name for absRoutes
// this function return relative path
export const absRoutes = recObjIterator(routes, makeAbsRoute) as typeof routes;
