import {FC} from "react";
import {iCompaniesContact} from "api/contacts/types";
import {tCrewContact} from "api/jobCrewGroup/types";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import "./RowRate.scss";

type tRowRateProps = {
  contact: iCompaniesContact;
  crewContact: tCrewContact;
  groupId: number;
};

export const RowRate: FC<tRowRateProps> = ({contact, crewContact}) => {
  const defValue = crewContact?.rate ? crewContact?.rate : contact?.rate;

  return (
    <TextComponent size="13" weight="400" color="black-10" className="rate" truncate nowrap>
      {defValue || ""}
    </TextComponent>
  );
};
