import {tPayIdsItem} from "api/common/types";

type tUpdatePayIdData = {
  data: tPayIdsItem[] | undefined;
  newItem: tPayIdsItem;
  updatedKey: "credit_card" | "payroll_company";
  updateType: "add" | "update";
};

export const updatePayIdData = ({data, newItem, updateType, updatedKey}: tUpdatePayIdData) => {
  if (!data) return [];

  return data?.map(item => {
    if (item?.target_type === updatedKey) {
      return {...item, target_items: returnNewItems(item, {newItem, updateType})};
    }

    return item;
  });
};

const returnNewItems = (
  item: tPayIdsItem,
  {newItem, updateType}: Omit<tUpdatePayIdData, "updatedKey" | "data">
) => {
  if (updateType === "add") {
    return !!item?.target_items ? [...item?.target_items, newItem] : [newItem];
  }

  if (updateType === "update") {
    return item?.target_items?.map(item => {
      if (item?.id === newItem?.id) {
        return newItem;
      }
      return item;
    });
  }

  return [];
};
