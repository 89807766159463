import {FC} from "react";
import {tTrackExpenseLine, typeFees} from "api/budget/track/types";
import {DragAndDropWrap, tDraggingItem} from "components/DragAndDropWrap/DragAndDropWrap";
import {useGetExpenseRowCells} from "./useGetExpenseRowCells";
import "./ExpenseRow.scss";

type tExpenseRowProps = {
  trackLine: tTrackExpenseLine;
  dropCallBack: (draggingItem: tDraggingItem) => void;
  setCurrItem: React.Dispatch<React.SetStateAction<tDraggingItem | null>>;
  isLoading: boolean;
  typeFees?: typeFees;
};

export const ExpenseRow: FC<tExpenseRowProps> = ({
  trackLine,
  setCurrItem,
  dropCallBack,
  isLoading,
  typeFees
}) => {
  const {cells} = useGetExpenseRowCells({typeFees});

  return (
    <DragAndDropWrap
      draggingItem={trackLine}
      setCurrItem={setCurrItem}
      dropCallBack={item => dropCallBack(item)}
      className={`ExpenseRow`}
      isLoading={isLoading}
      as="tr"
    >
      {cells({className: "ExpenseRow-cell", trackLine}).map((item, i) => {
        return (
          <td key={i} colSpan={!!item?.colSpan ? item?.colSpan : 1}>
            {item.component}
          </td>
        );
      })}
    </DragAndDropWrap>
  );
};
