import {FC} from "react";
import {EstimateSidebar} from "./EstimateSidebar/EstimateSidebar";
import {EstimateTable} from "./EstimateTable/EstimateTable";
import {useOffsetTop} from "hooks/useOffsetTop";
import {Loading} from "components/shared/Loading/Loading";
import {JobBudgetProvider, useContextJobBudget} from "./_context/JobBudgetContext";
import {JobBudgetHeaderLayout} from "./JobBudgetHeaderLayout/JobBudgetHeaderLayout";
import {EstimateManagement} from "./JobBudgetHeaderLayout/EstimateManagement/EstimateManagement";
import "./EstimateBuild.scss";

interface iEstimateBuildProps {}

export function Estimate() {
  return (
    <JobBudgetProvider>
      <EstimateBuild />
    </JobBudgetProvider>
  );
}

export const EstimateBuild: FC<iEstimateBuildProps> = () => {
  const {estimate, isLoading} = useContextJobBudget();
  const {elementRef, offsetTop} = useOffsetTop();

  return (
    <>
      <JobBudgetHeaderLayout>
        <EstimateManagement />
      </JobBudgetHeaderLayout>

      <div
        className="EstimateBuild"
        ref={elementRef}
        style={{height: `calc(100vh - ${offsetTop}px)`}}
      >
        {isLoading && <Loading type="inBlock" />}
        {!!estimate && (
          <>
            <EstimateSidebar heightSidebar={offsetTop} />
            <EstimateTable />
          </>
        )}
      </div>
    </>
  );
};
