import {JobBudgetHeaderLayout} from "../../JobBudgetHeaderLayout/JobBudgetHeaderLayout";
import {TrackNavigation} from "../_common/TrackNavigation";
import {TrackWAMenu} from "./TrackWAHeader/TrackWAMenu";
import {TrackSync} from "./TrackWAHeader/TrackSync";
import {TableTrackWA} from "./TableTrackWA/TableTrackWA";

export default function TrackWA() {
  return (
    <>
      <JobBudgetHeaderLayout>
        <TrackSync />
        <TrackNavigation />
        <TrackWAMenu />
      </JobBudgetHeaderLayout>

      <TableTrackWA />
    </>
  );
}
