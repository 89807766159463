import {FC} from "react";
import {EventEdit} from "../modals/EventEdit/EventEdit";
import {EventDetails} from "../modals/EventDetails/EventDetails";
import {JobSchedulerComponent} from "./JobSchedulerComponent";
import {useJobScheduler} from "./useJobScheduler";
import "./JobScheduler.scss";

export type tSchedulerEvent = {
  start_date: string;
  end_date: string;
  name: string;
  type: string;
  id: number;
};

export const JobScheduler: FC = () => {
  const {
    calendarSheets,
    setCalendarSheets,
    addEvent,
    status,
    handleEmptyClick,
    clearEventId,
    setClearEventId,
    handleCloseEventEdit,
    handleBeforeEventChange,
    onShowEventDetails,
    showEventDetails,
    setShowEventDetails,
    onEditEvent
  } = useJobScheduler();

  const scriptIsLoaded = status === "ready";

  return (
    <>
      {scriptIsLoaded && (
        <JobSchedulerComponent
          key="job-calendar"
          events={calendarSheets}
          addEvent={addEvent}
          handleEmptyClick={handleEmptyClick}
          handleBeforeEventChange={handleBeforeEventChange}
          onShowEventDetails={onShowEventDetails}
          clearEventId={clearEventId}
          setClearEventId={setClearEventId}
          setEvents={setCalendarSheets}
        />
      )}

      {addEvent && (
        <EventEdit
          isUpdating={Boolean(showEventDetails)}
          setShowEventDetails={setShowEventDetails}
          event={addEvent}
          onClose={handleCloseEventEdit}
        />
      )}

      {showEventDetails && (
        <EventDetails
          onEditEvent={onEditEvent}
          data={showEventDetails}
          onClose={() => setShowEventDetails(null)}
        />
      )}
    </>
  );
};
