import {FC} from "react";
import "./TableEmptyState.scss";

type tTableEmptyStateProps = {
  message: string;
};

export const TableEmptyState: FC<tTableEmptyStateProps> = ({message}) => {
  return <div className="TableEmptyState">{message}</div>;
};
