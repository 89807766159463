import {ImgWithFallback} from "components/shared/ImgWithFallback/ImgWithFallback";
import {FC} from "react";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import "./RowName.scss";

type tRowNameAgentProps = {
  agent: any;
};

export const RowNameAgent: FC<tRowNameAgentProps> = ({agent}) => {
  return (
    <div className="RowName RowNameAgent">
      <div className="RowName-imgWrap">
        <ImgWithFallback src="" fallBackType="avatar" />
      </div>

      <TextComponent weight="500" size="14">
        {agent?.name}
      </TextComponent>
    </div>
  );
};
