import {FC, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useContextProfile} from "context";
import {absRoutes, routes} from "router";
import {Loading} from "../shared/Loading/Loading";

export const Redirects: FC = () => {
  const {userData} = useContextProfile();
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const callSheetConfirmRout = routes?.callSheetConfirm?.main?.split("/")?.[0];

  useEffect(() => {
    if (pathname.includes(callSheetConfirmRout)) return;

    if (userData === null && !pathname.includes(routes.auth.main)) navigate(absRoutes.auth.login);

    if (
      userData &&
      pathname.includes(routes.auth.main) &&
      !pathname.includes(routes.auth.resetPass)
    )
      navigate(absRoutes.dashboard.main);
  }, [userData, pathname]); // eslint-disable-line

  if (userData !== undefined) return null;

  return <Loading size={"lg"} style={{zIndex: 99999}} />;
};
