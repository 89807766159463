import {useGeneratePage} from "./useGeneratePage";
import "./PDFView.scss";

type PdfPageProps = {
  page: any;
  scale: number;
  style?: any; // not React.CSSProperties because of {"--scale-factor": scale};
};

export const PdfPage = (props: PdfPageProps) => {
  const {page, scale, style} = props;
  const {canvasRef, textLayerRef} = useGeneratePage({scale, page});

  return (
    <div className="PdfPage" style={{...style}}>
      <canvas ref={canvasRef} />
      <div ref={textLayerRef} className="PdfPage-textLayer" style={{scale}} />
    </div>
  );
};
