import React, {useEffect, useRef} from "react";

type tProps = {
  callBack: () => void;
  deps?: React.DependencyList | undefined;
  interval?: number;
};

export const useSyncData = ({callBack, deps, interval = 60000}: tProps) => {
  const timerRef = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    timerRef?.current && clearInterval(timerRef.current);

    timerRef.current = setInterval(() => {
      callBack();
    }, interval);

    return () => {
      timerRef?.current && clearInterval(timerRef.current);
    };
  }, deps); // eslint-disable-line
};
