import {FC, MouseEvent} from "react";
import "./ListLineLayout.scss";

type tListLineLayoutProps = {
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
};

export const ListLineLayout: FC<tListLineLayoutProps> = ({children, onClick}) => {
  return (
    <div onClick={onClick} className="ListLineLayout">
      {children}
    </div>
  );
};
