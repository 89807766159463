import {ChangeEvent, FC, useEffect, useRef} from "react";
import {JobSchedulerControls} from "./JobSchedulerControls";
import {tCalendarTemplateEvent} from "../helpers";
import {jobSchedulerInit} from "./schedulerInitHelpers/jobSchedulerInit";
import {jobSchedulerTemplate} from "./schedulerInitHelpers/jobSchedulerTemplate";
import {JobCalendarBody} from "./JobCalendarBody";

interface iJobSchedulerComponentProps {
  events: tCalendarTemplateEvent[];
  handleEmptyClick: (date: Date, event: tCalendarTemplateEvent) => void;
  handleBeforeEventChange: (
    event: tCalendarTemplateEvent,
    e: ChangeEvent<HTMLInputElement>,
    is_new: boolean,
    original: any,
    all_day: boolean
  ) => void;
  onEditEvent: (events: tCalendarTemplateEvent) => void;
  clearEventId: number | null;
  setClearEventId: (id: number | null) => void;
  addEvent: any;
}

export const JobSchedulerComponent: FC<iJobSchedulerComponentProps> = ({
  events,
  handleEmptyClick,
  handleBeforeEventChange,
  onEditEvent,
  clearEventId,
  setClearEventId,
  addEvent
}) => {
  const initMode = "week";
  // @ts-ignore
  const scheduler = window.scheduler;
  let schedulerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scheduler._$initialized) {
      scheduler._$initialized = false;
      scheduler.checkEvent("onEmptyClick") && scheduler.detachAllEvents();
    }
    initSchedulerEvents();
  }, [events, addEvent]); // eslint-disable-line

  // clear uncreated event
  useEffect(() => {
    if (!scheduler || !clearEventId) return;
    scheduler.deleteEvent(clearEventId);
    setClearEventId(null);
  }, [scheduler, clearEventId, setClearEventId]);

  const initSchedulerEvents = () => {
    if (scheduler._$initialized) return;
    scheduler.attachEvent(
      "onBeforeEventChanged",
      (
        event: tCalendarTemplateEvent,
        e: ChangeEvent<HTMLInputElement>,
        is_new: boolean,
        original: any
      ) => {
        const all_day = !!Object.keys(original).length;
        handleBeforeEventChange(event, e, is_new, original, all_day);
        return true;
      }
    );

    scheduler.attachEvent("onEmptyClick", (date: Date, event: tCalendarTemplateEvent) => {
      handleEmptyClick(date, event);
      return true;
    });

    scheduler.attachEvent("onBeforeLightbox", () => false);
    scheduler.attachEvent("onDblClick", () => false);
    scheduler.attachEvent("onClick", (id: string) => {
      if (!events) return;

      const ev = events.find((event: tCalendarTemplateEvent) => String(event.id) === String(id));
      if (!ev) return false;

      onEditEvent(ev);
      return true;
    });

    scheduler._$initialized = true;
  };

  useEffect(() => {
    jobSchedulerInit(scheduler, schedulerRef, initMode);
  }, []); // eslint-disable-line

  useEffect(() => {
    jobSchedulerTemplate(scheduler, events, initMode);
  }, [events]); // eslint-disable-line

  return (
    <>
      <div key="dhx-wrap" className="JobsSettingsCalendarTemplate">
        <div className="JobsSettingsCalendarTemplate-toolbarRow ControlRow">
          <JobSchedulerControls scheduler={scheduler} />
        </div>

        <div id="JobSchedulerContainer" className="JobsSettingsCalendarTemplate-data">
          <JobCalendarBody
            id="JobScheduler-calendar"
            className="JobsSettingsCalendarTemplate-calendar dhx_cal_container"
            schedulerRef={schedulerRef}
          />
        </div>
      </div>
    </>
  );
};
