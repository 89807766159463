import {FC, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {tCrewContact} from "api/jobCrewGroup/types";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import {useContextJobCrew} from "pages/Jobs/JobDetails/JobCrew/JobCrewContext";
import {useChangeCrewValue} from "../_common/useChangeCrewValue";

type tDeleteContactProps = {
  crewContact: tCrewContact;
  typeRow: "agent" | "contact";
  groupId: number;
};

export const DeleteContact: FC<tDeleteContactProps> = ({crewContact, typeRow, groupId}) => {
  const {jobCrew: {crew}} = useContextJobCrew(); // prettier-ignore
  const [showModal, setModal] = useState(false);
  const {changeValue, isLoading} = useChangeCrewValue();

  const onSubmit = async () => {
    if (typeRow === "agent") {
      changeValue({type: "unLinkAgent", id: crewContact?.id, groupId, showSuccessMessage: true});
    }

    if (typeRow === "contact") {
      changeValue({
        type: "deleteContacts",
        id: crew?.id,
        data: {contacts: [crewContact.id]},
        groupId: null,
        showSuccessMessage: true
      });
    }
  };

  const title = typeRow === "agent" ? "Unlink agent?" : "Remove contact?";
  const subTitle =
    typeRow === "agent" ? "This agent will be unlinked." : "This contact will be removed.";

  return (
    <>
      <Tooltip
        message="Remove"
        position="top"
        classname="removeContactTooltip"
        onClick={() => setModal(true)}
      >
        <Button onClick={() => setModal(true)}>
          <Icon size="sm" color="gray" icon="close" />
        </Button>
      </Tooltip>

      {showModal && (
        <ConfirmWindow
          show={true}
          title={title}
          subTitle={subTitle}
          cancelBtn={{text: "Cancel", type: "button"}}
          onClose={() => setModal(false)}
          confirmBtn={{text: "Remove", type: "submit", color: "red"}}
          onConfirm={onSubmit}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
