import {FC, MouseEvent} from "react";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import {iTooltipProps} from "components/shared/Tooltip/Tooltip";
import {Tag, iTagProps} from "components/shared/Tag/Tag";
import {iBaseOptions} from "api/common/types";
import "./TagsList.scss";

interface iTagsList {
  tags: iBaseOptions[];
  tooltipText?: string;
  tooltipPosition?: iTooltipProps["position"];
  handleClick?: (event?: MouseEvent<HTMLSpanElement, any>, tag?: iBaseOptions) => void;
  removeTag?: (id: string) => void;
  viewWithWrap?: boolean;
  tagSize?: iTagProps["size"];
}

const TagsList: FC<iTagsList> = ({
  tags = [],
  tooltipText = "",
  tooltipPosition,
  handleClick,
  viewWithWrap = false,
  tagSize = "sm"
}) => {
  if (!tags || !Array.isArray(tags)) return null;

  return (
    <div className="TagsList">
      {tags.map((tag, index, array) => {
        if (!viewWithWrap && index > 2) return null;
        if (!viewWithWrap && index > 1) {
          return (
            <Tag key={tag.id} onClick={event => event.stopPropagation()}>
              +{array.length - 2}
            </Tag>
          );
        }

        if (tooltipText) {
          return (
            <Tooltip
              key={tag.id}
              message={tooltipText}
              position={tooltipPosition}
              onClick={event => handleClick?.(event, tag)}
            >
              <Tag size={tagSize}>{tag.name}</Tag>
            </Tooltip>
          );
        }

        return (
          <Tag size={tagSize} key={tag.id} onClick={event => handleClick?.(event, tag)}>
            {tag.name}
          </Tag>
        );
      })}
    </div>
  );
};

export default TagsList;
