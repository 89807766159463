import {FC, KeyboardEvent} from "react";
import CreatableSelect, {components, GroupBase, Props} from "react-select";
import {tUseTagsFieldCreating} from "./useTagsFieldCreating";
import {Button} from "components/shared/Button/Button";
import {Loading} from "components/shared/Loading/Loading";

import "./TagsFieldCreating.scss";

export type iStateOption = Record<string, string>;

type SelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = Props<Option, IsMulti, Group> & {
  label?: string;
  id?: string;
};

export interface iTagsFieldProps extends SelectProps<any, any> {
  id?: string;
  label?: string;
  placeholder?: string;
  options?: Record<string, string | number>[];
  errors?: string[];
  uniqValues?: boolean;
  value?: Record<string, string | number>[];
  className?: string;
  labelKey?: string[];
  valueKey?: string[];
  fieldProps: tUseTagsFieldCreating;
}

export const TagsFieldCreating: FC<iTagsFieldProps> = ({
  label = "",
  errors = [],
  placeholder = "",
  uniqValues = true,
  value,
  id = "",
  className = "",
  labelKey = ["name"],
  valueKey = ["id"],
  fieldProps,
  ...props
}) => {
  const {isCreating, titlesListLoading, options, setMenuRef, onCreateTitle} = fieldProps;
  const {inputValue} = props;

  function getOption(item: Record<string, string>, keys: string[]) {
    return keys.map(key => item[key]).join(" ");
  }

  function onKeyDown(event: KeyboardEvent<HTMLDivElement>) {
    if (event.which === 13) {
      onCreateTitle(event, inputValue);
      return;
    }
  }

  return (
    <div className={`TagsFieldCreating ${className} ${!!errors?.length ? "error" : ""}`}>
      {label && <label className="TagsFieldCreating-label label">{label}</label>}
      <CreatableSelect
        {...props}
        ref={ref => setMenuRef(ref)}
        id={id}
        value={value}
        isSearchable
        isMulti
        isLoading={titlesListLoading}
        options={options}
        className="TagsFieldCreating-select"
        classNamePrefix="TagsFieldCreating-select"
        placeholder={placeholder}
        getOptionLabel={(option: any) => getOption(option, labelKey)}
        getOptionValue={(option: any) => getOption(option, valueKey)}
        isClearable={false}
        onKeyDown={onKeyDown}
        components={{
          NoOptionsMessage: selectProps => {
            if (!inputValue) {
              return <components.NoOptionsMessage {...selectProps} />;
            }
            return (
              <components.NoOptionsMessage {...selectProps}>
                {isCreating && <Loading type="inBlock" />}
                <Button
                  type="button"
                  className="TagsFieldCreating-button listItemNotice"
                  onClick={event => onCreateTitle(event, inputValue)}
                >{`Press enter to add new title “${inputValue}”`}</Button>
              </components.NoOptionsMessage>
            );
          }
        }}
      />

      {errors.map((error, index) => (
        <p key={index} className="TagsFieldCreating-error-message">
          {error}
        </p>
      ))}
    </div>
  );
};
