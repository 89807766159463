import {useHttp} from "hooks/httpServices/useHttp";
import {iCompanyDetails} from "api/contacts/companies/interfaces";
import {companies as companiesApi} from "api/contacts/companies";
import {useCompanyFormMain} from "./CompanyForms/CompanyFormMain/useCompanyFormMain";
import {useCompanyFormAddress} from "./CompanyForms/CompanyFormAddress/useCompanyFormAddress";
import {useCompanyFormOther} from "./CompanyForms/CompanyFormOther/useCompanyFormOther";
import {getApiError} from "helpers/getApiError";
import {notValidForm} from "helpers/misc";
import {tNotValidTabs} from "./CompanyForm";
import {Dispatch, FormEvent, SetStateAction, useState} from "react";
import {generateFormData} from "./generateFormData";
import {eventBus} from "EventBus/EventBus";
import {useCustomFieldsForm} from "components/shared/CustomFieldsForm/useCustomFieldsForm";

export type tFullForm = {
  mainFormData: ReturnType<typeof useCompanyFormMain>;
  addressFormData: ReturnType<typeof useCompanyFormAddress>;
  otherFormData: ReturnType<typeof useCompanyFormOther>;
};

interface iProps {
  company?: iCompanyDetails | null;
  setNotValidTab: Dispatch<SetStateAction<Partial<tNotValidTabs> | undefined>>;
  tab: number;
  closeModal: () => void;
  onEditCallback?: Function;
}

export const useCompanyForm = ({company, setNotValidTab, tab, onEditCallback}: iProps) => {
  const {call, isLoading, data, isSuccess} = useHttp();

  const mainFormData = useCompanyFormMain({company, setNotValidTab});
  const addressFormData = useCompanyFormAddress({company});
  const otherFormData = useCompanyFormOther({company, tab});
  const customFormData = useCustomFieldsForm({data: company?.custom_fields, fieldsType: "company"});

  const [favorite, setFavorite] = useState(company?.in_favorite || false);

  const isChangedFormData =
    mainFormData.isChanged ||
    addressFormData.isChanged ||
    otherFormData.isChanged ||
    customFormData.isChanged ||
    favorite !== !!company?.in_favorite;

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const notValidTabs: tNotValidTabs = {
      "0": notValidForm(mainFormData.formData), //fixme fix avatar default image
      "1": notValidForm({...addressFormData.formData.billing_info}), // FIXME address validation
      "2": notValidForm(otherFormData.formData)
    };

    setNotValidTab(notValidTabs);
    if (Object.values(notValidTabs).find(item => item)) return;

    const fullForm = {mainFormData, addressFormData, otherFormData};
    const requestData = {
      ...generateFormData(fullForm),
      preferred: favorite,
      custom_fields: customFormData.getFormDataValues()
    };

    try {
      const id = company?.id;
      const response = await call(
        id ? companiesApi.update(requestData, id) : companiesApi.create(requestData)
      );
      onEditCallback?.(response);
      eventBus.dispatch("showToast", {text: response?.data?.message});
    } catch (err) {
      const form = {
        ...mainFormData.formData,
        ...addressFormData.formData.billing_info,
        ...otherFormData.formData
      };

      const {msg} = getApiError(err, form);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return {
    mainFormData,
    addressFormData,
    otherFormData,
    customFormData,
    favorite,
    setFavorite,
    onSubmit,
    isChangedFormData,

    isLoading,
    data,
    isSuccess
  };
};
