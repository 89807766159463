import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useTags} from "hooks/inputHooks";
import {iPersonDetails} from "../../../interfaces";
import {useEffect, useState} from "react";
import {useContextContactsDetails} from "pages/Contacts/context/ContactsDetailsContext";
import {useDynamicFields} from "./useDynamicFields";
import {lengthValidation} from "hooks/useTextInput/validators";

interface iProps {
  person: iPersonDetails | null | undefined;
}

export type tUsePeopleFormMain = ReturnType<typeof usePeopleFormMain>;

export const usePeopleFormMain = ({person}: iProps) => {
  const context = useContextContactsDetails();
  const {labels, locales, emailTypes, platforms, phoneTypes} = context;
  const {getAdditionalFormDataMain, isLoadingMain} = context;

  const dynamicFields = useDynamicFields({person});
  const {
    phonesRef,
    phones,
    setPhones,
    emailsRef,
    emails,
    setEmails,
    socPlatformRef,
    accounts,
    setAccounts
  } = dynamicFields;

  useEffect(() => {
    getAdditionalFormDataMain();
  }, []); // eslint-disable-line

  const [personPhoto, setPersonPhoto] = useState(person?.avatar_url || "");

  const formData = {
    first_name: useTextInput({
      validators: [lengthValidation(1)],
      isRequired: true,
      value: person?.first_name || ""
    }),
    middle_name: useTextInput({
      validators: [lengthValidation(1)],
      isRequired: false,
      value: person?.middle_name || ""
    }),
    legal_name: useTextInput({
      validators: [lengthValidation(1)],
      isRequired: false,
      value: person?.legal_name || ""
    }),
    last_name: useTextInput({
      validators: [lengthValidation(1)],
      isRequired: false,
      value: person?.last_name || ""
    }),
    phones: {
      checkValidity: () => !phonesRef?.current?.checkFieldsValidity(),
      value: phones,
      isRequired: false,
      isChanged: dynamicFields.isChanged
    },
    emails: {
      checkValidity: () => !emailsRef?.current?.checkFieldsValidity(),
      value: emails,
      isRequired: false,
      isChanged: dynamicFields.isChanged
    },
    locales: useTags({
      value: person?.locales.map(({id, name}) => ({id: String(id), name})),
      isRequired: false
    }),
    labels: useTags({
      value: person?.labels.map(({id, name}) => ({id: String(id), name})),
      isRequired: false
    }),
    accounts: {
      checkValidity: () => !socPlatformRef?.current?.checkFieldsValidity(),
      value: accounts,
      isRequired: false,
      isChanged: dynamicFields.isChanged
    }
  };

  const propsAvatar = person?.avatar_url || "";

  const isChanged =
    Object.values(formData).some(field => field.isChanged) || propsAvatar !== personPhoto;

  return {
    localesOptions: locales.data?.data?.data.filter(
      ({id}: {id: number}) => !person?.locales.some(locale => locale.id === id)
    ),
    labelsOptions: labels.data?.data?.data.filter(
      ({id}: {id: number}) => !person?.labels.some(locale => locale.id === id)
    ),
    isLoading: isLoadingMain,
    isValid: true,

    formData,
    isChanged,
    personPhoto,
    setPersonPhoto,

    phonesOptions: phoneTypes,
    phonesRef,
    phones,
    setPhones,

    emailsOptions: emailTypes,
    emailsRef,
    emails,
    setEmails,

    accPlatformOptions: platforms,
    socPlatformRef,
    accounts,
    setAccounts,
    person
  };
};
