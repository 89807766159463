export const jobTasksTableColumns = [
  {
    id: "name",
    label: "Task Name",
    sort: true
  },
  {
    id: "assignee",
    label: "Assignee",
    sort: true
  },
  {
    id: "status",
    label: "Status",
    sort: true
  },
  {
    id: "due_date",
    label: "Due Date"
  },
  {
    id: "notes",
    label: "Notes"
  },
  {
    id: "reminder",
    label: "Reminder",
    sort: true
  },
  {
    id: "role",
    label: "File"
  },
  {
    id: "",
    label: ""
  }
];
