import {useCustomReactSelect} from "components/shared/FormComponents/ReactSelect/useCustomReactSelect";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useEffect} from "react";
import {useContextJobBudget} from "../../../context/JobBudgetContext";
import {useContextTrack} from "../../_context/TrackContext";
import {generateTrackList} from "../generateMoveLists";

export type tCategorizedForm = ReturnType<typeof useCategorizedForm>;

export const useCategorizedForm = () => {
  const {estimateList, currRates} = useContextJobBudget();
  const {lists: {qbStatuses, payIds, payStatuses}, trackData} = useContextTrack(); // prettier-ignore

  const form = {
    estimate: useCustomReactSelect({
      required: true,
      options: estimateList?.filter(item => !!item?.approved_at && !!item?.line_counts)
    }),
    lineNumber: useCustomReactSelect({
      required: true,
      options: null
    }),
    expenseDescription: useTextInput({
      value: "",
      isRequired: false
    }),
    vendor: useTextInput({
      value: ""
    }),
    totalAmount: useTextInput({
      value: "",
      filters: ["onlyDigitsOneDotAndMinus"]
    }),
    currency: useCustomReactSelect({
      required: true,
      options: currRates
    }),
    payID: useCustomReactSelect({
      required: true,
      options: payIds?.list?.map(item => {
        // id 3 = credit cards
        if (item?.id === 3) {
          return {
            ...item,
            custom: true,
            label: item?.name,
            value: item?.id,
            subMenuOptions: item?.target_items?.map(subItem => ({
              ...subItem,
              label: `${subItem?.type?.name ?? ''} ${subItem?.holder_initials ?? ''} ${subItem?.last_four_digits ?? ''}`, // prettier-ignore
              value: subItem?.id
            }))
          };
        }
        return {...item, value: item?.id, label: item?.name};
      })
    }),
    payStatus: useCustomReactSelect({
      options: payStatuses?.list
    }),
    qbStatus: useCustomReactSelect({
      options: qbStatuses?.list
    })
  };

  useEffect(() => {
    const options = generateTrackList(trackData)?.filter(item => {
      return item?.estimate === +form?.estimate?.value?.id;
    });

    form?.lineNumber?.setOptions(options);
  }, [form?.estimate?.value]); // eslint-disable-line

  return form;
};
