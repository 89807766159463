import {FC} from "react";
import {StyleSheet, Text, View} from "@react-pdf/renderer";
import {SectionTitle} from "./SectionTitle";
import {tEstimateData} from "api/budget/estimate/types";
import {getUsMoneyFormat} from "helpers/formattingData";

interface iEstimatedProductionCosts {
  data: tEstimateData;
}

export const EstimatedProductionCosts: FC<iEstimatedProductionCosts> = ({data}) => {
  const fees = [
    {label: "Crew Payroll Fee", value: data.crew_payroll_fee},
    {label: "Talent Payroll Fee", value: data.talent_payroll_fee},
    {label: "Union Crew Payroll Fee", value: data.union_crew_payroll_fee},
    {label: "Union Talent Payroll Fee", value: data.union_talent_payroll_fee}
  ];

  return (
    <View style={styles.container}>
      <SectionTitle>Estimated production costs</SectionTitle>
      <View style={styles.list}>
        {data.category_values.map(({id, name, code, total}) => (
          <View style={styles.row} key={id}>
            <View style={styles.code}>
              <Text>{code}</Text>
            </View>
            <View style={styles.name}>
              <Text>{name}</Text>
            </View>
            <View style={styles.total}>
              <Text>{getUsMoneyFormat(total)}</Text>
            </View>
          </View>
        ))}
      </View>
      <View style={styles.list}>
        {fees.map(({label, value}) => (
          <View key={label} style={styles.row}>
            <View style={styles.code}></View>
            <View style={styles.name}>
              <Text>{label}</Text>
            </View>
            <View style={styles.total}>
              <Text>{getUsMoneyFormat(value)}</Text>
            </View>
          </View>
        ))}
      </View>
      <View style={styles.totalEstimate}>
        <View>
          <Text>TOTAL ESTIMATED</Text>
          <Text>PRODUCTION COSTS</Text>
        </View>
        <Text>{getUsMoneyFormat(data.budgeted_costs)}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
    // marginBottom: "14px"
  },
  list: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row"
    // marginBottom: 6
  },
  code: {
    width: "7%",
    fontFamily: "Helvetica400",
    fontSize: "10px"
  },
  name: {
    width: "54%",
    fontFamily: "Helvetica500",
    fontSize: "10px"
  },
  total: {
    width: "40%",
    fontFamily: "Helvetica400",
    fontSize: "10px",
    textAlign: "right"
  },
  totalEstimate: {
    marginTop: "12px",
    fontSize: "12px",
    marginBottom: "24px",
    textTransform: "uppercase",
    fontFamily: "Helvetica700",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "48%",
    marginLeft: "auto"
  }
});
