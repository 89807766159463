import {FC, useEffect, useState} from "react";
import {tJobTask} from "api/jobs/tasks/types";
import {InputCell} from "components/shared/InputCell/InputCell";
import {useContextJobTasks} from "pages/Jobs/JobDetails/JobTasks/_context/JobTaskContext";

type tNoteCellProps = {
  data?: tJobTask;
};

export const NoteCell: FC<tNoteCellProps> = ({data}) => {
  const {onSubmit, submitting, cellKeyRef, generateKey} = useContextJobTasks();
  const [value, setValue] = useState("");

  useEffect(() => {
    !!data?.notes && setValue(data?.notes);
  }, [data]);

  const typeApi = "notes";
  const currCellKey = generateKey(typeApi, data?.id);
  const isLoading = cellKeyRef?.current === currCellKey && submitting;

  const setNote = (value: string) => {
    if (!data?.id) return;
    onSubmit({
      typeApi,
      taskId: data?.id,
      data: {notes: value},
      errorCallback: () => setValue(data?.notes || "")
    });
  };

  return (
    <InputCell
      value={value}
      setValue={setValue}
      isLoading={isLoading}
      placeholder="Add note..."
      initValue={data?.notes || ""}
      callBack={val => setNote(val)}
    />
  );
};
