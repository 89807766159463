import {iPhone} from "api/interfaces";
import {ButtonCopyClipboard} from "components/shared/ButtonCopyClipboard/ButtonCopyClipboard";
import {FC} from "react";
import "./PhoneCell.scss";

interface iPhoneCell {
  phones: iPhone[];
}

export const PhoneCell: FC<iPhoneCell> = ({phones}) => {
  const phone = phones?.[0]?.value ?? "";
  return (
    <div className="PhoneCell">
      <a href={`tel:${phone}`} onClick={e => e.stopPropagation()}>
        {/* {formatPhoneNumber({value: phones?.[0]?.value})} */}
        {phones?.[0]?.value}
      </a>
      <ButtonCopyClipboard elementForCopy={phone} prefixToastMessage="Number" />
    </div>
  );
};
