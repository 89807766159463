import {FC, FormEvent} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {eventBus} from "EventBus/EventBus";
import {advanceApi} from "api/budget/advance";
import {useContextAdvance} from "../context";
import {useNavigate} from "react-router-dom";

interface iAdvanceDeleteProps {
  onClose: () => void;
}

export const AdvanceDelete: FC<iAdvanceDeleteProps> = ({onClose}) => {
  const navigate = useNavigate();
  const {advanceDetails, advanceList, setAdvanceList} = useContextAdvance();
  const {call, isLoading} = useHttp();

  const onConfirm = async (event: FormEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (!advanceDetails?.id) return;

    try {
      const {data: {message}} = await call(advanceApi.deleteAdvance(advanceDetails.id)); // prettier-ignore
      const newAdvanceList = advanceList.filter((advance) => advance.id !== advanceDetails.id); // prettier-ignore
      setAdvanceList(newAdvanceList);
      newAdvanceList?.[0] && navigate(String(newAdvanceList?.[0]?.id));
      eventBus.dispatch("showToast", {text: message});
      onClose();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <ConfirmWindow
      isLoading={isLoading}
      show={true}
      onConfirm={onConfirm}
      onClose={onClose}
      title="Delete Advance"
      subTitle="This advance will be permanently deleted."
      warning="This can't be undone."
      confirmBtn={{
        text: "Delete",
        color: "red"
      }}
      cancelBtn={{
        text: "Cancel"
      }}
    />
  );
};
