import {FC, FormEvent} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {Select} from "components/shared/FormComponents/Select/Select";
import {Loading} from "components/shared/Loading/Loading";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {TemplateModal} from "components/shared/TemplateModal/TemplateModal";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {notValidForm} from "helpers/misc";
import {TextComponent} from "../../common/TextComponent/TextComponent";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useSelect} from "hooks/inputHooks/useSelect";
import {jobTasksApi} from "api/jobs/tasks";
import {useContextJobTasks} from "../_context/JobTaskContext";
import {useGetTasksTemplates} from "./useGetTasksTemplates";

type tCreateNEwTaskFromTemplateProps = {
  onClose: () => void;
};

export const CreateNEwTaskFromTemplate: FC<tCreateNEwTaskFromTemplateProps> = ({onClose}) => {
  const {jobDetailsData} = useContextJobDetails();
  const id = jobDetailsData?.id;
  const {setTasks} = useContextJobTasks();

  const {call, isLoading: submitting} = useHttp();
  const FORM_ID = "create-job-task-from-template-form-id";

  const form = {
    name: useTextInput({value: ""}),
    template: useSelect({isRequired: true, options: [], targetKeys: {value: "id", label: "name"}})
  };

  const {isLoading, templateList} = useGetTasksTemplates({select: form.template});

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (notValidForm(form) || !id) return;

    try {
      const res = await call(
        jobTasksApi.createTaskListFromTemplate(id, +form.template.value, {name: form.name.value})
      );
      onClose();
      setTasks(res?.data?.data);
      onClose();
    } catch (error) {
      const {msg} = apiErrorHandler(error, form);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <ModalPopup
      show={true}
      className="LoadFromTemplate"
      onClose={onClose}
      withoutPaddings
      size="w-528"
    >
      <form className="LoadFromTemplate-form posRelative" onSubmit={onSubmit} id={FORM_ID}>
        <TemplateModal
          formId={FORM_ID}
          title="New FROM TEMPLATE"
          primaryButton={{text: "LOAD TEMPLATE"}}
          secondaryButton={{text: "Cancel"}}
          onCancel={onClose}
          isLoading={submitting}
        >
          {isLoading && (
            <div className="emptyBlock">
              <Loading type="inBlock" />
            </div>
          )}

          {!isLoading && !templateList?.length && (
            <TextComponent center className="mb-40">
              You have no templates yet !
            </TextComponent>
          )}

          {!isLoading && !!templateList?.length && (
            <>
              <TextComponent weight="400" size="14" center m="mb-16">
                Name your task list
              </TextComponent>
              <InputField {...form.name.inputProps} errors={form.name.errors} />
              <TextComponent weight="400" size="14" center m="mb-16">
                Choose a template
              </TextComponent>
              <Select {...form.template.inputProps} errors={form.template.errors} canSelectEmpty />
            </>
          )}
        </TemplateModal>
      </form>
    </ModalPopup>
  );
};
