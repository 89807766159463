import {CSSProperties, FC, HTMLAttributes} from "react";
import {Portal} from "components/shared/Portal/Portal";
import {tPositionX, tPositionY, useFixedElementPosition} from "hooks/useFixedElementPosition";
import "./DropdownMenu.scss";

interface iDropdownMenuProps {
  className?: string;
  anchor: HTMLElement | null;
  onClose: () => void;
  anchorOrigin?: {vertical?: tPositionY; horizontal?: tPositionX};
  transformOrigin?: {vertical?: tPositionY; horizontal?: tPositionX};
  menuListProps?: HTMLAttributes<HTMLDivElement>;
  backdropProps?: HTMLAttributes<HTMLDivElement>;
  customPosition?: {bottom?: string; top?: string; left?: string; right?: string};
  withSubmenu?: boolean;
  bodyStyles?: CSSProperties;
  btnHover?: boolean;
}

export const DropdownMenu: FC<iDropdownMenuProps> = ({
  children,
  onClose,
  anchor,
  className = "",
  anchorOrigin = {vertical: "bottom", horizontal: "right"},
  transformOrigin = {vertical: "bottom", horizontal: "left"},
  menuListProps,
  backdropProps,
  customPosition,
  withSubmenu,
  bodyStyles,
  btnHover
}) => {
  const {setBodyRef, menuPosition} = useFixedElementPosition({
    anchorOrigin,
    transformOrigin,
    anchor
  });

  const isShow = Boolean(anchor);
  if (!isShow) return null;

  const isSubmenu = withSubmenu ? "withSubmenu" : "";
  const customPos = customPosition ? customPosition : menuPosition;
  const btnHoverClass = btnHover ? "btnHover" : "";

  return (
    <Portal className={`DropdownMenu ${className} ${isSubmenu} ${btnHoverClass}`}>
      <div className="DropdownMenu-backdrop backdrop" onClick={onClose} {...backdropProps} />
      <div
        ref={newRef => setBodyRef(newRef)}
        className={`DropdownMenu-body body`}
        style={{...bodyStyles, ...customPos}}
        {...menuListProps}
      >
        {children}
      </div>
    </Portal>
  );
};

/*
state:
const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
  setMenuAnchor(event.currentTarget);
};


component:
<Button onClick={showMenu} size="md" color="outline">
  <Icon icon="menu" size="xsm" />
</Button>

<DropdownMenu anchor={menuAnchor} onClose={() => setMenuAnchor(null)}>
  <Button size="sm">Duplicate job</Button>
  <Button size="sm">Save as template</Button>
  <Button size="sm">Delete</Button>
</DropdownMenu>
 */
