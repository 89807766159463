import {FC, ReactNode, useState, MouseEvent} from "react";
import {iBaseOptions} from "api/common/types";
import {TagsRow} from "./TagsRow";

interface iTableLocaleCell {
  data: iBaseOptions[];
  emptyData: ReactNode;
  onClick(event: MouseEvent<any, any>, item: iBaseOptions): void;
}

export const TableLocaleCell: FC<iTableLocaleCell> = ({data, onClick, emptyData}) => {
  const [cellWidth, setCellWidth] = useState(0);

  const onUpdateCellWidth = (el: HTMLTableDataCellElement | null) => {
    setCellWidth(el?.offsetWidth || 0);
  };

  return (
    <td className="JobsTableRow-cell" ref={onUpdateCellWidth}>
      {data && <TagsRow maxWidth={cellWidth} onClick={onClick} data={data} emptyData={emptyData} />}
    </td>
  );
};
