import {FC} from "react";
import {TableHeadItem} from "components/Table/TableHeadItem";
import {Table} from "components/Table/Table";
import {trackingTableColumn} from "./trackingTableColumn";
import {TrackingTableBody} from "./TrackingTableBody/TrackingTableBody";
import {useContextJobCallSheet} from "../context/JobCallSheetContext";

import "./TrackingTable.scss";

interface iTrackingTableProps {}

export const TrackingTable: FC<iTrackingTableProps> = () => {
  const {deliveryInfo} = useContextJobCallSheet();

  if (!deliveryInfo) return null;

  const renderHeaderList = () => {
    return trackingTableColumn.map(item => <TableHeadItem key={item.id} data={item} />);
  };

  const groups = deliveryInfo?.groups?.filter(({hidden}) => !hidden);
  return (
    <div className="TrackingTable">
      <Table
        headerRowChildren={renderHeaderList()}
        bodyChildren={
          <>
            {groups?.map((group, i) => {
              return (
                <TrackingTableBody
                  key={group.id}
                  group={group}
                  deliveryInfo={deliveryInfo}
                  isFirstGroup={i === 0}
                />
              );
            })}
          </>
        }
      />
    </div>
  );
};
