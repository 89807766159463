import {AxiosRequestConfig} from "axios";
import {iLocationPostData, iLocationsRequestParams} from "./types";

const LOCATIONS = "locations";
const LOCATION = "location";
const LOCATION_TYPES = "location_types";

export const locationApi = {
  locations: {
    get: (params?: iLocationsRequestParams): AxiosRequestConfig => ({
      url: LOCATIONS,
      method: "GET",
      params
    })
  },
  location: {
    get: (id: number): AxiosRequestConfig => ({
      url: `${LOCATION}/${id}`,
      method: "GET"
    }),
    post: (data: iLocationPostData): AxiosRequestConfig => ({
      url: LOCATION,
      method: "POST",
      data
    }),
    put: (data: iLocationPostData, id: number): AxiosRequestConfig => ({
      url: `${LOCATION}/${id}`,
      method: "POST",
      data
    }),
    delete: (id: number): AxiosRequestConfig => ({
      url: `${LOCATION}/${id}`,
      method: "DELETE"
    })
  },
  location_types: {
    get: (): AxiosRequestConfig => ({
      url: LOCATION_TYPES,
      method: "GET"
    })
  },
  exportLocations: (params?: iLocationsRequestParams): AxiosRequestConfig => ({
    method: "GET",
    url: `${LOCATIONS}/export`,
    params
  }),
  importLocations: (data: any[]): AxiosRequestConfig => ({
    method: "POST",
    url: `${LOCATIONS}/import`,
    data
  })
};
