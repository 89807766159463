import {FC} from "react";
import "./BudgetOverlayFooter.scss";

interface iBudgetOverlayFooterProps {
  page: number;
  total: number;
}

export const BudgetOverlayFooter: FC<iBudgetOverlayFooterProps> = ({page, total}) => {
  return (
    <div className="BudgetOverlayFooter">
      <div className="BudgetOverlayFooter-leftSide">
        <p>Versatile Studios</p>
        <p> 866 6th Ave, 8th Fl, New York, NY 10001, USA</p>
        <p> +1 212.685.4384</p>
      </div>
      <div className="BudgetOverlayFooter-rightSide">
        <p>
          Page {page} of {total}
        </p>
        <a id="versatile-link" rel="noreferrer" href="https://versatilestudios.com" target="_blank">
          www.versatilestudios.com
        </a>
      </div>
    </div>
  );
};
