import {FC, ReactNode, useState} from "react";
import {Button} from "components/shared/Button/Button";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {DeleteTasksGroup} from "../../../../_modals/DeleteTasksGroup";
import "./ActionCell.scss";

type tActionsCellProps = {
  index: number;
  groupName?: string;
};

type tModals = "delete";

export const ActionsCell: FC<tActionsCellProps> = ({index, groupName}) => {
  const [modal, setModal] = useState<tModals | null>(null);

  const [menu, setMenu] = useState<HTMLElement | null>(null);
  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenu(event.currentTarget);
  };
  const onCloseMenu = () => setMenu(null);
  const onCloseModal = () => setModal(null);
  const showModal = (type: tModals) => {
    setModal(type);
    onCloseMenu();
  };

  const modals: Record<tModals, ReactNode> = {
    delete: <DeleteTasksGroup onClose={onCloseModal} id={index} type="task" groupName={groupName} />
  };

  return (
    <div className="ActionsCell">
      <TextComponent onClick={showMenu} as="button" className="dots">
        &#183;&#183;&#183;
      </TextComponent>

      <DropdownMenu anchor={menu} onClose={() => setMenu(null)}>
        <Button size="sm" onClick={() => showModal("delete")}>
          Delete
        </Button>
      </DropdownMenu>

      {!!modal && modals[modal]}
    </div>
  );
};
