import {FC} from "react";
import {FormWrap} from "components/shared/FormWrap/FormWrap";
import {useContextTrack} from "../../_context/TrackContext";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {tTrackCostLineFile} from "api/budget/track/types";
import {CustomOption} from "components/shared/FormComponents/ReactSelect/CustomOption";
import {CustomReactSelect} from "components/shared/FormComponents/ReactSelect/CustomReactSelect";
import {tCategorizedForm} from "./useCategorizedForm";
import {tSubmitCategorizedForm} from "./useSubmitCategorizedForm";

type tCategorizedFormProps = {
  currFile: tTrackCostLineFile | undefined;
  form: tCategorizedForm;
  onSubmit: tSubmitCategorizedForm["onSubmit"];
  isLoading: tSubmitCategorizedForm["isLoading"];
};

export const CategorizedForm: FC<tCategorizedFormProps> = ({
  currFile,
  form,
  onSubmit,
  isLoading
}) => {
  const {trackData} = useContextTrack();
  const formId = "categorized-form-id";

  const currFileIndex = trackData?.expense_files?.findIndex(item => item?.id === currFile?.id);
  const index = currFileIndex !== undefined ? currFileIndex + 1 : 0;

  return (
    <FormWrap
      idForm={formId}
      headerTitle={`CATEGORIZE EXPENSE (${index}/${trackData?.expense_files?.length})`}
      type="titleCenterAndOneBtn"
      mainButtonColor="outline"
      submitTitle={"Save to line"}
      withScroll
      isLoading={isLoading}
    >
      <form id={formId} onSubmit={onSubmit}>
        <CustomReactSelect
          getOptionLabel={item => item?.name}
          getOptionValue={item => String(item?.id)}
          {...form?.estimate}
          label="ESTIMATE*"
        />
        <CustomReactSelect
          getOptionLabel={item => item?.label}
          getOptionValue={item => String(item?.id)}
          {...form?.lineNumber}
          label="LINE NUMBER*"
        />
        <InputField
          label="Expense Description"
          {...form.expenseDescription.inputProps}
          errors={form.expenseDescription.errors}
        />
        <InputField label="Vendor*" {...form.vendor.inputProps} errors={form.vendor.errors} />
        <div className="amountCurrency">
          <InputField
            label="Total Amount*"
            {...form.totalAmount.inputProps}
            errors={form.totalAmount.errors}
          />
          <CustomReactSelect
            getOptionLabel={item => item?.code}
            getOptionValue={item => String(item?.code)}
            {...form?.currency}
            label="Currency"
          />
        </div>
        <CustomReactSelect
          components={{Option: CustomOption}}
          getOptionLabel={item => item?.label}
          getOptionValue={item => String(item?.value)}
          {...form?.payID}
          label="Pay ID*"
        />
        <CustomReactSelect
          getOptionLabel={item => item?.name}
          getOptionValue={item => String(item?.id)}
          {...form?.payStatus}
          label="PAY STATUS"
        />
        <CustomReactSelect
          getOptionLabel={item => item?.name}
          getOptionValue={item => String(item?.id)}
          {...form?.qbStatus}
          label="QB STATUS"
        />
      </form>
    </FormWrap>
  );
};
