import {useEffect} from "react";
import {jobCrew} from "api/jobCrew";
import {tCrewTemplate} from "api/jobCrew/types";
import {useHttp} from "hooks/httpServices/useHttp";

export const useGetCrewTemplates = (select: any) => {
  const {data, ...props} = useHttp({config: jobCrew.getJobCrewTemplates(), onMountCall: true});
  const templateList = data?.data?.data as Array<tCrewTemplate>;

  useEffect(() => {
    !!data && select.setOptions(data?.data?.data);
  }, [data]); // eslint-disable-line

  return {
    ...props,
    templateList
  };
};
