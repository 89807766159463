import {FC} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {tTrackExpenseLine} from "api/budget/track/types";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextTrack} from "../_context/TrackContext";
import {trackApi} from "api/budget/track";
import {useContextExpenseList} from "../ExpenseList/context/ExpenseListContext";
import {useResponseType} from "../_common/useResponseType";

type tDeleteExpenseProps = {
  onClose: () => void;
  trackLine: tTrackExpenseLine;
};

export const DeleteExpense: FC<tDeleteExpenseProps> = ({onClose, trackLine}) => {
  const {setTrackData} = useContextTrack();
  const {setExpenseList} = useContextExpenseList();
  const {isExpense, response_type} = useResponseType();
  const {call, isLoading} = useHttp();

  const onRemoveExpense = async () => {
    try {
      const res = await call(trackApi?.deleteExpenseLine(trackLine?.id, response_type));
      isExpense && setExpenseList(res?.data?.data);
      !isExpense && setTrackData(res?.data?.data);
      onClose();
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <ConfirmWindow
      title="Delete Expense?"
      subTitle={`This expense will be permanently deleted.`}
      show={true}
      onClose={onClose}
      onConfirm={onRemoveExpense}
      isLoading={isLoading}
      confirmBtn={{
        text: "Delete",
        color: "red"
      }}
      cancelBtn={{
        text: "cancel"
      }}
    />
  );
};
