import {FC} from "react";
import {SwitchInput} from "components/shared/FormComponents/SwitchInput/SwitchInput";
import {useContextProfile} from "context";
import {profileInfo} from "api/profile";
import {useHttp} from "hooks/httpServices/useHttp";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {Loading} from "components/shared/Loading/Loading";
import "./AccountSettings.scss";

type tAccountSettingsProps = {
  [key: string]: any;
};

export const AccountSettings: FC<tAccountSettingsProps> = () => {
  const {userData, setUserData} = useContextProfile();
  const isClosedTooltips = !!userData?.tooltips?.length;
  const {call, isLoading} = useHttp();

  const updateTooltips = async () => {
    if (!isClosedTooltips) return;

    try {
      const res = await call(profileInfo?.resetTooltips());
      setUserData(res?.data?.data);
      eventBus.dispatch("showToast", {type: "success", text: res?.data?.message || "Success!"});
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <div className="AccountSettings">
      {isLoading && <Loading type="inBlock" />}

      <div className="AccountSettings-group-header">
        <h4 className="AccountSettings-title">TOOLTIPS</h4>
        <SwitchInput
          checked={isClosedTooltips ? false : true}
          value={String(isClosedTooltips)}
          onChange={updateTooltips}
        />
      </div>

      <div className="AccountSettings-group-info">Reset onboarding tooltips</div>
    </div>
  );
};
