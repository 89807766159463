import {Header} from "components/Header/Header";
import {tabs} from "../navModel";
import {OverviewJobsPipeline} from "./OverviewJobsPipeline/OverviewJobsPipeline";
import {TableToolbar} from "components/TableToolbar/TableToolbar";
import {FilterButtons} from "components/FilterButtons/FilterButtons";
import {Table} from "components/Table/Table";
import {TableEmptyState} from "components/Table/TableEmptyState";
import {OverviewContextProvider, useContextOverview} from "./OverviewContext";
import {useApiListNotification} from "hooks/useApiListNotification/useApiListNotification";
import {TableHeadItem} from "components/Table/TableHeadItem";
import {Pagination} from "components/Pagination/Pagination";
import {JobsTableRow} from "components/shared/JobsTable/JobsTableRow/JobsTableRow";
import {jobsTableColumn} from "components/shared/JobsTable/jobsTableColumn";
import {TeamContextProvider} from "pages/Team/TeamContext";
import {TeamDetailsContextProvider} from "pages/Team/TeamDetailsContext";
import {ContactsContextProvider} from "pages/Contacts/context/ContactsContext";
import {ContactsDetailsContextProvider} from "pages/Contacts/context/ContactsDetailsContext";
import "./Overview.scss";
import {LocationsContextProvider} from "pages/Locations/context/LocationsContext";
import {useContextProfile} from "context";

export default function Overview() {
  return (
    <OverviewContextProvider>
      <ContactsContextProvider>
        <LocationsContextProvider>
          <ContactsDetailsContextProvider>
            <TeamContextProvider>
              <TeamDetailsContextProvider>
                <OverviewComponent />
              </TeamDetailsContextProvider>
            </TeamContextProvider>
          </ContactsDetailsContextProvider>
        </LocationsContextProvider>
      </ContactsContextProvider>
    </OverviewContextProvider>
  );
}

const OverviewComponent = () => {
  const {
    overviewList,
    isLoading,
    isError,
    error,
    data,
    apiParams,
    getOverviewList,
    getJobsPipelines
  } = useContextOverview();

  const {show, element} = useApiListNotification({isLoading, isError, error, list: overviewList});
  const {userData} = useContextProfile();
  const isTooltip = !userData?.tooltips?.includes("FAVORITING") && !!overviewList?.length;

  const renderHeaderList = () => {
    return jobsTableColumn.map(item => <TableHeadItem key={item.id} data={item} />);
  };

  const renderBodyList = () => {
    if (!overviewList?.length && !isError) return element;
    return overviewList?.map((job, i) => (
      <JobsTableRow
        key={job?.id}
        data={job}
        onEditCallback={getOverviewList}
        id={job.id}
        isFirstRow={i === 0}
        onUpdatePipelineCallback={getJobsPipelines}
        isDashboard
      />
    ));
  };

  return (
    <div className="Overview">
      <Header title="Dashboard" tabs={tabs} />

      <OverviewJobsPipeline />

      <div className="content-padding">
        <TableToolbar
          titleChildren={
            <>
              YOUR JOBS&nbsp;
              <span className="jobs-number">Showing {overviewList?.length} Jobs</span>
            </>
          }
          typeFilter="customChildren"
          children={<FilterButtons apiParams={apiParams} isLoading={isLoading} />}
        />

        <Table
          isLoading={isLoading}
          scroll
          headerRowChildren={renderHeaderList()}
          bodyChildren={renderBodyList()}
          wrapperStyles={isTooltip ? {minHeight: "200px"} : undefined}
        />

        {show && !isLoading && !isError && (
          <TableEmptyState message="Your assigned jobs will appear here" />
        )}

        {data && <Pagination meta={data.data.meta} />}
      </div>
    </div>
  );
};
