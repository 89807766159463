import {FC} from "react";
import {tCrewContact} from "api/jobCrewGroup/types";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import "./RowNotes.scss";

type tRowNotesProps = {
  crewContact: tCrewContact;
  groupId: number;
};

export const RowNotes: FC<tRowNotesProps> = ({crewContact}) => {
  return (
    <TextComponent size="13" weight="400" color="black-10" className="note">
      {crewContact?.notes}
    </TextComponent>
  );
};
