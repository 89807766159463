import {FC} from "react";
import {Outlet} from "react-router-dom";
import {JobBudgetProvider, useContextJobBudget} from "./context/JobBudgetContext";
import {EmptyState} from "./EmptyState/EmptyState";
import {Loading} from "components/shared/Loading/Loading";
import {TrackProvider} from "./Track/_context/TrackContext";
import {ExpenseListProvider} from "./Track/ExpenseList/context/ExpenseListContext";

import "./JobBudget.scss";

export default function JobBudget() {
  return (
    <JobBudgetProvider>
      <JobBudgetComponent />
    </JobBudgetProvider>
  );
}

export const JobBudgetComponent: FC = () => {
  const {estimateList, estimateListLoading} = useContextJobBudget();
  const isEmpty = !estimateList?.length && !estimateListLoading;

  return (
    <div className="JobBudget">
      {estimateListLoading && <Loading type="inBlock" />}

      {isEmpty && <EmptyState />}

      {!isEmpty && (
        <TrackProvider>
          <ExpenseListProvider>
            <Outlet />
          </ExpenseListProvider>
        </TrackProvider>
      )}
    </div>
  );
};
