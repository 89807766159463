import {useOnClickOutside} from "hooks/useOnclickOutside";
import {currencyFilterWithNegativeValue} from "hooks/useTextInput/filters";
import React, {ChangeEvent, useEffect, useRef, useState} from "react";

type tProps = {
  defaultValue?: string;
  onBlurCallBack: (arg: string) => void;
};

export const useTrackLineInput = ({defaultValue, onBlurCallBack}: tProps) => {
  const [value, setValue] = useState(defaultValue || "");
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const showInputField = () => setShowInput(true);

  const clickOutside = () => {
    setShowInput(false);
    onBlurCallBack(value);
  };

  useOnClickOutside(inputRef, clickOutside);

  useEffect(() => {
    !!defaultValue && setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (showInput && inputRef && inputRef?.current) {
      const input = inputRef?.current?.children?.[0] as HTMLInputElement | HTMLTextAreaElement;
      input.focus();
      input?.setSelectionRange(value?.length, value?.length);
    }
  }, [showInput]); // eslint-disable-line

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let {value} = e.target;
    const val = currencyFilterWithNegativeValue(value);
    setValue(val);
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.key === "Enter" && clickOutside();
  };

  const onFocus = () => {
    setShowInput(true);
  };

  return {value, onChange, onKeyPress, onFocus, ref: inputRef, showInput, showInputField};
};
