import React, {FC} from "react";
import {iImgWithFallbackProps, ImgWithFallback} from "../ImgWithFallback/ImgWithFallback";
import {ImageCrop} from "../ImageCrop/ImageCrop";
import "./UserUploadFile.scss";

interface iUserUploadFile {
  avatar: string;
  setAvatar: React.Dispatch<React.SetStateAction<string>>;
  fallBackType?: iImgWithFallbackProps["fallBackType"];
}

export const UserUploadFile: FC<iUserUploadFile> = ({
  avatar,
  setAvatar,
  fallBackType = "default"
}) => {
  return (
    <div className="UserUploadFile">
      <ImgWithFallback src={avatar || ""} alt="avatar" fallBackType={fallBackType} />
      {/* <ImageCropper
        onChangeImage={setAvatar}
        cropper={{
          circularCrop: true
        }}
      /> */}

      <ImageCrop onChangeImage={setAvatar} aspect="circle" />
    </div>
  );
};
