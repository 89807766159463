import React, {FC, MouseEvent, useEffect, useState} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {LocationDetails} from "../LocationDetails/LocationDetails";
import {useHttp} from "hooks/httpServices/useHttp";
import {locationApi} from "api/location";
import {iLocationDetailsResponse} from "api/location/types";
import {Loading} from "components/shared/Loading/Loading";
import {LocationForm} from "../LocationForm/LocationForm";
import {PeopleModals} from "components/shared/Modals/PeopleModals/PeopleModals";
import {ModalConfirmCancelEditing} from "components/shared/ModalConfirmCancelEditing/ModalConfirmCancelEditing";

interface iLocationsModalsProps {
  location: number | iLocationDetailsResponse;
  closeModal: () => void;
  onEditCallback?: Function;
}

export const LocationsModals: FC<iLocationsModalsProps> = ({
  onEditCallback,
  location,
  closeModal
}) => {
  const {call} = useHttp();
  const [locationDetails, setLocationDetails] = useState<iLocationDetailsResponse | null>(null);
  const [showContact, setShowContact] = useState<number | null>(null);
  const [editModal, setEditModal] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (!location) return;
    if (typeof location === "number") {
      call(locationApi.location.get(location)).then(({data: {data}}) => setLocationDetails(data));
      return;
    }
    setLocationDetails(location);
  }, [location]); // eslint-disable-line

  const closePeopleModal = () => setShowContact(null);
  const closeEditModal = () => setEditModal(false);

  const showContactDetails = (event: MouseEvent<HTMLButtonElement>, id: number) => {
    event?.stopPropagation();
    setShowContact(id);
  };

  const editLocation = () => setEditModal(true);

  const closeEditForm = () => {
    if (editModal) {
      isChanged ? setShowCancelConfirm(true) : closeEditModal();
      return;
    }
    closeModal();
  };

  return (
    <>
      <ModalPopup className="LocationModal" show={true} onClose={closeEditForm}>
        {!locationDetails && <Loading type="inBlock" />}

        {locationDetails && !editModal && (
          <LocationDetails
            location={locationDetails}
            showContactDetails={showContactDetails}
            editLocation={editLocation}
          />
        )}

        {editModal && (
          <LocationForm
            location={locationDetails}
            closeEditModal={closeEditForm}
            onCloseModal={closeModal}
            onEditCallback={onEditCallback}
            formDataIsChanged={setIsChanged}
          />
        )}
        {showContact && <PeopleModals person={showContact} closePeopleModal={closePeopleModal} />}
      </ModalPopup>

      <ModalConfirmCancelEditing
        show={showCancelConfirm}
        onConfirm={() => {
          closeEditModal();
          setShowCancelConfirm(false);
        }}
        onClose={() => setShowCancelConfirm(false)}
      />
    </>
  );
};
