import {FC, ChangeEvent, useState} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {Checkbox} from "components/shared/FormComponents/Checkbox/Checkbox";
import {useHttp} from "hooks/httpServices/useHttp";
import {iExportContactsParams, tExportContactsFormats} from "api/contacts/people/interfaces";
import {AxiosRequestConfig} from "axios";
import moment from "moment";
import {eventBus} from "EventBus/EventBus";
import {useSetApiParams} from "hooks/useSetApiParams";

import "./ExportModal.scss";

type tExportType = "companies" | "people";

type tSaveFormat = tExportContactsFormats | "zip";

interface iExportModalProps {
  id?: number | undefined;
  type: tExportType;
  onClose: () => void;
  show: boolean;
  title?: string;
  subtitle?: string;
  exportRequest: (params: iExportContactsParams, id?: any) => AxiosRequestConfig;
}

export const ExportModal: FC<iExportModalProps> = ({
  type,
  show = true,
  onClose,
  title = "",
  subtitle,
  exportRequest,
  id
}) => {
  const apiParams = useSetApiParams();
  const {call, isLoading} = useHttp();
  const [formats, setFormats] = useState<tExportContactsFormats[]>([]);

  const filename = (type: tExportType, format: tSaveFormat) => {
    return `${type}_contacts_${moment().format("MM_DD")}.${format}`;
  };

  const onExportContacts = async () => {
    if (!formats) return;

    const params = {
      formats,
      ...apiParams?.preparedParams,
      locales: apiParams?.preparedParams.locales?.split(","),
      labels: apiParams?.preparedParams.labels?.split(","),
      ...(apiParams?.preparedParams.favorite && {
        favorite: apiParams?.preparedParams.favorite === "true" ? "1" : "0"
      })
    };

    const config = id ? {...exportRequest({formats}, id)} : {...exportRequest(params)};

    try {
      const {
        data: {url}
      } = await call(config);
      const saveFormat: tSaveFormat = formats.length === 2 ? "zip" : formats[0];
      const link = document.createElement("a");
      link.href = url;
      link.download = filename(type, saveFormat);
      link.click();
      link.remove();

      eventBus.dispatch("showToast", {
        text: `Contact${id ? " was" : "s were"} exported successfully.`
      });
      onClose();
    } catch (error: any) {
      eventBus.dispatch("showToast", {type: "error", text: error?.message});
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {checked, value} = event.target;
    const newValue = value as tExportContactsFormats;

    checked
      ? setFormats(prevState => [...prevState, newValue])
      : setFormats(prevState => prevState.filter(item => item !== value));
  };

  return (
    <ConfirmWindow
      title={title}
      show={show}
      onClose={onClose}
      confirmBtn={{text: "Export", disabled: !formats.length}}
      cancelBtn={{text: "Cancel"}}
      onConfirm={onExportContacts}
      isLoading={isLoading}
    >
      <form className="ExportModal-body">
        {subtitle}
        <label className="ExportModal-fields-group-label">Choose Format</label>
        <div className="ExportModal-fields-group">
          <Checkbox
            label="CSV"
            checked={formats.includes("csv")}
            value="csv"
            onChange={handleChange}
          />
          <Checkbox
            label="vCard (iOS)"
            checked={formats.includes("vcf")}
            value="vcf"
            onChange={handleChange}
          />
        </div>
      </form>
    </ConfirmWindow>
  );
};
