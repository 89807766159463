import {FC, useEffect, useState} from "react";
import {EstimateGroupRow} from "./EstimateGroupRow/EstimateGroupRow";
import {EstimateRow} from "./EstimateRow/EstimateRow";
import {EstimateTableHeader} from "./EstimateTableHeader/EstimateTableHeader";
import {EstimateGroupRowFooter} from "./EstimateGroupRowFooter/EstimateGroupRowFooter";
import {tEstimateGroup} from "api/budget/estimate/types";
import {useChangeOrderTableRow} from "./useChangeOrderTableRow";
import {useContextJobBudget} from "../_context/JobBudgetContext";
import "./EstimateTable.scss";

type tEstimateTableProps = {};

export const EstimateTable: FC<tEstimateTableProps> = () => {
  const {estimate} = useContextJobBudget();

  return (
    <div className={`EstimateTable`} id="EstimateTableId">
      <EstimateTableHeader />

      {estimate?.category_values?.map((group, i) => {
        return <Group key={group?.id} group={group} index={i} />;
      })}
    </div>
  );
};

type tGroup = {
  group: tEstimateGroup;
  index: number;
};

const Group = ({group, index}: tGroup) => {
  const [data, setData] = useState<tEstimateGroup>(group);
  const {setCurrItem, dropCallBack, isLoading} = useChangeOrderTableRow({setData});
  const [showList, setShowList] = useState(index === 0 ? true : false);
  const showListToggler = () => setShowList(prev => !prev);

  useEffect(() => {
    setData(group);
  }, [group]);

  return (
    <>
      <EstimateGroupRow
        groupId={group.id}
        title={`${group?.code} ${group.name}`}
        value={group?.production_percent}
        showListToggler={showListToggler}
        showList={showList}
      />

      {showList && (
        <>
          {data?.cost_values?.map(row => {
            return (
              <EstimateRow
                key={row?.id}
                group={group}
                currItem={row}
                setCurrItem={setCurrItem}
                dropCallBack={currItem => dropCallBack(currItem, group)}
                isLoading={isLoading}
              />
            );
          })}
        </>
      )}

      <EstimateGroupRowFooter totalVal={group?.total} groupId={group?.id} showList={showList} />
    </>
  );
};
