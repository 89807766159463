import {FC} from "react";

type tLinkProps = {
  [key: string]: any;
};

export const LinkIcon: FC<tLinkProps> = () => {
  return (
    <svg
      width="14"
      height="14"
      className="linkIcon"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.93079 5.06929C10.5645 6.70469 10.5421 9.32665 8.94064 10.937C8.93763 10.9403 8.93407 10.9438 8.93079 10.9471L7.0933 12.7846C5.47264 14.4052 2.83591 14.405 1.2155 12.7846C-0.405165 11.1642 -0.405165 8.52717 1.2155 6.90679L2.23011 5.89217C2.49917 5.62311 2.96254 5.80194 2.97643 6.18218C2.99415 6.66676 3.08105 7.15362 3.24139 7.62376C3.2957 7.78296 3.25689 7.95905 3.13795 8.078L2.7801 8.43584C2.01377 9.20218 1.98973 10.45 2.74852 11.2238C3.5148 12.0053 4.77431 12.0099 5.54646 11.2378L7.38396 9.40053C8.15481 8.62968 8.15158 7.38374 7.38396 6.61612C7.28276 6.51511 7.18082 6.43664 7.1012 6.38181C7.04487 6.34313 6.99836 6.29182 6.96538 6.23198C6.9324 6.17213 6.91386 6.1054 6.91124 6.03712C6.90041 5.74818 7.00279 5.45043 7.23111 5.22211L7.8068 4.64639C7.95777 4.49543 8.19459 4.47689 8.36965 4.59906C8.57012 4.73905 8.75789 4.8964 8.93079 5.06929ZM12.7845 1.21541C11.1641 -0.405029 8.52734 -0.405247 6.90668 1.21541L5.06918 3.05291C5.0659 3.05619 5.06234 3.05975 5.05934 3.06303C3.45792 4.67335 3.43548 7.29531 5.06918 8.93071C5.24207 9.1036 5.42983 9.26094 5.6303 9.40091C5.80535 9.52308 6.04221 9.50452 6.19314 9.35358L6.76884 8.77786C6.99716 8.54954 7.09953 8.25179 7.0887 7.96285C7.08609 7.89457 7.06755 7.82785 7.03457 7.768C7.00159 7.70815 6.95508 7.65684 6.89875 7.61816C6.81912 7.56333 6.71718 7.48486 6.61599 7.38385C5.84837 6.61623 5.84514 5.37029 6.61599 4.59944L8.45348 2.76222C9.22564 1.99006 10.4851 1.99471 11.2514 2.77616C12.0102 3.54999 11.9862 4.79779 11.2198 5.56413L10.862 5.92198C10.7431 6.04092 10.7043 6.21701 10.7586 6.37621C10.9189 6.84636 11.0058 7.33321 11.0235 7.81779C11.0374 8.19804 11.5008 8.37686 11.7698 8.1078L12.7844 7.09319C14.4051 5.47283 14.4051 2.8358 12.7845 1.21541Z"
        fill="#D4D4D6"
      />
    </svg>
  );
};
