import {useEffect} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {companies} from "api/contacts/companies";
import {useSetApiParams} from "hooks/useSetApiParams";
import {tCompanyPick} from "api/contacts/companies/interfaces";
import {useLocation} from "react-router-dom";
import {absRoutes} from "router/helpers";
import {tMeta} from "components/Pagination/types";

export const useCompanies = () => {
  const {call, data, isLoading, isError, error} = useHttp<{data: tCompanyPick[]; meta: tMeta}>();
  const apiParams = useSetApiParams();
  const history = useLocation();

  useEffect(() => {
    if (!history.pathname.includes(absRoutes.contacts.companies)) return;
    getCompaniesList();
  }, [apiParams?.searchParams, history.pathname]); // eslint-disable-line

  const getCompaniesList = async () => {
    const params = {
      locales: apiParams?.preparedParams.locales?.split(","),
      labels: apiParams?.preparedParams.labels?.split(","),
      ...(apiParams?.preparedParams.favorite && {
        favorite: apiParams?.preparedParams.favorite === "true" ? "1" : "0"
      })
    };

    await call(companies.getList({...apiParams?.preparedParams, ...params}));
  };

  return {
    companies: data?.data.data || [],
    companiesListMeta: data?.data?.meta,
    isLoading,
    isError,
    error,
    apiParams,
    getCompaniesList
  };
};
