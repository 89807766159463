import {FC, FormEvent, useEffect} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {Select} from "components/shared/FormComponents/Select/Select";
import {useSelect} from "hooks/inputHooks";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {eventBus} from "EventBus/EventBus";
import {Loading} from "components/shared/Loading/Loading";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {notValidForm} from "helpers/misc";
import {useContextJobBudget} from "../../context/JobBudgetContext";
import {estimateApi} from "api/budget/estimate";

interface iEstimateSaveAsExistingTemplateProps {
  onClose: () => void;
}

export const EstimateSaveAsExistingTemplate: FC<iEstimateSaveAsExistingTemplateProps> = ({
  onClose
}) => {
  const {estimate} = useContextJobBudget();
  const id = estimate?.id;
  const {call, isLoading: submitting} = useHttp();
  const FORM_ID = "save-as-existing-estimate-template-form-id";

  const {data, isLoading} = useHttp({onMountCall: true, config: estimateApi?.getEstimateTemplateList()}); // prettier-ignore
  const templateList = data?.data?.data as Array<any>;

  const form = {
    templateId: useSelect({
      options: [],
      targetKeys: {value: "id", label: "name"},
      placeholder: "Select...",
      isRequired: true
    })
  };

  useEffect(() => {
    data && form?.templateId?.setOptions(data?.data?.data);
  }, [data]); // eslint-disable-line

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (notValidForm(form) || !id) return;

    try {
      const res = await call(estimateApi.saveAsExistingTemplate(id, +form.templateId.value));

      onClose();
      eventBus.dispatch("showToast", {type: "success", text: res?.data?.message || "Success!"});
    } catch (error) {
      const {msg} = apiErrorHandler(error, form);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <ConfirmWindow
      show={true}
      title="SAVE AS EXISTING TEMPLATE"
      subTitle="Choose a template to replace"
      cancelBtn={{text: "Cancel", type: "button"}}
      confirmBtn={{text: "save", type: "submit", form: FORM_ID}}
      onClose={onClose}
      isLoading={submitting}
    >
      {isLoading && (
        <div className="emptyBlock">
          <Loading type="inBlock" />
        </div>
      )}

      {!isLoading && !templateList?.length && (
        <TextComponent center className="mb-40">
          You have no templates yet !
        </TextComponent>
      )}

      {!isLoading && !!templateList?.length && (
        <form onSubmit={onSubmit} id={FORM_ID}>
          <Select
            {...form.templateId.inputProps}
            errors={form.templateId.errors}
            canSelectEmpty={true}
          />
        </form>
      )}
    </ConfirmWindow>
  );
};
