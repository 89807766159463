import {iPersonDetails} from "../interfaces";
import {people as peopleApi} from "api/contacts/people";
import {useHttp} from "hooks/httpServices/useHttp";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {usePeopleFormMain} from "./PeopleForms/PeopleFormMain/usePeopleFormMain";
import {usePeopleFormWork} from "./PeopleForms/PeopleFormWork/usePeopleFormWork";
import {usePeopleFormAddress} from "./PeopleForms/PeopleFormAddress/usePeopleFormAddress";
import {usePeopleFormOther} from "./PeopleForms/PeopleFormOther/usePeopleFormOther";
import {notValidForm} from "helpers/misc";
import {generateFormData} from "./generateFormData";
import {FormEvent, useState} from "react";
import {tNotValidTabs} from "./PeopleForm";
import {eventBus} from "EventBus/EventBus";
import {useCustomFieldsForm} from "components/shared/CustomFieldsForm/useCustomFieldsForm";

export type tFullForm = {
  mainFormData: ReturnType<typeof usePeopleFormMain>;
  workFormData: ReturnType<typeof usePeopleFormWork>;
  addressFormData: ReturnType<typeof usePeopleFormAddress>;
  otherFormData: ReturnType<typeof usePeopleFormOther>;
};
interface iProps {
  person?: iPersonDetails | null;
  setNotValidTab: React.Dispatch<React.SetStateAction<Partial<tNotValidTabs> | undefined>>;
  tab: number;
  onEditCallback: Function | undefined;
  closeModal: () => void;
}

export const usePeopleForm = ({person, setNotValidTab, tab, onEditCallback}: iProps) => {
  const {call, isLoading, isSuccess, data} = useHttp();

  const mainFormData = usePeopleFormMain({person});
  const workFormData = usePeopleFormWork({person, tab});
  const addressFormData = usePeopleFormAddress({person});
  const otherFormData = usePeopleFormOther({person, tab});
  const customFormData = useCustomFieldsForm({data: person?.custom_fields, fieldsType: "person"});

  const [favorite, setFavorite] = useState(person?.in_favorite || false);

  const isChangedFormData =
    mainFormData.isChanged ||
    workFormData.isChanged ||
    addressFormData.isChanged ||
    otherFormData.isChanged ||
    customFormData.isChanged ||
    favorite !== !!person?.in_favorite;

  function handleValidTab() {
    const notValidTabs: tNotValidTabs = {
      "0": notValidForm(mainFormData.formData),
      "1": notValidForm(workFormData.formData),
      '2': notValidForm({ ...{ addr: addressFormData.formData.address }, ...addressFormData.formData.billing_info }), // prettier-ignore
      "3": notValidForm(otherFormData.formData)
    };
    setNotValidTab(notValidTabs);
    return Boolean(Object.values(notValidTabs).find(item => item));
  }

  function setErrorTabs(form: any, errors: any) {
    const formTemplate = {
      "0": mainFormData?.formData,
      "1": workFormData?.formData,
      "2": addressFormData?.formData,
      "3": otherFormData?.formData
    };

    let errorTabs: any = {"0": false, "1": false, "2": false, "3": false};

    Object.keys(errors).forEach(key => {
      Object.entries(formTemplate).forEach(([tab, form]) => {
        // @ts-ignore
        if (form?.[key]) {
          errorTabs[tab] = true;
        }
      });
    });
    setNotValidTab(errorTabs);
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (handleValidTab()) return;

    const fullForm = {
      mainFormData,
      workFormData,
      addressFormData,
      otherFormData
    };

    const requestData = {
      ...generateFormData(fullForm),
      preferred: favorite,
      custom_fields: customFormData.getFormDataValues()
    };

    try {
      const id = person?.id;
      const response = await call(id ? peopleApi.update(requestData, id) : peopleApi.create(requestData)); // prettier-ignore
      onEditCallback?.(response);
      eventBus.dispatch("showToast", {text: response?.data?.message});
    } catch (err) {
      const form: any = {
        ...mainFormData.formData,
        ...workFormData?.formData,
        ...addressFormData?.formData
      };
      const {msg, errors} = apiErrorHandler(err, form);
      setErrorTabs(form, errors);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return {
    onSubmit,
    isLoading,
    isSuccess,
    data,
    mainFormData,
    workFormData,
    addressFormData,
    otherFormData,
    favorite,
    setFavorite,
    customFormData,
    isChangedFormData
  };
};
