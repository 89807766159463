export type tListOptionsItem = {id: string; label: string};

export const ATTACHED_FILE_ID = "10";

export const LIST_OPTIONS = {
  lineNumber: {
    id: "1",
    label: "Line number"
  },
  green: {
    id: "2",
    label: "Green"
  },
  estimate: {
    id: "3",
    label: "Estimate"
  },
  payId: {
    id: "4",
    label: "Pay Id"
  },
  vendor: {
    id: "5",
    label: "VENDOR"
  },
  qbStatus: {
    id: "6",
    label: "QB Status"
  },
  description: {
    id: "7",
    label: "Description"
  },
  payStatus: {
    id: "8",
    label: "PAY Status"
  },
  wTotal: {
    id: "9",
    label: "Working Total"
  },
  attachedFile: {
    id: ATTACHED_FILE_ID, //'10'
    label: "W9/W8/ACH/WIRE"
  },
  aTotal: {
    id: "11",
    label: "Actual Total"
  },
  qPay: {
    id: "12",
    label: "Quickpay"
  },
  charged: {
    id: "13",
    label: "Charged Total"
  },
  note: {
    id: "14",
    label: "Note"
  }
};
