import {FC, useState} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {useContextJobCallSheet} from "../context/JobCallSheetContext";
import {callSheet} from "api/callSheet";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {eventBus} from "EventBus/EventBus";
import {useNavigate} from "react-router-dom";
import {useContextJobDetails} from "../../../context/JobDetailsContext/JobDetailsContext";

interface iCallSheetRenameProps {
  onClose: () => void;
}

export const CallSheetDuplicate: FC<iCallSheetRenameProps> = ({onClose}) => {
  const navigate = useNavigate();
  const {jobDetailsData} = useContextJobDetails();
  const {callSheetInfo, callSheetId, getCallSheetList} = useContextJobCallSheet();
  const {call} = useHttp();
  const [isLoading, setIsLoading] = useState(false);

  const name = useTextInput({
    isRequired: true,
    validators: [lengthValidation(3)],
    value: callSheetInfo?.name || ""
  });

  const onConfirm = async () => {
    if (!name.checkValidity() || !callSheetId || !jobDetailsData) return;
    setIsLoading(true);
    try {
      const {
        data: {data, message}
      } = await call(callSheet.duplicateCallSheet(+callSheetId, {name: name.value}));
      getCallSheetList(jobDetailsData.id);
      eventBus.dispatch("showToast", {text: message});
      onClose();
      navigate(String(data.id));
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ConfirmWindow
      title="DUPLICATE CALL SHEET"
      subTitle="Enter a new name"
      show={true}
      onClose={onClose}
      className="CallSheetRename"
      onConfirm={onConfirm}
      confirmBtn={{
        text: "Duplicate"
      }}
      cancelBtn={{
        text: "Cancel"
      }}
      isLoading={isLoading}
    >
      <InputField {...name.inputProps} errors={name.errors} />
    </ConfirmWindow>
  );
};

function lengthValidation(minLength: number) {
  return {
    checkFn: (v: string) => Boolean(v?.length >= minLength),
    error: "The call sheet name must be at least 3 characters long"
  };
}
