import {useEffect, useMemo, useState} from "react";
import {iTeamReturn, iUseFieldGroup, tFieldGroupRow} from "./interfaces";
import {createTeamNewRowObject} from "./helpers";
import {useDebounce} from "hooks/useDebounce";
import {useHttp} from "hooks/httpServices/useHttp";
import {people as peopleApi} from "api/contacts/people";

export const useTeamFieldGroup = (props?: iUseFieldGroup) => {
  const {call} = useHttp();
  const initValue = props?.team
    ? props.team.map(t =>
        createTeamNewRowObject({
          person: {
            value: String(t.id),
            label: `${t.first_name} ${t.last_name}`
          },
          relationship: {
            value: String(t.user_relationship_id),
            label: t.user_relationship_name
          }
        })
      )
    : [createTeamNewRowObject()];

  const [value, setValue] = useState<tFieldGroupRow[]>(initValue);
  const [isValid, setIsValid] = useState(props?.isValid !== undefined ? props.isValid : false);
  const [errors, setErrors] = useState(props?.errors || []);
  const [isRequired, setIsRequired] = useState(
    props?.isRequired !== undefined ? props.isRequired : true
  );
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState<any[]>([]);

  const debounceValue = useDebounce(search, 250);
  const emptyErr = props?.emptyErrText ? props?.emptyErrText : "This field can't be empty";
  const emptyRowErrorMessage = "You can't add new items before to fill previous ones";

  useEffect(() => {
    if (debounceValue) {
      onSearch(debounceValue);
    }
  }, [debounceValue]); // eslint-disable-line

  const onSearch = async (value: string) => {
    try {
      const response = await call(peopleApi.getList({search: value}));
      setOptions(response?.data?.data);
    } catch (error: any) {
      console.log(error?.message);
    }
  };

  const onInputChange = (v: string) => setSearch(v);

  useEffect(() => {
    const newValue: iTeamReturn[] = value.map(({value: {person, relationship}}) => ({
      user_id: +person.value,
      user_relationship_id: +relationship.value
    }));
    props?.onChangeValue?.(newValue);
  }, [value]); // eslint-disable-line

  const [teamMemberForRemove, setTeamMemberForRemove] = useState<string | null>(null);
  const hideRemoveModal = () => setTeamMemberForRemove(null);
  const confirmRemove = () => {
    if (!teamMemberForRemove) return;
    setValue(prevState => {
      const newValue = prevState.filter(item => item.id !== teamMemberForRemove);
      checkValidity(newValue);
      return newValue;
    });
    setTeamMemberForRemove(null);
  };

  const onChange = (val: tFieldGroupRow) => {
    const newValue = value.map(item =>
      item.id === val.id ? {...item, ...val, isDirty: true} : item
    );
    setValue(newValue);
    checkValidity(newValue);
  };

  const onRemoveRow = (id: string, withoutConfirm?: boolean) => {
    if (withoutConfirm) {
      setValue(prevState => {
        const newValue = prevState.filter(item => item.id !== id);
        checkValidity(newValue);
        return newValue;
      });
      return;
    }

    setTeamMemberForRemove(id);
  };
  const addNewRow = () => {
    // if (!checkValidity(value, true)) return;
    setValue(prevState => [...prevState, createTeamNewRowObject()]);
  };

  const checkValidity = (val?: tFieldGroupRow[], addingCheck?: boolean) => {
    let newValue = val ? val : value;
    setErrors([]);

    if (!isRequired && !newValue.length) {
      setErrors([]);
      setIsValid(true);
      return true;
    }

    if (newValue.length === 1 && !newValue[0]?.value?.person?.value) {
      setErrors([emptyErr]);
    }

    if (!newValue[newValue?.length - 1]?.value?.person?.value) {
      setErrors([emptyErr]);
    }

    newValue = newValue?.map(item => {
      const isValid = Boolean(item.value?.person?.value && item.value?.relationship?.value);
      const newError = !isValid ? [emptyErr] : [];
      return {...item, isValid, errors: newError};
    });

    let hasError = Boolean(newValue.filter(item => !item.isValid).length);

    addingCheck && setErrors(hasError ? [emptyRowErrorMessage] : []);

    setValue(newValue);
    setIsValid(!hasError);

    return !hasError;
  };

  const validateAndSet = (val: tFieldGroupRow[]) => {
    setValue(val);
    checkValidity(val);
  };
  const onClearSelect = (id: string) => {
    validateAndSet(
      value.map(item => {
        if (item.id !== id) return item;
        return {
          ...item,
          value: {...item.value, relationship: {label: "", value: ""}},
          isValid: false
        };
      })
    );
  };

  const preparedValue = useMemo(
    () =>
      value.map(({value: {person, relationship}}) => ({
        user_id: +person.value,
        user_relationship_id: +relationship.value
      })),
    [value]
  );

  function isChanged() {
    const propArray = props?.team || [];
    const values = value;

    if (!propArray?.length && values?.[0] && !values?.[0]?.id && !values?.[0]?.value) return false;
    if (propArray.length !== values.length) return true;

    return !!values.filter(
      value =>
        !propArray?.some(
          prop =>
            prop.id === Number(value.value.person.value) &&
            prop.user_relationship_id === Number(value.value.relationship.value)
        )
    ).length;
  }

  return {
    value,
    setValue: validateAndSet,
    preparedValue,

    errors,
    setErrors,

    isValid,
    setIsValid,

    isRequired,
    setIsRequired,

    checkValidity,
    addNewRow,
    onRemoveRow,
    isChanged: isChanged(),

    inputProps: {
      onInputChange,
      value,
      onChange,
      onRemoveRow,
      addNewRow,
      onClearSelect,
      relationshipsData:
        props?.relationships?.map(({name, id}) => ({
          value: String(id),
          label: name
        })) ?? [],

      hideRemoveModal,
      confirmRemove,
      setTeamMemberForRemove,
      teamMemberForRemove,
      options
    }
  };
};
