import {FC, useState} from "react";
import {components, ControlProps, MenuProps} from "react-select";
import {Icon} from "components/Icon/Icon";
import {tUseSearchFieldReturn} from "hooks/useSearchField";
import {Button} from "components/shared/Button/Button";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {PeopleForm} from "pages/Contacts/People/PeopleForm/PeopleForm";
import {CompanyForm} from "pages/Contacts/Companies/CompanyForm/CompanyForm";
import {SearchField} from "components/shared/FormComponents/SearchField/SearchField";

import "./SearchClientField.scss";

type tModal = "createCompany" | "createPerson";

interface iSearchFieldProps extends tUseSearchFieldReturn {
  title?: string;
  id?: string;
  className?: string;
}

export const SearchClientField: FC<iSearchFieldProps> = ({className = "", ...props}) => {
  const [modalType, setModalType] = useState<tModal | null>(null);
  const closeModal = () => setModalType(null);
  const onAddContactCallback = () => setModalType(null);

  const errorClassName = !!props.errors.length ? "error" : "";

  return (
    <>
      <div className={`${className} SearchClientField`} id={props?.id}>
        {props?.title && (
          <label className={`SearchClientField-label ${errorClassName}`}>{props?.title}</label>
        )}
        <SearchField
          classNamePrefix="ReactSelect-select"
          components={{
            Control,
            Menu: ({children, ...props}) => (
              <MenuComponent setModalType={setModalType} {...props}>
                {children}
              </MenuComponent>
            )
          }}
          getOptionLabel={option => option.name}
          {...props}
        />
      </div>

      <ModalPopup
        className="PeopleFormModal"
        show={modalType === "createPerson"}
        onClose={closeModal}
      >
        <PeopleForm onEditCallback={onAddContactCallback} closeModal={closeModal} />
      </ModalPopup>

      <ModalPopup
        className="CompanyForm-modal"
        show={modalType === "createCompany"}
        onClose={closeModal}
      >
        <CompanyForm onEditCallback={onAddContactCallback} closeModal={closeModal} />
      </ModalPopup>
    </>
  );
};

const Control = ({children, ...props}: ControlProps<any, false>) => {
  return (
    <components.Control {...props}>
      <span className="SearchClientField-searchIcon">
        <Icon icon="search" />
      </span>
      {children}
    </components.Control>
  );
};

type tMenuComponentProps = MenuProps<any, false> & {setModalType: (type: tModal) => void};
const MenuComponent = ({children, ...props}: tMenuComponentProps) => {
  return (
    <components.Menu {...props}>
      {children}
      <div className="SearchClientField-menu-footer">
        <Button size="md" color="outline" onClick={() => props.setModalType("createPerson")}>
          <Icon icon="add" size="xsm" color="dark-gray" />
          Add person
        </Button>
        <Button size="md" color="outline" onClick={() => props.setModalType("createCompany")}>
          <Icon icon="add" size="xsm" color="dark-gray" />
          Add company
        </Button>
      </div>
    </components.Menu>
  );
};
