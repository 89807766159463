import {FC} from "react";
import {tCrewGroupContact} from "api/jobCrewGroup/types";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {jobCrewGroup} from "api/jobCrewGroup";
import {Button} from "components/shared/Button/Button";
import {eventBus} from "EventBus/EventBus";
import {useContextJobCrew} from "pages/Jobs/JobDetails/JobCrew/JobCrewContext";

type tMoveGroupProps = {
  group: tCrewGroupContact;
  closeEditMenu: () => void;
};

export const MoveGroup: FC<tMoveGroupProps> = ({group, closeEditMenu}) => {
  const {
    jobCrewGroupList: {crewGroupList, setCrewGroupList},
    moveGroupSubmitting,
    onMoveGroup
  } = useContextJobCrew();
  const isLoading = moveGroupSubmitting;

  type moveType = "up" | "top" | "down" | "bottom";
  const moveTo = async (type: moveType) => {
    const position = getPosition(type);
    closeEditMenu();

    try {
      const res = await onMoveGroup(jobCrewGroup.updateJobCrewGroup(group?.id, {position}));
      setCrewGroupList(() => res?.data?.data);
      eventBus.dispatch("showToast", {text: res?.data?.message || "Group successfully moved!"});
      closeEditMenu();
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  const getPosition = (type: moveType) => {
    if (type === "up") return group?.position - 1;
    if (type === "top") return 1;
    if (type === "down") return group?.position + 1;
    if (type === "bottom") return crewGroupList?.length;
  };

  const isFirstItem = group?.id === crewGroupList?.[0]?.id;
  const isLastItem = group?.id === crewGroupList[crewGroupList?.length - 1]?.id;

  return (
    <>
      {!isFirstItem && (
        <Button size="sm" onClick={() => moveTo("up")} disabled={isLoading}>
          Move up
        </Button>
      )}
      {!isLastItem && (
        <Button size="sm" onClick={() => moveTo("down")} disabled={isLoading}>
          Move down
        </Button>
      )}
      {!isFirstItem && (
        <Button size="sm" onClick={() => moveTo("top")} disabled={isLoading}>
          Move to top
        </Button>
      )}
      {!isLastItem && (
        <Button size="sm" onClick={() => moveTo("bottom")} disabled={isLoading}>
          Move to bottom
        </Button>
      )}
    </>
  );
};
