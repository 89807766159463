import {FC, useState} from "react";
import {EmptyPageState} from "components/shared/EmptyPageState/EmptyPageState";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import callSheets from "assets/images/CallSheets.svg";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {CreateNewCallSheet} from "./CreateNewCallSheet/CreateNewCallSheet";

type tEmptyStateProps = {};

export const EmptyState: FC<tEmptyStateProps> = () => {
  const {removeSection, removeSectionQuery} = useContextJobDetails();
  const onRemoveSection = () => removeSection();
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);

  return (
    <>
      <EmptyPageState
        img={callSheets}
        text="Build your first call sheet"
        primaryButton={{text: "Add call sheet", onClick: () => setShowModal(true)}}
        removeButton={{text: "REMOVE CALL SHEET SECTION", onClick: onRemoveSection}}
        isLoading={removeSectionQuery.isLoading}
      />

      {showModal && (
        <ModalPopup
          show={showModal}
          onClose={() => setShowModal(false)}
          withoutPaddings
          size="w-528"
        >
          <CreateNewCallSheet closeModal={closeModal} />
        </ModalPopup>
      )}
    </>
  );
};
