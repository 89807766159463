import {tIcon} from "../Icon/Icon";

export const iconsMap: {[k: string]: tIcon} = {
  People: "contact",
  Companies: "contact",
  Jobs: "jobs",
  Locations: "location",
  Team: "team"
};

export function getColumnNameAndText(queryString: string, data: {[k: string]: any}) {
  let column = "";
  let text = "";
  const maxStringLength = 50;
  const startOffsetNum = 10;

  let query = queryString.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&"); // eslint-disable-line

  const re = new RegExp(`(${query})`, "gmi");

  if (!re) return {column, text};

  // eslint-disable-next-line array-callback-return
  Object.keys(data).find(k => {
    if (data[k] === null) return false;

    const strData = String(data[k]);
    const dataMatchIndex = strData.toLowerCase().indexOf(query.toLowerCase());

    if (dataMatchIndex < 0) return false;

    const startInd = dataMatchIndex - startOffsetNum > 0 ? dataMatchIndex - startOffsetNum : 0;

    if (startInd >= 0) {
      const prefix = startInd > startOffsetNum ? "... " : "";
      const suffix = startInd + maxStringLength < strData.length ? " ..." : "";
      const cutFrom = startInd > startOffsetNum ? startInd : 0;
      const cutTo = startInd + maxStringLength;

      let originalText = prefix + strData.slice(cutFrom, cutTo) + suffix;

      text = originalText
        .split(" ")
        .map((str: string) =>
          !!String(str).match(re)?.length
            ? str.replace(re, "<strong class='highlight'>$1</strong>")
            : str
        )
        .join(" ");

      column = k.replaceAll(".", " / ").replaceAll("_", " ");
      return true;
    }
  });

  return {column, text};
}
