import {FC, MouseEvent, useState} from "react";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {useContextJobCalendar} from "../../context";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {NewCalendar} from "../../modals/NewCalendar/NewCalendar";
import {NewCalendarFromTemplate} from "../../modals/NewCalendarFromTemplate/NewCalendarFromTemplate";
import {iCalendarListItemReturn} from "api/jobs/calendar/types";
import {useNavigate, useParams} from "react-router-dom";

interface iCalendarSelectionProps {}

type tModal = "new" | "template";

export const CalendarSelection: FC<iCalendarSelectionProps> = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {calendarsList, activeCalendar, setActiveCalendar} = useContextJobCalendar();
  const {calendarTemplatesList} = useContextJobCalendar();

  const [modal, setModal] = useState<tModal | null>(null);

  const [listMenu, setListMenu] = useState<HTMLElement | null>(null);

  const onStartBlank = () => setModal("new");
  const onLoadTemplate = () => setModal("template");

  const openListMenu = (event: MouseEvent<HTMLButtonElement>) => setListMenu(event.currentTarget);

  const onShowBlank = () => {
    setListMenu(null);
    onStartBlank();
  };

  const onShowTemplateLoader = () => {
    setListMenu(null);
    onLoadTemplate();
  };

  const onSelectActiveCalendar = (calendar: iCalendarListItemReturn) => {
    setActiveCalendar(calendar);
    setListMenu(null);
    navigate(`/jobs/${id}/calendar/${calendar.id}`);
  };

  const sortedCalendarsList = calendarsList?.sort((a, b) => a.name.localeCompare(b.name));
  return (
    <>
      <div className="CalendarSelection">
        <Button
          className="CalendarActionsMenu-openButton"
          size="sm"
          color="outline"
          onClick={openListMenu}
        >
          {activeCalendar?.name}
          <Icon icon={Boolean(listMenu) ? "triangle-up" : "triangle-down"} size="xsm" />
        </Button>
        <DropdownMenu
          className="CalendarActionsMenu"
          anchor={listMenu}
          onClose={() => setListMenu(null)}
        >
          {sortedCalendarsList.map(calendar => {
            const isActive = calendar.id === activeCalendar?.id;
            return (
              <Button
                className={`CalendarActionsMenu-button ${isActive ? "active" : ""}`}
                onClick={() => onSelectActiveCalendar(calendar)}
                size="sm"
                key={calendar.id}
              >
                {calendar.name}
                {calendar.locked && <Icon icon="lock" size="xsm" color="light-gray" />}
              </Button>
            );
          })}
          <hr className="divider" />
          <div className="Button sm">
            Add new Calendar <Icon icon="triangle-right" size="xsm" color="light-gray" />
            <div className="submenu">
              <Button onClick={onShowBlank} size="sm">
                Blank
              </Button>
              <Button onClick={onShowTemplateLoader} size="sm">
                Load template
              </Button>
            </div>
          </div>
        </DropdownMenu>
      </div>

      {modal && (
        <ModalPopup
          className="JobCalendar-creatingModal"
          show={!!modal}
          onClose={() => setModal(null)}
          showCloseButton={false}
        >
          {modal === "new" && <NewCalendar onCancel={() => setModal(null)} />}
          {modal === "template" && (
            <NewCalendarFromTemplate
              calendarTemplatesList={calendarTemplatesList}
              onCancel={() => setModal(null)}
            />
          )}
        </ModalPopup>
      )}
    </>
  );
};
