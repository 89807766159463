import {Navigate, Route, Routes} from "react-router-dom";
import {routes} from "router/routes";
import {JobsListContextProvider} from "./context/JobsListContext/JobsListContext";
import {ContactsContextProvider} from "../Contacts/context/ContactsContext";
import {ContactsDetailsContextProvider} from "../Contacts/context/ContactsDetailsContext";
import {TeamContextProvider} from "../Team/TeamContext";
import {TeamDetailsContextProvider} from "../Team/TeamDetailsContext";
import {allIn} from "helpers/misc";
import {JobsList} from "./JobsList/JobsList";
import {JobDetails} from "./JobDetails";
import PageLayout from "layouts/PageLayout/PageLayout";
import {FullPageLayout} from "layouts/FullPageLayout/FullPageLayout";
import CreateNewJob from "./CreateNewJob/CreateNewJob";
import {LocationsContextProvider} from "pages/Locations/context/LocationsContext";
import {useContextProfile} from "context";

const JobsRouter = () => {
  const {userData} = useContextProfile();

  const isPrivilegedUser = userData?.role?.name === "Admin" || userData?.role?.name === "User+";

  if (!userData) return null;

  return (
    <Routes>
      <Route element={<PageLayout />}>
        {isPrivilegedUser ? (
          <Route index element={<JobsList />} />
        ) : (
          <Route path="*" element={<Navigate to="/" />} />
        )}
        <Route path="*" element={<Navigate to="" />} />
      </Route>

      <Route element={<FullPageLayout />}>
        {isPrivilegedUser ? (
          <Route path={allIn(routes.jobs.createNewJob)} element={<CreateNewJob />} />
        ) : (
          <Route path={allIn(routes.jobs.createNewJob)} element={<Navigate to="/" />} />
        )}
        <Route path={allIn(routes.jobs.details.main)} element={<JobDetails />} />
      </Route>
    </Routes>
  );
};

const Jobs = () => {
  return (
    <JobsListContextProvider>
      <LocationsContextProvider>
        <ContactsContextProvider>
          <ContactsDetailsContextProvider>
            <TeamContextProvider>
              <TeamDetailsContextProvider>
                <JobsRouter />
              </TeamDetailsContextProvider>
            </TeamContextProvider>
          </ContactsDetailsContextProvider>
        </ContactsContextProvider>
      </LocationsContextProvider>
    </JobsListContextProvider>
  );
};

export default Jobs;
