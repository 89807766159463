import {FC, Fragment} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import UploadField from "components/shared/FormComponents/UploadField/UploadField";
import {useImportModal} from "./useImportModal";
import {tContactsType} from "../HeaderButtons/HeaderButtons";
import {Loading} from "components/shared/Loading/Loading";
import "./ImportModal.scss";

interface iImportModalProps {
  type: tContactsType;
  onClose: () => void;
  show: boolean;
  title: string;
  subtitle?: string;
  label?: string;
  onUpdateList?: () => void;
}

export const ImportModal: FC<iImportModalProps> = ({
  show = true,
  label = "",
  onClose,
  title = "",
  subtitle,
  type,
  onUpdateList
}) => {
  const {
    sizeWarning,
    onChange,
    filename,
    contacts,
    handleClose,
    isLoading,
    resultStatus,
    onImport,
    progress,
    errors,
    isLoadingFields
  } = useImportModal({
    type,
    onClose,
    onUpdateList
  });

  const isShowStatus = contacts?.length > 1 && isLoading;

  return (
    <ConfirmWindow
      title={title}
      show={show}
      onClose={isLoading ? () => {} : handleClose}
      confirmBtn={{
        text: "Import",
        disabled: !contacts?.length || isLoading || errors || isLoadingFields
      }}
      cancelBtn={{text: "Cancel", disabled: isLoading || isLoadingFields}}
      onConfirm={onImport}
      isLoading={isLoading}
    >
      <div className="ImportModal-body">
        {isShowStatus && isLoading && <Loading progressBar progress={progress} type="inBlock" />}
        {subtitle}
        {label && <label className="ImportModal-fields-group-label">{label}</label>}
        <div className="ImportModal-fields-group">
          <UploadField isLoading={isLoadingFields} accept=".csv,.vcf" onChange={onChange} />
        </div>
        {!errors && sizeWarning && <p className="ImportModal-warning">{sizeWarning}</p>}
        {errors && (
          <div className="ImportModal-message-error">
            {Object.values(errors).map((error: any, index) => {
              const [key, value] = Object.entries(error)?.[0];
              return (
                <Fragment key={index}>
                  <p>
                    Record <strong>{key}</strong>: {value}
                  </p>
                  <br />
                </Fragment>
              );
            })}
          </div>
        )}
        {!errors && resultStatus?.status && (
          <>
            <p className="ImportModal-message">{filename}</p>
            <div className={`ImportModal-message-${resultStatus?.status || ""}`}>
              {resultStatus?.message}
            </div>
          </>
        )}
      </div>
    </ConfirmWindow>
  );
};
