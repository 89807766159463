import {AxiosRequestConfig} from "axios";
import {tBankDetailsPostData} from "./types";

const BANK_DETAILS = "bank_details";
const BANK_DETAIL = "bank_detail";

export const bankDetailsApi = {
  getBankDetailsList: (): AxiosRequestConfig => ({
    url: BANK_DETAILS
  }),
  createBankDetails: (data: tBankDetailsPostData): AxiosRequestConfig => ({
    method: "POST",
    url: BANK_DETAIL,
    data
  }),
  updateBankDetails: (bankDetailsId: number, data: tBankDetailsPostData): AxiosRequestConfig => ({
    method: "POST",
    url: `${BANK_DETAIL}/${bankDetailsId}`,
    data
  }),
  removeBankDetails: (bankDetailsId: number): AxiosRequestConfig => ({
    method: "DELETE",
    url: `${BANK_DETAIL}/${bankDetailsId}`
  })
};
