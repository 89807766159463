import {FC, FormEvent, useEffect, useState} from "react";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {TemplateModal} from "components/shared/TemplateModal/TemplateModal";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {DateRangePicker} from "components/shared/FormComponents/DateRangePicker/DateRangePicker";
import {Select} from "components/shared/FormComponents/Select/Select";
import {useSelect} from "hooks/inputHooks";
import {notValidForm, sortByKey} from "helpers/misc";
import {useDateRangePicker} from "hooks/useDateRangePicker";
import {
  iCalendarListItemReturn,
  iJobCalendarTemplatesListItemReturn
} from "api/jobs/calendar/types";
import {jobsCalendarApi} from "api/jobs/calendar";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextJobCalendar} from "../../context";
import {eventBus} from "EventBus/EventBus";
import moment from "moment";
import {getDateFormat} from "helpers/dates";
import {useNavigate, useParams} from "react-router-dom";

import "./NewCalendarFromTemplate.scss";

interface iNewCalendarFromTemplateProps {
  onCancel: () => void;
  calendarTemplatesList: iJobCalendarTemplatesListItemReturn[];
}

export const NewCalendarFromTemplate: FC<iNewCalendarFromTemplateProps> = ({
  calendarTemplatesList,
  onCancel
}) => {
  const navigate = useNavigate();
  const {id} = useParams();
  const {setCalendarsList, setActiveCalendar} = useContextJobCalendar();
  const {call} = useHttp();

  const [isLoading, setIsLoading] = useState(false);

  const options = calendarTemplatesList.map(v => ({...v, name: `${v.name} (${v.days} days)`}));
  const formData = {
    name: useTextInput({
      isRequired: true,
      validators: [lengthValidation(3)]
    }),
    template: useSelect({
      isRequired: true,
      options: options,
      targetKeys: {label: "name", value: "id"}
    }),
    dates: useDateRangePicker({required: true})
  };

  useEffect(() => {
    if (formData.template.value && !!calendarTemplatesList?.length) {
      const selected = calendarTemplatesList.find(v => v.id === Number(formData.template.value));
      if (!selected) return;
      formData.dates.setEndDate(moment(formData.dates.startDate).add(selected.days, "days").toDate()); // prettier-ignore
    }
  }, [formData.template.value, formData.dates.startDate, calendarTemplatesList]); // eslint-disable-line

  const handleSubmit = async (event: FormEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (notValidForm(formData)) return;
    if (!id) return;

    setIsLoading(true);
    const {name, template, dates} = formData;

    const templateId = Number(template.value);

    const payload = {
      job_id: Number(id),
      name: name.value,
      start_date: getDateFormat(dates.startDate, "timestamp"),
      end_date: getDateFormat(dates.endDate, "timestamp")
    };

    try {
      const {
        data: {data, message}
      } = await call(jobsCalendarApi.createCalendarFromTemplate(templateId, payload));
      setCalendarsList((prev: iCalendarListItemReturn[]) => sortByKey([...prev, data], "name"));
      setActiveCalendar(data);
      eventBus.dispatch("showToast", {text: message});
      navigate(`/jobs/${id}/calendar/${data.id}`);
      onCancel();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error, formData);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    } finally {
      setIsLoading(false);
    }
  };

  const templateIsSelected = Boolean(formData.template.value);
  return (
    <TemplateModal
      formId="new-calendar-template"
      title="New From Template"
      secondaryButton={{text: "Cancel"}}
      primaryButton={{text: "Create Calendar"}}
      onCancel={onCancel}
      isLoading={isLoading}
    >
      <form id="new-calendar-template" className="NewCalendarFromTemplate" onSubmit={handleSubmit}>
        <p className="NewCalendarFromTemplate-label">Name your calendar</p>
        <InputField {...formData.name.inputProps} errors={formData.name.errors} />

        <p className="NewCalendarFromTemplate-label">Choose a template</p>
        <Select
          {...formData.template.inputProps}
          disabled={!calendarTemplatesList?.length}
          errors={formData.template.errors}
          canSelectEmpty={true}
        />

        <p className="NewCalendarFromTemplate-label">
          Enter a start or end date to schedule events from
        </p>
        <DateRangePicker
          {...formData.dates}
          endPickerProps={{
            disabled: templateIsSelected
          }}
          separator="to"
        />
      </form>
    </TemplateModal>
  );
};

function lengthValidation(minLength: number) {
  return {
    checkFn: (v: string) => Boolean(v.length >= minLength),
    error: "The calendar name must be at least 3 characters long"
  };
}
