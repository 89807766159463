import {FC} from "react";
import "./Title.scss";

interface iTitleProps {
  text: string;
  size?: "sm";
  fullWidth?: boolean;
}

export const Title: FC<iTitleProps> = ({text, size = "", fullWidth}) => {
  return <div className={`Title ${size} ${fullWidth ? "fullWidth" : ""}`}>{text}</div>;
};
