import {FC, Fragment, ReactNode} from "react";
import "./ModalInfoSection.scss";

type tItemList = {label: string; value: ReactNode; valueClass?: string; labelClass?: string};
interface iModalInfoSectionProps {
  list: Array<tItemList>;
  hideEmptyValue?: boolean;
  valuePlaceholder?: string;
  className?: string;
  title?: string;
  hideWhenListEmpty?: boolean;
  labelSize?: "md" | "sm";
}

export const ModalInfoSection: FC<iModalInfoSectionProps> = ({
  hideWhenListEmpty = true,
  valuePlaceholder = "n/a",
  hideEmptyValue = true,
  className = "",
  title,
  list,
  labelSize = "md"
}) => {
  const isEmpty = !list?.find(item => item?.value);
  if (hideWhenListEmpty && isEmpty) return null;

  return (
    <div className={`ModalInfoSection ${className} labelSize-${labelSize}`}>
      {title && <h4 className="title">{title}</h4>}

      {list?.map(({label, value, valueClass = "", labelClass = ""}, i) => {
        if (!value && hideEmptyValue) return null;
        let val = value ? value : valuePlaceholder ? valuePlaceholder : "";

        if (Array.isArray(val) && !val.length) val = valuePlaceholder;

        return (
          <Fragment key={i}>
            <div className={`label ${labelClass}`}>{label}</div>
            <div className={`value ${!value ? "empty" : ""} ${valueClass}`}>{val}</div>
          </Fragment>
        );
      })}
    </div>
  );
};
