import {ReactNode, MouseEvent, forwardRef} from "react";
import {Icon} from "components/Icon/Icon";
import "./Tag.scss";

export interface iTagProps {
  children: ReactNode;
  size?: "lg" | "sm";
  color?: "primary" | "outline";
  className?: string;
  onRemove?: (id?: number) => void;
  onClick?: (e: MouseEvent<HTMLDivElement, any>) => void;
  id?: number;
}

export const Tag = forwardRef<HTMLDivElement, iTagProps>(
  ({id, onClick, children, size = "sm", color = "primary", className = "", onRemove}, ref) => {
    return (
      <div ref={ref} className={`Tag ${color} ${size} ${className}`} onClick={onClick}>
        <span className="Tag-child">{children}</span>

        {onRemove && (
          <button className="Tag-button" onClick={() => onRemove(id)}>
            <Icon icon="close" color="black" size="xsm" />
          </button>
        )}
      </div>
    );
  }
);
