import {FC, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {useHttp} from "hooks/httpServices/useHttp";
import {fields} from "api/fields";
import {iField} from "api/fields/types";
import {getApiError} from "helpers/getApiError";
import {eventBus} from "EventBus/EventBus";

interface iManageFieldsDelete {
  getFieldList: () => Promise<any>;
  field: iField;
  setHide: (is: boolean) => void;
}

export const ManageFieldsDelete: FC<iManageFieldsDelete> = ({getFieldList, field, setHide}) => {
  const {call: deleteCall, isLoading: deleteLoading} = useHttp();
  const [showDeleteWindow, setShowDeleteWindow] = useState<iField | null>(null);

  const show = () => {
    setHide(true);
    setShowDeleteWindow(field);
  };

  const hide = () => {
    setHide(false);
    setShowDeleteWindow(null);
  };

  const onDeleteField = async () => {
    if (!showDeleteWindow) return;

    try {
      const res = await deleteCall(fields?.deleteField(showDeleteWindow?.id));
      hide();
      getFieldList();
      eventBus.dispatch("showToast", {text: res?.data?.message || "Success !"});
    } catch (error) {
      const {msg} = getApiError(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error !"});
    }
  };

  const deleteWarning = `Deleting the ${showDeleteWindow?.name} field will remove content from 
            ${field?.values_count} associated contact${field?.values_count > 1 ? "s." : "."}`;
  return (
    <>
      <button onClick={show} className="ManageFieldsList-list-btn">
        <Icon icon="close" size="sm" color="dark-gray" />
      </button>

      <ConfirmWindow
        title="Delete this FIELD?"
        confirmBtn={{text: "Delete", color: "red"}}
        cancelBtn={{text: "Cancel"}}
        className="ManageField-delete-window"
        show={!!showDeleteWindow}
        onClose={hide}
        onConfirm={onDeleteField}
        isLoading={deleteLoading}
      >
        <div className="ManageFields-confirmWindow-body">
          <p>{deleteWarning}</p>
          <p className="ManageFields-confirmWindow-warning">This cannot be undone.</p>
        </div>
      </ConfirmWindow>
    </>
  );
};
