import {FC} from "react";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {tEstimateData} from "api/budget/estimate/types";
import {EstimateList} from "./EstimateList";
import "./EstimateListWrap.scss";

type tEstimateListWrapProps = {
  data: tEstimateData | null;
};

export const EstimateListWrap: FC<tEstimateListWrapProps> = ({data}) => {
  return (
    <div className="EstimateListWrap">
      <TextComponent size="20" uppercase className="EstimateListWrap-title">
        {data?.name}
      </TextComponent>

      <EstimateList data={data} />
    </div>
  );
};
