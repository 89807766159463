import {createContext, FC, useContext} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {common} from "api/common";
import {tags} from "api/tags";
import {titles as titlesApi} from "api/titles";
import {companies as companiesApi} from "api/contacts/companies";
import {iCompany} from "api/interfaces";
import {iBaseOptions} from "api/common/types";
import {useContextProfile} from "context";
import {useContextBasicData} from "context/BasicDataContext/BasicDataContext";

const useContactsDetails = () => {
  const {userData} = useContextProfile();
  const {
    emailTypes,
    departments,
    userRelationships,
    relationships,
    platforms,
    pronouns,
    phoneTypes,
    getData
  } = useContextBasicData();

  const locales = useHttp();
  const labels = useHttp();

  const titles = useHttp();
  const companies = useHttp<{data: iCompany[]}>();
  const dietaryRestriction = useHttp<{data: iBaseOptions[]}>();

  const getAdditionalFormDataMain = () => {
    !locales.data && locales.call(tags.locales.get());
    !labels.data && labels.call(tags.labels.get());

    !emailTypes.length && getData("emailTypes");
    !platforms.length && getData("platforms");
    !phoneTypes.length && getData("phoneTypes");
  };

  const getAdditionalFormDataWork = () => {
    !titles.data && titles.call(titlesApi.get());
    !companies.data && companies.call(companiesApi.getList());
    !relationships.length && getData("relationships");
    !userRelationships.length && getData("userRelationships");
  };

  const getAdditionalFormDataOther = () => {
    const canGetDepartments = !departments.length && userData?.role?.name === "Admin";
    canGetDepartments && getData("departments");

    !dietaryRestriction.data && dietaryRestriction.call(common.getDietaryRestrictions());
    !pronouns.length && getData("pronouns");
    !relationships.length && getData("relationships");
  };

  const getDepartments = () => {
    !departments.length && getData("departments");
  };

  const isLoadingMain = locales.isLoading || labels.isLoading;
  const isLoadingWork = titles.isLoading || companies.isLoading || !relationships.length;
  const isLoadingOther = dietaryRestriction.isLoading || !departments.length || !pronouns.length || !relationships.length; // prettier-ignore

  return {
    locales,
    labels,
    emailTypes,
    platforms,
    phoneTypes,

    titles,
    companies: companies?.data?.data?.data || [],
    userRelationships,
    relationships: relationships,
    dietaryRestriction: dietaryRestriction.data?.data?.data || [],
    departments: departments,
    pronoun: pronouns,

    isLoadingMain,
    isLoadingWork,
    isLoadingOther,

    getAdditionalFormDataMain,
    getAdditionalFormDataWork,
    getAdditionalFormDataOther,

    departmentsData: departments,
    getDepartments
  };
};

type tContactsDetailsContext = ReturnType<typeof useContactsDetails>;
const ContactsDetailsContext = createContext<tContactsDetailsContext | null>(null);
ContactsDetailsContext.displayName = "ContactsDetailsContext";
export const ContactsDetailsContextProvider: FC = ({children}) => (
  <ContactsDetailsContext.Provider value={useContactsDetails()}>
    {children}
  </ContactsDetailsContext.Provider>
);

/**
 * Provides teamDetails info and list for checkboxes and radio button groupe
 * @returns (teammate details, checkboxes, radio button data info and function for call it)
 */
export const useContextContactsDetails = () => {
  const context = useContext(ContactsDetailsContext);

  if (context === null) {
    throw new Error(
      "<<< ContactsDetailsContext >>> must be used within aContactsDetailsContextProvider"
    );
  }

  return context as tContactsDetailsContext;
};
