import {FormEvent, FC} from "react";
import {TagsField} from "components/shared/FormComponents/TagsField/TagsField";
import {Button} from "components/shared/Button/Button";
import {Loading} from "components/shared/Loading/Loading";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {tUsePeopleFormMain} from "./usePeopleFormMain";
import {UserUploadFile} from "components/shared/UserUploadFile/UserUploadFile";
import {DeletePerson} from "./DeletePerson";
import {DynamicFieldsWrap as DynamicFieldsWrapPhone} from "components/shared/FormComponents/DynamicFieldsPhone/DynamicFieldsWrap";
import {DynamicFieldsWrap as DynamicFieldsWrapEmail} from "components/shared/FormComponents/DynamicFieldsEmail/DynamicFieldsWrap";
import {DynamicFieldsWrap} from "components/shared/FormComponents/DynamicFields/DynamicFieldsWrap";
import {PEOPlE_FORM_ID} from "../../model";

interface iPeopleFormMainProps {
  toNextTab: () => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  formDataMain: tUsePeopleFormMain;
}

export const PeopleFormMain: FC<iPeopleFormMainProps> = ({toNextTab, onSubmit, formDataMain}) => {
  const {formData, localesOptions, person} = formDataMain;
  const {labelsOptions, isLoading, personPhoto, setPersonPhoto, isValid} = formDataMain;

  const {phonesOptions, phones, setPhones, phonesRef} = formDataMain;
  const {emailsOptions, emails, setEmails, emailsRef} = formDataMain;
  const {accPlatformOptions, accounts, setAccounts, socPlatformRef} = formDataMain;

  const id = person?.id;
  const creatorName = `${person?.creator.first_name ?? ""}${" " + person?.creator.last_name ?? ""}`;

  if (isLoading) return <Loading className="PeopleLoading-form" />;
  return (
    <form className="PeopleFormMain" id={PEOPlE_FORM_ID} onSubmit={onSubmit}>
      <UserUploadFile avatar={personPhoto} setAvatar={setPersonPhoto} fallBackType="avatar" />

      <div className="PeopleForm-section">
        <div className="PeopleForm-col-3">
          <InputField
            {...formData.first_name.inputProps}
            errors={formData.first_name.errors}
            id="first_name"
            label="First Name"
          />
          <InputField
            {...formData.middle_name.inputProps}
            errors={formData.middle_name.errors}
            id="middle_name"
            label="Middle Name"
          />
          <InputField
            {...formData.last_name.inputProps}
            errors={formData.last_name.errors}
            id="last_name"
            label="Last Name"
          />
        </div>

        <InputField
          {...formData.legal_name.inputProps}
          errors={formData.legal_name.errors}
          id="legal_name"
          label="Legal Name"
        />
      </div>

      <div className="PeopleForm-section">
        <h5 className="h5 PeopleForm-section-title phone">Phone</h5>
        <DynamicFieldsWrapPhone
          ref={phonesRef}
          dataList={phones as any}
          setDataList={setPhones}
          selectOptions={phonesOptions}
          type="phones"
          buttonName="Add Phone"
          title="Delete phone number?"
          notificationType="deletePhone"
          notificationData={`${person?.first_name ? person?.first_name + "'s record?" : ""}`}
        />
      </div>

      <div className="PeopleForm-section">
        <h5 className="h5 PeopleForm-section-title">Email</h5>
        <DynamicFieldsWrapEmail
          ref={emailsRef}
          dataList={emails}
          setDataList={setEmails}
          selectOptions={emailsOptions}
          type="emails"
          buttonName="Add Email"
          title="Delete email?"
        />
      </div>

      <div className="PeopleForm-section">
        <h5 className="h5 PeopleForm-section-title">Tags</h5>
        <TagsField
          {...formData.locales.inputProps}
          errors={formData.locales.errors}
          options={localesOptions}
          placeholder=""
          label="Locales"
        />
        <TagsField
          {...formData.labels.inputProps}
          errors={formData.labels.errors}
          options={labelsOptions}
          placeholder=""
          label="Labels"
        />
      </div>

      <div className="PeopleForm-section">
        <h5 className="h5 PeopleForm-section-title">Accounts</h5>
        <DynamicFieldsWrap
          ref={socPlatformRef}
          dataList={accounts}
          setDataList={setAccounts}
          selectOptions={accPlatformOptions}
          type="socPlatform"
          buttonName="Add Account"
          title="Delete account?"
          emptyRowErrorMsg="Complete the previous account to add another one."
        />
      </div>

      {id ? (
        <DeletePerson id={id} created={{date: person?.created_at, name: creatorName}} />
      ) : (
        <Button
          disabled={!isValid}
          type="button"
          color="light"
          className="PeopleForm-button-next"
          onClick={toNextTab}
        >
          Continue to Work Details
        </Button>
      )}
    </form>
  );
};
