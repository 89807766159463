import {FC} from "react";
import {trackApi} from "api/budget/track";
import {Button} from "components/shared/Button/Button";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextTrack} from "../../../_context/TrackContext";
import {tTrackExpenseLine, typeFees} from "api/budget/track/types";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {useContextExpenseList} from "../../../ExpenseList/context/ExpenseListContext";
import {useResponseType} from "../../useResponseType";

type tActionsBillAndReceiptProps = {
  data: tTrackExpenseLine;
  typeFees?: typeFees;
};

export const ActionsBillAndReceipt: FC<tActionsBillAndReceiptProps> = ({data, typeFees}) => {
  const {setTrackData} = useContextTrack();
  const {setExpenseList} = useContextExpenseList();
  const {isExpense, response_type} = useResponseType();
  const {call, isLoading} = useHttp();

  const requests = {
    receipt: trackApi.setReceiptRequired(data?.id, {state: !data?.receipt_required, response_type}),
    bill: trackApi.setBillState(data?.id, {state: !data?.bill_state, response_type})
  };

  const changeState = async (type: "receipt" | "bill") => {
    try {
      const res = await call(requests[type]);
      isExpense && setExpenseList(res?.data?.data);
      !isExpense && setTrackData(res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <>
      <Button size="sm" className="btn" onClick={() => changeState("receipt")} disabled={isLoading}>
        {data?.receipt_required && "No receipt requirement"}
        {!data?.receipt_required && "Add receipt requirement"}
      </Button>
      {!typeFees && (
        <Button size="sm" className="btn" onClick={() => changeState("bill")} disabled={isLoading}>
          {data?.bill_state && "DO NOT BILL"}
          {!data?.bill_state && "ADD TO BILL"}
        </Button>
      )}
    </>
  );
};
