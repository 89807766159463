import {FC} from "react";
import {Button} from "components/shared/Button/Button";
import {TagsField} from "components/shared/FormComponents/TagsField/TagsField";
import {useSetApiParams} from "hooks/useSetApiParams";
import {useLocationsTableFilter} from "./useLocationsTableFilter";

import "./LocationsTableFilter.scss";

export const LocationsTableFilter: FC = () => {
  const apiParams = useSetApiParams();
  const {filterControl, options, onChangeInput} = useLocationsTableFilter();

  return (
    <div className="LocationsTableFilter">
      <div className="LocationsTableFilter-row LocationsTableFilter-row-col-2">
        <div className="LocationsTableFilter-label">Locale</div>

        <TagsField
          {...filterControl}
          getOptionValue={value => value.id}
          onInputChange={onChangeInput}
          placeholder="Search Locales"
          options={options}
          menuPosition="fixed"
        />
      </div>

      <Button
        onClick={apiParams.clearAllParams}
        size="sm"
        color="light"
        className="LocationsTableFilter-button"
      >
        Clear Filters
      </Button>
    </div>
  );
};
