import {FC, useEffect, useRef} from "react";
import {Select} from "components/shared/FormComponents/Select/Select";
import {InputField} from "../InputField/InputField";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useSelect} from "hooks/inputHooks";
import moment from "moment";
import "./TimeField.scss";

type tPosition = "start" | "end";
interface iTimeFieldProps {
  onChange: (value: {hours: string; minutes: string; zone: string; type: tPosition}) => void;
  type: tPosition;
  time: any;
  showTimezone?: boolean;
  disabled?: boolean;
  label?: string;
}

export const TimeField: FC<iTimeFieldProps> = ({
  onChange,
  time,
  type,
  showTimezone = false,
  disabled,
  label
}) => {
  const minuteRef = useRef<HTMLInputElement | null>(null);
  const hourRef = useRef<HTMLInputElement | null>(null);
  const periodRef = useRef<HTMLSelectElement | null>(null);

  const timeData = {
    hours: useTextInput({
      value: moment(time).format("hh"),
      filters: [digitFilter, (v: string) => timeFilter(v, 12, "12")]
    }),
    minutes: useTextInput({
      value: moment(time).format("mm"),
      filters: [digitFilter, (v: string) => timeFilter(v, 59, "59")]
    }),
    zone: useSelect({value: moment(time).format("a")})
  };

  useEffect(() => {
    onChange({
      hours: timeData.hours.value,
      minutes: timeData.minutes.value,
      zone: timeData.zone.value,
      type: type
    });
  }, [timeData.hours.value, timeData.minutes.value, timeData.zone.value]); // eslint-disable-line

  // const handleKeyPress = (event: KeyboardEvent<HTMLInputElement | HTMLSelectElement>) => {
  // const {name} = event.currentTarget;
  // const isLeftArrow = event.keyCode === 37;
  // const isRightArrow = event.keyCode === 39;
  // const fieldsRef = [
  //   {name: "hours", ref: hourRef.current},
  //   {name: "minutes", ref: minuteRef.current},
  //   {name: "period", ref: periodRef.current}
  // ];
  //
  // if (isLeftArrow) {
  //   const index = fieldsRef.findIndex(field => field.name === name);
  //   if (index === 0) {
  //     fieldsRef[2]?.ref?.focus();
  //     return;
  //   }
  //   fieldsRef[index - 1]?.ref?.focus();
  // }
  //
  // if (isRightArrow) {
  //   const index = fieldsRef.findIndex(field => field.name === name);
  //   if (index === 2) {
  //     fieldsRef[0]?.ref?.focus();
  //     return;
  //   }
  //   fieldsRef[index + 1]?.ref?.focus();
  // }
  // };

  const timezone = showTimezone
    ? new Date(time)
        .toLocaleTimeString("en-us", {timeZoneName: "short"})
        ?.split(" ")[2]
        ?.slice(0, 3)
    : null;

  const disabledClassName = disabled ? "disabled" : "";

  return (
    <div className={`TimeField ${disabledClassName}`}>
      {label && <label className="TimeField-label label">{label}</label>}
      <div className="TimeField-control control">
        <InputField
          {...timeData.hours.inputProps}
          refProp={hourRef}
          disabled={disabled}
          //  onKeyDown={handleKeyPress}
          className="TimeField-hours"
          name="hours"
        />
        <span className="TimeField-separator">:</span>
        <InputField
          {...timeData.minutes.inputProps}
          refProp={minuteRef}
          disabled={disabled}
          //  onKeyDown={handleKeyPress}
          className="TimeField-minutes"
          name="minutes"
        />
        <Select
          {...timeData.zone.inputProps}
          refProp={periodRef}
          disabled={disabled}
          //  onKeyDown={handleKeyPress}
          canSelectEmpty={false}
          className="TimeField-zone"
          options={formats}
          targetKeys={{value: "value", label: "label"}}
          name="period"
        />
        {showTimezone && <span className="TimeField-timezone">{timezone}</span>}
      </div>
    </div>
  );
};

const formats = [
  {value: "am", label: "AM"},
  {value: "pm", label: "PM"}
];

function digitFilter(value: string) {
  return value.replace(/\D/g, "");
}

function timeFilter(value: string, maxTimeLimit: number, defaultMaxTime: string) {
  let newValue = digitFilter(value);
  if (newValue.length > 2) {
    newValue = newValue.substr(1, 2);
  }
  if (Number(newValue) > maxTimeLimit) {
    newValue = defaultMaxTime;
  }
  return newValue;
}
