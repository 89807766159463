import {FC} from "react";
import {ChapterHeader} from "../../../OverlayTemplates/ChapterHeader/ChapterHeader";
import {tEstimateData} from "api/budget/estimate/types";
import {getUsMoneyFormat} from "helpers/formattingData";

import "./EstimateProductionCosts.scss";

interface iEstimateProductionCostsProps {
  estimate: tEstimateData;
}

export const EstimateProductionCosts: FC<iEstimateProductionCostsProps> = ({estimate}) => {
  const {
    category_values,
    union_crew_payroll_fee,
    union_talent_payroll_fee,
    talent_payroll_fee,
    crew_payroll_fee,
    budgeted_costs
  } = estimate;

  return (
    <div className="EstimateProductionCosts">
      <ChapterHeader>Estimated Production Costs</ChapterHeader>
      <table className="EstimateProductionCosts-table">
        <tbody>
          {category_values.map(({id, code, name, total}) => {
            return (
              <tr key={id}>
                <td>{code}</td>
                <td>{name}</td>
                <td>{getUsMoneyFormat(total)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="EstimateProductionCosts-feeRow">
        <span>Crew Payroll Fee</span>
        <span>{getUsMoneyFormat(crew_payroll_fee)}</span>
      </div>
      <div className="EstimateProductionCosts-feeRow">
        <span>Talent Payroll Fee</span>
        <span>{getUsMoneyFormat(talent_payroll_fee)}</span>
      </div>
      <div className="EstimateProductionCosts-feeRow">
        <span>Union Crew Payroll Fee</span>
        <span>{getUsMoneyFormat(union_crew_payroll_fee)}</span>
      </div>
      <div className="EstimateProductionCosts-feeRow">
        <span>Union Talent Payroll Fee</span>
        <span>{getUsMoneyFormat(union_talent_payroll_fee)}</span>
      </div>
      <div className="EstimateProductionCosts-total">
        <span>TOTAL ESTIMATED PRODUCTION COSTS</span>
        <span>{getUsMoneyFormat(budgeted_costs)}</span>
      </div>
    </div>
  );
};
