import {FC} from "react";
import logo from "assets/images/VersatileStudios.svg";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";

import "./BudgetOverlayTitle.scss";

export const BudgetOverlayTitle: FC = ({children}) => {
  return (
    <div className="BudgetOverlayTitle">
      <img src={logo} alt="logo" />
      <TextComponent size="26" textAlign="right" uppercase>
        {children}
      </TextComponent>
    </div>
  );
};
