import {useEffect, useState} from "react";
import {iUseContactFieldGroup, tContactFieldGroupRow} from "./interfaces";
import {uid} from "helpers/misc";
import {useDebounce} from "hooks/useDebounce";
import {useHttp} from "hooks/httpServices/useHttp";
import {people as peopleApi} from "api/contacts/people";

const createNewRowObj = (): tContactFieldGroupRow => ({
  id: uid(),
  value: undefined,
  errors: [],
  isValid: false
});

export const useContactFieldGroup = ({...props}: iUseContactFieldGroup) => {
  const {call: callContact, isLoading} = useHttp();
  const [value, setValue] = useState<tContactFieldGroupRow[]>(props?.value || [createNewRowObj()]);

  const [isValid, setIsValid] = useState(props?.isValid !== undefined ? props.isValid : false);
  const [errors, setErrors] = useState(props?.errors || []);
  const [isRequired, setIsRequired] = useState(
    props?.isRequired !== undefined ? props.isRequired : true
  );
  const [options, setOptions] = useState([]); // contacts options
  const [selected, setSelected] = useState<any[]>([]); // contacts selected
  const [isDirty, setIsDirty] = useState(false);
  const [search, setSearch] = useState("");

  const debounceValue = useDebounce(search, 250);
  const emptyErr = props?.emptyErrText ? props?.emptyErrText : "This field can't be empty";
  const emptyRowErrorMessage = "You can't add new items before to fill previous ones";

  useEffect(() => {
    if (debounceValue) {
      onSearch(debounceValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  const onSearch = async (value: string) => {
    try {
      const response = await callContact(peopleApi.getList({search: value}));
      setOptions(response?.data?.data);
    } catch (error: any) {
      console.log(error?.message);
    }
  };

  const findContact = (v: string) => setSearch(v);

  const onChange = (val: tContactFieldGroupRow) => {
    const newValue = value.map(item => (item.id === val.id ? {...item, ...val} : item));
    setValue(newValue);
    val?.value?.title?.name && checkValidity(newValue);
  };

  const onRemoveRow = (id: string) =>
    setValue(prevState => prevState.filter(item => String(item.id) !== String(id)));

  const addNewRow = () => {
    if (!checkValidity(value, true)) return;
    setValue(prevState => [...prevState, createNewRowObj()]);
  };

  const checkValidity = (val?: tContactFieldGroupRow[], addingCheck?: boolean) => {
    let newValue = val ? val : value;
    const emptyValue = !newValue.length;

    setErrors([]);

    if (!isRequired && emptyValue) {
      setErrors([]);
      setIsValid(true);
      return true;
    }

    if (isRequired && emptyValue && !addingCheck) {
      setErrors([emptyErr]);
      setIsValid(false);
      return false;
    }

    const lastElement = newValue[newValue.length - 1] || null;
    if (lastElement && isRequired && !lastElement?.value?.person?.id) {
      setErrors([emptyErr]);
    }

    newValue = newValue?.map(item => {
      const isValid = Boolean(item.value?.person?.id && item.value?.title?.id);
      const newError = isValid ? [] : [emptyErr];
      return {...item, isValid, errors: newError};
    });

    let hasError = Boolean(newValue.filter(item => !item.isValid).length);

    addingCheck && setErrors(hasError ? [emptyRowErrorMessage] : []);

    setValue(newValue);
    setIsValid(!hasError);

    return !hasError;
  };

  const validateAndSet = (val: tContactFieldGroupRow[]) => {
    setValue(val);
    checkValidity(val);
  };

  function isChanged() {
    if (!props?.value?.length && !value?.length) return false;
    if (props?.value?.length !== value?.length) return true;
    return !props?.value?.filter(
      ct =>
        !!value?.find(
          v =>
            v?.value?.person?.id === ct?.value?.person?.id &&
            v?.value?.title?.id === ct?.value?.title?.id
        )
    ).length;
  }

  return {
    setValue: validateAndSet,

    errors,
    setErrors,

    isValid,
    setIsValid,

    isRequired,
    setIsRequired,

    checkValidity,
    options,
    findContact,
    selected,
    setSelected,
    isLoading,
    isDirty,
    setIsDirty,

    onInputChange: findContact,
    isChanged: isChanged(),

    value,
    onChange,
    onRemoveRow,
    addNewRow
  };
};
