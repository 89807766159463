import {FC, FormEvent, useState} from "react";
import {TemplateModal} from "components/shared/TemplateModal/TemplateModal";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useHttp} from "hooks/httpServices/useHttp";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {jobsCalendarApi} from "api/jobs/calendar";
import {useContextJobCalendar} from "../../context";
import {eventBus} from "EventBus/EventBus";
import {sortByKey} from "helpers/misc";
import {iCalendarListItemReturn} from "api/jobs/calendar/types";
import {useNavigate, useParams} from "react-router-dom";

import "./NewCalendar.scss";

interface iNewCalendarProps {
  onCancel: () => void;
}

export const NewCalendar: FC<iNewCalendarProps> = ({onCancel}) => {
  const navigate = useNavigate();
  const {id} = useParams();
  const {setActiveCalendar, setCalendarsList} = useContextJobCalendar();
  const {call} = useHttp();

  const [isLoading, setIsLoading] = useState(false);

  const calendarName = useTextInput({isRequired: true});

  const handleSubmit = async (event: FormEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (calendarName.value.length < 3) {
      calendarName.setErrors(["The calendar name must be at least 3 characters long"]);
      return;
    }

    if (!id) return;

    setIsLoading(true);

    const formData = {
      job_id: Number(id),
      name: calendarName.value
    };

    try {
      const {data: {data, message}} = await call(jobsCalendarApi.createJobCalendar(formData)); // prettier-ignore
      setCalendarsList((prev: iCalendarListItemReturn[]) => sortByKey([...prev, data], "name"));
      setActiveCalendar(data);
      eventBus.dispatch("showToast", {text: message});
      navigate(`/jobs/${id}/calendar/${data.id}`);
      onCancel();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      calendarName.setErrors([msg]);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form id="new-calendar" className="NewCalendar" onSubmit={handleSubmit}>
      <TemplateModal
        formId="new-calendar"
        title="New Calendar"
        secondaryButton={{text: "Cancel"}}
        primaryButton={{text: "Create Calendar"}}
        onCancel={onCancel}
        isLoading={isLoading}
      >
        <p className="NewCalendar-label">Name your calendar</p>
        <InputField {...calendarName.inputProps} errors={calendarName.errors} />
      </TemplateModal>
    </form>
  );
};
