import {FC} from "react";
import {Button} from "components/shared/Button/Button";
import {useContextJobTasks} from "../../../_context/JobTaskContext";
import {tJobTasksTemplateDataTask} from "api/jobs/tasks/types";

type tMoveTasksGroupProps = {
  groupId: number; //actually it's index not id
  onClose: () => void;
};

export const MoveTasksGroup: FC<tMoveTasksGroupProps> = ({groupId, onClose}) => {
  const {tasks, updateTasksData, updateDataCall: {isLoading}} = useContextJobTasks(); // prettier-ignore

  const moveTo = async (newIndex: number) => {
    if (!tasks) return;
    const currItem = tasks?.data?.find((_, i) => i === groupId) as tJobTasksTemplateDataTask;
    const newData = tasks?.data?.filter((_, i) => i !== groupId);
    newData.splice(newIndex, 0, currItem as any);

    updateTasksData(newData, onClose);
  };

  const isFirstItem = groupId === 0;
  const lastItem = tasks?.data?.length ? tasks?.data?.length - 1 : 0;
  const isLastItem = groupId === lastItem;
  return (
    <>
      {!isFirstItem && (
        <Button size="sm" onClick={() => moveTo(groupId - 1)} disabled={isLoading}>
          Move up
        </Button>
      )}
      {!isLastItem && (
        <Button size="sm" onClick={() => moveTo(groupId + 1)} disabled={isLoading}>
          Move down
        </Button>
      )}
      {!isFirstItem && (
        <Button size="sm" onClick={() => moveTo(0)} disabled={isLoading}>
          Move to top
        </Button>
      )}
      {!isLastItem && (
        <Button size="sm" onClick={() => moveTo(lastItem)} disabled={isLoading}>
          Move to bottom
        </Button>
      )}
    </>
  );
};
