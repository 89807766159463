import {FC} from "react";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {TemplateModal} from "components/shared/TemplateModal/TemplateModal";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {TextComponent} from "../../common/TextComponent/TextComponent";
import {useContextJobGrid} from "../JobGridContext";
import {tGridFormType} from "../GridHeader/MenuGrid";
import {useOnSubmitGrid} from "./useOnSubmitGrid";

type tCreateGridFormProps = {
  closeTemplateModal: () => void;
  showTemplateModal: boolean;
  type?: tGridFormType;
};

export const CreateGridForm: FC<tCreateGridFormProps> = ({
  showTemplateModal,
  closeTemplateModal,
  type
}) => {
  const {grid} = useContextJobGrid();
  const FORM_ID = "change-grid-form-id";

  const form = {
    name: useTextInput({value: !!type ? grid?.name : ""})
  };

  const {onSubmit, submitting} = useOnSubmitGrid({type, closeTemplateModal, form});

  const title = type === "duplicate" ? "Duplicate" : "Rename";
  return (
    <ModalPopup
      show={showTemplateModal}
      className="LoadFromTemplate"
      onClose={closeTemplateModal}
      withoutPaddings
      size="w-528"
    >
      <form className="LoadFromTemplate-form" onSubmit={onSubmit} id={FORM_ID}>
        <TemplateModal
          formId={FORM_ID}
          title={`${!!type ? title : "New"} Grid`}
          primaryButton={{text: `${!!type ? title : "Create"} grid`}}
          secondaryButton={{text: "Cancel"}}
          onCancel={closeTemplateModal}
          isLoading={submitting}
        >
          <TextComponent weight="400" size="14" center m="mb-16">
            {!!type ? title : "Name"} your grid
          </TextComponent>
          <InputField {...form.name.inputProps} errors={form.name.errors} />
        </TemplateModal>
      </form>
    </ModalPopup>
  );
};
