import {FC} from "react";
import {tTrack} from "api/budget/track/types";
import {HeaderFees} from "./HeaderFees/HeaderFees";
import {BodyFees} from "./BodyFees";

type tPayrollFeesProps = {
  trackData: tTrack | null;
};

export const PayrollFees: FC<tPayrollFeesProps> = ({trackData}) => {
  return (
    <>
      <HeaderFees
        title="Payroll fees"
        tooltipText="Payroll actuals default to the estimated amount and the fields are editable. Expenses are not billed."
      />

      <BodyFees
        typeFees="payroll_fees"
        data={trackData?.payroll_fees?.crew_payroll_fee}
        title={"Crew payroll fee"}
      />
      <BodyFees
        typeFees="payroll_fees"
        data={trackData?.payroll_fees?.talent_payroll_fee}
        title={"Talent payroll fee"}
      />
      <BodyFees
        typeFees="payroll_fees"
        data={trackData?.payroll_fees?.union_crew_payroll_fee}
        title={"Union Crew payroll fee"}
      />
      <BodyFees
        typeFees={"payroll_fees"}
        data={trackData?.payroll_fees?.union_talent_payroll_fee}
        title={"Union talent payroll fee"}
      />
    </>
  );
};
