export const routes = {
  auth: {
    main: "auth",
    login: "login",
    verification: "verification", // FIXME need to check
    register: "register",
    forgotPass: "forgot-password",
    resetPass: "reset-password"
  },

  dashboard: {
    main: "dashboard",
    overview: "overview",
    calendar: "calendar"
  },

  jobs: {
    main: "jobs",
    createNewJob: "create-new-job",
    details: {
      main: ":id",
      overview: "overview",
      calendar: {
        main: "calendar",
        details: ":calendarId"
      },
      crew: "crew",
      callSheets: {
        main: "call-sheets",
        details: {
          main: ":callSheetId",
          track: ":callSheetId/track"
        }
      },
      files: {
        main: "files",
        directory: ":path"
      },
      tasks: {
        main: "tasks"
      },
      budget: {
        main: "budget",
        estimate: {
          main: "estimate",
          details: {
            build: ":estimateId",
            export: ":estimateId/export"
          }
        },
        advance: {
          main: "advance",
          details: {
            main: ":advanceId"
          }
        },
        track: "track",
        expenseList: "expense-list",
        wrapPDF: "wrap-pdf",
        invoice: "invoice"
      },
      grids: {
        main: "grids",
        gridId: ":gridId"
      },
      schedule: {
        main: "schedule"
      },
      wrap: {
        main: "wrap"
      }
    }
  },

  callSheetConfirm: {
    main: "call-sheet-confirm/:token"
  },

  contacts: {
    main: "contacts",
    people: "people",
    companies: "companies"
  },

  locations: {
    main: "locations"
  },

  team: {
    main: "team"
  },

  account: {
    main: "account"
  }
};
