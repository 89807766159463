import {AxiosRequestConfig} from "axios";

const search_url = "search";

export type tSearchParams = {
  query: string;
  // type?: "Jobs" | "Companies" | "People" | "Locations" | "Users"
  type?: string;
};

export const search = {
  get: (params: tSearchParams): AxiosRequestConfig => ({
    url: search_url,
    params
  }),
  getTypes: (): AxiosRequestConfig => ({
    url: `${search_url}/types`
  })
};
