import React, {FC} from "react";
import {tEmails, tNotificationsSettingsPostData} from "api/notifications/types";
import {Checkbox} from "components/shared/FormComponents/Checkbox/Checkbox";
import {useContextProfile} from "context";

interface iAccountUpdateEmails {
  emails: tEmails[];
  updateNotificationHandler: (data: Partial<tNotificationsSettingsPostData>) => Promise<void>;
}

export const AccountUpdateEmails: FC<iAccountUpdateEmails> = ({
  emails,
  updateNotificationHandler
}) => {
  const {userData} = useContextProfile();

  const setEmailsHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const name = e.target.name as tEmails;
    let updateEmails: tEmails[] = [];

    checked && !emails.includes(name) && (updateEmails = [...emails, name]);
    !checked && (updateEmails = emails.filter(item => item !== name));

    updateNotificationHandler({emails: updateEmails});
  };

  return (
    <div className="AccountNotifications-group">
      <div className="AccountNotifications-group-header">
        <h4 className="AccountNotifications-title">Send Emails To</h4>
      </div>

      <div className="AccountNotifications-group-body">
        {userData?.email && (
          <Checkbox
            name="email"
            label={userData?.email}
            checked={!!emails.includes("email")}
            onChange={setEmailsHandler}
            disabled={emails.length === 1 && emails[0] === "email"}
          />
        )}

        {userData?.personal_email && (
          <Checkbox
            name="personal_email"
            label={userData?.personal_email}
            checked={!!emails?.includes("personal_email")}
            onChange={setEmailsHandler}
            disabled={emails.length === 1 && emails[0] === "personal_email"}
          />
        )}
      </div>
    </div>
  );
};
