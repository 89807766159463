import {FC, useEffect, Dispatch, useState, SetStateAction} from "react";
import {Select} from "components/shared/FormComponents/Select/Select";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {Icon} from "components/Icon/Icon";
import {useHttp} from "hooks/httpServices/useHttp";
import {useDebounce} from "hooks/useDebounce";
import {iconsMap} from "./helpers";
import {TeamContextProvider} from "pages/Team/TeamContext";
import {TeamDetailsContextProvider} from "pages/Team/TeamDetailsContext";
import {LocationsContextProvider} from "pages/Locations/context/LocationsContext";
import {ContactsDetailsContextProvider} from "pages/Contacts/context/ContactsDetailsContext";
import {ContactsContextProvider} from "pages/Contacts/context/ContactsContext";
import {search} from "api/search";
import {SearchRow} from "./searchRows";
import {useLocation} from "react-router-dom";
import {tSearchTypesData} from "./types";
import {useContextApp} from "context";
import "./Search.scss";

export const Search: FC = () => {
  const {hubSearchWindow, setHubSearchWindow} = useContextApp();
  const {pathname} = useLocation();
  const [searchStr, setSearchStr] = useState<string>();
  const [type, setType] = useState<string>();
  const {data, isError, call: callSearch} = useHttp<{data: {[k: string]: any[]}}>();
  const {data: types, call: callTypes} = useHttp<{data: tSearchTypesData[]}>();

  const query = useDebounce(searchStr || "", 500);

  const dataSet: any = data?.data?.data;
  const isEmpty = !!dataSet ? Object.values(dataSet).every((i: any) => !i.length) : undefined;

  useEffect(() => {
    if (!types?.data?.data) return;
    const typesList: tSearchTypesData[] = types?.data?.data;
    const preselectedType = typesList.find(item => {
      return pathname.includes(item.value.toLowerCase()) || (item.value === "Users" && pathname.includes("team"))
    }); // prettier-ignore
    setType(preselectedType?.value || undefined);
  }, [pathname, types?.data?.data]);

  useEffect(() => {
    if (hubSearchWindow) callTypes(search.getTypes());
  }, [hubSearchWindow]); // eslint-disable-line

  useEffect(() => {
    if (!searchStr || !query?.trim()) return;
    callSearch(search.get({query: query?.trim(), type}));
  }, [query, type]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!hubSearchWindow) return null;

  return (
    <SearchModal setHubSearchWindow={setHubSearchWindow}>
      <div className="Search">
        <div className="Search-control">
          <div className="Search-input-group">
            <Icon icon="search" size="lg" className="Search-input-group-icon-search" />
            <input
              className="Search-input"
              value={searchStr || ""}
              onChange={event => setSearchStr(event.target.value)}
              placeholder="What are you looking?"
            />
            {searchStr && (
              <button className="Search-cancel-button" onClick={() => setSearchStr("")}>
                <Icon icon="close" size="sm" className="Search-input-group-icon-close" />
              </button>
            )}
          </div>
          <Select
            value={type}
            onChange={({target: {value}}) => setType(value)}
            options={[{value: "", label: "All"}, ...(types?.data?.data || [])]}
            className="Search-select"
          />
        </div>

        {isError && <p>Something went wrong please reload the page and try again</p>}

        {searchStr && !!dataSet && (
          <div className="Search-result">
            {Object.keys(dataSet).map(
              (type, i) =>
                Array(dataSet[type]) &&
                !!dataSet[type]?.length && (
                  <div className="Search-chapter" key={i}>
                    <div className="Search-chapter-title">
                      <Icon
                        className="Search-chapter-icon"
                        icon={iconsMap[type]}
                        color="black"
                        size="md"
                      />
                      <span>
                        {type} ({dataSet[type].length})
                      </span>
                    </div>
                    {dataSet[type].map((item: any, ii: number) => (
                      <SearchRow type={type} data={item} query={searchStr} key={ii} />
                    ))}
                  </div>
                )
            )}
          </div>
        )}
        {isEmpty && <p className="Search-empty">Nothing found.</p>}
      </div>
    </SearchModal>
  );
};

interface iSearchModal {
  setHubSearchWindow: Dispatch<SetStateAction<boolean>>;
}

const SearchModal: FC<iSearchModal> = ({setHubSearchWindow, children}) => {
  return (
    <ModalPopup
      className="Search-modal"
      showCloseButton={false}
      show={true}
      onClose={() => setHubSearchWindow(false)}
    >
      <LocationsContextProvider>
        <ContactsContextProvider>
          <ContactsDetailsContextProvider>
            <TeamContextProvider>
              <TeamDetailsContextProvider>{children}</TeamDetailsContextProvider>
            </TeamContextProvider>
          </ContactsDetailsContextProvider>
        </ContactsContextProvider>
      </LocationsContextProvider>
    </ModalPopup>
  );
};
