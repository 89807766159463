import {FC, useEffect, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {jobFilesApi} from "api/jobs/files";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextJobDetails} from "../../../context/JobDetailsContext/JobDetailsContext";
import {iJobFilesReturn} from "api/jobs/files/types";
import "./JobFilesHeader.scss";

interface iJobFilesHeaderProps {
  data: iJobFilesReturn["entries"] | null;
}

export const JobFilesHeader: FC<iJobFilesHeaderProps> = ({data}) => {
  const {jobDetailsData} = useContextJobDetails();
  const getSharedUrlQuery = useHttp();
  const [mainDirPath, setMainDirPath] = useState("");

  useEffect(() => {
    getSharedUrlQuery
      .call(jobFilesApi.getSharedUrl(jobDetailsData!.id, {path: "/"}))
      .then(({data: {data}}) => {
        setMainDirPath(data.url);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="JobFilesHeader">
      <h2 className="JobFilesHeader-title">FILES</h2>
      <a
        rel="noreferrer"
        href="https://dropbox.com/"
        target="_blank"
        className={`JobFilesHeader-link ${!mainDirPath ? "disabled" : ""}`}
      >
        Dropbox
        <Icon icon="external" size="xsm" />
      </a>
    </div>
  );
};
