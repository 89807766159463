import {tUseFilter} from "./useFilter";

type tFilterApiData = tUseFilter["filterApiData"];

// prevent re-render for clients API calls
export const checkExistingClientsParamData = (
  key: keyof tFilterApiData,
  params: {[p: string]: string},
  options: {id: number; type: string}[]
) => {
  const hasParamKey = !!(params?.[key] && params?.[key]?.split(",")?.length);
  if (!hasParamKey) return false;

  const keyType: Record<string, string> = {
    people: "person",
    companies: "company"
  };
  const keysParamArray = params?.[key]?.split(",");
  const paramData = keysParamArray.filter(id =>
    options.find(option => option.id === Number(id) && option.type === keyType[key])
  );
  const hasAllParamData = paramData.length === keysParamArray?.length;
  return hasParamKey && !hasAllParamData;
};

export const checkKeyExistsById = (
  params: {[p: string]: string},
  options: {id: number}[],
  key: "locales" | "labels" | "artists"
) => {
  const hasParamKey = !!(params?.[key] && params?.[key]?.split(",")?.length);
  if (!hasParamKey) return false;

  const keysParamArray = params?.[key]?.split(",");
  const paramData = keysParamArray.filter(id =>
    options?.find((option: any) => Number(option.id) === Number(id))
  );
  const hasAllParamData = paramData.length === keysParamArray?.length;
  return hasParamKey && !hasAllParamData;
};

export function getUnique(options: any[], uniqueValues: string[]) {
  return [
    ...new Map(options.map(item => [uniqueValues.map(k => item[k]).join("|"), item])).values()
  ];
}
