import {currencyFilter, onlyDigits, percent} from "hooks/useTextInput/filters";
import {tEstimateRowINputProps} from "./types";

export const rowInputFilter = (value: string, type: tEstimateRowINputProps["type"]) => {
  let val = value;

  if (type === "text") return val;

  if (type === "percent") {
    return percent(value);
  }

  if (type === "currency") {
    return currencyFilter(value);
  }

  return onlyDigits(val);
};
