import {FC, ReactNode, useRef, RefObject, MouseEvent} from "react";
import {useTooltip} from "./useTooltip";
import "./Tooltip.scss";

export interface iTooltipProps {
  children?: ReactNode;
  position?: "left" | "right" | "top" | "bottom";
  classname?: string;
  color?: "dark" | "light";
  message: ReactNode;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  inlineBlock?: boolean;
}

/**
 *
 * @param title: string;
 * @param children: ReactNode;
 * @param position: "left" | "right" | "top" | "bottom";
 * @param classname: string;
 * @param color: "dark" | "light";
 * @constructor
 */
export const Tooltip: FC<iTooltipProps> = ({
  children,
  position = "bottom",
  classname = "",
  color = "dark",
  message,
  onClick,
  inlineBlock = false
}) => {
  let messageRef: RefObject<HTMLDivElement> = useRef(null);
  const {handleMouseEnter, handleMouseLeave, messageStyles} = useTooltip(position);
  const inline = inlineBlock ? "inline" : "";

  return (
    <div
      className={`Tooltip ${inline} ${classname}`}
      onMouseEnter={event => handleMouseEnter({event, messageRef})}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      {children}
      <div
        ref={messageRef}
        style={messageStyles}
        className={`Tooltip-text ${color} Tooltip-${position}`}
      >
        {message}
      </div>
    </div>
  );
};
