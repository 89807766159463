import {FC} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {useHttp} from "hooks/httpServices/useHttp";
import {trackApi} from "api/budget/track";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {useContextExpenseList} from "../../context/ExpenseListContext";

type tETAlertProps = {
  id: number;
  state: boolean;
};

export const ETAlert: FC<tETAlertProps> = ({id, state}) => {
  const {call, isLoading} = useHttp();
  const {setExpenseList} = useContextExpenseList();

  const onChange = async () => {
    try {
      const res = await call(
        trackApi.setExpenseLineAlert(id, {state: !state, response_type: "list"})
      );
      setExpenseList(() => res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };
  return (
    <Button
      className={`ETAlert ${state ? "" : "placeholder"}`}
      disabled={isLoading}
      loading={isLoading}
      onClick={onChange}
    >
      <Icon icon="info" size="sm" color={state ? "red" : "light-gray"} />
    </Button>
  );
};
