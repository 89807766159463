import {FC, Fragment} from "react";
import {tCallSheetContact, tCallSHeetGroupContacts} from "api/callSheet/types";
import {SectionHeader} from "../../CallSheetBuild/CallSheetBuildCommon/SectionHeader";
import "./CallSheetTemplateContacts.scss";

type tCallSheetTemplateContactsProps = {
  contactsGroup: tCallSHeetGroupContacts[];
};

export const CallSheetTemplateContacts: FC<tCallSheetTemplateContactsProps> = ({contactsGroup}) => {
  return (
    <div className="CallSheetTemplateContacts">
      {contactsGroup?.map(group => {
        return (
          <Fragment key={group?.id}>
            <SectionHeader title={group?.name} className="CallSheetTemplateContactsHeader" />

            {group?.contacts?.map(item => {
              if (!item.selected && typeof item.id === "number") return null;
              return (
                <Fragment key={item?.id}>
                  <>
                    <Row contact={item} />
                    {item?.agent && <RowAgent contact={item} />}
                  </>
                </Fragment>
              );
            })}
          </Fragment>
        );
      })}
    </div>
  );
};

const Row = ({contact}: {contact: tCallSheetContact}) => {
  const isManuallyEntered = typeof contact.id === "string";
  return (
    <>
      <div className={`CSContact name`}>{contact?.name}</div>
      <div className={`CSContact`}>{contact?.position}</div>
      <div className={`CSContact`}>
        {contact?.email?.show || isManuallyEntered ? contact?.email?.value : ""}
      </div>
      <div className={`CSContact`}>
        {contact?.phone?.show || isManuallyEntered
          // ? formatPhoneNumber({value: contact?.phone?.value})
          ? contact?.phone?.value
          : ""}
      </div>

      <div className={`CSContact time`}>{contact?.time}</div>
      <div className={`CSContact location`}>{contact?.location?.name}</div>
    </>
  );
};

const RowAgent = ({contact}: {contact: tCallSheetContact}) => {
  return (
    <>
      <div className={`CSContact name agent`}>&bull; {contact?.agent?.name}</div>
      <div className={`CSContact`}>{contact?.agent?.position}</div>
      <div className={`CSContact`}>{contact?.agent?.email?.value}</div>
      <div className={`CSContact`}>
        {contact?.agent?.phone?.value}
        {/* {formatPhoneNumber({value: contact?.agent?.phone?.value})} */}
      </div>

      <div className={`CSContact time`}></div>
      <div className={`CSContact location`}></div>
    </>
  );
};
