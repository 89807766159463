import {FormEvent} from "react";
import {estimateApi} from "api/budget/estimate";
import {tUpdateTotalDetails} from "api/budget/estimate/types";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useTextInput, useTextInputReturn} from "hooks/useTextInput/useTextInput";
import {useContextJobBudget} from "../../../_context/JobBudgetContext";

export type tEditEstimateTotalDetailsForm = Record<
  keyof Omit<tUpdateTotalDetails, "global_production_percent">,
  useTextInputReturn
>;

export const useUpdateTotalEstimate = ({onClose}: {onClose: () => void}) => {
  const {estimate, setEstimate} = useContextJobBudget();
  const {call, isLoading} = useHttp();
  const FORM_ID = "edit-estimate-form";

  const form: tEditEstimateTotalDetailsForm = {
    crew_payroll_fee_percent: useTextInput({
      value: String(estimate?.crew_payroll_fee_percent) || "0"
    }),
    talent_payroll_fee_percent: useTextInput({
      value: String(estimate?.talent_payroll_fee_percent) || "0"
    }),
    union_crew_payroll_fee_percent: useTextInput({
      value: String(estimate?.union_crew_payroll_fee_percent) || "0"
    }),
    union_talent_payroll_fee_percent: useTextInput({
      value: String(estimate?.union_talent_payroll_fee_percent) || "0"
    }),
    insurance_percent: useTextInput({value: String(estimate?.insurance_percent) || "0"}),
    green_percent: useTextInput({value: String(estimate?.green_percent) || "0"}),
    sales_tax_percent: useTextInput({value: String(estimate?.sales_tax_percent) || "0"}),
    advance_required_percent: useTextInput({
      value: String(estimate?.advance_required_percent) || "0"
    })
  };

  const generatePostData = () => {
    if (!estimate?.id) return {};

    return {
      ...(+form.crew_payroll_fee_percent.value !== estimate.crew_payroll_fee_percent && {
        crew_payroll_fee_percent: +form.crew_payroll_fee_percent.value
      }),
      ...(+form.talent_payroll_fee_percent.value !== estimate.talent_payroll_fee_percent && {
        talent_payroll_fee_percent: +form.talent_payroll_fee_percent.value
      }),
      ...(+form.union_crew_payroll_fee_percent.value !==
        estimate.union_crew_payroll_fee_percent && {
        union_crew_payroll_fee_percent: +form.union_crew_payroll_fee_percent.value
      }),
      ...(+form.union_talent_payroll_fee_percent.value !==
        estimate.union_talent_payroll_fee_percent && {
        union_talent_payroll_fee_percent: +form.union_talent_payroll_fee_percent.value
      }),
      ...(+form.insurance_percent.value !== estimate.insurance_percent && {
        insurance_percent: +form.insurance_percent.value
      }),
      ...(+form.green_percent.value !== estimate.green_percent && {
        green_percent: +form.green_percent.value
      }),
      ...(+form.sales_tax_percent.value !== estimate.sales_tax_percent && {
        sales_tax_percent: +form.sales_tax_percent.value
      }),
      ...(+form.advance_required_percent.value !== estimate.advance_required_percent && {
        advance_required_percent: +form.advance_required_percent.value
      })
    };
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!estimate?.id) return;

    try {
      const data = generatePostData();
      const res = await call(estimateApi.updateTotalDetails(estimate?.id, data));
      onClose();
      setEstimate(res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return {FORM_ID, onSubmit, form, isLoading};
};
