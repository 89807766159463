import {FC, MouseEvent, ReactNode} from "react";
import {Button, iButtonProps} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import "./TemplateModal.scss";

interface tButton extends iButtonProps {
  text?: string;
}

interface iTemplateModalProps {
  size?: "sm" | "md";
  title?: string;
  subTitle?: string;
  primaryButton?: tButton;
  secondaryButton?: tButton;
  formId?: string;
  onCancel?: () => void;
  isLoading?: boolean;
  onBack?: () => void;
  footer?: ReactNode;
}

export const TemplateModal: FC<iTemplateModalProps> = ({
  primaryButton,
  secondaryButton,
  title,
  subTitle,
  children,
  size = "md",
  formId,
  onCancel,
  isLoading,
  onBack,
  footer
}) => {
  const cancelHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onCancel?.();
  };

  return (
    <div className={`TemplateModal ${size}`}>
      {title && (
        <div className="TemplateModal-header">
          {onBack && (
            <button className="TemplateModal-header-button" onClick={onBack}>
              <Icon icon="arrow-left" color="light-gray" size="lg" />
            </button>
          )}

          <h2 className="TemplateModal-title">{title}</h2>
          <div className="TemplateModal-subTitle">{subTitle}</div>
        </div>
      )}
      <div className="TemplateModal-main">{children}</div>

      {(secondaryButton?.text || footer || primaryButton?.text) && (
        <div className="TemplateModal-footer">
          {footer}
          {secondaryButton?.text && (
            <Button
              type="button"
              size="md"
              onClick={cancelHandler}
              color="light"
              disabled={isLoading}
              {...secondaryButton}
            >
              {secondaryButton?.text}
            </Button>
          )}
          {primaryButton?.text && (
            <Button
              type="submit"
              form={formId}
              loading={isLoading}
              size="md"
              color="dark"
              {...primaryButton}
            >
              {primaryButton?.text}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
