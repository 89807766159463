import React, {FC} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {CompanyForm} from "pages/Contacts/Companies/CompanyForm/CompanyForm";
import {PeopleForm} from "pages/Contacts/People/PeopleForm/PeopleForm";
import {iContacts} from "api/contacts/types";

interface iShowModalsButtonsProps {
  setModal: React.Dispatch<React.SetStateAction<"createPerson" | "createCompany" | null>>;
  modalType: "createPerson" | "createCompany" | null;
  onEditCallback: (data: iContacts) => void;
}

export const ShowModalsButtons: FC<iShowModalsButtonsProps> = ({
  onEditCallback,
  setModal,
  modalType
}) => {
  const closeModal = () => setModal(null);

  const openCreatePerson = () => setModal("createPerson");
  const openCreateCompany = () => setModal("createCompany");
  return (
    <>
      <Button type="button" className="SearchClient-button" onClick={openCreateCompany}>
        <Icon icon="add" size="xsm" color="dark-gray" /> Add company
      </Button>

      <Button type="button" className="SearchClient-button" onClick={openCreatePerson}>
        <Icon icon="add" size="xsm" color="dark-gray" />
        Add person
      </Button>

      <ModalPopup
        className="PeopleFormModal"
        show={modalType === "createPerson"}
        onClose={closeModal}
      >
        <PeopleForm closeModal={closeModal} onEditCallback={onEditCallback} />
      </ModalPopup>

      <ModalPopup
        className="CompanyForm-modal"
        show={modalType === "createCompany"}
        onClose={closeModal}
      >
        <CompanyForm closeModal={closeModal} onEditCallback={onEditCallback} />
      </ModalPopup>
    </>
  );
};
