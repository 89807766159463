import {FC} from "react";
import {NavLink} from "react-router-dom";
import {AppTooltips, tAppTooltipsProps} from "components/AppTooltips/AppTooltips";
import "./Header.scss";

export type pageTabObj = {
  label: string;
  url: string;
  showTooltip?: tAppTooltipsProps;
};

interface iHeader {
  title?: string;
  tabs?: pageTabObj[];
}

/**
 * Header template
 *
 * @param title?: string;
 * @param tabs?: {label: string; url: string}[];
 * @param buttons?: ReactNode;
 * @constructor
 */
export const Header: FC<iHeader> = ({title = "", tabs, children}) => {
  return (
    <div className="Header">
      <div className="Header-main">
        <h1 className="Header-title">{title}</h1>
        {tabs && (
          <div className="Header-tab">
            {tabs.map(({label, url, showTooltip}) => (
              <NavLink key={url} to={url} className="Header-tab-item">
                {label}

                {showTooltip && <AppTooltips {...showTooltip} />}
              </NavLink>
            ))}
          </div>
        )}
      </div>
      {children && <div className="Header-btns-group">{children}</div>}
    </div>
  );
};
