import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import {TeamFieldGroupItem} from "./TeamFieldGroupItem/TeamFieldGroupItem";
import {tFieldGroupRow} from "./interfaces";
import {ConfirmWindow} from "../../ConfirmWindow/ConfirmWindow";
import "./TeamFieldGroup.scss";

interface iTeamFieldGroupProps {
  value: tFieldGroupRow[];
  onChange: (val: tFieldGroupRow) => void;
  onRemoveRow: (id: string) => void;
  addNewRow: () => void;
  errors: string[];
  onClearSelect: (id: string) => void;
  relationshipsData: {value: string; label: string}[];
  hideRemoveModal: () => void;
  confirmRemove: () => void;
  teamMemberForRemove: string | null;
  personName: string;
  onInputChange: (value: string) => void;
  options: any[];
}
export const TeamFieldGroup = (props: iTeamFieldGroupProps) => {
  const {options, onInputChange} = props;
  const {value, onChange, onRemoveRow, addNewRow, onClearSelect} = props;
  const {hideRemoveModal, confirmRemove, teamMemberForRemove, personName} = props;

  const selectData = options?.filter(
    i => !value.find(v => Number(v.value?.person?.value) === Number(i.id))
  );

  const lastElementIsEmpty = value.length && !value[value.length - 1]?.value?.person?.value;
  const isItemsError = value.some(({errors}) => !!errors.length);

  const teammateName = value.find(i => i.id === teamMemberForRemove)?.value.person.label;

  return (
    <div className="FieldGroup">
      {!!value.length && value?.[0]?.value?.person && (
        <div className="FieldGroup-labelRow">
          <label className="FieldGroup-label">Member</label>
          <label className="FieldGroup-label">Relationship</label>
        </div>
      )}

      {value?.map(item => (
        <TeamFieldGroupItem
          key={item.id}
          item={item}
          onChange={onChange}
          handleRemove={onRemoveRow}
          selectData={selectData}
          onClearSelect={onClearSelect}
          onInputChange={onInputChange}
          isItemsError={isItemsError}
        />
      ))}
      {/* {errors.map((error, index) => {
        return (
          <p key={index} className="FieldGroup-emptyRowErr">
            {error}
          </p>
        );
      })} */}
      {!lastElementIsEmpty && !isItemsError && (
        <Button
          className="FieldGroup-button"
          size="sm"
          color="outline"
          type="button"
          onClick={addNewRow}
        >
          <Icon icon="add" size="sm" />
          <span>Add Contact</span>
        </Button>
      )}

      <ConfirmWindow
        show={!!teamMemberForRemove}
        onClose={hideRemoveModal}
        onConfirm={confirmRemove}
        confirmBtn={{color: "red", text: "Delete"}}
        cancelBtn={{text: "Cancel"}}
        title="Unlink Team Member"
      >
        <div className="PeopleForm-confirmWindow-body">
          <p>
            Are you sure you want to unlink {teammateName} from {personName}'s record?
          </p>
          <p className="PeopleForm-confirmWindow-warning">This can't be undone.</p>
        </div>
      </ConfirmWindow>
    </div>
  );
};
