import {FC, useEffect} from "react";
import {RadioField} from "components/shared/FormComponents/RadioField/RadioField";
import {useRadio} from "hooks/useRadio/useRadio";

interface iProps {
  value: string;
  onChange: ({params, removePagination}: {params: {}; removePagination: boolean}) => void;
}

const favoriteRadioList = [
  {label: "Starred", name: "favorites", value: "1"},
  {label: "No Star", name: "favorites", value: "0"}
];

export const FilterFavorite: FC<iProps> = ({value, onChange}) => {
  const favoriteData = useRadio({
    fields: favoriteRadioList,
    value: value ? value : ""
  });

  useEffect(() => {
    const params = {favorite: favoriteData?.value};
    onChange({params, removePagination: false});
  }, [favoriteData?.value]); // eslint-disable-line

  useEffect(() => {
    if (!value) {
      favoriteData.setValue("");
      return;
    }
  }, [value]); // eslint-disable-line

  return (
    <>
      <label htmlFor="JobsFilter-favorite" className="JobsTableFilter-label">
        Favorite
      </label>
      <RadioField {...favoriteData} className="JobsTableFilter-value" />
    </>
  );
};
