import {useEffect} from "react";
import {useSelect} from "hooks/inputHooks/useSelect";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {tCreditCard} from "api/budget/creditCardAndPayroll/types";
// import {useHttp} from "hooks/httpServices/useHttp";
// import {creditCardAndPayroll} from "api/budget/creditCardAndPayroll";

export const useCreditCardForm = ({
  cardData,
  cardsList
}: {
  cardData?: tCreditCard;
  cardsList: any;
}) => {
  const form = {
    type: useSelect({
      options: [],
      value: String(cardData?.type?.id || ""),
      isRequired: true,
      targetKeys: {value: "id", label: "name"}
    }),
    initials: useTextInput({
      value: cardData?.holder_initials || "",
      validators: ["name"],
      filters: ["name"]
    }),
    digits: useTextInput({
      value: cardData?.last_four_digits || "",
      filters: ["onlyDigits"]
    })
  };

  // const {data} = useHttp({onMountCall: true, config: creditCardAndPayroll?.getCreditCardTypes()});
  useEffect(() => {
    // data && form?.type?.setOptions(data?.data?.data || []);
    cardsList && form?.type?.setOptions(cardsList || []);
  }, [cardsList]); // eslint-disable-line
  // }, [data]); // eslint-disable-line

  return {form};
};
