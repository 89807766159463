import {FC} from "react";
import {Icon} from "components/Icon/Icon";
import {AxiosRequestConfig} from "axios";
import {useFavoriteCell} from "./useFavoriteCell";
import {AppTooltips, tAppTooltipsProps} from "components/AppTooltips/AppTooltips";
import "./FavoriteCell.scss";

interface iFavoriteCell {
  addConfig: AxiosRequestConfig;
  removeConfig: AxiosRequestConfig;
  in_favorite: boolean;
  className?: string;
  showTooltip?: tAppTooltipsProps;
  icon: "star" | "heart";
  refetch?: boolean;
}

export const FavoriteCell: FC<iFavoriteCell> = ({
  in_favorite,
  addConfig,
  removeConfig,
  className = "",
  showTooltip,
  icon,
  refetch
}) => {
  const {isLoading, isFavorite, handleOnClick} = useFavoriteCell({
    in_favorite,
    removeConfig,
    addConfig,
    refetch
  });

  return (
    <td className={`FavoriteCell posRelative ${className}`} onClick={e => e.stopPropagation()}>
      <button type="button" onClick={handleOnClick} disabled={isLoading}>
        <Icon icon={icon} color={isFavorite ? "yellow" : "gray"} />
      </button>
      {showTooltip && <AppTooltips {...showTooltip} chevronPosition={"topLeft"} withAnimation />}
    </td>
  );
};
