import {FC, useEffect} from "react";
import {useSelect} from "hooks/inputHooks";
import {useIsFirstRender} from "hooks/useIsFirstRender";
import {useSearchParams} from "react-router-dom";
import {iPaginationProps} from "./types";
import {Select} from "components/shared/FormComponents/Select/Select";

export const PerPage: FC<iPaginationProps> = ({meta, setPageParams}) => {
  const isFirst = useIsFirstRender();
  const [searchParams, setParams] = useSearchParams();

  const perPage = useSelect({
    options: generatePerPageOption(),
    value: String(meta?.per_page) || "15"
  });

  useEffect(() => {
    const itemsOnCurrPage = meta?.to - meta?.from;
    const totalItemsOnPage = meta?.total % +perPage?.value;

    if (isFirst) return;
    if (totalItemsOnPage >= itemsOnCurrPage && +perPage?.value !== 1) {
      setPageParams?.(perPage?.value);
      return;
    }
    setParams({...Object.fromEntries(searchParams), per_page: perPage?.value});
  }, [perPage?.value]); // eslint-disable-line

  useEffect(() => {
    if (isFirst) return;
    !Object.fromEntries(searchParams).hasOwnProperty("per_page") && perPage.setValue("15");
  }, [searchParams]); // eslint-disable-line

  return <Select {...perPage.inputProps} canSelectEmpty={false} className="PerPage" />;
};

export function generatePerPageOption(maxItems = 20, step = 5) {
  const items = Array(maxItems).fill(step);

  return items.map((item, i) => {
    const currItem = item * (i + 1);
    return {id: currItem, label: currItem};
  });
}
