import {FC, useState} from "react";
import {Button} from "components/shared/Button/Button";
import {EditEstimateForm} from "./EditEstimateForm/EditEstimateForm";
import "./EstimateSIdebarActions.scss";

type tEstimateSidebarActionsProps = {
  [key: string]: any;
};

export const EstimateSidebarActions: FC<tEstimateSidebarActionsProps> = () => {
  const [showModal, setShowModal] = useState<"approve" | "edit" | null>(null);
  const onCloseModal = () => setShowModal(null);

  return (
    <div className="EstimateSidebarActions">
      <Button color="outline" size="sm" width="fullWidth" onClick={() => setShowModal("edit")}>
        Edit total details
      </Button>
      {showModal === "edit" && <EditEstimateForm onClose={onCloseModal} />}
    </div>
  );
};
