import {useRef, useState} from "react";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {
  tCheckFieldsValidity,
  tDynamicFieldRowObj
} from "components/shared/FormComponents/DynamicFields/types";
import {createNewRowObj} from "components/shared/FormComponents/DynamicFields/model";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useDatePicker} from "components/shared/FormComponents/Datepicker/useDatePicker";

export const useCreateNewCallSheetForm = () => {
  const {jobDetailsData} = useContextJobDetails();
  const locationList = useRef(jobDetailsData?.locations?.map(item => ({id: item?.id, name: item?.name, address: item?.address}))); // prettier-ignore
  const locationsRef = useRef<tCheckFieldsValidity>(null);
  const [locations, setLocations] = useState<tDynamicFieldRowObj[]>([createNewRowObj()]);

  const form = {
    name: useTextInput(),
    title: useTextInput(),
    date: useDatePicker({initValue: jobDetailsData?.start_at || "", isRequired: true})
  };

  return {
    form,
    locationList,
    locationsRef,
    locations,
    setLocations
  };
};
