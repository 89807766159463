import {FC} from "react";
import {StyleSheet, View, Text, Image} from "@react-pdf/renderer";
import logo from "assets/images/logo_text.png";
import {Label, Value} from "./common";

interface iHeaderBreakdownProps {
  jobName: string;
  jobNumber: string;
  photographer: string;
  po: string;
  title: string;
}

export const HeaderBreakdown: FC<iHeaderBreakdownProps> = ({
  jobName,
  jobNumber,
  photographer,
  po,
  title,
  ...props
}) => {
  return (
    <View {...props}>
      <View style={styles.footer}>
        <View style={styles.left}>
          <Image src={logo} />
        </View>
        <View style={styles.right}>
          <Text style={styles.title}>{title}</Text>
          <Label>Job name</Label>
          <Value>{jobName}</Value>
          <Label>Job Number</Label>
          <Value>{jobNumber}</Value>
          <Label>Photographer</Label>
          <Value>{photographer}</Value>
          <Label>PO</Label>
          <Value>{po}</Value>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  footer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "29px"
  },
  left: {
    display: "flex",
    flexDirection: "column",
    width: "55%"
  },
  right: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "40%"
  },
  title: {
    fontSize: 17,
    marginBottom: 14,
    fontFamily: "Helvetica700",
    textTransform: "uppercase"
  }
});
