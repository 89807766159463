import {useState} from "react";
import {trackApi} from "api/budget/track";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {tTrackExpenseLine} from "api/budget/track/types";
import {eventBus} from "EventBus/EventBus";
import {useContextTrack} from "pages/Jobs/JobDetails/JobBudget/Track/_context/TrackContext";
import {useContextExpenseList} from "pages/Jobs/JobDetails/JobBudget/Track/ExpenseList/context/ExpenseListContext";
import {useResponseType} from "pages/Jobs/JobDetails/JobBudget/Track/_common/useResponseType";

type tProps = {
  trackLine: tTrackExpenseLine;
  type: "payStatus" | "qbStatus" | "pay";
};

export const useDropdownCellWrap = ({trackLine, type}: tProps) => {
  const {setTrackData} = useContextTrack();
  const {setExpenseList} = useContextExpenseList();
  const {isExpense, response_type} = useResponseType();
  const {call, isLoading} = useHttp();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const calls = (id: number | null, target_id?: number) => {
    return {
      payStatus: trackApi.setPayStatus(trackLine?.id, {
        pay_status_id: id,
        target_id,
        response_type
      }),
      qbStatus: trackApi.setQBStatus(trackLine?.id, {qb_status_id: id, response_type}),
      pay: trackApi.setPayId(trackLine?.id, {pay_id: id, target_id, response_type})
    };
  };

  const changeValue = async (id: number | null, target_id?: number) => {
    setMenuAnchor(null);

    try {
      const res = await call(calls(id, target_id)[type]);
      isExpense && setExpenseList(res?.data?.data);
      !isExpense && setTrackData(res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return {changeValue, isLoading, menuAnchor, setMenuAnchor};
};
