import {estimateApi} from "api/budget/estimate";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextJobBudget} from "../../context/JobBudgetContext";
import {getDateFormat} from "helpers/dates";
import {SyncData} from "pages/Jobs/JobDetails/common/SyncData/SyncData";
import {useSyncData} from "hooks/useSyncData";

export const EstimateSync = () => {
  const {estimate, setEstimate} = useContextJobBudget();
  const {call, isLoading} = useHttp({cancelRequest: false});

  useSyncData({callBack: syncEstimate, deps: [estimate]});

  async function syncEstimate() {
    if (!estimate?.id) return;
    try {
      const time = getDateFormat(estimate.updated_at, "timestampWithUTC");
      const res = await call(estimateApi.syncEstimate(estimate?.id, time));
      res?.data?.data && setEstimate(res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  }

  return (
    <SyncData
      name={estimate?.updater?.name || ""}
      updateCallback={syncEstimate}
      isLoading={isLoading}
    />
  );
};
