import {FC, useState} from "react";
import {tLinks} from "api/jobs/types";
import {RemoveLink} from "./RemoveLink";
import {CopyLInkPassword} from "./CopyLInkPassword";
import {AddUrlLinkForm} from "../../sections/Links/AddUrlLinkForm/AddUrlLinkForm";
import "./LinkBlock.scss";

type tLinkBlockProps = {
  link: tLinks;
};

export const LinkBlock: FC<tLinkBlockProps> = ({link}) => {
  const [showForm, setShowForm] = useState(false);

  const onCloseForm = () => setShowForm(false);

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const el = e.target as HTMLDivElement;

    if (el?.hasAttribute("data-link")) {
      return setShowForm(true);
    }

    window.open(link?.link);
  };

  return (
    <div className="LinkBlock">
      <div onClick={onClick}>
        <div className="LinkBlock-title">{link?.title}</div>
        <span data-link>{link?.link}</span>
      </div>

      {link.password && <CopyLInkPassword link={link} />}
      <RemoveLink link={link} />

      {showForm && <AddUrlLinkForm show={showForm} onCloseModal={onCloseForm} data={link} />}
    </div>
  );
};
