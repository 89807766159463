import {ForwardedRef, forwardRef, useState} from "react";
import {JobPipeline} from "./JobPipeline/JobPipeline";
import {Icon} from "../../../../../components/Icon/Icon";
import {tPipeline} from "api/dashboard/overview/types";
import {useCountHiddenElements} from "./useCountHiddenElements";
import "./OverviewPipelineColumn.scss";

export type tColor = "gray" | "yellow" | "blue" | "green";
interface iOverviewPipelineColumn {
  title: "Bidding" | "Working" | "Wrap & BILL" | "Done";
  color: tColor;
  data: tPipeline;
  height?: number;
}

export const OverviewPipelineColumn = forwardRef(
  ({title, color, data, height}: iOverviewPipelineColumn, ref: ForwardedRef<any>) => {
    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapsed = () => setCollapsed(prev => !prev);
    const {dynamicFieldsRef, blockRef} = useCountHiddenElements({height, color, ref, data});

    return (
      <div className={`OverviewPipelineColumn ${collapsed ? "collapsed" : ""}`} ref={blockRef}>
        <h5 className={`OverviewPipelineColumn-title ${color}`}>
          {title}
          {title === "Done" && (
            <button className="collapseToggler" onClick={toggleCollapsed}>
              <Icon color="light-gray" icon="collapse" />
            </button>
          )}
        </h5>

        {collapsed && (
          <div className="OverviewPipelineColumn-narrow">
            <div className="title">
              <div>{data?.jobs?.length || ""}</div>
              jobs
            </div>
            <button className="expandBtn" onClick={toggleCollapsed}>
              expand
            </button>
          </div>
        )}

        {!collapsed &&
          data?.jobs?.map((job, i) => (
            <JobPipeline ref={dynamicFieldsRef.current[i]} key={job?.id} data={job} />
          ))}
      </div>
    );
  }
);
