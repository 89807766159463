import {FC} from "react";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {estimateApi} from "api/budget/estimate";
import {useHttp} from "hooks/httpServices/useHttp";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useContextJobBudget} from "pages/Jobs/JobDetails/JobBudget/context/JobBudgetContext";
import {getUsMoneyFormat} from "helpers/formattingData";
import "./EstimateGroupRowFooter.scss";

type tEstimateGroupRowFooterProps = {
  totalVal: number;
  groupId: number;
  showList: boolean;
};

export const EstimateGroupRowFooter: FC<tEstimateGroupRowFooterProps> = ({
  totalVal,
  groupId,
  showList
}) => {
  const {call, isLoading} = useHttp();
  const {setEstimate, estimate} = useContextJobBudget();

  const createNewRow = async () => {
    try {
      const res = await call(estimateApi.createLine({category_value_id: groupId}));
      setEstimate(res?.data?.data);
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const approved = estimate?.approved_at || !showList ? "approved" : "";
  return (
    <div className={`EstimateGroupRowFooter ${approved}`}>
      <Button onClick={createNewRow} loading={isLoading}>
        <Icon icon="add" size="xsm" color="gray" />
        <TextComponent size="10" color="gray-20">
          add line item
        </TextComponent>
      </Button>

      <TextComponent size="15" uppercase className="totalWrap">
        <span>Total</span>
        <span>{getUsMoneyFormat(totalVal)}</span>
      </TextComponent>
    </div>
  );
};
