import {useEffect, useRef, useState} from "react";
import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenSource} from "axios";
import {
  LOCAL_BASE_URL,
  EPIC_BASE_URL,
  DEV_BASE_URL,
  STAGING_BASE_URL,
  PROD_BASE_URL
} from "api/config";
import {getStorageData} from "helpers/misc";
import {absRoutes} from "router";

const userInfo = "";

/**
 * Setting configuration for api requests (base url, token, timeout)
 * @returns {AxiosInstance} AxiosInstance
 */

export const getBaseURL = () => {
  switch (process.env.REACT_APP_ENV) {
    case "production":
      return PROD_BASE_URL;
    case "development":
      return DEV_BASE_URL;
    case "staging":
      return STAGING_BASE_URL;
    case "epic":
      return EPIC_BASE_URL;
    case "local":
      return LOCAL_BASE_URL;
    default:
      return DEV_BASE_URL;
  }
};

export const configuredAxios = (
  removeBaseUrl?: boolean,
  disableTimeout?: boolean
): AxiosInstance => {
  const http = axios.create({
    timeout: disableTimeout ? undefined : 20000,
    baseURL: removeBaseUrl ? undefined : getBaseURL()
  });

  http?.interceptors.request.use(
    config => {
      let userInfo = getStorageData();

      if (!config.headers) return;
      !config.headers["Authorization"] && (config.headers["Authorization"] = userInfo.token || "");

      return config;
    },
    (error: any) => Promise.reject(error)
  );

  typeof window !== undefined &&
    http?.interceptors.response.use(
      r => r,
      error => {
        if (error?.response?.status === 401 && window.location.pathname.indexOf("auth") < 0) {
          window.localStorage.removeItem(userInfo);
          window.location.pathname = absRoutes.auth.main;
        }

        return Promise.reject({...error, cancelledPromise: axios.isCancel(error)});
      }
    );

  return http;
};

export interface iUseApi {
  apiCall: (configObj: AxiosRequestConfig, cancelRequest: boolean) => Promise<AxiosResponse>;
}

/**
 * Makes api requests
 * @param removeBaseUrl makes api request without base url
 * @returns {iUseApi} iUseApi
 */
export const useApi = (removeBaseUrl?: boolean, disableTimeout?: boolean): iUseApi => {
  const [http] = useState<AxiosInstance>(() => configuredAxios(removeBaseUrl, disableTimeout));
  const activeRequests = useRef<CancelTokenSource[]>([]);

  useEffect(() => () => activeRequests.current.forEach(ar => ar.cancel()), []);

  const apiCall = (
    configObj: AxiosRequestConfig,
    cancelRequest: boolean = true
  ): Promise<AxiosResponse> => {
    let cancelSource = axios.CancelToken.source();

    if (!cancelRequest) return http?.request({...configObj, cancelToken: cancelSource.token});

    activeRequests.current.push(cancelSource);

    return http?.request({...configObj, cancelToken: cancelSource.token}).finally(() => {
      activeRequests.current.splice(activeRequests.current.indexOf(cancelSource), 1);
    });
  };

  return {apiCall};
};
