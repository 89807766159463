import {FC} from "react";
import {tCrewContact} from "api/jobCrewGroup/types";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {RowActions} from "./RowActions/RowActions";
import {RowEmails} from "./RowEmails/RowEmails";
import {RowNameAgent} from "./RowName/RowNameAgent";
import {RowPhones} from "./RowPhones/RowPhones";
import {iCompaniesContact} from "api/contacts/types";

type tCrewTableRowAgentProps = {
  agent: iCompaniesContact;
  crewContact: tCrewContact;
  groupId: number;
};

export const CrewTableRowAgent: FC<tCrewTableRowAgentProps> = ({agent, crewContact, groupId}) => {
  return (
    <>
      <div className={`CrewTableCell CrewTableCell-agent  CrewTableCell-firstRowItem`}>
        <RowNameAgent agent={agent} />
      </div>
      <div className={`CrewTableCell CrewTableCell-agent`}>
        <TextComponent weight="500" size="10" uppercase color="black-10">
          Agent
        </TextComponent>
      </div>
      <div className={`CrewTableCell CrewTableCell-agent`} />
      <div className={`CrewTableCell CrewTableCell-agent`} />
      <div className={`CrewTableCell CrewTableCell-agent`} />
      <div className={`CrewTableCell CrewTableCell-agent`}>
        <RowPhones crewContact={crewContact} groupId={groupId} isAgent />
      </div>
      <div className={`CrewTableCell CrewTableCell-agent`}>
        <RowEmails crewContact={crewContact} groupId={groupId} isAgent />
      </div>
      <div className={`CrewTableCell CrewTableCell-agent`} />
      <div className={`CrewTableCell CrewTableCell-agent`} />
      <div className={`CrewTableCell CrewTableCell-agent`} />
      <div className={`CrewTableCell CrewTableCell-agent`}>
        <RowActions crewContact={crewContact} typeRow="agent" groupId={groupId} />
      </div>
    </>
  );
};
