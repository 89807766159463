import {useState} from "react";
import {common as commonApi} from "api/common";
import {jobsApi} from "api/jobs";
import {useHttp} from "hooks/httpServices/useHttp";

type tDataKey = keyof typeof apiConfigs;
type tDataSet = Record<tDataKey, any[]>;

const apiConfigs = {
  pronouns: commonApi.getPronouns,
  pipelines: jobsApi.getPipelines,
  platforms: commonApi.getPlatforms,
  countries: commonApi.getCountries,
  departments: commonApi.getDepartments,
  userRelationships: commonApi.getUserRelationships,
  relationships: commonApi.getVersatileRelationships,
  emailTypes: commonApi.getEmailTypes,
  phoneTypes: commonApi.getPhoneTypes
};

export const useBasicData = () => {
  const set: tDataSet = Object.fromEntries(Object.keys(apiConfigs).map(key => [[key], []]));
  const [data, setData] = useState(set);

  const query = useHttp();

  const getData = (key: tDataKey) => {
    if (key in data && !!data[key].length) return;

    const httpQuery = query;
    // httpQuery.call(apiConfigs[key]()).then(({data: {data}}) => {
    //   setData((prev: any) => ({...prev, [key]: data}));
    // });
    return httpQuery.call(apiConfigs[key]()).then(({data: {data}}) => {
      setData((prev: any) => ({...prev, [key]: data}));
      return data;
    });
  };

  return {
    getData,
    ...data
  };
};

/*
*** USAGE ***
const {countries, platforms, pronouns, getData} = useContextBasicData();

useEffect(() => {
  getData("pronouns");
  getData("countries");
  getData("platforms");
}, []);
*/
