import {useState} from "react";
import {tDraggingItem} from "components/DragAndDropWrap/DragAndDropWrap";
import {useContextJobTasks} from "../../_context/JobTaskContext";
import {tJobTasksTemplateDataTask} from "api/jobs/tasks/types";

type tProps = {
  setData?: React.Dispatch<React.SetStateAction<tJobTasksTemplateDataTask>>;
  data: tJobTasksTemplateDataTask;
};

export const useDragTask = ({setData, data}: tProps) => {
  const {tasks: tasksData, updateTasksData, updateDataCall: {isLoading}} = useContextJobTasks(); // prettier-ignore
  const [currItem, setCurrItem] = useState<tDraggingItem | null>(null);

  const dropCallBack = (draggingItem: any, tasks: Array<any>) => {
    if (currItem?.id === draggingItem?.id || isLoading) return;

    const newIndex = tasks?.findIndex((_, i) => {
      return i === draggingItem?.id;
    }) as number;

    const generateNewData = (tasks: tJobTasksTemplateDataTask) => {
      if (!tasks || !tasks?.tasks?.length) return data;
      const newArrLines = [...tasks?.tasks?.filter((_, i) => i !== currItem?.id)];

      currItem && newArrLines.splice(newIndex, 0, currItem?.task as string);
      return {...tasks, tasks: newArrLines};
    };

    setData?.(generateNewData(data));

    const postData = tasksData?.data?.map(item => {
      if (item?.name === data?.name) {
        return generateNewData(data);
      }
      return item;
    });
    updateTasksData(postData);
  };

  return {
    setCurrItem,
    dropCallBack,
    currItem,
    isLoading
  };
};
