import {FC, FormEvent} from "react";
import {EmptyPageState} from "components/shared/EmptyPageState/EmptyPageState";
import budgetImage from "assets/images/budget_gray.png";
import {Select} from "components/shared/FormComponents/Select/Select";
import {Button} from "components/shared/Button/Button";
import {tEstimateListItem} from "api/budget/estimate/types";
import {useSelect} from "hooks/inputHooks";
import {useContextInvoice} from "../context/InvoiceContext";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {invoiceApi} from "api/invoice";
import {eventBus} from "EventBus/EventBus";

import "./InvoiceEmptyState.scss";

interface iInvoiceEmptyStateProps {
  estimates: tEstimateListItem[];
}

export const InvoiceEmptyState: FC<iInvoiceEmptyStateProps> = ({estimates}) => {
  const {call, isLoading} = useHttp();
  const {setInvoice} = useContextInvoice();

  const estimate = useSelect({
    options: estimates
  });

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!estimate?.value) return;
    try {
      const payload = {estimate_id: Number(estimate?.value)};
      const {
        data: {data, message}
      } = await call(invoiceApi.generateInvoiceByEstimate(payload));
      setInvoice(data);
      eventBus.dispatch("showToast", {text: message});
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <div className="InvoiceEmptyState">
      <EmptyPageState img={budgetImage} text="Choose an estimate to import job details from.">
        <form className="InvoiceEmptyState-form" onSubmit={handleSubmit}>
          <Select
            {...estimate.inputProps}
            options={estimates}
            disabled={isLoading}
            targetKeys={{
              value: "id",
              label: "name"
            }}
          />
          <Button
            loading={isLoading}
            disabled={!estimate?.value}
            size="md"
            color="dark"
            width="fullWidth"
          >
            Generate invoice
          </Button>
        </form>
      </EmptyPageState>
    </div>
  );
};
