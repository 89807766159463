import {FC} from "react";
import {Datepicker} from "components/shared/FormComponents/Datepicker/Datepicker";
import {useDatePicker} from "components/shared/FormComponents/Datepicker/useDatePicker";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {advanceApi} from "api/budget/advance";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useContextAdvance} from "../../context";
import {useHttp} from "hooks/httpServices/useHttp";
import {advanceStatus} from "api/budget/advance/advanceStatus";
import {getDateFormat} from "helpers/dates";

import "./AdvanceSend.scss";

interface iAdvanceSendProps {
  show: boolean;
  onClose: () => void;
}

export const AdvanceSend: FC<iAdvanceSendProps> = ({show, onClose}) => {
  const {advanceId, setAdvanceDetails} = useContextAdvance();

  const {call, isLoading} = useHttp();

  const date = useDatePicker();

  const onChangeToSent = async () => {
    if (!advanceId) return;

    try {
      const {
        data: {message, data}
      } = await call(
        advanceApi.changeAdvanceStatus(advanceId, {
          status: advanceStatus.sent,
          date: +getDateFormat(date.value, "timestamp")
        })
      );
      setAdvanceDetails(data);
      eventBus.dispatch("showToast", {text: message});
      onClose();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {text: msg, type: "error"});
    }
  };
  return (
    <ConfirmWindow
      className="AdvanceSend"
      show={show}
      title="Mark as sent?"
      subTitle="Enter the advance send date"
      onClose={onClose}
      onConfirm={onChangeToSent}
      isLoading={isLoading}
      confirmBtn={{
        text: "Save"
      }}
      cancelBtn={{
        text: "Cancel"
      }}
    >
      <Datepicker {...date.inputProps} withIcon />
    </ConfirmWindow>
  );
};
