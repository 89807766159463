import { iGetJobsParams } from "api/jobs/types";
import { AxiosRequestConfig } from "axios";

export const overviewApi = {
  getJobs: (params?: iGetJobsParams): AxiosRequestConfig => ({
    url: `dashboard/jobs`,
    method: "get",
    params
  }),
  getPipelines: (): AxiosRequestConfig => ({
    url: `dashboard/pipelines`
  })
};
