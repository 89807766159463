import {jobCrewGroup} from "api/jobCrewGroup";
import {tCrewGroupContact} from "api/jobCrewGroup/types";
import {Button} from "components/shared/Button/Button";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {PeopleForm} from "pages/Contacts/People/PeopleForm/PeopleForm";
import {useContextJobCrew} from "pages/Jobs/JobDetails/JobCrew/JobCrewContext";
import {FC, useState} from "react";

type tAddContactProps = {
  group: tCrewGroupContact;
};

export const AddContact: FC<tAddContactProps> = ({group}) => {
  const {jobCrewGroupList: {getJobCrewGroupList}} = useContextJobCrew(); // prettier-ignore
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);

  const {call} = useHttp();

  const getContactData = async (data: any) => {
    try {
      const id = data?.data?.data?.id;
      const res = await call(jobCrewGroup.addContactToCrewGroup(group?.id, {person_id: id}));
      eventBus.dispatch("showToast", {text: res?.data?.message || "Success!"});
      getJobCrewGroupList();
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <>
      <Button size="sm" onClick={() => setShowModal(true)}>
        New
      </Button>

      {showModal && (
        <ModalPopup className="PeopleFormModal" show={showModal} onClose={closeModal}>
          <PeopleForm
            closeModal={closeModal}
            title="Add new contact"
            primaryButtonTitle="Create and add to crew"
            onEditCallback={getContactData}
          />
        </ModalPopup>
      )}
    </>
  );
};
