import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useAddressField} from "components/shared/FormComponents/AddressField/useAddressField";
import {iCompanyDetails} from "api/contacts/companies/interfaces";
import {common} from "api/common";
import {useHttp} from "hooks/httpServices/useHttp";
import {usePhoneInput} from "hooks/usePhoneInput";
import {lengthValidation} from "hooks/useTextInput/validators";

export type tUseCompanyFormAddress = ReturnType<typeof useCompanyFormAddress>;

interface iProps {
  company: iCompanyDetails | undefined | null;
}

export const useCompanyFormAddress = ({company}: iProps) => {
  const {data: countriesData} = useHttp({config: common.getCountries(), onMountCall: true});

  const formData = {
    billing_info: {
      first_name: useTextInput({
        value: company?.billing_info?.first_name || "",
        validators: [lengthValidation(2)],
        isRequired: false
      }),
      last_name: useTextInput({
        value: company?.billing_info?.last_name || "",
        validators: [lengthValidation(2)],
        isRequired: false
      }),
      email: useTextInput({
        value: company?.billing_info?.email || "",
        validators: ["email"],
        filters: ["email"],
        isRequired: false
      }),
      phone: useTextInput({
        validators: ["phone"],
        filters: ["phone"],
        value: company?.billing_info ? company?.billing_info?.phone : "", // prettier-ignore
        isRequired: false
      }),
      address: useAddressField({
        isRequired: false,
        address: company?.billing_info?.address,
        countryOptions: countriesData?.data?.data,
        raw: company?.billing_info?.address?.raw
      })
    },
    address: useAddressField({
      isRequired: false,
      address: company?.address,
      countryOptions: countriesData?.data?.data,
      raw: company?.address?.raw
    })
  };
  const isChanged = Object.values(formData.billing_info).some(field => field.isChanged) || formData.address.isChanged; // prettier-ignore

  return {formData, isChanged};
};
