import {createContext, FC, useContext, useLayoutEffect, useState} from "react";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {eventBus} from "EventBus/EventBus";
import {jobTasksApi} from "api/jobs/tasks";
import {tJobTasksTemplateData} from "api/jobs/tasks/types";
import {useContextTemplateLayout} from "../../_common/TemplateLayoutContext";

const useJobTasks = () => {
  const {id} = useContextTemplateLayout();

  const [tasks, setTasks] = useState<tJobTasksTemplateData | null>(null);
  const {call, isLoading, isError, error} = useHttp();

  const updateDataCall = useHttp();

  const getTasks = async () => {
    if (!id) return;
    try {
      const res = await call(jobTasksApi.getTemplateData(+id));
      setTasks(res?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error getting tasks!"});
    }
  };

  const updateTasksData = async (
    newData: tJobTasksTemplateData["data"] | undefined,
    callBack?: () => void
  ) => {
    const res = await updateDataCall.call(jobTasksApi?.updateTemplateData(id, {data: newData}));

    setTasks(prev => {
      if (!prev) return prev;
      return {...prev, data: res?.data?.data};
    });

    callBack?.();
  };

  useLayoutEffect(() => {
    getTasks();
  }, []); // eslint-disable-line

  return {tasks, isLoading, error, isError, setTasks, updateTasksData, updateDataCall};
};

type tJobTasksContext = ReturnType<typeof useJobTasks>;
const JobTasksContext = createContext<tJobTasksContext | null>(null);
JobTasksContext.displayName = "JobTasksContext";
export const JobTasksContextProvider: FC = ({children}) => (
  <JobTasksContext.Provider value={useJobTasks()}>{children}</JobTasksContext.Provider>
);

export const useContextJobTasks = () => {
  const context = useContext(JobTasksContext);

  if (context === null) {
    throw new Error("<<< JobTasksContext >>> must be used within a JobTasksContextProvider");
  }

  return context as tJobTasksContext;
};
