import {FC, useState} from "react";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextContacts} from "pages/Contacts/context/ContactsContext";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {getDateFormat} from "helpers/dates";
import {companies} from "api/contacts/companies";
import {eventBus} from "EventBus/EventBus";

interface iDeletePersonProps {
  id: number;
  created?: {
    date?: string | null;
    name?: string;
  };
}

export const DeleteCompany: FC<iDeletePersonProps> = ({id, created}) => {
  const {companies: {getCompaniesList}} = useContextContacts(); // prettier-ignore
  const [confirmDelete, setConfirmDelete] = useState(false);
  const closeConfirmDeleteWindow = () => setConfirmDelete(false);
  const {call, isLoading} = useHttp();

  const deleteCompany = async () => {
    if (!id) return;
    try {
      const response = await call(companies.delete(id));
      await getCompaniesList();
      eventBus.dispatch("showToast", {text: response?.data?.message});
    } catch (err: any) {
      eventBus.dispatch("showToast", {type: "error", text: err?.data?.message});
    }
  };

  const onDeleteCompany = () => setConfirmDelete(true);

  return (
    <>
      <div className="CompanyForm-footer">
        <p className="CompanyForm-info">
          Created {created?.date && `on ${getDateFormat(created.date, "MMM DD, YYYY")} `}
          {created?.name && `by ${created.name}`}{" "}
        </p>
        <button type="button" className="CompanyForm-footer-delete" onClick={onDeleteCompany}>
          Delete Company
        </button>
      </div>

      <ConfirmWindow
        title="Do you really want to delete company?"
        show={confirmDelete}
        confirmBtn={{text: "Delete", color: "red"}}
        cancelBtn={{text: "Cancel"}}
        isLoading={isLoading}
        onClose={closeConfirmDeleteWindow}
        onConfirm={deleteCompany}
      />
    </>
  );
};
