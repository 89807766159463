import {FC} from "react";
import {Checkbox} from "components/shared/FormComponents/Checkbox/Checkbox";
import {uid} from "helpers/misc";
import {Tooltip} from "components/shared/Tooltip/Tooltip";
import {trackApi} from "api/budget/track";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextTrack} from "../../_context/TrackContext";
import {Loading} from "components/shared/Loading/Loading";
import {eventBus} from "EventBus/EventBus";
import {useContextExpenseList} from "../../ExpenseList/context/ExpenseListContext";
import {useResponseType} from "../useResponseType";
import "./QuickPay.scss";

type tQuickPayProps = {
  className?: string;
  id: number;
  value: boolean;
};

export const QuickPay: FC<tQuickPayProps> = ({className = "", id, value}) => {
  const {call, isLoading} = useHttp();
  const {setTrackData} = useContextTrack();
  const {setExpenseList} = useContextExpenseList();
  const {isExpense, response_type} = useResponseType();

  const onChange = async () => {
    try {
      const res = await call(trackApi.setQBState(id, {state: !value, response_type}));
      isExpense && setExpenseList(res?.data?.data);
      !isExpense && setTrackData(res?.data?.data);
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const uniqId = uid();

  return (
    <Tooltip message="Quick pay" position="top" classname={`${className} QuickPay posRelative`}>
      {isLoading && <Loading type="inBlock" customSize={0.5} />}

      <Checkbox label={uniqId} checked={value} onChange={onChange} className="QuickPay-checkbox" />
    </Tooltip>
  );
};
