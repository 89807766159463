import {FC, useEffect, useState} from "react";
import {tCategoryCost, tEstimateType} from "api/budget/estimate/types";
import {tBudgetTypesReturn} from "../useBudgetTypes";
import {CreatingLine} from "../CreatingLine/CreatingLine";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {useHttp} from "hooks/httpServices/useHttp";
import {estimateApi} from "api/budget/estimate";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {Loading} from "components/shared/Loading/Loading";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {BudgetTypesEditLine} from "../BudgetTypesEditLine/BudgetTypesEditLine";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";

import "./BudgetTypeCostsList.scss";

interface iBudgetTypeCostsListProps {
  categoryId: number;
  data: tCategoryCost[];
  setData: tBudgetTypesReturn["setCosts"];
  onSetStep: tBudgetTypesReturn["setStepData"];
  title: string;
  currentBudgetType: tEstimateType | undefined;
  setBudgetTypeList: tBudgetTypesReturn["setBudgetTypeList"];
}

type tModal = "removeCategory" | "removeCost" | "renameCost";

export const BudgetTypeCostsList: FC<iBudgetTypeCostsListProps> = ({
  categoryId,
  onSetStep,
  setData,
  data,
  title,
  setBudgetTypeList,
  currentBudgetType
}) => {
  const getCostsQuery = useHttp();
  const renameCostQuery = useHttp();
  const createCostQuery = useHttp();
  const deleteCostQuery = useHttp();
  const deleteCategoryQuery = useHttp();

  const [creating, setCreating] = useState(false);
  const [showModal, setShowModal] = useState<tModal | null>(null);
  const [activeCostLine, setActiveCostLine] = useState<Partial<tCategoryCost> | null>(null);

  const name = useTextInput();

  const onAddLine = () => setCreating(true);

  useEffect(() => {
    getCostsQuery.call(estimateApi.getCategoryCosts(categoryId)).then(({data: {data}}) => {
      setData(prev => ({...prev, [categoryId]: data}));
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (activeCostLine?.name) {
      name.setValue(activeCostLine?.name);
    }
  }, [activeCostLine?.name]); // eslint-disable-line

  const onRename = async () => {
    if (!activeCostLine?.id || !name.checkValidity()) return;

    try {
      const {
        data: {message}
      } = await renameCostQuery.call(
        estimateApi.renameEstimateCost(activeCostLine.id, {name: name.value})
      );
      /// Director
      setData(prev => ({
        ...prev,
        [categoryId]: prev[categoryId].map(k =>
          k.id === activeCostLine.id ? {...k, name: name.value} : k
        )
      }));
      eventBus.dispatch("showToast", {text: message});
      closeModal();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const onCreateCostLine = async (payload: {name: string; code: string}) => {
    try {
      const {
        data: {data: costLine, message}
      } = await createCostQuery.call(estimateApi.addNewCost(categoryId, payload));
      setData(prev => ({
        ...prev,
        [categoryId]: [...prev[categoryId], costLine]
      }));

      setCreating(false);
      eventBus.dispatch("showToast", {text: message});
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const onDeleteCostLine = async () => {
    if (!activeCostLine?.id) return;
    try {
      const {
        data: {message}
      } = await deleteCostQuery.call(estimateApi.deleteEstimateCost(activeCostLine.id));
      setData(prev => ({
        ...prev,
        [categoryId]: prev[categoryId].filter(line => line.id !== activeCostLine.id)
      }));
      eventBus.dispatch("showToast", {text: message});
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    } finally {
      closeModal();
    }
  };

  const onDeleteCategory = async () => {
    try {
      const {
        data: {message}
      } = await deleteCategoryQuery.call(estimateApi.removeEstimateCategory(categoryId));
      setBudgetTypeList(prevState =>
        prevState.map(estimate =>
          estimate.id === currentBudgetType?.id
            ? {
                ...estimate,
                categories: estimate.categories.filter(cat => cat.id !== categoryId)
              }
            : estimate
        )
      );
      setData(prev => {
        const newData = prev;
        delete newData[categoryId];
        return newData;
      });
      onSetStep(prev => ({...prev, step: "categories"}));
      eventBus.dispatch("showToast", {text: message});
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    } finally {
      closeModal();
    }
    //
  };

  const onConfirmDelete = (id: number) => {
    setActiveCostLine({id});
    setShowModal("removeCost");
  };

  const onEdit = (line: tCategoryCost) => {
    setActiveCostLine(line);
    setShowModal("renameCost");
  };

  const closeModal = () => {
    setShowModal(null);
    setActiveCostLine(null);
  };

  return (
    <>
      <div className="BudgetTypeCostsList">
        {!data && <Loading type="inBlock" />}
        <TextComponent className="BudgetTypeCostsList-title" weight="700" size="15">
          {title}
        </TextComponent>

        {!data?.length && (
          <TextComponent className="BudgetTypeCategoryList-warning" textAlign="center">
            Category hasn't any line items yet
          </TextComponent>
        )}

        {data?.map(cat => {
          return (
            <BudgetTypesEditLine
              key={cat.id}
              code={cat.code}
              id={cat.id}
              name={cat.name}
              onDelete={onConfirmDelete}
              onEdit={onEdit}
            />
          );
        })}
        {creating && (
          <CreatingLine
            onSave={onCreateCostLine}
            placeholder="Line Item Name"
            onRemove={setCreating}
            isLoading={createCostQuery.isLoading}
          />
        )}
        {!creating && (
          <Button
            onClick={onAddLine}
            className="BudgetTypes-addBtn"
            size="sm"
            width="fullWidth"
            color="outline"
          >
            <Icon icon="add" size="xsm" />
            Line Item
          </Button>
        )}
        <Button onClick={() => setShowModal("removeCategory")} className="ButtonDelete">
          DELETE CATEGory
        </Button>
      </div>

      <ConfirmWindow
        isLoading={renameCostQuery.isLoading}
        onClose={closeModal}
        show={showModal === "renameCost"}
        title="Edit cost line"
        onConfirm={onRename}
        cancelBtn={{
          text: "cancel",
          onClick: closeModal
        }}
        confirmBtn={{
          text: "save"
        }}
      >
        <InputField {...name.inputProps} errors={name.errors} />
      </ConfirmWindow>

      <ConfirmWindow
        isLoading={deleteCategoryQuery.isLoading}
        onClose={closeModal}
        show={showModal === "removeCategory"}
        title="Delete category?"
        warning="This category and its line items will be permanently deleted."
        onConfirm={onDeleteCategory}
        cancelBtn={{
          text: "cancel",
          onClick: closeModal
        }}
        confirmBtn={{
          text: "remove",
          color: "red"
        }}
      />
      <ConfirmWindow
        isLoading={deleteCostQuery.isLoading}
        onClose={closeModal}
        show={showModal === "removeCost"}
        title="Delete cost line?"
        warning="This cost line will be permanently deleted."
        onConfirm={onDeleteCostLine}
        cancelBtn={{
          text: "cancel",
          onClick: closeModal
        }}
        confirmBtn={{
          text: "remove",
          color: "red"
        }}
      />
    </>
  );
};
