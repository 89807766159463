import {FC, Dispatch, SetStateAction} from "react";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import "./JobFilesListHeader.scss";

interface iJobFilesListHeaderProps {
  onSort: Dispatch<SetStateAction<"asc" | "desc">>;
  sortBy: "asc" | "desc";
}

export const JobFilesListHeader: FC<iJobFilesListHeaderProps> = ({sortBy, onSort}) => {
  const handleClick = () => (sortBy === "asc" ? onSort("desc") : onSort("asc"));
  const icon = sortBy === "asc" ? "arrow-up" : "arrow-down";

  return (
    <div className="JobFilesListHeader">
      <Button className="JobFilesListHeader-button" onClick={handleClick}>
        <span>Name</span>
        <Icon icon={icon} />
      </Button>
    </div>
  );
};
