import {AxiosRequestConfig} from "axios";
import {
  tAddDeliveryPostData,
  tCallSheetInfoPostData,
  tCallSheetPostData,
  tResendDeliveryPayload,
  tUpdateDeliveryPostData
} from "./types";

const jobCallSheet = "job_call_sheet";
const jobCallSheetDelivery = "job_call_sheet_delivery";
const callSheetConfirmation = "call_sheet_confirmation";

export const callSheet = {
  getCallSheetList: (jobId: number): AxiosRequestConfig => ({
    method: "GET",
    url: `job/${jobId}/call_sheets`
  }),
  getCallSheet: (callSheetId: number): AxiosRequestConfig => ({
    method: "GET",
    url: `${jobCallSheet}/${callSheetId}`
  }),
  updateCallSheet: (callSheetId: number, data: tCallSheetInfoPostData): AxiosRequestConfig => ({
    method: "POST",
    url: `${jobCallSheet}/${callSheetId}`,
    data
  }),
  uploadImage: (callSheetId: number, data: {image: string}): AxiosRequestConfig => ({
    method: "POST",
    url: `${jobCallSheet}/${callSheetId}/upload-image`,
    data
  }),
  deleteCallSheet: (callSheetId: number): AxiosRequestConfig => ({
    method: "DELETE",
    url: `${jobCallSheet}/${callSheetId}`
  }),
  create: (data: tCallSheetPostData): AxiosRequestConfig => ({
    method: "POST",
    url: jobCallSheet,
    data
  }),
  duplicateCallSheet: (callSheetId: number, data: {name: string}): AxiosRequestConfig => ({
    method: "POST",
    url: `${jobCallSheet}/${callSheetId}/duplicate`,
    data
  }),
  saveToDropbox: (callSheetId: number, data: {file: any}): AxiosRequestConfig => ({
    headers: {"Content-Type": "multipart/form-data"},
    method: "POST",
    url: `${jobCallSheet}/${callSheetId}/save_to_dropbox`,
    data
  }),
  //  Call sheets delivery
  getLatestDelivery: (jobCallSheetId: number): AxiosRequestConfig => ({
    url: `${jobCallSheet}/${jobCallSheetId}/delivery`
  }),
  addDelivery: (jobCallSheetId: number, data: tAddDeliveryPostData): AxiosRequestConfig => ({
    method: "POST",
    url: `${jobCallSheet}/${jobCallSheetId}/delivery`,
    data
  }),
  updateDelivery: (
    jobCallSheetDeliveryId: number,
    data: tUpdateDeliveryPostData
  ): AxiosRequestConfig => ({
    method: "POST",
    url: `${jobCallSheetDelivery}/${jobCallSheetDeliveryId}`,
    data
  }),
  unscheduleDelivery: (job_call_sheet_id: number): AxiosRequestConfig => ({
    method: "POST",
    url: `${jobCallSheetDelivery}/${job_call_sheet_id}/unschedule`
  }),
  getDeliveryConfirmationInfo: (confirmation_id: string): AxiosRequestConfig => ({
    url: `${callSheetConfirmation}/${confirmation_id}`
  }),
  confirmDelivery: (confirmation_id: string): AxiosRequestConfig => ({
    method: "POST",
    url: `${callSheetConfirmation}/${confirmation_id}`
  }),
  getCallSheetLogs: (jobCallSheetId: number): AxiosRequestConfig => ({
    url: `${jobCallSheet}/${jobCallSheetId}/delivery_logs`
  }),
  getCallSheetsDelivery: (jobCallSheetDeliveryId: number): AxiosRequestConfig => ({
    url: `job_call_sheet/delivery/${jobCallSheetDeliveryId}`
  }),
  resendDeliveryByContact: ({
    deliveryId,
    contactId,
    data
  }: tResendDeliveryPayload): AxiosRequestConfig => ({
    method: "POST",
    url: `${jobCallSheet}/delivery/${deliveryId}/${contactId}`,
    data
  }),
  // WARNING
  // Not for using on the client side
  // system use only
  getDeliveryConfirmationByType: (
    confirmation_id: number,
    type: "google" | "ical"
  ): AxiosRequestConfig => ({
    url: `${callSheetConfirmation}/${confirmation_id}/${type}`
  }),
  // ==============================
  //FIXME seems like unneeded
  downloadCallSheetImage: (jobCallSheetId: number): AxiosRequestConfig => ({
    method: "GET",
    url: `${jobCallSheet}/${jobCallSheetId}/download-image`,
    responseType: "blob"
  })
};
