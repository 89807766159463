import {FC, useState, MouseEvent} from "react";
import {routes} from "router/routes";
import {generatePath} from "react-router";
import {ImgWithFallback} from "components/shared/ImgWithFallback/ImgWithFallback";
import {Link} from "react-router-dom";
import folderImg from "assets/images/db_folder.svg";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {Button} from "components/shared/Button/Button";
import {useHttp} from "hooks/httpServices/useHttp";
import {jobFilesApi} from "api/jobs/files";
import {useContextJobDetails} from "../../../context/JobDetailsContext/JobDetailsContext";
import {Loading} from "components/shared/Loading/Loading";
import {eventBus} from "EventBus/EventBus";
import "./JobFilesListItem.scss";

interface iJobFilesListItemProps {
  data: any;
}

export const JobFilesListItem: FC<iJobFilesListItemProps> = ({data}) => {
  const {jobDetailsData} = useContextJobDetails();
  const getSharedUrlQuery = useHttp();
  const downloadFileQuery = useHttp();

  const [isDownloading, setIsDownloading] = useState(false);

  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const isFolder = data[".tag"] === "folder";

  // const pathParams = data?.path_lower?.split("/")?.splice(2)?.join("/");
  const pathParams = data?.path_lower;
  const directoryPath = data?.path_lower?.split("/")?.at(-1);

  const itemClassname = isFolder ? "JobFilesListItem-folder" : "JobFilesListItem-file";

  const showMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };

  const openInDropbox = async () => {
    closeMenu();
    try {
      const response = await getSharedUrlQuery.call(
        jobFilesApi.getSharedUrl(jobDetailsData!.id, {path: pathParams})
      );
      window.open(response.data.data.url, "_blank", "noopener,noreferrer");
    } catch (error: any) {
      eventBus.dispatch("showToast", {type: "error", text: error.response.data.message});
    }
  };

  const downloadFile = async () => {
    closeMenu();
    setIsDownloading(true);
    try {
      const {data: file} = await downloadFileQuery.call(
        jobFilesApi.downloadFileFromDropbox(jobDetailsData!.id, {path: pathParams})
      );

      const blob = new Blob([file]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      link.download = `${data.name}.${data.name.split(".")?.at(-1)}`;
      link.click();
      link.remove();
    } catch (error: any) {
      console.log(error);
      eventBus.dispatch("showToast", {
        type: "error",
        text: error?.message || error.response.data.message
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const closeMenu = () => setAnchor(null);

  if (isFolder) {
    return (
      <Link
        to={generatePath(routes.jobs.details.files.directory, {
          path: directoryPath
        })}
        className={`JobFilesListItem ${itemClassname}`}
      >
        <ImgWithFallback className="JobFilesListItem-img" src={folderImg} alt="dropbox folder" />
        <span>{data.name}</span>
      </Link>
    );
  }
  const isLoading = getSharedUrlQuery.isLoading || isDownloading;
  return (
    <div className={`JobFilesListItem ${itemClassname}`}>
      {isLoading && <Loading type="inBlock" />}
      <span className="JobFilesListItem-text">{data.name}</span>

      <button onClick={showMenu} className="JobFilesListItem-actionButton">
        ...
      </button>
      <DropdownMenu anchor={anchor} onClose={closeMenu}>
        <Button
          size="sm"
          onClick={downloadFile}
          width="fullWidth"
          justifyContent="justifyContentStart"
        >
          download
        </Button>
        <Button
          size="sm"
          onClick={openInDropbox}
          width="fullWidth"
          justifyContent="justifyContentStart"
        >
          open in dropbox
        </Button>
      </DropdownMenu>
    </div>
  );
};
