import {useDatePicker} from "components/shared/FormComponents/Datepicker/useDatePicker";
import {useCheckBoxGroupe} from "hooks/inputHooks/useCheckBoxGroupe";
import {useRadio} from "hooks/useRadio/useRadio";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {iUseTeamMember} from "./interfaces";

export const useTeamMemberForm = (props: iUseTeamMember) => {
  const {roleList, officeLocationList, initData} = props;
  const dep = initData?.departments.map(item => item?.id) || [];

  const formData = {
    first_name: useTextInput({
      value: initData?.first_name || "",
      validators: ["name"],
      filters: ["name"]
    }),
    last_name: useTextInput({
      value: initData?.last_name || "",
      validators: ["name"],
      filters: ["name"]
    }),
    email: useTextInput({value: initData?.email || "", validators: ["email"]}),
    title: useTextInput({value: initData?.title || "", isRequired: false}),
    departments: useCheckBoxGroupe({isRequired: true, initValue: dep}),
    role_id: useRadio({
      isRequired: true,
      value: initData?.role?.id ? String(initData?.role?.id) : "",
      fields: roleList?.map(({id, name}) => ({label: name, name: "role_id", value: String(id)}))
    }),
    office_location_id: useRadio({
      isRequired: true,
      value: initData?.office_location?.id ? String(initData?.office_location?.id) : "",
      fields: officeLocationList?.map(({id, name}) => ({label: name, name: "officeLocation", value: String(id)})) // prettier-ignore
    }),

    start_date: useDatePicker({initValue: initData?.start_date || "", isRequired: true}),
    notes: useTextInput({isRequired: false, value: initData?.notes || ""})
  };

  return formData;
};
