import {createContext, FC, useContext} from "react";
import {useJobCalendar} from "./useJobCalendar";

export type tJobCalendarContext = ReturnType<typeof useJobCalendar>;

const JobCalendarContext = createContext<tJobCalendarContext | null>(null);
JobCalendarContext.displayName = "JobCalendarContext";

export const JobCalendarContextProvider: FC = ({children}) => {
  const value = useJobCalendar();
  return <JobCalendarContext.Provider value={value}>{children}</JobCalendarContext.Provider>;
};

export const useContextJobCalendar = () => {
  const context = useContext(JobCalendarContext);

  if (context === null) {
    throw new Error("<<< JobCalendarContext >>> must be used within a JobCalendarContextProvider");
  }

  return context as tJobCalendarContext;
};
