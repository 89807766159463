import {FC, useEffect, useRef, useState} from "react";
import {Button} from "components/shared/Button/Button";
import {DropdownMenu} from "components/shared/DropdownMenu/DropdownMenu";
import {useContextJobCallSheet} from "pages/Jobs/JobDetails/JobCallSheets/context/JobCallSheetContext";
import {tCallSheetContact, tCallSHeetGroupContacts} from "api/callSheet/types";
import {generateTimeInterval} from "helpers/dates";

type tTimeCellProps = {
  group: tCallSHeetGroupContacts;
  contact: tCallSheetContact;
  setContactGroup: React.Dispatch<React.SetStateAction<tCallSHeetGroupContacts[] | undefined>>;
};

export const TimeCell: FC<tTimeCellProps> = ({group, contact, setContactGroup}) => {
  const {callSheetInfo} = useContextJobCallSheet();
  const refInput = useRef<HTMLInputElement>(null);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const showMenu = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    setMenuAnchor(event.currentTarget);
  };

  useEffect(() => {
    refInput.current && (refInput.current.value = contact?.time);
  }, []); // eslint-disable-line

  useEffect(() => {
    refInput.current && (refInput.current.value = contact?.time);
  }, [contact?.time]);

  const setTime = (time: string) => {
    refInput.current && (refInput.current.value = time);
    changeTime(time);
    refInput?.current?.focus();
    setMenuAnchor(null);
  };

  const onBlurHandler = () => {
    if (refInput?.current?.value === contact?.time) return;
    changeTime(refInput?.current?.value || "");
  };

  function changeTime(value: string) {
    setContactGroup(prev => {
      return prev?.map(item => {
        if (item?.id !== group?.id) return item;
        const newContacts = item?.contacts?.map(cont => {
          if (cont?.id === contact?.id) {
            return {
              ...cont,
              time: value
            };
          }
          return cont;
        });

        return {...item, contacts: newContacts};
      });
    });
  }

  const customTime =
    callSheetInfo?.attributes?.schedule
      ?.flat()
      ?.map(v => ({id: v.time, value: v.time, label: v.time})) || [];

  const times = [
    {id: "N/A", value: "N/A", label: "N/A"},
    ...customTime,
    ...generateTimeInterval({
      maxHour: 24,
      startTimeInMinutes: 6 * 60,
      interval: 30,
      format: "h"
    })
  ].filter(v => v.value || v.id);

  return (
    <div className="TimeCell">
      <input
        type="text"
        className="TimeCell-input"
        onFocus={showMenu}
        ref={refInput}
        placeholder="add time"
        onBlur={onBlurHandler}
      />

      <DropdownMenu anchor={menuAnchor} onClose={() => setMenuAnchor(null)}>
        {times.map(({id, value}) => {
          return (
            <Button key={id} size="sm" onClick={() => setTime(value)}>
              {value}
            </Button>
          );
        })}
      </DropdownMenu>
    </div>
  );
};
