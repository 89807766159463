import {useSetApiParams} from "hooks/useSetApiParams";
import {MouseEvent, useState} from "react";

// TODO need t improve
export const usePeopleTableRowFilters = (apiParams: ReturnType<typeof useSetApiParams>) => {
  const handleFilterLocale = (
    event?: MouseEvent<HTMLSpanElement, any>,
    tag?: {id: number; name: string}
  ) => {
    event?.stopPropagation();
    const params = {locales: [String(tag?.id)]};
    apiParams.setApiParams({...Object.fromEntries(apiParams?.searchParams), params});
  };

  const handleFilterLabels = (
    event?: MouseEvent<HTMLSpanElement, any>,
    tag?: {id: number; name: string}
  ) => {
    event?.stopPropagation();
    const params = {labels: [String(tag?.id)]};
    apiParams.setApiParams({...Object.fromEntries(apiParams?.searchParams), params});
  };

  const [companiesModal, setCompaniesModal] = useState<null | number>(null);
  const closeCompaniesModal = () => setCompaniesModal(null);
  const handleCompanyLocale = (
    event?: MouseEvent<HTMLSpanElement, any>,
    tag?: {id: number; name: string}
  ) => {
    event?.stopPropagation();
    setCompaniesModal(tag?.id || null);
  };

  return {
    handleFilterLocale,
    handleFilterLabels,
    handleCompanyLocale,
    companiesModal,
    closeCompaniesModal
  };
};
