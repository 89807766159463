export const getApiError = (error: any, formData?: any) => {
  const errors = error?.response?.data?.errors;
  const isErrors = errors && !!Object.keys(errors).length;

  isErrors &&
    Object.entries(errors).forEach(([key, value]) => {
      formData?.[key]?.setErrors(value as string[]);
    });

  return {
    msg: error?.response?.data?.message,
    errors: isErrors ? errors : null
  };
};
