import {Dispatch, SetStateAction, FC} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {CallSheetSenderForm} from "../../CallSheetSenderForm/CallSheetSenderForm";
import {callSheet} from "api/callSheet";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {iDeliveryInfo, tCallSheetContact, tResendDeliveryPayload} from "api/callSheet/types";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextJobCallSheet} from "../../context/JobCallSheetContext";

interface iCallSheetResendProps {
  data: tCallSheetContact;
  deliveryInfo: iDeliveryInfo;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

type tResendCallbackPostData = tResendDeliveryPayload["data"];

export const CallSheetResend: FC<iCallSheetResendProps> = ({data, deliveryInfo, setShowModal}) => {
  const {getDeliveryInfo} = useContextJobCallSheet();
  const {call, isLoading} = useHttp();

  const onResend = async (payload: tResendCallbackPostData) => {
    if (!data) return;
    try {
      const {
        data: {message}
      } = await call(
        callSheet.resendDeliveryByContact({
          deliveryId: deliveryInfo.id,
          contactId: String(data.id),
          data: payload
        })
      );
      setShowModal(false);
      const responseDelivery = await getDeliveryInfo();
      const confirmation = responseDelivery?.confirmations?.find(
        conf => String(conf.contact.id) === String(data?.id)
      );

      if (confirmation?.custom && !confirmation?.custom?.sent_time) {
        setTimeout(() => {
          getDeliveryInfo();
        }, 5000);
      }
      eventBus.dispatch("showToast", {text: message});
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {text: msg, type: "error"});
    }
  };

  return (
    <ModalPopup show={true} onClose={() => setShowModal(false)} withoutPaddings>
      <CallSheetSenderForm
        deliveryInfo={deliveryInfo}
        type="resend"
        isLoading={isLoading}
        formWrapProps={{
          headerTitle: `RESEND LATEST DELIVERY TO ${data?.name}`
        }}
        onSubmitCallback={onResend}
      />
    </ModalPopup>
  );
};
