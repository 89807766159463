import {FC, useEffect, useState} from "react";
import {useContextInvoice} from "../context/InvoiceContext";
// import {PdfViewer} from "components/shared/PdfViewer/PdfViewer";
import {Loading} from "components/shared/Loading/Loading";
import {ViewersWrap} from "components/Viewers/ViewersWrap/ViewersWrap";
import "./InvoicePreviewOverlay.scss";

export const InvoicePreviewOverlay: FC = () => {
  const {getPreviewPdf, track, invoice} = useContextInvoice();
  const [url, setUrl] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!track) return;
    getPreviewPdf()
      .then(data => {
        setUrl(data?.url);
      })
      .finally(() => setIsLoading(false));
  }, [track]); // eslint-disable-line

  return (
    <div className="InvoicePreviewOverlay">
      {isLoading && <Loading type="inBlock" />}
      {/* {url && <PdfViewer blobUrl={url} />} */}

      {url && (
        <ViewersWrap fullHeight>
          <ViewersWrap.Main>
            <ViewersWrap.Viewer file={{url, name: invoice?.job_name + ".pdf"}} pdf />
          </ViewersWrap.Main>
        </ViewersWrap>
      )}
    </div>
  );
};
