import {FC, useState} from "react";
import {Button} from "components/shared/Button/Button";
import {DropdownSubmenu} from "components/shared/DropdownMenu/DropdownSubmenu";
import {PayrollCompany} from "pages/Jobs/JobDetails/JobBudget/Track/_modals/payRollAndCreditCards/PayrollCompany/PayrollCompany";
import {tPayIdsItem} from "api/common/types";

type tPayPayrollProps = {
  payroll: tPayIdsItem;
  callBack: (id?: number) => void;
};

export const PayPayroll: FC<tPayPayrollProps> = ({payroll, callBack}) => {
  const [showModal, setModal] = useState(false);
  return (
    <>
      <DropdownSubmenu title="Payroll">
        {payroll?.target_items?.map(item => {
          return (
            <Button key={item?.id} size="sm" className="btn" onClick={() => callBack(item?.id)}>
              {item?.name}
            </Button>
          );
        })}

        <hr />
        <Button size="sm" className="btn" onClick={() => setModal(true)}>
          Edit payroll companies
        </Button>
      </DropdownSubmenu>

      {showModal && <PayrollCompany onClose={() => setModal(false)} payroll={payroll} />}
    </>
  );
};
