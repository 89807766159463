import {FC, FormEvent} from "react";
import {Datepicker} from "components/shared/FormComponents/Datepicker/Datepicker";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {FormWrap} from "components/shared/FormWrap/FormWrap";
import {TextComponent} from "../../common/TextComponent/TextComponent";
import {notValidForm} from "helpers/misc";
import {useCreateNewCallSheetForm} from "./useCreateNewCallSheetForm";
import {tCallSheetPostData} from "api/callSheet/types";
import {callSheet} from "api/callSheet";
import {useHttp} from "hooks/httpServices/useHttp";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {getDateFormat} from "helpers/dates";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useContextJobCallSheet} from "../context/JobCallSheetContext";
import {eventBus} from "EventBus/EventBus";
import {LocationDynamicInput} from "../CallSheetBuild/CallSheetBuildLocations/LocationDynamicInput/LocationDynamicInput";
import {useLocationDynamicInput} from "../CallSheetBuild/CallSheetBuildLocations/LocationDynamicInput/useLocationDynamicInput";
import {useNavigate} from "react-router-dom";

import "./CreateNewCallSheet.scss";

type tCreateNewCallSheetProps = {
  closeModal: () => void;
};

export const CreateNewCallSheet: FC<tCreateNewCallSheetProps> = ({closeModal}) => {
  const navigate = useNavigate();
  const {jobDetailsData} = useContextJobDetails();
  const {getCallSheetList} = useContextJobCallSheet();
  const FORM_ID = "create-new-call-sheet-form-id";
  const {form, locationList} = useCreateNewCallSheetForm();
  const {call, isLoading} = useHttp();
  const {
    values,
    setShowConfirmRemove,
    showConfirmRemove,
    onRemoveRow,
    onCreateNewRow,
    onChangeInputRow,
    emptyRowError,
    checkValidity
  } = useLocationDynamicInput();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!checkValidity() || notValidForm(form) || !jobDetailsData?.id) return;

    const payloadLocation = values
      ?.map(item => {
        delete item.errors;
        return item;
      })
      .filter(item => !!item?.location_id);

    try {
      const data: tCallSheetPostData = {
        job_id: jobDetailsData.id,
        name: form?.name?.value,
        date: getDateFormat(form?.date?.value, "timestamp"),
        attributes: {
          title: form?.title?.value,
          locations: payloadLocation
        }
      };
      const res = await call(callSheet.create(data));
      getCallSheetList(jobDetailsData.id);
      closeModal();
      eventBus.dispatch("showToast", {text: res?.data?.message || "Success!"});
      navigate(String(res?.data?.data?.id));
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <FormWrap
      idForm={FORM_ID}
      submitTitle="Generate call sheet"
      headerTitle="New call sheet"
      type="titleCenterAndOneBtn"
      isLoading={isLoading}
    >
      <form className="CreateNewCallSheet" id={FORM_ID} onSubmit={onSubmit}>
        <TextComponent uppercase size="13">
          Name* (internal)
        </TextComponent>
        <InputField {...form.name.inputProps} errors={form.name.errors} />

        <TextComponent uppercase size="13">
          Call sheet details
        </TextComponent>
        <div className="CreateNewCallSheet-twoColumnSection">
          <InputField label="Title*" {...form.title.inputProps} errors={form.title.errors} />
          <Datepicker
            {...form.date.inputProps}
            label="Date*"
            placeholderText="M/DD/yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            errors={form.date.errors}
            dateFormat="M/DD/yyyy"
            withIcon
            isPortal
          />
        </div>

        <TextComponent uppercase size="13">
          Locations
        </TextComponent>

        <LocationDynamicInput
          values={values}
          emptyRowError={emptyRowError}
          onChangeInputRow={onChangeInputRow}
          onCreateNewRow={onCreateNewRow}
          onRemoveRow={onRemoveRow}
          setShowConfirmRemove={setShowConfirmRemove}
          showConfirmRemove={showConfirmRemove}
          options={locationList?.current || []}
        />
      </form>
    </FormWrap>
  );
};
