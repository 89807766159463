import {useEffect, useState} from "react";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {tCurrencyRates} from "api/common/types";
import {useHttp} from "hooks/httpServices/useHttp";
import {common} from "api/common";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";
import {iCurrencyRatesProps} from "./CurrencyRates";
import {estimateApi} from "api/budget/estimate";
import {trackApi} from "api/budget/track";

interface iUseCurrencyRatesProps {
  type: iCurrencyRatesProps["type"];
  templateId?: number;
  jobId?: number;
  estimateID?: number;
  customList?: tCurrencyRates[];
  updateCallback?: (arg: any) => void;
}

export const CODE_EXIST_ERR = "code exist";
export const EMPTY_CURRENCY = "empty currency";

export function useCurrencyRates({
  type,
  templateId,
  customList,
  updateCallback,
  jobId
}: iUseCurrencyRatesProps) {
  const [currency, setCurrency] = useState<tCurrencyRates[]>([]);

  const {isLoading, call} = useHttp();
  const {call: updateCall, isLoading: submitting} = useHttp();
  const [confirmDelete, setConfirmDelete] = useState("");

  const getList: Record<typeof type, any> = {
    default: common.getCurrencyRates(),
    estimateTemplate: estimateApi.getEstimateTemplateCurrencyList(templateId || 0),
    trackCurrency: estimateApi.getEstimateTemplateCurrencyList(jobId || 0)
  };

  useEffect(() => {
    if (!!customList) return setCurrency(customList);
    call(getList[type]).then(res => {
      setCurrency(res?.data?.data);
    });
  }, []); // eslint-disable-line

  const currencyData = {
    currency: useTextInput(),
    code: useTextInput({filters: ["maxLengthTree"]}),
    exchange_rate: useTextInput({filters: ["currencyFilter"]}),
    hedge_percent: useTextInput({filters: ["percent99", "onlyDigits"]})
  };

  const generatePostData = () => {
    const {code, exchange_rate, hedge_percent, currency} = currencyData;
    return {
      currency: currency.value,
      code: code.value, //should be unique
      exchange_rate: +exchange_rate.value,
      hedge_percent: +hedge_percent.value
    };
  };

  // const isValid = () => Object.values(currencyData).every(v => !!v.value);
  const isValid = () => currencyData?.code?.value && currencyData?.currency?.value;
  const isNewRow = () => Object.values(currencyData).some(v => !!v.value);
  const clearRow = () => Object.values(currencyData).forEach(v => v.setValue(""));
  const isExistCode = () => {
    const isExistCode = currency?.find(
      item => item?.code?.toUpperCase() === generatePostData()?.code?.toUpperCase()
    );

    if (isExistCode) {
      currencyData?.code?.setErrors([CODE_EXIST_ERR]);
      return eventBus.dispatch("showToast", {
        type: "error",
        text: `Code "${generatePostData()?.code?.toUpperCase()}" already exist!`
      });
    }

    return !!isExistCode;
  };

  const onAddCurrency = () => {
    if (!isValid()) {
      return eventBus.dispatch("showToast", {
        type: "error",
        text: "Currency and code fields should be filled!"
      });
    }
    // if (!currencyData?.code?.value) {
    //   currencyData?.code?.setErrors([EMPTY_CURRENCY]);
    //   return eventBus.dispatch("showToast", {
    //     type: "error",
    //     text: "Code fields should be filled!"
    //   });
    // }
    if (isExistCode()) return;

    setCurrency(prev => [...prev, generatePostData()]);
    clearRow();
  };

  const updateList = (typeApi: typeof type, data: tCurrencyRates[]) => {
    const newData = [...data];

    if (isNewRow()) {
      newData.push(generatePostData());
    }

    const apiTypes = {
      default: common.editCurrencyRates(newData),
      estimateTemplate: estimateApi.editEstimateTemplateCurrencyList(templateId || 0, newData),
      trackCurrency: trackApi.setTrackCurrencyList(jobId || 0, newData)
    };

    return apiTypes[typeApi];
  };

  const onSave = async () => {
    if (isNewRow() && !isValid()) {
      // return eventBus.dispatch("showToast", {type: "error", text: "All field should be filled!"});
      return eventBus.dispatch("showToast", {
        type: "error",
        text: "Currency and code fields should be filled!"
      });
    }

    if (isNewRow() && isExistCode()) return;

    try {
      const res = await updateCall(updateList(type, currency));
      setCurrency(res?.data?.data);
      updateCallback?.(res?.data?.data);
      eventBus.dispatch("showToast", {type: "success", text: res?.data?.message || "Success"});
      clearRow();
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const onDeleteCurrency = async () => {
    const data = currency?.filter(item => item.currency !== confirmDelete);
    try {
      const res = await updateCall(updateList(type, data));
      setCurrency(res?.data?.data);
      updateCallback?.(res?.data?.data);
      eventBus.dispatch("showToast", {type: "success", text: res?.data?.message || "Success"});
      clearRow();
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    } finally {
      setConfirmDelete("");
    }
  };

  const hasNewCurrency = currency.length;

  return {
    hasNewCurrency,
    updateList,
    onSave,
    currency,
    onAddCurrency,
    currencyData,
    isLoading,
    submitting,
    onDeleteCurrency,
    confirmDelete,
    setConfirmDelete,
    setCurrency
  };
}
