import {FC, InputHTMLAttributes} from "react";
import "./RadioField.scss";

export interface iRadioItemProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
}

export interface iRadioFieldProps {
  fields: iRadioItemProps[];
  className?: string;
  title?: string;
  value?: string;
  errors?: string[];
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  disabled?: boolean;
}

export const RadioField: FC<iRadioFieldProps> = ({
  className = "",
  value,
  title,
  fields,
  errors = [],
  disabled = false,
  ...props
}) => {
  return (
    <div className={`RadioField ${className} ${disabled ? "disabled" : ""}`}>
      {title && <p className="RadioField-title title">{title}</p>}

      <div className="RadioField-fields">
        {fields?.map(({name, value: fieldValue, label}, index) => {
          const isCheckedClass = value === fieldValue ? "RadioField-checked" : "";
          const uuid = fieldValue + name;
          return (
            <div key={index} className={isCheckedClass}>
              <input
                {...props.inputProps}
                type="radio"
                name={name}
                value={fieldValue}
                id={uuid}
                checked={value === fieldValue}
                className="RadioField-input input"
                disabled={disabled}
              />
              <label htmlFor={uuid} className="RadioField-label label">
                <span className="RadioField-flag" />
                <span>{label}</span>
              </label>
            </div>
          );
        })}
      </div>
      {errors.map(item => {
        return (
          <p key={item} className="RadioField-error-message">
            {item}
          </p>
        );
      })}
    </div>
  );
};

/*

  <RadioField
    {...formData.permit_required}
    title="Permit REQUIRED"
    errors={formData.permit_required.errors}
  />

 */
