import {FC} from "react";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {Title} from "../common/Title";
import {ContactBlock} from "../common/ContactBlock";
import {LocationCard} from "../common/LocationCard";

interface iLocationsProps {
  [key: string]: any;
}

export const Locations: FC<iLocationsProps> = () => {
  const {jobDetailsData} = useContextJobDetails();
  if (!jobDetailsData) return null;
  const {locations} = jobDetailsData;

  if (!locations?.length) {
    return (
      <div className="JobOverview-section">
        <Title text="Locations" fullWidth />
        <ContactBlock placeholder="Locations will appear here" fulWidth />
      </div>
    );
  }

  return (
    <div className="JobOverview-section">
      <Title text="Locations" fullWidth />

      {locations?.map(item => {
        return (
          <LocationCard
            key={item?.id}
            title={item?.name}
            address={item.address}
            imgUrl={item?.photo_url || ""}
            id={item?.id}
            locationType={item?.location_type?.name || ""}
          />
        );
      })}
    </div>
  );
};
