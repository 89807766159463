export type tParsingDataItemModel = typeof parsingDataItemModel;
export const parsingDataItemModel = {
  companies: {
    name: "",
    address: {
      raw: ""
    },
    notes: "",
    locales: [],
    labels: [],
    website: "",
    phones: [],
    emails: [],
    accounts: [], // value: "", platform: facebook, instagram, linkedin, twitter,
    departments: [],
    billing_info: {
      first_name: "",
      last_name: "",
      billing_email: "",
      billing_phone: "",
      address: {
        raw: ""
      }
    },
    preferred: false,
    custom_fields: []
  },
  people: {
    first_name: "",
    last_name: "",
    middle_name: "",
    legal_name: "",
    notes: "",
    locales: [],
    labels: [],
    phones: [],
    emails: [],
    companies: [],
    agencies: [],
    accounts: [], // value: "", platform: facebook, instagram, linkedin, twitter,
    address: {
      raw: ""
    },
    billing_info: {
      first_name: "",
      last_name: "",
      billing_email: "",
      billing_phone: "",
      address: {
        raw: ""
      }
    },
    team: [], // user_id, user_relationship_id
    rate: "",
    billing_default: "",
    pronoun_id: "",
    versatile_relationship_id: "",
    custom_fields: []
  },
  locations: {
    photo: "",
    name: "",
    rate: "",
    permit_required: false,
    website: "",
    location_type_id: "",
    address: {
      raw: ""
    },
    notes: "",
    contacts: [
      {
        person_id: "",
        title_id: ""
      }
    ],
    locales: []
  }
};
