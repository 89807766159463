import {FC} from "react";
import {Button} from "components/shared/Button/Button";
import {ImgWithFallback} from "components/shared/ImgWithFallback/ImgWithFallback";
import {steps} from "./steps";
import "./OnboardingStep.scss";

type tOnboardingStepProps = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  updateStep: () => void;
  step: number;
  isLoading: boolean;
};

export const OnboardingStep: FC<tOnboardingStepProps> = ({
  setStep,
  step,
  updateStep,
  isLoading
}) => {
  return (
    <div className="OnboardingStep">
      <div className="OnboardingStep-header">
        {steps[step]?.icons?.map(icon => {
          return <ImgWithFallback src={icon} className="icon" />;
        })}

        {steps[step]?.title && <h2 className="title">{steps[step]?.title}</h2>}
      </div>

      <div className="OnboardingStep-body">{steps[step]?.body}</div>

      <Button
        size="md"
        onClick={updateStep}
        color="lightHoverLight"
        className="OnboardingStep-changeStepBtn"
        loading={isLoading}
      >
        {steps[step]?.buttonTitle}
      </Button>

      <div className="OnboardingStep-stepMarkers">
        {steps?.map((_, i) => {
          const active = i === step ? "active" : "";
          return (
            <Button key={i} className={`dot ${active}`} onClick={() => setStep(i)}>
              &#183;
            </Button>
          );
        })}
      </div>
    </div>
  );
};
