import {FC} from "react";
import {useContextJobTasks} from "../_context/JobTaskContext";
import {Table} from "components/Table/Table";
import {TableHeadItem} from "components/Table/TableHeadItem";
import {jobTasksTableColumns} from "./jobTasksTableColumns";
import {TableGroup} from "./TableGroup/TableGroup";
import {Loading} from "components/shared/Loading/Loading";
import {useOffsetTop} from "hooks/useOffsetTop";
import "./JobTaskTable.scss";

type tJobTaskTableProps = {
  [key: string]: any;
};

export const JobTaskTable: FC<tJobTaskTableProps> = () => {
  const {tasks, isLoading} = useContextJobTasks();

  const {elementRef, offsetTop} = useOffsetTop();

  const renderHeaderList = () =>
    jobTasksTableColumns.map(item => <TableHeadItem key={item.id} data={item} />);

  const renderBodyList = () => {
    return tasks?.groups?.map(item => {
      return <TableGroup key={item.id} data={item} />;
    });
  };

  return (
    <div className="JobTaskTable" ref={elementRef} style={{height: `calc(100vh - ${offsetTop}px)`}}>
      {isLoading && <Loading type="inBlock" bgColor="transparent" />}

      {!!tasks?.groups?.length && (
        <Table headerRowChildren={renderHeaderList()} bodyChildren={renderBodyList()} />
      )}
    </div>
  );
};
