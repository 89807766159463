export const tableTrackWAColumns = [
  {
    id: "firstCol",
    label: ""
  },
  {
    id: "estimate",
    label: "Estimate"
  },
  {
    id: "qty",
    label: "QTY"
  },
  {
    id: "cost",
    label: "Cost"
  },
  {
    id: "time",
    label: "Time"
  },
  {
    id: "budget",
    label: "Budget"
  },
  {
    id: "working",
    label: "Working total"
  },
  {
    id: "actual",
    label: "Actual total"
  },
  {
    id: "difference",
    label: "Difference"
  },
  {
    id: "w8",
    label: "W8"
  },
  {
    id: "w9",
    label: "W9"
  },
  {
    id: "ach",
    label: "ACH"
  },
  {
    id: "wire",
    label: "WIRE"
  },
  {
    id: "actions",
    label: ""
  }
];
