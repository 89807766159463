import {FC, useState} from "react";
import moment from "moment";
import {tCrewContact} from "api/jobCrewGroup/types";
import {getDateFormat, getDateRange} from "helpers/dates";
import {DateRangePicker} from "components/shared/FormComponents/DateRangePicker/DateRangePicker";
import {useDateRangePicker} from "hooks/useDateRangePicker";
import {notValidForm} from "helpers/misc";
import {getHoldColor} from "pages/Jobs/JobDetails/JobCrew/commonJobCrew/getHoldColor";
import {SelectCell} from "../_common/SelectCell/SelectCell";
import {useChangeCrewValue} from "../_common/useChangeCrewValue";
import "./RowDates.scss";

type tRowDatesProps = {
  crewContact: tCrewContact;
};

export const RowDates: FC<tRowDatesProps> = ({crewContact}) => {
  const {hold} = crewContact;

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const {changeValue, isLoading} = useChangeCrewValue();

  const formData = {
    dates: useDateRangePicker({
      startDate: !!hold?.start_at ? String(hold?.start_at) : undefined,
      endDate: hold?.end_at ? String(hold?.end_at) : undefined
    })
  };

  const onClose = () => {
    const {startDate, endDate} = formData.dates;
    const isValid = !moment(startDate).isSame(hold?.start_at) || !moment(endDate).isSame(hold?.end_at); // prettier-ignore
    isValid && onSubmit();
    !isValid && setMenuAnchor(null);
  };

  const onSubmit = async () => {
    if (notValidForm(formData)) return;
    const data = {
      hold_status_id: hold?.status?.id as number,
      start_at: getDateFormat(formData.dates.startDate, "timestampStart") as number,
      end_at: getDateFormat(formData.dates.endDate, "timestampEnd") as number
    };

    changeValue({type: "hold", id: crewContact?.id, groupId: null, data});
  };

  if (!hold?.status?.with_dates) return null;

  const placeholder = <span className="RowDates-placeholder">Select dates...</span>;
  const isDate = !!hold?.start_at && !!hold?.end_at;
  const date = isDate && getDateRange({start_at: String(hold?.start_at), end_at: String(hold?.end_at)}); // prettier-ignore

  return (
    <SelectCell
      isLoading={isLoading}
      menuAnchor={menuAnchor}
      setMenuAnchor={setMenuAnchor}
      className={`RowDates ${menuAnchor ? "open" : ""}`}
      dropdownCustomClass={"RowDates-dropDown"}
      onCloseCallback={() => onClose()}
      btnChildren={
        <>
          {!date && placeholder}
          {date && <span style={getHoldColor(hold?.status)}>{date?.dataPeriod}</span>}
        </>
      }
    >
      <DateRangePicker {...formData.dates} separator="to" />
    </SelectCell>
  );
};
