import {FC, FormEvent, useState} from "react";
import {Button} from "components/shared/Button/Button";
import {FormWrap} from "components/shared/FormWrap/FormWrap";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {useJobTagsField} from "pages/Jobs/JobDetails/JobDetailsModals/EditJobDetails/JobTagsField/useJobTagsField";
import {JobTagsField} from "pages/Jobs/JobDetails/JobDetailsModals/EditJobDetails/JobTagsField/JobTagsField";
import {useHttp} from "hooks/httpServices/useHttp";
import {tCrewGroupContact} from "api/jobCrewGroup/types";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {jobCrewGroup} from "api/jobCrewGroup";
import {notValidForm} from "helpers/misc";
import {useContextJobCrew} from "../../../../JobCrewContext";
import {TagsRow} from "./TagsRow";
import {people} from "api/contacts/people";

import {eventBus} from "EventBus/EventBus";
import "./AddExistingContact.scss";

type tAddExistingContactProps = {
  group: tCrewGroupContact;
};

export const AddExistingContact: FC<tAddExistingContactProps> = ({group}) => {
  const {jobCrew} = useContextJobCrew();
  const FORM_ID = "add-existing-form-id";
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);

  const formData = {
    contacts: useJobTagsField({
      apiConfig: people.getList,
      searchKey: "search",
      additionalParams: {job_crew: jobCrew?.crew?.id}
    })
  };

  const {jobCrewGroupList: {getJobCrewGroupList}} = useContextJobCrew(); // prettier-ignore
  const {call, isLoading} = useHttp();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (notValidForm(formData)) return;

    try {
      const ids = formData.contacts.value?.map(item => item.id);

      const res = await call(jobCrewGroup.addContactListToCrewGroup(group?.id, {contacts: ids}));
      eventBus.dispatch("showToast", {text: res?.data?.message || "Success!"});
      getJobCrewGroupList();
    } catch (error) {
      const {msg} = apiErrorHandler(error, formData);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <>
      <Button size="sm" onClick={() => setShowModal(true)}>
        Existing
      </Button>

      {showModal && (
        <ModalPopup show={showModal} onClose={closeModal} withoutPaddings>
          <FormWrap
            headerTitle="Add existing contacts"
            submitTitle="Add"
            cancelTitle="Cancel"
            idForm={FORM_ID}
            onCancel={closeModal}
            withScroll={false}
            isLoading={isLoading}
          >
            <form className="AddExistingContact-form" onSubmit={onSubmit} id={FORM_ID}>
              <JobTagsField
                {...formData.contacts}
                tagsRow={<TagsRow contacts={formData.contacts} />}
                menuPlacement="bottom"
                alwaysShowSelect
                withSearchIcon
                labelKey="name"
                valueKey="id"
                selectPlaceholder="Search people..."
                tagsPosition="bottom"
              />
            </form>
          </FormWrap>
        </ModalPopup>
      )}
    </>
  );
};
