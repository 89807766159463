import {FC} from "react";

import {StyleSheet, View} from "@react-pdf/renderer";
import {SectionTitle} from "./SectionTitle";
import {Label, Value} from "./common";
import moment from "moment";
import {tAdvanceContact} from "api/budget/advanceContact/types";

interface iAdvanceInvoiceDetailsProps {
  invoiceNumber: string;
  date: string | null;
  terms: string;
  dueDate: string | null;
  advanceContact: tAdvanceContact | null;
}

export const AdvanceInvoiceDetails: FC<iAdvanceInvoiceDetailsProps> = ({
  invoiceNumber,
  advanceContact,
  dueDate,
  terms,
  date
}) => {
  const data = [
    {
      label: "Invoice no.",
      value: invoiceNumber
    },
    {
      label: "Date",
      value: date ? moment(date).format("MMMM DD, YYYY") : null
    },
    {
      label: "Terms",
      value: terms
    },
    {
      label: "Due Date",
      value: dueDate ? moment(dueDate).format("MMMM DD, YYYY") : null
    },
    {
      label: "CONTACT",
      value: advanceContact?.name || ""
    },
    {
      label: "",
      value: advanceContact?.phone || ""
      // value: formatPhoneNumber({value: advanceContact?.phone}) || ""
    },
    {
      label: "",
      value: advanceContact?.email || ""
    }
  ];

  return (
    <View>
      <SectionTitle>Details</SectionTitle>
      {data.map((item, index) => {
        if (!item.value) return null;
        return (
          <View key={item?.value + index} style={styles.row}>
            <Label>{item.label}</Label>
            <Value>{item.value}</Value>
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row"
  }
});
