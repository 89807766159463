import {FC, MouseEvent, useState} from "react";
import {Button} from "components/shared/Button/Button";
import {useFIltersExpenseList} from "./useFIltersExpenseList";
import {TagsField} from "components/shared/FormComponents/TagsField/TagsField";
import {RadioField} from "components/shared/FormComponents/RadioField/RadioField";
import {Popover} from "components/shared/Popover/Popover";
import {useGetAppliedFilters} from "./useGetAppliedFilters";
import "./ExpenseListFilter.scss";

export const ExpenseListFIlter: FC = () => {
  const [showFilter, setShowFilter] = useState<HTMLElement | null>(null); // prettier-ignore
  const {filterControl, clearFilters, lineNumberList, payIdList, qbList, payStatusList} = useFIltersExpenseList(); // prettier-ignore
  const {appliedFilters} = useGetAppliedFilters();

  const onShowFilter = (event: MouseEvent<HTMLElement>) => setShowFilter(event.currentTarget);

  return (
    <>
      <Button onClick={onShowFilter} size="sm" color={"outline"}>
        {appliedFilters}
      </Button>

      <Popover
        anchor={showFilter}
        onClose={() => setShowFilter(null)}
        //hotfix
        bodyStyles={{maxHeight: "710px"}}
      >
        <div className="ExpenseListFilter">
          <div className="ExpenseListFilter-row">
            <div className="ExpenseListFilter-label">Flag</div>
            <RadioField {...filterControl?.alert} />
          </div>
          <div className="ExpenseListFilter-row">
            <div className="ExpenseListFilter-label">Line number</div>
            <TagsField
              {...filterControl?.lineNumber}
              placeholder="Select"
              options={lineNumberList}
            />
          </div>
          {/* <div className="ExpenseListFilter-row">
            <div className="ExpenseListFilter-label">Expense image attached</div>
            <RadioField {...filterControl?.additional_files} />
          </div> */}
          <div className="ExpenseListFilter-row">
            <div className="ExpenseListFilter-label">Expense image attached</div>
            <RadioField {...filterControl?.actual_file} />
          </div>
          <div className="ExpenseListFilter-row">
            <div className="ExpenseListFilter-label">Do not bill</div>
            <RadioField {...filterControl?.bill_state} />
          </div>
          <div className="ExpenseListFilter-row">
            <div className="ExpenseListFilter-label">Green</div>
            <RadioField {...filterControl?.green} />
          </div>
          <div className="ExpenseListFilter-row">
            <div className="ExpenseListFilter-label">Pay ID</div>
            <TagsField {...filterControl?.pay_value} placeholder="Select" options={payIdList} />
          </div>
          <div className="ExpenseListFilter-row">
            <div className="ExpenseListFilter-label">QB Status</div>
            <TagsField {...filterControl?.qb_status} placeholder="Select" options={qbList} />
          </div>
          <div className="ExpenseListFilter-row">
            <div className="ExpenseListFilter-label">Pay Status</div>
            <TagsField
              {...filterControl?.pay_status}
              placeholder="Select"
              options={payStatusList}
            />
          </div>
          <div className="ExpenseListFilter-row">
            <div className="ExpenseListFilter-label">Quick pay</div>
            <RadioField {...filterControl?.qb_state} />
          </div>
          <div className="ExpenseListFilter-row">
            <div className="ExpenseListFilter-label">W8 Attached</div>
            <RadioField {...filterControl?.w8_file} />
          </div>
          <div className="ExpenseListFilter-row">
            <div className="ExpenseListFilter-label">W9 Attached</div>
            <RadioField {...filterControl?.w9_file} />
          </div>
          <div className="ExpenseListFilter-row">
            <div className="ExpenseListFilter-label">ACH Attached</div>
            <RadioField {...filterControl?.ach_file} />
          </div>
          <div className="ExpenseListFilter-row">
            <div className="ExpenseListFilter-label">WIRE Attached</div>
            <RadioField {...filterControl?.wire_file} />
          </div>

          <Button
            size="sm"
            color="light"
            className="ExpenseListFilter-button"
            onClick={clearFilters}
          >
            Clear Filters
          </Button>
        </div>
      </Popover>
    </>
  );
};
