import {FC} from "react";
import {useContextWrapPDF} from "./context";
import {Loading} from "components/shared/Loading/Loading";
// import {PdfViewer} from "components/shared/PdfViewer/PdfViewer";
// import {ViewersWrap} from "components/Viewers/ViewersWrap/ViewersWrap";
import "./WrapOverlay.scss";

export const WrapOverlay: FC = () => {
  const {url} = useContextWrapPDF();

  return (
    // <div className="WrapOverlay">
    //   {!url && <Loading type="inBlock" />}
    //   {/* <div className="WrapOverlay-document">
    //     <PdfViewer blobUrl={url} />
    //   </div> */}

    //   {/* <ViewersWrap fullHeight>
    //     <ViewersWrap.Main>
    //       <ViewersWrap.Viewer file={{url}} pdf hideToolbar />
    //     </ViewersWrap.Main>
    //   </ViewersWrap> */}
    // </div>
    <div style={{height: "100%", position: "relative"}}>
      {!url && <Loading type="inBlock" />}
      <iframe src={url} title="Wrap pdf" width={"100%"} height={"100%"} />
    </div>
  );
};
