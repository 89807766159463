import {FC, createContext, useState, useEffect, useContext} from "react";
import {useNavigate} from "react-router-dom";
import {getStorageData, removeStorageData} from "helpers/misc";
import {absRoutes} from "router";
import {useHttp} from "hooks/httpServices/useHttp";
import {profileInfo} from "api/profile";
import {iUpdateProfileData, iUserProfile} from "api/profile/types";
import {auth} from "api/auth";
import {getApiError} from "helpers/getApiError";
import {eventBus} from "EventBus/EventBus";

const useProfile = () => {
  const navigate = useNavigate();
  const {call, isLoading} = useHttp<{data: iUserProfile; message: string}>();
  const {call: callLogout, isLoading: logoutLoading} = useHttp();
  const {call: callUpdateUser, isLoading: updateLoading} =
    useHttp<{data: iUserProfile; message: string}>();
  const [userData, setUserData] = useState<iUserProfile | null>();
  const token = getStorageData()?.token || null;

  useEffect(() => {
    token ? getUser() : setUserData(null);
  }, []); // eslint-disable-line

  const getUser = () =>
    call(profileInfo.getUserProfile())
      .then(({data: {data}}) => setUserData(data))
      .catch(() => {
        setUserData(null);
        navigate(absRoutes.auth.login);
      });

  const updateUserData = async (data: iUpdateProfileData, formData: any) => {
    try {
      const res = await callUpdateUser(profileInfo.updateUserProfile(data));
      getUser();
      eventBus.dispatch("showToast", {text: res?.data?.message || "Success !"});
    } catch (error) {
      const {msg} = getApiError(error, formData);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error !"});
    }
  };

  const logout = async () => {
    try {
      await callLogout(auth.logout());
      removeStorageData();
      setUserData(null);
    } catch (error) {
      const {msg} = getApiError(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error !"});
    }
  };

  return {
    userData,
    setUserData,
    isLoading,
    logoutLoading,
    updateLoading,
    getUser,
    updateUserData,
    logout
  };
};

type tUserContext = ReturnType<typeof useProfile>;
const UserContext = createContext<tUserContext | null>(null);
UserContext.displayName = "UserContext";
export const UserContextProvider: FC = ({children}) => (
  <UserContext.Provider value={useProfile()}>{children}</UserContext.Provider>
);

/**
 * Provides all profile information
 * @returns (get and update profile info + logout)
 */
export const useContextProfile = () => {
  const context = useContext(UserContext);

  if (context === null) {
    throw new Error("<<< UserContext >>> must be used within a UserContextProvider");
  }

  return context as tUserContext;
};
