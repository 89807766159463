import {FC} from "react";
import {useOffsetTop} from "hooks/useOffsetTop";
import {useContextJobCrew} from "../JobCrewContext";
import {Loading} from "components/shared/Loading/Loading";
import {useGetCommonLists} from "./useGetCommonLists";
import {Table} from "components/Table/Table";
import {TableHeadItem} from "components/Table/TableHeadItem";
import {crewTableTitles} from "./crewTableTitles";
import {CrewTableBody} from "./CrewTableBody/CrewTableBody";
import "./CrewTable.scss";

export const CrewTable: FC = () => {
  const {jobCrewGroupList: {crewGroupList, isLoading}, adjustWidths, moveGroupSubmitting} = useContextJobCrew(); // prettier-ignore
  const {positionsList, holdStatusList} = useGetCommonLists();

  const {elementRef, offsetTop} = useOffsetTop();
  const tableHeight = elementRef?.current?.clientHeight;

  const renderHeaderList = () =>
    crewTableTitles?.map((item, i) => {
      return (
        <TableHeadItem
          key={item.id}
          data={item}
          tableHeigh={tableHeight}
          adjustWidth={{...adjustWidths[i]}}
        />
      );
    });

  const renderBodyList = () => {
    if (!crewGroupList?.length) return null;

    return crewGroupList?.map((group, index) => {
      return (
        <CrewTableBody
          key={group?.id}
          group={group}
          positionsList={positionsList}
          holdStatusList={holdStatusList}
          index={index}
        />
      );
    });
  };

  return (
    <div
      ref={elementRef}
      className="CrewTableWrap"
      style={{height: `calc(100vh - ${offsetTop}px)`}}
    >
      {(isLoading || moveGroupSubmitting) && <Loading type="inBlock" bgColor="transparent" />}

      {(!isLoading || !moveGroupSubmitting) && (
        <Table headerRowChildren={renderHeaderList()} bodyChildren={renderBodyList()} />
      )}
    </div>
  );
};
