export const toBool = (key: undefined | string) => {
  if (key === undefined) return undefined;
  if (!!key) return key === "true" ? true : false;
};

export const generateRadioList = ({
  name,
  label = "On",
  label2 = "Off"
}: {
  name: string;
  label?: string;
  label2?: string;
}) => {
  return [
    {label: label, name: name, value: "true"},
    {label: label2, name: name, value: "false"}
  ];
};

export function alphabetically({ascending, a, b}: {ascending: boolean; a: any; b: any}) {
  // equal items sort equally
  if (a === b) return 0;

  // nulls sort after anything else
  if (a === null || a === undefined) return 1;
  if (b === null || b === undefined) return -1;

  // otherwise, if we're ascending, lowest sorts first
  if (ascending) return a < b ? -1 : 1;

  // if descending, highest sorts first
  return a < b ? 1 : -1;
}
