import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {FC} from "react";
import "./SectionHeader.scss";

type tSectionHeaderProps = {
  title: string;
  className?: string;
};

export const SectionHeader: FC<tSectionHeaderProps> = ({title, children, className = ""}) => {
  return (
    <div className={`SectionHeader ${className}`}>
      <TextComponent uppercase size="26">
        {title}
      </TextComponent>

      {children}
    </div>
  );
};
