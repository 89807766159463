import {FC} from "react";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {tableTrackWAColumns} from "../../tableTrackWAHeadItems";
import "./CategoryHeader.scss";

type tCategoryHeaderProps = {
  title: string;
  showListToggler?: () => void;
  showList?: boolean;
};

export const CategoryHeader: FC<tCategoryHeaderProps> = ({title, showList, showListToggler}) => {
  return (
    <tr>
      <td colSpan={tableTrackWAColumns?.length}>
        <div className="CategoryHeader">
          {/* <TextComponent size="17" weight="700" uppercase onClick={showListToggler}> */}
          <TextComponent size="17" weight="700" uppercase>
            {/* {title} {showList !== undefined && <Icon icon={showList ? "arrow-up" : "arrow-down"} />} */}
            {title}
          </TextComponent>
        </div>
      </td>
    </tr>
  );
};
