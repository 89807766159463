import {FC, ReactNode, forwardRef} from "react";
import DatePicker, {ReactDatePickerProps} from "react-datepicker";
import {Icon} from "components/Icon/Icon";
import {useDateRangePickerReturn} from "hooks/useDateRangePicker";
import {TimeField} from "./TimeField";
import "./DateRangePicker.scss";

type tPickerProps = {label?: string};

interface iDateRangePickerProps extends useDateRangePickerReturn {
  start?: tPickerProps;
  end?: tPickerProps;
  startTitle?: string;
  endTitle?: string;
  separator?: ReactNode;
  startPickerProps?: Partial<ReactDatePickerProps>;
  endPickerProps?: Partial<ReactDatePickerProps>;
  withTime?: boolean;
  id?: string;
}

export const DateRangePicker: FC<iDateRangePickerProps> = ({
  startTitle = "",
  endTitle = "",
  separator,
  startPickerProps,
  endPickerProps,
  withTime = false,
  ...props
}) => {
  const {startDate, setStartDate, setEndDate, endDate, errors} = props;
  const {onChangeTime} = props;

  const InputWithIcon = forwardRef((props: any, ref: any) => (
    <div ref={ref} className="DateRangePicker-InputWithIcon" onClick={props.onClick}>
      <Icon icon="calendar" color="dark-gray" />
      <input type="text" {...props} readOnly />
    </div>
  ));

  const className = !!errors?.length ? "error" : "";

  return (
    <div className={`DateRangePicker input ${className}`} id={props?.id}>
      <div className="DateRangePicker-control">
        <div className="DateRangePicker-field">
          {startTitle && <label className="DateRangePicker-label">{startTitle}</label>}
          <div className="DateRangePicker-inputGroup">
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              // formatWeekDay={(date, "en-US") => nameOfDay.substr(0, 1)}
              customInput={<InputWithIcon />}
              {...startPickerProps}
            />
            {withTime && <TimeField time={startDate} type="start" onChange={onChangeTime} />}
          </div>
        </div>
        {separator && <div className="DateRangePicker-separator">{separator}</div>}
        <div className="DateRangePicker-field">
          {endTitle && <label className="DateRangePicker-label">{endTitle}</label>}
          <div className="DateRangePicker-inputGroup">
            <DatePicker
              selected={endDate}
              onChange={date => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              // formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
              customInput={<InputWithIcon />}
              {...endPickerProps}
            />
            {withTime && <TimeField time={endDate} type="end" onChange={onChangeTime} />}
          </div>
        </div>
      </div>
      {errors.map(key => (
        <p key={key} className="DateRangePicker-error-message">
          {key}
        </p>
      ))}
    </div>
  );
};
