import { useState } from "react";
import { notValidForm } from "helpers/misc";
import { eventBus } from "EventBus/EventBus";

type tabTypes = "about" | "accounts" | "personalInfo" | "emergency";

export const useAccountFormValidation = () => {

  const [showTab, setShowTab] = useState<Record<tabTypes, boolean>>({
    about: false,
    accounts: false,
    personalInfo: false,
    emergency: false
  });

  const notValid = (form: any) => {
    const { display_name, pronoun_id, birthday, fun_facts } = form;
    const acc = form?.accounts;
    const { phone, home_address, personal_email } = form;
    const { email, phoneEmergency, relationship, full_name } = form;

    const isNotValidAbout = notValidForm({ display_name, pronoun_id, birthday, fun_facts });
    const isNotValidAccounts = notValidForm({ acc });
    const isNotValidPersonalInfo = notValidForm({ phone, personal_email, home_address });
    const isNotValidEmergency = notValidForm({ email, phoneEmergency, relationship, full_name });

    if (isNotValidAbout || isNotValidAccounts || isNotValidPersonalInfo || isNotValidEmergency) {
      setShowTab(prev => ({
        ...prev,
        about: isNotValidAbout,
        accounts: isNotValidAccounts,
        personalInfo: isNotValidPersonalInfo,
        emergency: isNotValidEmergency
      }));
      eventBus.dispatch("showToast", { type: "error", text: "All fields should be valid!" });
      return true;
    }

    return false;
  };

  const changeShowTab = (type: tabTypes) => {
    setShowTab(prev => ({ ...prev, [type]: !prev[type] }));
  };

  return { changeShowTab, showTab, notValid };
};
