import {FC, SetStateAction, Dispatch} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {FormWrap} from "components/shared/FormWrap/FormWrap";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {TextareaField} from "components/shared/FormComponents/TextareaField/TextareaField";
import {Checkbox} from "components/shared/FormComponents/Checkbox/Checkbox";
import {tCalendarTemplateEvent} from "../../helpers";
import {useEventEdit} from "./useEventEdit";
import {Select} from "components/shared/FormComponents/Select/Select";
import {TimeField} from "components/shared/FormComponents/DateRangePicker/TimeField";
import {useContextTemplateCalendar} from "../../context";
import {Button} from "components/shared/Button/Button";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";

import "./EventEdit.scss";

interface iEventEditProps {
  event: any;
  setEvents: Dispatch<SetStateAction<tCalendarTemplateEvent[]>>;
  onClose: (clearId?: number) => void;
}

export const EventEdit: FC<iEventEditProps> = ({event, setEvents, onClose}) => {
  const {
    formData,
    startTime,
    endTime,
    onChangeStartTime,
    onChangeEndTime,
    createEventQuery,
    eventUpdateQuery,
    onDeleteEvent,
    handleSubmit,
    allDay,
    setAllDay,
    confirmDelete,
    setConfirmDelete,
    deleteEventQuery
  } = useEventEdit({event, setEvents, onClose});

  const {calendarInfo} = useContextTemplateCalendar();
  const handleChangeCheckbox = () => setAllDay((prev: boolean) => !prev);
  const handleClose = () => (event?.clearId ? onClose(event.clearId) : onClose());

  const options = Array.from({length: calendarInfo?.days || 30}, (_, i) => ({
    value: i + 1,
    label: `Day ${i + 1}`
  }));
  const isNewEvent = !!event?.id && event.id !== event?.clearId;

  return (
    <>
      <ModalPopup
        showCloseButton={false}
        className="EventEdit"
        withoutPaddings
        show={Boolean(event)}
        onClose={handleClose}
      >
        <FormWrap
          idForm="event-edit-form"
          submitTitle="Save"
          onCancel={handleClose}
          isLoading={createEventQuery.isLoading || eventUpdateQuery.isLoading}
          footer={
            isNewEvent ? (
              <Button onClick={() => setConfirmDelete(true)} className="EventEdit-deleteButton">
                delete
              </Button>
            ) : null
          }
        >
          <form id="event-edit-form" onSubmit={handleSubmit}>
            <InputField {...formData.name.inputProps} errors={formData.name.errors} label="Title" />
            <div className="EventEdit-daysRow">
              <Select
                {...formData.start_day.inputProps}
                options={options}
                targetKeys={{value: "value", label: "label"}}
                name="start_day"
                errors={formData.start_day.errors}
                canSelectEmpty
              />
              {!allDay && <TimeField type="start" time={startTime} onChange={onChangeStartTime} />}
              <span className="EventEdit-daysRow-separator">to</span>
              {!allDay && <TimeField type="end" time={endTime} onChange={onChangeEndTime} />}
              <Select
                {...formData.end_day.inputProps}
                options={options}
                targetKeys={{value: "value", label: "label"}}
                name="end_day"
                errors={formData.end_day.errors}
                canSelectEmpty
              />
            </div>

            <Checkbox
              checked={allDay}
              onChange={handleChangeCheckbox}
              className="EventEdit-checkbox"
              label="All day"
            />
            <TextareaField
              {...formData.description.inputProps}
              errors={formData.description.errors}
              label="Description"
            />
          </form>
        </FormWrap>
      </ModalPopup>
      <ConfirmWindow
        isLoading={deleteEventQuery.isLoading}
        show={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onConfirm={onDeleteEvent}
        title="DELETE EVENT?"
        warning="This event will be permanently deleted."
        cancelBtn={{
          text: "cancel"
        }}
        confirmBtn={{
          text: "delete",
          color: "red"
        }}
      />
    </>
  );
};
