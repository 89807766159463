import {FC} from "react";
import {tTrackTotalItems} from "api/budget/track/types";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {getDiffValueColor, getValue} from "../_common/getAndFormatVal";
import "./GrandTotal.scss";

type tGrandTotalProps = {
  togglePayroll: () => void;
  data: tTrackTotalItems | undefined;
};

export const GrandTotal: FC<tGrandTotalProps> = ({togglePayroll, data}) => {
  let a = getDiffValueColor(data?.total_difference || 0);
  console.log("a", a);

  const cells = [
    {
      component: (
        <TextComponent
          id="payroll-footer-id"
          size="15"
          className="GrandTotal-cell Total"
          onClick={togglePayroll}
        >
          Grand Total
        </TextComponent>
      )
    },
    {
      component: (
        <TextComponent size="15" className="GrandTotal-cell Budget" onClick={togglePayroll}>
          {getValue(data?.total_budget)}
        </TextComponent>
      ),
      colSpan: 5
    },
    {
      component: (
        <TextComponent
          weight="400"
          size="14"
          className="GrandTotal-cell WorkingTotal"
          onClick={togglePayroll}
        >
          {getValue(data?.total_working)}
        </TextComponent>
      )
    },
    {
      component: (
        <TextComponent size="15" className="GrandTotal-cell ActualTotal" onClick={togglePayroll}>
          {getValue(data?.total_actual)}
        </TextComponent>
      )
    },
    {
      component: (
        <TextComponent
          weight="400"
          size="14"
          className={`GrandTotal-cell Difference ${getDiffValueColor(data?.total_difference || 0)}`}
          onClick={togglePayroll}
        >
          {getValue(data?.total_difference)}
        </TextComponent>
      )
    },
    {
      component: <div className="GrandTotal-cell" onClick={togglePayroll} />,
      colSpan: 5
    }
  ];
  return (
    <tr className="GrandTotal">
      {cells.map((item, i) => {
        return (
          <td key={i} colSpan={item?.colSpan ? item.colSpan : 1}>
            {item.component}
          </td>
        );
      })}
    </tr>
  );
};
