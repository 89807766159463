import {FC} from "react";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {EmptyLinesToggler} from "./EmptyLinesToggler";
import "./EstimateTableHeader.scss";

type tEstimateTableHeaderProps = {};

const titles = [
  "",
  "Cost",
  "Crncy",
  "Budget",
  "Qty",
  "Time",
  "Unit",
  "AGY %",
  "Total",
  "Sales Tax",
  "Payroll",
  "Prod %",
  ""
] as const;

export const EstimateTableHeader: FC<tEstimateTableHeaderProps> = () => {
  return (
    <>
      {titles.map((item, i) => {
        if (i === 0) return <EmptyLinesToggler key={i} />;

        return (
          <TextComponent
            key={i}
            weight="500"
            size="13"
            className={`EstimateTableHeader-cell ${item === "Unit" ? "unit" : ""}`}
          >
            {item}
          </TextComponent>
        );
      })}
    </>
  );
};
