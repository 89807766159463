import {FC, useState} from "react";
import logo from "assets/images/VersatileStudios.svg";
import {TextComponent} from "pages/Jobs/JobDetails/common/TextComponent/TextComponent";
import {Icon} from "components/Icon/Icon";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {EditTitleForm} from "./EditTitleForm";
import {Button} from "components/shared/Button/Button";
import {useContextJobCallSheet} from "../../context/JobCallSheetContext";
import {getDateFormat} from "helpers/dates";
import {getName} from "helpers/misc";
import "./CallSheetBuildHeader.scss";

type tCallSheetBuildHeaderProps = {
  [key: string]: any;
};

export const CallSheetBuildHeader: FC<tCallSheetBuildHeaderProps> = () => {
  const {callSheetInfo} = useContextJobCallSheet();
  const {jobDetailsData} = useContextJobDetails();
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);

  return (
    <div className="CallSheetBuildHeader">
      <img src={logo} alt="versatile hub" />

      <div className="CallSheetBuildHeader-titleDate">
        <TextComponent size="20" uppercase>
          {callSheetInfo?.name}
          <Button onClick={() => setShowModal(true)}>
            <Icon icon="edit" />
          </Button>
        </TextComponent>
        <TextComponent>
          {callSheetInfo?.date ? getDateFormat(callSheetInfo.date, "ddd, MMM DD, YYYY") : ""}
        </TextComponent>
      </div>

      <div className="CallSheetBuildHeader-jobInfo">
        <TextComponent uppercase size="26">
          {getName(jobDetailsData?.client)}
        </TextComponent>
        <TextComponent uppercase size="26">
          {jobDetailsData?.name}
        </TextComponent>
        <TextComponent uppercase size="26">
          {getName(jobDetailsData?.artists?.[0]?.artist)}
        </TextComponent>
      </div>

      {showModal && (
        <ModalPopup show={showModal} onClose={closeModal} withoutPaddings size="w-528">
          <EditTitleForm closeModal={closeModal} />
        </ModalPopup>
      )}
    </div>
  );
};
