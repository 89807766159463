import {getColumnNameAndText} from "./helpers";
import {FC, useState, Fragment} from "react";
import {JobDetailsModal} from "../shared/Modals/JobDetailsModal/JobDetailsModal";
import {PeopleModals} from "../shared/Modals/PeopleModals/PeopleModals";
import {CompanyModals} from "../shared/Modals/CompanyModals/CompanyModals";
import {LocationsModals} from "../../pages/Locations/LocationsModals/LocationsModals";
import {TeamModals} from "../shared/Modals/TeamModals/TeamModals";

export const SearchRow: FC<{type: string; query: string; data: {[k: string]: any}}> = ({
  type,
  data,
  query
}) => {
  return (
    <Fragment>
      {type === "Jobs" && <JobRow item={data} query={query} />}
      {type === "People" && <PeopleRow item={data} query={query} />}
      {type === "Companies" && <CompanyRow item={data} query={query} />}
      {type === "Locations" && <LocationRow item={data} query={query} />}
      {type === "Team" && <UserRow item={data} query={query} />}
    </Fragment>
  );
};

export interface iRowProps {
  query: string;
  name: string;
  item: any;
  onClick: (...p: any) => void;
}

export const Row: FC<iRowProps> = ({query = "", name, item, onClick}) => {
  const {column, text} = getColumnNameAndText(query, item);

  return (
    <div className="Row" onClick={onClick}>
      <span className="Row-cell entry">{name}</span>
      <span className="Row-cell column"> {column} </span>
      <span className="Row-cell text" dangerouslySetInnerHTML={{__html: text}} />
    </div>
  );
};

interface iSearchItemRowProps {
  query: string;
  item: any;
}

const JobRow: FC<iSearchItemRowProps> = ({query, item}) => {
  const [showModal, setShowModal] = useState(false);
  const {name = "N/A"} = item;
  const nameWithId = `${item?.id} ${name}`;
  return (
    <>
      <Row name={nameWithId} query={query} item={item} onClick={() => setShowModal(true)} />
      {showModal && <JobDetailsModal job={item.id} onModalClose={() => setShowModal(false)} />}
    </>
  );
};

const PeopleRow: FC<iSearchItemRowProps> = ({item, query}) => {
  const [showModal, setShowModal] = useState(false);
  let name = `${item.first_name || ""} ${item.middle_name || ""} ${item.last_name || ""}`;

  if (!name.trim() && item.legal_name) {
    name = item.legal_name || "N/A";
  }

  return (
    <>
      <Row name={name} query={query} item={item} onClick={() => setShowModal(true)} />
      {showModal && <PeopleModals person={item.id} closePeopleModal={() => setShowModal(false)} />}
    </>
  );
};

const CompanyRow: FC<iSearchItemRowProps> = ({item, query}) => {
  const [showModal, setShowModal] = useState(false);
  const {name = "N/A"} = item;

  return (
    <>
      <Row name={name} item={item} query={query} onClick={() => setShowModal(true)} />
      {showModal && (
        <CompanyModals company={item.id} closeCompaniesModal={() => setShowModal(false)} />
      )}
    </>
  );
};

const LocationRow: FC<iSearchItemRowProps> = ({item, query}) => {
  const [showModal, setShowModal] = useState(false);
  const {name = "N/A"} = item;

  return (
    <>
      <Row name={name} onClick={() => setShowModal(true)} item={item} query={query} />
      {showModal && <LocationsModals location={item.id} closeModal={() => setShowModal(false)} />}
    </>
  );
};

const UserRow: FC<iSearchItemRowProps> = ({item, query}) => {
  const [showModal, setShowModal] = useState(false);
  let name = `${item.first_name} ${item.last_name}`;
  !name.trim() && (name = "N/A");

  return (
    <>
      <Row name={name} item={item} query={query} onClick={() => setShowModal(true)} />
      {showModal && <TeamModals teammate={item.id} closeTeamModal={() => setShowModal(false)} />}
    </>
  );
};
