import {iPersonDetails} from "../interfaces";

export const PEOPlE_FORM_ID = "People-form-id";

export const tabsName = ["Main", "Work", "Address & Billing", "Other", "Custom"];

export const getTabTitle = (person?: iPersonDetails | null) => {
  const name = `${person?.first_name ?? ""}${person?.middle_name ? " " + person.middle_name : ""}${
    person?.last_name ? " " + person.last_name : ""
  }`;
  return person?.id ? `Edit ${name}` : "Add Person";
};
