import {FC} from "react";
import {tTrackExpenseLine} from "api/budget/track/types";
import {useChangeExpenseInputValue} from "../_common/useChangeExpenseInputValue";
import {TotalCellWrap} from "../_common/TotalCellWrap";
import {TrackLineInput} from "../_common/TrackLineInput";
import {Select} from "components/shared/FormComponents/Select/Select";

type tExpenseRowActualProps = {
  className?: string;
  trackLine: tTrackExpenseLine;
};

export const ExpenseRowActual: FC<tExpenseRowActualProps> = ({className = "", trackLine}) => {
  const {isLoading, trackInputProps, select} = useChangeExpenseInputValue({
    id: trackLine?.id,
    type: "actual",
    defaultValue: trackLine?.actual_details?.amount?.toString() || "",
    defaultSelectValue: trackLine?.actual_details?.currency_code,
    estimate: trackLine?.estimate_id ? String(trackLine?.estimate_id) : "default"
  });

  const isAmount = !!trackLine?.actual_details;

  return (
    <TotalCellWrap
      isValue={isAmount}
      showInputField={trackInputProps.showInputField}
      trackLine={trackLine}
      className={className}
      type="actual"
    >
      <TrackLineInput {...trackInputProps} placeholder={"$0.00"} isLoading={isLoading} autoResize>
        <Select {...select.inputProps} />
      </TrackLineInput>
    </TotalCellWrap>
  );
};
