import {FC} from "react";
import {iJobDetailsDataReturn} from "api/jobs/types";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {Button} from "components/shared/Button/Button";
import {useEditJobDetails} from "./useEditJobDetails";
import {Loading} from "components/shared/Loading/Loading";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {TextareaField} from "components/shared/FormComponents/TextareaField/TextareaField";
import {TagsField} from "components/shared/FormComponents/TagsField/TagsField";
import {DateRangePicker} from "components/shared/FormComponents/DateRangePicker/DateRangePicker";
import {SearchClientField} from "components/shared/FormComponents/SearchClientField/SearchClientField";
import {JobContactsField} from "./JobContactsField/JobContactsField";
import {JobArtistField} from "./JobArtistField/JobArtistField";
import {JobTagsField} from "./JobTagsField/JobTagsField";
import {JobTeamTag} from "./JobTagsField/JobTeamTag";
import {JobLocationTag} from "./JobTagsField/JobLocationTag";
import {SearchField} from "components/shared/FormComponents/SearchField/SearchField";
import moment from "moment";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";

import "./EditJobDetails.scss";
import {useContextProfile} from "context";

interface iEditJobDetailsProps {
  job: iJobDetailsDataReturn | number;
  onClose(): void;
}

export const EditJobDetails: FC<iEditJobDetailsProps> = ({job, onClose}) => {
  const {userData} = useContextProfile();
  const isAdmin = userData?.role?.name === "Admin";
  const {
    localesData,
    onDelete,
    confirmDelete,
    setConfirmDelete,
    details,
    handleSubmit,
    isUpdatingJob,
    formData,
    deleteQuery,
    titles
  } = useEditJobDetails({
    job,
    onCloseCallback: onClose
  });

  const name = `${details?.creator?.first_name} ${details?.creator?.last_name}`;
  const createAuthor = `Created on ${moment(details?.created_at).format(
    "MMM DD, YYYY"
  )} by ${name}`;

  return (
    <>
      <ModalPopup show={true} onClose={onClose} className="EditJobDetails">
        {!details && <Loading type="inBlock" />}
        {details && (
          <form className="EditJobDetails-form" onSubmit={handleSubmit}>
            <div className="EditJobDetails-header">
              <h2>Edit Job details</h2>
            </div>

            <div className="EditJobDetails-body">
              <div className="EditJobDetails-row ">
                <InputField
                  value={formData?.number?.value}
                  label="Job Number"
                  className="job-number"
                  disabled
                />
                <InputField
                  {...formData.name.inputProps}
                  errors={formData.name.errors}
                  label="Name"
                  id="name"
                />
              </div>
              <SearchClientField
                {...formData.client}
                title="Client"
                id="client"
                className="SearchField-contacts"
              />
              <SearchField
                {...formData.ad_agency_id}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.name}
                label="Ad Agency"
                id="ad_agency_id"
                className="SearchField-contacts"
              />
              <DateRangePicker
                id="dates"
                {...formData.dates}
                startTitle="start date"
                endTitle="end date"
              />
              <TagsField
                id="locales"
                {...formData.locales.inputProps}
                label="Locale"
                options={localesData as Record<string, string | number>[]}
                errors={formData.locales.errors}
                menuPosition="absolute"
              />
              <JobContactsField id="people" {...formData.people} />
              <JobArtistField id="artists" {...formData.artists} titles={titles} />
              <JobTagsField
                {...formData.locations}
                id="locations"
                tagsRow={
                  <JobLocationTag
                    value={formData.locations.value}
                    onRemove={formData.locations.onRemove}
                  />
                }
                selectPlaceholder="Type to search locations..."
                title="Locations"
                labelKey="name"
                addButtonText="add location"
              />
              <TextareaField
                id="description"
                {...formData.description.inputProps}
                errors={formData.description.errors}
                label="description"
              />
              <TextareaField
                id="notes"
                {...formData.notes.inputProps}
                errors={formData.notes.errors}
                label="notes"
              />
              <JobTagsField
                id="team"
                blockAddButton={!isAdmin}
                {...formData.team}
                tagsRow={
                  <JobTeamTag
                    value={formData.team.value}
                    onRemove={isAdmin ? formData.team.onRemove : undefined}
                  />
                }
                menuPlacement="top"
                title="Team"
                addButtonText="add team member"
                labelKey="display_name"
              />

              <div className="EditJobDetails-controlRow">
                <p className="date">{createAuthor}</p>
                {isAdmin && (
                  <Button type="button" className="delete" onClick={() => setConfirmDelete(true)}>
                    delete job
                  </Button>
                )}
              </div>
            </div>

            <div className="EditJobDetails-footer">
              <Button size="md" onClick={onClose} disabled={isUpdatingJob}>
                cancel
              </Button>
              <Button size="md" color="light" loading={isUpdatingJob}>
                save
              </Button>
            </div>
          </form>
        )}
      </ModalPopup>

      <ConfirmWindow
        show={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onConfirm={onDelete}
        isLoading={deleteQuery.isLoading}
        title="Delete job?"
        subTitle="This job will be permanently deleted."
        confirmBtn={{
          color: "red",
          text: "Delete"
        }}
        cancelBtn={{
          text: "Cancel"
        }}
      />
    </>
  );
};
