import {FC, useEffect, useState} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {tTrackCostLineFile} from "api/budget/track/types";
import {tDeleteFIleProps} from "./DeleteFIle";
import {ViewersWrap} from "components/Viewers/ViewersWrap/ViewersWrap";
import {tViewersFile} from "components/Viewers/types";
import {UploadFileButton} from "../../_common/UploadFileButton";
import {useExpenseLineFile} from "../../TrackWA/TableTrackWA/Category/CategoryBody/ExpenseRow/_common/useExpenseLineFile";
import {eventBus} from "EventBus/EventBus";

type tFIleProps = {
  onClose: () => void;
  selectedFile: tTrackCostLineFile | undefined;
  typeFile?: tDeleteFIleProps["typeFile"];
  expenseLineId?: number;
  onSaveChanges?: ((file: any) => void) | undefined;
  disabled?: boolean;
  files?: tTrackCostLineFile[];
};

export const FIle: FC<tFIleProps> = ({
  onClose,
  selectedFile,
  files,
  typeFile,
  expenseLineId
  // onSaveChanges,
  // disabled
}) => {
  const [currFile, setSelectedFile] = useState<tTrackCostLineFile | undefined>(files?.[0]);
  const changeFile = (file: tViewersFile) => {
    if (!file.url || !file?.id) return console.log("file not completed");
    setSelectedFile({file_url: file.url, id: file?.id, file_name: file?.name});
  };

  const {attachFile, isLoading} = useExpenseLineFile();
  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!expenseLineId)
      return eventBus.dispatch("showToast", {type: "error", text: "Expense line id not found"});

    attachFile(e, expenseLineId, "actual");
  };

  const preparedFile = currFile && ViewersWrap.formatFromTrack(currFile);

  const isFiles = Array.isArray(files);
  const file = isFiles
    ? preparedFile
    : {
        name: selectedFile?.file_name,
        url: selectedFile?.file_url,
        id: selectedFile?.id
      };

  const deleteFile = isFiles ? currFile : selectedFile;
  const needToClose = !isFiles || !files?.length;

  useEffect(() => {
    setSelectedFile(files?.[0]);
  }, [files, selectedFile]);

  return (
    <ModalPopup size="md" show={true} onClose={onClose} className="FIleModal" withoutPaddings>
      <ViewersWrap>
        <ViewersWrap.Main>
          <ViewersWrap.Viewer
            file={file}
            uploadButton={
              typeFile === "actual" && (
                <UploadFileButton
                  inputProps={{
                    accept: ".jpg, .png, .jpeg, .pdf",
                    multiple: true
                  }}
                  callBack={onUpload}
                  title="UPLOAD FILES"
                  isLoading={isLoading}
                />
              )
            }
            onDeleteBtn={
              deleteFile && (
                <ViewersWrap.DeleteBtn
                  file={deleteFile}
                  typeFile={typeFile}
                  expenseLineId={expenseLineId}
                  onClose={needToClose ? onClose : undefined}
                />
              )
            }
          />
        </ViewersWrap.Main>

        {!!files?.length && (
          <ViewersWrap.Footer>
            <ViewersWrap.FileNavigation
              files={files?.map(file => ViewersWrap.formatFromTrack(file))}
              selectedFile={preparedFile}
              changeFile={changeFile}
            />
          </ViewersWrap.Footer>
        )}
      </ViewersWrap>

      {/* <EditFile
          selectedFile={selectedFile}
          typeFile={typeFile}
          expenseLineId={expenseLineId}
          onClose={onClose}
          onSaveChanges={onSaveChanges}
          disabled={disabled}
        /> */}
    </ModalPopup>
  );
};
