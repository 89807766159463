import {FC} from "react";
import {JobsTableFilter} from "../JobsTableFilter/JobsTableFilter";
import {JobsTableRow} from "components/shared/JobsTable/JobsTableRow/JobsTableRow";
import {Header} from "components/Header/Header";
import {TableToolbar} from "components/TableToolbar/TableToolbar";
import {Table} from "components/Table/Table";
import {Pagination} from "components/Pagination/Pagination";
import {TableHeadItem} from "components/Table/TableHeadItem";
import {jobsTableColumn} from "components/shared/JobsTable/jobsTableColumn";
import {useContextJobsList} from "../context/JobsListContext/JobsListContext";
import {useApiListNotification} from "hooks/useApiListNotification/useApiListNotification";
import {JobsHeaderButtons} from "../JobsHeaderButtons/JobsHeaderButtons";

import "./JobsList.scss";

export const JobsList: FC = () => {
  const {jobsListData, isLoading, isError, error, data, getJobsList} = useContextJobsList(); // prettier-ignore
  const {element} = useApiListNotification({isLoading, isError, error, list: jobsListData});

  const renderHeaderList = () =>
    jobsTableColumn.map(item => <TableHeadItem key={item.id} data={item} />);

  const renderBodyList = () => {
    if (!jobsListData?.length && !isError) return element;
    return jobsListData?.map(job => (
      <JobsTableRow key={job?.id} data={job} onEditCallback={getJobsList} id={job?.id} />
    ));
  };

  return (
    <div className="JobsList">
      <Header title="Jobs" children={<JobsHeaderButtons />} />
      <div className="content-padding ">
        <TableToolbar
          titleChildren={!!jobsListData?.length ? `Showing ${jobsListData?.length} Jobs` : ""}
          children={<JobsTableFilter />}
        />
        <Table
          scroll
          isLoading={isLoading}
          headerRowChildren={renderHeaderList()}
          bodyChildren={renderBodyList()}
        />
        {data && <Pagination meta={data.data.meta} />}
      </div>
    </div>
  );
};
