import {FC} from "react";
import {Button} from "components/shared/Button/Button";
import {useContextJobBudget} from "../../context/JobBudgetContext";
import {useHttp} from "hooks/httpServices/useHttp";
import {estimateApi} from "api/budget/estimate";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {eventBus} from "EventBus/EventBus";

type tRemoveApprovalProps = {
  onClose: () => void;
};

export const RemoveApproval: FC<tRemoveApprovalProps> = ({onClose}) => {
  const {estimate, setEstimate, getEstimateList} = useContextJobBudget();
  const {call, isLoading} = useHttp();

  const removeApproval = async () => {
    if (!estimate?.id) return;

    try {
      const res = await call(estimateApi.estimateApprove(estimate.id, {approved_at: null}));
      setEstimate(res?.data?.data);
      getEstimateList();
      onClose();
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  const isApproved = !!estimate?.approved_at;
  return (
    <>
      {isApproved && (
        <>
          <hr />
          <Button size="sm" width="fullWidth" onClick={removeApproval} disabled={isLoading}>
            Remove Approval
          </Button>
        </>
      )}
    </>
  );
};
