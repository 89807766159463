import {useState} from "react";
import {EmptyPageState} from "components/shared/EmptyPageState/EmptyPageState";
import Calendar from "assets/images/Calendar.svg";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";
import {NewCalendar} from "./modals/NewCalendar/NewCalendar";
import {NewCalendarFromTemplate} from "./modals/NewCalendarFromTemplate/NewCalendarFromTemplate";
import {Loading} from "components/shared/Loading/Loading";
import {JobCalendarContextProvider, useContextJobCalendar} from "./context";
import {useContextJobDetails} from "../../context/JobDetailsContext/JobDetailsContext";
import {Outlet} from "react-router-dom";

import "./JobCalendar.scss";

type tModal = "new" | "template";

export const JobCalendarComponent = () => {
  const {removeSection, removeSectionQuery} = useContextJobDetails();
  const {calendarsList, calendarsListQuery} = useContextJobCalendar();
  const {calendarTemplatesList, activeCalendar} = useContextJobCalendar();

  const [modal, setModal] = useState<tModal | null>(null);

  const onStartBlank = () => setModal("new");
  const onLoadTemplate = () => setModal("template");
  const onRemoveSection = () => removeSection();

  const isEmptyState = !activeCalendar || !calendarsList.length;
  const isLoading = removeSectionQuery.isLoading || !calendarsListQuery.data;

  return (
    <div className="JobCalendar">
      <Outlet />
      {isLoading && <Loading type="inBlock" />}
      {isEmptyState && (
        <EmptyPageState
          img={Calendar}
          text="How do you want to start your calendar?"
          primaryButton={{
            text: "Start blank",
            onClick: onStartBlank
          }}
          secondaryButton={{
            text: !!calendarTemplatesList?.length ? "Load Template" : "",
            onClick: onLoadTemplate
          }}
          removeButton={{
            text: "REMOVE CALENDAR SECTION",
            onClick: onRemoveSection
          }}
        />
      )}
      {modal && (
        <ModalPopup
          className="JobCalendar-creatingModal"
          show={!!modal}
          onClose={() => setModal(null)}
          showCloseButton={false}
        >
          {modal === "new" && <NewCalendar onCancel={() => setModal(null)} />}
          {modal === "template" && (
            <NewCalendarFromTemplate
              calendarTemplatesList={calendarTemplatesList}
              onCancel={() => setModal(null)}
            />
          )}
        </ModalPopup>
      )}
    </div>
  );
};

export const JobCalendar = () => {
  return (
    <JobCalendarContextProvider>
      <JobCalendarComponent />
    </JobCalendarContextProvider>
  );
};
