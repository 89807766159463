import {FC} from "react";

type tCrewTableRowInactiveProps = {
  inactiveLength: number;
};

export const CrewTableRowInactive: FC<tCrewTableRowInactiveProps> = ({inactiveLength}) => {
  return (
    <>
      <div className={`CrewTableCell CrewTableCell-firstRowItem CrewTableCell-inactive`}>
        {inactiveLength} inactive rows
      </div>
      <div className="CrewTableCell CrewTableCell-inactive CrewTableCell-full" />
    </>
  );
};
