import {FC, useState} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {TextComponent} from "../../common/TextComponent/TextComponent";
import {callSheet} from "api/callSheet";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useContextJobCallSheet} from "../context/JobCallSheetContext";
import {onSortName} from "../helpers";
import {useHttp} from "hooks/httpServices/useHttp";
import {eventBus} from "EventBus/EventBus";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {useNavigate} from "react-router-dom";

interface iCallSheetDeleteProps {
  onClose: () => void;
}

export const CallSheetDelete: FC<iCallSheetDeleteProps> = ({onClose}) => {
  const navigate = useNavigate();
  const {jobDetailsData} = useContextJobDetails();
  const {callSheetId, getCallSheetList} = useContextJobCallSheet(); // prettier-ignore
  const {call} = useHttp();

  const [isLoading, setIsLoading] = useState(false);

  const onDeleteCallSheet = async () => {
    if (!callSheetId || !jobDetailsData) return;
    setIsLoading(true);
    try {
      const res = await call(callSheet.deleteCallSheet(+callSheetId));
      const {
        data: {data}
      } = await getCallSheetList(jobDetailsData.id);

      eventBus.dispatch("showToast", {
        text: res?.data?.message || "Call sheet successfully deleted!"
      });

      if (data?.[0]?.id) {
        navigate(String(onSortName(data)?.[0]?.id));
      } else {
        navigate("");
      }
      onClose();
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ConfirmWindow
      onClose={onClose}
      className="CallSheetDelete"
      show={true}
      title="Delete call sheet?"
      subTitle="This call sheet will be permanently deleted."
      onConfirm={onDeleteCallSheet}
      isLoading={isLoading}
      confirmBtn={{
        color: "red",
        text: "Delete"
      }}
      cancelBtn={{
        text: "Cancel"
      }}
    >
      <TextComponent center color="red" size="14" weight="400">
        This can be undone.
      </TextComponent>
    </ConfirmWindow>
  );
};
