import {useContextJobCalendar} from "../../context";
import {useHttp} from "hooks/httpServices/useHttp";
import {Dispatch, FormEvent, SetStateAction, useEffect, useState} from "react";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {useDateRangePicker} from "hooks/useDateRangePicker";
import {useJobTagsField} from "pages/Jobs/JobDetails/JobDetailsModals/EditJobDetails/JobTagsField/useJobTagsField";
import {notValidForm} from "helpers/misc";
import moment from "moment/moment";
import {jobEventModel, tEventModal} from "../../helpers";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {iCreateEventFormData} from "api/jobs/calendar/types";
import {jobsCalendarApi} from "api/jobs/calendar";
import {eventBus} from "EventBus/EventBus";
import {getDateFormat} from "helpers/dates";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";

interface iUseEventEditProps {
  event: tEventModal;
  setShowEventDetails?: Dispatch<SetStateAction<tEventModal | null>>;
  onClose: (clearId?: number) => void;
  isUpdating: boolean;
}

export function useEventEdit({
  event,
  setShowEventDetails,
  isUpdating,
  onClose
}: iUseEventEditProps) {
  const {activeCalendar, setCalendarSheets} = useContextJobCalendar();
  const {jobDetailsData} = useContextJobDetails();
  const createEventQuery = useHttp();
  const eventUpdateQuery = useHttp();

  const [allDay, setAllDay] = useState(event?.all_day !== undefined ? event.all_day : true);

  function prepareAllDayEndDate(endDate: string | undefined, allDay: boolean) {
    if (!endDate || !allDay) return endDate;

    return moment(endDate).format("h:mm A") === "12:00 AM"
      ? moment(endDate).subtract(1, "minute").toDate()
      : moment(endDate).toDate();
  }

  let formData = {
    name: useTextInput({value: event?.name}),
    dates: useDateRangePicker({
      startDate: event?.start_date,
      endDate: event?.end_date || event?.start_date
    }),
    location: useJobTagsField({
      options: jobDetailsData?.locations,
      required: false,
      value: event?.location ? [event?.location] : []
    }),
    description: useTextInput({value: event?.description, isRequired: false})
  };

  useEffect(() => {
    const newEndDate = prepareAllDayEndDate(event?.end_date, event?.all_day) as Date | null;
    if (!newEndDate) return;
    formData.dates.setEndDate(newEndDate);
  }, []); // eslint-disable-line

  const handleSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (notValidForm(formData) || !activeCalendar) return;
    const {name, location, description} = formData;

    const start_date = formData.dates.startDate;
    const end_date = formData.dates.endDate;

    const payload = {
      job_calendar_id: activeCalendar.id,
      name: name.value,
      start_date: allDay ? getDateFormat(moment(start_date).startOf('day').toDate(), "timestamp") : getDateFormat(start_date, "timestamp"), // prettier-ignore
      end_date: allDay ? getDateFormat(moment(end_date).add(1, 'day').set({
        hour: 0,
        minutes: 0,
        second: 0
      }).toDate(), "timestamp") : getDateFormat(end_date, "timestamp"), // prettier-ignore
      all_day: allDay,
      ...(description.value && {description: description.value}),
      ...(!!location.value.length && {location_id: location.value[0].id})
    };

    try {
      const response = isUpdating
        ? await updateEvent(event.id, payload)
        : await createEvent(payload);
      onClose();
      eventBus.dispatch("showToast", {text: response?.data?.message});
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  const createEvent = async (payload: iCreateEventFormData) => {
    const response = await createEventQuery.call(jobsCalendarApi.createNewEvent(payload));
    const newEvent = jobEventModel(response.data.data, event?.type || "events");
    setCalendarSheets(prev => [...prev, newEvent]);
    return response;
  };

  const updateEvent = async (id: number, payload: iCreateEventFormData) => {
    const response = await eventUpdateQuery.call(jobsCalendarApi.updateJobCalendarEvent(event.id, payload)); // prettier-ignore
    const newEvent = jobEventModel(response.data.data, event?.type || "events");
    setShowEventDetails?.(newEvent);
    setCalendarSheets(prev =>
      prev.map(ev => {
        if (String(ev.id) === String(id)) {
          return newEvent;
        }
        return ev;
      })
    );
    return response;
  };

  return {
    formData,
    createEventQuery,
    eventUpdateQuery,
    handleSubmit,
    allDay,
    setAllDay
  };
}
