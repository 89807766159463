import {FC} from "react";
import {Icon} from "components/Icon/Icon";
import "./BudgetTypesListItem.scss";

interface iBudgetTypesListItemProps {
  handleClick: (item: any) => void;
  name: string;
  code?: number | string;
  id: number;
}

export const BudgetTypesListItem: FC<iBudgetTypesListItemProps> = ({
  id,
  name,
  code,
  handleClick
}) => {
  return (
    <div className="BudgetTypesListItem" onClick={() => handleClick(id)}>
      {code && <div className="BudgetTypesListItem-code">{code}</div>}
      <div className="BudgetTypesListItem-name">{name}</div>
      <button className="BudgetTypesListItem-button">
        <Icon icon="arrow-right" color="dark-gray" />
      </button>
    </div>
  );
};
