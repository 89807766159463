import {FC, FormEvent} from "react";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {InputField} from "components/shared/FormComponents/InputField/InputField";
import {useTextInput} from "hooks/useTextInput/useTextInput";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {eventBus} from "EventBus/EventBus";
import {advanceApi} from "api/budget/advance";
import {useContextAdvance} from "../context";
import {sortByKey} from "helpers/misc";

interface iAdvanceRenameProps {
  onClose: () => void;
}

export const AdvanceRename: FC<iAdvanceRenameProps> = ({onClose}) => {
  const {advanceDetails, setAdvanceList, setAdvanceDetails} = useContextAdvance();
  const {call, isLoading} = useHttp();

  const name = useTextInput({isRequired: true, value: advanceDetails?.name});

  const onConfirm = async (event: FormEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (name.value.length < 3) {
      name.setErrors(["The advance name must be at least 3 characters long"]);
      return;
    }

    if (!advanceDetails?.id) return;

    try {
      const {data: {message}} = await call(advanceApi.renameAdvance(advanceDetails.id, {name: name.value})); // prettier-ignore
      setAdvanceDetails(prevState => (prevState ? {...prevState, name: name.value} : null));
      setAdvanceList(prev => {
        const newList = prev.map(advance =>
          advance.id === advanceDetails.id ? {...advance, name: name.value} : advance
        );

        return sortByKey(newList, "name");
      });
      eventBus.dispatch("showToast", {text: message});
      onClose();
    } catch (error: any) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg});
    }
  };

  return (
    <ConfirmWindow
      isLoading={isLoading}
      show={true}
      onConfirm={onConfirm}
      onClose={onClose}
      title="Rename advance"
      subTitle="Enter a new name"
      confirmBtn={{
        text: "Rename"
      }}
      cancelBtn={{
        text: "Cancel"
      }}
    >
      <InputField {...name.inputProps} errors={name.errors} />
    </ConfirmWindow>
  );
};
