import {renderToString} from "react-dom/server";
import moment from "moment/moment";
import {Dispatch, SetStateAction} from "react";

export function jobSchedulerInit(
  scheduler: any,
  schedulerRef: any,
  setHeaderTitle: Dispatch<SetStateAction<string>>,
  initMode: string
) {
  const formatMonthScale = scheduler.date.date_to_str("%D");

  scheduler.skin = "material";
  scheduler.xy.nav_height = 0;

  scheduler.templates.month_scale_date = (date: Date) => {
    return renderToString(
      <span className="month-scale-bar">{date ? formatMonthScale(date) : ""}</span>
    );
  };

  scheduler.templates.month_date = (date: Date) => {
    const dateToStr_func = scheduler.date.date_to_str(scheduler.config.month_date);
    setHeaderTitle(dateToStr_func(date));
    return dateToStr_func(date);
  };

  scheduler.templates.week_date = (start: Date) => {
    const dateToStr_func = scheduler.date.date_to_str(scheduler.config.month_date);
    setHeaderTitle(dateToStr_func(start));
    return dateToStr_func(start);
  };

  scheduler.templates.month_day = (date: Date) => {
    return moment(date).format("D");
  };

  scheduler.templates.month_date_class = (date: Date) => {
    const day = date.getDay();
    const isWeekendDay: boolean = day === 6 || day === 0;
    return isWeekendDay ? "is-weekend-day" : "";
  };
  scheduler.templates.week_scale_date = (date: any) => {
    const isToday = moment().isSame(date, "d");
    const dateString = moment(date).format("ddd, MMM D");

    if (isToday) {
      return renderToString(<span className="week-scale-bar today">{dateString}</span>);
    }
    return renderToString(<span className="week-scale-bar">{dateString}</span>);
  };

  scheduler.plugins({
    agenda_view: true
  });

  scheduler.date.agenda_start = (date: any) => {
    return scheduler.date.month_start(new Date(date));
  };

  scheduler.date.add_agenda = (date: any, inc: any) => {
    return scheduler.date.add(date, inc, "month");
  };
  //
  scheduler.attachEvent("onTemplatesReady", function () {
    scheduler.templates.agenda_date = scheduler.templates.month_date;
  });

  scheduler.config.mark_now = true;
  scheduler.config.now_date = new Date();
  scheduler.config.dblclick_create = false;
  scheduler.config.start_on_monday = false;
  scheduler.config.container_autoresize = true;
  scheduler.config.update_render = false;
  scheduler.config.server_utc = true;
  scheduler.init(schedulerRef.current, new Date(), initMode, "%D");
}
