import {FC} from "react";
import {JobBudgetHeaderLayout} from "../../../JobBudgetHeaderLayout/JobBudgetHeaderLayout";
import {TrackNavigation} from "../../_common/TrackNavigation";
import {ExpenseMenu} from "./ExpenseMenu";
import {ExpenseSync} from "./ExpenseSync";
import {ExpenseListFIlter} from "../../_modals/ExpenseListFIlter/ExpenseListFIlter";
import {ExpenseListViewOptions} from "../../_modals/ExpenseListViewOptions/ExpenseListViewOptions";
import "./ExpenseHeader.scss";

type tExpenseHeaderProps = {
  [key: string]: any;
};

export const ExpenseHeader: FC<tExpenseHeaderProps> = () => {
  return (
    <JobBudgetHeaderLayout>
      <ExpenseSync />

      <ExpenseListFIlter />
      <ExpenseListViewOptions />

      <TrackNavigation />
      <ExpenseMenu />
    </JobBudgetHeaderLayout>
  );
};
