import {FC} from "react";
import {iTeammateDetailsData} from "api/team/types";
import {TeamMemberFooter} from "../shared/TeamMemberFooter";
import {TeamMemberHeader} from "../shared/TeamMemberHeader";
import {Reactivate} from "./Reactivate";
import {ModalInfoSection} from "components/shared/ModalInfoSection/ModalInfoSection";
import {parseAddress} from "helpers/misc";
import {getDateFormat} from "helpers/dates";
import {Button} from "components/shared/Button/Button";
import {Icon} from "components/Icon/Icon";
import {AccountsList} from "components/shared/AccountsList/AccountsList";
import {useContextProfile} from "context";
import {ModalInfoSectionWrap} from "components/shared/ModalInfoSection/ModalInfoSectionWrap";
import "./TeamMemberDetails.scss";

interface iTeamMemberDetails {
  teammateDetail: iTeammateDetailsData;
  type: "active" | "inactive";
  openEdit?: () => void;
  onCloseModal: () => void;
}

export const TeamMemberDetails: FC<iTeamMemberDetails> = ({
  teammateDetail,
  type,
  openEdit,
  onCloseModal
}) => {
  const {userData} = useContextProfile();
  const isAdmin = userData?.role?.name === "Admin";
  const {emergency_contact, status, role, notes, start_date, phone} = teammateDetail;
  const {display_name, pronoun, pronoun_custom, birthday, fun_facts, personal_email, accounts} =
    teammateDetail;

  const isAccounts = !!teammateDetail?.accounts?.length;
  const isAddress = !!Object.keys(teammateDetail?.home_address || {}).length;

  return (
    <div className={`TeamMemberDetails ${type}`}>
      <TeamMemberHeader teammateDetail={teammateDetail}>
        {isAdmin && type === "active" && (
          <Button size="md" color="light" onClick={openEdit}>
            <Icon icon="edit" size="sm" color="black" /> Edit
          </Button>
        )}
        {isAdmin && type === "inactive" && (
          <Reactivate teammateDetail={teammateDetail} onCloseModal={onCloseModal} />
        )}
      </TeamMemberHeader>

      <ModalInfoSectionWrap>
        <ModalInfoSection
          labelSize="sm"
          list={[
            {label: "Mobile", value: phone},
            {label: "Personal", value: personal_email},
            {
              label: "Address",
              value: isAddress && parseAddress(teammateDetail.home_address).string
            },
            {label: "Accounts", value: isAccounts && <AccountsList accountsList={accounts} />}
          ]}
        />
        <ModalInfoSection
          labelSize="sm"
          list={[
            {label: "Nickname", value: display_name},
            {label: "Pronouns", value: pronoun_custom || pronoun?.name},
            {label: "Birthday", value: getDateFormat(birthday, "MMM DD, YYYY")},
            {label: "Fun Facts", value: fun_facts}
          ]}
        />
      </ModalInfoSectionWrap>

      <ModalInfoSection
        title="EMERGENCY CONTACT"
        list={[
          {label: "Name", value: emergency_contact?.full_name},
          {label: "Relationship", value: emergency_contact?.relationship},
          {
            label: "Phone",
            value: emergency_contact?.phone
            // value: formatPhoneNumber({
            //   value: emergency_contact?.phone
            // })
          },
          {label: "Email", value: emergency_contact?.email}
        ]}
      />

      <ModalInfoSection
        title="INTERNAL"
        list={[
          {label: "Status", value: status?.name, valueClass: "capitalize"},
          {label: "Role", value: role?.name, valueClass: "capitalize"},
          {label: "Notes", value: notes},
          {label: "Start Date", value: getDateFormat(start_date, "MMM DD, YYYY")}
        ]}
      />

      <TeamMemberFooter teammateDetail={teammateDetail} />
    </div>
  );
};
