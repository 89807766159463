import {FC} from "react";
import {useContextJobTasks} from "../_context/JobTaskContext";
import {Table} from "components/Table/Table";
import {TableGroup} from "./TableGroup/TableGroup";
import {Loading} from "components/shared/Loading/Loading";
import "./JobTaskTable.scss";

type tJobTaskTableProps = {
  [key: string]: any;
};

export const JobTaskTable: FC<tJobTaskTableProps> = () => {
  const {tasks, isLoading} = useContextJobTasks();

  const renderBodyList = () => {
    return tasks?.data?.map((item, i) => {
      return <TableGroup key={item.name} data={item} index={i} />;
    });
  };

  return (
    <div className="JobTaskTable">
      {isLoading && <Loading type="inBlock" bgColor="transparent" />}

      {!!tasks?.data && <Table bodyChildren={renderBodyList()} />}
    </div>
  );
};
