import {FC, useState} from "react";
import {tCrewContact} from "api/jobCrewGroup/types";
import {InputCell} from "components/shared/InputCell/InputCell";
import {useChangeCrewValue} from "../_common/useChangeCrewValue";
import "./CrewRowNote.scss";

type tRowNotesProps = {
  crewContact: tCrewContact;
  groupId: number;
};

export const RowNotes: FC<tRowNotesProps> = ({crewContact, groupId}) => {
  const [value, setValue] = useState(crewContact?.notes || "");
  const {changeValue, isLoading} = useChangeCrewValue();

  const setNotesForContact = async (val: string) => {
    changeValue({
      type: "notes",
      id: crewContact?.id,
      groupId,
      data: {notes: val},
      errorCallback: () => setValue(crewContact?.notes || "")
    });
  };

  return (
    <InputCell
      value={value}
      setValue={setValue}
      isLoading={isLoading}
      placeholder="Add note..."
      initValue={crewContact?.notes || ""}
      callBack={val => setNotesForContact(val)}
      className="CrewRowNote"
    />
  );
};
