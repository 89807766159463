import {FC, useState} from "react";
import {Icon} from "components/Icon/Icon";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {useHttp} from "hooks/httpServices/useHttp";
import {tJobTask} from "api/jobs/tasks/types";
import {jobTasksApi} from "api/jobs/tasks";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useContextJobTasks} from "pages/Jobs/JobDetails/JobTasks/_context/JobTaskContext";

type tDeleteFileBtnProps = {
  data: tJobTask;
};

export const DeleteFileBtn: FC<tDeleteFileBtnProps> = ({data}) => {
  const {setTasks} = useContextJobTasks();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const {call, isLoading} = useHttp();

  const onDelete = async () => {
    if (!data?.id) return;

    try {
      const res = await call(jobTasksApi.detachFile(data.id));
      setTasks(res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <>
      <button className="EditFile-editButton" onClick={() => setConfirmDelete(true)}>
        <Icon color="dark-gray" size="md" icon="delete" />
      </button>

      <ConfirmWindow
        title="Delete this Image?"
        subTitle="This image will be permanently deleted."
        show={confirmDelete}
        onConfirm={onDelete}
        onClose={() => setConfirmDelete(false)}
        cancelBtn={{text: "Cancel"}}
        confirmBtn={{text: "delete", color: "red"}}
        isLoading={isLoading}
      />
    </>
  );
};
