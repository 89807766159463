import {useEffect, useState} from "react";
import {Header} from "components/Header/Header";
import {
  SchedulerDashboard,
  tSchedulerEvent
} from "components/shared/SchedulerDashboard/SchedulerDashboard";
import {tabs} from "../navModel";
import {Button} from "components/shared/Button/Button";
import {useCalendar} from "./useCalendar";
import {useScript} from "hooks/useScript";
import {Loading} from "components/shared/Loading/Loading";
import {tCalendar} from "api/dashboard/calendar/type";

import "./Calendar.scss";
import moment from "moment";
import {getDateFormat} from "../../../helpers/dates";

const Calendar = () => {
  const {list, apiParams, onExport, isDownloading} = useCalendar();
  const {status, removeScript} = useScript(
    "https://cdn.dhtmlx.com/scheduler/edge/dhtmlxscheduler.js"
  );

  const [events, setEvents] = useState<Array<tSchedulerEvent>>([]);

  useEffect(() => {
    if (!list) return;
    let newEvents: Array<tSchedulerEvent> = list?.map(prepareEventToCalendar);

    setEvents(newEvents);
  }, [list]);

  function prepareEventToCalendar(item: tCalendar): tSchedulerEvent {
    return {
      ...item,
      start_date: moment(item.start_at).toDate() as any,
      end_date: prepareEndDate(item.start_at, item.end_at) as string,
      departments: item.departments
    };
  }

  function prepareEndDate(start_date: string, end_date: string) {
    if (moment(start_date).isSame(end_date, "date")) {
      return moment(end_date).startOf("day").add(2, "days").toDate();
    }
    return end_date;
  }

  useEffect(() => {
    return () => removeScript();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const scriptIsLoaded = status === "ready";

  return (
    <div className="Calendar">
      <Header title="Dashboard" tabs={tabs}>
        <Button loading={isDownloading} size="md" color="light" onClick={onExport}>
          Export
        </Button>
      </Header>

      <div className="content-body" id="contentBody">
        {isDownloading && <Loading type="inBlock" className="SchedulerDashboard-loading" />}
        {scriptIsLoaded && <SchedulerDashboard events={events} apiParams={apiParams} />}
      </div>
    </div>
  );
};

export default Calendar;
