import {FC} from "react";
import {ModalPopup} from "components/shared/ModalPopup/ModalPopup";

import {CallSheetSenderRecipients} from "../../CallSheetSenderRecipients/CallSheetSenderRecipients";
import {CallSheetSenderForm} from "../../CallSheetSenderForm/CallSheetSenderForm";
import {useCallSheetSender} from "./useCallSheetSender";
import {useContextJobCallSheet} from "../../context/JobCallSheetContext";

import "./CallSheetSender.scss";

interface iCallSheetSenderProps {
  onClose: () => void;
}

export const CallSheetSender: FC<iCallSheetSenderProps> = ({onClose}) => {
  const {deliveryInfo} = useContextJobCallSheet();
  const {
    step,
    setStep,
    groups,
    setGroups,
    isLoading,
    onRecipientSubmitCallback,
    onSubmitCallback
  } = useCallSheetSender({onClose}); // prettier-ignore

  const senderTitle = deliveryInfo ? "Send Updated Call Sheet" : "Send Call Sheet";

  return (
    <ModalPopup
      size={step === 0 ? "w-528" : "md"}
      className="CallSheetSender"
      show={true}
      onClose={onClose}
      withoutPaddings
    >
      {step === 0 && (
        <CallSheetSenderRecipients
          onRecipientSubmitCallback={onRecipientSubmitCallback}
          contactsGroups={groups}
          setContactsGroups={setGroups}
          formWrapProps={{
            headerTitle: senderTitle
          }}
        />
      )}
      {step === 1 && (
        <CallSheetSenderForm
          type="schedule"
          isLoading={isLoading}
          formWrapProps={{
            onBack: () => setStep(0),
            headerTitle: senderTitle
          }}
          onSubmitCallback={onSubmitCallback}
          deliveryInfo={deliveryInfo}
        />
      )}
    </ModalPopup>
  );
};
