import {FC, useState} from "react";
import {team} from "api/team";
import {iTeammateDetailsData} from "api/team/types";
import {Icon} from "components/Icon/Icon";
import {Button} from "components/shared/Button/Button";
import {ConfirmWindow} from "components/shared/ConfirmWindow/ConfirmWindow";
import {getApiError} from "helpers/getApiError";
import {useHttp} from "hooks/httpServices/useHttp";
import {eventBus} from "EventBus/EventBus";

interface iResentInviteProps {
  teammateDetail: iTeammateDetailsData;
}

export const ResentInvite: FC<iResentInviteProps> = ({teammateDetail}) => {
  const [showConfirmWindow, setConfirmWindow] = useState(false);
  const {call, isLoading} = useHttp();

  const closeConfirmModal = () => setConfirmWindow(false);

  const onResentInvite = async () => {
    try {
      const res = await call(team.resentInvite(teammateDetail.id));
      const successMsg = res?.data?.message;
      eventBus.dispatch("showToast", {text: successMsg || "Success !"});
      closeConfirmModal();
    } catch (error) {
      const {msg} = getApiError(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error !"});
    }
  };
  return (
    <>
      <Button size="md" color="light" onClick={() => setConfirmWindow(true)}>
        <Icon icon="resend" color="black" size="sm" /> Resent Invite
      </Button>

      <ConfirmWindow
        show={showConfirmWindow}
        title="Resent Invite ?"
        confirmBtn={{text: "Resent Invite"}}
        cancelBtn={{text: "Cancel"}}
        onConfirm={onResentInvite}
        onClose={closeConfirmModal}
        isLoading={isLoading}
      >
        <div className="TeamMemberConfirmMessage">
          Do you really want to resent invite for{" "}
          <span className="noWrap">{teammateDetail?.display_name}</span>?
        </div>
      </ConfirmWindow>
    </>
  );
};
