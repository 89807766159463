import {profileInfo} from "api/profile";
import {useContextProfile} from "context/UserContext/UserContext";
import {eventBus} from "EventBus/EventBus";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useHttp} from "hooks/httpServices/useHttp";
import {tooltips, tTooltipPages, tTooltipTypes} from "./tooltips";

type tProps = {
  tooltip: tTooltipTypes;
  page: tTooltipPages;
};

export const useHideTooltip = ({page, tooltip}: tProps) => {
  const {setUserData, userData} = useContextProfile();
  const hiddenTooltips = userData?.tooltips;
  const currPage = tooltips[page];

  const {call, isLoading} = useHttp({cancelRequest: false});

  const onCloseTooltip = async (type: "single" | "all") => {
    const allPageTooltips = currPage?.map(item => item?.title);
    const data = type === "single" ? [tooltip] : (allPageTooltips as Array<tTooltipTypes>);
    setUserData(prev => {
      if (!prev) return prev;
      return {
        ...prev,
        tooltips: !!prev?.tooltips?.length ? [...prev.tooltips, ...data] : [...data]
      };
    });

    try {
      const res = await call(profileInfo?.setTooltips({tooltips: data}));
      setUserData(res?.data?.data);
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});

      setUserData(prev => {
        if (!prev) return prev;
        return {
          ...prev,
          tooltips: !!prev?.tooltips?.length
            ? [...prev.tooltips?.filter(item => !data?.includes(item))]
            : []
        };
      });
    }
  };

  return {onCloseTooltip, isLoading, hiddenTooltips, currPage};
};
