import {FC} from "react";
import {Loading} from "components/shared/Loading/Loading";
import {JobTasksContextProvider, useContextJobTasks} from "./_context/JobTaskContext";
import {JobTasksHeader} from "./JobTasksHeader/JobTasksHeader";
import {JobTaskTable} from "./JobTaskTable/JobTaskTable";
import "./JobTasks.scss";

interface iJobTasksProps {}

export default function JobTasks() {
  return (
    <JobTasksContextProvider>
      <JobTasksComponent />
    </JobTasksContextProvider>
  );
}

export const JobTasksComponent: FC<iJobTasksProps> = () => {
  const {tasks, isLoading} = useContextJobTasks();

  return (
    <div className="JobTasks">
      {isLoading && !tasks?.id && <Loading type="inBlock" />}

      {!!tasks?.id && (
        <>
          <JobTasksHeader />
          <JobTaskTable />
        </>
      )}
    </div>
  );
};
