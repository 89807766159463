import {FC} from "react";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {ContactBlock} from "../common/ContactBlock";
import {Title} from "../common/Title";

interface iAgencyProps {
  [key: string]: any;
}

export const Agency: FC<iAgencyProps> = () => {
  const {jobDetailsData} = useContextJobDetails();
  const ad_agency = jobDetailsData?.ad_agency;

  if (!ad_agency) return null;
  const address = `${ad_agency?.address?.city || ""}${
    ad_agency?.address?.state ? ", " + ad_agency?.address?.state : ""
  }`;

  return (
    <div className="Agency">
      <Title text="Ad Agency" />
      <ContactBlock
        title={`${ad_agency?.name || ""}`}
        subTitle={address}
        imgUrl={ad_agency?.avatar_url}
        fallBackType="default"
        id={ad_agency?.id}
        type="company"
      />
    </div>
  );
};
