import {FC, useState} from "react";
import {LoadFromTemplate} from "./LoadFromTemplate";
import {EmptyPageState} from "components/shared/EmptyPageState/EmptyPageState";
import {jobCrew} from "api/jobCrew";
import {apiErrorHandler} from "helpers/apiErrorHandler";
import {useContextJobDetails} from "pages/Jobs/context/JobDetailsContext/JobDetailsContext";
import {useContextJobCrew} from "../JobCrewContext";
import {useHttp} from "hooks/httpServices/useHttp";
import {eventBus} from "EventBus/EventBus";
import crew from "assets/images/Crew.svg";

type tEmptyStateProps = {};

export const EmptyState: FC<tEmptyStateProps> = () => {
  const {removeSection, removeSectionQuery} = useContextJobDetails();
  const [showTemplateModal, setTemplateModal] = useState(false);
  const closeTemplateModal = () => setTemplateModal(false);
  const onRemoveSection = () => removeSection();

  const {jobCrew: {getJobCrew}} = useContextJobCrew(); // prettier-ignore
  const {jobDetailsData} = useContextJobDetails(); // prettier-ignore
  const id = jobDetailsData?.id;
  const {call, isLoading} = useHttp();

  const createCrew = async () => {
    if (!id) return;
    try {
      const res = await call(jobCrew.addCrew({job_id: id}));
      eventBus.dispatch("showToast", {text: res?.data?.message || "Success!"});
      getJobCrew();
    } catch (error) {
      const {msg} = apiErrorHandler(error);
      eventBus.dispatch("showToast", {type: "error", text: msg || "Error!"});
    }
  };

  return (
    <>
      <EmptyPageState
        img={crew}
        text="How do you want to start your crew list?"
        primaryButton={{text: " Start Blank", onClick: createCrew}}
        secondaryButton={{text: "Load Template", onClick: () => setTemplateModal(true)}}
        removeButton={{text: "REMOVE CREW SECTION", onClick: onRemoveSection}}
        isLoading={isLoading || removeSectionQuery.isLoading}
      />

      {showTemplateModal && (
        <LoadFromTemplate
          closeTemplateModal={closeTemplateModal}
          showTemplateModal={showTemplateModal}
        />
      )}
    </>
  );
};
